import * as React from 'react';
import { ComponentMeta, Story } from '@storybook/react';
import { MemoryRouter } from 'react-router-dom';
import { TextLink, Props } from './textLink';

export default {
  title: 'TextLink',
  component: TextLink,
  parameters: {
    docs: {
      description: {
        component: `
Figma url: https://www.figma.com/file/eEddHpyen6jqDNjkjLN4vP/%E3%83%90%E3%82%A4%E3%83%88%E3%83%A9%E3%83%99%E3%83%AB%E9%81%8B%E5%96%B6%E7%AE%A1%E7%90%86?node-id=1852%3A32410&t=8epZEks2W0TYxsPR-4
`,
      },
    },
  },
  decorators: [(storyFn) => <MemoryRouter>{storyFn()}</MemoryRouter>],
  argTypes: {
    width: {
      description: '未指定の場合デフォルトはnormalだと56px、bigだと108px',
    },
  },
} as ComponentMeta<typeof TextLink>;

const Template: Story<Props> = ({ children, ...args }) => <TextLink {...args}>{children}</TextLink>;

export const AnchorLink = Template.bind({});
AnchorLink.args = {
  children: '所属',
  href: 'https://google.com',
  onClick: undefined,
};

export const ReactRouterLink = Template.bind({});
ReactRouterLink.args = {
  children: '所属',
  to: '/',
  onClick: undefined,
};

export const ButtonLink = Template.bind({});
ButtonLink.args = {
  children: 'リンクの見た目をしたボタン',
  onClick: () => {},
};
