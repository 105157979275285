import React from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';

export interface Props {
  items: {
    // レンダリングの最適化のために一意なキーを設定して下さい
    uniqueKey: string | number;
    // text: string;
    text: React.ReactNode;
    dateTime: string;
  }[];
}

const StyledWrapper = styled.ul`
  width: 100%;
`;

const StyledListRow = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  border-bottom: 1px solid #dbdbdb;
  height: 59px;
  box-sizing: border-box;
  list-style-type: none;
  :first-child {
    border-top: 1px solid #dbdbdb;
  }
`;

export function HistoryList({ items }: Props) {
  return (
    <StyledWrapper>
      {items.map((item) => (
        <StyledListRow key={item.uniqueKey}>
          <Text variant="body14">{item.text}</Text>
          <Text variant="caption11" color="darkGray">
            {item.dateTime}
          </Text>
        </StyledListRow>
      ))}
    </StyledWrapper>
  );
}
