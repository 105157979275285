/**
 * Figma ID: 07-02-03
 * 名称: 逆オファー（受入店情報）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Link as RouterLink } from 'react-router-dom';

type TpmemDetailHeaderModel = {
  tpmemName?: string;
  tpmemId?: number;
};
function TpmemDetailHeader(props: TpmemDetailHeaderModel) {
  const { tpmemName, tpmemId } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Text variant="h2" color="darkBlue">
        {tpmemName}
      </Text>
      <RouterLink to={`/s03/${tpmemId ?? ''}`} style={{ textDecoration: 'none' }}>
        <MainButton width={74} variant="secondary" thin>
          詳しく
        </MainButton>
      </RouterLink>
    </Box>
  );
}

export { TpmemDetailHeader };
