/**
 * Figma ID: 04-02-01
 * 名称: 成約バイト状況
 */
import React, { ReactNode, useEffect } from 'react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { Icon } from 'components/icon';
import { DatePicker } from 'components/datePicker';
import { orderBy } from 'lodash';
import { List } from 'components/list';
import { useNavigate } from 'react-router-dom';
import {
  VtMemberBase,
  VtWorkingList,
  useGetVtWorkingListQuery,
  useGetVtMemberBaseByIdLazyQuery,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { AgreementStatus } from 'components/const';
import {
  MainContentWrapper,
  MainContentHeader,
  MainContentTitle,
  MainContentArea,
} from 'components/pageLayout/mainContent';
import { useErrorModal } from 'components/error/errorModalProvider';
import { isSameDay } from 'date-fns';
import { TextLink } from 'components/textLink';
import { StoreModal } from 'modal/storeModal';
import { StatusChip } from './components/statusChip';

// 成約バイト状況
export function S0402() {
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();
  const {
    data: { getVTWorkingList: workingList } = { getVTWorkingList: [] },
    loading,
    error: getVTWorkingListError,
  } = useGetVtWorkingListQuery();
  const [getVtMemberBaseById, { error: getVtMemberBaseByIdError }] = useGetVtMemberBaseByIdLazyQuery();

  useEffect(() => {
    if (getVTWorkingListError || getVtMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVTWorkingListError, getVtMemberBaseByIdError, openErrorModal]);

  const [searchNo, setSearchNo] = React.useState<string>('');
  const [searchTitle, setSearchTitle] = React.useState<string>('');
  const [searchStore, setSearchStore] = React.useState<string>('');
  const [searchName, setSearchName] = React.useState<string>('');

  // 検索条件用の型
  type SearchCondition = {
    no: string;
    title: string;
    store: string;
    name: string;
  };

  const [searchConditions, setSearchConditions] = React.useState<SearchCondition | null>(null);
  const [workingDay, setWorkingDay] = React.useState<Date | null>(null);
  const [statusSelect, setStatusSelect] = React.useState<string>('');
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();
  const [vtMemberBase, setVtMemberBase] = React.useState<VtMemberBase | null>(null);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeSort = (key: string) => {
    setCurrentPage(1);
    if (sort?.key !== key) {
      setSort({ key, direction: 'asc' });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({ key, direction: 'desc' });
        return;
      }
    }
    setSort(undefined);
  };

  /**
   * 一覧のヘッダ部
   */
  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { columnName: '', key: 'eye', width: 24 },
    { columnName: '状態', key: 'status', width: 96 },
    { columnName: '修正承認期限', key: 'time_left', width: 104 },
    { columnName: '掲載No.', key: 'offer_number', width: 112 },
    { columnName: '氏名', key: 'btmem_name', width: 120 },
    { columnName: '所属', key: 'type_name', width: 92 },
    { columnName: '掲載タイトル', key: 'title', width: 160 },
    { columnName: '募集店', key: 'tpmem_name', width: 160 },
    { columnName: 'バイト開始期日', key: 'begin_date', width: 160 },
    { columnName: 'バイト終了期日', key: 'end_date', width: 160 },
    { columnName: 'チェックイン実績', key: 'qr_checkin', width: 160 },
    { columnName: 'チェックアウト実績', key: 'qr_checkout', width: 160 },
    { columnName: '修正チェックイン', key: 'fix_checkin', width: 160 },
    { columnName: '修正チェックアウト', key: 'fix_checkout', width: 160 },
    { columnName: '修正依頼回数', key: 'fix_count', width: 160 },
    { columnName: '応募No.', key: 'entry_number', width: 112 },
  ];

  /**
   * チェックイン/チェックアウト列の描画
   * 「無し」の場合は赤字で表示する
   */
  const renderCheckInOut = (value: string) => (
    <Text variant="caption12" color={value === '無し' ? 'cautionRed' : 'black'}>
      {value}
    </Text>
  );

  /**
   * ステータスのフィルタ処理
   */
  const filterStatus = (agreementStatus: string, list: VtWorkingList[]) => {
    // ステータスでフィルタリング
    const filteredList = [...list].filter(({ status }) => {
      switch (agreementStatus) {
        case String(AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT):
          // 完了待ち(チェックアウトなし)は完了待ち(チェックアウト済み)も含んで抽出する
          return (
            status === AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED ||
            status === AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT
          );
        case String(AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS):
          // 修正依頼中は修正依頼却下も含んで抽出する
          return (
            status === AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS ||
            status === AgreementStatus.REQUEST_CORRECTION_REJECTED
          );
        default:
          // 上記以外の場合は指定したステータスで抽出する
          return agreementStatus === String(status);
      }
    });

    return filteredList;
  };

  /**
   * 検索条件でのフィルタ処理
   */
  const filterSearchConditions = React.useCallback((searchParams: SearchCondition, list: VtWorkingList[]) => {
    // 検索条件でフィルタリング
    const filteredList = [...list]
      .filter(({ offer_number }) => {
        // 掲載No
        if (!searchParams.no) return true;
        return offer_number === searchParams.no;
      })
      .filter(({ title }) => {
        // タイトル
        if (!searchParams.title) return true;
        return String(title).indexOf(searchParams.title || '') > -1;
      })
      .filter(({ tpmem_name }) => {
        // 募集店
        if (!searchParams.store) return true;
        return String(tpmem_name).indexOf(searchParams.store || '') > -1;
      })
      .filter(({ btmem_name }) => {
        // 採用者
        if (!searchParams.name) return true;
        return String(btmem_name).indexOf(searchParams.name || '') > -1;
      });

    return filteredList;
  }, []);

  /**
   * バイト日でのフィルタ処理
   */
  const filterWorkingDay = (workingDate: Date, list: VtWorkingList[]) => {
    const filteredList = [...list].filter(({ begin_date }) => {
      if (!begin_date) {
        return false;
      }
      const regex = /(\d{4})年(\d{2})月(\d{2})日/;
      const matches = begin_date.match(regex);

      if (matches) {
        const year = Number(matches[1]);
        const month = Number(matches[2]);
        const day = Number(matches[3]);
        return isSameDay(new Date(year, month - 1, day), workingDate);
      }
      return false;
    });

    return filteredList;
  };

  /**
   * 加盟店モーダル表示
   */
  const showStoreModal = React.useCallback(
    (item: VtWorkingList) => {
      (async () => {
        const result = await getVtMemberBaseById({ variables: { id: item.tpmem_id } });
        const vTMemberBase = result?.data?.getVTMemberBaseById;
        // 加盟店の情報を設定してモーダルを表示する
        if (vTMemberBase) {
          setVtMemberBase(vTMemberBase);
        }
      })();
    },
    [getVtMemberBaseById]
  );

  /**
   * 一覧データ
   */
  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    if (loading || getVTWorkingListError) {
      return [];
    }

    let items: VtWorkingList[] = workingList;

    // ステータスでフィルタリング
    if (statusSelect) items = filterStatus(statusSelect, items);

    // 検索条件でフィルタリング
    if (searchConditions) items = filterSearchConditions(searchConditions, items);

    // バイト日でフィルタリング
    if (workingDay) items = filterWorkingDay(workingDay, items);

    // データ数を設定
    setDataSize(items.length);

    // ソート
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item) => ({
      uniqueKey: item.offer_id + Math.random(),
      eye: <Icon name="eye" onClick={() => navigate(`/s04-02/${String(item.offer_id)}`)} />,
      status: <StatusChip status={item.status} width={98} />,
      time_left: item.time_left,
      offer_number: item.offer_number,
      btmem_name: item.btmem_name,
      type_name: item.type_name,
      title: item.title,
      tpmem_name: (
        <TextLink
          lineClamp={1}
          onClick={() => {
            showStoreModal(item);
          }}
        >
          {item.tpmem_name}
        </TextLink>
      ),
      begin_date: item.begin_date,
      end_date: item.end_date,
      qr_checkin: renderCheckInOut(String(item.qr_checkin)),
      qr_checkout: renderCheckInOut(String(item.qr_checkout)),
      fix_checkin: item.fix_checkin,
      fix_checkout: item.fix_checkout,
      fix_count: item.fix_count,
      entry_number: item.entry_number,
    }));
  }, [
    loading,
    getVTWorkingListError,
    sort,
    statusSelect,
    searchConditions,
    workingDay,
    workingList,
    navigate,
    filterSearchConditions,
    showStoreModal,
  ]);

  const filterHeight = 64;
  const dividerHeight = 1;
  const totalHeaderHeight = filterHeight + dividerHeight;

  return (
    <>
      {loading && <Loading />}
      <MainContentWrapper>
        <MainContentHeader>
          <MainContentTitle text="成約バイト状況" />
        </MainContentHeader>
        <MainContentArea>
          <Card overflow="hidden">
            <Box display="flex" flexDirection="column" height="100%">
              <Box display="flex" alignItems="center" pa={16} gap={16} flexWrap="wrap">
                <Input
                  value={searchNo}
                  onChange={(event) => setSearchNo(event?.target.value || '')}
                  width={120}
                  placeholder="掲載No."
                />
                <Input
                  value={searchTitle}
                  onChange={(event) => setSearchTitle(event?.target.value || '')}
                  width={120}
                  placeholder="タイトル"
                />
                <Input
                  value={searchStore}
                  onChange={(event) => setSearchStore(event?.target.value || '')}
                  width={120}
                  placeholder="募集店"
                />
                <Input
                  value={searchName}
                  onChange={(event) => setSearchName(event?.target.value || '')}
                  width={120}
                  placeholder="採用者"
                />
                <MainButton
                  variant="primary"
                  width={80}
                  icon="search"
                  onClick={() => {
                    setSearchConditions({
                      no: searchNo,
                      title: searchTitle,
                      store: searchStore,
                      name: searchName,
                    });
                    setCurrentPage(1);
                  }}
                >
                  検索
                </MainButton>
                <Divider option="vertical" length={24} />
                <div>
                  <DatePicker
                    selected={workingDay}
                    onChange={(value) => {
                      setWorkingDay(value);
                    }}
                    placeholderText="バイト日"
                    width={120}
                  />
                </div>
                <Select
                  value={statusSelect}
                  options={[
                    { value: '', label: '全て' },
                    { value: String(AgreementStatus.BEFORE), label: 'バイト前' },
                    { value: String(AgreementStatus.STAND_BY), label: 'スタンバイ' },
                    { value: String(AgreementStatus.NO_CHECK_IN), label: 'チェックインなし' },
                    { value: String(AgreementStatus.NO_CHECK_OUT), label: 'チェックアウトなし' },
                    { value: String(AgreementStatus.NO_CHECK_IN_OUT), label: 'チェックイン/アウトなし' },
                    { value: String(AgreementStatus.IN_PROGRESS), label: 'バイト中' },
                    { value: String(AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT), label: '完了待ち' },
                    { value: String(AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS), label: '修正依頼' },
                    { value: String(AgreementStatus.COMPLETE), label: '完了' },
                    { value: String(AgreementStatus.CANCEL), label: 'キャンセル' },
                    { value: String(AgreementStatus.NO_SHOW), label: 'バックレ' },
                  ]}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setStatusSelect(value);
                  }}
                  placeholder="状態"
                  width={210}
                />
                <MainButton
                  variant="clear"
                  width={44}
                  onClick={() => {
                    setSearchNo('');
                    setSearchTitle('');
                    setSearchStore('');
                    setSearchName('');
                    setSearchConditions(null);
                    setWorkingDay(null);
                    setStatusSelect('');
                    setCurrentPage(1);
                  }}
                >
                  クリア
                </MainButton>
              </Box>
              <Divider option="horizontal" />
              <Box px={16} pb={16} height={`calc(100% - ${totalHeaderHeight}px)`}>
                <PaginationBox
                  dataSize={dataSize}
                  limit={currentLimit}
                  page={currentPage}
                  onChangeLimit={handleChangeLimit}
                  onChangePage={handleChangePage}
                >
                  <Box overflow="auto" height="100%">
                    <List
                      key={String(currentLimit) + String(currentPage) + (sort?.key || '')}
                      header={header}
                      items={displayItems.slice(currentLimit * (currentPage - 1), currentLimit * currentPage)}
                      sort={sort}
                      rowHeight={40}
                      onChangeSort={handleChangeSort}
                    />
                  </Box>
                </PaginationBox>
              </Box>
            </Box>
          </Card>
        </MainContentArea>
      </MainContentWrapper>
      {vtMemberBase && (
        <StoreModal
          isOpen={Boolean(vtMemberBase)}
          vtMemberBase={vtMemberBase}
          onClickClose={() => setVtMemberBase(null)}
        />
      )}
    </>
  );
}
