import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@linaria/react';
import { useNavigate } from 'react-router-dom';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { TextLink } from 'components/textLink';
import { Icon } from 'components/icon';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import bitravelLogo from 'components/assets/images/bitravelLogo.png';
import loginAvatar from 'components/assets/images/loginAvatar.png';

export interface Props {
  isOpenMainMenuBar: boolean;
  currentRoute: string;
  mailAddress: string;
  userName: string;
  toggleMainMenubar: (value: boolean) => void;
  onClickLogout: () => void;
}

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  width: 100vw;
  height: 48px;
  background: ${PRIMARY_COLOR.DARK_BLUE};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
`;

const StyledLeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  padding: 4px;
  gap: 4px;
  height: 32px;
  box-sizing: border-box;
`;

const StyledLogo = styled.img`
  width: 104px;
  object-fit: contain;
`;

const StyledBackLink = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  padding: 0px;
  gap: 4px;
  cursor: pointer;
`;

const StyledRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 32px;
  position: relative;
`;

const StyledAvatarImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  cursor: pointer;
`;

const StyledAccountMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  gap: 24px;
  position: absolute;
  width: 260px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  right: 0;
  top: 34px;
  box-sizing: border-box;
  z-index: 101;
`;

const StyledAccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 210px;
  height: 88px;
`;

const StyledAccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 210px;
  height: 40px;
`;

const StyledAuthControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const StyledTermsAndPolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function Header({
  isOpenMainMenuBar,
  currentRoute,
  mailAddress,
  userName,
  toggleMainMenubar,
  onClickLogout,
}: Props) {
  const navigate = useNavigate();
  const [isOpenAccountMenu, setIsOpenAccountMenu] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const avatarRef = useRef<HTMLImageElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    // dropdownが非表示の場合何もしない
    if (!dropdownRef.current) {
      return;
    }
    // ボタンかドロップダウンメニュー以外が押された場合はドロップダウンメニューを閉じる
    if (!avatarRef.current?.contains(e.target as Node) && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpenAccountMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isShowBackLink = useMemo<boolean>(() => {
    const routingArray = currentRoute.split('/').filter((route) => route.length > 0);
    return routingArray.length > 1;
  }, [currentRoute]);

  return (
    <StyledHeader>
      <StyledLeftSide>
        {isOpenMainMenuBar ? (
          <Icon name="menuOpen" onClick={() => toggleMainMenubar(false)} />
        ) : (
          <Icon name="menuClose" onClick={() => toggleMainMenubar(true)} />
        )}
        <StyledLogoWrapper>
          <StyledLogo src={bitravelLogo} alt="" />
          <Text variant="body14" color="white" bold>
            運営管理
          </Text>
        </StyledLogoWrapper>
        {isShowBackLink && (
          <StyledBackLink onClick={() => navigate(-1)}>
            <Icon name="chevronRwd" color="white" />
            <Text variant="body14" color="white">
              戻る
            </Text>
          </StyledBackLink>
        )}
      </StyledLeftSide>
      <StyledRightSide>
        <Text variant="caption12" color="white">
          {mailAddress}
        </Text>
        <StyledAvatarImage
          ref={avatarRef}
          src={loginAvatar}
          alt="アイコン"
          onClick={() => setIsOpenAccountMenu(!isOpenAccountMenu)}
        />
        {isOpenAccountMenu && (
          <StyledAccountMenu ref={dropdownRef}>
            <StyledAccountInfoWrapper>
              <StyledAvatarImage src={loginAvatar} alt="アイコン" />
              <StyledAccountInfo>
                <Text variant="caption11" color="darkGray" lineClamp={1}>
                  {userName}
                </Text>
                <Text variant="caption11" color="darkGray" lineClamp={1}>
                  {mailAddress}
                </Text>
              </StyledAccountInfo>
            </StyledAccountInfoWrapper>
            <StyledAuthControlWrapper>
              <MainButton
                variant="secondary"
                width={160}
                onClick={() => {
                  setIsOpenAccountMenu(false);
                  onClickLogout();
                }}
              >
                ログアウト
              </MainButton>
              <TextLink
                onClick={() => {
                  setIsOpenAccountMenu(false);
                  navigate('/s10-01');
                }}
              >
                アカウント設定
              </TextLink>
            </StyledAuthControlWrapper>
          </StyledAccountMenu>
        )}
      </StyledRightSide>
    </StyledHeader>
  );
}
