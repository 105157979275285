import React, { useEffect, useRef } from 'react';
import { styled } from '@linaria/react';
import { MainButton } from '../mainButton';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  isOpen: boolean;
  width?: number;
  height?: number;
  src: string;
  alt?: string;
  onClose: () => void;
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${GENERIC_COLOR.MORDAL_bg};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const StyledModal = styled.div<{
  width: number;
}>`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 6;
`;

const StyledImageWrapper = styled.div<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  justify-content: center;
  background-color: ${GENERIC_COLOR.BLACK};
`;

const StyledImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export function ImageModal({ isOpen, width = 600, height = 494, src, alt, onClose }: Props) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // モーダルの外側がクリックされたら閉じる処理
    const handleClickOutside = (e: MouseEvent) => {
      if (!modalRef.current) {
        return;
      }
      if (!modalRef.current?.contains(e.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return isOpen ? (
    <StyledOverlay>
      <StyledModal width={width} ref={modalRef}>
        <StyledImageWrapper width={width} height={height}>
          <StyledImage src={src} alt={alt ?? 'modal-image'} />
        </StyledImageWrapper>
        <MainButton onClick={onClose} variant="secondary" width={104}>
          閉じる
        </MainButton>
      </StyledModal>
    </StyledOverlay>
  ) : null;
}
