/**
 * Figma ID: 07-01-03, 07-01-05, 07-01-06, 07-01-08
 * 名称: 逆オファー（モーダル）
 */
import React from 'react';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { useUpdateBOfferS1ByIdMutation, useUpdateBOfferS5ByIdMutation } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';

type ChangeStatusModalModel = {
  btmemActiveStatus: boolean;
  isOpenModal: boolean;
  offerId: number;
  onClickClose: () => void;
  refetch: () => void;
};
function ChangeStatusModal(props: ChangeStatusModalModel) {
  const { btmemActiveStatus, isOpenModal, onClickClose, offerId, refetch } = props;
  const [updateActive, { error: updateActiveError }] = useUpdateBOfferS1ByIdMutation({
    variables: { id: offerId },
  });
  const [updateOfferStop, { error: updateOfferStopError }] = useUpdateBOfferS5ByIdMutation({
    variables: { id: offerId },
  });
  const [isComplete, setIsComplete] = React.useState(false);

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (updateActiveError || updateOfferStopError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [updateActiveError, updateOfferStopError, openErrorModal]);

  const getModalContent = () => {
    if (btmemActiveStatus) {
      return (
        <>
          <Text variant="body14">この逆オファーを強制停止しますか？</Text>
          <br />
          <Text variant="body14">申し込み済みの募集が自動的にキャンセルになります。</Text>
        </>
      );
    }
    return <Text variant="body14">この募集をアクティブに変更しますか？</Text>;
  };
  const getModalFooter = () => (
    <Box display="flex" justifyContent="flex-end" columnGap={8}>
      <MainButton width={104} variant="secondary" onClick={onClickClose}>
        キャンセル
      </MainButton>
      <MainButton
        width={104}
        onClick={() => {
          if (btmemActiveStatus) {
            updateOfferStop()
              .then((result) => {
                if (result.data?.updateBOfferS5ById.resultRows === 1) {
                  setIsComplete(true);
                } else {
                  onClickClose();
                  openErrorModal({
                    message: 'ステータス変更が完了できませんでした。',
                  });
                }
              })
              .catch(() => {
                onClickClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          } else {
            updateActive()
              .then((result) => {
                if (result.data?.updateBOfferS1ById.resultRows === 1) {
                  setIsComplete(true);
                } else {
                  onClickClose();
                  openErrorModal({
                    message: 'ステータス変更が完了できませんでした。',
                  });
                }
              })
              .catch(() => {
                onClickClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }
        }}
      >
        変更
      </MainButton>
    </Box>
  );
  return (
    <Modal
      isOpen={isOpenModal}
      height={isComplete || !btmemActiveStatus ? 192 : 240}
      header={
        <Text variant="h1" color="darkBlue">
          {!isComplete ? 'ステータス変更' : 'ステータス変更 完了'}
        </Text>
      }
      content={!isComplete ? getModalContent() : <Text variant="body14">ステータス変更が完了しました。</Text>}
      footer={
        !isComplete ? (
          getModalFooter()
        ) : (
          <Box display="flex" justifyContent="flex-end">
            <MainButton
              width={104}
              variant="secondary"
              onClick={() => {
                onClickClose();
                setIsComplete(false);
                refetch();
              }}
            >
              閉じる
            </MainButton>
          </Box>
        )
      }
      onClose={() => {
        setIsComplete(true);
      }}
    />
  );
}

export default ChangeStatusModal;
