import React, { ReactNode } from 'react';
import { Box } from '../box';

export interface Props {
  children: ReactNode;
  pa?: number;
  gap?: number;
  height?: number | string;
}

export function CampaignTableBody({ children, pa, gap, height }: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      pa={pa}
      gap={gap}
      width="100%"
      flex="none"
      height={height}
    >
      {children}
    </Box>
  );
}
