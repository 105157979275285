import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { ModalRet, VtMemberListCreateKey } from 'pages/s03/type';
import React, { useEffect } from 'react';
import { Control, UseFormGetValues, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { useGetMBusinessQuery, useGetMBrandByBizIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ErrorMessage } from 'components/errorMessage';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

export function EnterBrandBusinessModal(
  control: Control<VtMemberListCreateKey, any>,
  getValues: UseFormGetValues<VtMemberListCreateKey>,
  handleSubmit: UseFormHandleSubmit<VtMemberListCreateKey>,
  errors: FieldErrors<VtMemberListCreateKey>,
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const currentBusiness = useWatch({ control, name: 'business' });
  const { openErrorModal } = useErrorModal();
  const {
    data: mBusinessData,
    loading: mBusinessLoading,
    error: mBusinessError,
  } = useGetMBusinessQuery({ context: { clientName: 'master' } });
  const [getMBrandByBizId, { data: mBrandData, loading: mBrandLoading, error: mBrandError }] =
    useGetMBrandByBizIdLazyQuery({
      context: { clientName: 'master' },
    });
  useEffect(() => {
    if (mBusinessError || mBrandError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [mBusinessError, mBrandError, openErrorModal]);

  const businessData = mBusinessData?.getMBusiness || [];
  const businessList =
    businessData?.map((item) => ({
      value: String(item?.id),
      label: item?.name ?? '',
    })) || [];
  const brandData = mBrandData?.getMBrandByBizId || [];

  const mBrandList =
    brandData?.map((item) => ({
      value: String(item?.id),
      label: item?.name ?? '',
    })) || [];

  useEffect(() => {
    if (currentBusiness === undefined || currentBusiness === null) return;
    getMBrandByBizId({
      variables: { bid: Number(currentBusiness) },
    });
  }, [currentBusiness, getMBrandByBizId]);

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      onNext();
    }
  });

  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        新規登録 業種とブランド選択
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        {(mBusinessLoading || mBrandLoading) && <Loading />}
        <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
          <Text variant="body14">業種とブランドを選択して「次へ」を押してください。</Text>
          <Box display="flex" flexDirection="row" gap={13}>
            <Box display="flex" flexDirection="column">
              <Text variant="h3" color="blueGray">
                業種
              </Text>
              <RhfSelect
                width={370}
                control={control}
                name="business"
                options={businessList}
                defaultValue=""
                placeholder="業種を選択"
                rules={{ required: '業種を選択してください' }}
              />
              {errors?.business && <ErrorMessage>{errors?.business.message}</ErrorMessage>}
            </Box>
            <Box display="flex" flexDirection="column">
              <Text variant="h3" color="blueGray">
                ブランド
              </Text>
              <RhfSelect
                width={370}
                control={control}
                name="brand"
                options={mBrandList}
                defaultValue=""
                placeholder="ブランドを選択"
                rules={{ required: 'ブランドを選択してください' }}
              />
              {errors?.brand && <ErrorMessage>{errors?.brand.message}</ErrorMessage>}
            </Box>
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={(data) => {
            onClickHandler(data);
          }}
        >
          次へ
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 283,
  };

  return {
    ...modalContent,
  };
}
