import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useState } from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';

enum ModalStep {
  CONFIRM = 1,
  COMPLETE = 2,
}

export function DeleteNotifyInfoModal(props: { isOpen: boolean; onClose: () => void }) {
  const params = useParams<{ id: string }>();
  const { isOpen, onClose } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CONFIRM);
  const navigate = useNavigate();

  const onModalClose = () => {
    setModalStep(ModalStep.CONFIRM);
    onClose();
  };
  const [deleteUSendNotifyMutation, { loading: deleteUsendNotifyLoading }] = gql.useDeleteUSendNotifyMutation();

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  const confirmModal = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          削除
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Box display="flex" flexDirection="row" alignItems="center" height="100%">
        <Box display="flex" flexDirection="column">
          <Text variant="body14">本当に下書きを削除しますか？</Text>
          <Text variant="body14">削除した下書きは元に戻せません。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            deleteUSendNotifyMutation({
              variables: {
                id: Number(params.id),
              },
            })
              .then((e) => {
                if (e.data?.deleteUSendNotify?.resultRows === ResultRows.UPDATED) {
                  setModalStep(ModalStep.COMPLETE);
                } else {
                  onClose();
                  openErrorModal({
                    message: '下書きを削除に失敗しました。',
                  });
                }
              })
              .catch((e) => {
                // 通信自体ができない場合
                if (e instanceof Error) {
                  onClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              });
          }}
        >
          削除
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210,
  };

  const completeModal = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          削除 完了
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Box display="flex" flexDirection="row" alignItems="center" height="100%">
        <Text variant="body14">下書きの削除が完了しました。</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {deleteUsendNotifyLoading && <Loading />}
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => {
            onModalClose();
            navigate('/s09');
            // TODO : 再描画は後日対応
          }}
        >
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189,
  };

  const modalContent = {
    [ModalStep.CONFIRM]: confirmModal,
    [ModalStep.COMPLETE]: completeModal,
  };

  return <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />;
}
