import { Box } from 'components/box';
import { Card } from 'components/card';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useMemo, useState } from 'react';
import { DatePicker } from 'components/datePicker';
import { Divider } from 'components/newDivider';
import * as gql from 'graphql/graphql-ow';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils/hooks';
import { Chip } from 'components/chip';
import { Icon } from 'components/icon';
import { Link, useLocation } from 'react-router-dom';
import { Input } from 'components/input';
import { Select } from 'components/select';
import { orderBy } from 'lodash';
import { isSameDay, parse } from 'date-fns';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ja } from 'date-fns/locale';
import { ListHeaderType, ListItemsType, SelectOptionsType } from './type';
import { NotifyInfoStatus } from './const';
import { CreateNotifyInfoModal } from './components/modal/createNotifyInfoModal';

enum ModalType {
  NONE = 'none',
  CREATE = 'create',
}
export function S09() {
  const location = useLocation();
  const [activeModalType, setActiveModalType] = useState<ModalType>(ModalType.NONE);

  // 絞り込み条件
  const [filterValue, setFilterValue] = useState<{
    title?: string | null;
    destNotify?: string | null;
    sendDate?: Date | null;
    status?: string | null;
  }>();
  const [title, setTitle] = useState<string>('');

  const { sort, handleChangeSort } = useSort();

  const {
    data: { getVUSendNotifyList } = { getVUSendNotifyList: [] },
    loading: vuSendNotifiListLoading,
    error: vuSendNotifiListError,
    refetch,
  } = gql.useGetVuSendNotifyListQuery({ variables: {}, fetchPolicy: 'no-cache' });

  const [getMBusiness, { data: mBusinessData, loading: mBusinessLoading, error: mBusinessError }] =
    gql.useGetMBusinessLazyQuery({
      context: { clientName: 'master' },
    });

  const [dataSize, setDataSize] = useState<number>(getVUSendNotifyList.length);

  const { handleChangeLimit, handleChangePage, limit, page, setPage } = usePagenation();

  const onClear = React.useCallback(() => {
    setFilterValue(undefined);
    setTitle('');
  }, []);

  const mBusiness = mBusinessData?.getMBusiness;
  React.useEffect(() => {
    if (mBusiness === undefined) {
      getMBusiness();
    }
  }, [mBusiness, getMBusiness]);

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (vuSendNotifiListError || mBusinessError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vuSendNotifiListError, mBusinessError, openErrorModal]);

  const deliveryDestOptions: SelectOptionsType = useMemo(() => {
    if (mBusiness === undefined || mBusiness === null) return [];
    if (mBusiness.length === 0) return [];
    const businessNameList = Array.from(new Set(mBusiness.map((item) => item?.name)));

    const MemberStoreAllList: { label: string; value: string }[] = [{ label: '加盟店すべて', value: '加盟店すべて' }];
    const BitravelerAllList: { label: string; value: string }[] = [
      { label: 'バイトラベラー', value: 'バイトラベラー' },
    ];
    const businessList = businessNameList.map((item) => ({ label: item ?? '', value: item ?? '' }));
    const businessListOptions = MemberStoreAllList.concat(businessList).concat(BitravelerAllList);

    return businessListOptions;
  }, [mBusiness]);

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'eye', width: 24 },
      { key: 'status', width: 56, columnName: '状態' },
      { key: 'title', width: 160, columnName: 'タイトル' },
      { key: 'created_date', width: 140, columnName: '作成日時' },
      { key: 'send_date', width: 140, columnName: '配信日時' },
      { key: 'to_kind_name', width: 160, columnName: '配信先種別' },
      { key: 'brand_name', width: 160, columnName: '配信先ブランド' },
    ],
    []
  );

  const listItems: ListItemsType = useMemo(() => {
    if (vuSendNotifiListLoading) {
      return [];
    }
    if (vuSendNotifiListError) {
      return [];
    }
    let items = getVUSendNotifyList;

    // 作成日時降順でsort
    items.sort((beforeData, afterData) => {
      const date1 = parse(String(beforeData?.created_date), 'yyyy年MM月dd日 HH:mm', new Date(), { locale: ja });
      const date2 = parse(String(afterData?.created_date), 'yyyy年MM月dd日 HH:mm', new Date(), { locale: ja });

      return date2.getTime() - date1.getTime();
    });

    if (filterValue?.destNotify) {
      // 配信先でのfilter
      items = items.filter(({ to_kind_name }) => to_kind_name === filterValue?.destNotify);
    }
    if (filterValue?.sendDate) {
      // createdDateでのfilter
      const regex = /(\d{4})年(\d{2})月(\d{2})日/;
      items = items.filter(({ send_date }) => {
        const matches = send_date?.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return isSameDay(new Date(year, month - 1, day), filterValue?.sendDate ?? new Date());
        }
        return false;
      });
    }
    if (filterValue?.status && filterValue.status !== '全て') {
      // statusでのfilter
      items = items.filter(({ status }) => {
        if (filterValue?.status === '下書き') {
          return status === NotifyInfoStatus.ROUGH_DRAFT;
        }
        return status === NotifyInfoStatus.PUBLISHED;
      });
    }
    if (filterValue?.title) {
      // titleでのfilter
      const reg = new RegExp(filterValue?.title ?? '', 'i');
      items = items.filter((item) => item.title?.match(reg));
    }

    setDataSize(items.length);

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items.map((item, key) => ({
      eye: (
        <Link to={`${location.pathname}/${item.id}`}>
          <Icon name="eye" />
        </Link>
      ),
      status:
        item.status === NotifyInfoStatus.ROUGH_DRAFT ? (
          <Chip color="warningYellow">下書き</Chip>
        ) : (
          <Chip color="okBlue">配信済</Chip>
        ),
      title: item.title,
      created_date: item.created_date,
      send_date: item.send_date,
      to_kind_name: item.to_kind_name,
      brand_name: item.brand_name,
      uniqueKey: key,
    }));
  }, [filterValue, getVUSendNotifyList, location.pathname, sort, vuSendNotifiListLoading, vuSendNotifiListError]);

  return (
    <>
      {(vuSendNotifiListLoading || mBusinessLoading) && <Loading />}
      <Box display="flex" flex="1" flexDirection="column" gap={25}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text variant="h1" color="darkBlue">
            通知配信
          </Text>
          <MainButton icon="addCircle" thin onClick={() => setActiveModalType(ModalType.CREATE)}>
            新規作成
          </MainButton>
        </Box>
        <Card overflow="hidden">
          <Box display="flex" flexDirection="row" gap={16} ma={16} alignItems="center">
            <Input
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              width={160}
              placeholder="タイトル"
            />
            <MainButton
              width={80}
              icon="search"
              onClick={() => {
                // ページ初期化
                setPage(1);
                setFilterValue({ ...filterValue, title });
              }}
            >
              検索
            </MainButton>
            <Divider option="vertical" length={24} />
            <Select
              name="deliveryDest"
              onChange={(e) => {
                // ページ初期化
                setPage(1);
                setFilterValue({ ...filterValue, destNotify: e });
              }}
              value={filterValue?.destNotify ?? ''}
              options={deliveryDestOptions}
              width={120}
              placeholder="配信先"
            />
            <DatePicker
              onChange={(date: Date) => {
                // ページ初期化
                setPage(1);
                setFilterValue({ ...filterValue, sendDate: date });
              }}
              placeholderText="配信日"
              selected={filterValue?.sendDate}
              width={120}
            />
            <Select
              name="status"
              onChange={(e) => {
                // ページ初期化
                setPage(1);
                setFilterValue({ ...filterValue, status: e });
              }}
              value={filterValue?.status ?? ''}
              options={[
                { label: '全て', value: '全て' },
                { label: '下書き', value: '下書き' },
                { label: '配信済', value: '配信済' },
              ]}
              width={120}
              placeholder="状態"
            />
            <MainButton variant="clear" width={44} onClick={() => onClear()}>
              クリア
            </MainButton>
          </Box>
          <Divider option="horizontal" />
          <Box flex="auto" width="100%" px={16}>
            <PaginationBox
              dataSize={dataSize}
              limit={limit}
              page={page}
              onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
              onChangePage={(value: number) => handleChangePage(value)}
            >
              <ScrollWrapper bottom={317}>
                <List
                  header={listHeader}
                  items={listItems.slice(limit * (page - 1), limit * page)}
                  key={String(limit) + String(page) + String(sort?.key || '')}
                  sort={sort}
                  onChangeSort={handleChangeSort}
                  rowHeight={40}
                  width="100%"
                  rowWidth="100%"
                />
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </Card>
      </Box>
      <CreateNotifyInfoModal
        isOpen={activeModalType === ModalType.CREATE}
        onClose={() => {
          setActiveModalType(ModalType.NONE);
          refetch();
        }}
      />
    </>
  );
}
