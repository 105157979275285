import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Icon } from 'components/icon';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Rating } from 'components/rating';
import { ScoreToast } from 'components/rating/scoreToast';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { ScoreSelectKey } from 'pages/s03/type';
import React, { useMemo } from 'react';
import { useGetVtScorePercentInfoByTpmemIdQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { CommentDeleteModal } from 'pages/s03/components/modal/commentDeleteModal';
import { TextLink } from 'components/textLink';
import { MemberBaseModal } from 'pages/s03/components/modal';
import { Avatar } from 'components/avatar';

enum ModalType {
  None = 'none',
  CommentDelete = 'baseInfoEdit',
  MEMBER_INFO = 'MEMBER_INFO',
}

/**
 * Figma ID: 03-06-01
 * レビュー
 * Review
 */

function IconStars({ num }: { num: number }) {
  return (
    <Box display="flex">
      {[...(Array(num) as number[])].map((_) => (
        <Icon name="star" size={24} />
      ))}
    </Box>
  );
}

function CustomListRow({
  image,
  post_date,
  score,
  comment,
  name,
  work_id,
  onDeleteComment,
  onScreenTransition,
}: {
  image: string;
  post_date: string;
  score: number;
  comment: string;
  name: string;
  work_id: number;
  onDeleteComment: () => void;
  onScreenTransition: () => void;
}) {
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="row" height={100} width="100%" my={8} justifyContent="space-between">
      <Box display="flex" gap={16}>
        <Box display="flex" height="100%" width={32} mt={4}>
          <Avatar src={image} rounded />
        </Box>
        <Box display="flex" flex="1" flexDirection="column" gap={4}>
          <div>
            <TextLink
              variant="body14"
              onClick={() => {
                onScreenTransition();
              }}
            >
              {name}
            </TextLink>
          </div>
          <Text variant="caption12">{post_date}</Text>
          <Rating value={score} size="small" precision={0.5} readOnly />
          <Text variant="body14">{comment}</Text>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <ThreeDotLeader
          menu={[
            { label: 'コメント削除', value: 'delete' },
            { label: '該当募集に移動', value: 'get' },
          ]}
          onClick={(value: string) => {
            if (value === 'delete') {
              onDeleteComment();
            } else if (value === 'get') {
              navigate(`/s04-01/${work_id ?? ''}`);
            }
          }}
          right={0}
        />
      </Box>
    </Box>
  );
}

export function Review(props: { id: number }) {
  const { id } = props;
  const { openErrorModal } = useErrorModal();
  const {
    data: vTScorePercentInfoData,
    loading: vTScorePercentInfoLoading,
    error: vTScorePercentInfoError,
  } = useGetVtScorePercentInfoByTpmemIdQuery({
    variables: { tpmem_id: id },
  });

  React.useEffect(() => {
    if (vTScorePercentInfoError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTScorePercentInfoError, openErrorModal]);

  const { control } = useForm<ScoreSelectKey>();
  const data = vTScorePercentInfoData?.getVTScorePercentInfoByTpmemId;
  const scorePercentInfo = data?.info;
  const scorePercentList = data?.list;
  const [workId, setWorkId] = React.useState<number | undefined>(0);
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.None);
  const [btmemId, setBtmemId] = React.useState(0);

  const currentScore = useWatch({ control, name: 'score' });

  let items = useMemo(
    () =>
      scorePercentList?.map((item) => ({
        image: item?.image ?? '',
        btmem_id: item?.btmem_id ?? 0,
        post_date: item?.post_date ?? '',
        score: item?.score ?? 0,
        comment: item?.comment ?? '',
        name: item?.name ?? '',
        work_id: item?.work_id ?? 0,
      })) || [],
    [scorePercentList]
  );

  /**
   * 星でフィルター
   */
  if (currentScore) {
    items = items.filter(({ score }) => {
      if (currentScore === 'all') {
        return true;
      }
      if (Number(currentScore) !== score) {
        return false;
      }
      return true;
    });
  }

  const listItems: React.ReactNode[] = useMemo(
    () =>
      items
        .map((item) => (
          <CustomListRow
            image={item?.image ?? ''}
            post_date={item?.post_date ?? ''}
            score={item?.score ?? 0}
            comment={item?.comment ?? ''}
            name={item?.name ?? ''}
            work_id={item?.work_id ?? 0}
            onDeleteComment={() => {
              setActiveModalType(ModalType.CommentDelete);
              setWorkId(item?.work_id);
            }}
            onScreenTransition={() => {
              setBtmemId(item?.btmem_id ?? 0);
              setActiveModalType(ModalType.MEMBER_INFO);
            }}
          />
        ))
        .slice(currentLimit * (currentPage - 1), currentLimit * currentPage),
    [items, currentLimit, currentPage]
  );

  return (
    <>
      {vTScorePercentInfoLoading && <Loading />}
      <Box px={16} display="flex" flex="1" flexDirection="column">
        <Box display="flex" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            レビュー
          </Text>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" flexDirection="row" height={56} justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center" gap={16}>
              <Box display="flex" flexDirection="row" gap={8} alignItems="center">
                <ScoreToast
                  vTScorePercentInfo={{
                    tpmem_id: scorePercentInfo?.tpmem_id ?? 0,
                    score5_per: scorePercentInfo?.score5_per ?? 0,
                    score4_per: scorePercentInfo?.score4_per ?? 0,
                    score3_per: scorePercentInfo?.score3_per ?? 0,
                    score2_per: scorePercentInfo?.score2_per ?? 0,
                    score1_per: scorePercentInfo?.score1_per ?? 0,
                  }}
                >
                  <Rating value={scorePercentInfo?.score_value ?? 0} size="large" precision={0.5} readOnly />
                </ScoreToast>
                <Text variant="bigTitle">{scorePercentInfo?.score_value}</Text>
                <Text variant="caption12">{`(${scorePercentInfo?.score_num ?? 0}人が評価)`}</Text>
              </Box>
              <Divider option="vertical" length={24} />
              <Box display="flex" flexDirection="row" alignItems="center" gap={8}>
                <Text variant="h2" color="blueGray">
                  またバイトしたい
                </Text>
                <Text variant="bigTitle">{scorePercentInfo?.again_num}</Text>
                <Text variant="caption12">人</Text>
              </Box>
            </Box>
            <RhfSelect
              width={208}
              control={control}
              name="score"
              options={[
                { label: '全ての星', value: 'all' },
                { label: '星5つ', value: '5', icon: <IconStars num={5} /> },
                { label: '星4つ', value: '4', icon: <IconStars num={4} /> },
                { label: '星3つ', value: '3', icon: <IconStars num={3} /> },
                { label: '星2つ', value: '2', icon: <IconStars num={2} /> },
                { label: '星1つ', value: '1', icon: <IconStars num={1} /> },
              ]}
              defaultValue="all"
              placeholder=""
            />
          </Box>
          <Box display="flex" flex="1" flexDirection="column">
            <PaginationBox
              dataSize={items?.length ?? 0}
              limit={currentLimit}
              page={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <Divider option="horizontal" length={0} />
              <ScrollWrapper bottom={402}>
                {listItems.map((listItem) => (
                  <>
                    {listItem}
                    <Divider option="horizontal" length={0} />
                  </>
                ))}
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </Box>
      </Box>
      <CommentDeleteModal
        isOpen={activeModalType === ModalType.CommentDelete}
        workId={workId ?? 0}
        onClose={() => setActiveModalType(ModalType.None)}
        onDelete={() => {}}
      />
      <MemberBaseModal
        isOpen={activeModalType === ModalType.MEMBER_INFO}
        onClose={() => setActiveModalType(ModalType.None)}
        btmemId={btmemId}
      />
    </>
  );
}
