/**
 * Figma ID: 05-06-01
 * 名称: お気に入り店舗
 */

import NoImageSmall from 'components/assets/images/no-image-small.svg';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import React, { useEffect, useMemo, useState } from 'react';
import { List } from 'components/list';
import * as gql from 'graphql/graphql-ow';

import { usePagenation, useSort } from 'components/utils';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ErrorModal } from 'components/errorModal';
import { Loading } from 'components/loading/loading';
import { Modal } from 'components/newModal';
import { TpmemDetailHeader } from 'modal/tpmemDetail/TpmemDetailHeader';
import { TpmemDetailContent } from 'modal/tpmemDetail/TpmemDetailContent';
import { TpmemDetailFooter } from 'modal/tpmemDetail/TpmemDetailFooter';
import { TextLink } from 'components/textLink';
import { PageNumber } from 'pages/s05/constants';
import { orderBy } from 'lodash';

export function FavoriteStore({ id }: { id: string }) {
  const {
    data,
    loading,
    error: memberListFavoriteError,
  } = gql.useGetVbMemberListFavoriteByIdQuery({
    variables: { id: Number(id) },
  });

  const [errorModalStep, setErrorModalStep] = React.useState<boolean>(false);
  const [dataSize, setDataSize] = useState<number>(0);
  const [isTpmemDetailShow, setIsTpmemDetailShow] = React.useState(false);
  const [modalData, setModalData] = React.useState<{ tpmemName: string; tpmemId: number }>();

  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(PageNumber.initialValue),
  });

  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (memberListFavoriteError) {
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [memberListFavoriteError, openErrorModal]);

  const items = useMemo(() => {
    if (loading) {
      return [];
    }
    let favorites = data?.getVBMemberListFavoriteById.favorite || [];
    setDataSize(favorites.length);

    if (sort) {
      favorites = orderBy(favorites, sort.key, sort.direction);
    }

    return (
      favorites.map((favorite, key) => ({
        uniqueKey: key,
        image: (
          <img src={favorite.image || NoImageSmall} style={{ width: 40, height: 40, objectFit: 'cover' }} alt="" />
        ),
        store_name: (
          <TextLink
            lineClamp={1}
            onClick={() => {
              setIsTpmemDetailShow(true);
              setModalData({ tpmemId: favorite.tpmem_id, tpmemName: favorite.store_name || '' });
            }}
          >
            {favorite.store_name}
          </TextLink>
        ),
        reg_date: favorite.reg_date,
        score: favorite.score,
      })) ?? []
    );
  }, [data?.getVBMemberListFavoriteById.favorite, loading, sort]);

  useEffect(() => {
    setDataSize(items.length);
  }, [items.length]);

  if (loading) {
    return Loading();
  }

  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            お気に入り店舗
          </Text>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flex="1" flexDirection="column">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <div style={{ height: 'calc(100vh - 370px)' }}>
              <ScrollableArea>
                <List
                  header={[
                    { key: 'image', width: 40 },
                    { key: 'store_name', width: 240, columnName: '店舗名' },
                    { key: 'reg_date', width: 132, columnName: 'お気に入り登録日' },
                    { key: 'score', width: 120, columnName: 'スコア' },
                  ]}
                  items={items.slice(limit * (page - 1), limit * page)}
                  sort={sort}
                  onChangeSort={handleChangeSort}
                  width="100%"
                  rowWidth="100%"
                />
              </ScrollableArea>
            </div>
          </PaginationBox>
        </Box>
      </Box>
      <ErrorModal
        title="error"
        isOpen={errorModalStep}
        message={memberListFavoriteError?.message || ''}
        onClose={() => {
          setErrorModalStep(false);
        }}
      />
      <Modal
        isOpen={isTpmemDetailShow}
        header={<TpmemDetailHeader tpmemName={modalData?.tpmemName} tpmemId={modalData?.tpmemId} />}
        content={<TpmemDetailContent tpmemId={modalData?.tpmemId} />}
        footer={<TpmemDetailFooter onClickClose={() => setIsTpmemDetailShow(false)} />}
        onClose={() => setIsTpmemDetailShow(false)}
        width={955}
        height="auto"
      />
    </>
  );
}
