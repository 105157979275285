import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useMemo, ComponentProps, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { styled } from '@linaria/react';
import { Divider } from 'components/newDivider';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { useErrorModal } from 'components/error/errorModalProvider';
import { MBrand2, MLicense2 } from 'graphql/graphql-ow';
import { ConrirmModalStep, ConfirmEditProps } from '../s10-02-types';
import { handleFileSet, iconEditConfirmSubFinction, skillReRegistrationSubFinction } from '../s10-02-utils';
import { InputsBrandContainer } from '../components/inputsContainer/inputsBrandContainer';
import { InputsLicensesContainer } from '../components/inputsContainer/inputsLicensesContainer';
/**
 * Figma ID: 10-02-11-06 ～ 10-02-11-22
 * 名称:  業種/ブランド 確認/編集
 * 内容:
 *       10-02-11-06 : 現在の業種情報を表示。変更、追加、再登録ボタンで各編集を行う事ができる
 *       10-02-11-07 ～ 10-02-11-10 : アイコン編集
 *       10-02-11-11 ～ 10-02-11-14 : ブランド編集
 *       10-02-11-15 ～ 10-02-11-18 : ライセンス編集
 *       10-02-11-19 ～ 10-02-11-22 : スキル編集
 */
export interface Props {
  items: {
    label: ReactNode;
    labelBold?: boolean;
    content: ReactNode;
    align?: string;
    pt?: number;
    isShow?: boolean;
  }[];
}

const StyledWrapper = styled.div<{
  align?: string;
}>`
  width: 100%;
`;

const StyledRow = styled.div<{
  align?: string;
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  padding: 8px 0px;
  border-bottom: #dbdbdb 1px solid;
`;

const StyledLabel = styled.div<{
  pt?: number;
}>`
  min-width: 160px;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : 'auto')};
  line-height: 28px;
`;

const StyledContent = styled.div`
  width: 100%;
  flex: auto;
  line-height: 28px;
`;

export function Info({ items }: Props) {
  return (
    <StyledWrapper>
      {items.map(
        (item) =>
          item.isShow !== false && (
            <StyledRow key={Math.random().toString()} align={item.align}>
              <StyledLabel pt={item.pt}>
                <Text variant="body14" color="darkGray" bold={item.labelBold}>
                  {typeof item.label === 'string' || typeof item.label === 'number' ? (
                    <Text variant="body14">{item.label}</Text>
                  ) : (
                    item.label
                  )}
                </Text>
              </StyledLabel>
              <StyledContent>
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text variant="body14">{item.content}</Text>
                ) : (
                  item.content
                )}
              </StyledContent>
            </StyledRow>
          )
      )}
    </StyledWrapper>
  );
}
export type InfoItemsType = ComponentProps<typeof Info>['items'];

function SubInfo({ item }: { item: { label?: string; content: JSX.Element } }) {
  return (
    <Box display="flex" flexDirection="row" gap={8} alignItems="center">
      <Box width="160px">
        <Text variant="body14">{item.label}</Text>
      </Box>
      <Box width="410px">
        <Text variant="body14">{item.content}</Text>
      </Box>
    </Box>
  );
}

export function ConfirmEditBusiness({
  confirmEditModalStep,
  setConrirmEditModalStep,
  onClose,
  onCancel,
  control,
  getValues,
  handleSubmit,
  errors,
  brandFields,
  brandAppend,
  brandRemove,
  licenseFields,
  licenseAppend,
  licenseRemove,
  licenseListPrepare,
  businessInfo,
  mBusinessDataInfo,
  iconMutation,
  brandMutation,
  licenseMutation,
  skillCheckMutation,
  mBusinessDataByIdRefetch,
  fileIconInputRef,
  fileSkillSectionInputRef,
  fileSkillCheckInputRef,
  selectedFileIcon,
  selectedFileSkillSection,
  selectedFileSkillCheck,
  setSelectedFileIcon,
  setSelectedFileSkillSection,
  setSelectedFileSkillCheck,
}: ConfirmEditProps) {
  const { openErrorModal } = useErrorModal();
  const mBrand = mBusinessDataInfo?.brand as MBrand2[];
  const mLicense = mBusinessDataInfo?.license as MLicense2[];
  const mSkillCheck = mBusinessDataInfo?.skillCheck;

  const errorFunc = React.useCallback(() => {
    onClose();
    openErrorModal({ message: 'ファイル選択できませんでした' });
  }, [onClose, openErrorModal]);

  // 画像ファイル（png）
  const handleIconFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'png', setSelectedFileIcon, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileIcon, errorFunc]
  );
  // スキルチェック（セクション）
  const handleSkillSectionFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'csv', setSelectedFileSkillSection, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileSkillSection, errorFunc]
  );

  // スキルチェック（チェック項目）
  const handleSkillCheckFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'csv', setSelectedFileSkillCheck, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileSkillCheck, errorFunc]
  );

  const onClickIconHandler = handleSubmit(() => iconMutation());
  const onClickBrandHandler = handleSubmit(() => {
    setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD_CHECK);
  });
  const onClickLicenseHandler = handleSubmit(() => {
    setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_CHECK);
  });
  const onClickSkillCheckHandler = handleSubmit(() => skillCheckMutation());

  // 10-02-11-06 画面表示用のリストを作成（ここから） ーーーーーーーーーーーーーーーーーーーーーーーーーーーー
  // ラベル（icon）
  const iconLabelElement: React.ReactNode = useMemo(
    () => (
      <Box>
        <Text variant="h3" color="blueGray">
          業種アイコン1
        </Text>
        <MainButton
          width={94}
          variant="secondary"
          icon="edit"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          thin
          onClick={() => {
            setConrirmEditModalStep(ConrirmModalStep.ICON_UPDATE);
          }}
        >
          変更
        </MainButton>
      </Box>
    ),
    [setConrirmEditModalStep]
  );

  // ラベル（ブランド）
  const brandLabelElement: React.ReactNode = useMemo(
    () => (
      <Box>
        <Text variant="h3" color="blueGray">
          ブランド
        </Text>
        <MainButton
          width={94}
          icon="addCircle"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          variant="secondary"
          thin
          onClick={() => {
            setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD);
          }}
        >
          追加
        </MainButton>
      </Box>
    ),
    [setConrirmEditModalStep]
  );

  // ラベル（業種保有資格）
  const licenseLabelElement: React.ReactNode = useMemo(
    () => (
      <Box>
        <Text variant="h3" color="blueGray">
          業種保有資格
        </Text>
        <MainButton
          width={94}
          icon="edit"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          variant="secondary"
          thin
          onClick={() => {
            licenseListPrepare();
            setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_SELECT);
          }}
        >
          編集
        </MainButton>
      </Box>
    ),
    [setConrirmEditModalStep, licenseListPrepare]
  );

  // ラベル（スキルチェック）
  const skillLabelElement: React.ReactNode = useMemo(
    () => (
      <Box>
        <Text variant="h3" color="blueGray">
          スキルチェック
        </Text>
        <MainButton
          width={94}
          icon="edit"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          variant="secondary"
          thin
          disabled={mBusinessDataInfo?.existsStore !== false}
          onClick={() => {
            setConrirmEditModalStep(ConrirmModalStep.SKILL_ADD_SELECT);
          }}
        >
          再登録
        </MainButton>
      </Box>
    ),
    [setConrirmEditModalStep, mBusinessDataInfo?.existsStore]
  );

  // データ（icon）
  const iconElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="row" gap={8}>
        <Box>
          {mBusinessDataInfo?.icon && (
            <img
              src={`${process.env.REACT_APP_FILE_READ_URL || ''}/icon/${mBusinessDataInfo?.icon || ''}`}
              width="144px"
              height="144px"
              alt="icon"
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="end">
          {mBusinessDataInfo?.icon && (
            <Text variant="h3" color="blueGray">
              {mBusinessDataInfo.icon}
            </Text>
          )}
        </Box>
      </Box>
    ),
    [mBusinessDataInfo?.icon]
  );

  // データ（ブランド）
  const brandElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={8}>
        {mBrand?.map((item) => (
          <Text variant="body14">
            {item?.brand_name} ({item?.brand_prefix}) (ID {item?.brand_id || 0})
          </Text>
        ))}
      </Box>
    ),
    [mBrand]
  );
  const brandList = useWatch({ control, name: 'brand' });

  // データ（業種保有資格）
  const licenseList: React.ReactNode = useMemo(
    () =>
      mLicense?.reduce((acc: string[], item, index) => {
        acc.push(item?.license_name || '');
        if (mLicense?.length > index + 1) {
          acc.push(', ');
        }
        return acc;
      }, []),
    [mLicense]
  );

  const licenseElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={8}>
        <Text variant="body14">{licenseList}</Text>
      </Box>
    ),
    [licenseList]
  );
  const liceseList = useWatch({ control, name: 'license' });

  // データ（スキルチェック）
  const skillCheckElement = React.useCallback(
    (no: number) => (
      <Box>
        {mSkillCheck
          ?.filter((item) => item?.section_no === no)
          ?.map((item, index) => (
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" gap={8}>
                <Box>
                  <Text variant="body14">{item?.check_item}</Text>
                </Box>
                <Box>{item?.bt_rank_name && <Text variant="body14">{item?.bt_rank_name}</Text>}</Box>
              </Box>
              {mSkillCheck?.filter((forLastDivider) => forLastDivider?.section_no === no).length > index + 1 && (
                <Divider option="horizontal" length={0} />
              )}
            </Box>
          ))}
      </Box>
    ),
    [mSkillCheck]
  );

  // データ（スキルセクション）
  const skillElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={8}>
        {mSkillCheck
          ?.filter((item, index, self) => index === self.findIndex((t) => t?.section_no === item?.section_no))
          ?.map((data, index) => (
            <Box>
              <SubInfo item={{ label: data?.section_name || '', content: skillCheckElement(data?.section_no ?? 0) }} />
              {index + 1 <
                mSkillCheck?.filter(
                  (item, idx, self) => idx === self.findIndex((t) => t?.section_no === item?.section_no)
                ).length && <Divider option="horizontal" length={0} />}
            </Box>
          ))}
      </Box>
    ),
    [mSkillCheck, skillCheckElement]
  );

  const infoItems: InfoItemsType = useMemo(
    () => [
      { label: '業種', content: `${businessInfo.name} (${businessInfo.prefix || ''})` },
      { label: iconLabelElement, content: iconElement },
      { label: brandLabelElement, content: brandElement },
      { label: licenseLabelElement, content: licenseElement },
      { label: skillLabelElement, content: skillElement },
    ],
    [
      businessInfo.name,
      businessInfo.prefix,
      iconLabelElement,
      brandLabelElement,
      licenseLabelElement,
      skillLabelElement,
      iconElement,
      brandElement,
      licenseElement,
      skillElement,
    ]
  );
  // 10-02-11-06 画面表示用のリストを作成（ここまで） ーーーーーーーーーーーーーーーーーーーーーーーーーーーー

  // 更新完了モーダルのヘッダー文言
  const completeHeaderMessage = React.useCallback(() => {
    switch (confirmEditModalStep) {
      case ConrirmModalStep.ICON_UPDATE_COMPLETE:
        return '業種アイコン 変更 完了';
      case ConrirmModalStep.BRAND_ADD_COMPLETE:
        return 'ブランド追加 完了';
      case ConrirmModalStep.LICENSE_ADD_COMPLETE:
        return '業種保有資格編集 完了';
      case ConrirmModalStep.SKILL_ADD_COMPLETE:
        return 'スキルチェック再登録 完了';
      default:
        return '';
    }
  }, [confirmEditModalStep]);

  const displayModal = React.useMemo(() => {
    switch (confirmEditModalStep) {
      case ConrirmModalStep.CONFIRM:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {businessInfo.name}
            </Text>
          ),
          content: (
            <Box display="flex" flex="1" flexDirection="column">
              <Divider option="horizontal" length={0} />
              <Box display="flex" flexDirection="column" flex="1" pb={16}>
                <ScrollWrapper bottom={244}>
                  <Info items={infoItems} />
                  <Divider option="horizontal" length={0} />
                </ScrollWrapper>
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onClose();
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.ICON_UPDATE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              業種アイコン変更
            </Text>
          ),
          content: (
            <Text variant="body14">
              業種アイコンを変更するとアプリ各所で表示されているアイコンが変更されます。
              <br />
              よろしいですか？
              <br />
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setConrirmEditModalStep(ConrirmModalStep.ICON_UPDATE_CHECK);
                }}
                variant="secondary"
                width={104}
              >
                ファイル選択
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.ICON_UPDATE_CHECK:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              業種アイコン変更
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
              <Text variant="body14">変更するファイルを選択して「変更」を押してください。</Text>
              {iconEditConfirmSubFinction({
                fileName: businessInfo.icon,
                fileInfo: selectedFileIcon,
                fileIconInputRef,
              })}

              <input
                type="file"
                ref={fileIconInputRef}
                style={{ display: 'none' }}
                onChange={handleIconFileSelect}
                accept="image/png"
              />
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  onClickIconHandler();
                }}
                variant="secondary"
                disabled={!selectedFileIcon}
                width={104}
              >
                変更
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.BRAND_ADD:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              ブランド追加
            </Text>
          ),
          content: (
            <ScrollWrapper bottom={550}>
              <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
                <Text variant="body14">ブランドを入力して「次へ」を押してください。</Text>
                <br />
                <Box display="flex" flexDirection="column" rowGap={8} columnGap={16} pb={8}>
                  <Text variant="h3" color="blueGray">
                    ブランド/ブランドコード
                  </Text>
                  <InputsBrandContainer
                    fields={brandFields}
                    control={control}
                    append={brandAppend}
                    remove={brandRemove}
                    errors={errors}
                    brands={mBrand || []}
                    inputs={brandList}
                  />
                </Box>
                <Box>
                  <Text variant="body14">登録済</Text>
                  {mBrand?.map((item) => (
                    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={8}>
                      <Box>
                        <Text variant="body14">
                          {item?.brand_name}({item?.brand_prefix})
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </ScrollWrapper>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  onClickBrandHandler();
                }}
                variant="secondary"
                width={104}
              >
                次へ
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.BRAND_ADD_CHECK:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              ブランド追加 確認
            </Text>
          ),
          content: (
            <ScrollWrapper bottom={550}>
              <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
                <Box>
                  <Box>
                    <Text variant="body14">以下のブランドを追加します。</Text>
                  </Box>
                  <Box>
                    <Text variant="body14" color="cautionRed">
                      ブランド名は追加できますが削除はできません。
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text variant="body14">新規追加</Text>
                  {getValues('brand')?.map((item) => (
                    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={8}>
                      {item.name && (
                        <Text variant="body14">
                          {item?.name}({item?.code})
                        </Text>
                      )}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Text variant="body14">登録済</Text>
                  {mBrand?.map((item) => (
                    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={8}>
                      <Text variant="body14">
                        {item?.brand_name}({item?.brand_prefix})
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            </ScrollWrapper>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  brandMutation();
                }}
                variant="secondary"
                width={104}
              >
                追加
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.LICENSE_ADD_SELECT:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              業種保有資格編集
            </Text>
          ),
          content: (
            <ScrollWrapper bottom={550}>
              <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
                <Text variant="body14">資格名を入力して「次へ」を押してください。</Text>
                <InputsLicensesContainer
                  fields={licenseFields}
                  control={control}
                  append={licenseAppend}
                  remove={licenseRemove}
                  errors={errors}
                  licenses={mBusinessDataInfo?.existsStore === true ? mLicense || [] : []}
                  inputs={liceseList}
                />
                {mBusinessDataInfo?.existsStore === true && (
                  <Box display="flex" flexDirection="column" gap={8}>
                    <Box>
                      <Text variant="body14">登録済</Text>
                    </Box>
                    {mLicense?.map((item) => (
                      <Box>{item?.license_name && <Text variant="body14">{item?.license_name}</Text>}</Box>
                    ))}
                  </Box>
                )}
              </Box>
            </ScrollWrapper>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  onClickLicenseHandler();
                }}
                variant="secondary"
                width={104}
              >
                次へ
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.LICENSE_ADD_CHECK:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              業務保有資格編集 確認
            </Text>
          ),
          content: (
            <ScrollWrapper bottom={550}>
              <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
                <Box>
                  <Box>
                    <Text variant="body14">以下の資格を保存します。</Text>
                  </Box>
                  <Box>
                    {mBusinessDataInfo?.existsStore === true && (
                      <Text variant="body14" color="cautionRed">
                        資格名は追加できますが削除できません。
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box>
                  {mBusinessDataInfo?.existsStore === true && (
                    <Box>
                      <Text variant="body14">新規追加</Text>
                    </Box>
                  )}
                  {getValues('license')?.map((item) => (
                    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={8}>
                      <Text variant="body14">{item?.lcValue}</Text>
                    </Box>
                  ))}
                </Box>
                {mBusinessDataInfo?.existsStore === true && (
                  <Box display="flex" flexDirection="column" gap={8}>
                    <Box>
                      <Text variant="body14">登録済</Text>
                    </Box>
                    {mLicense?.map((item) => (
                      <Box>{item?.license_name && <Text variant="body14">{item?.license_name}</Text>}</Box>
                    ))}
                  </Box>
                )}
              </Box>
            </ScrollWrapper>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_SELECT);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  licenseMutation();
                }}
                variant="secondary"
                width={104}
              >
                保存
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.SKILL_ADD_SELECT:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              スキルチェック再登録
            </Text>
          ),
          content: (
            <Text variant="body14">
              スキルチェックを再登録すると現在のスキルチェック一覧が変更されます。
              <br />
              よろしいですか？
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setConrirmEditModalStep(ConrirmModalStep.SKILL_ADD_CHECK);
                }}
                variant="secondary"
                width={104}
              >
                ファイル選択
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.SKILL_ADD_CHECK:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              スキルチェック再登録
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
              <Text variant="body14">再登録するCSVファイルを選択して「再登録」を押してください。</Text>
              <br />
              {skillReRegistrationSubFinction({
                SectionInfo: selectedFileSkillSection,
                SkillInfo: selectedFileSkillCheck,
                fileSkillSectionInputRef,
                fileSkillCheckInputRef,
              })}
              <input
                type="file"
                ref={fileSkillSectionInputRef}
                style={{ display: 'none' }}
                onChange={handleSkillSectionFileSelect}
                accept="text/csv"
              />
              <input
                type="file"
                ref={fileSkillCheckInputRef}
                style={{ display: 'none' }}
                onChange={handleSkillCheckFileSelect}
                accept="text/csv"
              />
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                disabled={!selectedFileSkillSection || !selectedFileSkillCheck}
                onClick={() => {
                  onClickSkillCheckHandler();
                }}
                variant="secondary"
                width={104}
              >
                再登録
              </MainButton>
            </Box>
          ),
        };
      case ConrirmModalStep.ICON_UPDATE_COMPLETE:
      case ConrirmModalStep.BRAND_ADD_COMPLETE:
      case ConrirmModalStep.LICENSE_ADD_COMPLETE:
      case ConrirmModalStep.SKILL_ADD_COMPLETE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {completeHeaderMessage()}
            </Text>
          ),
          content: (
            <Text variant="body14">
              情報の登録が完了しました。
              <br />
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  mBusinessDataByIdRefetch();
                  onCancel();
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
        };
    }
  }, [
    confirmEditModalStep,
    completeHeaderMessage,
    onClose,
    onCancel,
    setConrirmEditModalStep,
    businessInfo,
    infoItems,
    handleIconFileSelect,
    selectedFileIcon,
    brandMutation,
    licenseMutation,
    handleSkillCheckFileSelect,
    handleSkillSectionFileSelect,
    selectedFileSkillCheck,
    selectedFileSkillSection,
    fileSkillCheckInputRef,
    fileSkillSectionInputRef,
    fileIconInputRef,
    mBrand,
    brandAppend,
    brandFields,
    brandRemove,
    control,
    errors,
    getValues,
    licenseAppend,
    licenseFields,
    licenseRemove,
    mBusinessDataByIdRefetch,
    mBusinessDataInfo,
    mLicense,
    onClickBrandHandler,
    onClickIconHandler,
    onClickLicenseHandler,
    onClickSkillCheckHandler,
    brandList,
    liceseList,
  ]);
  return displayModal;
}
