export enum TabNumber {
  PAYMENT_REQUEST = 1, // 即日支払い請求
  IDENTIFY_VERIFICATION = 2, // 本人確認依頼
  MEMBERSHIP_REGISTRATION = 3, // 会員登録
  MEMBERSIHP_CANCELLATION = 4, // 退会
  OCCURRENCE_OF_EVENT = 5, // 神/覇者の出現
  REFUND_REQUEST = 6, // 払戻請求
  MEMBERSHIP_UPDATE = 7, // 会員情報変更
}

export enum SystemStatus {
  BEITRAVEL = 3, // バイトラベル
  MEMBER_STORE = 2, // 加盟店管理
}

export enum RowAction {
  TRANSIT_MYPAGE_SCREEN_03_04 = '03-04-01',
  TRANSIT_MYPAGE_SCREEN_03_12 = '03-12-01',
  TRANSIT_MYPAGE_SCREEN_05 = '05-02-01',
  TRANSIT_MYPAGE_SCREEN_06 = '06-02-01',
  TRANSIT_MYPAGE_SCREEN_08_01 = '08-01-01',
  TRANSIT_MYPAGE_SCREEN_08_05 = '08-05-01',
}

export enum BooleanEnum {
  TRUE = 1,
  FALSE = 0,
}
