import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React from 'react';


export function BulkRegistrationModal(
  props: {
    isOpen: boolean, onClose: ()=> void
  }
){
  const { isOpen, onClose } = props;

  const modal = {
    header: (
      <Text variant="h2" color="darkBlue">
        新規登録 完了
      </Text>),
    content: (
      <Box display='flex' height='100%' flexDirection='row' alignItems='center' flex='1'>
        <Box display="flex" flexDirection="column" gap={16} pa={8} flex='1'>
          <Text variant='body14'>
            情報の一括登録が完了しました。
          </Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
    </Box>)
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={()=> onClose()}
      header={modal.header}
      content={modal.content}
      footer={modal.footer}
      width={384}
      height={189}
    />
  );


}