/* eslint-disable  @typescript-eslint/no-unsafe-assignment */
/* eslint-disable  @typescript-eslint/no-unsafe-argument */
/* eslint-disable  @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'layout/layout';
import { useAuth } from 'hooks/authProvider';
import { useGetVuNewlyMenuAllQuery } from 'graphql/graphql-ow';
import { MainMenu } from 'components/mainMenuBar/types';
import { OperationalMainMenu } from './mainMenu/mainMenu';

// このファイル内でuseAuthなどからユーザー名などの認証情報を注入する
export function MainLayout() {
  const location = useLocation();
  const { signOut, userInfo } = useAuth();
  const [mainMenu, setMainMenu] = React.useState<MainMenu[]>([]);
  const defaultPollingInterval = 600000;
  const { data: { getVUNewlyMenuAll } = { getVUNewlyMenuAll: null }, loading } = useGetVuNewlyMenuAllQuery({
    pollInterval: Number(process.env.REACT_APP_MENU_POLLING_INTERVAL) || defaultPollingInterval,
  });

  React.useLayoutEffect(() => {
    const notifications = getVUNewlyMenuAll?.menu;

    if (!notifications) return;

    // オリジナルのメニューのオブジェクト配列をディープコピーする
    // 単純な代入だと、参照元の値が変更されてしまうため。
    const nextMenu = JSON.parse(JSON.stringify(OperationalMainMenu));

    if (notifications.home) {
      // HOME
      nextMenu[0].highlighted = true;
    }
    if (notifications.tenpo) {
      // 加盟店
      nextMenu[1].highlighted = true;
    }

    if (nextMenu[1].accordionList) {
      if (notifications.tp_info) {
        // 加盟店情報
        nextMenu[1].accordionList[0].highlighted = true;
      }
    }

    if (notifications.baitra) {
      // バイトラベラー
      nextMenu[2].highlighted = true;
    }

    if (nextMenu[2].accordionList) {
      if (notifications.bt_info) {
        // 会員情報
        nextMenu[2].accordionList[0].highlighted = true;
      }
      if (notifications.bt_identity) {
        // 本人確認
        nextMenu[2].accordionList[1].highlighted = true;
      }
    }

    if (notifications.payment) {
      // 支払関連
      nextMenu[5].highlighted = true;
    }

    if (nextMenu[5].accordionList) {
      if (notifications.pay_work) {
        // バイト代支払
        nextMenu[5].accordionList[0].highlighted = true;
      }
      if (notifications.pay_back) {
        // 払戻支払
        nextMenu[5].accordionList[1].highlighted = true;
      }
    }

    if (notifications.settings) {
      // 各種設定
      nextMenu[7].highlighted = true;
    }

    if (nextMenu[7].accordionList) {
      if (notifications.set_account) {
        // 経理関連設定
        nextMenu[7].accordionList[5].highlighted = true;
      }
    }
    setMainMenu(nextMenu);
  }, [getVUNewlyMenuAll]);

  const handleLogout = () => {
    signOut();
  };

  return (
    <Layout
      currentRoute={location.pathname}
      mailAddress={`${userInfo?.email || ''}`}
      userName={`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
      onClickLogout={handleLogout}
      mainMenuBarData={mainMenu}
      // 再レンダリングを起こすためのコード
      key={loading ? 'loading' : 'loaded'}
    >
      <Outlet />
    </Layout>
  );
}
