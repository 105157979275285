import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { useCallback, useMemo } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { VbCpGiftList } from 'graphql/graphql-ow';
import { Modal } from 'components/newModal';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { usePagenation } from 'components/utils';
import { BenefitsSettingMembershipPrivilegeCampaignUpdateType } from '../s10-03-types';
import { chipColor, chipText, leaderMenu, parseBizBrandList } from '../s10-03-utils';
import { DeleteModalStep, NewUpdateModalStep, ListTitleWidth } from '../constants';
import { DeleteModal } from '../modal/delete-modal';
import { SelectGiftBrand } from '../modal/select-gift-brand';
/**
 * Figma ID: 10-03-08-01
 * 名称: 入会特典キャンペーン
 */

export interface Props {
  cpRow: VbCpGiftList[];
  setDeleteModalStep: React.Dispatch<React.SetStateAction<DeleteModalStep>>;
  setDeleteId: React.Dispatch<React.SetStateAction<number>>;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setVbCpGiftList: React.Dispatch<React.SetStateAction<VbCpGiftList | undefined>>;
}

export interface FreeCampaignProps {
  cpRow: VbCpGiftList[];
  setDeleteModalStep: React.Dispatch<React.SetStateAction<DeleteModalStep>>;
  setDeleteId: React.Dispatch<React.SetStateAction<number>>;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setVbCpGiftList: React.Dispatch<React.SetStateAction<VbCpGiftList | undefined>>;
}

const FreeCampaign = ({ cpRow, setDeleteId, setDeleteModalStep, setModalStep, setVbCpGiftList }: FreeCampaignProps) =>
  cpRow.map((row) => ({
    title: row.title,
    chip: {
      color: chipColor(row.status),
      text: chipText(row.status),
    },
    createdDate: row.created_date,
    leaderMenu: leaderMenu(row.status),
    onClickLeader: (val: string) => {
      if (val === 'delete') {
        setDeleteModalStep(1);
        setDeleteId(row.id || 0);
      } else {
        setVbCpGiftList(row);
        setModalStep(1);
      }
    },
    rows: (
      <CampaignTableBody pa={0} gap={4}>
        <CampaignTableRow isDivider pa={0} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              配布期間
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.begin_end}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        <CampaignTableRow isDivider pa={0} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              eギフト
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.gift_name}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        <CampaignTableRow isDivider pa={0} gap={16} height={84}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={84} dividerHeight={84} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              地域
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={84} dividerHeight={84} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.state_names}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
      </CampaignTableBody>
    ),
  }));

function makeListData({ cpRow, setDeleteModalStep, setDeleteId, setModalStep, setVbCpGiftList }: Props) {
  const CampaignType = FreeCampaign({ cpRow, setDeleteModalStep, setDeleteId, setModalStep, setVbCpGiftList });
  return CampaignType;
}

export function MembershipPrivilegeCampaign() {
  const { limit, page, handleChangeLimit, handleChangePage } = usePagenation();
  const [dataSize, setDataSize] = React.useState<number>(0);

  const { data, loading, error, refetch } = gql.useGetVbCpGiftListByKind11Query();
  const [mutation, { loading: loadingKind, error: errorCampaignKind, reset }] = gql.useAddSCampaignKind11Mutation();
  const { openErrorModal } = useErrorModal();

  const [deleteModalStep, setDeleteModalStep] = React.useState<DeleteModalStep>(DeleteModalStep.NULL);
  const [deleteId, setDeleteId] = React.useState<number>(0);

  const [vbCpGiftListData, setVbCpGiftList] = React.useState<VbCpGiftList>();

  const [modalStep, setModalStep] = React.useState<NewUpdateModalStep>(NewUpdateModalStep.NULL);

  const listItems = useMemo(
    () =>
      makeListData({
        cpRow: data?.getVBCpGiftListByKind11 || [],
        setDeleteModalStep,
        setDeleteId,
        setModalStep,
        setVbCpGiftList,
      }),
    [data]
  );
  const { displayDeleteModal } = DeleteModal({
    deleteModalStep,
    setDeleteModalStep,
    deleteId,
    onClose: () => {
      setDeleteModalStep(DeleteModalStep.NULL);
      refetch();
    },
  });

  useMemo(() => {
    setDataSize(data?.getVBCpGiftListByKind11.length || 0);
  }, [data]);

  const update = useCallback(
    ({
      begin_date,
      end_date,
      state_ids,
      title,
      umgmt_id,
      egift_code,
    }: BenefitsSettingMembershipPrivilegeCampaignUpdateType) => {
      mutation({
        variables: {
          begin_date,
          end_date,
          state_ids,
          title,
          umgmt_id,
          egift_code,
        },
      })
        .then(() => {
          setModalStep(NewUpdateModalStep.COMPLETE);
          refetch();
        })
        .catch(() => {
          setModalStep(NewUpdateModalStep.NULL);
        });
    },
    [mutation, refetch]
  );

  if (error) {
    openErrorModal({ message: 'データ取得に失敗しました。' });
    reset();
  }

  if (errorCampaignKind) {
    openErrorModal({ message: 'データの作成に失敗しました。' });
    reset();
  }

  const defaultSelectGiftBrandValues = useMemo(
    () =>
      vbCpGiftListData
        ? {
            title: `コピー${vbCpGiftListData.title || ''}`,
            beginEnd: vbCpGiftListData.begin_end || '',
            beginDate: vbCpGiftListData.begin_date || '',
            endDate: vbCpGiftListData.end_date || '',
            stateNames: vbCpGiftListData.state_names || '',
            stateIds: vbCpGiftListData.state_ids || '',
            brandNames: vbCpGiftListData.brand_names || '',
            brandIds: vbCpGiftListData.brand_ids || '',
            bizNames: vbCpGiftListData.biz_names || '',
            egiftCode: vbCpGiftListData.egift_code || '',
            bizBrandList: parseBizBrandList(vbCpGiftListData.biz_ids || '', vbCpGiftListData.brand_ids || ''),
          }
        : undefined,
    [vbCpGiftListData]
  );

  const { displayModal } = SelectGiftBrand({
    modalStep,
    setModalStep,
    defaultValues: defaultSelectGiftBrandValues,
    update,
    titles: {
      newTitleValue: 'キャンペーン新規作成　期間/eギフトを選択',
      copiedTitleValue: 'キャンペーン複製編集　期間/eギフトを選択',
    },
  });

  if (loading || loadingKind) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                入会特典キャンペーン
              </Text>
              <Text color="darkBlue" variant="caption12">
                バイトラベル会員向け入会特典キャンペーンの期間とeギフトの設定
              </Text>
            </Box>
            <div>
              <MainButton
                onClick={() => {
                  setModalStep(1);
                  setVbCpGiftList(undefined);
                }}
                variant="primary"
                width={160}
                icon="addCircle"
              >
                新規作成
              </MainButton>
            </div>
          </Box>
          <Divider option="horizontal" />
          <Box>
            <PaginationBox
              dataSize={dataSize}
              limit={limit}
              page={page}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <Box>
                <ScrollWrapper bottom={327}>
                  {listItems ? (
                    <CampaignTable listItems={listItems.slice(limit * (page - 1), limit * page)} makeHeader frame />
                  ) : null}
                </ScrollWrapper>
              </Box>
            </PaginationBox>
          </Box>
        </Box>
      </HideWrapper>
      <Modal
        width={displayDeleteModal.width}
        height={displayDeleteModal.height}
        isOpen={deleteModalStep !== 0}
        onClose={() => displayDeleteModal.onClose}
        header={displayDeleteModal.header}
        content={displayDeleteModal.content}
        footer={displayDeleteModal.footer}
      />
      <Modal
        height={displayModal.height}
        width={displayModal.width}
        isOpen={modalStep !== 0}
        content={displayModal.content}
        header={displayModal.header}
        footer={displayModal.footer}
        onClose={() => setModalStep(0)}
        overflow="visible"
      />
    </>
  );
}
