/**
 * Figma ID: 05-07-01
 * 名称: 源泉徴収票一覧
 */

import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import React, { useEffect, useMemo, useState } from 'react';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import * as gql from 'graphql/graphql-ow';

import { usePagenation, useSort } from 'components/utils';
import { Loading } from 'components/loading/loading';
import { Modal } from 'components/newModal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { PageNumber } from 'pages/s05/constants';
import { orderBy } from 'lodash';
import { useWithholdSlipPdfDownload } from 'pdfComponent';
import { WithholdingModal } from './modal/withholding-modal';

export function Withholding({ id }: { id: string }) {
  const {
    data,
    loading,
    error: memberListWithholdError,
  } = gql.useGetVbMemberListWithholdByIdQuery({
    variables: { id: Number(id) },
  });
  const [getSlipList, { data: slipListData, loading: slipListLoading }] =
    gql.useGetVbWithholdSlipListByBtmemIdCorporateNoLazyQuery();
  const [modalStep, setModalStep] = React.useState<boolean>(false);
  const [dataSize, setDataSize] = useState<number>(0);
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(PageNumber.initialValue),
  });
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (memberListWithholdError) {
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [memberListWithholdError, openErrorModal]);

  // [hook] 源泉徴収票PDFダウンロード
  const { downloadPdf, loading: downloadLoading } = useWithholdSlipPdfDownload();

  /**
   * [onClick] 源泉徴収票PDFダウンロード
   */
  const onClickDownload = (btmemId: number, year: number, corporateNo: string) => {
    (async () => {
      try {
        // ロード中は処理しない(2重クリック防止)
        if (downloadLoading) return;

        // PDFダウンロード処理
        const result = await downloadPdf(btmemId, year, corporateNo);
        // 処理に失敗した場合はエラー内容を表示する
        if (result?.error) {
          setModalStep(false);
          openErrorModal({ message: result?.error });
        }
      } catch (error) {
        setModalStep(false);
        console.error(error);
        openErrorModal({ message: 'ダウンロード処理に失敗しました。' });
      }
    })();
  };

  const items = useMemo(() => {
    if (loading) {
      return [];
    }
    let withholdList = data?.getVBMemberListWithholdById.withhold || [];
    setDataSize(withholdList.length);

    if (sort) {
      withholdList = orderBy(withholdList, sort.key, sort.direction);
    }

    return (
      withholdList.map((withhold, key) => ({
        uniqueKey: key,
        icon: (
          <Icon
            key={`${String(withhold.corporate_no)}-${String(key)}`}
            name="eye"
            onClick={() => {
              getSlipList({
                variables: {
                  btmem_id: Number(id),
                  corporate_no: withhold.corporate_no || '',
                },
              }).then((result) => {
                if (!result.error) {
                  setModalStep(true);
                } else {
                  openErrorModal({ message: '情報取得に失敗しました。' });
                }
              });
            }}
          />
        ),
        corp_name: withhold.corp_name,
      })) ?? []
    );
  }, [data?.getVBMemberListWithholdById.withhold, getSlipList, id, loading, openErrorModal, sort]);

  const withholdingModal = WithholdingModal({
    lists: slipListData?.getVBWithholdSlipListByBtmemIdCorporateNo.list,
    onClose: () => {
      setModalStep(false);
    },
    onClickDownload: (btmemId, year, corporateNo) => {
      onClickDownload(btmemId, year, corporateNo);
    },
  });

  useEffect(() => {
    setDataSize(items.length);
  }, [items.length]);

  if (loading || slipListLoading) {
    return Loading();
  }

  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            源泉徴収票一覧
          </Text>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flex="1" flexDirection="column">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <div style={{ height: 'calc(100vh - 370px)' }}>
              <ScrollableArea>
                <List
                  header={[
                    { key: 'icon', width: 24 },
                    { key: 'corp_name', width: 240, columnName: '事業者' },
                  ]}
                  items={items.slice(limit * (page - 1), limit * page)}
                  onChangeSort={handleChangeSort}
                  sort={sort}
                  width="100%"
                  rowWidth="100%"
                />
              </ScrollableArea>
            </div>
          </PaginationBox>
        </Box>
      </Box>
      <Modal
        height={withholdingModal.height}
        width={withholdingModal.width}
        isOpen={modalStep}
        content={withholdingModal.content}
        header={withholdingModal.header}
        footer={withholdingModal.footer}
        onClose={withholdingModal.onClose}
      />
    </>
  );
}
