/**
 * Figma ID: 10-08-02-05
 * 名称: ギフト選択肢(新規作成)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

export function CompleteHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        新規追加 完了
      </Text>
    </Box>
  );
}

export function CompleteContent() {
  return (
    <Box>
      <div style={{ padding: '9px 0px' }}>
        <Text variant="body14">情報の設定が完了しました。</Text>
      </div>
    </Box>
  );
}

type CompleteFooterModel = {
  onClickClose: () => void;
};
export function CompleteFooter(props: CompleteFooterModel) {
  const { onClickClose } = props;

  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
      <MainButton
        onClick={() => {
          onClickClose();
        }}
        width={104}
        variant="secondary"
      >
        閉じる
      </MainButton>
    </Box>
  );
}
