import { createWithholdingSlipPdf, WsPdfProps, download } from 'components/pdf';
import { useGetVbWithholdingSlipDataByGCcalBIdCNoLazyQuery } from 'graphql/graphql-ow';

export { preview, download } from 'components/pdf';

// 源泉徴収票PDFデータ取得用フック
export const useWithholdSlipPdfDownload = () => {
  // 源泉徴収票PDFデータ取得
  const [getWithholdingSlipData, { loading, error }] = useGetVbWithholdingSlipDataByGCcalBIdCNoLazyQuery();

  // 源泉徴収票PDFデータ取得
  // 例外時やデータが取得できなかった場合はnullを返す
  const downloadPdf = async (btmemId: number, year: number, corporateNo: string) => {
    try {
      // 源泉徴収票データ取得
      const data = await getWithholdingSlipData({
        variables: {
          btmem_id: btmemId,
          gregorian_cal: year,
          corporate_no: corporateNo,
        },
      });

      const pdfData = data.data?.getVBWithholdingSlipDataByGCcalBIdCNo;

      // データが取得できなかった場合はエラーを返す
      if (!pdfData || !pdfData.btmem_id) return { success: false, error: 'PDFデータの取得に失敗しました。' };

      // 抽出したデータをPDFのプロパティに設定
      const pdfProps: WsPdfProps = {
        year: pdfData?.gregorian_cal || undefined,
        address: pdfData?.btmem_address || undefined,
        nameKana: pdfData?.btmem_kana || undefined,
        name: pdfData?.btmem_name || undefined,
        paymentPrice: pdfData?.salary || undefined,
        withholdingPrice: pdfData?.tax_amount || undefined,
        payerAddress: pdfData?.corp_address || undefined,
        payerName: pdfData?.corp_name || undefined,
        payerPhone: pdfData?.corp_phone || undefined,
      };

      // PDF作成
      const pdfBuffer = await createWithholdingSlipPdf(pdfProps);
      if (!pdfBuffer) return { success: false, error: 'PDFの作成に失敗しました。' };

      // PDFダウンロード
      download(pdfBuffer, `${pdfProps.name || ''}_${pdfProps.payerName || ''}_源泉${year}年分.pdf`);

      return { success: true };
    } catch (e) {
      console.log(e);
      return { success: false, error: 'ダウンロード処理に失敗しました。' };
    }
  };

  return {
    downloadPdf,
    loading,
    error,
  };
};
