import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { Loading } from 'components/loading/loading';
import { Modal } from 'components/newModal';
import { TpmemDetailHeader } from 'modal/tpmemDetail/TpmemDetailHeader';
import { TpmemDetailContent } from 'modal/tpmemDetail/TpmemDetailContent';
import { TpmemDetailFooter } from 'modal/tpmemDetail/TpmemDetailFooter';
import {
  TWalletSum,
  VtBalanceList,
  useGetMAreaQuery,
  useGetMBrandQuery,
  useGetMBusinessQuery,
  useGetMStateQuery,
} from 'graphql/graphql-ow';
import { RhfSelect } from 'components/rhfSelect';
import { useForm } from 'react-hook-form';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { downloadCsvFile } from 'components/csv';
import { TextLink } from 'components/textLink';

interface Props {
  walletBalanceList: VtBalanceList[];
  walletSum: TWalletSum;
}

const StyledListWrapper = styled.div`
  height: calc(100vh - 378px);
  max-height: calc(100vh - 378px);
  overflow: auto;
`;

// Figma管理番号
// 10-07-01
// 10-07-02
export function WalletBallanceList({ walletBalanceList, walletSum }: Props) {
  const { control, reset, watch } = useForm<{ biz: string; brand: string; area: string; state: string }>({
    mode: 'onChange',
  });
  const currentBiz = watch('biz');
  const currentBrand = watch('brand');
  const currentArea = watch('area');
  const currentState = watch('state');

  const { data: mareaData, loading: mareaLoading } = useGetMAreaQuery({ context: { clientName: 'master' } });
  const { data: mstateData, loading: mstateLoading } = useGetMStateQuery({ context: { clientName: 'master' } });
  const { data: businessData, loading: businessLoading } = useGetMBusinessQuery({
    context: { clientName: 'master' },
  });
  const { data: brandData, loading: brandLoading } = useGetMBrandQuery({ context: { clientName: 'master' } });
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [isTpmemDetailShow, setIsTpmemDetailShow] = React.useState(false);
  const [selectedTpmemId, setSelectedTpmemId] = React.useState<number | undefined>();
  const [selectedTpmemName, setSelectedTpmemName] = React.useState<string | undefined>();
  const today = new Date();
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { width: 40, key: 'image' },
    { width: 160, key: 'name', columnName: '加盟店名' },
    { width: 140, key: 'price_disp', columnName: 'ウォレット残高' },
    { width: 80, key: 'area', columnName: '地域' },
    { width: 80, key: 'state', columnName: '都道府県' },
    { width: 120, key: 'biz_name', columnName: '業種' },
    { width: 120, key: 'brand_name', columnName: 'ブランド' },
    { width: 160, key: 'mco_name', columnName: '事業者' },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let walletBalanceListItems = walletBalanceList;
    if (sort) {
      if (sort.key === 'status') {
        walletBalanceListItems = orderBy(walletBalanceListItems, 'status', sort.direction);
      } else {
        walletBalanceListItems = orderBy(walletBalanceListItems, sort.key, sort.direction);
      }
    }
    walletBalanceListItems = walletBalanceListItems
      .filter((item) => (currentBiz ? String(item.biz_id) === currentBiz : true))
      .filter((item) => (currentBrand ? String(item.brand_id) === currentBrand : true))
      .filter((item) => (currentArea ? String(item.area) === currentArea : true))
      .filter((item) => (currentState ? String(item.state) === currentState : true));

    // フィルター後の件数
    setDataSize(walletBalanceListItems.length);

    return walletBalanceListItems
      .map((item, index) => ({
        uniqueKey: Math.random(),
        backgroundColor: index !== 1 ? 'white' : 'red',
        image:
          index !== 1 ? (
            <img src={item.image ?? ' '} width={40} height={40} alt={item.image ?? ' '} />
          ) : (
            <img src="/conveni_stop.jpg" width={40} height={40} alt={item.image ?? ' '} />
          ),
        name: (
          <TextLink
            onClick={() => {
              setIsTpmemDetailShow(true);
              setSelectedTpmemId(item.tpmem_id);
              setSelectedTpmemName(item.name ?? '');
            }}
            lineClamp={1}
          >
            {item.name}
          </TextLink>
        ),
        price_disp: `¥${item.price_disp ?? 0}`,
        area: item.area,
        state: item.state,
        biz_name: item.biz_name,
        brand_name: item.brand_name,
        mco_name: item.mco_name,
      }))
      .slice(limit * (page - 1), limit * page);
  }, [walletBalanceList, sort, limit, page, currentBiz, currentBrand, currentArea, currentState]);

  const dlCsvData = () => {
    const fileName = `ウォレット残高${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}.csv`;
    const items = walletBalanceList;
    const column = [
      { header: '加盟店名', key: 'storeName' },
      { header: 'ウォレット残高', key: 'wallet' },
      { header: '地域', key: 'area' },
      { header: '都道府県', key: 'state' },
      { header: '業種', key: 'biz' },
      { header: 'ブランド', key: 'brand' },
      { header: '事業者', key: 'mco' },
    ];
    const rows = items.map((val) => ({
      storeName: val.name,
      wallet: val.price_disp,
      area: val.area,
      state: val.state,
      biz: val.biz_name,
      brand: val.brand_name,
      mco: val.mco_name,
    }));
    downloadCsvFile(column, rows, fileName);
  };

  return (
    <Box px={24} display="flex" flexDirection="column" alignItems="center" overflow="hidden" width="100%">
      {(mareaLoading || mstateLoading || businessLoading || brandLoading) && <Loading />}
      <Box width="100%">
        <Box height={60} display="flex" justifyContent="space-between" gap={8} alignItems="center">
          <Box display="flex" alignItems="center" gap={5}>
            <Text variant="h2" color="darkBlue">
              ウォレット残高一覧
            </Text>
            <Text variant="caption12" color="darkBlue">
              各加盟店ウォレット残高。ダウンロードで最新情報を出力します。
            </Text>
          </Box>
          <Box display="flex" gap={5}>
            <Box display="flex" px={4} py={2} gap={8} alignItems="center">
              <Text variant="body14" color="darkBlue">
                総額
              </Text>
              <Text variant="h2" color="darkBlue">
                ¥{walletSum?.price?.toLocaleString() ?? ''}
              </Text>
            </Box>
            <MainButton width={116} variant="secondary" onClick={() => dlCsvData()}>
              ダウンロード
            </MainButton>
          </Box>
        </Box>
        <Divider option="horizontal" />
      </Box>
      <Box width="100%">
        <Box px={16} py={8} display="flex" alignItems="center" flexWrap="wrap" gap={16}>
          <RhfSelect
            control={control}
            name="biz"
            options={
              businessData?.getMBusiness?.map((val) => ({ value: String(val?.id) ?? '', label: val?.name ?? '' })) ?? []
            }
            placeholder="業種"
            width={120}
            onChange={() => {
              // ページ初期化
              setPage(1);
            }}
          />
          <RhfSelect
            control={control}
            name="brand"
            options={
              brandData?.getMBrand
                ?.filter((val) => val?.biz_id === Number(currentBiz ?? 0))
                ?.map((val) => ({ value: String(val?.id) ?? '', label: val?.name ?? '' })) ?? []
            }
            placeholder="ブランド"
            width={120}
            onChange={() => {
              // ページ初期化
              setPage(1);
            }}
          />
          <Divider option="vertical" length={24} />
          <RhfSelect
            control={control}
            name="area"
            options={
              mareaData?.getMArea?.map((val) => ({ value: String(val?.name) ?? '', label: val?.name ?? '' })) ?? []
            }
            placeholder="地域"
            width={120}
            onChange={() => {
              // ページ初期化
              setPage(1);
            }}
          />
          <RhfSelect
            control={control}
            name="state"
            options={mstateData?.getMState?.map((val) => ({ value: val?.name ?? '', label: val?.name ?? '' })) ?? []}
            placeholder="都道府県"
            width={120}
            onChange={() => {
              // ページ初期化
              setPage(1);
            }}
          />
          <MainButton
            variant="clear"
            width={42}
            onClick={() => {
              // ページ初期化
              setPage(1);
              reset();
            }}
          >
            クリア
          </MainButton>
        </Box>
        <Divider option="horizontal" />
      </Box>
      <Box flex="auto" width="100%">
        <PaginationBox
          dataSize={dataSize}
          limit={limit}
          page={page}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <StyledListWrapper>
            <List
              key={String(limit) + String(page) + (sort?.key || '')}
              header={header}
              items={displayItems}
              sort={sort}
              width="100%"
              rowWidth="100%"
              onChangeSort={handleChangeSort}
            />
          </StyledListWrapper>
        </PaginationBox>
      </Box>
      <Modal
        isOpen={isTpmemDetailShow}
        header={<TpmemDetailHeader tpmemName={selectedTpmemName} tpmemId={selectedTpmemId} />}
        content={<TpmemDetailContent tpmemId={selectedTpmemId} />}
        footer={<TpmemDetailFooter onClickClose={() => setIsTpmemDetailShow(false)} />}
        onClose={() => setIsTpmemDetailShow(false)}
        width={600}
        height="auto"
      />
    </Box>
  );
}
