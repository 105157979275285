/**
 * Figma ID: 04-02-03
 * 名称: 採用（成約バイト状況）
 */
import React from 'react';
import { styled } from '@linaria/react';
import { Chip } from 'components/chip';
import { Text } from 'components/text';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import { Box } from 'components/box';
import { VbMemberBase, VtArbeitEntryList, useGetVbMemberBaseByIdLazyQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { BitravelMemberModal } from 'modal/bitravelMemberModal';
import NoImgSvg from 'components/assets/images/no-image-small.svg';

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 223px);
  max-height: calc(100vh - 223px);
`;

interface Props {
  arbeitEntryInfo: VtArbeitEntryList | null;
}

// 採用
export function Adopt({ arbeitEntryInfo }: Props) {
  const { openErrorModal } = useErrorModal();
  // 採用一覧
  const arbeitEntryList = arbeitEntryInfo ? [arbeitEntryInfo] : [];
  // バイトラベル会員情報
  const [vbMemberBase, setVbMemberBase] = React.useState<VbMemberBase | null>(null);
  // バイトラベル会員情報取得
  const [getVbMemberBaseById, { error: getVbMemberBaseByIdError }] = useGetVbMemberBaseByIdLazyQuery();
  React.useEffect(() => {
    if (getVbMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVbMemberBaseByIdError, openErrorModal]);

  /**
   * バイトラベル会員モーダル表示
   */
  const showBitravelMemberModal = React.useCallback(
    (btmem_id: number) => {
      (async () => {
        const result = await getVbMemberBaseById({ variables: { id: btmem_id } });
        const vBMemberBase = result?.data?.getVBMemberBaseById;
        // バイトラベル会員の情報を設定してモーダルを表示する
        if (vBMemberBase) {
          setVbMemberBase(vBMemberBase);
        }
      })();
    },
    [getVbMemberBaseById]
  );

  return (
    <>
      <Box
        px={24}
        display="flex"
        flexDirection="column"
        alignItems="center"
        overflow="scroll"
        width="100%"
        height="100%"
      >
        <Box display="flex" height={60} width="100%" alignItems="center">
          <Text color="darkBlue" variant="h2">
            採用
          </Text>
        </Box>
        <Box flex="auto" width="100%">
          <StyledOverflowWrapper>
            <List
              header={[
                {
                  columnName: '',
                  key: 'eye',
                  width: 24,
                },
                {
                  columnName: '',
                  key: 'image',
                  width: 40,
                },
                {
                  columnName: '氏名',
                  key: 'name',
                  width: 192,
                },
                {
                  columnName: '会員番号',
                  key: 'btmem_number',
                  width: 92,
                },
                {
                  columnName: '採用手数料',
                  key: 'total_hire_fee',
                  width: 92,
                },
                {
                  columnName: 'スコア',
                  key: 'score',
                  width: 92,
                },
                {
                  columnName: 'ランク',
                  key: 'rank_name',
                  width: 92,
                },
                {
                  columnName: '応募No',
                  key: 'entry_no',
                  width: 112,
                },
              ]}
              width="100%"
              rowWidth="100%"
              items={arbeitEntryList.map((item) => ({
                uniqueKey: item.entry_id,
                eye: (
                  <Icon
                    name="eye"
                    onClick={() => {
                      showBitravelMemberModal(item.btmem_id);
                    }}
                  />
                ),
                image: <img style={{ width: '40px', height: '40px' }} src={item.image || NoImgSvg} alt="サムネイル" />,
                name: (
                  <Box display="flex" alignItems="center">
                    <Chip color={item.kind_name === '他店/一般' ? 'okBlue' : 'darkBlue'} width={56}>
                      {item.kind_name}
                    </Chip>
                    <Box ml={16}>
                      <Text variant="caption12" lineClamp={1}>
                        {item.name}
                      </Text>
                    </Box>
                  </Box>
                ),
                btmem_number: item.btmem_number,
                status: item.status,
                total_hire_fee: item.total_hire_fee && `¥${item.total_hire_fee}`,
                kind_name: item.kind_name,
                score: item.score,
                rank_name: item.rank_name,
                entry_no: item.entry_no,
              }))}
              onChangeSort={() => {}}
            />
          </StyledOverflowWrapper>
        </Box>
      </Box>
      {vbMemberBase && (
        <BitravelMemberModal
          isOpen={Boolean(vbMemberBase)}
          vbMemberBase={vbMemberBase}
          onClickClose={() => setVbMemberBase(null)}
        />
      )}
    </>
  );
}
