import React from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { ImageModal } from 'components/newModal';
import { Avatar } from 'components/avatar';

export interface Props {
  image: string;
}

const StyledAvatarBG = styled.div`
  display: flex;
  width: 480px;
  height: 360px;
  background-color: black;
`;

export function Photograph({ image }: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  return (
    <>
      <Box px={24} display="flex" flexDirection="column" gap={16} width="100%">
        <div>
          <Box height={60} display="flex" alignItems="center">
            <Box flex="auto">
              <Text variant="h2" color="darkBlue">
                写真
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
        </div>
        <Box display="flex" justifyContent="center" flex="1" alignItems="center">
          <StyledAvatarBG onClick={() => setIsModalOpen(true)}>
            <Avatar width={480} height={360} alt="サムネイル" src={image} />
          </StyledAvatarBG>
        </Box>
      </Box>
      <ImageModal src={image} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
