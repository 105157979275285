import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  useGetMStateLazyQuery,
  useGetMCityByStateIdLazyQuery,
  useGetMBusinessLazyQuery,
  useGetMBrandByBizIdLazyQuery,
  useUploadTBaseMutation,
  useGetVtMemberListWithOrderQuery,
} from 'graphql/graphql-ow';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Card } from 'components/card';
import { useForm, useWatch } from 'react-hook-form';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { RhfSelect } from 'components/rhfSelect';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { List } from 'components/list';
import { uploadFile } from 'components/api';
import { useErrorModal } from 'components/error/errorModalProvider';
import { RhfInput } from 'components/rhfInput';
import { usePagenation, useSort } from 'components/utils';
import { TextLink } from 'components/textLink';
import { AffiliationStatus } from 'components/const';
import NoImgSvg from 'components/assets/images/no-image-small.svg';
import { ListHeaderType, ListItemsType, VtMemberListSearchKey } from './type';
import { Alert, MemberStatus, MemberStatusList, PaymentMethod, PaymentMethodList } from './const';
import { EyeIcon } from './components/eyeIcon';
import { RegistrationModal, BulkRegistrationModal, CsvOutputModal } from './components/modal';

/**
 * Figma ID: 03-01-01
 * 名称: 加盟店情報
 */

enum ModalType {
  None = 'none',
  Registration = 'Registration',
  BulkRegistration = 'BulkRegistration',
  CsvOutput = 'CsvOutput',
}
export function S03() {
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  const { control, reset, setValue } = useForm<VtMemberListSearchKey>();
  const [activeModalType, setActiveModalType] = useState<ModalType>(ModalType.None);

  // ファイルアップロード用ローディング
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);

  const [getMState, { data: mStateData, loading: mStateLoading, error: getMStateError }] = useGetMStateLazyQuery({
    context: { clientName: 'master' },
  });
  const mState = mStateData?.getMState;

  const [getMCityByStateId, { data: mCityByStateIdData, loading: mCityByStateILoading, error: mCityByStateError }] =
    useGetMCityByStateIdLazyQuery({
      context: { clientName: 'master' },
    });
  const [getMBusiness, { data: mBusinessData, loading: mBusinessLoading, error: getMBusinessError }] =
    useGetMBusinessLazyQuery({
      context: { clientName: 'master' },
    });
  const [getMBrandByBizId, { data: mBrandByBizIdData, loading: mBrandByBizIdLoading, error: mBrandByBizIdError }] =
    useGetMBrandByBizIdLazyQuery({
      context: { clientName: 'master' },
    });

  // 絞込み用の入力値をwatchで取得
  const currentStoreCodeOrName = useWatch({ control, name: 'search_store_code_name' });
  const currentState = useWatch({ control, name: 'state' });
  const currentCity = useWatch({ control, name: 'city' });
  const currentBrandId = useWatch({ control, name: 'brand_id' });
  const currentBizId = useWatch({ control, name: 'biz_id' });
  const currentStatus = useWatch({ control, name: 'status' });
  const currentPaymentMethod = useWatch({ control, name: 'payment_method' });
  const [searchStoreCodeOrName, setSearchStoreCodeOrName] = useState<string>();
  const offset = page === 1 ? 0 : (page - 1) * limit;

  const [dataSize, setDataSize] = useState<number>(0);

  const changeSortKey = (key: string | null) => {
    switch (key) {
      case 'again':
        return 'again_num';
      case 'belong_count':
        return 'belong_num';
      case 'sub_count':
        return 'sub_num';
      case 'other_adopt':
        return 'other_adopt_num';
      case 'total_adopt':
        return 'total_adopt_num';
      default:
        return key;
    }
  };

  const {
    data: vTmemberListData,
    loading: vTMemberListLoading,
    error: getVTMemberListError,
    refetch: refetchList,
  } = useGetVtMemberListWithOrderQuery({
    variables: {
      status: currentStatus ? Number(currentStatus) : undefined,
      code_name: searchStoreCodeOrName,
      state: mState?.find((s) => s?.id.toString() === currentState)?.name,
      city: currentCity?.length > 0 ? currentCity : undefined,
      biz_id: currentBizId ? Number(currentBizId) : undefined,
      brand_id: currentBrandId ? Number(currentBrandId) : undefined,
      payment_method: currentPaymentMethod ? Number(currentPaymentMethod) : undefined,
      order_name: changeSortKey(sort?.key ?? null),
      desc: sort?.direction === 'desc',
      offset,
      limit,
    },
    onCompleted: (data) => {
      setDataSize(data?.getVTMemberListWithOrder?.count ?? 0);
    },
    fetchPolicy: 'no-cache',
  });

  // 店舗ベース情報を一括登録
  const [uploadTBase, { error: uploadTBaseError }] = useUploadTBaseMutation();

  useEffect(() => {
    if (
      getMStateError ||
      mCityByStateError ||
      getMBusinessError ||
      mBrandByBizIdError ||
      getVTMemberListError ||
      uploadTBaseError
    ) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [
    getMStateError,
    mCityByStateError,
    getMBusinessError,
    mBrandByBizIdError,
    getVTMemberListError,
    uploadTBaseError,
    openErrorModal,
  ]);

  // 絞込み用リストを作成
  useEffect(() => {
    if (mState === undefined) {
      getMState();
    }
  }, [mState, getMState]);
  const mStateList =
    mState?.map((item) => ({
      value: String(item?.id),
      label: item?.name ?? '',
    })) || [];

  const mCity = mCityByStateIdData?.getMCityByStateId;
  useEffect(() => {
    if (currentState !== undefined) {
      getMCityByStateId({
        variables: { sid: Number(currentState) },
      });
    }
  }, [mState, mCity, getMCityByStateId, currentState, setValue]);
  const mCityList = React.useMemo(
    () =>
      currentState && mCity
        ? mCity.map((item) => ({
            value: item?.name ?? '',
            label: item?.name ?? '',
          }))
        : [],
    [currentState, mCity]
  );
  const mBusiness = mBusinessData?.getMBusiness;
  useEffect(() => {
    if (mBusiness === undefined) {
      getMBusiness();
    }
  }, [mBusiness, getMBusiness]);
  const mBusinessList =
    mBusiness?.map((item) => ({
      value: String(item?.id),
      label: item?.name ?? '',
    })) || [];
  const mBrandByBizId = mBrandByBizIdData?.getMBrandByBizId;

  const mBrandByBizIdList = React.useMemo(
    () =>
      currentBizId && mBrandByBizId
        ? mBrandByBizId.map((item) => ({
            value: String(item?.id),
            label: item?.name ?? '',
          }))
        : [],
    [currentBizId, mBrandByBizId]
  );

  const getBGColor = (status: MemberStatus): 'white' | 'gray' | 'red' => {
    switch (status) {
      case MemberStatus.Unregistered:
        return 'gray';
      case MemberStatus.Suspended:
        return 'red';
      default:
        return 'white';
    }
  };

  const setPpaymentMethod = (type: PaymentMethod) => {
    switch (type) {
      case PaymentMethod.Giro:
        return (
          <Text variant="caption12" color="okBlue" bold>
            振替
          </Text>
        );
      case PaymentMethod.TransferRequesting:
        return (
          <Text variant="caption12" color="cautionRed" bold>
            振替依頼中(出力済)
          </Text>
        );
        return '';
      case PaymentMethod.Transfer:
        return (
          <Text variant="caption12" color="black" bold>
            振込
          </Text>
        );
      default:
        return (
          <Text variant="caption12" color="black" bold>
            -
          </Text>
        );
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickBulkRegister = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const mailAddressSlice = (mailAddress: string) => {
    if (mailAddress?.length < 18) {
      return mailAddress;
    }
    return `${mailAddress?.slice(0, 18) ?? ''}...`;
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        (async () => {
          try {
            if (file.size === 0) {
              openErrorModal({ message: '空のファイルはアップロードできません。' });
              return;
            }

            // 拡張子チェック
            const fileExtension = file.name.split('.').pop();

            if (fileExtension?.toLowerCase() !== 'csv') {
              openErrorModal({ message: 'CSVファイルを指定してください。' });
              return;
            }

            // ファイルアップロード中はローディング表示
            setFileUploadLoading(true);
            // CSVファイルアップロード
            await uploadFile({ filePath: `temporary/${file.name}`, file });

            // アップロードAPI実行
            // ※同一データを登録しようとすると例外が発生する
            const result = await uploadTBase({
              variables: {
                s3_bucket: process.env.REACT_APP_UPLOAD_S3_BUCKET || '',
                s3_filepath: `temporary/${file.name}`,
              },
            });

            // 一覧上の情報を更新する
            await refetchList();

            if (Number(result.data?.uploadTBase.resultRows) === 0) {
              openErrorModal({ title: '', message: '登録対象のデータが存在しませんでした。' });
            } else {
              // 完了ダイアログ表示
              setActiveModalType(ModalType.BulkRegistration);
            }
          } catch (e) {
            openErrorModal({ message: '一括登録に失敗しました。' });
          } finally {
            // ローディング解除
            setFileUploadLoading(false);
            // ファイル選択欄を空にする
            // ※この処理を入れないと再度ファイル選択してもonChangeが発火しない
            event.target.value = '';
          }
        })();
      };
    }
  };

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'eye', width: 24 },
      { key: 'image', width: 40 },
      { key: 'code', width: 120, columnName: '店舗コード' },
      { key: 'store_name', width: 156, columnName: '店舗名' },
      { key: 'payment_method', width: 120, columnName: '支払方法' },
      { key: 'zip_code', width: 240, columnName: '住所' },
      { key: 'manager', width: 120, columnName: '責任者氏名' },
      { key: 'phone', width: 100, columnName: '電話番号' },
      { key: 'email', width: 160, columnName: 'メールアドレス（ID）' },
      { key: 'score', width: 64, columnName: 'スコア' },
      { key: 'again', width: 136, columnName: 'またバイトしたい' },
      { key: 'belong_count', width: 80, columnName: '所属' },
      { key: 'sub_count', width: 80, columnName: 'サブ所属' },
      { key: 'other_adopt', width: 120, columnName: '他店応援回数' },
      { key: 'total_adopt', width: 120, columnName: '総雇用回数' },
      { key: 'opening', width: 140, columnName: '営業時間' },
      { key: 'parking', width: 100, columnName: '駐車場' },
      { key: 'block_count', width: 170, columnName: '店舗ブロック状況' },
      { key: 'mco_name', width: 200, columnName: '事業者' },
      { key: 'area_name', width: 100, columnName: '地域' },
      { key: 'biz_name', width: 120, columnName: '業種' },
      { key: 'brand_name', width: 120, columnName: 'ブランド' },
    ],
    []
  );
  const items = useMemo(
    () =>
      vTmemberListData?.getVTMemberListWithOrder?.list?.map((item) => ({
        address: item?.address,
        address2: item?.address2,
        again: item?.again,
        again_num: item?.again_num,
        area_name: item?.area_name,
        belong_count: item?.belong_count,
        belong_num: item?.belong_num,
        belong_total: item?.belong_total,
        biz_id: item?.biz_id,
        biz_name: item?.biz_name,
        block_count: item?.block_count,
        block_num: item?.block_num,
        brand_id: item?.brand_id,
        brand_name: item?.brand_name,
        city: item?.city,
        code: item?.code,
        email: item?.email,
        group_num: item?.group_num,
        id: item?.id,
        image: item?.image,
        manager: item?.manager,
        mco_name: item?.mco_name,
        mgr_id: item?.mgr_id,
        newly: item?.newly,
        opening: item?.opening,
        other_adopt: item?.other_adopt,
        parking: item?.parking,
        payment_method: item?.payment_method,
        phone: item?.phone,
        score: item?.score,
        score_num: item?.score_num,
        score_value: item?.score_value,
        state: item?.state,
        status: item?.status,
        store_name: item?.store_name,
        sub_count: item?.sub_count,
        sub_num: item?.sub_num,
        ticket_num: item?.ticket_num,
        total_adopt: item?.total_adopt,
        unclaimed: item?.unclaimed,
        zip_code: item?.zip_code,
      })) || [],
    [vTmemberListData?.getVTMemberListWithOrder]
  );

  const listItems: ListItemsType = useMemo(
    () =>
      items.map((item, key) => ({
        eye: (
          <EyeIcon
            status={{ status: item?.status ?? MemberStatus.Unregistered }}
            url={`/s03/${item?.id ?? 0}`}
            hasAlert={item?.newly === Alert.YES ?? Alert.NO}
          />
        ),
        image: <img src={item.image || NoImgSvg} alt="" style={{ width: '40px', height: '40px' }} />,
        code: item?.code,
        store_name: item?.store_name,
        payment_method: setPpaymentMethod(item?.payment_method as PaymentMethod),
        zip_code: `〒${item?.zip_code?.slice(0, 3) ?? '000'}-${item?.zip_code?.slice(3, 7) ?? '0000'} ${
          item?.state ?? ''
        }${item?.city ?? ''}${item?.address ?? ''}${item?.address2 ?? ''}`,
        manager: item?.manager,
        phone: item?.phone,
        email:
          item.email !== '-' ? (
            <TextLink href={`mailto:${item?.email ?? ''}`}>{mailAddressSlice(item.email ?? '')}</TextLink>
          ) : (
            item.email
          ),
        score: item?.score,
        again: item?.again,
        belong_count: item?.belong_count,
        sub_count: item?.sub_count,
        other_adopt: item?.other_adopt,
        total_adopt: item?.total_adopt,
        opening: item?.opening,
        parking: item?.parking,
        block_count: item?.block_count,
        mco_name: item?.mco_name,
        area_name: item?.area_name,
        biz_name: item?.biz_name,
        brand_name: item?.brand_name,
        uniqueKey: key,
        backgroundColor: getBGColor(item?.status ?? MemberStatus.Unregistered),
      })),
    [items]
  );

  const listContents = () => (
    <ScrollWrapper bottom={345}>
      <List
        header={listHeader}
        items={listItems}
        onChangeSort={handleChangeSort}
        sort={sort}
        isSortable={currentStatus === String(AffiliationStatus.ACTIVE)}
      />
    </ScrollWrapper>
  );

  return (
    <>
      {(vTMemberListLoading ||
        mStateLoading ||
        mCityByStateILoading ||
        mBusinessLoading ||
        mBrandByBizIdLoading ||
        fileUploadLoading) && <Loading />}
      <Box display="flex" flex="1" flexDirection="column" gap={26}>
        <Box display="flex" height={32} justifyContent="space-between" alignItems="center">
          <Text variant="h1" color="darkBlue">
            加盟店情報
          </Text>
          <Box display="flex" gap={8}>
            <MainButton variant="secondary" onClick={() => setActiveModalType(ModalType.CsvOutput)}>
              決済会社用CSV出力
            </MainButton>
            <MainButton variant="secondary" onClick={() => handleClickBulkRegister()}>
              一括登録
            </MainButton>
            <MainButton icon="addCircle" onClick={() => setActiveModalType(ModalType.Registration)}>
              新規登録
            </MainButton>
          </Box>
        </Box>
        <Box display="flex" flex="auto">
          <Card overflow="hidden">
            <Box display="flex" pa={16} justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={16} alignItems="center" flexWrap="wrap">
                <Box display="flex" gap={16}>
                  <RhfInput
                    width={120}
                    control={control}
                    name="search_store_code_name"
                    defaultValue=""
                    placeholder="コード/店舗名"
                  />
                  <MainButton
                    variant="primary"
                    width={80}
                    icon="search"
                    onClick={() => {
                      setPage(1);
                      setSearchStoreCodeOrName(currentStoreCodeOrName);
                    }}
                  >
                    検索
                  </MainButton>
                  <Divider option="vertical" length={24} />
                  <RhfSelect
                    width={120}
                    control={control}
                    name="state"
                    options={mStateList}
                    defaultValue=""
                    placeholder="都道府県"
                    onChange={(stateId) => {
                      setPage(1);
                      setValue('city', '');
                      getMCityByStateId({
                        variables: { sid: Number(stateId) },
                      });
                    }}
                  />
                  <RhfSelect
                    width={120}
                    control={control}
                    name="city"
                    options={mCityList}
                    defaultValue=""
                    placeholder="市区町村"
                    onChange={() => {
                      setPage(1);
                    }}
                  />
                  <Divider option="vertical" length={24} />
                </Box>
                <Box display="flex" gap={16}>
                  <RhfSelect
                    width={120}
                    control={control}
                    name="biz_id"
                    options={mBusinessList}
                    defaultValue=""
                    placeholder="業種"
                    onChange={(bizId) => {
                      setPage(1);
                      setValue('brand_id', '');
                      getMBrandByBizId({
                        variables: { bid: Number(bizId) },
                      });
                    }}
                  />
                  <RhfSelect
                    width={160}
                    control={control}
                    name="brand_id"
                    options={mBrandByBizIdList}
                    defaultValue=""
                    placeholder="ブランド"
                    onChange={() => {
                      setPage(1);
                    }}
                  />
                  <RhfSelect
                    width={160}
                    control={control}
                    name="status"
                    options={MemberStatusList}
                    defaultValue=""
                    placeholder="状態"
                    onChange={() => {
                      setPage(1);
                    }}
                  />
                  <RhfSelect
                    width={160}
                    control={control}
                    name="payment_method"
                    options={PaymentMethodList}
                    defaultValue=""
                    placeholder="支払方法"
                    onChange={() => {
                      setPage(1);
                    }}
                  />
                  <MainButton
                    variant="clear"
                    width={44}
                    onClick={() => {
                      reset();
                      setSearchStoreCodeOrName('');
                    }}
                  >
                    クリア
                  </MainButton>
                </Box>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box flex="auto" flexDirection="column" px={10}>
              <PaginationBox
                dataSize={dataSize}
                limit={limit}
                page={page}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
              >
                {listContents()}
              </PaginationBox>
            </Box>
          </Card>
        </Box>
      </Box>
      <RegistrationModal
        isOpen={activeModalType === ModalType.Registration}
        onClose={() => setActiveModalType(ModalType.None)}
      />
      <BulkRegistrationModal
        isOpen={activeModalType === ModalType.BulkRegistration}
        onClose={() => setActiveModalType(ModalType.None)}
      />
      <CsvOutputModal
        isOpen={activeModalType === ModalType.CsvOutput}
        onClose={() => setActiveModalType(ModalType.None)}
      />
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} accept="text/csv" />
    </>
  );
}
