import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { STpmemType } from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { CHIP_TEXT, InputType, ModalStep, RADIUS_OPTIONS, RECRUIT_RATIO_OPTIONS, ListTitleWidth } from '../constants';
import { chipColor, leaderMenu } from '../s10-03-utils';
import { MiniModalViewDataTyep } from '../s10-03-types';
import { BasicModal } from '../modal/basic-modal';

/**
 * Figma ID: 10-03-01-01
 * 名称: 所属店舗日時優先表示
 */

export interface Props {
  cpRow: STpmemType[];
  setRecruitRatioModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  setRadiusModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
}

const makeListData = ({ cpRow, setRecruitRatioModalStep, setRadiusModalStep }: Props) =>
  cpRow.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              募集期間重なり%
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row.recruit_ratio}%
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setRecruitRatioModalStep(1)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <CampaignTableRow isDivider pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              有効半径
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row.radius}Km
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setRadiusModalStep(1)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

export function AffiliatedStorePriority() {
  const { openErrorModal } = useErrorModal();

  const [RecruitRatioViewData, setRecruitRatioViewData] = useState<MiniModalViewDataTyep>();

  const [RadiusViewData, setRadiusViewData] = useState<MiniModalViewDataTyep>();

  const { data: getData, loading, error, refetch } = gql.useGetSTpmemTypeByTypeId1Query();

  const [updateRecruitRatio, { error: recruitRatioError, loading: recruitRatioLoading }] =
    gql.useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation();

  const [updateRadius, { error: radiusError, loading: radiusLoading }] =
    gql.useUpdateSTpmemTypeRadiusByTypeId1Mutation();

  const [RecruitRatioModalStep, setRecruitRatioModalStep] = React.useState<ModalStep>(ModalStep.NULL);

  const [RadiusModalStep, setRadiusModalStep] = React.useState<ModalStep>(ModalStep.NULL);

  const handleRecruitRatioModalClose = useCallback(() => {
    refetch().then((e) => {
      if (e.loading) {
        setRecruitRatioModalStep(0);
      }
    });
  }, [refetch]);

  const handleRadiusModalClose = useCallback(() => {
    refetch().then((e) => {
      if (e.loading) {
        setRadiusModalStep(0);
      }
    });
  }, [refetch]);
  useEffect(() => {
    if (getData) {
      setRecruitRatioViewData({
        title: {
          input: '募集期間重なり%',
          check: '募集期間重なり%',
          complete: '募集期間重なり% 完了',
        },
        label: '重なり率',
        name: 'recruit_ratio',
        placeholder: '選択',
        inputType: InputType.Select,
        unit: '%',
        text: '率',
        options: RECRUIT_RATIO_OPTIONS,
        data: getData?.getSTpmemTypeByTypeId1.recruit_ratio,
      });
      setRadiusViewData({
        title: {
          input: '有効半径',
          check: '有効半径',
          complete: '有効半径 完了',
        },
        label: '半径',
        name: 'radius',
        placeholder: '選択',
        inputType: InputType.Select,
        unit: 'Km',
        text: '半径',
        options: RADIUS_OPTIONS,
        data: getData?.getSTpmemTypeByTypeId1.radius,
      });
    }
  }, [getData]);
  const listItems = useMemo(
    () =>
      makeListData({
        cpRow: getData?.getSTpmemTypeByTypeId1 ? [getData?.getSTpmemTypeByTypeId1] : [],
        setRecruitRatioModalStep,
        setRadiusModalStep,
      }),
    [getData]
  );
  const { displayModal } = BasicModal({
    modalStep: RecruitRatioModalStep,
    setModalStep: setRecruitRatioModalStep,
    onClose: handleRecruitRatioModalClose,
    modalViewData: RecruitRatioViewData,
    mutation: (data?: string | number | null) => {
      updateRecruitRatio({ variables: { recruit_ratio: data as number } })
        .then((e) => {
          if (!e.errors && !recruitRatioError) {
            setRecruitRatioModalStep(3);
            refetch();
          } else {
            openErrorModal({
              message: '募集期間重なりの更新に失敗しました。\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    },
  });
  const { displayModal: displayModal2 } = BasicModal({
    modalStep: RadiusModalStep,
    setModalStep: setRadiusModalStep,
    onClose: handleRadiusModalClose,
    modalViewData: RadiusViewData,
    mutation: (data?: string | number | null) => {
      updateRadius({ variables: { radius: data as number } })
        .then((e) => {
          if (!e.errors && !radiusError) {
            setRadiusModalStep(3);
            refetch();
          } else {
            openErrorModal({
              message: '有効半径の更新に失敗しました。\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    },
  });

  if (error) {
    refetch();
  }

  if (loading || recruitRatioLoading || radiusLoading) {
    return Loading();
  }
  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                所属店舗日時優先表示
              </Text>
              <Text color="darkBlue" variant="caption12">
                募集期間の重なり%と有効半径を設定できます
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <ScrollWrapper bottom={311}>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollWrapper>
        </Box>
      </HideWrapper>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={RecruitRatioModalStep !== 0}
        onClose={() => displayModal.onClose}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow="visible"
      />
      <Modal
        width={displayModal2.width}
        height={displayModal2.height}
        isOpen={RadiusModalStep !== 0}
        onClose={() => displayModal2.onClose}
        header={displayModal2.header}
        content={displayModal2.content}
        footer={displayModal2.footer}
        overflow="visible"
      />
    </>
  );
}
