/**
 * Figma ID: 07-01-03
 * 名称: 逆オファー（オファーした人詳細モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';

type BtmemDetailFooterModel = {
  onClickClose: () => void;
};
function BtmemDetailFooter(props: BtmemDetailFooterModel) {
  const { onClickClose } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end">
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        閉じる
      </MainButton>
    </Box>
  );
}

export default BtmemDetailFooter;
