  import React, { createContext, useState } from 'react';

  export type Notification = {
    im_inv: number;
    im_pay: number;
    mn_pay: number;
  }

  type NewlyNtificationContextProps = {
    newlyNotification: Notification
    setNewlyNotification: (newlyNotification: Notification) => void
  }

  const defaultContext: NewlyNtificationContextProps = {
    newlyNotification: {
      im_inv: 0,
      im_pay: 0,
      mn_pay: 0,
    },
    setNewlyNotification: () => {},
  };

  export const NewlyNotificationContext = createContext<NewlyNtificationContextProps>(defaultContext);

  export const useNewlyNotification = (): NewlyNtificationContextProps => {
    const [newlyNotification, setNewlyNotification] = useState<Notification>({
      im_inv: 0,
      im_pay: 0,
      mn_pay: 0,
    });

    return {
      newlyNotification,
      setNewlyNotification
    };
  };

  export function NewlyNotificationContextProvider(
    props: { children: React.ReactNode }
  ){
    const { children } = props;

    const value = useNewlyNotification();

    return (
      <NewlyNotificationContext.Provider value={value}>
        {children}
      </NewlyNotificationContext.Provider>
    );
  }