import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { useCallback, useMemo } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { splitString } from 'components/utils';
import { Modal } from 'components/newModal';
import * as gql from 'graphql/graphql-ow';
import { VtCpFreeList } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { chipColor, chipText, leaderMenu, parseBizBrandList, splitComponent } from '../s10-02-utils';
import { DeleteModal } from '../modal/delete-modal';
import { DeleteModalStep, NewUpdateModalStep, ListTitleWidth } from '../constants';
import { SelectPeriodBrand as useSelectPeriodBrand } from '../modal/modal10-02-01-02/select-period-brand';
import { MonthlyCampaignUpdateType } from '../s10-02-types';
/**
 * Figma ID: 10-02-01-01
 * 名称: 月会費無料キャンペーン
 */

export interface Props {
  cpRow: VtCpFreeList[];
  setDeleteModalStep: React.Dispatch<React.SetStateAction<DeleteModalStep>>;
  setDeleteId: React.Dispatch<React.SetStateAction<number>>;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setVTCpFreeList: React.Dispatch<React.SetStateAction<VtCpFreeList | undefined>>;
}

const FreeCampaign = (
  cpRow: VtCpFreeList[],
  setDeleteModalStep: React.Dispatch<React.SetStateAction<DeleteModalStep>>,
  setDeleteId: React.Dispatch<React.SetStateAction<number>>,
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>,
  setVTCpFreeList: React.Dispatch<React.SetStateAction<VtCpFreeList | undefined>>
) =>
  cpRow.map((row) => ({
    title: row.title,
    chip: {
      color: chipColor(row.status),
      text: chipText(row.status),
    },
    createdDate: row.created_date,
    leaderMenu: leaderMenu(row.status),
    onClickLeader: (val: string) => {
      if (val === 'delete') {
        setDeleteModalStep(DeleteModalStep.CHECK);
        setDeleteId(row.id || 0);
      } else {
        setVTCpFreeList(row);
        setModalStep(NewUpdateModalStep.INPUT);
      }
    },
    rows: (
      <CampaignTableBody key={row.id} pa={0} gap={4}>
        <CampaignTableRow isDivider pa={0} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              配布期間
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.begin_end}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        {splitComponent(row.biz_names ? splitString(row.biz_names, ',') : [], splitString(row.brand_names || '', ','))}
        <CampaignTableRow isDivider pa={0} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              無料継続期間
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.free_months_disp}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        <CampaignTableRow isDivider pa={0} gap={16} height={84}>
          <CampaignTableItem width={ListTitleWidth.WideWidth} height={84} dividerHeight={84} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              地域
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={84} dividerHeight={84} alignItems="center">
            <Text color="blueGray" variant="caption12">
              {row.state_names}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
      </CampaignTableBody>
    ),
  }));

function makeListData({ cpRow, setDeleteModalStep, setDeleteId, setModalStep, setVTCpFreeList }: Props) {
  const CampaignType = FreeCampaign(cpRow, setDeleteModalStep, setDeleteId, setModalStep, setVTCpFreeList);
  return CampaignType;
}

export function MonthlyCampaign() {
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);

  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const { data, loading, error, refetch } = gql.useGetVtCpFreeListQuery();
  const [mutation, { loading: loadingKind, error: CampaignKind, reset }] = gql.useAddSCampaignKind1Mutation();
  const { openErrorModal } = useErrorModal();

  const [deleteModalStep, setDeleteModalStep] = React.useState<DeleteModalStep>(DeleteModalStep.NULL);

  const [deleteId, setDeleteId] = React.useState<number>(0);

  const [vTCpFreeListData, setVTCpFreeList] = React.useState<VtCpFreeList>();

  const [modalStep, setModalStep] = React.useState<NewUpdateModalStep>(NewUpdateModalStep.NULL);

  const listItems = useMemo(
    () =>
      makeListData({
        cpRow: data?.getVTCpFreeList || [],
        setDeleteModalStep,
        setDeleteId,
        setModalStep,
        setVTCpFreeList,
      }),
    [data]
  );

  const onClose = useCallback(() => {
    refetch();
    setDeleteModalStep(DeleteModalStep.NULL);
  }, [refetch]);

  const { displayDeleteModal } = DeleteModal({
    deleteModalStep,
    setDeleteModalStep,
    deleteId,
    onClose,
  });

  const update = useCallback(
    ({ begin_date, brand_ids, end_date, free_months, state_ids, title, umgmt_id }: MonthlyCampaignUpdateType) => {
      mutation({
        variables: {
          begin_date,
          brand_ids,
          end_date,
          free_months,
          state_ids,
          title,
          umgmt_id,
        },
      })
        .then(() => {
          setModalStep(NewUpdateModalStep.COMPLETE);
          refetch();
        })
        .catch(() => {
          setModalStep(NewUpdateModalStep.NULL);
        });
    },
    [mutation, refetch]
  );

  if (error) {
    openErrorModal({ message: 'データ取得に失敗しました。' });
    reset();
  }

  if (CampaignKind) {
    openErrorModal({ message: 'データの作成に失敗しました。' });
    reset();
  }

  const defaultSelectPeriodBrandValues = useMemo(
    () =>
      vTCpFreeListData
        ? {
            title: `コピー${vTCpFreeListData.title || ''}`,
            brandIds: vTCpFreeListData.brand_ids || '',
            beginEnd: vTCpFreeListData.begin_end || '',
            beginDate: vTCpFreeListData.begin_date || '',
            endDate: vTCpFreeListData.end_date || '',
            freeMonths: vTCpFreeListData.free_months,
            stateNames: vTCpFreeListData.state_names || '',
            stateIds: vTCpFreeListData.state_ids || '',
            bizBrandList: parseBizBrandList(vTCpFreeListData.biz_ids || '', vTCpFreeListData.brand_ids || ''),
          }
        : undefined,
    [vTCpFreeListData]
  );

  const { displayModal } = useSelectPeriodBrand({
    modalStep,
    setModalStep,
    defaultValues: defaultSelectPeriodBrandValues,
    update,
  });

  if (loading || loadingKind) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                月会費無料キャンペーン
              </Text>
              <Text color="darkBlue" variant="caption12">
                加盟店入会時の月会費無料キャンペーン設定
              </Text>
            </Box>
            <div>
              <MainButton
                onClick={() => {
                  setModalStep(NewUpdateModalStep.INPUT);
                  setVTCpFreeList(undefined);
                }}
                variant="primary"
                width={160}
                icon="addCircle"
              >
                新規作成
              </MainButton>
            </div>
          </Box>
          <Divider option="horizontal" />
          <Box>
            <PaginationBox
              dataSize={listItems.length}
              limit={currentLimit}
              page={currentPage}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <Box>
                <ScrollWrapper bottom={327}>
                  {listItems ? (
                    <CampaignTable
                      listItems={listItems.slice(currentLimit * (currentPage - 1), currentLimit * currentPage)}
                      makeHeader
                      frame
                    />
                  ) : null}
                </ScrollWrapper>
              </Box>
            </PaginationBox>
          </Box>
        </Box>
      </HideWrapper>
      <Modal
        width={displayDeleteModal.width}
        height={displayDeleteModal.height}
        isOpen={deleteModalStep !== DeleteModalStep.NULL}
        onClose={() => displayDeleteModal.onClose}
        header={displayDeleteModal.header}
        content={displayDeleteModal.content}
        footer={displayDeleteModal.footer}
      />
      <Modal
        height={displayModal.height}
        width={displayModal.width}
        isOpen={modalStep !== NewUpdateModalStep.NULL}
        content={displayModal.content}
        header={displayModal.header}
        footer={displayModal.footer}
        onClose={() => displayModal.onClose}
      />
    </>
  );
}
