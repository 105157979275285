/**
 * Figma ID: 10-01-06-02
 * 名称: 管理アカウント（削除完了）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';

type Props = {
  isDeleteMe: boolean;
  onClickCloseAndLogout: () => void;
  onClickClose: () => void;
};

export function CompleteDeleteAccountModal({ isDeleteMe, onClickCloseAndLogout, onClickClose }: Props) {
  if (isDeleteMe) {
    return (
      <Modal
        isOpen
        header={
          <Box pl={5}>
            <Text color="darkBlue" variant="h2">
              削除 完了
            </Text>
          </Box>
        }
        content={
          <Box pa={8}>
            <Box>
              <Text variant="body14">アカウントの削除が完了しました。</Text>
            </Box>
            <Box>
              <Text variant="body14">本システムからログアウトします。</Text>
            </Box>
          </Box>
        }
        footer={
          <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
            <MainButton onClick={onClickCloseAndLogout} width={130} variant="secondary">
              閉じる
            </MainButton>
          </Box>
        }
        onClose={onClickCloseAndLogout}
        height="auto"
      />
    );
  }
  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            削除 完了
          </Text>
        </Box>
      }
      content={
        <Box pa={8}>
          <Text variant="body14">アカウントの削除が完了しました。</Text>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
          <MainButton onClick={onClickClose} width={130} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height="auto"
    />
  );
}
