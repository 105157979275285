import { Amplify, Auth } from 'aws-amplify';
import type { CognitoUser } from '@aws-amplify/auth';
import type { ChallengeName } from 'amazon-cognito-identity-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  useGetUMemberByEmailLazyQuery,
  useUpdateStatusByEmailMutation,
  useUpdateUMemberEmailByIdMutation,
  GetUMemberByEmailQuery,
  useUpdateUMemberTfa1ByIdMutation,
  useUpdateUMemberTfa0ByIdMutation,
} from 'graphql/graphql-ow';
import AwsConfigAuth from '../aws-config/auth';

Amplify.configure({ Auth: AwsConfigAuth });

type ProvideAuthProps = {
  children: React.ReactNode;
};

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  userInfo: {
    id: number;
    cognitoId: string;
    email: string;
    tfa: number;
    privilege: number;
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    status: number;
  } | null;
  signIn: (email: string, password: string) => Promise<ChallengeName | undefined>;
  signInWithMfaToken: (token: string, email: string) => Promise<void>;
  signUp: (email: string, _password: string) => Promise<void>;
  confirmSignUp: (email: string, verificationCode: string) => Promise<void>;
  signOut: () => Promise<void>;
  resendConfirmationCode: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  confirmUpdateEmail: (confirmationCode: string, email: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  checkMfaActivated: () => Promise<boolean>;
  generateTotpToken: () => Promise<string>;
  activateMfa: (code: string) => Promise<void>;
  releaseMfa: () => Promise<void>;
  sendEmailToResetPassword: (email: string) => Promise<void>;
  resetPassword: (email: string, confirmationCode: string, password: string) => Promise<void>;
}

type ExtendCognitoUserType = CognitoUser & {
  attributes: {
    email: string;
    email_verified: boolean;
  };
};

const authContext = createContext({} as UseAuth);

export const useAuth = () => useContext(authContext);

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<{
    id: number;
    cognitoId: string;
    email: string;
    tfa: number;
    privilege: number;
    firstName: string;
    lastName: string;
    firstKana: string;
    lastKana: string;
    status: number;
  } | null>(null);
  // 2段階認証の場合に、一時的にcognitoUserを保持する。それ以外では使わない
  const [cognitoUser, setCognitoUser] = useState<ExtendCognitoUserType | null>(null);

  const [getUMemberByEmailLazyQuery] = useGetUMemberByEmailLazyQuery({ fetchPolicy: 'network-only' });
  const [updateStatusByEmailMutation] = useUpdateStatusByEmailMutation();
  const [updateUMemberEmailByIdMutation] = useUpdateUMemberEmailByIdMutation();
  const [activateUMemberTfa] = useUpdateUMemberTfa1ByIdMutation();
  const [releaseUMemberTfa] = useUpdateUMemberTfa0ByIdMutation();

  const fetchUserInfo = async (email: string): Promise<NonNullable<GetUMemberByEmailQuery['getUMemberByEmail']>> => {
    const { data: { getUMemberByEmail } = {}, error } = await getUMemberByEmailLazyQuery({
      context: { clientName: 'api_key' },
      variables: { email },
    });
    if (error) {
      throw error;
    }
    if (getUMemberByEmail !== null && getUMemberByEmail !== undefined) {
      return getUMemberByEmail;
    }
    throw new Error('ユーザー情報の取得に失敗しました');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (result: ExtendCognitoUserType) => {
        const { email } = result.attributes;
        const getUMemberByEmail = await fetchUserInfo(email);
        setUserInfo({
          id: getUMemberByEmail.id,
          cognitoId: getUMemberByEmail.cognito_id ?? '',
          email: getUMemberByEmail.email ?? '',
          tfa: getUMemberByEmail.tfa ?? 0,
          privilege: getUMemberByEmail.privilege ?? 2,
          firstName: getUMemberByEmail.first_name ?? '',
          lastName: getUMemberByEmail.last_name ?? '',
          firstKana: getUMemberByEmail.first_kana ?? '',
          lastKana: getUMemberByEmail.last_kana ?? '',
          status: getUMemberByEmail.status,
        });
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      // 未ログインの場合はエラーがthrowされる
      .catch(() => {
        setUserInfo(null);
        setIsAuthenticated(false);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // challengeNameがundefinedの場合はそのまま認証終わり
  // challengeName === SOFTWARE_TOKEN_MFA なら二段階認証が必要なのでログイン済みにさせない
  const signIn = async (email: string, password: string): Promise<ChallengeName | undefined> => {
    const result = (await Auth.signIn(email, password)) as ExtendCognitoUserType;
    const { challengeName } = result;

    if (challengeName) {
      setCognitoUser(result);
      return challengeName;
    }

    const getUMemberByEmail = await fetchUserInfo(email);
    setUserInfo({
      id: getUMemberByEmail.id,
      cognitoId: getUMemberByEmail.cognito_id ?? '',
      email: getUMemberByEmail.email ?? '',
      tfa: getUMemberByEmail.tfa ?? 0,
      privilege: getUMemberByEmail.privilege ?? 2,
      firstName: getUMemberByEmail.first_name ?? '',
      lastName: getUMemberByEmail.last_name ?? '',
      firstKana: getUMemberByEmail.first_kana ?? '',
      lastKana: getUMemberByEmail.last_kana ?? '',
      status: getUMemberByEmail.status,
    });
    setIsAuthenticated(true);
    return undefined;
  };

  const signOut = async () => {
    await Auth.signOut();
    setUserInfo(null);
    setIsAuthenticated(false);
  };

  // Cognito上にユーザー登録を行い、メールアドレス宛に確認コードを送信する
  // DB上のデータと帳尻を合わせるために、DB上に存在するかチェックを行う
  const signUp = async (email: string, _password: string) => {
    const getUMemberByEmail = await fetchUserInfo(email);
    if (!getUMemberByEmail) {
      throw new Error('ユーザー情報の取得に失敗しました');
    }
    await Auth.signUp(email, _password);
  };

  // 確認コードを入力し、Cognito上の確認ステータスを確認済みにする
  // Auth.confirmSignUpが成功した場合'SUCCESS'という文字列が返ってくる
  const confirmSignUp = async (email: string, verificationCode: string) => {
    await Auth.confirmSignUp(email, verificationCode);
    await updateStatusByEmailMutation({ context: { clientName: 'api_key' }, variables: { email, status: 1 } });
  };

  // Cognitoのユーザー作成時の確認コードを再送する
  const resendConfirmationCode = async (email: string) => {
    await Auth.resendSignUp(email);
  };

  // Cognito上のメールアドレスの更新のリクエストをかけ、メールアドレス宛に確認コードを送信する
  const updateEmail = async (email: string): Promise<void> => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    await Auth.updateUserAttributes(currentUser, { email });
  };

  // 確認コードをCognitoに送信し、DB上のメールアドレスも変更し更新を完了する
  const confirmUpdateEmail = async (confirmationCode: string, newEmail: string): Promise<void> => {
    await Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode);
    (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    if (userInfo) {
      await updateUMemberEmailByIdMutation({ variables: { id: userInfo.id, email: newEmail } });
    }
  };

  // Cognito上のパスワードを変更する
  const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    await Auth.changePassword(currentUser, oldPassword, newPassword);
  };

  // パスワード再設定用認証コードの送信
  const sendEmailToResetPassword = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  // パスワード再設定
  const resetPassword = async (email: string, confirmationCode: string, password: string) => {
    await Auth.forgotPasswordSubmit(email, confirmationCode, password);
  };

  // 多重認証用ログイン
  // confirmSignInの返り値はCognitoUserTypeだが、attributesプロパティがないためemailが取得できない
  // そのためページ上で一時保存しておいたemailを使用する
  const signInWithMfaToken = async (token: string, email: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    await Auth.confirmSignIn(cognitoUser, token, 'SOFTWARE_TOKEN_MFA');
    const getUMemberByEmail = await fetchUserInfo(email);
    setUserInfo({
      id: getUMemberByEmail.id,
      cognitoId: getUMemberByEmail.cognito_id ?? '',
      email: getUMemberByEmail.email ?? '',
      tfa: getUMemberByEmail.tfa ?? 0,
      privilege: getUMemberByEmail.privilege ?? 2,
      firstName: getUMemberByEmail.first_name ?? '',
      lastName: getUMemberByEmail.last_name ?? '',
      firstKana: getUMemberByEmail.first_kana ?? '',
      lastKana: getUMemberByEmail.last_kana ?? '',
      status: getUMemberByEmail.status,
    });
    setCognitoUser(null);
    setIsAuthenticated(true);
  };

  const checkMfaActivated = async (): Promise<boolean> => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    const state = await Auth.getPreferredMFA(currentUser, { bypassCache: false });

    return state === 'SOFTWARE_TOKEN_MFA';
  };

  // TOTP認証の為のトークン生成
  const generateTotpToken = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const currentUser = await Auth.currentAuthenticatedUser();
    const token = await Auth.setupTOTP(currentUser);
    return token;
  };

  // TOTPトークンを使ってMFAを有効化する
  const activateMfa = async (code: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(currentUser, code);
    Auth.setPreferredMFA(currentUser, 'TOTP');
    if (userInfo) {
      await activateUMemberTfa({ variables: { id: userInfo.id } });
    }
  };

  // ２段階認証の解除
  const releaseMfa = async () => {
    const currentUser = (await Auth.currentAuthenticatedUser()) as ExtendCognitoUserType;
    await Auth.setPreferredMFA(currentUser, 'NOMFA');
    if (userInfo) {
      await releaseUMemberTfa({ variables: { id: userInfo.id } });
    }
  };

  return {
    isLoading,
    isAuthenticated,
    userInfo,
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    updateEmail,
    confirmUpdateEmail,
    changePassword,
    signIn,
    signInWithMfaToken,
    signOut,
    checkMfaActivated,
    generateTotpToken,
    activateMfa,
    releaseMfa,
    sendEmailToResetPassword,
    resetPassword,
  };
};

export function AuthProvider(props: ProvideAuthProps) {
  const { children } = props;
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
