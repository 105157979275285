import { saveAs } from 'file-saver';

// 労働条件通知書PDF
export { default as createNoticeOfEmploymentPdf } from './noticeOfEmployment';
export type { NoticeOfEmploymentPdfProps } from './noticeOfEmployment';

// 源泉徴収票PDF
export { default as createWithholdingSlipPdf } from './withholdingSlip';
export type { WsPdfProps } from './withholdingSlip';

// ダウンロード処理
export const download = (buffer: Buffer | null, fileName: string) => {
  if (!buffer) return;
  // ダウンロード処理
  saveAs(new Blob([buffer], { type: 'application/pdf' }), fileName);
};

// プレビュー処理
export const preview = (buffer: Buffer | null) => {
  if (!buffer) return;
  const blob = new Blob([buffer], { type: 'application/pdf' });
  const fileUrl = URL.createObjectURL(blob);
  // 別タブでPDFを表示
  window.open(fileUrl);
};

// URLからPDFダウンロード
export const downloadFromUrl = (pdfName: string, pdfUrl: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', pdfUrl);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    saveAs(xhr.responseURL, pdfName);
  };
  xhr.send();
};
