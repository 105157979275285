export const COLORS_NAME = {
  Red: 'red',
  White: 'white',
};

export const BIZ_NAME = {
  Convenience: 'コンビニ',
  Cafe: 'カフェ',
};

export const StarsOptionName = {
  AllStars: '全ての星',
  FiveStars: '星5つ',
  FourStars: '星4つ',
  ThreeStars: '星3つ',
  TwoStars: '星2つ',
  OneStar: '星1つ',
};

export const MenuOption = [
  { label: 'コメント削除', value: 'delete' },
  { label: '該当募集に移動', value: 'move' },
];

export enum ModalStep {
  NULL = 0,
  DELETE = 1,
  COMPLETE = 2,
}

export enum StatusChangeModalStep {
  NULL = 0,
  ACTIVE = 1,
  INACTIVE = 4,
  COMPLETE = 3,
}

export enum PageNumber {
  initialValue = 1,
}

export enum EditProfileModalStep {
  NULL = 0,
  EDIT = 1,
  SAVE = 2,
  COMPLETE = 3,
}

export type SelectProfileDataKey = {
  first_name: string;
  last_name: string;
  first_kana: string;
  last_kana: string;
  gender: string;
  dob: string;
  id: string;
};

export type BtmemEditProfileType = {
  dob: string;
  first_kana: string;
  first_name: string;
  gender: string;
  last_kana: string;
  last_name: string;
  id: string;
};
