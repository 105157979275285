import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';

export interface Props {
  items: {
    label: string;
    labelBold?: boolean;
    content: ReactNode;
    align?: string;
    pt?: number;
    isShow?: boolean;
  }[];
}

const StyledWrapper = styled.div<{
  align?: string;
}>`
  width: 100%;
`;

const StyledRow = styled.div<{
  align?: string;
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  padding: 8px 0px;
  border-bottom: #dbdbdb 1px solid;
`;

const StyledLabel = styled.div<{
  pt?: number;
}>`
  width: 168px;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : 'auto')};
  line-height: 28px;
`;

const StyledContent = styled.div`
  width: 100%;
  flex: auto;
  line-height: 28px;
`;

export function Info({ items }: Props) {
  return (
    <StyledWrapper>
      {items.map(
        (item) =>
          item.isShow !== false && (
            <StyledRow key={item.label} align={item.align}>
              <StyledLabel pt={item.pt}>
                <Text variant="body14" color="darkGray" bold={item.labelBold} wordBreak="break-all">
                  {item.label}
                </Text>
              </StyledLabel>
              <StyledContent>
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text variant="body14">{item.content}</Text>
                ) : (
                  item.content
                )}
              </StyledContent>
            </StyledRow>
          )
      )}
    </StyledWrapper>
  );
}
