/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Input, Props as InputProps } from 'components/input/input';

// 入力項目が動的で配列の為、rhfInputでは赤枠の表示ができない。KEYをチェックしてエラーがあるか返す
// rhfInputを修正するにはリスクが大きい為

// エラーのKeyを再帰してエラーが存在するかチェック
const checkNestedError = (errorObj: Record<string, any>, path: string): boolean => {
  // Keyを取得
  const pathArray = path.split('.');

  let currentError: Record<string, any> | undefined = errorObj;
  pathArray.forEach((key) => {
    if (currentError && key in currentError) {
      currentError = currentError[key];
    } else {
      currentError = undefined;
    }
  });
  return !!currentError;
};

// rhfInputコピー
export type AddInputProps<T extends FieldValues> = Omit<InputProps, 'name' | 'value' | 'onChange'> &
  UseControllerProps<T>;

export function AddInput<T extends FieldValues>(props: AddInputProps<T>) {
  const { name, control, rules, defaultValue, ...otherProps } = props;
  const {
    field: { ref, ...rest },
    formState: { errors },
  } = useController<T>({ name, control, rules, defaultValue });
  // エラーチェック
  const error = checkNestedError(errors, name);

  return <Input {...rest} {...otherProps} error={!!error} />;
}
