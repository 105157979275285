/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Select, Props as SelectProps } from '../select/select';

// React Hook Form では name を必須にしたいため、optionalなnameプロパティを外す
// value と onChange は useController で自動的に付与されるので、こちらも外す
export type RhfSelectProps<T extends FieldValues> = Omit<SelectProps, 'name' | 'value'> & UseControllerProps<T>;

export function RhfSelect<T extends FieldValues>(props: RhfSelectProps<T>) {
  const { name, control, rules, defaultValue, onChange: customOnChange, ...otherProps } = props;
  const {
    field: { ref, onChange, ...rest },
    formState: { errors },
  } = useController<T>({ name, control, rules, defaultValue });

  return (
    <Select
      {...rest}
      {...otherProps}
      onChange={(value: string, label: string) => {
        onChange(value, label);
        customOnChange?.(value, label);
      }}
      error={!!errors[name]}
    />
  );
}
