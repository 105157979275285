import React, { useState, useEffect, useMemo } from 'react';
import { Text } from 'components/text';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import {
  Control,
  useWatch,
  UseFormGetValues,
  UseFormSetValue,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormClearErrors,
} from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { RhfInput } from 'components/rhfInput';
import { RhfTextarea } from 'components/rhfTextarea';
import { MainButton } from 'components/mainButton';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { ErrorMessage } from 'components/errorMessage';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import { useErrorModal } from 'components/error/errorModalProvider';

import { CreateNotifyInfoModalForm, ModalRet, SelectOptionsType } from '../../type';

// 通知配信モーダルタイプ
export enum DeliveryModalType {
  New = 'New',
  Edit = 'Edit',
}

/**
 * 通知配信モーダル
 */
export function NotifyDeliveryModal(
  isOpen: boolean,
  modalType: DeliveryModalType,
  control: Control<CreateNotifyInfoModalForm>,
  handleSubmit: UseFormHandleSubmit<CreateNotifyInfoModalForm>,
  trigger: UseFormTrigger<CreateNotifyInfoModalForm>,
  clearErrors: UseFormClearErrors<CreateNotifyInfoModalForm>,
  getValues: UseFormGetValues<CreateNotifyInfoModalForm>,
  setValue: UseFormSetValue<CreateNotifyInfoModalForm>,
  onClose: () => void,
  errors: FieldErrors<CreateNotifyInfoModalForm>,
  onDelivery: () => void,
  onSaveDraft: () => void,
  mutationLoading: boolean
): ModalRet {
  const { openErrorModal } = useErrorModal();

  // Lazyを使用
  const [getMBrand, { loading: mBrandLoading }] = gql.useGetMBrandLazyQuery({ context: { clientName: 'master' } });
  // ブランド情報をState管理
  const [brandData, setBrandData] = useState<gql.MBrand[] | undefined>(undefined);

  const deliveryDestReload = useWatch({ control, name: 'deliveryDest' });

  const deliveryDest = getValues('deliveryDest');

  const [getMBusiness, { data: mBusinessData, loading: mBusinessLoading, error: mBusinessError }] =
    gql.useGetMBusinessLazyQuery({
      context: { clientName: 'master' },
    });
  const mBusiness = mBusinessData?.getMBusiness;
  React.useEffect(() => {
    if (mBusiness === undefined) {
      getMBusiness();
    }
  }, [mBusiness, getMBusiness]);

  React.useEffect(() => {
    if (mBusinessError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mBusinessError, openErrorModal]);

  const deliveryDestOptions: SelectOptionsType = useMemo(() => {
    if (mBusiness === undefined || mBusiness === null) return [];
    if (mBusiness.length === 0) return [];
    const businessNameList = Array.from(new Set(mBusiness.map((item) => item?.name)));

    const MemberStoreAllList: { label: string; value: string }[] = [{ label: '加盟店すべて', value: '加盟店すべて' }];
    const BitravelerAllList: { label: string; value: string }[] = [
      { label: 'バイトラベラーすべて', value: 'バイトラベラーすべて' },
    ];
    const businessList = businessNameList.map((item) => ({
      label: `加盟店（${item ?? ''}）`,
      value: `加盟店（${item ?? ''}）`,
    }));

    const businessListOptions = MemberStoreAllList.concat(businessList).concat(BitravelerAllList);

    return businessListOptions;
  }, [mBusiness]);

  useEffect(() => {
    if (isOpen) {
      // 配信先が'加盟店すべて'、'バイトラベラーすべて'以外の場合はブランド情報を取得する
      getMBrand()
        .then((data) => {
          if (data.error) {
            throw new Error(data.error.message);
          }
          setBrandData(data.data?.getMBrand as gql.MBrand[]);
        })
        .catch((e) => {
          if (e instanceof Error) {
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          }
        });
    }
  }, [getMBrand, openErrorModal, onClose, isOpen]);

  const businessTypeList = React.useMemo(() => {
    if (mBusiness === undefined || mBusiness === null) return [];
    if (deliveryDest === undefined || deliveryDest === null || deliveryDest === '') return [];

    const businessType = Array.from(
      new Set(
        mBusiness.map((item) => {
          if (item?.name === undefined) return { id: 0, name: '' };
          if (item?.id === undefined) return { id: 0, name: '' };
          return { id: item.id, name: `加盟店（${item.name}）` };
        })
      )
    );
    const MemberStoreAllType: { id: number; name: string }[] = [{ id: 0, name: '加盟店すべて' }];
    const BitravelerAllType: { id: number; name: string }[] = [
      { id: mBusiness.length + 1, name: 'バイトラベラーすべて' },
    ];
    const businessTypeInfoList = MemberStoreAllType.concat(businessType).concat(BitravelerAllType);
    const bizId = businessTypeInfoList.filter((item) => item.name === deliveryDest)[0].id;
    setValue('business', String(bizId));
    return (
      brandData
        ?.filter((item) => item.biz_id === bizId)
        .map((item) => ({
          value: String(item.id),
          label: item.name,
        })) || []
    );
  }, [mBusiness, deliveryDest, brandData, setValue]);

  const getBrandItem = () => businessTypeList;

  // 配信ボタンクリック時
  const onClickDelivery = handleSubmit((form) => {
    if (form) {
      onDelivery();
    }
  });

  // 下書き保存ボタンクリック時
  const onClickSaveDraft = () => {
    (async () => {
      // エラーをクリア
      clearErrors();

      let result = true;

      // 配信先が'加盟店すべて'または’バイトラベラーすべて'以外の場合はブランド必須チェック
      if (deliveryDestReload !== '加盟店すべて' && deliveryDestReload !== 'バイトラベラーすべて') {
        result = await trigger('brand');
        // エラーがある場合は処理を中断
        if (!result) return;
      }

      // 下書き保存時はタイトル必須チェック
      result = await trigger('title');
      // エラーがある場合は処理を中断
      if (!result) return;

      // 下書き保存処理実行
      onSaveDraft();
    })();
  };

  const modalContent = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          {modalType === DeliveryModalType.New && '新規作成'}
          {modalType === DeliveryModalType.Edit && '編集'}
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Box display="flex" flexDirection="column" flex="1" gap={19.5}>
        <Box gap={5.5}>
          <Text variant="h3" color="blueGray">
            配信先
          </Text>
          <RhfSelect
            width={752}
            control={control}
            name="deliveryDest"
            options={deliveryDestOptions}
            defaultValue={getValues('deliveryDest') || ''}
            placeholder="配信先を選択"
            rules={{ required: '配信先を選択してください' }}
          />
          {errors?.deliveryDest && (
            <Box pt={4}>
              <ErrorMessage>{errors?.deliveryDest.message}</ErrorMessage>
            </Box>
          )}
        </Box>
        {deliveryDestReload !== '加盟店すべて' && deliveryDestReload !== 'バイトラベラーすべて' && (
          <Box gap={5.5}>
            <RhfRadioGroup
              control={control}
              name="brand"
              options={getBrandItem()}
              rules={{
                required: 'ブランドを選択してください',
                validate: (value) => {
                  const items = getBrandItem();
                  // 配信先配下のブランドが選択されていない場合はエラー
                  const brandItem = items.find((item) => item.value === value);
                  return !brandItem ? 'ブランドを選択してください' : true;
                },
              }}
            />
            {errors?.brand && (
              <Box pt={4}>
                <ErrorMessage>{errors?.brand.message}</ErrorMessage>
              </Box>
            )}
          </Box>
        )}
        <Box gap={5.5}>
          <Text variant="h3" color="blueGray">
            件名
          </Text>
          <RhfInput
            fullWidth
            control={control}
            name="title"
            placeholder="件名"
            rules={{ required: '件名を入力してください' }}
          />
          {errors?.title && (
            <Box pt={4}>
              <ErrorMessage>{errors?.title.message}</ErrorMessage>
            </Box>
          )}
        </Box>
        <Box gap={5.5}>
          <Text variant="h3" color="blueGray">
            本文
          </Text>
          <RhfTextarea
            name="body"
            control={control}
            fullWidth
            height={180}
            rules={{ required: '本文を入力してください' }}
          />
          {errors?.body && (
            <Box pt={4}>
              <ErrorMessage>{errors?.body.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {(mBrandLoading || mutationLoading || mBusinessLoading) && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          variant="secondary"
          thin
          onClick={() => {
            onClickSaveDraft();
          }}
        >
          下書き保存
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={(event) => {
            onClickDelivery(event);
          }}
        >
          配信
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 'auto',
  };

  return modalContent;
}
