import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { BaseInfoEditKey, ModalRet } from 'pages/s03/type';
import React from 'react';
import { Control, UseFormGetValues, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { ErrorMessage } from 'components/errorMessage';

const ConfirmText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #484848;
`;

type CustomListItemsType = {
  label: string;
  content: React.ReactNode;
  optional?: boolean | undefined;
}[];

function CustomList(props: { items: CustomListItemsType }) {
  const { items } = props;

  const listItems = items.map(({ label, content, optional = false }) => (
    <Box display="flex" flexDirection="column" gap={5.5}>
      <InputLabel optional={optional}>{label}</InputLabel>
      {typeof content === 'string' ? <ConfirmText>{content}</ConfirmText> : <div>{content}</div>}
    </Box>
  ));

  return (
    <Box display="flex" flexDirection="column" rowGap={19.5}>
      {listItems}
    </Box>
  );
}

export function EditCorporationInfoModal(
  control: Control<BaseInfoEditKey, any>,
  getValues: UseFormGetValues<BaseInfoEditKey>,
  handleSubmit: UseFormHandleSubmit<BaseInfoEditKey>,
  errors: FieldErrors<BaseInfoEditKey>,
  onClose: () => void,
  onNext: () => void,
  onBack: () => void
): ModalRet {
  const onClickHandler = handleSubmit((data) => {
    if (data) {
      onNext();
    }
  });

  const customListItems: CustomListItemsType = [
    { label: '事業者名', content: getValues('mco_name') },
    {
      label: '郵便番号',
      content: `${getValues('mco_zip_code')?.slice(0, 3) ?? ''}-${getValues('mco_zip_code')?.slice(3, 7) ?? ''}`,
    },
    {
      label: '住所',
      content: `${getValues('mco_state') ?? ''}${getValues('mco_city') ?? ''} ${getValues('mco_address') ?? ''} ${
        getValues('mco_address2') ?? ''
      }`,
    },
    {
      label: '法人番号',
      content: getValues('corporate_no') ?? '',
    },
    {
      label: '代表者氏名',
      content: (
        <Box display="flex" flexDirection="row" gap={8}>
          <div>
            <RhfInput
              name="mco_m_last_name"
              control={control}
              width={120}
              defaultValue={getValues('mco_m_last_name') ?? ''}
              placeholder="名字"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_m_last_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_m_last_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mco_m_first_name"
              control={control}
              width={120}
              defaultValue={getValues('mco_m_first_name') ?? ''}
              placeholder="名前"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_m_first_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_m_first_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '代表者氏名（フリガナ）',
      content: (
        <Box display="flex" flexDirection="row" gap={8}>
          <div>
            <RhfInput
              name="mco_m_last_kana"
              control={control}
              width={120}
              defaultValue={getValues('mco_m_last_kana') ?? ''}
              placeholder="名字(カナ)"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_m_last_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_m_last_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mco_m_first_kana"
              control={control}
              width={120}
              defaultValue={getValues('mco_m_first_kana') ?? ''}
              placeholder="名前(カナ)"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_m_first_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_m_first_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '担当者氏名',
      content: (
        <Box display="flex" flexDirection="row" gap={8}>
          <div>
            <RhfInput
              name="mco_t_last_name"
              control={control}
              width={120}
              defaultValue={getValues('mco_t_last_name') ?? ''}
              placeholder="名字"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_t_last_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_t_last_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mco_t_first_name"
              control={control}
              width={120}
              defaultValue={getValues('mco_t_first_name') ?? ''}
              placeholder="名前"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_t_first_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_t_first_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '担当者氏名（フリガナ）',
      content: (
        <Box display="flex" flexDirection="row" gap={8}>
          <div>
            <RhfInput
              name="mco_t_last_kana"
              control={control}
              width={120}
              defaultValue={getValues('mco_t_last_kana') ?? ''}
              placeholder="名前(カナ)"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_t_last_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_t_last_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mco_t_first_kana"
              control={control}
              width={120}
              defaultValue={getValues('mco_t_first_kana') ?? ''}
              placeholder="名字(カナ)"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mco_t_first_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mco_t_first_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '担当者電話番号',
      content: (
        <Box>
          <RhfInput
            name="mco_t_phone"
            control={control}
            width={328}
            placeholder=""
            defaultValue={getValues('mco_t_phone')}
            rules={{ pattern: { value: /^\d*$/g, message: '数字のみ入力してください' } }}
          />
          {errors?.mco_t_phone && (
            <Box pt={4}>
              <ErrorMessage>{errors?.mco_t_phone.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      ),
      optional: true,
    },
  ];

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        基本情報編集 - 事業者情報
      </Text>
    ),
    content: (
      <Box display="flex" flexDirection="row" height="auto" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={24}>
          <Text variant="body14">事業者情報を入力して次へを押してください。</Text>
          <Box display="flex" flex="1" flexDirection="column">
            <CustomList items={customListItems} />
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            onClickHandler();
          }}
        >
          次へ
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 558,
  };

  return {
    ...modalContent,
  };
}
