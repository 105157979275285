import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { useMemo, ReactNode, useContext } from 'react';
import { List } from 'components/list';

import { Chip } from 'components/chip';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import * as gql from 'graphql/graphql-ow';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { Loading } from 'components/loading/loading';
import { TextLink } from 'components/textLink';
import { BitravelMemberModal } from 'modal/bitravelMemberModal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useMultipleCheck } from '../components/useMultipleCheck';
import { ChildCheckbox } from '../components/multipleCheck';
import { ListHeaderType, ListItemsType } from '../type';
import { PaymentList } from '../const';
import { NewlyNotificationContext } from '../components/newlyNotificationContext';

/**
 * Figma ID: 08-01-01
 * 名称: バイト費用支払-即日支払請求
 */

const enum ModalType {
  None = 'none',
  CreatePaymentFile = 'createPaymentFile',
}

type StatusDepenentItemType = {
  checkbox: ReactNode;
  status: ReactNode;
  output_date: string;
  request_no: ReactNode;
  backgroundColor: 'white' | 'red' | undefined;
};

export function ImInvoice(props: {
  setMenuIndex: (num: number) => void;
  transitionPayment: (outputNumber: string) => void;
}) {
  const { setMenuIndex, transitionPayment } = props;
  const { setNewlyNotification } = useContext(NewlyNotificationContext);

  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const [getupdVbiPaymentListNewlyPayArbeitMutation, { data, loading, called, error: paymentListNewlyPayArbeitError }] =
    gql.useGetupdVbiPaymentListNewlyPayArbeitMutation({
      variables: {},
    });

  const {
    getupdVBIPaymentListNewlyPayArbeit: { newly, payment },
  } = data || {
    getupdVBIPaymentListNewlyPayArbeit: {
      newly: {} as gql.VuNewlyPayArbeit,
      payment: [] as gql.VbiPaymentList[],
    },
  };

  // バイトラベル会員情報
  const [vbMemberBase, setVbMemberBase] = React.useState<gql.VbMemberBase | null>(null);
  // バイトラベル会員情報取得
  const [getVbMemberBaseById] = gql.useGetVbMemberBaseByIdLazyQuery();
  // エラーモーダル
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (paymentListNewlyPayArbeitError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [paymentListNewlyPayArbeitError, openErrorModal]);

  React.useEffect(() => {
    if (!called) {
      getupdVbiPaymentListNewlyPayArbeitMutation();
    }
  }, [called, getupdVbiPaymentListNewlyPayArbeitMutation]);

  const { checked, toggleChecked, isChecked } = useMultipleCheck<number | null | undefined>(
    payment.filter((item) => item.status === PaymentList.NOT_CREATED).map(({ request_no }) => request_no)
  );

  if (Object.keys(newly).length !== 0) {
    setNewlyNotification(newly);
  }

  // バイトラベル会員モーダル表示
  const showBitravelMemberModal = React.useCallback(
    (btmem_id: number) => {
      (async () => {
        const result = await getVbMemberBaseById({ variables: { id: btmem_id } });
        const vBMemberBase = result?.data?.getVBMemberBaseById;
        // バイトラベル会員の情報を設定してモーダルを表示する
        if (!vBMemberBase) {
          openErrorModal({ message: 'バイトラベル会員情報の取得に失敗しました。' });
          return;
        }
        // バイトラベル会員情報を設定してモーダルを表示する
        setVbMemberBase(vBMemberBase);
      })();
    },
    [getVbMemberBaseById, openErrorModal]
  );

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'req_number', width: 136, columnName: '請求No.' },
      { key: 'name', width: 130, columnName: '氏名' },
      { key: 'req_date', width: 140, columnName: '請求日時' },
      { key: 'price', width: 128, columnName: '請求金額' },
      { key: 'output_date', width: 140, columnName: '処理日時' },
    ],
    []
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading) {
      return [];
    }

    let items = payment;

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else if (sort.key === 'price') {
        // 数値変換
        items = orderBy(items, (item) => parseInt(item.price?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => {
      const statusDepenentItem: StatusDepenentItemType =
        item.status === PaymentList.NOT_CREATED
          ? {
              checkbox: (
                <ChildCheckbox<gql.VbiPaymentList>
                  tgt={item}
                  isChecked={() => isChecked(item.request_no)}
                  toggleChecked={() => toggleChecked(item.request_no)}
                />
              ),
              status: <Chip color="redLite">未作成</Chip>,
              output_date: '-',
              request_no: '-',
              backgroundColor: 'red',
            }
          : {
              checkbox: <div style={{ width: '24px', height: '24px' }} />,
              status: <Chip color="okBlue">作成済</Chip>,
              output_date: item.output_date || '-',
              request_no: (
                <TextLink onClick={() => transitionPayment(item.output_number || '')}>{item.output_number}</TextLink>
              ),
              backgroundColor: 'white',
            };

      return {
        req_number: item.req_number,
        name: (
          <TextLink variant="caption12" lineClamp={1} onClick={() => showBitravelMemberModal(item.btmem_id)}>
            {item.name}
          </TextLink>
        ),
        req_date: item.req_date,
        price: `¥${item.price}`,
        uniqueKey: key,
        ...statusDepenentItem,
      };
    });
  }, [isChecked, loading, payment, sort, toggleChecked, showBitravelMemberModal, transitionPayment]);

  React.useEffect(() => {
    setMenuIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 32px)">
        <Box display="flex" flexDirection="row" height={60} px={16} alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={8} alignItems="center">
            <Text variant="h2" color="darkBlue">
              即日支払
            </Text>
            <Text variant="caption12" color="darkBlue">
              バイトラベラーからの即日支払請求の処理済一覧です。
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="auto" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={380}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>

      {vbMemberBase && (
        <BitravelMemberModal
          isOpen={Boolean(vbMemberBase)}
          vbMemberBase={vbMemberBase}
          onClickClose={() => setVbMemberBase(null)}
        />
      )}
    </>
  );
}
