/**
 * Figma ID: 10-08-02-01
 * 名称: eギフト設定（eギフト選択肢）
 */
import React from 'react';
import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { ScrollableArea } from 'components/scrollableArea';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { useForm } from 'react-hook-form';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows, ModalZindex } from 'components/const';
import {
  useAddSEgiftCodeMutation,
  useDeleteSEgiftCodeByEgiftCodeMutation,
  useGetSEgiftCodeByThanksGift0Query,
  useUpdateSEgiftCodeByEgiftCodeMutation,
  useIsSBenefitsSCampaignLazyQuery,
} from 'graphql/graphql-ow';
import { EgiftList } from 'components/egiftList';
import { ConfirmContent, ConfirmFooter, ConfirmHeader } from './newGiftChoiceModal/Confirm';
import { CompleteContent, CompleteFooter, CompleteHeader } from './newGiftChoiceModal/Complete';
import {
  GiftNameAndTypeContent,
  GiftNameAndTypeFooter,
  GiftNameAndTypeHeader,
} from './newGiftChoiceModal/GiftNameAndType';
import { TokenAndIdContent, TokenAndIdFooter } from './newGiftChoiceModal/TokenAndId';
import { DeleteContent, DeleteFooter, DeleteHeader } from './deleteGiftChoiceModal/Delete';
import { DeleteCompleteContent, DeleteCompleteFooter, DeleteCompleteHeader } from './deleteGiftChoiceModal/Complete';
import { UpdateDeadlinePhase } from './GiftUpdate';
import ConfirmUpdateDeadlineHeader from './confirmUpdateDeadline/ConfirmUpdateDeadlineHeader';
import UpdateDeadlineHeader from './updateDeadline/UpdateDeadlineHeader';
import InputDataUpdateDeadlineContent from './updateDeadline/InputDataUpdateDeadlineContent';
import ConfirmUpdateDeadlineContent from './confirmUpdateDeadline/ConfirmUpdateDeadlineContent';
import CompleteUpdateDeadlineContent from './confirmUpdateDeadline/CompleteUpdateDeadlineContent';
import UpdateDeadlineContent from './updateDeadline/UpdateDeadlineContent';
import ConfirmUpdateDeadlineFooter from './confirmUpdateDeadline/ConfirmUpdateDeadlineFooter';
import UpdateDeadlineFooter from './updateDeadline/UpdateDeadlineFooter';

export interface InputDataModel {
  giftName?: string;
  giftType?: number;
  deadline?: Date | null;
  token?: string;
  egiftCode?: number;
  configCode?: string;
  price?: number;
}
type NewSelectGiftPhase = 'NameAndType' | 'TokenAndId' | 'Confirm' | 'Complete';
type DeleteSelectGiftPhase = 'Delete' | 'Complete';
function GiftChoice() {
  const { openErrorModal } = useErrorModal();
  const {
    data: GiftChoiceData,
    loading: giftChoiceLoading,
    error: giftChoiceError,
    refetch,
  } = useGetSEgiftCodeByThanksGift0Query();
  const [
    isSBenefitsSCampaign,
    { data: isSBenefitsSCampaignData, loading: isSBenefitsSCampaignLoading, error: isSBenefitsSCampaignError },
  ] = useIsSBenefitsSCampaignLazyQuery();
  const [addEGift, { loading: addEgiftLoading }] = useAddSEgiftCodeMutation();
  const [updateEGift, { loading: updateEgiftLoading }] = useUpdateSEgiftCodeByEgiftCodeMutation();
  const [deleteEGift, { loading: deleteEgiftLoading }] = useDeleteSEgiftCodeByEgiftCodeMutation();
  const [newGiftChoiceOpen, setNewGiftChoiceOpen] = React.useState(false);
  const [deleteGiftChoiceOpen, setDeleteGiftChoiceOpen] = React.useState(false);
  const [updateDeadlineShow, setUpdateDeadlineShow] = React.useState(false);
  const [updatePhase, setUpdatePhase] = React.useState<UpdateDeadlinePhase>();
  const [selectedGift, setSelectedGift] = React.useState<string>();
  const [isDeletable, setIsDeletable] = React.useState(false);
  const [newSelectGiftPhase, setNewSelectGiftPhase] = React.useState<NewSelectGiftPhase>();
  const [deleteGiftPhase, setDeleteGiftPhase] = React.useState<DeleteSelectGiftPhase>();

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputDataModel>({ reValidateMode: 'onSubmit' });

  const currentDeadline = watch('deadline');
  const currentEgiftType = watch('giftType');
  const currentEgiftName = watch('giftName');
  const currentToken = watch('token');
  const currentConfigCode = watch('configCode');
  const currentEgiftCode = watch('egiftCode');
  const price = 0; // API上はpriceが必須だが、実際には必要ないので0を入れている

  React.useEffect(() => {
    if (giftChoiceError) {
      openErrorModal({
        message: '店舗一覧が取得できませんでした。時間をおいてもう一度お試しください。',
      });
    }
  }, [giftChoiceError, openErrorModal]);

  React.useEffect(() => {
    if (isSBenefitsSCampaignError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [isSBenefitsSCampaignError, openErrorModal]);

  const newSelectGiftHeader = () => {
    switch (newSelectGiftPhase) {
      case 'Confirm':
        return <ConfirmHeader />;
      case 'Complete':
        return <CompleteHeader />;
      default:
        return <GiftNameAndTypeHeader />;
    }
  };
  const newSelectGiftContent = () => {
    switch (newSelectGiftPhase) {
      case 'TokenAndId':
        return <TokenAndIdContent control={control} errors={errors} />;
      case 'Confirm':
        return <ConfirmContent watch={watch} />;
      case 'Complete':
        return <CompleteContent />;
      default:
        return (
          <GiftNameAndTypeContent
            control={control}
            register={register}
            setValue={setValue}
            watch={watch}
            errors={errors}
          />
        );
    }
  };

  const newToBackPhase = () => {
    switch (newSelectGiftPhase) {
      case 'Confirm':
        setNewSelectGiftPhase('TokenAndId');
        break;
      default:
        setNewSelectGiftPhase('NameAndType');
    }
  };

  const newToNextPhase = () => {
    switch (newSelectGiftPhase) {
      case 'NameAndType':
        handleSubmit(() => {
          setNewSelectGiftPhase('TokenAndId');
        })();
        break;
      case 'TokenAndId':
        handleSubmit(() => {
          setNewSelectGiftPhase('Confirm');
        })();
        break;
      default:
        addEGift({
          variables: {
            config_code: currentConfigCode ?? '',
            name: currentEgiftName ?? '',
            deadline: `${currentDeadline?.getFullYear() ?? ''}-${(currentDeadline?.getMonth() ?? 0) + 1}-${
              currentDeadline?.getDate() ?? ''
            }`,
            token: currentToken ?? '',
            egift_type: Number(currentEgiftType) ?? 0,
          },
        })
          .then(() => {
            setNewSelectGiftPhase('Complete');
          })
          .catch(() => {
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          });
    }
  };

  const onClickCloseModal = () => {
    reset();
    setNewGiftChoiceOpen(false);
    refetch();
  };

  const newSelectGiftFooter = () => {
    switch (newSelectGiftPhase) {
      case 'TokenAndId':
        return (
          <TokenAndIdFooter
            onClickBack={() => newToBackPhase()}
            onClickClose={onClickCloseModal}
            onClickNext={() => newToNextPhase()}
          />
        );
      case 'Confirm':
        return (
          <ConfirmFooter
            onClickBack={() => newToBackPhase()}
            onClickClose={onClickCloseModal}
            onClickNext={() => newToNextPhase()}
          />
        );
      case 'Complete':
        return (
          <CompleteFooter
            onClickClose={() => {
              onClickCloseModal();
            }}
          />
        );
      default:
        return <GiftNameAndTypeFooter onClickNext={() => newToNextPhase()} onClickClose={onClickCloseModal} />;
    }
  };

  const updateModalHeader = React.useMemo(() => {
    switch (updatePhase) {
      case 'confirmUpdateData':
      case 'complete':
        return <ConfirmUpdateDeadlineHeader updateDeadlinePhase={updatePhase} />;
      default:
        return <UpdateDeadlineHeader updateDeadlinePhase={updatePhase} />;
    }
  }, [updatePhase]);

  const updateModalContent = () => {
    switch (updatePhase) {
      case 'inputUpdateData':
        return (
          <InputDataUpdateDeadlineContent
            control={control}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
          />
        );
      case 'confirmUpdateData':
        return <ConfirmUpdateDeadlineContent watch={watch} selectedGift={selectedGift} />;
      case 'complete':
        return <CompleteUpdateDeadlineContent />;
      default:
        return <UpdateDeadlineContent message="ギフトの発行期限を更新します。" selectedGift={selectedGift} />;
    }
  };

  const updateToBackPhase = () => {
    switch (updatePhase) {
      case 'confirmUpdateData':
        setUpdatePhase('inputUpdateData');
        break;
      default:
        setUpdatePhase('selectedGift');
    }
  };
  const updateToNextPhase = () => {
    switch (updatePhase) {
      case 'inputUpdateData':
        handleSubmit(() => {
          setUpdatePhase('confirmUpdateData');
        })();
        break;
      case 'confirmUpdateData':
        updateEGift({
          variables: {
            config_code: currentConfigCode ?? '',
            deadline: `${currentDeadline?.getFullYear() ?? ''}-${(currentDeadline?.getMonth() ?? 0) + 1}-${
              currentDeadline?.getDate() ?? ''
            }`,
            egift_code: currentEgiftCode ?? 0,
            price,
            token: currentToken ?? '',
          },
        })
          .then((result) => {
            if (result.data?.updateSEgiftCodeByEgiftCode.resultRows === ResultRows.UPDATED) {
              setUpdatePhase('complete');
            } else {
              openErrorModal({
                message: '更新に失敗しました。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          })
          .catch((e) => {
            // 通信自体ができない場合
            if (e instanceof Error) {
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          });

        break;
      default:
        setUpdatePhase('inputUpdateData');
    }
  };

  const updateModalFooter = () => {
    switch (updatePhase) {
      case 'confirmUpdateData':
      case 'complete':
        return (
          <ConfirmUpdateDeadlineFooter
            updateDeadlinePhase={updatePhase}
            onClickNext={() => updateToNextPhase()}
            onClickBack={() => updateToBackPhase()}
            onClickClose={() => setSelectedGift(undefined)}
          />
        );
      default:
        return (
          <UpdateDeadlineFooter
            updateDeadlinePhase={updatePhase}
            onClickNext={() => updateToNextPhase()}
            onClickBack={() => updateToBackPhase()}
            onClickClose={() => setSelectedGift(undefined)}
          />
        );
    }
  };

  React.useEffect(() => {
    if (newGiftChoiceOpen) {
      setNewSelectGiftPhase('NameAndType');
    } else {
      setNewSelectGiftPhase(undefined);
    }
  }, [newGiftChoiceOpen]);

  React.useEffect(() => {
    if (selectedGift === undefined) {
      setUpdateDeadlineShow(false);
      reset();
      refetch();
    } else {
      setUpdateDeadlineShow(true);
      setUpdatePhase('selectedGift');
    }
  }, [reset, refetch, selectedGift]);

  React.useEffect(() => {
    if (isSBenefitsSCampaignData && deleteGiftPhase === 'Delete') {
      setIsDeletable(isDeletable || isSBenefitsSCampaignData?.isSBenefitsSCampaign.bResult === false);
      setDeleteGiftChoiceOpen(true);
    }
  }, [isSBenefitsSCampaignData, isDeletable, deleteGiftPhase]);

  React.useEffect(() => {
    if (deleteGiftChoiceOpen) {
      // setDeleteGiftPhase('Delete');
    } else {
      setDeleteGiftPhase(undefined);
    }
  }, [deleteGiftChoiceOpen]);

  return (
    <HideWrapper>
      <Box pa={24} display="flex">
        <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
          <Text color="darkBlue" variant="h2">
            ギフト選択肢
          </Text>
          <Text color="darkBlue" variant="caption12">
            キャンペーンに使用するギフトを追加・設定します
          </Text>
        </Box>
        <Box>
          <MainButton icon="addCircle" onClick={() => setNewGiftChoiceOpen(true)}>
            新規追加
          </MainButton>
        </Box>
      </Box>
      <Box pr={24} pl={24}>
        <Divider option="horizontal" />
      </Box>
      <ScrollWrapper bottom={260}>
        <ScrollableArea>
          {giftChoiceLoading ||
          deleteEgiftLoading ||
          addEgiftLoading ||
          updateEgiftLoading ||
          isSBenefitsSCampaignLoading ? (
            <Loading />
          ) : (
            <Box px={24} pb={20}>
              {GiftChoiceData?.getSEgiftCodeByThanksGift0?.map((val) => (
                <EgiftList
                  key={Math.random().toString()}
                  text1={val?.name}
                  deadline={val?.deadline}
                  menu={[
                    { label: '更新', value: 'update' },
                    { label: '削除', value: 'delete' },
                  ]}
                  menuCallback={(clickThreeDotLeader) => {
                    const selectedGiftDeadlineString = val.deadline?.replace(/年|月/g, '-').replace(/日/g, '');
                    const selectedGiftDeadline = new Date(selectedGiftDeadlineString ?? '');
                    if (clickThreeDotLeader === 'delete') {
                      isSBenefitsSCampaign({
                        variables: { egift_code: val.egift_code },
                      });
                    }
                    setIsDeletable(selectedGiftDeadline < new Date());
                    setValue('deadline', selectedGiftDeadline);
                    setValue('egiftCode', val.egift_code);
                    setValue('configCode', val.config_code ?? '');
                    setValue('token', val.token ?? '');
                    if (clickThreeDotLeader === 'delete') {
                      setDeleteGiftPhase('Delete');
                    } else {
                      setSelectedGift(val?.name || '');
                    }
                  }}
                />
              ))}
            </Box>
          )}
        </ScrollableArea>
      </ScrollWrapper>
      <Modal
        isOpen={newGiftChoiceOpen}
        header={newSelectGiftHeader()}
        content={newSelectGiftContent()}
        footer={newSelectGiftFooter()}
        onClose={() => {
          onClickCloseModal();
        }}
        height="auto"
      />
      <Modal
        isOpen={updateDeadlineShow}
        header={updateModalHeader}
        content={updateModalContent()}
        footer={updateModalFooter()}
        onClose={() => setSelectedGift(undefined)}
        height="auto"
      />
      <Modal
        isOpen={deleteGiftChoiceOpen}
        header={deleteGiftPhase === 'Delete' ? <DeleteHeader /> : <DeleteCompleteHeader />}
        content={deleteGiftPhase === 'Delete' ? <DeleteContent isDeletable={isDeletable} /> : <DeleteCompleteContent />}
        footer={
          deleteGiftPhase === 'Delete' ? (
            <DeleteFooter
              onClickDelete={() => {
                deleteEGift({
                  variables: { egift_code: currentEgiftCode ?? 0 },
                })
                  .then((result) => {
                    if (result.data?.deleteSEgiftCodeByEgiftCode.resultRows === ResultRows.UPDATED) {
                      setDeleteGiftPhase('Complete');
                    } else {
                      openErrorModal({
                        message: '更新に失敗しました。',
                        zIndex: ModalZindex.ERRMODALZINDEX,
                      });
                    }
                  })
                  .catch((e) => {
                    // 通信自体ができない場合
                    if (e instanceof Error) {
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                        zIndex: ModalZindex.ERRMODALZINDEX,
                      });
                    }
                  });
              }}
              onClickClose={() => setDeleteGiftChoiceOpen(false)}
              isDeletable={isDeletable}
            />
          ) : (
            <DeleteCompleteFooter
              onClickClose={() => {
                refetch();
                setDeleteGiftChoiceOpen(false);
              }}
            />
          )
        }
        onClose={() => {
          onClickCloseModal();
        }}
        height="auto"
      />
    </HideWrapper>
  );
}

export default GiftChoice;
