import React, { useMemo, useContext } from 'react';
import * as gql from 'graphql/graphql-ow';
import { orderBy } from 'lodash';

import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils';
import { TextLink } from 'components/textLink';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';

import { StoreModal } from 'modal/storeModal';

import { ListHeaderType, ListItemsType } from '../type';
import { NewlyNotificationContext } from '../components/newlyNotificationContext';

/**
 * Figma ID: 08-05-01
 * 名称: 払戻支払-払戻支払一覧
 */

function RedIndicator({ label }: { label: string }) {
  return (
    <Box display="flex" flexDirection="row" gap={4} alignItems="center">
      <Icon name="indicatorRed" size={8} />
      <Text variant="caption12">{label}</Text>
    </Box>
  );
}

export function RefundPaymentList(props: { setMenuIndex: (num: number) => void }) {
  const { setMenuIndex } = props;

  const { setNewlyNotification } = useContext(NewlyNotificationContext);

  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const [getupdVtmPaymentListNewlyPayRefundMutation, { data, loading, called, error: paymentListNewlyPayRefundError }] =
    gql.useGetupdVtmPaymentListNewlyPayRefundMutation({
      variables: {},
    });

  const {
    getupdVTMPaymentListNewlyPayRefund: { newly, payment },
  } = data || {
    getupdVTMPaymentListNewlyPayRefund: {
      newly: {} as gql.VuNewlyPayRefund,
      payment: [] as gql.VtmPaymentList[],
    },
  };

  // 店舗会員情報
  const [vtMemberBase, setVtMemberBase] = React.useState<gql.VtMemberBase | null>(null);
  // 店舗会員情報取得
  const [getVtMemberBaseById] = gql.useGetVtMemberBaseByIdLazyQuery();
  // エラーモーダル
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (!called) {
      getupdVtmPaymentListNewlyPayRefundMutation();
    }
  }, [called, getupdVtmPaymentListNewlyPayRefundMutation]);

  React.useEffect(() => {
    if (paymentListNewlyPayRefundError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [paymentListNewlyPayRefundError, openErrorModal]);

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'name', width: 182, columnName: '店舗名' },
      { key: 'bill_date', width: 140, columnName: '請求日時' },
      { key: 'price', width: 128, columnName: '請求金額' },
    ],
    []
  );

  // 店舗情報モーダル表示
  const showStoreModal = React.useCallback(
    (tpmemId: number) => {
      (async () => {
        const result = await getVtMemberBaseById({ variables: { id: tpmemId } });
        const storeInfo = result?.data?.getVTMemberBaseById;
        // 店舗情報を設定してモーダルを表示する
        if (!storeInfo) {
          openErrorModal({ message: '店舗情報の取得に失敗しました。' });
          return;
        }
        // 店舗情報を設定してモーダルを表示する
        setVtMemberBase(storeInfo);
      })();
    },
    [getVtMemberBaseById, openErrorModal]
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading) return [];
    let items = payment;

    items = orderBy(items, 'bill_date', 'desc');

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else if (sort.key === 'price') {
        // 数値変換
        items = orderBy(items, (item) => parseInt(item.price?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      bill_date: item.bill_date,
      price: `¥${item.price ?? 0}`,
      name: (
        <TextLink onClick={() => showStoreModal(item.tpmem_id)} lineClamp={1}>
          {item.newly ? <RedIndicator label={item.name ?? ''} /> : item.name}
        </TextLink>
      ),
      uniqueKey: key,
    }));
  }, [loading, payment, sort, showStoreModal]);

  if (Object.keys(newly).length !== 0) {
    setNewlyNotification(newly);
  }

  React.useEffect(() => {
    setMenuIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 32px)">
        <Box display="flex" flexDirection="row" height={60} px={16} alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={8} alignItems="center">
            <Text variant="h2" color="darkBlue">
              払戻支払一覧
            </Text>
            <Text variant="caption12" color="darkBlue">
              各加盟店からのウォレットの払戻請求です。
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="auto" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={380}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
      {vtMemberBase && (
        <StoreModal
          isOpen={Boolean(vtMemberBase)}
          vtMemberBase={vtMemberBase}
          onClickClose={() => setVtMemberBase(null)}
        />
      )}
    </>
  );
}
