import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useState } from 'react';

enum ModalStep {
  Unblock = 1,
  Complete = 2
}


export function UnblockModal(
  props: {isOpen: boolean; onClose: () => void; onUnblock: () => void}
){
  const { isOpen, onClose, onUnblock } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Unblock);

  const onModalClose = () => {
    setModalStep(ModalStep.Unblock);
    onClose();
  };

  const unblockModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック解除
      </Text>
    ),
    content: (
      <Box display='flex' height='100%' flexDirection='row' alignItems='center'>
        <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
          <Text variant='body14'>
            店舗ブロックを解除しますか？
          </Text>         
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton 
          width={104} thin 
          onClick={() => {
            setModalStep(ModalStep.Complete);
            onUnblock();
          }}
        >
          解除
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210
  };
  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック解除 完了
      </Text>
    ),
    content: (
      <Box display='flex' height='100%' flexDirection='row' alignItems='center'>
        <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
          <Text variant='body14'>
            店舗ブロック解除が完了しました。
          </Text>         
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210
  };

  const modalContent = modalStep === ModalStep.Unblock ? unblockModal : completeModal;

  return (
    <Modal
      isOpen={isOpen}
      onClose={()=>onModalClose()}
      {...modalContent}
    />
  );
}