import React from 'react';
import { Box } from 'components/box';
import { BitravelLogo } from 'components/assets/css/pages/s01/screen01Style';
import { Text } from 'components/text';

export function TitleHeader() {
  return (
    <Box width="100%" justifyContent="center" display="flex">
      <BitravelLogo width={104} height={18} src="/bitravel_logo.png" alt="bitravel_logo" />
      <Box justifyContent="center" alignItems="center" display="grid" pl={8}>
        <Text color="darkBlue" variant="body14" bold>
          運営管理
        </Text>
      </Box>
    </Box>
  );
}
