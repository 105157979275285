import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { CreateBlockKey, ModalRet } from 'pages/s03/type';
import React from 'react';
import { Control, UseFormTrigger, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { useGetMRejectReasonByKindQuery, MRejectReason } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { ErrorMessage } from 'components/errorMessage';
import { Reason } from 'pages/s03/const';

// ブロック理由種別
enum RejectReasonKind {
  MEMBER_STORE = 2, // 店舗会員ブロック
}

export function SelectReasonModal(
  control: Control<CreateBlockKey>,
  trigger: UseFormTrigger<CreateBlockKey>,
  handleSubmit: UseFormHandleSubmit<CreateBlockKey>,
  errors: FieldErrors<CreateBlockKey>,
  onClose: () => void,
  onNext: () => void,
  onBack: () => void
): ModalRet {
  const currentReason = useWatch({ control, name: 'reason' });
  const { openErrorModal } = useErrorModal();
  const {
    data: mRejectReasonData,
    loading: mRejectReasonLoading,
    error: mRejectReasonError,
  } = useGetMRejectReasonByKindQuery({
    context: { clientName: 'master' },
    variables: {
      kind: RejectReasonKind.MEMBER_STORE, // 2: 店舗会員ブロック
    },
  });
  React.useEffect(() => {
    if (mRejectReasonError) {
      onClose();
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mRejectReasonError, openErrorModal, onClose]);

  const blockReasonData = mRejectReasonData?.getMRejectReasonByKind as MRejectReason[];
  const blockReasons =
    blockReasonData?.map((item) => ({
      value: String(item.no),
      label: item.reason,
    })) || [];

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      onNext();
    }
  });

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック登録 - ブロック理由
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={17.5} pa={8} width="100%">
          <Text variant="caption11">ブロック理由を選択してください。</Text>
          <Box display="flex" flexDirection="column" gap={5.5} height={78}>
            <Text variant="h3" color="blueGray">
              ブロック理由
            </Text>
            <RhfSelect
              control={control}
              name="reason"
              options={blockReasons}
              defaultValue=""
              fullWidth
              placeholder="ブロック理由を選択"
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.reason && <ErrorMessage>{errors?.reason.message}</ErrorMessage>}
          </Box>
          {currentReason === String(Reason.OTHER) && (
            <Box display="flex" flexDirection="column" gap={5.5} height={78}>
              <Text variant="h3" color="blueGray">
                ブロック理由
              </Text>
              <RhfInput
                name="reason_other"
                control={control}
                fullWidth
                placeholder="コメントを記入"
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.reason_other && <ErrorMessage>{errors?.reason_other.message}</ErrorMessage>}
            </Box>
          )}
        </Box>
      </Box>
    ),
    footer: (
      <>
        {mRejectReasonLoading && <Loading />}
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
            キャンセル
          </MainButton>
          <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
            戻る
          </MainButton>
          <MainButton
            width={104}
            thin
            onClick={(data) => {
              onClickHandler(data);
            }}
            disabled={currentReason === undefined || currentReason === ''}
          >
            確認
          </MainButton>
        </Box>
      </>
    ),
    width: 800,
    height: currentReason === String(Reason.OTHER) ? 389 : 295,
  };

  return modalContent;
}
