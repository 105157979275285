import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useMemo } from 'react';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { HistoryType } from 'components/const';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { HistoryList } from 'components/historyList';
import { orderBy } from 'lodash';

/**
 * Figma ID: 03-10-01
 * 利用履歴
 * Usage
 */

export function Usage(props: { id: number }) {
  const { id } = props;
  const { openErrorModal } = useErrorModal();
  const {
    data: tMemberLoggingListData,
    loading: tMemberLoggingListLoading,
    error: tMemberLoggingListError,
  } = gql.useGetVtMemberLogging3ListByIdQuery({
    variables: { id },
  });
  React.useEffect(() => {
    if (tMemberLoggingListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [tMemberLoggingListError, openErrorModal]);

  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  /* 履歴を利用履歴(=3)だけ抽出 */
  let extractTMemberLoggingList = tMemberLoggingListData?.getVTMemberLogging3ListById.logging.filter(
    (item) => item.kind === HistoryType.UTILIZATION
  );
  extractTMemberLoggingList = orderBy(extractTMemberLoggingList, 'log_date', 'desc');
  const tLoggingList = useMemo(
    () =>
      extractTMemberLoggingList?.map((item) => ({
        tpmem_id: item?.tpmem_id || 0,
        log_date: item?.log_date || '',
        matter: item?.matter || '',
      })) || [],
    [extractTMemberLoggingList]
  );
  const displayHistoryItems: {
    uniqueKey: string | number;
    text: string;
    dateTime: string;
  }[] = React.useMemo(
    () =>
      tLoggingList
        .map(({ tpmem_id, matter, log_date }) => ({
          uniqueKey: tpmem_id,
          text: matter,
          dateTime: log_date,
        }))
        .slice(currentLimit * (currentPage - 1), currentLimit * currentPage),
    [currentPage, currentLimit, tLoggingList]
  );

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  return (
    <Box px={16} display="flex" flex="1" flexDirection="column">
      {tMemberLoggingListLoading && <Loading />}
      <Box display="flex" flexDirection="row" alignItems="center" height={60} justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          利用履歴
        </Text>
      </Box>
      <Divider option="horizontal" length={0} />
      <Box display="flex" flexDirection="column" flex="1">
        <Box display="flex" flex="1" flexDirection="column">
          <PaginationBox
            dataSize={extractTMemberLoggingList?.length ?? 0}
            limit={currentLimit}
            page={currentPage}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <ScrollWrapper bottom={346}>
              <HistoryList items={displayHistoryItems} />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
    </Box>
  );
}
