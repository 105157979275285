import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { VbWithholdSlipList } from 'graphql/graphql-ow';
import React from 'react';

/**
 * Figma ID: 05-07-02
 * 名称: 源泉徴収票年度別一覧モーダル
 */

interface Props {
  lists?: Array<VbWithholdSlipList | null> | null;
  onClose: () => void;
  onClickDownload: (btmemId: number, year: number, corporateNo: string) => void;
}

const leaderMenu = [{ label: 'ダウンロード', value: 'download' }];

export function WithholdingModal({ lists, onClose, onClickDownload }: Props) {
  const modal = React.useMemo(
    () => ({
      width: 384,
      height: 589,
      header: (
        <Text variant="h2" color="darkBlue">
          源泉徴収票年度別一覧
        </Text>
      ),
      content: (
        <>
          {lists?.map((list, key) => (
            <Box
              key={`${String(list?.btmem_id)}-${String(list?.corp_name)}-${String(list?.corporate_no)}-${String(key)}`}
            >
              <Divider option="horizontal" />
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={8} my={8}>
                <Box>
                  <Box mb={2}>
                    <Text variant="body14">{list?.year_val}年分発行</Text>
                  </Box>
                  <Box>
                    <Text variant="caption11" color="darkGray">
                      {list?.last_date}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <ThreeDotLeader menu={leaderMenu} right={0} onClick={() => {
                      onClickDownload(Number(list?.btmem_id), Number(list?.year_val), String(list?.corporate_no));
                  }} />
                </Box>
              </Box>
            </Box>
          ))}
          <Divider option="horizontal" />
        </>
      ),
      footer: (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton thin onClick={onClose} variant="secondary" width={104}>
            閉じる
          </MainButton>
        </Box>
      ),
      onClose,
    }),
    [lists, onClose, onClickDownload]
  );
  return modal;
}
