import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { StatusChangeModalStep } from 'pages/s05/constants';
import React from 'react';

/**
 * Figma ID: 05-04-02 05-04-03
 * 名称: コメント削除 コメント削除 完了
 */

interface Props {
  modalStep: StatusChangeModalStep;
  onClose: () => void;
  cancelHandler: () => void;
  activeHandler: () => void;
  inactiveHandler: () => void;
}

export function StatusChangeModal({ modalStep, onClose, cancelHandler, activeHandler, inactiveHandler }: Props) {
  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case StatusChangeModalStep.ACTIVE:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          content: <Text variant="body14">このユーザーをアクティブに変更しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={cancelHandler} variant="secondary" width={104}>
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  inactiveHandler();
                }}
                variant="primary"
                width={104}
              >
                変更
              </MainButton>
            </Box>
          ),
          onClose: cancelHandler,
        };
      case StatusChangeModalStep.INACTIVE:
        return {
          width: 384,
          height: 236,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          content: (
            <Text variant="body14">
              このユーザーを休止中に変更しますか？
              <br />
              申込み済みの採用や逆オファーが自動的にキャンセルになります。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={cancelHandler} variant="secondary" width={104}>
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  activeHandler();
                }}
                variant="primary"
                width={104}
              >
                変更
              </MainButton>
            </Box>
          ),
          onClose: cancelHandler,
        };
      case StatusChangeModalStep.COMPLETE:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更 完了
            </Text>
          ),
          content: <Text variant="body14">ステータス変更が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                閉じる
              </MainButton>
            </Box>
          ),
          onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose,
        };
    }
  }, [modalStep, onClose, cancelHandler, activeHandler, inactiveHandler]);
  return displayModal;
}
