import { styled } from '@linaria/react';
import { FONT } from 'components/assets/css/style';

const ScreenOver = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  margin: 24px;
  gap: 26px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 32px;
  margin-bottom: 24px;
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const PageBody = styled.div<{
  bottom: number;
}>`
  overflow: hidden;
  padding-bottom: 4px;
  height: calc(100% - ${({ bottom }) => bottom}px);
`;

const PageContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: #F0F; */
`;

const ContentsCard = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin: 2px;
  height: 100%;
  min-height: 100%;
`;

const ContentsCardHeader = styled.div<{
  height?: number;
}>`
  /*display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px 20px;
  padding: 20px;*/
  border-bottom: 1px solid #dbdbdb;
`;

const ContentsCardFilters = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const CardFilterDevider = styled.div`
  flex: 0 0 1px;
  height: 24px;
  background-color: #dbdbdb;
`;

const ContentsCardBody = styled.div<{
  height: number;
}>`
  position: relative;
  padding: 16px;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

const InnerBox = styled.div<{
  padding: string;
}>`
  padding: ${({ padding }) => padding};
`;

const FlexCenter = styled.div<{
  height: number;
}>`
  display: flex;
  align-items: center;
  height: ${({ height }) => height}px;
`;

const StickyHeader = styled.div<{
  height: number;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ height }) => height}px;
  gap: 8px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  z-index: 4;
`;

const ImgWrapper = styled.div`
  cursor: pointer;
`;

const HideWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ScrollWrapper = styled.div<{
  bottom: number;
}>`
  height: calc(100vh - ${({ bottom }) => bottom}px);
  overflow: auto;
`;

const Title = FONT.H1;

export {
  ScreenOver,
  Header,
  Body,
  PageBody,
  PageContentsWrapper,
  Title,
  ContentsCard,
  ContentsCardHeader,
  ContentsCardBody,
  ContentsCardFilters,
  CardFilterDevider,
  InnerBox,
  FlexCenter,
  StickyHeader,
  ImgWrapper,
  HideWrapper,
  ScrollWrapper,
};
