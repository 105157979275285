/**
 * Figma ID: 10-08-01-01, 10-08-02-01, 10-08-03-01, 10-08-03-05
 * 名称: eギフト設定
 */
import React from 'react';
import { SubMenu } from 'components/subMenu';
import { ContentsCard, PageBody, PageContentsWrapper } from 'components/assets/css/pages/pageStyle';
import GiftUpdate from './component/GiftUpdate';
import EGiftHeader from './component/EGiftHeader';
import GiftChoice from './component/GiftChoice';
import FixedPrivilegeSetting from './component/FixedPrivilegeSetting';

function Screen1008() {
  return (
    <PageContentsWrapper>
      <EGiftHeader />
      <PageBody bottom={60}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                content: <GiftUpdate />,
                label: '加盟店Thanksギフト更新',
              },
              {
                content: <GiftChoice />,
                label: 'ギフト選択肢',
              },
              {
                content: <FixedPrivilegeSetting />,
                label: '固定特典設定',
              },
            ]}
          />
        </ContentsCard>
      </PageBody>
    </PageContentsWrapper>
  );
}

export default Screen1008;
