import axios, { AxiosResponse } from 'axios';
import { Amplify } from 'aws-amplify';
import { PDFDocument, PDFFont, PDFPage } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { Buffer } from 'buffer';
import { splitPdfString, drawAllLine, isOverTextWidth } from './util';

Amplify.configure({
  region: process.env.REACT_APP_AUTH_REGION,
  identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
});

// PDFパラメータ
export type NoticeOfEmploymentPdfProps = {
  userName?: string;
  userAddress?: string;
  employmentDate?: string;
  workingAddress?: string;
  description?: string;
  beginTime?: string;
  endTime?: string;
  breakTime?: number;
  hourWage?: string;
  transFee?: string;
  socialInsurance?: string;
  passiveSmokingMeasure?: string;
  isWorkSmokingArea?: boolean;
};

// 業務内容を印字する
const drawDescription = (description: string, customFont: PDFFont, fontSize: number, page: PDFPage) => {
  // 業務内容が設定されていない場合は処理しない
  if (!description) return;

  // 業務内容リスト
  let descriptionList = [] as string[];
  // 業務内容の１行の幅
  const descriptionTextWidth = 370;
  // 業務内容の１行の高さ(行間)
  const descriptionTextHeight = 15;

  // 業務内容を改行で分割する
  const descriptionSpArray = description.split(/\r?\n/);

  // 改行で分割した配列分(行数分)繰り返す
  descriptionSpArray.forEach((data) => {
    // 業務内容を指定したテキスト幅で分割する
    // ※テキスト幅を超えている場合は次の行に印字する
    const lineArray = splitPdfString(customFont, fontSize, data, descriptionTextWidth);
    descriptionList = descriptionList.concat(lineArray);
  });

  // 全行数分印字する
  drawAllLine(descriptionList, descriptionTextHeight, 193, 623, page, customFont, fontSize, 15);
};

// CloudFrontパス設定
const CloudFrontURL = process.env.REACT_APP_PDF_CLOUDFRONT_URL || '';
const FontPath = process.env.REACT_APP_PDF_FONT || '';
const TemplatePath = process.env.REACT_APP_NOE_PDF_TEMPLATE || '';

/**
 * 労働条件通知書PDF作成
 *
 * @param props パラメータ
 * @returns PDFのバッファデータを返す
 */
export default async function createNoticeOfEmploymentPdf(props: NoticeOfEmploymentPdfProps): Promise<Buffer | null> {
  const {
    userName,
    userAddress,
    employmentDate,
    workingAddress,
    description,
    beginTime,
    endTime,
    breakTime,
    hourWage,
    transFee,
    socialInsurance,
    passiveSmokingMeasure,
    isWorkSmokingArea,
  } = props;

  // CloudFront経由でPDFテンプレートとフォントを取得
  const templateURL = `${CloudFrontURL}/${TemplatePath}`;
  const fontURL = `${CloudFrontURL}/${FontPath}`;

  // データ取得
  async function fetchArrayBuffer(url: string): Promise<ArrayBuffer> {
    try {
      const response: AxiosResponse<Blob> = await axios.get(url, { responseType: 'blob' });
      const blob = response.data;
      const arrayBuffer = await blob.arrayBuffer();
      return arrayBuffer;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Axios特有のエラーハンドリング
        console.log('Axios error occurred while fetching: ', url, ' :', error.message);
        throw new Error(`Axios error occurred while fetching ${url}: ${error.message}`);
      } else if (error instanceof Error) {
        // エラーハンドリング
        console.log('Failed to fetch: ', url, ' :', error.message);
        throw new Error(`Failed to fetch ${url}: ${error.message}`);
      } else {
        // その他のエラー
        console.log('An unknown error occurred while fetching: ', url);
        throw new Error(`An unknown error occurred while fetching ${url}`);
      }
    }
  }
  try {
    // PDFテンプレートとフォントのArrayBufferを取得
    const [templateBuffer, fontBuffer] = await Promise.all([
      fetchArrayBuffer(`${CloudFrontURL}/${TemplatePath}`),
      fetchArrayBuffer(`${CloudFrontURL}/${FontPath}`),
    ]);
    // PDF Font取得
    // const [templateBuffer, fontBuffer] = await Promise.all([fetchArrayBuffer(templateURL), fetchArrayBuffer(fontURL)]);

    // PDFデータをロード
    const pdfDoc = await PDFDocument.load(templateBuffer);

    // ページ情報を読み込み
    const pages = pdfDoc.getPages();
    // カスタムフォントを使用する準備を行う
    pdfDoc.registerFontkit(fontkit);

    // デフォルトフォントサイズ
    const fontSize = 10;
    // フォント情報
    const customFont = await pdfDoc.embedFont(fontBuffer, { subset: true });

    // 使用者
    if (userName) pages[0].drawText(userName, { x: 193, y: 765, font: customFont, size: fontSize });

    // 使用者所在地
    const addressTextWidth = 370; // 住所の１行の幅
    if (userAddress) {
      // 使用者所在地が１行に収まるかチェック
      const isTextWidthOver = isOverTextWidth(customFont, fontSize, userAddress, addressTextWidth);
      // 使用者所在地が１行に収まらない場合はフォントサイズを小さくする
      pages[0].drawText(userAddress, {
        x: 193,
        y: 745,
        font: customFont,
        size: isTextWidthOver === true ? 5.8 : fontSize,
      });
    }

    // 就労日
    if (employmentDate) pages[0].drawText(employmentDate, { x: 193, y: 723, font: customFont, size: fontSize });

    // 就業場所
    if (workingAddress) {
      // 就業場所が１行に収まるかチェック
      const isTextWidthOver = isOverTextWidth(customFont, fontSize, workingAddress, addressTextWidth);
      // 就業場所が１行に収まらない場合はフォントサイズを小さくする
      pages[0].drawText(workingAddress, {
        x: 193,
        y: 642,
        font: customFont,
        size: isTextWidthOver === true ? 5.8 : fontSize,
      });
    }

    // 業務内容
    if (description) {
      drawDescription(description, customFont, fontSize, pages[0]);
    }

    // 始業時刻
    if (beginTime) pages[0].drawText(beginTime, { x: 218, y: 370, font: customFont, size: fontSize });

    // 終業時刻
    if (endTime) pages[0].drawText(endTime, { x: 218, y: 354, font: customFont, size: fontSize });

    // 休憩時間
    const breakTimeWork = breakTime || 0;
    pages[0].drawText(`${breakTimeWork.toString()}分`, { x: 193, y: 332, font: customFont, size: fontSize });

    // 基本賃金
    if (hourWage) pages[0].drawText(`(\xA5${hourWage})`, { x: 228, y: 273, font: customFont, size: fontSize });

    // 交通費
    if (transFee) pages[0].drawText(`${transFee}円`, { x: 228, y: 251, font: customFont, size: fontSize });

    // 社会保険等
    if (socialInsurance) pages[0].drawText(socialInsurance, { x: 193, y: 83, font: customFont, size: fontSize });

    // 受動喫煙防止措置
    if (passiveSmokingMeasure)
      pages[1].drawText(passiveSmokingMeasure, { x: 193, y: 610, font: customFont, size: fontSize });

    // 喫煙可能エリアでの作業の有無
    if (isWorkSmokingArea !== undefined)
      pages[1].drawText(isWorkSmokingArea === true ? '有' : '無', { x: 193, y: 589, font: customFont, size: fontSize });

    // PDFデータを取得
    const pdfBytes = await pdfDoc.save();

    return Buffer.from(pdfBytes);
  } catch (error) {
    console.error('An error occurred while creating the PDF:', error);
    return null;
  }
}
