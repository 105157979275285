import React from 'react';
import { chipColors } from 'components/chip/chip';
import { MAreaState, MBrand2, MBusinessBrand } from 'graphql/graphql-ow';
import { splitString } from 'components/utils';
import { CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { CHIP_TEXT, COLORS_NAME, FormatDateType, ListTitleWidth } from './constants';
import { IconProps, SkillProps } from './s10-02-types';

type ChipColorKeyType = keyof typeof chipColors;

type LocalBrandType = {
  id: number;
  brands?: MBrand2[];
};

interface OptionValue {
  type: string;
  message: string;
  ref: {
    name: string;
  };
}

export interface Message {
  optionValues: OptionValue[];
}

export const splitComponent = (bizNames?: string[], brandNames?: string[]) => {
  const splitNameRows = bizNames?.map((text: string, i: number) => (
    <CampaignTableRow key={`${text}-${brandNames ? brandNames[i] : i}`} isDivider pa={0} gap={16} height={18}>
      <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
        <Text color="darkBlue" variant="caption12" bold>
          {text}
        </Text>
      </CampaignTableItem>
      <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
        <Text color="blueGray" variant="caption12">
          {brandNames ? brandNames[i] : null}
        </Text>
      </CampaignTableItem>
    </CampaignTableRow>
  ));
  return bizNames ? splitNameRows : null;
};

export const chipColor = (status?: number): ChipColorKeyType => {
  switch (status) {
    case 0:
      return COLORS_NAME.Yellow as ChipColorKeyType;
    case 1:
      return COLORS_NAME.BLUE as ChipColorKeyType;
    case 2:
      return COLORS_NAME.Gray as ChipColorKeyType;
    default:
      return COLORS_NAME.Yellow as ChipColorKeyType;
  }
};

export const chipText = (status?: number) => {
  switch (status) {
    case 0:
      return CHIP_TEXT.Before;
    case 1:
      return CHIP_TEXT.Progress;
    case 2:
      return CHIP_TEXT.End;
    default:
      return CHIP_TEXT.Before;
  }
};

export const leaderMenu = (status?: number) => {
  switch (status) {
    case 0:
      return [
        { label: '複製編集', value: 'update' },
        { label: '削除', value: 'delete' },
      ];
    case 1:
      return [{ label: '複製編集', value: 'update' }];
    case 2:
      return [{ label: '複製編集', value: 'update' }];
    default:
      return [
        { label: '複製編集', value: 'update' },
        { label: '削除', value: 'delete' },
      ];
  }
};

/**
 *
 * @param ids
 * @param mAreaState
 * @returns const stateNames = '北海道、秋田、岩手'
 */
export const getStateNamesByIds = (ids: string[], mAreaState?: Array<MAreaState | null> | null) => {
  const status = ids.map((id: string) => {
    const targetStates = mAreaState
      ?.flatMap((region) => region?.state)
      .filter((state) => state?.state_id === Number(id));

    return targetStates;
  });

  const sortStates = status.flat().sort((state1, state2) => Number(state1?.state_id) - Number(state2?.state_id));
  const stateNames = sortStates
    .map((state) => state?.state_name)
    .flat()
    .join(' ');

  return stateNames;
};

/**
 * @param ids
 * @param mBusinessBrand
 * @returns const targetBrands = [ MBrand2[],MBrand2[],MBrand2[] ]
 */
const getTargetBrands = (ids: string[], mBusinessBrand: Array<MBusinessBrand | null>) =>
  ids.map((id: string) => {
    const targetBrand = mBusinessBrand
      .flatMap((brand) => brand?.brand)
      .filter((brand) => brand?.brand_id === Number(id));

    return targetBrand;
  });

/**
 *
 * @param brands
 * @returns const sortBrands =[ MBrand2[],MBrand2[],MBrand2[] ]
 */
const sortedBrandsById = (brands: (MBrand2 | null | undefined)[][]) =>
  brands.flat().sort((state1, state2) => Number(state1?.brand_id) - Number(state2?.brand_id));

const createLocalBrandData = (mBusinessBrand: Array<MBusinessBrand | null>) =>
  mBusinessBrand.map((i) => {
    const b: LocalBrandType = {
      id: i?.id || 0,
      brands: [],
    };
    return b;
  });

/**
 *
 * @param sortBrands
 * @param localBrandData
 * sortBrandsのビジネスIDと同じIDの箇所にデータを格納する
 * @returns const brandsToLocalData = {
//       id
//       name
//       brands: [MBrand2,MBrand2,....],
//     }
 */
const mapBrandsToLocalData = (sortBrands: (MBrand2 | null | undefined)[], localBrandData: LocalBrandType[]) => {
  sortBrands.forEach((brand) => {
    localBrandData.forEach((data) => {
      if (brand?.biz_id === data.id) {
        data?.brands?.push(brand);
      }
    });
  });
  return localBrandData;
};

/**
 *
 * @param localBrandData
 * @returns const brandNames = ‘ファミリーマート ローソン, ドトール スターバックス, 吉野家 松屋 すき家’
 */
const getBrandNames = (localBrandData: LocalBrandType[]) =>
  localBrandData
    .map((category) => {
      const brandName = category.brands?.map((brand) => brand?.brand_name).join(' ');
      return brandName;
    })
    .join(', ');

/**
 *
 * @param brands
 * @returns const bizIds = '3, 1, 2'
 */
const generateCommaSeparetedBizIds = (brands: (MBrand2 | null | undefined)[]) =>
  brands
    .map((brand) => brand?.biz_id)
    .filter((num): num is number => num !== undefined)
    .flat()
    .reduce((acc, curr) => {
      if (!acc.includes(curr as never)) {
        acc.push(curr as never);
      }
      return acc;
    }, [])
    .join(',');

/**
 *
 * @param sortBizIds
 * @param mBusinessBrand
 * @returns const bizNames = ‘コンビニ, カフェ, 牛丼屋’
 */
const generateCommaSeparetedBizNames = (sortBizIds: string, mBusinessBrand: Array<MBusinessBrand | null>) =>
  splitString(sortBizIds || '', ',')
    .map((id) => mBusinessBrand.filter((brand) => brand?.id === Number(id)))
    .flat()
    .flatMap((brand) => brand?.name)
    .flat()
    .join(',');

/**
 *
 * @param ids
 * @param businessBrands
 * @returns const sortBrands = [MBrand2,MBrand2,MBrand2]
 */
const getFilteredAndSortedBrands = (ids: string[], businessBrands: Array<MBusinessBrand | null>) => {
  const targetBrands = getTargetBrands(ids, businessBrands);
  const sortBrands = sortedBrandsById(targetBrands);
  return sortBrands;
};

/**
 *
 * @param ids
 * @param businessBrands
 * @returns const brandNames = ‘ファミリーマート ローソン, ドトール スターバックス, 吉野家 松屋 すき家’
 */
export const getBrandNamesByIds = (ids: string[], businessBrands: Array<MBusinessBrand | null>) => {
  const sortBrands = getFilteredAndSortedBrands(ids, businessBrands);
  const localBrandData = createLocalBrandData(businessBrands);
  const brandsToLocalData = mapBrandsToLocalData(sortBrands, localBrandData);
  const brandNames = getBrandNames(brandsToLocalData);
  return brandNames;
};

/**
 *
 * @param ids
 * @param businessBrands
 * @returns const bizNames = ‘牛丼屋, コンビニ, カフェ’
 */
export const getBizNamesByIds = (ids: string[], businessBrands: Array<MBusinessBrand | null>) => {
  const sortBrands = getFilteredAndSortedBrands(ids, businessBrands);
  const sortedBizIds = generateCommaSeparetedBizIds(sortBrands);
  const bizNames = generateCommaSeparetedBizNames(sortedBizIds, businessBrands);
  return bizNames;
};

/**
 *
 * @param ids
 * @param businessBrands
 * @returns const brandNames = [[‘ファミリーマート ローソン'], ['ドトール スターバックス'], ['なし']]
 */
export const getViewBrandNames = (ids: string[], businessBrands: Array<MBusinessBrand | null>) => {
  const sortBrands = getFilteredAndSortedBrands(ids, businessBrands);
  const localBrandData = createLocalBrandData(businessBrands);
  const brandsToLocalData = mapBrandsToLocalData(sortBrands, localBrandData);
  return brandsToLocalData.map((category) => {
    const brandName = category.brands?.map((brand) => brand?.brand_name).join(' ');
    return brandName || 'なし';
  });
};

/**
 *
 * @param mBusinessBrand
 * @returns const bizNames = [‘コンビニ', 'カフェ', '牛丼屋’]
 */
export const getViewBizNames = (mBusinessBrand: Array<MBusinessBrand | null>) =>
  mBusinessBrand.map((biz) => biz?.name || '');

/**
 *
 * @param array ['1 2','3','4 ...n']
 * @returns data:['1','2','3','4','...n']
 */
export const flattenArray = (array: string[]) => {
  const result: string[] = [];

  array.forEach((item) => {
    result.push(...item.split(' '));
  });

  return result;
};

/**
 *
 * @param ids
 * @param mAreaState
 * @returns [{optionValues:['1']},{optionValues:['2','3']},{optionValues:['4','5']}, ...n]
 */
export const viewStateValues = (ids: string[], mAreaState?: Array<MAreaState | null> | null) => {
  const stateValues = mAreaState?.map((mState: MAreaState | null) => {
    const targetStates = mState?.state?.filter((value) => ids?.includes(String(value?.state_id)));
    const options = targetStates?.map((state) => String(state?.state_id));
    return { optionValues: options || [] };
  });

  return stateValues;
};

/**
 *
 * @param ids
 * @param mBusinessBrand
 * @returns [{optionValues:['1']},{optionValues:['2','3']},{optionValues:['4','5']}, ...n]
 */

export const viewbrandValues = (ids: string[], mBusinessBrand?: Array<MBusinessBrand | null> | null) => {
  const brandValues = mBusinessBrand?.map((mBusiness: MBusinessBrand | null) => {
    const targetBrand = mBusiness?.brand?.filter((value) => ids?.includes(String(value?.brand_id)));
    const options = targetBrand?.map((brand) => String(brand?.brand_id));
    return { optionValues: options || [] };
  });

  return brandValues;
};

/**
 *
 * @param ids
 * @param mAreaState
 * @returns [{optionValues:['1',null]},{optionValues:['2',null,'3']},{optionValues:['4','5']}, ...n]
 * 指定されたidが合ったら追加、そうでない場合nullを追加する
 */
export const formViewStateValues = (ids: string[], mAreaState?: Array<MAreaState | null> | null) => {
  const stateValues = mAreaState?.map((mState: MAreaState | null) => {
    const options = mState?.state?.map((value) =>
      ids?.includes(String(value?.state_id)) ? String(value?.state_id) : null
    );
    return { value: false, optionValues: options || [] };
  });

  return stateValues;
};

/**
 *
 * @param ids
 * @param mBusinessBrand
 * @returns [{optionValues:['1',null]},{optionValues:['2',null,'3']},{optionValues:['4','5']}, ...n]
 * 指定されたidが合ったら追加、そうでない場合nullを追加する
 */

export const formViewBrandValues = (ids: string[], mBusinessBrand?: Array<MBusinessBrand | null> | null) => {
  const brandValues = mBusinessBrand?.map((mBusiness: MBusinessBrand | null) => {
    const options = mBusiness?.brand?.map((value) =>
      ids?.includes(String(value?.brand_id)) ? String(value?.brand_id) : null
    );
    return { value: false, optionValues: options || [] };
  });

  return brandValues;
};

/**
 *
 * @param str
 * @returns const convertString = ['1 3', '4']
 */
export const convertStringToArray = (str: string) => str.split(',').map((item) => item.trim());

/**
 *
 * @param dateString
 * @returns '2023-01-01'
 */
export const formatHyphenDate = (dateString: Date | string) => {
  const date = new Date(dateString);

  const options = {
    year: FormatDateType.Numeric,
    month: FormatDateType.Digit,
    day: FormatDateType.Digit,
  };

  const formattedDate = new Intl.DateTimeFormat('ja-JP', options).format(date);

  const year = formattedDate.slice(0, 4);
  const month = formattedDate.slice(5, 7);
  const day = formattedDate.slice(8, 10);

  return `${year}-${month}-${day}`;
};

/*
 * @param text パーセンテージ値を含むテキスト '5% ～ 20%'
 * @returns パーセンテージ値の配列 ['5%', '20%']
 */
export function extractPercentageValues(text: string): string[] {
  const matches: RegExpMatchArray | null = text.match(/\d+%/g);
  const result: string[] = matches ? matches.map((match) => match.replace('%', '')) : [];
  return result;
}

/**
 *
 * @param date 開始日
 * @param period 期間
 * @returns 2024年5月5日
 */
export const calculateDate = (date: string | Date, period: number): string => {
  const startDate = new Date(date); // 開始日を指定します
  const endDate = new Date(startDate.getTime() + period * 24 * 60 * 60 * 1000); // 開始日からn日後の日付を計算します

  const year = endDate.getFullYear();
  const month = String(endDate.getMonth() + 1).padStart(2, '0');
  const day = String(endDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}年${month}月${day}日`;

  return formattedDate;
};

/**
 *
 * @param booleanArray
 * @returns 全ての項目がcheckStatusと同じだったらcheckStatusをそうでなければcheckStatusの反対を返す
 */
export const checkAllTrue = (booleanArray: Array<boolean | string | null>, checkStatus: boolean) => {
  if (booleanArray.length <= 0) {
    return false;
  }

  for (let i = 0; i < booleanArray.length; i += 1) {
    if (booleanArray[i] !== checkStatus) {
      return !checkStatus;
    }
  }

  return checkStatus;
};

/**
 *
 * @param messages
 * @returns 全てが'ブランドをチェックしてください'だったらtrue or それ以外false
 */
export const checkBrandMessages = (messages: Message[]): boolean => {
  if (messages.length <= 0) {
    return false;
  }

  const hasRequiredMessage = messages.every((obj) =>
    obj.optionValues.every((values) => values.message === 'ブランドをチェックしてください')
  );
  return hasRequiredMessage;
};

/**
 *
 * @param arr
 * @returns ['1', null, '2'] remove ['1', '2']
 */
export const removeNullValues = (ids: (string | null)[]): string[] =>
  ids.filter((item): item is string => item !== null);

/**
 *
 * @param array1
 * @param array2
 * @returns 配列の中身が一緒ならtrueそうじゃなきゃflase
 */
export const arraysAreEqual = (
  array1: Array<string | null>,
  array2: {
    value: string;
    label: string;
  }[]
): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i += 1) {
    if (array1[i] !== array2[i].value) {
      return false;
    }
  }

  return true;
};

const oneDay = 24 * 60 * 60 * 1000;

// 新しい日付を作成して1日を追加する関数
export const addOneDay = (date: Date) => new Date(date.getTime() + oneDay);

/**
 * 指定された値が最小値以上かつ最大値以下であるかを判定します。
 * @param min 最小値
 * @param defaultValue デフォルト値
 * @param max 最大値
 * @returns 最小値以上かつ最大値以下である場合はtrue、そうでない場合はfalse
 */
export function checkRange(min: number, defaultValue: number, max: number): boolean {
  return min <= defaultValue && defaultValue <= max;
}

export function parseBizBrandList(bizIds: string, brandIds: string) {
  if (bizIds.length < 1 || brandIds.length < 1) {
    return [];
  }

  const bizIdsArray = bizIds.split(',');
  const brandIdsArray = brandIds.split(',').map((brandIdsByBiz) => brandIdsByBiz.split(' '));

  return bizIdsArray.map((bizId, index) => ({
    bizId,
    brandIds: brandIdsArray[index],
  }));
}

// 業種/ブランド管理関連のユーティリティ関数
export function handleClickHiddenFileInput(fileRef: React.RefObject<HTMLInputElement>) {
  if (fileRef.current) {
    fileRef.current.click();
  }
}

export function handleFileSet(
  event: React.ChangeEvent<HTMLInputElement>,
  fileType: string,
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>,
  errFunc: () => void
) {
  const fileList = event.target.files;
  if (fileList && fileList.length > 0) {
    const file = fileList[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      if (file.size === 0) {
        errFunc();
        return;
      }
      // 拡張子チェック
      const fileExtension = file.name.split('.').pop();
      if (fileExtension?.toLowerCase() !== fileType) {
        errFunc();
        return;
      }
      setSelectedFile(file);
    };
  }
}

export function iconEditConfirmSubFinction({ fileName, fileInfo, fileIconInputRef }: IconProps) {
  if (fileIconInputRef === undefined) return '';

  let fileUrl = '';
  let fName = '';
  if (fileInfo !== null) {
    fileUrl = URL.createObjectURL(fileInfo);
    fName = fileInfo.name;
  } else if (fileName !== null && fileName !== undefined && fileName !== '') {
    fileUrl = `${process.env.REACT_APP_FILE_READ_URL || ''}/icon/${fileName}`; // S3のURLを指定する
    fName = fileName;
  }

  return (
    <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
      <Text variant="h3" color="blueGray">
        業種アイコン
      </Text>
      <Text variant="h3" color="blueGray">
        150px × 150pxのpngファイルを選択してください。
      </Text>
      <MainButton
        width={160}
        variant="secondary"
        thin
        onClick={() => {
          handleClickHiddenFileInput(fileIconInputRef);
        }}
      >
        画像を選択
      </MainButton>
      <Box display="flex" flexDirection="row" gap={16}>
        <Box>{fileUrl && <img src={fileUrl} width="144px" height="144px" alt="icon" />}</Box>
        <Box display="flex" flexDirection="column" justifyContent="end">
          {/* TODO: エラーチェックは後で修正 */}
          <Text variant="h3" color="blueGray">
            {fName}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
export function skillReRegistrationSubFinction({
  SectionInfo,
  SkillInfo,
  fileSkillSectionInputRef,
  fileSkillCheckInputRef,
}: SkillProps) {
  if (fileSkillSectionInputRef === undefined) return '';
  if (fileSkillCheckInputRef === undefined) return '';

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
        <Text variant="h3" color="blueGray">
          スキルチェック（セクション）
        </Text>
        <MainButton
          width={160}
          variant="secondary"
          thin
          onClick={() => {
            handleClickHiddenFileInput(fileSkillSectionInputRef);
          }}
        >
          ファイル選択
        </MainButton>
        {SectionInfo && (
          <Text variant="h3" color="blueGray">
            {SectionInfo.name}
          </Text>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
        {/* TODO: エラーチェックは後で修正 */}
        <Text variant="h3" color="blueGray">
          スキルチェック（チェック項目）
        </Text>
        <MainButton
          width={160}
          variant="secondary"
          thin
          onClick={() => {
            handleClickHiddenFileInput(fileSkillCheckInputRef);
          }}
        >
          ファイル選択
        </MainButton>
        {SkillInfo && (
          <Text variant="h3" color="blueGray">
            {SkillInfo.name}
          </Text>
        )}
      </Box>
    </Box>
  );
}
