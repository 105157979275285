/**
 * Figma ID: 09-02-01, 09-02-02, 09-02-03, 09-02-04, 09-02-05
 * 新規作成 確認 完了 親
 */

import { Modal } from 'components/newModal';
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { CreateNotifyInfoModalForm } from 'pages/s09/type';
import { CreateModal as createModal } from './createModal';
import { ConfirmPublishedModal as confirmPublishedModal } from './confirmPublishedModal';
import { completePublishedModal } from './completePublishedModal';
import { completeRoughDrafhModal } from './completeRoughDrafhModal';

enum ModalStep {
  CREATE = 1,
  CONFIRM_PUBLISHD = 2,
  COMPLETE_ROUGH_DRAHT = 3,
  COMPLETE_PUBLISHED = 4,
}

export function CreateNotifyInfoModal(props: { isOpen: boolean; onClose: () => void }) {
  const { isOpen, onClose } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.CREATE);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateNotifyInfoModalForm>();

  const onModalClose = useCallback(() => {
    setModalStep(ModalStep.CREATE);
    reset();
    onClose();
  }, [onClose, reset]);

  const createModalContent = createModal(
    isOpen,
    control,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    setValue,
    onModalClose,
    errors,
    () => setModalStep(ModalStep.COMPLETE_ROUGH_DRAHT),
    () => setModalStep(ModalStep.CONFIRM_PUBLISHD)
  );

  const confirmPublishedModalContent = confirmPublishedModal(
    control,
    getValues,
    onModalClose,
    () => setModalStep(ModalStep.CREATE),
    () => setModalStep(ModalStep.COMPLETE_PUBLISHED)
  );

  const completeRoughDraftModalContent = completeRoughDrafhModal(onModalClose);

  const completePublishedModalContent = completePublishedModal(onModalClose);

  const modalContent = {
    [ModalStep.CREATE]: createModalContent,
    [ModalStep.CONFIRM_PUBLISHD]: confirmPublishedModalContent,
    [ModalStep.COMPLETE_ROUGH_DRAHT]: completeRoughDraftModalContent,
    [ModalStep.COMPLETE_PUBLISHED]: completePublishedModalContent,
  };

  return <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />;
}
