import React from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { PRIMARY_COLOR, GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  currentValue: string;
  options: {
    value: string;
    label: string;
    activeColor?: string;
  }[];
  width?: number;
  onClick?: (value: string) => void;
}

const StyledDiv = styled.div<{
  width: number;
}>`
  width: ${({ width }) => width}px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
`;

const StyledButton = styled.button<{
  color: string;
  width: number;
  isLeft: boolean;
  clickable: boolean;
}>`
  width: ${({ width }) => width}px;
  background-color: ${({ color }) => color};
  height: 32px;
  box-sizing: border-box;
  border: none;
  border-radius: ${({ isLeft }) => (isLeft ? '4px 0 0 4px' : '0 4px 4px 0')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

export function ToggleButton({ currentValue, options, width = 241, onClick = () => {} }: Props) {
  return (
    <StyledDiv width={width}>
      {options.map(({ value, label, activeColor = PRIMARY_COLOR.BLUE }, index) => (
        <StyledButton
          key={value}
          type="button"
          width={width / 2}
          color={currentValue === value ? activeColor : GENERIC_COLOR.WHITE}
          isLeft={index === 0}
          clickable={value !== currentValue}
          onClick={() => value !== currentValue && onClick(value)}
        >
          <Text variant="body14" color={currentValue === value ? 'white' : 'blueGray'} bold>
            {label}
          </Text>
        </StyledButton>
      ))}
    </StyledDiv>
  );
}
