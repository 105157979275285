import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { ModalRet, VtMemberListEditKey } from 'pages/s03/type';
import React, { useEffect } from 'react';
import { useGetMStateLazyQuery, useGetMCityByStateIdLazyQuery, MState, MCity } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Control, UseFormGetValues, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { ErrorMessage } from 'components/errorMessage';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

const ConfirmText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #484848;
`;

enum ZipCode {
  VALID_LENGTH = 7,
}

export function EnterStoreInfoModal(
  control: Control<VtMemberListEditKey>,
  getValues: UseFormGetValues<VtMemberListEditKey>,
  setValue: (name: keyof VtMemberListEditKey, value: string) => void,
  handleSubmit: UseFormHandleSubmit<VtMemberListEditKey>,
  errors: FieldErrors<VtMemberListEditKey>,
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const currentStateId = useWatch({ control, name: 'stateId' });
  const { openErrorModal } = useErrorModal();

  const zipCode1Length = 3;
  const zipCode2Length = 4;
  const [getMState, { data: mStateData, loading: mStateLoading, error: mStateError }] = useGetMStateLazyQuery({
    context: { clientName: 'master' },
  });
  useEffect(() => {
    if (mStateData === undefined) {
      getMState();
    }
  }, [getMState, mStateData]);
  const mState = mStateData?.getMState as MState[];
  const mStateList =
    mState?.map((item) => ({
      value: String(item.id),
      label: item.name,
    })) || [];

  const [getMCityByStateId, { data: mCityByStateIdData, loading: mCityByStateIdLoading, error: mCityByStateIdError }] =
    useGetMCityByStateIdLazyQuery({
      context: { clientName: 'master' },
      variables: { sid: Number(getValues('state')) },
    });

  useEffect(() => {
    if (mStateError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mStateError, openErrorModal]);
  useEffect(() => {
    if (mCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [mCityByStateIdError, openErrorModal, onClose]);

  let mCityList = getValues('city') ?? ([] as { label: string; value: string }[]);
  const mCity = mCityByStateIdData?.getMCityByStateId as MCity[];
  if (mCity === undefined) {
    mCityList = [{ label: '', value: '' }];
  } else {
    mCityList =
      mCity.map((item) => ({
        label: item.name,
        value: String(item.name),
      })) || [];
  }
  useEffect(() => {
    // 入力に合わせてDBから取得した郵便番号7桁を上位3桁と下位4桁に分割
    if (getValues('zip_code')?.length === ZipCode.VALID_LENGTH && getValues('zip_code') !== undefined) {
      setValue('zip_code1', getValues('zip_code')?.slice(0, 3) ?? '');
      setValue('zip_code2', getValues('zip_code')?.slice(3, 7) ?? '');
    }
  }, [getValues, setValue]);

  useEffect(() => {
    if (currentStateId !== undefined) {
      getMCityByStateId({
        variables: { sid: Number(currentStateId) },
      });
    }
  }, [currentStateId, getMCityByStateId]);

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      onNext();
    }
  });

  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = getValues('biz_name') ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = getValues('brand_name') ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        編集 店舗情報
      </Text>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
        <Text variant="body14">店舗情報を入力して「次へ」を押してください。</Text>
        <Box display="flex" flexDirection="row" gap={13}>
          <Box display="flex" flexDirection="column" width={328} gap={10}>
            <Text variant="h3" color="blueGray">
              業種
            </Text>
            <ConfirmText>{editBizName}</ConfirmText>
          </Box>
          <Box display="flex" flexDirection="column" width={328} gap={10}>
            <Text variant="h3" color="blueGray">
              ブランド
            </Text>
            <ConfirmText>{editBrandName}</ConfirmText>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            店舗名
          </Text>
          <Box>
            <RhfInput
              name="store_name"
              control={control}
              defaultValue={getValues('store_name') || ''}
              fullWidth
              placeholder=""
              rules={{ required: '店舗名を入力してください' }}
            />
            {errors?.store_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.store_name.message}</ErrorMessage>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            郵便番号
          </Text>
          <Box display="flex" flexDirection="row" gap={16} alignItems="center">
            <Box>
              <RhfInput
                name="zip_code1"
                control={control}
                width={120}
                defaultValue=""
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください',
                  validate: (value) =>
                    String(value).length === zipCode1Length || `${zipCode1Length}桁の数字を入力してください`,
                }}
              />
            </Box>
            <Divider option="horizontal" length={7} />
            <Box>
              <RhfInput
                name="zip_code2"
                control={control}
                width={120}
                defaultValue=""
                placeholder=""
                type="number"
                rules={{
                  required: '郵便番号を入力してください',
                  validate: (value) =>
                    String(value).length === zipCode2Length || `${zipCode2Length}桁の数字を入力してください`,
                }}
              />
            </Box>
          </Box>
          {(errors?.zip_code1 || errors.zip_code2) && (
            <Box pt={4}>
              <ErrorMessage>{errors.zip_code1?.message ?? errors.zip_code2?.message}</ErrorMessage>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            住所
          </Text>
          <Box display="flex" flexDirection="column" gap={18}>
            <Box display="flex" flexDirection="row" gap={16}>
              <Box>
                <RhfSelect
                  width={328}
                  control={control}
                  name="state_name"
                  options={mStateList}
                  defaultValue={getValues('state_name')}
                  placeholder={getValues('state_name') ?? '都道府県'}
                  rules={{ required: '都道府県を選択してください' }}
                  onChange={(val) => setValue('stateId', String(val))}
                />
                {errors?.state && (
                  <Box pt={4}>
                    <ErrorMessage>{errors?.state.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
              <Box>
                <RhfSelect
                  width={328}
                  control={control}
                  name="city"
                  options={mCityList}
                  defaultValue=""
                  placeholder={getValues('city') ?? '市区町村'}
                  rules={{ required: '市区町村を選択してください' }}
                />
                {errors?.city && (
                  <Box pt={4}>
                    <ErrorMessage>{errors?.city.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap={16}>
              <Box>
                <RhfInput
                  name="address"
                  control={control}
                  defaultValue={getValues('address') || ''}
                  width={328}
                  placeholder="番地等"
                  rules={{ required: '番地等を入力してください' }}
                />
                {errors?.address && (
                  <Box pt={4}>
                    <ErrorMessage>{errors?.address.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
              <Box>
                <RhfInput
                  name="address2"
                  control={control}
                  defaultValue={getValues('address2') || ''}
                  width={328}
                  placeholder="建物名・部屋番号"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={5.5}>
          <Text variant="h3" color="blueGray">
            電話番号
          </Text>
          <RhfInput
            name="phone"
            control={control}
            width={328}
            placeholder="電話番号"
            type="number"
            rules={{
              required: '電話番号（数字のみ）を入力してください',
              pattern: {
                value: /^\d*$/,
                message: '数字のみを入力してください',
              },
            }}
          />
          {errors?.phone && <ErrorMessage>{errors?.phone.message}</ErrorMessage>}
        </Box>

        <Box display="flex" flexDirection="row" gap={16}>
          <Box display="flex" flexDirection="column" gap={5.5}>
            <Text variant="h3" color="blueGray">
              緯度
            </Text>
            <RhfInput
              name="lat"
              control={control}
              width={328}
              placeholder="緯度"
              type="number"
              rules={{
                required: '緯度を入力してください',
                max: {
                  value: 90,
                  message: '90以下の数字を入力してください。',
                },
                min: {
                  value: -90,
                  message: '-90以上の数字を入力してください。',
                },
              }}
            />
            {errors?.lat && <ErrorMessage>{errors?.lat.message}</ErrorMessage>}
          </Box>
          <Box display="flex" flexDirection="column" gap={5.5}>
            <Text variant="h3" color="blueGray">
              経度
            </Text>
            <RhfInput
              name="lng"
              control={control}
              width={328}
              placeholder="経度"
              type="number"
              rules={{
                required: '経度を入力してください',
                max: {
                  value: 180,
                  message: '180以下の数字を入力してください。',
                },
                min: {
                  value: -180,
                  message: '-180以上の数字を入力してください。',
                },
              }}
            />
            {errors?.lng && <ErrorMessage>{errors?.lng.message}</ErrorMessage>}
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {(mStateLoading || mCityByStateIdLoading) && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={(data) => {
            onClickHandler(data);
            setValue('zip_code', `${getValues('zip_code1')}${getValues('zip_code2')}`);
          }}
        >
          次へ
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 723,
  };

  return modalContent;
}
