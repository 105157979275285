import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { BaseInfoEditKey, InfoItemsType, ModalRet } from 'pages/s03/type';
import React from 'react';
import { Info } from 'components/info';
import { UseFormGetValues } from 'react-hook-form';
import { MainButton } from 'components/mainButton';
import { useUpdateTManagerTMemberMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import uploadFile from 'components/api/fileUpload';

export function CustomRow(props: { datas: { label: string; content: React.ReactNode }[] }) {
  const { datas } = props;

  const element = datas.map(({ label, content }) => (
    <Box display="flex" flex="row" py={4} gap={8} alignItems="center">
      <Box width={112}>
        <Text variant="body14" color="darkGray">
          {label}
        </Text>
      </Box>
      <Text variant="body14">{content}</Text>
    </Box>
  ));

  return <div>{element}</div>;
}

export function ConfirmModal(
  id: number,
  getValues: UseFormGetValues<BaseInfoEditKey>,
  onClose: () => void,
  onNext: () => void,
  onBack: () => void,
  imageFile: File | null
): ModalRet {
  const [updateTManagerTMemberMutation, { loading: tManagerTMemberLoading }] = useUpdateTManagerTMemberMutation();
  const { openErrorModal } = useErrorModal();

  const infoItems: InfoItemsType = [
    { label: 'ID', content: getValues('id') },
    { label: '業種', content: getValues('biz_name') },
    { label: 'ブランド', content: getValues('brand_name') },
    { label: '店舗名', content: getValues('store_name') },
    {
      label: '住所',
      content: `〒${getValues('zip_code')?.slice(0, 3) ?? ''}-${getValues('zip_code')?.slice(3, 7) ?? ''}
        ${getValues('state') ?? ''}${getValues('city') ?? ''}${getValues('address') ?? ''}${
        getValues('address2') ?? ''
      }`,
    },
    { label: '電話番号', content: getValues('phone') },
    { label: 'メールアドレス(ID)', content: getValues('email') },
    { label: '営業時間', content: getValues('opening') },
    { label: '駐車場', content: getValues('parking') },
    { label: '責任者氏名', content: `${getValues('mgr_last_name') ?? ''} ${getValues('mgr_first_name') ?? ''}` },
    {
      label: '責任者氏名(フリガナ)',
      content: `${getValues('mgr_last_kana') ?? ''} ${getValues('mgr_first_kana') ?? ''}`,
    },
    {
      label: '責任者生年月日',
      content: `${getValues('mgr_dob_year') ?? ''}年${getValues('mgr_dob_month') ?? ''}月${
        getValues('mgr_dob_day') ?? ''
      }日`,
    },
    { label: '責任者電話番号', content: getValues('mgr_phone') },
    {
      label: '事業者',
      content: (
        <Box display="flex" flexDirection="column" gap={8}>
          <Text variant="body14">{`${getValues('mco_name') ?? ''}`}</Text>
          <Text variant="body14">
            {`〒${getValues('mco_zip_code')?.slice(0, 3) ?? ''}-${getValues('mco_zip_code')?.slice(3, 7) ?? ''}
              ${getValues('mco_state') ?? ''}${getValues('mco_city') ?? ''}${getValues('mco_address') ?? ''}${
              getValues('mco_address2') ?? ''
            }`}
          </Text>
          <CustomRow
            datas={[
              { label: '法人番号', content: getValues('corporate_no') },
              { label: '電話番号', content: getValues('phone') },
              {
                label: '代表者',
                content: `${getValues('mco_m_last_name') ?? ''} ${getValues('mco_m_first_name') ?? ''}`,
              },
              { label: '', content: `${getValues('mco_m_last_kana') ?? ''} ${getValues('mco_m_first_kana') ?? ''}` },
              {
                label: '担当者',
                content: `${getValues('mco_t_last_name') ?? ''} ${getValues('mco_t_first_name') ?? ''}`,
              },
              { label: '', content: `${getValues('mco_t_last_kana') ?? ''} ${getValues('mco_t_first_kana') ?? ''}` },
              { label: '担当者電話番号', content: `${getValues('mco_t_phone') ?? ''}` },
            ]}
          />
        </Box>
      ),
    },
    {
      label: '入金口座',
      content: `${getValues('bank') || ''} ${getValues('branch_name') || ''} ${getValues('account_type') || ''} ${
        getValues('account_no') || ''
      } ${getValues('account_name') || ''}`,
    },
  ];

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        基本情報編集 - 確認
      </Text>
    ),
    content: (
      <Box display="flex" height="auto" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={24} width="100%">
          <Text variant="body14">下記内容で登録します。よろしければ完了を押してください。</Text>
          <Box display="flex" flexDirection="column" gap={8}>
            <Divider option="horizontal" />
            <Box gap={5.5} display="flex" flexDirection="column">
              <Text variant="body14" color="darkGray">
                店舗画像
              </Text>
              <img src={getValues('image') || ''} alt="" style={{ width: 160, height: 120 }} />
              <Divider option="horizontal" />
            </Box>
            <Info items={infoItems} />
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {tManagerTMemberLoading && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            const imageFileName = imageFile?.name || '';
            const imagePath = `store-data/${id}.${imageFileName.split('.').pop() ?? ''}`;

            if (imageFile) {
              uploadFile({
                filePath: imagePath,
                file: imageFile,
              });
            }

            updateTManagerTMemberMutation({
              variables: {
                first_name: getValues('mgr_first_name') ?? '',
                last_name: getValues('mgr_last_name') ?? '',
                first_kana: getValues('mgr_first_kana') ?? '',
                last_kana: getValues('mgr_last_kana') ?? '',
                dob: `${getValues('mgr_dob_year') ?? ''}-${getValues('mgr_dob_month') ?? ''}-${
                  getValues('mgr_dob_day') ?? ''
                }`,
                phone: getValues('phone') ?? '',
                tpmem_id: id,
                open_time: getValues('open_time') ?? '',
                close_time: getValues('close_time') ?? '',
                parking: getValues('parking') === '有',
                image: imageFile
                  ? `${process.env.REACT_APP_FILE_READ_URL || ''}/${imagePath}`
                  : getValues('image') || '',
                corporate_type: Number(getValues('corporate_type')),
                corporate_no: getValues('corporate_no') ?? '',
                mco_name: getValues('mco_name') ?? '',
                mco_zip_code: getValues('mco_zip_code') ?? '',
                mco_state: getValues('mco_state') ?? '',
                mco_city: getValues('mco_city') ?? '',
                mco_address1: getValues('mco_address') ?? '',
                mco_address2: getValues('mco_address2') ?? '',
                mco_phone: getValues('mco_phone') ?? '',
                mco_m_first_name: getValues('mco_m_first_name') ?? '',
                mco_m_last_name: getValues('mco_m_last_name') ?? '',
                mco_m_first_kana: getValues('mco_m_first_kana') ?? '',
                mco_m_last_kana: getValues('mco_m_last_kana') ?? '',
                mco_t_first_name: getValues('mco_t_first_name') ?? '',
                mco_t_last_name: getValues('mco_t_last_name') ?? '',
                mco_t_first_kana: getValues('mco_t_first_kana') ?? '',
                mco_t_last_kana: getValues('mco_t_last_kana') ?? '',
                mco_t_phone: getValues('mco_t_phone') ?? '',
              },
            })
              .then((e) => {
                if (!e.errors) {
                  onNext();
                } else {
                  onClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              })
              .catch(() => {
                onClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }}
        >
          完了
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 558,
  };

  return {
    ...modalContent,
  };
}
