import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useEffect, useState } from 'react';
import { SelectForm } from 'components/selectForm';
import { ModalStep } from '../constants';
import { MiniModalViewDataTyep } from '../s10-03-types';

/**
 * Figma ID: 10-03-01-02, 10-03-01-03, 10-03-01-04
 * 名称: 募集期間重なり%
 * Figma ID: 10-03-01-05, 10-03-01-06, 10-03-01-07
 * 名称: 有効半径
 * Figma ID: 10-03-07-02, 10-03-07-03, 10-03-07-04
 * 名称: ボーナス設定
 */

interface Props {
  modalStep: ModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  onClose: () => void;
  modalViewData?: MiniModalViewDataTyep;
  mutation?: (data?: string | number | null) => void;
}

export function BasicModal({ modalStep, setModalStep, modalViewData, mutation, onClose }: Props) {
  const defaultValue = React.useMemo(
    () => (modalViewData?.data !== undefined ? String(modalViewData?.data) : ''),
    [modalViewData]
  );
  const [modalData, setModalData] = useState<string>(defaultValue);

  useEffect(() => {
    if (modalViewData) {
      setModalData(defaultValue);
    }
  }, [modalViewData, defaultValue]);

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.INPUT:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.input}
            </Text>
          ),
          content: (
            <Box pa={8}>
              <Box alignItems="center" backgroundColor="white" display="flex" gap={8}>
                <SelectForm
                  options={modalViewData?.options || []}
                  label={modalViewData?.label || ''}
                  onChange={(value) => setModalData(value)}
                  placeholder={modalViewData?.placeholder || ''}
                  value={modalData}
                  width={120}
                />
                <Box mt={10}>
                  <Text color="blueGray" variant="body14">
                    {modalViewData?.unit}
                  </Text>
                </Box>
              </Box>
              {modalViewData?.infoText ? (
                <Box>
                  <Text color="black" variant="body14">
                    {modalViewData?.infoText}
                  </Text>
                </Box>
              ) : null}
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalData(defaultValue);
                  setModalStep(0);
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(2);
                }}
                variant="primary"
                width={104}
                disabled={!modalData}
              >
                変更
              </MainButton>
            </Box>
          ),
          onClose: () => setModalStep(0),
        };
      case ModalStep.CHECK:
        return {
          width: 384,
          height: "auto",
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.check}
            </Text>
          ),
          content: (
            <Box>
              <Text variant="body14">
                以下の{modalViewData?.text}に設定してよろしいですか？
                <br />
              </Text>
              <Box mt={16}>
                <Text variant="body14">
                  <Text variant="body14" bold>
                    ・
                  </Text>
                  {modalData}
                  {modalViewData?.unit}
                </Text>
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalData(defaultValue);
                  setModalStep(0);
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(1);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  if (mutation) {
                    mutation(Number(modalData));
                  }
                }}
                variant="primary"
                width={104}
              >
                設定
              </MainButton>
            </Box>
          ),
          onClose: () => setModalStep(0),
        };
      case ModalStep.COMPLETE:
        return {
          width: 384,
          height: "auto",
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.complete}
            </Text>
          ),
          content: (
            <Box pa={8}>
              <Text variant="body14">
                情報の設定が完了しました。
                <br />
              </Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(0);
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
          onClose: () => onClose(),
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose: () => setModalStep(0),
        };
    }
  }, [modalData, modalStep, modalViewData, mutation, onClose, setModalStep, defaultValue]);
  return { displayModal };
}
