import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { Icon } from '../icon';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  items: {
    label: string;
    labelLength?: number;
    content: ReactNode;
    hasNotification?: boolean;
    callBack?: (index: number) => void;
  }[];
  defaultIndex?: number;
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTabWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  padding: 0 16px;
  gap: 4px;
`;

const StyledTab = styled.button<{
  active: boolean;
  labelLength: number;
}>`
  position: relative;
  height: 40px;
  min-width: ${({ labelLength }) => labelLength}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  background-color: ${({ active }) => (active ? 'white' : '#FBFBFB')};
  cursor: pointer;
`;

const StyledWhiteBar = styled.div<{
  labelLength: number;
}>`
  height: 3px;
  width: ${({ labelLength }) => labelLength - 2}px;
  background-color: ${GENERIC_COLOR.WHITE};
  position: absolute;
  bottom: -2px;
  box-sizing: border-box;
`;

const StyledIndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
`;

export function Tab({ items, defaultIndex }: Props) {
  const [tab, setTab] = React.useState<number>(defaultIndex || 0);

  React.useEffect(() => {
    if (defaultIndex !== undefined) setTab(defaultIndex);
  }, [defaultIndex]);

  return (
    <StyledWrapper>
      <StyledTabWrapper>
        {items.map((item, index) => (
          <StyledTab
            type="button"
            role="tab"
            active={tab === index}
            onClick={() => {
              setTab(index);
              if (item.callBack) item.callBack(index);
            }}
            labelLength={item.labelLength || 120}
          >
            <Text variant="body14" bold color={tab === index ? 'primaryBlue' : 'liteGray'}>
              {item.label}
            </Text>
            {tab === index && <StyledWhiteBar labelLength={item.labelLength || 120} />}
            {item.hasNotification && (
              <StyledIndicatorWrapper>
                <Icon name="indicatorRed" size={8} />
              </StyledIndicatorWrapper>
            )}
          </StyledTab>
        ))}
      </StyledTabWrapper>
      {items[tab].content}
    </StyledWrapper>
  );
}
