import { Box } from 'components/box';
import { Info } from 'components/info';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { InfoItemsType, ModalRet, VtMemberListEditKey } from 'pages/s03/type';
import React from 'react';
import { useUpdateTBaseByIdMutation, useGetMStateByIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';
import { useErrorModal } from 'components/error/errorModalProvider';

export function ConfirmModal(
  control: Control<VtMemberListEditKey>,
  getValues: UseFormGetValues<VtMemberListEditKey>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();
  const currentStateId = useWatch({ control, name: 'stateId' });
  const [updateTBaseById, { loading: updateTBaseByIdLoading }] = useUpdateTBaseByIdMutation();
  const [getMStateById, { data: mStateByIdData, loading: mStateByIdLoading, error: mStateByIdError }] =
    useGetMStateByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentStateId) },
    });
  const StateName = mStateByIdData?.getMStateById?.name;
  React.useEffect(() => {
    if (currentStateId !== undefined) {
      getMStateById({
        variables: { mid: Number(currentStateId) },
      });
    }
  }, [currentStateId, getMStateById, StateName]);

  React.useEffect(() => {
    if (mStateByIdError) {
      onClose();
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mStateByIdError, openErrorModal, onClose]);

  const infoItems: InfoItemsType = [
    { label: '業種', content: getValues('biz_name') },
    { label: 'ブランド', content: getValues('brand_name') },
    { label: '店舗名', content: getValues('store_name') },
    {
      label: '住所',
      content: `〒${getValues('zip_code')?.slice(0, 3) ?? ''}-${getValues('zip_code')?.slice(3, 7) ?? ''} 
      ${StateName ?? getValues('state_name')}${getValues('city') ?? ''}
      ${getValues('address') ?? ''}${getValues('address2') ?? ''}`,
    },
    { label: '電話番号', content: getValues('phone') },
    { label: '緯度/経度', content: `${(getValues('lat') || 0).toString()}/${(getValues('lng') || 0).toString()}` },
  ];

  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        編集 確認
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pa={8} width="100%">
          <Text variant="body14">下記内容で登録します。よろしければ登録を押してください。</Text>
          <Box display="flex" flexDirection="column">
            <Divider option="horizontal" />
            <Info items={infoItems} />
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {(updateTBaseByIdLoading || mStateByIdLoading) && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            updateTBaseById({
              variables: {
                address1: getValues('address') ?? '',
                address2: getValues('address2') ?? '',
                city: getValues('city') ?? '',
                id: getValues('id'),
                lat: Number(getValues('lat')),
                lng: Number(getValues('lng')),
                name: getValues('store_name') ?? '',
                phone: getValues('phone') ?? '',
                state: StateName ?? getValues('state_name'),
                zip_code: getValues('zip_code') ?? '',
              },
            })
              .then((e) => {
                if (!e.errors) {
                  onNext();
                } else {
                  onClose();
                  openErrorModal({
                    message:
                      '1サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              })
              .catch((e) => {
                onClose();
                openErrorModal({
                  message:
                    '2サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }}
        >
          登録
        </MainButton>
      </Box>
    ),
    height: 476,
    width: 800,
  };

  return {
    ...modalContent,
  };
}
