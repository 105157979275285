/**
 * Figma ID: 10-01-06-01
 * 名称: 管理アカウント（削除）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';

type Props = {
  isDeleteMe: boolean;
  onClickClose: () => void;
  toNextPhase: () => void;
};

export function DeleteAccountModal({ isDeleteMe, onClickClose, toNextPhase }: Props) {
  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            削除
          </Text>
        </Box>
      }
      content={
        isDeleteMe ? (
          <Box pa={8}>
            <Box>
              <Text variant="body14">本当に自分のアカウントを削除しますか？</Text>
            </Box>
            <Box>
              <Text variant="body14">削除した情報は元に戻せません。</Text>
            </Box>
            <Box>
              <Text variant="body14">削除実施後は本システムからログアウトします。</Text>
            </Box>
          </Box>
        ) : (
          <Box pa={8}>
            <Box>
              <Text variant="body14">本当にアカウントを削除しますか？</Text>
            </Box>
            <Box>
              <Text variant="body14">削除した情報は元に戻せません。</Text>
            </Box>
          </Box>
        )
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton onClick={toNextPhase} width={104}>
            削除
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height="auto"
    />
  );
}
