import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import Divider from 'components/divider/divider';
import { Input } from 'components/input';
import { List } from 'components/list';
import { MainButton } from 'components/mainButton';
import { PaginationBox } from 'components/pagination';
import { Select } from 'components/select';
import { Text } from 'components/text';
import React, { ChangeEvent, useState, useMemo, useEffect, useCallback } from 'react';
import * as gql from 'graphql/graphql-ow';
import { Icon } from 'components/icon';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { usePagenation, useSort } from 'components/utils';
import { BitravelerInfoStatus } from 'components/const';
import { orderBy } from 'lodash';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ChangeSmallImgWithStatus } from './[id]/components/changeSmallImgWithStatus';
import { backgroundColor, formatZipCode } from './s05-utils';
import { PageNumber } from './constants';

/**
 * Figma ID: 05-01-01
 * 名称: バイトラベル会員
 */
const StyledListWrapper = styled.div`
  height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  overflow: auto;
`;

type FormProps = {
  name: string;
  state: {
    // 都道府県データ
    label: string;
    value: string;
  };
  city: {
    // 市区町村データ
    label: string;
    value: string;
  };
  rank: {
    // ランクデータ
    label: string;
    value: string;
  };
  age: {
    // 年齢データ
    label: string;
    value: string;
  };
  gender: {
    // 性別データ
    label: string;
    value: string;
  };
  attribute: {
    // 属性データ
    label: string;
    value: string;
  };
  belong: {
    // 所属の有無
    label: string;
    value: string;
  };
  status: {
    // 状態データ
    label: string;
    value: string;
  };
};

const Indicator = styled.div`
  position: absolute;
  top: -6px;
  right: 0;
`;
const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export function S05() {
  const { data, loading, error } = gql.useGetVbMemberListQuery({
    fetchPolicy: 'no-cache',
  });

  const {
    data: stateData,
    loading: stateLoading,
    error: stateError,
  } = gql.useGetMStateQuery({ context: { clientName: 'master' } });

  const {
    data: cityData,
    loading: cityLoading,
    error: cityError,
  } = gql.useGetMCityQuery({
    context: { clientName: 'master' },
  });

  const [getCityByStateId, { data: cityByStateIdData, loading: cityByStateIdLoading, error: cityByStateIdError }] =
    gql.useGetMCityByStateIdLazyQuery({
      context: { clientName: 'master' },
    });

  const {
    data: attributeData,
    loading: cattributeLoading,
    error: attributeError,
  } = gql.useGetMOccAttrQuery({
    context: { clientName: 'master' },
  });

  const {
    data: memberRankData,
    loading: memberRankLoading,
    error: memberRankError,
  } = gql.useGetMBtmemRankQuery({
    context: { clientName: 'master' },
  });

  const { openErrorModal } = useErrorModal();

  if (error || stateError || cityError || cityByStateIdError || attributeError || memberRankError) {
    openErrorModal({ message: 'データ取得に失敗しました。' });
  }

  const [cityOption, setCityOption] = useState<
    {
      label: string;
      value: string;
    }[]
  >();
  // 選択された都道府県のIDを保持するステート
  const [selectedStateId, setSelectedStateId] = useState('');

  // 都道府県が選択されたらselectedStateIdを更新する関数
  const handleStateSelect = (stateId: string) => {
    setSelectedStateId(stateId);
  };

  // 都道府県が選択されたら市区町村データを取得する関数
  const fetchCityDataByStateId = useCallback(
    (stateId: number) => {
      getCityByStateId({ variables: { sid: stateId } }).then(() => {
        const updatedCityOption = [
          ...(cityByStateIdData?.getMCityByStateId
            ?.map((city) => ({
              label: city?.name || '',
              value: city?.name || '',
            }))
            .filter((option) => option !== null && option !== undefined) || []),
        ];
        setCityOption(updatedCityOption);
      });
    },
    [cityByStateIdData?.getMCityByStateId, getCityByStateId]
  );

  // 都道府県が変更されたら市区町村データを取得する
  useEffect(() => {
    if (selectedStateId) {
      fetchCityDataByStateId(Number(selectedStateId));
    }
  }, [cityData?.getMCity, fetchCityDataByStateId, selectedStateId]);

  // cityDataの更新を監視してcityOptionを更新する
  useEffect(() => {
    if (selectedStateId && cityData?.getMCity) {
      const updatedCityOption = [
        ...(cityData.getMCity
          .map((city) => ({
            label: city?.name || '',
            value: city?.name || '',
          }))
          .filter((option) => option !== null && option !== undefined) || []),
      ];
      setCityOption(updatedCityOption);
    } else {
      // 都道府県が選択されていない場合はcityOptionを空にする
      setCityOption([]);
    }
  }, [selectedStateId, cityData]);

  const navigate = useNavigate();

  const [dataSize, setDataSize] = useState<number>(0);
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  // ここからソート系
  const [searchConditions, setSearchConditions] = useState<{ name: string } | null>(null);
  const [form, setForm] = useState<FormProps>({
    name: '',
    state: { label: '', value: '' },
    city: { label: '', value: '' },
    rank: { label: '', value: '' },
    age: { label: '', value: '' },
    attribute: { label: '', value: '' },
    gender: { label: '', value: '' },
    belong: { label: '', value: '' },
    status: { label: '', value: '' },
  });

  const headerColumnList = [
    {
      key: 'eye',
      width: 24,
    },
    {
      key: 'image',
      width: 40,
    },
    {
      columnName: '氏名',
      key: 'name',
      width: 120,
    },
    {
      columnName: '年齢',
      key: 'age',
      width: 56,
    },
    {
      columnName: '会員番号',
      key: 'btmem_number',
      width: 112,
    },
    {
      columnName: 'メールアドレス(ID)',
      key: 'email',
      width: 160,
    },
    {
      columnName: '電話番号',
      key: 'phone',
      width: 120,
    },
    {
      columnName: '生年月日',
      key: 'birthday',
      width: 120,
    },
    {
      columnName: '性別',
      key: 'gender',
      width: 56,
    },
    {
      columnName: '属性',
      key: 'attribute',
      width: 104,
    },
    {
      columnName: '住所',
      key: 'address',
      width: 240,
    },
    {
      columnName: 'スコア',
      key: 'score',
      width: 70,
    },
    {
      columnName: '利用回数',
      key: 'worked_count',
      width: 104,
    },
    {
      columnName: 'キャンセル率',
      key: 'cancel_per',
      width: 105,
    },
    {
      columnName: '所属店舗1',
      key: 'tpmem_name1',
      width: 240,
    },
    {
      columnName: '所属店舗1 登録日',
      key: 'tpmem_date1',
      width: 184,
    },
    {
      columnName: 'サブ所属店舗1',
      key: 'sub_name1',
      width: 240,
    },
    {
      columnName: '所属店舗2',
      key: 'tpmem_name2',
      width: 240,
    },
    {
      columnName: '所属店舗2 登録日',
      key: 'tpmem_date2',
      width: 184,
    },
    {
      columnName: 'サブ所属店舗2',
      key: 'sub_name2',
      width: 240,
    },
    {
      columnName: '制服サイズ',
      key: 'uniform_size',
      width: 104,
    },
  ];

  const allBizIds = useMemo(() => {
    if (data?.getVBMemberList && data.getVBMemberList.length > 0) {
      return data.getVBMemberList[0].all_biz_ids?.split(',').reverse() || [];
    }
    return [];
  }, [data?.getVBMemberList]);
  // 省略表示文字数
  const bizNameMax = 10;
  if (data?.getVBMemberList && data.getVBMemberList.length > 0) {
    data?.getVBMemberList[0].all_biz_names
      ?.split(',')
      .reverse()
      .forEach((bizName, index) => {
        const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
        // ヘッダー項目のサイズを取得 ボタン+ランク()+業種
        const calBizWidth = 38 + 4 * 12 + editBizName.length * 12;
        headerColumnList.splice(12, 0, {
          columnName: `ランク(${editBizName})`,
          key: `${typeof allBizIds !== 'undefined' ? allBizIds[index] : ''}`,
          width: calBizWidth,
        });
      });
  }

  const items = useMemo(() => {
    if (loading) {
      return [];
    }

    let memberList = data?.getVBMemberList || [];

    /**
     * Statusでフィルター
     */
    if (form.status.value && form.status.value !== '0') {
      memberList = memberList.filter(({ status }) => {
        switch (form.status.value) {
          case '1':
            return status === BitravelerInfoStatus.ACTIVE;
          case '2':
            return status === BitravelerInfoStatus.TEMPORARY_PAUSE;
          case '3':
            return status === BitravelerInfoStatus.STOP;
          case '4':
            return status === BitravelerInfoStatus.PAUSE;
          case '':
          default:
            return true;
        }
      });
    }

    /**
     * stateでフィルター
     */
    if (form.state.value) {
      memberList = memberList.filter(({ state }) => form.state.label === state);
    }

    /**
     * cityでフィルター
     */
    if (form.city.value) {
      memberList = memberList.filter(({ city }) => form.city.value === city);
    }

    /**
     * genderでフィルター
     */
    if (form.gender.value && form.gender.value !== 'all') {
      memberList = memberList.filter(({ gender }) => form.gender.value === gender);
    }

    /**
     * attributeでフィルター
     */
    if (form.attribute.value && form.attribute.value !== 'all') {
      memberList = memberList.filter(({ attribute }) => form.attribute.value === attribute);
    }

    /**
     * belongでフィルター
     */
    if (form.belong.value && form.belong.value !== 'all') {
      memberList = memberList.filter(({ belong_biz_count }) => form.belong.value === String(belong_biz_count));
    }

    /**
     * ageでフィルター
     */
    if (form.age.value && form.age.value !== 'all') {
      const selectedValue = parseInt(form.age.value, 10);
      let maxAge = selectedValue + 9;

      if (selectedValue === 60) {
        maxAge = 100; // 60代の場合は100までにする
      }

      memberList = memberList.filter(({ age }) => (age || 0) >= selectedValue && (age || 0) <= maxAge);
    }

    /**
     * rankでフィルター
     */
    if (form.rank.value && form.rank.value !== 'all') {
      const targetRank = form.rank.value;

      memberList = memberList.filter(({ rank_names }) => {
        const itemIds = rank_names?.split(',');
        return itemIds?.some((id) => targetRank === id);
      });
    } else if (form.rank.value === 'all') {
      memberList = memberList.filter(({ rank_biz_ids }) => rank_biz_ids !== ('' || null));
    }

    /**
     * キーワードでフィルター
     */
    if (searchConditions) {
      memberList = memberList.filter(({ name }) => {
        if (!searchConditions.name) {
          return true;
        }
        const reg = new RegExp(searchConditions.name, 'i');
        return reg.test(name || '');
      });
    }

    setDataSize(memberList.length);

    return (
      memberList.map((item, index) => {
        const splitTpmemNamesArray = item.tpmem_names?.split(',') || [];
        const splitBizNamesArray = item.tp_biz_names?.split(',') || [];

        const tpmemNames: { [key: string]: string } = splitTpmemNamesArray
          .map((tpmemName, tpmemNamesIndex) => {
            const trimmedTpmemName = tpmemName.trim();
            if (trimmedTpmemName === '') {
              return { [`tpmem_name${tpmemNamesIndex + 1}`]: `${trimmedTpmemName}` }; // 空のオブジェクトを返すことで処理をスキップ
            }

            return {
              [`tpmem_name${tpmemNamesIndex + 1}`]:
                splitBizNamesArray.length === 0
                  ? `${trimmedTpmemName}`
                  : `（${
                      splitBizNamesArray.length > 0 && splitBizNamesArray[tpmemNamesIndex].length > bizNameMax
                        ? `${splitBizNamesArray[tpmemNamesIndex].slice(0, bizNameMax)}…`
                        : splitBizNamesArray[tpmemNamesIndex]
                    }）${trimmedTpmemName}`, // 各要素に対して"tpmem_name1", "tpmem_name2", ...というキーを追加
            };
          })
          .reduce((acc, obj) => ({ ...acc, ...obj }), {});

        const splitSubmNamesArray = item.sub_names?.split(',') || [];

        const subNames: { [key: string]: string | null } = splitSubmNamesArray
          .filter((subName) => subName !== '')
          .map((subName, subNameIndex) => {
            const trimmedSubName = subName.trim();
            if (trimmedSubName === '') {
              return { [`sub_name${subNameIndex + 1}`]: null }; // 空のオブジェクトを返すことで処理をスキップ
            }
            return {
              [`sub_name${subNameIndex + 1}`]:
                splitBizNamesArray.length === 0
                  ? `${trimmedSubName}`
                  : `（${
                      splitBizNamesArray[subNameIndex].length > bizNameMax
                        ? `${splitBizNamesArray[subNameIndex].slice(0, bizNameMax)}…`
                        : splitBizNamesArray[subNameIndex]
                    }）${trimmedSubName}`, // 各要素に対して"sub_names1", "sub_names2", ...というキーを追加
            };
          })
          .reduce<{ [key: string]: string | null }>((acc, obj) => ({ ...acc, ...obj }), {});

        const splitTpmemDatesArray = item.tpmem_dates?.split(',') || [];

        const tpmemDates: { [key: string]: string } = splitTpmemDatesArray
          .map((tpmemDate, tpmemDateIndex) => ({
            [`tpmem_date${tpmemDateIndex + 1}`]: tpmemDate.trim(), // 各要素に対して"tpmem_date1", "tpmem_date2", ...というキーを追加
          }))
          .reduce((acc, obj) => ({ ...acc, ...obj }), {});

        const rankData: { [key: string]: string } = {}; // ランクの表示列位置に基づいたデータを格納するオブジェクト

        // rank_biz_idsとrank_namesのデータを配列に分割
        const rankBizIdsArray = item.rank_biz_ids?.split(',') || [];
        const rankNamesArray = item.rank_names?.split(',') || [];

        // rank_biz_idsのデータを基に、ランクの表示列位置に対応するデータを作成
        for (let i = 0; i < rankBizIdsArray.length; i += 1) {
          const bizIdIndex = item.all_biz_ids?.split(',').indexOf(rankBizIdsArray[i]);

          // ランクの表示列位置に対応するデータを作成
          if (bizIdIndex !== -1) {
            const columnName = `${i + 1}`;
            rankData[columnName] = rankNamesArray[i];
          }
        }

        return {
          eye: (
            <Wrapper>
              {item.newly === 1 && (
                <Indicator>
                  <Icon name="indicatorRed" size={8} />
                </Indicator>
              )}{' '}
              <Icon name="eye" onClick={() => navigate(`/s05/${item.id ?? ''}`)} />
            </Wrapper>
          ),
          image: (
            <ChangeSmallImgWithStatus status={item?.status}>
              <img src={item.image || ''} alt="" />
            </ChangeSmallImgWithStatus>
          ),
          backgroundColor: backgroundColor(item?.status),
          name: item.name,
          age: item.age,
          btmem_number: item.btmem_number,
          email: item.email,
          phone: item.phone,
          birthday: item.birthday,
          gender: item.gender,
          attribute: item.attribute || '-',
          address: `〒${formatZipCode(item.zip_code ?? '')} ${item.state ?? ''}${item.city ?? ''} ${
            item.address ?? ''
          } ${item.address2 ?? ''}`,
          score: item.score,
          worked_count: item.worked_count,
          uniqueKey: index,
          uniform_size: item.uniform_size,
          cancel_per: `${item.cancel_per}%`,
          ...tpmemNames, // tpmemNamesオブジェクト内のキーと値を追加
          ...subNames, // subNamesオブジェクト内のキーと値を追加
          ...tpmemDates, // tpmemDatesオブジェクト内のキーと値を追加
          ...rankData, // ランクの表示列位置に基づいたデータを追加
        };
      }) ?? []
    );
  }, [data?.getVBMemberList, form, loading, navigate, searchConditions]);

  if (loading || stateLoading || cityLoading || cattributeLoading || memberRankLoading || cityByStateIdLoading) {
    return Loading();
  }

  let displayItems = items;
  if (sort) {
    if (sort.key === 'status') {
      displayItems = orderBy(items, 'status', sort.direction);
    } else {
      displayItems = orderBy(items, sort.key, sort.direction);
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box height={30} mb={26} display="flex" alignItems="center">
        <Text variant="h1" color="darkBlue">
          バイトラベル会員
        </Text>
      </Box>
      <Box display="flex" flex="auto">
        <Card overflow="hidden">
          <Box display="flex" pa={16} justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={16} alignItems="center" flexWrap="wrap">
              <Box display="flex" gap={16}>
                <Input
                  name="useName"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setForm({ ...form, name: e.target.value })}
                  placeholder="氏名"
                  value={form.name}
                  width={160}
                />
                <MainButton
                  onClick={() => {
                    setSearchConditions({ name: form.name });
                    setPage(PageNumber.initialValue);
                  }}
                  icon="search"
                  width={80}
                >
                  検索
                </MainButton>
                <div>
                  <Divider length={24} option="vertical" />
                </div>
                <Select
                  placeholder="都道府県"
                  options={[
                    ...(stateData?.getMState
                      ?.map((state) => ({
                        label: state?.name || '',
                        value: String(state?.id) || '',
                      }))
                      ?.filter((option) => option !== null && option !== undefined) || []),
                  ]}
                  width={120}
                  value={form.state.value}
                  onChange={(value: string, label: string) => {
                    if (value) {
                      setForm({ ...form, state: { value, label } });
                    } else {
                      // 都道府県が未選択の場合、市区町村の選択肢をクリアする
                      setForm({ ...form, state: { value, label }, city: { value: '', label: '' } });
                    }
                    handleStateSelect(value);
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="市区町村"
                  options={cityOption || []}
                  width={120}
                  value={form.city.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, city: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <div>
                  <Divider length={24} option="vertical" />
                </div>
              </Box>
              <Box display="flex" gap={16}>
                <Select
                  placeholder="ランク"
                  options={[
                    { label: '全て', value: 'all' },
                    ...(memberRankData?.getMBtmemRank
                      ?.map((rank) => ({
                        label: rank?.name || '',
                        value: String(rank?.name) || '',
                      }))
                      ?.filter((option) => option !== null && option !== undefined) || []),
                  ]}
                  width={120}
                  value={form.rank.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, rank: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="年齢"
                  options={[
                    { label: '全て', value: 'all' },
                    { label: '10代', value: '10' },
                    { label: '20代', value: '20' },
                    { label: '30代', value: '30' },
                    { label: '40代', value: '40' },
                    { label: '50代', value: '50' },
                    { label: '60代以上', value: '60' },
                  ]}
                  width={120}
                  value={form.age.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, age: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="性別"
                  options={[
                    { label: '全て', value: 'all' },
                    { label: '男性', value: '男' },
                    { label: '女性', value: '女' },
                  ]}
                  width={120}
                  value={form.gender.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, gender: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="属性"
                  options={[
                    { label: '全て', value: 'all' },
                    ...(attributeData?.getMOccAttr
                      ?.map((attribute) => ({
                        label: attribute?.name || '',
                        value: String(attribute?.name) || '',
                      }))
                      ?.filter((option) => option !== null && option !== undefined) || []),
                  ]}
                  width={120}
                  value={form.attribute.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, attribute: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="所属の有無"
                  options={[
                    { label: '全て', value: 'all' },
                    { label: '有', value: '1' },
                    { label: '無', value: '0' },
                  ]}
                  width={120}
                  value={form.belong.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, belong: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <Select
                  placeholder="状態"
                  options={[
                    { label: '全て', value: '0' },
                    { label: 'アクティブ', value: String(BitravelerInfoStatus.ACTIVE) },
                    { label: '休止', value: String(BitravelerInfoStatus.PAUSE) },
                    { label: '停止', value: String(BitravelerInfoStatus.STOP) },
                    { label: '一時停止', value: String(BitravelerInfoStatus.TEMPORARY_PAUSE) },
                  ]}
                  width={120}
                  value={form.status.value}
                  onChange={(value: string, label: string) => {
                    setForm({ ...form, status: { value, label } });
                    setPage(PageNumber.initialValue);
                  }}
                />
                <MainButton
                  variant="clear"
                  width={42}
                  onClick={() => {
                    setForm({
                      name: '',
                      state: { label: '', value: '' },
                      city: { label: '', value: '' },
                      rank: { label: '', value: '' },
                      age: { label: '', value: '' },
                      attribute: { label: '', value: '' },
                      gender: { label: '', value: '' },
                      belong: { label: '', value: '' },
                      status: { label: '', value: '' },
                    });
                    setSelectedStateId('');
                    setCityOption([]);
                    setSearchConditions(null);
                    setPage(PageNumber.initialValue);
                  }}
                >
                  クリア
                </MainButton>
              </Box>
            </Box>
          </Box>

          <Divider option="horizontal" />
          <Box flex="auto" px={16}>
            <PaginationBox
              dataSize={dataSize}
              limit={limit}
              page={page}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <StyledListWrapper>
                <List
                  header={headerColumnList}
                  items={displayItems.slice(limit * (page - 1), limit * page)}
                  sort={sort}
                  onChangeSort={handleChangeSort}
                />
              </StyledListWrapper>
            </PaginationBox>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
