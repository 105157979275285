import { Box } from 'components/box';
import React, { useEffect, useMemo } from 'react';
import {
  useGetVtMemberTicketListByIdQuery,
  useUpdateUTrialTicketS2ByIdMutation,
  VtTicketList,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { usePagenation } from 'pages/s03/components/usePagenate';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { ScrollableArea } from 'components/scrollableArea';
import { TicketStatus } from 'pages/s03/const';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { Chip } from 'components/chip';
import { TicketDeleteModal, CreateTicketModal } from 'pages/s03/components/modal';
import { useErrorModal } from 'components/error/errorModalProvider';

/**
 * Figma ID: 03-09-01
 * トライアルチケット
 * Trial Ticket
 */

enum ModalType {
  None = 'none',
  TicketDelete = 'ticketDelete',
  CreateTicket = 'createTicket',
}

function CustomRow({ props, onDeleteTicket }: { props: VtTicketList | null; onDeleteTicket: () => void }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      height={56}
      width="100%"
      py={8}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" gap={4}>
        {props?.timeup === TicketStatus.VALID && (
          <Text variant="caption12" bold color="darkBlue">
            {props?.item}
          </Text>
        )}
        {props?.timeup === TicketStatus.EXPIRED && (
          <div style={{ opacity: '0.5' }}>
            <Text variant="caption12" bold color="darkBlue">
              {props?.item}
            </Text>
          </div>
        )}
        <Box display="flex" flexDirection="row" gap={16} alignItems="center">
          <Box width={144}>
            <Text variant="caption12" color="blueGray">{`付与日：${props?.award_date ?? ''}`}</Text>
          </Box>
          <Divider option="vertical" length={18} />
          <Box width={190}>
            <Text variant="caption12" color="blueGray">
              {`期限：${props?.valid_days ?? 0}日（${props?.end_date ?? ''}）`}
            </Text>
          </Box>
          <Divider option="vertical" length={18} />
          <Box width={200}>
            <Text variant="caption12" color="blueGray">{`発行者：${props?.umgmt_name ?? ''}`}</Text>
          </Box>
          <Divider option="vertical" length={18} />
          <Box>
            <Text variant="caption12" color="blueGray">{`残数 : ${props?.enabled_count ?? 0}枚`}</Text>
          </Box>
        </Box>
      </Box>
      {props?.timeup === TicketStatus.VALID && (
        <ThreeDotLeader
          menu={[{ label: '削除', value: 'delete' }]}
          onClick={(value: string) => {
            if (value === 'delete') {
              onDeleteTicket();
            }
          }}
          right={0}
        />
      )}
      {props?.timeup === TicketStatus.EXPIRED && <Chip color="liteGray">期限切れ</Chip>}
    </Box>
  );
}

export function Ticket(props: { id: number }) {
  const { id } = props;
  const { openErrorModal } = useErrorModal();
  const {
    data: memberTicketListData,
    loading: memberTicketListLoading,
    error: memberTicketListError,
    refetch: refetchMemberTicketList,
  } = useGetVtMemberTicketListByIdQuery({
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });
  const [updateUTrialTicketS2ByIdMutation, { loading: updateTrialTicketLoading, error: updateTrialTicketError }] =
    useUpdateUTrialTicketS2ByIdMutation({ variables: { id } });

  const member = memberTicketListData?.getVTMemberTicketListById.member;
  const ticketList = memberTicketListData?.getVTMemberTicketListById.ticket;

  const { items, handleChangeLimit, handleChangePage, pagenation } = usePagenation<VtTicketList | null>(
    ticketList ?? [],
    10,
    1
  );
  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.None);
  const [ticketId, setTicketId] = React.useState<number>(0);
  const listItems: React.ReactNode[] = useMemo(
    () =>
      items.map((item) => (
        <CustomRow
          props={item}
          onDeleteTicket={() => {
            setActiveModalType(ModalType.TicketDelete);
            setTicketId(item?.id ?? 0);
          }}
        />
      )),
    [items]
  );
  useEffect(() => {
    if (memberTicketListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [memberTicketListError, openErrorModal]);

  useEffect(() => {
    if (updateTrialTicketError) {
      setActiveModalType(ModalType.None);
      setTicketId(0);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [updateTrialTicketError, openErrorModal]);
  return (
    <>
      {(memberTicketListLoading || updateTrialTicketLoading) && <Loading />}
      <Box px={16} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" height={60} justifyContent="space-between">
          <Text variant="h2" color="darkBlue">
            トライアルチケット
          </Text>
          <Box display="flex" gap={12} alignItems="center">
            <Box display="flex" flexDirection="row" gap={8} alignItems="center">
              <Text variant="body14" color="darkBlue">
                利用可能枚数
              </Text>
              <Text variant="h2" color="darkBlue">{`${member?.ticket_num ?? 0}枚`}</Text>
            </Box>
            <MainButton width={160} onClick={() => setActiveModalType(ModalType.CreateTicket)}>
              チケット付与
            </MainButton>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" flex="1" flexDirection="column">
            <PaginationBox
              dataSize={ticketList?.length ?? 0}
              limit={pagenation.limit}
              page={pagenation.page}
              onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
              onChangePage={(value: number) => handleChangePage(value)}
            >
              <Divider option="horizontal" />
              <ScrollWrapper bottom={346}>
                <ScrollableArea>
                  {listItems.map((item) => (
                    <>
                      {item}
                      <Divider option="horizontal" />
                    </>
                  ))}
                </ScrollableArea>
              </ScrollWrapper>
            </PaginationBox>
          </Box>
        </Box>
      </Box>
      <TicketDeleteModal
        isOpen={activeModalType === ModalType.TicketDelete}
        onClose={() => {
          setActiveModalType(ModalType.None);
          setTicketId(0);
        }}
        onDelete={() => {
          updateUTrialTicketS2ByIdMutation({
            variables: {
              id: ticketId,
            },
          }).then((e) => {
            if (e.errors) {
              setActiveModalType(ModalType.None);
              setTicketId(0);
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
            }
          });
        }}
        refetch={() => {
          refetchMemberTicketList();
        }}
      />
      <CreateTicketModal
        isOpen={activeModalType === ModalType.CreateTicket}
        id={id}
        onClose={() => setActiveModalType(ModalType.None)}
        refetch={() => {
          refetchMemberTicketList();
        }}
      />
    </>
  );
}
