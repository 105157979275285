/**
 * Figma ID: 04-01-03-01
 * 名称: 応募（バイト募集情報）
 */
import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Chip } from 'components/chip';
import { Divider } from 'components/newDivider';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { PaginationBox } from 'components/pagination/paginationBox';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import { orderBy } from 'lodash';
import {
  GetVtArbeitOfferEntryListByIdQuery,
  useGetVbMemberBaseByIdLazyQuery,
  GetVbMemberBaseByIdQuery,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Avatar } from 'components/avatar';
import NoImgSvg from 'components/assets/images/no-image-small.svg';
import { BitravelMemberModal } from 'modal/bitravelMemberModal';

interface Props {
  vtArbeitOfferEntryList: GetVtArbeitOfferEntryListByIdQuery['getVTArbeitOfferEntryListById']['entry'];
  vtArbeitOffer: GetVtArbeitOfferEntryListByIdQuery['getVTArbeitOfferEntryListById']['offer'];
}

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 323px);
  max-height: calc(100vh - 323px);
  overflow: auto;
`;

export function Application({ vtArbeitOfferEntryList, vtArbeitOffer }: Props) {
  const { openErrorModal } = useErrorModal();
  const [getVbMemberBaseById, { error: getVbMemberBaseByIdError }] = useGetVbMemberBaseByIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  React.useEffect(() => {
    if (getVbMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVbMemberBaseByIdError, openErrorModal]);
  const [vbMemberBase, setVbMemberBase] = React.useState<GetVbMemberBaseByIdQuery['getVBMemberBaseById'] | null>(null);

  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeSort = (key: string) => {
    setCurrentPage(1);
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };

  const generateChip = (status: number) => {
    switch (status) {
      case 1:
        return (
          <Chip color="okBlue" width={56}>
            採用
          </Chip>
        );
      case 2:
        return (
          <Chip color="liteGray" width={56}>
            不採用
          </Chip>
        );
      default:
        return null;
    }
  };

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    {
      columnName: '',
      key: 'eye',
      width: 24,
    },
    {
      columnName: '',
      key: 'image',
      width: 40,
    },
    {
      columnName: '氏名',
      key: 'name',
      width: 192,
    },
    {
      columnName: '会員番号',
      key: 'btmem_id',
      width: 92,
    },
    {
      columnName: '採用手数料',
      key: 'total_hire_fee',
      width: 92,
    },
    {
      columnName: '所属',
      key: 'kind_name',
      width: 92,
    },
    {
      columnName: 'スコア',
      key: 'score',
      width: 92,
    },
    {
      columnName: 'ランク',
      key: 'rank_name',
      width: 92,
    },
    {
      columnName: '応募No',
      key: 'entry_id',
      width: 112,
    },
    {
      columnName: '返答期限',
      key: 'deadline_date',
      width: 160,
    },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let items = vtArbeitOfferEntryList;
    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'identification', sort.direction);
      } else if (sort.key === 'total_hire_fee') {
        items = orderBy(
          items,
          (item) => parseInt(item.total_hire_fee?.replace(/[^\d]/g, '') ?? '0', 10),
          sort.direction
        );
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items
      .map((item) => ({
        uniqueKey: item.entry_id,
        eye: (
          <Icon
            name="eye"
            onClick={() => {
              getVbMemberBaseById({ variables: { id: item.btmem_id } }).then(
                ({ data: { getVBMemberBaseById } = {} }) => {
                  if (getVBMemberBaseById) {
                    setVbMemberBase(getVBMemberBaseById);
                  }
                }
              );
            }}
          />
        ),
        image: <Avatar src={item.image || NoImgSvg} width={40} height={40} alt="" />,
        name: (
          <Box display="flex" gap={16} alignItems="center">
            {generateChip(item.status)}
            <Text variant="caption12">{item.name}</Text>
          </Box>
        ),
        btmem_id: item.btmem_id,
        status: item.status,
        total_hire_fee: `¥${item.total_hire_fee?.toLocaleString() || '0'}`,
        kind_name: item.kind_name,
        score: item.score,
        rank_name: item.rank_name,
        entry_id: item.entry_id,
        deadline_date: item.deadline_date,
      }))
      .slice(currentLimit * (currentPage - 1), currentLimit * currentPage);
  }, [vtArbeitOfferEntryList, sort, currentPage, currentLimit, getVbMemberBaseById]);

  return (
    <>
      <Box px={24} display="flex" flexDirection="column" alignItems="center" overflow="scroll" width="100%">
        <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
          <Box flex="auto">
            <Text color="darkBlue" variant="h2">
              応募 ({vtArbeitOffer.entry_count})
            </Text>
          </Box>
          <Box
            height={28}
            display="flex"
            alignItems="center"
            gap={8}
            px={4}
            border="1px solid #5F797D"
            borderRadius={2}
          >
            <Text variant="body14" color="darkBlue">
              他店/一般
            </Text>
            <Text variant="h2" color="darkBlue">
              {vtArbeitOffer.normal_count}
            </Text>
          </Box>
          <Box
            height={28}
            display="flex"
            alignItems="center"
            gap={8}
            px={4}
            border="1px solid #5F797D"
            borderRadius={2}
          >
            <Text variant="body14" color="darkBlue">
              自店
            </Text>
            <Text variant="h2" color="darkBlue">
              {vtArbeitOffer.main_count}
            </Text>
          </Box>
          <Box
            height={28}
            display="flex"
            alignItems="center"
            gap={8}
            px={4}
            border="1px solid #5F797D"
            borderRadius={2}
          >
            <Text variant="body14" color="darkBlue">
              サブ
            </Text>
            <Text variant="h2" color="darkBlue">
              {vtArbeitOffer.sub_count}
            </Text>
          </Box>
          <Box
            height={28}
            display="flex"
            alignItems="center"
            gap={8}
            px={4}
            border="1px solid #5F797D"
            borderRadius={2}
          >
            <Text variant="body14" color="darkBlue">
              グループ
            </Text>
            <Text variant="h2" color="darkBlue">
              {vtArbeitOffer.group_count}
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box flex="auto" width="100%">
          <PaginationBox
            dataSize={dataSize}
            limit={currentLimit}
            page={currentPage}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <StyledOverflowWrapper>
              <List
                header={header}
                items={displayItems}
                onChangeSort={handleChangeSort}
                sort={sort}
                width="100%"
                rowWidth="100%"
              />
            </StyledOverflowWrapper>
          </PaginationBox>
        </Box>
      </Box>
      {vbMemberBase && (
        <BitravelMemberModal
          isOpen={Boolean(vbMemberBase)}
          vbMemberBase={vbMemberBase}
          onClickClose={() => setVbMemberBase(null)}
        />
      )}
    </>
  );
}
