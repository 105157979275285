import { Modal } from 'components/newModal';
import React, { useState } from 'react';
import { VtMemberBase } from 'graphql/graphql-ow';
import { useForm } from 'react-hook-form';
import { BaseInfoEditKey } from 'pages/s03/type';
import { EditStoreInfoModal } from './editStoreinfoModal';
import { EditCorporationInfoModal } from './editCorporationInfoModal';
import { ConfirmModal } from './confirmModal';
import { completeModal } from './completeModal';

enum ModalStep {
  EditStoreInfo = 1,
  EditCorporationInfo = 2,
  Confirm = 3,
  Complete = 4,
}
// 1:法人 2:個人事業主
enum CorporateType {
  Corporation = 1,
  SoleProprietorship = 2,
}

export function EditRegistrationModal(props: {
  isOpen: boolean;
  onClose: () => void;
  item: VtMemberBase | null;
  id: number;
  refetch: () => void;
}) {
  const { isOpen, onClose, item, id, refetch } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.EditStoreInfo);

  const [imageFile, setImageFile] = React.useState<File | null>(null);

  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<BaseInfoEditKey>({ defaultValues: { ...item }, reValidateMode: 'onSubmit' });
  const onModalClose = () => {
    onClose();
    reset();
    setModalStep(ModalStep.EditStoreInfo);
  };

  const editStoreinfo = EditStoreInfoModal(
    control,
    getValues,
    setValue,
    handleSubmit,
    errors,
    onModalClose,
    () =>
      item?.corporate_type === CorporateType.SoleProprietorship
        ? setModalStep(ModalStep.Confirm)
        : setModalStep(ModalStep.EditCorporationInfo),
    setImageFile
  );
  const editCorporationInfo = EditCorporationInfoModal(
    control,
    getValues,
    handleSubmit,
    errors,
    onModalClose,
    () => setModalStep(ModalStep.Confirm),
    () => setModalStep(ModalStep.EditStoreInfo)
  );
  const confirm = ConfirmModal(
    id,
    getValues,
    onModalClose,
    () => setModalStep(ModalStep.Complete),
    () =>
      item?.corporate_type === CorporateType.SoleProprietorship
        ? setModalStep(ModalStep.EditStoreInfo)
        : setModalStep(ModalStep.EditCorporationInfo),
    imageFile
  );
  const complete = completeModal(() => {
    onModalClose();
    refetch();
  });

  const modalContents = {
    [ModalStep.EditStoreInfo]: editStoreinfo,
    [ModalStep.EditCorporationInfo]: editCorporationInfo,
    [ModalStep.Confirm]: confirm,
    [ModalStep.Complete]: complete,
  };

  return <Modal isOpen={isOpen} onClose={() => onModalClose()} {...modalContents[modalStep]} />;
}
