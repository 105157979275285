/**
 * Figma ID: 10-08-01-01
 * 名称: eギフト設定（加盟店Thanksギフト更新）
 */
import React from 'react';
import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { ScrollableArea } from 'components/scrollableArea';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { EgiftList } from 'components/egiftList';
import { ResultRows, ModalZindex } from 'components/const';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useForm } from 'react-hook-form';
import { useGetSEgiftCodeByThanksGift1Query, useUpdateSEgiftCodeByEgiftCodeMutation } from 'graphql/graphql-ow';
import UpdateDeadlineHeader from './updateDeadline/UpdateDeadlineHeader';
import UpdateDeadlineFooter from './updateDeadline/UpdateDeadlineFooter';
import UpdateDeadlineContent from './updateDeadline/UpdateDeadlineContent';
import InputDataUpdateDeadlineContent from './updateDeadline/InputDataUpdateDeadlineContent';
import ConfirmUpdateDeadlineContent from './confirmUpdateDeadline/ConfirmUpdateDeadlineContent';
import ConfirmUpdateDeadlineFooter from './confirmUpdateDeadline/ConfirmUpdateDeadlineFooter';
import CompleteUpdateDeadlineContent from './confirmUpdateDeadline/CompleteUpdateDeadlineContent';
import ConfirmUpdateDeadlineHeader from './confirmUpdateDeadline/ConfirmUpdateDeadlineHeader';
import type { InputDataModel } from './GiftChoice';

export type UpdateDeadlinePhase = 'selectedGift' | 'inputUpdateData' | 'confirmUpdateData' | 'complete';

function GiftUpdate() {
  const { data: eGiftData, loading: eGiftLoading, refetch } = useGetSEgiftCodeByThanksGift1Query();
  const [updateEgift, { loading: updateLoading }] = useUpdateSEgiftCodeByEgiftCodeMutation();
  const [updateDeadlineShow, setUpdateDeadlineShow] = React.useState(false);
  const [updatePhase, setUpdatePhase] = React.useState<UpdateDeadlinePhase>();
  const [selectedGift, setSelectedGift] = React.useState<string>();
  const { openErrorModal } = useErrorModal();

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<InputDataModel>({ reValidateMode: 'onSubmit' });
  const currentToken = watch('token');
  const currentConfigCode = watch('configCode');
  const currentDate = watch('deadline');
  const currentEgiftCode = watch('egiftCode');
  const currentPrice = watch('price');
  const deadline = `${String(currentDate?.getFullYear())}-${String((currentDate?.getMonth() ?? 0) + 1)}-${String(
    currentDate?.getDate()
  )}`;
  const CORRECT_DATE = -1;

  React.useEffect(() => {
    if (selectedGift === undefined) {
      setUpdateDeadlineShow(false);
    } else {
      setUpdateDeadlineShow(true);
      setUpdatePhase('selectedGift');
    }
  }, [selectedGift]);

  const modalHeader = React.useMemo(() => {
    switch (updatePhase) {
      case 'confirmUpdateData':
      case 'complete':
        return <ConfirmUpdateDeadlineHeader updateDeadlinePhase={updatePhase} />;
      default:
        return <UpdateDeadlineHeader updateDeadlinePhase={updatePhase} />;
    }
  }, [updatePhase]);

  const modalContent = () => {
    switch (updatePhase) {
      case 'inputUpdateData':
        return (
          <InputDataUpdateDeadlineContent
            control={control}
            setValue={setValue}
            errors={errors}
            register={register}
            watch={watch}
          />
        );
      case 'confirmUpdateData':
        return <ConfirmUpdateDeadlineContent watch={watch} selectedGift={selectedGift} />;
      case 'complete':
        return <CompleteUpdateDeadlineContent />;
      default:
        return <UpdateDeadlineContent message="Thanksギフトの発行期限を更新します。" selectedGift={selectedGift} />;
    }
  };

  const toBackPhase = () => {
    switch (updatePhase) {
      case 'confirmUpdateData':
        setUpdatePhase('inputUpdateData');
        break;
      default:
        setUpdatePhase('selectedGift');
    }
  };
  const toNextPhase = () => {
    switch (updatePhase) {
      case 'inputUpdateData':
        handleSubmit(() => {
          setUpdatePhase('confirmUpdateData');
        })();
        break;
      case 'confirmUpdateData':
        updateEgift({
          variables: {
            egift_code: currentEgiftCode ?? 0,
            deadline,
            price: currentPrice ?? 0,
            token: currentToken ?? '',
            config_code: currentConfigCode ?? '',
          },
        })
          .then((result) => {
            if (result.data?.updateSEgiftCodeByEgiftCode.resultRows === ResultRows.UPDATED) {
              setUpdatePhase('complete');
            } else {
              openErrorModal({
                message: '更新に失敗しました。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          })
          .catch((e) => {
            // 通信自体ができない場合
            if (e instanceof Error) {
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          });

        break;
      default:
        setUpdatePhase('inputUpdateData');
    }
  };
  const closeModal = () => {
    setSelectedGift(undefined);
    reset();
    refetch();
  };

  const modalFooter = () => {
    switch (updatePhase) {
      case 'confirmUpdateData':
      case 'complete':
        return (
          <ConfirmUpdateDeadlineFooter
            updateDeadlinePhase={updatePhase}
            onClickNext={() => toNextPhase()}
            onClickBack={() => toBackPhase()}
            onClickClose={() => closeModal()}
          />
        );
      default:
        return (
          <UpdateDeadlineFooter
            updateDeadlinePhase={updatePhase}
            onClickNext={() => toNextPhase()}
            onClickBack={() => toBackPhase()}
            onClickClose={() => closeModal()}
          />
        );
    }
  };
  return (
    <HideWrapper>
      <Box pa={24} display="flex">
        <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
          <Text color="darkBlue" variant="h2">
            加盟店Thanksギフト更新
          </Text>
          <Text color="darkBlue" variant="caption12">
            加盟店支給のThanksギフト発行期限をこちらから事前に更新してください
          </Text>
        </Box>
      </Box>
      <Box pr={24} pl={24}>
        <Divider option="horizontal" />
      </Box>

      <ScrollWrapper bottom={260}>
        <ScrollableArea>
          {eGiftLoading || updateLoading ? (
            <Loading />
          ) : (
            <Box px={24} pb={20}>
              {eGiftData?.getSEgiftCodeByThanksGift1?.map((val) => (
                <EgiftList
                  key={Math.random().toString()}
                  text1={val?.name}
                  deadline={val?.deadline}
                  menu={[{ label: '更新', value: 'update' }]}
                  menuCallback={() => {
                    // サブメニューを押した時の処理
                    setSelectedGift(val?.name || '');
                    let selectedGiftDeadlineString;
                    let selectedGiftDeadline;
                    if (
                      (val.deadline?.indexOf('0000年') ||
                        val.deadline?.indexOf('00月') ||
                        val.deadline?.indexOf('00日')) === CORRECT_DATE
                    ) {
                      selectedGiftDeadlineString = val.deadline?.replace(/年|月/g, '-').replace(/日/g, '');
                      selectedGiftDeadline = new Date(selectedGiftDeadlineString ?? '');
                    } else {
                      selectedGiftDeadline = new Date();
                    }
                    setValue('deadline', selectedGiftDeadline);
                    setValue('configCode', val.config_code ?? '');
                    setValue('egiftCode', val.egift_code);
                    setValue('token', val.token ?? '');
                    setValue('price', val.price ?? 0);
                  }}
                />
              ))}
            </Box>
          )}
        </ScrollableArea>
      </ScrollWrapper>
      <Modal
        isOpen={updateDeadlineShow}
        header={modalHeader}
        content={modalContent()}
        footer={modalFooter()}
        onClose={() => closeModal()}
        height="auto"
      />
    </HideWrapper>
  );
}

export default GiftUpdate;
