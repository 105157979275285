import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AddResult = {
  __typename?: 'AddResult';
  id: Scalars['Int']['output'];
};

export type BArbeitLog = {
  __typename?: 'BArbeitLog';
  bg_area_id: Scalars['Int']['output'];
};

export type BBadge = {
  __typename?: 'BBadge';
  badge_id: Scalars['Int']['output'];
};

export type BLogging = {
  __typename?: 'BLogging';
  btmem_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  kind: Scalars['Int']['output'];
  log_date?: Maybe<Scalars['String']['output']>;
  matter?: Maybe<Scalars['String']['output']>;
  ref_no?: Maybe<Scalars['Int']['output']>;
};

export type BMember = {
  __typename?: 'BMember';
  account_name?: Maybe<Scalars['String']['output']>;
  account_no?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  achievement?: Maybe<Scalars['String']['output']>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  attr_id?: Maybe<Scalars['Int']['output']>;
  bank_code?: Maybe<Scalars['String']['output']>;
  branch_name?: Maybe<Scalars['String']['output']>;
  branch_no?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cognito_id?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  ecn?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_kana?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  invited_input?: Maybe<Scalars['String']['output']>;
  is_japanese?: Maybe<Scalars['Boolean']['output']>;
  last_kana?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  nationality_id?: Maybe<Scalars['Int']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  parental?: Maybe<Scalars['Boolean']['output']>;
  parental_name?: Maybe<Scalars['String']['output']>;
  pause_date?: Maybe<Scalars['String']['output']>;
  penalty?: Maybe<Scalars['Int']['output']>;
  period_date?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quit_date?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reg_hs_student?: Maybe<Scalars['Boolean']['output']>;
  reg_minor?: Maybe<Scalars['Boolean']['output']>;
  residence?: Maybe<Scalars['Int']['output']>;
  restart_date?: Maybe<Scalars['String']['output']>;
  rule?: Maybe<Scalars['Boolean']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  uniform_id?: Maybe<Scalars['Int']['output']>;
  wire_new_code?: Maybe<Scalars['Int']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type CBillingCsv = {
  __typename?: 'CBillingCsv';
  csv_no?: Maybe<Scalars['Int']['output']>;
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  download_uid?: Maybe<Scalars['Int']['output']>;
  kind?: Maybe<Scalars['Int']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  transfer_date?: Maybe<Scalars['String']['output']>;
};

export type CPayment = {
  __typename?: 'CPayment';
  all_csv_no?: Maybe<Scalars['Int']['output']>;
  bill_date?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['Int']['output']>;
  csv_no?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mem_id?: Maybe<Scalars['Int']['output']>;
  origin_id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  req_csv_no?: Maybe<Scalars['Int']['output']>;
  req_date?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Scalars['Int']['output']>;
  request_no?: Maybe<Scalars['Int']['output']>;
  subcategory?: Maybe<Scalars['Int']['output']>;
  sys_id?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
};

export type DbResult = {
  __typename?: 'DBResult';
  resultRows: Scalars['Int']['output'];
};

export type DbResultStatus = {
  __typename?: 'DBResultStatus';
  resultRows?: Maybe<Scalars['Int']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export type MAchieve = {
  __typename?: 'MAchieve';
  id: Scalars['Int']['output'];
  kind: Scalars['Int']['output'];
  phrase: Scalars['String']['output'];
};

export type MArea = {
  __typename?: 'MArea';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MAreaState = {
  __typename?: 'MAreaState';
  area_name: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  state?: Maybe<Array<Maybe<MState2>>>;
};

export type MAutoBadge = {
  __typename?: 'MAutoBadge';
  count: Scalars['Int']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MBadgeArea = {
  __typename?: 'MBadgeArea';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  state_id: Scalars['Int']['output'];
};

export type MBadgeArea2 = {
  __typename?: 'MBadgeArea2';
  area_id: Scalars['Int']['output'];
  area_name: Scalars['String']['output'];
};

export type MBadgeAreaDef = {
  __typename?: 'MBadgeAreaDef';
  city?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type MBankBranch = {
  __typename?: 'MBankBranch';
  branch: Scalars['String']['output'];
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sequence: Scalars['Int']['output'];
};

export type MBankBranch2 = {
  __typename?: 'MBankBranch2';
  bank_code: Scalars['String']['output'];
  branch_code: Scalars['String']['output'];
  branch_name: Scalars['String']['output'];
  sequence: Scalars['Int']['output'];
};

export type MBankCode = {
  __typename?: 'MBankCode';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  zeus_web: Scalars['Boolean']['output'];
};

export type MBankCodeBranch = {
  __typename?: 'MBankCodeBranch';
  bank_name: Scalars['String']['output'];
  branch?: Maybe<Array<Maybe<MBankBranch2>>>;
  code: Scalars['String']['output'];
  zeus_web: Scalars['Boolean']['output'];
};

export type MBrand = {
  __typename?: 'MBrand';
  biz_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
};

export type MBrand2 = {
  __typename?: 'MBrand2';
  biz_id: Scalars['Int']['output'];
  brand_id: Scalars['Int']['output'];
  brand_name: Scalars['String']['output'];
  brand_prefix?: Maybe<Scalars['String']['output']>;
};

export type MBtmemRank = {
  __typename?: 'MBtmemRank';
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  item: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MBusiness = {
  __typename?: 'MBusiness';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
};

export type MBusinessBrand = {
  __typename?: 'MBusinessBrand';
  brand?: Maybe<Array<Maybe<MBrand2>>>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
};

export type MBusinessData = {
  __typename?: 'MBusinessData';
  brand?: Maybe<Array<Maybe<MBrand2>>>;
  existsStore: Scalars['Boolean']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  license?: Maybe<Array<Maybe<MLicense2>>>;
  name: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
  skillCheck?: Maybe<Array<Maybe<MSkillCheck2>>>;
};

export type MBusinessLicense = {
  __typename?: 'MBusinessLicense';
  id: Scalars['Int']['output'];
  /**
   *  	prefix: String
   * ######	icon: String
   */
  license?: Maybe<Array<Maybe<MLicense2>>>;
  name: Scalars['String']['output'];
};

export type MCancelReason = {
  __typename?: 'MCancelReason';
  no: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  sys_id: Scalars['Int']['output'];
};

export type MCity = {
  __typename?: 'MCity';
  id: Scalars['Int']['output'];
  kana: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  state_id: Scalars['Int']['output'];
};

export type MCity2 = {
  __typename?: 'MCity2';
  city_id: Scalars['Int']['output'];
  city_kana: Scalars['String']['output'];
  city_lat: Scalars['Float']['output'];
  city_lng: Scalars['Float']['output'];
  city_name: Scalars['String']['output'];
  state_id: Scalars['Int']['output'];
};

export type MConqArea = {
  __typename?: 'MConqArea';
  area_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type MConqBadge = {
  __typename?: 'MConqBadge';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MConqBadgeList = {
  __typename?: 'MConqBadgeList';
  badge_area?: Maybe<Array<Maybe<MBadgeArea2>>>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MCountry = {
  __typename?: 'MCountry';
  english: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MIdCardType = {
  __typename?: 'MIdCardType';
  additional_id?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image_num: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  student: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  with_photo: Scalars['Boolean']['output'];
};

export type MLicense = {
  __typename?: 'MLicense';
  biz_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MLicense2 = {
  __typename?: 'MLicense2';
  biz_id: Scalars['Int']['output'];
  license_id: Scalars['Int']['output'];
  license_name: Scalars['String']['output'];
};

export type MMessage = {
  __typename?: 'MMessage';
  body?: Maybe<Scalars['String']['output']>;
  kind?: Maybe<Scalars['Int']['output']>;
  no?: Maybe<Scalars['Int']['output']>;
  screen_no?: Maybe<Scalars['String']['output']>;
  sys_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MNotifyTpl = {
  __typename?: 'MNotifyTpl';
  biz_id?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sys_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MOccAttr = {
  __typename?: 'MOccAttr';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MPenaltyDef = {
  __typename?: 'MPenaltyDef';
  backout: Scalars['Boolean']['output'];
  decision: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  point: Scalars['Int']['output'];
};

export type MPenaltyType = {
  __typename?: 'MPenaltyType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MRejectReason = {
  __typename?: 'MRejectReason';
  kind: Scalars['Int']['output'];
  no: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};

export type MResidence = {
  __typename?: 'MResidence';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MSkillCheck = {
  __typename?: 'MSkillCheck';
  biz_id: Scalars['Int']['output'];
  bt_rank_id: Scalars['Int']['output'];
  item: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  section_no: Scalars['Int']['output'];
};

export type MSkillCheck2 = {
  __typename?: 'MSkillCheck2';
  biz_id?: Maybe<Scalars['Int']['output']>;
  bt_rank_name?: Maybe<Scalars['String']['output']>;
  check_item?: Maybe<Scalars['String']['output']>;
  check_no?: Maybe<Scalars['Int']['output']>;
  section_name?: Maybe<Scalars['String']['output']>;
  section_no?: Maybe<Scalars['Int']['output']>;
};

export type MSkillSection = {
  __typename?: 'MSkillSection';
  biz_id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
};

export type MState = {
  __typename?: 'MState';
  area_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  kana: Scalars['String']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  min_wage: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MState2 = {
  __typename?: 'MState2';
  area_id: Scalars['Int']['output'];
  min_wage: Scalars['Int']['output'];
  state_id: Scalars['Int']['output'];
  state_kana: Scalars['String']['output'];
  state_lat: Scalars['Float']['output'];
  state_lng: Scalars['Float']['output'];
  state_name: Scalars['String']['output'];
};

export type MStateCity = {
  __typename?: 'MStateCity';
  area_id: Scalars['Int']['output'];
  city?: Maybe<Array<Maybe<MCity2>>>;
  id: Scalars['Int']['output'];
  min_wage: Scalars['Int']['output'];
  state_kana: Scalars['String']['output'];
  state_lat: Scalars['Float']['output'];
  state_lng: Scalars['Float']['output'];
  state_name: Scalars['String']['output'];
};

export type MSystemType = {
  __typename?: 'MSystemType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MTos = {
  __typename?: 'MTos';
  body?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
  sys_id: Scalars['Int']['output'];
};

export type MTpmemType = {
  __typename?: 'MTpmemType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MUniformSize = {
  __typename?: 'MUniformSize';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MutateResult = {
  __typename?: 'MutateResult';
  ErrorCode?: Maybe<Scalars['Int']['output']>;
  resultRows: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBPenaltyPoint: AddResult;
  addMBrandByBizId: MutateResult;
  addMBusinessData: MutateResult;
  addMLicenseByBizId: MutateResult;
  addSCampaign?: Maybe<AddResult>;
  addSCampaignKind1: AddResult;
  addSCampaignKind2: AddResult;
  addSCampaignKind3: AddResult;
  addSCampaignKind11: AddResult;
  addSCampaignKind12: AddResult;
  addSEgiftCode: AddResult;
  addSWithholdDay?: Maybe<SWithholdDay>;
  addTBase: AddResult;
  addTTpmemBlock: DbResult;
  addUMember?: Maybe<UMember>;
  addUSendNotify?: Maybe<USendNotify>;
  addUSendNotifyDraft?: Maybe<USendNotify>;
  addUTrialTicket: AddResult;
  addupdUMember: DbResultStatus;
  delInsMLicenseByBizId: MutateResult;
  delInsMSkillCheckByBizId: MutateResult;
  deleteSCampaignById: DbResult;
  deleteSEgiftCodeByEgiftCode: DbResult;
  deleteSWithholdDayByYear?: Maybe<DbResult>;
  deleteTTpmemBlockById: DbResult;
  deleteUMemberById: DbResult;
  deleteUSendNotify: DbResult;
  getVTMemberBaseRecvNotifyById: VtMemberBase;
  getupdURecvNotifyByS3K4: Array<URecvNotify>;
  getupdVBIPaymentListNewlyPayArbeit: VbiPaymentListNewlyPayArbeit;
  getupdVBIdentificationInfoByBtmemId: VbIdentificationInfo;
  getupdVBIdentificationInfoByKey: VbIdentificationInfo;
  getupdVBMemberBaseById: VbMemberBase;
  getupdVTMPaymentListNewlyPayRefund: VtmPaymentListNewlyPayRefund;
  loginUMember?: Maybe<UMember>;
  updateAuthorization: DbResult;
  updateBArbeitScoreVisibleByWorkId: DbResult;
  updateBIdentificationApprovalByIdSubmitDate: DbResult;
  updateBIdentificationDenialByIdSubmitDate: DbResult;
  updateBMemberDataById?: Maybe<DbResult>;
  updateBMemberStatusById?: Maybe<DbResult>;
  updateBMemberStatusPauseDateById: DbResult;
  updateBMemberStatusRestartDateById: DbResult;
  updateBOfferS1ById: DbResult;
  updateBOfferS5ById: DbResult;
  updateCBillingCsvDlByCsvNo: DbResult;
  updateCMonthPayCsvDlByCsvNo: DbResult;
  updateCPaymentAndPayCsvByRequestNo: DbResult;
  updateCRequestPayCsvDlByCsvNo: DbResult;
  updateMBusinessIconById: MutateResult;
  updatePassword: DbResult;
  updatePasswordByEmail?: Maybe<DbResult>;
  updateSBenefitsByKind: DbResult;
  updateSBenefitsTicketNum: DbResult;
  updateSBenefitsValidDays: DbResult;
  updateSEgiftCodeByEgiftCode: DbResult;
  updateSLendStaffByBtRankIdBizId: DbResult;
  updateSTpmemBonusPer: DbResult;
  updateSTpmemTypeRadiusByTypeId1: DbResult;
  updateSTpmemTypeRecruitDaysByTypeId: DbResult;
  updateSTpmemTypeRecruitDaysByTypeId1: DbResult;
  updateSTpmemTypeRecruitNumByTypeId: DbResult;
  updateSTpmemTypeRecruitNumByTypeId1: DbResult;
  updateSTpmemTypeRecruitRatioByTypeId1: DbResult;
  updateStatusByEmail?: Maybe<DbResult>;
  updateTArbeitOffereStatusById: DbResult;
  updateTBaseById: DbResult;
  updateTManagerTMember?: Maybe<DbResult>;
  updateTMemberById?: Maybe<DbResult>;
  updateTMemberS1ById: DbResult;
  updateTMemberS2ById: DbResult;
  updateTMemberTrProcStatusById: DbResult;
  updateTMemberTrProcTypeById: DbResult;
  updateTScoreVisibleByWorkId: DbResult;
  updateTTpmemBlockById?: Maybe<DbResult>;
  updateUMemberById?: Maybe<DbResult>;
  updateUMemberEmailById: DbResult;
  updateUMemberPrivilegeById: DbResult;
  updateUMemberTfa0ById?: Maybe<DbResult>;
  updateUMemberTfa1ById?: Maybe<DbResult>;
  updateURecvNotifyReadBySysIdKind: DbResult;
  updateUSendNotify?: Maybe<DbResult>;
  updateUSendNotifyDraft?: Maybe<DbResult>;
  updateUTrialTicketS2ById?: Maybe<DbResult>;
  uploadSPaymentDays: DbResult;
  uploadSWithholdDay: DbResult;
  uploadTBase: DbResult;
};

export type MutationAddBPenaltyPointArgs = {
  id: Scalars['Int']['input'];
};

export type MutationAddMBrandByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
  brands: Array<InputMaybe<InMBrand>>;
};

export type MutationAddMBusinessDataArgs = {
  brands?: InputMaybe<Array<InputMaybe<InMBrand>>>;
  checklist_filepath?: InputMaybe<Scalars['String']['input']>;
  icon: Scalars['String']['input'];
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>>>;
  name: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
  s3_bucket?: InputMaybe<Scalars['String']['input']>;
  section_filepath?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddMLicenseByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>>>;
};

export type MutationAddSCampaignArgs = {
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  give_num: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
};

export type MutationAddSCampaignKind1Args = {
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  free_months: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationAddSCampaignKind2Args = {
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
};

export type MutationAddSCampaignKind3Args = {
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  give_num: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
};

export type MutationAddSCampaignKind11Args = {
  begin_date: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  end_date: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationAddSCampaignKind12Args = {
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  end_date: Scalars['String']['input'];
  incl_resident: Scalars['Boolean']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationAddSEgiftCodeArgs = {
  config_code: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  egift_type: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationAddSWithholdDayArgs = {
  price: Scalars['Int']['input'];
  tax: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type MutationAddTBaseArgs = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  brand_id: Scalars['Int']['input'];
  city: Scalars['String']['input'];
  kana: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip_code: Scalars['String']['input'];
};

export type MutationAddTTpmemBlockArgs = {
  block_ids: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationAddUMemberArgs = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
};

export type MutationAddUSendNotifyArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body: Scalars['String']['input'];
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  sys_id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationAddUSendNotifyDraftArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  sys_id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type MutationAddUTrialTicketArgs = {
  item: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  tpmem_id: Scalars['Int']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
};

export type MutationAddupdUMemberArgs = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
};

export type MutationDelInsMLicenseByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>>>;
};

export type MutationDelInsMSkillCheckByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
  checklist_filepath?: InputMaybe<Scalars['String']['input']>;
  s3_bucket?: InputMaybe<Scalars['String']['input']>;
  section_filepath?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteSCampaignByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteSEgiftCodeByEgiftCodeArgs = {
  egift_code: Scalars['Int']['input'];
};

export type MutationDeleteSWithholdDayByYearArgs = {
  year: Scalars['Int']['input'];
};

export type MutationDeleteTTpmemBlockByIdArgs = {
  block_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type MutationDeleteUMemberByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteUSendNotifyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationGetVtMemberBaseRecvNotifyByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationGetupdVbIdentificationInfoByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type MutationGetupdVbIdentificationInfoByKeyArgs = {
  internal_key: Scalars['Int']['input'];
};

export type MutationGetupdVbMemberBaseByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationLoginUMemberArgs = {
  cognito_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdateAuthorizationArgs = {
  code: Scalars['String']['input'];
};

export type MutationUpdateBArbeitScoreVisibleByWorkIdArgs = {
  work_id: Scalars['Int']['input'];
};

export type MutationUpdateBIdentificationApprovalByIdSubmitDateArgs = {
  id: Scalars['Int']['input'];
  submit_date: Scalars['String']['input'];
};

export type MutationUpdateBIdentificationDenialByIdSubmitDateArgs = {
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  submit_date: Scalars['String']['input'];
};

export type MutationUpdateBMemberDataByIdArgs = {
  dob: Scalars['String']['input'];
  first_kana: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  gender: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  last_kana: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
};

export type MutationUpdateBMemberStatusByIdArgs = {
  id: Scalars['Int']['input'];
  status: Scalars['Int']['input'];
};

export type MutationUpdateBMemberStatusPauseDateByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateBMemberStatusRestartDateByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateBOfferS1ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateBOfferS5ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateCBillingCsvDlByCsvNoArgs = {
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
};

export type MutationUpdateCMonthPayCsvDlByCsvNoArgs = {
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
};

export type MutationUpdateCPaymentAndPayCsvByRequestNoArgs = {
  output_uid: Scalars['Int']['input'];
  request_no: Scalars['String']['input'];
  wire_date: Scalars['String']['input'];
};

export type MutationUpdateCRequestPayCsvDlByCsvNoArgs = {
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
};

export type MutationUpdateMBusinessIconByIdArgs = {
  icon: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUpdatePasswordArgs = {
  id: Scalars['Int']['input'];
  old_password: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdatePasswordByEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationUpdateSBenefitsByKindArgs = {
  egift_code: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
};

export type MutationUpdateSBenefitsTicketNumArgs = {
  iv_ticket_num: Scalars['Int']['input'];
};

export type MutationUpdateSBenefitsValidDaysArgs = {
  iv_valid_days: Scalars['Int']['input'];
};

export type MutationUpdateSEgiftCodeByEgiftCodeArgs = {
  config_code: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};

export type MutationUpdateSLendStaffByBtRankIdBizIdArgs = {
  biz_id: Scalars['Int']['input'];
  bt_rank_id: Scalars['Int']['input'];
  educ_fee: Scalars['Int']['input'];
  educ_fee_max: Scalars['Int']['input'];
  educ_fee_min: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemBonusPerArgs = {
  bonus_per: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemTypeRadiusByTypeId1Args = {
  radius: Scalars['Float']['input'];
};

export type MutationUpdateSTpmemTypeRecruitDaysByTypeIdArgs = {
  recruit_days: Scalars['Int']['input'];
  type_id: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemTypeRecruitDaysByTypeId1Args = {
  recruit_days: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemTypeRecruitNumByTypeIdArgs = {
  recruit_num: Scalars['Int']['input'];
  type_id: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemTypeRecruitNumByTypeId1Args = {
  recruit_num: Scalars['Int']['input'];
};

export type MutationUpdateSTpmemTypeRecruitRatioByTypeId1Args = {
  recruit_ratio: Scalars['Int']['input'];
};

export type MutationUpdateStatusByEmailArgs = {
  email: Scalars['String']['input'];
  status: Scalars['Int']['input'];
};

export type MutationUpdateTArbeitOffereStatusByIdArgs = {
  id: Scalars['Int']['input'];
  status: Scalars['Int']['input'];
};

export type MutationUpdateTBaseByIdArgs = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip_code: Scalars['String']['input'];
};

export type MutationUpdateTManagerTMemberArgs = {
  close_time: Scalars['String']['input'];
  corporate_no: Scalars['String']['input'];
  corporate_type: Scalars['Int']['input'];
  dob: Scalars['String']['input'];
  first_kana: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  image: Scalars['String']['input'];
  last_kana: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  mco_address1: Scalars['String']['input'];
  mco_address2: Scalars['String']['input'];
  mco_city: Scalars['String']['input'];
  mco_m_first_kana: Scalars['String']['input'];
  mco_m_first_name: Scalars['String']['input'];
  mco_m_last_kana: Scalars['String']['input'];
  mco_m_last_name: Scalars['String']['input'];
  mco_name: Scalars['String']['input'];
  mco_phone: Scalars['String']['input'];
  mco_state: Scalars['String']['input'];
  mco_t_first_kana: Scalars['String']['input'];
  mco_t_first_name: Scalars['String']['input'];
  mco_t_last_kana: Scalars['String']['input'];
  mco_t_last_name: Scalars['String']['input'];
  mco_t_phone: Scalars['String']['input'];
  mco_zip_code: Scalars['String']['input'];
  open_time: Scalars['String']['input'];
  parking: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
  tpmem_id: Scalars['Int']['input'];
};

export type MutationUpdateTMemberByIdArgs = {
  close_time: Scalars['String']['input'];
  corporate_no: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  mco_address1: Scalars['String']['input'];
  mco_address2: Scalars['String']['input'];
  mco_city: Scalars['String']['input'];
  mco_m_first_kana: Scalars['String']['input'];
  mco_m_first_name: Scalars['String']['input'];
  mco_m_last_kana: Scalars['String']['input'];
  mco_m_last_name: Scalars['String']['input'];
  mco_name: Scalars['String']['input'];
  mco_phone: Scalars['String']['input'];
  mco_state: Scalars['String']['input'];
  mco_t_first_kana: Scalars['String']['input'];
  mco_t_first_name: Scalars['String']['input'];
  mco_t_last_kana: Scalars['String']['input'];
  mco_t_last_name: Scalars['String']['input'];
  mco_t_phone: Scalars['String']['input'];
  mco_zip_code: Scalars['String']['input'];
  mgr_id: Scalars['Int']['input'];
  open_time: Scalars['String']['input'];
  parking: Scalars['Boolean']['input'];
};

export type MutationUpdateTMemberS1ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateTMemberS2ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateTMemberTrProcStatusByIdArgs = {
  id: Scalars['Int']['input'];
  tr_proc_status: Scalars['Int']['input'];
  tr_proc_uid: Scalars['Int']['input'];
};

export type MutationUpdateTMemberTrProcTypeByIdArgs = {
  id: Scalars['Int']['input'];
  tr_proc_type: Scalars['Int']['input'];
};

export type MutationUpdateTScoreVisibleByWorkIdArgs = {
  work_id: Scalars['Int']['input'];
};

export type MutationUpdateTTpmemBlockByIdArgs = {
  block_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  reg_date: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationUpdateUMemberByIdArgs = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
};

export type MutationUpdateUMemberEmailByIdArgs = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type MutationUpdateUMemberPrivilegeByIdArgs = {
  id: Scalars['Int']['input'];
  privilege: Scalars['Int']['input'];
};

export type MutationUpdateUMemberTfa0ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateUMemberTfa1ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateURecvNotifyReadBySysIdKindArgs = {
  kind: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
};

export type MutationUpdateUSendNotifyArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body: Scalars['String']['input'];
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
};

export type MutationUpdateUSendNotifyDraftArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  sys_id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type MutationUpdateUTrialTicketS2ByIdArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUploadSPaymentDaysArgs = {
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type MutationUploadSWithholdDayArgs = {
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type MutationUploadTBaseArgs = {
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  getAuthorization?: Maybe<DbResultStatus>;
  getBLoggingByBtmemIdKind?: Maybe<Array<Maybe<BLogging>>>;
  getBMemberById?: Maybe<BMember>;
  getCBillingCsv?: Maybe<Array<Maybe<CBillingCsv>>>;
  getCBillingCsvByKind?: Maybe<Array<Maybe<CBillingCsv>>>;
  getCPaymentBySysIdCategoryRequestRequestNo?: Maybe<CPayment>;
  getMAchieve?: Maybe<Array<Maybe<MAchieve>>>;
  getMAchieveById?: Maybe<MAchieve>;
  getMAchieveByKind?: Maybe<Array<Maybe<MAchieve>>>;
  getMArea?: Maybe<Array<Maybe<MArea>>>;
  getMAreaById?: Maybe<MArea>;
  getMAreaState?: Maybe<Array<Maybe<MAreaState>>>;
  getMAreaStateById?: Maybe<Array<Maybe<MAreaState>>>;
  getMAutoBadge?: Maybe<Array<Maybe<MAutoBadge>>>;
  getMAutoBadgeByCount?: Maybe<MAutoBadge>;
  getMAutoBadgeById?: Maybe<MAutoBadge>;
  getMAutoBadgeByMaxCount?: Maybe<MAutoBadge>;
  getMBadgeArea?: Maybe<Array<Maybe<MBadgeArea>>>;
  getMBadgeAreaById?: Maybe<MBadgeArea>;
  getMBadgeAreaDef?: Maybe<Array<Maybe<MBadgeAreaDef>>>;
  getMBadgeAreaDefByCity?: Maybe<Array<Maybe<MBadgeAreaDef>>>;
  getMBadgeAreaDefById?: Maybe<Array<Maybe<MBadgeAreaDef>>>;
  getMBadgeAreaDefByIdCity?: Maybe<MBadgeAreaDef>;
  getMBankBranch?: Maybe<Array<Maybe<MBankBranch>>>;
  getMBankBranchByCode?: Maybe<Array<Maybe<MBankBranch>>>;
  getMBankBranchByCodeBranch?: Maybe<Array<Maybe<MBankBranch>>>;
  getMBankCode?: Maybe<Array<Maybe<MBankCode>>>;
  getMBankCodeBranch?: Maybe<Array<Maybe<MBankCodeBranch>>>;
  getMBankCodeBranchByCode?: Maybe<Array<Maybe<MBankCodeBranch>>>;
  getMBankCodeByCode?: Maybe<MBankCode>;
  getMBrand?: Maybe<Array<Maybe<MBrand>>>;
  getMBrandByBizId?: Maybe<Array<Maybe<MBrand>>>;
  getMBrandById?: Maybe<MBrand>;
  getMBtmemRank?: Maybe<Array<Maybe<MBtmemRank>>>;
  getMBtmemRankById?: Maybe<MBtmemRank>;
  getMBusiness?: Maybe<Array<Maybe<MBusiness>>>;
  getMBusinessBrand?: Maybe<Array<Maybe<MBusinessBrand>>>;
  getMBusinessBrandById?: Maybe<Array<Maybe<MBusinessBrand>>>;
  getMBusinessBrandByPrefix?: Maybe<Array<Maybe<MBusinessBrand>>>;
  getMBusinessById?: Maybe<MBusiness>;
  getMBusinessByPrefix?: Maybe<MBusiness>;
  getMBusinessDataById?: Maybe<MBusinessData>;
  getMCancelReason?: Maybe<Array<Maybe<MCancelReason>>>;
  getMCancelReasonBySysId?: Maybe<Array<Maybe<MCancelReason>>>;
  getMCancelReasonBySysIdNo?: Maybe<MCancelReason>;
  getMCity?: Maybe<Array<Maybe<MCity>>>;
  getMCityById?: Maybe<MCity>;
  getMCityByStateId?: Maybe<Array<Maybe<MCity>>>;
  getMConqArea?: Maybe<Array<Maybe<MConqArea>>>;
  getMConqAreaById?: Maybe<Array<Maybe<MConqArea>>>;
  getMConqBadge?: Maybe<Array<Maybe<MConqBadge>>>;
  getMConqBadgeById?: Maybe<MConqBadge>;
  getMConqBadgeList?: Maybe<Array<Maybe<MConqBadgeList>>>;
  getMCountry?: Maybe<Array<Maybe<MCountry>>>;
  getMCountryById?: Maybe<MCountry>;
  getMIdCardType?: Maybe<Array<Maybe<MIdCardType>>>;
  getMIdCardTypeById?: Maybe<MIdCardType>;
  getMLicense?: Maybe<Array<Maybe<MLicense>>>;
  getMLicenseByBizId?: Maybe<Array<Maybe<MLicense>>>;
  getMLicenseById?: Maybe<MLicense>;
  getMMessage?: Maybe<Array<Maybe<MMessage>>>;
  getMMessageByNo?: Maybe<MMessage>;
  getMNotifyTpl?: Maybe<Array<Maybe<MNotifyTpl>>>;
  getMNotifyTplById?: Maybe<MNotifyTpl>;
  getMNotifyTplBySysId?: Maybe<Array<Maybe<MNotifyTpl>>>;
  getMOccAttr?: Maybe<Array<Maybe<MOccAttr>>>;
  getMOccAttrById?: Maybe<MOccAttr>;
  getMPenaltyDef?: Maybe<Array<Maybe<MPenaltyDef>>>;
  getMPenaltyDefByIdDecision?: Maybe<MPenaltyDef>;
  getMPenaltyDefByIdMaxDecision?: Maybe<MPenaltyDef>;
  getMPenaltyDefByIdMinDecision?: Maybe<MPenaltyDef>;
  getMPenaltyType?: Maybe<Array<Maybe<MPenaltyType>>>;
  getMPenaltyTypeById?: Maybe<MPenaltyType>;
  getMRejectReason?: Maybe<Array<Maybe<MRejectReason>>>;
  getMRejectReasonByKind?: Maybe<Array<Maybe<MRejectReason>>>;
  getMRejectReasonByKindNo?: Maybe<MRejectReason>;
  getMResidence?: Maybe<Array<Maybe<MResidence>>>;
  getMResidenceById?: Maybe<MResidence>;
  getMSkillCheck?: Maybe<Array<Maybe<MSkillCheck>>>;
  getMSkillCheckByBizIdNo?: Maybe<MSkillCheck>;
  getMSkillCheckByBtRankId?: Maybe<Array<Maybe<MSkillCheck>>>;
  getMSkillSection?: Maybe<Array<Maybe<MSkillSection>>>;
  getMSkillSectionByBizId?: Maybe<Array<Maybe<MSkillSection>>>;
  getMSkillSectionByBizIdNo?: Maybe<MSkillSection>;
  getMState?: Maybe<Array<Maybe<MState>>>;
  getMStateByAreaId?: Maybe<Array<Maybe<MState>>>;
  getMStateById?: Maybe<MState>;
  getMStateCity?: Maybe<Array<Maybe<MStateCity>>>;
  getMStateCityByAreaId?: Maybe<Array<Maybe<MStateCity>>>;
  getMStateCityById?: Maybe<Array<Maybe<MStateCity>>>;
  getMSystemType?: Maybe<Array<Maybe<MSystemType>>>;
  getMSystemTypeById?: Maybe<MSystemType>;
  getMTos?: Maybe<Array<Maybe<MTos>>>;
  getMTosByKind?: Maybe<Array<Maybe<MTos>>>;
  getMTosBySysId?: Maybe<Array<Maybe<MTos>>>;
  getMTosBySysIdKind?: Maybe<MTos>;
  getMTpmemType?: Maybe<Array<Maybe<MTpmemType>>>;
  getMTpmemTypeById?: Maybe<MTpmemType>;
  getMUniformSize?: Maybe<Array<Maybe<MUniformSize>>>;
  getMUniformSizeById?: Maybe<MUniformSize>;
  getSBenefits: SBenefits;
  getSEgiftCode: Array<SEgiftCode>;
  getSEgiftCodeByThanksGift0: Array<SEgiftCode>;
  getSEgiftCodeByThanksGift1: Array<SEgiftCode>;
  getSPaymentDaysByYear: SPaymentDaysNewly;
  getSTpmem: STpmem;
  getSTpmemTypeByTypeId1: STpmemType;
  getSWithholdDayByYear: SWithholdDayVuNewlyAccounting;
  getSYearScheduleGp: Array<SYearScheduleGp>;
  getSYearScheduleGpByPaymentRegist: Array<SYearScheduleGp>;
  getSYearScheduleGpByWithholdRegist: Array<SYearScheduleGp>;
  getTTpmemBlockById?: Maybe<TTpmemBlock>;
  getTWithholdSlipByBtmemId?: Maybe<TWithholdSlip>;
  getTmemberCsvDataForMfk?: Maybe<TmemberCsvMfk>;
  getUMemberByEmail?: Maybe<UMember>;
  getUMemberById?: Maybe<UMember>;
  getUMembers?: Maybe<Array<Maybe<UMember>>>;
  getURecvNotifyByS2K3: URecvNotifyHome;
  getURecvNotifyByS2K4: Array<URecvNotify>;
  getURecvNotifyByS2K6: Array<URecvNotify>;
  getURecvNotifyByS2K7: Array<URecvNotify>;
  getURecvNotifyByS3K1: URecvNotifyHome;
  getURecvNotifyByS3K2: Array<URecvNotify>;
  getURecvNotifyByS3K3: Array<URecvNotify>;
  getURecvNotifyByS3K5: Array<URecvNotify>;
  getVBCancelInfoById: VbCancelInfo;
  getVBCpGiftListByKind11: Array<VbCpGiftList>;
  getVBCpGiftListByKind12: Array<VbCpGiftList>;
  getVBFavoriteStoreListById?: Maybe<Array<Maybe<VbFavoriteStoreList>>>;
  getVBGiftSettingList: Array<VbGiftSettingList>;
  getVBIPayCsvListNewlyPayArbeit: VbiPayCsvListNewlyPayArbeit;
  getVBIdentificationInfo?: Maybe<Array<Maybe<VbIdentificationInfo>>>;
  getVBIdentificationList: Array<VbIdentificationList>;
  getVBIdentificationListByBtmemId: VbIdentificationList;
  getVBIdentificationListByKey: VbIdentificationList;
  getVBIdentificationLoggingListByBtmemId: VbIdentificationLogging;
  getVBIdentificationLoggingListByKey: VbIdentificationLogging;
  getVBMPayCsvListNewlyPayArbeit: VbmPayCsvListNewlyPayArbeit;
  getVBMemberBaseById: VbMemberBase;
  getVBMemberList: Array<VbMemberList>;
  getVBMemberListFavoriteById: VbMemberListFavorite;
  getVBMemberListLoggingById: VbMemberListLogging;
  getVBMemberListWithholdById: VbMemberListWithhold;
  getVBOfferAcceptInfoByAcceptId: VbOfferAcceptInfo;
  getVBOfferAcceptListByOfferId: Array<VbOfferAcceptList>;
  getVBOfferInfoById: VbOfferInfo;
  getVBOfferList: Array<VbOfferList>;
  getVBOfferListAcceptByOfferId: VbOfferListAccept;
  getVBOfferListLoggingByOfferId: VbOfferListLogging;
  getVBPayCsvAllList: VbPayCsvAllListNewly;
  getVBReviewList?: Maybe<Array<Maybe<VbReviewList>>>;
  getVBReviewListScoreByBtmemId: VbReviewListScore;
  getVBScorePercentInfoByBtmemId: VbScorePercentInfo;
  getVBWithholdSlipListByBtmemId?: Maybe<Array<Maybe<VbWithholdSlipList>>>;
  getVBWithholdSlipListByBtmemIdCorporateNo: VbWithholdSlipListData;
  getVBWithholdingSlipDataByGCcalBIdCNo?: Maybe<VbWithholdingSlipData>;
  getVBWorkScoreList: Array<VbWorkScoreList>;
  getVSLendStaffListByBizId: Array<VsLendStaffList>;
  getVSelBlockListById?: Maybe<Array<Maybe<VSelBlockList>>>;
  getVSelBlockListByMyIdBizIdBrandId: Array<VSelBlockList>;
  getVTArbeitEntryList?: Maybe<Array<Maybe<VtArbeitEntryList>>>;
  getVTArbeitEntryListWorkingByEntryId: VtArbeitEntryListWorking;
  getVTArbeitOfferEntryListById: VtArbeitOfferEntry;
  getVTArbeitOfferInfo?: Maybe<Array<Maybe<VtArbeitOfferInfo>>>;
  getVTArbeitOfferInfoById: VtArbeitOfferInfoList;
  getVTArbeitOfferInfoWorkingById: VtArbeitOfferInfoWorking;
  getVTArbeitOfferList: Array<VtArbeitOfferList>;
  getVTArbeitOfferLogging1ListById: VtArbeitOfferLogging;
  getVTBalanceList: VtBalanceListWalletNnewly;
  getVTBelongList?: Maybe<Array<Maybe<VtBelongList>>>;
  getVTBelongListMemberByTpmemIdId: VtBelongListMember;
  getVTBillCsvListNewlyBillStoreK1: VtBillCsvListNewlyBillStore;
  getVTBillCsvListNewlyBillStoreK2: VtBillCsvListNewlyBillStore;
  getVTBlockList?: Maybe<Array<Maybe<VtBlockList>>>;
  getVTBlockListById?: Maybe<Array<Maybe<VtBlockList>>>;
  getVTBrandStateInfoCountByStateIdBrandId: VtBrandStateInfoCount;
  getVTCpFreeList: Array<VtCpFreeList>;
  getVTCpTicketList?: Maybe<Array<Maybe<VtCpTicketList>>>;
  getVTCpTicketListByKind2: Array<VtCpTicketList>;
  getVTCpTicketListByKind3: Array<VtCpTicketList>;
  getVTGroupList?: Maybe<Array<Maybe<VtGroupList>>>;
  getVTGroupListById?: Maybe<Array<Maybe<VtGroupList>>>;
  getVTInvitedGroupInfoByTpmemId?: Maybe<VtInvitedGroupInfo>;
  getVTMPayCsvListNewlyPayRefund: VtmPayCsvListNewlyPayRefund;
  getVTMemberBase?: Maybe<Array<Maybe<VtMemberBase>>>;
  getVTMemberBaseById: VtMemberBase;
  getVTMemberBelongListById: VtMemberBelong;
  getVTMemberBlockListById: VtMemberBlock;
  getVTMemberGroupListById: VtMemberGroup;
  getVTMemberList: VtMemberListCount;
  getVTMemberList2: VtMemberListCount;
  getVTMemberListById?: Maybe<VtMemberList>;
  getVTMemberListWithOrder: VtMemberListCount;
  getVTMemberLogging3ListById: VtMemberLogging;
  getVTMemberLogging5ListById: VtMemberLogging;
  getVTMemberTicketListById: VtMemberTicket;
  getVTReviewList?: Maybe<Array<Maybe<VtReviewList>>>;
  getVTReviewListByTpmemId?: Maybe<Array<Maybe<VtReviewList>>>;
  getVTScoreList: Array<VtScoreList>;
  getVTScorePercentInfo?: Maybe<Array<Maybe<VtScorePercentInfo>>>;
  getVTScorePercentInfoByTpmemId: VtScorePercentInfoList;
  getVTSelectStoreListByBrandId: Array<VtSelectStoreList>;
  getVTTicketList?: Maybe<Array<Maybe<VtTicketList>>>;
  getVTTicketMgtInfo?: Maybe<Array<Maybe<VtTicketMgtInfo>>>;
  getVTTransProcListNewly: VtTransProcListNewly;
  getVTWorkScoreInfoByWorkId?: Maybe<VtWorkScoreInfo>;
  getVTWorkingList: Array<VtWorkingList>;
  getVTWorkingLogging2ListByOfferId: VtWorkingLogging;
  getVUMemberList: Array<VuMemberList>;
  getVUNewlyMenuAll?: Maybe<VuNewlyMenuAll>;
  getVUSendNotifyInfo?: Maybe<Array<Maybe<VuSendNotifyInfo>>>;
  getVUSendNotifyInfoById: VuSendNotifyInfo;
  getVUSendNotifyList: Array<VuSendNotifyList>;
  getVWorkScoreInfoByWorkId: VWorkScoreInfo;
  isSBenefitsSCampaign: BoolResult;
};

export type QueryGetBLoggingByBtmemIdKindArgs = {
  btmem_id: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
};

export type QueryGetBMemberByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCBillingCsvByKindArgs = {
  kind: Scalars['Int']['input'];
};

export type QueryGetCPaymentBySysIdCategoryRequestRequestNoArgs = {
  category: Scalars['Int']['input'];
  request: Scalars['Int']['input'];
  request_no: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
};

export type QueryGetMAchieveByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMAchieveByKindArgs = {
  knd: Scalars['Int']['input'];
};

export type QueryGetMAreaByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMAreaStateByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMAutoBadgeByCountArgs = {
  cnt: Scalars['Int']['input'];
};

export type QueryGetMAutoBadgeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMAutoBadgeByMaxCountArgs = {
  cnt: Scalars['Int']['input'];
};

export type QueryGetMBadgeAreaByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMBadgeAreaDefByCityArgs = {
  cty: Scalars['String']['input'];
};

export type QueryGetMBadgeAreaDefByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMBadgeAreaDefByIdCityArgs = {
  cty: Scalars['String']['input'];
  mid: Scalars['Int']['input'];
};

export type QueryGetMBankBranchByCodeArgs = {
  mcode: Scalars['String']['input'];
};

export type QueryGetMBankBranchByCodeBranchArgs = {
  mbrc: Scalars['String']['input'];
  mcode: Scalars['String']['input'];
};

export type QueryGetMBankCodeBranchByCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryGetMBankCodeByCodeArgs = {
  mcode: Scalars['String']['input'];
};

export type QueryGetMBrandByBizIdArgs = {
  bid: Scalars['Int']['input'];
};

export type QueryGetMBrandByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMBtmemRankByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMBusinessBrandByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMBusinessBrandByPrefixArgs = {
  prefix: Scalars['String']['input'];
};

export type QueryGetMBusinessByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMBusinessByPrefixArgs = {
  mpfx: Scalars['String']['input'];
};

export type QueryGetMBusinessDataByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMCancelReasonBySysIdArgs = {
  sys_id: Scalars['Int']['input'];
};

export type QueryGetMCancelReasonBySysIdNoArgs = {
  no: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
};

export type QueryGetMCityByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMCityByStateIdArgs = {
  sid: Scalars['Int']['input'];
};

export type QueryGetMConqAreaByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMConqBadgeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMCountryByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMIdCardTypeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMLicenseByBizIdArgs = {
  bid: Scalars['Int']['input'];
};

export type QueryGetMLicenseByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMMessageByNoArgs = {
  no: Scalars['Int']['input'];
};

export type QueryGetMNotifyTplByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMNotifyTplBySysIdArgs = {
  sid: Scalars['Int']['input'];
};

export type QueryGetMOccAttrByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMPenaltyDefByIdDecisionArgs = {
  dec: Scalars['Int']['input'];
  mid: Scalars['Int']['input'];
};

export type QueryGetMPenaltyDefByIdMaxDecisionArgs = {
  dec: Scalars['Int']['input'];
  mid: Scalars['Int']['input'];
};

export type QueryGetMPenaltyDefByIdMinDecisionArgs = {
  dec: Scalars['Int']['input'];
  mid: Scalars['Int']['input'];
};

export type QueryGetMPenaltyTypeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMRejectReasonByKindArgs = {
  kind: Scalars['Int']['input'];
};

export type QueryGetMRejectReasonByKindNoArgs = {
  kind: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
};

export type QueryGetMResidenceByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMSkillCheckByBizIdNoArgs = {
  bid: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
};

export type QueryGetMSkillCheckByBtRankIdArgs = {
  rid: Scalars['Int']['input'];
};

export type QueryGetMSkillSectionByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
};

export type QueryGetMSkillSectionByBizIdNoArgs = {
  biz_id: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
};

export type QueryGetMStateByAreaIdArgs = {
  aid: Scalars['Int']['input'];
};

export type QueryGetMStateByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMStateCityByAreaIdArgs = {
  area_id: Scalars['Int']['input'];
};

export type QueryGetMStateCityByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMSystemTypeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMTosByKindArgs = {
  kind: Scalars['Int']['input'];
};

export type QueryGetMTosBySysIdArgs = {
  sid: Scalars['Int']['input'];
};

export type QueryGetMTosBySysIdKindArgs = {
  kind: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
};

export type QueryGetMTpmemTypeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetMUniformSizeByIdArgs = {
  mid: Scalars['Int']['input'];
};

export type QueryGetSPaymentDaysByYearArgs = {
  year: Scalars['Int']['input'];
};

export type QueryGetSWithholdDayByYearArgs = {
  year: Scalars['Int']['input'];
};

export type QueryGetTTpmemBlockByIdArgs = {
  block_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type QueryGetTWithholdSlipByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetUMemberByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryGetUMemberByIdArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetVbCancelInfoByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbFavoriteStoreListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbIdentificationListByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetVbIdentificationListByKeyArgs = {
  internal_key: Scalars['Int']['input'];
};

export type QueryGetVbIdentificationLoggingListByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetVbIdentificationLoggingListByKeyArgs = {
  internal_key: Scalars['Int']['input'];
};

export type QueryGetVbMemberBaseByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbMemberListFavoriteByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbMemberListLoggingByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbMemberListWithholdByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbOfferAcceptInfoByAcceptIdArgs = {
  accept_id: Scalars['Int']['input'];
};

export type QueryGetVbOfferAcceptListByOfferIdArgs = {
  b_offer_id: Scalars['Int']['input'];
};

export type QueryGetVbOfferInfoByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVbOfferListAcceptByOfferIdArgs = {
  offer_id: Scalars['Int']['input'];
};

export type QueryGetVbOfferListLoggingByOfferIdArgs = {
  offer_id: Scalars['Int']['input'];
};

export type QueryGetVbReviewListScoreByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetVbScorePercentInfoByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetVbWithholdSlipListByBtmemIdArgs = {
  btmem_id: Scalars['Int']['input'];
};

export type QueryGetVbWithholdSlipListByBtmemIdCorporateNoArgs = {
  btmem_id: Scalars['Int']['input'];
  corporate_no: Scalars['String']['input'];
};

export type QueryGetVbWithholdingSlipDataByGCcalBIdCNoArgs = {
  btmem_id: Scalars['Int']['input'];
  corporate_no: Scalars['String']['input'];
  gregorian_cal: Scalars['Int']['input'];
};

export type QueryGetVsLendStaffListByBizIdArgs = {
  biz_id: Scalars['Int']['input'];
};

export type QueryGetVSelBlockListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVSelBlockListByMyIdBizIdBrandIdArgs = {
  biz_id: Scalars['Int']['input'];
  brand_id: Scalars['Int']['input'];
  my_id: Scalars['Int']['input'];
};

export type QueryGetVtArbeitEntryListWorkingByEntryIdArgs = {
  entry_id: Scalars['Int']['input'];
};

export type QueryGetVtArbeitOfferEntryListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtArbeitOfferInfoByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtArbeitOfferInfoWorkingByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtArbeitOfferLogging1ListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtBelongListMemberByTpmemIdIdArgs = {
  id: Scalars['Int']['input'];
  tpmem_id: Scalars['Int']['input'];
};

export type QueryGetVtBlockListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtBrandStateInfoCountByStateIdBrandIdArgs = {
  brand_ids: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
};

export type QueryGetVtGroupListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtInvitedGroupInfoByTpmemIdArgs = {
  tpmem_id: Scalars['Int']['input'];
};

export type QueryGetVtMemberBaseByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberBelongListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberBlockListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberGroupListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberListArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code_name?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetVtMemberList2Args = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetVtMemberListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberListWithOrderArgs = {
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code_name?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order_name?: InputMaybe<Scalars['String']['input']>;
  payment_method?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetVtMemberLogging3ListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberLogging5ListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtMemberTicketListByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVtReviewListByTpmemIdArgs = {
  tpmem_id: Scalars['Int']['input'];
};

export type QueryGetVtScorePercentInfoByTpmemIdArgs = {
  tpmem_id: Scalars['Int']['input'];
};

export type QueryGetVtSelectStoreListByBrandIdArgs = {
  brand_id: Scalars['Int']['input'];
};

export type QueryGetVtWorkScoreInfoByWorkIdArgs = {
  work_id: Scalars['Int']['input'];
};

export type QueryGetVtWorkingLogging2ListByOfferIdArgs = {
  offer_id: Scalars['Int']['input'];
};

export type QueryGetVuSendNotifyInfoByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetVWorkScoreInfoByWorkIdArgs = {
  work_id: Scalars['Int']['input'];
};

export type QueryIsSBenefitsSCampaignArgs = {
  egift_code: Scalars['Int']['input'];
};

export type SBenefits = {
  __typename?: 'SBenefits';
  bd_egift_code: Scalars['Int']['output'];
  iv_egift_code: Scalars['Int']['output'];
  iv_ticket_num: Scalars['Int']['output'];
  iv_valid_days: Scalars['Int']['output'];
  review_egift_code: Scalars['Int']['output'];
};

export type SEgiftCode = {
  __typename?: 'SEgiftCode';
  config_code?: Maybe<Scalars['String']['output']>;
  deadline?: Maybe<Scalars['String']['output']>;
  egift_code: Scalars['Int']['output'];
  egift_type: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  thanks_gift?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type SPaymentDays = {
  __typename?: 'SPaymentDays';
  day: Scalars['String']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type SPaymentDaysNewly = {
  __typename?: 'SPaymentDaysNewly';
  newly: VuNewlyAccounting;
  payment: Array<SPaymentDays>;
  schedule: SYearSchedule;
};

export type STpmem = {
  __typename?: 'STpmem';
  bonus_per?: Maybe<Scalars['Int']['output']>;
  checkin_start?: Maybe<Scalars['Int']['output']>;
  checkout_start?: Maybe<Scalars['Int']['output']>;
  finish_timeout?: Maybe<Scalars['Int']['output']>;
  fix_timeout?: Maybe<Scalars['Int']['output']>;
  hunt_accept?: Maybe<Scalars['Int']['output']>;
  penalty?: Maybe<Scalars['Int']['output']>;
  tax_per?: Maybe<Scalars['Int']['output']>;
};

export type STpmemType = {
  __typename?: 'STpmemType';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  monthly_fee?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Boolean']['output']>;
  publ_fee?: Maybe<Scalars['Int']['output']>;
  radius?: Maybe<Scalars['Float']['output']>;
  recruit_days?: Maybe<Scalars['Int']['output']>;
  recruit_num?: Maybe<Scalars['Int']['output']>;
  recruit_ratio?: Maybe<Scalars['Int']['output']>;
  reg_staff?: Maybe<Scalars['Boolean']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  video_fee?: Maybe<Scalars['Int']['output']>;
};

export type SWithholdDay = {
  __typename?: 'SWithholdDay';
  price?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type SWithholdDayVuNewlyAccounting = {
  __typename?: 'SWithholdDayVUNewlyAccounting';
  newly: VuNewlyAccounting;
  schedule: SYearSchedule;
  withhold: Array<SWithholdDay>;
};

export type SYearSchedule = {
  __typename?: 'SYearSchedule';
  payment_regist?: Maybe<Scalars['String']['output']>;
  withhold_regist?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type SYearScheduleGp = {
  __typename?: 'SYearScheduleGp';
  year: Scalars['Int']['output'];
};

export type TBase = {
  __typename?: 'TBase';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  kana?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type TLogging = {
  __typename?: 'TLogging';
  id: Scalars['Int']['output'];
  kind: Scalars['Int']['output'];
  log_date?: Maybe<Scalars['String']['output']>;
  matter?: Maybe<Scalars['String']['output']>;
  ref_no?: Maybe<Scalars['Int']['output']>;
  tpmem_id: Scalars['Int']['output'];
};

export type TTpmemBlock = {
  __typename?: 'TTpmemBlock';
  block_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reg_date?: Maybe<Scalars['String']['output']>;
  umgmt_id?: Maybe<Scalars['Int']['output']>;
};

export type TWalletSum = {
  __typename?: 'TWalletSUM';
  price?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type TWithholdSlip = {
  __typename?: 'TWithholdSlip';
  btmem_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  month_val?: Maybe<Scalars['Int']['output']>;
  withholding?: Maybe<Scalars['Int']['output']>;
  year_val?: Maybe<Scalars['Int']['output']>;
};

export type TmemberCsvMfk = {
  __typename?: 'TmemberCsvMfk';
  csv_data?: Maybe<Scalars['String']['output']>;
  resultRows: Scalars['Int']['output'];
};

export type UMember = {
  __typename?: 'UMember';
  cognito_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_kana?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_kana?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  privilege?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
  tfa?: Maybe<Scalars['Int']['output']>;
};

export type URecvNotify = {
  __typename?: 'URecvNotify';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_read: Scalars['Boolean']['output'];
  kind: Scalars['Int']['output'];
  recv_date?: Maybe<Scalars['String']['output']>;
  ref_no?: Maybe<Scalars['Int']['output']>;
  screen_no?: Maybe<Scalars['String']['output']>;
  sys_id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type URecvNotifyHome = {
  __typename?: 'URecvNotifyHome';
  home: VuNewlyHome;
  list: Array<URecvNotify>;
};

export type USendNotify = {
  __typename?: 'USendNotify';
  attr_id?: Maybe<Scalars['Int']['output']>;
  belonged?: Maybe<Scalars['Boolean']['output']>;
  biz_id?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  send_date?: Maybe<Scalars['String']['output']>;
  sys_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  umgmt_id?: Maybe<Scalars['Int']['output']>;
  update_date?: Maybe<Scalars['String']['output']>;
};

export type VbCancelInfo = {
  __typename?: 'VBCancelInfo';
  cancel_count?: Maybe<Scalars['Int']['output']>;
  cancel_per?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imminent_count?: Maybe<Scalars['Int']['output']>;
  imminent_per?: Maybe<Scalars['Float']['output']>;
  late_count?: Maybe<Scalars['Int']['output']>;
  late_per?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  penalty?: Maybe<Scalars['Int']['output']>;
  skip_count?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
};

export type VbCpGiftList = {
  __typename?: 'VBCpGiftList';
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  biz_count?: Maybe<Scalars['Int']['output']>;
  biz_ids?: Maybe<Scalars['String']['output']>;
  biz_names?: Maybe<Scalars['String']['output']>;
  brand_ids?: Maybe<Scalars['String']['output']>;
  brand_names?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  egift_code: Scalars['Int']['output'];
  end_date?: Maybe<Scalars['String']['output']>;
  gift_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  incl_resident?: Maybe<Scalars['Boolean']['output']>;
  incl_resident_disp?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
  state_ids?: Maybe<Scalars['String']['output']>;
  state_names?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type VbFavoriteStoreList = {
  __typename?: 'VBFavoriteStoreList';
  btmem_id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  reg_date?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  store_name?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
};

export type VbGiftSettingList = {
  __typename?: 'VBGiftSettingList';
  deadline?: Maybe<Scalars['String']['output']>;
  egift_code: Scalars['Int']['output'];
  egift_name?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
};

export type VbiPayCsvList = {
  __typename?: 'VBIPayCsvList';
  csv_no: Scalars['Int']['output'];
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_count?: Maybe<Scalars['Int']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  unopened?: Maybe<Scalars['Int']['output']>;
};

export type VbiPayCsvListNewlyPayArbeit = {
  __typename?: 'VBIPayCsvListNewlyPayArbeit';
  csvlist: Array<VbiPayCsvList>;
  newly: VuNewlyPayArbeit;
};

export type VbiPaymentList = {
  __typename?: 'VBIPaymentList';
  btmem_id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  newly: Scalars['Boolean']['output'];
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  price: Scalars['String']['output'];
  req_csv_no?: Maybe<Scalars['Int']['output']>;
  req_date?: Maybe<Scalars['String']['output']>;
  req_number?: Maybe<Scalars['String']['output']>;
  request_no?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
};

export type VbiPaymentListNewlyPayArbeit = {
  __typename?: 'VBIPaymentListNewlyPayArbeit';
  newly: VuNewlyPayArbeit;
  payment: Array<VbiPaymentList>;
};

export type VbIdentificationInfo = {
  __typename?: 'VBIdentificationInfo';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  btmem_id: Scalars['Int']['output'];
  btmem_number?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  created_date?: Maybe<Scalars['String']['output']>;
  ecn?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  general_licenses?: Maybe<Scalars['String']['output']>;
  id2_doc1?: Maybe<Scalars['String']['output']>;
  id2_doc2?: Maybe<Scalars['String']['output']>;
  id2_type?: Maybe<Scalars['String']['output']>;
  id_doc1?: Maybe<Scalars['String']['output']>;
  id_doc2?: Maybe<Scalars['String']['output']>;
  id_type?: Maybe<Scalars['String']['output']>;
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  internal_key?: Maybe<Scalars['Int']['output']>;
  is_japanese?: Maybe<Scalars['Boolean']['output']>;
  kana?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  parental?: Maybe<Scalars['String']['output']>;
  parental_name?: Maybe<Scalars['String']['output']>;
  period_date?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  reg_hs_student?: Maybe<Scalars['Boolean']['output']>;
  reg_minor?: Maybe<Scalars['Boolean']['output']>;
  residence_name?: Maybe<Scalars['String']['output']>;
  rule?: Maybe<Scalars['String']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  student?: Maybe<Scalars['String']['output']>;
  submit_date?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VbIdentificationList = {
  __typename?: 'VBIdentificationList';
  btmem_id: Scalars['Int']['output'];
  complete_date?: Maybe<Scalars['String']['output']>;
  entry_date?: Maybe<Scalars['String']['output']>;
  id2_doc1?: Maybe<Scalars['String']['output']>;
  id2_doc2?: Maybe<Scalars['String']['output']>;
  id2_type?: Maybe<Scalars['String']['output']>;
  id_doc1?: Maybe<Scalars['String']['output']>;
  id_doc2?: Maybe<Scalars['String']['output']>;
  id_type?: Maybe<Scalars['String']['output']>;
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  internal_key?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newly: Scalars['Int']['output'];
  submit_date?: Maybe<Scalars['String']['output']>;
};

export type VbIdentificationLogging = {
  __typename?: 'VBIdentificationLogging';
  identification: VbIdentificationList;
  logging: Array<BLogging>;
};

export type VbmPayCsvList = {
  __typename?: 'VBMPayCsvList';
  csv_no: Scalars['Int']['output'];
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_count?: Maybe<Scalars['Int']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  unopened?: Maybe<Scalars['Int']['output']>;
};

export type VbmPayCsvListNewlyPayArbeit = {
  __typename?: 'VBMPayCsvListNewlyPayArbeit';
  csvlist: Array<VbmPayCsvList>;
  newly: VuNewlyPayArbeit;
};

export type VbMemberBase = {
  __typename?: 'VBMemberBase';
  account_name?: Maybe<Scalars['String']['output']>;
  account_no?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  attr_id?: Maybe<Scalars['Int']['output']>;
  attribute?: Maybe<Scalars['String']['output']>;
  badge_name?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  belong_biz_count?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  birthday2?: Maybe<Scalars['String']['output']>;
  branch_name?: Maybe<Scalars['String']['output']>;
  btmem_number?: Maybe<Scalars['String']['output']>;
  cancel_count?: Maybe<Scalars['Int']['output']>;
  cancel_per?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gender_value?: Maybe<Scalars['Int']['output']>;
  general_licenses?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imminent_count?: Maybe<Scalars['Int']['output']>;
  imminent_per?: Maybe<Scalars['Int']['output']>;
  kana?: Maybe<Scalars['String']['output']>;
  late_count?: Maybe<Scalars['Int']['output']>;
  late_per?: Maybe<Scalars['Int']['output']>;
  lc_biz_count?: Maybe<Scalars['Int']['output']>;
  lc_biz_names?: Maybe<Scalars['String']['output']>;
  license_list?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  pause_end_day?: Maybe<Scalars['String']['output']>;
  period_date?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rank_biz_count?: Maybe<Scalars['Int']['output']>;
  rank_biz_names?: Maybe<Scalars['String']['output']>;
  rank_names?: Maybe<Scalars['String']['output']>;
  reg_biz_list?: Maybe<Scalars['String']['output']>;
  residence_name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  skip_count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  state_count?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
  sub_names?: Maybe<Scalars['String']['output']>;
  tp_biz_names?: Maybe<Scalars['String']['output']>;
  tpmem_names?: Maybe<Scalars['String']['output']>;
  uniform_id?: Maybe<Scalars['Int']['output']>;
  uniform_size?: Maybe<Scalars['String']['output']>;
  worked_count?: Maybe<Scalars['Int']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VbMemberList = {
  __typename?: 'VBMemberList';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  all_biz_count?: Maybe<Scalars['Int']['output']>;
  all_biz_ids?: Maybe<Scalars['String']['output']>;
  all_biz_names?: Maybe<Scalars['String']['output']>;
  attribute?: Maybe<Scalars['String']['output']>;
  belong_biz_count?: Maybe<Scalars['Int']['output']>;
  birthday: Scalars['String']['output'];
  btmem_number?: Maybe<Scalars['String']['output']>;
  cancel_per: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality_id?: Maybe<Scalars['Int']['output']>;
  newly: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  rank_biz_count?: Maybe<Scalars['Int']['output']>;
  rank_biz_ids?: Maybe<Scalars['String']['output']>;
  rank_biz_names?: Maybe<Scalars['String']['output']>;
  rank_names?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  score_count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  sub_names?: Maybe<Scalars['String']['output']>;
  tp_biz_names?: Maybe<Scalars['String']['output']>;
  tpmem_dates?: Maybe<Scalars['String']['output']>;
  tpmem_names?: Maybe<Scalars['String']['output']>;
  uniform_size?: Maybe<Scalars['String']['output']>;
  worked_count?: Maybe<Scalars['Int']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VbMemberListFavorite = {
  __typename?: 'VBMemberListFavorite';
  favorite: Array<VbFavoriteStoreList>;
  member: Array<VbMemberList>;
};

export type VbMemberListLogging = {
  __typename?: 'VBMemberListLogging';
  logging: Array<BLogging>;
  member: Array<VbMemberList>;
};

export type VbMemberListWithhold = {
  __typename?: 'VBMemberListWithhold';
  member: Array<VbMemberList>;
  withhold: Array<VbWithholdSlipList>;
};

export type VbOfferAcceptInfo = {
  __typename?: 'VBOfferAcceptInfo';
  accept_id: Scalars['Int']['output'];
  access?: Maybe<Scalars['String']['output']>;
  attached_file?: Maybe<Scalars['String']['output']>;
  attached_name?: Maybe<Scalars['String']['output']>;
  auto_decide?: Maybe<Scalars['Int']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  belongings?: Maybe<Scalars['String']['output']>;
  break_time?: Maybe<Scalars['Int']['output']>;
  caution?: Maybe<Scalars['String']['output']>;
  chief?: Maybe<Scalars['String']['output']>;
  conditions?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hour_wage?: Maybe<Scalars['Int']['output']>;
  image1?: Maybe<Scalars['String']['output']>;
  image2?: Maybe<Scalars['String']['output']>;
  image3?: Maybe<Scalars['String']['output']>;
  image4?: Maybe<Scalars['String']['output']>;
  image5?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offer_id: Scalars['Int']['output'];
  offer_number?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
  trans_fee?: Maybe<Scalars['String']['output']>;
};

export type VbOfferAcceptList = {
  __typename?: 'VBOfferAcceptList';
  accept_id: Scalars['Int']['output'];
  b_offer_id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offer_id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  tpmem_id: Scalars['Int']['output'];
};

export type VbOfferInfo = {
  __typename?: 'VBOfferInfo';
  age?: Maybe<Scalars['Int']['output']>;
  attribute?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  belong?: Maybe<Scalars['String']['output']>;
  biz_name?: Maybe<Scalars['String']['output']>;
  brand_name?: Maybe<Scalars['String']['output']>;
  btmem_id: Scalars['Int']['output'];
  btmem_name?: Maybe<Scalars['String']['output']>;
  btmem_state?: Maybe<Scalars['String']['output']>;
  cancel_count?: Maybe<Scalars['Int']['output']>;
  cancel_per?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hour_wage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imminent_count?: Maybe<Scalars['Int']['output']>;
  imminent_per?: Maybe<Scalars['Float']['output']>;
  late_count?: Maybe<Scalars['Int']['output']>;
  late_per?: Maybe<Scalars['Float']['output']>;
  offer_no?: Maybe<Scalars['String']['output']>;
  rank_biz_count?: Maybe<Scalars['Int']['output']>;
  rank_biz_names?: Maybe<Scalars['String']['output']>;
  rank_names?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  state_city?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tp_num?: Maybe<Scalars['Int']['output']>;
  work_time?: Maybe<Scalars['String']['output']>;
};

export type VbOfferList = {
  __typename?: 'VBOfferList';
  begin_end?: Maybe<Scalars['String']['output']>;
  biz_id?: Maybe<Scalars['Int']['output']>;
  biz_name?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  brand_name?: Maybe<Scalars['String']['output']>;
  btmem_id: Scalars['Int']['output'];
  btmem_name?: Maybe<Scalars['String']['output']>;
  btmem_state?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  offer_id: Scalars['Int']['output'];
  offer_no?: Maybe<Scalars['String']['output']>;
  state_city?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tp_num?: Maybe<Scalars['Int']['output']>;
  work_time?: Maybe<Scalars['String']['output']>;
};

export type VbOfferListAccept = {
  __typename?: 'VBOfferListAccept';
  accept: Array<VbOfferAcceptList>;
  offer: VbOfferList;
};

export type VbOfferListLogging = {
  __typename?: 'VBOfferListLogging';
  logging: Array<BLogging>;
  offer: VbOfferList;
};

export type VbPayCsvAllList = {
  __typename?: 'VBPayCsvAllList';
  csv_no: Scalars['Int']['output'];
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_count?: Maybe<Scalars['Int']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  unopened?: Maybe<Scalars['Boolean']['output']>;
};

export type VbPayCsvAllListNewly = {
  __typename?: 'VBPayCsvAllListNewly';
  csv: Array<VbPayCsvAllList>;
  newly: VuNewlyAccounting;
};

export type VbReviewList = {
  __typename?: 'VBReviewList';
  btmem_id: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_date?: Maybe<Scalars['String']['output']>;
  post_date_2?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  tpmem_id?: Maybe<Scalars['Int']['output']>;
  tpmem_status?: Maybe<Scalars['Int']['output']>;
  work_id: Scalars['Int']['output'];
};

export type VbReviewListScore = {
  __typename?: 'VBReviewListScore';
  info: VbScorePercentInfo;
  list: Array<VbReviewList>;
};

export type VbScorePercentInfo = {
  __typename?: 'VBScorePercentInfo';
  btmem_id: Scalars['Int']['output'];
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  score1_per?: Maybe<Scalars['Float']['output']>;
  score2_per?: Maybe<Scalars['Float']['output']>;
  score3_per?: Maybe<Scalars['Float']['output']>;
  score4_per?: Maybe<Scalars['Float']['output']>;
  score5_per?: Maybe<Scalars['Float']['output']>;
  score_count?: Maybe<Scalars['Int']['output']>;
  status: Scalars['Int']['output'];
};

export type VbWithholdSlipList = {
  __typename?: 'VBWithholdSlipList';
  btmem_id: Scalars['Int']['output'];
  corp_name?: Maybe<Scalars['String']['output']>;
  corporate_no?: Maybe<Scalars['String']['output']>;
  last_date?: Maybe<Scalars['String']['output']>;
  last_day?: Maybe<Scalars['String']['output']>;
  year_val?: Maybe<Scalars['Int']['output']>;
};

export type VbWithholdSlipListData = {
  __typename?: 'VBWithholdSlipListData';
  list?: Maybe<Array<Maybe<VbWithholdSlipList>>>;
};

export type VbWithholdingSlipData = {
  __typename?: 'VBWithholdingSlipData';
  btmem_address?: Maybe<Scalars['String']['output']>;
  btmem_id?: Maybe<Scalars['Int']['output']>;
  btmem_kana?: Maybe<Scalars['String']['output']>;
  btmem_name?: Maybe<Scalars['String']['output']>;
  corp_address?: Maybe<Scalars['String']['output']>;
  corp_name?: Maybe<Scalars['String']['output']>;
  corp_phone?: Maybe<Scalars['String']['output']>;
  corporate_no?: Maybe<Scalars['String']['output']>;
  gregorian_cal?: Maybe<Scalars['Int']['output']>;
  japanese_cal?: Maybe<Scalars['Int']['output']>;
  salary?: Maybe<Scalars['Float']['output']>;
  tax_amount?: Maybe<Scalars['Float']['output']>;
};

export type VbWorkScoreList = {
  __typename?: 'VBWorkScoreList';
  belong: Scalars['Int']['output'];
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  brand_id: Scalars['Int']['output'];
  brand_name?: Maybe<Scalars['String']['output']>;
  bt_score?: Maybe<Scalars['Int']['output']>;
  btmem_name?: Maybe<Scalars['String']['output']>;
  hour_wage?: Maybe<Scalars['String']['output']>;
  offer_no?: Maybe<Scalars['String']['output']>;
  rank_id?: Maybe<Scalars['Int']['output']>;
  rank_name?: Maybe<Scalars['String']['output']>;
  tp_post_day?: Maybe<Scalars['String']['output']>;
  tpmem_name?: Maybe<Scalars['String']['output']>;
  trans_fee?: Maybe<Scalars['String']['output']>;
  work_day?: Maybe<Scalars['String']['output']>;
  work_id: Scalars['Int']['output'];
};

export type VmIdCardType = {
  __typename?: 'VMIdCardType';
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image_num?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  s_icon?: Maybe<Scalars['String']['output']>;
  s_id?: Maybe<Scalars['Int']['output']>;
  s_image_num?: Maybe<Scalars['Int']['output']>;
  s_name?: Maybe<Scalars['String']['output']>;
};

export type VmLicense2 = {
  __typename?: 'VMLicense2';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VsLendStaffList = {
  __typename?: 'VSLendStaffList';
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  bt_rank_id: Scalars['Int']['output'];
  educ_default: Scalars['Int']['output'];
  educ_fee_max: Scalars['Int']['output'];
  educ_fee_min: Scalars['Int']['output'];
  educ_range?: Maybe<Scalars['String']['output']>;
  rank_name?: Maybe<Scalars['String']['output']>;
};

export type VSelBlockList = {
  __typename?: 'VSelBlockList';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  biz_id: Scalars['Int']['output'];
  blocked?: Maybe<Scalars['String']['output']>;
  brand_id: Scalars['Int']['output'];
  city?: Maybe<Scalars['String']['output']>;
  my_id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  tpmem_id: Scalars['Int']['output'];
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtArbeitEntryList = {
  __typename?: 'VTArbeitEntryList';
  btmem_id: Scalars['Int']['output'];
  btmem_number?: Maybe<Scalars['String']['output']>;
  deadline_date?: Maybe<Scalars['String']['output']>;
  entry_id: Scalars['Int']['output'];
  entry_no?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  kind_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offer_id: Scalars['Int']['output'];
  rank_name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Int']['output'];
  total_hire_fee?: Maybe<Scalars['String']['output']>;
};

export type VtArbeitEntryListWorking = {
  __typename?: 'VTArbeitEntryListWorking';
  entry: VtArbeitEntryList;
  working: VtWorkingList;
};

export type VtArbeitOfferEntry = {
  __typename?: 'VTArbeitOfferEntry';
  entry: Array<VtArbeitEntryList>;
  offer: VtArbeitOfferList;
};

export type VtArbeitOfferInfo = {
  __typename?: 'VTArbeitOfferInfo';
  access?: Maybe<Scalars['String']['output']>;
  attached_file?: Maybe<Scalars['String']['output']>;
  attached_name?: Maybe<Scalars['String']['output']>;
  auto_decide: Scalars['Int']['output'];
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  belongings?: Maybe<Scalars['String']['output']>;
  break_time?: Maybe<Scalars['Int']['output']>;
  bt_rank_id?: Maybe<Scalars['Int']['output']>;
  cancel_disp?: Maybe<Scalars['String']['output']>;
  cancel_per?: Maybe<Scalars['Float']['output']>;
  caution?: Maybe<Scalars['String']['output']>;
  chief?: Maybe<Scalars['String']['output']>;
  conditions?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  hire_fee_disp?: Maybe<Scalars['String']['output']>;
  hire_fee_total?: Maybe<Scalars['Int']['output']>;
  hour_wage?: Maybe<Scalars['Int']['output']>;
  hour_wage_disp?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image1?: Maybe<Scalars['String']['output']>;
  image2?: Maybe<Scalars['String']['output']>;
  image3?: Maybe<Scalars['String']['output']>;
  image4?: Maybe<Scalars['String']['output']>;
  image5?: Maybe<Scalars['String']['output']>;
  imminent_disp?: Maybe<Scalars['String']['output']>;
  imminent_per?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offer_number?: Maybe<Scalars['String']['output']>;
  post_begin?: Maybe<Scalars['String']['output']>;
  post_begin_date?: Maybe<Scalars['String']['output']>;
  post_end?: Maybe<Scalars['String']['output']>;
  post_end_date?: Maybe<Scalars['String']['output']>;
  rank_name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  score_disp?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
  trans_fee?: Maybe<Scalars['String']['output']>;
  trans_fee_val?: Maybe<Scalars['Int']['output']>;
};

export type VtArbeitOfferInfoList = {
  __typename?: 'VTArbeitOfferInfoList';
  info: VtArbeitOfferInfo;
  list: Array<VtArbeitOfferList>;
};

export type VtArbeitOfferInfoWorking = {
  __typename?: 'VTArbeitOfferInfoWorking';
  info: VtArbeitOfferInfo;
  working: VtWorkingList;
};

export type VtArbeitOfferList = {
  __typename?: 'VTArbeitOfferList';
  auto_decide: Scalars['Boolean']['output'];
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_day?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  entry_count?: Maybe<Scalars['Int']['output']>;
  group_count?: Maybe<Scalars['Int']['output']>;
  hour_wage?: Maybe<Scalars['Int']['output']>;
  hour_wage_disp?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  main_count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newly?: Maybe<Scalars['Int']['output']>;
  normal_count?: Maybe<Scalars['Int']['output']>;
  offer_number?: Maybe<Scalars['String']['output']>;
  post_begin?: Maybe<Scalars['String']['output']>;
  post_end?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  sub_count?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
  trans_fee?: Maybe<Scalars['String']['output']>;
};

export type VtArbeitOfferLogging = {
  __typename?: 'VTArbeitOfferLogging';
  logging?: Maybe<Array<Maybe<TLogging>>>;
  offer?: Maybe<VtArbeitOfferList>;
};

export type VtBalanceList = {
  __typename?: 'VTBalanceList';
  area?: Maybe<Scalars['String']['output']>;
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  brand_id: Scalars['Int']['output'];
  brand_name?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  mco_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  price_disp?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tpmem_id: Scalars['Int']['output'];
};

export type VtBalanceListWalletNnewly = {
  __typename?: 'VTBalanceListWalletNnewly';
  balance: Array<VtBalanceList>;
  newly: VuNewlyAccounting;
  wallet: TWalletSum;
};

export type VtBelongList = {
  __typename?: 'VTBelongList';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  attribute?: Maybe<Scalars['String']['output']>;
  begin_date?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  biz_id: Scalars['Int']['output'];
  btmem_number?: Maybe<Scalars['String']['output']>;
  cancel_per?: Maybe<Scalars['Float']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  identification: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rank_name?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  sub_name?: Maybe<Scalars['String']['output']>;
  tpmem_id?: Maybe<Scalars['Int']['output']>;
  tpmem_name?: Maybe<Scalars['String']['output']>;
  uniform_size?: Maybe<Scalars['String']['output']>;
  worked_count?: Maybe<Scalars['Int']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtBelongListMember = {
  __typename?: 'VTBelongListMember';
  belong: VtBelongList;
  member: VbMemberBase;
};

export type VtBillCsvList = {
  __typename?: 'VTBillCsvList';
  csv_no: Scalars['Int']['output'];
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_count?: Maybe<Scalars['Int']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  kind: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  unopened?: Maybe<Scalars['Int']['output']>;
};

export type VtBillCsvListNewlyBillStore = {
  __typename?: 'VTBillCsvListNewlyBillStore';
  csvlist: Array<VtBillCsvList>;
  newly: VuNewlyBillStore;
};

export type VtBlockList = {
  __typename?: 'VTBlockList';
  block_id: Scalars['Int']['output'];
  block_name?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  reg_date?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tpmem_id: Scalars['Int']['output'];
};

export type VtBrandStateInfoCount = {
  __typename?: 'VTBrandStateInfoCount';
  id_count: Scalars['Int']['output'];
};

export type VtCpFreeList = {
  __typename?: 'VTCpFreeList';
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  biz_count?: Maybe<Scalars['Int']['output']>;
  biz_ids?: Maybe<Scalars['String']['output']>;
  biz_names?: Maybe<Scalars['String']['output']>;
  brand_ids?: Maybe<Scalars['String']['output']>;
  brand_names?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  free_months?: Maybe<Scalars['Int']['output']>;
  free_months_disp?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  state_ids?: Maybe<Scalars['String']['output']>;
  state_names?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type VtCpTicketList = {
  __typename?: 'VTCpTicketList';
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  biz_count?: Maybe<Scalars['Int']['output']>;
  biz_ids?: Maybe<Scalars['String']['output']>;
  biz_names?: Maybe<Scalars['String']['output']>;
  brand_ids?: Maybe<Scalars['String']['output']>;
  brand_names?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  give_num?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  kind: Scalars['Int']['output'];
  state_ids?: Maybe<Scalars['String']['output']>;
  state_names?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  ticket_num?: Maybe<Scalars['Int']['output']>;
  ticket_num_disp?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  valid_days?: Maybe<Scalars['Int']['output']>;
  valid_days_disp?: Maybe<Scalars['String']['output']>;
};

export type VtGroupList = {
  __typename?: 'VTGroupList';
  accept_date?: Maybe<Scalars['String']['output']>;
  group_id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  my_id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tpmem_id: Scalars['Int']['output'];
};

export type VtInvitedGroupInfo = {
  __typename?: 'VTInvitedGroupInfo';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  corporate_no?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  invite_id?: Maybe<Scalars['Int']['output']>;
  mco_address?: Maybe<Scalars['String']['output']>;
  mco_address2?: Maybe<Scalars['String']['output']>;
  mco_city?: Maybe<Scalars['String']['output']>;
  mco_m_kana?: Maybe<Scalars['String']['output']>;
  mco_m_name?: Maybe<Scalars['String']['output']>;
  mco_name?: Maybe<Scalars['String']['output']>;
  mco_phone?: Maybe<Scalars['String']['output']>;
  mco_state?: Maybe<Scalars['String']['output']>;
  mco_t_kana?: Maybe<Scalars['String']['output']>;
  mco_t_name?: Maybe<Scalars['String']['output']>;
  mco_t_phone?: Maybe<Scalars['String']['output']>;
  mco_zip_code?: Maybe<Scalars['String']['output']>;
  mgr_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tpmem_id?: Maybe<Scalars['Int']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtmPayCsvList = {
  __typename?: 'VTMPayCsvList';
  csv_no: Scalars['Int']['output'];
  csv_url?: Maybe<Scalars['String']['output']>;
  dl_count?: Maybe<Scalars['Int']['output']>;
  dl_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  pay_date?: Maybe<Scalars['String']['output']>;
  unopened?: Maybe<Scalars['Int']['output']>;
};

export type VtmPayCsvListNewlyPayRefund = {
  __typename?: 'VTMPayCsvListNewlyPayRefund';
  csvlist: Array<VtmPayCsvList>;
  newly: VuNewlyPayRefund;
};

export type VtmPaymentList = {
  __typename?: 'VTMPaymentList';
  bill_date?: Maybe<Scalars['String']['output']>;
  csv_no?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  newly: Scalars['Boolean']['output'];
  output_date?: Maybe<Scalars['String']['output']>;
  output_number?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
};

export type VtmPaymentListNewlyPayRefund = {
  __typename?: 'VTMPaymentListNewlyPayRefund';
  newly: VuNewlyPayRefund;
  payment: Array<VtmPaymentList>;
};

export type VtMemberBase = {
  __typename?: 'VTMemberBase';
  account_name?: Maybe<Scalars['String']['output']>;
  account_no?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  again?: Maybe<Scalars['String']['output']>;
  bank?: Maybe<Scalars['String']['output']>;
  belong_total?: Maybe<Scalars['Int']['output']>;
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  block_num?: Maybe<Scalars['Int']['output']>;
  branch_name?: Maybe<Scalars['String']['output']>;
  brand_id: Scalars['Int']['output'];
  brand_name?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  close_time?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  corporate_no?: Maybe<Scalars['String']['output']>;
  corporate_type?: Maybe<Scalars['Int']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  favorite_num?: Maybe<Scalars['Int']['output']>;
  group_num: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  join_date?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  mco_address?: Maybe<Scalars['String']['output']>;
  mco_address2?: Maybe<Scalars['String']['output']>;
  mco_city?: Maybe<Scalars['String']['output']>;
  mco_m_first_kana?: Maybe<Scalars['String']['output']>;
  mco_m_first_name?: Maybe<Scalars['String']['output']>;
  mco_m_kana?: Maybe<Scalars['String']['output']>;
  mco_m_last_kana?: Maybe<Scalars['String']['output']>;
  mco_m_last_name?: Maybe<Scalars['String']['output']>;
  mco_m_name?: Maybe<Scalars['String']['output']>;
  mco_name?: Maybe<Scalars['String']['output']>;
  mco_phone?: Maybe<Scalars['String']['output']>;
  mco_state?: Maybe<Scalars['String']['output']>;
  mco_t_first_kana?: Maybe<Scalars['String']['output']>;
  mco_t_first_name?: Maybe<Scalars['String']['output']>;
  mco_t_kana?: Maybe<Scalars['String']['output']>;
  mco_t_last_kana?: Maybe<Scalars['String']['output']>;
  mco_t_last_name?: Maybe<Scalars['String']['output']>;
  mco_t_name?: Maybe<Scalars['String']['output']>;
  mco_t_phone?: Maybe<Scalars['String']['output']>;
  mco_zip_code?: Maybe<Scalars['String']['output']>;
  mgr_birthday?: Maybe<Scalars['String']['output']>;
  mgr_dob_day?: Maybe<Scalars['Int']['output']>;
  mgr_dob_month?: Maybe<Scalars['Int']['output']>;
  mgr_dob_year?: Maybe<Scalars['Int']['output']>;
  mgr_first_kana?: Maybe<Scalars['String']['output']>;
  mgr_first_name?: Maybe<Scalars['String']['output']>;
  mgr_id?: Maybe<Scalars['Int']['output']>;
  mgr_last_kana?: Maybe<Scalars['String']['output']>;
  mgr_last_name?: Maybe<Scalars['String']['output']>;
  mgr_phone?: Maybe<Scalars['String']['output']>;
  open_time?: Maybe<Scalars['String']['output']>;
  opening?: Maybe<Scalars['String']['output']>;
  parking?: Maybe<Scalars['String']['output']>;
  parking_val?: Maybe<Scalars['Boolean']['output']>;
  pause_date?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['Int']['output']>;
  personal_bank?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quit_date?: Maybe<Scalars['String']['output']>;
  restart_date?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  store_name?: Maybe<Scalars['String']['output']>;
  ticket_num?: Maybe<Scalars['Int']['output']>;
  total_adopt?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtMemberBelong = {
  __typename?: 'VTMemberBelong';
  belong: Array<VtBelongList>;
  member: VtMemberList;
  sub: Array<VtBelongList>;
};

export type VtMemberBlock = {
  __typename?: 'VTMemberBlock';
  block: Array<VtBlockList>;
  member: VtMemberList;
};

export type VtMemberGroup = {
  __typename?: 'VTMemberGroup';
  group: Array<VtGroupList>;
  member: VtMemberList;
};

export type VtMemberList = {
  __typename?: 'VTMemberList';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  again?: Maybe<Scalars['String']['output']>;
  again_num?: Maybe<Scalars['Int']['output']>;
  area_name?: Maybe<Scalars['String']['output']>;
  belong_count?: Maybe<Scalars['String']['output']>;
  belong_num?: Maybe<Scalars['Int']['output']>;
  belong_total?: Maybe<Scalars['Int']['output']>;
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  block_count?: Maybe<Scalars['String']['output']>;
  block_num?: Maybe<Scalars['Int']['output']>;
  brand_id: Scalars['Int']['output'];
  brand_name?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  group_num?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  mco_name?: Maybe<Scalars['String']['output']>;
  mgr_id?: Maybe<Scalars['Int']['output']>;
  newly?: Maybe<Scalars['Int']['output']>;
  opening?: Maybe<Scalars['String']['output']>;
  other_adopt?: Maybe<Scalars['String']['output']>;
  parking?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  score_num?: Maybe<Scalars['Int']['output']>;
  score_value?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  store_name?: Maybe<Scalars['String']['output']>;
  sub_count?: Maybe<Scalars['String']['output']>;
  sub_num?: Maybe<Scalars['Int']['output']>;
  ticket_num?: Maybe<Scalars['Int']['output']>;
  total_adopt?: Maybe<Scalars['String']['output']>;
  unclaimed?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtMemberListCount = {
  __typename?: 'VTMemberListCount';
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<VtMemberList>>>;
};

export type VtMemberLogging = {
  __typename?: 'VTMemberLogging';
  logging: Array<TLogging>;
  member: VtMemberList;
};

export type VtMemberTicket = {
  __typename?: 'VTMemberTicket';
  member: VtMemberList;
  ticket: Array<VtTicketList>;
};

export type VtReviewList = {
  __typename?: 'VTReviewList';
  anonymous?: Maybe<Scalars['String']['output']>;
  btmem_id?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  offer_id?: Maybe<Scalars['Int']['output']>;
  post_date?: Maybe<Scalars['String']['output']>;
  post_date_2?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  tpmem_id: Scalars['Int']['output'];
  work_id: Scalars['Int']['output'];
};

export type VtScoreList = {
  __typename?: 'VTScoreList';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  biz_id: Scalars['Int']['output'];
  biz_name?: Maybe<Scalars['String']['output']>;
  brand_id: Scalars['Int']['output'];
  brand_name?: Maybe<Scalars['String']['output']>;
  bt_post_day?: Maybe<Scalars['String']['output']>;
  btmem_name?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  hour_wage?: Maybe<Scalars['String']['output']>;
  offer_no?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  tp_score?: Maybe<Scalars['Int']['output']>;
  tpmem_name?: Maybe<Scalars['String']['output']>;
  trans_fee?: Maybe<Scalars['String']['output']>;
  work_day?: Maybe<Scalars['String']['output']>;
  work_id: Scalars['Int']['output'];
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtScorePercentInfo = {
  __typename?: 'VTScorePercentInfo';
  again_num?: Maybe<Scalars['Int']['output']>;
  belong_total?: Maybe<Scalars['Int']['output']>;
  block_num?: Maybe<Scalars['Int']['output']>;
  group_num?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  score1_per?: Maybe<Scalars['Float']['output']>;
  score2_per?: Maybe<Scalars['Float']['output']>;
  score3_per?: Maybe<Scalars['Float']['output']>;
  score4_per?: Maybe<Scalars['Float']['output']>;
  score5_per?: Maybe<Scalars['Float']['output']>;
  score_num?: Maybe<Scalars['Int']['output']>;
  score_value?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  store_name?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
};

export type VtScorePercentInfoList = {
  __typename?: 'VTScorePercentInfoList';
  info: VtScorePercentInfo;
  list: Array<VtReviewList>;
};

export type VtSelectStoreList = {
  __typename?: 'VTSelectStoreList';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type VtTicketList = {
  __typename?: 'VTTicketList';
  award_date?: Maybe<Scalars['String']['output']>;
  enabled_count?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  item?: Maybe<Scalars['String']['output']>;
  timeup?: Maybe<Scalars['Int']['output']>;
  tpmem_id: Scalars['Int']['output'];
  umgmt_name?: Maybe<Scalars['String']['output']>;
  valid_days?: Maybe<Scalars['Int']['output']>;
};

export type VtTicketMgtInfo = {
  __typename?: 'VTTicketMgtInfo';
  expired_count: Scalars['String']['output'];
  expired_price: Scalars['String']['output'];
  issue_count: Scalars['String']['output'];
  issue_price: Scalars['String']['output'];
};

export type VtTransProcList = {
  __typename?: 'VTTransProcList';
  account_name?: Maybe<Scalars['String']['output']>;
  account_no?: Maybe<Scalars['String']['output']>;
  account_type?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  branch_name?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  tpmem_id: Scalars['Int']['output'];
  tpmem_name?: Maybe<Scalars['String']['output']>;
  tr_proc_date?: Maybe<Scalars['String']['output']>;
  tr_proc_type?: Maybe<Scalars['String']['output']>;
};

export type VtTransProcListNewly = {
  __typename?: 'VTTransProcListNewly';
  newly: VuNewlyAccounting;
  trans: Array<VtTransProcList>;
};

export type VtWorkScoreInfo = {
  __typename?: 'VTWorkScoreInfo';
  again?: Maybe<Scalars['Boolean']['output']>;
  bt_comment?: Maybe<Scalars['String']['output']>;
  bt_post_date?: Maybe<Scalars['String']['output']>;
  bt_score?: Maybe<Scalars['Int']['output']>;
  btmem_id?: Maybe<Scalars['Int']['output']>;
  btmem_name?: Maybe<Scalars['String']['output']>;
  tp_comment?: Maybe<Scalars['String']['output']>;
  tp_post_date?: Maybe<Scalars['String']['output']>;
  tp_score?: Maybe<Scalars['Int']['output']>;
  tpmem_id?: Maybe<Scalars['Int']['output']>;
  tpmem_name?: Maybe<Scalars['String']['output']>;
  work_id?: Maybe<Scalars['Int']['output']>;
};

export type VtWorkingList = {
  __typename?: 'VTWorkingList';
  begin_date?: Maybe<Scalars['String']['output']>;
  begin_day?: Maybe<Scalars['String']['output']>;
  begin_end?: Maybe<Scalars['String']['output']>;
  biz_id: Scalars['Int']['output'];
  brand_id: Scalars['Int']['output'];
  btmem_id: Scalars['Int']['output'];
  btmem_name?: Maybe<Scalars['String']['output']>;
  btmem_number?: Maybe<Scalars['String']['output']>;
  end_date: Scalars['String']['output'];
  entry_id: Scalars['Int']['output'];
  entry_number?: Maybe<Scalars['String']['output']>;
  fix_checkin?: Maybe<Scalars['String']['output']>;
  fix_checkout?: Maybe<Scalars['String']['output']>;
  fix_count: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  newly?: Maybe<Scalars['Int']['output']>;
  newly_review?: Maybe<Scalars['Int']['output']>;
  offer_id: Scalars['Int']['output'];
  offer_number?: Maybe<Scalars['String']['output']>;
  qr_checkin?: Maybe<Scalars['String']['output']>;
  qr_checkout?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  time_left?: Maybe<Scalars['String']['output']>;
  time_left_minute?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tpmem_id: Scalars['Int']['output'];
  tpmem_name?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
  type_name?: Maybe<Scalars['String']['output']>;
  work_id?: Maybe<Scalars['Int']['output']>;
};

export type VtWorkingLogging = {
  __typename?: 'VTWorkingLogging';
  logging: Array<TLogging>;
  working: VtWorkingList;
};

export type VuMemberList = {
  __typename?: 'VUMemberList';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  privilege: Scalars['Int']['output'];
  privilege_name?: Maybe<Scalars['String']['output']>;
};

export type VuNewlyAccounting = {
  __typename?: 'VUNewlyAccounting';
  mn_all: Scalars['Int']['output'];
  trans_status: Scalars['Int']['output'];
};

export type VuNewlyBillStore = {
  __typename?: 'VUNewlyBillStore';
  zp_pay: Scalars['Int']['output'];
  zw_pay: Scalars['Int']['output'];
};

export type VuNewlyHome = {
  __typename?: 'VUNewlyHome';
  b_badge: Scalars['Int']['output'];
  b_identfy: Scalars['Int']['output'];
  b_join: Scalars['Int']['output'];
  b_pay: Scalars['Int']['output'];
  b_quit: Scalars['Int']['output'];
  t_change: Scalars['Int']['output'];
  t_pay: Scalars['Int']['output'];
  t_quit: Scalars['Int']['output'];
  t_regist: Scalars['Int']['output'];
};

export type VuNewlyMenu = {
  __typename?: 'VUNewlyMenu';
  baitra?: Maybe<Scalars['Int']['output']>;
  bill_tp?: Maybe<Scalars['Int']['output']>;
  billing?: Maybe<Scalars['Int']['output']>;
  bt_identity?: Maybe<Scalars['Int']['output']>;
  bt_info?: Maybe<Scalars['Int']['output']>;
  home?: Maybe<Scalars['Int']['output']>;
  pay_back?: Maybe<Scalars['Int']['output']>;
  pay_work?: Maybe<Scalars['Int']['output']>;
  payment?: Maybe<Scalars['Int']['output']>;
  set_account?: Maybe<Scalars['Int']['output']>;
  settings?: Maybe<Scalars['Int']['output']>;
  tenpo?: Maybe<Scalars['Int']['output']>;
  tp_info?: Maybe<Scalars['Int']['output']>;
};

export type VuNewlyMenuAll = {
  __typename?: 'VUNewlyMenuAll';
  accounting?: Maybe<VuNewlyAccounting>;
  arbeit?: Maybe<VuNewlyPayArbeit>;
  home?: Maybe<VuNewlyHome>;
  menu?: Maybe<VuNewlyMenu>;
  refund?: Maybe<VuNewlyPayRefund>;
  store?: Maybe<VuNewlyBillStore>;
};

export type VuNewlyPayArbeit = {
  __typename?: 'VUNewlyPayArbeit';
  im_inv: Scalars['Int']['output'];
  im_pay: Scalars['Int']['output'];
  mn_pay: Scalars['Int']['output'];
};

export type VuNewlyPayRefund = {
  __typename?: 'VUNewlyPayRefund';
  re_pay: Scalars['Int']['output'];
};

export type VuSendNotifyInfo = {
  __typename?: 'VUSendNotifyInfo';
  biz_id?: Maybe<Scalars['Int']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  brand_id?: Maybe<Scalars['Int']['output']>;
  brand_name?: Maybe<Scalars['String']['output']>;
  do_day?: Maybe<Scalars['String']['output']>;
  do_time?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  sys_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  to_kind_name?: Maybe<Scalars['String']['output']>;
};

export type VuSendNotifyList = {
  __typename?: 'VUSendNotifyList';
  brand_name?: Maybe<Scalars['String']['output']>;
  created_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  send_date?: Maybe<Scalars['String']['output']>;
  send_day?: Maybe<Scalars['String']['output']>;
  status: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  to_kind_name?: Maybe<Scalars['String']['output']>;
};

export type VWorkScoreInfo = {
  __typename?: 'VWorkScoreInfo';
  again?: Maybe<Scalars['Boolean']['output']>;
  bt_comment?: Maybe<Scalars['String']['output']>;
  bt_post_day?: Maybe<Scalars['String']['output']>;
  bt_score?: Maybe<Scalars['Int']['output']>;
  btmem_id?: Maybe<Scalars['Int']['output']>;
  btmem_image?: Maybe<Scalars['String']['output']>;
  btmem_name?: Maybe<Scalars['String']['output']>;
  offer_id: Scalars['Int']['output'];
  offer_no: Scalars['String']['output'];
  tp_comment?: Maybe<Scalars['String']['output']>;
  tp_post_day?: Maybe<Scalars['String']['output']>;
  tp_score?: Maybe<Scalars['Int']['output']>;
  tpmem_id?: Maybe<Scalars['Int']['output']>;
  tpmem_image?: Maybe<Scalars['String']['output']>;
  tpmem_name?: Maybe<Scalars['String']['output']>;
  work_id: Scalars['Int']['output'];
};

export type BoolResult = {
  __typename?: 'boolResult';
  bResult: Scalars['Boolean']['output'];
};

export type InMBrand = {
  brand_name: Scalars['String']['input'];
  brand_prefix: Scalars['String']['input'];
};

export type InMLicense = {
  license_name: Scalars['String']['input'];
};

export type AddBPenaltyPointMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type AddBPenaltyPointMutation = {
  __typename?: 'Mutation';
  addBPenaltyPoint: { __typename: 'AddResult'; id: number };
};

export type AddSCampaignMutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  give_num: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
}>;

export type AddSCampaignMutation = {
  __typename?: 'Mutation';
  addSCampaign?: { __typename: 'AddResult'; id: number } | null;
};

export type AddSCampaignKind1MutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  free_months: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type AddSCampaignKind1Mutation = {
  __typename?: 'Mutation';
  addSCampaignKind1: { __typename: 'AddResult'; id: number };
};

export type AddSCampaignKind11MutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  end_date: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type AddSCampaignKind11Mutation = {
  __typename?: 'Mutation';
  addSCampaignKind11: { __typename: 'AddResult'; id: number };
};

export type AddSCampaignKind12MutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  end_date: Scalars['String']['input'];
  incl_resident: Scalars['Boolean']['input'];
  state_ids: Scalars['String']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type AddSCampaignKind12Mutation = {
  __typename?: 'Mutation';
  addSCampaignKind12: { __typename: 'AddResult'; id: number };
};

export type AddSCampaignKind2MutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
}>;

export type AddSCampaignKind2Mutation = {
  __typename?: 'Mutation';
  addSCampaignKind2: { __typename: 'AddResult'; id: number };
};

export type AddSCampaignKind3MutationVariables = Exact<{
  begin_date: Scalars['String']['input'];
  brand_ids: Scalars['String']['input'];
  end_date: Scalars['String']['input'];
  give_num: Scalars['Int']['input'];
  state_ids: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
}>;

export type AddSCampaignKind3Mutation = {
  __typename?: 'Mutation';
  addSCampaignKind3: { __typename: 'AddResult'; id: number };
};

export type AddSEgiftCodeMutationVariables = Exact<{
  config_code: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  egift_type: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type AddSEgiftCodeMutation = { __typename?: 'Mutation'; addSEgiftCode: { __typename: 'AddResult'; id: number } };

export type AddSWithholdDayMutationVariables = Exact<{
  price: Scalars['Int']['input'];
  tax: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}>;

export type AddSWithholdDayMutation = {
  __typename?: 'Mutation';
  addSWithholdDay?: {
    __typename: 'SWithholdDay';
    price?: string | null;
    tax?: string | null;
    year?: number | null;
  } | null;
};

export type AddTBaseMutationVariables = Exact<{
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  brand_id: Scalars['Int']['input'];
  city: Scalars['String']['input'];
  kana: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip_code: Scalars['String']['input'];
}>;

export type AddTBaseMutation = { __typename?: 'Mutation'; addTBase: { __typename: 'AddResult'; id: number } };

export type AddTTpmemBlockMutationVariables = Exact<{
  block_ids: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type AddTTpmemBlockMutation = {
  __typename?: 'Mutation';
  addTTpmemBlock: { __typename: 'DBResult'; resultRows: number };
};

export type AddUMemberMutationVariables = Exact<{
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
}>;

export type AddUMemberMutation = {
  __typename?: 'Mutation';
  addUMember?: {
    __typename: 'UMember';
    cognito_id?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    id: number;
    last_kana?: string | null;
    last_name?: string | null;
    password?: string | null;
    privilege?: number | null;
    status: number;
    tfa?: number | null;
  } | null;
};

export type AddUSendNotifyMutationVariables = Exact<{
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body: Scalars['String']['input'];
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  sys_id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type AddUSendNotifyMutation = {
  __typename?: 'Mutation';
  addUSendNotify?: {
    __typename: 'USendNotify';
    attr_id?: number | null;
    belonged?: boolean | null;
    biz_id?: number | null;
    body?: string | null;
    brand_id?: number | null;
    created_date?: string | null;
    id?: number | null;
    send_date?: string | null;
    sys_id?: number | null;
    title?: string | null;
    umgmt_id?: number | null;
    update_date?: string | null;
  } | null;
};

export type AddUSendNotifyDraftMutationVariables = Exact<{
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  sys_id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
}>;

export type AddUSendNotifyDraftMutation = {
  __typename?: 'Mutation';
  addUSendNotifyDraft?: {
    __typename: 'USendNotify';
    attr_id?: number | null;
    belonged?: boolean | null;
    biz_id?: number | null;
    body?: string | null;
    brand_id?: number | null;
    created_date?: string | null;
    id?: number | null;
    send_date?: string | null;
    sys_id?: number | null;
    title?: string | null;
    umgmt_id?: number | null;
    update_date?: string | null;
  } | null;
};

export type AddUTrialTicketMutationVariables = Exact<{
  item: Scalars['String']['input'];
  ticket_num: Scalars['Int']['input'];
  tpmem_id: Scalars['Int']['input'];
  umgmt_id: Scalars['Int']['input'];
  valid_days: Scalars['Int']['input'];
}>;

export type AddUTrialTicketMutation = {
  __typename?: 'Mutation';
  addUTrialTicket: { __typename: 'AddResult'; id: number };
};

export type AddupdUMemberMutationVariables = Exact<{
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
}>;

export type AddupdUMemberMutation = {
  __typename?: 'Mutation';
  addupdUMember: { __typename: 'DBResultStatus'; resultRows?: number | null; statusCode?: number | null };
};

export type DeleteSCampaignByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteSCampaignByIdMutation = {
  __typename?: 'Mutation';
  deleteSCampaignById: { __typename: 'DBResult'; resultRows: number };
};

export type DeleteSEgiftCodeByEgiftCodeMutationVariables = Exact<{
  egift_code: Scalars['Int']['input'];
}>;

export type DeleteSEgiftCodeByEgiftCodeMutation = {
  __typename?: 'Mutation';
  deleteSEgiftCodeByEgiftCode: { __typename: 'DBResult'; resultRows: number };
};

export type DeleteSWithholdDayByYearMutationVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type DeleteSWithholdDayByYearMutation = {
  __typename?: 'Mutation';
  deleteSWithholdDayByYear?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type DeleteTTpmemBlockByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  block_id: Scalars['Int']['input'];
}>;

export type DeleteTTpmemBlockByIdMutation = {
  __typename?: 'Mutation';
  deleteTTpmemBlockById: { __typename: 'DBResult'; resultRows: number };
};

export type DeleteUMemberByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteUMemberByIdMutation = {
  __typename?: 'Mutation';
  deleteUMemberById: { __typename: 'DBResult'; resultRows: number };
};

export type DeleteUSendNotifyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type DeleteUSendNotifyMutation = {
  __typename?: 'Mutation';
  deleteUSendNotify: { __typename: 'DBResult'; resultRows: number };
};

export type GetVtMemberBaseRecvNotifyByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberBaseRecvNotifyByIdMutation = {
  __typename?: 'Mutation';
  getVTMemberBaseRecvNotifyById: {
    __typename: 'VTMemberBase';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    address?: string | null;
    address2?: string | null;
    again?: string | null;
    bank?: string | null;
    belong_total?: number | null;
    biz_id: number;
    biz_name?: string | null;
    block_num?: number | null;
    branch_name?: string | null;
    brand_id: number;
    brand_name?: string | null;
    city?: string | null;
    close_time?: string | null;
    code?: string | null;
    corporate_no?: string | null;
    corporate_type?: number | null;
    created_date?: string | null;
    email?: string | null;
    favorite_num?: number | null;
    group_num: number;
    id: number;
    image?: string | null;
    invite_code?: string | null;
    join_date?: string | null;
    manager?: string | null;
    mco_address?: string | null;
    mco_address2?: string | null;
    mco_city?: string | null;
    mco_m_first_kana?: string | null;
    mco_m_first_name?: string | null;
    mco_m_kana?: string | null;
    mco_m_last_kana?: string | null;
    mco_m_last_name?: string | null;
    mco_m_name?: string | null;
    mco_name?: string | null;
    mco_phone?: string | null;
    mco_state?: string | null;
    mco_t_first_kana?: string | null;
    mco_t_first_name?: string | null;
    mco_t_kana?: string | null;
    mco_t_last_kana?: string | null;
    mco_t_last_name?: string | null;
    mco_t_name?: string | null;
    mco_t_phone?: string | null;
    mco_zip_code?: string | null;
    mgr_birthday?: string | null;
    mgr_dob_day?: number | null;
    mgr_dob_month?: number | null;
    mgr_dob_year?: number | null;
    mgr_first_kana?: string | null;
    mgr_first_name?: string | null;
    mgr_id?: number | null;
    mgr_last_kana?: string | null;
    mgr_last_name?: string | null;
    mgr_phone?: string | null;
    open_time?: string | null;
    opening?: string | null;
    parking?: string | null;
    parking_val?: boolean | null;
    pause_date?: string | null;
    payment_method?: number | null;
    personal_bank?: string | null;
    phone?: string | null;
    quit_date?: string | null;
    restart_date?: string | null;
    state?: string | null;
    status: number;
    store_name?: string | null;
    ticket_num?: number | null;
    total_adopt?: string | null;
    zip_code?: string | null;
    lat?: number | null;
    lng?: number | null;
  };
};

export type GetupdURecvNotifyByS3K4MutationVariables = Exact<{ [key: string]: never }>;

export type GetupdURecvNotifyByS3K4Mutation = {
  __typename?: 'Mutation';
  getupdURecvNotifyByS3K4: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetupdVbiPaymentListNewlyPayArbeitMutationVariables = Exact<{ [key: string]: never }>;

export type GetupdVbiPaymentListNewlyPayArbeitMutation = {
  __typename?: 'Mutation';
  getupdVBIPaymentListNewlyPayArbeit: {
    __typename: 'VBIPaymentListNewlyPayArbeit';
    newly: { __typename: 'VUNewlyPayArbeit'; im_inv: number; im_pay: number; mn_pay: number };
    payment: Array<{
      __typename: 'VBIPaymentList';
      btmem_id: number;
      name?: string | null;
      newly: boolean;
      output_date?: string | null;
      output_number?: string | null;
      price: string;
      req_csv_no?: number | null;
      req_date?: string | null;
      req_number?: string | null;
      request_no?: number | null;
      status: number;
    }>;
  };
};

export type GetupdVbIdentificationInfoByBtmemIdMutationVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetupdVbIdentificationInfoByBtmemIdMutation = {
  __typename?: 'Mutation';
  getupdVBIdentificationInfoByBtmemId: {
    __typename: 'VBIdentificationInfo';
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    birthday?: string | null;
    btmem_id: number;
    btmem_number?: string | null;
    city: string;
    created_date?: string | null;
    ecn?: string | null;
    email?: string | null;
    gender?: string | null;
    general_licenses?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    is_japanese?: boolean | null;
    kana?: string | null;
    name?: string | null;
    nationality?: string | null;
    parental?: string | null;
    parental_name?: string | null;
    period_date?: string | null;
    phone?: string | null;
    reg_hs_student?: boolean | null;
    reg_minor?: boolean | null;
    residence_name?: string | null;
    rule?: string | null;
    school?: string | null;
    state?: string | null;
    status: number;
    student?: string | null;
    submit_date?: string | null;
    zip_code?: string | null;
  };
};

export type GetupdVbIdentificationInfoByKeyMutationVariables = Exact<{
  internal_key: Scalars['Int']['input'];
}>;

export type GetupdVbIdentificationInfoByKeyMutation = {
  __typename?: 'Mutation';
  getupdVBIdentificationInfoByKey: {
    __typename: 'VBIdentificationInfo';
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    birthday?: string | null;
    btmem_id: number;
    btmem_number?: string | null;
    city: string;
    created_date?: string | null;
    ecn?: string | null;
    email?: string | null;
    gender?: string | null;
    general_licenses?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    is_japanese?: boolean | null;
    kana?: string | null;
    name?: string | null;
    nationality?: string | null;
    parental?: string | null;
    parental_name?: string | null;
    period_date?: string | null;
    phone?: string | null;
    reg_hs_student?: boolean | null;
    reg_minor?: boolean | null;
    residence_name?: string | null;
    rule?: string | null;
    school?: string | null;
    state?: string | null;
    status: number;
    student?: string | null;
    submit_date?: string | null;
    zip_code?: string | null;
  };
};

export type GetupdVbMemberBaseByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetupdVbMemberBaseByIdMutation = {
  __typename?: 'Mutation';
  getupdVBMemberBaseById: {
    __typename: 'VBMemberBase';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    attr_id?: number | null;
    attribute?: string | null;
    badge_name?: string | null;
    bank?: string | null;
    belong_biz_count?: number | null;
    birthday?: string | null;
    birthday2?: string | null;
    branch_name?: string | null;
    btmem_number?: string | null;
    cancel_count?: number | null;
    cancel_per?: number | null;
    city?: string | null;
    created_date?: string | null;
    email?: string | null;
    gender?: string | null;
    gender_value?: number | null;
    general_licenses?: string | null;
    id: number;
    identification: number;
    image?: string | null;
    imminent_count?: number | null;
    imminent_per?: number | null;
    kana?: string | null;
    late_count?: number | null;
    late_per?: number | null;
    lc_biz_count?: number | null;
    lc_biz_names?: string | null;
    license_list?: string | null;
    name?: string | null;
    nationality?: string | null;
    pause_end_day?: string | null;
    period_date?: string | null;
    phone?: string | null;
    rank_biz_count?: number | null;
    rank_biz_names?: string | null;
    rank_names?: string | null;
    reg_biz_list?: string | null;
    residence_name?: string | null;
    score?: number | null;
    skip_count?: number | null;
    state?: string | null;
    state_count?: number | null;
    status: number;
    sub_names?: string | null;
    tp_biz_names?: string | null;
    tpmem_names?: string | null;
    uniform_id?: number | null;
    uniform_size?: string | null;
    worked_count?: number | null;
    zip_code?: string | null;
  };
};

export type GetupdVtmPaymentListNewlyPayRefundMutationVariables = Exact<{ [key: string]: never }>;

export type GetupdVtmPaymentListNewlyPayRefundMutation = {
  __typename?: 'Mutation';
  getupdVTMPaymentListNewlyPayRefund: {
    __typename: 'VTMPaymentListNewlyPayRefund';
    newly: { __typename: 'VUNewlyPayRefund'; re_pay: number };
    payment: Array<{
      __typename: 'VTMPaymentList';
      bill_date?: string | null;
      csv_no?: number | null;
      name?: string | null;
      newly: boolean;
      output_date?: string | null;
      output_number?: string | null;
      price?: string | null;
      tpmem_id: number;
    }>;
  };
};

export type LoginUMemberMutationVariables = Exact<{
  cognito_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginUMemberMutation = {
  __typename?: 'Mutation';
  loginUMember?: {
    __typename: 'UMember';
    cognito_id?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    id: number;
    last_kana?: string | null;
    last_name?: string | null;
    password?: string | null;
    privilege?: number | null;
    status: number;
    tfa?: number | null;
  } | null;
};

export type UpdateAuthorizationMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type UpdateAuthorizationMutation = {
  __typename?: 'Mutation';
  updateAuthorization: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBArbeitScoreVisibleByWorkIdMutationVariables = Exact<{
  work_id: Scalars['Int']['input'];
}>;

export type UpdateBArbeitScoreVisibleByWorkIdMutation = {
  __typename?: 'Mutation';
  updateBArbeitScoreVisibleByWorkId: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBIdentificationApprovalByIdSubmitDateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  submit_date: Scalars['String']['input'];
}>;

export type UpdateBIdentificationApprovalByIdSubmitDateMutation = {
  __typename?: 'Mutation';
  updateBIdentificationApprovalByIdSubmitDate: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBIdentificationDenialByIdSubmitDateMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  submit_date: Scalars['String']['input'];
}>;

export type UpdateBIdentificationDenialByIdSubmitDateMutation = {
  __typename?: 'Mutation';
  updateBIdentificationDenialByIdSubmitDate: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBMemberDataByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  last_name: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_kana: Scalars['String']['input'];
  first_kana: Scalars['String']['input'];
  gender: Scalars['Int']['input'];
  dob: Scalars['String']['input'];
}>;

export type UpdateBMemberDataByIdMutation = {
  __typename?: 'Mutation';
  updateBMemberDataById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateBMemberStatusByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  status: Scalars['Int']['input'];
}>;

export type UpdateBMemberStatusByIdMutation = {
  __typename?: 'Mutation';
  updateBMemberStatusById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateBMemberStatusPauseDateByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateBMemberStatusPauseDateByIdMutation = {
  __typename?: 'Mutation';
  updateBMemberStatusPauseDateById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBMemberStatusRestartDateByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateBMemberStatusRestartDateByIdMutation = {
  __typename?: 'Mutation';
  updateBMemberStatusRestartDateById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBOfferS1ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateBOfferS1ByIdMutation = {
  __typename?: 'Mutation';
  updateBOfferS1ById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateBOfferS5ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateBOfferS5ByIdMutation = {
  __typename?: 'Mutation';
  updateBOfferS5ById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateCBillingCsvDlByCsvNoMutationVariables = Exact<{
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
}>;

export type UpdateCBillingCsvDlByCsvNoMutation = {
  __typename?: 'Mutation';
  updateCBillingCsvDlByCsvNo: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateCMonthPayCsvDlByCsvNoMutationVariables = Exact<{
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
}>;

export type UpdateCMonthPayCsvDlByCsvNoMutation = {
  __typename?: 'Mutation';
  updateCMonthPayCsvDlByCsvNo: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateCPaymentAndPayCsvByRequestNoMutationVariables = Exact<{
  output_uid: Scalars['Int']['input'];
  request_no: Scalars['String']['input'];
  wire_date: Scalars['String']['input'];
}>;

export type UpdateCPaymentAndPayCsvByRequestNoMutation = {
  __typename?: 'Mutation';
  updateCPaymentAndPayCsvByRequestNo: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateCRequestPayCsvDlByCsvNoMutationVariables = Exact<{
  csv_no: Scalars['Int']['input'];
  download_uid: Scalars['Int']['input'];
}>;

export type UpdateCRequestPayCsvDlByCsvNoMutation = {
  __typename?: 'Mutation';
  updateCRequestPayCsvDlByCsvNo: { __typename: 'DBResult'; resultRows: number };
};

export type UpdatePasswordMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  old_password: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type UpdatePasswordMutation = {
  __typename?: 'Mutation';
  updatePassword: { __typename: 'DBResult'; resultRows: number };
};

export type UpdatePasswordByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type UpdatePasswordByEmailMutation = {
  __typename?: 'Mutation';
  updatePasswordByEmail?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateSBenefitsByKindMutationVariables = Exact<{
  egift_code: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
}>;

export type UpdateSBenefitsByKindMutation = {
  __typename?: 'Mutation';
  updateSBenefitsByKind: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSBenefitsTicketNumMutationVariables = Exact<{
  iv_ticket_num: Scalars['Int']['input'];
}>;

export type UpdateSBenefitsTicketNumMutation = {
  __typename?: 'Mutation';
  updateSBenefitsTicketNum: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSBenefitsValidDaysMutationVariables = Exact<{
  iv_valid_days: Scalars['Int']['input'];
}>;

export type UpdateSBenefitsValidDaysMutation = {
  __typename?: 'Mutation';
  updateSBenefitsValidDays: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSEgiftCodeByEgiftCodeMutationVariables = Exact<{
  config_code: Scalars['String']['input'];
  deadline: Scalars['String']['input'];
  egift_code: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
  token: Scalars['String']['input'];
}>;

export type UpdateSEgiftCodeByEgiftCodeMutation = {
  __typename?: 'Mutation';
  updateSEgiftCodeByEgiftCode: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSLendStaffByBtRankIdBizIdMutationVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  bt_rank_id: Scalars['Int']['input'];
  educ_fee: Scalars['Int']['input'];
  educ_fee_max: Scalars['Int']['input'];
  educ_fee_min: Scalars['Int']['input'];
}>;

export type UpdateSLendStaffByBtRankIdBizIdMutation = {
  __typename?: 'Mutation';
  updateSLendStaffByBtRankIdBizId: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemBonusPerMutationVariables = Exact<{
  bonus_per: Scalars['Int']['input'];
}>;

export type UpdateSTpmemBonusPerMutation = {
  __typename?: 'Mutation';
  updateSTpmemBonusPer: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRadiusByTypeId1MutationVariables = Exact<{
  radius: Scalars['Float']['input'];
}>;

export type UpdateSTpmemTypeRadiusByTypeId1Mutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRadiusByTypeId1: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRecruitDaysByTypeIdMutationVariables = Exact<{
  recruit_days: Scalars['Int']['input'];
  type_id: Scalars['Int']['input'];
}>;

export type UpdateSTpmemTypeRecruitDaysByTypeIdMutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRecruitDaysByTypeId: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRecruitDaysByTypeId1MutationVariables = Exact<{
  recruit_days: Scalars['Int']['input'];
}>;

export type UpdateSTpmemTypeRecruitDaysByTypeId1Mutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRecruitDaysByTypeId1: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRecruitNumByTypeIdMutationVariables = Exact<{
  recruit_num: Scalars['Int']['input'];
  type_id: Scalars['Int']['input'];
}>;

export type UpdateSTpmemTypeRecruitNumByTypeIdMutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRecruitNumByTypeId: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRecruitNumByTypeId1MutationVariables = Exact<{
  recruit_num: Scalars['Int']['input'];
}>;

export type UpdateSTpmemTypeRecruitNumByTypeId1Mutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRecruitNumByTypeId1: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateSTpmemTypeRecruitRatioByTypeId1MutationVariables = Exact<{
  recruit_ratio: Scalars['Int']['input'];
}>;

export type UpdateSTpmemTypeRecruitRatioByTypeId1Mutation = {
  __typename?: 'Mutation';
  updateSTpmemTypeRecruitRatioByTypeId1: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateStatusByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  status: Scalars['Int']['input'];
}>;

export type UpdateStatusByEmailMutation = {
  __typename?: 'Mutation';
  updateStatusByEmail?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateTArbeitOffereStatusByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  status: Scalars['Int']['input'];
}>;

export type UpdateTArbeitOffereStatusByIdMutation = {
  __typename?: 'Mutation';
  updateTArbeitOffereStatusById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTBaseByIdMutationVariables = Exact<{
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip_code: Scalars['String']['input'];
}>;

export type UpdateTBaseByIdMutation = {
  __typename?: 'Mutation';
  updateTBaseById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTManagerTMemberMutationVariables = Exact<{
  close_time: Scalars['String']['input'];
  corporate_no: Scalars['String']['input'];
  corporate_type: Scalars['Int']['input'];
  dob: Scalars['String']['input'];
  first_kana: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  image: Scalars['String']['input'];
  last_kana: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  mco_address1: Scalars['String']['input'];
  mco_address2: Scalars['String']['input'];
  mco_city: Scalars['String']['input'];
  mco_m_first_kana: Scalars['String']['input'];
  mco_m_first_name: Scalars['String']['input'];
  mco_m_last_kana: Scalars['String']['input'];
  mco_m_last_name: Scalars['String']['input'];
  mco_name: Scalars['String']['input'];
  mco_phone: Scalars['String']['input'];
  mco_state: Scalars['String']['input'];
  mco_t_first_kana: Scalars['String']['input'];
  mco_t_first_name: Scalars['String']['input'];
  mco_t_last_kana: Scalars['String']['input'];
  mco_t_last_name: Scalars['String']['input'];
  mco_t_phone: Scalars['String']['input'];
  mco_zip_code: Scalars['String']['input'];
  open_time: Scalars['String']['input'];
  parking: Scalars['Boolean']['input'];
  phone: Scalars['String']['input'];
  tpmem_id: Scalars['Int']['input'];
}>;

export type UpdateTManagerTMemberMutation = {
  __typename?: 'Mutation';
  updateTManagerTMember?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateTMemberByIdMutationVariables = Exact<{
  close_time: Scalars['String']['input'];
  corporate_no: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  mco_address1: Scalars['String']['input'];
  mco_address2: Scalars['String']['input'];
  mco_city: Scalars['String']['input'];
  mco_m_first_kana: Scalars['String']['input'];
  mco_m_first_name: Scalars['String']['input'];
  mco_m_last_kana: Scalars['String']['input'];
  mco_m_last_name: Scalars['String']['input'];
  mco_name: Scalars['String']['input'];
  mco_phone: Scalars['String']['input'];
  mco_state: Scalars['String']['input'];
  mco_t_first_kana: Scalars['String']['input'];
  mco_t_first_name: Scalars['String']['input'];
  mco_t_last_kana: Scalars['String']['input'];
  mco_t_last_name: Scalars['String']['input'];
  mco_t_phone: Scalars['String']['input'];
  mco_zip_code: Scalars['String']['input'];
  mgr_id: Scalars['Int']['input'];
  open_time: Scalars['String']['input'];
  parking: Scalars['Boolean']['input'];
}>;

export type UpdateTMemberByIdMutation = {
  __typename?: 'Mutation';
  updateTMemberById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateTMemberS1ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateTMemberS1ByIdMutation = {
  __typename?: 'Mutation';
  updateTMemberS1ById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTMemberS2ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateTMemberS2ByIdMutation = {
  __typename?: 'Mutation';
  updateTMemberS2ById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTMemberTrProcStatusByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  tr_proc_status: Scalars['Int']['input'];
  tr_proc_uid: Scalars['Int']['input'];
}>;

export type UpdateTMemberTrProcStatusByIdMutation = {
  __typename?: 'Mutation';
  updateTMemberTrProcStatusById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTMemberTrProcTypeByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  tr_proc_type: Scalars['Int']['input'];
}>;

export type UpdateTMemberTrProcTypeByIdMutation = {
  __typename?: 'Mutation';
  updateTMemberTrProcTypeById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTScoreVisibleByWorkIdMutationVariables = Exact<{
  work_id: Scalars['Int']['input'];
}>;

export type UpdateTScoreVisibleByWorkIdMutation = {
  __typename?: 'Mutation';
  updateTScoreVisibleByWorkId: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateTTpmemBlockByIdMutationVariables = Exact<{
  block_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  reg_date: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type UpdateTTpmemBlockByIdMutation = {
  __typename?: 'Mutation';
  updateTTpmemBlockById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateUMemberByIdMutationVariables = Exact<{
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  last_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  privilege: Scalars['Int']['input'];
}>;

export type UpdateUMemberByIdMutation = {
  __typename?: 'Mutation';
  updateUMemberById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateUMemberEmailByIdMutationVariables = Exact<{
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
}>;

export type UpdateUMemberEmailByIdMutation = {
  __typename?: 'Mutation';
  updateUMemberEmailById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateUMemberPrivilegeByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  privilege: Scalars['Int']['input'];
}>;

export type UpdateUMemberPrivilegeByIdMutation = {
  __typename?: 'Mutation';
  updateUMemberPrivilegeById: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateUMemberTfa0ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateUMemberTfa0ByIdMutation = {
  __typename?: 'Mutation';
  updateUMemberTfa0ById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateUMemberTfa1ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateUMemberTfa1ByIdMutation = {
  __typename?: 'Mutation';
  updateUMemberTfa1ById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateURecvNotifyReadBySysIdKindMutationVariables = Exact<{
  kind: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
}>;

export type UpdateURecvNotifyReadBySysIdKindMutation = {
  __typename?: 'Mutation';
  updateURecvNotifyReadBySysIdKind: { __typename: 'DBResult'; resultRows: number };
};

export type UpdateUSendNotifyMutationVariables = Exact<{
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body: Scalars['String']['input'];
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  umgmt_id: Scalars['Int']['input'];
}>;

export type UpdateUSendNotifyMutation = {
  __typename?: 'Mutation';
  updateUSendNotify?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateUSendNotifyDraftMutationVariables = Exact<{
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  sys_id?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
}>;

export type UpdateUSendNotifyDraftMutation = {
  __typename?: 'Mutation';
  updateUSendNotifyDraft?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UpdateUTrialTicketS2ByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type UpdateUTrialTicketS2ByIdMutation = {
  __typename?: 'Mutation';
  updateUTrialTicketS2ById?: { __typename: 'DBResult'; resultRows: number } | null;
};

export type UploadSPaymentDaysMutationVariables = Exact<{
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}>;

export type UploadSPaymentDaysMutation = {
  __typename?: 'Mutation';
  uploadSPaymentDays: { __typename: 'DBResult'; resultRows: number };
};

export type UploadSWithholdDayMutationVariables = Exact<{
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
  year: Scalars['Int']['input'];
}>;

export type UploadSWithholdDayMutation = {
  __typename?: 'Mutation';
  uploadSWithholdDay: { __typename: 'DBResult'; resultRows: number };
};

export type UploadTBaseMutationVariables = Exact<{
  s3_bucket: Scalars['String']['input'];
  s3_filepath: Scalars['String']['input'];
}>;

export type UploadTBaseMutation = {
  __typename?: 'Mutation';
  uploadTBase: { __typename: 'DBResult'; resultRows: number };
};

export type GetAuthorizationQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuthorizationQuery = {
  __typename?: 'Query';
  getAuthorization?: { __typename: 'DBResultStatus'; resultRows?: number | null; statusCode?: number | null } | null;
};

export type GetBLoggingByBtmemIdKindQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
}>;

export type GetBLoggingByBtmemIdKindQuery = {
  __typename?: 'Query';
  getBLoggingByBtmemIdKind?: Array<{
    __typename: 'BLogging';
    btmem_id: number;
    id: number;
    kind: number;
    log_date?: string | null;
    matter?: string | null;
    ref_no?: number | null;
  } | null> | null;
};

export type GetBMemberByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetBMemberByIdQuery = {
  __typename?: 'Query';
  getBMemberById?: {
    __typename: 'BMember';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    achievement?: string | null;
    address1?: string | null;
    address2?: string | null;
    attr_id?: number | null;
    bank_code?: string | null;
    branch_name?: string | null;
    branch_no?: string | null;
    city?: string | null;
    cognito_id?: string | null;
    created_date?: string | null;
    dob?: string | null;
    ecn?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    gender?: number | null;
    id?: number | null;
    image?: string | null;
    invite_code?: string | null;
    invited_input?: string | null;
    is_japanese?: boolean | null;
    last_kana?: string | null;
    last_name?: string | null;
    nationality_id?: number | null;
    nickname?: string | null;
    parental?: boolean | null;
    parental_name?: string | null;
    pause_date?: string | null;
    penalty?: number | null;
    period_date?: string | null;
    phone?: string | null;
    quit_date?: string | null;
    reason?: string | null;
    reg_hs_student?: boolean | null;
    reg_minor?: boolean | null;
    residence?: number | null;
    restart_date?: string | null;
    rule?: boolean | null;
    school?: string | null;
    state?: string | null;
    status?: number | null;
    uniform_id?: number | null;
    wire_new_code?: number | null;
    zip_code?: string | null;
  } | null;
};

export type GetCBillingCsvQueryVariables = Exact<{ [key: string]: never }>;

export type GetCBillingCsvQuery = {
  __typename?: 'Query';
  getCBillingCsv?: Array<{
    __typename: 'CBillingCsv';
    csv_no?: number | null;
    csv_url?: string | null;
    dl_date?: string | null;
    download_uid?: number | null;
    kind?: number | null;
    output_date?: string | null;
    pay_date?: string | null;
    transfer_date?: string | null;
  } | null> | null;
};

export type GetCBillingCsvByKindQueryVariables = Exact<{
  kind: Scalars['Int']['input'];
}>;

export type GetCBillingCsvByKindQuery = {
  __typename?: 'Query';
  getCBillingCsvByKind?: Array<{
    __typename: 'CBillingCsv';
    csv_no?: number | null;
    csv_url?: string | null;
    dl_date?: string | null;
    download_uid?: number | null;
    kind?: number | null;
    output_date?: string | null;
    pay_date?: string | null;
    transfer_date?: string | null;
  } | null> | null;
};

export type GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables = Exact<{
  category: Scalars['Int']['input'];
  request: Scalars['Int']['input'];
  request_no: Scalars['Int']['input'];
  sys_id: Scalars['Int']['input'];
}>;

export type GetCPaymentBySysIdCategoryRequestRequestNoQuery = {
  __typename?: 'Query';
  getCPaymentBySysIdCategoryRequestRequestNo?: {
    __typename: 'CPayment';
    all_csv_no?: number | null;
    bill_date?: string | null;
    category?: number | null;
    csv_no?: number | null;
    id?: number | null;
    mem_id?: number | null;
    origin_id?: number | null;
    price?: number | null;
    req_csv_no?: number | null;
    req_date?: string | null;
    request?: number | null;
    request_no?: number | null;
    subcategory?: number | null;
    sys_id?: number | null;
    tax?: number | null;
  } | null;
};

export type GetSBenefitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSBenefitsQuery = {
  __typename?: 'Query';
  getSBenefits: {
    __typename: 'SBenefits';
    bd_egift_code: number;
    iv_egift_code: number;
    iv_ticket_num: number;
    iv_valid_days: number;
    review_egift_code: number;
  };
};

export type GetSEgiftCodeQueryVariables = Exact<{ [key: string]: never }>;

export type GetSEgiftCodeQuery = {
  __typename?: 'Query';
  getSEgiftCode: Array<{
    __typename: 'SEgiftCode';
    config_code?: string | null;
    deadline?: string | null;
    egift_code: number;
    egift_type: number;
    name?: string | null;
    price?: number | null;
    thanks_gift?: boolean | null;
    token?: string | null;
  }>;
};

export type GetSEgiftCodeByThanksGift0QueryVariables = Exact<{ [key: string]: never }>;

export type GetSEgiftCodeByThanksGift0Query = {
  __typename?: 'Query';
  getSEgiftCodeByThanksGift0: Array<{
    __typename: 'SEgiftCode';
    config_code?: string | null;
    deadline?: string | null;
    egift_code: number;
    egift_type: number;
    name?: string | null;
    price?: number | null;
    thanks_gift?: boolean | null;
    token?: string | null;
  }>;
};

export type GetSEgiftCodeByThanksGift1QueryVariables = Exact<{ [key: string]: never }>;

export type GetSEgiftCodeByThanksGift1Query = {
  __typename?: 'Query';
  getSEgiftCodeByThanksGift1: Array<{
    __typename: 'SEgiftCode';
    config_code?: string | null;
    deadline?: string | null;
    egift_code: number;
    egift_type: number;
    name?: string | null;
    price?: number | null;
    thanks_gift?: boolean | null;
    token?: string | null;
  }>;
};

export type GetSPaymentDaysByYearQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type GetSPaymentDaysByYearQuery = {
  __typename?: 'Query';
  getSPaymentDaysByYear: {
    __typename: 'SPaymentDaysNewly';
    newly: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number };
    payment: Array<{ __typename: 'SPaymentDays'; day: string; month: number; year: number }>;
    schedule: {
      __typename: 'SYearSchedule';
      payment_regist?: string | null;
      withhold_regist?: string | null;
      year?: number | null;
    };
  };
};

export type GetSTpmemQueryVariables = Exact<{ [key: string]: never }>;

export type GetSTpmemQuery = {
  __typename?: 'Query';
  getSTpmem: {
    __typename: 'STpmem';
    bonus_per?: number | null;
    checkin_start?: number | null;
    checkout_start?: number | null;
    finish_timeout?: number | null;
    fix_timeout?: number | null;
    hunt_accept?: number | null;
    penalty?: number | null;
    tax_per?: number | null;
  };
};

export type GetSTpmemTypeByTypeId1QueryVariables = Exact<{ [key: string]: never }>;

export type GetSTpmemTypeByTypeId1Query = {
  __typename?: 'Query';
  getSTpmemTypeByTypeId1: {
    __typename: 'STpmemType';
    blocked?: boolean | null;
    monthly_fee?: number | null;
    priority?: boolean | null;
    publ_fee?: number | null;
    radius?: number | null;
    recruit_days?: number | null;
    recruit_num?: number | null;
    recruit_ratio?: number | null;
    reg_staff?: boolean | null;
    type_id?: number | null;
    video_fee?: number | null;
  };
};

export type GetSWithholdDayByYearQueryVariables = Exact<{
  year: Scalars['Int']['input'];
}>;

export type GetSWithholdDayByYearQuery = {
  __typename?: 'Query';
  getSWithholdDayByYear: {
    __typename: 'SWithholdDayVUNewlyAccounting';
    newly: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number };
    schedule: {
      __typename: 'SYearSchedule';
      payment_regist?: string | null;
      withhold_regist?: string | null;
      year?: number | null;
    };
    withhold: Array<{ __typename: 'SWithholdDay'; price?: string | null; tax?: string | null; year?: number | null }>;
  };
};

export type GetSYearScheduleGpQueryVariables = Exact<{ [key: string]: never }>;

export type GetSYearScheduleGpQuery = {
  __typename?: 'Query';
  getSYearScheduleGp: Array<{ __typename: 'SYearScheduleGp'; year: number }>;
};

export type GetSYearScheduleGpByPaymentRegistQueryVariables = Exact<{ [key: string]: never }>;

export type GetSYearScheduleGpByPaymentRegistQuery = {
  __typename?: 'Query';
  getSYearScheduleGpByPaymentRegist: Array<{ __typename: 'SYearScheduleGp'; year: number }>;
};

export type GetSYearScheduleGpByWithholdRegistQueryVariables = Exact<{ [key: string]: never }>;

export type GetSYearScheduleGpByWithholdRegistQuery = {
  __typename?: 'Query';
  getSYearScheduleGpByWithholdRegist: Array<{ __typename: 'SYearScheduleGp'; year: number }>;
};

export type GetTTpmemBlockByIdQueryVariables = Exact<{
  block_id: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
}>;

export type GetTTpmemBlockByIdQuery = {
  __typename?: 'Query';
  getTTpmemBlockById?: {
    __typename: 'TTpmemBlock';
    block_id?: number | null;
    id?: number | null;
    reason?: string | null;
    reg_date?: string | null;
    umgmt_id?: number | null;
  } | null;
};

export type GetTmemberCsvDataForMfkQueryVariables = Exact<{ [key: string]: never }>;

export type GetTmemberCsvDataForMfkQuery = {
  __typename?: 'Query';
  getTmemberCsvDataForMfk?: { __typename: 'TmemberCsvMfk'; resultRows: number; csv_data?: string | null } | null;
};

export type GetTWithholdSlipByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetTWithholdSlipByBtmemIdQuery = {
  __typename?: 'Query';
  getTWithholdSlipByBtmemId?: {
    __typename: 'TWithholdSlip';
    btmem_id?: string | null;
    id?: number | null;
    month_val?: number | null;
    withholding?: number | null;
    year_val?: number | null;
  } | null;
};

export type GetUMemberByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type GetUMemberByEmailQuery = {
  __typename?: 'Query';
  getUMemberByEmail?: {
    __typename: 'UMember';
    cognito_id?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    id: number;
    last_kana?: string | null;
    last_name?: string | null;
    password?: string | null;
    privilege?: number | null;
    status: number;
    tfa?: number | null;
  } | null;
};

export type GetUMemberByIdQueryVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;

export type GetUMemberByIdQuery = {
  __typename?: 'Query';
  getUMemberById?: {
    __typename: 'UMember';
    cognito_id?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    id: number;
    last_kana?: string | null;
    last_name?: string | null;
    password?: string | null;
    privilege?: number | null;
    status: number;
    tfa?: number | null;
  } | null;
};

export type GetUMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUMembersQuery = {
  __typename?: 'Query';
  getUMembers?: Array<{
    __typename: 'UMember';
    cognito_id?: string | null;
    email?: string | null;
    first_kana?: string | null;
    first_name?: string | null;
    id: number;
    last_kana?: string | null;
    last_name?: string | null;
    password?: string | null;
    privilege?: number | null;
    status: number;
    tfa?: number | null;
  } | null> | null;
};

export type GetURecvNotifyByS2K3QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS2K3Query = {
  __typename?: 'Query';
  getURecvNotifyByS2K3: {
    __typename: 'URecvNotifyHome';
    home: {
      __typename: 'VUNewlyHome';
      b_badge: number;
      b_identfy: number;
      b_join: number;
      b_pay: number;
      b_quit: number;
      t_change: number;
      t_pay: number;
      t_quit: number;
      t_regist: number;
    };
    list: Array<{
      __typename: 'URecvNotify';
      body?: string | null;
      id: number;
      is_read: boolean;
      kind: number;
      recv_date?: string | null;
      ref_no?: number | null;
      screen_no?: string | null;
      sys_id: number;
      title?: string | null;
    }>;
  };
};

export type GetURecvNotifyByS2K4QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS2K4Query = {
  __typename?: 'Query';
  getURecvNotifyByS2K4: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetURecvNotifyByS2K6QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS2K6Query = {
  __typename?: 'Query';
  getURecvNotifyByS2K6: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetURecvNotifyByS2K7QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS2K7Query = {
  __typename?: 'Query';
  getURecvNotifyByS2K7: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetURecvNotifyByS3K1QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS3K1Query = {
  __typename?: 'Query';
  getURecvNotifyByS3K1: {
    __typename: 'URecvNotifyHome';
    home: {
      __typename: 'VUNewlyHome';
      b_badge: number;
      b_identfy: number;
      b_join: number;
      b_pay: number;
      b_quit: number;
      t_change: number;
      t_pay: number;
      t_quit: number;
      t_regist: number;
    };
    list: Array<{
      __typename: 'URecvNotify';
      body?: string | null;
      id: number;
      is_read: boolean;
      kind: number;
      recv_date?: string | null;
      ref_no?: number | null;
      screen_no?: string | null;
      sys_id: number;
      title?: string | null;
    }>;
  };
};

export type GetURecvNotifyByS3K2QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS3K2Query = {
  __typename?: 'Query';
  getURecvNotifyByS3K2: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetURecvNotifyByS3K3QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS3K3Query = {
  __typename?: 'Query';
  getURecvNotifyByS3K3: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetURecvNotifyByS3K5QueryVariables = Exact<{ [key: string]: never }>;

export type GetURecvNotifyByS3K5Query = {
  __typename?: 'Query';
  getURecvNotifyByS3K5: Array<{
    __typename: 'URecvNotify';
    body?: string | null;
    id: number;
    is_read: boolean;
    kind: number;
    recv_date?: string | null;
    ref_no?: number | null;
    screen_no?: string | null;
    sys_id: number;
    title?: string | null;
  }>;
};

export type GetVbCancelInfoByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbCancelInfoByIdQuery = {
  __typename?: 'Query';
  getVBCancelInfoById: {
    __typename: 'VBCancelInfo';
    cancel_count?: number | null;
    cancel_per?: number | null;
    id: number;
    identification: number;
    image?: string | null;
    imminent_count?: number | null;
    imminent_per?: number | null;
    late_count?: number | null;
    late_per?: number | null;
    name?: string | null;
    penalty?: number | null;
    skip_count?: number | null;
    status: number;
  };
};

export type GetVbCpGiftListByKind11QueryVariables = Exact<{ [key: string]: never }>;

export type GetVbCpGiftListByKind11Query = {
  __typename?: 'Query';
  getVBCpGiftListByKind11: Array<{
    __typename: 'VBCpGiftList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    egift_code: number;
    end_date?: string | null;
    gift_name?: string | null;
    id: number;
    incl_resident?: boolean | null;
    incl_resident_disp?: string | null;
    kind: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    title?: string | null;
  }>;
};

export type GetVbCpGiftListByKind12QueryVariables = Exact<{ [key: string]: never }>;

export type GetVbCpGiftListByKind12Query = {
  __typename?: 'Query';
  getVBCpGiftListByKind12: Array<{
    __typename: 'VBCpGiftList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    egift_code: number;
    end_date?: string | null;
    gift_name?: string | null;
    id: number;
    incl_resident?: boolean | null;
    incl_resident_disp?: string | null;
    kind: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    title?: string | null;
  }>;
};

export type GetVbFavoriteStoreListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbFavoriteStoreListByIdQuery = {
  __typename?: 'Query';
  getVBFavoriteStoreListById?: Array<{
    __typename: 'VBFavoriteStoreList';
    btmem_id: number;
    image?: string | null;
    reg_date?: string | null;
    score?: number | null;
    store_name?: string | null;
    tpmem_id: number;
  } | null> | null;
};

export type GetVbGiftSettingListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbGiftSettingListQuery = {
  __typename?: 'Query';
  getVBGiftSettingList: Array<{
    __typename: 'VBGiftSettingList';
    deadline?: string | null;
    egift_code: number;
    egift_name?: string | null;
    kind: number;
  }>;
};

export type GetVbiPayCsvListNewlyPayArbeitQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbiPayCsvListNewlyPayArbeitQuery = {
  __typename?: 'Query';
  getVBIPayCsvListNewlyPayArbeit: {
    __typename: 'VBIPayCsvListNewlyPayArbeit';
    csvlist: Array<{
      __typename: 'VBIPayCsvList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      unopened?: number | null;
    }>;
    newly: { __typename: 'VUNewlyPayArbeit'; im_inv: number; im_pay: number; mn_pay: number };
  };
};

export type GetVbIdentificationInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbIdentificationInfoQuery = {
  __typename?: 'Query';
  getVBIdentificationInfo?: Array<{
    __typename: 'VBIdentificationInfo';
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    birthday?: string | null;
    btmem_id: number;
    btmem_number?: string | null;
    city: string;
    created_date?: string | null;
    ecn?: string | null;
    email?: string | null;
    gender?: string | null;
    general_licenses?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    is_japanese?: boolean | null;
    kana?: string | null;
    name?: string | null;
    nationality?: string | null;
    parental?: string | null;
    parental_name?: string | null;
    period_date?: string | null;
    phone?: string | null;
    reg_hs_student?: boolean | null;
    reg_minor?: boolean | null;
    residence_name?: string | null;
    rule?: string | null;
    school?: string | null;
    state?: string | null;
    status: number;
    student?: string | null;
    submit_date?: string | null;
    zip_code?: string | null;
  } | null> | null;
};

export type GetVbIdentificationListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbIdentificationListQuery = {
  __typename?: 'Query';
  getVBIdentificationList: Array<{
    __typename: 'VBIdentificationList';
    btmem_id: number;
    complete_date?: string | null;
    entry_date?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    name?: string | null;
    newly: number;
    submit_date?: string | null;
  }>;
};

export type GetVbIdentificationListByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetVbIdentificationListByBtmemIdQuery = {
  __typename?: 'Query';
  getVBIdentificationListByBtmemId: {
    __typename: 'VBIdentificationList';
    btmem_id: number;
    complete_date?: string | null;
    entry_date?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    name?: string | null;
    newly: number;
    submit_date?: string | null;
  };
};

export type GetVbIdentificationListByKeyQueryVariables = Exact<{
  internal_key: Scalars['Int']['input'];
}>;

export type GetVbIdentificationListByKeyQuery = {
  __typename?: 'Query';
  getVBIdentificationListByKey: {
    __typename: 'VBIdentificationList';
    btmem_id: number;
    complete_date?: string | null;
    entry_date?: string | null;
    id2_doc1?: string | null;
    id2_doc2?: string | null;
    id2_type?: string | null;
    id_doc1?: string | null;
    id_doc2?: string | null;
    id_type?: string | null;
    identification: number;
    image?: string | null;
    internal_key?: number | null;
    name?: string | null;
    newly: number;
    submit_date?: string | null;
  };
};

export type GetVbIdentificationLoggingListByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetVbIdentificationLoggingListByBtmemIdQuery = {
  __typename?: 'Query';
  getVBIdentificationLoggingListByBtmemId: {
    __typename: 'VBIdentificationLogging';
    identification: {
      __typename: 'VBIdentificationList';
      btmem_id: number;
      complete_date?: string | null;
      entry_date?: string | null;
      id2_doc1?: string | null;
      id2_doc2?: string | null;
      id2_type?: string | null;
      id_doc1?: string | null;
      id_doc2?: string | null;
      id_type?: string | null;
      identification: number;
      image?: string | null;
      internal_key?: number | null;
      name?: string | null;
      newly: number;
      submit_date?: string | null;
    };
    logging: Array<{
      __typename: 'BLogging';
      btmem_id: number;
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
    }>;
  };
};

export type GetVbIdentificationLoggingListByKeyQueryVariables = Exact<{
  internal_key: Scalars['Int']['input'];
}>;

export type GetVbIdentificationLoggingListByKeyQuery = {
  __typename?: 'Query';
  getVBIdentificationLoggingListByKey: {
    __typename: 'VBIdentificationLogging';
    identification: {
      __typename: 'VBIdentificationList';
      btmem_id: number;
      complete_date?: string | null;
      entry_date?: string | null;
      id2_doc1?: string | null;
      id2_doc2?: string | null;
      id2_type?: string | null;
      id_doc1?: string | null;
      id_doc2?: string | null;
      id_type?: string | null;
      identification: number;
      image?: string | null;
      internal_key?: number | null;
      name?: string | null;
      newly: number;
      submit_date?: string | null;
    };
    logging: Array<{
      __typename: 'BLogging';
      btmem_id: number;
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
    }>;
  };
};

export type GetVbmPayCsvListNewlyPayArbeitQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbmPayCsvListNewlyPayArbeitQuery = {
  __typename?: 'Query';
  getVBMPayCsvListNewlyPayArbeit: {
    __typename: 'VBMPayCsvListNewlyPayArbeit';
    csvlist: Array<{
      __typename: 'VBMPayCsvList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      pay_date?: string | null;
      unopened?: number | null;
    }>;
    newly: { __typename: 'VUNewlyPayArbeit'; im_inv: number; im_pay: number; mn_pay: number };
  };
};

export type GetVbMemberBaseByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbMemberBaseByIdQuery = {
  __typename?: 'Query';
  getVBMemberBaseById: {
    __typename: 'VBMemberBase';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    attr_id?: number | null;
    attribute?: string | null;
    badge_name?: string | null;
    bank?: string | null;
    belong_biz_count?: number | null;
    birthday?: string | null;
    birthday2?: string | null;
    branch_name?: string | null;
    btmem_number?: string | null;
    cancel_count?: number | null;
    cancel_per?: number | null;
    city?: string | null;
    created_date?: string | null;
    email?: string | null;
    gender?: string | null;
    gender_value?: number | null;
    general_licenses?: string | null;
    id: number;
    identification: number;
    image?: string | null;
    imminent_count?: number | null;
    imminent_per?: number | null;
    kana?: string | null;
    late_count?: number | null;
    late_per?: number | null;
    lc_biz_count?: number | null;
    lc_biz_names?: string | null;
    license_list?: string | null;
    name?: string | null;
    nationality?: string | null;
    pause_end_day?: string | null;
    period_date?: string | null;
    phone?: string | null;
    rank_biz_count?: number | null;
    rank_biz_names?: string | null;
    rank_names?: string | null;
    reg_biz_list?: string | null;
    residence_name?: string | null;
    score?: number | null;
    skip_count?: number | null;
    state?: string | null;
    state_count?: number | null;
    status: number;
    sub_names?: string | null;
    tp_biz_names?: string | null;
    tpmem_names?: string | null;
    uniform_id?: number | null;
    uniform_size?: string | null;
    worked_count?: number | null;
    zip_code?: string | null;
  };
};

export type GetVbMemberListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbMemberListQuery = {
  __typename?: 'Query';
  getVBMemberList: Array<{
    __typename: 'VBMemberList';
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    all_biz_count?: number | null;
    all_biz_ids?: string | null;
    all_biz_names?: string | null;
    attribute?: string | null;
    belong_biz_count?: number | null;
    birthday: string;
    btmem_number?: string | null;
    cancel_per: number;
    city?: string | null;
    email?: string | null;
    gender?: string | null;
    id: number;
    identification: number;
    image?: string | null;
    name?: string | null;
    nationality_id?: number | null;
    newly: number;
    phone?: string | null;
    rank_biz_count?: number | null;
    rank_biz_ids?: string | null;
    rank_biz_names?: string | null;
    rank_names?: string | null;
    residence?: number | null;
    score?: number | null;
    score_count?: number | null;
    state?: string | null;
    status: number;
    sub_names?: string | null;
    tp_biz_names?: string | null;
    tpmem_dates?: string | null;
    tpmem_names?: string | null;
    uniform_size?: string | null;
    worked_count?: number | null;
    zip_code?: string | null;
  }>;
};

export type GetVbMemberListFavoriteByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbMemberListFavoriteByIdQuery = {
  __typename?: 'Query';
  getVBMemberListFavoriteById: {
    __typename: 'VBMemberListFavorite';
    favorite: Array<{
      __typename: 'VBFavoriteStoreList';
      btmem_id: number;
      image?: string | null;
      reg_date?: string | null;
      score?: number | null;
      store_name?: string | null;
      tpmem_id: number;
    }>;
    member: Array<{
      __typename: 'VBMemberList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      all_biz_count?: number | null;
      all_biz_ids?: string | null;
      all_biz_names?: string | null;
      attribute?: string | null;
      belong_biz_count?: number | null;
      birthday: string;
      btmem_number?: string | null;
      cancel_per: number;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      name?: string | null;
      nationality_id?: number | null;
      newly: number;
      phone?: string | null;
      rank_biz_count?: number | null;
      rank_biz_ids?: string | null;
      rank_biz_names?: string | null;
      rank_names?: string | null;
      residence?: number | null;
      score?: number | null;
      score_count?: number | null;
      state?: string | null;
      status: number;
      sub_names?: string | null;
      tp_biz_names?: string | null;
      tpmem_dates?: string | null;
      tpmem_names?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    }>;
  };
};

export type GetVbMemberListLoggingByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbMemberListLoggingByIdQuery = {
  __typename?: 'Query';
  getVBMemberListLoggingById: {
    __typename: 'VBMemberListLogging';
    logging: Array<{
      __typename: 'BLogging';
      btmem_id: number;
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
    }>;
    member: Array<{
      __typename: 'VBMemberList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      all_biz_count?: number | null;
      all_biz_ids?: string | null;
      all_biz_names?: string | null;
      attribute?: string | null;
      belong_biz_count?: number | null;
      birthday: string;
      btmem_number?: string | null;
      cancel_per: number;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      name?: string | null;
      nationality_id?: number | null;
      newly: number;
      phone?: string | null;
      rank_biz_count?: number | null;
      rank_biz_ids?: string | null;
      rank_biz_names?: string | null;
      rank_names?: string | null;
      residence?: number | null;
      score?: number | null;
      score_count?: number | null;
      state?: string | null;
      status: number;
      sub_names?: string | null;
      tp_biz_names?: string | null;
      tpmem_dates?: string | null;
      tpmem_names?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    }>;
  };
};

export type GetVbMemberListWithholdByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbMemberListWithholdByIdQuery = {
  __typename?: 'Query';
  getVBMemberListWithholdById: {
    __typename: 'VBMemberListWithhold';
    member: Array<{
      __typename: 'VBMemberList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      all_biz_count?: number | null;
      all_biz_ids?: string | null;
      all_biz_names?: string | null;
      attribute?: string | null;
      belong_biz_count?: number | null;
      birthday: string;
      btmem_number?: string | null;
      cancel_per: number;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      name?: string | null;
      nationality_id?: number | null;
      newly: number;
      phone?: string | null;
      rank_biz_count?: number | null;
      rank_biz_ids?: string | null;
      rank_biz_names?: string | null;
      rank_names?: string | null;
      residence?: number | null;
      score?: number | null;
      score_count?: number | null;
      state?: string | null;
      status: number;
      sub_names?: string | null;
      tp_biz_names?: string | null;
      tpmem_dates?: string | null;
      tpmem_names?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    }>;
    withhold: Array<{
      __typename: 'VBWithholdSlipList';
      btmem_id: number;
      corp_name?: string | null;
      corporate_no?: string | null;
      last_date?: string | null;
      last_day?: string | null;
      year_val?: number | null;
    }>;
  };
};

export type GetVbOfferAcceptInfoByAcceptIdQueryVariables = Exact<{
  accept_id: Scalars['Int']['input'];
}>;

export type GetVbOfferAcceptInfoByAcceptIdQuery = {
  __typename?: 'Query';
  getVBOfferAcceptInfoByAcceptId: {
    __typename: 'VBOfferAcceptInfo';
    accept_id: number;
    access?: string | null;
    attached_file?: string | null;
    attached_name?: string | null;
    auto_decide?: number | null;
    begin_end?: string | null;
    belongings?: string | null;
    break_time?: number | null;
    caution?: string | null;
    chief?: string | null;
    conditions?: string | null;
    description?: string | null;
    hour_wage?: number | null;
    image1?: string | null;
    image2?: string | null;
    image3?: string | null;
    image4?: string | null;
    image5?: string | null;
    name?: string | null;
    offer_id: number;
    offer_number?: string | null;
    tpmem_id: number;
    trans_fee?: string | null;
  };
};

export type GetVbOfferAcceptListByOfferIdQueryVariables = Exact<{
  b_offer_id: Scalars['Int']['input'];
}>;

export type GetVbOfferAcceptListByOfferIdQuery = {
  __typename?: 'Query';
  getVBOfferAcceptListByOfferId: Array<{
    __typename: 'VBOfferAcceptList';
    accept_id: number;
    b_offer_id: number;
    image?: string | null;
    name: string;
    offer_id: number;
    status: number;
    tpmem_id: number;
  }>;
};

export type GetVbOfferInfoByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVbOfferInfoByIdQuery = {
  __typename?: 'Query';
  getVBOfferInfoById: {
    __typename: 'VBOfferInfo';
    age?: number | null;
    attribute?: string | null;
    begin_end?: string | null;
    belong?: string | null;
    biz_name?: string | null;
    brand_name?: string | null;
    btmem_id: number;
    btmem_name?: string | null;
    btmem_state?: string | null;
    cancel_count?: number | null;
    cancel_per?: number | null;
    comment?: string | null;
    created_date?: string | null;
    gender?: string | null;
    hour_wage?: string | null;
    id: number;
    imminent_count?: number | null;
    imminent_per?: number | null;
    late_count?: number | null;
    late_per?: number | null;
    offer_no?: string | null;
    rank_biz_count?: number | null;
    rank_biz_names?: string | null;
    rank_names?: string | null;
    score?: number | null;
    state_city?: string | null;
    status: number;
    tp_num?: number | null;
    work_time?: string | null;
  };
};

export type GetVbOfferListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbOfferListQuery = {
  __typename?: 'Query';
  getVBOfferList: Array<{
    __typename: 'VBOfferList';
    begin_end?: string | null;
    biz_id?: number | null;
    biz_name?: string | null;
    brand_id?: number | null;
    brand_name?: string | null;
    btmem_id: number;
    btmem_name?: string | null;
    btmem_state?: string | null;
    created_date?: string | null;
    offer_id: number;
    offer_no?: string | null;
    state_city?: string | null;
    status: number;
    tp_num?: number | null;
    work_time?: string | null;
  }>;
};

export type GetVbOfferListAcceptByOfferIdQueryVariables = Exact<{
  offer_id: Scalars['Int']['input'];
}>;

export type GetVbOfferListAcceptByOfferIdQuery = {
  __typename?: 'Query';
  getVBOfferListAcceptByOfferId: {
    __typename: 'VBOfferListAccept';
    accept: Array<{
      __typename: 'VBOfferAcceptList';
      accept_id: number;
      b_offer_id: number;
      image?: string | null;
      name: string;
      offer_id: number;
      status: number;
      tpmem_id: number;
    }>;
    offer: {
      __typename: 'VBOfferList';
      begin_end?: string | null;
      biz_id?: number | null;
      biz_name?: string | null;
      brand_id?: number | null;
      brand_name?: string | null;
      btmem_id: number;
      btmem_name?: string | null;
      btmem_state?: string | null;
      created_date?: string | null;
      offer_id: number;
      offer_no?: string | null;
      state_city?: string | null;
      status: number;
      tp_num?: number | null;
      work_time?: string | null;
    };
  };
};

export type GetVbOfferListLoggingByOfferIdQueryVariables = Exact<{
  offer_id: Scalars['Int']['input'];
}>;

export type GetVbOfferListLoggingByOfferIdQuery = {
  __typename?: 'Query';
  getVBOfferListLoggingByOfferId: {
    __typename: 'VBOfferListLogging';
    logging: Array<{
      __typename: 'BLogging';
      btmem_id: number;
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
    }>;
    offer: {
      __typename: 'VBOfferList';
      begin_end?: string | null;
      biz_id?: number | null;
      biz_name?: string | null;
      brand_id?: number | null;
      brand_name?: string | null;
      btmem_id: number;
      btmem_name?: string | null;
      btmem_state?: string | null;
      created_date?: string | null;
      offer_id: number;
      offer_no?: string | null;
      state_city?: string | null;
      status: number;
      tp_num?: number | null;
      work_time?: string | null;
    };
  };
};

export type GetVbPayCsvAllListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbPayCsvAllListQuery = {
  __typename?: 'Query';
  getVBPayCsvAllList: {
    __typename: 'VBPayCsvAllListNewly';
    csv: Array<{
      __typename: 'VBPayCsvAllList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      pay_date?: string | null;
      unopened?: boolean | null;
    }>;
    newly: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number };
  };
};

export type GetVbReviewListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbReviewListQuery = {
  __typename?: 'Query';
  getVBReviewList?: Array<{
    __typename: 'VBReviewList';
    btmem_id: number;
    comment?: string | null;
    image?: string | null;
    name?: string | null;
    offer_id?: number | null;
    post_date?: string | null;
    post_date_2?: string | null;
    score?: number | null;
    tpmem_id?: number | null;
    tpmem_status?: number | null;
    work_id: number;
  } | null> | null;
};

export type GetVbReviewListScoreByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetVbReviewListScoreByBtmemIdQuery = {
  __typename?: 'Query';
  getVBReviewListScoreByBtmemId: {
    __typename: 'VBReviewListScore';
    info: {
      __typename: 'VBScorePercentInfo';
      btmem_id: number;
      identification: number;
      image?: string | null;
      name?: string | null;
      score?: number | null;
      score1_per?: number | null;
      score2_per?: number | null;
      score3_per?: number | null;
      score4_per?: number | null;
      score5_per?: number | null;
      score_count?: number | null;
      status: number;
    };
    list: Array<{
      __typename: 'VBReviewList';
      btmem_id: number;
      comment?: string | null;
      image?: string | null;
      name?: string | null;
      offer_id?: number | null;
      post_date?: string | null;
      post_date_2?: string | null;
      score?: number | null;
      tpmem_id?: number | null;
      tpmem_status?: number | null;
      work_id: number;
    }>;
  };
};

export type GetVbScorePercentInfoByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetVbScorePercentInfoByBtmemIdQuery = {
  __typename?: 'Query';
  getVBScorePercentInfoByBtmemId: {
    __typename: 'VBScorePercentInfo';
    btmem_id: number;
    identification: number;
    image?: string | null;
    name?: string | null;
    score?: number | null;
    score1_per?: number | null;
    score2_per?: number | null;
    score3_per?: number | null;
    score4_per?: number | null;
    score5_per?: number | null;
    score_count?: number | null;
    status: number;
  };
};

export type GetVbWithholdSlipListByBtmemIdQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
}>;

export type GetVbWithholdSlipListByBtmemIdQuery = {
  __typename?: 'Query';
  getVBWithholdSlipListByBtmemId?: Array<{
    __typename: 'VBWithholdSlipList';
    btmem_id: number;
    corp_name?: string | null;
    corporate_no?: string | null;
    last_date?: string | null;
    last_day?: string | null;
    year_val?: number | null;
  } | null> | null;
};

export type GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
  corporate_no: Scalars['String']['input'];
}>;

export type GetVbWithholdSlipListByBtmemIdCorporateNoQuery = {
  __typename?: 'Query';
  getVBWithholdSlipListByBtmemIdCorporateNo: {
    __typename: 'VBWithholdSlipListData';
    list?: Array<{
      __typename: 'VBWithholdSlipList';
      btmem_id: number;
      corp_name?: string | null;
      corporate_no?: string | null;
      last_date?: string | null;
      last_day?: string | null;
      year_val?: number | null;
    } | null> | null;
  };
};

export type GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables = Exact<{
  btmem_id: Scalars['Int']['input'];
  corporate_no: Scalars['String']['input'];
  gregorian_cal: Scalars['Int']['input'];
}>;

export type GetVbWithholdingSlipDataByGCcalBIdCNoQuery = {
  __typename?: 'Query';
  getVBWithholdingSlipDataByGCcalBIdCNo?: {
    __typename: 'VBWithholdingSlipData';
    btmem_address?: string | null;
    btmem_id?: number | null;
    btmem_kana?: string | null;
    btmem_name?: string | null;
    corp_address?: string | null;
    corp_name?: string | null;
    corp_phone?: string | null;
    corporate_no?: string | null;
    gregorian_cal?: number | null;
    japanese_cal?: number | null;
    salary?: number | null;
    tax_amount?: number | null;
  } | null;
};

export type GetVbWorkScoreListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVbWorkScoreListQuery = {
  __typename?: 'Query';
  getVBWorkScoreList: Array<{
    __typename: 'VBWorkScoreList';
    belong: number;
    biz_id: number;
    biz_name?: string | null;
    brand_id: number;
    brand_name?: string | null;
    bt_score?: number | null;
    btmem_name?: string | null;
    hour_wage?: string | null;
    offer_no?: string | null;
    rank_id?: number | null;
    rank_name?: string | null;
    tp_post_day?: string | null;
    tpmem_name?: string | null;
    trans_fee?: string | null;
    work_day?: string | null;
    work_id: number;
  }>;
};

export type GetVsLendStaffListByBizIdQueryVariables = Exact<{
  biz_id: Scalars['Int']['input'];
}>;

export type GetVsLendStaffListByBizIdQuery = {
  __typename?: 'Query';
  getVSLendStaffListByBizId: Array<{
    __typename: 'VSLendStaffList';
    biz_id: number;
    biz_name?: string | null;
    bt_rank_id: number;
    educ_default: number;
    educ_fee_max: number;
    educ_fee_min: number;
    educ_range?: string | null;
    rank_name?: string | null;
  }>;
};

export type GetVSelBlockListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVSelBlockListByIdQuery = {
  __typename?: 'Query';
  getVSelBlockListById?: Array<{
    __typename: 'VSelBlockList';
    address?: string | null;
    address2?: string | null;
    biz_id: number;
    blocked?: string | null;
    brand_id: number;
    city?: string | null;
    my_id: number;
    name?: string | null;
    state: string;
    tpmem_id: number;
    zip_code?: string | null;
  } | null> | null;
};

export type GetVSelBlockListByMyIdBizIdBrandIdQueryVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  brand_id: Scalars['Int']['input'];
  my_id: Scalars['Int']['input'];
}>;

export type GetVSelBlockListByMyIdBizIdBrandIdQuery = {
  __typename?: 'Query';
  getVSelBlockListByMyIdBizIdBrandId: Array<{
    __typename: 'VSelBlockList';
    address?: string | null;
    address2?: string | null;
    biz_id: number;
    blocked?: string | null;
    brand_id: number;
    city?: string | null;
    my_id: number;
    name?: string | null;
    state: string;
    tpmem_id: number;
    zip_code?: string | null;
  }>;
};

export type GetVtArbeitEntryListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtArbeitEntryListQuery = {
  __typename?: 'Query';
  getVTArbeitEntryList?: Array<{
    __typename: 'VTArbeitEntryList';
    btmem_id: number;
    btmem_number?: string | null;
    entry_id: number;
    entry_no?: string | null;
    image?: string | null;
    kind_name?: string | null;
    name?: string | null;
    offer_id: number;
    rank_name?: string | null;
    score?: number | null;
    status: number;
    total_hire_fee?: string | null;
    deadline_date?: string | null;
  } | null> | null;
};

export type GetVtArbeitEntryListWorkingByEntryIdQueryVariables = Exact<{
  entry_id: Scalars['Int']['input'];
}>;

export type GetVtArbeitEntryListWorkingByEntryIdQuery = {
  __typename?: 'Query';
  getVTArbeitEntryListWorkingByEntryId: {
    __typename: 'VTArbeitEntryListWorking';
    entry: {
      __typename: 'VTArbeitEntryList';
      btmem_id: number;
      btmem_number?: string | null;
      entry_id: number;
      entry_no?: string | null;
      image?: string | null;
      kind_name?: string | null;
      name?: string | null;
      offer_id: number;
      rank_name?: string | null;
      score?: number | null;
      status: number;
      total_hire_fee?: string | null;
      deadline_date?: string | null;
    };
    working: {
      __typename: 'VTWorkingList';
      begin_date?: string | null;
      begin_day?: string | null;
      begin_end?: string | null;
      biz_id: number;
      brand_id: number;
      btmem_id: number;
      btmem_name?: string | null;
      btmem_number?: string | null;
      end_date: string;
      entry_id: number;
      entry_number?: string | null;
      fix_checkin?: string | null;
      fix_checkout?: string | null;
      fix_count: string;
      image?: string | null;
      newly?: number | null;
      newly_review?: number | null;
      offer_id: number;
      offer_number?: string | null;
      qr_checkin?: string | null;
      qr_checkout?: string | null;
      status: number;
      time_left?: string | null;
      time_left_minute?: string | null;
      title?: string | null;
      tpmem_id: number;
      tpmem_name?: string | null;
      type: number;
      type_name?: string | null;
      work_id?: number | null;
    };
  };
};

export type GetVtArbeitOfferEntryListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtArbeitOfferEntryListByIdQuery = {
  __typename?: 'Query';
  getVTArbeitOfferEntryListById: {
    __typename: 'VTArbeitOfferEntry';
    entry: Array<{
      __typename: 'VTArbeitEntryList';
      btmem_id: number;
      btmem_number?: string | null;
      entry_id: number;
      entry_no?: string | null;
      image?: string | null;
      kind_name?: string | null;
      name?: string | null;
      offer_id: number;
      rank_name?: string | null;
      score?: number | null;
      status: number;
      total_hire_fee?: string | null;
      deadline_date?: string | null;
    }>;
    offer: {
      __typename: 'VTArbeitOfferList';
      auto_decide: boolean;
      begin_date?: string | null;
      begin_day?: string | null;
      end_date?: string | null;
      entry_count?: number | null;
      group_count?: number | null;
      hour_wage?: number | null;
      hour_wage_disp?: string | null;
      id: number;
      main_count?: number | null;
      name?: string | null;
      newly?: number | null;
      normal_count?: number | null;
      offer_number?: string | null;
      post_begin?: string | null;
      post_end?: string | null;
      status: number;
      sub_count?: number | null;
      title?: string | null;
      tpmem_id: number;
      trans_fee?: string | null;
    };
  };
};

export type GetVtArbeitOfferInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtArbeitOfferInfoQuery = {
  __typename?: 'Query';
  getVTArbeitOfferInfo?: Array<{
    __typename: 'VTArbeitOfferInfo';
    access?: string | null;
    attached_name?: string | null;
    attached_file?: string | null;
    auto_decide: number;
    begin_date?: string | null;
    begin_end?: string | null;
    belongings?: string | null;
    break_time?: number | null;
    bt_rank_id?: number | null;
    cancel_disp?: string | null;
    cancel_per?: number | null;
    caution?: string | null;
    chief?: string | null;
    conditions?: string | null;
    description?: string | null;
    end_date?: string | null;
    hire_fee_disp?: string | null;
    hire_fee_total?: number | null;
    hour_wage?: number | null;
    hour_wage_disp?: string | null;
    id: number;
    image1?: string | null;
    image2?: string | null;
    image3?: string | null;
    image4?: string | null;
    image5?: string | null;
    imminent_disp?: string | null;
    imminent_per?: number | null;
    name?: string | null;
    offer_number?: string | null;
    post_begin?: string | null;
    post_begin_date?: string | null;
    post_end?: string | null;
    post_end_date?: string | null;
    rank_name?: string | null;
    score?: number | null;
    score_disp?: string | null;
    title?: string | null;
    tpmem_id: number;
    trans_fee?: string | null;
    trans_fee_val?: number | null;
  } | null> | null;
};

export type GetVtArbeitOfferInfoByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtArbeitOfferInfoByIdQuery = {
  __typename?: 'Query';
  getVTArbeitOfferInfoById: {
    __typename: 'VTArbeitOfferInfoList';
    info: {
      __typename: 'VTArbeitOfferInfo';
      access?: string | null;
      attached_name?: string | null;
      attached_file?: string | null;
      auto_decide: number;
      begin_date?: string | null;
      begin_end?: string | null;
      belongings?: string | null;
      break_time?: number | null;
      bt_rank_id?: number | null;
      cancel_disp?: string | null;
      cancel_per?: number | null;
      caution?: string | null;
      chief?: string | null;
      conditions?: string | null;
      description?: string | null;
      end_date?: string | null;
      hire_fee_disp?: string | null;
      hire_fee_total?: number | null;
      hour_wage?: number | null;
      hour_wage_disp?: string | null;
      id: number;
      image1?: string | null;
      image2?: string | null;
      image3?: string | null;
      image4?: string | null;
      image5?: string | null;
      imminent_disp?: string | null;
      imminent_per?: number | null;
      name?: string | null;
      offer_number?: string | null;
      post_begin?: string | null;
      post_begin_date?: string | null;
      post_end?: string | null;
      post_end_date?: string | null;
      rank_name?: string | null;
      score?: number | null;
      score_disp?: string | null;
      title?: string | null;
      tpmem_id: number;
      trans_fee?: string | null;
      trans_fee_val?: number | null;
    };
    list: Array<{
      __typename: 'VTArbeitOfferList';
      auto_decide: boolean;
      begin_date?: string | null;
      begin_day?: string | null;
      end_date?: string | null;
      entry_count?: number | null;
      group_count?: number | null;
      hour_wage?: number | null;
      hour_wage_disp?: string | null;
      id: number;
      main_count?: number | null;
      name?: string | null;
      newly?: number | null;
      normal_count?: number | null;
      offer_number?: string | null;
      post_begin?: string | null;
      post_end?: string | null;
      status: number;
      sub_count?: number | null;
      title?: string | null;
      tpmem_id: number;
      trans_fee?: string | null;
    }>;
  };
};

export type GetVtArbeitOfferInfoWorkingByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtArbeitOfferInfoWorkingByIdQuery = {
  __typename?: 'Query';
  getVTArbeitOfferInfoWorkingById: {
    __typename: 'VTArbeitOfferInfoWorking';
    info: {
      __typename: 'VTArbeitOfferInfo';
      access?: string | null;
      attached_name?: string | null;
      attached_file?: string | null;
      auto_decide: number;
      begin_date?: string | null;
      begin_end?: string | null;
      belongings?: string | null;
      break_time?: number | null;
      bt_rank_id?: number | null;
      cancel_disp?: string | null;
      cancel_per?: number | null;
      caution?: string | null;
      chief?: string | null;
      conditions?: string | null;
      description?: string | null;
      end_date?: string | null;
      hire_fee_disp?: string | null;
      hire_fee_total?: number | null;
      hour_wage?: number | null;
      hour_wage_disp?: string | null;
      id: number;
      image1?: string | null;
      image2?: string | null;
      image3?: string | null;
      image4?: string | null;
      image5?: string | null;
      imminent_disp?: string | null;
      imminent_per?: number | null;
      name?: string | null;
      offer_number?: string | null;
      post_begin?: string | null;
      post_begin_date?: string | null;
      post_end?: string | null;
      post_end_date?: string | null;
      rank_name?: string | null;
      score?: number | null;
      score_disp?: string | null;
      title?: string | null;
      tpmem_id: number;
      trans_fee?: string | null;
      trans_fee_val?: number | null;
    };
    working: {
      __typename: 'VTWorkingList';
      begin_date?: string | null;
      begin_day?: string | null;
      begin_end?: string | null;
      biz_id: number;
      brand_id: number;
      btmem_id: number;
      btmem_name?: string | null;
      btmem_number?: string | null;
      end_date: string;
      entry_id: number;
      entry_number?: string | null;
      fix_checkin?: string | null;
      fix_checkout?: string | null;
      fix_count: string;
      image?: string | null;
      newly?: number | null;
      newly_review?: number | null;
      offer_id: number;
      offer_number?: string | null;
      qr_checkin?: string | null;
      qr_checkout?: string | null;
      status: number;
      time_left?: string | null;
      time_left_minute?: string | null;
      title?: string | null;
      tpmem_id: number;
      tpmem_name?: string | null;
      type: number;
      type_name?: string | null;
      work_id?: number | null;
    };
  };
};

export type GetVtArbeitOfferListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtArbeitOfferListQuery = {
  __typename?: 'Query';
  getVTArbeitOfferList: Array<{
    __typename: 'VTArbeitOfferList';
    auto_decide: boolean;
    begin_date?: string | null;
    begin_day?: string | null;
    end_date?: string | null;
    entry_count?: number | null;
    group_count?: number | null;
    hour_wage?: number | null;
    hour_wage_disp?: string | null;
    id: number;
    main_count?: number | null;
    name?: string | null;
    newly?: number | null;
    normal_count?: number | null;
    offer_number?: string | null;
    post_begin?: string | null;
    post_end?: string | null;
    status: number;
    sub_count?: number | null;
    title?: string | null;
    tpmem_id: number;
    trans_fee?: string | null;
  }>;
};

export type GetVtArbeitOfferLogging1ListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtArbeitOfferLogging1ListByIdQuery = {
  __typename?: 'Query';
  getVTArbeitOfferLogging1ListById: {
    __typename: 'VTArbeitOfferLogging';
    logging?: Array<{
      __typename: 'TLogging';
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
      tpmem_id: number;
    } | null> | null;
    offer?: {
      __typename: 'VTArbeitOfferList';
      auto_decide: boolean;
      begin_date?: string | null;
      begin_day?: string | null;
      end_date?: string | null;
      entry_count?: number | null;
      group_count?: number | null;
      hour_wage?: number | null;
      hour_wage_disp?: string | null;
      id: number;
      main_count?: number | null;
      name?: string | null;
      newly?: number | null;
      normal_count?: number | null;
      offer_number?: string | null;
      post_begin?: string | null;
      post_end?: string | null;
      status: number;
      sub_count?: number | null;
      title?: string | null;
      tpmem_id: number;
      trans_fee?: string | null;
    } | null;
  };
};

export type GetVtBalanceListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtBalanceListQuery = {
  __typename?: 'Query';
  getVTBalanceList: {
    __typename: 'VTBalanceListWalletNnewly';
    balance: Array<{
      __typename: 'VTBalanceList';
      area?: string | null;
      biz_id: number;
      biz_name?: string | null;
      brand_id: number;
      brand_name?: string | null;
      image?: string | null;
      mco_name?: string | null;
      name?: string | null;
      price: number;
      price_disp?: string | null;
      state?: string | null;
      status: number;
      tpmem_id: number;
    }>;
    newly: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number };
    wallet: { __typename: 'TWalletSUM'; price?: number | null; status?: number | null };
  };
};

export type GetVtBelongListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtBelongListQuery = {
  __typename?: 'Query';
  getVTBelongList?: Array<{
    __typename: 'VTBelongList';
    address?: string | null;
    address2?: string | null;
    age?: number | null;
    attribute?: string | null;
    begin_date?: string | null;
    birthday?: string | null;
    biz_id: number;
    btmem_number?: string | null;
    cancel_per?: number | null;
    city?: string | null;
    email?: string | null;
    gender?: string | null;
    id: number;
    identification: number;
    image?: string | null;
    kind: number;
    name?: string | null;
    phone?: string | null;
    rank_name?: string | null;
    score?: number | null;
    state?: string | null;
    status: number;
    sub_name?: string | null;
    tpmem_id?: number | null;
    tpmem_name?: string | null;
    uniform_size?: string | null;
    worked_count?: number | null;
    zip_code?: string | null;
  } | null> | null;
};

export type GetVtBelongListMemberByTpmemIdIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  tpmem_id: Scalars['Int']['input'];
}>;

export type GetVtBelongListMemberByTpmemIdIdQuery = {
  __typename?: 'Query';
  getVTBelongListMemberByTpmemIdId: {
    __typename: 'VTBelongListMember';
    belong: {
      __typename: 'VTBelongList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      attribute?: string | null;
      begin_date?: string | null;
      birthday?: string | null;
      biz_id: number;
      btmem_number?: string | null;
      cancel_per?: number | null;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      kind: number;
      name?: string | null;
      phone?: string | null;
      rank_name?: string | null;
      score?: number | null;
      state?: string | null;
      status: number;
      sub_name?: string | null;
      tpmem_id?: number | null;
      tpmem_name?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    };
    member: {
      __typename: 'VBMemberBase';
      account_name?: string | null;
      account_no?: string | null;
      account_type?: string | null;
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      attr_id?: number | null;
      attribute?: string | null;
      badge_name?: string | null;
      bank?: string | null;
      belong_biz_count?: number | null;
      birthday?: string | null;
      birthday2?: string | null;
      branch_name?: string | null;
      btmem_number?: string | null;
      cancel_count?: number | null;
      cancel_per?: number | null;
      city?: string | null;
      created_date?: string | null;
      email?: string | null;
      gender?: string | null;
      gender_value?: number | null;
      general_licenses?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      imminent_count?: number | null;
      imminent_per?: number | null;
      kana?: string | null;
      late_count?: number | null;
      late_per?: number | null;
      lc_biz_count?: number | null;
      lc_biz_names?: string | null;
      license_list?: string | null;
      name?: string | null;
      nationality?: string | null;
      pause_end_day?: string | null;
      period_date?: string | null;
      phone?: string | null;
      rank_biz_count?: number | null;
      rank_biz_names?: string | null;
      rank_names?: string | null;
      reg_biz_list?: string | null;
      residence_name?: string | null;
      score?: number | null;
      skip_count?: number | null;
      state?: string | null;
      state_count?: number | null;
      status: number;
      sub_names?: string | null;
      tp_biz_names?: string | null;
      tpmem_names?: string | null;
      uniform_id?: number | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    };
  };
};

export type GetVtBillCsvListNewlyBillStoreK1QueryVariables = Exact<{ [key: string]: never }>;

export type GetVtBillCsvListNewlyBillStoreK1Query = {
  __typename?: 'Query';
  getVTBillCsvListNewlyBillStoreK1: {
    __typename: 'VTBillCsvListNewlyBillStore';
    csvlist: Array<{
      __typename: 'VTBillCsvList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      kind: number;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      pay_date?: string | null;
      unopened?: number | null;
    }>;
    newly: { __typename: 'VUNewlyBillStore'; zp_pay: number; zw_pay: number };
  };
};

export type GetVtBillCsvListNewlyBillStoreK2QueryVariables = Exact<{ [key: string]: never }>;

export type GetVtBillCsvListNewlyBillStoreK2Query = {
  __typename?: 'Query';
  getVTBillCsvListNewlyBillStoreK2: {
    __typename: 'VTBillCsvListNewlyBillStore';
    csvlist: Array<{
      __typename: 'VTBillCsvList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      kind: number;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      pay_date?: string | null;
      unopened?: number | null;
    }>;
    newly: { __typename: 'VUNewlyBillStore'; zp_pay: number; zw_pay: number };
  };
};

export type GetVtBlockListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtBlockListQuery = {
  __typename?: 'Query';
  getVTBlockList?: Array<{
    __typename: 'VTBlockList';
    block_id: number;
    block_name?: string | null;
    image?: string | null;
    name?: string | null;
    reason?: string | null;
    reg_date?: string | null;
    status: number;
    tpmem_id: number;
  } | null> | null;
};

export type GetVtBlockListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtBlockListByIdQuery = {
  __typename?: 'Query';
  getVTBlockListById?: Array<{
    __typename: 'VTBlockList';
    block_id: number;
    block_name?: string | null;
    image?: string | null;
    name?: string | null;
    reason?: string | null;
    reg_date?: string | null;
    status: number;
    tpmem_id: number;
  } | null> | null;
};

export type GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables = Exact<{
  brand_ids: Scalars['String']['input'];
  state_ids: Scalars['String']['input'];
}>;

export type GetVtBrandStateInfoCountByStateIdBrandIdQuery = {
  __typename?: 'Query';
  getVTBrandStateInfoCountByStateIdBrandId: { __typename: 'VTBrandStateInfoCount'; id_count: number };
};

export type GetVtCpFreeListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtCpFreeListQuery = {
  __typename?: 'Query';
  getVTCpFreeList: Array<{
    __typename: 'VTCpFreeList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    end_date?: string | null;
    free_months?: number | null;
    free_months_disp?: string | null;
    id: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    title?: string | null;
  }>;
};

export type GetVtCpTicketListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtCpTicketListQuery = {
  __typename?: 'Query';
  getVTCpTicketList?: Array<{
    __typename: 'VTCpTicketList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    end_date?: string | null;
    give_num?: number | null;
    id: number;
    kind: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    ticket_num?: number | null;
    ticket_num_disp?: string | null;
    title?: string | null;
    valid_days?: number | null;
    valid_days_disp?: string | null;
  } | null> | null;
};

export type GetVtCpTicketListByKind2QueryVariables = Exact<{ [key: string]: never }>;

export type GetVtCpTicketListByKind2Query = {
  __typename?: 'Query';
  getVTCpTicketListByKind2: Array<{
    __typename: 'VTCpTicketList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    end_date?: string | null;
    give_num?: number | null;
    id: number;
    kind: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    ticket_num?: number | null;
    ticket_num_disp?: string | null;
    title?: string | null;
    valid_days?: number | null;
    valid_days_disp?: string | null;
  }>;
};

export type GetVtCpTicketListByKind3QueryVariables = Exact<{ [key: string]: never }>;

export type GetVtCpTicketListByKind3Query = {
  __typename?: 'Query';
  getVTCpTicketListByKind3: Array<{
    __typename: 'VTCpTicketList';
    begin_date?: string | null;
    begin_end?: string | null;
    biz_count?: number | null;
    biz_ids?: string | null;
    biz_names?: string | null;
    brand_ids?: string | null;
    brand_names?: string | null;
    created_date?: string | null;
    end_date?: string | null;
    give_num?: number | null;
    id: number;
    kind: number;
    state_ids?: string | null;
    state_names?: string | null;
    status: number;
    ticket_num?: number | null;
    ticket_num_disp?: string | null;
    title?: string | null;
    valid_days?: number | null;
    valid_days_disp?: string | null;
  }>;
};

export type GetVtGroupListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtGroupListQuery = {
  __typename?: 'Query';
  getVTGroupList?: Array<{
    __typename: 'VTGroupList';
    accept_date?: string | null;
    group_id: number;
    image?: string | null;
    my_id: number;
    name?: string | null;
    status: number;
    tpmem_id: number;
  } | null> | null;
};

export type GetVtGroupListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtGroupListByIdQuery = {
  __typename?: 'Query';
  getVTGroupListById?: Array<{
    __typename: 'VTGroupList';
    accept_date?: string | null;
    group_id: number;
    image?: string | null;
    my_id: number;
    name?: string | null;
    status: number;
    tpmem_id: number;
  } | null> | null;
};

export type GetVtInvitedGroupInfoByTpmemIdQueryVariables = Exact<{
  tpmem_id: Scalars['Int']['input'];
}>;

export type GetVtInvitedGroupInfoByTpmemIdQuery = {
  __typename?: 'Query';
  getVTInvitedGroupInfoByTpmemId?: {
    __typename: 'VTInvitedGroupInfo';
    address?: string | null;
    address2?: string | null;
    city?: string | null;
    corporate_no?: string | null;
    id?: number | null;
    image?: string | null;
    invite_id?: number | null;
    mco_address?: string | null;
    mco_address2?: string | null;
    mco_city?: string | null;
    mco_m_kana?: string | null;
    mco_m_name?: string | null;
    mco_name?: string | null;
    mco_phone?: string | null;
    mco_state?: string | null;
    mco_t_kana?: string | null;
    mco_t_name?: string | null;
    mco_t_phone?: string | null;
    mco_zip_code?: string | null;
    mgr_name?: string | null;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    tpmem_id?: number | null;
    zip_code?: string | null;
  } | null;
};

export type GetVtmPayCsvListNewlyPayRefundQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtmPayCsvListNewlyPayRefundQuery = {
  __typename?: 'Query';
  getVTMPayCsvListNewlyPayRefund: {
    __typename: 'VTMPayCsvListNewlyPayRefund';
    csvlist: Array<{
      __typename: 'VTMPayCsvList';
      csv_no: number;
      csv_url?: string | null;
      dl_count?: number | null;
      dl_date?: string | null;
      name?: string | null;
      output_date?: string | null;
      output_number?: string | null;
      pay_date?: string | null;
      unopened?: number | null;
    }>;
    newly: { __typename: 'VUNewlyPayRefund'; re_pay: number };
  };
};

export type GetVtMemberBaseQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtMemberBaseQuery = {
  __typename?: 'Query';
  getVTMemberBase?: Array<{
    __typename: 'VTMemberBase';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    address?: string | null;
    address2?: string | null;
    again?: string | null;
    bank?: string | null;
    belong_total?: number | null;
    biz_id: number;
    biz_name?: string | null;
    block_num?: number | null;
    branch_name?: string | null;
    brand_id: number;
    brand_name?: string | null;
    city?: string | null;
    close_time?: string | null;
    code?: string | null;
    corporate_no?: string | null;
    corporate_type?: number | null;
    created_date?: string | null;
    email?: string | null;
    favorite_num?: number | null;
    group_num: number;
    id: number;
    image?: string | null;
    invite_code?: string | null;
    join_date?: string | null;
    manager?: string | null;
    mco_address?: string | null;
    mco_address2?: string | null;
    mco_city?: string | null;
    mco_m_first_kana?: string | null;
    mco_m_first_name?: string | null;
    mco_m_kana?: string | null;
    mco_m_last_kana?: string | null;
    mco_m_last_name?: string | null;
    mco_m_name?: string | null;
    mco_name?: string | null;
    mco_phone?: string | null;
    mco_state?: string | null;
    mco_t_first_kana?: string | null;
    mco_t_first_name?: string | null;
    mco_t_kana?: string | null;
    mco_t_last_kana?: string | null;
    mco_t_last_name?: string | null;
    mco_t_name?: string | null;
    mco_t_phone?: string | null;
    mco_zip_code?: string | null;
    mgr_birthday?: string | null;
    mgr_dob_day?: number | null;
    mgr_dob_month?: number | null;
    mgr_dob_year?: number | null;
    mgr_first_kana?: string | null;
    mgr_first_name?: string | null;
    mgr_id?: number | null;
    mgr_last_kana?: string | null;
    mgr_last_name?: string | null;
    mgr_phone?: string | null;
    open_time?: string | null;
    opening?: string | null;
    parking?: string | null;
    parking_val?: boolean | null;
    pause_date?: string | null;
    payment_method?: number | null;
    personal_bank?: string | null;
    phone?: string | null;
    quit_date?: string | null;
    restart_date?: string | null;
    state?: string | null;
    status: number;
    store_name?: string | null;
    ticket_num?: number | null;
    total_adopt?: string | null;
    zip_code?: string | null;
    lat?: number | null;
    lng?: number | null;
  } | null> | null;
};

export type GetVtMemberBaseByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberBaseByIdQuery = {
  __typename?: 'Query';
  getVTMemberBaseById: {
    __typename: 'VTMemberBase';
    account_name?: string | null;
    account_no?: string | null;
    account_type?: string | null;
    address?: string | null;
    address2?: string | null;
    again?: string | null;
    bank?: string | null;
    belong_total?: number | null;
    biz_id: number;
    biz_name?: string | null;
    block_num?: number | null;
    branch_name?: string | null;
    brand_id: number;
    brand_name?: string | null;
    city?: string | null;
    close_time?: string | null;
    code?: string | null;
    corporate_no?: string | null;
    corporate_type?: number | null;
    created_date?: string | null;
    email?: string | null;
    favorite_num?: number | null;
    group_num: number;
    id: number;
    image?: string | null;
    invite_code?: string | null;
    join_date?: string | null;
    manager?: string | null;
    mco_address?: string | null;
    mco_address2?: string | null;
    mco_city?: string | null;
    mco_m_first_kana?: string | null;
    mco_m_first_name?: string | null;
    mco_m_kana?: string | null;
    mco_m_last_kana?: string | null;
    mco_m_last_name?: string | null;
    mco_m_name?: string | null;
    mco_name?: string | null;
    mco_phone?: string | null;
    mco_state?: string | null;
    mco_t_first_kana?: string | null;
    mco_t_first_name?: string | null;
    mco_t_kana?: string | null;
    mco_t_last_kana?: string | null;
    mco_t_last_name?: string | null;
    mco_t_name?: string | null;
    mco_t_phone?: string | null;
    mco_zip_code?: string | null;
    mgr_birthday?: string | null;
    mgr_dob_day?: number | null;
    mgr_dob_month?: number | null;
    mgr_dob_year?: number | null;
    mgr_first_kana?: string | null;
    mgr_first_name?: string | null;
    mgr_id?: number | null;
    mgr_last_kana?: string | null;
    mgr_last_name?: string | null;
    mgr_phone?: string | null;
    open_time?: string | null;
    opening?: string | null;
    parking?: string | null;
    parking_val?: boolean | null;
    pause_date?: string | null;
    payment_method?: number | null;
    personal_bank?: string | null;
    phone?: string | null;
    quit_date?: string | null;
    restart_date?: string | null;
    state?: string | null;
    status: number;
    store_name?: string | null;
    ticket_num?: number | null;
    total_adopt?: string | null;
    zip_code?: string | null;
    lat?: number | null;
    lng?: number | null;
  };
};

export type GetVtMemberBelongListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberBelongListByIdQuery = {
  __typename?: 'Query';
  getVTMemberBelongListById: {
    __typename: 'VTMemberBelong';
    belong: Array<{
      __typename: 'VTBelongList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      attribute?: string | null;
      begin_date?: string | null;
      birthday?: string | null;
      biz_id: number;
      btmem_number?: string | null;
      cancel_per?: number | null;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      kind: number;
      name?: string | null;
      phone?: string | null;
      rank_name?: string | null;
      score?: number | null;
      state?: string | null;
      status: number;
      sub_name?: string | null;
      tpmem_id?: number | null;
      tpmem_name?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    }>;
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
    sub: Array<{
      __typename: 'VTBelongList';
      address?: string | null;
      address2?: string | null;
      age?: number | null;
      attribute?: string | null;
      begin_date?: string | null;
      birthday?: string | null;
      biz_id: number;
      btmem_number?: string | null;
      cancel_per?: number | null;
      city?: string | null;
      email?: string | null;
      gender?: string | null;
      id: number;
      identification: number;
      image?: string | null;
      kind: number;
      name?: string | null;
      phone?: string | null;
      rank_name?: string | null;
      score?: number | null;
      state?: string | null;
      status: number;
      sub_name?: string | null;
      tpmem_id?: number | null;
      tpmem_name?: string | null;
      uniform_size?: string | null;
      worked_count?: number | null;
      zip_code?: string | null;
    }>;
  };
};

export type GetVtMemberBlockListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberBlockListByIdQuery = {
  __typename?: 'Query';
  getVTMemberBlockListById: {
    __typename: 'VTMemberBlock';
    block: Array<{
      __typename: 'VTBlockList';
      block_id: number;
      block_name?: string | null;
      image?: string | null;
      name?: string | null;
      reason?: string | null;
      reg_date?: string | null;
      status: number;
      tpmem_id: number;
    }>;
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
  };
};

export type GetVtMemberGroupListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberGroupListByIdQuery = {
  __typename?: 'Query';
  getVTMemberGroupListById: {
    __typename: 'VTMemberGroup';
    group: Array<{
      __typename: 'VTGroupList';
      accept_date?: string | null;
      group_id: number;
      image?: string | null;
      my_id: number;
      name?: string | null;
      status: number;
      tpmem_id: number;
    }>;
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
  };
};

export type GetVtMemberListQueryVariables = Exact<{
  status?: InputMaybe<Scalars['Int']['input']>;
  code_name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type GetVtMemberListQuery = {
  __typename?: 'Query';
  getVTMemberList: {
    __typename: 'VTMemberListCount';
    count?: number | null;
    list?: Array<{
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    } | null> | null;
  };
};

export type GetVtMemberListWithOrderQueryVariables = Exact<{
  status?: InputMaybe<Scalars['Int']['input']>;
  code_name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  biz_id?: InputMaybe<Scalars['Int']['input']>;
  brand_id?: InputMaybe<Scalars['Int']['input']>;
  payment_method?: InputMaybe<Scalars['Int']['input']>;
  order_name?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;

export type GetVtMemberListWithOrderQuery = {
  __typename?: 'Query';
  getVTMemberListWithOrder: {
    __typename: 'VTMemberListCount';
    count?: number | null;
    list?: Array<{
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    } | null> | null;
  };
};

export type GetVtMemberList2QueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetVtMemberList2Query = {
  __typename?: 'Query';
  getVTMemberList2: {
    __typename: 'VTMemberListCount';
    count?: number | null;
    list?: Array<{
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    } | null> | null;
  };
};

export type GetVtMemberListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberListByIdQuery = {
  __typename?: 'Query';
  getVTMemberListById?: {
    __typename: 'VTMemberList';
    address?: string | null;
    address2?: string | null;
    again?: string | null;
    again_num?: number | null;
    area_name?: string | null;
    belong_count?: string | null;
    belong_num?: number | null;
    belong_total?: number | null;
    biz_id: number;
    biz_name?: string | null;
    block_count?: string | null;
    block_num?: number | null;
    brand_id: number;
    brand_name?: string | null;
    city?: string | null;
    code?: string | null;
    email?: string | null;
    group_num?: number | null;
    id: number;
    image?: string | null;
    manager?: string | null;
    mco_name?: string | null;
    mgr_id?: number | null;
    newly?: number | null;
    opening?: string | null;
    other_adopt?: string | null;
    parking?: string | null;
    payment_method?: number | null;
    phone?: string | null;
    score?: string | null;
    score_num?: number | null;
    score_value?: number | null;
    state?: string | null;
    status: number;
    store_name?: string | null;
    sub_count?: string | null;
    sub_num?: number | null;
    ticket_num?: number | null;
    total_adopt?: string | null;
    unclaimed?: string | null;
    zip_code?: string | null;
  } | null;
};

export type GetVtMemberLogging3ListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberLogging3ListByIdQuery = {
  __typename?: 'Query';
  getVTMemberLogging3ListById: {
    __typename: 'VTMemberLogging';
    logging: Array<{
      __typename: 'TLogging';
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
      tpmem_id: number;
    }>;
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
  };
};

export type GetVtMemberLogging5ListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberLogging5ListByIdQuery = {
  __typename?: 'Query';
  getVTMemberLogging5ListById: {
    __typename: 'VTMemberLogging';
    logging: Array<{
      __typename: 'TLogging';
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
      tpmem_id: number;
    }>;
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
  };
};

export type GetVtMemberTicketListByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVtMemberTicketListByIdQuery = {
  __typename?: 'Query';
  getVTMemberTicketListById: {
    __typename: 'VTMemberTicket';
    member: {
      __typename: 'VTMemberList';
      address?: string | null;
      address2?: string | null;
      again?: string | null;
      again_num?: number | null;
      area_name?: string | null;
      belong_count?: string | null;
      belong_num?: number | null;
      belong_total?: number | null;
      biz_id: number;
      biz_name?: string | null;
      block_count?: string | null;
      block_num?: number | null;
      brand_id: number;
      brand_name?: string | null;
      city?: string | null;
      code?: string | null;
      email?: string | null;
      group_num?: number | null;
      id: number;
      image?: string | null;
      manager?: string | null;
      mco_name?: string | null;
      mgr_id?: number | null;
      newly?: number | null;
      opening?: string | null;
      other_adopt?: string | null;
      parking?: string | null;
      payment_method?: number | null;
      phone?: string | null;
      score?: string | null;
      score_num?: number | null;
      score_value?: number | null;
      state?: string | null;
      status: number;
      store_name?: string | null;
      sub_count?: string | null;
      sub_num?: number | null;
      ticket_num?: number | null;
      total_adopt?: string | null;
      unclaimed?: string | null;
      zip_code?: string | null;
    };
    ticket: Array<{
      __typename: 'VTTicketList';
      award_date?: string | null;
      enabled_count?: number | null;
      end_date?: string | null;
      id: number;
      item?: string | null;
      timeup?: number | null;
      tpmem_id: number;
      umgmt_name?: string | null;
      valid_days?: number | null;
    }>;
  };
};

export type GetVtReviewListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtReviewListQuery = {
  __typename?: 'Query';
  getVTReviewList?: Array<{
    __typename: 'VTReviewList';
    anonymous?: string | null;
    btmem_id?: number | null;
    comment?: string | null;
    image?: string | null;
    name?: string | null;
    offer_id?: number | null;
    post_date?: string | null;
    post_date_2?: string | null;
    score?: number | null;
    tpmem_id: number;
    work_id: number;
  } | null> | null;
};

export type GetVtReviewListByTpmemIdQueryVariables = Exact<{
  tpmem_id: Scalars['Int']['input'];
}>;

export type GetVtReviewListByTpmemIdQuery = {
  __typename?: 'Query';
  getVTReviewListByTpmemId?: Array<{
    __typename: 'VTReviewList';
    anonymous?: string | null;
    btmem_id?: number | null;
    comment?: string | null;
    image?: string | null;
    name?: string | null;
    offer_id?: number | null;
    post_date?: string | null;
    post_date_2?: string | null;
    score?: number | null;
    tpmem_id: number;
    work_id: number;
  } | null> | null;
};

export type GetVtScoreListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtScoreListQuery = {
  __typename?: 'Query';
  getVTScoreList: Array<{
    __typename: 'VTScoreList';
    address?: string | null;
    address2?: string | null;
    biz_id: number;
    biz_name?: string | null;
    brand_id: number;
    brand_name?: string | null;
    bt_post_day?: string | null;
    btmem_name?: string | null;
    city?: string | null;
    hour_wage?: string | null;
    offer_no?: string | null;
    state?: string | null;
    tp_score?: number | null;
    tpmem_name?: string | null;
    trans_fee?: string | null;
    work_day?: string | null;
    work_id: number;
    zip_code?: string | null;
  }>;
};

export type GetVtScorePercentInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtScorePercentInfoQuery = {
  __typename?: 'Query';
  getVTScorePercentInfo?: Array<{
    __typename: 'VTScorePercentInfo';
    again_num?: number | null;
    belong_total?: number | null;
    block_num?: number | null;
    group_num?: number | null;
    image?: string | null;
    score1_per?: number | null;
    score2_per?: number | null;
    score3_per?: number | null;
    score4_per?: number | null;
    score5_per?: number | null;
    score_num?: number | null;
    score_value?: number | null;
    status?: number | null;
    store_name?: string | null;
    tpmem_id: number;
  } | null> | null;
};

export type GetVtScorePercentInfoByTpmemIdQueryVariables = Exact<{
  tpmem_id: Scalars['Int']['input'];
}>;

export type GetVtScorePercentInfoByTpmemIdQuery = {
  __typename?: 'Query';
  getVTScorePercentInfoByTpmemId: {
    __typename: 'VTScorePercentInfoList';
    info: {
      __typename: 'VTScorePercentInfo';
      again_num?: number | null;
      belong_total?: number | null;
      block_num?: number | null;
      group_num?: number | null;
      image?: string | null;
      score1_per?: number | null;
      score2_per?: number | null;
      score3_per?: number | null;
      score4_per?: number | null;
      score5_per?: number | null;
      score_num?: number | null;
      score_value?: number | null;
      status?: number | null;
      store_name?: string | null;
      tpmem_id: number;
    };
    list: Array<{
      __typename: 'VTReviewList';
      anonymous?: string | null;
      btmem_id?: number | null;
      comment?: string | null;
      image?: string | null;
      name?: string | null;
      offer_id?: number | null;
      post_date?: string | null;
      post_date_2?: string | null;
      score?: number | null;
      tpmem_id: number;
      work_id: number;
    }>;
  };
};

export type GetVtTicketListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtTicketListQuery = {
  __typename?: 'Query';
  getVTTicketList?: Array<{
    __typename: 'VTTicketList';
    award_date?: string | null;
    enabled_count?: number | null;
    end_date?: string | null;
    id: number;
    item?: string | null;
    timeup?: number | null;
    tpmem_id: number;
    umgmt_name?: string | null;
    valid_days?: number | null;
  } | null> | null;
};

export type GetVtTicketMgtInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtTicketMgtInfoQuery = {
  __typename?: 'Query';
  getVTTicketMgtInfo?: Array<{
    __typename: 'VTTicketMgtInfo';
    expired_count: string;
    expired_price: string;
    issue_count: string;
    issue_price: string;
  } | null> | null;
};

export type GetVtTransProcListNewlyQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtTransProcListNewlyQuery = {
  __typename?: 'Query';
  getVTTransProcListNewly: {
    __typename: 'VTTransProcListNewly';
    newly: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number };
    trans: Array<{
      __typename: 'VTTransProcList';
      account_name?: string | null;
      account_no?: string | null;
      account_type?: string | null;
      bank_name?: string | null;
      branch_name?: string | null;
      created_date?: string | null;
      name?: string | null;
      status: number;
      tpmem_id: number;
      tpmem_name?: string | null;
      tr_proc_date?: string | null;
      tr_proc_type?: string | null;
    }>;
  };
};

export type GetVtWorkScoreInfoByWorkIdQueryVariables = Exact<{
  work_id: Scalars['Int']['input'];
}>;

export type GetVtWorkScoreInfoByWorkIdQuery = {
  __typename?: 'Query';
  getVTWorkScoreInfoByWorkId?: {
    __typename: 'VTWorkScoreInfo';
    again?: boolean | null;
    bt_comment?: string | null;
    bt_post_date?: string | null;
    bt_score?: number | null;
    btmem_id?: number | null;
    btmem_name?: string | null;
    tp_comment?: string | null;
    tp_post_date?: string | null;
    tp_score?: number | null;
    tpmem_id?: number | null;
    tpmem_name?: string | null;
    work_id?: number | null;
  } | null;
};

export type GetVtWorkingListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVtWorkingListQuery = {
  __typename?: 'Query';
  getVTWorkingList: Array<{
    __typename: 'VTWorkingList';
    begin_date?: string | null;
    begin_day?: string | null;
    begin_end?: string | null;
    biz_id: number;
    brand_id: number;
    btmem_id: number;
    btmem_name?: string | null;
    btmem_number?: string | null;
    end_date: string;
    entry_id: number;
    entry_number?: string | null;
    fix_checkin?: string | null;
    fix_checkout?: string | null;
    fix_count: string;
    image?: string | null;
    newly?: number | null;
    newly_review?: number | null;
    offer_id: number;
    offer_number?: string | null;
    qr_checkin?: string | null;
    qr_checkout?: string | null;
    status: number;
    time_left?: string | null;
    time_left_minute?: string | null;
    title?: string | null;
    tpmem_id: number;
    tpmem_name?: string | null;
    type: number;
    type_name?: string | null;
    work_id?: number | null;
  }>;
};

export type GetVtWorkingLogging2ListByOfferIdQueryVariables = Exact<{
  offer_id: Scalars['Int']['input'];
}>;

export type GetVtWorkingLogging2ListByOfferIdQuery = {
  __typename?: 'Query';
  getVTWorkingLogging2ListByOfferId: {
    __typename: 'VTWorkingLogging';
    logging: Array<{
      __typename: 'TLogging';
      id: number;
      kind: number;
      log_date?: string | null;
      matter?: string | null;
      ref_no?: number | null;
      tpmem_id: number;
    }>;
    working: {
      __typename: 'VTWorkingList';
      begin_date?: string | null;
      begin_day?: string | null;
      begin_end?: string | null;
      biz_id: number;
      brand_id: number;
      btmem_id: number;
      btmem_name?: string | null;
      btmem_number?: string | null;
      end_date: string;
      entry_id: number;
      entry_number?: string | null;
      fix_checkin?: string | null;
      fix_checkout?: string | null;
      fix_count: string;
      image?: string | null;
      newly?: number | null;
      newly_review?: number | null;
      offer_id: number;
      offer_number?: string | null;
      qr_checkin?: string | null;
      qr_checkout?: string | null;
      status: number;
      time_left?: string | null;
      time_left_minute?: string | null;
      title?: string | null;
      tpmem_id: number;
      tpmem_name?: string | null;
      type: number;
      type_name?: string | null;
      work_id?: number | null;
    };
  };
};

export type GetVuMemberListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVuMemberListQuery = {
  __typename?: 'Query';
  getVUMemberList: Array<{
    __typename: 'VUMemberList';
    email: string;
    id: number;
    name: string;
    privilege: number;
    privilege_name?: string | null;
  }>;
};

export type GetVuNewlyMenuAllQueryVariables = Exact<{ [key: string]: never }>;

export type GetVuNewlyMenuAllQuery = {
  __typename?: 'Query';
  getVUNewlyMenuAll?: {
    __typename: 'VUNewlyMenuAll';
    accounting?: { __typename: 'VUNewlyAccounting'; mn_all: number; trans_status: number } | null;
    arbeit?: { __typename: 'VUNewlyPayArbeit'; im_inv: number; im_pay: number; mn_pay: number } | null;
    home?: {
      __typename: 'VUNewlyHome';
      b_badge: number;
      b_identfy: number;
      b_join: number;
      b_pay: number;
      b_quit: number;
      t_change: number;
      t_pay: number;
      t_quit: number;
      t_regist: number;
    } | null;
    menu?: {
      __typename: 'VUNewlyMenu';
      baitra?: number | null;
      bill_tp?: number | null;
      billing?: number | null;
      bt_identity?: number | null;
      bt_info?: number | null;
      home?: number | null;
      pay_back?: number | null;
      pay_work?: number | null;
      payment?: number | null;
      set_account?: number | null;
      settings?: number | null;
      tenpo?: number | null;
      tp_info?: number | null;
    } | null;
    refund?: { __typename: 'VUNewlyPayRefund'; re_pay: number } | null;
    store?: { __typename: 'VUNewlyBillStore'; zp_pay: number; zw_pay: number } | null;
  } | null;
};

export type GetVuSendNotifyInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetVuSendNotifyInfoQuery = {
  __typename?: 'Query';
  getVUSendNotifyInfo?: Array<{
    __typename: 'VUSendNotifyInfo';
    biz_id?: number | null;
    body?: string | null;
    brand_id?: number | null;
    brand_name?: string | null;
    do_day?: string | null;
    do_time?: string | null;
    id: number;
    status: number;
    sys_id?: number | null;
    title?: string | null;
    to_kind_name?: string | null;
  } | null> | null;
};

export type GetVuSendNotifyInfoByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetVuSendNotifyInfoByIdQuery = {
  __typename?: 'Query';
  getVUSendNotifyInfoById: {
    __typename: 'VUSendNotifyInfo';
    biz_id?: number | null;
    body?: string | null;
    brand_id?: number | null;
    brand_name?: string | null;
    do_day?: string | null;
    do_time?: string | null;
    id: number;
    status: number;
    sys_id?: number | null;
    title?: string | null;
    to_kind_name?: string | null;
  };
};

export type GetVuSendNotifyListQueryVariables = Exact<{ [key: string]: never }>;

export type GetVuSendNotifyListQuery = {
  __typename?: 'Query';
  getVUSendNotifyList: Array<{
    __typename: 'VUSendNotifyList';
    brand_name?: string | null;
    created_date?: string | null;
    id: number;
    send_date?: string | null;
    send_day?: string | null;
    status: number;
    title?: string | null;
    to_kind_name?: string | null;
  }>;
};

export type GetVWorkScoreInfoByWorkIdQueryVariables = Exact<{
  work_id: Scalars['Int']['input'];
}>;

export type GetVWorkScoreInfoByWorkIdQuery = {
  __typename?: 'Query';
  getVWorkScoreInfoByWorkId: {
    __typename: 'VWorkScoreInfo';
    again?: boolean | null;
    bt_comment?: string | null;
    bt_post_day?: string | null;
    bt_score?: number | null;
    btmem_id?: number | null;
    btmem_image?: string | null;
    btmem_name?: string | null;
    offer_id: number;
    offer_no: string;
    tp_comment?: string | null;
    tp_post_day?: string | null;
    tp_score?: number | null;
    tpmem_id?: number | null;
    tpmem_image?: string | null;
    tpmem_name?: string | null;
    work_id: number;
  };
};

export type IsSBenefitsSCampaignQueryVariables = Exact<{
  egift_code: Scalars['Int']['input'];
}>;

export type IsSBenefitsSCampaignQuery = {
  __typename?: 'Query';
  isSBenefitsSCampaign: { __typename: 'boolResult'; bResult: boolean };
};

export type AddMBrandByBizIdMutationVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  brands: Array<InputMaybe<InMBrand>> | InputMaybe<InMBrand>;
}>;

export type AddMBrandByBizIdMutation = {
  __typename?: 'Mutation';
  addMBrandByBizId: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type AddMBusinessDataMutationVariables = Exact<{
  s3_bucket?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  brands?: InputMaybe<Array<InputMaybe<InMBrand>> | InputMaybe<InMBrand>>;
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>> | InputMaybe<InMLicense>>;
  section_filepath?: InputMaybe<Scalars['String']['input']>;
  checklist_filepath?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddMBusinessDataMutation = {
  __typename?: 'Mutation';
  addMBusinessData: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type UpdateMBusinessIconByIdMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  icon: Scalars['String']['input'];
}>;

export type UpdateMBusinessIconByIdMutation = {
  __typename?: 'Mutation';
  updateMBusinessIconById: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type AddMLicenseByBizIdMutationVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>> | InputMaybe<InMLicense>>;
}>;

export type AddMLicenseByBizIdMutation = {
  __typename?: 'Mutation';
  addMLicenseByBizId: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type DelInsMLicenseByBizIdMutationVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  licenses?: InputMaybe<Array<InputMaybe<InMLicense>> | InputMaybe<InMLicense>>;
}>;

export type DelInsMLicenseByBizIdMutation = {
  __typename?: 'Mutation';
  delInsMLicenseByBizId: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type DelInsMSkillCheckByBizIdMutationVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  s3_bucket?: InputMaybe<Scalars['String']['input']>;
  section_filepath?: InputMaybe<Scalars['String']['input']>;
  checklist_filepath?: InputMaybe<Scalars['String']['input']>;
}>;

export type DelInsMSkillCheckByBizIdMutation = {
  __typename?: 'Mutation';
  delInsMSkillCheckByBizId: { __typename: 'MutateResult'; ErrorCode?: number | null; resultRows: number };
};

export type GetMSystemTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMSystemTypeQuery = {
  __typename?: 'Query';
  getMSystemType?: Array<{ __typename: 'MSystemType'; id: number; name: string } | null> | null;
};

export type GetMSystemTypeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMSystemTypeByIdQuery = {
  __typename?: 'Query';
  getMSystemTypeById?: { __typename: 'MSystemType'; id: number; name: string } | null;
};

export type GetMNotifyTplQueryVariables = Exact<{ [key: string]: never }>;

export type GetMNotifyTplQuery = {
  __typename?: 'Query';
  getMNotifyTpl?: Array<{
    __typename: 'MNotifyTpl';
    id?: number | null;
    created_date?: string | null;
    sys_id?: number | null;
    biz_id?: number | null;
    brand_id?: number | null;
    title?: string | null;
    body?: string | null;
  } | null> | null;
};

export type GetMNotifyTplByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMNotifyTplByIdQuery = {
  __typename?: 'Query';
  getMNotifyTplById?: {
    __typename: 'MNotifyTpl';
    id?: number | null;
    created_date?: string | null;
    sys_id?: number | null;
    biz_id?: number | null;
    brand_id?: number | null;
    title?: string | null;
    body?: string | null;
  } | null;
};

export type GetMNotifyTplBySysIdQueryVariables = Exact<{
  sid: Scalars['Int']['input'];
}>;

export type GetMNotifyTplBySysIdQuery = {
  __typename?: 'Query';
  getMNotifyTplBySysId?: Array<{
    __typename: 'MNotifyTpl';
    id?: number | null;
    created_date?: string | null;
    sys_id?: number | null;
    biz_id?: number | null;
    brand_id?: number | null;
    title?: string | null;
    body?: string | null;
  } | null> | null;
};

export type GetMTpmemTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMTpmemTypeQuery = {
  __typename?: 'Query';
  getMTpmemType?: Array<{ __typename: 'MTpmemType'; id: number; name: string } | null> | null;
};

export type GetMTpmemTypeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMTpmemTypeByIdQuery = {
  __typename?: 'Query';
  getMTpmemTypeById?: { __typename: 'MTpmemType'; id: number; name: string } | null;
};

export type GetMBusinessQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBusinessQuery = {
  __typename?: 'Query';
  getMBusiness?: Array<{
    __typename: 'MBusiness';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
  } | null> | null;
};

export type GetMBusinessByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMBusinessByIdQuery = {
  __typename?: 'Query';
  getMBusinessById?: {
    __typename: 'MBusiness';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
  } | null;
};

export type GetMBusinessByPrefixQueryVariables = Exact<{
  mpfx: Scalars['String']['input'];
}>;

export type GetMBusinessByPrefixQuery = {
  __typename?: 'Query';
  getMBusinessByPrefix?: {
    __typename: 'MBusiness';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
  } | null;
};

export type GetMBusinessBrandQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBusinessBrandQuery = {
  __typename?: 'Query';
  getMBusinessBrand?: Array<{
    __typename: 'MBusinessBrand';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
    brand?: Array<{
      __typename: 'MBrand2';
      brand_id: number;
      biz_id: number;
      brand_name: string;
      brand_prefix?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetMBusinessBrandByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMBusinessBrandByIdQuery = {
  __typename?: 'Query';
  getMBusinessBrandById?: Array<{
    __typename: 'MBusinessBrand';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
    brand?: Array<{
      __typename: 'MBrand2';
      brand_id: number;
      biz_id: number;
      brand_name: string;
      brand_prefix?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetMBusinessBrandByPrefixQueryVariables = Exact<{
  prefix: Scalars['String']['input'];
}>;

export type GetMBusinessBrandByPrefixQuery = {
  __typename?: 'Query';
  getMBusinessBrandByPrefix?: Array<{
    __typename: 'MBusinessBrand';
    id: number;
    name: string;
    prefix?: string | null;
    icon?: string | null;
    brand?: Array<{
      __typename: 'MBrand2';
      brand_id: number;
      biz_id: number;
      brand_name: string;
      brand_prefix?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetMBusinessDataByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMBusinessDataByIdQuery = {
  __typename?: 'Query';
  getMBusinessDataById?: {
    __typename: 'MBusinessData';
    id: number;
    name: string;
    prefix: string;
    icon?: string | null;
    existsStore: boolean;
    brand?: Array<{
      __typename: 'MBrand2';
      brand_id: number;
      biz_id: number;
      brand_name: string;
      brand_prefix?: string | null;
    } | null> | null;
    license?: Array<{
      __typename: 'MLicense2';
      license_id: number;
      biz_id: number;
      license_name: string;
    } | null> | null;
    skillCheck?: Array<{
      __typename: 'MSkillCheck2';
      biz_id?: number | null;
      section_no?: number | null;
      section_name?: string | null;
      check_no?: number | null;
      check_item?: string | null;
      bt_rank_name?: string | null;
    } | null> | null;
  } | null;
};

export type GetMBrandQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBrandQuery = {
  __typename?: 'Query';
  getMBrand?: Array<{
    __typename: 'MBrand';
    id: number;
    biz_id: number;
    name: string;
    prefix?: string | null;
  } | null> | null;
};

export type GetMBrandByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMBrandByIdQuery = {
  __typename?: 'Query';
  getMBrandById?: { __typename: 'MBrand'; id: number; biz_id: number; name: string; prefix?: string | null } | null;
};

export type GetMBrandByBizIdQueryVariables = Exact<{
  bid: Scalars['Int']['input'];
}>;

export type GetMBrandByBizIdQuery = {
  __typename?: 'Query';
  getMBrandByBizId?: Array<{
    __typename: 'MBrand';
    id: number;
    biz_id: number;
    name: string;
    prefix?: string | null;
  } | null> | null;
};

export type GetMBankCodeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBankCodeQuery = {
  __typename?: 'Query';
  getMBankCode?: Array<{ __typename: 'MBankCode'; code: string; name: string; zeus_web: boolean } | null> | null;
};

export type GetMBankCodeByCodeQueryVariables = Exact<{
  mcode: Scalars['String']['input'];
}>;

export type GetMBankCodeByCodeQuery = {
  __typename?: 'Query';
  getMBankCodeByCode?: { __typename: 'MBankCode'; code: string; name: string; zeus_web: boolean } | null;
};

export type GetMBankCodeBranchQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBankCodeBranchQuery = {
  __typename?: 'Query';
  getMBankCodeBranch?: Array<{
    __typename: 'MBankCodeBranch';
    code: string;
    bank_name: string;
    zeus_web: boolean;
    branch?: Array<{
      __typename: 'MBankBranch2';
      bank_code: string;
      branch_code: string;
      branch_name: string;
      sequence: number;
    } | null> | null;
  } | null> | null;
};

export type GetMBankCodeBranchByCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type GetMBankCodeBranchByCodeQuery = {
  __typename?: 'Query';
  getMBankCodeBranchByCode?: Array<{
    __typename: 'MBankCodeBranch';
    code: string;
    bank_name: string;
    zeus_web: boolean;
    branch?: Array<{
      __typename: 'MBankBranch2';
      bank_code: string;
      branch_code: string;
      branch_name: string;
      sequence: number;
    } | null> | null;
  } | null> | null;
};

export type GetMBankBranchQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBankBranchQuery = {
  __typename?: 'Query';
  getMBankBranch?: Array<{
    __typename: 'MBankBranch';
    code: string;
    branch: string;
    name: string;
    sequence: number;
  } | null> | null;
};

export type GetMBankBranchByCodeQueryVariables = Exact<{
  mcode: Scalars['String']['input'];
}>;

export type GetMBankBranchByCodeQuery = {
  __typename?: 'Query';
  getMBankBranchByCode?: Array<{
    __typename: 'MBankBranch';
    code: string;
    branch: string;
    name: string;
    sequence: number;
  } | null> | null;
};

export type GetMBankBranchByCodeBranchQueryVariables = Exact<{
  mcode: Scalars['String']['input'];
  mbrc: Scalars['String']['input'];
}>;

export type GetMBankBranchByCodeBranchQuery = {
  __typename?: 'Query';
  getMBankBranchByCodeBranch?: Array<{
    __typename: 'MBankBranch';
    code: string;
    branch: string;
    name: string;
    sequence: number;
  } | null> | null;
};

export type GetMTosQueryVariables = Exact<{ [key: string]: never }>;

export type GetMTosQuery = {
  __typename?: 'Query';
  getMTos?: Array<{ __typename: 'MTos'; sys_id: number; kind: number; body?: string | null } | null> | null;
};

export type GetMTosBySysIdQueryVariables = Exact<{
  sid: Scalars['Int']['input'];
}>;

export type GetMTosBySysIdQuery = {
  __typename?: 'Query';
  getMTosBySysId?: Array<{ __typename: 'MTos'; sys_id: number; kind: number; body?: string | null } | null> | null;
};

export type GetMTosByKindQueryVariables = Exact<{
  kind: Scalars['Int']['input'];
}>;

export type GetMTosByKindQuery = {
  __typename?: 'Query';
  getMTosByKind?: Array<{ __typename: 'MTos'; sys_id: number; kind: number; body?: string | null } | null> | null;
};

export type GetMTosBySysIdKindQueryVariables = Exact<{
  sys_id: Scalars['Int']['input'];
  kind: Scalars['Int']['input'];
}>;

export type GetMTosBySysIdKindQuery = {
  __typename?: 'Query';
  getMTosBySysIdKind?: { __typename: 'MTos'; sys_id: number; kind: number; body?: string | null } | null;
};

export type GetMAreaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMAreaQuery = {
  __typename?: 'Query';
  getMArea?: Array<{ __typename: 'MArea'; id: number; name: string } | null> | null;
};

export type GetMAreaByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMAreaByIdQuery = {
  __typename?: 'Query';
  getMAreaById?: { __typename: 'MArea'; id: number; name: string } | null;
};

export type GetMAreaStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetMAreaStateQuery = {
  __typename?: 'Query';
  getMAreaState?: Array<{
    __typename: 'MAreaState';
    id: number;
    area_name: string;
    state?: Array<{
      __typename: 'MState2';
      state_id: number;
      state_name: string;
      state_kana: string;
      min_wage: number;
      area_id: number;
      state_lat: number;
      state_lng: number;
    } | null> | null;
  } | null> | null;
};

export type GetMAreaStateByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMAreaStateByIdQuery = {
  __typename?: 'Query';
  getMAreaStateById?: Array<{
    __typename: 'MAreaState';
    id: number;
    area_name: string;
    state?: Array<{
      __typename: 'MState2';
      state_id: number;
      state_name: string;
      state_kana: string;
      min_wage: number;
      area_id: number;
      state_lat: number;
      state_lng: number;
    } | null> | null;
  } | null> | null;
};

export type GetMStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetMStateQuery = {
  __typename?: 'Query';
  getMState?: Array<{
    __typename: 'MState';
    id: number;
    name: string;
    kana: string;
    min_wage: number;
    area_id: number;
    lat: number;
    lng: number;
  } | null> | null;
};

export type GetMStateByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMStateByIdQuery = {
  __typename?: 'Query';
  getMStateById?: {
    __typename: 'MState';
    id: number;
    name: string;
    kana: string;
    min_wage: number;
    area_id: number;
    lat: number;
    lng: number;
  } | null;
};

export type GetMStateByAreaIdQueryVariables = Exact<{
  aid: Scalars['Int']['input'];
}>;

export type GetMStateByAreaIdQuery = {
  __typename?: 'Query';
  getMStateByAreaId?: Array<{
    __typename: 'MState';
    id: number;
    name: string;
    kana: string;
    min_wage: number;
    area_id: number;
    lat: number;
    lng: number;
  } | null> | null;
};

export type GetMStateCityQueryVariables = Exact<{ [key: string]: never }>;

export type GetMStateCityQuery = {
  __typename?: 'Query';
  getMStateCity?: Array<{
    __typename: 'MStateCity';
    id: number;
    state_name: string;
    state_kana: string;
    min_wage: number;
    area_id: number;
    state_lat: number;
    state_lng: number;
    city?: Array<{
      __typename: 'MCity2';
      city_id: number;
      city_name: string;
      city_kana: string;
      state_id: number;
      city_lat: number;
      city_lng: number;
    } | null> | null;
  } | null> | null;
};

export type GetMStateCityByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMStateCityByIdQuery = {
  __typename?: 'Query';
  getMStateCityById?: Array<{
    __typename: 'MStateCity';
    id: number;
    state_name: string;
    state_kana: string;
    min_wage: number;
    area_id: number;
    state_lat: number;
    state_lng: number;
    city?: Array<{
      __typename: 'MCity2';
      city_id: number;
      city_name: string;
      city_kana: string;
      state_id: number;
      city_lat: number;
      city_lng: number;
    } | null> | null;
  } | null> | null;
};

export type GetMStateCityByAreaIdQueryVariables = Exact<{
  area_id: Scalars['Int']['input'];
}>;

export type GetMStateCityByAreaIdQuery = {
  __typename?: 'Query';
  getMStateCityByAreaId?: Array<{
    __typename: 'MStateCity';
    id: number;
    state_name: string;
    state_kana: string;
    min_wage: number;
    area_id: number;
    state_lat: number;
    state_lng: number;
    city?: Array<{
      __typename: 'MCity2';
      city_id: number;
      city_name: string;
      city_kana: string;
      state_id: number;
      city_lat: number;
      city_lng: number;
    } | null> | null;
  } | null> | null;
};

export type GetMCityQueryVariables = Exact<{ [key: string]: never }>;

export type GetMCityQuery = {
  __typename?: 'Query';
  getMCity?: Array<{
    __typename: 'MCity';
    id: number;
    name: string;
    kana: string;
    state_id: number;
    lat: number;
    lng: number;
  } | null> | null;
};

export type GetMCityByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMCityByIdQuery = {
  __typename?: 'Query';
  getMCityById?: {
    __typename: 'MCity';
    id: number;
    name: string;
    kana: string;
    state_id: number;
    lat: number;
    lng: number;
  } | null;
};

export type GetMCityByStateIdQueryVariables = Exact<{
  sid: Scalars['Int']['input'];
}>;

export type GetMCityByStateIdQuery = {
  __typename?: 'Query';
  getMCityByStateId?: Array<{
    __typename: 'MCity';
    id: number;
    name: string;
    kana: string;
    state_id: number;
    lat: number;
    lng: number;
  } | null> | null;
};

export type GetMBtmemRankQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBtmemRankQuery = {
  __typename?: 'Query';
  getMBtmemRank?: Array<{
    __typename: 'MBtmemRank';
    id: number;
    name: string;
    item: string;
    image?: string | null;
  } | null> | null;
};

export type GetMBtmemRankByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMBtmemRankByIdQuery = {
  __typename?: 'Query';
  getMBtmemRankById?: {
    __typename: 'MBtmemRank';
    id: number;
    name: string;
    item: string;
    image?: string | null;
  } | null;
};

export type GetMPenaltyTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMPenaltyTypeQuery = {
  __typename?: 'Query';
  getMPenaltyType?: Array<{ __typename: 'MPenaltyType'; id: number; name: string } | null> | null;
};

export type GetMPenaltyTypeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMPenaltyTypeByIdQuery = {
  __typename?: 'Query';
  getMPenaltyTypeById?: { __typename: 'MPenaltyType'; id: number; name: string } | null;
};

export type GetMPenaltyDefQueryVariables = Exact<{ [key: string]: never }>;

export type GetMPenaltyDefQuery = {
  __typename?: 'Query';
  getMPenaltyDef?: Array<{
    __typename: 'MPenaltyDef';
    id: number;
    decision: number;
    point: number;
    backout: boolean;
  } | null> | null;
};

export type GetMPenaltyDefByIdDecisionQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
  dec: Scalars['Int']['input'];
}>;

export type GetMPenaltyDefByIdDecisionQuery = {
  __typename?: 'Query';
  getMPenaltyDefByIdDecision?: {
    __typename: 'MPenaltyDef';
    id: number;
    decision: number;
    point: number;
    backout: boolean;
  } | null;
};

export type GetMPenaltyDefByIdMinDecisionQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
  dec: Scalars['Int']['input'];
}>;

export type GetMPenaltyDefByIdMinDecisionQuery = {
  __typename?: 'Query';
  getMPenaltyDefByIdMinDecision?: {
    __typename: 'MPenaltyDef';
    id: number;
    decision: number;
    point: number;
    backout: boolean;
  } | null;
};

export type GetMPenaltyDefByIdMaxDecisionQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
  dec: Scalars['Int']['input'];
}>;

export type GetMPenaltyDefByIdMaxDecisionQuery = {
  __typename?: 'Query';
  getMPenaltyDefByIdMaxDecision?: {
    __typename: 'MPenaltyDef';
    id: number;
    decision: number;
    point: number;
    backout: boolean;
  } | null;
};

export type GetMBadgeAreaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBadgeAreaQuery = {
  __typename?: 'Query';
  getMBadgeArea?: Array<{ __typename: 'MBadgeArea'; id: number; name: string; state_id: number } | null> | null;
};

export type GetMBadgeAreaByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMBadgeAreaByIdQuery = {
  __typename?: 'Query';
  getMBadgeAreaById?: { __typename: 'MBadgeArea'; id: number; name: string; state_id: number } | null;
};

export type GetMBadgeAreaDefQueryVariables = Exact<{ [key: string]: never }>;

export type GetMBadgeAreaDefQuery = {
  __typename?: 'Query';
  getMBadgeAreaDef?: Array<{ __typename: 'MBadgeAreaDef'; id?: number | null; city?: string | null } | null> | null;
};

export type GetMBadgeAreaDefByIdCityQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
  cty: Scalars['String']['input'];
}>;

export type GetMBadgeAreaDefByIdCityQuery = {
  __typename?: 'Query';
  getMBadgeAreaDefByIdCity?: { __typename: 'MBadgeAreaDef'; id?: number | null; city?: string | null } | null;
};

export type GetMBadgeAreaDefByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMBadgeAreaDefByIdQuery = {
  __typename?: 'Query';
  getMBadgeAreaDefById?: Array<{ __typename: 'MBadgeAreaDef'; id?: number | null; city?: string | null } | null> | null;
};

export type GetMBadgeAreaDefByCityQueryVariables = Exact<{
  cty: Scalars['String']['input'];
}>;

export type GetMBadgeAreaDefByCityQuery = {
  __typename?: 'Query';
  getMBadgeAreaDefByCity?: Array<{
    __typename: 'MBadgeAreaDef';
    id?: number | null;
    city?: string | null;
  } | null> | null;
};

export type GetMOccAttrQueryVariables = Exact<{ [key: string]: never }>;

export type GetMOccAttrQuery = {
  __typename?: 'Query';
  getMOccAttr?: Array<{ __typename: 'MOccAttr'; id: number; name: string } | null> | null;
};

export type GetMOccAttrByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMOccAttrByIdQuery = {
  __typename?: 'Query';
  getMOccAttrById?: { __typename: 'MOccAttr'; id: number; name: string } | null;
};

export type GetMUniformSizeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMUniformSizeQuery = {
  __typename?: 'Query';
  getMUniformSize?: Array<{ __typename: 'MUniformSize'; id: number; name: string } | null> | null;
};

export type GetMUniformSizeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMUniformSizeByIdQuery = {
  __typename?: 'Query';
  getMUniformSizeById?: { __typename: 'MUniformSize'; id: number; name: string } | null;
};

export type GetMAchieveQueryVariables = Exact<{ [key: string]: never }>;

export type GetMAchieveQuery = {
  __typename?: 'Query';
  getMAchieve?: Array<{ __typename: 'MAchieve'; id: number; kind: number; phrase: string } | null> | null;
};

export type GetMAchieveByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMAchieveByIdQuery = {
  __typename?: 'Query';
  getMAchieveById?: { __typename: 'MAchieve'; id: number; kind: number; phrase: string } | null;
};

export type GetMAchieveByKindQueryVariables = Exact<{
  knd: Scalars['Int']['input'];
}>;

export type GetMAchieveByKindQuery = {
  __typename?: 'Query';
  getMAchieveByKind?: Array<{ __typename: 'MAchieve'; id: number; kind: number; phrase: string } | null> | null;
};

export type GetMIdCardTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMIdCardTypeQuery = {
  __typename?: 'Query';
  getMIdCardType?: Array<{
    __typename: 'MIdCardType';
    id: number;
    type: number;
    name: string;
    image_num: number;
    with_photo: boolean;
    student: number;
    additional_id?: number | null;
    icon?: string | null;
  } | null> | null;
};

export type GetMIdCardTypeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMIdCardTypeByIdQuery = {
  __typename?: 'Query';
  getMIdCardTypeById?: {
    __typename: 'MIdCardType';
    id: number;
    type: number;
    name: string;
    image_num: number;
    with_photo: boolean;
    student: number;
    additional_id?: number | null;
    icon?: string | null;
  } | null;
};

export type GetMLicenseQueryVariables = Exact<{ [key: string]: never }>;

export type GetMLicenseQuery = {
  __typename?: 'Query';
  getMLicense?: Array<{ __typename: 'MLicense'; id: number; biz_id: number; name: string } | null> | null;
};

export type GetMLicenseByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMLicenseByIdQuery = {
  __typename?: 'Query';
  getMLicenseById?: { __typename: 'MLicense'; id: number; biz_id: number; name: string } | null;
};

export type GetMLicenseByBizIdQueryVariables = Exact<{
  bid: Scalars['Int']['input'];
}>;

export type GetMLicenseByBizIdQuery = {
  __typename?: 'Query';
  getMLicenseByBizId?: Array<{ __typename: 'MLicense'; id: number; biz_id: number; name: string } | null> | null;
};

export type GetMAutoBadgeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMAutoBadgeQuery = {
  __typename?: 'Query';
  getMAutoBadge?: Array<{
    __typename: 'MAutoBadge';
    id: number;
    count: number;
    name: string;
    icon?: string | null;
  } | null> | null;
};

export type GetMAutoBadgeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMAutoBadgeByIdQuery = {
  __typename?: 'Query';
  getMAutoBadgeById?: {
    __typename: 'MAutoBadge';
    id: number;
    count: number;
    name: string;
    icon?: string | null;
  } | null;
};

export type GetMAutoBadgeByCountQueryVariables = Exact<{
  cnt: Scalars['Int']['input'];
}>;

export type GetMAutoBadgeByCountQuery = {
  __typename?: 'Query';
  getMAutoBadgeByCount?: {
    __typename: 'MAutoBadge';
    id: number;
    count: number;
    name: string;
    icon?: string | null;
  } | null;
};

export type GetMAutoBadgeByMaxCountQueryVariables = Exact<{
  cnt: Scalars['Int']['input'];
}>;

export type GetMAutoBadgeByMaxCountQuery = {
  __typename?: 'Query';
  getMAutoBadgeByMaxCount?: {
    __typename: 'MAutoBadge';
    id: number;
    count: number;
    name: string;
    icon?: string | null;
  } | null;
};

export type GetMConqBadgeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMConqBadgeQuery = {
  __typename?: 'Query';
  getMConqBadge?: Array<{ __typename: 'MConqBadge'; id: number; name: string; icon?: string | null } | null> | null;
};

export type GetMConqBadgeByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMConqBadgeByIdQuery = {
  __typename?: 'Query';
  getMConqBadgeById?: { __typename: 'MConqBadge'; id: number; name: string; icon?: string | null } | null;
};

export type GetMConqBadgeListQueryVariables = Exact<{ [key: string]: never }>;

export type GetMConqBadgeListQuery = {
  __typename?: 'Query';
  getMConqBadgeList?: Array<{
    __typename: 'MConqBadgeList';
    id: number;
    name: string;
    icon?: string | null;
    badge_area?: Array<{ __typename: 'MBadgeArea2'; area_id: number; area_name: string } | null> | null;
  } | null> | null;
};

export type GetMSkillCheckQueryVariables = Exact<{ [key: string]: never }>;

export type GetMSkillCheckQuery = {
  __typename?: 'Query';
  getMSkillCheck?: Array<{
    __typename: 'MSkillCheck';
    biz_id: number;
    section_no: number;
    no: number;
    bt_rank_id: number;
    item: string;
  } | null> | null;
};

export type GetMSkillCheckByBizIdNoQueryVariables = Exact<{
  bid: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
}>;

export type GetMSkillCheckByBizIdNoQuery = {
  __typename?: 'Query';
  getMSkillCheckByBizIdNo?: {
    __typename: 'MSkillCheck';
    biz_id: number;
    section_no: number;
    no: number;
    bt_rank_id: number;
    item: string;
  } | null;
};

export type GetMSkillCheckByBtRankIdQueryVariables = Exact<{
  rid: Scalars['Int']['input'];
}>;

export type GetMSkillCheckByBtRankIdQuery = {
  __typename?: 'Query';
  getMSkillCheckByBtRankId?: Array<{
    __typename: 'MSkillCheck';
    biz_id: number;
    section_no: number;
    no: number;
    bt_rank_id: number;
    item: string;
  } | null> | null;
};

export type GetMCountryQueryVariables = Exact<{ [key: string]: never }>;

export type GetMCountryQuery = {
  __typename?: 'Query';
  getMCountry?: Array<{ __typename: 'MCountry'; id: number; name: string; english: string } | null> | null;
};

export type GetMCountryByIdQueryVariables = Exact<{
  mid: Scalars['Int']['input'];
}>;

export type GetMCountryByIdQuery = {
  __typename?: 'Query';
  getMCountryById?: { __typename: 'MCountry'; id: number; name: string; english: string } | null;
};

export type GetMMessageQueryVariables = Exact<{ [key: string]: never }>;

export type GetMMessageQuery = {
  __typename?: 'Query';
  getMMessage?: Array<{
    __typename: 'MMessage';
    sys_id?: number | null;
    no?: number | null;
    kind?: number | null;
    title?: string | null;
    body?: string | null;
    screen_no?: string | null;
  } | null> | null;
};

export type GetMMessageByNoQueryVariables = Exact<{
  no: Scalars['Int']['input'];
}>;

export type GetMMessageByNoQuery = {
  __typename?: 'Query';
  getMMessageByNo?: {
    __typename: 'MMessage';
    sys_id?: number | null;
    no?: number | null;
    kind?: number | null;
    title?: string | null;
    body?: string | null;
    screen_no?: string | null;
  } | null;
};

export type GetMResidenceQueryVariables = Exact<{ [key: string]: never }>;

export type GetMResidenceQuery = {
  __typename?: 'Query';
  getMResidence?: Array<{ __typename: 'MResidence'; id: number; name: string } | null> | null;
};

export type GetMResidenceByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMResidenceByIdQuery = {
  __typename?: 'Query';
  getMResidenceById?: { __typename: 'MResidence'; id: number; name: string } | null;
};

export type GetMSkillSectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetMSkillSectionQuery = {
  __typename?: 'Query';
  getMSkillSection?: Array<{ __typename: 'MSkillSection'; biz_id: number; no: number; name: string } | null> | null;
};

export type GetMSkillSectionByBizIdNoQueryVariables = Exact<{
  biz_id: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
}>;

export type GetMSkillSectionByBizIdNoQuery = {
  __typename?: 'Query';
  getMSkillSectionByBizIdNo?: { __typename: 'MSkillSection'; biz_id: number; no: number; name: string } | null;
};

export type GetMSkillSectionByBizIdQueryVariables = Exact<{
  biz_id: Scalars['Int']['input'];
}>;

export type GetMSkillSectionByBizIdQuery = {
  __typename?: 'Query';
  getMSkillSectionByBizId?: Array<{
    __typename: 'MSkillSection';
    biz_id: number;
    no: number;
    name: string;
  } | null> | null;
};

export type GetMConqAreaQueryVariables = Exact<{ [key: string]: never }>;

export type GetMConqAreaQuery = {
  __typename?: 'Query';
  getMConqArea?: Array<{ __typename: 'MConqArea'; id: number; area_id: number } | null> | null;
};

export type GetMConqAreaByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetMConqAreaByIdQuery = {
  __typename?: 'Query';
  getMConqAreaById?: Array<{ __typename: 'MConqArea'; id: number; area_id: number } | null> | null;
};

export type GetMCancelReasonQueryVariables = Exact<{ [key: string]: never }>;

export type GetMCancelReasonQuery = {
  __typename?: 'Query';
  getMCancelReason?: Array<{ __typename: 'MCancelReason'; sys_id: number; no: number; reason: string } | null> | null;
};

export type GetMCancelReasonBySysIdQueryVariables = Exact<{
  sys_id: Scalars['Int']['input'];
}>;

export type GetMCancelReasonBySysIdQuery = {
  __typename?: 'Query';
  getMCancelReasonBySysId?: Array<{
    __typename: 'MCancelReason';
    sys_id: number;
    no: number;
    reason: string;
  } | null> | null;
};

export type GetMCancelReasonBySysIdNoQueryVariables = Exact<{
  sys_id: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
}>;

export type GetMCancelReasonBySysIdNoQuery = {
  __typename?: 'Query';
  getMCancelReasonBySysIdNo?: { __typename: 'MCancelReason'; sys_id: number; no: number; reason: string } | null;
};

export type GetMRejectReasonQueryVariables = Exact<{ [key: string]: never }>;

export type GetMRejectReasonQuery = {
  __typename?: 'Query';
  getMRejectReason?: Array<{ __typename: 'MRejectReason'; kind: number; no: number; reason: string } | null> | null;
};

export type GetMRejectReasonByKindQueryVariables = Exact<{
  kind: Scalars['Int']['input'];
}>;

export type GetMRejectReasonByKindQuery = {
  __typename?: 'Query';
  getMRejectReasonByKind?: Array<{
    __typename: 'MRejectReason';
    kind: number;
    no: number;
    reason: string;
  } | null> | null;
};

export type GetMRejectReasonByKindNoQueryVariables = Exact<{
  kind: Scalars['Int']['input'];
  no: Scalars['Int']['input'];
}>;

export type GetMRejectReasonByKindNoQuery = {
  __typename?: 'Query';
  getMRejectReasonByKindNo?: { __typename: 'MRejectReason'; kind: number; no: number; reason: string } | null;
};

export type GetVtSelectStoreListByBrandIdQueryVariables = Exact<{
  brand_id: Scalars['Int']['input'];
}>;

export type GetVtSelectStoreListByBrandIdQuery = {
  __typename?: 'Query';
  getVTSelectStoreListByBrandId: Array<{
    __typename: 'VTSelectStoreList';
    address1?: string | null;
    address2?: string | null;
    brand_id?: number | null;
    city?: string | null;
    id: number;
    name?: string | null;
    phone?: string | null;
    state?: string | null;
    zip_code?: string | null;
  }>;
};

export const AddBPenaltyPointDocument = gql`
  mutation AddBPenaltyPoint($id: Int!) {
    addBPenaltyPoint(id: $id) {
      id
      __typename
    }
  }
`;
export type AddBPenaltyPointMutationFn = Apollo.MutationFunction<
  AddBPenaltyPointMutation,
  AddBPenaltyPointMutationVariables
>;

/**
 * __useAddBPenaltyPointMutation__
 *
 * To run a mutation, you first call `useAddBPenaltyPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBPenaltyPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBPenaltyPointMutation, { data, loading, error }] = useAddBPenaltyPointMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddBPenaltyPointMutation(
  baseOptions?: Apollo.MutationHookOptions<AddBPenaltyPointMutation, AddBPenaltyPointMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBPenaltyPointMutation, AddBPenaltyPointMutationVariables>(
    AddBPenaltyPointDocument,
    options
  );
}
export type AddBPenaltyPointMutationHookResult = ReturnType<typeof useAddBPenaltyPointMutation>;
export type AddBPenaltyPointMutationResult = Apollo.MutationResult<AddBPenaltyPointMutation>;
export type AddBPenaltyPointMutationOptions = Apollo.BaseMutationOptions<
  AddBPenaltyPointMutation,
  AddBPenaltyPointMutationVariables
>;
export const AddSCampaignDocument = gql`
  mutation AddSCampaign(
    $begin_date: String!
    $brand_ids: String!
    $end_date: String!
    $give_num: Int!
    $kind: Int!
    $state_ids: String!
    $ticket_num: Int!
    $title: String!
    $umgmt_id: Int!
    $valid_days: Int!
  ) {
    addSCampaign(
      begin_date: $begin_date
      brand_ids: $brand_ids
      end_date: $end_date
      give_num: $give_num
      kind: $kind
      state_ids: $state_ids
      ticket_num: $ticket_num
      title: $title
      umgmt_id: $umgmt_id
      valid_days: $valid_days
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignMutationFn = Apollo.MutationFunction<AddSCampaignMutation, AddSCampaignMutationVariables>;

/**
 * __useAddSCampaignMutation__
 *
 * To run a mutation, you first call `useAddSCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignMutation, { data, loading, error }] = useAddSCampaignMutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      brand_ids: // value for 'brand_ids'
 *      end_date: // value for 'end_date'
 *      give_num: // value for 'give_num'
 *      kind: // value for 'kind'
 *      state_ids: // value for 'state_ids'
 *      ticket_num: // value for 'ticket_num'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *      valid_days: // value for 'valid_days'
 *   },
 * });
 */
export function useAddSCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignMutation, AddSCampaignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignMutation, AddSCampaignMutationVariables>(AddSCampaignDocument, options);
}
export type AddSCampaignMutationHookResult = ReturnType<typeof useAddSCampaignMutation>;
export type AddSCampaignMutationResult = Apollo.MutationResult<AddSCampaignMutation>;
export type AddSCampaignMutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignMutation,
  AddSCampaignMutationVariables
>;
export const AddSCampaignKind1Document = gql`
  mutation AddSCampaignKind1(
    $begin_date: String!
    $brand_ids: String!
    $end_date: String!
    $free_months: Int!
    $state_ids: String!
    $title: String!
    $umgmt_id: Int!
  ) {
    addSCampaignKind1(
      begin_date: $begin_date
      brand_ids: $brand_ids
      end_date: $end_date
      free_months: $free_months
      state_ids: $state_ids
      title: $title
      umgmt_id: $umgmt_id
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignKind1MutationFn = Apollo.MutationFunction<
  AddSCampaignKind1Mutation,
  AddSCampaignKind1MutationVariables
>;

/**
 * __useAddSCampaignKind1Mutation__
 *
 * To run a mutation, you first call `useAddSCampaignKind1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignKind1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignKind1Mutation, { data, loading, error }] = useAddSCampaignKind1Mutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      brand_ids: // value for 'brand_ids'
 *      end_date: // value for 'end_date'
 *      free_months: // value for 'free_months'
 *      state_ids: // value for 'state_ids'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useAddSCampaignKind1Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignKind1Mutation, AddSCampaignKind1MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignKind1Mutation, AddSCampaignKind1MutationVariables>(
    AddSCampaignKind1Document,
    options
  );
}
export type AddSCampaignKind1MutationHookResult = ReturnType<typeof useAddSCampaignKind1Mutation>;
export type AddSCampaignKind1MutationResult = Apollo.MutationResult<AddSCampaignKind1Mutation>;
export type AddSCampaignKind1MutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignKind1Mutation,
  AddSCampaignKind1MutationVariables
>;
export const AddSCampaignKind11Document = gql`
  mutation AddSCampaignKind11(
    $begin_date: String!
    $egift_code: Int!
    $end_date: String!
    $state_ids: String!
    $title: String!
    $umgmt_id: Int!
  ) {
    addSCampaignKind11(
      begin_date: $begin_date
      egift_code: $egift_code
      end_date: $end_date
      state_ids: $state_ids
      title: $title
      umgmt_id: $umgmt_id
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignKind11MutationFn = Apollo.MutationFunction<
  AddSCampaignKind11Mutation,
  AddSCampaignKind11MutationVariables
>;

/**
 * __useAddSCampaignKind11Mutation__
 *
 * To run a mutation, you first call `useAddSCampaignKind11Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignKind11Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignKind11Mutation, { data, loading, error }] = useAddSCampaignKind11Mutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      egift_code: // value for 'egift_code'
 *      end_date: // value for 'end_date'
 *      state_ids: // value for 'state_ids'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useAddSCampaignKind11Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignKind11Mutation, AddSCampaignKind11MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignKind11Mutation, AddSCampaignKind11MutationVariables>(
    AddSCampaignKind11Document,
    options
  );
}
export type AddSCampaignKind11MutationHookResult = ReturnType<typeof useAddSCampaignKind11Mutation>;
export type AddSCampaignKind11MutationResult = Apollo.MutationResult<AddSCampaignKind11Mutation>;
export type AddSCampaignKind11MutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignKind11Mutation,
  AddSCampaignKind11MutationVariables
>;
export const AddSCampaignKind12Document = gql`
  mutation AddSCampaignKind12(
    $begin_date: String!
    $brand_ids: String!
    $egift_code: Int!
    $end_date: String!
    $incl_resident: Boolean!
    $state_ids: String!
    $title: String!
    $umgmt_id: Int!
  ) {
    addSCampaignKind12(
      begin_date: $begin_date
      brand_ids: $brand_ids
      egift_code: $egift_code
      end_date: $end_date
      incl_resident: $incl_resident
      state_ids: $state_ids
      title: $title
      umgmt_id: $umgmt_id
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignKind12MutationFn = Apollo.MutationFunction<
  AddSCampaignKind12Mutation,
  AddSCampaignKind12MutationVariables
>;

/**
 * __useAddSCampaignKind12Mutation__
 *
 * To run a mutation, you first call `useAddSCampaignKind12Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignKind12Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignKind12Mutation, { data, loading, error }] = useAddSCampaignKind12Mutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      brand_ids: // value for 'brand_ids'
 *      egift_code: // value for 'egift_code'
 *      end_date: // value for 'end_date'
 *      incl_resident: // value for 'incl_resident'
 *      state_ids: // value for 'state_ids'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useAddSCampaignKind12Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignKind12Mutation, AddSCampaignKind12MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignKind12Mutation, AddSCampaignKind12MutationVariables>(
    AddSCampaignKind12Document,
    options
  );
}
export type AddSCampaignKind12MutationHookResult = ReturnType<typeof useAddSCampaignKind12Mutation>;
export type AddSCampaignKind12MutationResult = Apollo.MutationResult<AddSCampaignKind12Mutation>;
export type AddSCampaignKind12MutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignKind12Mutation,
  AddSCampaignKind12MutationVariables
>;
export const AddSCampaignKind2Document = gql`
  mutation AddSCampaignKind2(
    $begin_date: String!
    $brand_ids: String!
    $end_date: String!
    $state_ids: String!
    $ticket_num: Int!
    $title: String!
    $umgmt_id: Int!
    $valid_days: Int!
  ) {
    addSCampaignKind2(
      begin_date: $begin_date
      brand_ids: $brand_ids
      end_date: $end_date
      state_ids: $state_ids
      ticket_num: $ticket_num
      title: $title
      umgmt_id: $umgmt_id
      valid_days: $valid_days
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignKind2MutationFn = Apollo.MutationFunction<
  AddSCampaignKind2Mutation,
  AddSCampaignKind2MutationVariables
>;

/**
 * __useAddSCampaignKind2Mutation__
 *
 * To run a mutation, you first call `useAddSCampaignKind2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignKind2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignKind2Mutation, { data, loading, error }] = useAddSCampaignKind2Mutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      brand_ids: // value for 'brand_ids'
 *      end_date: // value for 'end_date'
 *      state_ids: // value for 'state_ids'
 *      ticket_num: // value for 'ticket_num'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *      valid_days: // value for 'valid_days'
 *   },
 * });
 */
export function useAddSCampaignKind2Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignKind2Mutation, AddSCampaignKind2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignKind2Mutation, AddSCampaignKind2MutationVariables>(
    AddSCampaignKind2Document,
    options
  );
}
export type AddSCampaignKind2MutationHookResult = ReturnType<typeof useAddSCampaignKind2Mutation>;
export type AddSCampaignKind2MutationResult = Apollo.MutationResult<AddSCampaignKind2Mutation>;
export type AddSCampaignKind2MutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignKind2Mutation,
  AddSCampaignKind2MutationVariables
>;
export const AddSCampaignKind3Document = gql`
  mutation AddSCampaignKind3(
    $begin_date: String!
    $brand_ids: String!
    $end_date: String!
    $give_num: Int!
    $state_ids: String!
    $ticket_num: Int!
    $title: String!
    $umgmt_id: Int!
    $valid_days: Int!
  ) {
    addSCampaignKind3(
      begin_date: $begin_date
      brand_ids: $brand_ids
      end_date: $end_date
      give_num: $give_num
      state_ids: $state_ids
      ticket_num: $ticket_num
      title: $title
      umgmt_id: $umgmt_id
      valid_days: $valid_days
    ) {
      id
      __typename
    }
  }
`;
export type AddSCampaignKind3MutationFn = Apollo.MutationFunction<
  AddSCampaignKind3Mutation,
  AddSCampaignKind3MutationVariables
>;

/**
 * __useAddSCampaignKind3Mutation__
 *
 * To run a mutation, you first call `useAddSCampaignKind3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSCampaignKind3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSCampaignKind3Mutation, { data, loading, error }] = useAddSCampaignKind3Mutation({
 *   variables: {
 *      begin_date: // value for 'begin_date'
 *      brand_ids: // value for 'brand_ids'
 *      end_date: // value for 'end_date'
 *      give_num: // value for 'give_num'
 *      state_ids: // value for 'state_ids'
 *      ticket_num: // value for 'ticket_num'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *      valid_days: // value for 'valid_days'
 *   },
 * });
 */
export function useAddSCampaignKind3Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddSCampaignKind3Mutation, AddSCampaignKind3MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSCampaignKind3Mutation, AddSCampaignKind3MutationVariables>(
    AddSCampaignKind3Document,
    options
  );
}
export type AddSCampaignKind3MutationHookResult = ReturnType<typeof useAddSCampaignKind3Mutation>;
export type AddSCampaignKind3MutationResult = Apollo.MutationResult<AddSCampaignKind3Mutation>;
export type AddSCampaignKind3MutationOptions = Apollo.BaseMutationOptions<
  AddSCampaignKind3Mutation,
  AddSCampaignKind3MutationVariables
>;
export const AddSEgiftCodeDocument = gql`
  mutation AddSEgiftCode(
    $config_code: String!
    $deadline: String!
    $egift_type: Int!
    $name: String!
    $token: String!
  ) {
    addSEgiftCode(config_code: $config_code, deadline: $deadline, egift_type: $egift_type, name: $name, token: $token) {
      id
      __typename
    }
  }
`;
export type AddSEgiftCodeMutationFn = Apollo.MutationFunction<AddSEgiftCodeMutation, AddSEgiftCodeMutationVariables>;

/**
 * __useAddSEgiftCodeMutation__
 *
 * To run a mutation, you first call `useAddSEgiftCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSEgiftCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSEgiftCodeMutation, { data, loading, error }] = useAddSEgiftCodeMutation({
 *   variables: {
 *      config_code: // value for 'config_code'
 *      deadline: // value for 'deadline'
 *      egift_type: // value for 'egift_type'
 *      name: // value for 'name'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddSEgiftCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSEgiftCodeMutation, AddSEgiftCodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSEgiftCodeMutation, AddSEgiftCodeMutationVariables>(AddSEgiftCodeDocument, options);
}
export type AddSEgiftCodeMutationHookResult = ReturnType<typeof useAddSEgiftCodeMutation>;
export type AddSEgiftCodeMutationResult = Apollo.MutationResult<AddSEgiftCodeMutation>;
export type AddSEgiftCodeMutationOptions = Apollo.BaseMutationOptions<
  AddSEgiftCodeMutation,
  AddSEgiftCodeMutationVariables
>;
export const AddSWithholdDayDocument = gql`
  mutation AddSWithholdDay($price: Int!, $tax: Int!, $year: Int!) {
    addSWithholdDay(price: $price, tax: $tax, year: $year) {
      price
      tax
      year
      __typename
    }
  }
`;
export type AddSWithholdDayMutationFn = Apollo.MutationFunction<
  AddSWithholdDayMutation,
  AddSWithholdDayMutationVariables
>;

/**
 * __useAddSWithholdDayMutation__
 *
 * To run a mutation, you first call `useAddSWithholdDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSWithholdDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSWithholdDayMutation, { data, loading, error }] = useAddSWithholdDayMutation({
 *   variables: {
 *      price: // value for 'price'
 *      tax: // value for 'tax'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAddSWithholdDayMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSWithholdDayMutation, AddSWithholdDayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSWithholdDayMutation, AddSWithholdDayMutationVariables>(
    AddSWithholdDayDocument,
    options
  );
}
export type AddSWithholdDayMutationHookResult = ReturnType<typeof useAddSWithholdDayMutation>;
export type AddSWithholdDayMutationResult = Apollo.MutationResult<AddSWithholdDayMutation>;
export type AddSWithholdDayMutationOptions = Apollo.BaseMutationOptions<
  AddSWithholdDayMutation,
  AddSWithholdDayMutationVariables
>;
export const AddTBaseDocument = gql`
  mutation AddTBase(
    $address1: String!
    $address2: String!
    $brand_id: Int!
    $city: String!
    $kana: String!
    $lat: Float!
    $lng: Float!
    $name: String!
    $phone: String!
    $state: String!
    $zip_code: String!
  ) {
    addTBase(
      address1: $address1
      address2: $address2
      brand_id: $brand_id
      city: $city
      kana: $kana
      lat: $lat
      lng: $lng
      name: $name
      phone: $phone
      state: $state
      zip_code: $zip_code
    ) {
      id
      __typename
    }
  }
`;
export type AddTBaseMutationFn = Apollo.MutationFunction<AddTBaseMutation, AddTBaseMutationVariables>;

/**
 * __useAddTBaseMutation__
 *
 * To run a mutation, you first call `useAddTBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTBaseMutation, { data, loading, error }] = useAddTBaseMutation({
 *   variables: {
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      brand_id: // value for 'brand_id'
 *      city: // value for 'city'
 *      kana: // value for 'kana'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      state: // value for 'state'
 *      zip_code: // value for 'zip_code'
 *   },
 * });
 */
export function useAddTBaseMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTBaseMutation, AddTBaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTBaseMutation, AddTBaseMutationVariables>(AddTBaseDocument, options);
}
export type AddTBaseMutationHookResult = ReturnType<typeof useAddTBaseMutation>;
export type AddTBaseMutationResult = Apollo.MutationResult<AddTBaseMutation>;
export type AddTBaseMutationOptions = Apollo.BaseMutationOptions<AddTBaseMutation, AddTBaseMutationVariables>;
export const AddTTpmemBlockDocument = gql`
  mutation AddTTpmemBlock($block_ids: String!, $id: Int!, $reason: String!, $umgmt_id: Int!) {
    addTTpmemBlock(block_ids: $block_ids, id: $id, reason: $reason, umgmt_id: $umgmt_id) {
      resultRows
      __typename
    }
  }
`;
export type AddTTpmemBlockMutationFn = Apollo.MutationFunction<AddTTpmemBlockMutation, AddTTpmemBlockMutationVariables>;

/**
 * __useAddTTpmemBlockMutation__
 *
 * To run a mutation, you first call `useAddTTpmemBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTTpmemBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTTpmemBlockMutation, { data, loading, error }] = useAddTTpmemBlockMutation({
 *   variables: {
 *      block_ids: // value for 'block_ids'
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useAddTTpmemBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTTpmemBlockMutation, AddTTpmemBlockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTTpmemBlockMutation, AddTTpmemBlockMutationVariables>(AddTTpmemBlockDocument, options);
}
export type AddTTpmemBlockMutationHookResult = ReturnType<typeof useAddTTpmemBlockMutation>;
export type AddTTpmemBlockMutationResult = Apollo.MutationResult<AddTTpmemBlockMutation>;
export type AddTTpmemBlockMutationOptions = Apollo.BaseMutationOptions<
  AddTTpmemBlockMutation,
  AddTTpmemBlockMutationVariables
>;
export const AddUMemberDocument = gql`
  mutation AddUMember(
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
    $privilege: Int!
  ) {
    addUMember(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
      privilege: $privilege
    ) {
      cognito_id
      email
      first_kana
      first_name
      id
      last_kana
      last_name
      password
      privilege
      status
      tfa
      __typename
    }
  }
`;
export type AddUMemberMutationFn = Apollo.MutationFunction<AddUMemberMutation, AddUMemberMutationVariables>;

/**
 * __useAddUMemberMutation__
 *
 * To run a mutation, you first call `useAddUMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUMemberMutation, { data, loading, error }] = useAddUMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      password: // value for 'password'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useAddUMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUMemberMutation, AddUMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUMemberMutation, AddUMemberMutationVariables>(AddUMemberDocument, options);
}
export type AddUMemberMutationHookResult = ReturnType<typeof useAddUMemberMutation>;
export type AddUMemberMutationResult = Apollo.MutationResult<AddUMemberMutation>;
export type AddUMemberMutationOptions = Apollo.BaseMutationOptions<AddUMemberMutation, AddUMemberMutationVariables>;
export const AddUSendNotifyDocument = gql`
  mutation AddUSendNotify(
    $biz_id: Int
    $body: String!
    $brand_id: Int
    $sys_id: Int!
    $title: String!
    $umgmt_id: Int!
  ) {
    addUSendNotify(
      biz_id: $biz_id
      body: $body
      brand_id: $brand_id
      sys_id: $sys_id
      title: $title
      umgmt_id: $umgmt_id
    ) {
      attr_id
      belonged
      biz_id
      body
      brand_id
      created_date
      id
      send_date
      sys_id
      title
      umgmt_id
      update_date
      __typename
    }
  }
`;
export type AddUSendNotifyMutationFn = Apollo.MutationFunction<AddUSendNotifyMutation, AddUSendNotifyMutationVariables>;

/**
 * __useAddUSendNotifyMutation__
 *
 * To run a mutation, you first call `useAddUSendNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUSendNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUSendNotifyMutation, { data, loading, error }] = useAddUSendNotifyMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      body: // value for 'body'
 *      brand_id: // value for 'brand_id'
 *      sys_id: // value for 'sys_id'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useAddUSendNotifyMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUSendNotifyMutation, AddUSendNotifyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUSendNotifyMutation, AddUSendNotifyMutationVariables>(AddUSendNotifyDocument, options);
}
export type AddUSendNotifyMutationHookResult = ReturnType<typeof useAddUSendNotifyMutation>;
export type AddUSendNotifyMutationResult = Apollo.MutationResult<AddUSendNotifyMutation>;
export type AddUSendNotifyMutationOptions = Apollo.BaseMutationOptions<
  AddUSendNotifyMutation,
  AddUSendNotifyMutationVariables
>;
export const AddUSendNotifyDraftDocument = gql`
  mutation AddUSendNotifyDraft($biz_id: Int, $body: String, $brand_id: Int, $sys_id: Int, $title: String!) {
    addUSendNotifyDraft(biz_id: $biz_id, body: $body, brand_id: $brand_id, sys_id: $sys_id, title: $title) {
      attr_id
      belonged
      biz_id
      body
      brand_id
      created_date
      id
      send_date
      sys_id
      title
      umgmt_id
      update_date
      __typename
    }
  }
`;
export type AddUSendNotifyDraftMutationFn = Apollo.MutationFunction<
  AddUSendNotifyDraftMutation,
  AddUSendNotifyDraftMutationVariables
>;

/**
 * __useAddUSendNotifyDraftMutation__
 *
 * To run a mutation, you first call `useAddUSendNotifyDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUSendNotifyDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUSendNotifyDraftMutation, { data, loading, error }] = useAddUSendNotifyDraftMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      body: // value for 'body'
 *      brand_id: // value for 'brand_id'
 *      sys_id: // value for 'sys_id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddUSendNotifyDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUSendNotifyDraftMutation, AddUSendNotifyDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUSendNotifyDraftMutation, AddUSendNotifyDraftMutationVariables>(
    AddUSendNotifyDraftDocument,
    options
  );
}
export type AddUSendNotifyDraftMutationHookResult = ReturnType<typeof useAddUSendNotifyDraftMutation>;
export type AddUSendNotifyDraftMutationResult = Apollo.MutationResult<AddUSendNotifyDraftMutation>;
export type AddUSendNotifyDraftMutationOptions = Apollo.BaseMutationOptions<
  AddUSendNotifyDraftMutation,
  AddUSendNotifyDraftMutationVariables
>;
export const AddUTrialTicketDocument = gql`
  mutation AddUTrialTicket($item: String!, $ticket_num: Int!, $tpmem_id: Int!, $umgmt_id: Int!, $valid_days: Int!) {
    addUTrialTicket(
      item: $item
      ticket_num: $ticket_num
      tpmem_id: $tpmem_id
      umgmt_id: $umgmt_id
      valid_days: $valid_days
    ) {
      id
      __typename
    }
  }
`;
export type AddUTrialTicketMutationFn = Apollo.MutationFunction<
  AddUTrialTicketMutation,
  AddUTrialTicketMutationVariables
>;

/**
 * __useAddUTrialTicketMutation__
 *
 * To run a mutation, you first call `useAddUTrialTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUTrialTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUTrialTicketMutation, { data, loading, error }] = useAddUTrialTicketMutation({
 *   variables: {
 *      item: // value for 'item'
 *      ticket_num: // value for 'ticket_num'
 *      tpmem_id: // value for 'tpmem_id'
 *      umgmt_id: // value for 'umgmt_id'
 *      valid_days: // value for 'valid_days'
 *   },
 * });
 */
export function useAddUTrialTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUTrialTicketMutation, AddUTrialTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUTrialTicketMutation, AddUTrialTicketMutationVariables>(
    AddUTrialTicketDocument,
    options
  );
}
export type AddUTrialTicketMutationHookResult = ReturnType<typeof useAddUTrialTicketMutation>;
export type AddUTrialTicketMutationResult = Apollo.MutationResult<AddUTrialTicketMutation>;
export type AddUTrialTicketMutationOptions = Apollo.BaseMutationOptions<
  AddUTrialTicketMutation,
  AddUTrialTicketMutationVariables
>;
export const AddupdUMemberDocument = gql`
  mutation AddupdUMember($email: String!, $first_name: String!, $last_name: String!, $privilege: Int!) {
    addupdUMember(email: $email, first_name: $first_name, last_name: $last_name, privilege: $privilege) {
      resultRows
      statusCode
      __typename
    }
  }
`;
export type AddupdUMemberMutationFn = Apollo.MutationFunction<AddupdUMemberMutation, AddupdUMemberMutationVariables>;

/**
 * __useAddupdUMemberMutation__
 *
 * To run a mutation, you first call `useAddupdUMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddupdUMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addupdUMemberMutation, { data, loading, error }] = useAddupdUMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useAddupdUMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<AddupdUMemberMutation, AddupdUMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddupdUMemberMutation, AddupdUMemberMutationVariables>(AddupdUMemberDocument, options);
}
export type AddupdUMemberMutationHookResult = ReturnType<typeof useAddupdUMemberMutation>;
export type AddupdUMemberMutationResult = Apollo.MutationResult<AddupdUMemberMutation>;
export type AddupdUMemberMutationOptions = Apollo.BaseMutationOptions<
  AddupdUMemberMutation,
  AddupdUMemberMutationVariables
>;
export const DeleteSCampaignByIdDocument = gql`
  mutation DeleteSCampaignById($id: Int!) {
    deleteSCampaignById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type DeleteSCampaignByIdMutationFn = Apollo.MutationFunction<
  DeleteSCampaignByIdMutation,
  DeleteSCampaignByIdMutationVariables
>;

/**
 * __useDeleteSCampaignByIdMutation__
 *
 * To run a mutation, you first call `useDeleteSCampaignByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSCampaignByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSCampaignByIdMutation, { data, loading, error }] = useDeleteSCampaignByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSCampaignByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSCampaignByIdMutation, DeleteSCampaignByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSCampaignByIdMutation, DeleteSCampaignByIdMutationVariables>(
    DeleteSCampaignByIdDocument,
    options
  );
}
export type DeleteSCampaignByIdMutationHookResult = ReturnType<typeof useDeleteSCampaignByIdMutation>;
export type DeleteSCampaignByIdMutationResult = Apollo.MutationResult<DeleteSCampaignByIdMutation>;
export type DeleteSCampaignByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteSCampaignByIdMutation,
  DeleteSCampaignByIdMutationVariables
>;
export const DeleteSEgiftCodeByEgiftCodeDocument = gql`
  mutation DeleteSEgiftCodeByEgiftCode($egift_code: Int!) {
    deleteSEgiftCodeByEgiftCode(egift_code: $egift_code) {
      resultRows
      __typename
    }
  }
`;
export type DeleteSEgiftCodeByEgiftCodeMutationFn = Apollo.MutationFunction<
  DeleteSEgiftCodeByEgiftCodeMutation,
  DeleteSEgiftCodeByEgiftCodeMutationVariables
>;

/**
 * __useDeleteSEgiftCodeByEgiftCodeMutation__
 *
 * To run a mutation, you first call `useDeleteSEgiftCodeByEgiftCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSEgiftCodeByEgiftCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSEgiftCodeByEgiftCodeMutation, { data, loading, error }] = useDeleteSEgiftCodeByEgiftCodeMutation({
 *   variables: {
 *      egift_code: // value for 'egift_code'
 *   },
 * });
 */
export function useDeleteSEgiftCodeByEgiftCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSEgiftCodeByEgiftCodeMutation,
    DeleteSEgiftCodeByEgiftCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSEgiftCodeByEgiftCodeMutation, DeleteSEgiftCodeByEgiftCodeMutationVariables>(
    DeleteSEgiftCodeByEgiftCodeDocument,
    options
  );
}
export type DeleteSEgiftCodeByEgiftCodeMutationHookResult = ReturnType<typeof useDeleteSEgiftCodeByEgiftCodeMutation>;
export type DeleteSEgiftCodeByEgiftCodeMutationResult = Apollo.MutationResult<DeleteSEgiftCodeByEgiftCodeMutation>;
export type DeleteSEgiftCodeByEgiftCodeMutationOptions = Apollo.BaseMutationOptions<
  DeleteSEgiftCodeByEgiftCodeMutation,
  DeleteSEgiftCodeByEgiftCodeMutationVariables
>;
export const DeleteSWithholdDayByYearDocument = gql`
  mutation DeleteSWithholdDayByYear($year: Int!) {
    deleteSWithholdDayByYear(year: $year) {
      resultRows
      __typename
    }
  }
`;
export type DeleteSWithholdDayByYearMutationFn = Apollo.MutationFunction<
  DeleteSWithholdDayByYearMutation,
  DeleteSWithholdDayByYearMutationVariables
>;

/**
 * __useDeleteSWithholdDayByYearMutation__
 *
 * To run a mutation, you first call `useDeleteSWithholdDayByYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSWithholdDayByYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSWithholdDayByYearMutation, { data, loading, error }] = useDeleteSWithholdDayByYearMutation({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useDeleteSWithholdDayByYearMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSWithholdDayByYearMutation, DeleteSWithholdDayByYearMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSWithholdDayByYearMutation, DeleteSWithholdDayByYearMutationVariables>(
    DeleteSWithholdDayByYearDocument,
    options
  );
}
export type DeleteSWithholdDayByYearMutationHookResult = ReturnType<typeof useDeleteSWithholdDayByYearMutation>;
export type DeleteSWithholdDayByYearMutationResult = Apollo.MutationResult<DeleteSWithholdDayByYearMutation>;
export type DeleteSWithholdDayByYearMutationOptions = Apollo.BaseMutationOptions<
  DeleteSWithholdDayByYearMutation,
  DeleteSWithholdDayByYearMutationVariables
>;
export const DeleteTTpmemBlockByIdDocument = gql`
  mutation DeleteTTpmemBlockById($id: Int!, $block_id: Int!) {
    deleteTTpmemBlockById(id: $id, block_id: $block_id) {
      resultRows
      __typename
    }
  }
`;
export type DeleteTTpmemBlockByIdMutationFn = Apollo.MutationFunction<
  DeleteTTpmemBlockByIdMutation,
  DeleteTTpmemBlockByIdMutationVariables
>;

/**
 * __useDeleteTTpmemBlockByIdMutation__
 *
 * To run a mutation, you first call `useDeleteTTpmemBlockByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTTpmemBlockByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTTpmemBlockByIdMutation, { data, loading, error }] = useDeleteTTpmemBlockByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      block_id: // value for 'block_id'
 *   },
 * });
 */
export function useDeleteTTpmemBlockByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTTpmemBlockByIdMutation, DeleteTTpmemBlockByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTTpmemBlockByIdMutation, DeleteTTpmemBlockByIdMutationVariables>(
    DeleteTTpmemBlockByIdDocument,
    options
  );
}
export type DeleteTTpmemBlockByIdMutationHookResult = ReturnType<typeof useDeleteTTpmemBlockByIdMutation>;
export type DeleteTTpmemBlockByIdMutationResult = Apollo.MutationResult<DeleteTTpmemBlockByIdMutation>;
export type DeleteTTpmemBlockByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteTTpmemBlockByIdMutation,
  DeleteTTpmemBlockByIdMutationVariables
>;
export const DeleteUMemberByIdDocument = gql`
  mutation DeleteUMemberById($id: Int!) {
    deleteUMemberById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type DeleteUMemberByIdMutationFn = Apollo.MutationFunction<
  DeleteUMemberByIdMutation,
  DeleteUMemberByIdMutationVariables
>;

/**
 * __useDeleteUMemberByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUMemberByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUMemberByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUMemberByIdMutation, { data, loading, error }] = useDeleteUMemberByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUMemberByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUMemberByIdMutation, DeleteUMemberByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUMemberByIdMutation, DeleteUMemberByIdMutationVariables>(
    DeleteUMemberByIdDocument,
    options
  );
}
export type DeleteUMemberByIdMutationHookResult = ReturnType<typeof useDeleteUMemberByIdMutation>;
export type DeleteUMemberByIdMutationResult = Apollo.MutationResult<DeleteUMemberByIdMutation>;
export type DeleteUMemberByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteUMemberByIdMutation,
  DeleteUMemberByIdMutationVariables
>;
export const DeleteUSendNotifyDocument = gql`
  mutation DeleteUSendNotify($id: Int!) {
    deleteUSendNotify(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type DeleteUSendNotifyMutationFn = Apollo.MutationFunction<
  DeleteUSendNotifyMutation,
  DeleteUSendNotifyMutationVariables
>;

/**
 * __useDeleteUSendNotifyMutation__
 *
 * To run a mutation, you first call `useDeleteUSendNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUSendNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUSendNotifyMutation, { data, loading, error }] = useDeleteUSendNotifyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUSendNotifyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUSendNotifyMutation, DeleteUSendNotifyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUSendNotifyMutation, DeleteUSendNotifyMutationVariables>(
    DeleteUSendNotifyDocument,
    options
  );
}
export type DeleteUSendNotifyMutationHookResult = ReturnType<typeof useDeleteUSendNotifyMutation>;
export type DeleteUSendNotifyMutationResult = Apollo.MutationResult<DeleteUSendNotifyMutation>;
export type DeleteUSendNotifyMutationOptions = Apollo.BaseMutationOptions<
  DeleteUSendNotifyMutation,
  DeleteUSendNotifyMutationVariables
>;
export const GetVtMemberBaseRecvNotifyByIdDocument = gql`
  mutation GetVTMemberBaseRecvNotifyById($id: Int!) {
    getVTMemberBaseRecvNotifyById(id: $id) {
      account_name
      account_no
      account_type
      address
      address2
      again
      bank
      belong_total
      biz_id
      biz_name
      block_num
      branch_name
      brand_id
      brand_name
      city
      close_time
      code
      corporate_no
      corporate_type
      created_date
      email
      favorite_num
      group_num
      id
      image
      invite_code
      join_date
      manager
      mco_address
      mco_address2
      mco_city
      mco_m_first_kana
      mco_m_first_name
      mco_m_kana
      mco_m_last_kana
      mco_m_last_name
      mco_m_name
      mco_name
      mco_phone
      mco_state
      mco_t_first_kana
      mco_t_first_name
      mco_t_kana
      mco_t_last_kana
      mco_t_last_name
      mco_t_name
      mco_t_phone
      mco_zip_code
      mgr_birthday
      mgr_dob_day
      mgr_dob_month
      mgr_dob_year
      mgr_first_kana
      mgr_first_name
      mgr_id
      mgr_last_kana
      mgr_last_name
      mgr_phone
      open_time
      opening
      parking
      parking_val
      pause_date
      payment_method
      personal_bank
      phone
      quit_date
      restart_date
      state
      status
      store_name
      ticket_num
      total_adopt
      zip_code
      lat
      lng
      __typename
    }
  }
`;
export type GetVtMemberBaseRecvNotifyByIdMutationFn = Apollo.MutationFunction<
  GetVtMemberBaseRecvNotifyByIdMutation,
  GetVtMemberBaseRecvNotifyByIdMutationVariables
>;

/**
 * __useGetVtMemberBaseRecvNotifyByIdMutation__
 *
 * To run a mutation, you first call `useGetVtMemberBaseRecvNotifyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberBaseRecvNotifyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVtMemberBaseRecvNotifyByIdMutation, { data, loading, error }] = useGetVtMemberBaseRecvNotifyByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberBaseRecvNotifyByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetVtMemberBaseRecvNotifyByIdMutation,
    GetVtMemberBaseRecvNotifyByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetVtMemberBaseRecvNotifyByIdMutation, GetVtMemberBaseRecvNotifyByIdMutationVariables>(
    GetVtMemberBaseRecvNotifyByIdDocument,
    options
  );
}
export type GetVtMemberBaseRecvNotifyByIdMutationHookResult = ReturnType<
  typeof useGetVtMemberBaseRecvNotifyByIdMutation
>;
export type GetVtMemberBaseRecvNotifyByIdMutationResult = Apollo.MutationResult<GetVtMemberBaseRecvNotifyByIdMutation>;
export type GetVtMemberBaseRecvNotifyByIdMutationOptions = Apollo.BaseMutationOptions<
  GetVtMemberBaseRecvNotifyByIdMutation,
  GetVtMemberBaseRecvNotifyByIdMutationVariables
>;
export const GetupdURecvNotifyByS3K4Document = gql`
  mutation GetupdURecvNotifyByS3K4 {
    getupdURecvNotifyByS3K4 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;
export type GetupdURecvNotifyByS3K4MutationFn = Apollo.MutationFunction<
  GetupdURecvNotifyByS3K4Mutation,
  GetupdURecvNotifyByS3K4MutationVariables
>;

/**
 * __useGetupdURecvNotifyByS3K4Mutation__
 *
 * To run a mutation, you first call `useGetupdURecvNotifyByS3K4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdURecvNotifyByS3K4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdURecvNotifyByS3K4Mutation, { data, loading, error }] = useGetupdURecvNotifyByS3K4Mutation({
 *   variables: {
 *   },
 * });
 */
export function useGetupdURecvNotifyByS3K4Mutation(
  baseOptions?: Apollo.MutationHookOptions<GetupdURecvNotifyByS3K4Mutation, GetupdURecvNotifyByS3K4MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetupdURecvNotifyByS3K4Mutation, GetupdURecvNotifyByS3K4MutationVariables>(
    GetupdURecvNotifyByS3K4Document,
    options
  );
}
export type GetupdURecvNotifyByS3K4MutationHookResult = ReturnType<typeof useGetupdURecvNotifyByS3K4Mutation>;
export type GetupdURecvNotifyByS3K4MutationResult = Apollo.MutationResult<GetupdURecvNotifyByS3K4Mutation>;
export type GetupdURecvNotifyByS3K4MutationOptions = Apollo.BaseMutationOptions<
  GetupdURecvNotifyByS3K4Mutation,
  GetupdURecvNotifyByS3K4MutationVariables
>;
export const GetupdVbiPaymentListNewlyPayArbeitDocument = gql`
  mutation GetupdVBIPaymentListNewlyPayArbeit {
    getupdVBIPaymentListNewlyPayArbeit {
      newly {
        im_inv
        im_pay
        mn_pay
        __typename
      }
      payment {
        btmem_id
        name
        newly
        output_date
        output_number
        price
        req_csv_no
        req_date
        req_number
        request_no
        status
        __typename
      }
      __typename
    }
  }
`;
export type GetupdVbiPaymentListNewlyPayArbeitMutationFn = Apollo.MutationFunction<
  GetupdVbiPaymentListNewlyPayArbeitMutation,
  GetupdVbiPaymentListNewlyPayArbeitMutationVariables
>;

/**
 * __useGetupdVbiPaymentListNewlyPayArbeitMutation__
 *
 * To run a mutation, you first call `useGetupdVbiPaymentListNewlyPayArbeitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdVbiPaymentListNewlyPayArbeitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdVbiPaymentListNewlyPayArbeitMutation, { data, loading, error }] = useGetupdVbiPaymentListNewlyPayArbeitMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetupdVbiPaymentListNewlyPayArbeitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetupdVbiPaymentListNewlyPayArbeitMutation,
    GetupdVbiPaymentListNewlyPayArbeitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetupdVbiPaymentListNewlyPayArbeitMutation,
    GetupdVbiPaymentListNewlyPayArbeitMutationVariables
  >(GetupdVbiPaymentListNewlyPayArbeitDocument, options);
}
export type GetupdVbiPaymentListNewlyPayArbeitMutationHookResult = ReturnType<
  typeof useGetupdVbiPaymentListNewlyPayArbeitMutation
>;
export type GetupdVbiPaymentListNewlyPayArbeitMutationResult =
  Apollo.MutationResult<GetupdVbiPaymentListNewlyPayArbeitMutation>;
export type GetupdVbiPaymentListNewlyPayArbeitMutationOptions = Apollo.BaseMutationOptions<
  GetupdVbiPaymentListNewlyPayArbeitMutation,
  GetupdVbiPaymentListNewlyPayArbeitMutationVariables
>;
export const GetupdVbIdentificationInfoByBtmemIdDocument = gql`
  mutation GetupdVBIdentificationInfoByBtmemId($btmem_id: Int!) {
    getupdVBIdentificationInfoByBtmemId(btmem_id: $btmem_id) {
      address
      address2
      age
      birthday
      btmem_id
      btmem_number
      city
      created_date
      ecn
      email
      gender
      general_licenses
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      is_japanese
      kana
      name
      nationality
      parental
      parental_name
      period_date
      phone
      reg_hs_student
      reg_minor
      residence_name
      rule
      school
      state
      status
      student
      submit_date
      zip_code
      __typename
    }
  }
`;
export type GetupdVbIdentificationInfoByBtmemIdMutationFn = Apollo.MutationFunction<
  GetupdVbIdentificationInfoByBtmemIdMutation,
  GetupdVbIdentificationInfoByBtmemIdMutationVariables
>;

/**
 * __useGetupdVbIdentificationInfoByBtmemIdMutation__
 *
 * To run a mutation, you first call `useGetupdVbIdentificationInfoByBtmemIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdVbIdentificationInfoByBtmemIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdVbIdentificationInfoByBtmemIdMutation, { data, loading, error }] = useGetupdVbIdentificationInfoByBtmemIdMutation({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetupdVbIdentificationInfoByBtmemIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetupdVbIdentificationInfoByBtmemIdMutation,
    GetupdVbIdentificationInfoByBtmemIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetupdVbIdentificationInfoByBtmemIdMutation,
    GetupdVbIdentificationInfoByBtmemIdMutationVariables
  >(GetupdVbIdentificationInfoByBtmemIdDocument, options);
}
export type GetupdVbIdentificationInfoByBtmemIdMutationHookResult = ReturnType<
  typeof useGetupdVbIdentificationInfoByBtmemIdMutation
>;
export type GetupdVbIdentificationInfoByBtmemIdMutationResult =
  Apollo.MutationResult<GetupdVbIdentificationInfoByBtmemIdMutation>;
export type GetupdVbIdentificationInfoByBtmemIdMutationOptions = Apollo.BaseMutationOptions<
  GetupdVbIdentificationInfoByBtmemIdMutation,
  GetupdVbIdentificationInfoByBtmemIdMutationVariables
>;
export const GetupdVbIdentificationInfoByKeyDocument = gql`
  mutation GetupdVBIdentificationInfoByKey($internal_key: Int!) {
    getupdVBIdentificationInfoByKey(internal_key: $internal_key) {
      address
      address2
      age
      birthday
      btmem_id
      btmem_number
      city
      created_date
      ecn
      email
      gender
      general_licenses
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      is_japanese
      kana
      name
      nationality
      parental
      parental_name
      period_date
      phone
      reg_hs_student
      reg_minor
      residence_name
      rule
      school
      state
      status
      student
      submit_date
      zip_code
      __typename
    }
  }
`;
export type GetupdVbIdentificationInfoByKeyMutationFn = Apollo.MutationFunction<
  GetupdVbIdentificationInfoByKeyMutation,
  GetupdVbIdentificationInfoByKeyMutationVariables
>;

/**
 * __useGetupdVbIdentificationInfoByKeyMutation__
 *
 * To run a mutation, you first call `useGetupdVbIdentificationInfoByKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdVbIdentificationInfoByKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdVbIdentificationInfoByKeyMutation, { data, loading, error }] = useGetupdVbIdentificationInfoByKeyMutation({
 *   variables: {
 *      internal_key: // value for 'internal_key'
 *   },
 * });
 */
export function useGetupdVbIdentificationInfoByKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetupdVbIdentificationInfoByKeyMutation,
    GetupdVbIdentificationInfoByKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetupdVbIdentificationInfoByKeyMutation, GetupdVbIdentificationInfoByKeyMutationVariables>(
    GetupdVbIdentificationInfoByKeyDocument,
    options
  );
}
export type GetupdVbIdentificationInfoByKeyMutationHookResult = ReturnType<
  typeof useGetupdVbIdentificationInfoByKeyMutation
>;
export type GetupdVbIdentificationInfoByKeyMutationResult =
  Apollo.MutationResult<GetupdVbIdentificationInfoByKeyMutation>;
export type GetupdVbIdentificationInfoByKeyMutationOptions = Apollo.BaseMutationOptions<
  GetupdVbIdentificationInfoByKeyMutation,
  GetupdVbIdentificationInfoByKeyMutationVariables
>;
export const GetupdVbMemberBaseByIdDocument = gql`
  mutation GetupdVBMemberBaseById($id: Int!) {
    getupdVBMemberBaseById(id: $id) {
      account_name
      account_no
      account_type
      address
      address2
      age
      attr_id
      attribute
      badge_name
      bank
      belong_biz_count
      birthday
      birthday2
      branch_name
      btmem_number
      cancel_count
      cancel_per
      city
      created_date
      email
      gender
      gender_value
      general_licenses
      id
      identification
      image
      imminent_count
      imminent_per
      kana
      late_count
      late_per
      lc_biz_count
      lc_biz_names
      license_list
      name
      nationality
      pause_end_day
      period_date
      phone
      rank_biz_count
      rank_biz_names
      rank_names
      reg_biz_list
      residence_name
      score
      skip_count
      state
      state_count
      status
      sub_names
      tp_biz_names
      tpmem_names
      uniform_id
      uniform_size
      worked_count
      zip_code
      __typename
    }
  }
`;
export type GetupdVbMemberBaseByIdMutationFn = Apollo.MutationFunction<
  GetupdVbMemberBaseByIdMutation,
  GetupdVbMemberBaseByIdMutationVariables
>;

/**
 * __useGetupdVbMemberBaseByIdMutation__
 *
 * To run a mutation, you first call `useGetupdVbMemberBaseByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdVbMemberBaseByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdVbMemberBaseByIdMutation, { data, loading, error }] = useGetupdVbMemberBaseByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetupdVbMemberBaseByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<GetupdVbMemberBaseByIdMutation, GetupdVbMemberBaseByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetupdVbMemberBaseByIdMutation, GetupdVbMemberBaseByIdMutationVariables>(
    GetupdVbMemberBaseByIdDocument,
    options
  );
}
export type GetupdVbMemberBaseByIdMutationHookResult = ReturnType<typeof useGetupdVbMemberBaseByIdMutation>;
export type GetupdVbMemberBaseByIdMutationResult = Apollo.MutationResult<GetupdVbMemberBaseByIdMutation>;
export type GetupdVbMemberBaseByIdMutationOptions = Apollo.BaseMutationOptions<
  GetupdVbMemberBaseByIdMutation,
  GetupdVbMemberBaseByIdMutationVariables
>;
export const GetupdVtmPaymentListNewlyPayRefundDocument = gql`
  mutation GetupdVTMPaymentListNewlyPayRefund {
    getupdVTMPaymentListNewlyPayRefund {
      newly {
        re_pay
        __typename
      }
      payment {
        bill_date
        csv_no
        name
        newly
        output_date
        output_number
        price
        tpmem_id
        __typename
      }
      __typename
    }
  }
`;
export type GetupdVtmPaymentListNewlyPayRefundMutationFn = Apollo.MutationFunction<
  GetupdVtmPaymentListNewlyPayRefundMutation,
  GetupdVtmPaymentListNewlyPayRefundMutationVariables
>;

/**
 * __useGetupdVtmPaymentListNewlyPayRefundMutation__
 *
 * To run a mutation, you first call `useGetupdVtmPaymentListNewlyPayRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetupdVtmPaymentListNewlyPayRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getupdVtmPaymentListNewlyPayRefundMutation, { data, loading, error }] = useGetupdVtmPaymentListNewlyPayRefundMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetupdVtmPaymentListNewlyPayRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetupdVtmPaymentListNewlyPayRefundMutation,
    GetupdVtmPaymentListNewlyPayRefundMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetupdVtmPaymentListNewlyPayRefundMutation,
    GetupdVtmPaymentListNewlyPayRefundMutationVariables
  >(GetupdVtmPaymentListNewlyPayRefundDocument, options);
}
export type GetupdVtmPaymentListNewlyPayRefundMutationHookResult = ReturnType<
  typeof useGetupdVtmPaymentListNewlyPayRefundMutation
>;
export type GetupdVtmPaymentListNewlyPayRefundMutationResult =
  Apollo.MutationResult<GetupdVtmPaymentListNewlyPayRefundMutation>;
export type GetupdVtmPaymentListNewlyPayRefundMutationOptions = Apollo.BaseMutationOptions<
  GetupdVtmPaymentListNewlyPayRefundMutation,
  GetupdVtmPaymentListNewlyPayRefundMutationVariables
>;
export const LoginUMemberDocument = gql`
  mutation LoginUMember($cognito_id: String!, $email: String!, $password: String!) {
    loginUMember(cognito_id: $cognito_id, email: $email, password: $password) {
      cognito_id
      email
      first_kana
      first_name
      id
      last_kana
      last_name
      password
      privilege
      status
      tfa
      __typename
    }
  }
`;
export type LoginUMemberMutationFn = Apollo.MutationFunction<LoginUMemberMutation, LoginUMemberMutationVariables>;

/**
 * __useLoginUMemberMutation__
 *
 * To run a mutation, you first call `useLoginUMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUMemberMutation, { data, loading, error }] = useLoginUMemberMutation({
 *   variables: {
 *      cognito_id: // value for 'cognito_id'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUMemberMutation, LoginUMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUMemberMutation, LoginUMemberMutationVariables>(LoginUMemberDocument, options);
}
export type LoginUMemberMutationHookResult = ReturnType<typeof useLoginUMemberMutation>;
export type LoginUMemberMutationResult = Apollo.MutationResult<LoginUMemberMutation>;
export type LoginUMemberMutationOptions = Apollo.BaseMutationOptions<
  LoginUMemberMutation,
  LoginUMemberMutationVariables
>;
export const UpdateAuthorizationDocument = gql`
  mutation UpdateAuthorization($code: String!) {
    updateAuthorization(code: $code) {
      resultRows
      __typename
    }
  }
`;
export type UpdateAuthorizationMutationFn = Apollo.MutationFunction<
  UpdateAuthorizationMutation,
  UpdateAuthorizationMutationVariables
>;

/**
 * __useUpdateAuthorizationMutation__
 *
 * To run a mutation, you first call `useUpdateAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuthorizationMutation, { data, loading, error }] = useUpdateAuthorizationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUpdateAuthorizationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAuthorizationMutation, UpdateAuthorizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAuthorizationMutation, UpdateAuthorizationMutationVariables>(
    UpdateAuthorizationDocument,
    options
  );
}
export type UpdateAuthorizationMutationHookResult = ReturnType<typeof useUpdateAuthorizationMutation>;
export type UpdateAuthorizationMutationResult = Apollo.MutationResult<UpdateAuthorizationMutation>;
export type UpdateAuthorizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAuthorizationMutation,
  UpdateAuthorizationMutationVariables
>;
export const UpdateBArbeitScoreVisibleByWorkIdDocument = gql`
  mutation UpdateBArbeitScoreVisibleByWorkId($work_id: Int!) {
    updateBArbeitScoreVisibleByWorkId(work_id: $work_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBArbeitScoreVisibleByWorkIdMutationFn = Apollo.MutationFunction<
  UpdateBArbeitScoreVisibleByWorkIdMutation,
  UpdateBArbeitScoreVisibleByWorkIdMutationVariables
>;

/**
 * __useUpdateBArbeitScoreVisibleByWorkIdMutation__
 *
 * To run a mutation, you first call `useUpdateBArbeitScoreVisibleByWorkIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBArbeitScoreVisibleByWorkIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBArbeitScoreVisibleByWorkIdMutation, { data, loading, error }] = useUpdateBArbeitScoreVisibleByWorkIdMutation({
 *   variables: {
 *      work_id: // value for 'work_id'
 *   },
 * });
 */
export function useUpdateBArbeitScoreVisibleByWorkIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBArbeitScoreVisibleByWorkIdMutation,
    UpdateBArbeitScoreVisibleByWorkIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBArbeitScoreVisibleByWorkIdMutation,
    UpdateBArbeitScoreVisibleByWorkIdMutationVariables
  >(UpdateBArbeitScoreVisibleByWorkIdDocument, options);
}
export type UpdateBArbeitScoreVisibleByWorkIdMutationHookResult = ReturnType<
  typeof useUpdateBArbeitScoreVisibleByWorkIdMutation
>;
export type UpdateBArbeitScoreVisibleByWorkIdMutationResult =
  Apollo.MutationResult<UpdateBArbeitScoreVisibleByWorkIdMutation>;
export type UpdateBArbeitScoreVisibleByWorkIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBArbeitScoreVisibleByWorkIdMutation,
  UpdateBArbeitScoreVisibleByWorkIdMutationVariables
>;
export const UpdateBIdentificationApprovalByIdSubmitDateDocument = gql`
  mutation UpdateBIdentificationApprovalByIdSubmitDate($id: Int!, $submit_date: String!) {
    updateBIdentificationApprovalByIdSubmitDate(id: $id, submit_date: $submit_date) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBIdentificationApprovalByIdSubmitDateMutationFn = Apollo.MutationFunction<
  UpdateBIdentificationApprovalByIdSubmitDateMutation,
  UpdateBIdentificationApprovalByIdSubmitDateMutationVariables
>;

/**
 * __useUpdateBIdentificationApprovalByIdSubmitDateMutation__
 *
 * To run a mutation, you first call `useUpdateBIdentificationApprovalByIdSubmitDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBIdentificationApprovalByIdSubmitDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBIdentificationApprovalByIdSubmitDateMutation, { data, loading, error }] = useUpdateBIdentificationApprovalByIdSubmitDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      submit_date: // value for 'submit_date'
 *   },
 * });
 */
export function useUpdateBIdentificationApprovalByIdSubmitDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBIdentificationApprovalByIdSubmitDateMutation,
    UpdateBIdentificationApprovalByIdSubmitDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBIdentificationApprovalByIdSubmitDateMutation,
    UpdateBIdentificationApprovalByIdSubmitDateMutationVariables
  >(UpdateBIdentificationApprovalByIdSubmitDateDocument, options);
}
export type UpdateBIdentificationApprovalByIdSubmitDateMutationHookResult = ReturnType<
  typeof useUpdateBIdentificationApprovalByIdSubmitDateMutation
>;
export type UpdateBIdentificationApprovalByIdSubmitDateMutationResult =
  Apollo.MutationResult<UpdateBIdentificationApprovalByIdSubmitDateMutation>;
export type UpdateBIdentificationApprovalByIdSubmitDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBIdentificationApprovalByIdSubmitDateMutation,
  UpdateBIdentificationApprovalByIdSubmitDateMutationVariables
>;
export const UpdateBIdentificationDenialByIdSubmitDateDocument = gql`
  mutation UpdateBIdentificationDenialByIdSubmitDate($id: Int!, $reason: String!, $submit_date: String!) {
    updateBIdentificationDenialByIdSubmitDate(id: $id, reason: $reason, submit_date: $submit_date) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBIdentificationDenialByIdSubmitDateMutationFn = Apollo.MutationFunction<
  UpdateBIdentificationDenialByIdSubmitDateMutation,
  UpdateBIdentificationDenialByIdSubmitDateMutationVariables
>;

/**
 * __useUpdateBIdentificationDenialByIdSubmitDateMutation__
 *
 * To run a mutation, you first call `useUpdateBIdentificationDenialByIdSubmitDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBIdentificationDenialByIdSubmitDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBIdentificationDenialByIdSubmitDateMutation, { data, loading, error }] = useUpdateBIdentificationDenialByIdSubmitDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      submit_date: // value for 'submit_date'
 *   },
 * });
 */
export function useUpdateBIdentificationDenialByIdSubmitDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBIdentificationDenialByIdSubmitDateMutation,
    UpdateBIdentificationDenialByIdSubmitDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBIdentificationDenialByIdSubmitDateMutation,
    UpdateBIdentificationDenialByIdSubmitDateMutationVariables
  >(UpdateBIdentificationDenialByIdSubmitDateDocument, options);
}
export type UpdateBIdentificationDenialByIdSubmitDateMutationHookResult = ReturnType<
  typeof useUpdateBIdentificationDenialByIdSubmitDateMutation
>;
export type UpdateBIdentificationDenialByIdSubmitDateMutationResult =
  Apollo.MutationResult<UpdateBIdentificationDenialByIdSubmitDateMutation>;
export type UpdateBIdentificationDenialByIdSubmitDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateBIdentificationDenialByIdSubmitDateMutation,
  UpdateBIdentificationDenialByIdSubmitDateMutationVariables
>;
export const UpdateBMemberDataByIdDocument = gql`
  mutation UpdateBMemberDataById(
    $id: Int!
    $last_name: String!
    $first_name: String!
    $last_kana: String!
    $first_kana: String!
    $gender: Int!
    $dob: String!
  ) {
    updateBMemberDataById(
      id: $id
      last_name: $last_name
      first_name: $first_name
      last_kana: $last_kana
      first_kana: $first_kana
      gender: $gender
      dob: $dob
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBMemberDataByIdMutationFn = Apollo.MutationFunction<
  UpdateBMemberDataByIdMutation,
  UpdateBMemberDataByIdMutationVariables
>;

/**
 * __useUpdateBMemberDataByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBMemberDataByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBMemberDataByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBMemberDataByIdMutation, { data, loading, error }] = useUpdateBMemberDataByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      last_name: // value for 'last_name'
 *      first_name: // value for 'first_name'
 *      last_kana: // value for 'last_kana'
 *      first_kana: // value for 'first_kana'
 *      gender: // value for 'gender'
 *      dob: // value for 'dob'
 *   },
 * });
 */
export function useUpdateBMemberDataByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBMemberDataByIdMutation, UpdateBMemberDataByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBMemberDataByIdMutation, UpdateBMemberDataByIdMutationVariables>(
    UpdateBMemberDataByIdDocument,
    options
  );
}
export type UpdateBMemberDataByIdMutationHookResult = ReturnType<typeof useUpdateBMemberDataByIdMutation>;
export type UpdateBMemberDataByIdMutationResult = Apollo.MutationResult<UpdateBMemberDataByIdMutation>;
export type UpdateBMemberDataByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBMemberDataByIdMutation,
  UpdateBMemberDataByIdMutationVariables
>;
export const UpdateBMemberStatusByIdDocument = gql`
  mutation UpdateBMemberStatusById($id: Int!, $status: Int!) {
    updateBMemberStatusById(id: $id, status: $status) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBMemberStatusByIdMutationFn = Apollo.MutationFunction<
  UpdateBMemberStatusByIdMutation,
  UpdateBMemberStatusByIdMutationVariables
>;

/**
 * __useUpdateBMemberStatusByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBMemberStatusByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBMemberStatusByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBMemberStatusByIdMutation, { data, loading, error }] = useUpdateBMemberStatusByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateBMemberStatusByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBMemberStatusByIdMutation, UpdateBMemberStatusByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBMemberStatusByIdMutation, UpdateBMemberStatusByIdMutationVariables>(
    UpdateBMemberStatusByIdDocument,
    options
  );
}
export type UpdateBMemberStatusByIdMutationHookResult = ReturnType<typeof useUpdateBMemberStatusByIdMutation>;
export type UpdateBMemberStatusByIdMutationResult = Apollo.MutationResult<UpdateBMemberStatusByIdMutation>;
export type UpdateBMemberStatusByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBMemberStatusByIdMutation,
  UpdateBMemberStatusByIdMutationVariables
>;
export const UpdateBMemberStatusPauseDateByIdDocument = gql`
  mutation UpdateBMemberStatusPauseDateById($id: Int!) {
    updateBMemberStatusPauseDateById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBMemberStatusPauseDateByIdMutationFn = Apollo.MutationFunction<
  UpdateBMemberStatusPauseDateByIdMutation,
  UpdateBMemberStatusPauseDateByIdMutationVariables
>;

/**
 * __useUpdateBMemberStatusPauseDateByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBMemberStatusPauseDateByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBMemberStatusPauseDateByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBMemberStatusPauseDateByIdMutation, { data, loading, error }] = useUpdateBMemberStatusPauseDateByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBMemberStatusPauseDateByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBMemberStatusPauseDateByIdMutation,
    UpdateBMemberStatusPauseDateByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBMemberStatusPauseDateByIdMutation,
    UpdateBMemberStatusPauseDateByIdMutationVariables
  >(UpdateBMemberStatusPauseDateByIdDocument, options);
}
export type UpdateBMemberStatusPauseDateByIdMutationHookResult = ReturnType<
  typeof useUpdateBMemberStatusPauseDateByIdMutation
>;
export type UpdateBMemberStatusPauseDateByIdMutationResult =
  Apollo.MutationResult<UpdateBMemberStatusPauseDateByIdMutation>;
export type UpdateBMemberStatusPauseDateByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBMemberStatusPauseDateByIdMutation,
  UpdateBMemberStatusPauseDateByIdMutationVariables
>;
export const UpdateBMemberStatusRestartDateByIdDocument = gql`
  mutation UpdateBMemberStatusRestartDateById($id: Int!) {
    updateBMemberStatusRestartDateById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBMemberStatusRestartDateByIdMutationFn = Apollo.MutationFunction<
  UpdateBMemberStatusRestartDateByIdMutation,
  UpdateBMemberStatusRestartDateByIdMutationVariables
>;

/**
 * __useUpdateBMemberStatusRestartDateByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBMemberStatusRestartDateByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBMemberStatusRestartDateByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBMemberStatusRestartDateByIdMutation, { data, loading, error }] = useUpdateBMemberStatusRestartDateByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBMemberStatusRestartDateByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBMemberStatusRestartDateByIdMutation,
    UpdateBMemberStatusRestartDateByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBMemberStatusRestartDateByIdMutation,
    UpdateBMemberStatusRestartDateByIdMutationVariables
  >(UpdateBMemberStatusRestartDateByIdDocument, options);
}
export type UpdateBMemberStatusRestartDateByIdMutationHookResult = ReturnType<
  typeof useUpdateBMemberStatusRestartDateByIdMutation
>;
export type UpdateBMemberStatusRestartDateByIdMutationResult =
  Apollo.MutationResult<UpdateBMemberStatusRestartDateByIdMutation>;
export type UpdateBMemberStatusRestartDateByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBMemberStatusRestartDateByIdMutation,
  UpdateBMemberStatusRestartDateByIdMutationVariables
>;
export const UpdateBOfferS1ByIdDocument = gql`
  mutation UpdateBOfferS1ById($id: Int!) {
    updateBOfferS1ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBOfferS1ByIdMutationFn = Apollo.MutationFunction<
  UpdateBOfferS1ByIdMutation,
  UpdateBOfferS1ByIdMutationVariables
>;

/**
 * __useUpdateBOfferS1ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBOfferS1ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBOfferS1ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBOfferS1ByIdMutation, { data, loading, error }] = useUpdateBOfferS1ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBOfferS1ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBOfferS1ByIdMutation, UpdateBOfferS1ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBOfferS1ByIdMutation, UpdateBOfferS1ByIdMutationVariables>(
    UpdateBOfferS1ByIdDocument,
    options
  );
}
export type UpdateBOfferS1ByIdMutationHookResult = ReturnType<typeof useUpdateBOfferS1ByIdMutation>;
export type UpdateBOfferS1ByIdMutationResult = Apollo.MutationResult<UpdateBOfferS1ByIdMutation>;
export type UpdateBOfferS1ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBOfferS1ByIdMutation,
  UpdateBOfferS1ByIdMutationVariables
>;
export const UpdateBOfferS5ByIdDocument = gql`
  mutation UpdateBOfferS5ById($id: Int!) {
    updateBOfferS5ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateBOfferS5ByIdMutationFn = Apollo.MutationFunction<
  UpdateBOfferS5ByIdMutation,
  UpdateBOfferS5ByIdMutationVariables
>;

/**
 * __useUpdateBOfferS5ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateBOfferS5ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBOfferS5ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBOfferS5ByIdMutation, { data, loading, error }] = useUpdateBOfferS5ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateBOfferS5ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBOfferS5ByIdMutation, UpdateBOfferS5ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBOfferS5ByIdMutation, UpdateBOfferS5ByIdMutationVariables>(
    UpdateBOfferS5ByIdDocument,
    options
  );
}
export type UpdateBOfferS5ByIdMutationHookResult = ReturnType<typeof useUpdateBOfferS5ByIdMutation>;
export type UpdateBOfferS5ByIdMutationResult = Apollo.MutationResult<UpdateBOfferS5ByIdMutation>;
export type UpdateBOfferS5ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateBOfferS5ByIdMutation,
  UpdateBOfferS5ByIdMutationVariables
>;
export const UpdateCBillingCsvDlByCsvNoDocument = gql`
  mutation UpdateCBillingCsvDlByCsvNo($csv_no: Int!, $download_uid: Int!) {
    updateCBillingCsvDlByCsvNo(csv_no: $csv_no, download_uid: $download_uid) {
      resultRows
      __typename
    }
  }
`;
export type UpdateCBillingCsvDlByCsvNoMutationFn = Apollo.MutationFunction<
  UpdateCBillingCsvDlByCsvNoMutation,
  UpdateCBillingCsvDlByCsvNoMutationVariables
>;

/**
 * __useUpdateCBillingCsvDlByCsvNoMutation__
 *
 * To run a mutation, you first call `useUpdateCBillingCsvDlByCsvNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCBillingCsvDlByCsvNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCBillingCsvDlByCsvNoMutation, { data, loading, error }] = useUpdateCBillingCsvDlByCsvNoMutation({
 *   variables: {
 *      csv_no: // value for 'csv_no'
 *      download_uid: // value for 'download_uid'
 *   },
 * });
 */
export function useUpdateCBillingCsvDlByCsvNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCBillingCsvDlByCsvNoMutation,
    UpdateCBillingCsvDlByCsvNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCBillingCsvDlByCsvNoMutation, UpdateCBillingCsvDlByCsvNoMutationVariables>(
    UpdateCBillingCsvDlByCsvNoDocument,
    options
  );
}
export type UpdateCBillingCsvDlByCsvNoMutationHookResult = ReturnType<typeof useUpdateCBillingCsvDlByCsvNoMutation>;
export type UpdateCBillingCsvDlByCsvNoMutationResult = Apollo.MutationResult<UpdateCBillingCsvDlByCsvNoMutation>;
export type UpdateCBillingCsvDlByCsvNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCBillingCsvDlByCsvNoMutation,
  UpdateCBillingCsvDlByCsvNoMutationVariables
>;
export const UpdateCMonthPayCsvDlByCsvNoDocument = gql`
  mutation UpdateCMonthPayCsvDlByCsvNo($csv_no: Int!, $download_uid: Int!) {
    updateCMonthPayCsvDlByCsvNo(csv_no: $csv_no, download_uid: $download_uid) {
      resultRows
      __typename
    }
  }
`;
export type UpdateCMonthPayCsvDlByCsvNoMutationFn = Apollo.MutationFunction<
  UpdateCMonthPayCsvDlByCsvNoMutation,
  UpdateCMonthPayCsvDlByCsvNoMutationVariables
>;

/**
 * __useUpdateCMonthPayCsvDlByCsvNoMutation__
 *
 * To run a mutation, you first call `useUpdateCMonthPayCsvDlByCsvNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCMonthPayCsvDlByCsvNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCMonthPayCsvDlByCsvNoMutation, { data, loading, error }] = useUpdateCMonthPayCsvDlByCsvNoMutation({
 *   variables: {
 *      csv_no: // value for 'csv_no'
 *      download_uid: // value for 'download_uid'
 *   },
 * });
 */
export function useUpdateCMonthPayCsvDlByCsvNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCMonthPayCsvDlByCsvNoMutation,
    UpdateCMonthPayCsvDlByCsvNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCMonthPayCsvDlByCsvNoMutation, UpdateCMonthPayCsvDlByCsvNoMutationVariables>(
    UpdateCMonthPayCsvDlByCsvNoDocument,
    options
  );
}
export type UpdateCMonthPayCsvDlByCsvNoMutationHookResult = ReturnType<typeof useUpdateCMonthPayCsvDlByCsvNoMutation>;
export type UpdateCMonthPayCsvDlByCsvNoMutationResult = Apollo.MutationResult<UpdateCMonthPayCsvDlByCsvNoMutation>;
export type UpdateCMonthPayCsvDlByCsvNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCMonthPayCsvDlByCsvNoMutation,
  UpdateCMonthPayCsvDlByCsvNoMutationVariables
>;
export const UpdateCPaymentAndPayCsvByRequestNoDocument = gql`
  mutation UpdateCPaymentAndPayCsvByRequestNo($output_uid: Int!, $request_no: String!, $wire_date: String!) {
    updateCPaymentAndPayCsvByRequestNo(output_uid: $output_uid, request_no: $request_no, wire_date: $wire_date) {
      resultRows
      __typename
    }
  }
`;
export type UpdateCPaymentAndPayCsvByRequestNoMutationFn = Apollo.MutationFunction<
  UpdateCPaymentAndPayCsvByRequestNoMutation,
  UpdateCPaymentAndPayCsvByRequestNoMutationVariables
>;

/**
 * __useUpdateCPaymentAndPayCsvByRequestNoMutation__
 *
 * To run a mutation, you first call `useUpdateCPaymentAndPayCsvByRequestNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCPaymentAndPayCsvByRequestNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCPaymentAndPayCsvByRequestNoMutation, { data, loading, error }] = useUpdateCPaymentAndPayCsvByRequestNoMutation({
 *   variables: {
 *      output_uid: // value for 'output_uid'
 *      request_no: // value for 'request_no'
 *      wire_date: // value for 'wire_date'
 *   },
 * });
 */
export function useUpdateCPaymentAndPayCsvByRequestNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCPaymentAndPayCsvByRequestNoMutation,
    UpdateCPaymentAndPayCsvByRequestNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCPaymentAndPayCsvByRequestNoMutation,
    UpdateCPaymentAndPayCsvByRequestNoMutationVariables
  >(UpdateCPaymentAndPayCsvByRequestNoDocument, options);
}
export type UpdateCPaymentAndPayCsvByRequestNoMutationHookResult = ReturnType<
  typeof useUpdateCPaymentAndPayCsvByRequestNoMutation
>;
export type UpdateCPaymentAndPayCsvByRequestNoMutationResult =
  Apollo.MutationResult<UpdateCPaymentAndPayCsvByRequestNoMutation>;
export type UpdateCPaymentAndPayCsvByRequestNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCPaymentAndPayCsvByRequestNoMutation,
  UpdateCPaymentAndPayCsvByRequestNoMutationVariables
>;
export const UpdateCRequestPayCsvDlByCsvNoDocument = gql`
  mutation UpdateCRequestPayCsvDlByCsvNo($csv_no: Int!, $download_uid: Int!) {
    updateCRequestPayCsvDlByCsvNo(csv_no: $csv_no, download_uid: $download_uid) {
      resultRows
      __typename
    }
  }
`;
export type UpdateCRequestPayCsvDlByCsvNoMutationFn = Apollo.MutationFunction<
  UpdateCRequestPayCsvDlByCsvNoMutation,
  UpdateCRequestPayCsvDlByCsvNoMutationVariables
>;

/**
 * __useUpdateCRequestPayCsvDlByCsvNoMutation__
 *
 * To run a mutation, you first call `useUpdateCRequestPayCsvDlByCsvNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCRequestPayCsvDlByCsvNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCRequestPayCsvDlByCsvNoMutation, { data, loading, error }] = useUpdateCRequestPayCsvDlByCsvNoMutation({
 *   variables: {
 *      csv_no: // value for 'csv_no'
 *      download_uid: // value for 'download_uid'
 *   },
 * });
 */
export function useUpdateCRequestPayCsvDlByCsvNoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCRequestPayCsvDlByCsvNoMutation,
    UpdateCRequestPayCsvDlByCsvNoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCRequestPayCsvDlByCsvNoMutation, UpdateCRequestPayCsvDlByCsvNoMutationVariables>(
    UpdateCRequestPayCsvDlByCsvNoDocument,
    options
  );
}
export type UpdateCRequestPayCsvDlByCsvNoMutationHookResult = ReturnType<
  typeof useUpdateCRequestPayCsvDlByCsvNoMutation
>;
export type UpdateCRequestPayCsvDlByCsvNoMutationResult = Apollo.MutationResult<UpdateCRequestPayCsvDlByCsvNoMutation>;
export type UpdateCRequestPayCsvDlByCsvNoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCRequestPayCsvDlByCsvNoMutation,
  UpdateCRequestPayCsvDlByCsvNoMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($id: Int!, $old_password: String!, $password: String!) {
    updatePassword(id: $id, old_password: $old_password, password: $password) {
      resultRows
      __typename
    }
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      old_password: // value for 'old_password'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const UpdatePasswordByEmailDocument = gql`
  mutation UpdatePasswordByEmail($email: String!, $password: String!) {
    updatePasswordByEmail(email: $email, password: $password) {
      resultRows
      __typename
    }
  }
`;
export type UpdatePasswordByEmailMutationFn = Apollo.MutationFunction<
  UpdatePasswordByEmailMutation,
  UpdatePasswordByEmailMutationVariables
>;

/**
 * __useUpdatePasswordByEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordByEmailMutation, { data, loading, error }] = useUpdatePasswordByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordByEmailMutation, UpdatePasswordByEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePasswordByEmailMutation, UpdatePasswordByEmailMutationVariables>(
    UpdatePasswordByEmailDocument,
    options
  );
}
export type UpdatePasswordByEmailMutationHookResult = ReturnType<typeof useUpdatePasswordByEmailMutation>;
export type UpdatePasswordByEmailMutationResult = Apollo.MutationResult<UpdatePasswordByEmailMutation>;
export type UpdatePasswordByEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordByEmailMutation,
  UpdatePasswordByEmailMutationVariables
>;
export const UpdateSBenefitsByKindDocument = gql`
  mutation UpdateSBenefitsByKind($egift_code: Int!, $kind: Int!) {
    updateSBenefitsByKind(egift_code: $egift_code, kind: $kind) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSBenefitsByKindMutationFn = Apollo.MutationFunction<
  UpdateSBenefitsByKindMutation,
  UpdateSBenefitsByKindMutationVariables
>;

/**
 * __useUpdateSBenefitsByKindMutation__
 *
 * To run a mutation, you first call `useUpdateSBenefitsByKindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSBenefitsByKindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSBenefitsByKindMutation, { data, loading, error }] = useUpdateSBenefitsByKindMutation({
 *   variables: {
 *      egift_code: // value for 'egift_code'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useUpdateSBenefitsByKindMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSBenefitsByKindMutation, UpdateSBenefitsByKindMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSBenefitsByKindMutation, UpdateSBenefitsByKindMutationVariables>(
    UpdateSBenefitsByKindDocument,
    options
  );
}
export type UpdateSBenefitsByKindMutationHookResult = ReturnType<typeof useUpdateSBenefitsByKindMutation>;
export type UpdateSBenefitsByKindMutationResult = Apollo.MutationResult<UpdateSBenefitsByKindMutation>;
export type UpdateSBenefitsByKindMutationOptions = Apollo.BaseMutationOptions<
  UpdateSBenefitsByKindMutation,
  UpdateSBenefitsByKindMutationVariables
>;
export const UpdateSBenefitsTicketNumDocument = gql`
  mutation UpdateSBenefitsTicketNum($iv_ticket_num: Int!) {
    updateSBenefitsTicketNum(iv_ticket_num: $iv_ticket_num) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSBenefitsTicketNumMutationFn = Apollo.MutationFunction<
  UpdateSBenefitsTicketNumMutation,
  UpdateSBenefitsTicketNumMutationVariables
>;

/**
 * __useUpdateSBenefitsTicketNumMutation__
 *
 * To run a mutation, you first call `useUpdateSBenefitsTicketNumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSBenefitsTicketNumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSBenefitsTicketNumMutation, { data, loading, error }] = useUpdateSBenefitsTicketNumMutation({
 *   variables: {
 *      iv_ticket_num: // value for 'iv_ticket_num'
 *   },
 * });
 */
export function useUpdateSBenefitsTicketNumMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSBenefitsTicketNumMutation, UpdateSBenefitsTicketNumMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSBenefitsTicketNumMutation, UpdateSBenefitsTicketNumMutationVariables>(
    UpdateSBenefitsTicketNumDocument,
    options
  );
}
export type UpdateSBenefitsTicketNumMutationHookResult = ReturnType<typeof useUpdateSBenefitsTicketNumMutation>;
export type UpdateSBenefitsTicketNumMutationResult = Apollo.MutationResult<UpdateSBenefitsTicketNumMutation>;
export type UpdateSBenefitsTicketNumMutationOptions = Apollo.BaseMutationOptions<
  UpdateSBenefitsTicketNumMutation,
  UpdateSBenefitsTicketNumMutationVariables
>;
export const UpdateSBenefitsValidDaysDocument = gql`
  mutation UpdateSBenefitsValidDays($iv_valid_days: Int!) {
    updateSBenefitsValidDays(iv_valid_days: $iv_valid_days) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSBenefitsValidDaysMutationFn = Apollo.MutationFunction<
  UpdateSBenefitsValidDaysMutation,
  UpdateSBenefitsValidDaysMutationVariables
>;

/**
 * __useUpdateSBenefitsValidDaysMutation__
 *
 * To run a mutation, you first call `useUpdateSBenefitsValidDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSBenefitsValidDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSBenefitsValidDaysMutation, { data, loading, error }] = useUpdateSBenefitsValidDaysMutation({
 *   variables: {
 *      iv_valid_days: // value for 'iv_valid_days'
 *   },
 * });
 */
export function useUpdateSBenefitsValidDaysMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSBenefitsValidDaysMutation, UpdateSBenefitsValidDaysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSBenefitsValidDaysMutation, UpdateSBenefitsValidDaysMutationVariables>(
    UpdateSBenefitsValidDaysDocument,
    options
  );
}
export type UpdateSBenefitsValidDaysMutationHookResult = ReturnType<typeof useUpdateSBenefitsValidDaysMutation>;
export type UpdateSBenefitsValidDaysMutationResult = Apollo.MutationResult<UpdateSBenefitsValidDaysMutation>;
export type UpdateSBenefitsValidDaysMutationOptions = Apollo.BaseMutationOptions<
  UpdateSBenefitsValidDaysMutation,
  UpdateSBenefitsValidDaysMutationVariables
>;
export const UpdateSEgiftCodeByEgiftCodeDocument = gql`
  mutation UpdateSEgiftCodeByEgiftCode(
    $config_code: String!
    $deadline: String!
    $egift_code: Int!
    $price: Int!
    $token: String!
  ) {
    updateSEgiftCodeByEgiftCode(
      config_code: $config_code
      deadline: $deadline
      egift_code: $egift_code
      price: $price
      token: $token
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSEgiftCodeByEgiftCodeMutationFn = Apollo.MutationFunction<
  UpdateSEgiftCodeByEgiftCodeMutation,
  UpdateSEgiftCodeByEgiftCodeMutationVariables
>;

/**
 * __useUpdateSEgiftCodeByEgiftCodeMutation__
 *
 * To run a mutation, you first call `useUpdateSEgiftCodeByEgiftCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSEgiftCodeByEgiftCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSEgiftCodeByEgiftCodeMutation, { data, loading, error }] = useUpdateSEgiftCodeByEgiftCodeMutation({
 *   variables: {
 *      config_code: // value for 'config_code'
 *      deadline: // value for 'deadline'
 *      egift_code: // value for 'egift_code'
 *      price: // value for 'price'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateSEgiftCodeByEgiftCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSEgiftCodeByEgiftCodeMutation,
    UpdateSEgiftCodeByEgiftCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSEgiftCodeByEgiftCodeMutation, UpdateSEgiftCodeByEgiftCodeMutationVariables>(
    UpdateSEgiftCodeByEgiftCodeDocument,
    options
  );
}
export type UpdateSEgiftCodeByEgiftCodeMutationHookResult = ReturnType<typeof useUpdateSEgiftCodeByEgiftCodeMutation>;
export type UpdateSEgiftCodeByEgiftCodeMutationResult = Apollo.MutationResult<UpdateSEgiftCodeByEgiftCodeMutation>;
export type UpdateSEgiftCodeByEgiftCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSEgiftCodeByEgiftCodeMutation,
  UpdateSEgiftCodeByEgiftCodeMutationVariables
>;
export const UpdateSLendStaffByBtRankIdBizIdDocument = gql`
  mutation UpdateSLendStaffByBtRankIdBizId(
    $biz_id: Int!
    $bt_rank_id: Int!
    $educ_fee: Int!
    $educ_fee_max: Int!
    $educ_fee_min: Int!
  ) {
    updateSLendStaffByBtRankIdBizId(
      biz_id: $biz_id
      bt_rank_id: $bt_rank_id
      educ_fee: $educ_fee
      educ_fee_max: $educ_fee_max
      educ_fee_min: $educ_fee_min
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSLendStaffByBtRankIdBizIdMutationFn = Apollo.MutationFunction<
  UpdateSLendStaffByBtRankIdBizIdMutation,
  UpdateSLendStaffByBtRankIdBizIdMutationVariables
>;

/**
 * __useUpdateSLendStaffByBtRankIdBizIdMutation__
 *
 * To run a mutation, you first call `useUpdateSLendStaffByBtRankIdBizIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSLendStaffByBtRankIdBizIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSLendStaffByBtRankIdBizIdMutation, { data, loading, error }] = useUpdateSLendStaffByBtRankIdBizIdMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      bt_rank_id: // value for 'bt_rank_id'
 *      educ_fee: // value for 'educ_fee'
 *      educ_fee_max: // value for 'educ_fee_max'
 *      educ_fee_min: // value for 'educ_fee_min'
 *   },
 * });
 */
export function useUpdateSLendStaffByBtRankIdBizIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSLendStaffByBtRankIdBizIdMutation,
    UpdateSLendStaffByBtRankIdBizIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSLendStaffByBtRankIdBizIdMutation, UpdateSLendStaffByBtRankIdBizIdMutationVariables>(
    UpdateSLendStaffByBtRankIdBizIdDocument,
    options
  );
}
export type UpdateSLendStaffByBtRankIdBizIdMutationHookResult = ReturnType<
  typeof useUpdateSLendStaffByBtRankIdBizIdMutation
>;
export type UpdateSLendStaffByBtRankIdBizIdMutationResult =
  Apollo.MutationResult<UpdateSLendStaffByBtRankIdBizIdMutation>;
export type UpdateSLendStaffByBtRankIdBizIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSLendStaffByBtRankIdBizIdMutation,
  UpdateSLendStaffByBtRankIdBizIdMutationVariables
>;
export const UpdateSTpmemBonusPerDocument = gql`
  mutation UpdateSTpmemBonusPer($bonus_per: Int!) {
    updateSTpmemBonusPer(bonus_per: $bonus_per) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemBonusPerMutationFn = Apollo.MutationFunction<
  UpdateSTpmemBonusPerMutation,
  UpdateSTpmemBonusPerMutationVariables
>;

/**
 * __useUpdateSTpmemBonusPerMutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemBonusPerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemBonusPerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemBonusPerMutation, { data, loading, error }] = useUpdateSTpmemBonusPerMutation({
 *   variables: {
 *      bonus_per: // value for 'bonus_per'
 *   },
 * });
 */
export function useUpdateSTpmemBonusPerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSTpmemBonusPerMutation, UpdateSTpmemBonusPerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSTpmemBonusPerMutation, UpdateSTpmemBonusPerMutationVariables>(
    UpdateSTpmemBonusPerDocument,
    options
  );
}
export type UpdateSTpmemBonusPerMutationHookResult = ReturnType<typeof useUpdateSTpmemBonusPerMutation>;
export type UpdateSTpmemBonusPerMutationResult = Apollo.MutationResult<UpdateSTpmemBonusPerMutation>;
export type UpdateSTpmemBonusPerMutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemBonusPerMutation,
  UpdateSTpmemBonusPerMutationVariables
>;
export const UpdateSTpmemTypeRadiusByTypeId1Document = gql`
  mutation UpdateSTpmemTypeRadiusByTypeId1($radius: Float!) {
    updateSTpmemTypeRadiusByTypeId1(radius: $radius) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRadiusByTypeId1MutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRadiusByTypeId1Mutation,
  UpdateSTpmemTypeRadiusByTypeId1MutationVariables
>;

/**
 * __useUpdateSTpmemTypeRadiusByTypeId1Mutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRadiusByTypeId1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRadiusByTypeId1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRadiusByTypeId1Mutation, { data, loading, error }] = useUpdateSTpmemTypeRadiusByTypeId1Mutation({
 *   variables: {
 *      radius: // value for 'radius'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRadiusByTypeId1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRadiusByTypeId1Mutation,
    UpdateSTpmemTypeRadiusByTypeId1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSTpmemTypeRadiusByTypeId1Mutation, UpdateSTpmemTypeRadiusByTypeId1MutationVariables>(
    UpdateSTpmemTypeRadiusByTypeId1Document,
    options
  );
}
export type UpdateSTpmemTypeRadiusByTypeId1MutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRadiusByTypeId1Mutation
>;
export type UpdateSTpmemTypeRadiusByTypeId1MutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRadiusByTypeId1Mutation>;
export type UpdateSTpmemTypeRadiusByTypeId1MutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRadiusByTypeId1Mutation,
  UpdateSTpmemTypeRadiusByTypeId1MutationVariables
>;
export const UpdateSTpmemTypeRecruitDaysByTypeIdDocument = gql`
  mutation UpdateSTpmemTypeRecruitDaysByTypeId($recruit_days: Int!, $type_id: Int!) {
    updateSTpmemTypeRecruitDaysByTypeId(recruit_days: $recruit_days, type_id: $type_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRecruitDaysByTypeIdMutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRecruitDaysByTypeIdMutation,
  UpdateSTpmemTypeRecruitDaysByTypeIdMutationVariables
>;

/**
 * __useUpdateSTpmemTypeRecruitDaysByTypeIdMutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRecruitDaysByTypeIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRecruitDaysByTypeIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRecruitDaysByTypeIdMutation, { data, loading, error }] = useUpdateSTpmemTypeRecruitDaysByTypeIdMutation({
 *   variables: {
 *      recruit_days: // value for 'recruit_days'
 *      type_id: // value for 'type_id'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRecruitDaysByTypeIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRecruitDaysByTypeIdMutation,
    UpdateSTpmemTypeRecruitDaysByTypeIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSTpmemTypeRecruitDaysByTypeIdMutation,
    UpdateSTpmemTypeRecruitDaysByTypeIdMutationVariables
  >(UpdateSTpmemTypeRecruitDaysByTypeIdDocument, options);
}
export type UpdateSTpmemTypeRecruitDaysByTypeIdMutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRecruitDaysByTypeIdMutation
>;
export type UpdateSTpmemTypeRecruitDaysByTypeIdMutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRecruitDaysByTypeIdMutation>;
export type UpdateSTpmemTypeRecruitDaysByTypeIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRecruitDaysByTypeIdMutation,
  UpdateSTpmemTypeRecruitDaysByTypeIdMutationVariables
>;
export const UpdateSTpmemTypeRecruitDaysByTypeId1Document = gql`
  mutation UpdateSTpmemTypeRecruitDaysByTypeId1($recruit_days: Int!) {
    updateSTpmemTypeRecruitDaysByTypeId1(recruit_days: $recruit_days) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRecruitDaysByTypeId1MutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRecruitDaysByTypeId1Mutation,
  UpdateSTpmemTypeRecruitDaysByTypeId1MutationVariables
>;

/**
 * __useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRecruitDaysByTypeId1Mutation, { data, loading, error }] = useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation({
 *   variables: {
 *      recruit_days: // value for 'recruit_days'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRecruitDaysByTypeId1Mutation,
    UpdateSTpmemTypeRecruitDaysByTypeId1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSTpmemTypeRecruitDaysByTypeId1Mutation,
    UpdateSTpmemTypeRecruitDaysByTypeId1MutationVariables
  >(UpdateSTpmemTypeRecruitDaysByTypeId1Document, options);
}
export type UpdateSTpmemTypeRecruitDaysByTypeId1MutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRecruitDaysByTypeId1Mutation
>;
export type UpdateSTpmemTypeRecruitDaysByTypeId1MutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRecruitDaysByTypeId1Mutation>;
export type UpdateSTpmemTypeRecruitDaysByTypeId1MutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRecruitDaysByTypeId1Mutation,
  UpdateSTpmemTypeRecruitDaysByTypeId1MutationVariables
>;
export const UpdateSTpmemTypeRecruitNumByTypeIdDocument = gql`
  mutation UpdateSTpmemTypeRecruitNumByTypeId($recruit_num: Int!, $type_id: Int!) {
    updateSTpmemTypeRecruitNumByTypeId(recruit_num: $recruit_num, type_id: $type_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRecruitNumByTypeIdMutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRecruitNumByTypeIdMutation,
  UpdateSTpmemTypeRecruitNumByTypeIdMutationVariables
>;

/**
 * __useUpdateSTpmemTypeRecruitNumByTypeIdMutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRecruitNumByTypeIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRecruitNumByTypeIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRecruitNumByTypeIdMutation, { data, loading, error }] = useUpdateSTpmemTypeRecruitNumByTypeIdMutation({
 *   variables: {
 *      recruit_num: // value for 'recruit_num'
 *      type_id: // value for 'type_id'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRecruitNumByTypeIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRecruitNumByTypeIdMutation,
    UpdateSTpmemTypeRecruitNumByTypeIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSTpmemTypeRecruitNumByTypeIdMutation,
    UpdateSTpmemTypeRecruitNumByTypeIdMutationVariables
  >(UpdateSTpmemTypeRecruitNumByTypeIdDocument, options);
}
export type UpdateSTpmemTypeRecruitNumByTypeIdMutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRecruitNumByTypeIdMutation
>;
export type UpdateSTpmemTypeRecruitNumByTypeIdMutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRecruitNumByTypeIdMutation>;
export type UpdateSTpmemTypeRecruitNumByTypeIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRecruitNumByTypeIdMutation,
  UpdateSTpmemTypeRecruitNumByTypeIdMutationVariables
>;
export const UpdateSTpmemTypeRecruitNumByTypeId1Document = gql`
  mutation UpdateSTpmemTypeRecruitNumByTypeId1($recruit_num: Int!) {
    updateSTpmemTypeRecruitNumByTypeId1(recruit_num: $recruit_num) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRecruitNumByTypeId1MutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRecruitNumByTypeId1Mutation,
  UpdateSTpmemTypeRecruitNumByTypeId1MutationVariables
>;

/**
 * __useUpdateSTpmemTypeRecruitNumByTypeId1Mutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRecruitNumByTypeId1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRecruitNumByTypeId1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRecruitNumByTypeId1Mutation, { data, loading, error }] = useUpdateSTpmemTypeRecruitNumByTypeId1Mutation({
 *   variables: {
 *      recruit_num: // value for 'recruit_num'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRecruitNumByTypeId1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRecruitNumByTypeId1Mutation,
    UpdateSTpmemTypeRecruitNumByTypeId1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSTpmemTypeRecruitNumByTypeId1Mutation,
    UpdateSTpmemTypeRecruitNumByTypeId1MutationVariables
  >(UpdateSTpmemTypeRecruitNumByTypeId1Document, options);
}
export type UpdateSTpmemTypeRecruitNumByTypeId1MutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRecruitNumByTypeId1Mutation
>;
export type UpdateSTpmemTypeRecruitNumByTypeId1MutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRecruitNumByTypeId1Mutation>;
export type UpdateSTpmemTypeRecruitNumByTypeId1MutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRecruitNumByTypeId1Mutation,
  UpdateSTpmemTypeRecruitNumByTypeId1MutationVariables
>;
export const UpdateSTpmemTypeRecruitRatioByTypeId1Document = gql`
  mutation UpdateSTpmemTypeRecruitRatioByTypeId1($recruit_ratio: Int!) {
    updateSTpmemTypeRecruitRatioByTypeId1(recruit_ratio: $recruit_ratio) {
      resultRows
      __typename
    }
  }
`;
export type UpdateSTpmemTypeRecruitRatioByTypeId1MutationFn = Apollo.MutationFunction<
  UpdateSTpmemTypeRecruitRatioByTypeId1Mutation,
  UpdateSTpmemTypeRecruitRatioByTypeId1MutationVariables
>;

/**
 * __useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation__
 *
 * To run a mutation, you first call `useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSTpmemTypeRecruitRatioByTypeId1Mutation, { data, loading, error }] = useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation({
 *   variables: {
 *      recruit_ratio: // value for 'recruit_ratio'
 *   },
 * });
 */
export function useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSTpmemTypeRecruitRatioByTypeId1Mutation,
    UpdateSTpmemTypeRecruitRatioByTypeId1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSTpmemTypeRecruitRatioByTypeId1Mutation,
    UpdateSTpmemTypeRecruitRatioByTypeId1MutationVariables
  >(UpdateSTpmemTypeRecruitRatioByTypeId1Document, options);
}
export type UpdateSTpmemTypeRecruitRatioByTypeId1MutationHookResult = ReturnType<
  typeof useUpdateSTpmemTypeRecruitRatioByTypeId1Mutation
>;
export type UpdateSTpmemTypeRecruitRatioByTypeId1MutationResult =
  Apollo.MutationResult<UpdateSTpmemTypeRecruitRatioByTypeId1Mutation>;
export type UpdateSTpmemTypeRecruitRatioByTypeId1MutationOptions = Apollo.BaseMutationOptions<
  UpdateSTpmemTypeRecruitRatioByTypeId1Mutation,
  UpdateSTpmemTypeRecruitRatioByTypeId1MutationVariables
>;
export const UpdateStatusByEmailDocument = gql`
  mutation UpdateStatusByEmail($email: String!, $status: Int!) {
    updateStatusByEmail(email: $email, status: $status) {
      resultRows
      __typename
    }
  }
`;
export type UpdateStatusByEmailMutationFn = Apollo.MutationFunction<
  UpdateStatusByEmailMutation,
  UpdateStatusByEmailMutationVariables
>;

/**
 * __useUpdateStatusByEmailMutation__
 *
 * To run a mutation, you first call `useUpdateStatusByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusByEmailMutation, { data, loading, error }] = useUpdateStatusByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateStatusByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStatusByEmailMutation, UpdateStatusByEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStatusByEmailMutation, UpdateStatusByEmailMutationVariables>(
    UpdateStatusByEmailDocument,
    options
  );
}
export type UpdateStatusByEmailMutationHookResult = ReturnType<typeof useUpdateStatusByEmailMutation>;
export type UpdateStatusByEmailMutationResult = Apollo.MutationResult<UpdateStatusByEmailMutation>;
export type UpdateStatusByEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateStatusByEmailMutation,
  UpdateStatusByEmailMutationVariables
>;
export const UpdateTArbeitOffereStatusByIdDocument = gql`
  mutation UpdateTArbeitOffereStatusById($id: Int!, $status: Int!) {
    updateTArbeitOffereStatusById(id: $id, status: $status) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTArbeitOffereStatusByIdMutationFn = Apollo.MutationFunction<
  UpdateTArbeitOffereStatusByIdMutation,
  UpdateTArbeitOffereStatusByIdMutationVariables
>;

/**
 * __useUpdateTArbeitOffereStatusByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTArbeitOffereStatusByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTArbeitOffereStatusByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTArbeitOffereStatusByIdMutation, { data, loading, error }] = useUpdateTArbeitOffereStatusByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTArbeitOffereStatusByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTArbeitOffereStatusByIdMutation,
    UpdateTArbeitOffereStatusByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTArbeitOffereStatusByIdMutation, UpdateTArbeitOffereStatusByIdMutationVariables>(
    UpdateTArbeitOffereStatusByIdDocument,
    options
  );
}
export type UpdateTArbeitOffereStatusByIdMutationHookResult = ReturnType<
  typeof useUpdateTArbeitOffereStatusByIdMutation
>;
export type UpdateTArbeitOffereStatusByIdMutationResult = Apollo.MutationResult<UpdateTArbeitOffereStatusByIdMutation>;
export type UpdateTArbeitOffereStatusByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTArbeitOffereStatusByIdMutation,
  UpdateTArbeitOffereStatusByIdMutationVariables
>;
export const UpdateTBaseByIdDocument = gql`
  mutation UpdateTBaseById(
    $address1: String!
    $address2: String!
    $city: String!
    $id: Int!
    $lat: Float!
    $lng: Float!
    $name: String!
    $phone: String!
    $state: String!
    $zip_code: String!
  ) {
    updateTBaseById(
      address1: $address1
      address2: $address2
      city: $city
      id: $id
      lat: $lat
      lng: $lng
      name: $name
      phone: $phone
      state: $state
      zip_code: $zip_code
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTBaseByIdMutationFn = Apollo.MutationFunction<
  UpdateTBaseByIdMutation,
  UpdateTBaseByIdMutationVariables
>;

/**
 * __useUpdateTBaseByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTBaseByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTBaseByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTBaseByIdMutation, { data, loading, error }] = useUpdateTBaseByIdMutation({
 *   variables: {
 *      address1: // value for 'address1'
 *      address2: // value for 'address2'
 *      city: // value for 'city'
 *      id: // value for 'id'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      state: // value for 'state'
 *      zip_code: // value for 'zip_code'
 *   },
 * });
 */
export function useUpdateTBaseByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTBaseByIdMutation, UpdateTBaseByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTBaseByIdMutation, UpdateTBaseByIdMutationVariables>(
    UpdateTBaseByIdDocument,
    options
  );
}
export type UpdateTBaseByIdMutationHookResult = ReturnType<typeof useUpdateTBaseByIdMutation>;
export type UpdateTBaseByIdMutationResult = Apollo.MutationResult<UpdateTBaseByIdMutation>;
export type UpdateTBaseByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTBaseByIdMutation,
  UpdateTBaseByIdMutationVariables
>;
export const UpdateTManagerTMemberDocument = gql`
  mutation UpdateTManagerTMember(
    $close_time: String!
    $corporate_no: String!
    $corporate_type: Int!
    $dob: String!
    $first_kana: String!
    $first_name: String!
    $image: String!
    $last_kana: String!
    $last_name: String!
    $mco_address1: String!
    $mco_address2: String!
    $mco_city: String!
    $mco_m_first_kana: String!
    $mco_m_first_name: String!
    $mco_m_last_kana: String!
    $mco_m_last_name: String!
    $mco_name: String!
    $mco_phone: String!
    $mco_state: String!
    $mco_t_first_kana: String!
    $mco_t_first_name: String!
    $mco_t_last_kana: String!
    $mco_t_last_name: String!
    $mco_t_phone: String!
    $mco_zip_code: String!
    $open_time: String!
    $parking: Boolean!
    $phone: String!
    $tpmem_id: Int!
  ) {
    updateTManagerTMember(
      close_time: $close_time
      corporate_no: $corporate_no
      corporate_type: $corporate_type
      dob: $dob
      first_kana: $first_kana
      first_name: $first_name
      image: $image
      last_kana: $last_kana
      last_name: $last_name
      mco_address1: $mco_address1
      mco_address2: $mco_address2
      mco_city: $mco_city
      mco_m_first_kana: $mco_m_first_kana
      mco_m_first_name: $mco_m_first_name
      mco_m_last_kana: $mco_m_last_kana
      mco_m_last_name: $mco_m_last_name
      mco_name: $mco_name
      mco_phone: $mco_phone
      mco_state: $mco_state
      mco_t_first_kana: $mco_t_first_kana
      mco_t_first_name: $mco_t_first_name
      mco_t_last_kana: $mco_t_last_kana
      mco_t_last_name: $mco_t_last_name
      mco_t_phone: $mco_t_phone
      mco_zip_code: $mco_zip_code
      open_time: $open_time
      parking: $parking
      phone: $phone
      tpmem_id: $tpmem_id
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTManagerTMemberMutationFn = Apollo.MutationFunction<
  UpdateTManagerTMemberMutation,
  UpdateTManagerTMemberMutationVariables
>;

/**
 * __useUpdateTManagerTMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTManagerTMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTManagerTMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTManagerTMemberMutation, { data, loading, error }] = useUpdateTManagerTMemberMutation({
 *   variables: {
 *      close_time: // value for 'close_time'
 *      corporate_no: // value for 'corporate_no'
 *      corporate_type: // value for 'corporate_type'
 *      dob: // value for 'dob'
 *      first_kana: // value for 'first_kana'
 *      first_name: // value for 'first_name'
 *      image: // value for 'image'
 *      last_kana: // value for 'last_kana'
 *      last_name: // value for 'last_name'
 *      mco_address1: // value for 'mco_address1'
 *      mco_address2: // value for 'mco_address2'
 *      mco_city: // value for 'mco_city'
 *      mco_m_first_kana: // value for 'mco_m_first_kana'
 *      mco_m_first_name: // value for 'mco_m_first_name'
 *      mco_m_last_kana: // value for 'mco_m_last_kana'
 *      mco_m_last_name: // value for 'mco_m_last_name'
 *      mco_name: // value for 'mco_name'
 *      mco_phone: // value for 'mco_phone'
 *      mco_state: // value for 'mco_state'
 *      mco_t_first_kana: // value for 'mco_t_first_kana'
 *      mco_t_first_name: // value for 'mco_t_first_name'
 *      mco_t_last_kana: // value for 'mco_t_last_kana'
 *      mco_t_last_name: // value for 'mco_t_last_name'
 *      mco_t_phone: // value for 'mco_t_phone'
 *      mco_zip_code: // value for 'mco_zip_code'
 *      open_time: // value for 'open_time'
 *      parking: // value for 'parking'
 *      phone: // value for 'phone'
 *      tpmem_id: // value for 'tpmem_id'
 *   },
 * });
 */
export function useUpdateTManagerTMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTManagerTMemberMutation, UpdateTManagerTMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTManagerTMemberMutation, UpdateTManagerTMemberMutationVariables>(
    UpdateTManagerTMemberDocument,
    options
  );
}
export type UpdateTManagerTMemberMutationHookResult = ReturnType<typeof useUpdateTManagerTMemberMutation>;
export type UpdateTManagerTMemberMutationResult = Apollo.MutationResult<UpdateTManagerTMemberMutation>;
export type UpdateTManagerTMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateTManagerTMemberMutation,
  UpdateTManagerTMemberMutationVariables
>;
export const UpdateTMemberByIdDocument = gql`
  mutation UpdateTMemberById(
    $close_time: String!
    $corporate_no: String!
    $email: String!
    $id: Int!
    $mco_address1: String!
    $mco_address2: String!
    $mco_city: String!
    $mco_m_first_kana: String!
    $mco_m_first_name: String!
    $mco_m_last_kana: String!
    $mco_m_last_name: String!
    $mco_name: String!
    $mco_phone: String!
    $mco_state: String!
    $mco_t_first_kana: String!
    $mco_t_first_name: String!
    $mco_t_last_kana: String!
    $mco_t_last_name: String!
    $mco_t_phone: String!
    $mco_zip_code: String!
    $mgr_id: Int!
    $open_time: String!
    $parking: Boolean!
  ) {
    updateTMemberById(
      close_time: $close_time
      corporate_no: $corporate_no
      email: $email
      id: $id
      mco_address1: $mco_address1
      mco_address2: $mco_address2
      mco_city: $mco_city
      mco_m_first_kana: $mco_m_first_kana
      mco_m_first_name: $mco_m_first_name
      mco_m_last_kana: $mco_m_last_kana
      mco_m_last_name: $mco_m_last_name
      mco_name: $mco_name
      mco_phone: $mco_phone
      mco_state: $mco_state
      mco_t_first_kana: $mco_t_first_kana
      mco_t_first_name: $mco_t_first_name
      mco_t_last_kana: $mco_t_last_kana
      mco_t_last_name: $mco_t_last_name
      mco_t_phone: $mco_t_phone
      mco_zip_code: $mco_zip_code
      mgr_id: $mgr_id
      open_time: $open_time
      parking: $parking
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTMemberByIdMutationFn = Apollo.MutationFunction<
  UpdateTMemberByIdMutation,
  UpdateTMemberByIdMutationVariables
>;

/**
 * __useUpdateTMemberByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTMemberByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTMemberByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTMemberByIdMutation, { data, loading, error }] = useUpdateTMemberByIdMutation({
 *   variables: {
 *      close_time: // value for 'close_time'
 *      corporate_no: // value for 'corporate_no'
 *      email: // value for 'email'
 *      id: // value for 'id'
 *      mco_address1: // value for 'mco_address1'
 *      mco_address2: // value for 'mco_address2'
 *      mco_city: // value for 'mco_city'
 *      mco_m_first_kana: // value for 'mco_m_first_kana'
 *      mco_m_first_name: // value for 'mco_m_first_name'
 *      mco_m_last_kana: // value for 'mco_m_last_kana'
 *      mco_m_last_name: // value for 'mco_m_last_name'
 *      mco_name: // value for 'mco_name'
 *      mco_phone: // value for 'mco_phone'
 *      mco_state: // value for 'mco_state'
 *      mco_t_first_kana: // value for 'mco_t_first_kana'
 *      mco_t_first_name: // value for 'mco_t_first_name'
 *      mco_t_last_kana: // value for 'mco_t_last_kana'
 *      mco_t_last_name: // value for 'mco_t_last_name'
 *      mco_t_phone: // value for 'mco_t_phone'
 *      mco_zip_code: // value for 'mco_zip_code'
 *      mgr_id: // value for 'mgr_id'
 *      open_time: // value for 'open_time'
 *      parking: // value for 'parking'
 *   },
 * });
 */
export function useUpdateTMemberByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTMemberByIdMutation, UpdateTMemberByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTMemberByIdMutation, UpdateTMemberByIdMutationVariables>(
    UpdateTMemberByIdDocument,
    options
  );
}
export type UpdateTMemberByIdMutationHookResult = ReturnType<typeof useUpdateTMemberByIdMutation>;
export type UpdateTMemberByIdMutationResult = Apollo.MutationResult<UpdateTMemberByIdMutation>;
export type UpdateTMemberByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTMemberByIdMutation,
  UpdateTMemberByIdMutationVariables
>;
export const UpdateTMemberS1ByIdDocument = gql`
  mutation UpdateTMemberS1ById($id: Int!) {
    updateTMemberS1ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTMemberS1ByIdMutationFn = Apollo.MutationFunction<
  UpdateTMemberS1ByIdMutation,
  UpdateTMemberS1ByIdMutationVariables
>;

/**
 * __useUpdateTMemberS1ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTMemberS1ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTMemberS1ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTMemberS1ByIdMutation, { data, loading, error }] = useUpdateTMemberS1ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTMemberS1ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTMemberS1ByIdMutation, UpdateTMemberS1ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTMemberS1ByIdMutation, UpdateTMemberS1ByIdMutationVariables>(
    UpdateTMemberS1ByIdDocument,
    options
  );
}
export type UpdateTMemberS1ByIdMutationHookResult = ReturnType<typeof useUpdateTMemberS1ByIdMutation>;
export type UpdateTMemberS1ByIdMutationResult = Apollo.MutationResult<UpdateTMemberS1ByIdMutation>;
export type UpdateTMemberS1ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTMemberS1ByIdMutation,
  UpdateTMemberS1ByIdMutationVariables
>;
export const UpdateTMemberS2ByIdDocument = gql`
  mutation UpdateTMemberS2ById($id: Int!) {
    updateTMemberS2ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTMemberS2ByIdMutationFn = Apollo.MutationFunction<
  UpdateTMemberS2ByIdMutation,
  UpdateTMemberS2ByIdMutationVariables
>;

/**
 * __useUpdateTMemberS2ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTMemberS2ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTMemberS2ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTMemberS2ByIdMutation, { data, loading, error }] = useUpdateTMemberS2ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTMemberS2ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTMemberS2ByIdMutation, UpdateTMemberS2ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTMemberS2ByIdMutation, UpdateTMemberS2ByIdMutationVariables>(
    UpdateTMemberS2ByIdDocument,
    options
  );
}
export type UpdateTMemberS2ByIdMutationHookResult = ReturnType<typeof useUpdateTMemberS2ByIdMutation>;
export type UpdateTMemberS2ByIdMutationResult = Apollo.MutationResult<UpdateTMemberS2ByIdMutation>;
export type UpdateTMemberS2ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTMemberS2ByIdMutation,
  UpdateTMemberS2ByIdMutationVariables
>;
export const UpdateTMemberTrProcStatusByIdDocument = gql`
  mutation UpdateTMemberTrProcStatusById($id: Int!, $tr_proc_status: Int!, $tr_proc_uid: Int!) {
    updateTMemberTrProcStatusById(id: $id, tr_proc_status: $tr_proc_status, tr_proc_uid: $tr_proc_uid) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTMemberTrProcStatusByIdMutationFn = Apollo.MutationFunction<
  UpdateTMemberTrProcStatusByIdMutation,
  UpdateTMemberTrProcStatusByIdMutationVariables
>;

/**
 * __useUpdateTMemberTrProcStatusByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTMemberTrProcStatusByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTMemberTrProcStatusByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTMemberTrProcStatusByIdMutation, { data, loading, error }] = useUpdateTMemberTrProcStatusByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tr_proc_status: // value for 'tr_proc_status'
 *      tr_proc_uid: // value for 'tr_proc_uid'
 *   },
 * });
 */
export function useUpdateTMemberTrProcStatusByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTMemberTrProcStatusByIdMutation,
    UpdateTMemberTrProcStatusByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTMemberTrProcStatusByIdMutation, UpdateTMemberTrProcStatusByIdMutationVariables>(
    UpdateTMemberTrProcStatusByIdDocument,
    options
  );
}
export type UpdateTMemberTrProcStatusByIdMutationHookResult = ReturnType<
  typeof useUpdateTMemberTrProcStatusByIdMutation
>;
export type UpdateTMemberTrProcStatusByIdMutationResult = Apollo.MutationResult<UpdateTMemberTrProcStatusByIdMutation>;
export type UpdateTMemberTrProcStatusByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTMemberTrProcStatusByIdMutation,
  UpdateTMemberTrProcStatusByIdMutationVariables
>;
export const UpdateTMemberTrProcTypeByIdDocument = gql`
  mutation UpdateTMemberTrProcTypeById($id: Int!, $tr_proc_type: Int!) {
    updateTMemberTrProcTypeById(id: $id, tr_proc_type: $tr_proc_type) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTMemberTrProcTypeByIdMutationFn = Apollo.MutationFunction<
  UpdateTMemberTrProcTypeByIdMutation,
  UpdateTMemberTrProcTypeByIdMutationVariables
>;

/**
 * __useUpdateTMemberTrProcTypeByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTMemberTrProcTypeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTMemberTrProcTypeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTMemberTrProcTypeByIdMutation, { data, loading, error }] = useUpdateTMemberTrProcTypeByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tr_proc_type: // value for 'tr_proc_type'
 *   },
 * });
 */
export function useUpdateTMemberTrProcTypeByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTMemberTrProcTypeByIdMutation,
    UpdateTMemberTrProcTypeByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTMemberTrProcTypeByIdMutation, UpdateTMemberTrProcTypeByIdMutationVariables>(
    UpdateTMemberTrProcTypeByIdDocument,
    options
  );
}
export type UpdateTMemberTrProcTypeByIdMutationHookResult = ReturnType<typeof useUpdateTMemberTrProcTypeByIdMutation>;
export type UpdateTMemberTrProcTypeByIdMutationResult = Apollo.MutationResult<UpdateTMemberTrProcTypeByIdMutation>;
export type UpdateTMemberTrProcTypeByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTMemberTrProcTypeByIdMutation,
  UpdateTMemberTrProcTypeByIdMutationVariables
>;
export const UpdateTScoreVisibleByWorkIdDocument = gql`
  mutation UpdateTScoreVisibleByWorkId($work_id: Int!) {
    updateTScoreVisibleByWorkId(work_id: $work_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTScoreVisibleByWorkIdMutationFn = Apollo.MutationFunction<
  UpdateTScoreVisibleByWorkIdMutation,
  UpdateTScoreVisibleByWorkIdMutationVariables
>;

/**
 * __useUpdateTScoreVisibleByWorkIdMutation__
 *
 * To run a mutation, you first call `useUpdateTScoreVisibleByWorkIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTScoreVisibleByWorkIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTScoreVisibleByWorkIdMutation, { data, loading, error }] = useUpdateTScoreVisibleByWorkIdMutation({
 *   variables: {
 *      work_id: // value for 'work_id'
 *   },
 * });
 */
export function useUpdateTScoreVisibleByWorkIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTScoreVisibleByWorkIdMutation,
    UpdateTScoreVisibleByWorkIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTScoreVisibleByWorkIdMutation, UpdateTScoreVisibleByWorkIdMutationVariables>(
    UpdateTScoreVisibleByWorkIdDocument,
    options
  );
}
export type UpdateTScoreVisibleByWorkIdMutationHookResult = ReturnType<typeof useUpdateTScoreVisibleByWorkIdMutation>;
export type UpdateTScoreVisibleByWorkIdMutationResult = Apollo.MutationResult<UpdateTScoreVisibleByWorkIdMutation>;
export type UpdateTScoreVisibleByWorkIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTScoreVisibleByWorkIdMutation,
  UpdateTScoreVisibleByWorkIdMutationVariables
>;
export const UpdateTTpmemBlockByIdDocument = gql`
  mutation UpdateTTpmemBlockById($block_id: Int!, $id: Int!, $reason: String!, $reg_date: String!, $umgmt_id: Int!) {
    updateTTpmemBlockById(block_id: $block_id, id: $id, reason: $reason, reg_date: $reg_date, umgmt_id: $umgmt_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateTTpmemBlockByIdMutationFn = Apollo.MutationFunction<
  UpdateTTpmemBlockByIdMutation,
  UpdateTTpmemBlockByIdMutationVariables
>;

/**
 * __useUpdateTTpmemBlockByIdMutation__
 *
 * To run a mutation, you first call `useUpdateTTpmemBlockByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTTpmemBlockByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTTpmemBlockByIdMutation, { data, loading, error }] = useUpdateTTpmemBlockByIdMutation({
 *   variables: {
 *      block_id: // value for 'block_id'
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      reg_date: // value for 'reg_date'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useUpdateTTpmemBlockByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTTpmemBlockByIdMutation, UpdateTTpmemBlockByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTTpmemBlockByIdMutation, UpdateTTpmemBlockByIdMutationVariables>(
    UpdateTTpmemBlockByIdDocument,
    options
  );
}
export type UpdateTTpmemBlockByIdMutationHookResult = ReturnType<typeof useUpdateTTpmemBlockByIdMutation>;
export type UpdateTTpmemBlockByIdMutationResult = Apollo.MutationResult<UpdateTTpmemBlockByIdMutation>;
export type UpdateTTpmemBlockByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateTTpmemBlockByIdMutation,
  UpdateTTpmemBlockByIdMutationVariables
>;
export const UpdateUMemberByIdDocument = gql`
  mutation UpdateUMemberById(
    $email: String!
    $first_name: String!
    $id: Int!
    $last_name: String!
    $password: String!
    $privilege: Int!
  ) {
    updateUMemberById(
      email: $email
      first_name: $first_name
      id: $id
      last_name: $last_name
      password: $password
      privilege: $privilege
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUMemberByIdMutationFn = Apollo.MutationFunction<
  UpdateUMemberByIdMutation,
  UpdateUMemberByIdMutationVariables
>;

/**
 * __useUpdateUMemberByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUMemberByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUMemberByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUMemberByIdMutation, { data, loading, error }] = useUpdateUMemberByIdMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      id: // value for 'id'
 *      last_name: // value for 'last_name'
 *      password: // value for 'password'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useUpdateUMemberByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUMemberByIdMutation, UpdateUMemberByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUMemberByIdMutation, UpdateUMemberByIdMutationVariables>(
    UpdateUMemberByIdDocument,
    options
  );
}
export type UpdateUMemberByIdMutationHookResult = ReturnType<typeof useUpdateUMemberByIdMutation>;
export type UpdateUMemberByIdMutationResult = Apollo.MutationResult<UpdateUMemberByIdMutation>;
export type UpdateUMemberByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUMemberByIdMutation,
  UpdateUMemberByIdMutationVariables
>;
export const UpdateUMemberEmailByIdDocument = gql`
  mutation UpdateUMemberEmailById($email: String!, $id: Int!) {
    updateUMemberEmailById(email: $email, id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUMemberEmailByIdMutationFn = Apollo.MutationFunction<
  UpdateUMemberEmailByIdMutation,
  UpdateUMemberEmailByIdMutationVariables
>;

/**
 * __useUpdateUMemberEmailByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUMemberEmailByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUMemberEmailByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUMemberEmailByIdMutation, { data, loading, error }] = useUpdateUMemberEmailByIdMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUMemberEmailByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUMemberEmailByIdMutation, UpdateUMemberEmailByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUMemberEmailByIdMutation, UpdateUMemberEmailByIdMutationVariables>(
    UpdateUMemberEmailByIdDocument,
    options
  );
}
export type UpdateUMemberEmailByIdMutationHookResult = ReturnType<typeof useUpdateUMemberEmailByIdMutation>;
export type UpdateUMemberEmailByIdMutationResult = Apollo.MutationResult<UpdateUMemberEmailByIdMutation>;
export type UpdateUMemberEmailByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUMemberEmailByIdMutation,
  UpdateUMemberEmailByIdMutationVariables
>;
export const UpdateUMemberPrivilegeByIdDocument = gql`
  mutation UpdateUMemberPrivilegeById($id: Int!, $privilege: Int!) {
    updateUMemberPrivilegeById(id: $id, privilege: $privilege) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUMemberPrivilegeByIdMutationFn = Apollo.MutationFunction<
  UpdateUMemberPrivilegeByIdMutation,
  UpdateUMemberPrivilegeByIdMutationVariables
>;

/**
 * __useUpdateUMemberPrivilegeByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUMemberPrivilegeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUMemberPrivilegeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUMemberPrivilegeByIdMutation, { data, loading, error }] = useUpdateUMemberPrivilegeByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      privilege: // value for 'privilege'
 *   },
 * });
 */
export function useUpdateUMemberPrivilegeByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUMemberPrivilegeByIdMutation,
    UpdateUMemberPrivilegeByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUMemberPrivilegeByIdMutation, UpdateUMemberPrivilegeByIdMutationVariables>(
    UpdateUMemberPrivilegeByIdDocument,
    options
  );
}
export type UpdateUMemberPrivilegeByIdMutationHookResult = ReturnType<typeof useUpdateUMemberPrivilegeByIdMutation>;
export type UpdateUMemberPrivilegeByIdMutationResult = Apollo.MutationResult<UpdateUMemberPrivilegeByIdMutation>;
export type UpdateUMemberPrivilegeByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUMemberPrivilegeByIdMutation,
  UpdateUMemberPrivilegeByIdMutationVariables
>;
export const UpdateUMemberTfa0ByIdDocument = gql`
  mutation UpdateUMemberTfa0ById($id: Int!) {
    updateUMemberTfa0ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUMemberTfa0ByIdMutationFn = Apollo.MutationFunction<
  UpdateUMemberTfa0ByIdMutation,
  UpdateUMemberTfa0ByIdMutationVariables
>;

/**
 * __useUpdateUMemberTfa0ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUMemberTfa0ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUMemberTfa0ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUMemberTfa0ByIdMutation, { data, loading, error }] = useUpdateUMemberTfa0ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUMemberTfa0ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUMemberTfa0ByIdMutation, UpdateUMemberTfa0ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUMemberTfa0ByIdMutation, UpdateUMemberTfa0ByIdMutationVariables>(
    UpdateUMemberTfa0ByIdDocument,
    options
  );
}
export type UpdateUMemberTfa0ByIdMutationHookResult = ReturnType<typeof useUpdateUMemberTfa0ByIdMutation>;
export type UpdateUMemberTfa0ByIdMutationResult = Apollo.MutationResult<UpdateUMemberTfa0ByIdMutation>;
export type UpdateUMemberTfa0ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUMemberTfa0ByIdMutation,
  UpdateUMemberTfa0ByIdMutationVariables
>;
export const UpdateUMemberTfa1ByIdDocument = gql`
  mutation UpdateUMemberTfa1ById($id: Int!) {
    updateUMemberTfa1ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUMemberTfa1ByIdMutationFn = Apollo.MutationFunction<
  UpdateUMemberTfa1ByIdMutation,
  UpdateUMemberTfa1ByIdMutationVariables
>;

/**
 * __useUpdateUMemberTfa1ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUMemberTfa1ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUMemberTfa1ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUMemberTfa1ByIdMutation, { data, loading, error }] = useUpdateUMemberTfa1ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUMemberTfa1ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUMemberTfa1ByIdMutation, UpdateUMemberTfa1ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUMemberTfa1ByIdMutation, UpdateUMemberTfa1ByIdMutationVariables>(
    UpdateUMemberTfa1ByIdDocument,
    options
  );
}
export type UpdateUMemberTfa1ByIdMutationHookResult = ReturnType<typeof useUpdateUMemberTfa1ByIdMutation>;
export type UpdateUMemberTfa1ByIdMutationResult = Apollo.MutationResult<UpdateUMemberTfa1ByIdMutation>;
export type UpdateUMemberTfa1ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUMemberTfa1ByIdMutation,
  UpdateUMemberTfa1ByIdMutationVariables
>;
export const UpdateURecvNotifyReadBySysIdKindDocument = gql`
  mutation UpdateURecvNotifyReadBySysIdKind($kind: Int!, $sys_id: Int!) {
    updateURecvNotifyReadBySysIdKind(kind: $kind, sys_id: $sys_id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateURecvNotifyReadBySysIdKindMutationFn = Apollo.MutationFunction<
  UpdateURecvNotifyReadBySysIdKindMutation,
  UpdateURecvNotifyReadBySysIdKindMutationVariables
>;

/**
 * __useUpdateURecvNotifyReadBySysIdKindMutation__
 *
 * To run a mutation, you first call `useUpdateURecvNotifyReadBySysIdKindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateURecvNotifyReadBySysIdKindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateURecvNotifyReadBySysIdKindMutation, { data, loading, error }] = useUpdateURecvNotifyReadBySysIdKindMutation({
 *   variables: {
 *      kind: // value for 'kind'
 *      sys_id: // value for 'sys_id'
 *   },
 * });
 */
export function useUpdateURecvNotifyReadBySysIdKindMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateURecvNotifyReadBySysIdKindMutation,
    UpdateURecvNotifyReadBySysIdKindMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateURecvNotifyReadBySysIdKindMutation,
    UpdateURecvNotifyReadBySysIdKindMutationVariables
  >(UpdateURecvNotifyReadBySysIdKindDocument, options);
}
export type UpdateURecvNotifyReadBySysIdKindMutationHookResult = ReturnType<
  typeof useUpdateURecvNotifyReadBySysIdKindMutation
>;
export type UpdateURecvNotifyReadBySysIdKindMutationResult =
  Apollo.MutationResult<UpdateURecvNotifyReadBySysIdKindMutation>;
export type UpdateURecvNotifyReadBySysIdKindMutationOptions = Apollo.BaseMutationOptions<
  UpdateURecvNotifyReadBySysIdKindMutation,
  UpdateURecvNotifyReadBySysIdKindMutationVariables
>;
export const UpdateUSendNotifyDocument = gql`
  mutation UpdateUSendNotify(
    $biz_id: Int
    $body: String!
    $brand_id: Int
    $id: Int!
    $sys_id: Int!
    $title: String!
    $umgmt_id: Int!
  ) {
    updateUSendNotify(
      biz_id: $biz_id
      body: $body
      brand_id: $brand_id
      id: $id
      sys_id: $sys_id
      title: $title
      umgmt_id: $umgmt_id
    ) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUSendNotifyMutationFn = Apollo.MutationFunction<
  UpdateUSendNotifyMutation,
  UpdateUSendNotifyMutationVariables
>;

/**
 * __useUpdateUSendNotifyMutation__
 *
 * To run a mutation, you first call `useUpdateUSendNotifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUSendNotifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUSendNotifyMutation, { data, loading, error }] = useUpdateUSendNotifyMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      body: // value for 'body'
 *      brand_id: // value for 'brand_id'
 *      id: // value for 'id'
 *      sys_id: // value for 'sys_id'
 *      title: // value for 'title'
 *      umgmt_id: // value for 'umgmt_id'
 *   },
 * });
 */
export function useUpdateUSendNotifyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUSendNotifyMutation, UpdateUSendNotifyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUSendNotifyMutation, UpdateUSendNotifyMutationVariables>(
    UpdateUSendNotifyDocument,
    options
  );
}
export type UpdateUSendNotifyMutationHookResult = ReturnType<typeof useUpdateUSendNotifyMutation>;
export type UpdateUSendNotifyMutationResult = Apollo.MutationResult<UpdateUSendNotifyMutation>;
export type UpdateUSendNotifyMutationOptions = Apollo.BaseMutationOptions<
  UpdateUSendNotifyMutation,
  UpdateUSendNotifyMutationVariables
>;
export const UpdateUSendNotifyDraftDocument = gql`
  mutation UpdateUSendNotifyDraft(
    $biz_id: Int
    $body: String
    $brand_id: Int
    $id: Int!
    $sys_id: Int
    $title: String!
  ) {
    updateUSendNotifyDraft(biz_id: $biz_id, body: $body, brand_id: $brand_id, id: $id, sys_id: $sys_id, title: $title) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUSendNotifyDraftMutationFn = Apollo.MutationFunction<
  UpdateUSendNotifyDraftMutation,
  UpdateUSendNotifyDraftMutationVariables
>;

/**
 * __useUpdateUSendNotifyDraftMutation__
 *
 * To run a mutation, you first call `useUpdateUSendNotifyDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUSendNotifyDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUSendNotifyDraftMutation, { data, loading, error }] = useUpdateUSendNotifyDraftMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      body: // value for 'body'
 *      brand_id: // value for 'brand_id'
 *      id: // value for 'id'
 *      sys_id: // value for 'sys_id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateUSendNotifyDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUSendNotifyDraftMutation, UpdateUSendNotifyDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUSendNotifyDraftMutation, UpdateUSendNotifyDraftMutationVariables>(
    UpdateUSendNotifyDraftDocument,
    options
  );
}
export type UpdateUSendNotifyDraftMutationHookResult = ReturnType<typeof useUpdateUSendNotifyDraftMutation>;
export type UpdateUSendNotifyDraftMutationResult = Apollo.MutationResult<UpdateUSendNotifyDraftMutation>;
export type UpdateUSendNotifyDraftMutationOptions = Apollo.BaseMutationOptions<
  UpdateUSendNotifyDraftMutation,
  UpdateUSendNotifyDraftMutationVariables
>;
export const UpdateUTrialTicketS2ByIdDocument = gql`
  mutation UpdateUTrialTicketS2ById($id: Int!) {
    updateUTrialTicketS2ById(id: $id) {
      resultRows
      __typename
    }
  }
`;
export type UpdateUTrialTicketS2ByIdMutationFn = Apollo.MutationFunction<
  UpdateUTrialTicketS2ByIdMutation,
  UpdateUTrialTicketS2ByIdMutationVariables
>;

/**
 * __useUpdateUTrialTicketS2ByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUTrialTicketS2ByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUTrialTicketS2ByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUTrialTicketS2ByIdMutation, { data, loading, error }] = useUpdateUTrialTicketS2ByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUTrialTicketS2ByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUTrialTicketS2ByIdMutation, UpdateUTrialTicketS2ByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUTrialTicketS2ByIdMutation, UpdateUTrialTicketS2ByIdMutationVariables>(
    UpdateUTrialTicketS2ByIdDocument,
    options
  );
}
export type UpdateUTrialTicketS2ByIdMutationHookResult = ReturnType<typeof useUpdateUTrialTicketS2ByIdMutation>;
export type UpdateUTrialTicketS2ByIdMutationResult = Apollo.MutationResult<UpdateUTrialTicketS2ByIdMutation>;
export type UpdateUTrialTicketS2ByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUTrialTicketS2ByIdMutation,
  UpdateUTrialTicketS2ByIdMutationVariables
>;
export const UploadSPaymentDaysDocument = gql`
  mutation UploadSPaymentDays($s3_bucket: String!, $s3_filepath: String!, $year: Int!) {
    uploadSPaymentDays(s3_bucket: $s3_bucket, s3_filepath: $s3_filepath, year: $year) {
      resultRows
      __typename
    }
  }
`;
export type UploadSPaymentDaysMutationFn = Apollo.MutationFunction<
  UploadSPaymentDaysMutation,
  UploadSPaymentDaysMutationVariables
>;

/**
 * __useUploadSPaymentDaysMutation__
 *
 * To run a mutation, you first call `useUploadSPaymentDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSPaymentDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSPaymentDaysMutation, { data, loading, error }] = useUploadSPaymentDaysMutation({
 *   variables: {
 *      s3_bucket: // value for 's3_bucket'
 *      s3_filepath: // value for 's3_filepath'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUploadSPaymentDaysMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadSPaymentDaysMutation, UploadSPaymentDaysMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadSPaymentDaysMutation, UploadSPaymentDaysMutationVariables>(
    UploadSPaymentDaysDocument,
    options
  );
}
export type UploadSPaymentDaysMutationHookResult = ReturnType<typeof useUploadSPaymentDaysMutation>;
export type UploadSPaymentDaysMutationResult = Apollo.MutationResult<UploadSPaymentDaysMutation>;
export type UploadSPaymentDaysMutationOptions = Apollo.BaseMutationOptions<
  UploadSPaymentDaysMutation,
  UploadSPaymentDaysMutationVariables
>;
export const UploadSWithholdDayDocument = gql`
  mutation UploadSWithholdDay($s3_bucket: String!, $s3_filepath: String!, $year: Int!) {
    uploadSWithholdDay(s3_bucket: $s3_bucket, s3_filepath: $s3_filepath, year: $year) {
      resultRows
      __typename
    }
  }
`;
export type UploadSWithholdDayMutationFn = Apollo.MutationFunction<
  UploadSWithholdDayMutation,
  UploadSWithholdDayMutationVariables
>;

/**
 * __useUploadSWithholdDayMutation__
 *
 * To run a mutation, you first call `useUploadSWithholdDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSWithholdDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSWithholdDayMutation, { data, loading, error }] = useUploadSWithholdDayMutation({
 *   variables: {
 *      s3_bucket: // value for 's3_bucket'
 *      s3_filepath: // value for 's3_filepath'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUploadSWithholdDayMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadSWithholdDayMutation, UploadSWithholdDayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadSWithholdDayMutation, UploadSWithholdDayMutationVariables>(
    UploadSWithholdDayDocument,
    options
  );
}
export type UploadSWithholdDayMutationHookResult = ReturnType<typeof useUploadSWithholdDayMutation>;
export type UploadSWithholdDayMutationResult = Apollo.MutationResult<UploadSWithholdDayMutation>;
export type UploadSWithholdDayMutationOptions = Apollo.BaseMutationOptions<
  UploadSWithholdDayMutation,
  UploadSWithholdDayMutationVariables
>;
export const UploadTBaseDocument = gql`
  mutation UploadTBase($s3_bucket: String!, $s3_filepath: String!) {
    uploadTBase(s3_bucket: $s3_bucket, s3_filepath: $s3_filepath) {
      resultRows
      __typename
    }
  }
`;
export type UploadTBaseMutationFn = Apollo.MutationFunction<UploadTBaseMutation, UploadTBaseMutationVariables>;

/**
 * __useUploadTBaseMutation__
 *
 * To run a mutation, you first call `useUploadTBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTBaseMutation, { data, loading, error }] = useUploadTBaseMutation({
 *   variables: {
 *      s3_bucket: // value for 's3_bucket'
 *      s3_filepath: // value for 's3_filepath'
 *   },
 * });
 */
export function useUploadTBaseMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadTBaseMutation, UploadTBaseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadTBaseMutation, UploadTBaseMutationVariables>(UploadTBaseDocument, options);
}
export type UploadTBaseMutationHookResult = ReturnType<typeof useUploadTBaseMutation>;
export type UploadTBaseMutationResult = Apollo.MutationResult<UploadTBaseMutation>;
export type UploadTBaseMutationOptions = Apollo.BaseMutationOptions<UploadTBaseMutation, UploadTBaseMutationVariables>;
export const GetAuthorizationDocument = gql`
  query GetAuthorization {
    getAuthorization {
      resultRows
      statusCode
      __typename
    }
  }
`;

/**
 * __useGetAuthorizationQuery__
 *
 * To run a query within a React component, call `useGetAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthorizationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAuthorizationQuery, GetAuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthorizationQuery, GetAuthorizationQueryVariables>(GetAuthorizationDocument, options);
}
export function useGetAuthorizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorizationQuery, GetAuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthorizationQuery, GetAuthorizationQueryVariables>(GetAuthorizationDocument, options);
}
export type GetAuthorizationQueryHookResult = ReturnType<typeof useGetAuthorizationQuery>;
export type GetAuthorizationLazyQueryHookResult = ReturnType<typeof useGetAuthorizationLazyQuery>;
export type GetAuthorizationQueryResult = Apollo.QueryResult<GetAuthorizationQuery, GetAuthorizationQueryVariables>;
export const GetBLoggingByBtmemIdKindDocument = gql`
  query GetBLoggingByBtmemIdKind($btmem_id: Int!, $kind: Int!) {
    getBLoggingByBtmemIdKind(btmem_id: $btmem_id, kind: $kind) {
      btmem_id
      id
      kind
      log_date
      matter
      ref_no
      __typename
    }
  }
`;

/**
 * __useGetBLoggingByBtmemIdKindQuery__
 *
 * To run a query within a React component, call `useGetBLoggingByBtmemIdKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBLoggingByBtmemIdKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBLoggingByBtmemIdKindQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useGetBLoggingByBtmemIdKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetBLoggingByBtmemIdKindQuery, GetBLoggingByBtmemIdKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBLoggingByBtmemIdKindQuery, GetBLoggingByBtmemIdKindQueryVariables>(
    GetBLoggingByBtmemIdKindDocument,
    options
  );
}
export function useGetBLoggingByBtmemIdKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBLoggingByBtmemIdKindQuery, GetBLoggingByBtmemIdKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBLoggingByBtmemIdKindQuery, GetBLoggingByBtmemIdKindQueryVariables>(
    GetBLoggingByBtmemIdKindDocument,
    options
  );
}
export type GetBLoggingByBtmemIdKindQueryHookResult = ReturnType<typeof useGetBLoggingByBtmemIdKindQuery>;
export type GetBLoggingByBtmemIdKindLazyQueryHookResult = ReturnType<typeof useGetBLoggingByBtmemIdKindLazyQuery>;
export type GetBLoggingByBtmemIdKindQueryResult = Apollo.QueryResult<
  GetBLoggingByBtmemIdKindQuery,
  GetBLoggingByBtmemIdKindQueryVariables
>;
export const GetBMemberByIdDocument = gql`
  query GetBMemberById($id: Int!) {
    getBMemberById(id: $id) {
      account_name
      account_no
      account_type
      achievement
      address1
      address2
      attr_id
      bank_code
      branch_name
      branch_no
      city
      cognito_id
      created_date
      dob
      ecn
      email
      first_kana
      first_name
      gender
      id
      image
      invite_code
      invited_input
      is_japanese
      last_kana
      last_name
      nationality_id
      nickname
      parental
      parental_name
      pause_date
      penalty
      period_date
      phone
      quit_date
      reason
      reg_hs_student
      reg_minor
      residence
      restart_date
      rule
      school
      state
      status
      uniform_id
      wire_new_code
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetBMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetBMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBMemberByIdQuery, GetBMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBMemberByIdQuery, GetBMemberByIdQueryVariables>(GetBMemberByIdDocument, options);
}
export function useGetBMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBMemberByIdQuery, GetBMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBMemberByIdQuery, GetBMemberByIdQueryVariables>(GetBMemberByIdDocument, options);
}
export type GetBMemberByIdQueryHookResult = ReturnType<typeof useGetBMemberByIdQuery>;
export type GetBMemberByIdLazyQueryHookResult = ReturnType<typeof useGetBMemberByIdLazyQuery>;
export type GetBMemberByIdQueryResult = Apollo.QueryResult<GetBMemberByIdQuery, GetBMemberByIdQueryVariables>;
export const GetCBillingCsvDocument = gql`
  query GetCBillingCsv {
    getCBillingCsv {
      csv_no
      csv_url
      dl_date
      download_uid
      kind
      output_date
      pay_date
      transfer_date
      __typename
    }
  }
`;

/**
 * __useGetCBillingCsvQuery__
 *
 * To run a query within a React component, call `useGetCBillingCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCBillingCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCBillingCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCBillingCsvQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCBillingCsvQuery, GetCBillingCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCBillingCsvQuery, GetCBillingCsvQueryVariables>(GetCBillingCsvDocument, options);
}
export function useGetCBillingCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCBillingCsvQuery, GetCBillingCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCBillingCsvQuery, GetCBillingCsvQueryVariables>(GetCBillingCsvDocument, options);
}
export type GetCBillingCsvQueryHookResult = ReturnType<typeof useGetCBillingCsvQuery>;
export type GetCBillingCsvLazyQueryHookResult = ReturnType<typeof useGetCBillingCsvLazyQuery>;
export type GetCBillingCsvQueryResult = Apollo.QueryResult<GetCBillingCsvQuery, GetCBillingCsvQueryVariables>;
export const GetCBillingCsvByKindDocument = gql`
  query GetCBillingCsvByKind($kind: Int!) {
    getCBillingCsvByKind(kind: $kind) {
      csv_no
      csv_url
      dl_date
      download_uid
      kind
      output_date
      pay_date
      transfer_date
      __typename
    }
  }
`;

/**
 * __useGetCBillingCsvByKindQuery__
 *
 * To run a query within a React component, call `useGetCBillingCsvByKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCBillingCsvByKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCBillingCsvByKindQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useGetCBillingCsvByKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetCBillingCsvByKindQuery, GetCBillingCsvByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCBillingCsvByKindQuery, GetCBillingCsvByKindQueryVariables>(
    GetCBillingCsvByKindDocument,
    options
  );
}
export function useGetCBillingCsvByKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCBillingCsvByKindQuery, GetCBillingCsvByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCBillingCsvByKindQuery, GetCBillingCsvByKindQueryVariables>(
    GetCBillingCsvByKindDocument,
    options
  );
}
export type GetCBillingCsvByKindQueryHookResult = ReturnType<typeof useGetCBillingCsvByKindQuery>;
export type GetCBillingCsvByKindLazyQueryHookResult = ReturnType<typeof useGetCBillingCsvByKindLazyQuery>;
export type GetCBillingCsvByKindQueryResult = Apollo.QueryResult<
  GetCBillingCsvByKindQuery,
  GetCBillingCsvByKindQueryVariables
>;
export const GetCPaymentBySysIdCategoryRequestRequestNoDocument = gql`
  query GetCPaymentBySysIdCategoryRequestRequestNo($category: Int!, $request: Int!, $request_no: Int!, $sys_id: Int!) {
    getCPaymentBySysIdCategoryRequestRequestNo(
      category: $category
      request: $request
      request_no: $request_no
      sys_id: $sys_id
    ) {
      all_csv_no
      bill_date
      category
      csv_no
      id
      mem_id
      origin_id
      price
      req_csv_no
      req_date
      request
      request_no
      subcategory
      sys_id
      tax
      __typename
    }
  }
`;

/**
 * __useGetCPaymentBySysIdCategoryRequestRequestNoQuery__
 *
 * To run a query within a React component, call `useGetCPaymentBySysIdCategoryRequestRequestNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCPaymentBySysIdCategoryRequestRequestNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCPaymentBySysIdCategoryRequestRequestNoQuery({
 *   variables: {
 *      category: // value for 'category'
 *      request: // value for 'request'
 *      request_no: // value for 'request_no'
 *      sys_id: // value for 'sys_id'
 *   },
 * });
 */
export function useGetCPaymentBySysIdCategoryRequestRequestNoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCPaymentBySysIdCategoryRequestRequestNoQuery,
    GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCPaymentBySysIdCategoryRequestRequestNoQuery,
    GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables
  >(GetCPaymentBySysIdCategoryRequestRequestNoDocument, options);
}
export function useGetCPaymentBySysIdCategoryRequestRequestNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCPaymentBySysIdCategoryRequestRequestNoQuery,
    GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCPaymentBySysIdCategoryRequestRequestNoQuery,
    GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables
  >(GetCPaymentBySysIdCategoryRequestRequestNoDocument, options);
}
export type GetCPaymentBySysIdCategoryRequestRequestNoQueryHookResult = ReturnType<
  typeof useGetCPaymentBySysIdCategoryRequestRequestNoQuery
>;
export type GetCPaymentBySysIdCategoryRequestRequestNoLazyQueryHookResult = ReturnType<
  typeof useGetCPaymentBySysIdCategoryRequestRequestNoLazyQuery
>;
export type GetCPaymentBySysIdCategoryRequestRequestNoQueryResult = Apollo.QueryResult<
  GetCPaymentBySysIdCategoryRequestRequestNoQuery,
  GetCPaymentBySysIdCategoryRequestRequestNoQueryVariables
>;
export const GetSBenefitsDocument = gql`
  query GetSBenefits {
    getSBenefits {
      bd_egift_code
      iv_egift_code
      iv_ticket_num
      iv_valid_days
      review_egift_code
      __typename
    }
  }
`;

/**
 * __useGetSBenefitsQuery__
 *
 * To run a query within a React component, call `useGetSBenefitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSBenefitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSBenefitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSBenefitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSBenefitsQuery, GetSBenefitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSBenefitsQuery, GetSBenefitsQueryVariables>(GetSBenefitsDocument, options);
}
export function useGetSBenefitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSBenefitsQuery, GetSBenefitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSBenefitsQuery, GetSBenefitsQueryVariables>(GetSBenefitsDocument, options);
}
export type GetSBenefitsQueryHookResult = ReturnType<typeof useGetSBenefitsQuery>;
export type GetSBenefitsLazyQueryHookResult = ReturnType<typeof useGetSBenefitsLazyQuery>;
export type GetSBenefitsQueryResult = Apollo.QueryResult<GetSBenefitsQuery, GetSBenefitsQueryVariables>;
export const GetSEgiftCodeDocument = gql`
  query GetSEgiftCode {
    getSEgiftCode {
      config_code
      deadline
      egift_code
      egift_type
      name
      price
      thanks_gift
      token
      __typename
    }
  }
`;

/**
 * __useGetSEgiftCodeQuery__
 *
 * To run a query within a React component, call `useGetSEgiftCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSEgiftCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSEgiftCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSEgiftCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSEgiftCodeQuery, GetSEgiftCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSEgiftCodeQuery, GetSEgiftCodeQueryVariables>(GetSEgiftCodeDocument, options);
}
export function useGetSEgiftCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSEgiftCodeQuery, GetSEgiftCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSEgiftCodeQuery, GetSEgiftCodeQueryVariables>(GetSEgiftCodeDocument, options);
}
export type GetSEgiftCodeQueryHookResult = ReturnType<typeof useGetSEgiftCodeQuery>;
export type GetSEgiftCodeLazyQueryHookResult = ReturnType<typeof useGetSEgiftCodeLazyQuery>;
export type GetSEgiftCodeQueryResult = Apollo.QueryResult<GetSEgiftCodeQuery, GetSEgiftCodeQueryVariables>;
export const GetSEgiftCodeByThanksGift0Document = gql`
  query GetSEgiftCodeByThanksGift0 {
    getSEgiftCodeByThanksGift0 {
      config_code
      deadline
      egift_code
      egift_type
      name
      price
      thanks_gift
      token
      __typename
    }
  }
`;

/**
 * __useGetSEgiftCodeByThanksGift0Query__
 *
 * To run a query within a React component, call `useGetSEgiftCodeByThanksGift0Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSEgiftCodeByThanksGift0Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSEgiftCodeByThanksGift0Query({
 *   variables: {
 *   },
 * });
 */
export function useGetSEgiftCodeByThanksGift0Query(
  baseOptions?: Apollo.QueryHookOptions<GetSEgiftCodeByThanksGift0Query, GetSEgiftCodeByThanksGift0QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSEgiftCodeByThanksGift0Query, GetSEgiftCodeByThanksGift0QueryVariables>(
    GetSEgiftCodeByThanksGift0Document,
    options
  );
}
export function useGetSEgiftCodeByThanksGift0LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSEgiftCodeByThanksGift0Query, GetSEgiftCodeByThanksGift0QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSEgiftCodeByThanksGift0Query, GetSEgiftCodeByThanksGift0QueryVariables>(
    GetSEgiftCodeByThanksGift0Document,
    options
  );
}
export type GetSEgiftCodeByThanksGift0QueryHookResult = ReturnType<typeof useGetSEgiftCodeByThanksGift0Query>;
export type GetSEgiftCodeByThanksGift0LazyQueryHookResult = ReturnType<typeof useGetSEgiftCodeByThanksGift0LazyQuery>;
export type GetSEgiftCodeByThanksGift0QueryResult = Apollo.QueryResult<
  GetSEgiftCodeByThanksGift0Query,
  GetSEgiftCodeByThanksGift0QueryVariables
>;
export const GetSEgiftCodeByThanksGift1Document = gql`
  query GetSEgiftCodeByThanksGift1 {
    getSEgiftCodeByThanksGift1 {
      config_code
      deadline
      egift_code
      egift_type
      name
      price
      thanks_gift
      token
      __typename
    }
  }
`;

/**
 * __useGetSEgiftCodeByThanksGift1Query__
 *
 * To run a query within a React component, call `useGetSEgiftCodeByThanksGift1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSEgiftCodeByThanksGift1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSEgiftCodeByThanksGift1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetSEgiftCodeByThanksGift1Query(
  baseOptions?: Apollo.QueryHookOptions<GetSEgiftCodeByThanksGift1Query, GetSEgiftCodeByThanksGift1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSEgiftCodeByThanksGift1Query, GetSEgiftCodeByThanksGift1QueryVariables>(
    GetSEgiftCodeByThanksGift1Document,
    options
  );
}
export function useGetSEgiftCodeByThanksGift1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSEgiftCodeByThanksGift1Query, GetSEgiftCodeByThanksGift1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSEgiftCodeByThanksGift1Query, GetSEgiftCodeByThanksGift1QueryVariables>(
    GetSEgiftCodeByThanksGift1Document,
    options
  );
}
export type GetSEgiftCodeByThanksGift1QueryHookResult = ReturnType<typeof useGetSEgiftCodeByThanksGift1Query>;
export type GetSEgiftCodeByThanksGift1LazyQueryHookResult = ReturnType<typeof useGetSEgiftCodeByThanksGift1LazyQuery>;
export type GetSEgiftCodeByThanksGift1QueryResult = Apollo.QueryResult<
  GetSEgiftCodeByThanksGift1Query,
  GetSEgiftCodeByThanksGift1QueryVariables
>;
export const GetSPaymentDaysByYearDocument = gql`
  query GetSPaymentDaysByYear($year: Int!) {
    getSPaymentDaysByYear(year: $year) {
      newly {
        mn_all
        trans_status
        __typename
      }
      payment {
        day
        month
        year
        __typename
      }
      schedule {
        payment_regist
        withhold_regist
        year
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetSPaymentDaysByYearQuery__
 *
 * To run a query within a React component, call `useGetSPaymentDaysByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSPaymentDaysByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSPaymentDaysByYearQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSPaymentDaysByYearQuery(
  baseOptions: Apollo.QueryHookOptions<GetSPaymentDaysByYearQuery, GetSPaymentDaysByYearQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSPaymentDaysByYearQuery, GetSPaymentDaysByYearQueryVariables>(
    GetSPaymentDaysByYearDocument,
    options
  );
}
export function useGetSPaymentDaysByYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSPaymentDaysByYearQuery, GetSPaymentDaysByYearQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSPaymentDaysByYearQuery, GetSPaymentDaysByYearQueryVariables>(
    GetSPaymentDaysByYearDocument,
    options
  );
}
export type GetSPaymentDaysByYearQueryHookResult = ReturnType<typeof useGetSPaymentDaysByYearQuery>;
export type GetSPaymentDaysByYearLazyQueryHookResult = ReturnType<typeof useGetSPaymentDaysByYearLazyQuery>;
export type GetSPaymentDaysByYearQueryResult = Apollo.QueryResult<
  GetSPaymentDaysByYearQuery,
  GetSPaymentDaysByYearQueryVariables
>;
export const GetSTpmemDocument = gql`
  query GetSTpmem {
    getSTpmem {
      bonus_per
      checkin_start
      checkout_start
      finish_timeout
      fix_timeout
      hunt_accept
      penalty
      tax_per
      __typename
    }
  }
`;

/**
 * __useGetSTpmemQuery__
 *
 * To run a query within a React component, call `useGetSTpmemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSTpmemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSTpmemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSTpmemQuery(baseOptions?: Apollo.QueryHookOptions<GetSTpmemQuery, GetSTpmemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSTpmemQuery, GetSTpmemQueryVariables>(GetSTpmemDocument, options);
}
export function useGetSTpmemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSTpmemQuery, GetSTpmemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSTpmemQuery, GetSTpmemQueryVariables>(GetSTpmemDocument, options);
}
export type GetSTpmemQueryHookResult = ReturnType<typeof useGetSTpmemQuery>;
export type GetSTpmemLazyQueryHookResult = ReturnType<typeof useGetSTpmemLazyQuery>;
export type GetSTpmemQueryResult = Apollo.QueryResult<GetSTpmemQuery, GetSTpmemQueryVariables>;
export const GetSTpmemTypeByTypeId1Document = gql`
  query GetSTpmemTypeByTypeId1 {
    getSTpmemTypeByTypeId1 {
      blocked
      monthly_fee
      priority
      publ_fee
      radius
      recruit_days
      recruit_num
      recruit_ratio
      reg_staff
      type_id
      video_fee
      __typename
    }
  }
`;

/**
 * __useGetSTpmemTypeByTypeId1Query__
 *
 * To run a query within a React component, call `useGetSTpmemTypeByTypeId1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetSTpmemTypeByTypeId1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSTpmemTypeByTypeId1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetSTpmemTypeByTypeId1Query(
  baseOptions?: Apollo.QueryHookOptions<GetSTpmemTypeByTypeId1Query, GetSTpmemTypeByTypeId1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSTpmemTypeByTypeId1Query, GetSTpmemTypeByTypeId1QueryVariables>(
    GetSTpmemTypeByTypeId1Document,
    options
  );
}
export function useGetSTpmemTypeByTypeId1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSTpmemTypeByTypeId1Query, GetSTpmemTypeByTypeId1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSTpmemTypeByTypeId1Query, GetSTpmemTypeByTypeId1QueryVariables>(
    GetSTpmemTypeByTypeId1Document,
    options
  );
}
export type GetSTpmemTypeByTypeId1QueryHookResult = ReturnType<typeof useGetSTpmemTypeByTypeId1Query>;
export type GetSTpmemTypeByTypeId1LazyQueryHookResult = ReturnType<typeof useGetSTpmemTypeByTypeId1LazyQuery>;
export type GetSTpmemTypeByTypeId1QueryResult = Apollo.QueryResult<
  GetSTpmemTypeByTypeId1Query,
  GetSTpmemTypeByTypeId1QueryVariables
>;
export const GetSWithholdDayByYearDocument = gql`
  query GetSWithholdDayByYear($year: Int!) {
    getSWithholdDayByYear(year: $year) {
      newly {
        mn_all
        trans_status
        __typename
      }
      schedule {
        payment_regist
        withhold_regist
        year
        __typename
      }
      withhold {
        price
        tax
        year
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetSWithholdDayByYearQuery__
 *
 * To run a query within a React component, call `useGetSWithholdDayByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSWithholdDayByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSWithholdDayByYearQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetSWithholdDayByYearQuery(
  baseOptions: Apollo.QueryHookOptions<GetSWithholdDayByYearQuery, GetSWithholdDayByYearQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSWithholdDayByYearQuery, GetSWithholdDayByYearQueryVariables>(
    GetSWithholdDayByYearDocument,
    options
  );
}
export function useGetSWithholdDayByYearLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSWithholdDayByYearQuery, GetSWithholdDayByYearQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSWithholdDayByYearQuery, GetSWithholdDayByYearQueryVariables>(
    GetSWithholdDayByYearDocument,
    options
  );
}
export type GetSWithholdDayByYearQueryHookResult = ReturnType<typeof useGetSWithholdDayByYearQuery>;
export type GetSWithholdDayByYearLazyQueryHookResult = ReturnType<typeof useGetSWithholdDayByYearLazyQuery>;
export type GetSWithholdDayByYearQueryResult = Apollo.QueryResult<
  GetSWithholdDayByYearQuery,
  GetSWithholdDayByYearQueryVariables
>;
export const GetSYearScheduleGpDocument = gql`
  query GetSYearScheduleGp {
    getSYearScheduleGp {
      year
      __typename
    }
  }
`;

/**
 * __useGetSYearScheduleGpQuery__
 *
 * To run a query within a React component, call `useGetSYearScheduleGpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSYearScheduleGpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSYearScheduleGpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSYearScheduleGpQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSYearScheduleGpQuery, GetSYearScheduleGpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSYearScheduleGpQuery, GetSYearScheduleGpQueryVariables>(
    GetSYearScheduleGpDocument,
    options
  );
}
export function useGetSYearScheduleGpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSYearScheduleGpQuery, GetSYearScheduleGpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSYearScheduleGpQuery, GetSYearScheduleGpQueryVariables>(
    GetSYearScheduleGpDocument,
    options
  );
}
export type GetSYearScheduleGpQueryHookResult = ReturnType<typeof useGetSYearScheduleGpQuery>;
export type GetSYearScheduleGpLazyQueryHookResult = ReturnType<typeof useGetSYearScheduleGpLazyQuery>;
export type GetSYearScheduleGpQueryResult = Apollo.QueryResult<
  GetSYearScheduleGpQuery,
  GetSYearScheduleGpQueryVariables
>;
export const GetSYearScheduleGpByPaymentRegistDocument = gql`
  query GetSYearScheduleGpByPaymentRegist {
    getSYearScheduleGpByPaymentRegist {
      year
      __typename
    }
  }
`;

/**
 * __useGetSYearScheduleGpByPaymentRegistQuery__
 *
 * To run a query within a React component, call `useGetSYearScheduleGpByPaymentRegistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSYearScheduleGpByPaymentRegistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSYearScheduleGpByPaymentRegistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSYearScheduleGpByPaymentRegistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSYearScheduleGpByPaymentRegistQuery,
    GetSYearScheduleGpByPaymentRegistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSYearScheduleGpByPaymentRegistQuery, GetSYearScheduleGpByPaymentRegistQueryVariables>(
    GetSYearScheduleGpByPaymentRegistDocument,
    options
  );
}
export function useGetSYearScheduleGpByPaymentRegistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSYearScheduleGpByPaymentRegistQuery,
    GetSYearScheduleGpByPaymentRegistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSYearScheduleGpByPaymentRegistQuery, GetSYearScheduleGpByPaymentRegistQueryVariables>(
    GetSYearScheduleGpByPaymentRegistDocument,
    options
  );
}
export type GetSYearScheduleGpByPaymentRegistQueryHookResult = ReturnType<
  typeof useGetSYearScheduleGpByPaymentRegistQuery
>;
export type GetSYearScheduleGpByPaymentRegistLazyQueryHookResult = ReturnType<
  typeof useGetSYearScheduleGpByPaymentRegistLazyQuery
>;
export type GetSYearScheduleGpByPaymentRegistQueryResult = Apollo.QueryResult<
  GetSYearScheduleGpByPaymentRegistQuery,
  GetSYearScheduleGpByPaymentRegistQueryVariables
>;
export const GetSYearScheduleGpByWithholdRegistDocument = gql`
  query GetSYearScheduleGpByWithholdRegist {
    getSYearScheduleGpByWithholdRegist {
      year
      __typename
    }
  }
`;

/**
 * __useGetSYearScheduleGpByWithholdRegistQuery__
 *
 * To run a query within a React component, call `useGetSYearScheduleGpByWithholdRegistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSYearScheduleGpByWithholdRegistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSYearScheduleGpByWithholdRegistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSYearScheduleGpByWithholdRegistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSYearScheduleGpByWithholdRegistQuery,
    GetSYearScheduleGpByWithholdRegistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSYearScheduleGpByWithholdRegistQuery, GetSYearScheduleGpByWithholdRegistQueryVariables>(
    GetSYearScheduleGpByWithholdRegistDocument,
    options
  );
}
export function useGetSYearScheduleGpByWithholdRegistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSYearScheduleGpByWithholdRegistQuery,
    GetSYearScheduleGpByWithholdRegistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSYearScheduleGpByWithholdRegistQuery, GetSYearScheduleGpByWithholdRegistQueryVariables>(
    GetSYearScheduleGpByWithholdRegistDocument,
    options
  );
}
export type GetSYearScheduleGpByWithholdRegistQueryHookResult = ReturnType<
  typeof useGetSYearScheduleGpByWithholdRegistQuery
>;
export type GetSYearScheduleGpByWithholdRegistLazyQueryHookResult = ReturnType<
  typeof useGetSYearScheduleGpByWithholdRegistLazyQuery
>;
export type GetSYearScheduleGpByWithholdRegistQueryResult = Apollo.QueryResult<
  GetSYearScheduleGpByWithholdRegistQuery,
  GetSYearScheduleGpByWithholdRegistQueryVariables
>;
export const GetTTpmemBlockByIdDocument = gql`
  query GetTTpmemBlockById($block_id: Int!, $id: Int!) {
    getTTpmemBlockById(block_id: $block_id, id: $id) {
      block_id
      id
      reason
      reg_date
      umgmt_id
      __typename
    }
  }
`;

/**
 * __useGetTTpmemBlockByIdQuery__
 *
 * To run a query within a React component, call `useGetTTpmemBlockByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTTpmemBlockByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTTpmemBlockByIdQuery({
 *   variables: {
 *      block_id: // value for 'block_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTTpmemBlockByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTTpmemBlockByIdQuery, GetTTpmemBlockByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTTpmemBlockByIdQuery, GetTTpmemBlockByIdQueryVariables>(
    GetTTpmemBlockByIdDocument,
    options
  );
}
export function useGetTTpmemBlockByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTTpmemBlockByIdQuery, GetTTpmemBlockByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTTpmemBlockByIdQuery, GetTTpmemBlockByIdQueryVariables>(
    GetTTpmemBlockByIdDocument,
    options
  );
}
export type GetTTpmemBlockByIdQueryHookResult = ReturnType<typeof useGetTTpmemBlockByIdQuery>;
export type GetTTpmemBlockByIdLazyQueryHookResult = ReturnType<typeof useGetTTpmemBlockByIdLazyQuery>;
export type GetTTpmemBlockByIdQueryResult = Apollo.QueryResult<
  GetTTpmemBlockByIdQuery,
  GetTTpmemBlockByIdQueryVariables
>;
export const GetTmemberCsvDataForMfkDocument = gql`
  query GetTmemberCsvDataForMfk {
    getTmemberCsvDataForMfk {
      resultRows
      csv_data
      __typename
    }
  }
`;

/**
 * __useGetTmemberCsvDataForMfkQuery__
 *
 * To run a query within a React component, call `useGetTmemberCsvDataForMfkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTmemberCsvDataForMfkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTmemberCsvDataForMfkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTmemberCsvDataForMfkQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTmemberCsvDataForMfkQuery, GetTmemberCsvDataForMfkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTmemberCsvDataForMfkQuery, GetTmemberCsvDataForMfkQueryVariables>(
    GetTmemberCsvDataForMfkDocument,
    options
  );
}
export function useGetTmemberCsvDataForMfkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTmemberCsvDataForMfkQuery, GetTmemberCsvDataForMfkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTmemberCsvDataForMfkQuery, GetTmemberCsvDataForMfkQueryVariables>(
    GetTmemberCsvDataForMfkDocument,
    options
  );
}
export type GetTmemberCsvDataForMfkQueryHookResult = ReturnType<typeof useGetTmemberCsvDataForMfkQuery>;
export type GetTmemberCsvDataForMfkLazyQueryHookResult = ReturnType<typeof useGetTmemberCsvDataForMfkLazyQuery>;
export type GetTmemberCsvDataForMfkQueryResult = Apollo.QueryResult<
  GetTmemberCsvDataForMfkQuery,
  GetTmemberCsvDataForMfkQueryVariables
>;
export const GetTWithholdSlipByBtmemIdDocument = gql`
  query GetTWithholdSlipByBtmemId($btmem_id: Int!) {
    getTWithholdSlipByBtmemId(btmem_id: $btmem_id) {
      btmem_id
      id
      month_val
      withholding
      year_val
      __typename
    }
  }
`;

/**
 * __useGetTWithholdSlipByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetTWithholdSlipByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTWithholdSlipByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTWithholdSlipByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetTWithholdSlipByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTWithholdSlipByBtmemIdQuery, GetTWithholdSlipByBtmemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTWithholdSlipByBtmemIdQuery, GetTWithholdSlipByBtmemIdQueryVariables>(
    GetTWithholdSlipByBtmemIdDocument,
    options
  );
}
export function useGetTWithholdSlipByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTWithholdSlipByBtmemIdQuery, GetTWithholdSlipByBtmemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTWithholdSlipByBtmemIdQuery, GetTWithholdSlipByBtmemIdQueryVariables>(
    GetTWithholdSlipByBtmemIdDocument,
    options
  );
}
export type GetTWithholdSlipByBtmemIdQueryHookResult = ReturnType<typeof useGetTWithholdSlipByBtmemIdQuery>;
export type GetTWithholdSlipByBtmemIdLazyQueryHookResult = ReturnType<typeof useGetTWithholdSlipByBtmemIdLazyQuery>;
export type GetTWithholdSlipByBtmemIdQueryResult = Apollo.QueryResult<
  GetTWithholdSlipByBtmemIdQuery,
  GetTWithholdSlipByBtmemIdQueryVariables
>;
export const GetUMemberByEmailDocument = gql`
  query GetUMemberByEmail($email: String!) {
    getUMemberByEmail(email: $email) {
      cognito_id
      email
      first_kana
      first_name
      id
      last_kana
      last_name
      password
      privilege
      status
      tfa
      __typename
    }
  }
`;

/**
 * __useGetUMemberByEmailQuery__
 *
 * To run a query within a React component, call `useGetUMemberByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUMemberByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUMemberByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUMemberByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<GetUMemberByEmailQuery, GetUMemberByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUMemberByEmailQuery, GetUMemberByEmailQueryVariables>(GetUMemberByEmailDocument, options);
}
export function useGetUMemberByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUMemberByEmailQuery, GetUMemberByEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUMemberByEmailQuery, GetUMemberByEmailQueryVariables>(
    GetUMemberByEmailDocument,
    options
  );
}
export type GetUMemberByEmailQueryHookResult = ReturnType<typeof useGetUMemberByEmailQuery>;
export type GetUMemberByEmailLazyQueryHookResult = ReturnType<typeof useGetUMemberByEmailLazyQuery>;
export type GetUMemberByEmailQueryResult = Apollo.QueryResult<GetUMemberByEmailQuery, GetUMemberByEmailQueryVariables>;
export const GetUMemberByIdDocument = gql`
  query GetUMemberById($userId: Int!) {
    getUMemberById(userId: $userId) {
      cognito_id
      email
      first_kana
      first_name
      id
      last_kana
      last_name
      password
      privilege
      status
      tfa
      __typename
    }
  }
`;

/**
 * __useGetUMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetUMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUMemberByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUMemberByIdQuery, GetUMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUMemberByIdQuery, GetUMemberByIdQueryVariables>(GetUMemberByIdDocument, options);
}
export function useGetUMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUMemberByIdQuery, GetUMemberByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUMemberByIdQuery, GetUMemberByIdQueryVariables>(GetUMemberByIdDocument, options);
}
export type GetUMemberByIdQueryHookResult = ReturnType<typeof useGetUMemberByIdQuery>;
export type GetUMemberByIdLazyQueryHookResult = ReturnType<typeof useGetUMemberByIdLazyQuery>;
export type GetUMemberByIdQueryResult = Apollo.QueryResult<GetUMemberByIdQuery, GetUMemberByIdQueryVariables>;
export const GetUMembersDocument = gql`
  query GetUMembers {
    getUMembers {
      cognito_id
      email
      first_kana
      first_name
      id
      last_kana
      last_name
      password
      privilege
      status
      tfa
      __typename
    }
  }
`;

/**
 * __useGetUMembersQuery__
 *
 * To run a query within a React component, call `useGetUMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUMembersQuery, GetUMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUMembersQuery, GetUMembersQueryVariables>(GetUMembersDocument, options);
}
export function useGetUMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUMembersQuery, GetUMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUMembersQuery, GetUMembersQueryVariables>(GetUMembersDocument, options);
}
export type GetUMembersQueryHookResult = ReturnType<typeof useGetUMembersQuery>;
export type GetUMembersLazyQueryHookResult = ReturnType<typeof useGetUMembersLazyQuery>;
export type GetUMembersQueryResult = Apollo.QueryResult<GetUMembersQuery, GetUMembersQueryVariables>;
export const GetURecvNotifyByS2K3Document = gql`
  query GetURecvNotifyByS2K3 {
    getURecvNotifyByS2K3 {
      home {
        b_badge
        b_identfy
        b_join
        b_pay
        b_quit
        t_change
        t_pay
        t_quit
        t_regist
        __typename
      }
      list {
        body
        id
        is_read
        kind
        recv_date
        ref_no
        screen_no
        sys_id
        title
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS2K3Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS2K3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS2K3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS2K3Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS2K3Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS2K3Query, GetURecvNotifyByS2K3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS2K3Query, GetURecvNotifyByS2K3QueryVariables>(
    GetURecvNotifyByS2K3Document,
    options
  );
}
export function useGetURecvNotifyByS2K3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS2K3Query, GetURecvNotifyByS2K3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS2K3Query, GetURecvNotifyByS2K3QueryVariables>(
    GetURecvNotifyByS2K3Document,
    options
  );
}
export type GetURecvNotifyByS2K3QueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K3Query>;
export type GetURecvNotifyByS2K3LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K3LazyQuery>;
export type GetURecvNotifyByS2K3QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS2K3Query,
  GetURecvNotifyByS2K3QueryVariables
>;
export const GetURecvNotifyByS2K4Document = gql`
  query GetURecvNotifyByS2K4 {
    getURecvNotifyByS2K4 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS2K4Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS2K4Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS2K4Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS2K4Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS2K4Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS2K4Query, GetURecvNotifyByS2K4QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS2K4Query, GetURecvNotifyByS2K4QueryVariables>(
    GetURecvNotifyByS2K4Document,
    options
  );
}
export function useGetURecvNotifyByS2K4LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS2K4Query, GetURecvNotifyByS2K4QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS2K4Query, GetURecvNotifyByS2K4QueryVariables>(
    GetURecvNotifyByS2K4Document,
    options
  );
}
export type GetURecvNotifyByS2K4QueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K4Query>;
export type GetURecvNotifyByS2K4LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K4LazyQuery>;
export type GetURecvNotifyByS2K4QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS2K4Query,
  GetURecvNotifyByS2K4QueryVariables
>;
export const GetURecvNotifyByS2K6Document = gql`
  query GetURecvNotifyByS2K6 {
    getURecvNotifyByS2K6 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS2K6Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS2K6Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS2K6Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS2K6Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS2K6Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS2K6Query, GetURecvNotifyByS2K6QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS2K6Query, GetURecvNotifyByS2K6QueryVariables>(
    GetURecvNotifyByS2K6Document,
    options
  );
}
export function useGetURecvNotifyByS2K6LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS2K6Query, GetURecvNotifyByS2K6QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS2K6Query, GetURecvNotifyByS2K6QueryVariables>(
    GetURecvNotifyByS2K6Document,
    options
  );
}
export type GetURecvNotifyByS2K6QueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K6Query>;
export type GetURecvNotifyByS2K6LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K6LazyQuery>;
export type GetURecvNotifyByS2K6QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS2K6Query,
  GetURecvNotifyByS2K6QueryVariables
>;
export const GetURecvNotifyByS2K7Document = gql`
  query GetURecvNotifyByS2K7 {
    getURecvNotifyByS2K7 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS2K7Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS2K7Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS2K7Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS2K7Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS2K7Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS2K7Query, GetURecvNotifyByS2K7QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS2K7Query, GetURecvNotifyByS2K7QueryVariables>(
    GetURecvNotifyByS2K7Document,
    options
  );
}
export function useGetURecvNotifyByS2K7LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS2K7Query, GetURecvNotifyByS2K7QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS2K7Query, GetURecvNotifyByS2K7QueryVariables>(
    GetURecvNotifyByS2K7Document,
    options
  );
}
export type GetURecvNotifyByS2K7QueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K7Query>;
export type GetURecvNotifyByS2K7LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS2K7LazyQuery>;
export type GetURecvNotifyByS2K7QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS2K7Query,
  GetURecvNotifyByS2K7QueryVariables
>;
export const GetURecvNotifyByS3K1Document = gql`
  query GetURecvNotifyByS3K1 {
    getURecvNotifyByS3K1 {
      home {
        b_badge
        b_identfy
        b_join
        b_pay
        b_quit
        t_change
        t_pay
        t_quit
        t_regist
        __typename
      }
      list {
        body
        id
        is_read
        kind
        recv_date
        ref_no
        screen_no
        sys_id
        title
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS3K1Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS3K1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS3K1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS3K1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS3K1Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS3K1Query, GetURecvNotifyByS3K1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS3K1Query, GetURecvNotifyByS3K1QueryVariables>(
    GetURecvNotifyByS3K1Document,
    options
  );
}
export function useGetURecvNotifyByS3K1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS3K1Query, GetURecvNotifyByS3K1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS3K1Query, GetURecvNotifyByS3K1QueryVariables>(
    GetURecvNotifyByS3K1Document,
    options
  );
}
export type GetURecvNotifyByS3K1QueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K1Query>;
export type GetURecvNotifyByS3K1LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K1LazyQuery>;
export type GetURecvNotifyByS3K1QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS3K1Query,
  GetURecvNotifyByS3K1QueryVariables
>;
export const GetURecvNotifyByS3K2Document = gql`
  query GetURecvNotifyByS3K2 {
    getURecvNotifyByS3K2 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS3K2Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS3K2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS3K2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS3K2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS3K2Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS3K2Query, GetURecvNotifyByS3K2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS3K2Query, GetURecvNotifyByS3K2QueryVariables>(
    GetURecvNotifyByS3K2Document,
    options
  );
}
export function useGetURecvNotifyByS3K2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS3K2Query, GetURecvNotifyByS3K2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS3K2Query, GetURecvNotifyByS3K2QueryVariables>(
    GetURecvNotifyByS3K2Document,
    options
  );
}
export type GetURecvNotifyByS3K2QueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K2Query>;
export type GetURecvNotifyByS3K2LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K2LazyQuery>;
export type GetURecvNotifyByS3K2QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS3K2Query,
  GetURecvNotifyByS3K2QueryVariables
>;
export const GetURecvNotifyByS3K3Document = gql`
  query GetURecvNotifyByS3K3 {
    getURecvNotifyByS3K3 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS3K3Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS3K3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS3K3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS3K3Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS3K3Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS3K3Query, GetURecvNotifyByS3K3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS3K3Query, GetURecvNotifyByS3K3QueryVariables>(
    GetURecvNotifyByS3K3Document,
    options
  );
}
export function useGetURecvNotifyByS3K3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS3K3Query, GetURecvNotifyByS3K3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS3K3Query, GetURecvNotifyByS3K3QueryVariables>(
    GetURecvNotifyByS3K3Document,
    options
  );
}
export type GetURecvNotifyByS3K3QueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K3Query>;
export type GetURecvNotifyByS3K3LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K3LazyQuery>;
export type GetURecvNotifyByS3K3QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS3K3Query,
  GetURecvNotifyByS3K3QueryVariables
>;
export const GetURecvNotifyByS3K5Document = gql`
  query GetURecvNotifyByS3K5 {
    getURecvNotifyByS3K5 {
      body
      id
      is_read
      kind
      recv_date
      ref_no
      screen_no
      sys_id
      title
      __typename
    }
  }
`;

/**
 * __useGetURecvNotifyByS3K5Query__
 *
 * To run a query within a React component, call `useGetURecvNotifyByS3K5Query` and pass it any options that fit your needs.
 * When your component renders, `useGetURecvNotifyByS3K5Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetURecvNotifyByS3K5Query({
 *   variables: {
 *   },
 * });
 */
export function useGetURecvNotifyByS3K5Query(
  baseOptions?: Apollo.QueryHookOptions<GetURecvNotifyByS3K5Query, GetURecvNotifyByS3K5QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetURecvNotifyByS3K5Query, GetURecvNotifyByS3K5QueryVariables>(
    GetURecvNotifyByS3K5Document,
    options
  );
}
export function useGetURecvNotifyByS3K5LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetURecvNotifyByS3K5Query, GetURecvNotifyByS3K5QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetURecvNotifyByS3K5Query, GetURecvNotifyByS3K5QueryVariables>(
    GetURecvNotifyByS3K5Document,
    options
  );
}
export type GetURecvNotifyByS3K5QueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K5Query>;
export type GetURecvNotifyByS3K5LazyQueryHookResult = ReturnType<typeof useGetURecvNotifyByS3K5LazyQuery>;
export type GetURecvNotifyByS3K5QueryResult = Apollo.QueryResult<
  GetURecvNotifyByS3K5Query,
  GetURecvNotifyByS3K5QueryVariables
>;
export const GetVbCancelInfoByIdDocument = gql`
  query GetVBCancelInfoById($id: Int!) {
    getVBCancelInfoById(id: $id) {
      cancel_count
      cancel_per
      id
      identification
      image
      imminent_count
      imminent_per
      late_count
      late_per
      name
      penalty
      skip_count
      status
      __typename
    }
  }
`;

/**
 * __useGetVbCancelInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetVbCancelInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbCancelInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbCancelInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbCancelInfoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbCancelInfoByIdQuery, GetVbCancelInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbCancelInfoByIdQuery, GetVbCancelInfoByIdQueryVariables>(
    GetVbCancelInfoByIdDocument,
    options
  );
}
export function useGetVbCancelInfoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbCancelInfoByIdQuery, GetVbCancelInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbCancelInfoByIdQuery, GetVbCancelInfoByIdQueryVariables>(
    GetVbCancelInfoByIdDocument,
    options
  );
}
export type GetVbCancelInfoByIdQueryHookResult = ReturnType<typeof useGetVbCancelInfoByIdQuery>;
export type GetVbCancelInfoByIdLazyQueryHookResult = ReturnType<typeof useGetVbCancelInfoByIdLazyQuery>;
export type GetVbCancelInfoByIdQueryResult = Apollo.QueryResult<
  GetVbCancelInfoByIdQuery,
  GetVbCancelInfoByIdQueryVariables
>;
export const GetVbCpGiftListByKind11Document = gql`
  query GetVBCpGiftListByKind11 {
    getVBCpGiftListByKind11 {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      egift_code
      end_date
      gift_name
      id
      incl_resident
      incl_resident_disp
      kind
      state_ids
      state_names
      status
      title
      __typename
    }
  }
`;

/**
 * __useGetVbCpGiftListByKind11Query__
 *
 * To run a query within a React component, call `useGetVbCpGiftListByKind11Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVbCpGiftListByKind11Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbCpGiftListByKind11Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVbCpGiftListByKind11Query(
  baseOptions?: Apollo.QueryHookOptions<GetVbCpGiftListByKind11Query, GetVbCpGiftListByKind11QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbCpGiftListByKind11Query, GetVbCpGiftListByKind11QueryVariables>(
    GetVbCpGiftListByKind11Document,
    options
  );
}
export function useGetVbCpGiftListByKind11LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbCpGiftListByKind11Query, GetVbCpGiftListByKind11QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbCpGiftListByKind11Query, GetVbCpGiftListByKind11QueryVariables>(
    GetVbCpGiftListByKind11Document,
    options
  );
}
export type GetVbCpGiftListByKind11QueryHookResult = ReturnType<typeof useGetVbCpGiftListByKind11Query>;
export type GetVbCpGiftListByKind11LazyQueryHookResult = ReturnType<typeof useGetVbCpGiftListByKind11LazyQuery>;
export type GetVbCpGiftListByKind11QueryResult = Apollo.QueryResult<
  GetVbCpGiftListByKind11Query,
  GetVbCpGiftListByKind11QueryVariables
>;
export const GetVbCpGiftListByKind12Document = gql`
  query GetVBCpGiftListByKind12 {
    getVBCpGiftListByKind12 {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      egift_code
      end_date
      gift_name
      id
      incl_resident
      incl_resident_disp
      kind
      state_ids
      state_names
      status
      title
      __typename
    }
  }
`;

/**
 * __useGetVbCpGiftListByKind12Query__
 *
 * To run a query within a React component, call `useGetVbCpGiftListByKind12Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVbCpGiftListByKind12Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbCpGiftListByKind12Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVbCpGiftListByKind12Query(
  baseOptions?: Apollo.QueryHookOptions<GetVbCpGiftListByKind12Query, GetVbCpGiftListByKind12QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbCpGiftListByKind12Query, GetVbCpGiftListByKind12QueryVariables>(
    GetVbCpGiftListByKind12Document,
    options
  );
}
export function useGetVbCpGiftListByKind12LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbCpGiftListByKind12Query, GetVbCpGiftListByKind12QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbCpGiftListByKind12Query, GetVbCpGiftListByKind12QueryVariables>(
    GetVbCpGiftListByKind12Document,
    options
  );
}
export type GetVbCpGiftListByKind12QueryHookResult = ReturnType<typeof useGetVbCpGiftListByKind12Query>;
export type GetVbCpGiftListByKind12LazyQueryHookResult = ReturnType<typeof useGetVbCpGiftListByKind12LazyQuery>;
export type GetVbCpGiftListByKind12QueryResult = Apollo.QueryResult<
  GetVbCpGiftListByKind12Query,
  GetVbCpGiftListByKind12QueryVariables
>;
export const GetVbFavoriteStoreListByIdDocument = gql`
  query GetVBFavoriteStoreListById($id: Int!) {
    getVBFavoriteStoreListById(id: $id) {
      btmem_id
      image
      reg_date
      score
      store_name
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVbFavoriteStoreListByIdQuery__
 *
 * To run a query within a React component, call `useGetVbFavoriteStoreListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbFavoriteStoreListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbFavoriteStoreListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbFavoriteStoreListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbFavoriteStoreListByIdQuery, GetVbFavoriteStoreListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbFavoriteStoreListByIdQuery, GetVbFavoriteStoreListByIdQueryVariables>(
    GetVbFavoriteStoreListByIdDocument,
    options
  );
}
export function useGetVbFavoriteStoreListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbFavoriteStoreListByIdQuery, GetVbFavoriteStoreListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbFavoriteStoreListByIdQuery, GetVbFavoriteStoreListByIdQueryVariables>(
    GetVbFavoriteStoreListByIdDocument,
    options
  );
}
export type GetVbFavoriteStoreListByIdQueryHookResult = ReturnType<typeof useGetVbFavoriteStoreListByIdQuery>;
export type GetVbFavoriteStoreListByIdLazyQueryHookResult = ReturnType<typeof useGetVbFavoriteStoreListByIdLazyQuery>;
export type GetVbFavoriteStoreListByIdQueryResult = Apollo.QueryResult<
  GetVbFavoriteStoreListByIdQuery,
  GetVbFavoriteStoreListByIdQueryVariables
>;
export const GetVbGiftSettingListDocument = gql`
  query GetVBGiftSettingList {
    getVBGiftSettingList {
      deadline
      egift_code
      egift_name
      kind
      __typename
    }
  }
`;

/**
 * __useGetVbGiftSettingListQuery__
 *
 * To run a query within a React component, call `useGetVbGiftSettingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbGiftSettingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbGiftSettingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbGiftSettingListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbGiftSettingListQuery, GetVbGiftSettingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbGiftSettingListQuery, GetVbGiftSettingListQueryVariables>(
    GetVbGiftSettingListDocument,
    options
  );
}
export function useGetVbGiftSettingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbGiftSettingListQuery, GetVbGiftSettingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbGiftSettingListQuery, GetVbGiftSettingListQueryVariables>(
    GetVbGiftSettingListDocument,
    options
  );
}
export type GetVbGiftSettingListQueryHookResult = ReturnType<typeof useGetVbGiftSettingListQuery>;
export type GetVbGiftSettingListLazyQueryHookResult = ReturnType<typeof useGetVbGiftSettingListLazyQuery>;
export type GetVbGiftSettingListQueryResult = Apollo.QueryResult<
  GetVbGiftSettingListQuery,
  GetVbGiftSettingListQueryVariables
>;
export const GetVbiPayCsvListNewlyPayArbeitDocument = gql`
  query GetVBIPayCsvListNewlyPayArbeit {
    getVBIPayCsvListNewlyPayArbeit {
      csvlist {
        csv_no
        csv_url
        dl_count
        dl_date
        name
        output_date
        output_number
        unopened
        __typename
      }
      newly {
        im_inv
        im_pay
        mn_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbiPayCsvListNewlyPayArbeitQuery__
 *
 * To run a query within a React component, call `useGetVbiPayCsvListNewlyPayArbeitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbiPayCsvListNewlyPayArbeitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbiPayCsvListNewlyPayArbeitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbiPayCsvListNewlyPayArbeitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVbiPayCsvListNewlyPayArbeitQuery,
    GetVbiPayCsvListNewlyPayArbeitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbiPayCsvListNewlyPayArbeitQuery, GetVbiPayCsvListNewlyPayArbeitQueryVariables>(
    GetVbiPayCsvListNewlyPayArbeitDocument,
    options
  );
}
export function useGetVbiPayCsvListNewlyPayArbeitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbiPayCsvListNewlyPayArbeitQuery,
    GetVbiPayCsvListNewlyPayArbeitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbiPayCsvListNewlyPayArbeitQuery, GetVbiPayCsvListNewlyPayArbeitQueryVariables>(
    GetVbiPayCsvListNewlyPayArbeitDocument,
    options
  );
}
export type GetVbiPayCsvListNewlyPayArbeitQueryHookResult = ReturnType<typeof useGetVbiPayCsvListNewlyPayArbeitQuery>;
export type GetVbiPayCsvListNewlyPayArbeitLazyQueryHookResult = ReturnType<
  typeof useGetVbiPayCsvListNewlyPayArbeitLazyQuery
>;
export type GetVbiPayCsvListNewlyPayArbeitQueryResult = Apollo.QueryResult<
  GetVbiPayCsvListNewlyPayArbeitQuery,
  GetVbiPayCsvListNewlyPayArbeitQueryVariables
>;
export const GetVbIdentificationInfoDocument = gql`
  query GetVBIdentificationInfo {
    getVBIdentificationInfo {
      address
      address2
      age
      birthday
      btmem_id
      btmem_number
      city
      created_date
      ecn
      email
      gender
      general_licenses
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      is_japanese
      kana
      name
      nationality
      parental
      parental_name
      period_date
      phone
      reg_hs_student
      reg_minor
      residence_name
      rule
      school
      state
      status
      student
      submit_date
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationInfoQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbIdentificationInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbIdentificationInfoQuery, GetVbIdentificationInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbIdentificationInfoQuery, GetVbIdentificationInfoQueryVariables>(
    GetVbIdentificationInfoDocument,
    options
  );
}
export function useGetVbIdentificationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbIdentificationInfoQuery, GetVbIdentificationInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbIdentificationInfoQuery, GetVbIdentificationInfoQueryVariables>(
    GetVbIdentificationInfoDocument,
    options
  );
}
export type GetVbIdentificationInfoQueryHookResult = ReturnType<typeof useGetVbIdentificationInfoQuery>;
export type GetVbIdentificationInfoLazyQueryHookResult = ReturnType<typeof useGetVbIdentificationInfoLazyQuery>;
export type GetVbIdentificationInfoQueryResult = Apollo.QueryResult<
  GetVbIdentificationInfoQuery,
  GetVbIdentificationInfoQueryVariables
>;
export const GetVbIdentificationListDocument = gql`
  query GetVBIdentificationList {
    getVBIdentificationList {
      btmem_id
      complete_date
      entry_date
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      name
      newly
      submit_date
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationListQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbIdentificationListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbIdentificationListQuery, GetVbIdentificationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbIdentificationListQuery, GetVbIdentificationListQueryVariables>(
    GetVbIdentificationListDocument,
    options
  );
}
export function useGetVbIdentificationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbIdentificationListQuery, GetVbIdentificationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbIdentificationListQuery, GetVbIdentificationListQueryVariables>(
    GetVbIdentificationListDocument,
    options
  );
}
export type GetVbIdentificationListQueryHookResult = ReturnType<typeof useGetVbIdentificationListQuery>;
export type GetVbIdentificationListLazyQueryHookResult = ReturnType<typeof useGetVbIdentificationListLazyQuery>;
export type GetVbIdentificationListQueryResult = Apollo.QueryResult<
  GetVbIdentificationListQuery,
  GetVbIdentificationListQueryVariables
>;
export const GetVbIdentificationListByBtmemIdDocument = gql`
  query GetVBIdentificationListByBtmemId($btmem_id: Int!) {
    getVBIdentificationListByBtmemId(btmem_id: $btmem_id) {
      btmem_id
      complete_date
      entry_date
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      name
      newly
      submit_date
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationListByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationListByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationListByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationListByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetVbIdentificationListByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbIdentificationListByBtmemIdQuery,
    GetVbIdentificationListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbIdentificationListByBtmemIdQuery, GetVbIdentificationListByBtmemIdQueryVariables>(
    GetVbIdentificationListByBtmemIdDocument,
    options
  );
}
export function useGetVbIdentificationListByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbIdentificationListByBtmemIdQuery,
    GetVbIdentificationListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbIdentificationListByBtmemIdQuery, GetVbIdentificationListByBtmemIdQueryVariables>(
    GetVbIdentificationListByBtmemIdDocument,
    options
  );
}
export type GetVbIdentificationListByBtmemIdQueryHookResult = ReturnType<
  typeof useGetVbIdentificationListByBtmemIdQuery
>;
export type GetVbIdentificationListByBtmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVbIdentificationListByBtmemIdLazyQuery
>;
export type GetVbIdentificationListByBtmemIdQueryResult = Apollo.QueryResult<
  GetVbIdentificationListByBtmemIdQuery,
  GetVbIdentificationListByBtmemIdQueryVariables
>;
export const GetVbIdentificationListByKeyDocument = gql`
  query GetVBIdentificationListByKey($internal_key: Int!) {
    getVBIdentificationListByKey(internal_key: $internal_key) {
      btmem_id
      complete_date
      entry_date
      id2_doc1
      id2_doc2
      id2_type
      id_doc1
      id_doc2
      id_type
      identification
      image
      internal_key
      name
      newly
      submit_date
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationListByKeyQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationListByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationListByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationListByKeyQuery({
 *   variables: {
 *      internal_key: // value for 'internal_key'
 *   },
 * });
 */
export function useGetVbIdentificationListByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbIdentificationListByKeyQuery, GetVbIdentificationListByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbIdentificationListByKeyQuery, GetVbIdentificationListByKeyQueryVariables>(
    GetVbIdentificationListByKeyDocument,
    options
  );
}
export function useGetVbIdentificationListByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbIdentificationListByKeyQuery,
    GetVbIdentificationListByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbIdentificationListByKeyQuery, GetVbIdentificationListByKeyQueryVariables>(
    GetVbIdentificationListByKeyDocument,
    options
  );
}
export type GetVbIdentificationListByKeyQueryHookResult = ReturnType<typeof useGetVbIdentificationListByKeyQuery>;
export type GetVbIdentificationListByKeyLazyQueryHookResult = ReturnType<
  typeof useGetVbIdentificationListByKeyLazyQuery
>;
export type GetVbIdentificationListByKeyQueryResult = Apollo.QueryResult<
  GetVbIdentificationListByKeyQuery,
  GetVbIdentificationListByKeyQueryVariables
>;
export const GetVbIdentificationLoggingListByBtmemIdDocument = gql`
  query GetVBIdentificationLoggingListByBtmemId($btmem_id: Int!) {
    getVBIdentificationLoggingListByBtmemId(btmem_id: $btmem_id) {
      identification {
        btmem_id
        complete_date
        entry_date
        id2_doc1
        id2_doc2
        id2_type
        id_doc1
        id_doc2
        id_type
        identification
        image
        internal_key
        name
        newly
        submit_date
        __typename
      }
      logging {
        btmem_id
        id
        kind
        log_date
        matter
        ref_no
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationLoggingListByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationLoggingListByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationLoggingListByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationLoggingListByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetVbIdentificationLoggingListByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbIdentificationLoggingListByBtmemIdQuery,
    GetVbIdentificationLoggingListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVbIdentificationLoggingListByBtmemIdQuery,
    GetVbIdentificationLoggingListByBtmemIdQueryVariables
  >(GetVbIdentificationLoggingListByBtmemIdDocument, options);
}
export function useGetVbIdentificationLoggingListByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbIdentificationLoggingListByBtmemIdQuery,
    GetVbIdentificationLoggingListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVbIdentificationLoggingListByBtmemIdQuery,
    GetVbIdentificationLoggingListByBtmemIdQueryVariables
  >(GetVbIdentificationLoggingListByBtmemIdDocument, options);
}
export type GetVbIdentificationLoggingListByBtmemIdQueryHookResult = ReturnType<
  typeof useGetVbIdentificationLoggingListByBtmemIdQuery
>;
export type GetVbIdentificationLoggingListByBtmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVbIdentificationLoggingListByBtmemIdLazyQuery
>;
export type GetVbIdentificationLoggingListByBtmemIdQueryResult = Apollo.QueryResult<
  GetVbIdentificationLoggingListByBtmemIdQuery,
  GetVbIdentificationLoggingListByBtmemIdQueryVariables
>;
export const GetVbIdentificationLoggingListByKeyDocument = gql`
  query GetVBIdentificationLoggingListByKey($internal_key: Int!) {
    getVBIdentificationLoggingListByKey(internal_key: $internal_key) {
      identification {
        btmem_id
        complete_date
        entry_date
        id2_doc1
        id2_doc2
        id2_type
        id_doc1
        id_doc2
        id_type
        identification
        image
        internal_key
        name
        newly
        submit_date
        __typename
      }
      logging {
        btmem_id
        id
        kind
        log_date
        matter
        ref_no
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbIdentificationLoggingListByKeyQuery__
 *
 * To run a query within a React component, call `useGetVbIdentificationLoggingListByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbIdentificationLoggingListByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbIdentificationLoggingListByKeyQuery({
 *   variables: {
 *      internal_key: // value for 'internal_key'
 *   },
 * });
 */
export function useGetVbIdentificationLoggingListByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbIdentificationLoggingListByKeyQuery,
    GetVbIdentificationLoggingListByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbIdentificationLoggingListByKeyQuery, GetVbIdentificationLoggingListByKeyQueryVariables>(
    GetVbIdentificationLoggingListByKeyDocument,
    options
  );
}
export function useGetVbIdentificationLoggingListByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbIdentificationLoggingListByKeyQuery,
    GetVbIdentificationLoggingListByKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVbIdentificationLoggingListByKeyQuery,
    GetVbIdentificationLoggingListByKeyQueryVariables
  >(GetVbIdentificationLoggingListByKeyDocument, options);
}
export type GetVbIdentificationLoggingListByKeyQueryHookResult = ReturnType<
  typeof useGetVbIdentificationLoggingListByKeyQuery
>;
export type GetVbIdentificationLoggingListByKeyLazyQueryHookResult = ReturnType<
  typeof useGetVbIdentificationLoggingListByKeyLazyQuery
>;
export type GetVbIdentificationLoggingListByKeyQueryResult = Apollo.QueryResult<
  GetVbIdentificationLoggingListByKeyQuery,
  GetVbIdentificationLoggingListByKeyQueryVariables
>;
export const GetVbmPayCsvListNewlyPayArbeitDocument = gql`
  query GetVBMPayCsvListNewlyPayArbeit {
    getVBMPayCsvListNewlyPayArbeit {
      csvlist {
        csv_no
        csv_url
        dl_count
        dl_date
        name
        output_date
        output_number
        pay_date
        unopened
        __typename
      }
      newly {
        im_inv
        im_pay
        mn_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbmPayCsvListNewlyPayArbeitQuery__
 *
 * To run a query within a React component, call `useGetVbmPayCsvListNewlyPayArbeitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbmPayCsvListNewlyPayArbeitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbmPayCsvListNewlyPayArbeitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbmPayCsvListNewlyPayArbeitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVbmPayCsvListNewlyPayArbeitQuery,
    GetVbmPayCsvListNewlyPayArbeitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbmPayCsvListNewlyPayArbeitQuery, GetVbmPayCsvListNewlyPayArbeitQueryVariables>(
    GetVbmPayCsvListNewlyPayArbeitDocument,
    options
  );
}
export function useGetVbmPayCsvListNewlyPayArbeitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbmPayCsvListNewlyPayArbeitQuery,
    GetVbmPayCsvListNewlyPayArbeitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbmPayCsvListNewlyPayArbeitQuery, GetVbmPayCsvListNewlyPayArbeitQueryVariables>(
    GetVbmPayCsvListNewlyPayArbeitDocument,
    options
  );
}
export type GetVbmPayCsvListNewlyPayArbeitQueryHookResult = ReturnType<typeof useGetVbmPayCsvListNewlyPayArbeitQuery>;
export type GetVbmPayCsvListNewlyPayArbeitLazyQueryHookResult = ReturnType<
  typeof useGetVbmPayCsvListNewlyPayArbeitLazyQuery
>;
export type GetVbmPayCsvListNewlyPayArbeitQueryResult = Apollo.QueryResult<
  GetVbmPayCsvListNewlyPayArbeitQuery,
  GetVbmPayCsvListNewlyPayArbeitQueryVariables
>;
export const GetVbMemberBaseByIdDocument = gql`
  query GetVBMemberBaseById($id: Int!) {
    getVBMemberBaseById(id: $id) {
      account_name
      account_no
      account_type
      address
      address2
      age
      attr_id
      attribute
      badge_name
      bank
      belong_biz_count
      birthday
      birthday2
      branch_name
      btmem_number
      cancel_count
      cancel_per
      city
      created_date
      email
      gender
      gender_value
      general_licenses
      id
      identification
      image
      imminent_count
      imminent_per
      kana
      late_count
      late_per
      lc_biz_count
      lc_biz_names
      license_list
      name
      nationality
      pause_end_day
      period_date
      phone
      rank_biz_count
      rank_biz_names
      rank_names
      reg_biz_list
      residence_name
      score
      skip_count
      state
      state_count
      status
      sub_names
      tp_biz_names
      tpmem_names
      uniform_id
      uniform_size
      worked_count
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVbMemberBaseByIdQuery__
 *
 * To run a query within a React component, call `useGetVbMemberBaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbMemberBaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbMemberBaseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbMemberBaseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbMemberBaseByIdQuery, GetVbMemberBaseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbMemberBaseByIdQuery, GetVbMemberBaseByIdQueryVariables>(
    GetVbMemberBaseByIdDocument,
    options
  );
}
export function useGetVbMemberBaseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbMemberBaseByIdQuery, GetVbMemberBaseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbMemberBaseByIdQuery, GetVbMemberBaseByIdQueryVariables>(
    GetVbMemberBaseByIdDocument,
    options
  );
}
export type GetVbMemberBaseByIdQueryHookResult = ReturnType<typeof useGetVbMemberBaseByIdQuery>;
export type GetVbMemberBaseByIdLazyQueryHookResult = ReturnType<typeof useGetVbMemberBaseByIdLazyQuery>;
export type GetVbMemberBaseByIdQueryResult = Apollo.QueryResult<
  GetVbMemberBaseByIdQuery,
  GetVbMemberBaseByIdQueryVariables
>;
export const GetVbMemberListDocument = gql`
  query GetVBMemberList {
    getVBMemberList {
      address
      address2
      age
      all_biz_count
      all_biz_ids
      all_biz_names
      attribute
      belong_biz_count
      birthday
      btmem_number
      cancel_per
      city
      email
      gender
      id
      identification
      image
      name
      nationality_id
      newly
      phone
      rank_biz_count
      rank_biz_ids
      rank_biz_names
      rank_names
      residence
      score
      score_count
      state
      status
      sub_names
      tp_biz_names
      tpmem_dates
      tpmem_names
      uniform_size
      worked_count
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVbMemberListQuery__
 *
 * To run a query within a React component, call `useGetVbMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbMemberListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbMemberListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbMemberListQuery, GetVbMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbMemberListQuery, GetVbMemberListQueryVariables>(GetVbMemberListDocument, options);
}
export function useGetVbMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbMemberListQuery, GetVbMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbMemberListQuery, GetVbMemberListQueryVariables>(GetVbMemberListDocument, options);
}
export type GetVbMemberListQueryHookResult = ReturnType<typeof useGetVbMemberListQuery>;
export type GetVbMemberListLazyQueryHookResult = ReturnType<typeof useGetVbMemberListLazyQuery>;
export type GetVbMemberListQueryResult = Apollo.QueryResult<GetVbMemberListQuery, GetVbMemberListQueryVariables>;
export const GetVbMemberListFavoriteByIdDocument = gql`
  query GetVBMemberListFavoriteById($id: Int!) {
    getVBMemberListFavoriteById(id: $id) {
      favorite {
        btmem_id
        image
        reg_date
        score
        store_name
        tpmem_id
        __typename
      }
      member {
        address
        address2
        age
        all_biz_count
        all_biz_ids
        all_biz_names
        attribute
        belong_biz_count
        birthday
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        name
        nationality_id
        newly
        phone
        rank_biz_count
        rank_biz_ids
        rank_biz_names
        rank_names
        residence
        score
        score_count
        state
        status
        sub_names
        tp_biz_names
        tpmem_dates
        tpmem_names
        uniform_size
        worked_count
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbMemberListFavoriteByIdQuery__
 *
 * To run a query within a React component, call `useGetVbMemberListFavoriteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbMemberListFavoriteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbMemberListFavoriteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbMemberListFavoriteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbMemberListFavoriteByIdQuery, GetVbMemberListFavoriteByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbMemberListFavoriteByIdQuery, GetVbMemberListFavoriteByIdQueryVariables>(
    GetVbMemberListFavoriteByIdDocument,
    options
  );
}
export function useGetVbMemberListFavoriteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbMemberListFavoriteByIdQuery, GetVbMemberListFavoriteByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbMemberListFavoriteByIdQuery, GetVbMemberListFavoriteByIdQueryVariables>(
    GetVbMemberListFavoriteByIdDocument,
    options
  );
}
export type GetVbMemberListFavoriteByIdQueryHookResult = ReturnType<typeof useGetVbMemberListFavoriteByIdQuery>;
export type GetVbMemberListFavoriteByIdLazyQueryHookResult = ReturnType<typeof useGetVbMemberListFavoriteByIdLazyQuery>;
export type GetVbMemberListFavoriteByIdQueryResult = Apollo.QueryResult<
  GetVbMemberListFavoriteByIdQuery,
  GetVbMemberListFavoriteByIdQueryVariables
>;
export const GetVbMemberListLoggingByIdDocument = gql`
  query GetVBMemberListLoggingById($id: Int!) {
    getVBMemberListLoggingById(id: $id) {
      logging {
        btmem_id
        id
        kind
        log_date
        matter
        ref_no
        __typename
      }
      member {
        address
        address2
        age
        all_biz_count
        all_biz_ids
        all_biz_names
        attribute
        belong_biz_count
        birthday
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        name
        nationality_id
        newly
        phone
        rank_biz_count
        rank_biz_ids
        rank_biz_names
        rank_names
        residence
        score
        score_count
        state
        status
        sub_names
        tp_biz_names
        tpmem_dates
        tpmem_names
        uniform_size
        worked_count
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbMemberListLoggingByIdQuery__
 *
 * To run a query within a React component, call `useGetVbMemberListLoggingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbMemberListLoggingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbMemberListLoggingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbMemberListLoggingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbMemberListLoggingByIdQuery, GetVbMemberListLoggingByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbMemberListLoggingByIdQuery, GetVbMemberListLoggingByIdQueryVariables>(
    GetVbMemberListLoggingByIdDocument,
    options
  );
}
export function useGetVbMemberListLoggingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbMemberListLoggingByIdQuery, GetVbMemberListLoggingByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbMemberListLoggingByIdQuery, GetVbMemberListLoggingByIdQueryVariables>(
    GetVbMemberListLoggingByIdDocument,
    options
  );
}
export type GetVbMemberListLoggingByIdQueryHookResult = ReturnType<typeof useGetVbMemberListLoggingByIdQuery>;
export type GetVbMemberListLoggingByIdLazyQueryHookResult = ReturnType<typeof useGetVbMemberListLoggingByIdLazyQuery>;
export type GetVbMemberListLoggingByIdQueryResult = Apollo.QueryResult<
  GetVbMemberListLoggingByIdQuery,
  GetVbMemberListLoggingByIdQueryVariables
>;
export const GetVbMemberListWithholdByIdDocument = gql`
  query GetVBMemberListWithholdById($id: Int!) {
    getVBMemberListWithholdById(id: $id) {
      member {
        address
        address2
        age
        all_biz_count
        all_biz_ids
        all_biz_names
        attribute
        belong_biz_count
        birthday
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        name
        nationality_id
        newly
        phone
        rank_biz_count
        rank_biz_ids
        rank_biz_names
        rank_names
        residence
        score
        score_count
        state
        status
        sub_names
        tp_biz_names
        tpmem_dates
        tpmem_names
        uniform_size
        worked_count
        zip_code
        __typename
      }
      withhold {
        btmem_id
        corp_name
        corporate_no
        last_date
        last_day
        year_val
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbMemberListWithholdByIdQuery__
 *
 * To run a query within a React component, call `useGetVbMemberListWithholdByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbMemberListWithholdByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbMemberListWithholdByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbMemberListWithholdByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbMemberListWithholdByIdQuery, GetVbMemberListWithholdByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbMemberListWithholdByIdQuery, GetVbMemberListWithholdByIdQueryVariables>(
    GetVbMemberListWithholdByIdDocument,
    options
  );
}
export function useGetVbMemberListWithholdByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbMemberListWithholdByIdQuery, GetVbMemberListWithholdByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbMemberListWithholdByIdQuery, GetVbMemberListWithholdByIdQueryVariables>(
    GetVbMemberListWithholdByIdDocument,
    options
  );
}
export type GetVbMemberListWithholdByIdQueryHookResult = ReturnType<typeof useGetVbMemberListWithholdByIdQuery>;
export type GetVbMemberListWithholdByIdLazyQueryHookResult = ReturnType<typeof useGetVbMemberListWithholdByIdLazyQuery>;
export type GetVbMemberListWithholdByIdQueryResult = Apollo.QueryResult<
  GetVbMemberListWithholdByIdQuery,
  GetVbMemberListWithholdByIdQueryVariables
>;
export const GetVbOfferAcceptInfoByAcceptIdDocument = gql`
  query GetVBOfferAcceptInfoByAcceptId($accept_id: Int!) {
    getVBOfferAcceptInfoByAcceptId(accept_id: $accept_id) {
      accept_id
      access
      attached_file
      attached_name
      auto_decide
      begin_end
      belongings
      break_time
      caution
      chief
      conditions
      description
      hour_wage
      image1
      image2
      image3
      image4
      image5
      name
      offer_id
      offer_number
      tpmem_id
      trans_fee
      __typename
    }
  }
`;

/**
 * __useGetVbOfferAcceptInfoByAcceptIdQuery__
 *
 * To run a query within a React component, call `useGetVbOfferAcceptInfoByAcceptIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferAcceptInfoByAcceptIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferAcceptInfoByAcceptIdQuery({
 *   variables: {
 *      accept_id: // value for 'accept_id'
 *   },
 * });
 */
export function useGetVbOfferAcceptInfoByAcceptIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbOfferAcceptInfoByAcceptIdQuery,
    GetVbOfferAcceptInfoByAcceptIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferAcceptInfoByAcceptIdQuery, GetVbOfferAcceptInfoByAcceptIdQueryVariables>(
    GetVbOfferAcceptInfoByAcceptIdDocument,
    options
  );
}
export function useGetVbOfferAcceptInfoByAcceptIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbOfferAcceptInfoByAcceptIdQuery,
    GetVbOfferAcceptInfoByAcceptIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferAcceptInfoByAcceptIdQuery, GetVbOfferAcceptInfoByAcceptIdQueryVariables>(
    GetVbOfferAcceptInfoByAcceptIdDocument,
    options
  );
}
export type GetVbOfferAcceptInfoByAcceptIdQueryHookResult = ReturnType<typeof useGetVbOfferAcceptInfoByAcceptIdQuery>;
export type GetVbOfferAcceptInfoByAcceptIdLazyQueryHookResult = ReturnType<
  typeof useGetVbOfferAcceptInfoByAcceptIdLazyQuery
>;
export type GetVbOfferAcceptInfoByAcceptIdQueryResult = Apollo.QueryResult<
  GetVbOfferAcceptInfoByAcceptIdQuery,
  GetVbOfferAcceptInfoByAcceptIdQueryVariables
>;
export const GetVbOfferAcceptListByOfferIdDocument = gql`
  query GetVBOfferAcceptListByOfferId($b_offer_id: Int!) {
    getVBOfferAcceptListByOfferId(b_offer_id: $b_offer_id) {
      accept_id
      b_offer_id
      image
      name
      offer_id
      status
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVbOfferAcceptListByOfferIdQuery__
 *
 * To run a query within a React component, call `useGetVbOfferAcceptListByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferAcceptListByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferAcceptListByOfferIdQuery({
 *   variables: {
 *      b_offer_id: // value for 'b_offer_id'
 *   },
 * });
 */
export function useGetVbOfferAcceptListByOfferIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbOfferAcceptListByOfferIdQuery, GetVbOfferAcceptListByOfferIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferAcceptListByOfferIdQuery, GetVbOfferAcceptListByOfferIdQueryVariables>(
    GetVbOfferAcceptListByOfferIdDocument,
    options
  );
}
export function useGetVbOfferAcceptListByOfferIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbOfferAcceptListByOfferIdQuery,
    GetVbOfferAcceptListByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferAcceptListByOfferIdQuery, GetVbOfferAcceptListByOfferIdQueryVariables>(
    GetVbOfferAcceptListByOfferIdDocument,
    options
  );
}
export type GetVbOfferAcceptListByOfferIdQueryHookResult = ReturnType<typeof useGetVbOfferAcceptListByOfferIdQuery>;
export type GetVbOfferAcceptListByOfferIdLazyQueryHookResult = ReturnType<
  typeof useGetVbOfferAcceptListByOfferIdLazyQuery
>;
export type GetVbOfferAcceptListByOfferIdQueryResult = Apollo.QueryResult<
  GetVbOfferAcceptListByOfferIdQuery,
  GetVbOfferAcceptListByOfferIdQueryVariables
>;
export const GetVbOfferInfoByIdDocument = gql`
  query GetVBOfferInfoById($id: Int!) {
    getVBOfferInfoById(id: $id) {
      age
      attribute
      begin_end
      belong
      biz_name
      brand_name
      btmem_id
      btmem_name
      btmem_state
      cancel_count
      cancel_per
      comment
      created_date
      gender
      hour_wage
      id
      imminent_count
      imminent_per
      late_count
      late_per
      offer_no
      rank_biz_count
      rank_biz_names
      rank_names
      score
      state_city
      status
      tp_num
      work_time
      __typename
    }
  }
`;

/**
 * __useGetVbOfferInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetVbOfferInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVbOfferInfoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbOfferInfoByIdQuery, GetVbOfferInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferInfoByIdQuery, GetVbOfferInfoByIdQueryVariables>(
    GetVbOfferInfoByIdDocument,
    options
  );
}
export function useGetVbOfferInfoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbOfferInfoByIdQuery, GetVbOfferInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferInfoByIdQuery, GetVbOfferInfoByIdQueryVariables>(
    GetVbOfferInfoByIdDocument,
    options
  );
}
export type GetVbOfferInfoByIdQueryHookResult = ReturnType<typeof useGetVbOfferInfoByIdQuery>;
export type GetVbOfferInfoByIdLazyQueryHookResult = ReturnType<typeof useGetVbOfferInfoByIdLazyQuery>;
export type GetVbOfferInfoByIdQueryResult = Apollo.QueryResult<
  GetVbOfferInfoByIdQuery,
  GetVbOfferInfoByIdQueryVariables
>;
export const GetVbOfferListDocument = gql`
  query GetVBOfferList {
    getVBOfferList {
      begin_end
      biz_id
      biz_name
      brand_id
      brand_name
      btmem_id
      btmem_name
      btmem_state
      created_date
      offer_id
      offer_no
      state_city
      status
      tp_num
      work_time
      __typename
    }
  }
`;

/**
 * __useGetVbOfferListQuery__
 *
 * To run a query within a React component, call `useGetVbOfferListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbOfferListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbOfferListQuery, GetVbOfferListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferListQuery, GetVbOfferListQueryVariables>(GetVbOfferListDocument, options);
}
export function useGetVbOfferListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbOfferListQuery, GetVbOfferListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferListQuery, GetVbOfferListQueryVariables>(GetVbOfferListDocument, options);
}
export type GetVbOfferListQueryHookResult = ReturnType<typeof useGetVbOfferListQuery>;
export type GetVbOfferListLazyQueryHookResult = ReturnType<typeof useGetVbOfferListLazyQuery>;
export type GetVbOfferListQueryResult = Apollo.QueryResult<GetVbOfferListQuery, GetVbOfferListQueryVariables>;
export const GetVbOfferListAcceptByOfferIdDocument = gql`
  query GetVBOfferListAcceptByOfferId($offer_id: Int!) {
    getVBOfferListAcceptByOfferId(offer_id: $offer_id) {
      accept {
        accept_id
        b_offer_id
        image
        name
        offer_id
        status
        tpmem_id
        __typename
      }
      offer {
        begin_end
        biz_id
        biz_name
        brand_id
        brand_name
        btmem_id
        btmem_name
        btmem_state
        created_date
        offer_id
        offer_no
        state_city
        status
        tp_num
        work_time
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbOfferListAcceptByOfferIdQuery__
 *
 * To run a query within a React component, call `useGetVbOfferListAcceptByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferListAcceptByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferListAcceptByOfferIdQuery({
 *   variables: {
 *      offer_id: // value for 'offer_id'
 *   },
 * });
 */
export function useGetVbOfferListAcceptByOfferIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbOfferListAcceptByOfferIdQuery, GetVbOfferListAcceptByOfferIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferListAcceptByOfferIdQuery, GetVbOfferListAcceptByOfferIdQueryVariables>(
    GetVbOfferListAcceptByOfferIdDocument,
    options
  );
}
export function useGetVbOfferListAcceptByOfferIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbOfferListAcceptByOfferIdQuery,
    GetVbOfferListAcceptByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferListAcceptByOfferIdQuery, GetVbOfferListAcceptByOfferIdQueryVariables>(
    GetVbOfferListAcceptByOfferIdDocument,
    options
  );
}
export type GetVbOfferListAcceptByOfferIdQueryHookResult = ReturnType<typeof useGetVbOfferListAcceptByOfferIdQuery>;
export type GetVbOfferListAcceptByOfferIdLazyQueryHookResult = ReturnType<
  typeof useGetVbOfferListAcceptByOfferIdLazyQuery
>;
export type GetVbOfferListAcceptByOfferIdQueryResult = Apollo.QueryResult<
  GetVbOfferListAcceptByOfferIdQuery,
  GetVbOfferListAcceptByOfferIdQueryVariables
>;
export const GetVbOfferListLoggingByOfferIdDocument = gql`
  query GetVBOfferListLoggingByOfferId($offer_id: Int!) {
    getVBOfferListLoggingByOfferId(offer_id: $offer_id) {
      logging {
        btmem_id
        id
        kind
        log_date
        matter
        ref_no
        __typename
      }
      offer {
        begin_end
        biz_id
        biz_name
        brand_id
        brand_name
        btmem_id
        btmem_name
        btmem_state
        created_date
        offer_id
        offer_no
        state_city
        status
        tp_num
        work_time
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbOfferListLoggingByOfferIdQuery__
 *
 * To run a query within a React component, call `useGetVbOfferListLoggingByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbOfferListLoggingByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbOfferListLoggingByOfferIdQuery({
 *   variables: {
 *      offer_id: // value for 'offer_id'
 *   },
 * });
 */
export function useGetVbOfferListLoggingByOfferIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbOfferListLoggingByOfferIdQuery,
    GetVbOfferListLoggingByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbOfferListLoggingByOfferIdQuery, GetVbOfferListLoggingByOfferIdQueryVariables>(
    GetVbOfferListLoggingByOfferIdDocument,
    options
  );
}
export function useGetVbOfferListLoggingByOfferIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbOfferListLoggingByOfferIdQuery,
    GetVbOfferListLoggingByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbOfferListLoggingByOfferIdQuery, GetVbOfferListLoggingByOfferIdQueryVariables>(
    GetVbOfferListLoggingByOfferIdDocument,
    options
  );
}
export type GetVbOfferListLoggingByOfferIdQueryHookResult = ReturnType<typeof useGetVbOfferListLoggingByOfferIdQuery>;
export type GetVbOfferListLoggingByOfferIdLazyQueryHookResult = ReturnType<
  typeof useGetVbOfferListLoggingByOfferIdLazyQuery
>;
export type GetVbOfferListLoggingByOfferIdQueryResult = Apollo.QueryResult<
  GetVbOfferListLoggingByOfferIdQuery,
  GetVbOfferListLoggingByOfferIdQueryVariables
>;
export const GetVbPayCsvAllListDocument = gql`
  query GetVBPayCsvAllList {
    getVBPayCsvAllList {
      csv {
        csv_no
        csv_url
        dl_count
        dl_date
        name
        output_date
        output_number
        pay_date
        unopened
        __typename
      }
      newly {
        mn_all
        trans_status
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbPayCsvAllListQuery__
 *
 * To run a query within a React component, call `useGetVbPayCsvAllListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbPayCsvAllListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbPayCsvAllListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbPayCsvAllListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbPayCsvAllListQuery, GetVbPayCsvAllListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbPayCsvAllListQuery, GetVbPayCsvAllListQueryVariables>(
    GetVbPayCsvAllListDocument,
    options
  );
}
export function useGetVbPayCsvAllListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbPayCsvAllListQuery, GetVbPayCsvAllListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbPayCsvAllListQuery, GetVbPayCsvAllListQueryVariables>(
    GetVbPayCsvAllListDocument,
    options
  );
}
export type GetVbPayCsvAllListQueryHookResult = ReturnType<typeof useGetVbPayCsvAllListQuery>;
export type GetVbPayCsvAllListLazyQueryHookResult = ReturnType<typeof useGetVbPayCsvAllListLazyQuery>;
export type GetVbPayCsvAllListQueryResult = Apollo.QueryResult<
  GetVbPayCsvAllListQuery,
  GetVbPayCsvAllListQueryVariables
>;
export const GetVbReviewListDocument = gql`
  query GetVBReviewList {
    getVBReviewList {
      btmem_id
      comment
      image
      name
      offer_id
      post_date
      post_date_2
      score
      tpmem_id
      tpmem_status
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVbReviewListQuery__
 *
 * To run a query within a React component, call `useGetVbReviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbReviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbReviewListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbReviewListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbReviewListQuery, GetVbReviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbReviewListQuery, GetVbReviewListQueryVariables>(GetVbReviewListDocument, options);
}
export function useGetVbReviewListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbReviewListQuery, GetVbReviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbReviewListQuery, GetVbReviewListQueryVariables>(GetVbReviewListDocument, options);
}
export type GetVbReviewListQueryHookResult = ReturnType<typeof useGetVbReviewListQuery>;
export type GetVbReviewListLazyQueryHookResult = ReturnType<typeof useGetVbReviewListLazyQuery>;
export type GetVbReviewListQueryResult = Apollo.QueryResult<GetVbReviewListQuery, GetVbReviewListQueryVariables>;
export const GetVbReviewListScoreByBtmemIdDocument = gql`
  query GetVBReviewListScoreByBtmemId($btmem_id: Int!) {
    getVBReviewListScoreByBtmemId(btmem_id: $btmem_id) {
      info {
        btmem_id
        identification
        image
        name
        score
        score1_per
        score2_per
        score3_per
        score4_per
        score5_per
        score_count
        status
        __typename
      }
      list {
        btmem_id
        comment
        image
        name
        offer_id
        post_date
        post_date_2
        score
        tpmem_id
        tpmem_status
        work_id
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbReviewListScoreByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetVbReviewListScoreByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbReviewListScoreByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbReviewListScoreByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetVbReviewListScoreByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVbReviewListScoreByBtmemIdQuery, GetVbReviewListScoreByBtmemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbReviewListScoreByBtmemIdQuery, GetVbReviewListScoreByBtmemIdQueryVariables>(
    GetVbReviewListScoreByBtmemIdDocument,
    options
  );
}
export function useGetVbReviewListScoreByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbReviewListScoreByBtmemIdQuery,
    GetVbReviewListScoreByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbReviewListScoreByBtmemIdQuery, GetVbReviewListScoreByBtmemIdQueryVariables>(
    GetVbReviewListScoreByBtmemIdDocument,
    options
  );
}
export type GetVbReviewListScoreByBtmemIdQueryHookResult = ReturnType<typeof useGetVbReviewListScoreByBtmemIdQuery>;
export type GetVbReviewListScoreByBtmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVbReviewListScoreByBtmemIdLazyQuery
>;
export type GetVbReviewListScoreByBtmemIdQueryResult = Apollo.QueryResult<
  GetVbReviewListScoreByBtmemIdQuery,
  GetVbReviewListScoreByBtmemIdQueryVariables
>;
export const GetVbScorePercentInfoByBtmemIdDocument = gql`
  query GetVBScorePercentInfoByBtmemId($btmem_id: Int!) {
    getVBScorePercentInfoByBtmemId(btmem_id: $btmem_id) {
      btmem_id
      identification
      image
      name
      score
      score1_per
      score2_per
      score3_per
      score4_per
      score5_per
      score_count
      status
      __typename
    }
  }
`;

/**
 * __useGetVbScorePercentInfoByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetVbScorePercentInfoByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbScorePercentInfoByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbScorePercentInfoByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetVbScorePercentInfoByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbScorePercentInfoByBtmemIdQuery,
    GetVbScorePercentInfoByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbScorePercentInfoByBtmemIdQuery, GetVbScorePercentInfoByBtmemIdQueryVariables>(
    GetVbScorePercentInfoByBtmemIdDocument,
    options
  );
}
export function useGetVbScorePercentInfoByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbScorePercentInfoByBtmemIdQuery,
    GetVbScorePercentInfoByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbScorePercentInfoByBtmemIdQuery, GetVbScorePercentInfoByBtmemIdQueryVariables>(
    GetVbScorePercentInfoByBtmemIdDocument,
    options
  );
}
export type GetVbScorePercentInfoByBtmemIdQueryHookResult = ReturnType<typeof useGetVbScorePercentInfoByBtmemIdQuery>;
export type GetVbScorePercentInfoByBtmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVbScorePercentInfoByBtmemIdLazyQuery
>;
export type GetVbScorePercentInfoByBtmemIdQueryResult = Apollo.QueryResult<
  GetVbScorePercentInfoByBtmemIdQuery,
  GetVbScorePercentInfoByBtmemIdQueryVariables
>;
export const GetVbWithholdSlipListByBtmemIdDocument = gql`
  query GetVBWithholdSlipListByBtmemId($btmem_id: Int!) {
    getVBWithholdSlipListByBtmemId(btmem_id: $btmem_id) {
      btmem_id
      corp_name
      corporate_no
      last_date
      last_day
      year_val
      __typename
    }
  }
`;

/**
 * __useGetVbWithholdSlipListByBtmemIdQuery__
 *
 * To run a query within a React component, call `useGetVbWithholdSlipListByBtmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbWithholdSlipListByBtmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbWithholdSlipListByBtmemIdQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *   },
 * });
 */
export function useGetVbWithholdSlipListByBtmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbWithholdSlipListByBtmemIdQuery,
    GetVbWithholdSlipListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbWithholdSlipListByBtmemIdQuery, GetVbWithholdSlipListByBtmemIdQueryVariables>(
    GetVbWithholdSlipListByBtmemIdDocument,
    options
  );
}
export function useGetVbWithholdSlipListByBtmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbWithholdSlipListByBtmemIdQuery,
    GetVbWithholdSlipListByBtmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbWithholdSlipListByBtmemIdQuery, GetVbWithholdSlipListByBtmemIdQueryVariables>(
    GetVbWithholdSlipListByBtmemIdDocument,
    options
  );
}
export type GetVbWithholdSlipListByBtmemIdQueryHookResult = ReturnType<typeof useGetVbWithholdSlipListByBtmemIdQuery>;
export type GetVbWithholdSlipListByBtmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVbWithholdSlipListByBtmemIdLazyQuery
>;
export type GetVbWithholdSlipListByBtmemIdQueryResult = Apollo.QueryResult<
  GetVbWithholdSlipListByBtmemIdQuery,
  GetVbWithholdSlipListByBtmemIdQueryVariables
>;
export const GetVbWithholdSlipListByBtmemIdCorporateNoDocument = gql`
  query GetVBWithholdSlipListByBtmemIdCorporateNo($btmem_id: Int!, $corporate_no: String!) {
    getVBWithholdSlipListByBtmemIdCorporateNo(btmem_id: $btmem_id, corporate_no: $corporate_no) {
      list {
        btmem_id
        corp_name
        corporate_no
        last_date
        last_day
        year_val
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVbWithholdSlipListByBtmemIdCorporateNoQuery__
 *
 * To run a query within a React component, call `useGetVbWithholdSlipListByBtmemIdCorporateNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbWithholdSlipListByBtmemIdCorporateNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbWithholdSlipListByBtmemIdCorporateNoQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *      corporate_no: // value for 'corporate_no'
 *   },
 * });
 */
export function useGetVbWithholdSlipListByBtmemIdCorporateNoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbWithholdSlipListByBtmemIdCorporateNoQuery,
    GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVbWithholdSlipListByBtmemIdCorporateNoQuery,
    GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables
  >(GetVbWithholdSlipListByBtmemIdCorporateNoDocument, options);
}
export function useGetVbWithholdSlipListByBtmemIdCorporateNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbWithholdSlipListByBtmemIdCorporateNoQuery,
    GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVbWithholdSlipListByBtmemIdCorporateNoQuery,
    GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables
  >(GetVbWithholdSlipListByBtmemIdCorporateNoDocument, options);
}
export type GetVbWithholdSlipListByBtmemIdCorporateNoQueryHookResult = ReturnType<
  typeof useGetVbWithholdSlipListByBtmemIdCorporateNoQuery
>;
export type GetVbWithholdSlipListByBtmemIdCorporateNoLazyQueryHookResult = ReturnType<
  typeof useGetVbWithholdSlipListByBtmemIdCorporateNoLazyQuery
>;
export type GetVbWithholdSlipListByBtmemIdCorporateNoQueryResult = Apollo.QueryResult<
  GetVbWithholdSlipListByBtmemIdCorporateNoQuery,
  GetVbWithholdSlipListByBtmemIdCorporateNoQueryVariables
>;
export const GetVbWithholdingSlipDataByGCcalBIdCNoDocument = gql`
  query GetVBWithholdingSlipDataByGCcalBIdCNo($btmem_id: Int!, $corporate_no: String!, $gregorian_cal: Int!) {
    getVBWithholdingSlipDataByGCcalBIdCNo(
      btmem_id: $btmem_id
      corporate_no: $corporate_no
      gregorian_cal: $gregorian_cal
    ) {
      btmem_address
      btmem_id
      btmem_kana
      btmem_name
      corp_address
      corp_name
      corp_phone
      corporate_no
      gregorian_cal
      japanese_cal
      salary
      tax_amount
      __typename
    }
  }
`;

/**
 * __useGetVbWithholdingSlipDataByGCcalBIdCNoQuery__
 *
 * To run a query within a React component, call `useGetVbWithholdingSlipDataByGCcalBIdCNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbWithholdingSlipDataByGCcalBIdCNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbWithholdingSlipDataByGCcalBIdCNoQuery({
 *   variables: {
 *      btmem_id: // value for 'btmem_id'
 *      corporate_no: // value for 'corporate_no'
 *      gregorian_cal: // value for 'gregorian_cal'
 *   },
 * });
 */
export function useGetVbWithholdingSlipDataByGCcalBIdCNoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVbWithholdingSlipDataByGCcalBIdCNoQuery,
    GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVbWithholdingSlipDataByGCcalBIdCNoQuery,
    GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables
  >(GetVbWithholdingSlipDataByGCcalBIdCNoDocument, options);
}
export function useGetVbWithholdingSlipDataByGCcalBIdCNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVbWithholdingSlipDataByGCcalBIdCNoQuery,
    GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVbWithholdingSlipDataByGCcalBIdCNoQuery,
    GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables
  >(GetVbWithholdingSlipDataByGCcalBIdCNoDocument, options);
}
export type GetVbWithholdingSlipDataByGCcalBIdCNoQueryHookResult = ReturnType<
  typeof useGetVbWithholdingSlipDataByGCcalBIdCNoQuery
>;
export type GetVbWithholdingSlipDataByGCcalBIdCNoLazyQueryHookResult = ReturnType<
  typeof useGetVbWithholdingSlipDataByGCcalBIdCNoLazyQuery
>;
export type GetVbWithholdingSlipDataByGCcalBIdCNoQueryResult = Apollo.QueryResult<
  GetVbWithholdingSlipDataByGCcalBIdCNoQuery,
  GetVbWithholdingSlipDataByGCcalBIdCNoQueryVariables
>;
export const GetVbWorkScoreListDocument = gql`
  query GetVBWorkScoreList {
    getVBWorkScoreList {
      belong
      biz_id
      biz_name
      brand_id
      brand_name
      bt_score
      btmem_name
      hour_wage
      offer_no
      rank_id
      rank_name
      tp_post_day
      tpmem_name
      trans_fee
      work_day
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVbWorkScoreListQuery__
 *
 * To run a query within a React component, call `useGetVbWorkScoreListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVbWorkScoreListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVbWorkScoreListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVbWorkScoreListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVbWorkScoreListQuery, GetVbWorkScoreListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVbWorkScoreListQuery, GetVbWorkScoreListQueryVariables>(
    GetVbWorkScoreListDocument,
    options
  );
}
export function useGetVbWorkScoreListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVbWorkScoreListQuery, GetVbWorkScoreListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVbWorkScoreListQuery, GetVbWorkScoreListQueryVariables>(
    GetVbWorkScoreListDocument,
    options
  );
}
export type GetVbWorkScoreListQueryHookResult = ReturnType<typeof useGetVbWorkScoreListQuery>;
export type GetVbWorkScoreListLazyQueryHookResult = ReturnType<typeof useGetVbWorkScoreListLazyQuery>;
export type GetVbWorkScoreListQueryResult = Apollo.QueryResult<
  GetVbWorkScoreListQuery,
  GetVbWorkScoreListQueryVariables
>;
export const GetVsLendStaffListByBizIdDocument = gql`
  query GetVSLendStaffListByBizId($biz_id: Int!) {
    getVSLendStaffListByBizId(biz_id: $biz_id) {
      biz_id
      biz_name
      bt_rank_id
      educ_default
      educ_fee_max
      educ_fee_min
      educ_range
      rank_name
      __typename
    }
  }
`;

/**
 * __useGetVsLendStaffListByBizIdQuery__
 *
 * To run a query within a React component, call `useGetVsLendStaffListByBizIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVsLendStaffListByBizIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVsLendStaffListByBizIdQuery({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *   },
 * });
 */
export function useGetVsLendStaffListByBizIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVsLendStaffListByBizIdQuery, GetVsLendStaffListByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVsLendStaffListByBizIdQuery, GetVsLendStaffListByBizIdQueryVariables>(
    GetVsLendStaffListByBizIdDocument,
    options
  );
}
export function useGetVsLendStaffListByBizIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVsLendStaffListByBizIdQuery, GetVsLendStaffListByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVsLendStaffListByBizIdQuery, GetVsLendStaffListByBizIdQueryVariables>(
    GetVsLendStaffListByBizIdDocument,
    options
  );
}
export type GetVsLendStaffListByBizIdQueryHookResult = ReturnType<typeof useGetVsLendStaffListByBizIdQuery>;
export type GetVsLendStaffListByBizIdLazyQueryHookResult = ReturnType<typeof useGetVsLendStaffListByBizIdLazyQuery>;
export type GetVsLendStaffListByBizIdQueryResult = Apollo.QueryResult<
  GetVsLendStaffListByBizIdQuery,
  GetVsLendStaffListByBizIdQueryVariables
>;
export const GetVSelBlockListByIdDocument = gql`
  query GetVSelBlockListById($id: Int!) {
    getVSelBlockListById(id: $id) {
      address
      address2
      biz_id
      blocked
      brand_id
      city
      my_id
      name
      state
      tpmem_id
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVSelBlockListByIdQuery__
 *
 * To run a query within a React component, call `useGetVSelBlockListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVSelBlockListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVSelBlockListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVSelBlockListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVSelBlockListByIdQuery, GetVSelBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVSelBlockListByIdQuery, GetVSelBlockListByIdQueryVariables>(
    GetVSelBlockListByIdDocument,
    options
  );
}
export function useGetVSelBlockListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVSelBlockListByIdQuery, GetVSelBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVSelBlockListByIdQuery, GetVSelBlockListByIdQueryVariables>(
    GetVSelBlockListByIdDocument,
    options
  );
}
export type GetVSelBlockListByIdQueryHookResult = ReturnType<typeof useGetVSelBlockListByIdQuery>;
export type GetVSelBlockListByIdLazyQueryHookResult = ReturnType<typeof useGetVSelBlockListByIdLazyQuery>;
export type GetVSelBlockListByIdQueryResult = Apollo.QueryResult<
  GetVSelBlockListByIdQuery,
  GetVSelBlockListByIdQueryVariables
>;
export const GetVSelBlockListByMyIdBizIdBrandIdDocument = gql`
  query GetVSelBlockListByMyIdBizIdBrandId($biz_id: Int!, $brand_id: Int!, $my_id: Int!) {
    getVSelBlockListByMyIdBizIdBrandId(biz_id: $biz_id, brand_id: $brand_id, my_id: $my_id) {
      address
      address2
      biz_id
      blocked
      brand_id
      city
      my_id
      name
      state
      tpmem_id
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVSelBlockListByMyIdBizIdBrandIdQuery__
 *
 * To run a query within a React component, call `useGetVSelBlockListByMyIdBizIdBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVSelBlockListByMyIdBizIdBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVSelBlockListByMyIdBizIdBrandIdQuery({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      brand_id: // value for 'brand_id'
 *      my_id: // value for 'my_id'
 *   },
 * });
 */
export function useGetVSelBlockListByMyIdBizIdBrandIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVSelBlockListByMyIdBizIdBrandIdQuery,
    GetVSelBlockListByMyIdBizIdBrandIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVSelBlockListByMyIdBizIdBrandIdQuery, GetVSelBlockListByMyIdBizIdBrandIdQueryVariables>(
    GetVSelBlockListByMyIdBizIdBrandIdDocument,
    options
  );
}
export function useGetVSelBlockListByMyIdBizIdBrandIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVSelBlockListByMyIdBizIdBrandIdQuery,
    GetVSelBlockListByMyIdBizIdBrandIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVSelBlockListByMyIdBizIdBrandIdQuery, GetVSelBlockListByMyIdBizIdBrandIdQueryVariables>(
    GetVSelBlockListByMyIdBizIdBrandIdDocument,
    options
  );
}
export type GetVSelBlockListByMyIdBizIdBrandIdQueryHookResult = ReturnType<
  typeof useGetVSelBlockListByMyIdBizIdBrandIdQuery
>;
export type GetVSelBlockListByMyIdBizIdBrandIdLazyQueryHookResult = ReturnType<
  typeof useGetVSelBlockListByMyIdBizIdBrandIdLazyQuery
>;
export type GetVSelBlockListByMyIdBizIdBrandIdQueryResult = Apollo.QueryResult<
  GetVSelBlockListByMyIdBizIdBrandIdQuery,
  GetVSelBlockListByMyIdBizIdBrandIdQueryVariables
>;
export const GetVtArbeitEntryListDocument = gql`
  query GetVTArbeitEntryList {
    getVTArbeitEntryList {
      btmem_id
      btmem_number
      entry_id
      entry_no
      image
      kind_name
      name
      offer_id
      rank_name
      score
      status
      total_hire_fee
      deadline_date
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitEntryListQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitEntryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitEntryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitEntryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtArbeitEntryListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtArbeitEntryListQuery, GetVtArbeitEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitEntryListQuery, GetVtArbeitEntryListQueryVariables>(
    GetVtArbeitEntryListDocument,
    options
  );
}
export function useGetVtArbeitEntryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtArbeitEntryListQuery, GetVtArbeitEntryListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitEntryListQuery, GetVtArbeitEntryListQueryVariables>(
    GetVtArbeitEntryListDocument,
    options
  );
}
export type GetVtArbeitEntryListQueryHookResult = ReturnType<typeof useGetVtArbeitEntryListQuery>;
export type GetVtArbeitEntryListLazyQueryHookResult = ReturnType<typeof useGetVtArbeitEntryListLazyQuery>;
export type GetVtArbeitEntryListQueryResult = Apollo.QueryResult<
  GetVtArbeitEntryListQuery,
  GetVtArbeitEntryListQueryVariables
>;
export const GetVtArbeitEntryListWorkingByEntryIdDocument = gql`
  query GetVTArbeitEntryListWorkingByEntryId($entry_id: Int!) {
    getVTArbeitEntryListWorkingByEntryId(entry_id: $entry_id) {
      entry {
        btmem_id
        btmem_number
        entry_id
        entry_no
        image
        kind_name
        name
        offer_id
        rank_name
        score
        status
        total_hire_fee
        deadline_date
        __typename
      }
      working {
        begin_date
        begin_day
        begin_end
        biz_id
        brand_id
        btmem_id
        btmem_name
        btmem_number
        end_date
        entry_id
        entry_number
        fix_checkin
        fix_checkout
        fix_count
        image
        newly
        newly_review
        offer_id
        offer_number
        qr_checkin
        qr_checkout
        status
        time_left
        time_left_minute
        title
        tpmem_id
        tpmem_name
        type
        type_name
        work_id
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitEntryListWorkingByEntryIdQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitEntryListWorkingByEntryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitEntryListWorkingByEntryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitEntryListWorkingByEntryIdQuery({
 *   variables: {
 *      entry_id: // value for 'entry_id'
 *   },
 * });
 */
export function useGetVtArbeitEntryListWorkingByEntryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtArbeitEntryListWorkingByEntryIdQuery,
    GetVtArbeitEntryListWorkingByEntryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitEntryListWorkingByEntryIdQuery, GetVtArbeitEntryListWorkingByEntryIdQueryVariables>(
    GetVtArbeitEntryListWorkingByEntryIdDocument,
    options
  );
}
export function useGetVtArbeitEntryListWorkingByEntryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtArbeitEntryListWorkingByEntryIdQuery,
    GetVtArbeitEntryListWorkingByEntryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVtArbeitEntryListWorkingByEntryIdQuery,
    GetVtArbeitEntryListWorkingByEntryIdQueryVariables
  >(GetVtArbeitEntryListWorkingByEntryIdDocument, options);
}
export type GetVtArbeitEntryListWorkingByEntryIdQueryHookResult = ReturnType<
  typeof useGetVtArbeitEntryListWorkingByEntryIdQuery
>;
export type GetVtArbeitEntryListWorkingByEntryIdLazyQueryHookResult = ReturnType<
  typeof useGetVtArbeitEntryListWorkingByEntryIdLazyQuery
>;
export type GetVtArbeitEntryListWorkingByEntryIdQueryResult = Apollo.QueryResult<
  GetVtArbeitEntryListWorkingByEntryIdQuery,
  GetVtArbeitEntryListWorkingByEntryIdQueryVariables
>;
export const GetVtArbeitOfferEntryListByIdDocument = gql`
  query GetVTArbeitOfferEntryListById($id: Int!) {
    getVTArbeitOfferEntryListById(id: $id) {
      entry {
        btmem_id
        btmem_number
        entry_id
        entry_no
        image
        kind_name
        name
        offer_id
        rank_name
        score
        status
        total_hire_fee
        deadline_date
        __typename
      }
      offer {
        auto_decide
        begin_date
        begin_day
        end_date
        entry_count
        group_count
        hour_wage
        hour_wage_disp
        id
        main_count
        name
        newly
        normal_count
        offer_number
        post_begin
        post_end
        status
        sub_count
        title
        tpmem_id
        trans_fee
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferEntryListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferEntryListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferEntryListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferEntryListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtArbeitOfferEntryListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtArbeitOfferEntryListByIdQuery, GetVtArbeitOfferEntryListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferEntryListByIdQuery, GetVtArbeitOfferEntryListByIdQueryVariables>(
    GetVtArbeitOfferEntryListByIdDocument,
    options
  );
}
export function useGetVtArbeitOfferEntryListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtArbeitOfferEntryListByIdQuery,
    GetVtArbeitOfferEntryListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferEntryListByIdQuery, GetVtArbeitOfferEntryListByIdQueryVariables>(
    GetVtArbeitOfferEntryListByIdDocument,
    options
  );
}
export type GetVtArbeitOfferEntryListByIdQueryHookResult = ReturnType<typeof useGetVtArbeitOfferEntryListByIdQuery>;
export type GetVtArbeitOfferEntryListByIdLazyQueryHookResult = ReturnType<
  typeof useGetVtArbeitOfferEntryListByIdLazyQuery
>;
export type GetVtArbeitOfferEntryListByIdQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferEntryListByIdQuery,
  GetVtArbeitOfferEntryListByIdQueryVariables
>;
export const GetVtArbeitOfferInfoDocument = gql`
  query GetVTArbeitOfferInfo {
    getVTArbeitOfferInfo {
      access
      attached_name
      attached_file
      auto_decide
      begin_date
      begin_end
      belongings
      break_time
      bt_rank_id
      cancel_disp
      cancel_per
      caution
      chief
      conditions
      description
      end_date
      hire_fee_disp
      hire_fee_total
      hour_wage
      hour_wage_disp
      id
      image1
      image2
      image3
      image4
      image5
      imminent_disp
      imminent_per
      name
      offer_number
      post_begin
      post_begin_date
      post_end
      post_end_date
      rank_name
      score
      score_disp
      title
      tpmem_id
      trans_fee
      trans_fee_val
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferInfoQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtArbeitOfferInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtArbeitOfferInfoQuery, GetVtArbeitOfferInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferInfoQuery, GetVtArbeitOfferInfoQueryVariables>(
    GetVtArbeitOfferInfoDocument,
    options
  );
}
export function useGetVtArbeitOfferInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtArbeitOfferInfoQuery, GetVtArbeitOfferInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferInfoQuery, GetVtArbeitOfferInfoQueryVariables>(
    GetVtArbeitOfferInfoDocument,
    options
  );
}
export type GetVtArbeitOfferInfoQueryHookResult = ReturnType<typeof useGetVtArbeitOfferInfoQuery>;
export type GetVtArbeitOfferInfoLazyQueryHookResult = ReturnType<typeof useGetVtArbeitOfferInfoLazyQuery>;
export type GetVtArbeitOfferInfoQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferInfoQuery,
  GetVtArbeitOfferInfoQueryVariables
>;
export const GetVtArbeitOfferInfoByIdDocument = gql`
  query GetVTArbeitOfferInfoById($id: Int!) {
    getVTArbeitOfferInfoById(id: $id) {
      info {
        access
        attached_name
        attached_file
        auto_decide
        begin_date
        begin_end
        belongings
        break_time
        bt_rank_id
        cancel_disp
        cancel_per
        caution
        chief
        conditions
        description
        end_date
        hire_fee_disp
        hire_fee_total
        hour_wage
        hour_wage_disp
        id
        image1
        image2
        image3
        image4
        image5
        imminent_disp
        imminent_per
        name
        offer_number
        post_begin
        post_begin_date
        post_end
        post_end_date
        rank_name
        score
        score_disp
        title
        tpmem_id
        trans_fee
        trans_fee_val
        __typename
      }
      list {
        auto_decide
        begin_date
        begin_day
        end_date
        entry_count
        group_count
        hour_wage
        hour_wage_disp
        id
        main_count
        name
        newly
        normal_count
        offer_number
        post_begin
        post_end
        status
        sub_count
        title
        tpmem_id
        trans_fee
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtArbeitOfferInfoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtArbeitOfferInfoByIdQuery, GetVtArbeitOfferInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferInfoByIdQuery, GetVtArbeitOfferInfoByIdQueryVariables>(
    GetVtArbeitOfferInfoByIdDocument,
    options
  );
}
export function useGetVtArbeitOfferInfoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtArbeitOfferInfoByIdQuery, GetVtArbeitOfferInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferInfoByIdQuery, GetVtArbeitOfferInfoByIdQueryVariables>(
    GetVtArbeitOfferInfoByIdDocument,
    options
  );
}
export type GetVtArbeitOfferInfoByIdQueryHookResult = ReturnType<typeof useGetVtArbeitOfferInfoByIdQuery>;
export type GetVtArbeitOfferInfoByIdLazyQueryHookResult = ReturnType<typeof useGetVtArbeitOfferInfoByIdLazyQuery>;
export type GetVtArbeitOfferInfoByIdQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferInfoByIdQuery,
  GetVtArbeitOfferInfoByIdQueryVariables
>;
export const GetVtArbeitOfferInfoWorkingByIdDocument = gql`
  query GetVTArbeitOfferInfoWorkingById($id: Int!) {
    getVTArbeitOfferInfoWorkingById(id: $id) {
      info {
        access
        attached_name
        attached_file
        auto_decide
        begin_date
        begin_end
        belongings
        break_time
        bt_rank_id
        cancel_disp
        cancel_per
        caution
        chief
        conditions
        description
        end_date
        hire_fee_disp
        hire_fee_total
        hour_wage
        hour_wage_disp
        id
        image1
        image2
        image3
        image4
        image5
        imminent_disp
        imminent_per
        name
        offer_number
        post_begin
        post_begin_date
        post_end
        post_end_date
        rank_name
        score
        score_disp
        title
        tpmem_id
        trans_fee
        trans_fee_val
        __typename
      }
      working {
        begin_date
        begin_day
        begin_end
        biz_id
        brand_id
        btmem_id
        btmem_name
        btmem_number
        end_date
        entry_id
        entry_number
        fix_checkin
        fix_checkout
        fix_count
        image
        newly
        newly_review
        offer_id
        offer_number
        qr_checkin
        qr_checkout
        status
        time_left
        time_left_minute
        title
        tpmem_id
        tpmem_name
        type
        type_name
        work_id
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferInfoWorkingByIdQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferInfoWorkingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferInfoWorkingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferInfoWorkingByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtArbeitOfferInfoWorkingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtArbeitOfferInfoWorkingByIdQuery,
    GetVtArbeitOfferInfoWorkingByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferInfoWorkingByIdQuery, GetVtArbeitOfferInfoWorkingByIdQueryVariables>(
    GetVtArbeitOfferInfoWorkingByIdDocument,
    options
  );
}
export function useGetVtArbeitOfferInfoWorkingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtArbeitOfferInfoWorkingByIdQuery,
    GetVtArbeitOfferInfoWorkingByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferInfoWorkingByIdQuery, GetVtArbeitOfferInfoWorkingByIdQueryVariables>(
    GetVtArbeitOfferInfoWorkingByIdDocument,
    options
  );
}
export type GetVtArbeitOfferInfoWorkingByIdQueryHookResult = ReturnType<typeof useGetVtArbeitOfferInfoWorkingByIdQuery>;
export type GetVtArbeitOfferInfoWorkingByIdLazyQueryHookResult = ReturnType<
  typeof useGetVtArbeitOfferInfoWorkingByIdLazyQuery
>;
export type GetVtArbeitOfferInfoWorkingByIdQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferInfoWorkingByIdQuery,
  GetVtArbeitOfferInfoWorkingByIdQueryVariables
>;
export const GetVtArbeitOfferListDocument = gql`
  query GetVTArbeitOfferList {
    getVTArbeitOfferList {
      auto_decide
      begin_date
      begin_day
      end_date
      entry_count
      group_count
      hour_wage
      hour_wage_disp
      id
      main_count
      name
      newly
      normal_count
      offer_number
      post_begin
      post_end
      status
      sub_count
      title
      tpmem_id
      trans_fee
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferListQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtArbeitOfferListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtArbeitOfferListQuery, GetVtArbeitOfferListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferListQuery, GetVtArbeitOfferListQueryVariables>(
    GetVtArbeitOfferListDocument,
    options
  );
}
export function useGetVtArbeitOfferListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtArbeitOfferListQuery, GetVtArbeitOfferListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferListQuery, GetVtArbeitOfferListQueryVariables>(
    GetVtArbeitOfferListDocument,
    options
  );
}
export type GetVtArbeitOfferListQueryHookResult = ReturnType<typeof useGetVtArbeitOfferListQuery>;
export type GetVtArbeitOfferListLazyQueryHookResult = ReturnType<typeof useGetVtArbeitOfferListLazyQuery>;
export type GetVtArbeitOfferListQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferListQuery,
  GetVtArbeitOfferListQueryVariables
>;
export const GetVtArbeitOfferLogging1ListByIdDocument = gql`
  query GetVTArbeitOfferLogging1ListById($id: Int!) {
    getVTArbeitOfferLogging1ListById(id: $id) {
      logging {
        id
        kind
        log_date
        matter
        ref_no
        tpmem_id
        __typename
      }
      offer {
        auto_decide
        begin_date
        begin_day
        end_date
        entry_count
        group_count
        hour_wage
        hour_wage_disp
        id
        main_count
        name
        newly
        normal_count
        offer_number
        post_begin
        post_end
        status
        sub_count
        title
        tpmem_id
        trans_fee
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtArbeitOfferLogging1ListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtArbeitOfferLogging1ListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtArbeitOfferLogging1ListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtArbeitOfferLogging1ListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtArbeitOfferLogging1ListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtArbeitOfferLogging1ListByIdQuery,
    GetVtArbeitOfferLogging1ListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtArbeitOfferLogging1ListByIdQuery, GetVtArbeitOfferLogging1ListByIdQueryVariables>(
    GetVtArbeitOfferLogging1ListByIdDocument,
    options
  );
}
export function useGetVtArbeitOfferLogging1ListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtArbeitOfferLogging1ListByIdQuery,
    GetVtArbeitOfferLogging1ListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtArbeitOfferLogging1ListByIdQuery, GetVtArbeitOfferLogging1ListByIdQueryVariables>(
    GetVtArbeitOfferLogging1ListByIdDocument,
    options
  );
}
export type GetVtArbeitOfferLogging1ListByIdQueryHookResult = ReturnType<
  typeof useGetVtArbeitOfferLogging1ListByIdQuery
>;
export type GetVtArbeitOfferLogging1ListByIdLazyQueryHookResult = ReturnType<
  typeof useGetVtArbeitOfferLogging1ListByIdLazyQuery
>;
export type GetVtArbeitOfferLogging1ListByIdQueryResult = Apollo.QueryResult<
  GetVtArbeitOfferLogging1ListByIdQuery,
  GetVtArbeitOfferLogging1ListByIdQueryVariables
>;
export const GetVtBalanceListDocument = gql`
  query GetVTBalanceList {
    getVTBalanceList {
      balance {
        area
        biz_id
        biz_name
        brand_id
        brand_name
        image
        mco_name
        name
        price
        price_disp
        state
        status
        tpmem_id
        __typename
      }
      newly {
        mn_all
        trans_status
        __typename
      }
      wallet {
        price
        status
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtBalanceListQuery__
 *
 * To run a query within a React component, call `useGetVtBalanceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBalanceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBalanceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtBalanceListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtBalanceListQuery, GetVtBalanceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBalanceListQuery, GetVtBalanceListQueryVariables>(GetVtBalanceListDocument, options);
}
export function useGetVtBalanceListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtBalanceListQuery, GetVtBalanceListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBalanceListQuery, GetVtBalanceListQueryVariables>(GetVtBalanceListDocument, options);
}
export type GetVtBalanceListQueryHookResult = ReturnType<typeof useGetVtBalanceListQuery>;
export type GetVtBalanceListLazyQueryHookResult = ReturnType<typeof useGetVtBalanceListLazyQuery>;
export type GetVtBalanceListQueryResult = Apollo.QueryResult<GetVtBalanceListQuery, GetVtBalanceListQueryVariables>;
export const GetVtBelongListDocument = gql`
  query GetVTBelongList {
    getVTBelongList {
      address
      address2
      age
      attribute
      begin_date
      birthday
      biz_id
      btmem_number
      cancel_per
      city
      email
      gender
      id
      identification
      image
      kind
      name
      phone
      rank_name
      score
      state
      status
      sub_name
      tpmem_id
      tpmem_name
      uniform_size
      worked_count
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVtBelongListQuery__
 *
 * To run a query within a React component, call `useGetVtBelongListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBelongListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBelongListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtBelongListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtBelongListQuery, GetVtBelongListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBelongListQuery, GetVtBelongListQueryVariables>(GetVtBelongListDocument, options);
}
export function useGetVtBelongListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtBelongListQuery, GetVtBelongListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBelongListQuery, GetVtBelongListQueryVariables>(GetVtBelongListDocument, options);
}
export type GetVtBelongListQueryHookResult = ReturnType<typeof useGetVtBelongListQuery>;
export type GetVtBelongListLazyQueryHookResult = ReturnType<typeof useGetVtBelongListLazyQuery>;
export type GetVtBelongListQueryResult = Apollo.QueryResult<GetVtBelongListQuery, GetVtBelongListQueryVariables>;
export const GetVtBelongListMemberByTpmemIdIdDocument = gql`
  query GetVTBelongListMemberByTpmemIdId($id: Int!, $tpmem_id: Int!) {
    getVTBelongListMemberByTpmemIdId(id: $id, tpmem_id: $tpmem_id) {
      belong {
        address
        address2
        age
        attribute
        begin_date
        birthday
        biz_id
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        kind
        name
        phone
        rank_name
        score
        state
        status
        sub_name
        tpmem_id
        tpmem_name
        uniform_size
        worked_count
        zip_code
        __typename
      }
      member {
        account_name
        account_no
        account_type
        address
        address2
        age
        attr_id
        attribute
        badge_name
        bank
        belong_biz_count
        birthday
        birthday2
        branch_name
        btmem_number
        cancel_count
        cancel_per
        city
        created_date
        email
        gender
        gender_value
        general_licenses
        id
        identification
        image
        imminent_count
        imminent_per
        kana
        late_count
        late_per
        lc_biz_count
        lc_biz_names
        license_list
        name
        nationality
        pause_end_day
        period_date
        phone
        rank_biz_count
        rank_biz_names
        rank_names
        reg_biz_list
        residence_name
        score
        skip_count
        state
        state_count
        status
        sub_names
        tp_biz_names
        tpmem_names
        uniform_id
        uniform_size
        worked_count
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtBelongListMemberByTpmemIdIdQuery__
 *
 * To run a query within a React component, call `useGetVtBelongListMemberByTpmemIdIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBelongListMemberByTpmemIdIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBelongListMemberByTpmemIdIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      tpmem_id: // value for 'tpmem_id'
 *   },
 * });
 */
export function useGetVtBelongListMemberByTpmemIdIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtBelongListMemberByTpmemIdIdQuery,
    GetVtBelongListMemberByTpmemIdIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBelongListMemberByTpmemIdIdQuery, GetVtBelongListMemberByTpmemIdIdQueryVariables>(
    GetVtBelongListMemberByTpmemIdIdDocument,
    options
  );
}
export function useGetVtBelongListMemberByTpmemIdIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtBelongListMemberByTpmemIdIdQuery,
    GetVtBelongListMemberByTpmemIdIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBelongListMemberByTpmemIdIdQuery, GetVtBelongListMemberByTpmemIdIdQueryVariables>(
    GetVtBelongListMemberByTpmemIdIdDocument,
    options
  );
}
export type GetVtBelongListMemberByTpmemIdIdQueryHookResult = ReturnType<
  typeof useGetVtBelongListMemberByTpmemIdIdQuery
>;
export type GetVtBelongListMemberByTpmemIdIdLazyQueryHookResult = ReturnType<
  typeof useGetVtBelongListMemberByTpmemIdIdLazyQuery
>;
export type GetVtBelongListMemberByTpmemIdIdQueryResult = Apollo.QueryResult<
  GetVtBelongListMemberByTpmemIdIdQuery,
  GetVtBelongListMemberByTpmemIdIdQueryVariables
>;
export const GetVtBillCsvListNewlyBillStoreK1Document = gql`
  query GetVTBillCsvListNewlyBillStoreK1 {
    getVTBillCsvListNewlyBillStoreK1 {
      csvlist {
        csv_no
        csv_url
        dl_count
        dl_date
        kind
        name
        output_date
        output_number
        pay_date
        unopened
        __typename
      }
      newly {
        zp_pay
        zw_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtBillCsvListNewlyBillStoreK1Query__
 *
 * To run a query within a React component, call `useGetVtBillCsvListNewlyBillStoreK1Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBillCsvListNewlyBillStoreK1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBillCsvListNewlyBillStoreK1Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVtBillCsvListNewlyBillStoreK1Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetVtBillCsvListNewlyBillStoreK1Query,
    GetVtBillCsvListNewlyBillStoreK1QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBillCsvListNewlyBillStoreK1Query, GetVtBillCsvListNewlyBillStoreK1QueryVariables>(
    GetVtBillCsvListNewlyBillStoreK1Document,
    options
  );
}
export function useGetVtBillCsvListNewlyBillStoreK1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtBillCsvListNewlyBillStoreK1Query,
    GetVtBillCsvListNewlyBillStoreK1QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBillCsvListNewlyBillStoreK1Query, GetVtBillCsvListNewlyBillStoreK1QueryVariables>(
    GetVtBillCsvListNewlyBillStoreK1Document,
    options
  );
}
export type GetVtBillCsvListNewlyBillStoreK1QueryHookResult = ReturnType<
  typeof useGetVtBillCsvListNewlyBillStoreK1Query
>;
export type GetVtBillCsvListNewlyBillStoreK1LazyQueryHookResult = ReturnType<
  typeof useGetVtBillCsvListNewlyBillStoreK1LazyQuery
>;
export type GetVtBillCsvListNewlyBillStoreK1QueryResult = Apollo.QueryResult<
  GetVtBillCsvListNewlyBillStoreK1Query,
  GetVtBillCsvListNewlyBillStoreK1QueryVariables
>;
export const GetVtBillCsvListNewlyBillStoreK2Document = gql`
  query GetVTBillCsvListNewlyBillStoreK2 {
    getVTBillCsvListNewlyBillStoreK2 {
      csvlist {
        csv_no
        csv_url
        dl_count
        dl_date
        kind
        name
        output_date
        output_number
        pay_date
        unopened
        __typename
      }
      newly {
        zp_pay
        zw_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtBillCsvListNewlyBillStoreK2Query__
 *
 * To run a query within a React component, call `useGetVtBillCsvListNewlyBillStoreK2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBillCsvListNewlyBillStoreK2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBillCsvListNewlyBillStoreK2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVtBillCsvListNewlyBillStoreK2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetVtBillCsvListNewlyBillStoreK2Query,
    GetVtBillCsvListNewlyBillStoreK2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBillCsvListNewlyBillStoreK2Query, GetVtBillCsvListNewlyBillStoreK2QueryVariables>(
    GetVtBillCsvListNewlyBillStoreK2Document,
    options
  );
}
export function useGetVtBillCsvListNewlyBillStoreK2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtBillCsvListNewlyBillStoreK2Query,
    GetVtBillCsvListNewlyBillStoreK2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBillCsvListNewlyBillStoreK2Query, GetVtBillCsvListNewlyBillStoreK2QueryVariables>(
    GetVtBillCsvListNewlyBillStoreK2Document,
    options
  );
}
export type GetVtBillCsvListNewlyBillStoreK2QueryHookResult = ReturnType<
  typeof useGetVtBillCsvListNewlyBillStoreK2Query
>;
export type GetVtBillCsvListNewlyBillStoreK2LazyQueryHookResult = ReturnType<
  typeof useGetVtBillCsvListNewlyBillStoreK2LazyQuery
>;
export type GetVtBillCsvListNewlyBillStoreK2QueryResult = Apollo.QueryResult<
  GetVtBillCsvListNewlyBillStoreK2Query,
  GetVtBillCsvListNewlyBillStoreK2QueryVariables
>;
export const GetVtBlockListDocument = gql`
  query GetVTBlockList {
    getVTBlockList {
      block_id
      block_name
      image
      name
      reason
      reg_date
      status
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVtBlockListQuery__
 *
 * To run a query within a React component, call `useGetVtBlockListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBlockListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBlockListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtBlockListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtBlockListQuery, GetVtBlockListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBlockListQuery, GetVtBlockListQueryVariables>(GetVtBlockListDocument, options);
}
export function useGetVtBlockListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtBlockListQuery, GetVtBlockListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBlockListQuery, GetVtBlockListQueryVariables>(GetVtBlockListDocument, options);
}
export type GetVtBlockListQueryHookResult = ReturnType<typeof useGetVtBlockListQuery>;
export type GetVtBlockListLazyQueryHookResult = ReturnType<typeof useGetVtBlockListLazyQuery>;
export type GetVtBlockListQueryResult = Apollo.QueryResult<GetVtBlockListQuery, GetVtBlockListQueryVariables>;
export const GetVtBlockListByIdDocument = gql`
  query GetVTBlockListById($id: Int!) {
    getVTBlockListById(id: $id) {
      block_id
      block_name
      image
      name
      reason
      reg_date
      status
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVtBlockListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtBlockListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBlockListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBlockListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtBlockListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtBlockListByIdQuery, GetVtBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtBlockListByIdQuery, GetVtBlockListByIdQueryVariables>(
    GetVtBlockListByIdDocument,
    options
  );
}
export function useGetVtBlockListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtBlockListByIdQuery, GetVtBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtBlockListByIdQuery, GetVtBlockListByIdQueryVariables>(
    GetVtBlockListByIdDocument,
    options
  );
}
export type GetVtBlockListByIdQueryHookResult = ReturnType<typeof useGetVtBlockListByIdQuery>;
export type GetVtBlockListByIdLazyQueryHookResult = ReturnType<typeof useGetVtBlockListByIdLazyQuery>;
export type GetVtBlockListByIdQueryResult = Apollo.QueryResult<
  GetVtBlockListByIdQuery,
  GetVtBlockListByIdQueryVariables
>;
export const GetVtBrandStateInfoCountByStateIdBrandIdDocument = gql`
  query GetVTBrandStateInfoCountByStateIdBrandId($brand_ids: String!, $state_ids: String!) {
    getVTBrandStateInfoCountByStateIdBrandId(brand_ids: $brand_ids, state_ids: $state_ids) {
      id_count
      __typename
    }
  }
`;

/**
 * __useGetVtBrandStateInfoCountByStateIdBrandIdQuery__
 *
 * To run a query within a React component, call `useGetVtBrandStateInfoCountByStateIdBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtBrandStateInfoCountByStateIdBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtBrandStateInfoCountByStateIdBrandIdQuery({
 *   variables: {
 *      brand_ids: // value for 'brand_ids'
 *      state_ids: // value for 'state_ids'
 *   },
 * });
 */
export function useGetVtBrandStateInfoCountByStateIdBrandIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtBrandStateInfoCountByStateIdBrandIdQuery,
    GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVtBrandStateInfoCountByStateIdBrandIdQuery,
    GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables
  >(GetVtBrandStateInfoCountByStateIdBrandIdDocument, options);
}
export function useGetVtBrandStateInfoCountByStateIdBrandIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtBrandStateInfoCountByStateIdBrandIdQuery,
    GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVtBrandStateInfoCountByStateIdBrandIdQuery,
    GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables
  >(GetVtBrandStateInfoCountByStateIdBrandIdDocument, options);
}
export type GetVtBrandStateInfoCountByStateIdBrandIdQueryHookResult = ReturnType<
  typeof useGetVtBrandStateInfoCountByStateIdBrandIdQuery
>;
export type GetVtBrandStateInfoCountByStateIdBrandIdLazyQueryHookResult = ReturnType<
  typeof useGetVtBrandStateInfoCountByStateIdBrandIdLazyQuery
>;
export type GetVtBrandStateInfoCountByStateIdBrandIdQueryResult = Apollo.QueryResult<
  GetVtBrandStateInfoCountByStateIdBrandIdQuery,
  GetVtBrandStateInfoCountByStateIdBrandIdQueryVariables
>;
export const GetVtCpFreeListDocument = gql`
  query GetVTCpFreeList {
    getVTCpFreeList {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      end_date
      free_months
      free_months_disp
      id
      state_ids
      state_names
      status
      title
      __typename
    }
  }
`;

/**
 * __useGetVtCpFreeListQuery__
 *
 * To run a query within a React component, call `useGetVtCpFreeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtCpFreeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtCpFreeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtCpFreeListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtCpFreeListQuery, GetVtCpFreeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtCpFreeListQuery, GetVtCpFreeListQueryVariables>(GetVtCpFreeListDocument, options);
}
export function useGetVtCpFreeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtCpFreeListQuery, GetVtCpFreeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtCpFreeListQuery, GetVtCpFreeListQueryVariables>(GetVtCpFreeListDocument, options);
}
export type GetVtCpFreeListQueryHookResult = ReturnType<typeof useGetVtCpFreeListQuery>;
export type GetVtCpFreeListLazyQueryHookResult = ReturnType<typeof useGetVtCpFreeListLazyQuery>;
export type GetVtCpFreeListQueryResult = Apollo.QueryResult<GetVtCpFreeListQuery, GetVtCpFreeListQueryVariables>;
export const GetVtCpTicketListDocument = gql`
  query GetVTCpTicketList {
    getVTCpTicketList {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      end_date
      give_num
      id
      kind
      state_ids
      state_names
      status
      ticket_num
      ticket_num_disp
      title
      valid_days
      valid_days_disp
      __typename
    }
  }
`;

/**
 * __useGetVtCpTicketListQuery__
 *
 * To run a query within a React component, call `useGetVtCpTicketListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtCpTicketListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtCpTicketListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtCpTicketListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtCpTicketListQuery, GetVtCpTicketListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtCpTicketListQuery, GetVtCpTicketListQueryVariables>(GetVtCpTicketListDocument, options);
}
export function useGetVtCpTicketListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtCpTicketListQuery, GetVtCpTicketListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtCpTicketListQuery, GetVtCpTicketListQueryVariables>(
    GetVtCpTicketListDocument,
    options
  );
}
export type GetVtCpTicketListQueryHookResult = ReturnType<typeof useGetVtCpTicketListQuery>;
export type GetVtCpTicketListLazyQueryHookResult = ReturnType<typeof useGetVtCpTicketListLazyQuery>;
export type GetVtCpTicketListQueryResult = Apollo.QueryResult<GetVtCpTicketListQuery, GetVtCpTicketListQueryVariables>;
export const GetVtCpTicketListByKind2Document = gql`
  query GetVTCpTicketListByKind2 {
    getVTCpTicketListByKind2 {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      end_date
      give_num
      id
      kind
      state_ids
      state_names
      status
      ticket_num
      ticket_num_disp
      title
      valid_days
      valid_days_disp
      __typename
    }
  }
`;

/**
 * __useGetVtCpTicketListByKind2Query__
 *
 * To run a query within a React component, call `useGetVtCpTicketListByKind2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVtCpTicketListByKind2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtCpTicketListByKind2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVtCpTicketListByKind2Query(
  baseOptions?: Apollo.QueryHookOptions<GetVtCpTicketListByKind2Query, GetVtCpTicketListByKind2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtCpTicketListByKind2Query, GetVtCpTicketListByKind2QueryVariables>(
    GetVtCpTicketListByKind2Document,
    options
  );
}
export function useGetVtCpTicketListByKind2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtCpTicketListByKind2Query, GetVtCpTicketListByKind2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtCpTicketListByKind2Query, GetVtCpTicketListByKind2QueryVariables>(
    GetVtCpTicketListByKind2Document,
    options
  );
}
export type GetVtCpTicketListByKind2QueryHookResult = ReturnType<typeof useGetVtCpTicketListByKind2Query>;
export type GetVtCpTicketListByKind2LazyQueryHookResult = ReturnType<typeof useGetVtCpTicketListByKind2LazyQuery>;
export type GetVtCpTicketListByKind2QueryResult = Apollo.QueryResult<
  GetVtCpTicketListByKind2Query,
  GetVtCpTicketListByKind2QueryVariables
>;
export const GetVtCpTicketListByKind3Document = gql`
  query GetVTCpTicketListByKind3 {
    getVTCpTicketListByKind3 {
      begin_date
      begin_end
      biz_count
      biz_ids
      biz_names
      brand_ids
      brand_names
      created_date
      end_date
      give_num
      id
      kind
      state_ids
      state_names
      status
      ticket_num
      ticket_num_disp
      title
      valid_days
      valid_days_disp
      __typename
    }
  }
`;

/**
 * __useGetVtCpTicketListByKind3Query__
 *
 * To run a query within a React component, call `useGetVtCpTicketListByKind3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVtCpTicketListByKind3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtCpTicketListByKind3Query({
 *   variables: {
 *   },
 * });
 */
export function useGetVtCpTicketListByKind3Query(
  baseOptions?: Apollo.QueryHookOptions<GetVtCpTicketListByKind3Query, GetVtCpTicketListByKind3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtCpTicketListByKind3Query, GetVtCpTicketListByKind3QueryVariables>(
    GetVtCpTicketListByKind3Document,
    options
  );
}
export function useGetVtCpTicketListByKind3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtCpTicketListByKind3Query, GetVtCpTicketListByKind3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtCpTicketListByKind3Query, GetVtCpTicketListByKind3QueryVariables>(
    GetVtCpTicketListByKind3Document,
    options
  );
}
export type GetVtCpTicketListByKind3QueryHookResult = ReturnType<typeof useGetVtCpTicketListByKind3Query>;
export type GetVtCpTicketListByKind3LazyQueryHookResult = ReturnType<typeof useGetVtCpTicketListByKind3LazyQuery>;
export type GetVtCpTicketListByKind3QueryResult = Apollo.QueryResult<
  GetVtCpTicketListByKind3Query,
  GetVtCpTicketListByKind3QueryVariables
>;
export const GetVtGroupListDocument = gql`
  query GetVTGroupList {
    getVTGroupList {
      accept_date
      group_id
      image
      my_id
      name
      status
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVtGroupListQuery__
 *
 * To run a query within a React component, call `useGetVtGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtGroupListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtGroupListQuery, GetVtGroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtGroupListQuery, GetVtGroupListQueryVariables>(GetVtGroupListDocument, options);
}
export function useGetVtGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtGroupListQuery, GetVtGroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtGroupListQuery, GetVtGroupListQueryVariables>(GetVtGroupListDocument, options);
}
export type GetVtGroupListQueryHookResult = ReturnType<typeof useGetVtGroupListQuery>;
export type GetVtGroupListLazyQueryHookResult = ReturnType<typeof useGetVtGroupListLazyQuery>;
export type GetVtGroupListQueryResult = Apollo.QueryResult<GetVtGroupListQuery, GetVtGroupListQueryVariables>;
export const GetVtGroupListByIdDocument = gql`
  query GetVTGroupListById($id: Int!) {
    getVTGroupListById(id: $id) {
      accept_date
      group_id
      image
      my_id
      name
      status
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVtGroupListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtGroupListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtGroupListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtGroupListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtGroupListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtGroupListByIdQuery, GetVtGroupListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtGroupListByIdQuery, GetVtGroupListByIdQueryVariables>(
    GetVtGroupListByIdDocument,
    options
  );
}
export function useGetVtGroupListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtGroupListByIdQuery, GetVtGroupListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtGroupListByIdQuery, GetVtGroupListByIdQueryVariables>(
    GetVtGroupListByIdDocument,
    options
  );
}
export type GetVtGroupListByIdQueryHookResult = ReturnType<typeof useGetVtGroupListByIdQuery>;
export type GetVtGroupListByIdLazyQueryHookResult = ReturnType<typeof useGetVtGroupListByIdLazyQuery>;
export type GetVtGroupListByIdQueryResult = Apollo.QueryResult<
  GetVtGroupListByIdQuery,
  GetVtGroupListByIdQueryVariables
>;
export const GetVtInvitedGroupInfoByTpmemIdDocument = gql`
  query GetVTInvitedGroupInfoByTpmemId($tpmem_id: Int!) {
    getVTInvitedGroupInfoByTpmemId(tpmem_id: $tpmem_id) {
      address
      address2
      city
      corporate_no
      id
      image
      invite_id
      mco_address
      mco_address2
      mco_city
      mco_m_kana
      mco_m_name
      mco_name
      mco_phone
      mco_state
      mco_t_kana
      mco_t_name
      mco_t_phone
      mco_zip_code
      mgr_name
      name
      phone
      state
      tpmem_id
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVtInvitedGroupInfoByTpmemIdQuery__
 *
 * To run a query within a React component, call `useGetVtInvitedGroupInfoByTpmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtInvitedGroupInfoByTpmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtInvitedGroupInfoByTpmemIdQuery({
 *   variables: {
 *      tpmem_id: // value for 'tpmem_id'
 *   },
 * });
 */
export function useGetVtInvitedGroupInfoByTpmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtInvitedGroupInfoByTpmemIdQuery,
    GetVtInvitedGroupInfoByTpmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtInvitedGroupInfoByTpmemIdQuery, GetVtInvitedGroupInfoByTpmemIdQueryVariables>(
    GetVtInvitedGroupInfoByTpmemIdDocument,
    options
  );
}
export function useGetVtInvitedGroupInfoByTpmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtInvitedGroupInfoByTpmemIdQuery,
    GetVtInvitedGroupInfoByTpmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtInvitedGroupInfoByTpmemIdQuery, GetVtInvitedGroupInfoByTpmemIdQueryVariables>(
    GetVtInvitedGroupInfoByTpmemIdDocument,
    options
  );
}
export type GetVtInvitedGroupInfoByTpmemIdQueryHookResult = ReturnType<typeof useGetVtInvitedGroupInfoByTpmemIdQuery>;
export type GetVtInvitedGroupInfoByTpmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVtInvitedGroupInfoByTpmemIdLazyQuery
>;
export type GetVtInvitedGroupInfoByTpmemIdQueryResult = Apollo.QueryResult<
  GetVtInvitedGroupInfoByTpmemIdQuery,
  GetVtInvitedGroupInfoByTpmemIdQueryVariables
>;
export const GetVtmPayCsvListNewlyPayRefundDocument = gql`
  query GetVTMPayCsvListNewlyPayRefund {
    getVTMPayCsvListNewlyPayRefund {
      csvlist {
        csv_no
        csv_url
        dl_count
        dl_date
        name
        output_date
        output_number
        pay_date
        unopened
        __typename
      }
      newly {
        re_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtmPayCsvListNewlyPayRefundQuery__
 *
 * To run a query within a React component, call `useGetVtmPayCsvListNewlyPayRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtmPayCsvListNewlyPayRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtmPayCsvListNewlyPayRefundQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtmPayCsvListNewlyPayRefundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVtmPayCsvListNewlyPayRefundQuery,
    GetVtmPayCsvListNewlyPayRefundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtmPayCsvListNewlyPayRefundQuery, GetVtmPayCsvListNewlyPayRefundQueryVariables>(
    GetVtmPayCsvListNewlyPayRefundDocument,
    options
  );
}
export function useGetVtmPayCsvListNewlyPayRefundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtmPayCsvListNewlyPayRefundQuery,
    GetVtmPayCsvListNewlyPayRefundQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtmPayCsvListNewlyPayRefundQuery, GetVtmPayCsvListNewlyPayRefundQueryVariables>(
    GetVtmPayCsvListNewlyPayRefundDocument,
    options
  );
}
export type GetVtmPayCsvListNewlyPayRefundQueryHookResult = ReturnType<typeof useGetVtmPayCsvListNewlyPayRefundQuery>;
export type GetVtmPayCsvListNewlyPayRefundLazyQueryHookResult = ReturnType<
  typeof useGetVtmPayCsvListNewlyPayRefundLazyQuery
>;
export type GetVtmPayCsvListNewlyPayRefundQueryResult = Apollo.QueryResult<
  GetVtmPayCsvListNewlyPayRefundQuery,
  GetVtmPayCsvListNewlyPayRefundQueryVariables
>;
export const GetVtMemberBaseDocument = gql`
  query GetVTMemberBase {
    getVTMemberBase {
      account_name
      account_no
      account_type
      address
      address2
      again
      bank
      belong_total
      biz_id
      biz_name
      block_num
      branch_name
      brand_id
      brand_name
      city
      close_time
      code
      corporate_no
      corporate_type
      created_date
      email
      favorite_num
      group_num
      id
      image
      invite_code
      join_date
      manager
      mco_address
      mco_address2
      mco_city
      mco_m_first_kana
      mco_m_first_name
      mco_m_kana
      mco_m_last_kana
      mco_m_last_name
      mco_m_name
      mco_name
      mco_phone
      mco_state
      mco_t_first_kana
      mco_t_first_name
      mco_t_kana
      mco_t_last_kana
      mco_t_last_name
      mco_t_name
      mco_t_phone
      mco_zip_code
      mgr_birthday
      mgr_dob_day
      mgr_dob_month
      mgr_dob_year
      mgr_first_kana
      mgr_first_name
      mgr_id
      mgr_last_kana
      mgr_last_name
      mgr_phone
      open_time
      opening
      parking
      parking_val
      pause_date
      payment_method
      personal_bank
      phone
      quit_date
      restart_date
      state
      status
      store_name
      ticket_num
      total_adopt
      zip_code
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetVtMemberBaseQuery__
 *
 * To run a query within a React component, call `useGetVtMemberBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtMemberBaseQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtMemberBaseQuery, GetVtMemberBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberBaseQuery, GetVtMemberBaseQueryVariables>(GetVtMemberBaseDocument, options);
}
export function useGetVtMemberBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberBaseQuery, GetVtMemberBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberBaseQuery, GetVtMemberBaseQueryVariables>(GetVtMemberBaseDocument, options);
}
export type GetVtMemberBaseQueryHookResult = ReturnType<typeof useGetVtMemberBaseQuery>;
export type GetVtMemberBaseLazyQueryHookResult = ReturnType<typeof useGetVtMemberBaseLazyQuery>;
export type GetVtMemberBaseQueryResult = Apollo.QueryResult<GetVtMemberBaseQuery, GetVtMemberBaseQueryVariables>;
export const GetVtMemberBaseByIdDocument = gql`
  query GetVTMemberBaseById($id: Int!) {
    getVTMemberBaseById(id: $id) {
      account_name
      account_no
      account_type
      address
      address2
      again
      bank
      belong_total
      biz_id
      biz_name
      block_num
      branch_name
      brand_id
      brand_name
      city
      close_time
      code
      corporate_no
      corporate_type
      created_date
      email
      favorite_num
      group_num
      id
      image
      invite_code
      join_date
      manager
      mco_address
      mco_address2
      mco_city
      mco_m_first_kana
      mco_m_first_name
      mco_m_kana
      mco_m_last_kana
      mco_m_last_name
      mco_m_name
      mco_name
      mco_phone
      mco_state
      mco_t_first_kana
      mco_t_first_name
      mco_t_kana
      mco_t_last_kana
      mco_t_last_name
      mco_t_name
      mco_t_phone
      mco_zip_code
      mgr_birthday
      mgr_dob_day
      mgr_dob_month
      mgr_dob_year
      mgr_first_kana
      mgr_first_name
      mgr_id
      mgr_last_kana
      mgr_last_name
      mgr_phone
      open_time
      opening
      parking
      parking_val
      pause_date
      payment_method
      personal_bank
      phone
      quit_date
      restart_date
      state
      status
      store_name
      ticket_num
      total_adopt
      zip_code
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetVtMemberBaseByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberBaseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberBaseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberBaseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberBaseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberBaseByIdQuery, GetVtMemberBaseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberBaseByIdQuery, GetVtMemberBaseByIdQueryVariables>(
    GetVtMemberBaseByIdDocument,
    options
  );
}
export function useGetVtMemberBaseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberBaseByIdQuery, GetVtMemberBaseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberBaseByIdQuery, GetVtMemberBaseByIdQueryVariables>(
    GetVtMemberBaseByIdDocument,
    options
  );
}
export type GetVtMemberBaseByIdQueryHookResult = ReturnType<typeof useGetVtMemberBaseByIdQuery>;
export type GetVtMemberBaseByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberBaseByIdLazyQuery>;
export type GetVtMemberBaseByIdQueryResult = Apollo.QueryResult<
  GetVtMemberBaseByIdQuery,
  GetVtMemberBaseByIdQueryVariables
>;
export const GetVtMemberBelongListByIdDocument = gql`
  query GetVTMemberBelongListById($id: Int!) {
    getVTMemberBelongListById(id: $id) {
      belong {
        address
        address2
        age
        attribute
        begin_date
        birthday
        biz_id
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        kind
        name
        phone
        rank_name
        score
        state
        status
        sub_name
        tpmem_id
        tpmem_name
        uniform_size
        worked_count
        zip_code
        __typename
      }
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      sub {
        address
        address2
        age
        attribute
        begin_date
        birthday
        biz_id
        btmem_number
        cancel_per
        city
        email
        gender
        id
        identification
        image
        kind
        name
        phone
        rank_name
        score
        state
        status
        sub_name
        tpmem_id
        tpmem_name
        uniform_size
        worked_count
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberBelongListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberBelongListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberBelongListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberBelongListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberBelongListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberBelongListByIdQuery, GetVtMemberBelongListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberBelongListByIdQuery, GetVtMemberBelongListByIdQueryVariables>(
    GetVtMemberBelongListByIdDocument,
    options
  );
}
export function useGetVtMemberBelongListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberBelongListByIdQuery, GetVtMemberBelongListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberBelongListByIdQuery, GetVtMemberBelongListByIdQueryVariables>(
    GetVtMemberBelongListByIdDocument,
    options
  );
}
export type GetVtMemberBelongListByIdQueryHookResult = ReturnType<typeof useGetVtMemberBelongListByIdQuery>;
export type GetVtMemberBelongListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberBelongListByIdLazyQuery>;
export type GetVtMemberBelongListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberBelongListByIdQuery,
  GetVtMemberBelongListByIdQueryVariables
>;
export const GetVtMemberBlockListByIdDocument = gql`
  query GetVTMemberBlockListById($id: Int!) {
    getVTMemberBlockListById(id: $id) {
      block {
        block_id
        block_name
        image
        name
        reason
        reg_date
        status
        tpmem_id
        __typename
      }
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberBlockListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberBlockListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberBlockListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberBlockListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberBlockListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberBlockListByIdQuery, GetVtMemberBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberBlockListByIdQuery, GetVtMemberBlockListByIdQueryVariables>(
    GetVtMemberBlockListByIdDocument,
    options
  );
}
export function useGetVtMemberBlockListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberBlockListByIdQuery, GetVtMemberBlockListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberBlockListByIdQuery, GetVtMemberBlockListByIdQueryVariables>(
    GetVtMemberBlockListByIdDocument,
    options
  );
}
export type GetVtMemberBlockListByIdQueryHookResult = ReturnType<typeof useGetVtMemberBlockListByIdQuery>;
export type GetVtMemberBlockListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberBlockListByIdLazyQuery>;
export type GetVtMemberBlockListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberBlockListByIdQuery,
  GetVtMemberBlockListByIdQueryVariables
>;
export const GetVtMemberGroupListByIdDocument = gql`
  query GetVTMemberGroupListById($id: Int!) {
    getVTMemberGroupListById(id: $id) {
      group {
        accept_date
        group_id
        image
        my_id
        name
        status
        tpmem_id
        __typename
      }
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberGroupListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberGroupListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberGroupListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberGroupListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberGroupListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberGroupListByIdQuery, GetVtMemberGroupListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberGroupListByIdQuery, GetVtMemberGroupListByIdQueryVariables>(
    GetVtMemberGroupListByIdDocument,
    options
  );
}
export function useGetVtMemberGroupListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberGroupListByIdQuery, GetVtMemberGroupListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberGroupListByIdQuery, GetVtMemberGroupListByIdQueryVariables>(
    GetVtMemberGroupListByIdDocument,
    options
  );
}
export type GetVtMemberGroupListByIdQueryHookResult = ReturnType<typeof useGetVtMemberGroupListByIdQuery>;
export type GetVtMemberGroupListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberGroupListByIdLazyQuery>;
export type GetVtMemberGroupListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberGroupListByIdQuery,
  GetVtMemberGroupListByIdQueryVariables
>;
export const GetVtMemberListDocument = gql`
  query GetVTMemberList(
    $status: Int
    $code_name: String
    $state: String
    $city: String
    $biz_id: Int
    $brand_id: Int
    $limit: Int!
    $offset: Int!
  ) {
    getVTMemberList(
      status: $status
      code_name: $code_name
      state: $state
      city: $city
      biz_id: $biz_id
      brand_id: $brand_id
      limit: $limit
      offset: $offset
    ) {
      count
      list {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberListQuery__
 *
 * To run a query within a React component, call `useGetVtMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      code_name: // value for 'code_name'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      biz_id: // value for 'biz_id'
 *      brand_id: // value for 'brand_id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetVtMemberListQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberListQuery, GetVtMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberListQuery, GetVtMemberListQueryVariables>(GetVtMemberListDocument, options);
}
export function useGetVtMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberListQuery, GetVtMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberListQuery, GetVtMemberListQueryVariables>(GetVtMemberListDocument, options);
}
export type GetVtMemberListQueryHookResult = ReturnType<typeof useGetVtMemberListQuery>;
export type GetVtMemberListLazyQueryHookResult = ReturnType<typeof useGetVtMemberListLazyQuery>;
export type GetVtMemberListQueryResult = Apollo.QueryResult<GetVtMemberListQuery, GetVtMemberListQueryVariables>;
export const GetVtMemberListWithOrderDocument = gql`
  query GetVTMemberListWithOrder(
    $status: Int
    $code_name: String
    $state: String
    $city: String
    $biz_id: Int
    $brand_id: Int
    $payment_method: Int
    $order_name: String
    $desc: Boolean
    $limit: Int!
    $offset: Int!
  ) {
    getVTMemberListWithOrder(
      status: $status
      code_name: $code_name
      state: $state
      city: $city
      biz_id: $biz_id
      brand_id: $brand_id
      payment_method: $payment_method
      order_name: $order_name
      desc: $desc
      limit: $limit
      offset: $offset
    ) {
      count
      list {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberListWithOrderQuery__
 *
 * To run a query within a React component, call `useGetVtMemberListWithOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberListWithOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberListWithOrderQuery({
 *   variables: {
 *      status: // value for 'status'
 *      code_name: // value for 'code_name'
 *      state: // value for 'state'
 *      city: // value for 'city'
 *      biz_id: // value for 'biz_id'
 *      brand_id: // value for 'brand_id'
 *      payment_method: // value for 'payment_method'
 *      order_name: // value for 'order_name'
 *      desc: // value for 'desc'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetVtMemberListWithOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberListWithOrderQuery, GetVtMemberListWithOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberListWithOrderQuery, GetVtMemberListWithOrderQueryVariables>(
    GetVtMemberListWithOrderDocument,
    options
  );
}
export function useGetVtMemberListWithOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberListWithOrderQuery, GetVtMemberListWithOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberListWithOrderQuery, GetVtMemberListWithOrderQueryVariables>(
    GetVtMemberListWithOrderDocument,
    options
  );
}
export type GetVtMemberListWithOrderQueryHookResult = ReturnType<typeof useGetVtMemberListWithOrderQuery>;
export type GetVtMemberListWithOrderLazyQueryHookResult = ReturnType<typeof useGetVtMemberListWithOrderLazyQuery>;
export type GetVtMemberListWithOrderQueryResult = Apollo.QueryResult<
  GetVtMemberListWithOrderQuery,
  GetVtMemberListWithOrderQueryVariables
>;
export const GetVtMemberList2Document = gql`
  query GetVTMemberList2($limit: Int, $offset: Int, $statuses: String) {
    getVTMemberList2(limit: $limit, offset: $offset, statuses: $statuses) {
      count
      list {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberList2Query__
 *
 * To run a query within a React component, call `useGetVtMemberList2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberList2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberList2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetVtMemberList2Query(
  baseOptions?: Apollo.QueryHookOptions<GetVtMemberList2Query, GetVtMemberList2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberList2Query, GetVtMemberList2QueryVariables>(GetVtMemberList2Document, options);
}
export function useGetVtMemberList2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberList2Query, GetVtMemberList2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberList2Query, GetVtMemberList2QueryVariables>(GetVtMemberList2Document, options);
}
export type GetVtMemberList2QueryHookResult = ReturnType<typeof useGetVtMemberList2Query>;
export type GetVtMemberList2LazyQueryHookResult = ReturnType<typeof useGetVtMemberList2LazyQuery>;
export type GetVtMemberList2QueryResult = Apollo.QueryResult<GetVtMemberList2Query, GetVtMemberList2QueryVariables>;
export const GetVtMemberListByIdDocument = gql`
  query GetVTMemberListById($id: Int!) {
    getVTMemberListById(id: $id) {
      address
      address2
      again
      again_num
      area_name
      belong_count
      belong_num
      belong_total
      biz_id
      biz_name
      block_count
      block_num
      brand_id
      brand_name
      city
      code
      email
      group_num
      id
      image
      manager
      mco_name
      mgr_id
      newly
      opening
      other_adopt
      parking
      payment_method
      phone
      score
      score_num
      score_value
      state
      status
      store_name
      sub_count
      sub_num
      ticket_num
      total_adopt
      unclaimed
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVtMemberListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberListByIdQuery, GetVtMemberListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberListByIdQuery, GetVtMemberListByIdQueryVariables>(
    GetVtMemberListByIdDocument,
    options
  );
}
export function useGetVtMemberListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberListByIdQuery, GetVtMemberListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberListByIdQuery, GetVtMemberListByIdQueryVariables>(
    GetVtMemberListByIdDocument,
    options
  );
}
export type GetVtMemberListByIdQueryHookResult = ReturnType<typeof useGetVtMemberListByIdQuery>;
export type GetVtMemberListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberListByIdLazyQuery>;
export type GetVtMemberListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberListByIdQuery,
  GetVtMemberListByIdQueryVariables
>;
export const GetVtMemberLogging3ListByIdDocument = gql`
  query GetVTMemberLogging3ListById($id: Int!) {
    getVTMemberLogging3ListById(id: $id) {
      logging {
        id
        kind
        log_date
        matter
        ref_no
        tpmem_id
        __typename
      }
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberLogging3ListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberLogging3ListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberLogging3ListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberLogging3ListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberLogging3ListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberLogging3ListByIdQuery, GetVtMemberLogging3ListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberLogging3ListByIdQuery, GetVtMemberLogging3ListByIdQueryVariables>(
    GetVtMemberLogging3ListByIdDocument,
    options
  );
}
export function useGetVtMemberLogging3ListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberLogging3ListByIdQuery, GetVtMemberLogging3ListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberLogging3ListByIdQuery, GetVtMemberLogging3ListByIdQueryVariables>(
    GetVtMemberLogging3ListByIdDocument,
    options
  );
}
export type GetVtMemberLogging3ListByIdQueryHookResult = ReturnType<typeof useGetVtMemberLogging3ListByIdQuery>;
export type GetVtMemberLogging3ListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberLogging3ListByIdLazyQuery>;
export type GetVtMemberLogging3ListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberLogging3ListByIdQuery,
  GetVtMemberLogging3ListByIdQueryVariables
>;
export const GetVtMemberLogging5ListByIdDocument = gql`
  query GetVTMemberLogging5ListById($id: Int!) {
    getVTMemberLogging5ListById(id: $id) {
      logging {
        id
        kind
        log_date
        matter
        ref_no
        tpmem_id
        __typename
      }
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberLogging5ListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberLogging5ListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberLogging5ListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberLogging5ListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberLogging5ListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberLogging5ListByIdQuery, GetVtMemberLogging5ListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberLogging5ListByIdQuery, GetVtMemberLogging5ListByIdQueryVariables>(
    GetVtMemberLogging5ListByIdDocument,
    options
  );
}
export function useGetVtMemberLogging5ListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberLogging5ListByIdQuery, GetVtMemberLogging5ListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberLogging5ListByIdQuery, GetVtMemberLogging5ListByIdQueryVariables>(
    GetVtMemberLogging5ListByIdDocument,
    options
  );
}
export type GetVtMemberLogging5ListByIdQueryHookResult = ReturnType<typeof useGetVtMemberLogging5ListByIdQuery>;
export type GetVtMemberLogging5ListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberLogging5ListByIdLazyQuery>;
export type GetVtMemberLogging5ListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberLogging5ListByIdQuery,
  GetVtMemberLogging5ListByIdQueryVariables
>;
export const GetVtMemberTicketListByIdDocument = gql`
  query GetVTMemberTicketListById($id: Int!) {
    getVTMemberTicketListById(id: $id) {
      member {
        address
        address2
        again
        again_num
        area_name
        belong_count
        belong_num
        belong_total
        biz_id
        biz_name
        block_count
        block_num
        brand_id
        brand_name
        city
        code
        email
        group_num
        id
        image
        manager
        mco_name
        mgr_id
        newly
        opening
        other_adopt
        parking
        payment_method
        phone
        score
        score_num
        score_value
        state
        status
        store_name
        sub_count
        sub_num
        ticket_num
        total_adopt
        unclaimed
        zip_code
        __typename
      }
      ticket {
        award_date
        enabled_count
        end_date
        id
        item
        timeup
        tpmem_id
        umgmt_name
        valid_days
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtMemberTicketListByIdQuery__
 *
 * To run a query within a React component, call `useGetVtMemberTicketListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtMemberTicketListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtMemberTicketListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVtMemberTicketListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtMemberTicketListByIdQuery, GetVtMemberTicketListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtMemberTicketListByIdQuery, GetVtMemberTicketListByIdQueryVariables>(
    GetVtMemberTicketListByIdDocument,
    options
  );
}
export function useGetVtMemberTicketListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtMemberTicketListByIdQuery, GetVtMemberTicketListByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtMemberTicketListByIdQuery, GetVtMemberTicketListByIdQueryVariables>(
    GetVtMemberTicketListByIdDocument,
    options
  );
}
export type GetVtMemberTicketListByIdQueryHookResult = ReturnType<typeof useGetVtMemberTicketListByIdQuery>;
export type GetVtMemberTicketListByIdLazyQueryHookResult = ReturnType<typeof useGetVtMemberTicketListByIdLazyQuery>;
export type GetVtMemberTicketListByIdQueryResult = Apollo.QueryResult<
  GetVtMemberTicketListByIdQuery,
  GetVtMemberTicketListByIdQueryVariables
>;
export const GetVtReviewListDocument = gql`
  query GetVTReviewList {
    getVTReviewList {
      anonymous
      btmem_id
      comment
      image
      name
      offer_id
      post_date
      post_date_2
      score
      tpmem_id
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVtReviewListQuery__
 *
 * To run a query within a React component, call `useGetVtReviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtReviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtReviewListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtReviewListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtReviewListQuery, GetVtReviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtReviewListQuery, GetVtReviewListQueryVariables>(GetVtReviewListDocument, options);
}
export function useGetVtReviewListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtReviewListQuery, GetVtReviewListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtReviewListQuery, GetVtReviewListQueryVariables>(GetVtReviewListDocument, options);
}
export type GetVtReviewListQueryHookResult = ReturnType<typeof useGetVtReviewListQuery>;
export type GetVtReviewListLazyQueryHookResult = ReturnType<typeof useGetVtReviewListLazyQuery>;
export type GetVtReviewListQueryResult = Apollo.QueryResult<GetVtReviewListQuery, GetVtReviewListQueryVariables>;
export const GetVtReviewListByTpmemIdDocument = gql`
  query GetVTReviewListByTpmemId($tpmem_id: Int!) {
    getVTReviewListByTpmemId(tpmem_id: $tpmem_id) {
      anonymous
      btmem_id
      comment
      image
      name
      offer_id
      post_date
      post_date_2
      score
      tpmem_id
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVtReviewListByTpmemIdQuery__
 *
 * To run a query within a React component, call `useGetVtReviewListByTpmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtReviewListByTpmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtReviewListByTpmemIdQuery({
 *   variables: {
 *      tpmem_id: // value for 'tpmem_id'
 *   },
 * });
 */
export function useGetVtReviewListByTpmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtReviewListByTpmemIdQuery, GetVtReviewListByTpmemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtReviewListByTpmemIdQuery, GetVtReviewListByTpmemIdQueryVariables>(
    GetVtReviewListByTpmemIdDocument,
    options
  );
}
export function useGetVtReviewListByTpmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtReviewListByTpmemIdQuery, GetVtReviewListByTpmemIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtReviewListByTpmemIdQuery, GetVtReviewListByTpmemIdQueryVariables>(
    GetVtReviewListByTpmemIdDocument,
    options
  );
}
export type GetVtReviewListByTpmemIdQueryHookResult = ReturnType<typeof useGetVtReviewListByTpmemIdQuery>;
export type GetVtReviewListByTpmemIdLazyQueryHookResult = ReturnType<typeof useGetVtReviewListByTpmemIdLazyQuery>;
export type GetVtReviewListByTpmemIdQueryResult = Apollo.QueryResult<
  GetVtReviewListByTpmemIdQuery,
  GetVtReviewListByTpmemIdQueryVariables
>;
export const GetVtScoreListDocument = gql`
  query GetVTScoreList {
    getVTScoreList {
      address
      address2
      biz_id
      biz_name
      brand_id
      brand_name
      bt_post_day
      btmem_name
      city
      hour_wage
      offer_no
      state
      tp_score
      tpmem_name
      trans_fee
      work_day
      work_id
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVtScoreListQuery__
 *
 * To run a query within a React component, call `useGetVtScoreListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtScoreListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtScoreListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtScoreListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtScoreListQuery, GetVtScoreListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtScoreListQuery, GetVtScoreListQueryVariables>(GetVtScoreListDocument, options);
}
export function useGetVtScoreListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtScoreListQuery, GetVtScoreListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtScoreListQuery, GetVtScoreListQueryVariables>(GetVtScoreListDocument, options);
}
export type GetVtScoreListQueryHookResult = ReturnType<typeof useGetVtScoreListQuery>;
export type GetVtScoreListLazyQueryHookResult = ReturnType<typeof useGetVtScoreListLazyQuery>;
export type GetVtScoreListQueryResult = Apollo.QueryResult<GetVtScoreListQuery, GetVtScoreListQueryVariables>;
export const GetVtScorePercentInfoDocument = gql`
  query GetVTScorePercentInfo {
    getVTScorePercentInfo {
      again_num
      belong_total
      block_num
      group_num
      image
      score1_per
      score2_per
      score3_per
      score4_per
      score5_per
      score_num
      score_value
      status
      store_name
      tpmem_id
      __typename
    }
  }
`;

/**
 * __useGetVtScorePercentInfoQuery__
 *
 * To run a query within a React component, call `useGetVtScorePercentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtScorePercentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtScorePercentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtScorePercentInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtScorePercentInfoQuery, GetVtScorePercentInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtScorePercentInfoQuery, GetVtScorePercentInfoQueryVariables>(
    GetVtScorePercentInfoDocument,
    options
  );
}
export function useGetVtScorePercentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtScorePercentInfoQuery, GetVtScorePercentInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtScorePercentInfoQuery, GetVtScorePercentInfoQueryVariables>(
    GetVtScorePercentInfoDocument,
    options
  );
}
export type GetVtScorePercentInfoQueryHookResult = ReturnType<typeof useGetVtScorePercentInfoQuery>;
export type GetVtScorePercentInfoLazyQueryHookResult = ReturnType<typeof useGetVtScorePercentInfoLazyQuery>;
export type GetVtScorePercentInfoQueryResult = Apollo.QueryResult<
  GetVtScorePercentInfoQuery,
  GetVtScorePercentInfoQueryVariables
>;
export const GetVtScorePercentInfoByTpmemIdDocument = gql`
  query GetVTScorePercentInfoByTpmemId($tpmem_id: Int!) {
    getVTScorePercentInfoByTpmemId(tpmem_id: $tpmem_id) {
      info {
        again_num
        belong_total
        block_num
        group_num
        image
        score1_per
        score2_per
        score3_per
        score4_per
        score5_per
        score_num
        score_value
        status
        store_name
        tpmem_id
        __typename
      }
      list {
        anonymous
        btmem_id
        comment
        image
        name
        offer_id
        post_date
        post_date_2
        score
        tpmem_id
        work_id
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtScorePercentInfoByTpmemIdQuery__
 *
 * To run a query within a React component, call `useGetVtScorePercentInfoByTpmemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtScorePercentInfoByTpmemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtScorePercentInfoByTpmemIdQuery({
 *   variables: {
 *      tpmem_id: // value for 'tpmem_id'
 *   },
 * });
 */
export function useGetVtScorePercentInfoByTpmemIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtScorePercentInfoByTpmemIdQuery,
    GetVtScorePercentInfoByTpmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtScorePercentInfoByTpmemIdQuery, GetVtScorePercentInfoByTpmemIdQueryVariables>(
    GetVtScorePercentInfoByTpmemIdDocument,
    options
  );
}
export function useGetVtScorePercentInfoByTpmemIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtScorePercentInfoByTpmemIdQuery,
    GetVtScorePercentInfoByTpmemIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtScorePercentInfoByTpmemIdQuery, GetVtScorePercentInfoByTpmemIdQueryVariables>(
    GetVtScorePercentInfoByTpmemIdDocument,
    options
  );
}
export type GetVtScorePercentInfoByTpmemIdQueryHookResult = ReturnType<typeof useGetVtScorePercentInfoByTpmemIdQuery>;
export type GetVtScorePercentInfoByTpmemIdLazyQueryHookResult = ReturnType<
  typeof useGetVtScorePercentInfoByTpmemIdLazyQuery
>;
export type GetVtScorePercentInfoByTpmemIdQueryResult = Apollo.QueryResult<
  GetVtScorePercentInfoByTpmemIdQuery,
  GetVtScorePercentInfoByTpmemIdQueryVariables
>;
export const GetVtTicketListDocument = gql`
  query GetVTTicketList {
    getVTTicketList {
      award_date
      enabled_count
      end_date
      id
      item
      timeup
      tpmem_id
      umgmt_name
      valid_days
      __typename
    }
  }
`;

/**
 * __useGetVtTicketListQuery__
 *
 * To run a query within a React component, call `useGetVtTicketListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtTicketListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtTicketListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtTicketListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtTicketListQuery, GetVtTicketListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtTicketListQuery, GetVtTicketListQueryVariables>(GetVtTicketListDocument, options);
}
export function useGetVtTicketListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtTicketListQuery, GetVtTicketListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtTicketListQuery, GetVtTicketListQueryVariables>(GetVtTicketListDocument, options);
}
export type GetVtTicketListQueryHookResult = ReturnType<typeof useGetVtTicketListQuery>;
export type GetVtTicketListLazyQueryHookResult = ReturnType<typeof useGetVtTicketListLazyQuery>;
export type GetVtTicketListQueryResult = Apollo.QueryResult<GetVtTicketListQuery, GetVtTicketListQueryVariables>;
export const GetVtTicketMgtInfoDocument = gql`
  query GetVTTicketMgtInfo {
    getVTTicketMgtInfo {
      expired_count
      expired_price
      issue_count
      issue_price
      __typename
    }
  }
`;

/**
 * __useGetVtTicketMgtInfoQuery__
 *
 * To run a query within a React component, call `useGetVtTicketMgtInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtTicketMgtInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtTicketMgtInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtTicketMgtInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtTicketMgtInfoQuery, GetVtTicketMgtInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtTicketMgtInfoQuery, GetVtTicketMgtInfoQueryVariables>(
    GetVtTicketMgtInfoDocument,
    options
  );
}
export function useGetVtTicketMgtInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtTicketMgtInfoQuery, GetVtTicketMgtInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtTicketMgtInfoQuery, GetVtTicketMgtInfoQueryVariables>(
    GetVtTicketMgtInfoDocument,
    options
  );
}
export type GetVtTicketMgtInfoQueryHookResult = ReturnType<typeof useGetVtTicketMgtInfoQuery>;
export type GetVtTicketMgtInfoLazyQueryHookResult = ReturnType<typeof useGetVtTicketMgtInfoLazyQuery>;
export type GetVtTicketMgtInfoQueryResult = Apollo.QueryResult<
  GetVtTicketMgtInfoQuery,
  GetVtTicketMgtInfoQueryVariables
>;
export const GetVtTransProcListNewlyDocument = gql`
  query GetVTTransProcListNewly {
    getVTTransProcListNewly {
      newly {
        mn_all
        trans_status
        __typename
      }
      trans {
        account_name
        account_no
        account_type
        bank_name
        branch_name
        created_date
        name
        status
        tpmem_id
        tpmem_name
        tr_proc_date
        tr_proc_type
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtTransProcListNewlyQuery__
 *
 * To run a query within a React component, call `useGetVtTransProcListNewlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtTransProcListNewlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtTransProcListNewlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtTransProcListNewlyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtTransProcListNewlyQuery, GetVtTransProcListNewlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtTransProcListNewlyQuery, GetVtTransProcListNewlyQueryVariables>(
    GetVtTransProcListNewlyDocument,
    options
  );
}
export function useGetVtTransProcListNewlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtTransProcListNewlyQuery, GetVtTransProcListNewlyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtTransProcListNewlyQuery, GetVtTransProcListNewlyQueryVariables>(
    GetVtTransProcListNewlyDocument,
    options
  );
}
export type GetVtTransProcListNewlyQueryHookResult = ReturnType<typeof useGetVtTransProcListNewlyQuery>;
export type GetVtTransProcListNewlyLazyQueryHookResult = ReturnType<typeof useGetVtTransProcListNewlyLazyQuery>;
export type GetVtTransProcListNewlyQueryResult = Apollo.QueryResult<
  GetVtTransProcListNewlyQuery,
  GetVtTransProcListNewlyQueryVariables
>;
export const GetVtWorkScoreInfoByWorkIdDocument = gql`
  query GetVTWorkScoreInfoByWorkId($work_id: Int!) {
    getVTWorkScoreInfoByWorkId(work_id: $work_id) {
      again
      bt_comment
      bt_post_date
      bt_score
      btmem_id
      btmem_name
      tp_comment
      tp_post_date
      tp_score
      tpmem_id
      tpmem_name
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVtWorkScoreInfoByWorkIdQuery__
 *
 * To run a query within a React component, call `useGetVtWorkScoreInfoByWorkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtWorkScoreInfoByWorkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtWorkScoreInfoByWorkIdQuery({
 *   variables: {
 *      work_id: // value for 'work_id'
 *   },
 * });
 */
export function useGetVtWorkScoreInfoByWorkIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtWorkScoreInfoByWorkIdQuery, GetVtWorkScoreInfoByWorkIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtWorkScoreInfoByWorkIdQuery, GetVtWorkScoreInfoByWorkIdQueryVariables>(
    GetVtWorkScoreInfoByWorkIdDocument,
    options
  );
}
export function useGetVtWorkScoreInfoByWorkIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtWorkScoreInfoByWorkIdQuery, GetVtWorkScoreInfoByWorkIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtWorkScoreInfoByWorkIdQuery, GetVtWorkScoreInfoByWorkIdQueryVariables>(
    GetVtWorkScoreInfoByWorkIdDocument,
    options
  );
}
export type GetVtWorkScoreInfoByWorkIdQueryHookResult = ReturnType<typeof useGetVtWorkScoreInfoByWorkIdQuery>;
export type GetVtWorkScoreInfoByWorkIdLazyQueryHookResult = ReturnType<typeof useGetVtWorkScoreInfoByWorkIdLazyQuery>;
export type GetVtWorkScoreInfoByWorkIdQueryResult = Apollo.QueryResult<
  GetVtWorkScoreInfoByWorkIdQuery,
  GetVtWorkScoreInfoByWorkIdQueryVariables
>;
export const GetVtWorkingListDocument = gql`
  query GetVTWorkingList {
    getVTWorkingList {
      begin_date
      begin_day
      begin_end
      biz_id
      brand_id
      btmem_id
      btmem_name
      btmem_number
      end_date
      entry_id
      entry_number
      fix_checkin
      fix_checkout
      fix_count
      image
      newly
      newly_review
      offer_id
      offer_number
      qr_checkin
      qr_checkout
      status
      time_left
      time_left_minute
      title
      tpmem_id
      tpmem_name
      type
      type_name
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVtWorkingListQuery__
 *
 * To run a query within a React component, call `useGetVtWorkingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtWorkingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtWorkingListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVtWorkingListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVtWorkingListQuery, GetVtWorkingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtWorkingListQuery, GetVtWorkingListQueryVariables>(GetVtWorkingListDocument, options);
}
export function useGetVtWorkingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVtWorkingListQuery, GetVtWorkingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtWorkingListQuery, GetVtWorkingListQueryVariables>(GetVtWorkingListDocument, options);
}
export type GetVtWorkingListQueryHookResult = ReturnType<typeof useGetVtWorkingListQuery>;
export type GetVtWorkingListLazyQueryHookResult = ReturnType<typeof useGetVtWorkingListLazyQuery>;
export type GetVtWorkingListQueryResult = Apollo.QueryResult<GetVtWorkingListQuery, GetVtWorkingListQueryVariables>;
export const GetVtWorkingLogging2ListByOfferIdDocument = gql`
  query GetVTWorkingLogging2ListByOfferId($offer_id: Int!) {
    getVTWorkingLogging2ListByOfferId(offer_id: $offer_id) {
      logging {
        id
        kind
        log_date
        matter
        ref_no
        tpmem_id
        __typename
      }
      working {
        begin_date
        begin_day
        begin_end
        biz_id
        brand_id
        btmem_id
        btmem_name
        btmem_number
        end_date
        entry_id
        entry_number
        fix_checkin
        fix_checkout
        fix_count
        image
        newly
        newly_review
        offer_id
        offer_number
        qr_checkin
        qr_checkout
        status
        time_left
        time_left_minute
        title
        tpmem_id
        tpmem_name
        type
        type_name
        work_id
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVtWorkingLogging2ListByOfferIdQuery__
 *
 * To run a query within a React component, call `useGetVtWorkingLogging2ListByOfferIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtWorkingLogging2ListByOfferIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtWorkingLogging2ListByOfferIdQuery({
 *   variables: {
 *      offer_id: // value for 'offer_id'
 *   },
 * });
 */
export function useGetVtWorkingLogging2ListByOfferIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVtWorkingLogging2ListByOfferIdQuery,
    GetVtWorkingLogging2ListByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtWorkingLogging2ListByOfferIdQuery, GetVtWorkingLogging2ListByOfferIdQueryVariables>(
    GetVtWorkingLogging2ListByOfferIdDocument,
    options
  );
}
export function useGetVtWorkingLogging2ListByOfferIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtWorkingLogging2ListByOfferIdQuery,
    GetVtWorkingLogging2ListByOfferIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtWorkingLogging2ListByOfferIdQuery, GetVtWorkingLogging2ListByOfferIdQueryVariables>(
    GetVtWorkingLogging2ListByOfferIdDocument,
    options
  );
}
export type GetVtWorkingLogging2ListByOfferIdQueryHookResult = ReturnType<
  typeof useGetVtWorkingLogging2ListByOfferIdQuery
>;
export type GetVtWorkingLogging2ListByOfferIdLazyQueryHookResult = ReturnType<
  typeof useGetVtWorkingLogging2ListByOfferIdLazyQuery
>;
export type GetVtWorkingLogging2ListByOfferIdQueryResult = Apollo.QueryResult<
  GetVtWorkingLogging2ListByOfferIdQuery,
  GetVtWorkingLogging2ListByOfferIdQueryVariables
>;
export const GetVuMemberListDocument = gql`
  query GetVUMemberList {
    getVUMemberList {
      email
      id
      name
      privilege
      privilege_name
      __typename
    }
  }
`;

/**
 * __useGetVuMemberListQuery__
 *
 * To run a query within a React component, call `useGetVuMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVuMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVuMemberListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVuMemberListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVuMemberListQuery, GetVuMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVuMemberListQuery, GetVuMemberListQueryVariables>(GetVuMemberListDocument, options);
}
export function useGetVuMemberListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVuMemberListQuery, GetVuMemberListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVuMemberListQuery, GetVuMemberListQueryVariables>(GetVuMemberListDocument, options);
}
export type GetVuMemberListQueryHookResult = ReturnType<typeof useGetVuMemberListQuery>;
export type GetVuMemberListLazyQueryHookResult = ReturnType<typeof useGetVuMemberListLazyQuery>;
export type GetVuMemberListQueryResult = Apollo.QueryResult<GetVuMemberListQuery, GetVuMemberListQueryVariables>;
export const GetVuNewlyMenuAllDocument = gql`
  query GetVUNewlyMenuAll {
    getVUNewlyMenuAll {
      accounting {
        mn_all
        trans_status
        __typename
      }
      arbeit {
        im_inv
        im_pay
        mn_pay
        __typename
      }
      home {
        b_badge
        b_identfy
        b_join
        b_pay
        b_quit
        t_change
        t_pay
        t_quit
        t_regist
        __typename
      }
      menu {
        baitra
        bill_tp
        billing
        bt_identity
        bt_info
        home
        pay_back
        pay_work
        payment
        set_account
        settings
        tenpo
        tp_info
        __typename
      }
      refund {
        re_pay
        __typename
      }
      store {
        zp_pay
        zw_pay
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetVuNewlyMenuAllQuery__
 *
 * To run a query within a React component, call `useGetVuNewlyMenuAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVuNewlyMenuAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVuNewlyMenuAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVuNewlyMenuAllQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVuNewlyMenuAllQuery, GetVuNewlyMenuAllQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVuNewlyMenuAllQuery, GetVuNewlyMenuAllQueryVariables>(GetVuNewlyMenuAllDocument, options);
}
export function useGetVuNewlyMenuAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVuNewlyMenuAllQuery, GetVuNewlyMenuAllQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVuNewlyMenuAllQuery, GetVuNewlyMenuAllQueryVariables>(
    GetVuNewlyMenuAllDocument,
    options
  );
}
export type GetVuNewlyMenuAllQueryHookResult = ReturnType<typeof useGetVuNewlyMenuAllQuery>;
export type GetVuNewlyMenuAllLazyQueryHookResult = ReturnType<typeof useGetVuNewlyMenuAllLazyQuery>;
export type GetVuNewlyMenuAllQueryResult = Apollo.QueryResult<GetVuNewlyMenuAllQuery, GetVuNewlyMenuAllQueryVariables>;
export const GetVuSendNotifyInfoDocument = gql`
  query GetVUSendNotifyInfo {
    getVUSendNotifyInfo {
      biz_id
      body
      brand_id
      brand_name
      do_day
      do_time
      id
      status
      sys_id
      title
      to_kind_name
      __typename
    }
  }
`;

/**
 * __useGetVuSendNotifyInfoQuery__
 *
 * To run a query within a React component, call `useGetVuSendNotifyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVuSendNotifyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVuSendNotifyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVuSendNotifyInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVuSendNotifyInfoQuery, GetVuSendNotifyInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVuSendNotifyInfoQuery, GetVuSendNotifyInfoQueryVariables>(
    GetVuSendNotifyInfoDocument,
    options
  );
}
export function useGetVuSendNotifyInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVuSendNotifyInfoQuery, GetVuSendNotifyInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVuSendNotifyInfoQuery, GetVuSendNotifyInfoQueryVariables>(
    GetVuSendNotifyInfoDocument,
    options
  );
}
export type GetVuSendNotifyInfoQueryHookResult = ReturnType<typeof useGetVuSendNotifyInfoQuery>;
export type GetVuSendNotifyInfoLazyQueryHookResult = ReturnType<typeof useGetVuSendNotifyInfoLazyQuery>;
export type GetVuSendNotifyInfoQueryResult = Apollo.QueryResult<
  GetVuSendNotifyInfoQuery,
  GetVuSendNotifyInfoQueryVariables
>;
export const GetVuSendNotifyInfoByIdDocument = gql`
  query GetVUSendNotifyInfoById($id: Int!) {
    getVUSendNotifyInfoById(id: $id) {
      biz_id
      body
      brand_id
      brand_name
      do_day
      do_time
      id
      status
      sys_id
      title
      to_kind_name
      __typename
    }
  }
`;

/**
 * __useGetVuSendNotifyInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetVuSendNotifyInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVuSendNotifyInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVuSendNotifyInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVuSendNotifyInfoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVuSendNotifyInfoByIdQuery, GetVuSendNotifyInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVuSendNotifyInfoByIdQuery, GetVuSendNotifyInfoByIdQueryVariables>(
    GetVuSendNotifyInfoByIdDocument,
    options
  );
}
export function useGetVuSendNotifyInfoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVuSendNotifyInfoByIdQuery, GetVuSendNotifyInfoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVuSendNotifyInfoByIdQuery, GetVuSendNotifyInfoByIdQueryVariables>(
    GetVuSendNotifyInfoByIdDocument,
    options
  );
}
export type GetVuSendNotifyInfoByIdQueryHookResult = ReturnType<typeof useGetVuSendNotifyInfoByIdQuery>;
export type GetVuSendNotifyInfoByIdLazyQueryHookResult = ReturnType<typeof useGetVuSendNotifyInfoByIdLazyQuery>;
export type GetVuSendNotifyInfoByIdQueryResult = Apollo.QueryResult<
  GetVuSendNotifyInfoByIdQuery,
  GetVuSendNotifyInfoByIdQueryVariables
>;
export const GetVuSendNotifyListDocument = gql`
  query GetVUSendNotifyList {
    getVUSendNotifyList {
      brand_name
      created_date
      id
      send_date
      send_day
      status
      title
      to_kind_name
      __typename
    }
  }
`;

/**
 * __useGetVuSendNotifyListQuery__
 *
 * To run a query within a React component, call `useGetVuSendNotifyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVuSendNotifyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVuSendNotifyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVuSendNotifyListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVuSendNotifyListQuery, GetVuSendNotifyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVuSendNotifyListQuery, GetVuSendNotifyListQueryVariables>(
    GetVuSendNotifyListDocument,
    options
  );
}
export function useGetVuSendNotifyListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVuSendNotifyListQuery, GetVuSendNotifyListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVuSendNotifyListQuery, GetVuSendNotifyListQueryVariables>(
    GetVuSendNotifyListDocument,
    options
  );
}
export type GetVuSendNotifyListQueryHookResult = ReturnType<typeof useGetVuSendNotifyListQuery>;
export type GetVuSendNotifyListLazyQueryHookResult = ReturnType<typeof useGetVuSendNotifyListLazyQuery>;
export type GetVuSendNotifyListQueryResult = Apollo.QueryResult<
  GetVuSendNotifyListQuery,
  GetVuSendNotifyListQueryVariables
>;
export const GetVWorkScoreInfoByWorkIdDocument = gql`
  query GetVWorkScoreInfoByWorkId($work_id: Int!) {
    getVWorkScoreInfoByWorkId(work_id: $work_id) {
      again
      bt_comment
      bt_post_day
      bt_score
      btmem_id
      btmem_image
      btmem_name
      offer_id
      offer_no
      tp_comment
      tp_post_day
      tp_score
      tpmem_id
      tpmem_image
      tpmem_name
      work_id
      __typename
    }
  }
`;

/**
 * __useGetVWorkScoreInfoByWorkIdQuery__
 *
 * To run a query within a React component, call `useGetVWorkScoreInfoByWorkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVWorkScoreInfoByWorkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVWorkScoreInfoByWorkIdQuery({
 *   variables: {
 *      work_id: // value for 'work_id'
 *   },
 * });
 */
export function useGetVWorkScoreInfoByWorkIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVWorkScoreInfoByWorkIdQuery, GetVWorkScoreInfoByWorkIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVWorkScoreInfoByWorkIdQuery, GetVWorkScoreInfoByWorkIdQueryVariables>(
    GetVWorkScoreInfoByWorkIdDocument,
    options
  );
}
export function useGetVWorkScoreInfoByWorkIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVWorkScoreInfoByWorkIdQuery, GetVWorkScoreInfoByWorkIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVWorkScoreInfoByWorkIdQuery, GetVWorkScoreInfoByWorkIdQueryVariables>(
    GetVWorkScoreInfoByWorkIdDocument,
    options
  );
}
export type GetVWorkScoreInfoByWorkIdQueryHookResult = ReturnType<typeof useGetVWorkScoreInfoByWorkIdQuery>;
export type GetVWorkScoreInfoByWorkIdLazyQueryHookResult = ReturnType<typeof useGetVWorkScoreInfoByWorkIdLazyQuery>;
export type GetVWorkScoreInfoByWorkIdQueryResult = Apollo.QueryResult<
  GetVWorkScoreInfoByWorkIdQuery,
  GetVWorkScoreInfoByWorkIdQueryVariables
>;
export const IsSBenefitsSCampaignDocument = gql`
  query IsSBenefitsSCampaign($egift_code: Int!) {
    isSBenefitsSCampaign(egift_code: $egift_code) {
      bResult
      __typename
    }
  }
`;

/**
 * __useIsSBenefitsSCampaignQuery__
 *
 * To run a query within a React component, call `useIsSBenefitsSCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSBenefitsSCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSBenefitsSCampaignQuery({
 *   variables: {
 *      egift_code: // value for 'egift_code'
 *   },
 * });
 */
export function useIsSBenefitsSCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<IsSBenefitsSCampaignQuery, IsSBenefitsSCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsSBenefitsSCampaignQuery, IsSBenefitsSCampaignQueryVariables>(
    IsSBenefitsSCampaignDocument,
    options
  );
}
export function useIsSBenefitsSCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsSBenefitsSCampaignQuery, IsSBenefitsSCampaignQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsSBenefitsSCampaignQuery, IsSBenefitsSCampaignQueryVariables>(
    IsSBenefitsSCampaignDocument,
    options
  );
}
export type IsSBenefitsSCampaignQueryHookResult = ReturnType<typeof useIsSBenefitsSCampaignQuery>;
export type IsSBenefitsSCampaignLazyQueryHookResult = ReturnType<typeof useIsSBenefitsSCampaignLazyQuery>;
export type IsSBenefitsSCampaignQueryResult = Apollo.QueryResult<
  IsSBenefitsSCampaignQuery,
  IsSBenefitsSCampaignQueryVariables
>;
export const AddMBrandByBizIdDocument = gql`
  mutation AddMBrandByBizId($biz_id: Int!, $brands: [inMBrand]!) {
    addMBrandByBizId(biz_id: $biz_id, brands: $brands) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type AddMBrandByBizIdMutationFn = Apollo.MutationFunction<
  AddMBrandByBizIdMutation,
  AddMBrandByBizIdMutationVariables
>;

/**
 * __useAddMBrandByBizIdMutation__
 *
 * To run a mutation, you first call `useAddMBrandByBizIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMBrandByBizIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMBrandByBizIdMutation, { data, loading, error }] = useAddMBrandByBizIdMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      brands: // value for 'brands'
 *   },
 * });
 */
export function useAddMBrandByBizIdMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMBrandByBizIdMutation, AddMBrandByBizIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMBrandByBizIdMutation, AddMBrandByBizIdMutationVariables>(
    AddMBrandByBizIdDocument,
    options
  );
}
export type AddMBrandByBizIdMutationHookResult = ReturnType<typeof useAddMBrandByBizIdMutation>;
export type AddMBrandByBizIdMutationResult = Apollo.MutationResult<AddMBrandByBizIdMutation>;
export type AddMBrandByBizIdMutationOptions = Apollo.BaseMutationOptions<
  AddMBrandByBizIdMutation,
  AddMBrandByBizIdMutationVariables
>;
export const AddMBusinessDataDocument = gql`
  mutation AddMBusinessData(
    $s3_bucket: String
    $name: String!
    $prefix: String!
    $icon: String!
    $brands: [inMBrand]
    $licenses: [inMLicense]
    $section_filepath: String
    $checklist_filepath: String
  ) {
    addMBusinessData(
      s3_bucket: $s3_bucket
      name: $name
      prefix: $prefix
      icon: $icon
      brands: $brands
      licenses: $licenses
      section_filepath: $section_filepath
      checklist_filepath: $checklist_filepath
    ) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type AddMBusinessDataMutationFn = Apollo.MutationFunction<
  AddMBusinessDataMutation,
  AddMBusinessDataMutationVariables
>;

/**
 * __useAddMBusinessDataMutation__
 *
 * To run a mutation, you first call `useAddMBusinessDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMBusinessDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMBusinessDataMutation, { data, loading, error }] = useAddMBusinessDataMutation({
 *   variables: {
 *      s3_bucket: // value for 's3_bucket'
 *      name: // value for 'name'
 *      prefix: // value for 'prefix'
 *      icon: // value for 'icon'
 *      brands: // value for 'brands'
 *      licenses: // value for 'licenses'
 *      section_filepath: // value for 'section_filepath'
 *      checklist_filepath: // value for 'checklist_filepath'
 *   },
 * });
 */
export function useAddMBusinessDataMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMBusinessDataMutation, AddMBusinessDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMBusinessDataMutation, AddMBusinessDataMutationVariables>(
    AddMBusinessDataDocument,
    options
  );
}
export type AddMBusinessDataMutationHookResult = ReturnType<typeof useAddMBusinessDataMutation>;
export type AddMBusinessDataMutationResult = Apollo.MutationResult<AddMBusinessDataMutation>;
export type AddMBusinessDataMutationOptions = Apollo.BaseMutationOptions<
  AddMBusinessDataMutation,
  AddMBusinessDataMutationVariables
>;
export const UpdateMBusinessIconByIdDocument = gql`
  mutation UpdateMBusinessIconById($id: Int!, $icon: String!) {
    updateMBusinessIconById(id: $id, icon: $icon) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type UpdateMBusinessIconByIdMutationFn = Apollo.MutationFunction<
  UpdateMBusinessIconByIdMutation,
  UpdateMBusinessIconByIdMutationVariables
>;

/**
 * __useUpdateMBusinessIconByIdMutation__
 *
 * To run a mutation, you first call `useUpdateMBusinessIconByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMBusinessIconByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMBusinessIconByIdMutation, { data, loading, error }] = useUpdateMBusinessIconByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      icon: // value for 'icon'
 *   },
 * });
 */
export function useUpdateMBusinessIconByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMBusinessIconByIdMutation, UpdateMBusinessIconByIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMBusinessIconByIdMutation, UpdateMBusinessIconByIdMutationVariables>(
    UpdateMBusinessIconByIdDocument,
    options
  );
}
export type UpdateMBusinessIconByIdMutationHookResult = ReturnType<typeof useUpdateMBusinessIconByIdMutation>;
export type UpdateMBusinessIconByIdMutationResult = Apollo.MutationResult<UpdateMBusinessIconByIdMutation>;
export type UpdateMBusinessIconByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateMBusinessIconByIdMutation,
  UpdateMBusinessIconByIdMutationVariables
>;
export const AddMLicenseByBizIdDocument = gql`
  mutation AddMLicenseByBizId($biz_id: Int!, $licenses: [inMLicense]) {
    addMLicenseByBizId(biz_id: $biz_id, licenses: $licenses) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type AddMLicenseByBizIdMutationFn = Apollo.MutationFunction<
  AddMLicenseByBizIdMutation,
  AddMLicenseByBizIdMutationVariables
>;

/**
 * __useAddMLicenseByBizIdMutation__
 *
 * To run a mutation, you first call `useAddMLicenseByBizIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMLicenseByBizIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMLicenseByBizIdMutation, { data, loading, error }] = useAddMLicenseByBizIdMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      licenses: // value for 'licenses'
 *   },
 * });
 */
export function useAddMLicenseByBizIdMutation(
  baseOptions?: Apollo.MutationHookOptions<AddMLicenseByBizIdMutation, AddMLicenseByBizIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMLicenseByBizIdMutation, AddMLicenseByBizIdMutationVariables>(
    AddMLicenseByBizIdDocument,
    options
  );
}
export type AddMLicenseByBizIdMutationHookResult = ReturnType<typeof useAddMLicenseByBizIdMutation>;
export type AddMLicenseByBizIdMutationResult = Apollo.MutationResult<AddMLicenseByBizIdMutation>;
export type AddMLicenseByBizIdMutationOptions = Apollo.BaseMutationOptions<
  AddMLicenseByBizIdMutation,
  AddMLicenseByBizIdMutationVariables
>;
export const DelInsMLicenseByBizIdDocument = gql`
  mutation DelInsMLicenseByBizId($biz_id: Int!, $licenses: [inMLicense]) {
    delInsMLicenseByBizId(biz_id: $biz_id, licenses: $licenses) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type DelInsMLicenseByBizIdMutationFn = Apollo.MutationFunction<
  DelInsMLicenseByBizIdMutation,
  DelInsMLicenseByBizIdMutationVariables
>;

/**
 * __useDelInsMLicenseByBizIdMutation__
 *
 * To run a mutation, you first call `useDelInsMLicenseByBizIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelInsMLicenseByBizIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delInsMLicenseByBizIdMutation, { data, loading, error }] = useDelInsMLicenseByBizIdMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      licenses: // value for 'licenses'
 *   },
 * });
 */
export function useDelInsMLicenseByBizIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DelInsMLicenseByBizIdMutation, DelInsMLicenseByBizIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DelInsMLicenseByBizIdMutation, DelInsMLicenseByBizIdMutationVariables>(
    DelInsMLicenseByBizIdDocument,
    options
  );
}
export type DelInsMLicenseByBizIdMutationHookResult = ReturnType<typeof useDelInsMLicenseByBizIdMutation>;
export type DelInsMLicenseByBizIdMutationResult = Apollo.MutationResult<DelInsMLicenseByBizIdMutation>;
export type DelInsMLicenseByBizIdMutationOptions = Apollo.BaseMutationOptions<
  DelInsMLicenseByBizIdMutation,
  DelInsMLicenseByBizIdMutationVariables
>;
export const DelInsMSkillCheckByBizIdDocument = gql`
  mutation DelInsMSkillCheckByBizId(
    $biz_id: Int!
    $s3_bucket: String
    $section_filepath: String
    $checklist_filepath: String
  ) {
    delInsMSkillCheckByBizId(
      biz_id: $biz_id
      s3_bucket: $s3_bucket
      section_filepath: $section_filepath
      checklist_filepath: $checklist_filepath
    ) {
      ErrorCode
      resultRows
      __typename
    }
  }
`;
export type DelInsMSkillCheckByBizIdMutationFn = Apollo.MutationFunction<
  DelInsMSkillCheckByBizIdMutation,
  DelInsMSkillCheckByBizIdMutationVariables
>;

/**
 * __useDelInsMSkillCheckByBizIdMutation__
 *
 * To run a mutation, you first call `useDelInsMSkillCheckByBizIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelInsMSkillCheckByBizIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delInsMSkillCheckByBizIdMutation, { data, loading, error }] = useDelInsMSkillCheckByBizIdMutation({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      s3_bucket: // value for 's3_bucket'
 *      section_filepath: // value for 'section_filepath'
 *      checklist_filepath: // value for 'checklist_filepath'
 *   },
 * });
 */
export function useDelInsMSkillCheckByBizIdMutation(
  baseOptions?: Apollo.MutationHookOptions<DelInsMSkillCheckByBizIdMutation, DelInsMSkillCheckByBizIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DelInsMSkillCheckByBizIdMutation, DelInsMSkillCheckByBizIdMutationVariables>(
    DelInsMSkillCheckByBizIdDocument,
    options
  );
}
export type DelInsMSkillCheckByBizIdMutationHookResult = ReturnType<typeof useDelInsMSkillCheckByBizIdMutation>;
export type DelInsMSkillCheckByBizIdMutationResult = Apollo.MutationResult<DelInsMSkillCheckByBizIdMutation>;
export type DelInsMSkillCheckByBizIdMutationOptions = Apollo.BaseMutationOptions<
  DelInsMSkillCheckByBizIdMutation,
  DelInsMSkillCheckByBizIdMutationVariables
>;
export const GetMSystemTypeDocument = gql`
  query GetMSystemType {
    getMSystemType {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMSystemTypeQuery__
 *
 * To run a query within a React component, call `useGetMSystemTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSystemTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSystemTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMSystemTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMSystemTypeQuery, GetMSystemTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSystemTypeQuery, GetMSystemTypeQueryVariables>(GetMSystemTypeDocument, options);
}
export function useGetMSystemTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSystemTypeQuery, GetMSystemTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSystemTypeQuery, GetMSystemTypeQueryVariables>(GetMSystemTypeDocument, options);
}
export type GetMSystemTypeQueryHookResult = ReturnType<typeof useGetMSystemTypeQuery>;
export type GetMSystemTypeLazyQueryHookResult = ReturnType<typeof useGetMSystemTypeLazyQuery>;
export type GetMSystemTypeQueryResult = Apollo.QueryResult<GetMSystemTypeQuery, GetMSystemTypeQueryVariables>;
export const GetMSystemTypeByIdDocument = gql`
  query GetMSystemTypeById($mid: Int!) {
    getMSystemTypeById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMSystemTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetMSystemTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSystemTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSystemTypeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMSystemTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMSystemTypeByIdQuery, GetMSystemTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSystemTypeByIdQuery, GetMSystemTypeByIdQueryVariables>(
    GetMSystemTypeByIdDocument,
    options
  );
}
export function useGetMSystemTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSystemTypeByIdQuery, GetMSystemTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSystemTypeByIdQuery, GetMSystemTypeByIdQueryVariables>(
    GetMSystemTypeByIdDocument,
    options
  );
}
export type GetMSystemTypeByIdQueryHookResult = ReturnType<typeof useGetMSystemTypeByIdQuery>;
export type GetMSystemTypeByIdLazyQueryHookResult = ReturnType<typeof useGetMSystemTypeByIdLazyQuery>;
export type GetMSystemTypeByIdQueryResult = Apollo.QueryResult<
  GetMSystemTypeByIdQuery,
  GetMSystemTypeByIdQueryVariables
>;
export const GetMNotifyTplDocument = gql`
  query GetMNotifyTpl {
    getMNotifyTpl {
      id
      created_date
      sys_id
      biz_id
      brand_id
      title
      body
      __typename
    }
  }
`;

/**
 * __useGetMNotifyTplQuery__
 *
 * To run a query within a React component, call `useGetMNotifyTplQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMNotifyTplQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMNotifyTplQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMNotifyTplQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMNotifyTplQuery, GetMNotifyTplQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMNotifyTplQuery, GetMNotifyTplQueryVariables>(GetMNotifyTplDocument, options);
}
export function useGetMNotifyTplLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMNotifyTplQuery, GetMNotifyTplQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMNotifyTplQuery, GetMNotifyTplQueryVariables>(GetMNotifyTplDocument, options);
}
export type GetMNotifyTplQueryHookResult = ReturnType<typeof useGetMNotifyTplQuery>;
export type GetMNotifyTplLazyQueryHookResult = ReturnType<typeof useGetMNotifyTplLazyQuery>;
export type GetMNotifyTplQueryResult = Apollo.QueryResult<GetMNotifyTplQuery, GetMNotifyTplQueryVariables>;
export const GetMNotifyTplByIdDocument = gql`
  query GetMNotifyTplById($mid: Int!) {
    getMNotifyTplById(mid: $mid) {
      id
      created_date
      sys_id
      biz_id
      brand_id
      title
      body
      __typename
    }
  }
`;

/**
 * __useGetMNotifyTplByIdQuery__
 *
 * To run a query within a React component, call `useGetMNotifyTplByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMNotifyTplByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMNotifyTplByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMNotifyTplByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMNotifyTplByIdQuery, GetMNotifyTplByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMNotifyTplByIdQuery, GetMNotifyTplByIdQueryVariables>(GetMNotifyTplByIdDocument, options);
}
export function useGetMNotifyTplByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMNotifyTplByIdQuery, GetMNotifyTplByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMNotifyTplByIdQuery, GetMNotifyTplByIdQueryVariables>(
    GetMNotifyTplByIdDocument,
    options
  );
}
export type GetMNotifyTplByIdQueryHookResult = ReturnType<typeof useGetMNotifyTplByIdQuery>;
export type GetMNotifyTplByIdLazyQueryHookResult = ReturnType<typeof useGetMNotifyTplByIdLazyQuery>;
export type GetMNotifyTplByIdQueryResult = Apollo.QueryResult<GetMNotifyTplByIdQuery, GetMNotifyTplByIdQueryVariables>;
export const GetMNotifyTplBySysIdDocument = gql`
  query GetMNotifyTplBySysId($sid: Int!) {
    getMNotifyTplBySysId(sid: $sid) {
      id
      created_date
      sys_id
      biz_id
      brand_id
      title
      body
      __typename
    }
  }
`;

/**
 * __useGetMNotifyTplBySysIdQuery__
 *
 * To run a query within a React component, call `useGetMNotifyTplBySysIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMNotifyTplBySysIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMNotifyTplBySysIdQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useGetMNotifyTplBySysIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMNotifyTplBySysIdQuery, GetMNotifyTplBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMNotifyTplBySysIdQuery, GetMNotifyTplBySysIdQueryVariables>(
    GetMNotifyTplBySysIdDocument,
    options
  );
}
export function useGetMNotifyTplBySysIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMNotifyTplBySysIdQuery, GetMNotifyTplBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMNotifyTplBySysIdQuery, GetMNotifyTplBySysIdQueryVariables>(
    GetMNotifyTplBySysIdDocument,
    options
  );
}
export type GetMNotifyTplBySysIdQueryHookResult = ReturnType<typeof useGetMNotifyTplBySysIdQuery>;
export type GetMNotifyTplBySysIdLazyQueryHookResult = ReturnType<typeof useGetMNotifyTplBySysIdLazyQuery>;
export type GetMNotifyTplBySysIdQueryResult = Apollo.QueryResult<
  GetMNotifyTplBySysIdQuery,
  GetMNotifyTplBySysIdQueryVariables
>;
export const GetMTpmemTypeDocument = gql`
  query GetMTpmemType {
    getMTpmemType {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMTpmemTypeQuery__
 *
 * To run a query within a React component, call `useGetMTpmemTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTpmemTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTpmemTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMTpmemTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMTpmemTypeQuery, GetMTpmemTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTpmemTypeQuery, GetMTpmemTypeQueryVariables>(GetMTpmemTypeDocument, options);
}
export function useGetMTpmemTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMTpmemTypeQuery, GetMTpmemTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTpmemTypeQuery, GetMTpmemTypeQueryVariables>(GetMTpmemTypeDocument, options);
}
export type GetMTpmemTypeQueryHookResult = ReturnType<typeof useGetMTpmemTypeQuery>;
export type GetMTpmemTypeLazyQueryHookResult = ReturnType<typeof useGetMTpmemTypeLazyQuery>;
export type GetMTpmemTypeQueryResult = Apollo.QueryResult<GetMTpmemTypeQuery, GetMTpmemTypeQueryVariables>;
export const GetMTpmemTypeByIdDocument = gql`
  query GetMTpmemTypeById($mid: Int!) {
    getMTpmemTypeById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMTpmemTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetMTpmemTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTpmemTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTpmemTypeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMTpmemTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMTpmemTypeByIdQuery, GetMTpmemTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTpmemTypeByIdQuery, GetMTpmemTypeByIdQueryVariables>(GetMTpmemTypeByIdDocument, options);
}
export function useGetMTpmemTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMTpmemTypeByIdQuery, GetMTpmemTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTpmemTypeByIdQuery, GetMTpmemTypeByIdQueryVariables>(
    GetMTpmemTypeByIdDocument,
    options
  );
}
export type GetMTpmemTypeByIdQueryHookResult = ReturnType<typeof useGetMTpmemTypeByIdQuery>;
export type GetMTpmemTypeByIdLazyQueryHookResult = ReturnType<typeof useGetMTpmemTypeByIdLazyQuery>;
export type GetMTpmemTypeByIdQueryResult = Apollo.QueryResult<GetMTpmemTypeByIdQuery, GetMTpmemTypeByIdQueryVariables>;
export const GetMBusinessDocument = gql`
  query GetMBusiness {
    getMBusiness {
      id
      name
      prefix
      icon
      __typename
    }
  }
`;

/**
 * __useGetMBusinessQuery__
 *
 * To run a query within a React component, call `useGetMBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBusinessQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBusinessQuery, GetMBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessQuery, GetMBusinessQueryVariables>(GetMBusinessDocument, options);
}
export function useGetMBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessQuery, GetMBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessQuery, GetMBusinessQueryVariables>(GetMBusinessDocument, options);
}
export type GetMBusinessQueryHookResult = ReturnType<typeof useGetMBusinessQuery>;
export type GetMBusinessLazyQueryHookResult = ReturnType<typeof useGetMBusinessLazyQuery>;
export type GetMBusinessQueryResult = Apollo.QueryResult<GetMBusinessQuery, GetMBusinessQueryVariables>;
export const GetMBusinessByIdDocument = gql`
  query GetMBusinessById($mid: Int!) {
    getMBusinessById(mid: $mid) {
      id
      name
      prefix
      icon
      __typename
    }
  }
`;

/**
 * __useGetMBusinessByIdQuery__
 *
 * To run a query within a React component, call `useGetMBusinessByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMBusinessByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBusinessByIdQuery, GetMBusinessByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessByIdQuery, GetMBusinessByIdQueryVariables>(GetMBusinessByIdDocument, options);
}
export function useGetMBusinessByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessByIdQuery, GetMBusinessByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessByIdQuery, GetMBusinessByIdQueryVariables>(GetMBusinessByIdDocument, options);
}
export type GetMBusinessByIdQueryHookResult = ReturnType<typeof useGetMBusinessByIdQuery>;
export type GetMBusinessByIdLazyQueryHookResult = ReturnType<typeof useGetMBusinessByIdLazyQuery>;
export type GetMBusinessByIdQueryResult = Apollo.QueryResult<GetMBusinessByIdQuery, GetMBusinessByIdQueryVariables>;
export const GetMBusinessByPrefixDocument = gql`
  query GetMBusinessByPrefix($mpfx: String!) {
    getMBusinessByPrefix(mpfx: $mpfx) {
      id
      name
      prefix
      icon
      __typename
    }
  }
`;

/**
 * __useGetMBusinessByPrefixQuery__
 *
 * To run a query within a React component, call `useGetMBusinessByPrefixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessByPrefixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessByPrefixQuery({
 *   variables: {
 *      mpfx: // value for 'mpfx'
 *   },
 * });
 */
export function useGetMBusinessByPrefixQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBusinessByPrefixQuery, GetMBusinessByPrefixQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessByPrefixQuery, GetMBusinessByPrefixQueryVariables>(
    GetMBusinessByPrefixDocument,
    options
  );
}
export function useGetMBusinessByPrefixLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessByPrefixQuery, GetMBusinessByPrefixQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessByPrefixQuery, GetMBusinessByPrefixQueryVariables>(
    GetMBusinessByPrefixDocument,
    options
  );
}
export type GetMBusinessByPrefixQueryHookResult = ReturnType<typeof useGetMBusinessByPrefixQuery>;
export type GetMBusinessByPrefixLazyQueryHookResult = ReturnType<typeof useGetMBusinessByPrefixLazyQuery>;
export type GetMBusinessByPrefixQueryResult = Apollo.QueryResult<
  GetMBusinessByPrefixQuery,
  GetMBusinessByPrefixQueryVariables
>;
export const GetMBusinessBrandDocument = gql`
  query GetMBusinessBrand {
    getMBusinessBrand {
      id
      name
      prefix
      icon
      brand {
        brand_id
        biz_id
        brand_name
        brand_prefix
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMBusinessBrandQuery__
 *
 * To run a query within a React component, call `useGetMBusinessBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessBrandQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBusinessBrandQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBusinessBrandQuery, GetMBusinessBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessBrandQuery, GetMBusinessBrandQueryVariables>(GetMBusinessBrandDocument, options);
}
export function useGetMBusinessBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessBrandQuery, GetMBusinessBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessBrandQuery, GetMBusinessBrandQueryVariables>(
    GetMBusinessBrandDocument,
    options
  );
}
export type GetMBusinessBrandQueryHookResult = ReturnType<typeof useGetMBusinessBrandQuery>;
export type GetMBusinessBrandLazyQueryHookResult = ReturnType<typeof useGetMBusinessBrandLazyQuery>;
export type GetMBusinessBrandQueryResult = Apollo.QueryResult<GetMBusinessBrandQuery, GetMBusinessBrandQueryVariables>;
export const GetMBusinessBrandByIdDocument = gql`
  query GetMBusinessBrandById($id: Int!) {
    getMBusinessBrandById(id: $id) {
      id
      name
      prefix
      icon
      brand {
        brand_id
        biz_id
        brand_name
        brand_prefix
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMBusinessBrandByIdQuery__
 *
 * To run a query within a React component, call `useGetMBusinessBrandByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessBrandByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessBrandByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMBusinessBrandByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBusinessBrandByIdQuery, GetMBusinessBrandByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessBrandByIdQuery, GetMBusinessBrandByIdQueryVariables>(
    GetMBusinessBrandByIdDocument,
    options
  );
}
export function useGetMBusinessBrandByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessBrandByIdQuery, GetMBusinessBrandByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessBrandByIdQuery, GetMBusinessBrandByIdQueryVariables>(
    GetMBusinessBrandByIdDocument,
    options
  );
}
export type GetMBusinessBrandByIdQueryHookResult = ReturnType<typeof useGetMBusinessBrandByIdQuery>;
export type GetMBusinessBrandByIdLazyQueryHookResult = ReturnType<typeof useGetMBusinessBrandByIdLazyQuery>;
export type GetMBusinessBrandByIdQueryResult = Apollo.QueryResult<
  GetMBusinessBrandByIdQuery,
  GetMBusinessBrandByIdQueryVariables
>;
export const GetMBusinessBrandByPrefixDocument = gql`
  query GetMBusinessBrandByPrefix($prefix: String!) {
    getMBusinessBrandByPrefix(prefix: $prefix) {
      id
      name
      prefix
      icon
      brand {
        brand_id
        biz_id
        brand_name
        brand_prefix
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMBusinessBrandByPrefixQuery__
 *
 * To run a query within a React component, call `useGetMBusinessBrandByPrefixQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessBrandByPrefixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessBrandByPrefixQuery({
 *   variables: {
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useGetMBusinessBrandByPrefixQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBusinessBrandByPrefixQuery, GetMBusinessBrandByPrefixQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessBrandByPrefixQuery, GetMBusinessBrandByPrefixQueryVariables>(
    GetMBusinessBrandByPrefixDocument,
    options
  );
}
export function useGetMBusinessBrandByPrefixLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessBrandByPrefixQuery, GetMBusinessBrandByPrefixQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessBrandByPrefixQuery, GetMBusinessBrandByPrefixQueryVariables>(
    GetMBusinessBrandByPrefixDocument,
    options
  );
}
export type GetMBusinessBrandByPrefixQueryHookResult = ReturnType<typeof useGetMBusinessBrandByPrefixQuery>;
export type GetMBusinessBrandByPrefixLazyQueryHookResult = ReturnType<typeof useGetMBusinessBrandByPrefixLazyQuery>;
export type GetMBusinessBrandByPrefixQueryResult = Apollo.QueryResult<
  GetMBusinessBrandByPrefixQuery,
  GetMBusinessBrandByPrefixQueryVariables
>;
export const GetMBusinessDataByIdDocument = gql`
  query GetMBusinessDataById($id: Int!) {
    getMBusinessDataById(id: $id) {
      id
      name
      prefix
      icon
      brand {
        brand_id
        biz_id
        brand_name
        brand_prefix
        __typename
      }
      license {
        license_id
        biz_id
        license_name
        __typename
      }
      skillCheck {
        biz_id
        section_no
        section_name
        check_no
        check_item
        bt_rank_name
        __typename
      }
      existsStore
      __typename
    }
  }
`;

/**
 * __useGetMBusinessDataByIdQuery__
 *
 * To run a query within a React component, call `useGetMBusinessDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBusinessDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBusinessDataByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMBusinessDataByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBusinessDataByIdQuery, GetMBusinessDataByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBusinessDataByIdQuery, GetMBusinessDataByIdQueryVariables>(
    GetMBusinessDataByIdDocument,
    options
  );
}
export function useGetMBusinessDataByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBusinessDataByIdQuery, GetMBusinessDataByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBusinessDataByIdQuery, GetMBusinessDataByIdQueryVariables>(
    GetMBusinessDataByIdDocument,
    options
  );
}
export type GetMBusinessDataByIdQueryHookResult = ReturnType<typeof useGetMBusinessDataByIdQuery>;
export type GetMBusinessDataByIdLazyQueryHookResult = ReturnType<typeof useGetMBusinessDataByIdLazyQuery>;
export type GetMBusinessDataByIdQueryResult = Apollo.QueryResult<
  GetMBusinessDataByIdQuery,
  GetMBusinessDataByIdQueryVariables
>;
export const GetMBrandDocument = gql`
  query GetMBrand {
    getMBrand {
      id
      biz_id
      name
      prefix
      __typename
    }
  }
`;

/**
 * __useGetMBrandQuery__
 *
 * To run a query within a React component, call `useGetMBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBrandQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBrandQuery(baseOptions?: Apollo.QueryHookOptions<GetMBrandQuery, GetMBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBrandQuery, GetMBrandQueryVariables>(GetMBrandDocument, options);
}
export function useGetMBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBrandQuery, GetMBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBrandQuery, GetMBrandQueryVariables>(GetMBrandDocument, options);
}
export type GetMBrandQueryHookResult = ReturnType<typeof useGetMBrandQuery>;
export type GetMBrandLazyQueryHookResult = ReturnType<typeof useGetMBrandLazyQuery>;
export type GetMBrandQueryResult = Apollo.QueryResult<GetMBrandQuery, GetMBrandQueryVariables>;
export const GetMBrandByIdDocument = gql`
  query GetMBrandById($mid: Int!) {
    getMBrandById(mid: $mid) {
      id
      biz_id
      name
      prefix
      __typename
    }
  }
`;

/**
 * __useGetMBrandByIdQuery__
 *
 * To run a query within a React component, call `useGetMBrandByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBrandByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBrandByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMBrandByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBrandByIdQuery, GetMBrandByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBrandByIdQuery, GetMBrandByIdQueryVariables>(GetMBrandByIdDocument, options);
}
export function useGetMBrandByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBrandByIdQuery, GetMBrandByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBrandByIdQuery, GetMBrandByIdQueryVariables>(GetMBrandByIdDocument, options);
}
export type GetMBrandByIdQueryHookResult = ReturnType<typeof useGetMBrandByIdQuery>;
export type GetMBrandByIdLazyQueryHookResult = ReturnType<typeof useGetMBrandByIdLazyQuery>;
export type GetMBrandByIdQueryResult = Apollo.QueryResult<GetMBrandByIdQuery, GetMBrandByIdQueryVariables>;
export const GetMBrandByBizIdDocument = gql`
  query GetMBrandByBizId($bid: Int!) {
    getMBrandByBizId(bid: $bid) {
      id
      biz_id
      name
      prefix
      __typename
    }
  }
`;

/**
 * __useGetMBrandByBizIdQuery__
 *
 * To run a query within a React component, call `useGetMBrandByBizIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBrandByBizIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBrandByBizIdQuery({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useGetMBrandByBizIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBrandByBizIdQuery, GetMBrandByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBrandByBizIdQuery, GetMBrandByBizIdQueryVariables>(GetMBrandByBizIdDocument, options);
}
export function useGetMBrandByBizIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBrandByBizIdQuery, GetMBrandByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBrandByBizIdQuery, GetMBrandByBizIdQueryVariables>(GetMBrandByBizIdDocument, options);
}
export type GetMBrandByBizIdQueryHookResult = ReturnType<typeof useGetMBrandByBizIdQuery>;
export type GetMBrandByBizIdLazyQueryHookResult = ReturnType<typeof useGetMBrandByBizIdLazyQuery>;
export type GetMBrandByBizIdQueryResult = Apollo.QueryResult<GetMBrandByBizIdQuery, GetMBrandByBizIdQueryVariables>;
export const GetMBankCodeDocument = gql`
  query GetMBankCode {
    getMBankCode {
      code
      name
      zeus_web
      __typename
    }
  }
`;

/**
 * __useGetMBankCodeQuery__
 *
 * To run a query within a React component, call `useGetMBankCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBankCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBankCodeQuery, GetMBankCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankCodeQuery, GetMBankCodeQueryVariables>(GetMBankCodeDocument, options);
}
export function useGetMBankCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankCodeQuery, GetMBankCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankCodeQuery, GetMBankCodeQueryVariables>(GetMBankCodeDocument, options);
}
export type GetMBankCodeQueryHookResult = ReturnType<typeof useGetMBankCodeQuery>;
export type GetMBankCodeLazyQueryHookResult = ReturnType<typeof useGetMBankCodeLazyQuery>;
export type GetMBankCodeQueryResult = Apollo.QueryResult<GetMBankCodeQuery, GetMBankCodeQueryVariables>;
export const GetMBankCodeByCodeDocument = gql`
  query GetMBankCodeByCode($mcode: String!) {
    getMBankCodeByCode(mcode: $mcode) {
      code
      name
      zeus_web
      __typename
    }
  }
`;

/**
 * __useGetMBankCodeByCodeQuery__
 *
 * To run a query within a React component, call `useGetMBankCodeByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankCodeByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankCodeByCodeQuery({
 *   variables: {
 *      mcode: // value for 'mcode'
 *   },
 * });
 */
export function useGetMBankCodeByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBankCodeByCodeQuery, GetMBankCodeByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankCodeByCodeQuery, GetMBankCodeByCodeQueryVariables>(
    GetMBankCodeByCodeDocument,
    options
  );
}
export function useGetMBankCodeByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankCodeByCodeQuery, GetMBankCodeByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankCodeByCodeQuery, GetMBankCodeByCodeQueryVariables>(
    GetMBankCodeByCodeDocument,
    options
  );
}
export type GetMBankCodeByCodeQueryHookResult = ReturnType<typeof useGetMBankCodeByCodeQuery>;
export type GetMBankCodeByCodeLazyQueryHookResult = ReturnType<typeof useGetMBankCodeByCodeLazyQuery>;
export type GetMBankCodeByCodeQueryResult = Apollo.QueryResult<
  GetMBankCodeByCodeQuery,
  GetMBankCodeByCodeQueryVariables
>;
export const GetMBankCodeBranchDocument = gql`
  query GetMBankCodeBranch {
    getMBankCodeBranch {
      code
      bank_name
      zeus_web
      branch {
        bank_code
        branch_code
        branch_name
        sequence
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMBankCodeBranchQuery__
 *
 * To run a query within a React component, call `useGetMBankCodeBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankCodeBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankCodeBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBankCodeBranchQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBankCodeBranchQuery, GetMBankCodeBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankCodeBranchQuery, GetMBankCodeBranchQueryVariables>(
    GetMBankCodeBranchDocument,
    options
  );
}
export function useGetMBankCodeBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankCodeBranchQuery, GetMBankCodeBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankCodeBranchQuery, GetMBankCodeBranchQueryVariables>(
    GetMBankCodeBranchDocument,
    options
  );
}
export type GetMBankCodeBranchQueryHookResult = ReturnType<typeof useGetMBankCodeBranchQuery>;
export type GetMBankCodeBranchLazyQueryHookResult = ReturnType<typeof useGetMBankCodeBranchLazyQuery>;
export type GetMBankCodeBranchQueryResult = Apollo.QueryResult<
  GetMBankCodeBranchQuery,
  GetMBankCodeBranchQueryVariables
>;
export const GetMBankCodeBranchByCodeDocument = gql`
  query GetMBankCodeBranchByCode($code: String!) {
    getMBankCodeBranchByCode(code: $code) {
      code
      bank_name
      zeus_web
      branch {
        bank_code
        branch_code
        branch_name
        sequence
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMBankCodeBranchByCodeQuery__
 *
 * To run a query within a React component, call `useGetMBankCodeBranchByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankCodeBranchByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankCodeBranchByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetMBankCodeBranchByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBankCodeBranchByCodeQuery, GetMBankCodeBranchByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankCodeBranchByCodeQuery, GetMBankCodeBranchByCodeQueryVariables>(
    GetMBankCodeBranchByCodeDocument,
    options
  );
}
export function useGetMBankCodeBranchByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankCodeBranchByCodeQuery, GetMBankCodeBranchByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankCodeBranchByCodeQuery, GetMBankCodeBranchByCodeQueryVariables>(
    GetMBankCodeBranchByCodeDocument,
    options
  );
}
export type GetMBankCodeBranchByCodeQueryHookResult = ReturnType<typeof useGetMBankCodeBranchByCodeQuery>;
export type GetMBankCodeBranchByCodeLazyQueryHookResult = ReturnType<typeof useGetMBankCodeBranchByCodeLazyQuery>;
export type GetMBankCodeBranchByCodeQueryResult = Apollo.QueryResult<
  GetMBankCodeBranchByCodeQuery,
  GetMBankCodeBranchByCodeQueryVariables
>;
export const GetMBankBranchDocument = gql`
  query GetMBankBranch {
    getMBankBranch {
      code
      branch
      name
      sequence
      __typename
    }
  }
`;

/**
 * __useGetMBankBranchQuery__
 *
 * To run a query within a React component, call `useGetMBankBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBankBranchQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBankBranchQuery, GetMBankBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankBranchQuery, GetMBankBranchQueryVariables>(GetMBankBranchDocument, options);
}
export function useGetMBankBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankBranchQuery, GetMBankBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankBranchQuery, GetMBankBranchQueryVariables>(GetMBankBranchDocument, options);
}
export type GetMBankBranchQueryHookResult = ReturnType<typeof useGetMBankBranchQuery>;
export type GetMBankBranchLazyQueryHookResult = ReturnType<typeof useGetMBankBranchLazyQuery>;
export type GetMBankBranchQueryResult = Apollo.QueryResult<GetMBankBranchQuery, GetMBankBranchQueryVariables>;
export const GetMBankBranchByCodeDocument = gql`
  query GetMBankBranchByCode($mcode: String!) {
    getMBankBranchByCode(mcode: $mcode) {
      code
      branch
      name
      sequence
      __typename
    }
  }
`;

/**
 * __useGetMBankBranchByCodeQuery__
 *
 * To run a query within a React component, call `useGetMBankBranchByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankBranchByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankBranchByCodeQuery({
 *   variables: {
 *      mcode: // value for 'mcode'
 *   },
 * });
 */
export function useGetMBankBranchByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBankBranchByCodeQuery, GetMBankBranchByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankBranchByCodeQuery, GetMBankBranchByCodeQueryVariables>(
    GetMBankBranchByCodeDocument,
    options
  );
}
export function useGetMBankBranchByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankBranchByCodeQuery, GetMBankBranchByCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankBranchByCodeQuery, GetMBankBranchByCodeQueryVariables>(
    GetMBankBranchByCodeDocument,
    options
  );
}
export type GetMBankBranchByCodeQueryHookResult = ReturnType<typeof useGetMBankBranchByCodeQuery>;
export type GetMBankBranchByCodeLazyQueryHookResult = ReturnType<typeof useGetMBankBranchByCodeLazyQuery>;
export type GetMBankBranchByCodeQueryResult = Apollo.QueryResult<
  GetMBankBranchByCodeQuery,
  GetMBankBranchByCodeQueryVariables
>;
export const GetMBankBranchByCodeBranchDocument = gql`
  query GetMBankBranchByCodeBranch($mcode: String!, $mbrc: String!) {
    getMBankBranchByCodeBranch(mcode: $mcode, mbrc: $mbrc) {
      code
      branch
      name
      sequence
      __typename
    }
  }
`;

/**
 * __useGetMBankBranchByCodeBranchQuery__
 *
 * To run a query within a React component, call `useGetMBankBranchByCodeBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBankBranchByCodeBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBankBranchByCodeBranchQuery({
 *   variables: {
 *      mcode: // value for 'mcode'
 *      mbrc: // value for 'mbrc'
 *   },
 * });
 */
export function useGetMBankBranchByCodeBranchQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBankBranchByCodeBranchQuery, GetMBankBranchByCodeBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBankBranchByCodeBranchQuery, GetMBankBranchByCodeBranchQueryVariables>(
    GetMBankBranchByCodeBranchDocument,
    options
  );
}
export function useGetMBankBranchByCodeBranchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBankBranchByCodeBranchQuery, GetMBankBranchByCodeBranchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBankBranchByCodeBranchQuery, GetMBankBranchByCodeBranchQueryVariables>(
    GetMBankBranchByCodeBranchDocument,
    options
  );
}
export type GetMBankBranchByCodeBranchQueryHookResult = ReturnType<typeof useGetMBankBranchByCodeBranchQuery>;
export type GetMBankBranchByCodeBranchLazyQueryHookResult = ReturnType<typeof useGetMBankBranchByCodeBranchLazyQuery>;
export type GetMBankBranchByCodeBranchQueryResult = Apollo.QueryResult<
  GetMBankBranchByCodeBranchQuery,
  GetMBankBranchByCodeBranchQueryVariables
>;
export const GetMTosDocument = gql`
  query GetMTos {
    getMTos {
      sys_id
      kind
      body
      __typename
    }
  }
`;

/**
 * __useGetMTosQuery__
 *
 * To run a query within a React component, call `useGetMTosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMTosQuery(baseOptions?: Apollo.QueryHookOptions<GetMTosQuery, GetMTosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTosQuery, GetMTosQueryVariables>(GetMTosDocument, options);
}
export function useGetMTosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMTosQuery, GetMTosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTosQuery, GetMTosQueryVariables>(GetMTosDocument, options);
}
export type GetMTosQueryHookResult = ReturnType<typeof useGetMTosQuery>;
export type GetMTosLazyQueryHookResult = ReturnType<typeof useGetMTosLazyQuery>;
export type GetMTosQueryResult = Apollo.QueryResult<GetMTosQuery, GetMTosQueryVariables>;
export const GetMTosBySysIdDocument = gql`
  query GetMTosBySysId($sid: Int!) {
    getMTosBySysId(sid: $sid) {
      sys_id
      kind
      body
      __typename
    }
  }
`;

/**
 * __useGetMTosBySysIdQuery__
 *
 * To run a query within a React component, call `useGetMTosBySysIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTosBySysIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTosBySysIdQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useGetMTosBySysIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMTosBySysIdQuery, GetMTosBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTosBySysIdQuery, GetMTosBySysIdQueryVariables>(GetMTosBySysIdDocument, options);
}
export function useGetMTosBySysIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMTosBySysIdQuery, GetMTosBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTosBySysIdQuery, GetMTosBySysIdQueryVariables>(GetMTosBySysIdDocument, options);
}
export type GetMTosBySysIdQueryHookResult = ReturnType<typeof useGetMTosBySysIdQuery>;
export type GetMTosBySysIdLazyQueryHookResult = ReturnType<typeof useGetMTosBySysIdLazyQuery>;
export type GetMTosBySysIdQueryResult = Apollo.QueryResult<GetMTosBySysIdQuery, GetMTosBySysIdQueryVariables>;
export const GetMTosByKindDocument = gql`
  query GetMTosByKind($kind: Int!) {
    getMTosByKind(kind: $kind) {
      sys_id
      kind
      body
      __typename
    }
  }
`;

/**
 * __useGetMTosByKindQuery__
 *
 * To run a query within a React component, call `useGetMTosByKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTosByKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTosByKindQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useGetMTosByKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetMTosByKindQuery, GetMTosByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTosByKindQuery, GetMTosByKindQueryVariables>(GetMTosByKindDocument, options);
}
export function useGetMTosByKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMTosByKindQuery, GetMTosByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTosByKindQuery, GetMTosByKindQueryVariables>(GetMTosByKindDocument, options);
}
export type GetMTosByKindQueryHookResult = ReturnType<typeof useGetMTosByKindQuery>;
export type GetMTosByKindLazyQueryHookResult = ReturnType<typeof useGetMTosByKindLazyQuery>;
export type GetMTosByKindQueryResult = Apollo.QueryResult<GetMTosByKindQuery, GetMTosByKindQueryVariables>;
export const GetMTosBySysIdKindDocument = gql`
  query GetMTosBySysIdKind($sys_id: Int!, $kind: Int!) {
    getMTosBySysIdKind(sys_id: $sys_id, kind: $kind) {
      sys_id
      kind
      body
      __typename
    }
  }
`;

/**
 * __useGetMTosBySysIdKindQuery__
 *
 * To run a query within a React component, call `useGetMTosBySysIdKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMTosBySysIdKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMTosBySysIdKindQuery({
 *   variables: {
 *      sys_id: // value for 'sys_id'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useGetMTosBySysIdKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetMTosBySysIdKindQuery, GetMTosBySysIdKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMTosBySysIdKindQuery, GetMTosBySysIdKindQueryVariables>(
    GetMTosBySysIdKindDocument,
    options
  );
}
export function useGetMTosBySysIdKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMTosBySysIdKindQuery, GetMTosBySysIdKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMTosBySysIdKindQuery, GetMTosBySysIdKindQueryVariables>(
    GetMTosBySysIdKindDocument,
    options
  );
}
export type GetMTosBySysIdKindQueryHookResult = ReturnType<typeof useGetMTosBySysIdKindQuery>;
export type GetMTosBySysIdKindLazyQueryHookResult = ReturnType<typeof useGetMTosBySysIdKindLazyQuery>;
export type GetMTosBySysIdKindQueryResult = Apollo.QueryResult<
  GetMTosBySysIdKindQuery,
  GetMTosBySysIdKindQueryVariables
>;
export const GetMAreaDocument = gql`
  query GetMArea {
    getMArea {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMAreaQuery__
 *
 * To run a query within a React component, call `useGetMAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAreaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMAreaQuery(baseOptions?: Apollo.QueryHookOptions<GetMAreaQuery, GetMAreaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAreaQuery, GetMAreaQueryVariables>(GetMAreaDocument, options);
}
export function useGetMAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMAreaQuery, GetMAreaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAreaQuery, GetMAreaQueryVariables>(GetMAreaDocument, options);
}
export type GetMAreaQueryHookResult = ReturnType<typeof useGetMAreaQuery>;
export type GetMAreaLazyQueryHookResult = ReturnType<typeof useGetMAreaLazyQuery>;
export type GetMAreaQueryResult = Apollo.QueryResult<GetMAreaQuery, GetMAreaQueryVariables>;
export const GetMAreaByIdDocument = gql`
  query GetMAreaById($mid: Int!) {
    getMAreaById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMAreaByIdQuery__
 *
 * To run a query within a React component, call `useGetMAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAreaByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMAreaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAreaByIdQuery, GetMAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAreaByIdQuery, GetMAreaByIdQueryVariables>(GetMAreaByIdDocument, options);
}
export function useGetMAreaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAreaByIdQuery, GetMAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAreaByIdQuery, GetMAreaByIdQueryVariables>(GetMAreaByIdDocument, options);
}
export type GetMAreaByIdQueryHookResult = ReturnType<typeof useGetMAreaByIdQuery>;
export type GetMAreaByIdLazyQueryHookResult = ReturnType<typeof useGetMAreaByIdLazyQuery>;
export type GetMAreaByIdQueryResult = Apollo.QueryResult<GetMAreaByIdQuery, GetMAreaByIdQueryVariables>;
export const GetMAreaStateDocument = gql`
  query GetMAreaState {
    getMAreaState {
      id
      area_name
      state {
        state_id
        state_name
        state_kana
        min_wage
        area_id
        state_lat
        state_lng
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMAreaStateQuery__
 *
 * To run a query within a React component, call `useGetMAreaStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAreaStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAreaStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMAreaStateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMAreaStateQuery, GetMAreaStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAreaStateQuery, GetMAreaStateQueryVariables>(GetMAreaStateDocument, options);
}
export function useGetMAreaStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAreaStateQuery, GetMAreaStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAreaStateQuery, GetMAreaStateQueryVariables>(GetMAreaStateDocument, options);
}
export type GetMAreaStateQueryHookResult = ReturnType<typeof useGetMAreaStateQuery>;
export type GetMAreaStateLazyQueryHookResult = ReturnType<typeof useGetMAreaStateLazyQuery>;
export type GetMAreaStateQueryResult = Apollo.QueryResult<GetMAreaStateQuery, GetMAreaStateQueryVariables>;
export const GetMAreaStateByIdDocument = gql`
  query GetMAreaStateById($id: Int!) {
    getMAreaStateById(id: $id) {
      id
      area_name
      state {
        state_id
        state_name
        state_kana
        min_wage
        area_id
        state_lat
        state_lng
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMAreaStateByIdQuery__
 *
 * To run a query within a React component, call `useGetMAreaStateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAreaStateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAreaStateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMAreaStateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAreaStateByIdQuery, GetMAreaStateByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAreaStateByIdQuery, GetMAreaStateByIdQueryVariables>(GetMAreaStateByIdDocument, options);
}
export function useGetMAreaStateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAreaStateByIdQuery, GetMAreaStateByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAreaStateByIdQuery, GetMAreaStateByIdQueryVariables>(
    GetMAreaStateByIdDocument,
    options
  );
}
export type GetMAreaStateByIdQueryHookResult = ReturnType<typeof useGetMAreaStateByIdQuery>;
export type GetMAreaStateByIdLazyQueryHookResult = ReturnType<typeof useGetMAreaStateByIdLazyQuery>;
export type GetMAreaStateByIdQueryResult = Apollo.QueryResult<GetMAreaStateByIdQuery, GetMAreaStateByIdQueryVariables>;
export const GetMStateDocument = gql`
  query GetMState {
    getMState {
      id
      name
      kana
      min_wage
      area_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMStateQuery__
 *
 * To run a query within a React component, call `useGetMStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMStateQuery(baseOptions?: Apollo.QueryHookOptions<GetMStateQuery, GetMStateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateQuery, GetMStateQueryVariables>(GetMStateDocument, options);
}
export function useGetMStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateQuery, GetMStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateQuery, GetMStateQueryVariables>(GetMStateDocument, options);
}
export type GetMStateQueryHookResult = ReturnType<typeof useGetMStateQuery>;
export type GetMStateLazyQueryHookResult = ReturnType<typeof useGetMStateLazyQuery>;
export type GetMStateQueryResult = Apollo.QueryResult<GetMStateQuery, GetMStateQueryVariables>;
export const GetMStateByIdDocument = gql`
  query GetMStateById($mid: Int!) {
    getMStateById(mid: $mid) {
      id
      name
      kana
      min_wage
      area_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMStateByIdQuery__
 *
 * To run a query within a React component, call `useGetMStateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMStateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMStateByIdQuery, GetMStateByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateByIdQuery, GetMStateByIdQueryVariables>(GetMStateByIdDocument, options);
}
export function useGetMStateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateByIdQuery, GetMStateByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateByIdQuery, GetMStateByIdQueryVariables>(GetMStateByIdDocument, options);
}
export type GetMStateByIdQueryHookResult = ReturnType<typeof useGetMStateByIdQuery>;
export type GetMStateByIdLazyQueryHookResult = ReturnType<typeof useGetMStateByIdLazyQuery>;
export type GetMStateByIdQueryResult = Apollo.QueryResult<GetMStateByIdQuery, GetMStateByIdQueryVariables>;
export const GetMStateByAreaIdDocument = gql`
  query GetMStateByAreaId($aid: Int!) {
    getMStateByAreaId(aid: $aid) {
      id
      name
      kana
      min_wage
      area_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMStateByAreaIdQuery__
 *
 * To run a query within a React component, call `useGetMStateByAreaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateByAreaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateByAreaIdQuery({
 *   variables: {
 *      aid: // value for 'aid'
 *   },
 * });
 */
export function useGetMStateByAreaIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMStateByAreaIdQuery, GetMStateByAreaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateByAreaIdQuery, GetMStateByAreaIdQueryVariables>(GetMStateByAreaIdDocument, options);
}
export function useGetMStateByAreaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateByAreaIdQuery, GetMStateByAreaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateByAreaIdQuery, GetMStateByAreaIdQueryVariables>(
    GetMStateByAreaIdDocument,
    options
  );
}
export type GetMStateByAreaIdQueryHookResult = ReturnType<typeof useGetMStateByAreaIdQuery>;
export type GetMStateByAreaIdLazyQueryHookResult = ReturnType<typeof useGetMStateByAreaIdLazyQuery>;
export type GetMStateByAreaIdQueryResult = Apollo.QueryResult<GetMStateByAreaIdQuery, GetMStateByAreaIdQueryVariables>;
export const GetMStateCityDocument = gql`
  query GetMStateCity {
    getMStateCity {
      id
      state_name
      state_kana
      min_wage
      area_id
      state_lat
      state_lng
      city {
        city_id
        city_name
        city_kana
        state_id
        city_lat
        city_lng
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMStateCityQuery__
 *
 * To run a query within a React component, call `useGetMStateCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateCityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMStateCityQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMStateCityQuery, GetMStateCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateCityQuery, GetMStateCityQueryVariables>(GetMStateCityDocument, options);
}
export function useGetMStateCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateCityQuery, GetMStateCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateCityQuery, GetMStateCityQueryVariables>(GetMStateCityDocument, options);
}
export type GetMStateCityQueryHookResult = ReturnType<typeof useGetMStateCityQuery>;
export type GetMStateCityLazyQueryHookResult = ReturnType<typeof useGetMStateCityLazyQuery>;
export type GetMStateCityQueryResult = Apollo.QueryResult<GetMStateCityQuery, GetMStateCityQueryVariables>;
export const GetMStateCityByIdDocument = gql`
  query GetMStateCityById($id: Int!) {
    getMStateCityById(id: $id) {
      id
      state_name
      state_kana
      min_wage
      area_id
      state_lat
      state_lng
      city {
        city_id
        city_name
        city_kana
        state_id
        city_lat
        city_lng
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMStateCityByIdQuery__
 *
 * To run a query within a React component, call `useGetMStateCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateCityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMStateCityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMStateCityByIdQuery, GetMStateCityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateCityByIdQuery, GetMStateCityByIdQueryVariables>(GetMStateCityByIdDocument, options);
}
export function useGetMStateCityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateCityByIdQuery, GetMStateCityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateCityByIdQuery, GetMStateCityByIdQueryVariables>(
    GetMStateCityByIdDocument,
    options
  );
}
export type GetMStateCityByIdQueryHookResult = ReturnType<typeof useGetMStateCityByIdQuery>;
export type GetMStateCityByIdLazyQueryHookResult = ReturnType<typeof useGetMStateCityByIdLazyQuery>;
export type GetMStateCityByIdQueryResult = Apollo.QueryResult<GetMStateCityByIdQuery, GetMStateCityByIdQueryVariables>;
export const GetMStateCityByAreaIdDocument = gql`
  query GetMStateCityByAreaId($area_id: Int!) {
    getMStateCityByAreaId(area_id: $area_id) {
      id
      state_name
      state_kana
      min_wage
      area_id
      state_lat
      state_lng
      city {
        city_id
        city_name
        city_kana
        state_id
        city_lat
        city_lng
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMStateCityByAreaIdQuery__
 *
 * To run a query within a React component, call `useGetMStateCityByAreaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMStateCityByAreaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMStateCityByAreaIdQuery({
 *   variables: {
 *      area_id: // value for 'area_id'
 *   },
 * });
 */
export function useGetMStateCityByAreaIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMStateCityByAreaIdQuery, GetMStateCityByAreaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMStateCityByAreaIdQuery, GetMStateCityByAreaIdQueryVariables>(
    GetMStateCityByAreaIdDocument,
    options
  );
}
export function useGetMStateCityByAreaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMStateCityByAreaIdQuery, GetMStateCityByAreaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMStateCityByAreaIdQuery, GetMStateCityByAreaIdQueryVariables>(
    GetMStateCityByAreaIdDocument,
    options
  );
}
export type GetMStateCityByAreaIdQueryHookResult = ReturnType<typeof useGetMStateCityByAreaIdQuery>;
export type GetMStateCityByAreaIdLazyQueryHookResult = ReturnType<typeof useGetMStateCityByAreaIdLazyQuery>;
export type GetMStateCityByAreaIdQueryResult = Apollo.QueryResult<
  GetMStateCityByAreaIdQuery,
  GetMStateCityByAreaIdQueryVariables
>;
export const GetMCityDocument = gql`
  query GetMCity {
    getMCity {
      id
      name
      kana
      state_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMCityQuery__
 *
 * To run a query within a React component, call `useGetMCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMCityQuery(baseOptions?: Apollo.QueryHookOptions<GetMCityQuery, GetMCityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCityQuery, GetMCityQueryVariables>(GetMCityDocument, options);
}
export function useGetMCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMCityQuery, GetMCityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCityQuery, GetMCityQueryVariables>(GetMCityDocument, options);
}
export type GetMCityQueryHookResult = ReturnType<typeof useGetMCityQuery>;
export type GetMCityLazyQueryHookResult = ReturnType<typeof useGetMCityLazyQuery>;
export type GetMCityQueryResult = Apollo.QueryResult<GetMCityQuery, GetMCityQueryVariables>;
export const GetMCityByIdDocument = gql`
  query GetMCityById($mid: Int!) {
    getMCityById(mid: $mid) {
      id
      name
      kana
      state_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMCityByIdQuery__
 *
 * To run a query within a React component, call `useGetMCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCityByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMCityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMCityByIdQuery, GetMCityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCityByIdQuery, GetMCityByIdQueryVariables>(GetMCityByIdDocument, options);
}
export function useGetMCityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCityByIdQuery, GetMCityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCityByIdQuery, GetMCityByIdQueryVariables>(GetMCityByIdDocument, options);
}
export type GetMCityByIdQueryHookResult = ReturnType<typeof useGetMCityByIdQuery>;
export type GetMCityByIdLazyQueryHookResult = ReturnType<typeof useGetMCityByIdLazyQuery>;
export type GetMCityByIdQueryResult = Apollo.QueryResult<GetMCityByIdQuery, GetMCityByIdQueryVariables>;
export const GetMCityByStateIdDocument = gql`
  query GetMCityByStateId($sid: Int!) {
    getMCityByStateId(sid: $sid) {
      id
      name
      kana
      state_id
      lat
      lng
      __typename
    }
  }
`;

/**
 * __useGetMCityByStateIdQuery__
 *
 * To run a query within a React component, call `useGetMCityByStateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCityByStateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCityByStateIdQuery({
 *   variables: {
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useGetMCityByStateIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMCityByStateIdQuery, GetMCityByStateIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCityByStateIdQuery, GetMCityByStateIdQueryVariables>(GetMCityByStateIdDocument, options);
}
export function useGetMCityByStateIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCityByStateIdQuery, GetMCityByStateIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCityByStateIdQuery, GetMCityByStateIdQueryVariables>(
    GetMCityByStateIdDocument,
    options
  );
}
export type GetMCityByStateIdQueryHookResult = ReturnType<typeof useGetMCityByStateIdQuery>;
export type GetMCityByStateIdLazyQueryHookResult = ReturnType<typeof useGetMCityByStateIdLazyQuery>;
export type GetMCityByStateIdQueryResult = Apollo.QueryResult<GetMCityByStateIdQuery, GetMCityByStateIdQueryVariables>;
export const GetMBtmemRankDocument = gql`
  query GetMBtmemRank {
    getMBtmemRank {
      id
      name
      item
      image
      __typename
    }
  }
`;

/**
 * __useGetMBtmemRankQuery__
 *
 * To run a query within a React component, call `useGetMBtmemRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBtmemRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBtmemRankQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBtmemRankQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBtmemRankQuery, GetMBtmemRankQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBtmemRankQuery, GetMBtmemRankQueryVariables>(GetMBtmemRankDocument, options);
}
export function useGetMBtmemRankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBtmemRankQuery, GetMBtmemRankQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBtmemRankQuery, GetMBtmemRankQueryVariables>(GetMBtmemRankDocument, options);
}
export type GetMBtmemRankQueryHookResult = ReturnType<typeof useGetMBtmemRankQuery>;
export type GetMBtmemRankLazyQueryHookResult = ReturnType<typeof useGetMBtmemRankLazyQuery>;
export type GetMBtmemRankQueryResult = Apollo.QueryResult<GetMBtmemRankQuery, GetMBtmemRankQueryVariables>;
export const GetMBtmemRankByIdDocument = gql`
  query GetMBtmemRankById($mid: Int!) {
    getMBtmemRankById(mid: $mid) {
      id
      name
      item
      image
      __typename
    }
  }
`;

/**
 * __useGetMBtmemRankByIdQuery__
 *
 * To run a query within a React component, call `useGetMBtmemRankByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBtmemRankByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBtmemRankByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMBtmemRankByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBtmemRankByIdQuery, GetMBtmemRankByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBtmemRankByIdQuery, GetMBtmemRankByIdQueryVariables>(GetMBtmemRankByIdDocument, options);
}
export function useGetMBtmemRankByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBtmemRankByIdQuery, GetMBtmemRankByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBtmemRankByIdQuery, GetMBtmemRankByIdQueryVariables>(
    GetMBtmemRankByIdDocument,
    options
  );
}
export type GetMBtmemRankByIdQueryHookResult = ReturnType<typeof useGetMBtmemRankByIdQuery>;
export type GetMBtmemRankByIdLazyQueryHookResult = ReturnType<typeof useGetMBtmemRankByIdLazyQuery>;
export type GetMBtmemRankByIdQueryResult = Apollo.QueryResult<GetMBtmemRankByIdQuery, GetMBtmemRankByIdQueryVariables>;
export const GetMPenaltyTypeDocument = gql`
  query GetMPenaltyType {
    getMPenaltyType {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyTypeQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMPenaltyTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMPenaltyTypeQuery, GetMPenaltyTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyTypeQuery, GetMPenaltyTypeQueryVariables>(GetMPenaltyTypeDocument, options);
}
export function useGetMPenaltyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMPenaltyTypeQuery, GetMPenaltyTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyTypeQuery, GetMPenaltyTypeQueryVariables>(GetMPenaltyTypeDocument, options);
}
export type GetMPenaltyTypeQueryHookResult = ReturnType<typeof useGetMPenaltyTypeQuery>;
export type GetMPenaltyTypeLazyQueryHookResult = ReturnType<typeof useGetMPenaltyTypeLazyQuery>;
export type GetMPenaltyTypeQueryResult = Apollo.QueryResult<GetMPenaltyTypeQuery, GetMPenaltyTypeQueryVariables>;
export const GetMPenaltyTypeByIdDocument = gql`
  query GetMPenaltyTypeById($mid: Int!) {
    getMPenaltyTypeById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyTypeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMPenaltyTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMPenaltyTypeByIdQuery, GetMPenaltyTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyTypeByIdQuery, GetMPenaltyTypeByIdQueryVariables>(
    GetMPenaltyTypeByIdDocument,
    options
  );
}
export function useGetMPenaltyTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMPenaltyTypeByIdQuery, GetMPenaltyTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyTypeByIdQuery, GetMPenaltyTypeByIdQueryVariables>(
    GetMPenaltyTypeByIdDocument,
    options
  );
}
export type GetMPenaltyTypeByIdQueryHookResult = ReturnType<typeof useGetMPenaltyTypeByIdQuery>;
export type GetMPenaltyTypeByIdLazyQueryHookResult = ReturnType<typeof useGetMPenaltyTypeByIdLazyQuery>;
export type GetMPenaltyTypeByIdQueryResult = Apollo.QueryResult<
  GetMPenaltyTypeByIdQuery,
  GetMPenaltyTypeByIdQueryVariables
>;
export const GetMPenaltyDefDocument = gql`
  query GetMPenaltyDef {
    getMPenaltyDef {
      id
      decision
      point
      backout
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyDefQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyDefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyDefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyDefQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMPenaltyDefQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMPenaltyDefQuery, GetMPenaltyDefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyDefQuery, GetMPenaltyDefQueryVariables>(GetMPenaltyDefDocument, options);
}
export function useGetMPenaltyDefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMPenaltyDefQuery, GetMPenaltyDefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyDefQuery, GetMPenaltyDefQueryVariables>(GetMPenaltyDefDocument, options);
}
export type GetMPenaltyDefQueryHookResult = ReturnType<typeof useGetMPenaltyDefQuery>;
export type GetMPenaltyDefLazyQueryHookResult = ReturnType<typeof useGetMPenaltyDefLazyQuery>;
export type GetMPenaltyDefQueryResult = Apollo.QueryResult<GetMPenaltyDefQuery, GetMPenaltyDefQueryVariables>;
export const GetMPenaltyDefByIdDecisionDocument = gql`
  query GetMPenaltyDefByIdDecision($mid: Int!, $dec: Int!) {
    getMPenaltyDefByIdDecision(mid: $mid, dec: $dec) {
      id
      decision
      point
      backout
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyDefByIdDecisionQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyDefByIdDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyDefByIdDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyDefByIdDecisionQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *      dec: // value for 'dec'
 *   },
 * });
 */
export function useGetMPenaltyDefByIdDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<GetMPenaltyDefByIdDecisionQuery, GetMPenaltyDefByIdDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyDefByIdDecisionQuery, GetMPenaltyDefByIdDecisionQueryVariables>(
    GetMPenaltyDefByIdDecisionDocument,
    options
  );
}
export function useGetMPenaltyDefByIdDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMPenaltyDefByIdDecisionQuery, GetMPenaltyDefByIdDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyDefByIdDecisionQuery, GetMPenaltyDefByIdDecisionQueryVariables>(
    GetMPenaltyDefByIdDecisionDocument,
    options
  );
}
export type GetMPenaltyDefByIdDecisionQueryHookResult = ReturnType<typeof useGetMPenaltyDefByIdDecisionQuery>;
export type GetMPenaltyDefByIdDecisionLazyQueryHookResult = ReturnType<typeof useGetMPenaltyDefByIdDecisionLazyQuery>;
export type GetMPenaltyDefByIdDecisionQueryResult = Apollo.QueryResult<
  GetMPenaltyDefByIdDecisionQuery,
  GetMPenaltyDefByIdDecisionQueryVariables
>;
export const GetMPenaltyDefByIdMinDecisionDocument = gql`
  query GetMPenaltyDefByIdMinDecision($mid: Int!, $dec: Int!) {
    getMPenaltyDefByIdMinDecision(mid: $mid, dec: $dec) {
      id
      decision
      point
      backout
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyDefByIdMinDecisionQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyDefByIdMinDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyDefByIdMinDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyDefByIdMinDecisionQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *      dec: // value for 'dec'
 *   },
 * });
 */
export function useGetMPenaltyDefByIdMinDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<GetMPenaltyDefByIdMinDecisionQuery, GetMPenaltyDefByIdMinDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyDefByIdMinDecisionQuery, GetMPenaltyDefByIdMinDecisionQueryVariables>(
    GetMPenaltyDefByIdMinDecisionDocument,
    options
  );
}
export function useGetMPenaltyDefByIdMinDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMPenaltyDefByIdMinDecisionQuery,
    GetMPenaltyDefByIdMinDecisionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyDefByIdMinDecisionQuery, GetMPenaltyDefByIdMinDecisionQueryVariables>(
    GetMPenaltyDefByIdMinDecisionDocument,
    options
  );
}
export type GetMPenaltyDefByIdMinDecisionQueryHookResult = ReturnType<typeof useGetMPenaltyDefByIdMinDecisionQuery>;
export type GetMPenaltyDefByIdMinDecisionLazyQueryHookResult = ReturnType<
  typeof useGetMPenaltyDefByIdMinDecisionLazyQuery
>;
export type GetMPenaltyDefByIdMinDecisionQueryResult = Apollo.QueryResult<
  GetMPenaltyDefByIdMinDecisionQuery,
  GetMPenaltyDefByIdMinDecisionQueryVariables
>;
export const GetMPenaltyDefByIdMaxDecisionDocument = gql`
  query GetMPenaltyDefByIdMaxDecision($mid: Int!, $dec: Int!) {
    getMPenaltyDefByIdMaxDecision(mid: $mid, dec: $dec) {
      id
      decision
      point
      backout
      __typename
    }
  }
`;

/**
 * __useGetMPenaltyDefByIdMaxDecisionQuery__
 *
 * To run a query within a React component, call `useGetMPenaltyDefByIdMaxDecisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMPenaltyDefByIdMaxDecisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMPenaltyDefByIdMaxDecisionQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *      dec: // value for 'dec'
 *   },
 * });
 */
export function useGetMPenaltyDefByIdMaxDecisionQuery(
  baseOptions: Apollo.QueryHookOptions<GetMPenaltyDefByIdMaxDecisionQuery, GetMPenaltyDefByIdMaxDecisionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMPenaltyDefByIdMaxDecisionQuery, GetMPenaltyDefByIdMaxDecisionQueryVariables>(
    GetMPenaltyDefByIdMaxDecisionDocument,
    options
  );
}
export function useGetMPenaltyDefByIdMaxDecisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMPenaltyDefByIdMaxDecisionQuery,
    GetMPenaltyDefByIdMaxDecisionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMPenaltyDefByIdMaxDecisionQuery, GetMPenaltyDefByIdMaxDecisionQueryVariables>(
    GetMPenaltyDefByIdMaxDecisionDocument,
    options
  );
}
export type GetMPenaltyDefByIdMaxDecisionQueryHookResult = ReturnType<typeof useGetMPenaltyDefByIdMaxDecisionQuery>;
export type GetMPenaltyDefByIdMaxDecisionLazyQueryHookResult = ReturnType<
  typeof useGetMPenaltyDefByIdMaxDecisionLazyQuery
>;
export type GetMPenaltyDefByIdMaxDecisionQueryResult = Apollo.QueryResult<
  GetMPenaltyDefByIdMaxDecisionQuery,
  GetMPenaltyDefByIdMaxDecisionQueryVariables
>;
export const GetMBadgeAreaDocument = gql`
  query GetMBadgeArea {
    getMBadgeArea {
      id
      name
      state_id
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBadgeAreaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBadgeAreaQuery, GetMBadgeAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaQuery, GetMBadgeAreaQueryVariables>(GetMBadgeAreaDocument, options);
}
export function useGetMBadgeAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaQuery, GetMBadgeAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaQuery, GetMBadgeAreaQueryVariables>(GetMBadgeAreaDocument, options);
}
export type GetMBadgeAreaQueryHookResult = ReturnType<typeof useGetMBadgeAreaQuery>;
export type GetMBadgeAreaLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaLazyQuery>;
export type GetMBadgeAreaQueryResult = Apollo.QueryResult<GetMBadgeAreaQuery, GetMBadgeAreaQueryVariables>;
export const GetMBadgeAreaByIdDocument = gql`
  query GetMBadgeAreaById($mid: Int!) {
    getMBadgeAreaById(mid: $mid) {
      id
      name
      state_id
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaByIdQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMBadgeAreaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBadgeAreaByIdQuery, GetMBadgeAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaByIdQuery, GetMBadgeAreaByIdQueryVariables>(GetMBadgeAreaByIdDocument, options);
}
export function useGetMBadgeAreaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaByIdQuery, GetMBadgeAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaByIdQuery, GetMBadgeAreaByIdQueryVariables>(
    GetMBadgeAreaByIdDocument,
    options
  );
}
export type GetMBadgeAreaByIdQueryHookResult = ReturnType<typeof useGetMBadgeAreaByIdQuery>;
export type GetMBadgeAreaByIdLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaByIdLazyQuery>;
export type GetMBadgeAreaByIdQueryResult = Apollo.QueryResult<GetMBadgeAreaByIdQuery, GetMBadgeAreaByIdQueryVariables>;
export const GetMBadgeAreaDefDocument = gql`
  query GetMBadgeAreaDef {
    getMBadgeAreaDef {
      id
      city
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaDefQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaDefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaDefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaDefQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMBadgeAreaDefQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMBadgeAreaDefQuery, GetMBadgeAreaDefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaDefQuery, GetMBadgeAreaDefQueryVariables>(GetMBadgeAreaDefDocument, options);
}
export function useGetMBadgeAreaDefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaDefQuery, GetMBadgeAreaDefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaDefQuery, GetMBadgeAreaDefQueryVariables>(GetMBadgeAreaDefDocument, options);
}
export type GetMBadgeAreaDefQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefQuery>;
export type GetMBadgeAreaDefLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefLazyQuery>;
export type GetMBadgeAreaDefQueryResult = Apollo.QueryResult<GetMBadgeAreaDefQuery, GetMBadgeAreaDefQueryVariables>;
export const GetMBadgeAreaDefByIdCityDocument = gql`
  query GetMBadgeAreaDefByIdCity($mid: Int!, $cty: String!) {
    getMBadgeAreaDefByIdCity(mid: $mid, cty: $cty) {
      id
      city
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaDefByIdCityQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaDefByIdCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaDefByIdCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaDefByIdCityQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *      cty: // value for 'cty'
 *   },
 * });
 */
export function useGetMBadgeAreaDefByIdCityQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBadgeAreaDefByIdCityQuery, GetMBadgeAreaDefByIdCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaDefByIdCityQuery, GetMBadgeAreaDefByIdCityQueryVariables>(
    GetMBadgeAreaDefByIdCityDocument,
    options
  );
}
export function useGetMBadgeAreaDefByIdCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaDefByIdCityQuery, GetMBadgeAreaDefByIdCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaDefByIdCityQuery, GetMBadgeAreaDefByIdCityQueryVariables>(
    GetMBadgeAreaDefByIdCityDocument,
    options
  );
}
export type GetMBadgeAreaDefByIdCityQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByIdCityQuery>;
export type GetMBadgeAreaDefByIdCityLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByIdCityLazyQuery>;
export type GetMBadgeAreaDefByIdCityQueryResult = Apollo.QueryResult<
  GetMBadgeAreaDefByIdCityQuery,
  GetMBadgeAreaDefByIdCityQueryVariables
>;
export const GetMBadgeAreaDefByIdDocument = gql`
  query GetMBadgeAreaDefById($mid: Int!) {
    getMBadgeAreaDefById(mid: $mid) {
      id
      city
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaDefByIdQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaDefByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaDefByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaDefByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMBadgeAreaDefByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBadgeAreaDefByIdQuery, GetMBadgeAreaDefByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaDefByIdQuery, GetMBadgeAreaDefByIdQueryVariables>(
    GetMBadgeAreaDefByIdDocument,
    options
  );
}
export function useGetMBadgeAreaDefByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaDefByIdQuery, GetMBadgeAreaDefByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaDefByIdQuery, GetMBadgeAreaDefByIdQueryVariables>(
    GetMBadgeAreaDefByIdDocument,
    options
  );
}
export type GetMBadgeAreaDefByIdQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByIdQuery>;
export type GetMBadgeAreaDefByIdLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByIdLazyQuery>;
export type GetMBadgeAreaDefByIdQueryResult = Apollo.QueryResult<
  GetMBadgeAreaDefByIdQuery,
  GetMBadgeAreaDefByIdQueryVariables
>;
export const GetMBadgeAreaDefByCityDocument = gql`
  query GetMBadgeAreaDefByCity($cty: String!) {
    getMBadgeAreaDefByCity(cty: $cty) {
      id
      city
      __typename
    }
  }
`;

/**
 * __useGetMBadgeAreaDefByCityQuery__
 *
 * To run a query within a React component, call `useGetMBadgeAreaDefByCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMBadgeAreaDefByCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMBadgeAreaDefByCityQuery({
 *   variables: {
 *      cty: // value for 'cty'
 *   },
 * });
 */
export function useGetMBadgeAreaDefByCityQuery(
  baseOptions: Apollo.QueryHookOptions<GetMBadgeAreaDefByCityQuery, GetMBadgeAreaDefByCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMBadgeAreaDefByCityQuery, GetMBadgeAreaDefByCityQueryVariables>(
    GetMBadgeAreaDefByCityDocument,
    options
  );
}
export function useGetMBadgeAreaDefByCityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMBadgeAreaDefByCityQuery, GetMBadgeAreaDefByCityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMBadgeAreaDefByCityQuery, GetMBadgeAreaDefByCityQueryVariables>(
    GetMBadgeAreaDefByCityDocument,
    options
  );
}
export type GetMBadgeAreaDefByCityQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByCityQuery>;
export type GetMBadgeAreaDefByCityLazyQueryHookResult = ReturnType<typeof useGetMBadgeAreaDefByCityLazyQuery>;
export type GetMBadgeAreaDefByCityQueryResult = Apollo.QueryResult<
  GetMBadgeAreaDefByCityQuery,
  GetMBadgeAreaDefByCityQueryVariables
>;
export const GetMOccAttrDocument = gql`
  query GetMOccAttr {
    getMOccAttr {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMOccAttrQuery__
 *
 * To run a query within a React component, call `useGetMOccAttrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMOccAttrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMOccAttrQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMOccAttrQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMOccAttrQuery, GetMOccAttrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMOccAttrQuery, GetMOccAttrQueryVariables>(GetMOccAttrDocument, options);
}
export function useGetMOccAttrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMOccAttrQuery, GetMOccAttrQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMOccAttrQuery, GetMOccAttrQueryVariables>(GetMOccAttrDocument, options);
}
export type GetMOccAttrQueryHookResult = ReturnType<typeof useGetMOccAttrQuery>;
export type GetMOccAttrLazyQueryHookResult = ReturnType<typeof useGetMOccAttrLazyQuery>;
export type GetMOccAttrQueryResult = Apollo.QueryResult<GetMOccAttrQuery, GetMOccAttrQueryVariables>;
export const GetMOccAttrByIdDocument = gql`
  query GetMOccAttrById($mid: Int!) {
    getMOccAttrById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMOccAttrByIdQuery__
 *
 * To run a query within a React component, call `useGetMOccAttrByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMOccAttrByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMOccAttrByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMOccAttrByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMOccAttrByIdQuery, GetMOccAttrByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMOccAttrByIdQuery, GetMOccAttrByIdQueryVariables>(GetMOccAttrByIdDocument, options);
}
export function useGetMOccAttrByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMOccAttrByIdQuery, GetMOccAttrByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMOccAttrByIdQuery, GetMOccAttrByIdQueryVariables>(GetMOccAttrByIdDocument, options);
}
export type GetMOccAttrByIdQueryHookResult = ReturnType<typeof useGetMOccAttrByIdQuery>;
export type GetMOccAttrByIdLazyQueryHookResult = ReturnType<typeof useGetMOccAttrByIdLazyQuery>;
export type GetMOccAttrByIdQueryResult = Apollo.QueryResult<GetMOccAttrByIdQuery, GetMOccAttrByIdQueryVariables>;
export const GetMUniformSizeDocument = gql`
  query GetMUniformSize {
    getMUniformSize {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMUniformSizeQuery__
 *
 * To run a query within a React component, call `useGetMUniformSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMUniformSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMUniformSizeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMUniformSizeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMUniformSizeQuery, GetMUniformSizeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMUniformSizeQuery, GetMUniformSizeQueryVariables>(GetMUniformSizeDocument, options);
}
export function useGetMUniformSizeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMUniformSizeQuery, GetMUniformSizeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMUniformSizeQuery, GetMUniformSizeQueryVariables>(GetMUniformSizeDocument, options);
}
export type GetMUniformSizeQueryHookResult = ReturnType<typeof useGetMUniformSizeQuery>;
export type GetMUniformSizeLazyQueryHookResult = ReturnType<typeof useGetMUniformSizeLazyQuery>;
export type GetMUniformSizeQueryResult = Apollo.QueryResult<GetMUniformSizeQuery, GetMUniformSizeQueryVariables>;
export const GetMUniformSizeByIdDocument = gql`
  query GetMUniformSizeById($mid: Int!) {
    getMUniformSizeById(mid: $mid) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMUniformSizeByIdQuery__
 *
 * To run a query within a React component, call `useGetMUniformSizeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMUniformSizeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMUniformSizeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMUniformSizeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMUniformSizeByIdQuery, GetMUniformSizeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMUniformSizeByIdQuery, GetMUniformSizeByIdQueryVariables>(
    GetMUniformSizeByIdDocument,
    options
  );
}
export function useGetMUniformSizeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMUniformSizeByIdQuery, GetMUniformSizeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMUniformSizeByIdQuery, GetMUniformSizeByIdQueryVariables>(
    GetMUniformSizeByIdDocument,
    options
  );
}
export type GetMUniformSizeByIdQueryHookResult = ReturnType<typeof useGetMUniformSizeByIdQuery>;
export type GetMUniformSizeByIdLazyQueryHookResult = ReturnType<typeof useGetMUniformSizeByIdLazyQuery>;
export type GetMUniformSizeByIdQueryResult = Apollo.QueryResult<
  GetMUniformSizeByIdQuery,
  GetMUniformSizeByIdQueryVariables
>;
export const GetMAchieveDocument = gql`
  query GetMAchieve {
    getMAchieve {
      id
      kind
      phrase
      __typename
    }
  }
`;

/**
 * __useGetMAchieveQuery__
 *
 * To run a query within a React component, call `useGetMAchieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAchieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAchieveQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMAchieveQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMAchieveQuery, GetMAchieveQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAchieveQuery, GetMAchieveQueryVariables>(GetMAchieveDocument, options);
}
export function useGetMAchieveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAchieveQuery, GetMAchieveQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAchieveQuery, GetMAchieveQueryVariables>(GetMAchieveDocument, options);
}
export type GetMAchieveQueryHookResult = ReturnType<typeof useGetMAchieveQuery>;
export type GetMAchieveLazyQueryHookResult = ReturnType<typeof useGetMAchieveLazyQuery>;
export type GetMAchieveQueryResult = Apollo.QueryResult<GetMAchieveQuery, GetMAchieveQueryVariables>;
export const GetMAchieveByIdDocument = gql`
  query GetMAchieveById($mid: Int!) {
    getMAchieveById(mid: $mid) {
      id
      kind
      phrase
      __typename
    }
  }
`;

/**
 * __useGetMAchieveByIdQuery__
 *
 * To run a query within a React component, call `useGetMAchieveByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAchieveByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAchieveByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMAchieveByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAchieveByIdQuery, GetMAchieveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAchieveByIdQuery, GetMAchieveByIdQueryVariables>(GetMAchieveByIdDocument, options);
}
export function useGetMAchieveByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAchieveByIdQuery, GetMAchieveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAchieveByIdQuery, GetMAchieveByIdQueryVariables>(GetMAchieveByIdDocument, options);
}
export type GetMAchieveByIdQueryHookResult = ReturnType<typeof useGetMAchieveByIdQuery>;
export type GetMAchieveByIdLazyQueryHookResult = ReturnType<typeof useGetMAchieveByIdLazyQuery>;
export type GetMAchieveByIdQueryResult = Apollo.QueryResult<GetMAchieveByIdQuery, GetMAchieveByIdQueryVariables>;
export const GetMAchieveByKindDocument = gql`
  query GetMAchieveByKind($knd: Int!) {
    getMAchieveByKind(knd: $knd) {
      id
      kind
      phrase
      __typename
    }
  }
`;

/**
 * __useGetMAchieveByKindQuery__
 *
 * To run a query within a React component, call `useGetMAchieveByKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAchieveByKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAchieveByKindQuery({
 *   variables: {
 *      knd: // value for 'knd'
 *   },
 * });
 */
export function useGetMAchieveByKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAchieveByKindQuery, GetMAchieveByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAchieveByKindQuery, GetMAchieveByKindQueryVariables>(GetMAchieveByKindDocument, options);
}
export function useGetMAchieveByKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAchieveByKindQuery, GetMAchieveByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAchieveByKindQuery, GetMAchieveByKindQueryVariables>(
    GetMAchieveByKindDocument,
    options
  );
}
export type GetMAchieveByKindQueryHookResult = ReturnType<typeof useGetMAchieveByKindQuery>;
export type GetMAchieveByKindLazyQueryHookResult = ReturnType<typeof useGetMAchieveByKindLazyQuery>;
export type GetMAchieveByKindQueryResult = Apollo.QueryResult<GetMAchieveByKindQuery, GetMAchieveByKindQueryVariables>;
export const GetMIdCardTypeDocument = gql`
  query GetMIdCardType {
    getMIdCardType {
      id
      type
      name
      image_num
      with_photo
      student
      additional_id
      icon
      __typename
    }
  }
`;

/**
 * __useGetMIdCardTypeQuery__
 *
 * To run a query within a React component, call `useGetMIdCardTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMIdCardTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMIdCardTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMIdCardTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMIdCardTypeQuery, GetMIdCardTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMIdCardTypeQuery, GetMIdCardTypeQueryVariables>(GetMIdCardTypeDocument, options);
}
export function useGetMIdCardTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMIdCardTypeQuery, GetMIdCardTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMIdCardTypeQuery, GetMIdCardTypeQueryVariables>(GetMIdCardTypeDocument, options);
}
export type GetMIdCardTypeQueryHookResult = ReturnType<typeof useGetMIdCardTypeQuery>;
export type GetMIdCardTypeLazyQueryHookResult = ReturnType<typeof useGetMIdCardTypeLazyQuery>;
export type GetMIdCardTypeQueryResult = Apollo.QueryResult<GetMIdCardTypeQuery, GetMIdCardTypeQueryVariables>;
export const GetMIdCardTypeByIdDocument = gql`
  query GetMIdCardTypeById($mid: Int!) {
    getMIdCardTypeById(mid: $mid) {
      id
      type
      name
      image_num
      with_photo
      student
      additional_id
      icon
      __typename
    }
  }
`;

/**
 * __useGetMIdCardTypeByIdQuery__
 *
 * To run a query within a React component, call `useGetMIdCardTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMIdCardTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMIdCardTypeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMIdCardTypeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMIdCardTypeByIdQuery, GetMIdCardTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMIdCardTypeByIdQuery, GetMIdCardTypeByIdQueryVariables>(
    GetMIdCardTypeByIdDocument,
    options
  );
}
export function useGetMIdCardTypeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMIdCardTypeByIdQuery, GetMIdCardTypeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMIdCardTypeByIdQuery, GetMIdCardTypeByIdQueryVariables>(
    GetMIdCardTypeByIdDocument,
    options
  );
}
export type GetMIdCardTypeByIdQueryHookResult = ReturnType<typeof useGetMIdCardTypeByIdQuery>;
export type GetMIdCardTypeByIdLazyQueryHookResult = ReturnType<typeof useGetMIdCardTypeByIdLazyQuery>;
export type GetMIdCardTypeByIdQueryResult = Apollo.QueryResult<
  GetMIdCardTypeByIdQuery,
  GetMIdCardTypeByIdQueryVariables
>;
export const GetMLicenseDocument = gql`
  query GetMLicense {
    getMLicense {
      id
      biz_id
      name
      __typename
    }
  }
`;

/**
 * __useGetMLicenseQuery__
 *
 * To run a query within a React component, call `useGetMLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMLicenseQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMLicenseQuery, GetMLicenseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMLicenseQuery, GetMLicenseQueryVariables>(GetMLicenseDocument, options);
}
export function useGetMLicenseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMLicenseQuery, GetMLicenseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMLicenseQuery, GetMLicenseQueryVariables>(GetMLicenseDocument, options);
}
export type GetMLicenseQueryHookResult = ReturnType<typeof useGetMLicenseQuery>;
export type GetMLicenseLazyQueryHookResult = ReturnType<typeof useGetMLicenseLazyQuery>;
export type GetMLicenseQueryResult = Apollo.QueryResult<GetMLicenseQuery, GetMLicenseQueryVariables>;
export const GetMLicenseByIdDocument = gql`
  query GetMLicenseById($mid: Int!) {
    getMLicenseById(mid: $mid) {
      id
      biz_id
      name
      __typename
    }
  }
`;

/**
 * __useGetMLicenseByIdQuery__
 *
 * To run a query within a React component, call `useGetMLicenseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMLicenseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMLicenseByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMLicenseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMLicenseByIdQuery, GetMLicenseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMLicenseByIdQuery, GetMLicenseByIdQueryVariables>(GetMLicenseByIdDocument, options);
}
export function useGetMLicenseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMLicenseByIdQuery, GetMLicenseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMLicenseByIdQuery, GetMLicenseByIdQueryVariables>(GetMLicenseByIdDocument, options);
}
export type GetMLicenseByIdQueryHookResult = ReturnType<typeof useGetMLicenseByIdQuery>;
export type GetMLicenseByIdLazyQueryHookResult = ReturnType<typeof useGetMLicenseByIdLazyQuery>;
export type GetMLicenseByIdQueryResult = Apollo.QueryResult<GetMLicenseByIdQuery, GetMLicenseByIdQueryVariables>;
export const GetMLicenseByBizIdDocument = gql`
  query GetMLicenseByBizId($bid: Int!) {
    getMLicenseByBizId(bid: $bid) {
      id
      biz_id
      name
      __typename
    }
  }
`;

/**
 * __useGetMLicenseByBizIdQuery__
 *
 * To run a query within a React component, call `useGetMLicenseByBizIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMLicenseByBizIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMLicenseByBizIdQuery({
 *   variables: {
 *      bid: // value for 'bid'
 *   },
 * });
 */
export function useGetMLicenseByBizIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMLicenseByBizIdQuery, GetMLicenseByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMLicenseByBizIdQuery, GetMLicenseByBizIdQueryVariables>(
    GetMLicenseByBizIdDocument,
    options
  );
}
export function useGetMLicenseByBizIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMLicenseByBizIdQuery, GetMLicenseByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMLicenseByBizIdQuery, GetMLicenseByBizIdQueryVariables>(
    GetMLicenseByBizIdDocument,
    options
  );
}
export type GetMLicenseByBizIdQueryHookResult = ReturnType<typeof useGetMLicenseByBizIdQuery>;
export type GetMLicenseByBizIdLazyQueryHookResult = ReturnType<typeof useGetMLicenseByBizIdLazyQuery>;
export type GetMLicenseByBizIdQueryResult = Apollo.QueryResult<
  GetMLicenseByBizIdQuery,
  GetMLicenseByBizIdQueryVariables
>;
export const GetMAutoBadgeDocument = gql`
  query GetMAutoBadge {
    getMAutoBadge {
      id
      count
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMAutoBadgeQuery__
 *
 * To run a query within a React component, call `useGetMAutoBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAutoBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAutoBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMAutoBadgeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMAutoBadgeQuery, GetMAutoBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAutoBadgeQuery, GetMAutoBadgeQueryVariables>(GetMAutoBadgeDocument, options);
}
export function useGetMAutoBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAutoBadgeQuery, GetMAutoBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAutoBadgeQuery, GetMAutoBadgeQueryVariables>(GetMAutoBadgeDocument, options);
}
export type GetMAutoBadgeQueryHookResult = ReturnType<typeof useGetMAutoBadgeQuery>;
export type GetMAutoBadgeLazyQueryHookResult = ReturnType<typeof useGetMAutoBadgeLazyQuery>;
export type GetMAutoBadgeQueryResult = Apollo.QueryResult<GetMAutoBadgeQuery, GetMAutoBadgeQueryVariables>;
export const GetMAutoBadgeByIdDocument = gql`
  query GetMAutoBadgeById($mid: Int!) {
    getMAutoBadgeById(mid: $mid) {
      id
      count
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMAutoBadgeByIdQuery__
 *
 * To run a query within a React component, call `useGetMAutoBadgeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAutoBadgeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAutoBadgeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMAutoBadgeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAutoBadgeByIdQuery, GetMAutoBadgeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAutoBadgeByIdQuery, GetMAutoBadgeByIdQueryVariables>(GetMAutoBadgeByIdDocument, options);
}
export function useGetMAutoBadgeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAutoBadgeByIdQuery, GetMAutoBadgeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAutoBadgeByIdQuery, GetMAutoBadgeByIdQueryVariables>(
    GetMAutoBadgeByIdDocument,
    options
  );
}
export type GetMAutoBadgeByIdQueryHookResult = ReturnType<typeof useGetMAutoBadgeByIdQuery>;
export type GetMAutoBadgeByIdLazyQueryHookResult = ReturnType<typeof useGetMAutoBadgeByIdLazyQuery>;
export type GetMAutoBadgeByIdQueryResult = Apollo.QueryResult<GetMAutoBadgeByIdQuery, GetMAutoBadgeByIdQueryVariables>;
export const GetMAutoBadgeByCountDocument = gql`
  query GetMAutoBadgeByCount($cnt: Int!) {
    getMAutoBadgeByCount(cnt: $cnt) {
      id
      count
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMAutoBadgeByCountQuery__
 *
 * To run a query within a React component, call `useGetMAutoBadgeByCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAutoBadgeByCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAutoBadgeByCountQuery({
 *   variables: {
 *      cnt: // value for 'cnt'
 *   },
 * });
 */
export function useGetMAutoBadgeByCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAutoBadgeByCountQuery, GetMAutoBadgeByCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAutoBadgeByCountQuery, GetMAutoBadgeByCountQueryVariables>(
    GetMAutoBadgeByCountDocument,
    options
  );
}
export function useGetMAutoBadgeByCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAutoBadgeByCountQuery, GetMAutoBadgeByCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAutoBadgeByCountQuery, GetMAutoBadgeByCountQueryVariables>(
    GetMAutoBadgeByCountDocument,
    options
  );
}
export type GetMAutoBadgeByCountQueryHookResult = ReturnType<typeof useGetMAutoBadgeByCountQuery>;
export type GetMAutoBadgeByCountLazyQueryHookResult = ReturnType<typeof useGetMAutoBadgeByCountLazyQuery>;
export type GetMAutoBadgeByCountQueryResult = Apollo.QueryResult<
  GetMAutoBadgeByCountQuery,
  GetMAutoBadgeByCountQueryVariables
>;
export const GetMAutoBadgeByMaxCountDocument = gql`
  query GetMAutoBadgeByMaxCount($cnt: Int!) {
    getMAutoBadgeByMaxCount(cnt: $cnt) {
      id
      count
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMAutoBadgeByMaxCountQuery__
 *
 * To run a query within a React component, call `useGetMAutoBadgeByMaxCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMAutoBadgeByMaxCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMAutoBadgeByMaxCountQuery({
 *   variables: {
 *      cnt: // value for 'cnt'
 *   },
 * });
 */
export function useGetMAutoBadgeByMaxCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetMAutoBadgeByMaxCountQuery, GetMAutoBadgeByMaxCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMAutoBadgeByMaxCountQuery, GetMAutoBadgeByMaxCountQueryVariables>(
    GetMAutoBadgeByMaxCountDocument,
    options
  );
}
export function useGetMAutoBadgeByMaxCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMAutoBadgeByMaxCountQuery, GetMAutoBadgeByMaxCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMAutoBadgeByMaxCountQuery, GetMAutoBadgeByMaxCountQueryVariables>(
    GetMAutoBadgeByMaxCountDocument,
    options
  );
}
export type GetMAutoBadgeByMaxCountQueryHookResult = ReturnType<typeof useGetMAutoBadgeByMaxCountQuery>;
export type GetMAutoBadgeByMaxCountLazyQueryHookResult = ReturnType<typeof useGetMAutoBadgeByMaxCountLazyQuery>;
export type GetMAutoBadgeByMaxCountQueryResult = Apollo.QueryResult<
  GetMAutoBadgeByMaxCountQuery,
  GetMAutoBadgeByMaxCountQueryVariables
>;
export const GetMConqBadgeDocument = gql`
  query GetMConqBadge {
    getMConqBadge {
      id
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMConqBadgeQuery__
 *
 * To run a query within a React component, call `useGetMConqBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMConqBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMConqBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMConqBadgeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMConqBadgeQuery, GetMConqBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMConqBadgeQuery, GetMConqBadgeQueryVariables>(GetMConqBadgeDocument, options);
}
export function useGetMConqBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMConqBadgeQuery, GetMConqBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMConqBadgeQuery, GetMConqBadgeQueryVariables>(GetMConqBadgeDocument, options);
}
export type GetMConqBadgeQueryHookResult = ReturnType<typeof useGetMConqBadgeQuery>;
export type GetMConqBadgeLazyQueryHookResult = ReturnType<typeof useGetMConqBadgeLazyQuery>;
export type GetMConqBadgeQueryResult = Apollo.QueryResult<GetMConqBadgeQuery, GetMConqBadgeQueryVariables>;
export const GetMConqBadgeByIdDocument = gql`
  query GetMConqBadgeById($mid: Int!) {
    getMConqBadgeById(mid: $mid) {
      id
      name
      icon
      __typename
    }
  }
`;

/**
 * __useGetMConqBadgeByIdQuery__
 *
 * To run a query within a React component, call `useGetMConqBadgeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMConqBadgeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMConqBadgeByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMConqBadgeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMConqBadgeByIdQuery, GetMConqBadgeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMConqBadgeByIdQuery, GetMConqBadgeByIdQueryVariables>(GetMConqBadgeByIdDocument, options);
}
export function useGetMConqBadgeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMConqBadgeByIdQuery, GetMConqBadgeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMConqBadgeByIdQuery, GetMConqBadgeByIdQueryVariables>(
    GetMConqBadgeByIdDocument,
    options
  );
}
export type GetMConqBadgeByIdQueryHookResult = ReturnType<typeof useGetMConqBadgeByIdQuery>;
export type GetMConqBadgeByIdLazyQueryHookResult = ReturnType<typeof useGetMConqBadgeByIdLazyQuery>;
export type GetMConqBadgeByIdQueryResult = Apollo.QueryResult<GetMConqBadgeByIdQuery, GetMConqBadgeByIdQueryVariables>;
export const GetMConqBadgeListDocument = gql`
  query GetMConqBadgeList {
    getMConqBadgeList {
      id
      name
      icon
      badge_area {
        area_id
        area_name
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetMConqBadgeListQuery__
 *
 * To run a query within a React component, call `useGetMConqBadgeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMConqBadgeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMConqBadgeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMConqBadgeListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMConqBadgeListQuery, GetMConqBadgeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMConqBadgeListQuery, GetMConqBadgeListQueryVariables>(GetMConqBadgeListDocument, options);
}
export function useGetMConqBadgeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMConqBadgeListQuery, GetMConqBadgeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMConqBadgeListQuery, GetMConqBadgeListQueryVariables>(
    GetMConqBadgeListDocument,
    options
  );
}
export type GetMConqBadgeListQueryHookResult = ReturnType<typeof useGetMConqBadgeListQuery>;
export type GetMConqBadgeListLazyQueryHookResult = ReturnType<typeof useGetMConqBadgeListLazyQuery>;
export type GetMConqBadgeListQueryResult = Apollo.QueryResult<GetMConqBadgeListQuery, GetMConqBadgeListQueryVariables>;
export const GetMSkillCheckDocument = gql`
  query GetMSkillCheck {
    getMSkillCheck {
      biz_id
      section_no
      no
      bt_rank_id
      item
      __typename
    }
  }
`;

/**
 * __useGetMSkillCheckQuery__
 *
 * To run a query within a React component, call `useGetMSkillCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMSkillCheckQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMSkillCheckQuery, GetMSkillCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillCheckQuery, GetMSkillCheckQueryVariables>(GetMSkillCheckDocument, options);
}
export function useGetMSkillCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillCheckQuery, GetMSkillCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillCheckQuery, GetMSkillCheckQueryVariables>(GetMSkillCheckDocument, options);
}
export type GetMSkillCheckQueryHookResult = ReturnType<typeof useGetMSkillCheckQuery>;
export type GetMSkillCheckLazyQueryHookResult = ReturnType<typeof useGetMSkillCheckLazyQuery>;
export type GetMSkillCheckQueryResult = Apollo.QueryResult<GetMSkillCheckQuery, GetMSkillCheckQueryVariables>;
export const GetMSkillCheckByBizIdNoDocument = gql`
  query GetMSkillCheckByBizIdNo($bid: Int!, $no: Int!) {
    getMSkillCheckByBizIdNo(bid: $bid, no: $no) {
      biz_id
      section_no
      no
      bt_rank_id
      item
      __typename
    }
  }
`;

/**
 * __useGetMSkillCheckByBizIdNoQuery__
 *
 * To run a query within a React component, call `useGetMSkillCheckByBizIdNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillCheckByBizIdNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillCheckByBizIdNoQuery({
 *   variables: {
 *      bid: // value for 'bid'
 *      no: // value for 'no'
 *   },
 * });
 */
export function useGetMSkillCheckByBizIdNoQuery(
  baseOptions: Apollo.QueryHookOptions<GetMSkillCheckByBizIdNoQuery, GetMSkillCheckByBizIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillCheckByBizIdNoQuery, GetMSkillCheckByBizIdNoQueryVariables>(
    GetMSkillCheckByBizIdNoDocument,
    options
  );
}
export function useGetMSkillCheckByBizIdNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillCheckByBizIdNoQuery, GetMSkillCheckByBizIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillCheckByBizIdNoQuery, GetMSkillCheckByBizIdNoQueryVariables>(
    GetMSkillCheckByBizIdNoDocument,
    options
  );
}
export type GetMSkillCheckByBizIdNoQueryHookResult = ReturnType<typeof useGetMSkillCheckByBizIdNoQuery>;
export type GetMSkillCheckByBizIdNoLazyQueryHookResult = ReturnType<typeof useGetMSkillCheckByBizIdNoLazyQuery>;
export type GetMSkillCheckByBizIdNoQueryResult = Apollo.QueryResult<
  GetMSkillCheckByBizIdNoQuery,
  GetMSkillCheckByBizIdNoQueryVariables
>;
export const GetMSkillCheckByBtRankIdDocument = gql`
  query GetMSkillCheckByBtRankId($rid: Int!) {
    getMSkillCheckByBtRankId(rid: $rid) {
      biz_id
      section_no
      no
      bt_rank_id
      item
      __typename
    }
  }
`;

/**
 * __useGetMSkillCheckByBtRankIdQuery__
 *
 * To run a query within a React component, call `useGetMSkillCheckByBtRankIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillCheckByBtRankIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillCheckByBtRankIdQuery({
 *   variables: {
 *      rid: // value for 'rid'
 *   },
 * });
 */
export function useGetMSkillCheckByBtRankIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMSkillCheckByBtRankIdQuery, GetMSkillCheckByBtRankIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillCheckByBtRankIdQuery, GetMSkillCheckByBtRankIdQueryVariables>(
    GetMSkillCheckByBtRankIdDocument,
    options
  );
}
export function useGetMSkillCheckByBtRankIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillCheckByBtRankIdQuery, GetMSkillCheckByBtRankIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillCheckByBtRankIdQuery, GetMSkillCheckByBtRankIdQueryVariables>(
    GetMSkillCheckByBtRankIdDocument,
    options
  );
}
export type GetMSkillCheckByBtRankIdQueryHookResult = ReturnType<typeof useGetMSkillCheckByBtRankIdQuery>;
export type GetMSkillCheckByBtRankIdLazyQueryHookResult = ReturnType<typeof useGetMSkillCheckByBtRankIdLazyQuery>;
export type GetMSkillCheckByBtRankIdQueryResult = Apollo.QueryResult<
  GetMSkillCheckByBtRankIdQuery,
  GetMSkillCheckByBtRankIdQueryVariables
>;
export const GetMCountryDocument = gql`
  query GetMCountry {
    getMCountry {
      id
      name
      english
      __typename
    }
  }
`;

/**
 * __useGetMCountryQuery__
 *
 * To run a query within a React component, call `useGetMCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMCountryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMCountryQuery, GetMCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCountryQuery, GetMCountryQueryVariables>(GetMCountryDocument, options);
}
export function useGetMCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCountryQuery, GetMCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCountryQuery, GetMCountryQueryVariables>(GetMCountryDocument, options);
}
export type GetMCountryQueryHookResult = ReturnType<typeof useGetMCountryQuery>;
export type GetMCountryLazyQueryHookResult = ReturnType<typeof useGetMCountryLazyQuery>;
export type GetMCountryQueryResult = Apollo.QueryResult<GetMCountryQuery, GetMCountryQueryVariables>;
export const GetMCountryByIdDocument = gql`
  query GetMCountryById($mid: Int!) {
    getMCountryById(mid: $mid) {
      id
      name
      english
      __typename
    }
  }
`;

/**
 * __useGetMCountryByIdQuery__
 *
 * To run a query within a React component, call `useGetMCountryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCountryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCountryByIdQuery({
 *   variables: {
 *      mid: // value for 'mid'
 *   },
 * });
 */
export function useGetMCountryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMCountryByIdQuery, GetMCountryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCountryByIdQuery, GetMCountryByIdQueryVariables>(GetMCountryByIdDocument, options);
}
export function useGetMCountryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCountryByIdQuery, GetMCountryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCountryByIdQuery, GetMCountryByIdQueryVariables>(GetMCountryByIdDocument, options);
}
export type GetMCountryByIdQueryHookResult = ReturnType<typeof useGetMCountryByIdQuery>;
export type GetMCountryByIdLazyQueryHookResult = ReturnType<typeof useGetMCountryByIdLazyQuery>;
export type GetMCountryByIdQueryResult = Apollo.QueryResult<GetMCountryByIdQuery, GetMCountryByIdQueryVariables>;
export const GetMMessageDocument = gql`
  query GetMMessage {
    getMMessage {
      sys_id
      no
      kind
      title
      body
      screen_no
      __typename
    }
  }
`;

/**
 * __useGetMMessageQuery__
 *
 * To run a query within a React component, call `useGetMMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMMessageQuery, GetMMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMMessageQuery, GetMMessageQueryVariables>(GetMMessageDocument, options);
}
export function useGetMMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMMessageQuery, GetMMessageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMMessageQuery, GetMMessageQueryVariables>(GetMMessageDocument, options);
}
export type GetMMessageQueryHookResult = ReturnType<typeof useGetMMessageQuery>;
export type GetMMessageLazyQueryHookResult = ReturnType<typeof useGetMMessageLazyQuery>;
export type GetMMessageQueryResult = Apollo.QueryResult<GetMMessageQuery, GetMMessageQueryVariables>;
export const GetMMessageByNoDocument = gql`
  query GetMMessageByNo($no: Int!) {
    getMMessageByNo(no: $no) {
      sys_id
      no
      kind
      title
      body
      screen_no
      __typename
    }
  }
`;

/**
 * __useGetMMessageByNoQuery__
 *
 * To run a query within a React component, call `useGetMMessageByNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMMessageByNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMMessageByNoQuery({
 *   variables: {
 *      no: // value for 'no'
 *   },
 * });
 */
export function useGetMMessageByNoQuery(
  baseOptions: Apollo.QueryHookOptions<GetMMessageByNoQuery, GetMMessageByNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMMessageByNoQuery, GetMMessageByNoQueryVariables>(GetMMessageByNoDocument, options);
}
export function useGetMMessageByNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMMessageByNoQuery, GetMMessageByNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMMessageByNoQuery, GetMMessageByNoQueryVariables>(GetMMessageByNoDocument, options);
}
export type GetMMessageByNoQueryHookResult = ReturnType<typeof useGetMMessageByNoQuery>;
export type GetMMessageByNoLazyQueryHookResult = ReturnType<typeof useGetMMessageByNoLazyQuery>;
export type GetMMessageByNoQueryResult = Apollo.QueryResult<GetMMessageByNoQuery, GetMMessageByNoQueryVariables>;
export const GetMResidenceDocument = gql`
  query GetMResidence {
    getMResidence {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMResidenceQuery__
 *
 * To run a query within a React component, call `useGetMResidenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMResidenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMResidenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMResidenceQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMResidenceQuery, GetMResidenceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMResidenceQuery, GetMResidenceQueryVariables>(GetMResidenceDocument, options);
}
export function useGetMResidenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMResidenceQuery, GetMResidenceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMResidenceQuery, GetMResidenceQueryVariables>(GetMResidenceDocument, options);
}
export type GetMResidenceQueryHookResult = ReturnType<typeof useGetMResidenceQuery>;
export type GetMResidenceLazyQueryHookResult = ReturnType<typeof useGetMResidenceLazyQuery>;
export type GetMResidenceQueryResult = Apollo.QueryResult<GetMResidenceQuery, GetMResidenceQueryVariables>;
export const GetMResidenceByIdDocument = gql`
  query GetMResidenceById($id: Int!) {
    getMResidenceById(id: $id) {
      id
      name
      __typename
    }
  }
`;

/**
 * __useGetMResidenceByIdQuery__
 *
 * To run a query within a React component, call `useGetMResidenceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMResidenceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMResidenceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMResidenceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMResidenceByIdQuery, GetMResidenceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMResidenceByIdQuery, GetMResidenceByIdQueryVariables>(GetMResidenceByIdDocument, options);
}
export function useGetMResidenceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMResidenceByIdQuery, GetMResidenceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMResidenceByIdQuery, GetMResidenceByIdQueryVariables>(
    GetMResidenceByIdDocument,
    options
  );
}
export type GetMResidenceByIdQueryHookResult = ReturnType<typeof useGetMResidenceByIdQuery>;
export type GetMResidenceByIdLazyQueryHookResult = ReturnType<typeof useGetMResidenceByIdLazyQuery>;
export type GetMResidenceByIdQueryResult = Apollo.QueryResult<GetMResidenceByIdQuery, GetMResidenceByIdQueryVariables>;
export const GetMSkillSectionDocument = gql`
  query GetMSkillSection {
    getMSkillSection {
      biz_id
      no
      name
      __typename
    }
  }
`;

/**
 * __useGetMSkillSectionQuery__
 *
 * To run a query within a React component, call `useGetMSkillSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMSkillSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMSkillSectionQuery, GetMSkillSectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillSectionQuery, GetMSkillSectionQueryVariables>(GetMSkillSectionDocument, options);
}
export function useGetMSkillSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillSectionQuery, GetMSkillSectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillSectionQuery, GetMSkillSectionQueryVariables>(GetMSkillSectionDocument, options);
}
export type GetMSkillSectionQueryHookResult = ReturnType<typeof useGetMSkillSectionQuery>;
export type GetMSkillSectionLazyQueryHookResult = ReturnType<typeof useGetMSkillSectionLazyQuery>;
export type GetMSkillSectionQueryResult = Apollo.QueryResult<GetMSkillSectionQuery, GetMSkillSectionQueryVariables>;
export const GetMSkillSectionByBizIdNoDocument = gql`
  query GetMSkillSectionByBizIdNo($biz_id: Int!, $no: Int!) {
    getMSkillSectionByBizIdNo(biz_id: $biz_id, no: $no) {
      biz_id
      no
      name
      __typename
    }
  }
`;

/**
 * __useGetMSkillSectionByBizIdNoQuery__
 *
 * To run a query within a React component, call `useGetMSkillSectionByBizIdNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillSectionByBizIdNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillSectionByBizIdNoQuery({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *      no: // value for 'no'
 *   },
 * });
 */
export function useGetMSkillSectionByBizIdNoQuery(
  baseOptions: Apollo.QueryHookOptions<GetMSkillSectionByBizIdNoQuery, GetMSkillSectionByBizIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillSectionByBizIdNoQuery, GetMSkillSectionByBizIdNoQueryVariables>(
    GetMSkillSectionByBizIdNoDocument,
    options
  );
}
export function useGetMSkillSectionByBizIdNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillSectionByBizIdNoQuery, GetMSkillSectionByBizIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillSectionByBizIdNoQuery, GetMSkillSectionByBizIdNoQueryVariables>(
    GetMSkillSectionByBizIdNoDocument,
    options
  );
}
export type GetMSkillSectionByBizIdNoQueryHookResult = ReturnType<typeof useGetMSkillSectionByBizIdNoQuery>;
export type GetMSkillSectionByBizIdNoLazyQueryHookResult = ReturnType<typeof useGetMSkillSectionByBizIdNoLazyQuery>;
export type GetMSkillSectionByBizIdNoQueryResult = Apollo.QueryResult<
  GetMSkillSectionByBizIdNoQuery,
  GetMSkillSectionByBizIdNoQueryVariables
>;
export const GetMSkillSectionByBizIdDocument = gql`
  query GetMSkillSectionByBizId($biz_id: Int!) {
    getMSkillSectionByBizId(biz_id: $biz_id) {
      biz_id
      no
      name
      __typename
    }
  }
`;

/**
 * __useGetMSkillSectionByBizIdQuery__
 *
 * To run a query within a React component, call `useGetMSkillSectionByBizIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMSkillSectionByBizIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMSkillSectionByBizIdQuery({
 *   variables: {
 *      biz_id: // value for 'biz_id'
 *   },
 * });
 */
export function useGetMSkillSectionByBizIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMSkillSectionByBizIdQuery, GetMSkillSectionByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMSkillSectionByBizIdQuery, GetMSkillSectionByBizIdQueryVariables>(
    GetMSkillSectionByBizIdDocument,
    options
  );
}
export function useGetMSkillSectionByBizIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMSkillSectionByBizIdQuery, GetMSkillSectionByBizIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMSkillSectionByBizIdQuery, GetMSkillSectionByBizIdQueryVariables>(
    GetMSkillSectionByBizIdDocument,
    options
  );
}
export type GetMSkillSectionByBizIdQueryHookResult = ReturnType<typeof useGetMSkillSectionByBizIdQuery>;
export type GetMSkillSectionByBizIdLazyQueryHookResult = ReturnType<typeof useGetMSkillSectionByBizIdLazyQuery>;
export type GetMSkillSectionByBizIdQueryResult = Apollo.QueryResult<
  GetMSkillSectionByBizIdQuery,
  GetMSkillSectionByBizIdQueryVariables
>;
export const GetMConqAreaDocument = gql`
  query GetMConqArea {
    getMConqArea {
      id
      area_id
      __typename
    }
  }
`;

/**
 * __useGetMConqAreaQuery__
 *
 * To run a query within a React component, call `useGetMConqAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMConqAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMConqAreaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMConqAreaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMConqAreaQuery, GetMConqAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMConqAreaQuery, GetMConqAreaQueryVariables>(GetMConqAreaDocument, options);
}
export function useGetMConqAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMConqAreaQuery, GetMConqAreaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMConqAreaQuery, GetMConqAreaQueryVariables>(GetMConqAreaDocument, options);
}
export type GetMConqAreaQueryHookResult = ReturnType<typeof useGetMConqAreaQuery>;
export type GetMConqAreaLazyQueryHookResult = ReturnType<typeof useGetMConqAreaLazyQuery>;
export type GetMConqAreaQueryResult = Apollo.QueryResult<GetMConqAreaQuery, GetMConqAreaQueryVariables>;
export const GetMConqAreaByIdDocument = gql`
  query GetMConqAreaById($id: Int!) {
    getMConqAreaById(id: $id) {
      id
      area_id
      __typename
    }
  }
`;

/**
 * __useGetMConqAreaByIdQuery__
 *
 * To run a query within a React component, call `useGetMConqAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMConqAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMConqAreaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMConqAreaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMConqAreaByIdQuery, GetMConqAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMConqAreaByIdQuery, GetMConqAreaByIdQueryVariables>(GetMConqAreaByIdDocument, options);
}
export function useGetMConqAreaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMConqAreaByIdQuery, GetMConqAreaByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMConqAreaByIdQuery, GetMConqAreaByIdQueryVariables>(GetMConqAreaByIdDocument, options);
}
export type GetMConqAreaByIdQueryHookResult = ReturnType<typeof useGetMConqAreaByIdQuery>;
export type GetMConqAreaByIdLazyQueryHookResult = ReturnType<typeof useGetMConqAreaByIdLazyQuery>;
export type GetMConqAreaByIdQueryResult = Apollo.QueryResult<GetMConqAreaByIdQuery, GetMConqAreaByIdQueryVariables>;
export const GetMCancelReasonDocument = gql`
  query GetMCancelReason {
    getMCancelReason {
      sys_id
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMCancelReasonQuery__
 *
 * To run a query within a React component, call `useGetMCancelReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCancelReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCancelReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMCancelReasonQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMCancelReasonQuery, GetMCancelReasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCancelReasonQuery, GetMCancelReasonQueryVariables>(GetMCancelReasonDocument, options);
}
export function useGetMCancelReasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCancelReasonQuery, GetMCancelReasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCancelReasonQuery, GetMCancelReasonQueryVariables>(GetMCancelReasonDocument, options);
}
export type GetMCancelReasonQueryHookResult = ReturnType<typeof useGetMCancelReasonQuery>;
export type GetMCancelReasonLazyQueryHookResult = ReturnType<typeof useGetMCancelReasonLazyQuery>;
export type GetMCancelReasonQueryResult = Apollo.QueryResult<GetMCancelReasonQuery, GetMCancelReasonQueryVariables>;
export const GetMCancelReasonBySysIdDocument = gql`
  query GetMCancelReasonBySysId($sys_id: Int!) {
    getMCancelReasonBySysId(sys_id: $sys_id) {
      sys_id
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMCancelReasonBySysIdQuery__
 *
 * To run a query within a React component, call `useGetMCancelReasonBySysIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCancelReasonBySysIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCancelReasonBySysIdQuery({
 *   variables: {
 *      sys_id: // value for 'sys_id'
 *   },
 * });
 */
export function useGetMCancelReasonBySysIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetMCancelReasonBySysIdQuery, GetMCancelReasonBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCancelReasonBySysIdQuery, GetMCancelReasonBySysIdQueryVariables>(
    GetMCancelReasonBySysIdDocument,
    options
  );
}
export function useGetMCancelReasonBySysIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCancelReasonBySysIdQuery, GetMCancelReasonBySysIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCancelReasonBySysIdQuery, GetMCancelReasonBySysIdQueryVariables>(
    GetMCancelReasonBySysIdDocument,
    options
  );
}
export type GetMCancelReasonBySysIdQueryHookResult = ReturnType<typeof useGetMCancelReasonBySysIdQuery>;
export type GetMCancelReasonBySysIdLazyQueryHookResult = ReturnType<typeof useGetMCancelReasonBySysIdLazyQuery>;
export type GetMCancelReasonBySysIdQueryResult = Apollo.QueryResult<
  GetMCancelReasonBySysIdQuery,
  GetMCancelReasonBySysIdQueryVariables
>;
export const GetMCancelReasonBySysIdNoDocument = gql`
  query GetMCancelReasonBySysIdNo($sys_id: Int!, $no: Int!) {
    getMCancelReasonBySysIdNo(sys_id: $sys_id, no: $no) {
      sys_id
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMCancelReasonBySysIdNoQuery__
 *
 * To run a query within a React component, call `useGetMCancelReasonBySysIdNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMCancelReasonBySysIdNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMCancelReasonBySysIdNoQuery({
 *   variables: {
 *      sys_id: // value for 'sys_id'
 *      no: // value for 'no'
 *   },
 * });
 */
export function useGetMCancelReasonBySysIdNoQuery(
  baseOptions: Apollo.QueryHookOptions<GetMCancelReasonBySysIdNoQuery, GetMCancelReasonBySysIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMCancelReasonBySysIdNoQuery, GetMCancelReasonBySysIdNoQueryVariables>(
    GetMCancelReasonBySysIdNoDocument,
    options
  );
}
export function useGetMCancelReasonBySysIdNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMCancelReasonBySysIdNoQuery, GetMCancelReasonBySysIdNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMCancelReasonBySysIdNoQuery, GetMCancelReasonBySysIdNoQueryVariables>(
    GetMCancelReasonBySysIdNoDocument,
    options
  );
}
export type GetMCancelReasonBySysIdNoQueryHookResult = ReturnType<typeof useGetMCancelReasonBySysIdNoQuery>;
export type GetMCancelReasonBySysIdNoLazyQueryHookResult = ReturnType<typeof useGetMCancelReasonBySysIdNoLazyQuery>;
export type GetMCancelReasonBySysIdNoQueryResult = Apollo.QueryResult<
  GetMCancelReasonBySysIdNoQuery,
  GetMCancelReasonBySysIdNoQueryVariables
>;
export const GetMRejectReasonDocument = gql`
  query GetMRejectReason {
    getMRejectReason {
      kind
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMRejectReasonQuery__
 *
 * To run a query within a React component, call `useGetMRejectReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMRejectReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMRejectReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMRejectReasonQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMRejectReasonQuery, GetMRejectReasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMRejectReasonQuery, GetMRejectReasonQueryVariables>(GetMRejectReasonDocument, options);
}
export function useGetMRejectReasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMRejectReasonQuery, GetMRejectReasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMRejectReasonQuery, GetMRejectReasonQueryVariables>(GetMRejectReasonDocument, options);
}
export type GetMRejectReasonQueryHookResult = ReturnType<typeof useGetMRejectReasonQuery>;
export type GetMRejectReasonLazyQueryHookResult = ReturnType<typeof useGetMRejectReasonLazyQuery>;
export type GetMRejectReasonQueryResult = Apollo.QueryResult<GetMRejectReasonQuery, GetMRejectReasonQueryVariables>;
export const GetMRejectReasonByKindDocument = gql`
  query GetMRejectReasonByKind($kind: Int!) {
    getMRejectReasonByKind(kind: $kind) {
      kind
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMRejectReasonByKindQuery__
 *
 * To run a query within a React component, call `useGetMRejectReasonByKindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMRejectReasonByKindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMRejectReasonByKindQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useGetMRejectReasonByKindQuery(
  baseOptions: Apollo.QueryHookOptions<GetMRejectReasonByKindQuery, GetMRejectReasonByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMRejectReasonByKindQuery, GetMRejectReasonByKindQueryVariables>(
    GetMRejectReasonByKindDocument,
    options
  );
}
export function useGetMRejectReasonByKindLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMRejectReasonByKindQuery, GetMRejectReasonByKindQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMRejectReasonByKindQuery, GetMRejectReasonByKindQueryVariables>(
    GetMRejectReasonByKindDocument,
    options
  );
}
export type GetMRejectReasonByKindQueryHookResult = ReturnType<typeof useGetMRejectReasonByKindQuery>;
export type GetMRejectReasonByKindLazyQueryHookResult = ReturnType<typeof useGetMRejectReasonByKindLazyQuery>;
export type GetMRejectReasonByKindQueryResult = Apollo.QueryResult<
  GetMRejectReasonByKindQuery,
  GetMRejectReasonByKindQueryVariables
>;
export const GetMRejectReasonByKindNoDocument = gql`
  query GetMRejectReasonByKindNo($kind: Int!, $no: Int!) {
    getMRejectReasonByKindNo(kind: $kind, no: $no) {
      kind
      no
      reason
      __typename
    }
  }
`;

/**
 * __useGetMRejectReasonByKindNoQuery__
 *
 * To run a query within a React component, call `useGetMRejectReasonByKindNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMRejectReasonByKindNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMRejectReasonByKindNoQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *      no: // value for 'no'
 *   },
 * });
 */
export function useGetMRejectReasonByKindNoQuery(
  baseOptions: Apollo.QueryHookOptions<GetMRejectReasonByKindNoQuery, GetMRejectReasonByKindNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMRejectReasonByKindNoQuery, GetMRejectReasonByKindNoQueryVariables>(
    GetMRejectReasonByKindNoDocument,
    options
  );
}
export function useGetMRejectReasonByKindNoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMRejectReasonByKindNoQuery, GetMRejectReasonByKindNoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMRejectReasonByKindNoQuery, GetMRejectReasonByKindNoQueryVariables>(
    GetMRejectReasonByKindNoDocument,
    options
  );
}
export type GetMRejectReasonByKindNoQueryHookResult = ReturnType<typeof useGetMRejectReasonByKindNoQuery>;
export type GetMRejectReasonByKindNoLazyQueryHookResult = ReturnType<typeof useGetMRejectReasonByKindNoLazyQuery>;
export type GetMRejectReasonByKindNoQueryResult = Apollo.QueryResult<
  GetMRejectReasonByKindNoQuery,
  GetMRejectReasonByKindNoQueryVariables
>;
export const GetVtSelectStoreListByBrandIdDocument = gql`
  query GetVTSelectStoreListByBrandId($brand_id: Int!) {
    getVTSelectStoreListByBrandId(brand_id: $brand_id) {
      address1
      address2
      brand_id
      city
      id
      name
      phone
      state
      zip_code
      __typename
    }
  }
`;

/**
 * __useGetVtSelectStoreListByBrandIdQuery__
 *
 * To run a query within a React component, call `useGetVtSelectStoreListByBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVtSelectStoreListByBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVtSelectStoreListByBrandIdQuery({
 *   variables: {
 *      brand_id: // value for 'brand_id'
 *   },
 * });
 */
export function useGetVtSelectStoreListByBrandIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetVtSelectStoreListByBrandIdQuery, GetVtSelectStoreListByBrandIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVtSelectStoreListByBrandIdQuery, GetVtSelectStoreListByBrandIdQueryVariables>(
    GetVtSelectStoreListByBrandIdDocument,
    options
  );
}
export function useGetVtSelectStoreListByBrandIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVtSelectStoreListByBrandIdQuery,
    GetVtSelectStoreListByBrandIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVtSelectStoreListByBrandIdQuery, GetVtSelectStoreListByBrandIdQueryVariables>(
    GetVtSelectStoreListByBrandIdDocument,
    options
  );
}
export type GetVtSelectStoreListByBrandIdQueryHookResult = ReturnType<typeof useGetVtSelectStoreListByBrandIdQuery>;
export type GetVtSelectStoreListByBrandIdLazyQueryHookResult = ReturnType<
  typeof useGetVtSelectStoreListByBrandIdLazyQuery
>;
export type GetVtSelectStoreListByBrandIdQueryResult = Apollo.QueryResult<
  GetVtSelectStoreListByBrandIdQuery,
  GetVtSelectStoreListByBrandIdQueryVariables
>;
