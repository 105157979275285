/**
 * Figma ID: 10-08-01-04, 10-08-01-05
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { UseFormWatch } from 'react-hook-form';
import type { InputDataModel } from '../GiftChoice';

type ConfirmUpdateDeadlineContentModel = {
  watch: UseFormWatch<InputDataModel>;
  selectedGift?: string;
};
function ConfirmUpdateDeadlineContent(props: ConfirmUpdateDeadlineContentModel) {
  const { watch, selectedGift } = props;
  const currentUpdatedDate = watch('deadline');
  const deadlineDateString = `${String(currentUpdatedDate?.getFullYear())}年${String(
    (currentUpdatedDate?.getMonth() ?? 0) + 1
  )}月${String(currentUpdatedDate?.getDate())}日`;
  const currentPrice = watch('price');
  return (
    <Box>
      <Box pb={16}>
        <Text variant="body14">以下の商品で更新してよろしいですか？</Text>
      </Box>
      <Box>
        <Text variant="body14">・{selectedGift}</Text>
      </Box>
      <Box>
        <Text variant="body14">・発行期限:{deadlineDateString}</Text>
      </Box>
      {currentPrice !== undefined && (
        <Box>
          <Text variant="body14">・ギフト単価:¥{currentPrice}</Text>
        </Box>
      )}
    </Box>
  );
}

export default ConfirmUpdateDeadlineContent;
