/**
 * Figma ID: 01-02-03
 * 名称: ログイン
 */
import React from 'react';
import { passwordPattern, passwordLength } from 'components/const';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  onResetPassword: (args: { confirmationCode: string; password: string }) => Promise<void>;
};
export default function ResetPassword({ onResetPassword }: Props) {
  const codeLength = 6;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<{ inputCode: string; inputPassword: string; confirmPassword: string }>({ reValidateMode: 'onChange' });
  const passwordWatch = watch('inputPassword');

  const onSubmit = handleSubmit(async (data) => {
    await onResetPassword({ confirmationCode: data.inputCode, password: data.inputPassword });
  });

  return (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定" />
      <Box display="flex" flexDirection="column" gap={24} pt={24} px={24}>
        <Box display="flex" flexDirection="column" gap={8}>
          <Box width={300}>
            <Text variant="body14">メールに記載された6桁の確認コードと新しいパスワードを入力してください。</Text>
          </Box>
          <Box width={300} pt={8}>
            <Text color="cautionRed" variant="caption12">
              パスワードは8文字で、1つ以上の数字・特殊文字 (^ $ * . [ ] {} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
              &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
            </Text>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={8}>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>確認コード</InputLabel>
            <RhfInput
              placeholder="6桁のコードを入力"
              name="inputCode"
              control={control}
              rules={{ validate: (value = '') => value.length === codeLength || '6桁のコードを入力してください' }}
            />
            {errors.inputCode?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>パスワード</InputLabel>
            <RhfInput
              placeholder="パスワード"
              type="password"
              name="inputPassword"
              control={control}
              rules={{
                validate: (value) =>
                  passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
                minLength: { value: passwordLength, message: '8文字以上で入力してください' },
              }}
            />
            {errors.inputPassword?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.inputPassword.message}`}</Text>
            )}
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <InputLabel>パスワード（確認）</InputLabel>
            <RhfInput
              type="password"
              placeholder="パスワード（確認）"
              name="confirmPassword"
              control={control}
              rules={{ validate: (value) => value === passwordWatch || 'パスワードが一致しません。' }}
            />
            {errors.confirmPassword?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.confirmPassword.message}`}</Text>
            )}
          </Box>
        </Box>
        <Box display="grid" justifyContent="center" alignItems="center">
          <MainButton
            disabled={!isValid}
            width={160}
            onClick={() => {
              onSubmit();
            }}
          >
            設定
          </MainButton>
        </Box>
      </Box>
    </Box>
  );
}
