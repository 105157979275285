import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s03/type';
import React, { useState } from 'react';
import { useUpdateTScoreVisibleByWorkIdMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';

enum ModalStep {
  Delete = 1,
  Complete = 2,
}

export function CommentDeleteModal(props: {
  isOpen: boolean;
  workId: number;
  onClose: () => void;
  onDelete: () => void;
}) {
  const { isOpen, workId, onClose, onDelete } = props;
  const { openErrorModal } = useErrorModal();
  const [updateTScoreVisibleByWorkIdMutation, { loading: tScoreVisibleByWorkIdLoading }] =
    useUpdateTScoreVisibleByWorkIdMutation();

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Delete);

  const onModalClose = () => {
    setModalStep(ModalStep.Delete);
    onClose();
  };

  const deleteModal: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        コメント削除
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" flex="1">
          <Text variant="body14">このコメントを削除しますか？</Text>
          <Text variant="body14">削除した情報は元に戻せません。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            updateTScoreVisibleByWorkIdMutation({
              variables: {
                work_id: workId,
              },
            })
              .then(() => {
                setModalStep(ModalStep.Complete);
                onDelete();
              })
              .catch((e) => {
                onModalClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }}
        >
          削除
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210,
  };

  const completeModal: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        コメント削除 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
          <Text variant="body14">情報の削除が完了しました。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189,
  };

  const modalContent = modalStep === ModalStep.Delete ? deleteModal : completeModal;

  return (
    <>
      {tScoreVisibleByWorkIdLoading && <Loading />}
      <Modal isOpen={isOpen} onClose={() => onModalClose()} {...modalContent} />
    </>
  );
}
