/**
 * Figma ID: 10-08-01-01, 10-08-02-01, 10-08-03-01, 10-08-03-05
 * 名称: eギフト設定（ヘッダー）
 */
import React from 'react';
import { Header } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';

function EGiftHeader() {
  return (
    <Header>
      <Box>
        <Text color="darkBlue" variant="h1">
          eギフト設定
        </Text>
      </Box>
    </Header>
  );
}

export default EGiftHeader;
