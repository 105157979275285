/**
 * Figma ID: 07-02-03
 * 名称: 逆オファー（受入店情報）
 */
import { Info } from 'components/info';
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import Divider from 'components/divider/divider';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

type TpmemDetailContentModel = {
  tpmemId?: number;
};
function TpmemDetailContent(props: TpmemDetailContentModel) {
  const { tpmemId } = props;
  const {
    data: tpmemDetailData,
    loading: tpmemDetailLoading,
    error: tpmemDetailError,
  } = gql.useGetVtMemberBaseByIdQuery({
    variables: { id: tpmemId ?? 0 },
  });
  // 郵便番号にハイフンを挿入する
  function formatZipCode(zipCode: string) {
    return zipCode.replace(/(\d{3})(\d{4})/, '$1-$2');
  }

  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (tpmemDetailError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [tpmemDetailError, openErrorModal]);

  const tpmemInfo = tpmemDetailData?.getVTMemberBaseById;
  const address = `〒 ${formatZipCode(tpmemInfo?.zip_code ?? '')} ${tpmemInfo?.state ?? ''} ${tpmemInfo?.city ?? ''} ${
    tpmemInfo?.address ?? ''
  } ${tpmemInfo?.address2 ?? ''}`;
  const mcoAddress = `〒 ${formatZipCode(tpmemInfo?.mco_zip_code ?? '')} ${tpmemInfo?.mco_state ?? ''} ${
    tpmemInfo?.mco_city ?? ''
  } ${tpmemInfo?.mco_address ?? ''} ${tpmemInfo?.mco_address2 ?? ''}`;
  const bank = `${tpmemInfo?.bank ?? ''} ${tpmemInfo?.branch_name ?? ''} ${tpmemInfo?.account_type ?? ''} ${
    tpmemInfo?.account_no ?? ''
  } ${tpmemInfo?.account_name ?? ''}`;
  const mcoItems = [
    {
      label: '法人番号',
      content: tpmemInfo?.corporate_no,
    },
    {
      label: '電話番号',
      content: tpmemInfo?.mco_phone,
    },
    {
      label: '代表者',
      content: tpmemInfo?.mco_m_name,
    },
    {
      label: '',
      content: tpmemInfo?.mco_m_kana,
    },
    {
      label: '担当者',
      content: tpmemInfo?.mco_t_name,
    },
    {
      label: '',
      content: tpmemInfo?.mco_t_kana,
    },
    {
      label: '担当者電話番号',
      content: tpmemInfo?.mco_t_phone,
    },
  ];
  const managedCompany = () => (
    <>
      <Box gap={8}>
        <Text variant="body14">{tpmemInfo?.mco_name}</Text>
      </Box>
      <Box gap={8}>
        <Text variant="body14">{mcoAddress}</Text>
      </Box>
      {mcoItems.map((val) => (
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex">
            <Box width={112}>
              <Text variant="body14" color="darkGray">
                {val.label}
              </Text>
            </Box>
            <Box>
              <Text variant="body14">{val.content}</Text>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box pr={18}>
          <img
            alt="tpmemImage"
            src={tpmemInfo?.image ?? ''}
            width={90}
            height={60}
            style={{ paddingRight: 2, objectFit: 'contain' }}
          />
        </Box>
        <Text variant="h2" color="darkBlue">
          {tpmemInfo?.store_name}
        </Text>
      </Box>
      <Box py={8}>
        <Divider option="horizontal" />
      </Box>
      <div
        style={{
          overflow: 'auto',
          height: 450,
        }}
      >
        {tpmemDetailLoading ? (
          <Loading />
        ) : (
          <Info
            items={[
              {
                label: '店舗コード',
                content: tpmemInfo?.code,
              },
              {
                label: '店舗名',
                content: tpmemInfo?.store_name,
              },
              {
                label: '住所',
                content: address,
              },
              {
                label: '電話番号',
                content: tpmemInfo?.phone,
              },
              {
                label: 'メールアドレス(ID)',
                content: tpmemInfo?.email,
              },
              {
                label: '営業時間',
                content: tpmemInfo?.opening,
              },
              {
                label: '駐車場',
                content: tpmemInfo?.parking,
              },
              {
                label: '責任者氏名',
                content: tpmemInfo?.manager,
              },
              {
                label: '責任者電話番号',
                content: tpmemInfo?.mgr_phone,
              },
              {
                label: '事業者',
                content: managedCompany(),
              },
              {
                label: '業種',
                content: tpmemInfo?.biz_name,
              },
              {
                label: 'ブランド',
                content: tpmemInfo?.brand_name,
              },
              {
                label: 'またバイトしたい',
                content: tpmemInfo?.again,
              },
              {
                label: '友達招待コード',
                content: tpmemInfo?.invite_code,
              },
              {
                label: '総雇用回数',
                content: tpmemInfo?.total_adopt,
              },
              {
                label: 'お気に入り登録数',
                content: tpmemInfo?.favorite_num,
              },
              {
                label: '口座情報',
                content: bank,
              },
              {
                label: '加盟日',
                content: tpmemInfo?.join_date,
              },
              {
                label: '退会日',
                content: tpmemInfo?.quit_date,
              },
              {
                label: '休止日',
                content: tpmemInfo?.pause_date,
              },
              {
                label: '再開日',
                content: tpmemInfo?.restart_date,
              },
              {
                label: '新規登録日',
                content: tpmemInfo?.created_date,
              },
            ]}
          />
        )}
      </div>
    </Box>
  );
}

export { TpmemDetailContent };
