import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  children: ReactNode;
  width?: number;
  height?: number;
  maxHeight?: number | string;
  overflow?: string;
}

const StyledDiv = styled.div<{
  width?: number;
  height?: number;
  maxHeight?: number | string;
  overflow?: string;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  max-height: ${({ maxHeight }) => {
    if (typeof maxHeight === 'number') {
      return `${maxHeight}px`;
    }
    if (typeof maxHeight === 'string') {
      return maxHeight;
    }
    return 'none';
  }};
  background-color: ${GENERIC_COLOR.WHITE};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: ${({ overflow }) => (overflow ? `${overflow}` : 'visible')};
`;

export function Card({ children, width, height, maxHeight, overflow }: Props) {
  return (
    <StyledDiv width={width} height={height} maxHeight={maxHeight} overflow={overflow}>
      {children}
    </StyledDiv>
  );
}
