import React, { useMemo, useRef, useState } from 'react';
import { styled } from '@linaria/react';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { SelectForm } from 'components/selectForm';
import { Modal } from 'components/newModal';
import {
  GetSWithholdDayByYearQuery,
  SWithholdDay,
  useGetSWithholdDayByYearQuery,
  useGetSYearScheduleGpByWithholdRegistQuery,
  useUploadSWithholdDayMutation,
} from 'graphql/graphql-ow';
import { downloadCsvFile } from 'components/csv';
import { uploadFile } from 'components/api';
import { useErrorModal } from 'components/error/errorModalProvider';
import { GENERIC_COLOR, PRIMARY_COLOR } from 'components/assets/css/style';

interface Props {
  withHoldingTax: SWithholdDay[];
}

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 420px);
  max-height: calc(100vh - 420px);
  overflow: auto;
  width: 100%;
`;

const StyledListHeader = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 40px;
  background: ${PRIMARY_COLOR.BLUE_GRAY};
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 32px;
  border-bottom: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
`;

enum ModalStep {
  NULL = 0,
  OVERWRITE_REGISTRATION = 1,
  REGISTER_CONFIRMATION = 2,
  REGISTER_COMPLETED = 3,
}

// Figma管理番号
// 10-05-01
// 10-05-02
// 10-05-03
// 10-05-04
// 10-05-05
// 10-05-06
// 10-05-07
// 10-05-08
export function WithholdingTaxConfig({ withHoldingTax }: Props) {
  const {
    data: scheduleData,
    loading: scheduleLoading,
    refetch: refetchSchedule,
  } = useGetSYearScheduleGpByWithholdRegistQuery();
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  // ファイルアップロード用ローディング
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const csvFileName = `源泉徴収日額表設定${selectedYear}.csv`;
  const {
    data: withHoldingTaxData,
    loading: withHoldingTaxLoading,
    refetch: refetchList,
  } = useGetSWithholdDayByYearQuery({
    variables: {
      year: Number(selectedYear),
    },
    fetchPolicy: 'no-cache',
  });

  // 源泉徴収日額表情報一括登録
  const [uploadSWithholdDay] = useUploadSWithholdDayMutation();

  const lastDataUpdated = withHoldingTaxData?.getSWithholdDayByYear.schedule.withhold_regist;
  const handleClickHiddenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // 翌年取得
  const nextYear = useMemo(() => new Date().getFullYear() + 1, []);

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (file.size === 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '空のファイルはアップロードできません。' });
          return;
        }

        // 拡張子チェック
        const fileExtension = file.name.split('.').pop();

        if (fileExtension?.toLowerCase() !== 'csv') {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: 'CSVファイルを指定してください。' });
          return;
        }

        setSelectedFile(file);

        // ファイル選択欄を空にする
        // ※この処理を入れないと再度ファイル選択してもonChangeが発火しない
        event.target.value = '';

        // 次のダイアログを表示する
        setModalStep(ModalStep.REGISTER_CONFIRMATION);
      };
    }
  };

  // ファイルアップロード処理
  const uploadCsvFile = React.useCallback(async () => {
    try {
      const file = selectedFile;

      if (!file) return;

      // ファイルアップロード中はローディング表示
      setFileUploadLoading(true);
      // CSVファイルアップロード
      await uploadFile({ filePath: `temporary/${file.name}`, file });

      // アップロードAPI実行
      const result = await uploadSWithholdDay({
        variables: {
          s3_bucket: process.env.REACT_APP_UPLOAD_S3_BUCKET || '',
          s3_filepath: `temporary/${file.name}`,
          year: nextYear,
        },
      });

      // 年を再取得し直す
      await refetchSchedule();
      // 一覧上の情報を更新する
      await refetchList();

      // 更新対象件数
      const resultRows = Number(result.data?.uploadSWithholdDay.resultRows);

      // 該当年のデータが存在しない場合
      if (resultRows === -1) {
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: '登録対象外の年のデータは登録できません。' });
        return;
      }

      if (Number(result.data?.uploadSWithholdDay.resultRows) === 0) {
        setModalStep(ModalStep.NULL);
        openErrorModal({ title: '', message: '登録対象のデータが存在しませんでした。' });
        return;
      }

      // 完了ダイアログ表示
      setModalStep(ModalStep.REGISTER_COMPLETED);
    } catch (e) {
      console.log(e);
      setModalStep(ModalStep.NULL);
      openErrorModal({ message: '登録に失敗しました。' });
    } finally {
      // ローディング解除
      setFileUploadLoading(false);
    }
  }, [selectedFile, uploadSWithholdDay, refetchList, refetchSchedule, openErrorModal, nextYear]);

  // 年選択用Selectオプション
  const scheduleSelectOptions = React.useMemo(() => {
    const yearList = scheduleData?.getSYearScheduleGpByWithholdRegist || [];
    return yearList.map((data) => ({ label: String(data.year), value: String(data.year) }));
  }, [scheduleData]);

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.OVERWRITE_REGISTRATION:
        return {
          width: 400,
          height: 'fit-content',
          header: (
            <Text variant="h2" color="darkBlue">
              登録
            </Text>
          ),
          content: (
            <Box width={350}>
              <Text variant="body14">
                源泉徴収設定用のデータを登録します。
                <br />
                この操作は中断できません。
                <br />
                翌年のデータに関してのみアップロードが何度でも可能です。
                <br />
                <br />
                <Box display="grid">
                  <Text variant="body14" bold color="blueGray">
                    アップロードする年
                  </Text>
                  <Text variant="body14">・{nextYear}年</Text>
                </Box>
              </Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  handleClickHiddenFileInput();
                }}
              >
                登録
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.REGISTER_CONFIRMATION:
        return {
          width: 400,
          height: 'fit-content',
          header: (
            <Text variant="h2" color="darkBlue">
              登録
            </Text>
          ),
          content: (
            <Box display="grid" width={350} gap={10}>
              <Text variant="body14">
                下記のデータを登録します。
                <br />
                <br />・{selectedFile && selectedFile.name}
                <br />
                <br />
                よろしいですか？
              </Text>
              <Text variant="caption12" color="cautionRed">
                {uploadErrorMessage}
              </Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                  setUploadErrorMessage('');
                }}
              >
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.OVERWRITE_REGISTRATION);
                  setUploadErrorMessage('');
                }}
              >
                戻る
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  uploadCsvFile();
                }}
              >
                登録
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.REGISTER_COMPLETED:
        return {
          width: 384,
          height: 'fit-content',
          header: (
            <Text variant="h2" color="darkBlue">
              登録 完了
            </Text>
          ),
          content: <Text variant="body14">情報の登録が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                閉じる
              </MainButton>
            </Box>
          ),
        };

      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [modalStep, nextYear, selectedFile, uploadErrorMessage, uploadCsvFile]);

  const holdingTaxData = React.useMemo(
    () =>
      withHoldingTaxData?.getSWithholdDayByYear.withhold.map((item, index) => ({
        uniqueKey: index,
        price: item.price ?? '0',
        tax: `￥${item.tax ?? 0}`,
      })) || [],
    [withHoldingTaxData]
  );

  const createCsvData = (fileName: string, data?: GetSWithholdDayByYearQuery) => {
    const column = [
      { header: '年度', key: 'year' },
      { header: `給与金額 未満`, key: 'price' },
      { header: '税額', key: 'tax' },
    ];
    const rows =
      data?.getSWithholdDayByYear.withhold.map((val) => ({
        year: val.year,
        price: Number(val.price?.replace(/,/, '')),
        tax: val.tax,
      })) ?? [];
    downloadCsvFile(column, rows, fileName, undefined, true);
  };

  return (
    <>
      {(withHoldingTaxLoading || scheduleLoading || fileUploadLoading) && <Loading />}
      <Box px={24} py={16} display="flex" flexDirection="column" alignItems="center" gap={16} width="100%">
        <Box justifyContent="space-between" display="flex" gap={8} alignItems="center" width="100%">
          <Box display="flex" height="100%" alignItems="center" gap={5}>
            <Text variant="h2" color="darkBlue">
              源泉徴収設定
            </Text>
            <Box flex="auto">
              <Text variant="caption12" color="darkBlue">
                源泉徴収税の設定を行います
              </Text>
            </Box>
          </Box>
          <MainButton width={165} icon="upload" onClick={() => setModalStep(ModalStep.OVERWRITE_REGISTRATION)}>
            登録
          </MainButton>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <SelectForm
            width={160}
            label="年"
            value={selectedYear}
            options={scheduleSelectOptions}
            onChange={(value) => {
              setSelectedYear(value);
            }}
            placeholder="年を選択"
          />
          <Box display="flex" alignItems="center" gap={10}>
            {lastDataUpdated && (
              <Text variant="caption12" color="darkBlue">
                最終データアップロード登録 : {lastDataUpdated}
              </Text>
            )}
            <MainButton
              width={165}
              icon="download"
              variant="secondary"
              onClick={() => createCsvData(csvFileName, withHoldingTaxData)}
              disabled={selectedYear === '' || withHoldingTaxData?.getSWithholdDayByYear.withhold.length === 0}
            >
              CSVダウンロード
            </MainButton>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box flex="auto" width="100%">
          <ul>
            <StyledListHeader>
              <Box display="flex" alignItems="center" width={184}>
                <Text variant="caption12" color="white">
                  給与金額 未満
                </Text>
              </Box>
              <Divider option="vertical" length={24} />
              <Box display="flex" alignItems="center">
                <Text variant="caption12" color="white">
                  税額
                </Text>
              </Box>
            </StyledListHeader>
          </ul>
          <StyledOverflowWrapper>
            <ul>
              {holdingTaxData.map((item) => (
                <StyledListItem key={item.uniqueKey}>
                  <Box width={184}>
                    <Text variant="body14" color="darkGray">
                      {item.price}
                    </Text>
                  </Box>
                  <Divider option="vertical" length={32} />
                  <Text variant="body14" color="darkGray">
                    {item.tax}
                  </Text>
                </StyledListItem>
              ))}
            </ul>
          </StyledOverflowWrapper>
        </Box>
      </Box>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(0)}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
      />
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} accept="text/csv" />
    </>
  );
}
