import { Box } from 'components/box';
import React, { useState, useEffect } from 'react';
import { useGetVtMemberBaseRecvNotifyByIdMutation, VtMemberBase } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import NoImageLarge from 'components/assets/images/no-image-large.svg';
import { Text } from 'components/text';
import { ToggleButton } from 'components/toggleButton';
import { Card } from 'components/card';
import { useParams } from 'react-router-dom';
import { MemberStatus } from '../const';
import { ChangeBigImgWithStatus } from '../components/changeBigImgWithStatus';
import { RegisteredCard } from './registeredCard';
import { UnRegisteredCard } from './unRegisteredCard';

import { StatusChangeModal } from '../components/modal';

enum ModalType {
  None = 'none',
  StatusChange = 'statusChange',
}

export function S03Details() {
  const { id } = useParams();
  const { openErrorModal } = useErrorModal();
  const [
    getVtMemberBaseRecvNotifyByIdMutation,
    { data: vTMemberBaseData, loading: vTMemberBaseLoading, error: vTMemberBaseError },
  ] = useGetVtMemberBaseRecvNotifyByIdMutation({
    variables: {
      id: Number(id),
    },
  });
  const item = vTMemberBaseData?.getVTMemberBaseRecvNotifyById as VtMemberBase;
  const memberStatus = item?.status;
  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.None);
  const [activeStatus, setActiveStatus] = useState<number>(0);

  useEffect(() => {
    if (vTMemberBaseData === undefined && Number(id) !== 0 && Number(id) !== undefined) {
      getVtMemberBaseRecvNotifyByIdMutation({ variables: { id: Number(id) } });
    }
  }, [getVtMemberBaseRecvNotifyByIdMutation, id, vTMemberBaseData]);

  useEffect(() => {
    if (vTMemberBaseError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTMemberBaseError, openErrorModal]);

  useEffect(() => {
    if (memberStatus !== undefined) {
      setActiveStatus(memberStatus === MemberStatus.Active ? MemberStatus.Active : MemberStatus.Suspended);
    }
  }, [memberStatus]);

  return memberStatus === MemberStatus.Active || memberStatus === MemberStatus.Suspended ? (
    <>
      {vTMemberBaseLoading && <Loading />}
      <Box display="flex" flex="1" flexDirection="column" gap={15}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '60',
            backgroundColor: `${activeStatus === MemberStatus.Suspended ? 'rgba(229, 0, 0, 0.1)' : ''}`,
          }}
        >
          <Box display="flex" gap={20} height="100%" alignItems="center">
            <ChangeBigImgWithStatus status={activeStatus}>
              <img src={item.image || NoImageLarge} alt="" style={{ width: '90px', height: '60px' }} />
            </ChangeBigImgWithStatus>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Text color="darkBlue" variant="h1">
                {item?.store_name}
              </Text>
            </Box>
          </Box>
          <ToggleButton
            currentValue={activeStatus?.toString()}
            onClick={(value) => setActiveModalType(ModalType.StatusChange)}
            options={[
              { label: '休止中', value: MemberStatus.Suspended.toString(), activeColor: '#E50000' },
              { label: 'アクティブ', value: MemberStatus.Active.toString() },
            ]}
          />
        </div>
        <Box display="flex" height="calc(100% - 66px)">
          <RegisteredCard data={item} />
        </Box>
      </Box>
      <StatusChangeModal
        isOpen={activeModalType === ModalType.StatusChange}
        onClose={() => setActiveModalType(ModalType.None)}
        status={activeStatus === MemberStatus.Active ? MemberStatus.Active : MemberStatus.Suspended}
        toggleState={() =>
          setActiveStatus(activeStatus === MemberStatus.Active ? MemberStatus.Suspended : MemberStatus.Active)
        }
      />
    </>
  ) : (
    <Box display="flex" flex="1" flexDirection="column" gap={26}>
      <Box display="flex" flexDirection="row" alignItems="center" height={60}>
        <Box display="flex" gap={20} height="100%" alignItems="center">
          {memberStatus === MemberStatus.Unregistered && (
            <ChangeBigImgWithStatus status={MemberStatus.Unregistered}>
              <img src={item.image || NoImageLarge} alt="" style={{ width: '90px', height: '60px' }} />
            </ChangeBigImgWithStatus>
          )}
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            {memberStatus === MemberStatus.Unregistered && (
              <Text color="darkBlue" variant="h1">
                {item?.store_name}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" height="calc(100% - 66px)">
        <Card overflow="hidden">
          <UnRegisteredCard data={item} />
        </Card>
      </Box>
    </Box>
  );
}
