// 運営管理者情報 ow
export enum Privilege {
  SUPER_USER = 0, // 管理アカウント
  ADMIN = 1, // 管理者
  GENERAL = 2, // 一般
}

// 加盟店情報 ow/mw
export enum MemberStoreStatus {
  UNREGISTERD = 0, // 未登録
  ACTIVE = 1, // アクティブ
  PAUSE = 2, // 休止
}

// バイト募集 ow/mw
export enum RecruitStatus {
  NON_ACTIVE = 0, // 未アクティブ（下書き）
  BEFORE_RECRUITMENT = 1, // アクティブ（募集前）
  RECRUITING = 2, // アクティブ（募集中）
  ACCEPTED = 3, // アクティブ（採用確定）
  FINISHED = 4, // アクティブ（募集終了）
  RECRUITING_DISABLE = 5, // 募集不可（可能件数オーバー）
  RECRUITING_STOP = 6, // 募集停止
  FORCED_STOP = 7, // 募集強制停止（運営）
}

// バイト募集日程種別 mw
export enum RepeatsType {
  SINGLE_DAY = 1, // 単日
  WEEKLY = 2, // 週定期
}

// バイト申込状態
export enum ArbeitApplicationStatus {
  APPLYING = 0, // 申込中
  BEFORE_CANCEL = 1, // 成約前キャンセル（本人）
  ADOPTION = 2, // 採用（成約）
  AUTO_ADOPTION = 3, // 自動採用
  NOT_ADOPTED = 4, // 不採用
  AUTO_NOT_ADOPTED = 5, // 自動不採用
  AFTER_CANCEL = 6, // 成約後キャンセル（本人）
  STOP = 7, // 募集停止（店舗）
  NO_SHOW = 8, // 無断欠勤
  FORCE_STOP = 9, // 募集強制停止（運営）
  DELETE_APPLY = 10, // 申込削除（バイトラ一時利用停止、無期限停止）
}

// バイト応募 ow/mw
export enum ApplicationStatus {
  APPLYING = 0, // 申込中
  ADOPTION = 1, // 採用
  NOT_ADOPTED = 2, // 不採用
}

// 成約バイト ow/mw
export enum AgreementStatus {
  ALL = -1, // 全て
  BEFORE = 0, // バイト前
  STAND_BY = 1, // スタンバイ
  IN_PROGRESS = 2, // バイト中
  NO_CHECK_IN = 3, // チェックインなし
  NO_CHECK_OUT = 4, // チェックアウトなし
  NO_CHECK_IN_OUT = 5, // イン／アウトなし
  WAITING_COMPLETE_QR_CHECKOUT_FINISHED = 6, // 完了待ち(QRチェックアウト完了)
  WAITING_COMPLETE_QR_NO_CHECKOUT = 7, // 完了待ち(QRチェックアウトなし)
  REQUEST_CORRECTION_IN_PROGRESS = 8, // 修正依頼(依頼中)
  REQUEST_CORRECTION_REJECTED = 9, // 修正依頼(依頼否認)
  COMPLETE = 10, // 完了
  CANCEL = 11, // キャンセル
  NO_SHOW = 12, // バックレ
  ARBEIT_START = 21, // （所属・サブ・グループ）バイト開始
  ARBEIT_END = 22, // （所属・サブ・グループ）勤務完了
}

// バイトラベラー情報 ow/mw
export enum BitravelerInfoStatus {
  ACTIVE = 1, // アクティブ
  TEMPORARY_PAUSE = 2, // 一時停止
  STOP = 3, // 停止
  PAUSE = 4, // 休止
}

// 本人確認情報 ow
export enum VerifyInfoOwIdentification {
  NOT_SUBMITTED = 0, // 未提出
  UNCONFIRMED_IDENNTITY = 1, // 本人未確認
  CONFIRMED_IDENTITY = 2, // 本人確認済み
  REJECTED = 3, // 否認
  REQUEST_RECONFIRMATION = 4, // 再確認依頼
}

// 本人確認情報 mw
export enum VerifyInfoMwIdentification {
  NOT_SUBMITTED = 0, // 未提出
  IN_PROGRESS = 1, // 審査中
  APPROVED = 2, // 承認済み
  NOT_SUBMITTED_2 = 3, // 未提出
  IN_PROGRESS_2 = 4, // 審査中
}

// 性別
export enum Gender {
  MALE = 0, // 男
  FEMALE = 1, // 女
  UNKNOWN = 2, // 不明
}

// 振込新規コード
export enum WireNewCode {
  OTHERS = 0, // その他
  FIRST = 1, // 初回振込
  CHANGE = 2, // 変更
}

// 銀行口座預金種目
export enum BankAccountStatus {
  SAVINGS = 1, // 普通
  CURRENT = 2, // 当座
}

// 加盟店請求種別
export enum BillingTypeStatus {
  PAPER = 1, // 紙手続き
  ZEUS_WEB = 2, // ZEUS WEB手続き
}

// 通知配信状態
export enum NotifyDeliveryStatus {
  DRAFTS = 0, // 下書き
  DELIVERED = 1, // 配信済
}

// 加盟店向けキャンペーン種別 ow
export enum MwCampaignTypeKind {
  FREE_FEE = 1, // 入会月会費無料キャンペーン
  MEMBERSHIP = 2, // 入会特典キャンペーン
  BY_REGION = 3, // 地域別キャンペーン
  BENEFITS_ENTRANT = 51, // 店舗招待特典（入会者）
  BENEFITS_REFERRER = 52, // 店舗招待特典（紹介者）
  INDIVIDUAL_GRANT = 101, // 運営個別付与
}

// バイトラベラー向けキャンペーン種別
export enum BtCampaignTypeKind {
  MEMBERSHIP = 11, // 入会特典キャンペーン
  BY_REGION = 12, // 地域別特典キャンペーン
  RENTAL_FEE = 13, // （将来用）レンタル料割引キャンペーン
}

// キャンペーン状態
export enum CampaignStatus {
  BEFORE_GRANT = 0, // 実施前／付与前
  IN_PROGRESS_GRANTED = 1, // 実施中／付与済み
  FINISHED = 2, // 終了
}

// バイト申込一覧 kind sp
export enum ApplicationSpKind {
  APPLYING = 0, // 申し込み済み
  TODAYS_WORK = 1, // 本日のお仕事
  FINISHED = 2, // 完了
}

// バイト申込一覧 status sp
export enum ApplicationSpStatus {
  APPLYING = 0, // 申し込み中
  NO_MATCH = 1, // マッチングしませんでした
  BEFORE_WORK = 2, // お仕事前
  ADOPTED = 3, // 採用されました
  START_OF_WORK = 4, // お仕事開始
  WORK = 5, // お仕事中
  END_OF_WORK = 6, // お仕事終了
  THANKS = 7, // お疲れさまでした
  THINKS_FINISHED = 8, // 完了（お疲れさまでした）
}

// 本人確認種別 sp
export enum IdentificationSpKind {
  JAPANESE_ADULT = 1, // 日本国籍（成人・高校生以外）
  JAPANESE_MINOR = 2, // 日本国籍未成年高校生
  FOREIGNER = 3, // 外国籍
}

// 勤務時間修正状況 mw
export enum FixWorkingHoursMwStatus {
  REQUEST_PROGRESS = 0, // 修正依頼がありました
  APPROVE_REQUEST = 1, // 修正依頼を承認しました
  REJECTED_REQUEST = 2, // 修正依頼を否認しました
}

// 勤務時間修正状況 sp
export enum FixWorkingHoursSpStatus {
  REQUEST_PROGRESS = 0, // 店舗承認待ち
  APPROVE_REQUEST = 1, // 承認済み
  REJECTED_REQUEST = 2, // 否認連絡
}

// ペナルティポイント情報 sp
export enum PenaltyPointInfoStatus {
  NO_LIMIT = 0, // 制限なし
  LIMITED = 1, // 制限あり
  TEMPORARY_SUSPENSION = 2, // 一時利用停止
  INDEFINITE_SUSPENSION = 3, // 無期限利用停止
}

// 所属申請情報 01 sp
export enum BelongApplicationInfoStatus01 {
  APPLIED = 0, // 申請中
  APPROVED = 1, // 承認済み（所属）
}

// 所属申請情報 02 sp
export enum BelongApplicationInfoStatus02 {
  APPLIED = 0, // 申請中
  APPROVED = 1, // 承認済み（所属）
}

// ウォレット入金／出勤情報 sp
export enum WalletDepositInfoStatus {
  RECEIPT = 0, // 受取（入金）
  TRANSFER = 1, // 振込（出金）
}

// 称号タイプ
export enum TitleType {
  ATTAIN_AREA = 1, // エリア達成
  ATTAIN_ARBITE_TIMES = 2, // バイト回数達成
}

// システム種別
export enum SystemType {
  OW_MEMBER = 1, // 運営会員（運営）
  MW_MEMBER = 2, // 店舗会員（加盟店）
  BT_MEMBER = 3, // バイトラベル会員
}

// メッセージ種別
export enum MessageType {
  PAYMENT_REQUEST = 1, // 即日支払請求
  IDENTITY_VERIFICATION = 2, // 本人確認依頼
  MEMBERSHIP_REGISTRATION = 3, // 会員登録
  DELETE = 4, // 退会
  APPEARANCE_OVER = 5, // 神／覇者の出現
  REQUEST_REFUND = 6, // 払戻請求
  CHANGE_MEMBER_INFO = 7, // 会員情報変更
}

// バイトラベル会員受信通知情報メッセージ種別
export enum BtMemberMessageType {
  REGISTRATION = 1, // 会員登録
  ADOPTION = 2, // 採用
  WORKING_HOURS = 3, // お仕事時間
  WORKING_HOURECTION = 4, // 勤務時間修正
  COMPLETE_ARBEIT = 5, // 完了バイト
  REVIEW_INDUCTION = 6, // レビュー誘導
  STORE = 7, // 所属店舗
  IDENTITY_VERIFICATION = 8, // 本人確認
  TITLE = 9, // 称号
  ARBEIT_INDUCED = 10, // バイト誘起
  SKILLS_CHECK = 11, // スキルチェック
  PERIOD_STAY = 12, // 在留期限
  GIFT = 13, // ギフト（特典）
  REVIEW_ARRIVAL = 14, // レビュー着
  REVERSE_OFFER = 15, // 逆オファー
  SUSPENSION_USE = 16, // 利用停止
  FROM_MANAGEMENT = 122, //	運営より
}

// eギフト情報ステータス
export enum EgiftStatus {
  ERROR = 0, // 発行エラー（レスポンスなし）
  GRANTED = 1, // 付与済み
}

// バイト費用差分
export enum ArbeitCostDifference {
  ARBEIT_COST = 1, // バイト費用
  ARBEIT_COST_ALL = 2, // バイト費用全件
  WALLET = 3, // ウォレット払戻
}

// CSV種類
export enum CsvType {
  CHARGE_CSV = 1, // 請求CSV情報
  REQUEST_PAYMENT_CSV = 2, // 支払請求CSV情報
  MONTHLY_PAYMENT_CSV = 3, // 月次支払いCSV情報
}

// eギフトタイプ
export enum EgiftType {
  GIFTEE_SELECT = 1, // giftee 選べるギフト`
  GITTEE_ONLY = 2, // giftee 単一ギフト
  TGAIA = 3, // T-GAIA プチっとギフト
  TGAIA_QUO = 4, // T_GAIA QUOカードPAY
}

// 選べるpay3種類
export enum PayType {
  Y100 = 100, // 100円
  Y300 = 300, // 300円
  Y500 = 500, // 500円
}

// 選べるpay3種類 ラベル
export enum PayTypeLabel {
  Y100 = '100円',
  Y300 = '300円',
  Y500 = '500円',
}

// 加盟店ステータス
export enum AffiliationStatus {
  NON_ACTIVE = 0, // 未アクティブ
  ACTIVE = 1, // アクティブ
  PAUSE = 2, // 休止（運営）
  DELETE = 3, // 退会
}

// メッセージ種別
export enum MessageStatus {
  ADOPTION = 1, // 採用
  GROUP = 2, // グループ承認
  REVIEW = 3, // レビュー
  WORK_HOUR_FIX = 4, // 勤務時間修正
  CONTRACT_ARBITE = 5, // 成約バイト
  STORE = 6, // 所属店舗承認
  REVERSE_OFFER = 7, // 逆オファー
  FAVORITE = 8, // お気に入り
  CHEERING = 9, // 応援
  NOTICE = 121, // 運営からのお知らせ
}

// 参加ステータス
export enum JoinStatus {
  INVITATION = 0, // 招待
  JOIN = 1, // 参加
  REJECT = 2, // 不参加
  LEFT = 3, // 退出
}

// 所属ステータス
export enum BelongStatus {
  NON_ACTIVE = 0, // 未アクティブ
  APPROVAL = 1, // 承認
  REJECT = 2, // 否認
  CANCEL = 3, // 解除
}

// 定期バイト募集ステータス
export enum EditStatus {
  NON_ACTIVE = 0, // 未アクティブ（下書き）
  ACTIVE = 1, // アクティブ（編集禁止）
}

// 履歴種別
export enum HistoryType {
  ADOPTION = 1, // 採用履歴
  ARBEIT_STATUS = 2, // バイト状況履歴
  UTILIZATION = 3, // 利用履歴
  APPLICATION_RECRUITMENT = 4, // 応募/採用履歴
  EDUCATION_FEE = 5, // 育成料履歴
}

// 項目分類
export enum ItemType {
  EDUCATION_FEE = 1, // 育成料
  E_GIFT = 2, // eギフト
  REFUND = 3, // 払戻
}

// 入出金種別
export enum WalletKind {
  DEPOSIT = 0, // 入金
  WITHDRAWAL = 1, // 出金
}

// 入出金ステータス
export enum WalletStatus {
  ON_HOLD = 0, // 保留
  CONFIRMED = 1, // 確定
}

// 本人確認ステータス
export enum IdentityVerificationStatus {
  NOT_APPROVED = 0, // 未承認
  APPROVED = 1, // 承認
  REJECTED = 2, // 否認
}

// 逆オファーステータス
export enum ReverseOfferStatus {
  REVERSE_OFFER = 1, // 逆オファー中
  APPROVAL = 2, // 成約済み
  DECISION = 3, // 終了
  DELETE = 4, // オファー中止（削除）
  FORCE_STOP = 5, // 逆オファー強制停止（運営）
}

// 逆オファー受入ステータス
export enum ReverseOfferAcceptStatus {
  APPLYING = 1, // 募集送信済（申込中）
  CANCELL_ACCEPTANCE = 2, // 受入取消
  CLOSE = 3, // 成約
  NON_ACCEPT = 4, // 他店成約（不成約）
  EXPIRES = 5, // 期限終了
  DELETE = 6, // 削除（逆オファー停止）
  FORCE_STOP = 7, // 逆オファー強制停止（運営）
  ACCEPTANCE_DELETE = 8, // 受入削除（加盟店休止）
}

// 旅程情報ステータス
export enum TravelStatus {
  NON_ACTIVE = 0, // 未アクティブ（下書き）
  ACTIVE = 1, // アクティブ（プラン確定`）
  DELETE = 2, // 削除
}

// バイトラベル会員ステータス
export enum MemberStatus {
  NOT_ACTIVE = 0, // 未アクティブ
  ACTIVE = 1, // アクティブ
  TEMPORARY_SUSPENSION = 2, // 一時利用停止
  INDEFINITE_SUSPENSION = 3, // 無期限利用停止
  PAUSE = 4, // 休止（運営）
  DELETE = 5, // 退会
}

// バイトラベル会員履歴情報ステータス
export enum MemberHistoryStatus {
  USAGE = 1, // 利用履歴
  WORKFLOW = 2, // ワークフロー履歴
  REVERSE_OFFER = 3, // 逆オファー募集履歴
  PENALTY = 4, // ペナルティ履歴
}

export enum RecruitType {
  HIRED = 0,
  BELONGED = 1,
  SUB = 2,
  GROUP = 3,
}

export enum RecruitTypeLabel {
  HIRED = '採用',
  BELONGED = '所属',
  SUB = 'サブ',
  GROUP = 'グループ',
}

export enum Favorite {
  YES = 1,
  NO = 0,
}

export enum BelongTo {
  YES = 1,
  NO = 0,
}

export enum Sub {
  YES = 1,
  NO = 0,
}

// 口座振替申請手続きステータス
export enum TransferProcessStatus {
  UNAPPLIED = 0, // 未申請
  APPLYING = 1, // 申請中
  COMPLETED = 2, // 完了
  NOT_PAYED = 3, // 未納
}

// バイト募集のAPIエラーコード
// TODO: 共通コンポーネントに載せ替えたら削除
export enum CreateOfferErrorCode {
  SUCCESS = 0, // 成功
  MAX_LIMIT_REACHED_ERROR = -1, // 募集の最大数に達した
  MONEY_TRANSFER_ERROR = -2, // 与信審査がまだ通過していません
  INVALID_STATUS_ERROR = -3, // ステータスエラー（非アクティブ、退会日後の募集など）
  OUT_OF_DATE_RANGE_ERROR = -4, // 募集可能期間外エラー
  UNKNOWN_ERROR = -10, // その他のエラー
}

// APIエラーコード
export enum GraphQLErrorCode {
  SUCCESS = 0, // 成功
  MAX_LIMIT_REACHED_ERROR = -1, // 募集の最大数に達した
  MONEY_TRANSFER_ERROR = -2, // 与信審査がまだ通過していません
  INVALID_STATUS_ERROR = -3, // ステータスエラー（非アクティブ、退会日後の募集など）
  OUT_OF_DATE_RANGE_ERROR = -4, // 募集可能期間外エラー
  LIMIT_SALARY_ERROR = -5, // 給与の上限に達した
  OVER_WORKINGHOURS_ERROR = -8, // 休憩時間労働時間越え
  UNKNOWN_ERROR = -10, // その他のエラー
  SQL_ERROR = -21, // SQLエラー
  COGNITOADD_ERROR = -22, // cognito登録エラー
  INSUFFICLIENT_INFOMATION_ERROR = -23, // 責任者情報不備
  ADDRESS_REGISTERED_ERROR = -24, // アドレス登録済みエラー
  CONFIRMATION_CODE_ERROR = -25, // 確認コード誤りエラー
  CONFIRMATION_CODE_EXPIRED_ERROR = -26, // 確認コード期限切れエラー
  CONFIRMATION_CODE_EXCESS_ERROR = -27, // 確認コード誤り回数超過エラー
  BUSINESS_ADD_ERROR = -31, // 業種登録エラー
  BRAND_ADD_ERROR = -32, // ブランド登録エラー
  LICENSE_ADD_ERROR = -33, // 資格登録エラー
  SKILL_SECTION_ADD_ERROR = -34, // スキルセクション登録エラー
  SKILL_CHECK_ADD_ERROR = -35, // スキルチェック登録エラー
  BUSINESS_ICON_UPDATE_ERROR = -36, // 業種アイコン更新エラー
  BUSINESS_DUPULICATION_ERROR = -37, // 業種名、店舗コード接頭辞重複エラー
  BRAND_DUPULICATION_ERROR = -38, // ブランド名、店舗コード接頭辞重複エラー
  LICENSE_DUPULICATION_ERROR = -39, // 保有資格名重複エラー
  SECTION_NO_DUPULICATION_ERROR = -40, // セクションNo重複エラー
  SKILL_CHECK_NO_DUPULICATION_ERROR = -41, // チェック項目No重複エラー
  SECTION_NO_NOT_FOUND_ERROR = -42, // セクションNo該当なしエラー
  SKILL_CHECK_INVALID_RANGE_ERROR = -43, // チェック項目範囲エラー
  MEMBER_ADD_ERROR = -51, // この店舗は会員登録が完了していますエラー
  MEMBER_NOT_REGISTERED_ERROR = -52, // この店舗は会員として登録されておりませんエラー
  MEMBER_NOT_INFO_ERROR = -53, // 入力情報が見つかりませんエラー
  PAUSE_STATUS_ERROR = -99, // 休止状態
}

// 本人確認情報 共通
export enum CommonIdentificationStatus {
  NOT_SUBMITTED = 0, // 未提出
  UNCONFIRMED_IDENTITY = 1, // 本人未確認
  CONFIRMED_IDENTITY = 2, // 本人確認済
  REJECTED = 3, // 非承認
  REQUEST_RECONFIRMATION = 4, // 再確認依頼
  EXPIRATION_RESIDENCE = 5, // 在留期限切
  RENEWAL_RESIDENCE = 6, // 在留更新依頼
}

export enum ResultRows {
  UPDATED = 1, // 更新あり
  NOT_UPDATED = 0, // 更新なし
  PAUSE_STATUS_ERROR = -99, // 休止状態
}

// APIエラーコード レスポンス
export enum ResponseErrorCode {
  SUCCESS = 0, // 成功
  ER_DUP_ENTRY = -24, // メールアドレス重複エラー
}

// メールアドレスバリテーション
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// パスワードバリテーション
export const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\\^$*.\\[\]{}()?&;!@#%&/\\,:|_~`+=])/;
// パスワード桁数
export const passwordLength = 8;

// モーダルの表示位置
export enum ModalZindex {
  OVERLAYZINDEX = 5, // 通常モーダル
  ERRMODALZINDEX = 10, // モーダル用エラーモーダル
}
