import React from 'react';
import { styled } from '@linaria/react';
import { Control, FieldArrayWithId, UseFieldArrayAppend, FieldErrors } from 'react-hook-form';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Box } from 'components/box';
import { Icon } from 'components/icon';
import { MainButton } from 'components/mainButton';
import { ErrorMessage } from 'components/errorMessage';
import { MBrand2 } from 'graphql/graphql-ow';
import { AddInput } from './addInput';
import { BusinessInfoSettingKey } from '../../s10-02-types';

const IconWrap = styled.div`
  position: relative;
  cursor: pointer;
  height: 24px;
`;

// ブランド入力項目 登録・削除 コンポーネント
export interface Props {
  fields: FieldArrayWithId<BusinessInfoSettingKey, 'brand', 'id'>[];
  control: Control<BusinessInfoSettingKey>;
  append: UseFieldArrayAppend<BusinessInfoSettingKey, 'brand'>;
  remove: (index: number) => void;
  errors: FieldErrors<BusinessInfoSettingKey>;
  brands: MBrand2[] | undefined | null;
  inputs: { name: string; code: string }[];
}

const brandNameValidation = (
  val: string,
  brands: MBrand2[],
  inputs: { name: string; code: string }[],
  index: number
) => {
  for (let i = 0; i < brands.length; i += 1) {
    if (val === brands[i].brand_name) {
      return '登録済なので使用できません。';
    }
  }
  const nameList = Array.from(new Set(inputs.map((item) => item.name)));
  if (nameList.length !== inputs.length && inputs.length > 1) {
    const wkName = inputs[index].name;
    for (let i = 0; i < inputs.length; i += 1) {
      if (i !== index) {
        if (wkName === inputs[i].name) {
          return '同じブランド名があります。';
        }
      }
    }
  }
  return undefined;
};

// 文字列チェック
function isString(value: any): value is string {
  return typeof value === 'string';
}
const brandPrefixValidation = (
  val: string,
  brands: MBrand2[],
  inputs: { name: string; code: string }[],
  index: number
) => {
  if (isString(val)) {
    if (val.length !== 2) {
      return '2桁で入力してください';
    }
    if (val !== val.match(/^[a-z]+$/)?.[0]) {
      return '英子文字で入力してください';
    }
  }
  for (let i = 0; i < brands.length; i += 1) {
    if (val === brands[i].brand_prefix) {
      return '登録済なので使用できません。';
    }
  }
  const codeList = Array.from(new Set(inputs.map((item) => item.code)));
  if (codeList.length !== inputs.length && inputs.length > 1) {
    const wkCode = inputs[index].code;
    for (let i = 0; i < inputs.length; i += 1) {
      if (i !== index) {
        if (wkCode === inputs[i].code) {
          return '同じコードがあります。';
        }
      }
    }
  }
  return undefined;
};

export function InputsBrandContainer({ fields, control, append, remove, errors, brands, inputs }: Props) {
  // 入力項目削除
  const reduce = (index: number) => () => {
    remove(index);
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <Box alignItems="center" display="flex">
            <Box alignItems="center" display="grid" mr={16}>
              <AddInput
                width={444}
                control={control}
                name={`brand.${index}.name`}
                placeholder="ブランド名を入力"
                rules={{
                  required: 'ブランド名を入力してください',
                  validate: (val) => brandNameValidation(val as string, brands || [], inputs, index),
                }}
              />
              {errors?.brand && errors.brand[index] && (
                <Box pt={4}>
                  <ErrorMessage>{errors.brand[index]?.name?.message}</ErrorMessage>
                </Box>
              )}
            </Box>
            <Box alignItems="center" display="grid" mr={8}>
              <AddInput
                width={260}
                control={control}
                name={`brand.${index}.code`}
                placeholder="英子文字２桁の文字を入力"
                rules={{
                  required: '英子文字２桁を入力してください',
                  pattern: {
                    value: /^[a-z]*$/,
                    message: '半角の英子文字で入力してください。',
                  },
                  validate: (val) => brandPrefixValidation(val as string, brands || [], inputs, index),
                }}
              />
              {errors?.brand && errors.brand[index] && (
                <Box pt={4}>
                  <ErrorMessage>{errors.brand[index]?.code?.message}</ErrorMessage>
                </Box>
              )}
            </Box>
            {index > 0 && (
              <IconWrap onClick={reduce(index)}>
                <Icon name="cancel" />
              </IconWrap>
            )}
          </Box>
        </div>
      ))}
      <Box display="flex" justifyContent="center" gap={5.5} pb={16}>
        <MainButton
          icon="addCircle"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          variant="secondary"
          thin
          onClick={() => [append({ name: '', code: '' })]}
          width={108}
        >
          行追加
        </MainButton>
      </Box>
    </>
  );
}
