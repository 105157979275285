/**
 * Figma ID: 10-01-04-01
 * 名称: 管理アカウント（パスワード編集）
 */

import React from 'react';
import { passwordPattern, passwordLength } from 'components/const';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { Modal } from 'components/newModal';

type Props = {
  onClickClose: () => void;
  toNextPhase: (args: { oldPassword: string; newPassword: string }) => void;
};

export function UpdatePasswordModal({ onClickClose, toNextPhase }: Props) {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ oldPassword: string; newPassword: string; newConfirmPassword: string }>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    toNextPhase({ oldPassword: data.oldPassword, newPassword: data.newPassword });
  });

  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            パスワード変更
          </Text>
        </Box>
      }
      content={
        <Box pa={8}>
          <Box display="grid" justifyContent="center">
            <Box pb={5.5}>
              <InputLabel>現在のパスワード</InputLabel>
            </Box>
            <RhfInput
              placeholder="パスワード"
              name="oldPassword"
              control={control}
              rules={{
                required: '現在のパスワードの入力は必須です',
              }}
              type="password"
              width={752}
            />
            {errors.oldPassword?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.oldPassword.message}`}</Text>
            )}
          </Box>
          <Box display="grid" justifyContent="center" pt={24}>
            <Box pb={5.5}>
              <InputLabel>新しいパスワード</InputLabel>
            </Box>
            <RhfInput
              name="newPassword"
              control={control}
              rules={{
                validate: (value) =>
                  passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
                minLength: { value: passwordLength, message: '8文字以上で入力してください' },
              }}
              type="password"
              width={752}
            />
            {errors.newPassword?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.newPassword.message}`}</Text>
            )}
          </Box>
          <Box display="grid" justifyContent="center" pt={24}>
            <Box pb={5.5}>
              <InputLabel>新しいパスワード（確認）</InputLabel>
            </Box>
            <RhfInput
              name="newConfirmPassword"
              control={control}
              rules={{ validate: (value) => value === watch('newPassword') || 'パスワードが一致しません。' }}
              type="password"
              width={752}
            />
            {errors.newConfirmPassword?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.newConfirmPassword.message}`}</Text>
            )}
          </Box>
          <Box pt={5}>
            <Text color="cautionRed" variant="caption12">
              パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {`{ }`} ( ) ? - &quot; ! @ # % & / \ , &gt;
              &lt; &prime; : ; | _ ~ ` + =) ・ アルファベットの大文字と小文字を含む必要があります。
            </Text>
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton
            disabled={!isValid}
            onClick={() => {
              onSubmit();
            }}
            width={104}
          >
            変更
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      width={800}
      height="auto"
    />
  );
}
