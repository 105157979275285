import React, { useEffect, useState } from 'react';
import { CreateBlockKey, ModalRet } from 'pages/s03/type';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { Control, UseFormGetValues, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { RhfCheckbox } from 'components/rhfCheckbox';
import {
  useGetVSelBlockListByMyIdBizIdBrandIdLazyQuery,
  useGetMBrandByIdLazyQuery,
  useGetMStateLazyQuery,
  useGetMCityByStateIdLazyQuery,
  useGetMStateByIdLazyQuery,
  MState,
  MCity,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ErrorMessage } from 'components/errorMessage';
import { FilterSearch } from 'components/filterSearch';
import { useErrorModal } from 'components/error/errorModalProvider';

function CustomRow(props: {
  id: number;
  storeName: string;
  address: string;
  index: number;
  errors: FieldErrors<CreateBlockKey>;
  control: Control<CreateBlockKey, any>;
  disabled?: boolean;
}) {
  const { id, storeName, address, index, errors, control, disabled = false } = props;

  return (
    <Box display="flex" gap={16} alignItems="center" pa={10}>
      <RhfCheckbox
        name={`validStoreId.${index}`}
        control={control}
        defaultValue={false}
        disabled={disabled}
        rules={{
          validate: (_, values) => {
            const allEmpty = values.validStoreId?.every((value) => value === false);
            if (allEmpty) {
              return 'ブロックする店舗をチェックしてください';
            }
            return true;
          },
        }}
      />

      <Text variant="caption12">{storeName}</Text>
      <Text variant="caption12">{address}</Text>
    </Box>
  );
}

export function SelectStoreModal(
  control: Control<CreateBlockKey>,
  getValues: UseFormGetValues<CreateBlockKey>,
  setValue: (name: keyof CreateBlockKey, value: string) => void,
  handleSubmit: UseFormHandleSubmit<CreateBlockKey>,
  reset: () => void,
  errors: FieldErrors<CreateBlockKey>,
  myId: number,
  bizId: number,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const blockedStringNumber = '1';
  const [codeStoreName, setCodeStoreName] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const currentSearchText = useWatch({ control, name: 'search_name' });
  const currentKeyState = useWatch({ control, name: 'key_state' });
  const currentKeyCity = useWatch({ control, name: 'key_city' });
  const currentBrand = useWatch({ control, name: 'brand_name' });
  const { openErrorModal } = useErrorModal();
  const [
    getVSelBlockListByMyIdBizIdBrandId,
    { data: vSelBlockListData, loading: vSelBlockListLoading, error: vSelBlockListError },
  ] = useGetVSelBlockListByMyIdBizIdBrandIdLazyQuery({
    variables: {
      my_id: myId,
      biz_id: bizId,
      brand_id: Number(currentBrand),
    },
  });
  let blockList = vSelBlockListData?.getVSelBlockListByMyIdBizIdBrandId || [];
  const [getMBrandById, { data: mBrandByIdData, loading: mBrandByIdLoading, error: mBrandByIdError }] =
    useGetMBrandByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentBrand) },
    });
  const [getMState, { data: mStateData, loading: mStateLoading, error: mStateError }] = useGetMStateLazyQuery({
    context: { clientName: 'master' },
  });
  const [getMStateById, { data: mStateByIdData, loading: mStateByIdLoading, error: mStateByIdError }] =
    useGetMStateByIdLazyQuery({
      context: { clientName: 'master' },
    });

  const [getMCityByStateId, { data: mCityByStateIdData, loading: mCityByStateIdLoading, error: mCityByStateIdError }] =
    useGetMCityByStateIdLazyQuery({
      context: { clientName: 'master' },
      variables: { sid: Number(currentKeyState) },
    });
  const mState = mStateData?.getMState as MState[];
  const mStateList =
    mState?.map((item) => ({
      value: String(item.id),
      label: item.name,
    })) || [];

  useEffect(() => {
    if (currentKeyState !== undefined) {
      getMStateById({
        variables: { mid: Number(currentKeyState) },
      });
    }
  }, [currentKeyState, getMStateById]);

  useEffect(() => {
    if (myId === undefined || bizId === undefined || currentBrand === undefined) return;
    getVSelBlockListByMyIdBizIdBrandId({
      variables: {
        my_id: myId,
        biz_id: bizId,
        brand_id: Number(currentBrand),
      },
    });
    if (currentBrand === undefined) return;
    getMBrandById({
      variables: { mid: Number(currentBrand) },
    });
    getMState();
    if (currentKeyState !== undefined && currentKeyState !== '') {
      getMCityByStateId({
        variables: { sid: Number(currentKeyState) },
      });
    }
  }, [
    bizId,
    currentBrand,
    getMState,
    getMBrandById,
    getVSelBlockListByMyIdBizIdBrandId,
    myId,
    currentKeyState,
    getMCityByStateId,
  ]);

  useEffect(() => {
    if (mStateByIdError || mCityByStateIdError || mStateError || mBrandByIdError || vSelBlockListError) {
      onClose();
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mStateByIdError, mCityByStateIdError, mStateError, mBrandByIdError, vSelBlockListError, openErrorModal, onClose]);

  let mCityList = [] as { label: string; value: string }[];
  const mCity = mCityByStateIdData?.getMCityByStateId as MCity[];
  if (mCity === undefined) {
    mCityList = [{ label: '', value: '' }];
  } else {
    mCityList =
      mCity.map((item) => ({
        label: item.name,
        value: item.name,
      })) || [];
  }

  const brandName = () => {
    if (currentBrand === undefined) return '';
    return mBrandByIdData?.getMBrandById?.name || '';
  };

  const onClickHandler = handleSubmit((data) => {
    // チェックボックスにチェックされた情報から次の画面で使用する為の情報で、店舗IDと店舗名を取得
    const storeIds = [] as string[];
    const storeNames = [] as string[];

    blockList.forEach((value, index) => {
      if (getValues(`validStoreId.${index}`)) {
        storeIds.push(value.tpmem_id?.toString() || '');
        storeNames.push(value.name || '');
      }
    });
    setValue('store_id', storeIds.join(','));
    setValue('store_name', storeNames.join('\n'));

    onNext();
  });

  /**
   * キーワードでフィルター
   */
  if (searchText) {
    blockList = blockList.filter(({ name }) => {
      if (!searchText) {
        return true;
      }
      const reg = new RegExp(searchText);
      return reg.test(name || '');
    });
  }
  /**
   * 都道府県でフィルター
   */
  if (currentKeyState) {
    blockList = blockList.filter(({ state }) => {
      if (mStateByIdData?.getMStateById?.name !== state) {
        return false;
      }
      return true;
    });
  }
  /**
   * 市区町村でフィルター
   */
  if (currentKeyCity) {
    blockList = blockList.filter(({ city }) => {
      if (currentKeyCity !== city) {
        return false;
      }
      return true;
    });
  }

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック登録 - 店舗検索
      </Text>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={16} pa={8} overflow="hidden" width="100%">
        <Box display="flex" flexDirection="column" height={130} gap={8}>
          <Text variant="caption11" color="redLite">
            検索をやり直すと選択されたものは解除されますのでご注意ください。
          </Text>
          <Box display="flex" flex="row" gap={8}>
            <Text variant="caption12" color="blueGray">
              ブランド
            </Text>
            <Text variant="caption12" color="blueGray">
              :
            </Text>
            <Text variant="caption12" color="blueGray">
              {brandName()}
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" gap={16}>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                キーワードで検索
              </Text>
              <FilterSearch
                name="search_name"
                control={control}
                placeholder="店舗名"
                onClick={() => setSearchText(currentSearchText)}
                width={280}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                都道府県
              </Text>
              <RhfSelect
                width={260}
                control={control}
                name="key_state"
                options={mStateList}
                defaultValue=""
                placeholder="都道府県を選択"
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                市区町村
              </Text>
              <RhfSelect
                width={260}
                control={control}
                name="key_city"
                options={mCityList}
                defaultValue=""
                placeholder="市区町村を選択"
              />
            </Box>
            <Box alignItems="end" mb={5} display="grid" height="100%">
              <MainButton
                variant="clear"
                width={44}
                onClick={() => {
                  setValue('search_name', '');
                  setValue('key_state', '');
                  setValue('key_city', '');
                  setSearchText('');
                }}
              >
                クリア
              </MainButton>
            </Box>
          </Box>
        </Box>
        <Box width="100%">
          <Text variant="caption11" color="darkGray">
            {blockList.length}件の検索結果
          </Text>
          <Box display="block" gap={11} overflow="auto" width="100%" height={290}>
            <Divider option="horizontal" />
            {blockList?.map((item, key) => (
              <>
                <CustomRow
                  control={control}
                  errors={errors}
                  id={item.tpmem_id ?? 0}
                  storeName={item.name ?? 'a'}
                  index={key}
                  disabled={item.blocked === blockedStringNumber}
                  address={
                    `〒${item.zip_code?.slice(0, 3) || ''}-${item.zip_code?.slice(3, 7) || ''} ${item.state} ${
                      item.city || ''
                    } ${item.address || ''} ${item.address2 || ''}` ?? 'a'
                  }
                />
                <Divider option="horizontal" />
              </>
            ))}
            {errors && errors.validStoreId && errors.validStoreId[0]?.message && (
              <ErrorMessage>{errors.validStoreId[0]?.message}</ErrorMessage>
            )}
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <>
        {(mStateLoading || vSelBlockListLoading || mBrandByIdLoading || mCityByStateIdLoading || mStateByIdLoading) && (
          <Loading />
        )}
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
            キャンセル
          </MainButton>
          <MainButton
            width={104}
            variant="secondary"
            thin
            onClick={() => {
              setCodeStoreName('');
              setSearchText('');
              reset();
              onBack();
            }}
          >
            戻る
          </MainButton>
          <MainButton
            width={104}
            thin
            onClick={(data) => {
              onClickHandler(data);
            }}
          >
            次へ
          </MainButton>
        </Box>
      </>
    ),
    width: 1000,
    height: 640,
  };

  return modalContent;
}
