import { styled } from '@linaria/react';
import React, { ReactNode, useMemo } from 'react';
import { Text } from 'components/text';
import { MemberStatus } from '../const';

const StyledImageLabel = styled.div`
  display: flex;
  position: relative;
`;

const StyledBG = styled.div<{
  color: string;
}>`
  position: absolute;
  width: 90px;
  height: 60px;
  background-color: ${({ color }) => color};
`;

const StyledLabel = styled.div<{
  status: MemberStatus | null | undefined;
}>`
  position: absolute;
  top: ${({ status }) => {
    switch (status) {
      case MemberStatus.Unregistered:
        return '16px';
      case MemberStatus.Suspended:
        return '16px';
      default:
        return '0px';
    }
  }};
  left: ${({ status }) => {
    switch (status) {
      case MemberStatus.Unregistered:
        return '22px';
      case MemberStatus.Suspended:
        return '28px';
      default:
        return '0px';
    }
  }};
`;

export function ChangeBigImgWithStatus({ status, children }: { status?: number | null; children: ReactNode }) {
  const label: string = useMemo(() => {
    switch (status) {
      case MemberStatus.Unregistered:
        return '未登録';
      case MemberStatus.Suspended:
        return '休止';
      default:
        return '';
    }
  }, [status]);

  const bgColor: string = useMemo(() => {
    switch (status) {
      case MemberStatus.Unregistered:
        return 'rgba(150, 150, 150, 0.9)';
      case MemberStatus.Suspended:
        return 'rgba(229, 0, 0, 0.7)';
      default:
        return '';
    }
  }, [status]);

  return (
    <StyledImageLabel>
      <StyledBG color={bgColor} />
      <StyledLabel status={status}>
        <Text variant="body14" bold color="white">
          {label}
        </Text>
      </StyledLabel>
      {children}
    </StyledImageLabel>
  );
}
