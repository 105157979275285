/**
 * Figma ID: 01-02-04
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { ContentTitle } from '../component/ContentTitle';

export default function CompleteUpdatePassword() {
  return (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定 完了" />
      <Box display="grid" justifyContent="center" pt={21}>
        <Box width={300}>
          <Text variant="body14">パスワード再設定が完了しました。</Text>
        </Box>
        <Box width={300}>
          <Text variant="body14">ログイン画面から再度ログインしてください。</Text>
        </Box>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
      </Box>
    </Box>
  );
}
