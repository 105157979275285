import React from 'react';
import { styled } from '@linaria/react';
import { Icon } from 'components/icon';

type Props = {
  readOnly?: boolean,
  value: number,
  size: 'large' | 'small',
  precision?: 1 | 0.5,
  onChange?: (value: number)=> void
};

const Wrapper = styled.div<{
  size: 'small' | 'large'
}>`
  display: flex;
  flex-direction: row;

  gap: ${({size})=> size==='small' ? 2 : 8}px;
`;

export function Rating(
  {
      value, size='small', readOnly, precision=1,
      onChange=(arg: number)=>console.log('Rating Component require readOnly')
  }: Props
){
  const iconSize = size === 'small' ? 24 : 32;
  const children: React.ReactNode[] = [];

  Array(Math.floor(value)).fill('').forEach(
    ()=> children.push(<Icon name='star' size={iconSize} />)
  );

  if(value%1 >= 0.5){
    children.push(
      precision===0.5 ? <Icon name='starHalf' size={iconSize} /> 
      : <Icon name='star' size={iconSize} />
    );
  }

  if(children.length<5){
    Array(5-children.length).fill('').forEach(
      ()=> children.push(<Icon name='starBorder' size={iconSize} />)
    );
  }

  
  return (
    <Wrapper size={size}>
      {
        children.map((child, index)=> 
          readOnly ? ( <div>{child}</div> ) : (
            <div 
              onClick={()=> onChange(index+1)}
              onKeyDown={()=> {}}
              role='button'
              tabIndex={-1} // tabで到達不能
            >
              {child}
            </div>
          )
        )
      }
    </Wrapper>
  );
}