/**
 * Figma ID: 05-02-01
 * 名称: 基本情報
 */

import { Box } from 'components/box';
import Divider from 'components/divider/divider';
import { Info } from 'components/info';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import React, { useEffect, useCallback, useMemo } from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ErrorModal } from 'components/errorModal';
import { InfoItemsType } from 'pages/s05/type';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { EditProfileModal } from './modal/editProfileModal';
import { EditProfileModalStep } from '../../constants';
import { formatZipCode } from '../../s05-utils';

type RecruitInfoModel = {
  id: string;
  refetchName: () => void;
};
export function RecruitInfo(props: RecruitInfoModel) {
  const { id, refetchName } = props;
  const [update, { data, loading, error }] = gql.useGetupdVbMemberBaseByIdMutation({
    variables: { id: Number(id) },
    fetchPolicy: 'no-cache',
  });
  const [errorModalStep, setErrorModalStep] = React.useState<boolean>(false);

  const lcBizNamesList = data?.getupdVBMemberBaseById?.lc_biz_names?.split(',');
  const rankBizNamesList = data?.getupdVBMemberBaseById?.rank_biz_names?.split(',');
  const tpBizNameList = data?.getupdVBMemberBaseById?.tp_biz_names?.split(',');
  const rankList = data?.getupdVBMemberBaseById?.rank_names?.split(',');
  const licenceList = data?.getupdVBMemberBaseById?.license_list?.split('\n');
  const mainNameList = data?.getupdVBMemberBaseById?.tpmem_names?.split(',');
  const subNameList = data?.getupdVBMemberBaseById?.sub_names?.split(',');
  const bankInfo = `${data?.getupdVBMemberBaseById.bank ?? ''} ${data?.getupdVBMemberBaseById.branch_name ?? ''} ${
    data?.getupdVBMemberBaseById.account_type ?? ''
  } ${data?.getupdVBMemberBaseById.account_no ?? ''} ${data?.getupdVBMemberBaseById.account_name ?? ''}`;
  const rankContent: InfoItemsType = (rankBizNamesList ?? []).map((rankBizNames, index) => ({
    label: `ランク(${rankBizNames})`,
    content: rankList?.[index],
  }));
  const licenceContent: InfoItemsType = (lcBizNamesList ?? []).map((lcBizName, index) => ({
    label: `${lcBizName}資格`,
    content: licenceList?.[index] !== undefined && licenceList?.[index] !== '' ? licenceList[index] : '-',
  }));
  const belongStoreContent: InfoItemsType[] = (tpBizNameList ?? []).map((tpBizNames, index) => [
    { label: `所属店舗(${tpBizNames})`, content: mainNameList?.[index] },
    { label: `サブ店舗(${tpBizNames})`, content: subNameList?.[index] || '-' },
  ]);

  const nationalityContent: InfoItemsType = data?.getupdVBMemberBaseById.nationality
    ? [
        {
          label: '国籍',
          content: data?.getupdVBMemberBaseById.nationality,
        },
        {
          label: '在留資格',
          content: data?.getupdVBMemberBaseById.residence_name || '-',
        },
        {
          label: '在留期限',
          content: data?.getupdVBMemberBaseById.period_date || '-',
        },
      ]
    : [];

  // 所属店舗（コンビニ）','ランク（コンビニ）'等をどの項目を抜粋して項目を追加するか不明だが、恐らく加工が必要な為変数化
  const items = [
    {
      content: data?.getupdVBMemberBaseById.name,
      label: '氏名',
    },
    {
      content: data?.getupdVBMemberBaseById.kana,
      label: '氏名(フリガナ)',
    },
    {
      content: data?.getupdVBMemberBaseById.email,
      label: 'メールアドレス(ID)',
    },
    {
      content: data?.getupdVBMemberBaseById.btmem_number,
      label: '会員番号',
    },
    {
      content: data?.getupdVBMemberBaseById.phone,
      label: '電話番号',
    },
    {
      content: data?.getupdVBMemberBaseById.birthday,
      label: '生年月日',
    },
    {
      content: data?.getupdVBMemberBaseById.age,
      label: '年齢',
    },
    ...rankContent,
    {
      content: data?.getupdVBMemberBaseById.state_count,
      label: '達成都道府県',
    },
    {
      content: `${data?.getupdVBMemberBaseById.worked_count ?? '0'} ${data?.getupdVBMemberBaseById.badge_name ?? ''}`,
      label: 'バイト回数',
    },
    {
      content: data?.getupdVBMemberBaseById.created_date,
      label: '入会日',
    },
    {
      content: `〒${formatZipCode(data?.getupdVBMemberBaseById.zip_code ?? '')} ${
        data?.getupdVBMemberBaseById.state ?? ''
      } ${data?.getupdVBMemberBaseById.city ?? ''} ${data?.getupdVBMemberBaseById.address ?? ''} ${
        data?.getupdVBMemberBaseById.address2 ?? ''
      }`,
      label: '住所',
    },
    ...belongStoreContent.flat(),
    {
      content: data?.getupdVBMemberBaseById.gender,
      label: '性別',
    },
    ...nationalityContent,
    {
      content: data?.getupdVBMemberBaseById.attribute || '-',
      label: '属性',
    },
    {
      content: data?.getupdVBMemberBaseById.general_licenses || '-',
      label: '保有資格',
    },
    ...licenceContent,
    {
      content: data?.getupdVBMemberBaseById.uniform_size,
      label: '制服サイズ',
    },
    {
      label: '遅刻率',
      content: `${data?.getupdVBMemberBaseById?.late_count ?? 0}回 / ${data?.getupdVBMemberBaseById?.late_per ?? 0}%`,
    },
    {
      label: 'キャンセル率',
      content: `${data?.getupdVBMemberBaseById?.cancel_count ?? 0}回 / ${
        data?.getupdVBMemberBaseById?.cancel_per ?? 0
      }%`,
    },
    {
      label: 'ドタキャン率',
      content: `${data?.getupdVBMemberBaseById?.imminent_count ?? 0}回 / ${
        data?.getupdVBMemberBaseById?.imminent_per ?? 0
      }%`,
    },
    { label: 'バックレ回数', content: `${data?.getupdVBMemberBaseById?.imminent_count ?? 0}回` },
    {
      content: data?.getupdVBMemberBaseById.reg_biz_list || '-',
      label: '登録業種',
    },
    {
      content: bankInfo,
      label: '入金口座',
    },
    {
      content: identificationLabel(data?.getupdVBMemberBaseById.identification ?? 0),
      label: '本人確認',
    },
  ];

  const [editProfileModalStep, setEditProfileModalStep] = React.useState<EditProfileModalStep>(
    EditProfileModalStep.NULL
  );
  const firstName = data?.getupdVBMemberBaseById.name?.split(' ')[0] || '';
  const lastName = data?.getupdVBMemberBaseById.name?.split(' ')[1] || '';
  const firstKana = data?.getupdVBMemberBaseById.kana?.split(' ')[0] || '';
  const lastKana = data?.getupdVBMemberBaseById.kana?.split(' ')[1] || '';

  const initialBirthYear = data?.getupdVBMemberBaseById.birthday?.split('年')[0];
  const initialBirthMonth = data?.getupdVBMemberBaseById.birthday?.split('年')[1].split('月')[0];
  const initialBirthDay = data?.getupdVBMemberBaseById.birthday?.split('年')[1].split('月')[1].split('日')[0];

  const initialDateOfBirth = new Date(
    typeof initialBirthYear !== 'number' ? Number(initialBirthYear) : 0,
    typeof initialBirthMonth !== 'number' ? Number(initialBirthMonth) - 1 : 0, // 月は0から始まるため、-1する
    typeof initialBirthDay !== 'number' ? Number(initialBirthDay) : 0
  );

  const initialGender = data?.getupdVBMemberBaseById.gender === '-' ? '' : data?.getupdVBMemberBaseById.gender || '';

  const defaultProfileValues = useMemo(
    () =>
      data?.getupdVBMemberBaseById
        ? {
            dob: data.getupdVBMemberBaseById.birthday || '',
            first_kana: firstKana,
            first_name: firstName,
            gender: initialGender,
            last_kana: lastKana,
            last_name: lastName,
            id,
          }
        : undefined,
    [data, firstKana, firstName, initialGender, lastName, lastKana, id]
  );

  const onCloseHandler = useCallback(() => {
    if (editProfileModalStep === EditProfileModalStep.COMPLETE) {
      update();
      refetchName();
    }
    setEditProfileModalStep(EditProfileModalStep.NULL);
  }, [update, editProfileModalStep, refetchName]);

  const editProfileHandler = useCallback(() => {
    if (editProfileModalStep === EditProfileModalStep.EDIT) {
      setEditProfileModalStep(EditProfileModalStep.SAVE);
    } else if (editProfileModalStep === EditProfileModalStep.SAVE) {
      setEditProfileModalStep(EditProfileModalStep.COMPLETE);
    }
  }, [editProfileModalStep]);

  const modal = EditProfileModal({
    editProfileModalStep,
    title: {
      edit: '基本情報編集',
      save: '保存',
      complete: '保存完了',
    },
    defaultValues: defaultProfileValues,
    initialBirthday: initialDateOfBirth
      ? new Date(initialDateOfBirth.toDateString().replace(/年|月/g, '-').replace('日', ''))
      : undefined,
    onClose: onCloseHandler,
    editProfileHandler,
  });

  useEffect(() => {
    update();
  }, [update]);
  useEffect(() => {
    if (error) {
      setErrorModalStep(true);
      setEditProfileModalStep(EditProfileModalStep.NULL);
    }
  }, [error]);

  if (loading) {
    return Loading();
  }
  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            基本情報
          </Text>
          <MainButton thin icon="edit" onClick={() => setEditProfileModalStep(EditProfileModalStep.EDIT)}>
            編集
          </MainButton>
        </Box>
        <Divider option="horizontal" length={0} />
        <ScrollableArea>
          <Info items={items} />
        </ScrollableArea>
      </Box>
      <Modal
        height={modal.height}
        width={modal.width}
        isOpen={editProfileModalStep !== EditProfileModalStep.NULL}
        content={modal.content}
        header={modal.header}
        footer={modal.footer}
        onClose={() => {
          setEditProfileModalStep(EditProfileModalStep.NULL);
        }}
      />
      <ErrorModal
        title="エラー"
        isOpen={errorModalStep}
        message={
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        }
        onClose={() => {
          setErrorModalStep(false);
        }}
      />
    </>
  );
}
