import React from 'react';
import { styled } from '@linaria/react';
import { ReactComponent as accountArrowRight } from './svg/accountArrowRight.svg';
import { ReactComponent as accountBalanceWallet } from './svg/accountBalanceWallet.svg';
import { ReactComponent as action } from './svg/action.svg';
import { ReactComponent as addCircle } from './svg/addCircle.svg';
import { ReactComponent as adminPanelSettings } from './svg/adminPanelSettings.svg';
import { ReactComponent as apartment } from './svg/apartment.svg';
import { ReactComponent as bank } from './svg/bank.svg';
import { ReactComponent as camera } from './svg/camera.svg';
import { ReactComponent as campaign } from './svg/campaign.svg';
import { ReactComponent as cancel } from './svg/cancel.svg';
import { ReactComponent as cancelHover } from './svg/cancelHover.svg';
import { ReactComponent as cancelOnimage } from './svg/cancelOnimage.svg';
import { ReactComponent as cancelOnimageHover } from './svg/cancelOnimageHover.svg';
import { ReactComponent as check } from './svg/check.svg';
import { ReactComponent as checkboxChecked } from './svg/checkboxChecked.svg';
import { ReactComponent as checkboxDisabled } from './svg/checkboxDisabled.svg';
import { ReactComponent as checkboxUnchecked } from './svg/checkboxUnchecked.svg';
import { ReactComponent as chevronClose } from './svg/chevronClose.svg';
import { ReactComponent as chevronFwd } from './svg/chevronFwd.svg';
import { ReactComponent as chevronLess } from './svg/chevronLess.svg';
import { ReactComponent as chevronMore } from './svg/chevronMore.svg';
import { ReactComponent as chevronOpen } from './svg/chevronOpen.svg';
import { ReactComponent as chevronRwd } from './svg/chevronRwd.svg';
import { ReactComponent as clock } from './svg/clock.svg';
import { ReactComponent as closeCircleOutlineCheck } from './svg/closeCircleOutlineCheck.svg';
import { ReactComponent as comment } from './svg/comment.svg';
import { ReactComponent as confirm } from './svg/confirm.svg';
import { ReactComponent as confirmationNumber } from './svg/confirmationNumber.svg';
import { ReactComponent as contentCopyNormal } from './svg/contentCopyNormal.svg';
import { ReactComponent as currencyYen } from './svg/currencyYen.svg';
import { ReactComponent as dateRange } from './svg/dateRange.svg';
import { ReactComponent as dateRangeNormal } from './svg/dateRangeNormal.svg';
import { ReactComponent as doNotDisturb } from './svg/doNotDisturb.svg';
import { ReactComponent as download } from './svg/download.svg';
import { ReactComponent as downloading } from './svg/downloading.svg';
import { ReactComponent as edit } from './svg/edit.svg';
import { ReactComponent as ellipsis } from './svg/ellipsis.svg';
import { ReactComponent as ellipsisSelected } from './svg/ellipsisSelected.svg';
import { ReactComponent as end } from './svg/end.svg';
import { ReactComponent as eyeSlash } from './svg/eyeSlash.svg';
import { ReactComponent as eye } from './svg/eye.svg';
import { ReactComponent as favoriteBorder } from './svg/favoriteBorder.svg';
import { ReactComponent as favoriteBorderO } from './svg/favoriteBorderO.svg';
import { ReactComponent as feed } from './svg/feed.svg';
import { ReactComponent as hail } from './svg/hail.svg';
import { ReactComponent as handExtended } from './svg/handExtended.svg';
import { ReactComponent as helpOutline } from './svg/helpOutline.svg';
import { ReactComponent as home } from './svg/home.svg';
import { ReactComponent as hotel } from './svg/hotel.svg';
import { ReactComponent as howToReg } from './svg/howToReg.svg';
import { ReactComponent as income } from './svg/income.svg';
import { ReactComponent as indicatorBlue } from './svg/indicatorBlue.svg';
import { ReactComponent as indicatorRed } from './svg/indicatorRed.svg';
import { ReactComponent as indictorYellow } from './svg/indictorYellow.svg';
import { ReactComponent as key } from './svg/key.svg';
import { ReactComponent as language } from './svg/language.svg';
import { ReactComponent as mailOutline } from './svg/mailOutline.svg';
import { ReactComponent as mainMember } from './svg/mainMember.svg';
import { ReactComponent as menuClose } from './svg/menuClose.svg';
import { ReactComponent as menuOpen } from './svg/menuOpen.svg';
import { ReactComponent as militaryTech } from './svg/militaryTech.svg';
import { ReactComponent as nonActive } from './svg/nonActive.svg';
import { ReactComponent as notificationsExistence } from './svg/notificationsExistence.svg';
import { ReactComponent as notificationsNone } from './svg/notificationsNone.svg';
import { ReactComponent as openInBrowser } from './svg/openInBrowser.svg';
import { ReactComponent as outcome } from './svg/outcome.svg';
import { ReactComponent as payment } from './svg/payment.svg';
import { ReactComponent as pdf } from './svg/pdf.svg';
import { ReactComponent as personOutline } from './svg/personOutline.svg';
import { ReactComponent as personSearch } from './svg/personSearch.svg';
import { ReactComponent as point } from './svg/point.svg';
import { ReactComponent as print } from './svg/print.svg';
import { ReactComponent as radioDisabled } from './svg/radioDisabled.svg';
import { ReactComponent as radioOff } from './svg/radioOff.svg';
import { ReactComponent as radioOn } from './svg/radioOn.svg';
import { ReactComponent as rateReview } from './svg/rateReview.svg';
import { ReactComponent as Recruiting } from './svg/Recruiting.svg';
import { ReactComponent as redeem } from './svg/redeem.svg';
import { ReactComponent as rest } from './svg/rest.svg';
import { ReactComponent as reverseOffer } from './svg/reverseOffer.svg';
import { ReactComponent as search } from './svg/search.svg';
import { ReactComponent as selfImprovement } from './svg/selfImprovement.svg';
import { ReactComponent as settings } from './svg/settings.svg';
import { ReactComponent as shield } from './svg/shield.svg';
import { ReactComponent as sortDown } from './svg/sortDown.svg';
import { ReactComponent as sortDownSelected } from './svg/sortDownSelected.svg';
import { ReactComponent as sortUp } from './svg/sortUp.svg';
import { ReactComponent as sortUpSelected } from './svg/sortUpSelected.svg';
import { ReactComponent as star } from './svg/star.svg';
import { ReactComponent as starBorder } from './svg/starBorder.svg';
import { ReactComponent as starHalf } from './svg/starHalf.svg';
import { ReactComponent as stop } from './svg/stop.svg';
import { ReactComponent as store } from './svg/store.svg';
import { ReactComponent as subMember } from './svg/subMember.svg';
import { ReactComponent as swapHorizontal } from './svg/swapHorizontal.svg';
import { ReactComponent as top } from './svg/top.svg';
import { ReactComponent as trash } from './svg/trash.svg';
import { ReactComponent as upload } from './svg/upload.svg';
import { ReactComponent as uploading } from './svg/uploading.svg';
import { ReactComponent as warning } from './svg/warning.svg';
import { ReactComponent as wavingHand } from './svg/wavingHand.svg';

const icons = {
  accountArrowRight,
  accountBalanceWallet,
  action,
  addCircle,
  adminPanelSettings,
  apartment,
  bank,
  camera,
  campaign,
  cancel,
  cancelHover,
  cancelOnimage,
  cancelOnimageHover,
  check,
  checkboxChecked,
  checkboxDisabled,
  checkboxUnchecked,
  chevronClose,
  chevronFwd,
  chevronLess,
  chevronMore,
  chevronOpen,
  chevronRwd,
  clock,
  closeCircleOutlineCheck,
  comment,
  confirm,
  confirmationNumber,
  contentCopyNormal,
  currencyYen,
  dateRange,
  dateRangeNormal,
  doNotDisturb,
  download,
  downloading,
  edit,
  ellipsis,
  ellipsisSelected,
  end,
  eye,
  eyeSlash,
  favoriteBorder,
  favoriteBorderO,
  feed,
  hail,
  handExtended,
  helpOutline,
  home,
  hotel,
  howToReg,
  income,
  indicatorBlue,
  indicatorRed,
  indictorYellow,
  key,
  language,
  mailOutline,
  mainMember,
  menuClose,
  menuOpen,
  militaryTech,
  nonActive,
  notificationsExistence,
  notificationsNone,
  openInBrowser,
  outcome,
  payment,
  pdf,
  personOutline,
  personSearch,
  point,
  print,
  radioDisabled,
  radioOff,
  radioOn,
  rateReview,
  Recruiting,
  redeem,
  rest,
  reverseOffer,
  search,
  selfImprovement,
  settings,
  shield,
  sortDown,
  sortDownSelected,
  sortUp,
  sortUpSelected,
  star,
  starBorder,
  starHalf,
  stop,
  store,
  subMember,
  swapHorizontal,
  top,
  trash,
  upload,
  uploading,
  warning,
  wavingHand,
};

export type Name = keyof typeof icons;

export const iconList = Object.keys(icons) as Name[];

export interface Props {
  name: Name;
  size?: number;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button`
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const StyledSpan = styled.span<{ color: string }>`
  display: inline-flex;
  svg {
    fill: ${({ color }) => color};
    path {
      fill: ${({ color }) => color};
    }
  }
`;

const DEFAULT_SIZE = 24;

export function Icon({ name, size = DEFAULT_SIZE, color, onClick }: Props) {
  const SvgComponent = icons[name];
  if (onClick) {
    return (
      <StyledButton type="button" onClick={onClick}>
        {color ? (
          <StyledSpan color={color}>
            <SvgComponent style={{ height: `${size}px`, width: `${size}px` }} />
          </StyledSpan>
        ) : (
          <SvgComponent style={{ height: `${size}px`, width: `${size}px` }} />
        )}
      </StyledButton>
    );
  }
  return color ? (
    <StyledSpan color={color}>
      <SvgComponent style={{ height: `${size}px`, width: `${size}px` }} />
    </StyledSpan>
  ) : (
    <SvgComponent style={{ height: `${size}px`, width: `${size}px` }} />
  );
}
