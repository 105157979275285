/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useRef, useState } from 'react';
import { styled } from '@linaria/react';
import { Text } from 'components/text';

type VTScorePercentInfo = {
	tpmem_id?: number | null
	score5_per?: number | null
	score4_per?: number | null
	score3_per?: number | null
	score2_per?: number | null
	score1_per?: number | null
};

const Wrapper = styled.div`
  display: flex;
`;

const Toast = styled.div`
  display: flex;
  flex: 1;
  position: absolute;

`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  left: 5px;
  top: 35px;
  
  height: 160px;
  width: 285px;

  padding: 24px 16px;

  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 16px;

  gap: 8px;
`;

const Indicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  flex: 1;

  background: #DBDBDB;
  border: 1px solid #E8A700;
  border-radius: 4px;
`;

const Bar = styled.div<{
  width: number
}>`
  display: flex;

  width: ${({width})=> `${width}%`};
  height: 100%;

  background: #E8A700;
`;

function CustomRow(
  props: {
    percent: number
    score: number
  }
){
  const { percent, score } = props;

  return(
    <Row>
      <Text
        color="primaryBlue"
        variant="caption12"
      >
        星{score}つ
      </Text>
      <Indicator>
        <Bar width={percent} />
      </Indicator>
      <Text
        color="darkBlue"
        variant="caption12"
      >
        {percent}%
      </Text>
    </Row>
  );
}

function ScoreDistribution (
  { vTScorePercentInfo } : {
    vTScorePercentInfo : VTScorePercentInfo
  }
) {
  const element: ReactNode[] = [];

  element.push(
    <CustomRow 
      percent={
        typeof vTScorePercentInfo.score1_per === 'number' ?
        vTScorePercentInfo.score1_per : 0
      }
      score={1}
    />
  );
  element.push(
    <CustomRow 
      percent={
        typeof vTScorePercentInfo.score2_per === 'number' ?
        vTScorePercentInfo.score2_per : 0
      } 
      score={2}
    />
  );
  element.push(
    <CustomRow 
      percent={
        typeof vTScorePercentInfo.score3_per === 'number' ?
        vTScorePercentInfo.score3_per : 0
      } 
      score={3}
    />
  );
  element.push(
    <CustomRow 
      percent={
        typeof vTScorePercentInfo.score4_per === 'number' ?
        vTScorePercentInfo.score4_per : 0
      } 
      score={4}
    />
  );
  element.push(
    <CustomRow 
      percent={
        typeof vTScorePercentInfo.score5_per === 'number' ?
        vTScorePercentInfo.score5_per : 0
      } 
      score={5}
    />
  );

  return element;
};

export function ScoreToast(
  { children, vTScorePercentInfo } : {
    children: ReactNode, vTScorePercentInfo: VTScorePercentInfo 
  }
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toastRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent | TouchEvent) => {
    if(!toastRef.current) throw Error('scoreToast.tsx: ref object is not assigned');
    if(!toastRef.current.contains(e.target as Element)){
      setIsOpen(false);
    }
  };

  React.useEffect(()=> {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  return (
    <Wrapper>
      <div onClick={()=> setIsOpen(true)}>
        {children}
      </div>
      {
        // isOpen ?? (
        isOpen === true ? (
          <Toast ref={toastRef}>
            <Card>
              {
                ScoreDistribution({vTScorePercentInfo})
              }
            </Card>
          </Toast>
        ) : (<div ref={toastRef} />)
      }
    </Wrapper>
  );
}