/**
 * Figma ID: 10-08-03-05
 * 名称: eギフト設定（eギフト選択肢）
 */
import React, { useEffect } from 'react';
import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { ScrollableArea } from 'components/scrollableArea';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { EgiftList } from 'components/egiftList';
import { useForm } from 'react-hook-form';
import {
  useGetSEgiftCodeQuery,
  useGetVbGiftSettingListQuery,
  useUpdateSBenefitsByKindMutation,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows, ModalZindex } from 'components/const';
import { SelectGiftContent, SelectGiftFooter, SelectGiftHeader } from './settingFixedPrivilege/SelectEgift';
import { ConfirmSelectedGiftContent, ConfirmSelectedGiftFooter } from './settingFixedPrivilege/ConfirmSelectedEgift';
import { CompleteGiftContent, CompleteGiftFooter, CompleteGiftHeader } from './settingFixedPrivilege/CompleteEgift';

export interface SelectedGiftModel {
  title: string;
  deadline: string;
  configCode: string;
  kind: number;
  egiftCode: number;
}

type SettingFixedPrivilegePhase = 'Select' | 'Confirm' | 'Complete';
function FixedPrivilegeSetting() {
  const {
    data: GiftChoiceData,
    loading: giftChoiceLoading,
    error: giftChoiceError,
    refetch: giftChoiceRefetch,
  } = useGetVbGiftSettingListQuery();
  const { data: SettingEGift, loading: settingGiftLoading, error: settingGiftError, refetch } = useGetSEgiftCodeQuery();
  const [updateBenefits] = useUpdateSBenefitsByKindMutation();
  const [settingFixedPrivilegeOpen, setSettingFixedPrivilegeOpen] = React.useState(false);
  const [settingPhase, setSettingPhase] = React.useState<SettingFixedPrivilegePhase>();
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectedGiftModel>({ reValidateMode: 'onSubmit' });
  const currentKind = watch('kind');
  const currentEgiftCode = watch('egiftCode');
  const { openErrorModal } = useErrorModal();

  useEffect(() => {
    if (settingGiftError || giftChoiceError) {
      openErrorModal({ message: 'エラーが発生しました。一定時間後、再度お試しください。' });
    }
  }, [giftChoiceError, openErrorModal, settingGiftError]);

  const generateKindText = (index: number) => {
    switch (index) {
      case 0:
        return 'レビュー後支給用';
      case 1:
        return '誕生月特典用';
      case 2:
        return '友達招待特典用';
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (settingFixedPrivilegeOpen) {
      setSettingPhase('Select');
    } else {
      setSettingPhase(undefined);
    }
  }, [settingFixedPrivilegeOpen]);

  const settingFixedPrivilegeHeader = () => {
    switch (settingPhase) {
      case 'Complete':
        return <CompleteGiftHeader />;
      default:
        return <SelectGiftHeader />;
    }
  };
  const settingFixedPrivilegeContent = () => {
    switch (settingPhase) {
      case 'Confirm':
        return <ConfirmSelectedGiftContent watch={watch} />;
      case 'Complete':
        return <CompleteGiftContent />;
      default:
        return (
          <SelectGiftContent
            control={control}
            selectOption={SettingEGift}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        );
    }
  };
  const toNextPhase = () => {
    switch (settingPhase) {
      case 'Select':
        handleSubmit(() => setSettingPhase('Confirm'))();
        break;
      default:
        updateBenefits({
          variables: { kind: currentKind, egift_code: currentEgiftCode },
        })
          .then((result) => {
            if (result.data?.updateSBenefitsByKind.resultRows === ResultRows.UPDATED) {
              setSettingPhase('Complete');
            } else {
              openErrorModal({
                message: '保存に失敗しました。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          })
          .catch((e) => {
            // 通信自体ができない場合
            if (e instanceof Error) {
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                zIndex: ModalZindex.ERRMODALZINDEX,
              });
            }
          });

        break;
    }
  };
  const toBackPhase = () => {
    setSettingPhase('Select');
  };

  const onClickClose = () => {
    setSettingFixedPrivilegeOpen(false);
    reset();
  };
  const settingFixedPrivilegeFooter = () => {
    switch (settingPhase) {
      case 'Confirm':
        return (
          <ConfirmSelectedGiftFooter
            onClickClose={() => onClickClose()}
            onClickNext={toNextPhase}
            onClickBack={toBackPhase}
          />
        );
      case 'Complete':
        return (
          <CompleteGiftFooter
            onClickClose={() => {
              onClickClose();
              refetch();
              giftChoiceRefetch();
            }}
          />
        );
      default:
        return <SelectGiftFooter onClickNext={toNextPhase} onClickClose={() => onClickClose()} />;
    }
  };

  return (
    <HideWrapper>
      <Box pa={24} display="flex">
        <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
          <Text color="darkBlue" variant="h2">
            固定特典設定
          </Text>
          <Text color="darkBlue" variant="caption12">
            ギフト選択肢から追加したレビュー後、eギフトを誕生月特典用、友達招待特典用に設定します。
          </Text>
        </Box>
      </Box>
      <Box pr={24} pl={24}>
        <Divider option="horizontal" />
      </Box>
      <ScrollWrapper bottom={260}>
        <ScrollableArea>
          {giftChoiceLoading || settingGiftLoading ? (
            <Loading />
          ) : (
            <Box px={24} pb={20}>
              {GiftChoiceData?.getVBGiftSettingList?.map((val, index) => (
                <EgiftList
                  key={Math.random().toString()}
                  text1={generateKindText(index)}
                  text2={val?.egift_name}
                  deadline={val?.deadline}
                  menu={[{ label: '設定', value: 'setting' }]}
                  menuCallback={() => {
                    setSettingFixedPrivilegeOpen(true);
                    setValue('kind', val.kind);
                  }}
                />
              ))}
            </Box>
          )}
        </ScrollableArea>
      </ScrollWrapper>
      <Modal
        isOpen={settingFixedPrivilegeOpen}
        overflow="visible"
        header={settingFixedPrivilegeHeader()}
        content={settingFixedPrivilegeContent()}
        footer={settingFixedPrivilegeFooter()}
        onClose={() => {
          setSettingFixedPrivilegeOpen(false);
          reset();
        }}
        height="auto"
      />
    </HideWrapper>
  );
}

export default FixedPrivilegeSetting;
