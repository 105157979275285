import { useState, useCallback } from 'react';

type UseMultipleCheckedRet<T> = {
  checked: T[];
  canCheckItems: T[];
  toggleChecked: (tgt: T) => void;
  allCheck: () => void;
  clearCheck: () => void;
  isChecked: (tgt: T) => boolean;
};

export function useMultipleCheck<T>(
  canCheckItems: T[], initVal?: T[]
): UseMultipleCheckedRet<T> {
  const [checked, setChecked] = useState<T[]>(initVal || []);

  const toggleChecked = useCallback((tgt: T) => {
    if (checked.includes(tgt)) {
      setChecked([...checked.filter((item) => item !== tgt)]);
    } else {
      setChecked([...checked.concat([tgt])]);
    }
  }, [checked]);

  const isChecked = useCallback(
    (tgt: T) => checked.includes(tgt), [checked]
  );

  const allCheck = useCallback(() => setChecked(canCheckItems), [canCheckItems]);
  const clearCheck = useCallback(() => setChecked([]), []);

  return {
    checked,
    canCheckItems,
    toggleChecked,
    allCheck,
    clearCheck,
    isChecked,
  };
}