/**
 * Figma ID: 07-01-01, 07-01-02, 07-01-04, 07-01-07, 07-02-01, 07-03-01, 07-04-01
 * 名称: 逆オファー
 */
import React from 'react';
import { SubMenu } from 'components/subMenu';
import { useParams } from 'react-router-dom';
import { ContentsCard, PageBody, PageContentsWrapper } from 'components/assets/css/pages/pageStyle';
import { Loading } from 'components/loading/loading';
import {
  GetVbOfferAcceptListByOfferIdQuery,
  GetVbOfferInfoByIdQuery,
  useGetVbOfferAcceptListByOfferIdLazyQuery,
  useGetVbOfferInfoByIdQuery,
  useGetVbOfferListLoggingByOfferIdQuery,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import ReverceOfferInfo from './component/ReverceOfferInfo';
import OFHeader from './component/Header';
import AcceptOffer from './component/AcceptOffer';
import RecruitmentHistory from './component/RecruitmentHistory';

type TabTypes = 'ReverceOfferInfo' | 'AcceptOffer' | 'RecruitmentHistory';
function Screen07Details() {
  const { offerId } = useParams(); // APIに渡す用のID。
  const [selectedTab, setSelectedTab] = React.useState<TabTypes>();
  const {
    data: reverceOfferInfoData,
    loading: reverceOfferInfoLoading,
    refetch: reverceOfferInfoRefetch,
    error: reverceOfferInfoError,
  } = useGetVbOfferInfoByIdQuery({
    variables: { id: Number(offerId) },
  });

  const [reverceOfferInfo, setReverceOfferInfo] = React.useState<GetVbOfferInfoByIdQuery | undefined>(
    reverceOfferInfoData
  );
  const [
    getVBOfferAcceptListByOfferId,
    { data: acceptOfferInfo, loading: acceptOfferLoading, error: acceptOfferError },
  ] = useGetVbOfferAcceptListByOfferIdLazyQuery();
  const [acceptOfferData, setAcceptOfferData] = React.useState<GetVbOfferAcceptListByOfferIdQuery | undefined>(
    acceptOfferInfo
  );
  const {
    data: recruitmentHistoryData,
    loading: recruitmentHistoryLoading,
    error: recruitmentHistoryError,
  } = useGetVbOfferListLoggingByOfferIdQuery({
    variables: { offer_id: Number(offerId ?? 0) },
  });

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (reverceOfferInfo?.getVBOfferInfoById.id !== undefined) {
      getVBOfferAcceptListByOfferId({
        variables: { b_offer_id: Number(reverceOfferInfo.getVBOfferInfoById.id) },
      });
    }
  }, [getVBOfferAcceptListByOfferId, reverceOfferInfo?.getVBOfferInfoById.id]);

  React.useEffect(() => {
    if (reverceOfferInfoError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [reverceOfferInfoError, openErrorModal]);

  React.useEffect(() => {
    if (recruitmentHistoryError && selectedTab === 'RecruitmentHistory') {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [recruitmentHistoryError, openErrorModal, selectedTab]);

  React.useEffect(() => {
    if (acceptOfferError && selectedTab === 'AcceptOffer') {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [acceptOfferError, openErrorModal, selectedTab]);

  const tpNum = reverceOfferInfo?.getVBOfferInfoById?.tp_num ?? 0; // 受入店数
  React.useEffect(() => {
    if (selectedTab === 'ReverceOfferInfo') {
      setReverceOfferInfo(reverceOfferInfoData);
    } else if (selectedTab === 'AcceptOffer') {
      setAcceptOfferData(acceptOfferInfo);
    }
  }, [acceptOfferInfo, reverceOfferInfoData, selectedTab]);
  return (
    <PageContentsWrapper>
      {reverceOfferInfo?.getVBOfferInfoById !== undefined && (
        <OFHeader
          offerData={reverceOfferInfo?.getVBOfferInfoById}
          refetch={() => {
            reverceOfferInfoRefetch();
          }}
        />
      )}
      <PageBody bottom={60}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                content: reverceOfferInfoLoading ? (
                  <Loading />
                ) : (
                  <ReverceOfferInfo
                    reverceOfferInfo={reverceOfferInfo}
                    selectTab={() => setSelectedTab('ReverceOfferInfo')}
                  />
                ),
                label: '逆オファー情報',
              },
              {
                content: acceptOfferLoading ? (
                  <Loading />
                ) : (
                  <AcceptOffer
                    acceptOfferData={acceptOfferData}
                    selectTab={() => setSelectedTab('AcceptOffer')}
                    tpNum={tpNum}
                  />
                ),
                label: `オファー受入店 (${tpNum})`,
              },
              {
                content: recruitmentHistoryLoading ? (
                  <Loading />
                ) : (
                  <RecruitmentHistory
                    recruitmentHistoryData={recruitmentHistoryData}
                    selectTab={() => setSelectedTab('RecruitmentHistory')}
                  />
                ),
                label: '履歴',
              },
            ]}
          />
        </ContentsCard>
      </PageBody>
    </PageContentsWrapper>
  );
}

export default Screen07Details;
