/**
 * Figma ID: 10-08-01-03
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { DatePicker } from 'components/datePicker';
import { RhfInput } from 'components/rhfInput';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import type { InputDataModel } from '../GiftChoice';

type InputDataUpdateDeadlineContentModel = {
  control: Control<InputDataModel>;
  setValue: UseFormSetValue<InputDataModel>;
  errors: FieldErrors<InputDataModel>;
  register: UseFormRegister<InputDataModel>;
  watch: UseFormWatch<InputDataModel>;
};
function InputDataUpdateDeadlineContent(props: InputDataUpdateDeadlineContentModel) {
  const { control, setValue, errors, register, watch } = props;
  const currentDate = watch('deadline');
  const currentToken = watch('token');
  const currentId = watch('configCode');
  const currentPrice = watch('price');

  return (
    <Box>
      {currentPrice !== undefined ? (
        <Box pb={8}>
          <Text variant="body14">トークン、ID、加盟店請求用金額、発行期限を入力してください。</Text>
        </Box>
      ) : (
        <Box pb={8}>
          <Text variant="body14">トークン、ID、発行期限を入力してください。</Text>
        </Box>
      )}
      <Box pb={26}>
        <InputLabel>トークンを入力</InputLabel>
        <RhfInput
          control={control}
          defaultValue={currentToken}
          name="token"
          placeholder="トークン番号"
          type="text"
          rules={{
            required: '必須項目です',
            pattern: {
              value: /^[a-zA-Z0-9-]*$/,
              message: '半角の英数字でトークン番号を入力してください。',
            },
          }}
        />
        {errors.token?.message && <Text color="cautionRed" variant="caption12">{`${errors.token.message}`}</Text>}
      </Box>
      <Box pb={26}>
        <InputLabel>IDを入力</InputLabel>
        <RhfInput
          control={control}
          defaultValue={currentId}
          name="configCode"
          placeholder="ID"
          type="text"
          rules={{
            required: '必須項目です',
            pattern: {
              value: /^[a-zA-Z0-9-]*$/,
              message: '半角の英数字でIDを入力してください。',
            },
          }}
        />
        {errors.configCode?.message && (
          <Text color="cautionRed" variant="caption12">{`${errors.configCode.message}`}</Text>
        )}
      </Box>
      {currentPrice !== undefined && (
        <Box pb={26}>
          <InputLabel>ギフト単価（¥）</InputLabel>
          <RhfInput
            control={control}
            defaultValue={currentPrice}
            name="price"
            placeholder="金額"
            type="number"
            rules={{
              required: '必須項目です',
              pattern: {
                value: /^[0-9]*$/,
                message: '正しい金額を入力してください。',
              },
            }}
          />
          {errors.price?.message && <Text color="cautionRed" variant="caption12">{`${errors.price.message}`}</Text>}
        </Box>
      )}
      <Box pb={18}>
        <InputLabel>発行期限</InputLabel>
        <DatePicker
          placeholderText="発行期限"
          minDate={new Date()}
          selected={
            currentDate === null || currentDate === undefined || currentDate < new Date() ? new Date() : currentDate
          }
          {...register('deadline', { required: '発行期限を選択してください。' })}
          onChange={(val) => {
            setValue('deadline', val);
          }}
        />
        {/* TODO エラーメッセージの有無で表示判定したい https://bitbucket.org/slothnetworksinc/bitravel_operational_web/pull-requests/275 */}
        {!currentDate && <Text color="cautionRed" variant="caption12">{`${errors.deadline?.message ?? ''}`}</Text>}
      </Box>
    </Box>
  );
}

export default InputDataUpdateDeadlineContent;
