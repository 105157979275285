/**
 * Figma ID: 10-01-04-02
 * 名称: 管理アカウント（パスワード編集）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';

type Props = {
  onClickClose: () => void;
};

export function CompleteUpdatePasswordModal({ onClickClose }: Props) {
  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            パスワード変更 完了
          </Text>
        </Box>
      }
      content={
        <Box pa={8}>
          <Text variant="body14">情報の変更が完了しました。</Text>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
          <MainButton onClick={onClickClose} width={130} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      width={384}
      height={189}
    />
  );
}
