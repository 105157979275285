/**
 * Figma ID: 10-08-01-04, 10-08-01-05
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import type { UpdateDeadlinePhase } from '../GiftUpdate';

type ConfirmUpdateDeadlineFooterModel = {
  updateDeadlinePhase?: UpdateDeadlinePhase;
  onClickNext: () => void;
  onClickClose: () => void;
  onClickBack: () => void;
};
function ConfirmUpdateDeadlineFooter(props: ConfirmUpdateDeadlineFooterModel) {
  const { onClickNext, onClickClose, onClickBack, updateDeadlinePhase } = props;
  const completeCloseModal = () => {
    onClickClose();
  };
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
      {updateDeadlinePhase === 'complete' ? (
        <MainButton onClick={completeCloseModal} width={104} variant="secondary" thin>
          閉じる
        </MainButton>
      ) : (
        <Box display="flex" justifyContent="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary" thin>
            キャンセル
          </MainButton>
          <MainButton onClick={onClickBack} width={104} variant="secondary" thin>
            戻る
          </MainButton>
          <MainButton onClick={onClickNext} width={104} thin>
            更新する
          </MainButton>
        </Box>
      )}
    </Box>
  );
}

export default ConfirmUpdateDeadlineFooter;
