/**
 * Figma ID: 07-01-02
 * 名称: 逆オファー（逆オファー情報）
 */
import { HideWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Info } from 'components/info';
import { Divider } from 'components/newDivider';
import { ScrollableArea } from 'components/scrollableArea';
import { Text } from 'components/text';
import React from 'react';
import { Modal } from 'components/newModal';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ButtonLink } from 'components/textLink/textLink.stories';
import { useErrorModal } from 'components/error/errorModalProvider';
import BtmemDetailHeader from './btmemDetail/BtmemDetailHeader';
import BtmemDetailContent from './btmemDetail/BtmemDetailContent';
import BtmemDetailFooter from './btmemDetail/BtmemDetailFooter';

type ReverceOfferOInfoTabModel = {
  selectTab: () => void;
  reverceOfferInfo: gql.GetVbOfferInfoByIdQuery | undefined;
};
function ReverceOfferInfo(props: ReverceOfferOInfoTabModel) {
  const { selectTab, reverceOfferInfo } = props;
  const [btmemDetailShow, setBtmemDetailShow] = React.useState(false);
  const [getVBMemberBaseById, { data: btmemData, loading: btmemDataLoading, error: btmemDataError }] =
    gql.useGetVbMemberBaseByIdLazyQuery();
  const [btmemDataInfo, setBtmemDataInfo] = React.useState<gql.GetVbMemberBaseByIdQuery | undefined>(btmemData);

  const btmemId = reverceOfferInfo?.getVBOfferInfoById.btmem_id;
  React.useEffect(() => {
    if (btmemId !== undefined && btmemId !== 0) {
      getVBMemberBaseById({
        variables: { id: btmemId },
      });
    }
  }, [getVBMemberBaseById, btmemId]);

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (btmemDataError && btmemDetailShow && reverceOfferInfo?.getVBOfferInfoById?.age !== null) {
      setBtmemDetailShow(false);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    } else if (btmemDataError && btmemDetailShow && reverceOfferInfo?.getVBOfferInfoById.age === null) {
      setBtmemDetailShow(false);
      openErrorModal({
        title: '',
        message: `${reverceOfferInfo?.getVBOfferInfoById.btmem_name}は退会しました。`,
      });
    }
  }, [btmemDataError, openErrorModal, btmemDetailShow, reverceOfferInfo]);

  React.useEffect(() => {
    selectTab();
    setBtmemDataInfo(btmemData);
  }, [btmemData, selectTab]);

  const rankBizNameList = reverceOfferInfo?.getVBOfferInfoById?.rank_biz_names?.split(',') ?? [];
  const ranknameList = reverceOfferInfo?.getVBOfferInfoById?.rank_names?.split(',') ?? [];

  const rankBiz = () => {
    const item = [];
    for (let i = 0; i < rankBizNameList.length; i += 1) {
      item.push({
        label: `ランク(${rankBizNameList[i]})`,
        content: ranknameList[i],
      });
    }
    return item;
  };

  return (
    <HideWrapper style={{ padding: '24px' }}>
      <Box pb={19}>
        <Text color="darkBlue" variant="h2">
          逆オファー情報
        </Text>
      </Box>
      <Divider option="horizontal" />
      <ScrollableArea>
        <Box pb={80}>
          <Info
            items={[
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.offer_no,
                label: 'オファーNo.',
              },
              {
                content: btmemDataLoading ? (
                  <Loading />
                ) : (
                  <ButtonLink onClick={() => setBtmemDetailShow(true)}>
                    <div style={{ fontSize: '14px' }}>{reverceOfferInfo?.getVBOfferInfoById.btmem_name}</div>
                  </ButtonLink>
                ),
                label: 'オファーした人',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.btmem_state,
                label: '居住都道府県',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.created_date,
                label: '登録日時',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.biz_name,
                label: '希望業種',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.brand_name,
                label: '希望ブランド',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.begin_end,
                label: 'バイト期間',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.work_time,
                label: '希望時間',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.hour_wage,
                label: '希望時給',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.state_city,
                label: '希望地域',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.age,
                label: '年齢',
              },
              ...rankBiz(),
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.score,
                label: 'スコア',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.belong,
                label: '所属店舗',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.gender,
                label: '性別',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.attribute,
                label: '属性',
              },
              {
                content: `${String(reverceOfferInfo?.getVBOfferInfoById?.late_count)}回 / ${String(
                  reverceOfferInfo?.getVBOfferInfoById?.late_per
                )}%`,
                label: '遅刻率',
              },
              {
                content: `${String(reverceOfferInfo?.getVBOfferInfoById?.cancel_count)}回 / ${String(
                  reverceOfferInfo?.getVBOfferInfoById?.cancel_per
                )}%`,
                label: 'キャンセル率',
              },
              {
                content: `${String(reverceOfferInfo?.getVBOfferInfoById?.imminent_count)}回 / ${String(
                  reverceOfferInfo?.getVBOfferInfoById?.imminent_per
                )}%`,
                label: 'ドタキャン率',
              },
              {
                content: reverceOfferInfo?.getVBOfferInfoById?.comment,
                label: 'コメント',
              },
            ]}
          />
        </Box>
      </ScrollableArea>
      <Modal
        isOpen={btmemDetailShow}
        header={
          <BtmemDetailHeader
            name={reverceOfferInfo?.getVBOfferInfoById?.btmem_name ?? ''}
            id={reverceOfferInfo?.getVBOfferInfoById?.btmem_id ?? 0}
          />
        }
        content={<BtmemDetailContent data={btmemDataInfo} />}
        footer={<BtmemDetailFooter onClickClose={() => setBtmemDetailShow(false)} />}
        onClose={() => {
          setBtmemDetailShow(false);
        }}
        width={955}
        height="auto"
      />
    </HideWrapper>
  );
}

export default ReverceOfferInfo;
