import React from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { HistoryList } from 'components/historyList';
import { PaginationBox } from 'components/pagination';
import { WorkflowHistoryItem } from '../types';

export interface Props {
  workflowHistory: WorkflowHistoryItem[];
}

const StyledListWrapper = styled.div`
  max-height: calc(100vh - 350px);
  height: calc(100vh - 350px);
  overflow: auto;
`;

export function WorkflowHistory({ workflowHistory }: Props) {
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const displayHistoryItems: {
    uniqueKey: string | number;
    text: string;
    dateTime: string;
  }[] = React.useMemo(
    () =>
      workflowHistory
        .map(({ btmem_id, matter, log_date }) => ({
          uniqueKey: btmem_id,
          text: matter,
          dateTime: log_date,
        }))
        .slice(currentLimit * (currentPage - 1), currentLimit * currentPage),
    [currentPage, currentLimit, workflowHistory]
  );

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  return (
    <Box px={24} display="flex" flexDirection="column" width="100%">
      <div>
        <Box height={60} display="flex" alignItems="center">
          <Box flex="auto">
            <Text variant="h2" color="darkBlue">
              ワークフロー履歴
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
      </div>
      <Box flex="auto">
        <PaginationBox
          dataSize={workflowHistory.length}
          limit={currentLimit}
          page={currentPage}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <StyledListWrapper>
            <HistoryList items={displayHistoryItems} />
          </StyledListWrapper>
        </PaginationBox>
      </Box>
    </Box>
  );
}
