/**
 * Figma ID: 10-04-05
 * 名称: 2段階認証設定
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

export function ReleaseMfaModalHeader() {
  return (
    <Box>
      <Text color="darkBlue" variant="h2">
        二段階認証設定解除
      </Text>
    </Box>
  );
}

export function ReleaseMfaModalContent() {
  return (
    <Box>
      <Box>
        <Text variant="body14">二段階認証設定を解除します。</Text>
      </Box>
      <Box>
        <Text variant="body14">よろしいですか？</Text>
      </Box>
    </Box>
  );
}

type CompleteUpdateModel = {
  onClickClose: () => void;
  toNextPhase: () => void;
};
export function ReleaseMfaModalFooter(props: CompleteUpdateModel) {
  const { onClickClose, toNextPhase } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={130} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={toNextPhase} width={130}>
        解除
      </MainButton>
    </Box>
  );
}
