/**
 * Figma ID: 10-01-02-02
 * 名称: 管理アカウント（新規作成）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';

type Props = {
  onClickClose: () => void;
};

export function CompleteCreateAccountModal({ onClickClose }: Props) {
  return (
    <Modal
      isOpen
      header={
        <ModalHeaderContentWrapper>
          <Text color="darkBlue" variant="h2">
            アカウント新規追加 完了
          </Text>
        </ModalHeaderContentWrapper>
      }
      content={
        <Box pa={8}>
          <Text variant="body14">新規ユーザーに招待メールを送信しました。</Text>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
          <MainButton onClick={onClickClose} width={88} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      width={384}
      height={212}
      overflow="visible"
    />
  );
}
