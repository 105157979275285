/**
 * Figma ID: 10-01-02-01
 * 名称: 管理アカウント（新規作成）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { InputLabel } from 'components/inputLabel';

type Props = {
  toNextPhase: (args: { firstName: string; lastName: string; email: string; privilege: string }) => void;
  onClickClose: () => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  privilege: string;
};

export function CreateAccountModal({ toNextPhase, onClickClose }: Props) {
  const privilege = ['管理アカウント', '管理者', '一般'];
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    toNextPhase(data);
  });

  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            アカウント新規追加
          </Text>
        </Box>
      }
      content={
        <Box px={8}>
          <Box pt={8} pb={10}>
            <Box height={78} display="flex" flexDirection="column" gap={4} pb={18}>
              <InputLabel>氏名</InputLabel>
              <Box display="flex" gap={8} alignItems="center">
                <RhfInput
                  width={120}
                  placeholder="姓"
                  name="lastName"
                  control={control}
                  rules={{
                    required: '氏名を入力してください。',
                  }}
                />
                <RhfInput
                  width={120}
                  placeholder="名"
                  name="firstName"
                  control={control}
                  rules={{
                    required: '氏名を入力してください。',
                  }}
                />
              </Box>

              {(errors.lastName?.message || errors.firstName?.message) && (
                <Text color="cautionRed" variant="caption12">{`※${
                  errors.lastName?.message || errors.firstName?.message || ''
                }`}</Text>
              )}
            </Box>

            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>メールアドレス</InputLabel>
              <RhfInput
                placeholder="メールアドレス"
                name="email"
                control={control}
                rules={{
                  required: 'メールアドレスを入力してください。',
                  pattern: {
                    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                    message: '入力形式がメールアドレスではありません。',
                  },
                }}
                width={752}
              />
              {errors.email?.message && (
                <Text color="cautionRed" variant="caption12">{`※${errors.email.message}`}</Text>
              )}
            </Box>

            <Box height={78} display="flex" flexDirection="column" gap={4}>
              <InputLabel>権限</InputLabel>
              <RhfSelect
                name="privilege"
                control={control}
                options={[
                  { label: privilege[1], value: '1' },
                  { label: privilege[2], value: '2' },
                ]}
                placeholder="権限を選択"
                defaultValue=""
                rules={{
                  required: '権限を入力してください。',
                }}
                width={160}
              />
              {errors.privilege?.message && (
                <Text color="cautionRed" variant="caption12">{`※${errors.privilege.message}`}</Text>
              )}
            </Box>
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton
            disabled={!isValid}
            onClick={() => {
              onSubmit();
            }}
            width={104}
          >
            招待
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={426}
      width={800}
      overflow="visible"
    />
  );
}
