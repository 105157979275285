import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo } from 'react';
import * as gql from 'graphql/graphql-ow';
import { ErrorMessage } from 'components/errorMessage';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { useForm } from 'react-hook-form';
import { styled } from '@linaria/react';
import { checkAllTrue, removeNullValues, formViewStateValues, arraysAreEqual } from '../s10-02-utils';
import { RegionModalKey } from '../s10-02-types';
import { NewUpdateModalStep, StateId } from '../constants';

/**
 * Figma ID:10-02-01-03,10-02-01-09,10-02-02-03,10-02-02-09,10-02-09-03,10-02-09-09
 * 名称: 地域モーダル
 */

interface Props {
  title: string;
  stateIds: string[];
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setModalData: (e: string[]) => void;
  onClose: () => void;
  onNextHandleClick?: (values: string[]) => void;
  getMAreaState?: Array<gql.MAreaState | null> | null;
}

const StyledChildCheckboxWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 8px;
  padding: 0 16px;
`;

export function RegionModal({
  title,
  stateIds,
  setModalStep,
  setModalData,
  onClose,
  getMAreaState,
  onNextHandleClick,
}: Props) {
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegionModalKey>({ reValidateMode: 'onSubmit' });

  const onClickHandler = handleSubmit((event) => {
    if (onNextHandleClick) {
      const flatValues = event.state_ids?.ids.flat().flatMap((value) => value.optionValues);
      const ids = removeNullValues(flatValues || []);
      onNextHandleClick(removeNullValues(ids || []));
    } else {
      setModalStep(NewUpdateModalStep.CHECK);
    }
  });

  const dataFind = useMemo(() => {
    const checkBoxGroupData = getMAreaState
      ? getMAreaState?.map((AreaStates) => ({
          label: AreaStates?.area_name ? AreaStates?.area_name : '',
          options: AreaStates?.state
            ? AreaStates?.state?.map((state) => ({
                label: state?.state_name || '',
                value: String(state?.state_id),
              }))
            : [],
        }))
      : [];

    return { checkBoxGroupData };
  }, [getMAreaState]);

  const stateValues = useMemo(() => formViewStateValues(stateIds, getMAreaState), [getMAreaState, stateIds]);

  useEffect(() => {
    if (stateValues) {
      setValue('state_ids.views', stateValues || []);
      setValue('state_ids.ids', stateValues || []);
      stateValues?.forEach((value, index) => {
        setValue(
          `state_ids.views.${index}.value`,
          arraysAreEqual(value.optionValues, dataFind.checkBoxGroupData?.[index].options || [])
        );
      });
    }
  }, [dataFind.checkBoxGroupData, getMAreaState, setValue, stateValues, reset]);

  const setFormDateForModalData = useCallback(() => {
    const flatValues = getValues(`state_ids.ids`)
      .flat()
      .flatMap((value) => value.optionValues);
    const ids = removeNullValues(flatValues);
    setModalData(ids);
  }, [getValues, setModalData]);

  const displayRegionModal = React.useMemo(
    () => ({
      width: 800,
      height: 716,
      header: (
        <Text variant="h2" color="darkBlue">
          {title}
        </Text>
      ),
      content: (
        <Box>
          {dataFind.checkBoxGroupData?.map((item, index) => (
            <Box pb={16}>
              <RhfCheckbox
                name={`state_ids.views.${index}.value`}
                control={control}
                onChange={(event) => {
                  item.options.forEach((option, optionIndex) => {
                    if (event.target.checked) {
                      setValue(`state_ids.views.${index}.optionValues.${optionIndex}`, event.target.checked);
                      setValue(`state_ids.ids.${index}.optionValues.${optionIndex}`, item.options[optionIndex].value);
                    } else {
                      setValue(`state_ids.views.${index}.optionValues.${optionIndex}`, null);
                      setValue(`state_ids.ids.${index}.optionValues.${optionIndex}`, null);
                    }
                    setFormDateForModalData();
                  });
                }}
              >
                <Text variant="h3" color="blueGray">
                  {item.label}
                </Text>
              </RhfCheckbox>
              <StyledChildCheckboxWrapper>
                {item.options.map((option, optionIndex) => (
                  <Box display={option.value === StateId.Hokkaido ? 'none' : 'block'}>
                    <RhfCheckbox
                      name={`state_ids.views.${index}.optionValues.${optionIndex}`}
                      defaultValue={getValues(`state_ids.views.${index}.optionValues.${optionIndex}`)}
                      control={control}
                      rules={{
                        validate: (_, values) => {
                          let isAtLeastOneChecked = false;
                          values.state_ids?.views.forEach((brand) => {
                            brand.optionValues.forEach((value) => {
                              if (value) {
                                isAtLeastOneChecked = true;
                              }
                            });
                          });
                          return isAtLeastOneChecked || '地域をチェックしてください';
                        },
                      }}
                      onChange={(event) => {
                        const optionValues = checkAllTrue(getValues(`state_ids.views.${index}.optionValues`), true);
                        setValue(`state_ids.views.${index}.value`, optionValues);
                        setValue(
                          `state_ids.ids.${index}.optionValues.${optionIndex}`,
                          event.target.checked ? option.value : null
                        );
                        setFormDateForModalData();
                      }}
                    >
                      {option.label}
                    </RhfCheckbox>
                  </Box>
                ))}
              </StyledChildCheckboxWrapper>
            </Box>
          ))}
          {errors?.state_ids && (
            <Box pt={4}>
              <ErrorMessage>地域をチェックしてください</ErrorMessage>
            </Box>
          )}
        </Box>
      ),
      footer: (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton
            thin
            onClick={() => {
              reset();
              onClose();
            }}
            variant="secondary"
            width={104}
          >
            キャンセル
          </MainButton>
          <MainButton
            thin
            onClick={() => {
              setModalStep(NewUpdateModalStep.INPUT);
            }}
            variant="secondary"
            width={104}
          >
            戻る
          </MainButton>
          <MainButton
            thin
            onClick={(event) => {
              onClickHandler(event);
            }}
            variant="primary"
            width={104}
          >
            次へ
          </MainButton>
        </Box>
      ),
      onClose: () => {
        reset();
        onClose();
      },
      reset,
    }),
    [
      title,
      dataFind.checkBoxGroupData,
      errors?.state_ids,
      control,
      setFormDateForModalData,
      setValue,
      getValues,
      onClose,
      setModalStep,
      onClickHandler,
      reset,
    ]
  );
  return { displayRegionModal };
}
