import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet, VtMemberListCreateKey } from 'pages/s03/type';
import React from 'react';
import { Control, UseFormGetValues } from 'react-hook-form';

export function CompleteModal(
  control: Control<VtMemberListCreateKey>,
  getValues: UseFormGetValues<VtMemberListCreateKey>,
  onClose: () => void
): ModalRet {
  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        新規登録 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" flex="1">
          <Text variant="body14">情報の登録が完了しました。</Text>
          <Text variant="body14">店舗コード：{getValues('id')}</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210,
  };

  return {
    ...modalContent,
  };
}
