import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { GENERIC_COLOR } from '../assets/css/style';
import { Card } from '../card';
import { ModalZindex } from '../const';

export interface Props {
  isOpen: boolean;
  width?: number | string;
  height?: number | string;
  header?: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
  overflow?: string;
  zindex?: number;
}

const StyledOverlay = styled.div<{
  zindex: number;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${GENERIC_COLOR.MORDAL_bg};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ zindex }) => zindex}};
`;

const StyledModal = styled.div<{
  width: number | string;
  height: number | string;
  zindex: number;
}>`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  z-index: ${({ zindex }) => zindex}};
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeader = styled.div<{
  zindex: number;
}>`
  width: 100%;
  height: 60px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  z-index: ${({ zindex }) => zindex}};
`;

const StyledContent = styled.div<{ overflow: string }>`
  width: 100%;
  height: 100%;
  padding: 24px;
  flex: auto;
  box-sizing: border-box;
  overflow: ${({ overflow }) => overflow}};
`;

const StyledFooter = styled.div<{
  zindex: number;
}>`
  width: 100%;
  height: 60px;
  padding: 16px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 8px 8px;
  box-sizing: border-box;
  z-index: ${({ zindex }) => zindex}};
`;

export function Modal({
  isOpen,
  width = 384,
  height = 231,
  header,
  content,
  footer,
  onClose: _onClose,
  overflow = 'auto',
  zindex,
}: Props) {
  const closeWithClickOutSideMethod = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      // onClose();
    }
  };
  const overlayZIndex = typeof zindex === 'number' ? zindex : ModalZindex.OVERLAYZINDEX;
  const modalContentZIndex = overlayZIndex + 1;
  const modalHeaderZIndex = modalContentZIndex + 1;
  const modalFooterZIndex = modalContentZIndex + 1;

  return isOpen ? (
    <StyledOverlay
      onClick={(e) => {
        closeWithClickOutSideMethod(e);
      }}
      zindex={overlayZIndex}
    >
      <StyledModal width={width} height={height} zindex={modalContentZIndex}>
        <Card>
          <StyledContentWrapper>
            {header ? <StyledHeader zindex={modalHeaderZIndex}>{header}</StyledHeader> : null}
            <StyledContent overflow={overflow}>{content}</StyledContent>
            {footer ? <StyledFooter zindex={modalFooterZIndex}>{footer}</StyledFooter> : null}
          </StyledContentWrapper>
        </Card>
      </StyledModal>
    </StyledOverlay>
  ) : null;
}
