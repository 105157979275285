import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import { MemberStatus } from 'pages/s03/const';
import React, { useState } from 'react';
import { useUpdateTMemberS2ByIdMutation, useUpdateTMemberS1ByIdMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useParams } from 'react-router-dom';
import { useErrorModal } from 'components/error/errorModalProvider';

enum ModalStep {
  Confirm = 1,
  Complete = 2,
}

// アクティブ/休止中・ステータス更新結果
enum ChangeResult {
  NOT_CHANGE = 0, // 更新なし
  CHANGED = 1, // 更新有り
  ADOPTED = -1, // 採用中のバイトあり
  RECRUTING = -2, // 募集中のバイトあり
  ADOPTED_BY_OTHER = -3, // 所属バイトラの他店成約バイトあり
}

export function StatusChangeModal(props: {
  isOpen: boolean;
  onClose: () => void;
  status: number;
  toggleState: () => void;
}) {
  const { isOpen, onClose, status, toggleState } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Confirm);
  const [updateTMemberS2ByIdMutation, { loading: tMemberS2ByIdLoading }] = useUpdateTMemberS2ByIdMutation();
  const [updateTMemberS1ByIdMutation, { loading: tMemberS1ByIdLoading }] = useUpdateTMemberS1ByIdMutation();
  const { id } = useParams<{ id: string }>();
  const onModalClose = () => {
    setModalStep(ModalStep.Confirm);
    onClose();
  };
  const { openErrorModal } = useErrorModal();
  const confirmModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        ステータス変更
      </Text>
    ),
    content:
      status === MemberStatus.Active ? (
        <Box display="flex" height="100%" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Text variant="body14">この加盟店を休止中に変更しますか？</Text>
            <Text variant="body14">募集中の採用等が配信停止になります。</Text>
          </Box>
        </Box>
      ) : (
        <Box display="flex" height="100%" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
            <Text variant="body14">この加盟店をアクティブに変更しますか？</Text>
          </Box>
        </Box>
      ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            if (status === MemberStatus.Active) {
              updateTMemberS2ByIdMutation({
                variables: {
                  id: Number(id),
                },
              })
                .then((result) => {
                  if (!result.errors) {
                    if (
                      result.data?.updateTMemberS2ById.resultRows !== undefined &&
                      result.data?.updateTMemberS2ById.resultRows !== null
                    ) {
                      switch (result.data.updateTMemberS2ById.resultRows) {
                        case ChangeResult.CHANGED:
                          setModalStep(ModalStep.Complete);
                          toggleState();
                          break;
                        case ChangeResult.ADOPTED:
                          onModalClose();
                          openErrorModal({
                            title: 'ステータスを変更できませんでした',
                            message: (
                              <Text variant="body14">
                                以下の理由によりステータスが変更できませんでした。
                                <br />
                                ・採用した成約バイトがあります
                                <br />
                              </Text>
                            ),
                            onClose: () => onModalClose(),
                          });
                          break;
                        case ChangeResult.RECRUTING:
                          onModalClose();
                          openErrorModal({
                            title: 'ステータスを変更できませんでした',
                            message: (
                              <Text variant="body14">
                                以下の理由によりステータスが変更できませんでした。
                                <br />
                                ・募集中の募集バイトがあります
                              </Text>
                            ),
                            onClose: () => onModalClose(),
                          });
                          break;
                        case ChangeResult.ADOPTED_BY_OTHER:
                          onModalClose();
                          openErrorModal({
                            title: 'ステータスを変更できませんでした',
                            message: (
                              <Text variant="body14">
                                以下の理由によりステータスが変更できませんでした。
                                <br />
                                ・所属バイトラベラーが他店募集に成約しています
                              </Text>
                            ),
                            onClose: () => onModalClose(),
                          });
                          break;
                        case ChangeResult.NOT_CHANGE:
                        default:
                          onModalClose();
                          openErrorModal({
                            message: 'ステータス変更が完了できませんでした。',
                          });
                          break;
                      }
                    } else {
                      onModalClose();
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                      });
                    }
                  } else {
                    onModalClose();
                    openErrorModal({
                      message:
                        'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                    });
                  }
                })
                .catch(() => {
                  onModalClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
            } else {
              updateTMemberS1ByIdMutation({
                variables: {
                  id: Number(id),
                },
              })
                .then((result) => {
                  if (!result.errors) {
                    setModalStep(ModalStep.Complete);
                    toggleState();
                  } else {
                    onModalClose();
                    openErrorModal({
                      message:
                        'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                    });
                  }
                })
                .catch(() => {
                  onModalClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                });
            }
          }}
        >
          変更
        </MainButton>
      </Box>
    ),
  };

  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        ステータス変更 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
          <Text variant="body14">ステータス変更が完了しました。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
  };

  const modalContent = modalStep === ModalStep.Confirm ? confirmModal : completeModal;

  return (
    <>
      {(tMemberS2ByIdLoading || tMemberS1ByIdLoading) && <Loading />}
      <Modal
        isOpen={isOpen}
        onClose={() => onModalClose()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
        width={384}
        height={210}
      />
    </>
  );
}
