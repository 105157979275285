/**
 * Figma ID: 10-01-05-02
 * 名称: 管理アカウント（権限変更）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';

type Props = {
  onClickClose: () => void;
};

export function CompleteUpdatePermissionModal({ onClickClose }: Props) {
  return (
    <Modal
      isOpen
      header={
        <ModalHeaderContentWrapper>
          <Text color="darkBlue" variant="h2">
            権限変更 完了
          </Text>
        </ModalHeaderContentWrapper>
      }
      content={
        <Box>
          <Text variant="body14">情報の変更が完了しました。</Text>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
          <MainButton onClick={onClickClose} width={88} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={192}
    />
  );
}
