import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { VariantType } from 'components/text/text';
import { Text } from '../text';
import { PRIMARY_COLOR } from '../assets/css/style';

export interface Props {
  children?: ReactNode;
  // サイト内リンクの場合はtoを指定してください
  to?: string;
  // 外部リンクの場合はhrefを指定してください
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: VariantType;
  lineClamp?: number;
}

const StyledAnchor = styled.a`
  text-decoration-line: underline;
  color: ${PRIMARY_COLOR.PRIMARY_BLUE};
`;

const StyledButton = styled.button`
  text-decoration-line: underline;
  color: ${PRIMARY_COLOR.PRIMARY_BLUE};
  border: none;
  background-color: inherit;
  cursor: pointer;
`;

const StyledRouterLink = styled.span`
  a {
    text-decoration-line: underline;
    color: ${PRIMARY_COLOR.PRIMARY_BLUE};
  }
`;

export function TextLink({ children, to = '', href, onClick, variant = 'caption12', lineClamp }: Props) {
  if (onClick) {
    return (
      <StyledButton onClick={onClick}>
        <Text variant={variant} color="primaryBlue" lineClamp={lineClamp}>
          {children}
        </Text>
      </StyledButton>
    );
  }

  if (href) {
    return (
      <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
        <Text variant={variant} color="primaryBlue" lineClamp={lineClamp}>
          {children}
        </Text>
      </StyledAnchor>
    );
  }

  return (
    <StyledRouterLink>
      <Link to={to}>
        <Text variant={variant} color="primaryBlue" lineClamp={lineClamp}>
          {children}
        </Text>
      </Link>
    </StyledRouterLink>
  );
}
