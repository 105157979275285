import { styled } from '@linaria/react';
import React, { ReactNode, useMemo } from 'react';
import { Text } from 'components/text';
import { BitravelerInfoStatus } from 'components/const';

const StyledImageLabel = styled.div`
  display: flex;
  position: relative;
`;

const StyledBG = styled.div<{
  color: string;
}>`
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: ${({ color }) => color};
`;

const StyledLabel = styled.div<{
  status: BitravelerInfoStatus | null;
}>`
  position: absolute;
  top: ${({ status }) => {
    switch (status) {
      case BitravelerInfoStatus.TEMPORARY_PAUSE:
      case BitravelerInfoStatus.STOP:
      case BitravelerInfoStatus.PAUSE:
        return '16px';
      default:
        return '0px';
    }
  }};
  left: ${({ status }) => {
    switch (status) {
      case BitravelerInfoStatus.STOP:
      case BitravelerInfoStatus.PAUSE:
        return '18px';
      default:
        return '0px';
    }
  }};
`;

// TODO: このコンポーネントを作るときに絶対知でテキストの設置位置を指定しているので、画像のサイズを変えたとき用にコンポーネントが２つになってしまっている。
export function ChangeBigImgWithStatus({
  status = BitravelerInfoStatus.ACTIVE,
  children,
}: {
  status?: number | null;
  children: ReactNode;
}) {
  const label: string = useMemo(() => {
    switch (status) {
      case BitravelerInfoStatus.TEMPORARY_PAUSE:
        return '一時停止';
      case BitravelerInfoStatus.STOP:
        return '停止';
      case BitravelerInfoStatus.PAUSE:
        return '休止';
      default:
        return '';
    }
  }, [status]);

  const bgColor: string = useMemo(() => {
    switch (status) {
      case BitravelerInfoStatus.TEMPORARY_PAUSE:
      case BitravelerInfoStatus.STOP:
        return 'rgba(0, 0, 0, 0.7)';
      case BitravelerInfoStatus.PAUSE:
        return 'rgba(229, 0, 0, 0.7)';
      default:
        return '';
    }
  }, [status]);

  return (
    <StyledImageLabel>
      <StyledBG color={bgColor} />
      {/* TODO: テキストの場所を絶対値で入れてしまっているので相対値にする必要あり */}
      <StyledLabel status={status}>
        <Text variant="body14" bold color="white">
          {label}
        </Text>
      </StyledLabel>
      {children}
    </StyledImageLabel>
  );
}
