/**
 * Figma ID: 05-05-01
 * 名称: 利用履歴
 */

import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { useEffect, useMemo, useState } from 'react';
import { HistoryList } from 'components/historyList';
import { ScrollableArea } from 'components/scrollableArea';
import * as gql from 'graphql/graphql-ow';
import { usePagenation } from 'components/utils';
import { Loading } from 'components/loading/loading';
import { ErrorModal } from 'components/errorModal';
import { useErrorModal } from 'components/error/errorModalProvider';

export function UsageHistory({ id }: { id: string }) {
  const {
    data,
    loading,
    error: memberListLoggingError,
  } = gql.useGetVbMemberListLoggingByIdQuery({
    variables: { id: Number(id) },
  });
  const [errorModalStep, setErrorModalStep] = React.useState<boolean>(false);
  const [dataSize, setDataSize] = useState<number>(0);

  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();

  const items = useMemo(
    () =>
      data?.getVBMemberListLoggingById.logging.map((logging, key) => ({
        text: logging.matter ?? '',
        dateTime: logging.log_date ?? '',
        uniqueKey: key,
      })) ?? [],
    [data?.getVBMemberListLoggingById]
  );

  useEffect(() => {
    setDataSize(items.length);
  }, [items.length]);

  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (memberListLoggingError) {
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [memberListLoggingError, openErrorModal]);

  if (loading) {
    return Loading();
  }

  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            利用履歴
          </Text>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flex="1" flexDirection="column">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <div style={{ height: 'calc(100vh - 370px)' }}>
              <ScrollableArea>
                <HistoryList items={items} />
              </ScrollableArea>
            </div>
          </PaginationBox>
        </Box>
      </Box>
      <ErrorModal
        title="error"
        isOpen={errorModalStep}
        message={memberListLoggingError?.message || ''}
        onClose={() => {
          setErrorModalStep(false);
        }}
      />
    </>
  );
}
