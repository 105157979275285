/**
 * Figma ID: 05-08-01
 * 名称: キャンセル率
 */

import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Info } from 'components/info';
import { Text } from 'components/text';
import React from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ErrorModal } from 'components/errorModal';

export function CancelPer({ id }: { id: string }) {
  const {
    data,
    loading,
    error: cancelInfoError,
  } = gql.useGetVbCancelInfoByIdQuery({
    variables: { id: Number(id) },
  });
  const [errorModalStep, setErrorModalStep] = React.useState<boolean>(false);
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (cancelInfoError) {
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [cancelInfoError, openErrorModal]);

  if (loading) {
    return Loading();
  }
  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" height={60} alignItems="center">
          <Text variant="h2" color="darkBlue">
            キャンセル率
          </Text>
          <Box display="flex" flex="row" gap={8} alignItems="center">
            <Text variant="body14" color="darkBlue">
              ペナルティポイント
            </Text>
            <Text variant="h2" color="darkBlue">
              {data?.getVBCancelInfoById.penalty}ポイント
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" length={0} />
        <Info
          items={[
            {
              content: `${data?.getVBCancelInfoById.late_count ?? 0}回 / ${data?.getVBCancelInfoById.late_per ?? 0}%`,
              label: '遅刻率',
            },
            {
              content: `${data?.getVBCancelInfoById.cancel_count ?? 0}回 / ${
                data?.getVBCancelInfoById.cancel_per ?? 0
              }%`,
              label: 'キャンセル率',
            },
            {
              content: `${data?.getVBCancelInfoById.imminent_count ?? 0}回 / ${
                data?.getVBCancelInfoById.imminent_per ?? 0
              }%`,
              label: 'ドタキャン率',
            },
            {
              content: `${data?.getVBCancelInfoById.skip_count ?? 0}回`,
              label: 'バックレ率',
            },
          ]}
        />
      </Box>
      <ErrorModal
        title="error"
        isOpen={errorModalStep}
        message={cancelInfoError?.message || ''}
        onClose={() => {
          setErrorModalStep(false);
        }}
      />
    </>
  );
}
