import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';

export interface Props {
  children: ReactNode;
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export function ScrollableArea({ children }: Props) {
  return <StyledDiv>{children}</StyledDiv>;
}
