import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Chip } from '../chip';
import { chipColors } from '../chip/chip';
import { Text } from '../text';
import { ThreeDotLeader } from '../threeDotLeader';
import { Box } from '../box';

export type CpType = {
  title?: string | null;
  chip: {
    color: keyof typeof chipColors;
    text: string;
  };
  createdDate?: string | null;
  leaderMenu: LeaderMenu[];
  onClickLeader?: (value: string) => void;
  rows: ReactNode;
};

export type LeaderMenu = {
  label: string;
  value: string;
};

export interface Props {
  listItems: CpType[];
  makeHeader?: boolean;
  frame?: boolean;
  simple?: boolean;
  bold?: boolean;
}

const calculateWidthAndHeight = (value?: string | number): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return `${value}px`;
  }
  return 'auto';
};

const header = (item: CpType, check?: boolean) => {
  const h = check ? (
    <Box
      display="flex"
      height={20}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      gap={8}
      backgroundColor="white"
      mb={6}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
        <Box flex="none" alignItems="center">
          <Text color="darkBlue" variant="caption12" bold>
            {item.title}
          </Text>
        </Box>
        <Box flex="1">
          <Chip color={item.chip.color}>{item.chip.text}</Chip>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box flex="3">
          <Text color="darkBlue" variant="caption11">
            作成：{item.createdDate}
          </Text>
        </Box>
        <div style={{ marginLeft: '27px', alignItems: 'center', height: '24px' }}>
          <ThreeDotLeader menu={item.leaderMenu} right={0} onClick={item.onClickLeader} />
        </div>
      </Box>
    </Box>
  ) : null;
  return h;
};

const simpleHeader = (item: CpType, bold?: boolean) => (
  <Box
    display="flex"
    height={28}
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    gap={8}
    backgroundColor="white"
  >
    <Text color="darkBlue" variant="caption12" bold={bold}>
      {item.title}
    </Text>
  </Box>
);

export function CampaignTable({ listItems, makeHeader, frame, simple, bold }: Props) {
  const ItemFrame = styled.div<{
    height: string | number;
  }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 4px;
    height: ${calculateWidthAndHeight()};
    width: 100%;

    background: #ffffff;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  `;

  const ListItemsFrame = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 16px;
    isolation: isolate;

    background: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;
  `;

  const Table = frame ? (
    <ListItemsFrame>
      {listItems.map((item, index) => (
        <ItemFrame key={item.title ? `${item.title}${index}` : `${''}${index}`} height="auto">
          {simple ? simpleHeader(item, bold) : header(item, makeHeader)}
          {item.rows}
        </ItemFrame>
      ))}
    </ListItemsFrame>
  ) : (
    <>
      {listItems.map((item, index) => (
        <Box key={item.title ? `${item.title}${index}` : `${''}${index}`}>
          {header(item, makeHeader)}
          {item.rows}
        </Box>
      ))}
    </>
  );
  return Table;
}
