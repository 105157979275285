/**
 * Figma ID: 07-02-03
 * 名称: 逆オファー（受入店情報）
 */
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import React from 'react';

type TpmemDetailFooterModel = {
  onClickClose: () => void;
};
function TpmemDetailFooter(props: TpmemDetailFooterModel) {
  const { onClickClose } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end">
      <MainButton onClick={onClickClose} width={104} variant="secondary" thin>
        閉じる
      </MainButton>
    </Box>
  );
}

export { TpmemDetailFooter };
