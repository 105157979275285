import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text/text';

export const chipColors = {
  blue: '#00A5BF',
  okBlue: '#3170C8',
  darkBlue: '#16495F',
  redLite: '#F35555',
  cautionRed: '#E50000',
  warningYellow: '#E8A700',
  green: '#35A554',
  liteGray: '#969696',
  blueGray: '#5F797D',
} as const;

type ChipColorKeyType = keyof typeof chipColors;

type VariantType = 'normal' | 'big';

export interface Props {
  children: ReactNode;
  color: ChipColorKeyType;
  variant?: VariantType;
  width?: number;
}

const StyledDiv = styled.div<{
  width: number;
  height: number;
  color: string;
  borderRadius: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0;
  background: ${({ color }) => color};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  box-sizing: border-box;
`;

export function Chip({ children, color, variant = 'normal', width }: Props) {
  if (variant === 'big') {
    return (
      <StyledDiv width={width ?? 108} height={32} color={chipColors[color]} borderRadius={2}>
        <Text variant="body14" color="white" bold>
          {children}
        </Text>
      </StyledDiv>
    );
  }
  return (
    <StyledDiv width={width ?? 56} height={16} color={chipColors[color]} borderRadius={1}>
      <Text variant="chip10" color="white" bold>
        {children}
      </Text>
    </StyledDiv>
  );
}
