import { Modal } from 'components/newModal';
import * as gql from 'graphql/graphql-ow';
import { CreateNotifyInfoModalForm } from 'pages/s09/type';
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EditModal as editModal } from './editModal';
import { ConfirmModal as confirmModal } from './confirmModal';
import { completeModal } from './completeModal';
import { completeRoughDrafhModal } from './completeRoughDrafhModal';

enum ModalStep {
  EDIT = 1,
  CONFIRM = 2,
  COMPLETE = 3,
  COMPLETE_ROUGH_DRAHT = 4,
}

export function EditNotifyInfoModal(props: {
  isOpen: boolean;
  onClose: () => void;
  data: gql.VuSendNotifyInfo | undefined;
  refetch: () => void;
}) {
  const { isOpen, onClose, data, refetch } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.EDIT);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<CreateNotifyInfoModalForm>();
  const params = useParams<{ id: string }>();

  React.useEffect(() => {
    setValue('deliveryDest', data?.to_kind_name ?? '');
    if (data?.brand_id) setValue('brand', String(data?.brand_id));
    setValue('title', data?.title ?? '');
    setValue('body', data?.body ?? '');
  }, [data?.body, data?.title, data?.to_kind_name, data?.brand_id, setValue, isOpen]);

  const onModalClose = useCallback(() => {
    setModalStep(ModalStep.EDIT);
    reset();
    onClose();
  }, [onClose, reset]);

  const editModalContent = editModal(
    isOpen,
    control,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    setValue,
    onModalClose,
    errors,
    () => setModalStep(ModalStep.CONFIRM),
    () => setModalStep(ModalStep.COMPLETE_ROUGH_DRAHT),
    () => refetch()
  );

  const confirmModalContent = confirmModal(
    params?.id,
    getValues,
    onModalClose,
    () => setModalStep(ModalStep.EDIT),
    () => setModalStep(ModalStep.COMPLETE),
    () => refetch()
  );

  const completeModalContent = completeModal(onModalClose);
  const completeRoughDraftModalContent = completeRoughDrafhModal(onModalClose);

  const modalContent = {
    [ModalStep.EDIT]: editModalContent,
    [ModalStep.CONFIRM]: confirmModalContent,
    [ModalStep.COMPLETE]: completeModalContent,
    [ModalStep.COMPLETE_ROUGH_DRAHT]: completeRoughDraftModalContent,
  };

  return <Modal isOpen={isOpen} onClose={onModalClose} {...modalContent[modalStep]} />;
}
