import React from 'react';
import { HistoryList } from 'components/historyList';
import { ListItemsType } from '../../type';

export type ListProps = {
  listItems: ListItemsType[];
  page: number;
  limit: number;
};

export function List(props: ListProps) {
  const { listItems, page, limit } = props;

  const list = listItems.slice(limit * (page - 1), limit * page);
  return <HistoryList items={list} />;
}
