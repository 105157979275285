/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { Auth } from 'aws-amplify';

import { createAuthLink, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

/* The HTTPS endpoint of the AWS AppSync API 
(e.g. *https://aaaaaaaaaaaaaaaaaaaaaaaaaa.appsync-api.us-east-1.amazonaws.com/graphql*). 
[Custom domain names](https://docs.aws.amazon.com/appsync/latest/devguide/custom-domain-name.html) can also be supplied here (e.g. *https://api.yourdomain.com/graphql*). 
Custom domain names can have any format, but must end with `/graphql` 
(see https://graphql.org/learn/serving-over-http/#uris-routes). */
const url1 = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT || '';
const url2 = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT2 || '';
const url3 = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT2 || '';

const region = process.env.REACT_APP_AWS_APPSYNC_REGION || '';

const auth1: AuthOptions = {
  type: 'API_KEY',
  apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY || '',
};
// const auth2: AuthOptions = {
//   type: 'API_KEY',
//   apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY2 || '',
// };
const auth2: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};
const auth3: AuthOptions = {
  type: 'API_KEY',
  apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY2 || '',
};

const httpLink1 = new HttpLink({ uri: url1 });
const httpLink2 = new HttpLink({ uri: url2 });
const httpLink3 = new HttpLink({ uri: url3 });

const link1 = ApolloLink.from([
  createAuthLink({ url: url1, region, auth: auth1 }),
  createSubscriptionHandshakeLink({ url: url1, region, auth: auth1 }, httpLink1),
]);
const link2 = ApolloLink.from([
  createAuthLink({ url: url2, region, auth: auth2 }),
  createSubscriptionHandshakeLink({ url: url2, region, auth: auth2 }, httpLink2),
]);
const link3 = ApolloLink.from([
  createAuthLink({ url: url3, region, auth: auth3 }),
  createSubscriptionHandshakeLink({ url: url3, region, auth: auth3 }, httpLink3),
]);

/*
  カスタムフックの呼び出し方によりエンドポイントを切り替える
  link1: const { data, loading, error } = useGetQuery({ context: { clientName: 'master' } });
  link2: const { data, loading, error } = useGetQuery();
*/
const link = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'master',
  link1,
  ApolloLink.split((operation) => operation.getContext().clientName === 'api_key', link3, link2)
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

type ProvideGraphQLProps = {
  children: React.ReactNode;
};

function ApolloWrapper(props: ProvideGraphQLProps) {
  const { children } = props;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloWrapper;
