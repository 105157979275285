/**
 * Figma ID: 10-01-01-01, 10-01-01-02, 10-01-01-03
 * 名称: 管理アカウント
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';

type Props = {
  onClickClose: () => void;
  toNextPhase: (confirmationCode: string) => void;
};

export function InputConfirmCodeModal({ toNextPhase, onClickClose }: Props) {
  const codeLength = 6;
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ inputCode: string }>({ reValidateMode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    toNextPhase(data.inputCode);
  });

  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            メールアドレス編集
          </Text>
        </Box>
      }
      content={
        <Box display="flex" flexDirection="column" gap={24} pa={8}>
          <Text variant="body14">メールに記載された確認コードを入力してください。</Text>
          <Box display="flex" flexDirection="column" gap={4}>
            <RhfInput
              placeholder="6桁のコードを入力"
              name="inputCode"
              control={control}
              rules={{ validate: (value) => value.length === codeLength || '6桁のコードを入力してください' }}
            />
            {errors.inputCode?.message && (
              <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
            )}
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton
            disabled={!isValid}
            onClick={() => {
              onSubmit();
            }}
            width={104}
          >
            認証
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={263}
      width={800}
    />
  );
}
