import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { CreateBlockKey, ModalRet } from 'pages/s03/type';
import React from 'react';
import { Control, UseFormTrigger, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { useGetMBrandByBizIdLazyQuery, MBrand } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { ErrorMessage } from 'components/errorMessage';

export function SelectBrandBusinessModal(
  control: Control<CreateBlockKey>,
  trigger: UseFormTrigger<CreateBlockKey>,
  handleSubmit: UseFormHandleSubmit<CreateBlockKey>,
  errors: FieldErrors<CreateBlockKey>,
  bizId: number,
  onClose: () => void,
  onNext: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();
  const [getMBrandByBizId, { data: mBrandData, loading: mBrandLoading, error: mBrandError }] =
    useGetMBrandByBizIdLazyQuery({
      context: { clientName: 'master' },
    });
  React.useEffect(() => {
    if (bizId !== undefined && bizId !== 0) {
      getMBrandByBizId({ variables: { bid: bizId } });
    }
  }, [bizId, getMBrandByBizId]);

  React.useEffect(() => {
    if (mBrandError) {
      onClose();
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [mBrandError, openErrorModal, onClose]);

  const brandData = mBrandData?.getMBrandByBizId as MBrand[];
  const brandList =
    brandData?.map((item) => ({
      value: String(item.id),
      label: item.name,
    })) || [];

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      onNext();
    }
  });

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック登録 - ブランド選択
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
          <Text variant="body14">ブランドを選択して「次へ」を押してください。</Text>
          <Box display="flex" flexDirection="row" gap={13}>
            <Box display="flex" flexDirection="column">
              <Text variant="h3" color="blueGray">
                ブランド
              </Text>
              <RhfSelect
                width={752}
                control={control}
                name="brand_name"
                options={brandList}
                defaultValue=""
                placeholder="ブランドを選択"
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.brand_name && <ErrorMessage>{errors?.brand_name.message}</ErrorMessage>}
            </Box>
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <>
        {mBrandLoading && <Loading />}
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
            キャンセル
          </MainButton>
          <MainButton
            width={104}
            thin
            onClick={(data) => {
              onClickHandler(data);
            }}
          >
            次へ
          </MainButton>
        </Box>
      </>
    ),
    width: 800,
    height: 283,
  };

  return modalContent;
}
