export enum MemberStatus {
  Unregistered = 0, // 未登録
  Active = 1, // アクティブ
  Suspended = 2, // 休止中
  Closed = 3, // 退会済み は今回なし
}

export const MemberStatusList = [
  { value: MemberStatus.Active.toString(), label: 'アクティブ' },
  { value: MemberStatus.Suspended.toString(), label: '休止' },
  { value: MemberStatus.Closed.toString(), label: '退会' },
  { value: MemberStatus.Unregistered.toString(), label: '未登録' },
];

export enum PaymentMethod { // TODO: 支払方法の変数に応じて変更を予定。
  Transfer = 0, // 振込
  TransferRequesting = 1, // 振替依頼中
  Giro = 2, // 振替
}

export const PaymentMethodList = [
  { value: PaymentMethod.TransferRequesting.toString(), label: '振替依頼中' },
  { value: PaymentMethod.Giro.toString(), label: '振替' },
  { value: PaymentMethod.Transfer.toString(), label: '振込' },
];

export enum TicketStatus {
  VALID = 1,
  EXPIRED = 0,
}

export enum Alert {
  NO = 0,
  YES = 1,
}

export enum Reason {
  POTENTIALLY_DRAFTED = 1, // 引き抜きされる可能性がある
  ALREADY_DRAFTED = 2, // 引き抜きされた
  OTHER = 3, // その他
}
export {};
