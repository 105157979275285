import { useState } from 'react';

interface UsePagenationRet<T> {
  items: T[],
  handleChangeLimit: (limit: 10 | 20 | 30) => void,
  handleChangePage: (page: number) => void,
  pagenation: { limit: 10 | 20 | 30, page: number }
}

export function usePagenation<T>(
    data: T[],
    limitInit?: (10 | 20 | 30),
    pagesInit?: number
): UsePagenationRet<T>{
  const [pagenation, setPagenation] = useState<
    { limit: 10 | 20 | 30, page: number }
  >({limit: limitInit || 10, page: pagesInit || 1});

  const items: T[] = data.slice(
    pagenation.limit*pagenation.page - pagenation.limit, pagenation.limit*pagenation.page
  );

  const handleChangeLimit = (limit: 10 | 20 | 30) => {
    setPagenation({ page: 1, limit });
  };
  const handleChangePage = (page: number) => {
    setPagenation({ ...pagenation, page });
  };

  return {
    items,
    handleChangeLimit,
    handleChangePage,
    pagenation
  };
};

