import { styled } from '@linaria/react';

import { GENERIC_COLOR, PRIMARY_COLOR, FONT, Divider, TextBox } from '../../style';

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  position: absolute;
  width: 328px;
  height: 372.03px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Flame3281 = styled.div`
  /* Frame 3281 */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;

  width: 328-16px;
  height: 86.03-16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const Flame3282 = styled.div`
  /* Frame 3282 */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 200px;
  height: 70.03px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const BitravelLogo = styled.img<{
  width: number;
  height: number;
}>`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Login = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 328px;
  height: 262px;
  /* バイトラベル共通/white */
  background: ${GENERIC_COLOR.WHITE};
  border-radius: 6px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Administration = styled(FONT.BODY18B)<{
  width: number;
  height: number;
}>`
  /* 運営管理 */
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  justify-content: center;
`;

const PasswordLink = styled(FONT.CAPTION11)<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* identical to box height, or 164% */
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
`;

const LoginFrame = styled.div<{
  width: number;
  height: number;
}>`
  /* Login */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;

  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  left: calc(50% - 188px);
  top: 93px;

  /* バイトラベル共通/white */
  background: ${GENERIC_COLOR.WHITE};
  border-radius: 6px;
`;

const LoginHeaderText = styled(FONT.BODY16B)<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* identical to box height */
  display: flex;
  align-items: center;

  /* 運営管理/primary_blue */
  color: ${PRIMARY_COLOR.PRIMARY_BLUE};

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const HeaderDivider = styled(Divider)`
  background: ${PRIMARY_COLOR.PRIMARY_BLUE};
`;

const LoginBodyText = styled(FONT.BODY14)<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* identical to box height */
  display: flex;
  align-items: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const LoginLink = styled(FONT.CAPTION11)<{
  width: number;
  height: number;
  color: string;
}>`
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
`;

const Frame = styled.div<{
  width: number;
  height: number;
  alignitems: string;
  gap: number;
  order: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignitems }) => alignitems};
  padding: 0px;
  gap: ${({ gap }) => gap}px;

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  /* Inside auto layout */
  flex: none;
  order: ${({ order }) => order};
  flex-grow: 0;
`;

const TextBoxS01 = styled(TextBox)`
  order: 0;
`;

const PasswordCondition = styled(FONT.CAPTION12)<{
  width: number;
  height: number;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  display: flex;
  align-items: center;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const FrameLogo = styled.div`
  /* logo */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  position: absolute;
  width: 164px;
  height: 21px;
  left: calc(50% - 82px);
  top: 40px;
`;

const LogoLavel = styled(FONT.BODY14B)`
  /* 運営管理 */
  width: 56px;
  height: 21px;

  display: flex;
  align-items: center;
  text-align: center;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export {
  Contents,
  Flame3281,
  Flame3282,
  LoginFrame,
  LoginHeaderText,
  Frame,
  LoginBodyText,
  Administration,
  PasswordLink,
  BitravelLogo,
  Login,
  HeaderDivider,
  LoginLink,
  TextBoxS01,
  PasswordCondition,
  FrameLogo,
  LogoLavel,
};
