/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Input, Props as InputProps } from '../input/input';

// React Hook Form では name を必須にしたいため。
// Input で定義されている name プロパティを外す
// Input で必須になっている value と onChange も外す
export type RhfInputProps<T extends FieldValues> = Omit<InputProps, 'name' | 'value' | 'onChange'> &
  UseControllerProps<T>;

export function RhfInput<T extends FieldValues>(props: RhfInputProps<T>) {
  const { name, control, rules, defaultValue, ...otherProps } = props;
  const {
    field: { ref, ...rest },
    formState: { errors },
  } = useController<T>({ name, control, rules, defaultValue });

  return <Input {...rest} {...otherProps} error={!!errors[name]} />;
}
