import { Modal } from 'components/newModal';
import React, { useState } from 'react';
import { VtMemberBase } from 'graphql/graphql-ow';
import { useForm } from 'react-hook-form';
import { VtMemberListEditKey } from 'pages/s03/type';
import { EnterStoreInfoModal as enterStoreInfoModal } from './enterStoreInfoModal';
import { ConfirmModal as confirmModal } from './confirmModal';
import { completeModal } from './completeModal';

enum ModalStep {
  EnterStoreInfo = 1,
  Confirm = 2,
  Complete = 3,
}

export function EditUnregistrationModal(props: {
  isOpen: boolean;
  onClose: () => void;
  item: VtMemberBase | null;
  refetch: () => void;
}) {
  const { isOpen, onClose, item, refetch } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.EnterStoreInfo);

  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<VtMemberListEditKey>({ defaultValues: { ...item }, reValidateMode: 'onSubmit' });

  React.useEffect(() => {
    setValue('brand_name', item?.brand_name ?? '');
    setValue('biz_name', item?.biz_name ?? '');
    setValue('zip_code1', item?.zip_code?.slice(0, 3) ?? '');
    setValue('zip_code2', item?.zip_code?.slice(4, 7) ?? '');
  }, [item?.brand_name, item?.biz_name, setValue, item?.zip_code]);

  React.useEffect(() => {
    setValue('state_name', getValues('state') ?? '');
  }, [getValues, setValue]);

  const onCloseModal = () => {
    reset();
    setValue('brand_name', item?.brand_name ?? '');
    setValue('biz_name', item?.biz_name ?? '');
    setModalStep(ModalStep.EnterStoreInfo);
    refetch();
    onClose();
  };

  const enterStoreInfo = enterStoreInfoModal(control, getValues, setValue, handleSubmit, errors, onCloseModal, () =>
    setModalStep(ModalStep.Confirm)
  );

  const confirm = confirmModal(
    control,
    getValues,
    onCloseModal,
    () => setModalStep(ModalStep.EnterStoreInfo),
    () => setModalStep(ModalStep.Complete)
  );

  const complete = completeModal(onCloseModal);

  const modalMap = {
    [ModalStep.EnterStoreInfo]: enterStoreInfo,
    [ModalStep.Confirm]: confirm,
    [ModalStep.Complete]: complete,
  };

  return <Modal isOpen={isOpen} onClose={() => onCloseModal()} {...modalMap[modalStep]} />;
}
