import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './locales/en.json';
import jaJson from './locales/ja.json';
import zhCNJson from './locales/zhCN.json';
import zhTWJson from './locales/zhTW.json';
import koJson from './locales/ko.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'ja',
    resources: {
      ja: { translation: jaJson },
      en: { translation: enJson },
      zhCN: { translation: zhCNJson },
      zhTW: { translation: zhTWJson },
      ko: { translation: koJson },
      default: { translation: jaJson },
    },
    lng: 'ja',
    interpolation: { escapeValue: false },  
    debug: false,
  });

export default i18n;