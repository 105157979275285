import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalStep } from 'pages/s05/constants';
import React from 'react';

/**
 * Figma ID: 05-04-02 05-04-03
 * 名称: コメント削除 コメント削除 完了
 */

interface Props {
  modalStep: ModalStep;
  title: {
    delete: string;
    complete: string;
  };
  onClose: () => void;
  deleteHandler: () => void;
}

export function CommentDeleteModal({ modalStep, title, onClose, deleteHandler }: Props) {
  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.DELETE:
        return {
          width: 384,
          height: 212,
          header: (
            <Text variant="h2" color="darkBlue">
              {title.delete}
            </Text>
          ),
          content: (
            <Text variant="body14">
              このコメントを削除しますか？
              <br />
              削除した情報は元に戻せません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  deleteHandler();
                }}
                variant="primary"
                width={104}
              >
                削除
              </MainButton>
            </Box>
          ),
          onClose,
        };
      case ModalStep.COMPLETE:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              {title.complete}
            </Text>
          ),
          content: <Text variant="body14">情報の削除が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                閉じる
              </MainButton>
            </Box>
          ),
          onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose,
        };
    }
  }, [modalStep, onClose, title.complete, title.delete, deleteHandler]);
  return displayModal;
}
