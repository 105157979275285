import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { useMemo, useContext, useState } from 'react';
import { List } from 'components/list';
import { orderBy } from 'lodash';

import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { NewlyNotificationContext } from '../components/newlyNotificationContext';
import { ListHeaderType, ListItemsType } from '../type';

/**
 * Figma ID: 08-06-01
 * 名称: 払戻支払-払戻支払
 */

export function RefundPayment(props: { setOutputNumber: (outputNumber: string) => void; outputNumber: string }) {
  const { setOutputNumber, outputNumber } = props;

  const { setNewlyNotification } = useContext(NewlyNotificationContext);

  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const {
    data: { getVTMPayCsvListNewlyPayRefund: { csvlist, newly } } = {
      getVTMPayCsvListNewlyPayRefund: {
        csvlist: [] as gql.VtmPayCsvList[],
        newly: {} as gql.VuNewlyPayRefund,
      },
    },
    loading: listLoading,
    error: listError,
  } = gql.useGetVtmPayCsvListNewlyPayRefundQuery({
    variables: {},
  });

  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (listError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [listError, openErrorModal]);

  // ダウンロード処理のローディング
  const [downloadLoading] = useState<boolean>(false);

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'pay_date', width: 128, columnName: '発行年月' },
      { key: 'output_date', width: 140, columnName: '処理日時' },
    ],
    []
  );

  const listItems: ListItemsType = useMemo(() => {
    if (listLoading) {
      return [];
    }

    let items = csvlist;

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      pay_date: item.pay_date,
      output_date: item.output_date,
      uniqueKey: key,
    }));
  }, [csvlist, listLoading, sort]);

  if (Object.keys(newly).length !== 0) {
    setNewlyNotification(newly);
  }

  // 出力No(outputNumber)に該当するページに遷移する
  React.useEffect(() => {
    // 出力Noがない場合は何もしない
    if (!outputNumber) return;
    // リスト表示後のみ対応する
    if (!listItems.length) return;
    try {
      // 出力Noで検索
      const index = listItems.findIndex((item) => item.output_number === outputNumber);
      // 該当する出力Noがない場合は何もしない
      if (index === -1) return;
      // 出力Noに該当するページを取得
      const targetPage = Math.floor(index / limit) + 1;
      // 該当するページを表示
      handleChangePage(targetPage);
    } finally {
      // 出力No検索後は出力Noをクリア
      setOutputNumber('');
    }
  }, [listItems, outputNumber, handleChangePage, limit, page, setOutputNumber]);

  return (
    <>
      {(listLoading || downloadLoading) && <Loading />}
      <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 32px)">
        <Box display="flex" flexDirection="row" height={60} px={16} alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={8} alignItems="center">
            <Text variant="h2" color="darkBlue">
              払戻支払
            </Text>
            <Text variant="caption12" color="darkBlue">
              毎月5日に自動処理済の月次支払一覧です。
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="auto" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={380}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
    </>
  );
}
