/**
 * Figma ID: 10-08-01-04, 10-08-01-05
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Text } from 'components/text';

function CompleteUpdateDeadlineContent() {
  return (
    <div style={{ padding: '9px 0px' }}>
      <Text variant="body14">情報の設定が完了しました。</Text>
    </div>
  );
}

export default CompleteUpdateDeadlineContent;
