import { styled } from '@linaria/react';
import React, { ReactNode, useMemo } from 'react';
import { MemberStatus } from '../const';

const StyledImageLabel = styled.div`
display: flex;
position: relative;
`;

const StyledBG = styled.div<{
color: string;
}>`
position: absolute;
width: 40px;
height: 40px;
background-color: ${({ color }) => color};
`;

const StyledLabel = styled.div<{
  status: MemberStatus | null;
}>`
  position: absolute;
  top: ${({status})=> {
    switch(status) {
      case MemberStatus.Unregistered:
        return '14px';
      case MemberStatus.Suspended:
        return '14px';
      default:
        return '0px';
    }
  }};
  left: ${({status})=> {
    switch(status) {
      case MemberStatus.Unregistered:
        return '5px';
      case MemberStatus.Suspended:
        return '10px';
      default:
        return '0px';
    }
  }};
`;

const StyledText = styled.div`
font-family: 'Meiryo';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 15px;

color: #FFFFFF;
`;

// TODO: このコンポーネントを作るときに絶対知でテキストの設置位置を指定しているので、画像のサイズを変えたとき用にコンポーネントが２つになってしまっている。
export function ChangeSmallImgWithStatus(
  { status=MemberStatus.Unregistered, children } : { status?: number | null, children: ReactNode }
) {
  const label: string = useMemo(() => {
    switch(status) {
      case MemberStatus.Unregistered:
        return  '未登録';
      case MemberStatus.Suspended:
        return '休止';
      default:
        return '';
    }
  }, [status]);

  const bgColor: string = useMemo(() => {
    switch(status) {
      case MemberStatus.Unregistered:
        return 'rgba(150, 150, 150, 0.9)';
      case MemberStatus.Suspended:
        return 'rgba(229, 0, 0, 0.7)';
      default:
        return '';
    }
  }, [status]);

  return(
    <StyledImageLabel>
      <StyledBG color={bgColor}/>
      {/* TODO: テキストの場所を絶対値で入れてしまっているので相対値にする必要あり */}
      <StyledLabel status={status}>
        <StyledText>
          {label}
        </StyledText>
      </StyledLabel>
      {children}
    </StyledImageLabel>
  );
}