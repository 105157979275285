import { Modal } from 'components/newModal';

import React from 'react';
import { CreateBlockKey } from 'pages/s03/type';
import { useForm } from 'react-hook-form';
import { SelectBrandBusinessModal as selectBrandBusinessModal } from './selectBrandBusinessModal';
import { SelectStoreModal as selectStoreModal } from './selectStoreModal';
import { SelectReasonModal as selectReasonModal } from './selectReasonModal';
import { ConfirmModal as confirmModal } from './confirmModal';
import { completeModal } from './completeModal';

enum ModalStep {
  SELECT_BRAND_BUSINESS = 1,
  SELECT_STORE = 2,
  SELECT_REASON = 3,
  CONFIRM = 4,
  COMPLETE = 5,
}

export function CreateBlockModal(props: {
  isOpen: boolean;
  myId: number;
  name: string;
  bizId: number;
  onClose: () => void;
}) {
  const { isOpen, myId, name, bizId, onClose } = props;

  const {
    control,
    trigger,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateBlockKey>({ reValidateMode: 'onSubmit' });

  const [modalStep, setModalStep] = React.useState(ModalStep.SELECT_STORE);

  React.useEffect(() => {
    setModalStep(ModalStep.SELECT_BRAND_BUSINESS);
    reset();
  }, [isOpen, reset]);

  const selectBrandBusiness = selectBrandBusinessModal(control, trigger, handleSubmit, errors, bizId, onClose, () =>
    setModalStep(ModalStep.SELECT_STORE)
  );

  const selectStore = selectStoreModal(
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    errors,
    myId,
    bizId,
    onClose,
    () => setModalStep(ModalStep.SELECT_BRAND_BUSINESS),
    () => setModalStep(ModalStep.SELECT_REASON)
  );

  const selectReason = selectReasonModal(
    control,
    trigger,
    handleSubmit,
    errors,
    onClose,
    () => setModalStep(ModalStep.CONFIRM),
    () => setModalStep(ModalStep.SELECT_STORE)
  );

  const confirm = confirmModal(
    myId,
    name,
    getValues,
    onClose,
    () => setModalStep(ModalStep.SELECT_REASON),
    () => setModalStep(ModalStep.COMPLETE)
  );

  const complete = completeModal(onClose);

  const modalContent = {
    [ModalStep.SELECT_BRAND_BUSINESS]: selectBrandBusiness,
    [ModalStep.SELECT_STORE]: selectStore,
    [ModalStep.SELECT_REASON]: selectReason,
    [ModalStep.CONFIRM]: confirm,
    [ModalStep.COMPLETE]: complete,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      {...modalContent[modalStep]}
      overflow={modalStep === 4 ? 'auto' : 'visible'} // ブロックリスト確認画面(modalStep: 4)はスクロール可能にする
    />
  );
}
