/**
 * Figma ID: 10-04-02
 * 名称: 2段階認証設定
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import QrToAppStore from './QrToAppStore.png';
import QrToGooglePlay from './QrToGooglePlay.png';
import appStore from './appStore.png';
import googlePlay from './googlePlay.png';

export function QrCodeToInstallApp() {
  return (
    <Box display="flex" flexDirection="column" gap={16}>
      <Box>
        <Text variant="body14" color="black">
          セキュリティ強化のため、スマ−トフォンアプリから認証アプリ（GoogleAuthenticator）を使い２段階認証の設定を行います。
          <br />
          以下の二次元バーコードより認証アプリをスマートフォンにダウンロードしてください。
        </Text>
      </Box>
      <Box pa={24} display="flex" gap={80} alignItems="center">
        <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
          <img src={QrToGooglePlay} alt="add" width={80} />
          <Text variant="body14" color="black" align="center">
            Android Google Play Store
          </Text>
          <img src={googlePlay} alt="add" width={135} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
          <img src={QrToAppStore} alt="add" width={80} />
          <Text variant="body14" color="black" align="center">
            iOS App Store
          </Text>
          <img src={appStore} alt="add" width={135} />
        </Box>
      </Box>
    </Box>
  );
}
