import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React from 'react';
import { SelectForm } from 'components/selectForm';
import { Control, RegisterOptions, UseFormSetValue, useForm } from 'react-hook-form';
import { InputType, ModalStep } from '../constants';
import { BasicModalType, BasicModalTypeKeys, MiniModalViewDataTyep } from '../s10-02-types';
import { RhfBasicForm } from '../components/rhfBasicForm';

/**
 * Figma ID: 10-02-06-02, 10-02-06-03, 10-02-06-04
 * 名称: 募集可能件数
 * Figma ID: 10-02-07-02, 10-02-07-03, 10-02-07-04
 * 名称: 募集可能期間
 * Figma ID: 10-02-08-02, 10-02-08-03, 10-02-08-04
 * 名称: 枚数
 * Figma ID: 10-02-08-05 10-02-08-06, 10-02-08-07
 * 名称: 期限
 */

interface Props {
  modalStep: ModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  onClose: () => void;
  id?: number; // ID
  modalViewData?: MiniModalViewDataTyep;
  mutation?: (type_id?: number, data?: string | number | null) => void;
  rules?: RegisterOptions<BasicModalType>;
}

export const selectInputComponent = (
  setValue: UseFormSetValue<BasicModalType>,
  targetName: BasicModalTypeKeys,
  modalViewData?: MiniModalViewDataTyep,
  currentData?: string,
  control?: Control<BasicModalType>,
  rules?: RegisterOptions<BasicModalType>
) => {
  switch (modalViewData?.inputType) {
    case InputType.Input:
      return (
        <RhfBasicForm
          label={modalViewData.label}
          placeholder={modalViewData.placeholder}
          width={120}
          name={modalViewData.name}
          control={control}
          rules={rules}
          unit={modalViewData.unit}
          defaultValue={currentData}
        />
      );
    case InputType.Select:
      return (
        <>
          <SelectForm
            options={modalViewData?.options || []}
            label={modalViewData.label}
            onChange={(value) => setValue(targetName, value)}
            placeholder={modalViewData.placeholder}
            value={currentData ?? ''}
            width={120}
          />
          <Box mt={10}>
            <Text color="blueGray" variant="body14">
              {modalViewData?.unit}
            </Text>
          </Box>
        </>
      );
    default:
      return null;
  }
};

export function BasicModal({ modalStep, setModalStep, modalViewData, id, mutation, onClose, rules }: Props) {
  const {
    control,
    clearErrors,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<BasicModalType>({ reValidateMode: 'onChange' });

  // 処理対象項目名
  const targetName = modalViewData?.name as BasicModalTypeKeys;
  // 現在値
  const currentData = watch(targetName, modalViewData?.data !== undefined ? String(modalViewData?.data) : '');

  const onClickHandler = handleSubmit((formData) => {
    if (formData) {
      setModalStep(ModalStep.CHECK);
    }
  });

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.INPUT:
        return {
          width: 384,
          height: "auto",
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.input}
            </Text>
          ),
          content: (
            <Box ma={8} height={modalViewData?.inputType === InputType.Input ? 78 : 'auto'}>
              <Box display="flex" alignItems="center" gap={8}>
                {selectInputComponent(setValue, targetName, modalViewData, currentData, control, rules)}
              </Box>
              {errors[targetName]?.message && (
                <Box>
                  <Text color="cautionRed" variant="caption12">
                    {errors[targetName]?.message}
                  </Text>
                </Box>
              )}
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setValue(targetName, String(modalViewData?.data));
                  clearErrors();
                  setModalStep(ModalStep.NULL);
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  onClickHandler();
                }}
                variant="primary"
                width={104}
                disabled={(currentData ?? '') === ''}
              >
                変更
              </MainButton>
            </Box>
          ),
          onClose: () => setModalStep(ModalStep.NULL),
        };
      case ModalStep.CHECK:
        return {
          width: 384,
          height: "auto",
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.check}
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">
                以下の{modalViewData?.label}に設定してよろしいですか？
                <br />
              </Text>
              <Box mt={16}>
                <Text variant="body14">
                  <Text variant="body14" bold>
                    ・
                  </Text>
                  {currentData}
                  {modalViewData?.unit}
                </Text>
              </Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setValue(targetName, String(modalViewData?.data));
                  setModalStep(ModalStep.NULL);
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(ModalStep.INPUT);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  if (mutation) {
                    mutation(id, Number(currentData));
                  }
                }}
                variant="primary"
                width={104}
              >
                設定
              </MainButton>
            </Box>
          ),
          onClose: () => setModalStep(ModalStep.NULL),
        };
      case ModalStep.COMPLETE:
        return {
          width: 384,
          height: "auto",
          header: (
            <Text variant="h2" color="darkBlue">
              {modalViewData?.title.complete}
            </Text>
          ),
          content: (
            <Text variant="body14">
              情報の設定が完了しました。
              <br />
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                閉じる
              </MainButton>
            </Box>
          ),
          onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose: () => setModalStep(ModalStep.NULL),
        };
    }
  }, [
    id,
    modalStep,
    modalViewData,
    mutation,
    onClose,
    setModalStep,
    onClickHandler,
    clearErrors,
    control,
    rules,
    errors,
    setValue,
    currentData,
    targetName,
  ]);
  return { displayModal };
}
