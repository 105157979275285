import React from 'react';
import { styled } from '@linaria/react';
import { Tab } from 'components/newTab';
import { Box } from 'components/box';
import * as gql from 'graphql/graphql-ow';
import { BusinessTab } from './tabs/business_tab';
/**
 * Figma ID: 10-02-03-01, 10-02-04-01
 * 名称: 育成料設定（コンビニ）,育成料設定（カフェ）
 */

// タブが増えた場合の横スクロール
const AutoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export function EducationFeeSetting() {
  const { data } = gql.useGetMBusinessQuery({ context: { clientName: 'master' } });
  const items = data?.getMBusiness
    ? data.getMBusiness.map((business) => {
        // 業種 省略表示文字数
        const bizNameMax = 10;
        const bizName = business?.name ?? '';
        const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax - 1)}…` : bizName;

        return {
          label: editBizName,
          labelLength: 160,
          content: <BusinessTab id={business?.id || 0} name={business?.name ?? ''} />,
        };
      })
    : [
        {
          label: '',
          labelLength: 160,
          content: <BusinessTab />,
        },
      ];
  return (
    <AutoWrapper>
      <Box px={24} pt={16} pb={24}>
        <Tab items={items} />
      </Box>
    </AutoWrapper>
  );
}
