/**
 * Figma ID: 10-08-03-03
 * 名称: 固定特典設定(設定)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { UseFormWatch } from 'react-hook-form';
import type { SelectedGiftModel } from '../FixedPrivilegeSetting';

type ConfirmSelectedGiftContentModel = {
  watch: UseFormWatch<SelectedGiftModel>;
};
export function ConfirmSelectedGiftContent(props: ConfirmSelectedGiftContentModel) {
  const { watch } = props;
  const currentTitle = watch('title');
  const currentDeadline = watch('deadline');
  return (
    <Box>
      <Box pb={16}>
        <Text variant="body14">以下の商品を設定してよろしいですか？</Text>
      </Box>
      <Box>
        <Text variant="body14">・{currentTitle}</Text>
      </Box>
      <Box>
        <Text variant="body14">・発行期限:{currentDeadline}</Text>
      </Box>
    </Box>
  );
}

type ConfirmSelectedGiftFooterModel = {
  onClickClose: () => void;
  onClickNext: () => void;
  onClickBack: () => void;
};
export function ConfirmSelectedGiftFooter(props: ConfirmSelectedGiftFooterModel) {
  const { onClickClose, onClickNext, onClickBack } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickBack} width={104} variant="secondary">
        戻る
      </MainButton>
      <MainButton onClick={onClickNext} width={104}>
        保存
      </MainButton>
    </Box>
  );
}
