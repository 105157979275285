import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useEffect } from 'react';
import { useGetVbMemberBaseByIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { MemberStatus } from 'pages/s03/const';
import { Info } from 'components/info';
import { InfoItemsType } from 'pages/s03/type';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { Divider } from 'components/newDivider';
import { useNavigate } from 'react-router-dom';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

import { ChangeBigImgWithStatus } from '../changeBigImgWithStatus';

export function MemberBaseModal(props: { isOpen: boolean; onClose: () => void; btmemId: number }) {
  const { isOpen, onClose, btmemId } = props;
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();

  const [
    getVBMemberBaseById,
    { data: vBMemberBaseByIdData, loading: vBMemberBaseByIdLoading, error: uBMemberBaseByIdError },
  ] = useGetVbMemberBaseByIdLazyQuery({ variables: { id: btmemId } });
  useEffect(() => {
    if (btmemId !== undefined && btmemId !== 0) {
      getVBMemberBaseById({ variables: { id: btmemId } });
    }
  }, [btmemId, getVBMemberBaseById]);

  useEffect(() => {
    if (uBMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [uBMemberBaseByIdError, openErrorModal]);

  const memberBase = vBMemberBaseByIdData?.getVBMemberBaseById;

  const lcBizNamesList = memberBase?.lc_biz_names?.split(',');
  const rankList = memberBase?.rank_names?.split(',');
  const rankBizNamesList = memberBase?.rank_biz_names?.split(',');
  const licenceList = memberBase?.license_list?.split('\n');
  const tpBizNamesList = memberBase?.tp_biz_names?.split(',');
  const mainNameList = memberBase?.tpmem_names?.split(',');
  const subNameList = memberBase?.sub_names?.split(',');

  const rankContent: InfoItemsType = (rankBizNamesList ?? []).map((rkBizNames, index) => ({
    label: `ランク(${rkBizNames})`,
    content: rankList?.[index],
  }));
  const licenceContent: InfoItemsType = (lcBizNamesList ?? []).map((lcBizName, index) => ({
    label: `${lcBizName}資格`,
    content: licenceList?.[index],
  }));
  const belongStoreContent: InfoItemsType[] = (tpBizNamesList ?? []).map((tpBizNames, index) => [
    { label: `所属店舗(${tpBizNames})`, content: mainNameList?.[index] },
    { label: `サブ店舗(${tpBizNames})`, content: subNameList?.[index] || '-' },
  ]);

  const infoItems: InfoItemsType = [
    { label: '氏名(フリガナ)', content: memberBase?.kana },
    { label: 'メールアドレス(ID)', content: memberBase?.email },
    { label: '会員番号', content: memberBase?.id },
    { label: '電話番号', content: memberBase?.phone },
    { label: '生年月日', content: memberBase?.birthday },
    { label: '年齢', content: memberBase?.age },

    ...rankContent,

    { label: '達成都道府県', content: memberBase?.state_count },
    { label: 'バイト回数', content: `${memberBase?.worked_count ?? '0'}  ${memberBase?.badge_name || ''}` },
    { label: '入会日', content: memberBase?.created_date },
    {
      label: '住所',
      content: `〒${memberBase?.zip_code?.slice(0, 3) ?? ''}-${memberBase?.zip_code?.slice(3, 7) ?? ''} 
      ${memberBase?.state ?? ''}${memberBase?.city ?? ''}${memberBase?.address ?? ''}${memberBase?.address2 ?? ''}`,
    },

    ...belongStoreContent.flat(),

    { label: '性別', content: memberBase?.gender },
    { label: '属性', content: memberBase?.attribute || '-' },
    { label: '保有資格', content: memberBase?.general_licenses || '-' },

    ...licenceContent,

    { label: '制服サイズ', content: memberBase?.uniform_size },
    { label: '遅刻率', content: `${memberBase?.late_count ?? 0}回 / ${memberBase?.late_per ?? 0}%` },
    { label: 'キャンセル率', content: `${memberBase?.cancel_count ?? 0}回 / ${memberBase?.cancel_per ?? 0}%` },
    { label: 'ドタキャン率', content: `${memberBase?.imminent_count ?? 0}回 / ${memberBase?.imminent_per ?? 0}%` },
    { label: 'バックレ回数', content: `${memberBase?.imminent_count ?? 0}回` },

    { label: '登録業種', content: memberBase?.reg_biz_list },
    { label: '入金口座', content: memberBase?.bank },
    { label: '本人確認', content: identificationLabel(memberBase?.identification ?? 0) },
  ];

  const modalContent = {
    header: (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          {memberBase?.name}
        </Text>
        <MainButton variant="secondary" width={74} onClick={() => navigate(`/s05/${memberBase?.id || 0}`)}>
          詳しく
        </MainButton>
      </Box>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={8} pa={0} width="100%">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            alignItems: 'center',
            height: '60px',
            backgroundColor: `${memberBase?.status === MemberStatus.Suspended ? 'rgba(229, 0, 0, 0.1)' : ''}`,
          }}
        >
          <ChangeBigImgWithStatus
            status={memberBase?.status === MemberStatus.Active ? MemberStatus.Active : MemberStatus.Suspended}
          >
            <img src={memberBase?.image ?? ''} alt="" style={{ width: '90px', height: '60px' }} />
          </ChangeBigImgWithStatus>
          <Box display="flex" flexDirection="column" gap={1} height={48}>
            <Text color="darkBlue" variant="h1">
              {memberBase?.name}
            </Text>
          </Box>
        </div>
        <Divider option="horizontal" />
        <Info items={infoItems} />
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 1003,
    height: '90vh',
  };

  return (
    <>
      {vBMemberBaseByIdLoading && <Loading />}
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
        width={modalContent.width}
        height={modalContent.height}
      />
    </>
  );
}
