/**
 * Figma ID: 07-02-02
 * 名称: 逆オファー（受け入れ募集内容）
 */
import { Info } from 'components/info';
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import Divider from 'components/divider/divider';
import { ImgWrapper } from 'components/assets/css/pages/pageStyle';
import { TextLink } from 'components/textLink';
import { ImageModal } from 'components/newModal';

import { Link as RouterLink } from 'react-router-dom';
import { MainButton } from 'components/mainButton';
import { ReversedOfferListStatusIndex } from 'pages/s07/status';
import { Loading } from 'components/loading/loading';
import { useGetVbOfferAcceptInfoByAcceptIdQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';

type AcceptRevercedOfferDetailContentModel = {
  acceptId?: number;
  offerId?: number;
  onClickTpmemDetail: () => void;
  status?: number;
  onClose: () => void;
};
function AcceptRevercedOfferDetailContent(props: AcceptRevercedOfferDetailContentModel) {
  const { acceptId, onClickTpmemDetail, status, offerId, onClose } = props;
  const {
    data,
    loading,
    error: offerAcceptInfoError,
  } = useGetVbOfferAcceptInfoByAcceptIdQuery({
    variables: { accept_id: acceptId ?? 0 },
  });
  const [isImageModalOpen, setIsImageModalOpen] = React.useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = React.useState<string>('');

  const info = data?.getVBOfferAcceptInfoByAcceptId;

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (offerAcceptInfoError) {
      onClose();
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [offerAcceptInfoError, openErrorModal, onClose]);

  return (
    <Box>
      {loading && <Loading />}
      <Box display="flex" justifyContent="space-between">
        <Text variant="h2" bold color="darkBlue">
          募集内容
        </Text>
        {status === ReversedOfferListStatusIndex.SUCCESS && (
          <RouterLink to={`/s04-02/${offerId ?? 0}`} style={{ textDecoration: 'none' }}>
            <MainButton width={160} variant="secondary" thin>
              成約バイト状況
            </MainButton>
          </RouterLink>
        )}
      </Box>
      <Box pt={10} pb={8}>
        <Divider option="horizontal" />
      </Box>
      <Box overflow="auto" height={450}>
        <Info
          items={[
            {
              label: '掲載No.',
              content: info?.offer_number,
            },
            {
              label: '採用方式',
              content: info?.auto_decide,
            },
            {
              label: '募集店',
              content: info?.name,
            },
            {
              label: 'バイト期間',
              content: info?.begin_end,
            },
            {
              label: '休憩',
              content: `${info?.break_time || ''}分`,
            },
            {
              label: '時給',
              content: `¥${info?.hour_wage?.toLocaleString() || 0}`,
            },
            {
              label: '当日の店舗責任者',
              content: info?.chief,
            },
            {
              label: '交通費',
              content: `¥${info?.trans_fee || 0}`,
            },
            {
              label: '業務詳細',
              content: (
                <Text wordBreak="break-all" variant="body14">
                  {charcterReplaceBr(info?.description)}
                </Text>
              ),
            },
            {
              label: '働くための条件',
              content: (
                <Text wordBreak="break-all" variant="body14">
                  {charcterReplaceBr(info?.conditions)}
                </Text>
              ),
            },
            {
              label: '注意事項',
              content: (
                <Text wordBreak="break-all" variant="body14">
                  {charcterReplaceBr(info?.caution)}
                </Text>
              ),
            },
            {
              label: '持ち物',
              content: (
                <Text wordBreak="break-all" variant="body14">
                  {charcterReplaceBr(info?.belongings)}
                </Text>
              ),
            },
            {
              label: 'アクセス',
              content: (
                <Text wordBreak="break-all" variant="body14">
                  {charcterReplaceBr(info?.access)}
                </Text>
              ),
            },
            {
              content: (
                <div style={{ alignItems: 'center', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {info?.image1 && (
                    <ImgWrapper
                      onClick={() => {
                        setModalImageSrc(info?.image1 || '');
                        setIsImageModalOpen(true);
                      }}
                    >
                      <img style={{ width: '126px', height: '94.5px' }} src={info?.image1 || ''} alt="サムネイル" />
                    </ImgWrapper>
                  )}
                  {info?.image2 && (
                    <ImgWrapper
                      onClick={() => {
                        setModalImageSrc(info?.image2 || '');
                        setIsImageModalOpen(true);
                      }}
                    >
                      <img style={{ width: '126px', height: '94.5px' }} src={info?.image2 || ''} alt="サムネイル" />
                    </ImgWrapper>
                  )}
                  {info?.image3 && (
                    <ImgWrapper
                      onClick={() => {
                        setModalImageSrc(info?.image3 || '');
                        setIsImageModalOpen(true);
                      }}
                    >
                      <img style={{ width: '126px', height: '94.5px' }} src={info?.image3 || ''} alt="サムネイル" />
                    </ImgWrapper>
                  )}
                  {info?.image4 && (
                    <ImgWrapper
                      onClick={() => {
                        setModalImageSrc(info?.image4 || '');
                        setIsImageModalOpen(true);
                      }}
                    >
                      <img style={{ width: '126px', height: '94.5px' }} src={info?.image4 || ''} alt="サムネイル" />
                    </ImgWrapper>
                  )}
                  {info?.image5 && (
                    <ImgWrapper
                      onClick={() => {
                        setModalImageSrc(info?.image5 || '');
                        setIsImageModalOpen(true);
                      }}
                    >
                      <img style={{ width: '126px', height: '94.5px' }} src={info?.image5 || ''} alt="サムネイル" />
                    </ImgWrapper>
                  )}
                </div>
              ),
              label: 'メイン画像',
            },
            {
              content: info?.attached_file ? (
                <TextLink href={info?.attached_file} variant="body14">
                  {info?.attached_name}
                </TextLink>
              ) : (
                '無'
              ),
              label: '添付ファイル',
            },
          ]}
        />
        <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
      </Box>
    </Box>
  );
}

export { AcceptRevercedOfferDetailContent };
