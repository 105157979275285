import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { CreateBlockKey, ModalRet } from 'pages/s03/type';
import React from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useAddTTpmemBlockMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Reason } from 'pages/s03/const';
import { Divider } from 'components/newDivider';
import { useAuth } from 'hooks/authProvider';

export function ConfirmModal(
  myId: number,
  name: string,
  getValues: UseFormGetValues<CreateBlockKey>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const [addTTpmemBlockMutation, { loading: tTpmemBlockLoading }] = useAddTTpmemBlockMutation();
  const { openErrorModal } = useErrorModal();
  const auth = useAuth();
  const getReasonLabel = () => {
    switch (Number(getValues('reason'))) {
      case Reason.POTENTIALLY_DRAFTED:
        return '引き抜きされる可能性がある';
      case Reason.ALREADY_DRAFTED:
        return '引き抜きされた';
      case Reason.OTHER:
      default:
        return 'その他';
    }
  };
  const generateBlockStoreNamesList = () => {
    const storeName = getValues('store_name');
    return (
      <Box>
        {storeName.split('\n').map((item) => (
          <Box display="grid">
            <Text variant="body14">・{item}</Text>
          </Box>
        ))}
      </Box>
    );
  };

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック登録 - 確認
      </Text>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={16} pa={8} width="100%">
        <Text variant="body14">以下の店舗をブロック登録します。よろしいですか？</Text>
        <Box display="flex" flexDirection="column" overflow="auto">
          {getValues('store_name') !== undefined && generateBlockStoreNamesList()}
        </Box>
        <Divider option="horizontal" />
        <Text variant="body14">店舗ブロック理由（正当な理由がない場合、運営がブロックを解除する場合があります）</Text>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Text variant="body14">・{getReasonLabel() ?? ''}</Text>
          {(getValues('reason_other') ?? '') !== '' && <Text variant="body14">{getValues('reason_other') ?? ''}</Text>}
        </Box>
        <Text variant="body14">よろしいですか？</Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {tTpmemBlockLoading && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            addTTpmemBlockMutation({
              variables: {
                block_ids: String(getValues('store_id')),
                id: myId,
                reason: getReasonLabel(),
                umgmt_id: auth.userInfo?.id ?? 0,
              },
            })
              .then((e) => {
                if (!e.errors) {
                  onNext();
                } else {
                  onClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              })
              .catch(() => {
                onClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }}
        >
          登録
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 421,
  };

  return modalContent;
}
