import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { GENERIC_COLOR } from '../assets/css/style';

export interface Props {
  children: ReactNode;
  htmlFor?: string;
  width?: number;
  fullWidth?: boolean;
  optional?: boolean;
  required?: boolean;
  subLabel?: string;
  subLabelHref?: string;
}

const StyledDiv = styled.div<{ width: number; fullWidth?: boolean }>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};

  display: flex;
  justify-content: space-between;
`;

const StyledLabel = styled.label``;

const StyledOptional = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.OK_BLUE};
  border-radius: 2px;
`;
const StyledRequired = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.CAUTION_RED};
  border-radius: 2px;
`;
const StyledSubLabel = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 18px;
  border: 1px solid ${GENERIC_COLOR.OK_BLUE};
  border-radius: 2px;
  cursor: pointer;
`;

export function InputLabel({
  children,
  htmlFor,
  width = 328,
  optional,
  subLabel,
  fullWidth,
  required,
  subLabelHref,
}: Props) {
  return (
    <StyledDiv width={width} fullWidth={fullWidth}>
      <StyledLabel htmlFor={htmlFor}>
        <Text variant="body14" bold color="blueGray">
          {children}
        </Text>
      </StyledLabel>
      {required && (
        <StyledRequired>
          <Text variant="caption11" color="cautionRed">
            必須
          </Text>
        </StyledRequired>
      )}
      {optional && (
        <StyledOptional>
          <Text variant="caption11" color="okBlue">
            任意
          </Text>
        </StyledOptional>
      )}
      {subLabel && (
        <StyledSubLabel href={subLabelHref} target="_blank" rel="noopener noreferrer">
          <Text variant="caption11" color="okBlue">
            {subLabel}
          </Text>
        </StyledSubLabel>
      )}
    </StyledDiv>
  );
}
