import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { PRIMARY_COLOR } from '../assets/css/style';
import { Icon } from '../icon';

export interface Props {
  items: { label: string; content: ReactNode; hasNotification?: boolean; isDisable?: boolean }[];
  defaultIndex?: number;
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const StyledSubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px;
  min-width: 200px;
  background: ${PRIMARY_COLOR.LITE_BLUE};
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px 0 0 8px;
`;

const StyledSubMenuItem = styled.button<{ active: boolean; isDisable?: boolean }>`
  position: relative;
  padding: 9.5px 16px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ active }) => (active ? PRIMARY_COLOR.BLUE : 'inherit')};
  border: none;
  cursor: ${({ isDisable }) => (isDisable ? 'default' : 'pointer')};
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
`;

export function SubMenu({ items, defaultIndex }: Props) {
  const [menuIndex, setMenuIndex] = React.useState<number>(defaultIndex || 0);

  return (
    <StyledWrapper>
      <StyledSubMenuWrapper>
        {items.map((item, index) => (
          <StyledSubMenuItem
            key={item.label}
            active={menuIndex === index}
            type="button"
            role="tab"
            onClick={() => (item.isDisable ? {} : setMenuIndex(index))}
            isDisable={item.isDisable}
          >
            {item.hasNotification && (
              <StyledIconWrapper>
                <Icon name="indicatorRed" size={8} />
              </StyledIconWrapper>
            )}
            <Text
              variant="body14"
              bold={menuIndex === index}
              color={item.isDisable ? 'unlinkBlue2' : menuIndex === index ? 'white' : 'primaryBlue'}
            >
              {item.label}
            </Text>
          </StyledSubMenuItem>
        ))}
      </StyledSubMenuWrapper>
      {items[menuIndex] && items[menuIndex].content}
    </StyledWrapper>
  );
}
