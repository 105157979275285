/*
 * Texｔで改行させたい場合に改行を<br />に置き換えないと改行されない
 */
import React from 'react';

export function charcterReplaceBr(msg?: string | null) {
  if (!msg) return <div />;
  const texts = msg?.split('\n').map((item, index) => (
    <React.Fragment key={`${Math.random()}`}>
      {item}
      <br />
    </React.Fragment>
  ));
  return <div>{texts}</div>;
}
