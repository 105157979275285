import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';

export const colorPalette = {
  white: '#FFFFFF',
  gray: '#DBDBDB',
  red: 'rgba(255, 0, 0, 0.1)',
  yellow: 'rgba(255, 184, 0, 0.1)',
} as const;

export type ColorKeyType = keyof typeof colorPalette;
type ColorValueType = typeof colorPalette[ColorKeyType];

export interface Props {
  backgroundColor?: ColorKeyType;
  rowItem: {
    width: number | string;
    content: ReactNode;
    key: string;
  }[];
  rowHeight?: number;
  rowWidth?: number | string;
}

const StyledRow = styled.li<{
  backgroundColor: ColorValueType;
  rowHeight?: number;
  rowWidth: number | string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ rowHeight }) => (rowHeight ? `${rowHeight}px` : '56px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #dbdbdb;
  width: ${({ rowWidth }) => (typeof rowWidth === 'number' ? `${rowWidth}px` : rowWidth)};
`;

const StyledCell = styled.div<{ width: number | string }>`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export function ListRow({ rowItem, backgroundColor = 'white', rowHeight, rowWidth = 'max-content' }: Props) {
  return (
    <StyledRow backgroundColor={colorPalette[backgroundColor]} rowHeight={rowHeight} rowWidth={rowWidth}>
      {rowItem.map((item) => (
        <StyledCell key={item.key} width={item.width}>
          {typeof item.content === 'string' || typeof item.content === 'number' ? (
            <Text variant="caption12" lineClamp={1}>
              {item.content}
            </Text>
          ) : (
            item.content
          )}
        </StyledCell>
      ))}
    </StyledRow>
  );
}
