import React, { useRef, useState } from 'react';
import { Loading } from 'components/loading/loading';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { SelectForm } from 'components/selectForm';
import { PRIMARY_COLOR, GENERIC_COLOR } from 'components/assets/css/style';
import { Modal } from 'components/newModal';
import { Select } from 'components/select';
import { uploadFile } from 'components/api';
import { useErrorModal } from 'components/error/errorModalProvider';
import { downloadCsvFile } from 'components/csv';
import {
  SPaymentDays,
  useGetSPaymentDaysByYearQuery,
  useGetSYearScheduleGpByPaymentRegistQuery,
  useUploadSPaymentDaysMutation,
} from 'graphql/graphql-ow';

interface Props {
  annualPayday: SPaymentDays[];
}

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 358px);
  max-height: calc(100vh - 358px);
  overflow: auto;
  width: 100%;
`;

const StyledListHeader = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 40px;
  background: ${PRIMARY_COLOR.BLUE_GRAY};
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 32px;
  border-bottom: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
`;

enum ModalStep {
  NULL = 0,
  OVERWRITE_REGISTRATION = 1,
  REGISTER_CONFIRMATION = 2,
  REGISTER_COMPLETED = 3,
}

// Figma管理番号
// 10-10-01
// 10-10-02
// 10-10-03
// 10-10-04
// 10-10-05
// 10-10-06
export function AnnualPaydayConfig({ annualPayday }: Props) {
  const {
    data: scheduleData,
    loading: scheduleLoading,
    refetch: refetchSchedule,
  } = useGetSYearScheduleGpByPaymentRegistQuery();
  const [uploadSPaymentDays, { loading: sWithHolydayLoading }] = useUploadSPaymentDaysMutation();

  const [selectedYear, setSelectedYear] = useState<string>('');
  // アップロード対象年
  const [selectedUploadYear, setSelectedUploadYear] = useState<string>('');
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {
    data: annualPaydayData,
    loading: annualPaydayLoading,
    refetch: refetchList,
  } = useGetSPaymentDaysByYearQuery({
    variables: {
      year: Number(selectedYear),
    },
    fetchPolicy: 'no-cache',
  });
  const isNoData = annualPaydayData?.getSPaymentDaysByYear.payment.length === 0;
  const lastDataUpdated = annualPaydayData?.getSPaymentDaysByYear.schedule.payment_regist
    ?.replace('年', '/')
    .replace('月', '/')
    .replace('日', '');
  const csvFileName = `給料日年間予定表${selectedYear}.csv`;

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  // ファイルアップロード用ローディング
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);

  const handleClickHiddenFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (file.size === 0) {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: '空のファイルはアップロードできません。' });
          return;
        }

        // 拡張子チェック
        const fileExtension = file.name.split('.').pop();

        if (fileExtension?.toLowerCase() !== 'csv') {
          setModalStep(ModalStep.NULL);
          openErrorModal({ message: 'CSVファイルを指定してください。' });
          return;
        }

        setSelectedFile(file);

        // ファイル選択欄を空にする
        // ※この処理を入れないと再度ファイル選択してもonChangeが発火しない
        event.target.value = '';

        // 次のダイアログを表示する
        setModalStep(ModalStep.REGISTER_CONFIRMATION);
      };
    }
  };

  // ファイルアップロード処理
  const uploadCsvFile = React.useCallback(async () => {
    try {
      const file = selectedFile;

      if (!file) return;

      // ファイルアップロード中はローディング表示
      setFileUploadLoading(true);
      // CSVファイルアップロード
      await uploadFile({ filePath: `temporary/${file.name}`, file });

      // アップロードAPI実行
      const result = await uploadSPaymentDays({
        variables: {
          s3_bucket: process.env.REACT_APP_UPLOAD_S3_BUCKET || '',
          s3_filepath: `temporary/${file.name}`,
          year: Number(selectedUploadYear),
        },
      });

      // 年を再取得し直す
      await refetchSchedule();
      // 一覧上の情報を更新する
      await refetchList();

      // 更新対象件数
      const resultRows = Number(result.data?.uploadSPaymentDays.resultRows);

      // 該当年のデータが存在しない場合
      if (resultRows === -1) {
        setModalStep(ModalStep.NULL);
        openErrorModal({ message: '登録対象外の年のデータは登録できません。' });
        return;
      }

      if (Number(result.data?.uploadSPaymentDays.resultRows) === 0) {
        setModalStep(ModalStep.NULL);
        openErrorModal({ title: '', message: '登録対象のデータが存在しませんでした。' });
        return;
      }

      // 完了ダイアログ表示
      setModalStep(ModalStep.REGISTER_COMPLETED);
    } catch (e) {
      console.log(e);
      setModalStep(ModalStep.NULL);
      openErrorModal({ message: '登録に失敗しました。' });
    } finally {
      // ローディング解除
      setFileUploadLoading(false);
    }
  }, [selectedFile, uploadSPaymentDays, refetchList, refetchSchedule, openErrorModal, selectedUploadYear]);

  // 年選択用Selectオプション
  const scheduleSelectOptions = React.useMemo(() => {
    const yearList = scheduleData?.getSYearScheduleGpByPaymentRegist || [];
    return yearList.map((data) => ({ label: String(data.year), value: String(data.year) }));
  }, [scheduleData?.getSYearScheduleGpByPaymentRegist]);

  // アップロード年選択Selectのオプション
  // 現在年から10年分
  const uoploadSelectOptions = React.useMemo(() => {
    // 現在年
    const currentYear = new Date().getFullYear();
    const options = [];

    for (let year = currentYear; year <= currentYear + 9; year += 1) {
      options.push({ label: String(year), value: String(year) });
    }

    return options;
  }, []);

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.OVERWRITE_REGISTRATION:
        return {
          width: 384,
          height: 'fit-content',
          overflow: 'visible',
          header: (
            <Text variant="h2" color="darkBlue">
              登録
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">
                月次支払設定用のデータを登録します。
                <br />
                この操作は中断できません。
                <br />
                本年以降のデータに関してのみアップロードが何度でも可能です。
                <br />
                <br />
                設定する年を選択して登録を押してください。
              </Text>
              <Box display="grid" mt={17.5}>
                <Text variant="body14" bold color="blueGray">
                  年
                </Text>
                <Box mt={5.5}>
                  <Select
                    width={160}
                    value={selectedUploadYear}
                    options={uoploadSelectOptions}
                    onChange={(value) => setSelectedUploadYear(value)}
                    placeholder="年を選択"
                  />
                </Box>
              </Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                disabled={selectedUploadYear === ''}
                width={104}
                onClick={() => {
                  handleClickHiddenFileInput();
                }}
                variant="primary"
              >
                登録
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.REGISTER_CONFIRMATION:
        return {
          width: 400,
          height: 'fit-content',
          header: (
            <Text variant="h2" color="darkBlue">
              登録
            </Text>
          ),
          content: (
            <Box display="grid" width={350} gap={10}>
              <Text variant="body14">
                下記のデータを登録します。
                <br />
                <br />・{selectedFile && selectedFile.name}
                <br />
                <br />
                よろしいですか？
              </Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.OVERWRITE_REGISTRATION);
                }}
              >
                戻る
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  uploadCsvFile();
                }}
              >
                登録
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.REGISTER_COMPLETED:
        return {
          width: 384,
          height: 'fit-content',
          header: (
            <Text variant="h2" color="darkBlue">
              登録 完了
            </Text>
          ),
          content: <Text variant="body14">情報の登録が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                閉じる
              </MainButton>
            </Box>
          ),
        };

      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [modalStep, selectedFile, selectedUploadYear, uploadCsvFile, uoploadSelectOptions]);

  return (
    <>
      {(sWithHolydayLoading || annualPaydayLoading || scheduleLoading || fileUploadLoading) && <Loading />}
      <Box px={24} py={16} display="flex" flexDirection="column" alignItems="center" gap={16} width="100%">
        <Box justifyContent="space-between" display="flex" gap={8} alignItems="center" width="100%">
          <Box display="flex" height="100%" alignItems="center" gap={5}>
            <Text variant="h2" color="darkBlue">
              給料日年間設定
            </Text>
            <Text variant="caption12" color="darkBlue">
              支払日の年間での月次スケジュールを管理します。
            </Text>
          </Box>
          <MainButton
            width={165}
            icon="upload"
            onClick={() => {
              setModalStep(ModalStep.OVERWRITE_REGISTRATION);
              setSelectedUploadYear('');
            }}
          >
            登録
          </MainButton>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <SelectForm
            width={160}
            label="年"
            value={selectedYear}
            options={scheduleSelectOptions}
            onChange={(value) => setSelectedYear(value)}
            placeholder="年を選択"
          />
          <Box display="flex" gap={5} alignItems="center">
            {!isNoData && (
              <Text variant="caption12" color="darkBlue">
                最終データアップロード登録 : {lastDataUpdated}
              </Text>
            )}
            <MainButton
              width={165}
              icon="download"
              variant="secondary"
              disabled={isNoData}
              onClick={() => {
                const columns = [
                  { header: '年', key: 'year' },
                  { header: '月', key: 'month' },
                  { header: '日付', key: 'date' },
                ];
                const rows: { year: string; month: string; date: string }[] = [];
                annualPaydayData?.getSPaymentDaysByYear.payment.map(
                  (val) => rows.push({ year: String(val.year), month: String(val.month), date: val.day }) // yyyy-mm-ddからddのみを取得
                );
                downloadCsvFile(columns, rows, csvFileName, undefined, true);
              }}
            >
              CSVダウンロード
            </MainButton>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <StyledOverflowWrapper>
          <ul>
            <StyledListHeader>
              <Box width={160}>
                <Text variant="caption12" color="white">
                  月
                </Text>
              </Box>
              <Divider option="vertical" length={24} />
              <Text variant="caption12" color="white">
                日付
              </Text>
            </StyledListHeader>
            {annualPaydayData?.getSPaymentDaysByYear.payment.map(({ month, day }) => (
              <StyledListItem>
                <Box width={160}>
                  <Text variant="caption12">{month}</Text>
                </Box>
                <Divider option="vertical" length={32} />
                {/* yyyy-mm-ddからddのみを取得 */}
                <Text variant="caption12">{day.substring(day.length - 2)}</Text>
              </StyledListItem>
            ))}
          </ul>
        </StyledOverflowWrapper>
      </Box>
      <Modal
        width={displayModal.width + 50}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(0)}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow={displayModal.overflow || 'auto'}
      />
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} accept="text/csv" />
    </>
  );
}
