/**
 * Figma ID: 10-08-02-03
 * 名称: ギフト選択肢(新規作成)
 */
import React from 'react';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Control, FieldErrors } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import type { InputDataModel } from '../GiftChoice';

export function TokenAndIdHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        新規追加
      </Text>
    </Box>
  );
}

type TokenAndIdContentModel = {
  control: Control<InputDataModel>;
  errors: FieldErrors<InputDataModel>;
};
export function TokenAndIdContent(props: TokenAndIdContentModel) {
  const { control, errors } = props;
  return (
    <Box>
      <Box pb={10}>
        <Text variant="body14">トークンとIDを入力してください。</Text>
      </Box>
      <Box pb={26}>
        <InputLabel>トークンを入力</InputLabel>
        <RhfInput
          control={control}
          name="token"
          placeholder="トークン番号"
          defaultValue=""
          type="text"
          rules={{
            required: '必須項目です',
            pattern: {
              value: /^[a-zA-Z0-9-]*$/,
              message: '半角の英数字でトークン番号を入力してください。',
            },
          }}
        />
        {errors.token?.message && <Text color="cautionRed" variant="caption12">{`${errors.token.message}`}</Text>}
      </Box>
      <Box pb={18}>
        <InputLabel>IDを入力</InputLabel>
        <RhfInput
          control={control}
          name="configCode"
          placeholder="ID"
          defaultValue=""
          type="text"
          rules={{
            required: '必須項目です',
            pattern: {
              value: /^[a-zA-Z0-9-]*$/,
              message: '半角の英数字でIDを入力してください。',
            },
          }}
        />
        {errors.configCode?.message && (
          <Text color="cautionRed" variant="caption12">{`${errors.configCode.message}`}</Text>
        )}
      </Box>
    </Box>
  );
}

type TokenAndIdFooterModel = {
  onClickClose: () => void;
  onClickNext: () => void;
  onClickBack: () => void;
};
export function TokenAndIdFooter(props: TokenAndIdFooterModel) {
  const { onClickClose, onClickBack, onClickNext } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickBack} width={104} variant="secondary">
        戻る
      </MainButton>
      <MainButton onClick={onClickNext} width={104}>
        次へ
      </MainButton>
    </Box>
  );
}
