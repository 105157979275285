/**
 * Figma ID: 04-01-01
 * 名称: バイト募集情報
 */

import React, { ReactNode, useEffect } from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Card } from 'components/card';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { Icon } from 'components/icon';
import { Chip } from 'components/chip';
import { DatePicker } from 'components/datePicker';
import { orderBy } from 'lodash';
import { List } from 'components/list';
import { useNavigate } from 'react-router-dom';
import {
  useGetVtArbeitOfferListQuery,
  useGetVtMemberBaseByIdLazyQuery,
  GetVtMemberBaseByIdQuery,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { TextLink } from 'components/textLink';
import {
  MainContentWrapper,
  MainContentHeader,
  MainContentTitle,
  MainContentArea,
} from 'components/pageLayout/mainContent';

import { isSameDay } from 'date-fns';
import { StoreModal } from 'modal/storeModal';

const StyledListWrapper = styled.div`
  max-height: calc(100vh - 348px);
  overflow: auto;
`;

// バイト募集情報
export function S0401() {
  const { openErrorModal } = useErrorModal();
  const {
    data: { getVTArbeitOfferList } = { getVTArbeitOfferList: [] },
    loading,
    error: getVTArbeitOfferListError,
  } = useGetVtArbeitOfferListQuery({ fetchPolicy: 'no-cache' });
  const [getVtMemberBaseById, { error: getVtMemberBaseByIdError }] = useGetVtMemberBaseByIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (getVTArbeitOfferListError || getVtMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVTArbeitOfferListError, getVtMemberBaseByIdError, openErrorModal]);
  const [vtMemberBase, setVtMemberBase] = React.useState<GetVtMemberBaseByIdQuery['getVTMemberBaseById'] | null>(null);

  const navigate = useNavigate();

  const [searchOfferNo, setSearchOfferNo] = React.useState<string>('');
  const [searchTitle, setSearchTitle] = React.useState<string>('');
  const [searchStore, setSearchStore] = React.useState<string>('');
  const [searchConditions, setSearchConditions] = React.useState<{
    offerNumber: string;
    title: string;
    store: string;
  } | null>(null);
  const [workingDay, setWorkingDay] = React.useState<Date | null>(null);
  const [statusSelect, setStatusSelect] = React.useState<string>('');
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeSort = (key: string) => {
    setCurrentPage(1);
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };

  const statusChip = (identification: number) => {
    switch (identification) {
      case 1:
        return <Chip color="blue">募集前</Chip>;
      case 2:
        return <Chip color="darkBlue">募集中</Chip>;
      case 3:
        return <Chip color="okBlue">採用確定</Chip>;
      case 4:
        return <Chip color="liteGray">募集終了</Chip>;
      case 6:
        return <Chip color="redLite">募集停止</Chip>;
      case 7:
        return <Chip color="cautionRed">強制停止</Chip>;
      default:
        return <Chip color="warningYellow">?</Chip>;
    }
  };

  const autoTitle = (title: string, auto_decide: boolean) => (
    <>
      {auto_decide && (
        <Chip color="liteGray" width={56}>
          自動採用
        </Chip>
      )}
      <Text variant="caption12" lineClamp={1}>
        {title}
      </Text>
    </>
  );

  const generateBgColor = (status: number) => {
    switch (status) {
      case 4:
        return 'gray';
      case 6:
      case 7:
        return 'red';
      default:
        return 'white';
    }
  };

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { width: 24, key: 'eye' },
    { width: 56, key: 'status', columnName: '状態' },
    { width: 112, key: 'offer_number', columnName: '掲載No.' },
    { width: 233, key: 'title', columnName: '掲載タイトル' },
    { width: 160, key: 'name', columnName: '募集店' },
    { width: 80, key: 'entry_count', columnName: '応募人数' },
    { width: 80, key: 'hour_wage_disp', columnName: '時給' },
    { width: 104, key: 'trans_fee', columnName: '交通費の有無' },
    { width: 140, key: 'post_begin', columnName: '募集開始' },
    { width: 140, key: 'post_end', columnName: '募集締切' },
    { width: 160, key: 'begin_date', columnName: 'バイト開始期日' },
    { width: 160, key: 'end_date', columnName: 'バイト終了期日' },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    if (loading || getVTArbeitOfferListError) {
      return [];
    }
    let items = getVTArbeitOfferList;
    if (statusSelect) {
      items = items.filter(({ status }) => {
        switch (statusSelect) {
          case '1':
            return status === 1;
          case '2':
            return status === 2;
          case '3':
            return status === 3;
          case '4':
            return status === 4;
          case '5':
            return status === 6;
          case '6':
            return status === 7;
          default:
            return true;
        }
      });
    }
    if (searchConditions) {
      items = items
        .filter(({ offer_number }) => {
          if (!searchConditions.offerNumber) {
            return true;
          }
          const reg = new RegExp(searchConditions.offerNumber);
          return reg.test(String(offer_number));
        })
        .filter(({ title }) => {
          if (!searchConditions.title) {
            return true;
          }
          const reg = new RegExp(searchConditions.title);
          return reg.test(title || '');
        })
        .filter(({ name }) => {
          if (!searchConditions.store) {
            return true;
          }
          const reg = new RegExp(searchConditions.store);
          return reg.test(name || '');
        });
    }
    if (workingDay) {
      items = items.filter(({ begin_date }) => {
        if (!begin_date) {
          return false;
        }
        const regex = /(\d{4})年(\d{2})月(\d{2})日/;
        const matches = begin_date.match(regex);

        if (matches) {
          const year = Number(matches[1]);
          const month = Number(matches[2]);
          const day = Number(matches[3]);
          return isSameDay(new Date(year, month - 1, day), workingDay);
        }
        return false;
      });
    }
    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else if (sort.key === 'hour_wage_disp') {
        items = orderBy(items, (item) => item.hour_wage, sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items.map((item) => ({
      uniqueKey: item.id,
      backgroundColor: generateBgColor(item.status),
      eye: <Icon name="eye" onClick={() => navigate(`/s04-01/${String(item.id)}`)} />,
      status: statusChip(item.status),
      offer_number: item.offer_number,
      title: autoTitle(item.title || '', item.auto_decide),
      name: (
        <TextLink
          lineClamp={1}
          onClick={() => {
            getVtMemberBaseById({ variables: { id: item.tpmem_id } }).then(({ data: { getVTMemberBaseById } = {} }) => {
              if (getVTMemberBaseById) {
                setVtMemberBase(getVTMemberBaseById);
              }
            });
          }}
        >
          {item.name}
        </TextLink>
      ),
      entry_count: item.entry_count,
      hour_wage_disp: `¥${item.hour_wage_disp ?? ''}`,
      trans_fee: item.trans_fee,
      post_begin: item.post_begin,
      post_end: item.post_end,
      begin_date: item.begin_date,
      end_date: item.end_date,
    }));
  }, [
    sort,
    statusSelect,
    searchConditions,
    workingDay,
    loading,
    getVTArbeitOfferList,
    navigate,
    getVtMemberBaseById,
    getVTArbeitOfferListError,
  ]);

  const filterHeight = 64;
  const dividerHeight = 1;
  const totalHeaderHeight = filterHeight + dividerHeight;

  return (
    <>
      {loading && <Loading />}
      <MainContentWrapper>
        <MainContentHeader>
          <MainContentTitle text="バイト募集情報" />
        </MainContentHeader>
        <MainContentArea>
          <Card overflow="hidden">
            <Box display="flex" flexDirection="column" height="100%">
              <Box display="flex" flexWrap="wrap" alignItems="center" pa={16} gap={16}>
                <Input
                  value={searchOfferNo}
                  onChange={(event) => setSearchOfferNo(event?.target.value || '')}
                  width={120}
                  placeholder="掲載No."
                />
                <Input
                  value={searchTitle}
                  onChange={(event) => setSearchTitle(event?.target.value || '')}
                  width={120}
                  placeholder="タイトル"
                />
                <Input
                  value={searchStore}
                  onChange={(event) => setSearchStore(event?.target.value || '')}
                  width={120}
                  placeholder="店名"
                />
                <MainButton
                  variant="primary"
                  width={80}
                  icon="search"
                  onClick={() => {
                    // ページ初期化
                    setCurrentPage(1);
                    setSearchConditions({
                      offerNumber: searchOfferNo,
                      title: searchTitle,
                      store: searchStore,
                    });
                  }}
                >
                  検索
                </MainButton>
                <Divider option="vertical" length={24} />
                <div>
                  <DatePicker
                    selected={workingDay}
                    onChange={(value) => {
                      // ページ初期化
                      setCurrentPage(1);
                      setWorkingDay(value);
                    }}
                    placeholderText="バイト日"
                    width={160}
                  />
                </div>

                <Select
                  value={statusSelect}
                  options={[
                    {
                      value: '0',
                      label: '全ての状態',
                    },
                    {
                      value: '1',
                      label: '募集前',
                    },
                    {
                      value: '2',
                      label: '募集中',
                    },
                    {
                      value: '3',
                      label: '採用確定',
                    },
                    {
                      value: '4',
                      label: '募集終了',
                    },
                    {
                      value: '5',
                      label: '募集停止',
                    },
                    {
                      value: '6',
                      label: '強制停止',
                    },
                  ]}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setStatusSelect(value);
                  }}
                  placeholder="状態"
                  width={120}
                />

                <MainButton
                  variant="clear"
                  width={44}
                  onClick={() => {
                    setSearchOfferNo('');
                    setSearchTitle('');
                    setSearchStore('');
                    setSearchConditions(null);
                    setWorkingDay(null);
                    setStatusSelect('');
                    // ページ初期化
                    setCurrentPage(1);
                  }}
                >
                  クリア
                </MainButton>
              </Box>
              <Divider option="horizontal" />
              <Box px={16} pb={16} height={`calc(100% - ${totalHeaderHeight}px)`}>
                <PaginationBox
                  dataSize={dataSize}
                  limit={currentLimit}
                  page={currentPage}
                  onChangeLimit={handleChangeLimit}
                  onChangePage={handleChangePage}
                >
                  <Box overflow="auto" height="100%">
                    <List
                      key={String(currentLimit) + String(currentPage) + (sort?.key || '')}
                      header={header}
                      items={displayItems.slice(currentLimit * (currentPage - 1), currentLimit * currentPage)}
                      sort={sort}
                      rowHeight={40}
                      onChangeSort={handleChangeSort}
                    />
                  </Box>
                </PaginationBox>
              </Box>
            </Box>
          </Card>
        </MainContentArea>
      </MainContentWrapper>
      {vtMemberBase && (
        <StoreModal
          isOpen={Boolean(vtMemberBase)}
          vtMemberBase={vtMemberBase}
          onClickClose={() => setVtMemberBase(null)}
        />
      )}
    </>
  );
}
