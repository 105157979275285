/**
 * Figma ID: 04-01-02-01 04-01-02-05 04-01-02-06 04-01-02-07 04-01-02-08 04-01-02-09
 * 名称: 募集情報（バイト募集情報）
 */

import React, { useEffect, useState } from 'react';
import { styled } from '@linaria/react';
import { Text } from 'components/text';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { ImageModal } from 'components/newModal';
import {
  useGetVtMemberBaseByIdLazyQuery,
  GetVtArbeitOfferInfoByIdQuery,
  GetVtMemberBaseByIdQuery,
} from 'graphql/graphql-ow';
import { TextLink } from 'components/textLink';
import { StoreModal } from 'modal/storeModal';
import { Divider } from 'components/newDivider';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';
import { useErrorModal } from 'components/error/errorModalProvider';

interface Props {
  vtArbeitOfferInfo: GetVtArbeitOfferInfoByIdQuery['getVTArbeitOfferInfoById']['info'];
}

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 223px);
  max-height: calc(100vh - 223px);
  overflow: auto;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 126px;
  height: 94.5px;
  object-fit: contain;
  cursor: pointer;
`;

// 募集情報
export function RecruitInfo({ vtArbeitOfferInfo }: Props) {
  const { openErrorModal } = useErrorModal();
  const [getVtMemberBaseById, { error: getVtMemberBaseByIdError }] = useGetVtMemberBaseByIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (getVtMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtMemberBaseByIdError, openErrorModal]);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [vtMemberBase, setVtMemberBase] = React.useState<GetVtMemberBaseByIdQuery['getVTMemberBaseById'] | null>(null);
  const attachedFileName = vtArbeitOfferInfo?.attached_name || '';

  return (
    <>
      <Box px={24} pb={16} display="flex" flexDirection="column" alignItems="center" width="100%">
        <Box width="100%" height={60} display="flex" alignItems="center">
          <Text color="darkBlue" variant="h2">
            募集情報
          </Text>
        </Box>
        <Divider option="horizontal" />
        <StyledOverflowWrapper>
          <Info
            items={[
              {
                content: vtArbeitOfferInfo.offer_number,
                label: '掲載No.',
              },
              {
                content: vtArbeitOfferInfo.auto_decide ? '自動採用' : '手動採用',
                label: '採用方式',
              },
              {
                content: vtArbeitOfferInfo.title,
                label: '掲載タイトル',
              },
              {
                content: (
                  <TextLink
                    onClick={() => {
                      getVtMemberBaseById({ variables: { id: vtArbeitOfferInfo.tpmem_id } }).then(
                        ({ data: { getVTMemberBaseById } = {} }) => {
                          if (getVTMemberBaseById) {
                            setVtMemberBase(getVTMemberBaseById);
                          }
                        }
                      );
                    }}
                  >
                    <div style={{ fontSize: '14px' }}>{vtArbeitOfferInfo.name}</div>
                  </TextLink>
                ),
                label: '募集店',
              },
              {
                content: vtArbeitOfferInfo.post_begin,
                label: '募集開始',
              },
              {
                content: vtArbeitOfferInfo.post_end,
                label: '募集締切',
              },
              {
                content: `${vtArbeitOfferInfo.begin_date || ''} ~ ${vtArbeitOfferInfo.end_date || ''}`,
                label: 'バイト期間',
              },
              {
                content: `${vtArbeitOfferInfo.break_time || '0'}分`,
                label: '休憩',
              },
              {
                content: `¥${vtArbeitOfferInfo.hour_wage_disp || ''}`,
                label: '時給',
              },
              /**
               * TODO: statusで表示・非表示が必要
               */
              {
                content: vtArbeitOfferInfo.rank_name || '-',
                label: '募集ランク',
              },
              {
                content: vtArbeitOfferInfo.score_disp || '-',
                label: 'スコア',
              },
              {
                content: vtArbeitOfferInfo.hire_fee_disp || '-',
                label: '採用手数料',
              },
              {
                content: vtArbeitOfferInfo.cancel_disp || '-',
                label: 'キャンセル率',
              },
              {
                content: vtArbeitOfferInfo.imminent_disp || '-',
                label: 'ドタキャン率',
              },
              {
                content: vtArbeitOfferInfo.chief || '-',
                label: '当日の店舗責任者',
              },
              {
                content: vtArbeitOfferInfo.trans_fee ? `¥${vtArbeitOfferInfo.trans_fee || ''}` : '-',
                label: '交通費',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {vtArbeitOfferInfo.description ? charcterReplaceBr(vtArbeitOfferInfo.description) : '-'}
                  </Text>
                ),
                label: '業務詳細',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {vtArbeitOfferInfo.conditions ? charcterReplaceBr(vtArbeitOfferInfo.conditions) : '-'}
                  </Text>
                ),
                label: '働くための条件',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {vtArbeitOfferInfo.caution ? charcterReplaceBr(vtArbeitOfferInfo.caution) : '-'}
                  </Text>
                ),
                label: '注意事項',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {vtArbeitOfferInfo.belongings ? charcterReplaceBr(vtArbeitOfferInfo.belongings) : '-'}
                  </Text>
                ),
                label: '持ち物',
              },
              {
                content: (
                  <Text wordBreak="break-all" variant="body14">
                    {vtArbeitOfferInfo.access ? charcterReplaceBr(vtArbeitOfferInfo.access) : '-'}
                  </Text>
                ),
                label: 'アクセス',
              },
              {
                content:
                  vtArbeitOfferInfo.image1 ||
                  vtArbeitOfferInfo.image2 ||
                  vtArbeitOfferInfo.image3 ||
                  vtArbeitOfferInfo.image4 ||
                  vtArbeitOfferInfo.image5 ? (
                    <Box display="flex" flexWrap="wrap" gap={8}>
                      {vtArbeitOfferInfo.image1 && (
                        <StyledImage
                          src={vtArbeitOfferInfo.image1}
                          alt="サムネイル"
                          onClick={() => setModalImage(vtArbeitOfferInfo.image1 || '')}
                        />
                      )}
                      {vtArbeitOfferInfo.image2 && (
                        <StyledImage
                          src={vtArbeitOfferInfo.image2}
                          alt="サムネイル"
                          onClick={() => setModalImage(vtArbeitOfferInfo.image2 || '')}
                        />
                      )}
                      {vtArbeitOfferInfo.image3 && (
                        <StyledImage
                          src={vtArbeitOfferInfo.image3}
                          alt="サムネイル"
                          onClick={() => setModalImage(vtArbeitOfferInfo.image3 || '')}
                        />
                      )}
                      {vtArbeitOfferInfo.image4 && (
                        <StyledImage
                          src={vtArbeitOfferInfo.image4}
                          alt="サムネイル"
                          onClick={() => setModalImage(vtArbeitOfferInfo.image4 || '')}
                        />
                      )}
                      {vtArbeitOfferInfo.image5 && (
                        <StyledImage
                          src={vtArbeitOfferInfo.image5}
                          alt="サムネイル"
                          onClick={() => setModalImage(vtArbeitOfferInfo.image5 || '')}
                        />
                      )}
                    </Box>
                  ) : (
                    <Text variant="body14">-</Text>
                  ),
                label: 'メイン画像',
              },
              {
                content: (
                  <Box>
                    {vtArbeitOfferInfo?.attached_name ? (
                      <TextLink variant="body14" href={vtArbeitOfferInfo?.attached_file || ''}>
                        {attachedFileName}
                      </TextLink>
                    ) : (
                      <Text variant="body14">-</Text>
                    )}
                  </Box>
                ),
                label: '添付ファイル',
              },
            ]}
          />
        </StyledOverflowWrapper>
      </Box>
      <ImageModal isOpen={modalImage !== null} src={modalImage || ''} onClose={() => setModalImage(null)} />
      {vtMemberBase && (
        <StoreModal
          isOpen={Boolean(vtMemberBase)}
          vtMemberBase={vtMemberBase}
          onClickClose={() => setVtMemberBase(null)}
        />
      )}
    </>
  );
}
