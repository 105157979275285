import React, { useMemo, useContext } from 'react';
import { orderBy } from 'lodash';
import * as gql from 'graphql/graphql-ow';

import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import { List } from 'components/list';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { usePagenation, useSort } from 'components/utils';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { NewlyNotificationContext } from '../components/newlyNotificationContext';

import { ListHeaderType, ListItemsType } from '../type';

/**
 * Figma ID: 08-03-01
 * 名称: バイト費用支払-月次支払
 */

export function MnPayment() {
  const { setNewlyNotification } = useContext(NewlyNotificationContext);

  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();

  const {
    data: { getVBMPayCsvListNewlyPayArbeit: { csvlist, newly } } = {
      getVBMPayCsvListNewlyPayArbeit: {
        csvlist: [] as gql.VbmPayCsvList[],
        newly: {} as gql.VuNewlyPayArbeit,
      },
    },
    loading: listLoading,
    error: listError,
  } = gql.useGetVbmPayCsvListNewlyPayArbeitQuery({
    variables: {},
  });
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (listError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [listError, openErrorModal]);

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'pay_date', width: 128, columnName: '発行年月' },
      { key: 'output_date', width: 160, columnName: '処理日時' },
    ],
    []
  );

  const listItems: ListItemsType = useMemo(() => {
    if (listLoading) return [];
    let items = csvlist;

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      pay_date: item.pay_date,
      output_date: item.output_date,
      uniqueKey: key,
    }));
  }, [csvlist, listLoading, sort]);

  if (Object.keys(newly).length !== 0) {
    setNewlyNotification(newly);
  }

  return (
    <>
      {listLoading && <Loading />}
      <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 32px)">
        <Box display="flex" flexDirection="row" height={60} px={16} alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={8} alignItems="center">
            <Text variant="h2" color="darkBlue">
              月次支払
            </Text>
            <Text variant="caption12" color="darkBlue">
              毎月5日に自動処理済の月次支払一覧です。
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="auto" px={16}>
          <PaginationBox
            dataSize={listItems.length}
            limit={limit}
            page={page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={380}>
              <List
                header={listHeader}
                items={listItems.slice(limit * (page - 1), limit * page)}
                key={String(limit) + String(page) + String(sort?.key || '')}
                sort={sort}
                onChangeSort={handleChangeSort}
                rowHeight={40}
                rowWidth="100%"
                width="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
    </>
  );
}
