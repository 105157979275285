import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React from 'react';

/**
 * Figma ID: 10-03-01-04, 10-03-01-07,10-03-06-05,10-03-06-11,10-03-08-05,10-03-08-11
 * 名称: 完了モーダル
 */

interface Props {
  title: string;
  onClose: () => void;
}

export function CompleteModal({ title, onClose }: Props) {
  const displayCompleteModal = React.useMemo(
    () => ({
      width: 384,
      height: 'auto',
      header: (
        <Text variant="h2" color="darkBlue">
          {title}
        </Text>
      ),
      content: <Text variant="body14">情報の設定が完了しました。</Text>,
      footer: (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton thin onClick={onClose} variant="secondary" width={104}>
            閉じる
          </MainButton>
        </Box>
      ),
      onClose: () => onClose,
    }),
    [onClose, title]
  );
  return { displayCompleteModal };
}
