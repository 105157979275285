export const CHIP_TEXT = {
  Before: '実施前',
  Progress: '実施中',
  End: '終了',
  BeforeGrant: '付与前',
  Granted: '付与済',
};

export const COLORS_NAME = {
  Yellow: 'warningYellow',
  BLUE: 'okBlue',
  Gray: 'liteGray',
};

export const BUSINESS_TEXT = {
  Convenience: 'コンビニ',
  Cafe: 'カフェ',
};

export const TICKET_OPTIONS = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
];

export enum DeleteModalStep {
  NULL = 0,
  CHECK = 1,
  COMPLETE = 2,
}

export enum ModalStep {
  NULL = 0,
  INPUT = 1,
  CHECK = 2,
  COMPLETE = 3,
}

export enum NewUpdateModalStep {
  NULL = 0,
  INPUT = 1,
  REGION = 2,
  CHECK = 3,
  COMPLETE = 4,
}

export enum InputType {
  Input = 'input',
  Select = 'select',
}

export enum FormUnitType {
  single = 'single',
  double = 'double',
}

export const ParcentList = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
];

export enum FormatDateType {
  Numeric = 'numeric',
  Digit = '2-digit',
}

export enum DateTime {
  BeginDateTime = '00:00:00',
  EndDateTime = '23:59:59',
}

export const RateOptions = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '35',
    value: '35',
  },
  {
    label: '40',
    value: '40',
  },
  {
    label: '45',
    value: '45',
  },
  {
    label: '50',
    value: '50',
  },
];

export enum StateId {
  Hokkaido = '1',
}

export enum ListTitleWidth {
  NarrowWidth = 160,
  WideWidth = 256,
}
