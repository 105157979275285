import React from 'react';
import { Box } from 'components/box';
import { mainContentHeaderHeight } from './MainContentHeader';

const fixedHeight = `calc(100% - ${mainContentHeaderHeight}px)`;

export function MainContentArea({ children }: { children: React.ReactNode }) {
  return (
    <Box minHeight={fixedHeight} maxHeight={fixedHeight} height={fixedHeight}>
      {children}
    </Box>
  );
}
