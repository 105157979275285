import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { Icon } from '../icon';
import { PRIMARY_COLOR } from '../assets/css/style';

export interface Props {
  header: {
    width: number | string;
    columnName?: string;
    columnIcon?: ReactNode;
    key: string;
  }[];
  sort?: {
    key: string;
    direction: 'asc' | 'desc';
  };
  onChangeSort: (key: string) => void;
  isSortable?: boolean;
}

const StyledHeader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 0 16px;
  background: ${PRIMARY_COLOR.BLUE_GRAY};
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
`;

const StyledCell = styled.div<{ width: number | string }>`
  min-width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export function ListHeader({ header, sort, onChangeSort, isSortable = true }: Props) {
  const displayIcon = (key: string): 'sortUp' | 'sortDownSelected' | 'sortUpSelected' => {
    if (!sort) {
      return 'sortUp';
    }
    if (sort.key === key) {
      if (sort.direction === 'asc') {
        return 'sortDownSelected';
      }
      return 'sortUpSelected';
    }
    return 'sortUp';
  };
  return (
    <StyledHeader>
      {header.map(({ columnName, columnIcon, width, key }) => (
        <StyledCell key={key} width={width}>
          {columnName && (
            <>
              {isSortable && <Icon name={displayIcon(key)} onClick={() => onChangeSort(key)} />}
              <Text variant="caption12" color="white">
                {columnName}
              </Text>
            </>
          )}
          {columnIcon ?? <div>{columnIcon}</div>}
        </StyledCell>
      ))}
    </StyledHeader>
  );
}
