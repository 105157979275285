import React, { useEffect, useMemo } from 'react';
import { useGetVtMemberBaseRecvNotifyByIdMutation } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Info } from 'components/info';
import { InfoItemsType } from 'pages/s03/type';
import { MemberStatus, PaymentMethod } from 'pages/s03/const';
import { EditRegistrationModal, EditUnregistrationModal } from 'pages/s03/components/modal';

/**
 * Figma ID: 03-04-01
 * 基本情報
 * BaseInfo
 */

enum ModalType {
  NONE = 'none',
  EDIT_REGISTRATION = 'edit_registration',
  EDIT_UNREGISTRATION = 'edit_unregistration',
}

function SubInfo({ item }: { item: { label?: string; content: string } }) {
  return (
    <Box display="flex" flexDirection="row" gap={8} alignItems="center">
      <Box width={112}>
        <Text variant="body14">{item.label}</Text>
      </Box>
      <Text variant="body14">{item.content}</Text>
    </Box>
  );
}

export function BaseInfo(props: { id: number }) {
  const { id } = props;
  const { openErrorModal } = useErrorModal();
  const [
    getVtMemberBaseRecvNotifyByIdMutation,
    { data: vTMemberBaseData, loading: vTMemberBaseLoading, error: vTMemberBaseError },
  ] = useGetVtMemberBaseRecvNotifyByIdMutation({ variables: { id } });

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.NONE);

  useEffect(() => {
    if (vTMemberBaseData === undefined && id !== 0 && id !== undefined) {
      getVtMemberBaseRecvNotifyByIdMutation({ variables: { id } });
    }
  }, [getVtMemberBaseRecvNotifyByIdMutation, id, vTMemberBaseData]);

  useEffect(() => {
    if (vTMemberBaseError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTMemberBaseError, openErrorModal]);

  const item = vTMemberBaseData?.getVTMemberBaseRecvNotifyById;

  const mCoAddress = `〒${item?.mco_zip_code?.slice(0, 3) ?? ''}-${item?.mco_zip_code?.slice(3, 7) ?? ''}
  ${item?.mco_state ?? ''}${item?.mco_city ?? ''}${item?.mco_address ?? ''}${item?.mco_address2 ?? ''}`;

  const mcoElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gap={8}>
        <Text variant="body14">{item?.mco_name}</Text>
        <Text variant="body14">{item?.mco_zip_code !== '-' ? mCoAddress : '-'}</Text>
        <SubInfo item={{ label: '法人番号', content: item?.corporate_no ?? '' }} />
        <SubInfo item={{ label: '電話番号', content: item?.mco_phone ?? '' }} />
        <SubInfo item={{ label: '代表者', content: item?.mco_m_name ?? '' }} />
        <SubInfo item={{ content: item?.mco_m_kana ?? '' }} />
        <SubInfo item={{ label: '担当者', content: item?.mco_t_name ?? '' }} />
        <SubInfo item={{ content: item?.mco_t_kana ?? '' }} />
        <SubInfo item={{ label: '担当者電話番号', content: item?.mco_t_phone ?? '' }} />
      </Box>
    ),
    [item, mCoAddress]
  );

  const setPpaymentMethod = (type: PaymentMethod) => {
    switch (type) {
      case PaymentMethod.Giro:
        return (
          <Text variant="body14" color="okBlue" bold>
            振替
          </Text>
        );
      case PaymentMethod.TransferRequesting:
        return (
          <Text variant="body14" color="cautionRed" bold>
            振替依頼中(出力済)
          </Text>
        );
        return '';
      case PaymentMethod.Transfer:
      default:
        return (
          <Text variant="body14" color="black" bold>
            振込
          </Text>
        );
    }
  };

  const infoItems: InfoItemsType = useMemo(
    () => [
      { label: '店舗コード', content: item?.code },
      { label: '店舗名', content: item?.store_name },
      { label: '支払方法', content: setPpaymentMethod(item?.payment_method ?? 0) },
      {
        label: '住所',
        content: `〒 ${item?.zip_code?.slice(0, 3) ?? ''}-${item?.zip_code?.slice(3, 7) ?? ''}
        ${item?.state ?? ''}${item?.city ?? ''}${item?.address ?? ''}${item?.address2 ?? ''}`,
      },
      { label: '電話番号', content: item?.phone },
      { label: 'メールアドレス(ID)', content: item?.email },
      { label: '営業時間', content: `${item?.open_time ?? '- '}～${item?.close_time || ' -'}` },
      { label: '駐車場', content: item?.parking },
      { label: '責任者氏名', content: item?.manager },
      { label: '責任者電話番号', content: item?.mgr_phone },
      { label: '事業者', content: mcoElement },
      { label: '業種', content: item?.biz_name },
      { label: 'ブランド', content: item?.brand_name },
      { label: 'またバイトしたい', content: item?.again },
      { label: '友達招待コード', content: item?.invite_code },
      { label: '総雇用回数', content: item?.total_adopt },
      { label: 'お気に入り登録数', content: item?.favorite_num },
      {
        label: '口座情報',
        content: `${item?.bank || ''} ${item?.branch_name || ''} ${item?.account_type || ''} ${
          item?.account_no || ''
        } ${item?.account_name || ''}`,
      },
      { label: '加盟日', content: item?.join_date },
      { label: '退会日', content: item?.quit_date },
      { label: '休止日', content: item?.pause_date },
      { label: '再開日', content: item?.restart_date },
      { label: '新規登録日', content: item?.created_date },
    ],
    [item, mcoElement]
  );

  return (
    <>
      {vTMemberBaseLoading && <Loading />}
      <Box px={16} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            基本情報
          </Text>
          <MainButton
            icon="edit"
            onClick={() =>
              item?.status === MemberStatus.Unregistered
                ? setActiveModalType(ModalType.EDIT_UNREGISTRATION)
                : setActiveModalType(ModalType.EDIT_REGISTRATION)
            }
          >
            編集
          </MainButton>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flexDirection="column" flex="1" py={16}>
          <ScrollWrapper bottom={244}>
            <Info items={infoItems} />
          </ScrollWrapper>
        </Box>
      </Box>
      {item !== undefined && (
        <EditUnregistrationModal
          isOpen={activeModalType === ModalType.EDIT_UNREGISTRATION}
          onClose={() => {
            setActiveModalType(ModalType.NONE);
          }}
          item={item}
          refetch={() => {
            getVtMemberBaseRecvNotifyByIdMutation();
          }}
        />
      )}
      {item !== undefined && (
        <EditRegistrationModal
          isOpen={activeModalType === ModalType.EDIT_REGISTRATION}
          onClose={() => setActiveModalType(ModalType.NONE)}
          item={item}
          id={id}
          refetch={() => {
            getVtMemberBaseRecvNotifyByIdMutation();
          }}
        />
      )}
    </>
  );
}
