/**
 * Figma ID: 07-02-02
 * 名称: 逆オファー（受け入れ募集内容フッター）
 */
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import React from 'react';

type AcceptRevercedOfferDetailFooterModel = {
  onClickClose: () => void;
};
function AcceptRevercedOfferDetailFooter(props: AcceptRevercedOfferDetailFooterModel) {
  const { onClickClose } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end">
      <MainButton onClick={onClickClose} width={104} variant="secondary" thin>
        閉じる
      </MainButton>
    </Box>
  );
}

export { AcceptRevercedOfferDetailFooter };
