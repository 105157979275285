import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { RadioGroup, Props as RadioProps } from '../radioGroup/radioGroup';

export type RhfInputProps<T extends FieldValues> = Omit<RadioProps, 'value'> & UseControllerProps<T>;

export function RhfRadioGroup<T extends FieldValues>(props: RhfInputProps<T>) {
  const { name, control, rules, defaultValue, onChange: customOnChange, ...otherProps } = props;
  const {
    field: { ref, onChange, ...rest },
  } = useController<T>({ name, control, rules, defaultValue });

  return (
    <RadioGroup
      {...rest}
      {...otherProps}
      onChange={(event) => {
        onChange(event);
        customOnChange?.(event);
      }}
    />
  );
}
