/**
 * Figma ID: 07-01-01, 07-01-02, 07-01-04, 07-01-07, 07-02-01, 07-03-01, 07-04-01
 * 名称: 逆オファー（ヘッダー）
 */
import { Header } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { ToggleButton } from 'components/toggleButton';
import { RevercedOfferStatusChip } from 'pages/s07/components/statusChip';
import React from 'react';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { GetVbOfferInfoByIdQuery } from 'graphql/graphql-ow';
import ChangeStatusModal from './ChangeStatusModal';

type OFHeaderModel = {
  offerData?: GetVbOfferInfoByIdQuery['getVBOfferInfoById'];
  refetch: () => void;
};
enum ActiveStatus {
  REVERCED_OFFER = 1,
  FORCE_STOP = 5,
}
function OFHeader(props: OFHeaderModel) {
  const { offerData, refetch } = props;
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const btmemActiveStatus = offerData?.status !== ActiveStatus.FORCE_STOP;
  return (
    <Header>
      <Box display="flex">
        <RevercedOfferStatusChip
          status={btmemActiveStatus ? offerData?.status ?? 0 : ActiveStatus.FORCE_STOP}
          variant="big"
          width={128}
        />
        <Box pl={8}>
          <Text color="darkBlue" variant="h1">
            {offerData?.offer_no} / {offerData?.btmem_name} / {offerData?.btmem_state}
          </Text>
        </Box>
      </Box>
      <Box>
        {(offerData?.status === ActiveStatus.REVERCED_OFFER || offerData?.status === ActiveStatus.FORCE_STOP) && (
          <ToggleButton
            currentValue={btmemActiveStatus ? 'active' : 'stopRecruitment'}
            onClick={() => setIsOpenModal(true)}
            options={[
              {
                label: '強制停止',
                value: 'stopRecruitment',
                activeColor: GENERIC_COLOR.CAUTION_RED,
              },
              {
                label: 'アクティブ',
                value: 'active',
              },
            ]}
          />
        )}
      </Box>
      <ChangeStatusModal
        offerId={offerData?.id ?? 0}
        btmemActiveStatus={btmemActiveStatus}
        isOpenModal={isOpenModal}
        onClickClose={() => setIsOpenModal(false)}
        refetch={() => {
          refetch();
        }}
      />
    </Header>
  );
}

export default OFHeader;
