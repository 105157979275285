import { Icon } from 'components/icon';
import React, { useMemo } from 'react';

function ChildCheckbox<T>(
  props: {
    tgt: T; isChecked: (tgt:T)=> boolean; toggleChecked: (tgt: T) => void 
  }): JSX.Element {
  const { tgt, isChecked, toggleChecked } = props;

  return isChecked(tgt) ? (
    <Icon name="checkboxChecked" onClick={() => toggleChecked(tgt)} />
  ) : (
    <Icon name="checkboxUnchecked" onClick={() => toggleChecked(tgt)} />
  );
}


function ParentCheckbox<T>(
  props: {
    checked: T[];
    canCheckItems: T[];
    allCheck: () => void;
    clearCheck: () => void;
  }
) {
  const { checked, allCheck, clearCheck, canCheckItems } = props;

  const isAllCheck = useMemo(
    () => 
      canCheckItems.filter((i) => checked.indexOf(i) === -1),
    [canCheckItems, checked]
  );

  const CheckBox = () => {
    let checkbox;
    if (checked.length === 0) 
      checkbox = <Icon name="checkboxUnchecked" onClick={() => allCheck()} />;
    else
      checkbox =
        isAllCheck.length === 0 ? (
          <Icon name="checkboxChecked" onClick={() => clearCheck()} />
        ) : (
          <Icon name="checkboxDisabled" onClick={() => allCheck()} />
        );

    return checkbox;
  };

  return (<CheckBox />);
}

export { ChildCheckbox, ParentCheckbox };
