import React, { useMemo, useRef, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { ScrollableArea } from 'components/scrollableArea';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import {
  useGetMBusinessLazyQuery,
  MBusiness,
  InMBrand,
  InMLicense,
  useGetMBusinessDataByIdLazyQuery,
  useAddMBusinessDataMutation,
  useUpdateMBusinessIconByIdMutation,
  useAddMBrandByBizIdMutation,
  useDelInsMLicenseByBizIdMutation,
  useAddMLicenseByBizIdMutation,
  useDelInsMSkillCheckByBizIdMutation,
} from 'graphql/graphql-ow';
import { GraphQLErrorCode, ResultRows } from 'components/const';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { useErrorModal } from 'components/error/errorModalProvider';
import { uploadFile } from 'components/api';

import { CHIP_TEXT, ListTitleWidth } from 'pages/s10-02/constants';
import { chipColor, leaderMenu } from 'pages/s10-02/s10-02-utils';
import { ConrirmModalStep, NewRegistrationModalStep, BusinessInfoSettingKey } from 'pages/s10-02/s10-02-types';
import { NewRegisterBusiness } from 'pages/s10-02/modal/new-register-business-setting-modal';
import { ConfirmEditBusiness } from 'pages/s10-02/modal/confirm-business-setting-modal';

/**
 * Figma ID: 10-02-11-01
 * 名称: 業種/ブランド管理
 */
interface ListProps {
  cpRow: (MBusiness | null)[];
  setConrirmEditModalStep: React.Dispatch<React.SetStateAction<ConrirmModalStep>>;
  setBusinessInfo: React.Dispatch<React.SetStateAction<MBusiness>>;
}

// 表示するリストデータを作成
const makeListData = ({ cpRow, setConrirmEditModalStep, setBusinessInfo }: ListProps) =>
  cpRow?.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              {row?.name}
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => {
                    setConrirmEditModalStep(ConrirmModalStep.CONFIRM);
                    setBusinessInfo(row as MBusiness);
                  }}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  確認/編集
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

// 10-02-11-01 業務/ブランド管理
export function BusinessBrandManagement() {
  const { openErrorModal } = useErrorModal();

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<BusinessInfoSettingKey>({ reValidateMode: 'onSubmit' });

  // ブランド追加情報
  const {
    fields: brandFields,
    append: brandAppend,
    remove: brandRemove,
  } = useFieldArray({
    control,
    name: 'brand',
  });
  // 業種追加情報
  const {
    fields: licenseFields,
    append: licenseAppend,
    remove: licenseRemove,
  } = useFieldArray({
    control,
    name: 'license',
  });
  const [brandContainerInitialized, setBrandContainerInitialized] = React.useState(false);

  // 入力項目デフォルト
  React.useEffect(() => {
    if (!brandContainerInitialized) {
      // 入力項目デフォルト登録
      brandAppend({ name: '', code: '' });
      setBrandContainerInitialized(true);
    }
  }, [brandContainerInitialized, brandAppend]);

  // 入力項目領域初期化
  const clearFields = React.useCallback(() => {
    // ブランド初期化
    brandFields.forEach((field, index) => {
      brandRemove(index);
    });
    // 入力項目デフォルト登録
    brandAppend({ name: '', code: '' });

    // 資格初期化
    licenseFields.forEach((field, index) => {
      licenseRemove(index);
    });
  }, [brandFields, licenseFields, brandAppend, brandRemove, licenseRemove]);

  const fileIconInputRef = useRef<HTMLInputElement>(null);
  const fileSkillSectionInputRef = useRef<HTMLInputElement>(null);
  const fileSkillCheckInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileIcon, setSelectedFileIcon] = useState<File | null>(null);
  const [selectedFileSkillSection, setSelectedFileSkillSection] = useState<File | null>(null);
  const [selectedFileSkillCheck, setSelectedFileSkillCheck] = useState<File | null>(null);
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [fileUpErrorStatus, setUpFileErrorStatus] = useState<boolean>(false);

  const [newBusinessModalStep, setNewBusinessModalStep] = useState<NewRegistrationModalStep>(
    NewRegistrationModalStep.NULL
  );
  const [confirmEditModalStep, setConrirmEditModalStep] = useState<ConrirmModalStep>(ConrirmModalStep.NULL);
  const [businessInfo, setBusinessInfo] = React.useState<MBusiness>({} as MBusiness);

  // 業種データ取得API呼出
  const [getMBusiness, { data: mBusinessData, loading: mBusinessLoading, refetch: mBusinessRefetch }] =
    useGetMBusinessLazyQuery({
      context: { clientName: 'master' },
    });
  const mBusiness = mBusinessData?.getMBusiness;
  React.useEffect(() => {
    if (mBusiness === undefined) {
      getMBusiness();
    }
  }, [mBusiness, getMBusiness]);

  // 業種IDに紐づく業種/ブランド情報取得API呼出
  const [
    getMBusinessDataById,
    { data: mBusinessDataById, loading: mBusinessDataByIdLoading, refetch: mBusinessDataByIdRefetch },
  ] = useGetMBusinessDataByIdLazyQuery({
    context: { clientName: 'master' },
    fetchPolicy: 'no-cache',
  });
  const mBusinessDataInfo = mBusinessDataById?.getMBusinessDataById;
  React.useEffect(() => {
    if (businessInfo.id) {
      getMBusinessDataById({
        variables: {
          id: businessInfo.id,
        },
      });
    }
  }, [businessInfo.id, getMBusinessDataById]);

  // 業種/ブランド情報追加API呼出
  const [addMBusinessData, { loading: addMBusinessDataLoading }] = useAddMBusinessDataMutation({
    context: { clientName: 'master' },
  });
  // アイコン変更API呼出
  const [updateMBusinessIconById, { loading: updateMBusinessIconByIdLoading }] = useUpdateMBusinessIconByIdMutation({
    context: { clientName: 'master' },
  });
  // ブランド情報追加API呼出
  const [addMBrandByBizId, { loading: addMBrandByBizIdLoading }] = useAddMBrandByBizIdMutation({
    context: { clientName: 'master' },
  });
  // 業種保有資格更新API呼出
  const [delInsMLicenseByBizId, { loading: delInsMLicenseByBizIdLoading }] = useDelInsMLicenseByBizIdMutation({
    context: { clientName: 'master' },
  });
  // 業種保有資格追加API呼出
  const [addMLicenseByBizId, { loading: addMLicenseByBizIdLoading }] = useAddMLicenseByBizIdMutation({
    context: { clientName: 'master' },
  });
  // スキルチェック更新API呼出
  const [delInsMSkillCheckByBizId, { loading: delInsMSkillCheckByBizIdLoading }] = useDelInsMSkillCheckByBizIdMutation({
    context: { clientName: 'master' },
  });

  // 業務保有資格の編集で使用するリストデータを作成
  const licenseListPrepare = React.useCallback(() => {
    if (mBusinessDataInfo === undefined || mBusinessDataInfo === null) return;
    if (mBusinessDataInfo.license === undefined || mBusinessDataInfo.license === null) return;
    licenseFields.forEach((field, index) => {
      licenseRemove(index);
    });
    if (mBusinessDataInfo?.existsStore === false && mBusinessDataInfo.license.length > 0) {
      mBusinessDataInfo.license?.map((item) => licenseAppend({ lcValue: item?.license_name || '' }));
    } else {
      licenseAppend({ lcValue: '' });
    }
  }, [mBusinessDataInfo, licenseAppend, licenseRemove, licenseFields]);

  const listItems = useMemo(() => {
    if (mBusinessLoading) return [];
    if (mBusiness === undefined) return [];
    return makeListData({
      cpRow: mBusiness || [],
      setConrirmEditModalStep,
      setBusinessInfo,
    });
  }, [mBusiness, mBusinessLoading, setConrirmEditModalStep]);

  const closeFunc = () => {
    reset();
    setSelectedFileIcon(null);
    setSelectedFileSkillSection(null);
    setSelectedFileSkillCheck(null);
    setUpFileErrorStatus(false);
    clearFields(); // Fields初期化 デフォルト入力項目
  };

  // ファイルアップロード処理
  const uploadBusinessFile = React.useCallback(
    async (selectedFile: File) => {
      try {
        const file = selectedFile;

        if (!file) return;

        // ファイルアップロード中はローディング表示
        setFileUploadLoading(true);
        // 拡張子チェック
        const fileExtension = file.name.split('.').pop();

        if (fileExtension?.toLowerCase() === 'csv') {
          // CSVファイルアップロード
          await uploadFile({ filePath: `temporary/${file.name}`, file });
        } else if (fileExtension?.toLowerCase() === 'png') {
          // iconファイルアップロード
          await uploadFile({ filePath: `icon/${file.name}`, file });
        }
      } catch (e) {
        setUpFileErrorStatus(true);
        openErrorModal({ message: 'ファイルアップロードに失敗しました。' });
      } finally {
        // ローディング解除
        setFileUploadLoading(false);
      }
    },
    [openErrorModal]
  );
  const errorModalMessage = (errorCode: GraphQLErrorCode | undefined | null) => {
    switch (errorCode) {
      case GraphQLErrorCode.BUSINESS_ADD_ERROR:
        return '業種情報でエラーが発生しました。';
      case GraphQLErrorCode.BRAND_ADD_ERROR:
        return 'ブランド情報でエラーが発生しました。';
      case GraphQLErrorCode.LICENSE_ADD_ERROR:
        return '保有資格情報でエラーが発生しました。';
      case GraphQLErrorCode.SKILL_SECTION_ADD_ERROR:
        return 'セクション情報でエラーが発生しました。';
      case GraphQLErrorCode.SKILL_CHECK_ADD_ERROR:
        return 'チェック項目でエラーが発生しました。';
      case GraphQLErrorCode.BUSINESS_DUPULICATION_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・業種名もしくは業種コードが重複しています。
          </Text>
        );
      case GraphQLErrorCode.BRAND_DUPULICATION_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・ブランド名もしくはブランドコードが重複しています。
          </Text>
        );
      case GraphQLErrorCode.LICENSE_DUPULICATION_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・保有資格名が重複しています。
          </Text>
        );
      case GraphQLErrorCode.SECTION_NO_DUPULICATION_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・スキルチェック（セクション）のNoが重複しています。
          </Text>
        );
      case GraphQLErrorCode.SKILL_CHECK_NO_DUPULICATION_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・スキルチェック（チェック項目）のNoが重複しています。
          </Text>
        );
      case GraphQLErrorCode.SECTION_NO_NOT_FOUND_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・未設定のセクションNoがあります。
          </Text>
        );
      case GraphQLErrorCode.SKILL_CHECK_INVALID_RANGE_ERROR:
        return (
          <Text variant="body14">
            以下の理由により登録できませんでした
            <br />
            ・会員ランク範囲または判定タイプ範囲が不整合です。
          </Text>
        );
      default:
        return '業種情報でエラーが発生しました。';
    }
  };

  // 新規登録モーダル
  const newBusinessdisplayModal = NewRegisterBusiness({
    newBusinessModalStep,
    setNewBusinessModalStep,
    onClose: () => {
      setBusinessInfo({} as MBusiness);
      setNewBusinessModalStep(NewRegistrationModalStep.NULL);
      closeFunc();
    },
    mutation: () => {
      uploadBusinessFile(selectedFileIcon as File);
      uploadBusinessFile(selectedFileSkillSection as File);
      uploadBusinessFile(selectedFileSkillCheck as File);
      if (fileUpErrorStatus) return;
      addMBusinessData({
        variables: {
          s3_bucket: `${process.env.REACT_APP_UPLOAD_S3_BUCKET || ''}`,
          name: getValues('bizName'),
          prefix: getValues('bizCode'),
          icon: `${selectedFileIcon?.name || ''}`,
          brands: getValues('brand').map(
            (item) =>
              ({
                brand_name: item.name,
                brand_prefix: item.code,
              } as InMBrand)
          ),
          licenses: getValues('license').map((item) => ({ license_name: item.lcValue } as InMLicense)),
          section_filepath: `temporary/${selectedFileSkillSection?.name || ''}`,
          checklist_filepath: `temporary/${selectedFileSkillCheck?.name || ''}`,
        },
      })
        .then((e) => {
          if (e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.SUCCESS) {
            setNewBusinessModalStep(NewRegistrationModalStep.COMPLETE);
          } else {
            setNewBusinessModalStep(NewRegistrationModalStep.NULL);
            if (
              e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.BUSINESS_ADD_ERROR ||
              e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.BRAND_ADD_ERROR ||
              e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.LICENSE_ADD_ERROR ||
              e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.SKILL_SECTION_ADD_ERROR ||
              e.data?.addMBusinessData?.ErrorCode === GraphQLErrorCode.SKILL_CHECK_ADD_ERROR
            ) {
              // 謎システムエラーは手の施しようが無いものは10-02-11-01に遷移
              setBusinessInfo({} as MBusiness);
              closeFunc();
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.addMBusinessData?.ErrorCode),
              });
            } else {
              // 理由が明確なものは10-02-11-04に戻って再トライ
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.addMBusinessData?.ErrorCode),
                onClose: () => {
                  setNewBusinessModalStep(NewRegistrationModalStep.CHECK);
                },
              });
            }
          }
        })
        .catch(() => {
          setBusinessInfo({} as MBusiness);
          setNewBusinessModalStep(NewRegistrationModalStep.NULL);
          closeFunc();
          openErrorModal({
            message: (
              <Text variant="body14">
                サーバーとの接続に失敗しました。
                <br />
                一時的にサーバーとの接続が不安定となっている可能性があります。
                <br />
                少し時間をおいてから再度お試しください。
              </Text>
            ),
          });
        });
    },
    refetch: () => {
      mBusinessRefetch();
    },
    control,
    getValues,
    errors,
    handleSubmit,
    brandFields,
    brandAppend,
    brandRemove,
    licenseFields,
    licenseAppend,
    licenseRemove,
    fileIconInputRef,
    fileSkillSectionInputRef,
    fileSkillCheckInputRef,
    selectedFileIcon,
    selectedFileSkillSection,
    selectedFileSkillCheck,
    setSelectedFileIcon,
    setSelectedFileSkillSection,
    setSelectedFileSkillCheck,
  });

  // 確認/編集モーダル
  const confirmEditBusinessdisplayModal = ConfirmEditBusiness({
    confirmEditModalStep,
    setConrirmEditModalStep,
    onClose: () => {
      setConrirmEditModalStep(ConrirmModalStep.NULL);
      setBusinessInfo({} as MBusiness);
      closeFunc();
    },
    onCancel: () => {
      setConrirmEditModalStep(ConrirmModalStep.CONFIRM);
      closeFunc();
    },
    control,
    getValues,
    handleSubmit,
    errors,
    brandFields,
    brandAppend,
    brandRemove,
    licenseFields,
    licenseAppend,
    licenseRemove,
    licenseListPrepare,
    businessInfo,
    mBusinessDataInfo,
    iconMutation: () => {
      uploadBusinessFile(selectedFileIcon as File);
      if (fileUpErrorStatus) return;
      updateMBusinessIconById({
        variables: {
          id: businessInfo.id,
          icon: `${selectedFileIcon?.name || ''}`,
        },
      })
        .then((e) => {
          switch (e.data?.updateMBusinessIconById?.ErrorCode) {
            case GraphQLErrorCode.SUCCESS:
              // ファイルを指定していない場合でも正常終了が返る。resultRows=1, ErrorCode=0
              setConrirmEditModalStep(ConrirmModalStep.ICON_UPDATE_COMPLETE);
              break;
            default:
              setBusinessInfo({} as MBusiness);
              setConrirmEditModalStep(ConrirmModalStep.NULL);
              closeFunc();
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.updateMBusinessIconById?.ErrorCode),
              });
              break;
          }
        })
        .catch(() => {
          setBusinessInfo({} as MBusiness);
          setConrirmEditModalStep(ConrirmModalStep.NULL);
          closeFunc();
          openErrorModal({
            message: (
              <Text variant="body14">
                サーバーとの接続に失敗しました。
                <br />
                一時的にサーバーとの接続が不安定となっている可能性があります。
                <br />
                少し時間をおいてから再度お試しください。
              </Text>
            ),
          });
        });
    },
    brandMutation: () => {
      addMBrandByBizId({
        variables: {
          biz_id: businessInfo.id,
          brands: getValues('brand').map(
            (item) =>
              ({
                brand_name: item.name,
                brand_prefix: item.code,
              } as InMBrand)
          ),
        },
      })
        .then((e) => {
          switch (e.data?.addMBrandByBizId?.ErrorCode) {
            case GraphQLErrorCode.SUCCESS:
              setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD_COMPLETE);
              break;
            case GraphQLErrorCode.BRAND_DUPULICATION_ERROR:
              setConrirmEditModalStep(ConrirmModalStep.NULL);
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.addMBrandByBizId?.ErrorCode),
                onClose: () => {
                  setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD_CHECK);
                },
              });
              break;
            default:
              setBusinessInfo({} as MBusiness);
              setConrirmEditModalStep(ConrirmModalStep.NULL);
              closeFunc();
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.addMBrandByBizId?.ErrorCode),
              });
              break;
          }
        })
        .catch(() => {
          setBusinessInfo({} as MBusiness);
          setConrirmEditModalStep(ConrirmModalStep.NULL);
          closeFunc();
          openErrorModal({
            message: (
              <Text variant="body14">
                サーバーとの接続に失敗しました。
                <br />
                一時的にサーバーとの接続が不安定となっている可能性があります。
                <br />
                少し時間をおいてから再度お試しください。
              </Text>
            ),
          });
        });
    },
    licenseMutation: () => {
      if (mBusinessDataInfo?.existsStore === false) {
        delInsMLicenseByBizId({
          variables: {
            biz_id: businessInfo.id,
            licenses: getValues('license').map((item) => ({ license_name: item.lcValue } as InMLicense)),
          },
        })
          .then((e) => {
            switch (e.data?.delInsMLicenseByBizId?.ErrorCode) {
              case GraphQLErrorCode.SUCCESS:
                setConrirmEditModalStep(ConrirmModalStep.BRAND_ADD_COMPLETE);
                break;
              case GraphQLErrorCode.LICENSE_DUPULICATION_ERROR:
                setConrirmEditModalStep(ConrirmModalStep.NULL);
                openErrorModal({
                  title: '登録できませんでした',
                  message: errorModalMessage(e.data?.delInsMLicenseByBizId?.ErrorCode),
                  onClose: () => {
                    setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_CHECK);
                  },
                });
                break;
              default:
                setBusinessInfo({} as MBusiness);
                setConrirmEditModalStep(ConrirmModalStep.NULL);
                closeFunc();
                openErrorModal({
                  title: '登録できませんでした',
                  message: errorModalMessage(e.data?.delInsMLicenseByBizId?.ErrorCode),
                });
                break;
            }
          })
          .catch(() => {
            setBusinessInfo({} as MBusiness);
            setConrirmEditModalStep(ConrirmModalStep.NULL);
            closeFunc();
            openErrorModal({
              message: (
                <Text variant="body14">
                  サーバーとの接続に失敗しました。
                  <br />
                  一時的にサーバーとの接続が不安定となっている可能性があります。
                  <br />
                  少し時間をおいてから再度お試しください。
                </Text>
              ),
            });
          });
      } else {
        addMLicenseByBizId({
          variables: {
            biz_id: businessInfo.id,
            licenses: getValues('license').map((item) => ({ license_name: item.lcValue } as InMLicense)),
          },
        })
          .then((e) => {
            switch (e.data?.addMLicenseByBizId?.ErrorCode) {
              case GraphQLErrorCode.SUCCESS:
                setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_COMPLETE);
                break;
              case GraphQLErrorCode.LICENSE_DUPULICATION_ERROR:
                setConrirmEditModalStep(ConrirmModalStep.NULL);
                openErrorModal({
                  title: '登録できませんでした',
                  message: errorModalMessage(e.data?.addMLicenseByBizId?.ErrorCode),
                  onClose: () => {
                    setConrirmEditModalStep(ConrirmModalStep.LICENSE_ADD_CHECK);
                  },
                });
                break;
              default:
                setBusinessInfo({} as MBusiness);
                setConrirmEditModalStep(ConrirmModalStep.NULL);
                closeFunc();
                openErrorModal({
                  title: '登録できませんでした',
                  message: errorModalMessage(e.data?.addMLicenseByBizId?.ErrorCode),
                });
                break;
            }
          })
          .catch(() => {
            setBusinessInfo({} as MBusiness);
            setConrirmEditModalStep(ConrirmModalStep.NULL);
            closeFunc();
            openErrorModal({
              message: (
                <Text variant="body14">
                  サーバーとの接続に失敗しました。
                  <br />
                  一時的にサーバーとの接続が不安定となっている可能性があります。
                  <br />
                  少し時間をおいてから再度お試しください。
                </Text>
              ),
            });
          });
      }
    },
    skillCheckMutation: () => {
      uploadBusinessFile(selectedFileSkillSection as File);
      uploadBusinessFile(selectedFileSkillCheck as File);
      if (fileUpErrorStatus) return;
      delInsMSkillCheckByBizId({
        variables: {
          biz_id: businessInfo.id,
          s3_bucket: `${process.env.REACT_APP_UPLOAD_S3_BUCKET || ''}`,
          section_filepath: `temporary/${selectedFileSkillSection?.name || ''}`,
          checklist_filepath: `temporary/${selectedFileSkillCheck?.name || ''}`,
        },
      })
        .then((e) => {
          if (
            e.data?.delInsMSkillCheckByBizId?.ErrorCode === GraphQLErrorCode.SUCCESS &&
            e.data?.delInsMSkillCheckByBizId?.resultRows !== ResultRows.NOT_UPDATED
          ) {
            // 正常に更新された場合は、更新した行数且つ、正常が返却される
            setConrirmEditModalStep(ConrirmModalStep.SKILL_ADD_COMPLETE);
          } else {
            setConrirmEditModalStep(ConrirmModalStep.NULL);
            if (
              e.data?.delInsMSkillCheckByBizId?.ErrorCode === GraphQLErrorCode.SKILL_SECTION_ADD_ERROR ||
              e.data?.delInsMSkillCheckByBizId?.ErrorCode === GraphQLErrorCode.SKILL_CHECK_ADD_ERROR
            ) {
              setBusinessInfo({} as MBusiness);
              closeFunc();
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.delInsMSkillCheckByBizId?.ErrorCode),
              });
            } else {
              openErrorModal({
                title: '登録できませんでした',
                message: errorModalMessage(e.data?.delInsMSkillCheckByBizId?.ErrorCode),
                onClose: () => {
                  setConrirmEditModalStep(ConrirmModalStep.SKILL_ADD_CHECK);
                },
              });
            }
          }
        })
        .catch(() => {
          setBusinessInfo({} as MBusiness);
          setConrirmEditModalStep(ConrirmModalStep.NULL);
          closeFunc();
          openErrorModal({
            message: (
              <Text variant="body14">
                サーバーとの接続に失敗しました。
                <br />
                一時的にサーバーとの接続が不安定となっている可能性があります。
                <br />
                少し時間をおいてから再度お試しください。
              </Text>
            ),
          });
        });
    },
    mBusinessDataByIdRefetch: () => {
      mBusinessDataByIdRefetch();
    },
    fileIconInputRef,
    fileSkillSectionInputRef,
    fileSkillCheckInputRef,
    selectedFileIcon,
    selectedFileSkillSection,
    selectedFileSkillCheck,
    setSelectedFileIcon,
    setSelectedFileSkillSection,
    setSelectedFileSkillCheck,
  });

  // graphql APIのローディング中はローディング表示
  if (
    mBusinessLoading ||
    mBusinessDataByIdLoading ||
    addMBusinessDataLoading ||
    updateMBusinessIconByIdLoading ||
    addMBrandByBizIdLoading ||
    delInsMLicenseByBizIdLoading ||
    addMLicenseByBizIdLoading ||
    delInsMSkillCheckByBizIdLoading ||
    fileUploadLoading
  ) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                業種/ブランド管理
              </Text>
              <Text color="darkBlue" variant="caption12">
                加盟店の業種とブランドを管理
              </Text>
            </Box>
            <div>
              <MainButton
                onClick={() => {
                  licenseAppend({ lcValue: '' });
                  setNewBusinessModalStep(NewRegistrationModalStep.INPUT);
                }}
                variant="primary"
                width={160}
                icon="addCircle"
              >
                新規登録
              </MainButton>
            </div>
          </Box>
          <Divider option="horizontal" />
          <ScrollWrapper bottom={260}>
            <ScrollableArea>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollableArea>
          </ScrollWrapper>
        </Box>
      </HideWrapper>
      {/* 新規登録 */}
      <Modal
        height={newBusinessdisplayModal.height}
        width={newBusinessdisplayModal.width}
        isOpen={newBusinessModalStep !== NewRegistrationModalStep.NULL}
        content={newBusinessdisplayModal.content}
        header={newBusinessdisplayModal.header}
        footer={newBusinessdisplayModal.footer}
        onClose={() => setNewBusinessModalStep(NewRegistrationModalStep.NULL)}
        overflow="visible"
      />
      {/* 確認/編集 */}
      <Modal
        width={confirmEditBusinessdisplayModal.width}
        height={confirmEditBusinessdisplayModal.height}
        isOpen={confirmEditModalStep !== ConrirmModalStep.NULL}
        onClose={() => setNewBusinessModalStep(NewRegistrationModalStep.NULL)}
        header={confirmEditBusinessdisplayModal.header}
        content={confirmEditBusinessdisplayModal.content}
        footer={confirmEditBusinessdisplayModal.footer}
        overflow="visible"
      />
    </>
  );
}
