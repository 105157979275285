import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { EditProfileModalStep, SelectProfileDataKey, BtmemEditProfileType } from 'pages/s05/constants';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from 'components/errorMessage';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { DatePicker } from 'components/datePicker';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import _ from 'lodash';
import { getYear } from 'date-fns';

/**
 * Figma ID: 05-09-02 05-09-03 05-09-04
 * 名称: プロファイル編集 保存 完了
 */

interface Props {
  editProfileModalStep: EditProfileModalStep;
  title: {
    edit: string;
    save: string;
    complete: string;
  };
  defaultValues?: BtmemEditProfileType;
  initialBirthday?: Date;
  onClose: () => void;
  editProfileHandler: () => void;
}
export function EditProfileModal({
  editProfileModalStep,
  title,
  defaultValues,
  initialBirthday,
  onClose,
  editProfileHandler,
}: Props) {
  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectProfileDataKey>({ defaultValues: { ...defaultValues }, reValidateMode: 'onSubmit' });
  const { openErrorModal } = useErrorModal();
  const [selectedBirthDate, setSelectedBirthDate] = React.useState<Date | null>(null);
  const [updateProfile, { loading }] = gql.useUpdateBMemberDataByIdMutation();

  const onClickHandler = handleSubmit(() => {
    editProfileHandler();
  });

  useEffect(() => {
    if (!defaultValues) {
      return;
    }
    if (editProfileModalStep === EditProfileModalStep.EDIT) {
      setValue('gender', defaultValues.gender);
      setValue('dob', defaultValues.dob);
    }
  }, [defaultValues, setValue, editProfileModalStep]);

  const birthDayInput = useCallback(
    (date: Date | null) => {
      if (!date) return;
      const tempDate = new Date(date.toDateString().replace(/年|月/g, '-').replace('日', ''));
      setSelectedBirthDate(tempDate);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      setValue('dob', `${String(date.getFullYear())}年${month}月${day}日`);
    },
    [setValue]
  );

  const mutation = useCallback(() => {
    const birthDate = getValues('dob').trim().replace(/年|月/g, '/').replace('日', '').replace(/\s+/g, '');
    updateProfile({
      variables: {
        first_name: getValues('last_name'),
        last_name: getValues('first_name'),
        first_kana: getValues('last_kana'),
        last_kana: getValues('first_kana'),
        gender: getValues('gender') === '男' ? 0 : 1,
        dob: birthDate,
        id: Number(defaultValues?.id || 0),
      },
    })
      .then((e) => {
        switch (e.data?.updateBMemberDataById?.resultRows) {
          case ResultRows.UPDATED:
            editProfileHandler();
            break;
          default:
            onClose();
            reset();
            openErrorModal({ message: '更新に失敗しました' });
            break;
        }
      })
      .catch((e) => {
        reset();
        onClose();
        openErrorModal({ message: '更新に失敗しました' });
      });
  }, [getValues, updateProfile, defaultValues?.id, editProfileHandler, onClose, reset, openErrorModal]);

  const displayModal = React.useMemo(() => {
    switch (editProfileModalStep) {
      case EditProfileModalStep.EDIT:
        return {
          width: 752,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {title.edit}
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" gap={8}>
              <Box display="flex" flexDirection="column" gap={4} pb={8}>
                <Box>
                  <InputLabel>氏名</InputLabel>
                </Box>
                <Box display="flex" flexDirection="row" gap={16}>
                  <div>
                    <RhfInput
                      name="first_name"
                      control={control}
                      defaultValue={defaultValues?.first_name || ''}
                      placeholder="苗字を入力"
                      rules={{ required: '必須項目を入力' }}
                      width={120}
                    />
                    {errors?.first_name && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.first_name.message}</ErrorMessage>
                      </Box>
                    )}
                  </div>
                  <div>
                    <RhfInput
                      name="last_name"
                      control={control}
                      defaultValue={defaultValues?.last_name || ''}
                      placeholder="名前を入力"
                      rules={{ required: '必須項目を入力' }}
                      width={120}
                    />
                    {errors?.last_name && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.last_name.message}</ErrorMessage>
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={4} pb={8}>
                <Box>
                  <InputLabel>氏名（フリガナ）</InputLabel>
                </Box>
                <Box display="flex" flexDirection="row" gap={16}>
                  <div>
                    <RhfInput
                      name="first_kana"
                      control={control}
                      defaultValue={defaultValues?.first_kana || ''}
                      placeholder="苗字（カナ）を入力"
                      rules={{ required: '必須項目を入力' }}
                      width={120}
                    />
                    {errors?.first_kana && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.first_kana.message}</ErrorMessage>
                      </Box>
                    )}
                  </div>
                  <div>
                    <RhfInput
                      name="last_kana"
                      control={control}
                      defaultValue={defaultValues?.last_kana || ''}
                      placeholder="名前（カナ）を入力"
                      rules={{ required: '必須項目を入力' }}
                      width={120}
                    />
                    {errors?.last_kana && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.last_kana.message}</ErrorMessage>
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={16} pb={8}>
                <Box>
                  <InputLabel>性別</InputLabel>
                </Box>
                <RhfRadioGroup
                  name="gender"
                  options={[
                    { label: '男性', value: '男' },
                    { label: '女性', value: '女' },
                  ]}
                  control={control}
                  rules={{ required: '必須項目を入力' }}
                  defaultValue={defaultValues?.gender || ''}
                />
                {errors?.gender && (
                  <Box pt={4}>
                    <ErrorMessage>{errors?.gender.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" gap={16} pb={8}>
                <Box>
                  <InputLabel>生年月日</InputLabel>
                </Box>
                <Box>
                  <DatePicker
                    width={160}
                    placeholderText="生年月日"
                    selected={selectedBirthDate !== null ? selectedBirthDate : initialBirthday}
                    onChange={(value) => birthDayInput(value)}
                    yearRange={_.range(getYear(new Date()) - 80, getYear(new Date()) - 20, 1)}
                  />
                </Box>
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  reset();
                  onClose();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  onClickHandler();
                }}
                variant="primary"
                width={104}
              >
                保存
              </MainButton>
            </Box>
          ),
          onClose,
        };
      case EditProfileModalStep.SAVE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {title.save}
            </Text>
          ),
          content: (
            <Box>
              <Box>
                <Text variant="body14">
                  本当に情報を保存しますか？
                  <br />
                  バイトラベラーの基本情報が新しい情報に上書きされます。
                </Text>
              </Box>
              <Box>
                <Text variant="body14">
                  ・{getValues('first_name')} {getValues('last_name')}
                </Text>
              </Box>
              <Box>
                <Text variant="body14">
                  ・{getValues('first_kana')} {getValues('last_kana')}
                </Text>
              </Box>
              <Box>
                <Text variant="body14">・{getValues('gender')}性</Text>
              </Box>
              <Box>
                <Text variant="body14">・{getValues('dob').trim().replace(/\s+/g, '')}</Text>
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              {loading && <Loading />}
              <MainButton
                thin
                onClick={() => {
                  reset();
                  onClose();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton thin onClick={mutation} variant="primary" width={104}>
                保存
              </MainButton>
            </Box>
          ),
          onClose,
        };

      case EditProfileModalStep.COMPLETE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {title.complete}
            </Text>
          ),
          content: <Text variant="body14">情報の保存が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  reset();
                  onClose();
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
          onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose,
        };
    }
  }, [
    editProfileModalStep,
    onClose,
    reset,
    title.complete,
    title.edit,
    title.save,
    defaultValues,
    errors,
    control,
    getValues,
    birthDayInput,
    selectedBirthDate,
    initialBirthday,
    mutation,
    loading,
    onClickHandler,
  ]);
  return displayModal;
}
