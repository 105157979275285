/**
 * Figma ID: 01-01-01
 * 名称: ログイン
 */
import React, { useEffect } from 'react';
import { Box } from 'components/box';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { InputMailAndPassword } from './signIn/InputMailAndPassword';
import { InputMfaToken } from './signIn/InputMfaToken';

type SignInPhage = 'InputMailAndPassword' | 'InputMfaToken';

export function SignIn() {
  const [singInPhage, setSingInPhage] = React.useState<SignInPhage>('InputMailAndPassword');
  const navigate = useNavigate();
  const { isAuthenticated, signIn, signInWithMfaToken } = useAuth();
  const { openErrorModal } = useErrorModal();
  // 2段階認証の時の使う
  const [email, setEmail] = React.useState<string>('');

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ pathname: '/' });
    }
  }, [isAuthenticated, navigate]);

  const handleSignIn = async (args: { email: string; password: string }) => {
    try {
      const challengeName = await signIn(args.email, args.password);
      if (challengeName === 'SOFTWARE_TOKEN_MFA') {
        setSingInPhage('InputMfaToken');
        setEmail(args.email);
        return;
      }
    } catch (error) {
      openErrorModal({ message: 'ログインに失敗しました' });
    }
  };

  const handleSignInWithMfaToken = async (token: string) => {
    try {
      await signInWithMfaToken(token, email);
    } catch (error) {
      openErrorModal({ message: '2段階認証に失敗しました' });
    }
  };

  const content = () => {
    switch (singInPhage) {
      case 'InputMfaToken':
        return <InputMfaToken onSignInWithMfaToken={handleSignInWithMfaToken} />;
      default:
        return <InputMailAndPassword onSignIn={handleSignIn} />;
    }
  };

  return (
    <Box width="100vw" height="100vh" justifyContent="center">
      {content()}
    </Box>
  );
}
