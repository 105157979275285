/**
 * Figma ID: 10-11-01
 * 名称: GMOあおぞら連携
 */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import Checked from 'components/assets/images/checked.png';
import Caution from 'components/assets/images/caution.svg';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import axios from 'axios';

import { useGetAuthorizationQuery, useUpdateAuthorizationMutation } from 'graphql/graphql-ow';

type AuthGmoGetAuthorizationType = {
  location: string;
};

export default function S1011() {
  const location = useLocation();

  const { openErrorModal } = useErrorModal();

  const [isLoading, setIsLoading] = React.useState(false);

  const { data, refetch } = useGetAuthorizationQuery();

  const [updateAuthorizationMutation] = useUpdateAuthorizationMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search.includes('code')) {
      const code = queryParams.get('code');
      if (code) updateAuthorizationMutation({ variables: { code } });
    } else if (location.search.includes('error_description')) {
      const errorDescription = queryParams.get('error_description');
      openErrorModal({
        message: errorDescription,
      });
    }
  }, [location.search, openErrorModal, updateAuthorizationMutation]);

  const onAuthGmoGetAuthorization = () => {
    setIsLoading(true);
    const headers = {
      'x-api-key': process.env.REACT_APP_API_KEY,
    };

    const body = {
      fieldName: 'authGmoGetAuthorization',
    };
    axios
      .post(process.env.REACT_APP_UTIL_URL || '', body, {
        headers,
      })
      .then((res) => {
        setIsLoading(false);
        const response = res.data as AuthGmoGetAuthorizationType;
        if (response.location) {
          window.location.href = response.location;
        }
      });
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box display="flex" flexDirection="column" gap={26}>
        <Text variant="h1" color="darkBlue">
          GMOあおぞら連携
        </Text>
        <Box height="calc(100vh - 152px)">
          <Card overflow="hidden">
            <Box pa={16}>
              <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8} height={60}>
                <Text color="darkBlue" variant="h2">
                  連携状況
                </Text>
                <Text color="darkBlue" variant="caption12">
                  現在のGMOあおぞらとの連携状況を表示します。未練系の場合、「連携する」ボタンを押して連携を行なってください。
                </Text>
              </Box>
              <Divider option="horizontal" />
              {data?.getAuthorization?.statusCode === 2 ? (
                <Box display="flex" alignItems="center" gap={10} pt={24}>
                  <img src={Checked} alt="連携済み" height={24} width={24} />
                  連携済み
                </Box>
              ) : data?.getAuthorization?.statusCode === 1 ? (
                <Box display="grid" gap={24}>
                  <Box display="flex" alignItems="center" gap={10} pt={24}>
                    <img src={Caution} alt="連携済み" height={24} width={24} />
                    未連携
                  </Box>
                  <MainButton width={160} variant="primary" disabled>
                    連携する
                  </MainButton>
                  <Text color="cautionRed" variant="caption12">
                    連携依頼済みです。GMOからAPI連携開始の案内がありますので、インタ―ネットバンキングにログインして許可操作を行ってください。
                  </Text>
                </Box>
              ) : (
                <Box display="grid" gap={24}>
                  <Box display="flex" alignItems="center" gap={10} pt={24}>
                    <img src={Caution} alt="連携済み" height={24} width={24} />
                    未連携
                  </Box>
                  <MainButton
                    width={160}
                    onClick={() => {
                      onAuthGmoGetAuthorization();
                    }}
                    variant="primary"
                  >
                    連携する
                  </MainButton>
                </Box>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}
