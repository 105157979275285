import axios, { AxiosResponse } from 'axios';
import { Amplify } from 'aws-amplify';
import { PDFDocument, PDFFont } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { Buffer } from 'buffer';
import { splitPdfString, drawAllLine, isOverTextWidth } from './util';

Amplify.configure({
  region: process.env.REACT_APP_AUTH_REGION,
  identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
});

// パラメータタイプ
export type WsPdfProps = {
  year?: number;
  address?: string;
  nameKana?: string;
  name?: string;
  paymentPrice?: number;
  withholdingPrice?: number;
  payerAddress?: string;
  payerName?: string;
  payerPhone?: string;
};

// 文字列を指定した文字数で分割した配列で返す
const splitTextReverseArray = (text: string, splitCount: number) => {
  const result = [];

  for (let charCount = text.length; charCount > 0; charCount -= splitCount) {
    if (splitCount >= charCount) {
      // 文字数
      const part = text.substr(0, Math.max(charCount, 0));
      result.unshift(part);
      break;
    }

    // 文字数分取得
    const part = text.substr(Math.max(charCount - splitCount, 0), splitCount);
    // 前の配列に加算
    result.unshift(part);
  }

  // 逆順にする
  result.reverse();
  return result;
};

// 金額項目の印字位置を取得する
// 3桁右詰めした印字位置を取得する
const getPricePosition = (data: string, baseX: number, customFont: PDFFont, fontSize: number) => {
  // 金額が空の場合はそのまま返す
  if (!data) return baseX;

  // 数値1文字のフォントサイズを取得
  const customTextWidth = customFont.widthOfTextAtSize('1', fontSize);

  // 調整する桁数を取得
  // 3桁分右詰めする為に3から文字数を引く
  const adjustLength = 3 - data.length;

  // 金額の印字位置を取得する
  // X座標から3桁右詰めした印字位置を取得する
  const pricePosition = baseX + adjustLength * customTextWidth;
  return pricePosition;
};

// CloudFrontパス設定
const CloudFrontURL = process.env.REACT_APP_PDF_CLOUDFRONT_URL || '';
const FontPath = process.env.REACT_APP_PDF_FONT || '';
const TemplatePath = process.env.REACT_APP_WS_PDF_TEMPLATE || '';

/**
 * 源泉徴収票PDF作成
 *
 * @param props パラメータ
 * @returns PDFのバッファを返す
 *          失敗したらnullを返す
 */
export default async function createWithholdingSlipPdf(props: WsPdfProps): Promise<Buffer | null> {
  const { year, address, nameKana, name, paymentPrice, withholdingPrice, payerAddress, payerName, payerPhone } = props;

  // CloudFront経由でPDFテンプレートとフォントを取得
  const templateURL = `${CloudFrontURL}/${TemplatePath}`;
  const fontURL = `${CloudFrontURL}/${FontPath}`;

  // データ取得
  async function fetchArrayBuffer(url: string): Promise<ArrayBuffer> {
    try {
      const response: AxiosResponse<Blob> = await axios.get(url, { responseType: 'blob' });
      const blob = response.data;
      const arrayBuffer = await blob.arrayBuffer();
      return arrayBuffer;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Axios特有のエラーハンドリング
        console.log('Axios error occurred while fetching: ', url, ' :', error.message);
        throw new Error(`Axios error occurred while fetching ${url}: ${error.message}`);
      } else if (error instanceof Error) {
        // エラーハンドリング
        console.log('Failed to fetch: ', url, ' :', error.message);
        throw new Error(`Failed to fetch ${url}: ${error.message}`);
      } else {
        // その他のエラー
        console.log('An unknown error occurred while fetching: ', url);
        throw new Error(`An unknown error occurred while fetching ${url}`);
      }
    }
  }
  try {
    // PDF Font取得
    const [templateBuffer, fontBuffer] = await Promise.all([
      fetchArrayBuffer(`${CloudFrontURL}/${TemplatePath}`),
      fetchArrayBuffer(`${CloudFrontURL}/${FontPath}`),
    ]);

    // PDFデータをロード
    const pdfDoc = await PDFDocument.load(templateBuffer);

    // ページ情報を読み込み
    const pages = pdfDoc.getPages();
    // カスタムフォントを使用する準備を行う
    pdfDoc.registerFontkit(fontkit);

    // デフォルトフォントサイズ
    const fontSize = 10;
    // フォント情報
    const customFont = await pdfDoc.embedFont(fontBuffer, { subset: true });
    // PDFページ
    const page = pages[0];

    //----------------
    // 年度
    //----------------
    if (year !== undefined) {
      const date = new Date(`${year}/01/01`);
      const dtf = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'short' });
      const warekiParts = dtf.formatToParts(date);
      const warekiYear = warekiParts[1].value;
      page.drawText((' '.repeat(2) + String(warekiYear)).slice(-2), {
        x: 551,
        y: 567,
        font: customFont,
        size: fontSize,
      });
    }

    //----------------
    // 住所
    //----------------
    if (address) {
      const addressTextWidth = 190;
      const addressLineHeight = 12;

      // 住所をテキスト幅で分割する
      const addressSplitArray = splitPdfString(customFont, fontSize, address, addressTextWidth);
      // 全行印字する
      drawAllLine(addressSplitArray, addressLineHeight, 478, 545, page, customFont, fontSize);
    }

    //----------------
    // 氏名
    //----------------
    if (name) {
      page.drawText(name, { x: 685, y: 502, font: customFont, size: 7 });
    }

    //----------------
    // 氏名カナ
    //----------------
    if (nameKana) {
      const kanaTextWidth = 130;
      let kanaFont = 7.5;
      let kanaY = 514.5;
      const kanaineHeight = 4;
      // カナ氏名がテキスト幅を超えているかチェックする
      const isKanaTextWidthOver = isOverTextWidth(customFont, kanaFont, nameKana, kanaTextWidth);
      // テキスト幅を超えている場合はフォントサイズを小さくして印字位置を変更する
      if (isKanaTextWidthOver === true) {
        kanaFont = 5;
        kanaY = 516.5;
      }
      // カナ氏名をテキスト幅で分割する
      const kanaSplitArray = splitPdfString(customFont, kanaFont, nameKana, kanaTextWidth);
      // カナ氏名を全行印字する
      drawAllLine(kanaSplitArray, kanaineHeight, 706, kanaY, page, customFont, kanaFont);
    }

    //----------------
    // 種別
    //----------------
    page.drawText('給与・賞与', { x: 453, y: 472, font: customFont, size: fontSize });

    // ----------------
    // 支払金額
    // ----------------
    if (paymentPrice !== undefined) {
      // 支払金額を3桁区切りにする
      const paymentPriceArray = splitTextReverseArray(String(paymentPrice), 3);
      if (paymentPriceArray.length >= 3)
        page.drawText(paymentPriceArray[2], {
          x: getPricePosition(paymentPriceArray[2], 524, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
      if (paymentPriceArray.length >= 2)
        page.drawText(paymentPriceArray[1], {
          x: getPricePosition(paymentPriceArray[1], 553, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
      if (paymentPriceArray.length >= 1)
        page.drawText(paymentPriceArray[0], {
          x: getPricePosition(paymentPriceArray[0], 581, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
    }

    //----------------
    // 源泉徴収税額
    //----------------
    if (withholdingPrice !== undefined) {
      // 源泉徴収税額を3桁区切りにする
      const withholdingPriceArray = splitTextReverseArray(String(withholdingPrice), 3);
      if (withholdingPriceArray.length >= 3)
        page.drawText(withholdingPriceArray[2], {
          x: getPricePosition(withholdingPriceArray[2], 760, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
      if (withholdingPriceArray.length >= 2)
        page.drawText(withholdingPriceArray[1], {
          x: getPricePosition(withholdingPriceArray[1], 787, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
      if (withholdingPriceArray.length >= 1)
        page.drawText(withholdingPriceArray[0], {
          x: getPricePosition(withholdingPriceArray[0], 815, customFont, fontSize),
          y: 470,
          font: customFont,
          size: fontSize,
        });
    }

    // ----------------
    // 支払者住所
    // ----------------
    if (payerAddress) {
      const payerAddressTextWidth = 330;
      const payerAddressLineHeight = 10;

      let payerAddressY = 46;
      let payerAddressFontSize = fontSize;

      // 住所がテキスト幅を超えているかチェックする
      const isPayerAddressTextWidthOver = isOverTextWidth(
        customFont,
        payerAddressFontSize,
        payerAddress,
        payerAddressTextWidth
      );
      // テキスト幅を超えている場合はフォントサイズを小さくして印字位置を変更する
      if (isPayerAddressTextWidthOver === true) {
        payerAddressFontSize = 8;
        payerAddressY = 51;
      }

      // 住所をテキスト幅で分割する
      const payerAddressSplitArray = splitPdfString(
        customFont,
        payerAddressFontSize,
        payerAddress,
        payerAddressTextWidth
      );
      // 全行印字する
      drawAllLine(
        payerAddressSplitArray,
        payerAddressLineHeight,
        504,
        payerAddressY,
        page,
        customFont,
        payerAddressFontSize
      );
    }

    // ----------------
    // 支払者名
    // ----------------
    if (payerName) {
      page.drawText(payerName, { x: 504, y: 28, font: customFont, size: fontSize });
    }

    // ----------------
    // 支払者電話番号
    // ----------------
    if (payerPhone) {
      page.drawText(payerPhone, { x: 724, y: 27, font: customFont, size: fontSize });
    }

    // PDFデータを取得
    const pdfBytes = await pdfDoc.save();

    return Buffer.from(pdfBytes);
  } catch (error) {
    console.error('An error occurred while creating the PDF:', error);
    return null;
  }
}
