/**
 * Figma ID: 10-08-03-04
 * 名称: 固定特典設定(設定)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

export function CompleteGiftHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        設定 完了
      </Text>
    </Box>
  );
}

export function CompleteGiftContent() {
  return (
    <div style={{ padding: '9px 0px' }}>
      <Text variant="body14">情報の設定が完了しました。</Text>
    </div>
  );
}

type CompleteFooterModel = {
  onClickClose: () => void;
};
export function CompleteGiftFooter(props: CompleteFooterModel) {
  const { onClickClose } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        閉じる
      </MainButton>
    </Box>
  );
}
