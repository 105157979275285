export const CHIP_TEXT = {
  Before: '実施前',
  Completed: '実施済',
  End: '終了',
  BeforeGrant: '付与前',
  Granted: '付与済',
};

export const COLORS_NAME = {
  Yellow: 'warningYellow',
  BLUE: 'okBlue',
  Gray: 'liteGray',
};

export const RECRUIT_RATIO_OPTIONS = [
  { label: '0', value: '0' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
  { label: '60', value: '60' },
  { label: '70', value: '70' },
  { label: '80', value: '80' },
  { label: '90', value: '90' },
  { label: '100', value: '100' },
];

export const BONUS_PER_OPTIONS = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '40', value: '40' },
  { label: '50', value: '50' },
  { label: '60', value: '60' },
  { label: '70', value: '70' },
  { label: '80', value: '80' },
  { label: '90', value: '90' },
  { label: '100', value: '100' },
];

export const RADIUS_OPTIONS = [
  { label: '0', value: '0' },
  { label: '0.5', value: '0.5' },
  { label: '1', value: '1' },
  { label: '1.5', value: '1.5' },
  { label: '2', value: '2' },
  { label: '2.5', value: '2.5' },
  { label: '3', value: '3' },
];

export enum DeleteModalStep {
  NULL = 0,
  CHECK = 1,
  COMPLETE = 2,
}

export enum ModalStep {
  NULL = 0,
  INPUT = 1,
  CHECK = 2,
  COMPLETE = 3,
}

export enum NewUpdateModalStep {
  NULL = 0,
  INPUT = 1,
  REGION = 2,
  CHECK = 3,
  COMPLETE = 4,
}

export enum InputType {
  Input = 'input',
  Select = 'select',
}

export enum FormatDateType {
  Numeric = 'numeric',
  Digit = '2-digit',
}

export enum DateTime {
  BeginDateTime = '00:00:00',
  EndDateTime = '23:59:59',
}

export enum InclResidentDisp {
  TARGET = 'キャンペーンの対象にする',
  NOTARGET = 'キャンペーンの対象にしない',
}

export enum ListTitleWidth {
  NarrowWidth = 160,
  WideWidth = 256,
}
