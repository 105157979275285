/**
 * Figma ID: 10-01-02-03
 * 名称: ユーザー招待 新規登録
 */
import React from 'react';
import { passwordPattern, passwordLength } from 'components/const';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';

type FormValues = {
  mail: string;
  password: string;
  confirmPassword: string;
};

type Props = {
  queryStringEmail: string | null;
  onSubmitEmailAndPassword: (mail: string, password: string) => Promise<void>;
};

export default function RegisterMailAndPassword({ queryStringEmail, onSubmitEmailAndPassword }: Props) {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });
  const passwordWatch = watch('password');

  const onSubmit = handleSubmit((data) => {
    onSubmitEmailAndPassword(data.mail, data.password);
  });

  return (
    <Box width={328} display="flex" flexDirection="column" gap={24} px={14}>
      <Text variant="body14">ログイン用のメールアドレスとパスワードを設定してください。</Text>
      <Box display="flex" flexDirection="column" gap={8}>
        <Box height={78}>
          <Box pb={4}>
            <InputLabel>メールアドレス</InputLabel>
          </Box>
          {queryStringEmail ? (
            <Text color="darkGray" variant="body14">
              {queryStringEmail}
            </Text>
          ) : (
            <>
              <RhfInput
                placeholder="メールアドレス"
                name="mail"
                control={control}
                rules={{
                  required: 'メールアドレスを入力してください。',
                  pattern: {
                    value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                    message: '入力形式がメールアドレスではありません。',
                  },
                }}
              />
              {errors.mail?.message && <Text color="cautionRed" variant="caption12">{`※${errors.mail.message}`}</Text>}
            </>
          )}
        </Box>
        <Box height={78}>
          <Box pb={4}>
            <InputLabel>パスワード</InputLabel>
          </Box>
          <RhfInput
            placeholder="パスワード"
            type="password"
            name="password"
            control={control}
            rules={{
              validate: (value) => passwordPattern.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
              minLength: { value: passwordLength, message: '8文字以上で入力してください' },
            }}
          />
          {errors.password?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.password.message}`}</Text>
          )}
        </Box>
        <Box height={78}>
          <Box pb={4}>
            <InputLabel>パスワード（確認）</InputLabel>
          </Box>
          <RhfInput
            type="password"
            placeholder="パスワード（確認）"
            name="confirmPassword"
            control={control}
            rules={{ validate: (value) => value === passwordWatch || 'パスワードが一致しません。' }}
          />
          {errors.confirmPassword?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.confirmPassword.message}`}</Text>
          )}
        </Box>
        <Text color="cautionRed" variant="caption12">
          パスワードは8文字以上で、1つ以上の数字・特殊文字 (^ $ * . [ ] {`{ }`} ( ) ? - &quot; ! @ # % & / \ , &gt; &lt;
          &prime; : ; | _ ~ ` + =) ・アルファベットの大文字と小文字を含む必要があります。
        </Text>
      </Box>
      <Box display="flex" justifyContent="center">
        <MainButton
          disabled={!isValid}
          width={160}
          onClick={() => {
            onSubmit();
          }}
        >
          送信
        </MainButton>
      </Box>
    </Box>
  );
}
