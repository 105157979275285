import React, { useMemo } from 'react';
import { Modal } from 'components/newModal';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Link as RouterLink } from 'react-router-dom';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { Avatar } from 'components/avatar';
import { Divider } from 'components/newDivider';
import { VtMemberBase } from 'graphql/graphql-ow';

type Props = {
  isOpen: boolean;
  vtMemberBase: VtMemberBase;
  onClickClose: () => void;
};

/**
 * 加盟店用モーダルダイアログ
 */
// Figma管理番号
// 11-01-05
export function StoreModal({ isOpen, vtMemberBase, onClickClose }: Props) {
  const address = useMemo<string>(
    () => `${
      vtMemberBase.zip_code
        ? `〒${vtMemberBase?.zip_code?.slice(0, 3) ?? ''}-${vtMemberBase?.zip_code?.slice(3, 7) ?? ''}`
        : ''
    }
          ${vtMemberBase.state ? `${vtMemberBase.state} ` : ''}
          ${vtMemberBase.city ? `${vtMemberBase.city} ` : ''}
          ${vtMemberBase.address ? `${vtMemberBase.address} ` : ''}
          ${vtMemberBase.address2 ? `${vtMemberBase.address2} ` : ''}`,
    [vtMemberBase]
  );

  const bankInfo = useMemo<string>(
    () =>
      `${vtMemberBase.bank ? `${vtMemberBase.bank} ` : ''}
      ${vtMemberBase.branch_name ? `${vtMemberBase.branch_name} ` : ''}
      ${vtMemberBase.account_type ? `${vtMemberBase.account_type} ` : ''}
      ${vtMemberBase.account_no ? `${vtMemberBase.account_no} ` : ''}
      ${vtMemberBase.account_name ? `${vtMemberBase.account_name} ` : ''}`,
    [vtMemberBase]
  );

  const mcoItems = [
    {
      label: '法人番号',
      content: vtMemberBase.corporate_no,
    },
    {
      label: '電話番号',
      content: vtMemberBase.mco_phone,
    },
    {
      label: '代表者',
      content: vtMemberBase.mco_m_name,
    },
    {
      label: '',
      content: vtMemberBase.mco_m_kana,
    },
    {
      label: '担当者',
      content: vtMemberBase.mco_t_name,
    },
    {
      label: '',
      content: vtMemberBase.mco_t_kana,
    },
    {
      label: '担当者電話番号',
      content: vtMemberBase.mco_t_phone,
    },
  ];
  const managedCompany = () => (
    <>
      <Box pb={8}>
        <Text variant="body14">{vtMemberBase.mco_name}</Text>
      </Box>
      <Box pb={8}>
        <Text variant="body14">
          {`${
            vtMemberBase.mco_zip_code
              ? `〒${vtMemberBase?.mco_zip_code?.slice(0, 3) ?? ''}-${vtMemberBase?.mco_zip_code?.slice(3, 7) ?? ''} `
              : ''
          }
          ${vtMemberBase.mco_state ? `${vtMemberBase.mco_state} ` : ''}
          ${vtMemberBase.mco_city ? `${vtMemberBase.mco_city} ` : ''}
          ${vtMemberBase.mco_address ? `${vtMemberBase.mco_address} ` : ''}
          ${vtMemberBase.mco_address2 ? `${vtMemberBase.mco_address2} ` : ''}`}
        </Text>
      </Box>
      {mcoItems.map((val) => (
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex">
            <Box width={112}>
              <Text variant="body14" color="darkGray">
                {val.label}
              </Text>
            </Box>
            <Box>
              <Text variant="body14">{val.content}</Text>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      header={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text variant="h2" color="darkBlue">
            {vtMemberBase.store_name}
          </Text>
          <RouterLink to={`/s03/${vtMemberBase.id}`} style={{ textDecoration: 'none' }}>
            <MainButton width={74} variant="secondary" thin>
              詳しく
            </MainButton>
          </RouterLink>
        </Box>
      }
      content={
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" alignItems="center" gap={24}>
            <Avatar src={vtMemberBase.image || ''} width={90} height={60} />
            <Text variant="h1" color="darkBlue">
              {vtMemberBase.store_name}
            </Text>
          </Box>
          <Divider option="horizontal" />
          <Info
            items={[
              {
                label: '店舗コード',
                content: vtMemberBase.code,
              },
              {
                label: '店舗名',
                content: vtMemberBase.store_name,
              },
              {
                label: '住所',
                content: address,
              },
              {
                label: '電話番号',
                content: vtMemberBase.phone,
              },
              {
                label: 'メールアドレス(ID)',
                content: vtMemberBase.email,
              },
              {
                label: '営業時間',
                content: vtMemberBase.opening,
              },
              {
                label: '駐車場',
                content: vtMemberBase.parking,
              },
              {
                label: '責任者氏名',
                content: vtMemberBase.manager,
              },
              {
                label: '責任者電話番号',
                content: vtMemberBase.mgr_phone,
              },
              {
                label: '事業者',
                content: managedCompany(),
              },
              {
                label: '業種',
                content: vtMemberBase.biz_name,
              },
              {
                label: 'ブランド',
                content: vtMemberBase.brand_name,
              },
              {
                label: 'またバイトしたい',
                content: vtMemberBase.again,
              },
              {
                label: '友達招待コード',
                content: vtMemberBase.invite_code,
              },
              {
                label: '総雇用回数',
                content: vtMemberBase.total_adopt,
              },
              {
                label: 'お気に入り登録数',
                content: vtMemberBase.favorite_num,
              },
              {
                label: '口座情報',
                content: bankInfo,
              },
              {
                label: '加盟日',
                content: vtMemberBase.join_date,
              },
              {
                label: '退会日',
                content: vtMemberBase.quit_date,
              },
              {
                label: '休止日',
                content: vtMemberBase.pause_date,
              },
              {
                label: '再開日',
                content: vtMemberBase.restart_date,
              },
              {
                label: '新規登録日',
                content: vtMemberBase.created_date,
              },
            ]}
          />
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end">
          <MainButton onClick={onClickClose} width={104} variant="secondary" thin>
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      width={1003}
      height="calc(100vh - 100px)"
    />
  );
}
