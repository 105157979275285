/**
 * Figma ID: 10-08-01-02
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import Divider from 'components/divider/divider';

type UpdateDeadlineContentModel = {
  message: string;
  selectedGift?: string;
};
function UpdateDeadlineContent(props: UpdateDeadlineContentModel) {
  const { message, selectedGift } = props;
  return (
    <Box>
      <Box pb={8}>
        <Text variant="body14">{message}</Text>
      </Box>
      <Box pb={8}>
        <Text variant="caption12" color="liteGray">
          選択したギフト
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box py={8}>
        <Text variant="body14">{selectedGift}</Text>
      </Box>
      <Divider option="horizontal" />
    </Box>
  );
}

export default UpdateDeadlineContent;
