/**
 * Figma ID: 10-08-01-04, 10-08-01-05
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import type { UpdateDeadlinePhase } from '../GiftUpdate';

type ConfirmUpdateDeadlineHeaderModel = {
  updateDeadlinePhase: UpdateDeadlinePhase;
};
function ConfirmUpdateDeadlineHeader(props: ConfirmUpdateDeadlineHeaderModel) {
  const { updateDeadlinePhase } = props;
  const headerTitle = () => {
    switch (updateDeadlinePhase) {
      case 'confirmUpdateData':
        return '更新 確認';
      default:
        return '更新 完了';
    }
  };
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Text variant="h2" color="darkBlue">
        {headerTitle()}
      </Text>
    </Box>
  );
}

export default ConfirmUpdateDeadlineHeader;
