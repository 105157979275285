import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo } from 'react';
import { InputLabel } from 'components/inputLabel';
import { Divider } from 'components/newDivider';
import * as gql from 'graphql/graphql-ow';
import { styled } from '@linaria/react';
import { useForm } from 'react-hook-form';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { ErrorMessage } from 'components/errorMessage';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { InclResidentDisp, NewUpdateModalStep } from '../constants';
import { arraysAreEqual, checkAllTrue, formViewStateValues, removeNullValues } from '../s10-03-utils';
import { RegionModalKey } from '../s10-03-types';

/**
 * Figma ID:10-03-06-03,10-03-06-09,10-03-08-03,10-03-08-09
 * 名称: 地域モーダル
 */
interface Props {
  title: string;
  stateIds: string[];
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setModalData: (e: string[]) => void;
  setRadioBotton: (value: boolean, label: string) => void;
  onClose: () => void;
  getMAreaState?: Array<gql.MAreaState | null> | null;
  setBrand?: boolean;
  inclResident?: boolean;
}

const StyledChildCheckboxWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  margin-top: 8px;
  padding: 0 16px;
`;

export function RegionModal({
  title,
  stateIds,
  setModalStep,
  setModalData,
  setRadioBotton,
  onClose,
  getMAreaState,
  setBrand,
  inclResident,
}: Props) {
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegionModalKey>({ reValidateMode: 'onSubmit' });

  useEffect(() => {
    if (inclResident) {
      setValue('incl_resident', `${String(inclResident)}`);
    } else if (inclResident === false) {
      setValue('incl_resident', `${String(inclResident)}`);
    } else {
      setValue('incl_resident', '');
    }
  }, [inclResident, setValue]);

  const radioHandleCheck = useMemo(
    () => (incl_resident: string) => {
      setRadioBotton(
        incl_resident === 'true',
        incl_resident === 'true' ? InclResidentDisp.TARGET : InclResidentDisp.NOTARGET
      );
    },
    [setRadioBotton]
  );

  const onClickHandler = handleSubmit((event) => {
    if (event) {
      radioHandleCheck(event.incl_resident || '');
      setModalStep(NewUpdateModalStep.CHECK);
    }
  });

  const dataFind = useMemo(() => {
    const checkBoxGroupData = getMAreaState
      ? getMAreaState?.map((AreaStates) => ({
          label: AreaStates?.area_name ? AreaStates?.area_name : '',
          options: AreaStates?.state
            ? AreaStates?.state?.map((state) => ({
                label: state?.state_name || '',
                value: String(state?.state_id),
              }))
            : [],
        }))
      : [];

    return { checkBoxGroupData };
  }, [getMAreaState]);

  const stateValues = useMemo(() => formViewStateValues(stateIds, getMAreaState), [getMAreaState, stateIds]);

  useEffect(() => {
    if (stateValues) {
      setValue('state_ids.views', stateValues || []);
      setValue('state_ids.ids', stateValues || []);
      stateValues?.forEach((value, index) => {
        setValue(
          `state_ids.views.${index}.value`,
          arraysAreEqual(value.optionValues, dataFind.checkBoxGroupData?.[index].options || [])
        );
      });
    }
  }, [dataFind.checkBoxGroupData, getMAreaState, setValue, stateValues, reset]);

  const setFormDateForModalData = useCallback(() => {
    const flatValues = getValues(`state_ids.ids`)
      .flat()
      .flatMap((value) => value.optionValues);
    const ids = removeNullValues(flatValues);
    setModalData(ids);
  }, [getValues, setModalData]);

  const displayRegionModal = React.useMemo(
    () => ({
      width: 800,
      height: 'auto',
      header: (
        <Text variant="h2" color="darkBlue">
          {title}
        </Text>
      ),
      content: (
        <Box>
          {setBrand ? (
            <Box width="100%">
              <Box width="100%" pb={11} pt={10}>
                <InputLabel>該当地域の在住者をキャンペーンの対象にする</InputLabel>
              </Box>

              <Box width="100%" pb={21}>
                <RhfRadioGroup
                  name="incl_resident"
                  control={control}
                  options={[
                    {
                      label: 'する',
                      value: 'true',
                    },
                    {
                      label: 'しない',
                      value: 'false',
                    },
                  ]}
                  rules={{ required: '該当地域の在住者をキャンペーンを対象にするか選択してください' }}
                  defaultValue={getValues('incl_resident')}
                />
              </Box>
              {errors?.incl_resident && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.incl_resident?.message}</ErrorMessage>
                </Box>
              )}
            </Box>
          ) : null}
          <Box width="100%" pb={17}>
            <Divider option="horizontal" />
          </Box>
          {dataFind.checkBoxGroupData?.map((item, index) => (
            <>
              <RhfCheckbox
                name={`state_ids.views.${index}.value`}
                control={control}
                onChange={(event) => {
                  item.options.forEach((option, optionIndex) => {
                    if (event.target.checked) {
                      setValue(`state_ids.views.${index}.optionValues.${optionIndex}`, event.target.checked);
                      setValue(`state_ids.ids.${index}.optionValues.${optionIndex}`, item.options[optionIndex].value);
                    } else {
                      setValue(`state_ids.views.${index}.optionValues.${optionIndex}`, null);
                      setValue(`state_ids.ids.${index}.optionValues.${optionIndex}`, null);
                    }
                    setFormDateForModalData();
                  });
                }}
              >
                <Text variant="h3" color="blueGray">
                  {item.label}
                </Text>
              </RhfCheckbox>
              <StyledChildCheckboxWrapper>
                {item.options.map((option, optionIndex) => (
                  <RhfCheckbox
                    name={`state_ids.views.${index}.optionValues.${optionIndex}`}
                    defaultValue={getValues(`state_ids.views.${index}.optionValues.${optionIndex}`)}
                    control={control}
                    rules={{
                      validate: (_, values) => {
                        let isAtLeastOneChecked = false;
                        values.state_ids?.views.forEach((brand) => {
                          brand.optionValues.forEach((value) => {
                            if (value) {
                              isAtLeastOneChecked = true;
                            }
                          });
                        });
                        return isAtLeastOneChecked || '地域をチェックしてください';
                      },
                    }}
                    onChange={(event) => {
                      const optionValues = checkAllTrue(getValues(`state_ids.views.${index}.optionValues`), true);
                      setValue(`state_ids.views.${index}.value`, optionValues);
                      setValue(
                        `state_ids.ids.${index}.optionValues.${optionIndex}`,
                        event.target.checked ? option.value : null
                      );
                      setFormDateForModalData();
                    }}
                  >
                    {option.label}
                  </RhfCheckbox>
                ))}
              </StyledChildCheckboxWrapper>
            </>
          ))}
          {errors?.state_ids && (
            <Box pt={4}>
              <ErrorMessage>地域をチェックしてください</ErrorMessage>
            </Box>
          )}
        </Box>
      ),
      footer: (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton
            thin
            onClick={() => {
              onClose();
            }}
            variant="secondary"
            width={104}
          >
            キャンセル
          </MainButton>
          <MainButton
            thin
            onClick={() => {
              setModalStep(NewUpdateModalStep.INPUT);
            }}
            variant="secondary"
            width={104}
          >
            戻る
          </MainButton>
          <MainButton
            thin
            onClick={(event) => {
              onClickHandler(event);
            }}
            variant="primary"
            width={104}
          >
            次へ
          </MainButton>
        </Box>
      ),
      onClose: () => {
        onClose();
        reset();
      },
      reset,
    }),
    [
      title,
      setBrand,
      dataFind.checkBoxGroupData,
      errors,
      control,
      setFormDateForModalData,
      setValue,
      getValues,
      onClose,
      onClickHandler,
      setModalStep,
      reset,
    ]
  );
  return { displayRegionModal };
}
