import React from 'react';
import { URecvNotify } from 'graphql/graphql-ow';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { colorPalette } from 'components/text/text';
import { Icon } from 'components/icon';
import { styled } from '@linaria/react';

export type ListProps = {
  children: React.ReactNode;
  item: URecvNotify;
  onClickRow: () => void;
};

const StyledSpan = styled.span`
  &:hover {
    color: ${colorPalette.blue};
  }
`;

function ListText(props: { children: React.ReactNode; onClickRow: () => void }) {
  const { children, onClickRow } = props;

  return (
    <div role="button" style={{ cursor: 'pointer' }} tabIndex={0} onClick={() => onClickRow()} onKeyDown={() => {}}>
      <Text variant="body14">
        <StyledSpan>{children}</StyledSpan>
      </Text>
    </div>
  );
}

// 通知一覧の各行
export function ListRow(props: ListProps) {
  const { children, item, onClickRow } = props;

  const hasAlert = !item.is_read ?? false;

  return hasAlert ? (
    <Box display="flex" flexDirection="row" gap={8} alignItems="center">
      <Icon name="indicatorRed" size={8} />
      <ListText onClickRow={onClickRow}>{children}</ListText>
    </Box>
  ) : (
    <div>
      <ListText onClickRow={onClickRow}>{children}</ListText>
    </div>
  );
}
