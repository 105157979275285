import { styled } from '@linaria/react';

const Vertical = styled.div<{
  height: number
}>`
  width: 1px;
  height: ${({height})=> height===0 ? '100%' : `${height}px`};

  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
  background: #DBDBDB;
`;

const Horizontal = styled.div<{
  width: number
}>`
  width: ${({width})=> width===0 ? '100%' : `${width}px`};
  height: 1px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #DBDBDB;
`;



export { Vertical, Horizontal };