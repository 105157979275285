import { Box } from 'components/box';
import { Card } from 'components/card';
import { Tab } from 'components/newTab';
import { Text } from 'components/text';
import React, { useContext } from 'react';
import { ImInvoice, MnPayment } from './details';
import { NewlyNotificationContext, NewlyNotificationContextProvider } from './components/newlyNotificationContext';
import { NewlyNotification } from './const';

/**
 * Figma ID: 08-01
 * 名称: バイト費用支払
 */

function Content() {
  const { newlyNotification } = useContext(NewlyNotificationContext);
  const { im_inv: imInv, mn_pay: mnPay } = newlyNotification;

  // 出力No
  const [menuIndex, setMenuIndex] = React.useState<number>(0);

  return (
    <Box display="flex" flex="1" flexDirection="column" gap={26} height="100%">
      <Text variant="h1" color="darkBlue">
        バイト代支払
      </Text>
      <Box flex="1">
        <Card overflow="hidden">
          <Box display="flex" height="100%" py={16}>
            <Tab
              items={[
                {
                  label: '即日支払',
                  content: (
                    <ImInvoice
                      setMenuIndex={(num: number) => setMenuIndex(num)}
                      transitionPayment={() => {
                        setMenuIndex(1);
                      }}
                    />
                  ),
                  hasNotification: imInv === NewlyNotification.TRUE,
                },
                {
                  label: '月次支払',
                  content: <MnPayment />,
                  hasNotification: mnPay === NewlyNotification.TRUE,
                },
              ]}
              defaultIndex={menuIndex}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export function S0801() {
  return (
    <NewlyNotificationContextProvider>
      <Content />
    </NewlyNotificationContextProvider>
  );
}
