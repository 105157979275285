import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { ModalRet, VtMemberListCreateKey } from 'pages/s03/type';
import React, { useEffect } from 'react';
import { Control, UseFormGetValues, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import {
  useGetMStateQuery,
  useGetMBrandByIdLazyQuery,
  useGetMBusinessByIdLazyQuery,
  useGetMCityByStateIdLazyQuery,
  MState,
  MCity,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ErrorMessage } from 'components/errorMessage';
import { ScrollableArea } from 'components/scrollableArea';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

const ConfirmText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #484848;
`;

export function EnterStoreInfoModal(
  control: Control<VtMemberListCreateKey>,
  getValues: UseFormGetValues<VtMemberListCreateKey>,
  handleSubmit: UseFormHandleSubmit<VtMemberListCreateKey>,
  errors: FieldErrors<VtMemberListCreateKey>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const currentState = useWatch({ control, name: 'state' });
  const currentBrand = useWatch({ control, name: 'brand' });
  const currentBusiness = useWatch({ control, name: 'business' });
  const { openErrorModal } = useErrorModal();
  const {
    data: mStateData,
    loading: mStateLoading,
    error: mStateError,
  } = useGetMStateQuery({
    context: { clientName: 'master' },
  });
  const [getMBrandById, { data: mBrandByIdData, loading: mBrandByIdLoading, error: mBrandByIdError }] =
    useGetMBrandByIdLazyQuery({
      context: { clientName: 'master' },
    });
  const [getMBusinessById, { data: mBusiessByIdData, loading: mBusinessByIdLoading, error: mBusinessByIdError }] =
    useGetMBusinessByIdLazyQuery({
      context: { clientName: 'master' },
    });
  const [getMCityByStateId, { data: mCityByStateIdData, loading: mCityByStateIdLoading, error: mCityByStateIdError }] =
    useGetMCityByStateIdLazyQuery({
      context: { clientName: 'master' },
    });

  const zipCode1Length = 3;
  const zipCode2Length = 4;

  const mState = mStateData?.getMState as MState[];
  const mStateList =
    mState?.map((item) => ({
      value: String(item.id),
      label: item.name,
    })) || [];

  useEffect(() => {
    if (currentBusiness === undefined || currentBusiness === null) return;
    getMBusinessById({
      variables: { mid: Number(currentBusiness) },
    });
  }, [currentBusiness, getMBusinessById]);
  useEffect(() => {
    if (currentBrand === undefined || currentBrand === null) return;
    getMBrandById({
      variables: { mid: Number(currentBrand) },
    });
  }, [currentBrand, getMBrandById]);

  useEffect(() => {
    if (currentState !== undefined && currentState !== '' && currentState !== null) {
      getMCityByStateId({
        variables: { sid: Number(currentState) },
      });
    }
  }, [currentState, getMCityByStateId]);
  let mCityList = [] as { label: string; value: string }[];
  const mCity = mCityByStateIdData?.getMCityByStateId as MCity[];
  if (mCity === undefined) {
    mCityList = [{ label: '', value: '' }];
  } else {
    mCityList =
      mCity.map((item) => ({
        label: item.name,
        value: String(item.id),
      })) || [];
  }

  useEffect(() => {
    if (mStateError || mBrandByIdError || mBusinessByIdError || mCityByStateIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [mStateError, mBrandByIdError, mBusinessByIdError, mCityByStateIdError, openErrorModal]);

  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = mBusiessByIdData?.getMBusinessById?.name ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = mBrandByIdData?.getMBrandById?.name ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      if (brandName === '' || bizName === '') {
        onClose();
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          zIndex: ModalZindex.ERRMODALZINDEX,
        });
      } else {
        onNext();
      }
    }
  });

  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        新規登録 店舗情報
      </Text>
    ),
    content: (
      <Box display="flex" flexDirection="row" alignItems="center">
        {(mStateLoading || mBrandByIdLoading || mBusinessByIdLoading || mCityByStateIdLoading) && <Loading />}
        <ScrollableArea>
          <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
            <Text variant="body14">店舗情報を入力して「次へ」を押してください。</Text>
            <Box display="flex" flexDirection="row" gap={13}>
              <Box display="flex" flexDirection="column" width={328} gap={10}>
                <Text variant="h3" color="blueGray">
                  業種
                </Text>
                <ConfirmText>{editBizName}</ConfirmText>
              </Box>
              <Box display="flex" flexDirection="column" width={328} gap={10}>
                <Text variant="h3" color="blueGray">
                  ブランド
                </Text>
                <ConfirmText>{editBrandName}</ConfirmText>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                店舗名
              </Text>
              <RhfInput
                name="storeName"
                control={control}
                fullWidth
                defaultValue=""
                placeholder="店舗名"
                rules={{ required: '店舗名を入力してください' }}
              />
              {errors?.storeName && <ErrorMessage>{errors?.storeName.message}</ErrorMessage>}
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                郵便番号
              </Text>
              <Box display="flex" flexDirection="row" gap={16} alignItems="center">
                <Box>
                  <RhfInput
                    name="zipCode1"
                    control={control}
                    width={120}
                    defaultValue=""
                    placeholder=""
                    type="number"
                    rules={{
                      required: '郵便番号を入力してください',
                      validate: (value) =>
                        String(value).length === zipCode1Length || `${zipCode1Length}桁の数字を入力してください`,
                    }}
                  />
                </Box>
                <Divider option="horizontal" length={7} />
                <Box>
                  <RhfInput
                    name="zipCode2"
                    control={control}
                    width={120}
                    defaultValue=""
                    placeholder=""
                    type="number"
                    rules={{
                      required: '郵便番号を入力してください',
                      validate: (value) =>
                        String(value).length === zipCode2Length || `${zipCode2Length}桁の数字を入力してください`,
                    }}
                  />
                </Box>
              </Box>
              {(errors?.zipCode1 || errors.zipCode2) && (
                <Box pt={4}>
                  <ErrorMessage>{errors.zipCode1?.message ?? errors.zipCode2?.message}</ErrorMessage>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                住所
              </Text>
              <Box display="flex" flexDirection="column" gap={18}>
                <Box display="flex" flexDirection="row" gap={16}>
                  <Box>
                    {' '}
                    <RhfSelect
                      width={328}
                      control={control}
                      name="state"
                      options={mStateList}
                      defaultValue=""
                      placeholder="都道府県"
                      rules={{ required: '都道府県を選択してください' }}
                    />
                    {errors?.state && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.state.message}</ErrorMessage>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {getValues('state') !== '' && (
                      <RhfSelect
                        width={328}
                        control={control}
                        name="city"
                        options={mCityList}
                        defaultValue=""
                        placeholder="市区町村"
                        rules={{ required: '市区町村を選択してください' }}
                      />
                    )}
                    {errors?.city && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.city.message}</ErrorMessage>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" gap={16}>
                  <Box>
                    <RhfInput
                      name="address1"
                      control={control}
                      width={328}
                      defaultValue=""
                      placeholder="番地等"
                      rules={{ required: '番地等を入力してください' }}
                    />
                    {errors?.address1 && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.address1.message}</ErrorMessage>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <RhfInput
                      name="address2"
                      control={control}
                      width={328}
                      defaultValue=""
                      placeholder="建物名・部屋番号"
                    />
                    {errors?.address2 && (
                      <Box pt={4}>
                        <ErrorMessage>{errors?.address2.message}</ErrorMessage>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5}>
              <Text variant="h3" color="blueGray">
                電話番号
              </Text>
              <RhfInput
                name="phone"
                control={control}
                width={328}
                placeholder="電話番号"
                type="number"
                rules={{
                  required: '電話番号（数字のみ）を入力してください',
                  pattern: {
                    value: /^\d*$/,
                    message: '数字のみを入力してください',
                  },
                }}
              />
              {errors?.phone && <ErrorMessage>{errors?.phone.message}</ErrorMessage>}
            </Box>
            <Box display="flex" flexDirection="row" gap={16}>
              <Box display="flex" flexDirection="column" gap={5.5}>
                <Text variant="h3" color="blueGray">
                  緯度
                </Text>
                <RhfInput
                  name="lat"
                  control={control}
                  width={328}
                  placeholder="緯度"
                  type="number"
                  rules={{
                    required: '緯度を入力してください',
                    max: {
                      value: 90,
                      message: '90以下の数字を入力してください。',
                    },
                    min: {
                      value: -90,
                      message: '-90以上の数字を入力してください。',
                    },
                  }}
                />
                {errors?.lat && <ErrorMessage>{errors?.lat.message}</ErrorMessage>}
              </Box>
              <Box display="flex" flexDirection="column" gap={5.5}>
                <Text variant="h3" color="blueGray">
                  経度
                </Text>
                <RhfInput
                  name="lng"
                  control={control}
                  width={328}
                  placeholder="経度"
                  type="number"
                  rules={{
                    required: '経度を入力してください',
                    max: {
                      value: 180,
                      message: '180以下の数字を入力してください。',
                    },
                    min: {
                      value: -180,
                      message: '-180以上の数字を入力してください。',
                    },
                  }}
                />
                {errors?.lng && <ErrorMessage>{errors?.lng.message}</ErrorMessage>}
              </Box>
            </Box>
          </Box>
        </ScrollableArea>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={(data) => {
            onClickHandler(data);
          }}
        >
          次へ
        </MainButton>
      </Box>
    ),
    width: 800,
    height: 723,
  };

  return {
    ...modalContent,
  };
}
