import React, { createContext, useState } from 'react';

export type Notification = {
  re_pay: number;
};

type NewlyNtificationContextProps = {
  newlyNotification: Notification;
  setNewlyNotification: (newlyNotification: Notification) => void;
};

const defaultContext: NewlyNtificationContextProps = {
  newlyNotification: {
    re_pay: 0,
  },
  setNewlyNotification: () => {},
};

export const NewlyNotificationContext = createContext<NewlyNtificationContextProps>(defaultContext);

export const useNewlyNotification = (): NewlyNtificationContextProps => {
  const [newlyNotification, setNewlyNotification] = useState<Notification>({
    re_pay: 0,
  });

  return {
    newlyNotification,
    setNewlyNotification,
  };
};

export function NewlyNotificationContextProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const value = useNewlyNotification();

  return <NewlyNotificationContext.Provider value={value}>{children}</NewlyNotificationContext.Provider>;
}
