import React from 'react';
import { styled } from '@linaria/react';
import { Icon } from '../icon';

export interface Props {
  rate: number;
  size?: number;
  monotone?: boolean;
}

const StyledDiv = styled.div<{ monotone: boolean }>`
  box-sizing: border-box;
  display: flex;
  column-gap: ${({ monotone }) => (monotone ? '0px' : '2px')};
`;

export function Stars({ rate, size = 24, monotone = false }: Props) {
  const displayNumberOfStar = (): number => Math.trunc(rate);
  const displayNumberOfStarBorder = (): number => 5 - Math.ceil(rate);
  const isDisplayHalfStar = (): boolean => rate - Math.trunc(rate) > 0;

  // 加盟店管理のサイドバーにくらいにしか使わない
  if (monotone) {
    return (
      <StyledDiv monotone={monotone}>
        {Array.from(new Array(displayNumberOfStar())).map(() => (
          <Icon name="star" color="#5F797D" size={size} />
        ))}
        {isDisplayHalfStar() && <Icon name="starHalf" color="#5F797D" size={size} />}
        {Array.from(new Array(displayNumberOfStarBorder())).map(() => (
          <Icon name="starBorder" color="#5F797D" size={size} />
        ))}
      </StyledDiv>
    );
  }

  return (
    <StyledDiv monotone={monotone}>
      {Array.from(new Array(displayNumberOfStar())).map(() => (
        <Icon name="star" size={size} />
      ))}
      {isDisplayHalfStar() && <Icon name="starHalf" size={size} />}
      {Array.from(new Array(displayNumberOfStarBorder())).map(() => (
        <Icon name="starBorder" size={size} />
      ))}
    </StyledDiv>
  );
}
