/* eslint-disable react/jsx-props-no-spreading  */
import React from 'react';
import { styled } from '@linaria/react';
import ReactDatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { getYear, getMonth, format } from 'date-fns';
import _ from 'lodash';
import './custompicker.css';
import { Box } from 'components/box';
import { GENERIC_COLOR, LABEL_COLOR } from '../assets/css/style';
import { Icon } from '../icon';

export type Props = ReactDatePickerProps & {
  width?: number;
  yearRange?: number[];
  minDate?: Date | null;
  maxDate?: Date | null;
};

const StyledInputWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledInput = styled.input<{ width: number }>`
  min-width: ${({ width }) => width}px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: ${GENERIC_COLOR.WHITE};
  border: 1px solid ${GENERIC_COLOR.SUPERLITE_GRAY};
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${GENERIC_COLOR.BLACK};
  cursor: pointer;
  outline: none;
  &:focus {
    border: 1px solid ${LABEL_COLOR.BLUE};
  }
  ::placeholder {
    color: ${GENERIC_COLOR.LITE_GRAY};
  }
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
`;

const StyledCalenderHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 100%;
  height: 64px;
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 100%;
`;

const StyledChevronWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSelect = styled.select`
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  padding: 10px 0px 10px 8px;
  width: 61px;
  height: 48px;
  border: none;
  background-image: url('../assets/images/dropdownicon.png');
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  outline: none;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomInput = React.forwardRef((props: any, ref) => (
  /* eslint-disable @typescript-eslint/no-unsafe-member-access  */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment  */
  <StyledInputWrapper>
    <StyledInput {...props} ref={ref} />
    <StyledIconWrapper>
      <Icon name="dateRangeNormal" onClick={props.onClick} />
    </StyledIconWrapper>
  </StyledInputWrapper>
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */
));

// デフォルトの選択年(7年前から4年後まで)
const defaultYears = _.range(getYear(new Date()) - 7, getYear(new Date()) + 5, 1);

// 年選択範囲取得
// ※デフォルトでは、7年前から4年後までの範囲を取得する
//   但し、minDateが指定されている場合は、minDateの年を最小選択年とする
//   maxDateが指定されている場合は、maxDateの年を最大選択年とする
const getYearRange = (yearRange: number[] = defaultYears, minDate?: Date | null, maxDate?: Date | null) => {
  // 最小年
  const minYear = minDate ? getYear(minDate) : yearRange[0];
  // 最大年
  const maxYear = maxDate ? getYear(maxDate) : yearRange[yearRange.length - 1];

  return _.range(minYear, maxYear + 1, 1);
};

export function DatePicker({ width = 160, yearRange, minDate, maxDate, ...props }: Props) {
  registerLocale('ja', ja);
  const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];

  // 年選択範囲取得
  const yearSelectRange = getYearRange(yearRange, minDate, maxDate);
  // 最小選択日付
  const minSelectDate = minDate || new Date(`${yearSelectRange[0]}/01/01`);
  // 最大選択日付
  const maxSelectDate = maxDate || new Date(`${yearSelectRange[yearSelectRange.length - 1]}/12/31`);

  return (
    <div>
      <ReactDatePicker
        {...props}
        locale="ja"
        dateFormat="yyyy年MM月dd日"
        customInput={<CustomInput width={width} />}
        minDate={minSelectDate}
        maxDate={maxSelectDate}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({ popper, reference }) => ({
                right: Math.min(popper.width, reference.width) - width / 2,
              }),
            },
          },
        ]}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
        }) => (
          <StyledCalenderHeader>
            <StyledSelectWrapper>
              <StyledChevronWrapper>
                {Number(format(date, 'yyyyMM')) > Number(format(minSelectDate, 'yyyyMM')) ? (
                  <Icon name="chevronRwd" onClick={decreaseMonth} />
                ) : (
                  <Box width={24}>&nbsp;</Box>
                )}
              </StyledChevronWrapper>
              <StyledSelect
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </StyledSelect>
              <StyledChevronWrapper>
                {Number(format(date, 'yyyyMM')) < Number(format(maxSelectDate, 'yyyyMM')) ? (
                  <Icon name="chevronFwd" onClick={increaseMonth} />
                ) : (
                  <Box width={24}>&nbsp;</Box>
                )}
              </StyledChevronWrapper>
            </StyledSelectWrapper>
            <StyledSelectWrapper>
              <StyledChevronWrapper>
                {getYear(date) > getYear(minSelectDate) ? (
                  <Icon name="chevronRwd" onClick={decreaseYear} />
                ) : (
                  <Box width={24}>&nbsp;</Box>
                )}
              </StyledChevronWrapper>
              <StyledSelect value={getYear(date)} onChange={({ target: { value } }) => changeYear(Number(value))}>
                {yearSelectRange.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </StyledSelect>
              <StyledChevronWrapper>
                {getYear(date) < getYear(maxSelectDate) ? (
                  <Icon name="chevronFwd" onClick={increaseYear} />
                ) : (
                  <Box width={24}>&nbsp;</Box>
                )}
              </StyledChevronWrapper>
            </StyledSelectWrapper>
          </StyledCalenderHeader>
        )}
      />
    </div>
  );
}
