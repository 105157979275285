import React from 'react';
import { useGetVtScoreListQuery, useGetVbWorkScoreListQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Tab } from 'components/newTab';
import { useErrorModal } from 'components/error/errorModalProvider';
import { MembershipStore } from './components/membershipStore';
import { Bitraveler } from './components/bitraveler';

// Figma管理番号
// 11-01-01
export function S11() {
  const { data: vTScoreListData, loading: vTScoreListLoading, error: vTScoreListError } = useGetVtScoreListQuery();
  const {
    data: vBWorkScoreListData,
    loading: vBWorkScoreListLoading,
    error: vBWorkScoreListError,
  } = useGetVbWorkScoreListQuery();

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (vTScoreListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTScoreListError, openErrorModal]);

  // 該当のタブでエラー表示
  const handleTabClick = (index: number) => {
    if (index === 1 && vBWorkScoreListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
      {(vTScoreListLoading || vBWorkScoreListLoading) && <Loading />}
      <Text variant="h1" color="darkBlue">
        レビュー管理
      </Text>
      <Card overflow="hidden">
        <Box pt={16}>
          <Tab
            defaultIndex={0}
            items={[
              {
                content: <MembershipStore membershipStoreReviewList={vTScoreListData?.getVTScoreList || []} />,
                label: 'TO 加盟店',
                labelLength: 160,
                callBack: (index) => handleTabClick(index),
              },
              {
                content: <Bitraveler bitravelerReviewList={vBWorkScoreListData?.getVBWorkScoreList || []} />,
                label: 'TO バイトラベラー',
                labelLength: 160,
                callBack: (index) => handleTabClick(index),
              },
            ]}
          />
        </Box>
      </Card>
    </Box>
  );
}
