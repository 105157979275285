/**
 * Figma ID: 07-02-02
 * 名称: 逆オファー（受け入れ募集内容ヘッダー）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';

type AcceptRevercedOfferDetailHeaderModel = {
  id: string;
};
function AcceptRevercedOfferDetailHeader(props: AcceptRevercedOfferDetailHeaderModel) {
  const { id } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Text variant="h2" color="darkBlue">
        逆オファー受入 募集内容
      </Text>
    </Box>
  );
}

export { AcceptRevercedOfferDetailHeader };
