import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { useCallback, useState } from 'react';
import { Divider } from 'components/newDivider';
import { useForm } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { ErrorMessage } from 'components/errorMessage';
import { ModalStep, RateOptions } from '../constants';
import {
  EducationFeeSettinModalViewDataTyep,
  EducationFeeSettingKey,
  ModalOptionType,
  RateOptionsType,
} from '../s10-02-types';
import { TextSet } from '../components/textSet';
import { checkRange } from '../s10-02-utils';

/**
 * Figma ID: 10-02-03-02, 10-02-03-03, 10-02-03-04
 * 名称: 育成料設定（コンビニ）
 * Figma ID: 10-02-04-02, 10-02-04-03, 10-02-04-02
 * 名称: 育成料設定（カフェ）
 */

interface Props {
  modalStep: ModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  onClose: () => void;
  modalData?: EducationFeeSettinModalViewDataTyep;
  mutation?: (educ_fee: number, educ_fee_max: number, educ_fee_min: number) => void;
}

interface ModalData {
  min?: string;
  max?: string;
  default?: string;
}

export function EducationFeeSettingModal({ modalStep, setModalStep, modalData, mutation, onClose }: Props) {
  const [feeData, setFeeData] = useState<ModalData>();

  // バリデーション用
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EducationFeeSettingKey>({ reValidateMode: 'onSubmit' });

  const feeMinHandleSelect = useCallback(
    (value: string) => {
      setFeeData({
        ...feeData,
        min: value,
      });
    },
    [feeData]
  );

  const feeMaxHandleSelect = useCallback(
    (value: string) => {
      setFeeData({
        ...feeData,
        max: value,
      });
    },
    [feeData]
  );
  const feeDefaultHandleSelect = useCallback(
    (value: string) => {
      setFeeData({
        ...feeData,
        default: value,
      });
    },
    [feeData]
  );

  const onClickHandler = handleSubmit((formData) => {
    if (formData) {
      setFeeData({
        min: formData.minRate,
        max: formData.maxRate,
        default: formData.default,
      });
      setModalStep(ModalStep.CHECK);
    }
  });

  const [defaultRateOptions, setDefaultRateOptions] = useState<RateOptionsType>();

  const dataCopy = useCallback(
    (data: EducationFeeSettinModalViewDataTyep) => {
      setValue('maxRate', String(data.max));
      setValue('minRate', String(data.min));
      setValue('default', String(data.default));
      setFeeData({
        max: String(data?.max),
        min: String(data?.min),
        default: String(data?.default),
      });
    },
    [setValue]
  );

  // プルダウンのオプションを作成するヘルパー関数
  const createOptions = (start: number, end: number, step: number): ModalOptionType[] => {
    const options: ModalOptionType[] = [];
    for (let i = start; i <= end; i += step) {
      options.push({ label: i.toString(), value: String(i) });
    }
    return options;
  };

  // コンポーネントのマウント時にデータを作成
  React.useEffect(() => {
    // デフォルト値のプルダウンのデータ
    const defaultOptions = createOptions(Number(feeData?.min) || 0, Number(feeData?.max) || 0, 5);
    setDefaultRateOptions({ defaultOptions });
  }, [feeData, getValues]);

  React.useEffect(() => {
    if (modalData) {
      dataCopy(modalData);
    }
  }, [modalData, dataCopy]);

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.INPUT:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {modalData?.title.input}
            </Text>
          ),
          content: (
            <>
              <Box alignItems="center" pt={6}>
                <Text variant="body14">{modalData?.runk}の料率を変更します。</Text>
              </Box>
              <Box alignItems="center" pt={8}>
                <Text color="liteGray" variant="body14">
                  設定可能範囲：{modalData?.range}
                </Text>
              </Box>
              <Box alignItems="start" display="flex">
                <TextSet
                  label="最小（％）"
                  content={
                    <RhfSelect
                      width={120}
                      control={control}
                      name="minRate"
                      options={RateOptions}
                      defaultValue={getValues('minRate') || ''}
                      placeholder="選択"
                      rules={{
                        required: '最小料率を選択してください',
                        validate: (_, values) => {
                          if (Number(values.minRate) > Number(values.maxRate)) {
                            return '最小値を最大値より低く設定してください';
                          }
                          return true;
                        },
                      }}
                      onChange={(value: string) => feeMinHandleSelect(value)}
                    />
                  }
                />
                <Box mt={32} ml={8} mr={8}>
                  <Text color="liteGray" variant="body14" display="inline">
                    ~
                  </Text>
                </Box>
                <TextSet
                  label="最大（％）"
                  content={
                    <Box gap={8}>
                      <RhfSelect
                        width={120}
                        control={control}
                        name="maxRate"
                        options={RateOptions}
                        defaultValue={getValues('maxRate') || ''}
                        placeholder="選択"
                        rules={{ required: '最大料率を選択してください' }}
                        onChange={(value: string) => feeMaxHandleSelect(value)}
                      />
                    </Box>
                  }
                />
              </Box>
              <Box alignItems="start" display="flex" pt={10} pb={26}>
                <Box width={160}>
                  {errors?.minRate && (
                    <Box pt={4}>
                      <ErrorMessage>{errors?.minRate.message}</ErrorMessage>
                    </Box>
                  )}
                </Box>
                <Box>{null}</Box>
                <Box width={160}>
                  {errors?.maxRate && (
                    <Box pt={4}>
                      <ErrorMessage>{errors?.maxRate.message}</ErrorMessage>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider option="horizontal" />
              <Box alignItems="center" display="flex" pt={10} pb={42}>
                <TextSet
                  label="デフォルト値（％）"
                  content={
                    <>
                      <Box gap={8}>
                        <RhfSelect
                          width={120}
                          control={control}
                          name="default"
                          options={defaultRateOptions?.defaultOptions || []}
                          defaultValue={feeData?.default || ''}
                          placeholder="選択"
                          rules={{
                            required: 'デフォルト値を選択してください',
                            validate: (_, values) => {
                              if (!checkRange(Number(values.minRate), Number(values.default), Number(values.maxRate))) {
                                return 'デフォルト値は最小値以上かつ最大値以下に設定してください';
                              }
                              return true;
                            },
                          }}
                          onChange={(value: string) => feeDefaultHandleSelect(value)}
                        />
                      </Box>
                      {errors?.default && (
                        <Box pt={4}>
                          <ErrorMessage>{errors?.default.message}</ErrorMessage>
                        </Box>
                      )}
                    </>
                  }
                />
              </Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                  setFeeData(undefined);
                  reset();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={(event) => {
                  onClickHandler(event);
                }}
                variant="primary"
                width={104}
              >
                保存
              </MainButton>
            </Box>
          ),
          onClose: () => {
            setModalStep(ModalStep.NULL);
            setFeeData(undefined);
            reset();
          },
        };
      case ModalStep.CHECK:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {modalData?.title.check}
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">
                以下の料率で設定してよろしいですか？
                <br />
              </Text>
              <Box mt={16}>
                <Box>
                  <Text variant="body14">
                    <Text variant="body14" bold>
                      ・
                    </Text>
                    最小/最大 {feeData?.min}% ~ {feeData?.max}%
                  </Text>
                </Box>
                <Box mt={16}>
                  <Text variant="body14">
                    <Text variant="body14" bold>
                      ・
                    </Text>
                    デフォルト値 {feeData?.default}%
                  </Text>
                </Box>
              </Box>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                  setFeeData(undefined);
                  reset();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(ModalStep.INPUT);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  if (mutation) {
                    mutation(Number(feeData?.default), Number(feeData?.max), Number(feeData?.min));
                  }
                }}
                variant="primary"
                width={104}
              >
                保存
              </MainButton>
            </Box>
          ),
          onClose: () => {
            setModalStep(ModalStep.NULL);
            setFeeData(undefined);
            reset();
          },
        };
      case ModalStep.COMPLETE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              {modalData?.title.complete}
            </Text>
          ),
          content: (
            <Text variant="body14">
              情報の設定が完了しました。
              <br />
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                  setFeeData(undefined);
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
          onClose: () => {
            onClose();
            setFeeData(undefined);
            reset();
          },
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose: () => {
            setModalStep(ModalStep.NULL);
            setFeeData(undefined);
            reset();
          },
        };
    }
  }, [
    modalStep,
    modalData,
    control,
    feeData,
    errors,
    getValues,
    defaultRateOptions?.defaultOptions,
    setModalStep,
    onClickHandler,
    reset,
    mutation,
    onClose,
    feeDefaultHandleSelect,
    feeMaxHandleSelect,
    feeMinHandleSelect,
  ]);
  return displayModal;
}
