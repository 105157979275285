/**
 * Figma ID: 04-01-02-01
 * 名称: 募集情報（バイト募集情報）
 */
import React, { ReactNode, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { Chip } from 'components/chip';
import { ToggleButton } from 'components/toggleButton';

import { Box } from 'components/box';
import { Card } from 'components/card';
import {
  useGetVtArbeitOfferInfoByIdQuery,
  useGetVtArbeitOfferEntryListByIdQuery,
  useGetVtArbeitOfferLogging1ListByIdQuery,
  useUpdateTArbeitOffereStatusByIdMutation,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import { Loading } from 'components/loading/loading';
import { Modal } from 'components/newModal';
import { MainButton } from 'components/mainButton';
import { RecruitInfo } from './components/recruitInfo';
import { Application } from './components/application';
import { History } from './components/history';

enum ModalStep {
  NULL = 0,
  DEACTIVATE_OFFER = 1,
  ACTIVATE_OFFER = 2,
  COMPLETE = 3,
}

// 募集情報
export function S0401Details() {
  // パラメータ取得
  const { id } = useParams();
  const { openErrorModal } = useErrorModal();
  const {
    data: { getVTArbeitOfferInfoById } = { getVTArbeitOfferInfoById: { info: null, list: null } },
    loading: loadingArbeitOfferInfoById,
    error: errorArbeitOfferInfoById,
    refetch: refetchVTArbeitOfferInfoById,
  } = useGetVtArbeitOfferInfoByIdQuery({ variables: { id: Number(id) }, fetchPolicy: 'no-cache' });
  const {
    data: { getVTArbeitOfferEntryListById } = { getVTArbeitOfferEntryListById: { entry: null, offer: null } },
    loading: loadingArbeitOfferEntryList,
    error: errorArbeitOfferEntryList,
  } = useGetVtArbeitOfferEntryListByIdQuery({ variables: { id: Number(id) }, fetchPolicy: 'no-cache' });
  const {
    data: { getVTArbeitOfferLogging1ListById } = { getVTArbeitOfferLogging1ListById: { logging: null, offer: null } },
    loading: loadingArbeitOfferLogging1List,
    error: errorArbeitOfferLogging1List,
  } = useGetVtArbeitOfferLogging1ListByIdQuery({ variables: { id: Number(id) }, fetchPolicy: 'no-cache' });
  const [
    updateTArbeitOfferStatusById,
    { data: dataUpdateTArbeitOfferStatus, loading: loadingUpdateTArbeitOfferStatus },
  ] = useUpdateTArbeitOffereStatusByIdMutation();

  React.useEffect(() => {
    if (errorArbeitOfferInfoById || errorArbeitOfferEntryList || errorArbeitOfferLogging1List) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [errorArbeitOfferInfoById, errorArbeitOfferEntryList, errorArbeitOfferLogging1List, openErrorModal]);

  const [modalStep, setModalStep] = React.useState<ModalStep>(0);
  const [status, setStatus] = useState<number>(0);

  React.useEffect(() => {
    if (getVTArbeitOfferInfoById.list && getVTArbeitOfferInfoById.list[0].status) {
      setStatus(getVTArbeitOfferInfoById.list[0].status);
    }
  }, [getVTArbeitOfferInfoById]);

  const statusChip = useMemo<ReactNode>(() => {
    switch (status) {
      case 1:
        return (
          <Chip color="blue" variant="big" width={128}>
            募集前
          </Chip>
        );
      case 2:
        return (
          <Chip color="darkBlue" variant="big" width={128}>
            募集中
          </Chip>
        );
      case 3:
        return (
          <Chip color="okBlue" variant="big" width={128}>
            採用確定
          </Chip>
        );
      case 4:
        return (
          <Chip color="liteGray" variant="big" width={128}>
            募集終了
          </Chip>
        );
      case 6:
        return (
          <Chip color="redLite" variant="big" width={128}>
            募集停止
          </Chip>
        );
      case 7:
        return (
          <Chip color="cautionRed" variant="big" width={128}>
            強制停止
          </Chip>
        );
      default:
        return (
          <Chip color="darkBlue" variant="big" width={128}>
            ?
          </Chip>
        );
    }
  }, [status]);

  const displaySubMenuItems = useMemo<{ label: string; content: ReactNode }[]>(() => {
    const subMenuItems: { label: string; content: ReactNode }[] = [];
    if (getVTArbeitOfferInfoById.info) {
      subMenuItems.push({
        content: <RecruitInfo vtArbeitOfferInfo={getVTArbeitOfferInfoById.info} />,
        label: '募集情報',
      });
    }
    if (getVTArbeitOfferEntryListById.entry) {
      subMenuItems.push({
        content: (
          <Application
            vtArbeitOfferEntryList={getVTArbeitOfferEntryListById.entry}
            vtArbeitOffer={getVTArbeitOfferEntryListById.offer}
          />
        ),
        label: `応募(${String(getVTArbeitOfferEntryListById.offer.entry_count || 0)})`,
      });
    }
    if (getVTArbeitOfferLogging1ListById.logging) {
      subMenuItems.push({
        content: <History vtArbeitOfferLogging1List={getVTArbeitOfferLogging1ListById.logging} />,
        label: '採用履歴',
      });
    }
    return subMenuItems;
  }, [getVTArbeitOfferInfoById, getVTArbeitOfferEntryListById, getVTArbeitOfferLogging1ListById]);

  const displayModal = React.useMemo(() => {
    const handleChangeState = async () => {
      try {
        // statusを募集前（１）から募集停止（６）に変更する
        if (status === 1) {
          await updateTArbeitOfferStatusById({ variables: { id: Number(id), status: 6 } });
        }
        // statusを募集中（２）から強制停止（７）に変更する
        if (status === 2) {
          await updateTArbeitOfferStatusById({ variables: { id: Number(id), status: 7 } });
        }
        // statusを強制停止（７）から募集中（２）に変更する
        if (status === 7) {
          await updateTArbeitOfferStatusById({ variables: { id: Number(id), status: 2 } });
        }
      } catch (e) {
        setModalStep(ModalStep.NULL);
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      } finally {
        if (dataUpdateTArbeitOfferStatus?.updateTArbeitOffereStatusById.resultRows === ResultRows.UPDATED) {
          await refetchVTArbeitOfferInfoById();
          setModalStep(ModalStep.COMPLETE);
        }
      }
    };

    switch (modalStep) {
      case ModalStep.DEACTIVATE_OFFER:
        return {
          width: 384,
          height: 231,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          content: (
            <Text variant="body14">
              この募集を{status === 1 ? '停止' : '強制停止'}しますか？
              <br />
              申込み済みの採用が自動的にキャンセルになります。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  handleChangeState();
                }}
                variant="primary"
              >
                変更
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.ACTIVATE_OFFER:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更
            </Text>
          ),
          content: <Text variant="body14">この募集をアクティブに変更しますか？</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  handleChangeState();
                }}
                variant="primary"
              >
                変更
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.COMPLETE:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              ステータス変更 完了
            </Text>
          ),
          content: <Text variant="body14">ステータス変更が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                閉じる
              </MainButton>
            </Box>
          ),
        };

      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [
    modalStep,
    status,
    id,
    refetchVTArbeitOfferInfoById,
    updateTArbeitOfferStatusById,
    openErrorModal,
    dataUpdateTArbeitOfferStatus,
  ]);

  return (
    <>
      {(loadingArbeitOfferInfoById ||
        loadingArbeitOfferEntryList ||
        loadingArbeitOfferLogging1List ||
        loadingUpdateTArbeitOfferStatus) && <Loading />}

      <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={8} width="100%" height={32}>
          {status !== 0 && <div>{statusChip}</div>}
          <Box flex="auto">
            <Text color="darkBlue" variant="h1">
              {getVTArbeitOfferEntryListById.offer?.offer_number}
            </Text>
          </Box>
          <div>
            {[1, 2, 7].includes(status) && (
              <ToggleButton
                currentValue={status === 7 ? 'stop' : 'active'}
                onClick={(value) => {
                  if (value === 'stop') {
                    setModalStep(ModalStep.DEACTIVATE_OFFER);
                    return;
                  }
                  if (value === 'active') {
                    setModalStep(ModalStep.ACTIVATE_OFFER);
                  }
                }}
                options={[
                  {
                    activeColor: '#E50000',
                    label: status === 1 ? '募集停止' : '強制停止',
                    value: 'stop',
                  },
                  {
                    label: 'アクティブ',
                    value: 'active',
                  },
                ]}
              />
            )}
          </div>
        </Box>
        <Card overflow="hidden">
          <SubMenu items={displaySubMenuItems} />
        </Card>
      </Box>

      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(0)}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
      />
    </>
  );
}
