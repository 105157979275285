import React from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { Icon } from '../icon/icon';
import { Divider } from '../newDivider/divider';

export interface Props {
  page: number;
  totalPage: number;
  dataSize: number;
  onChange: (page: number) => void;
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const StyledPagerWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const StyledButton = styled.button`
  display: contents;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export function Pagination({ page: currentPage, totalPage, dataSize, onChange }: Props) {
  const pagesArray = React.useMemo(() => {
    if (currentPage <= 5) {
      return [...Array<number>(totalPage)].map((_, index) => index + 1).slice(0, 5);
    }
    if (currentPage + 5 > totalPage) {
      return [totalPage - 4, totalPage - 3, totalPage - 2, totalPage - 1, totalPage];
    }
    return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
  }, [currentPage, totalPage]);

  return (
    <StyledWrapper>
      <Text variant="caption11" color="darkGray">
        {currentPage} / {totalPage} ページ (全 {dataSize} 件)
      </Text>
      <StyledPagerWrapper>
        <StyledButton onClick={() => currentPage !== 1 && onChange(currentPage - 1)}>
          <Icon name="chevronRwd" />
        </StyledButton>
        {currentPage > 5 && (
          <>
            <StyledButton onClick={() => onChange(1)}>
              <Text variant="caption11" color="primaryBlue">
                1
              </Text>
            </StyledButton>
            <Text variant="caption11" color="superLiteGray">
              ...
            </Text>
          </>
        )}
        {pagesArray.map((page, index) => (
          <React.Fragment key={page}>
            <Divider option="vertical" length={8} />
            <StyledButton onClick={() => onChange(page)}>
              <Text variant="caption11" color={currentPage === page ? 'black' : 'primaryBlue'}>
                {String(page)}
              </Text>
            </StyledButton>
            {index === pagesArray.length - 1 && <Divider option="vertical" length={8} />}
          </React.Fragment>
        ))}
        {pagesArray[pagesArray.length - 1] < totalPage && (
          <>
            <Text variant="caption11" color="superLiteGray">
              ...
            </Text>
            <StyledButton onClick={() => onChange(totalPage)}>
              <Text variant="caption11" color="primaryBlue">
                {totalPage}
              </Text>
            </StyledButton>
          </>
        )}
        <StyledButton onClick={() => totalPage > 0 && currentPage !== totalPage && onChange(currentPage + 1)}>
          <Icon name="chevronFwd" />
        </StyledButton>
      </StyledPagerWrapper>
    </StyledWrapper>
  );
}
