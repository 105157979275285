/**
 * Figma ID: 07-02-01, 07-03-01
 * 名称: 逆オファー（オファー受入店）
 */
import { HideWrapper, InnerBox, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Chip } from 'components/chip';
import { Icon } from 'components/icon';
import { List } from 'components/list';
import { Divider } from 'components/newDivider';
import { Modal } from 'components/newModal';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import React, { ReactNode } from 'react';
import { orderBy } from 'lodash';
import { useSort, usePagenation } from 'components/utils';
import { ReversedOfferListStatusIndex } from 'pages/s07/status';
import { TpmemDetailHeader } from 'modal/tpmemDetail/TpmemDetailHeader';
import { TpmemDetailContent } from 'modal/tpmemDetail/TpmemDetailContent';
import { TpmemDetailFooter } from 'modal/tpmemDetail/TpmemDetailFooter';
import { GetVbOfferAcceptListByOfferIdQuery } from 'graphql/graphql-ow';
import { TextLink } from 'components/textLink';
import { AcceptRevercedOfferDetailHeader } from './acceptRevercedOfferDetail/AcceptRevercedOfferDetailHeader';
import { AcceptRevercedOfferDetailContent } from './acceptRevercedOfferDetail/AcceptRevercedOfferDetailContent';
import { AcceptRevercedOfferDetailFooter } from './acceptRevercedOfferDetail/AcceptRevercedOfferDetailFooter';

const AcceptOfferListHeader = [
  {
    columnName: '',
    key: 'eye',
    width: 24,
  },
  {
    columnName: '',
    key: 'image',
    width: 40,
  },
  {
    columnName: '店舗名',
    key: 'name',
    width: 500,
  },
];

const TitleMaxLength = 32;

type AcceptOfferTabModel = {
  selectTab: () => void;
  acceptOfferData: GetVbOfferAcceptListByOfferIdQuery | undefined;
  tpNum: number;
};
function AcceptOffer(props: AcceptOfferTabModel) {
  const { selectTab, acceptOfferData, tpNum } = props;
  const [acceptRevercedOfferDetailShow, setAcceptRevercedOfferDetailShow] = React.useState(false);
  const [isTpmemDetailShow, setIsTpmemDetailShow] = React.useState(false);
  const [tpmemId, setTpmemId] = React.useState(0);
  const [selectTpmemDataIndex, setSelectTpmemDataIndex] = React.useState<number | undefined>();
  React.useEffect(() => selectTab(), [selectTab]);
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();

  const { sort, handleChangeSort } = useSort({
    onBeforeSort: () => setPage(1),
  });

  React.useEffect(() => {
    if (selectTpmemDataIndex !== undefined) {
      setAcceptRevercedOfferDetailShow(true);
    } else {
      setAcceptRevercedOfferDetailShow(false);
    }
  }, [selectTpmemDataIndex]);

  const generateTitle = (title: string, status?: number | null, tpmem_id?: number | null) => {
    const maxLength = TitleMaxLength;
    const slicedTitle = title.length >= maxLength ? `${title.slice(0, maxLength)}...` : title;

    return (
      <>
        {status === ReversedOfferListStatusIndex.SUCCESS ? <Chip color="okBlue">成約</Chip> : undefined}
        <TextLink
          lineClamp={1}
          onClick={() => {
            setIsTpmemDetailShow(true);
            setTpmemId(tpmem_id ?? 0);
          }}
        >
          {slicedTitle}
        </TextLink>
      </>
    );
  };

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let items = acceptOfferData?.getVBOfferAcceptListByOfferId ?? [];

    if (sort) {
      items = orderBy(items, sort.key, sort.direction);
    }

    return items
      .map((item, index) => ({
        uniqueKey: Math.random(),
        eye: (
          <Icon
            name="eye"
            onClick={() => {
              setSelectTpmemDataIndex(index);
              setTpmemId(item?.tpmem_id ?? 0);
            }}
          />
        ),
        name: generateTitle(item?.name, item?.status, item?.tpmem_id),
        image: <img alt="acceptOfferImage" height={40} src={item?.image ?? ''} width={40} />,
      }))
      .slice(limit * (page - 1), limit * page);
  }, [limit, page, sort, acceptOfferData?.getVBOfferAcceptListByOfferId]);

  return (
    <HideWrapper>
      <InnerBox padding="24px">
        <Box pb={18}>
          <Text color="darkBlue" variant="h2">
            オファー受入店（{tpNum}）
          </Text>
        </Box>
        <Divider option="horizontal" />
        <PaginationBox
          dataSize={acceptOfferData?.getVBOfferAcceptListByOfferId.length || 0}
          limit={limit}
          page={page}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <ScrollWrapper bottom={350}>
            <List
              key={String(limit) + String(page) + (sort?.key || '')}
              header={AcceptOfferListHeader}
              width="100%"
              rowWidth="100%"
              items={displayItems}
              sort={sort}
              onChangeSort={handleChangeSort}
            />
          </ScrollWrapper>
        </PaginationBox>
      </InnerBox>

      <Modal
        isOpen={acceptRevercedOfferDetailShow}
        header={<AcceptRevercedOfferDetailHeader id="id" />}
        content={
          <AcceptRevercedOfferDetailContent
            status={Number(acceptOfferData?.getVBOfferAcceptListByOfferId?.[selectTpmemDataIndex ?? 0]?.status) ?? 0}
            acceptId={acceptOfferData?.getVBOfferAcceptListByOfferId?.[selectTpmemDataIndex ?? 0]?.accept_id ?? 0}
            onClickTpmemDetail={() => setIsTpmemDetailShow(true)}
            offerId={acceptOfferData?.getVBOfferAcceptListByOfferId?.[selectTpmemDataIndex ?? 0]?.offer_id ?? 0}
            onClose={() => {
              setAcceptRevercedOfferDetailShow(false);
            }}
          />
        }
        footer={<AcceptRevercedOfferDetailFooter onClickClose={() => setSelectTpmemDataIndex(undefined)} />}
        onClose={() => {}}
        width={955}
        height="auto"
      />
      <Modal
        isOpen={isTpmemDetailShow}
        header={
          <TpmemDetailHeader
            tpmemName={acceptOfferData?.getVBOfferAcceptListByOfferId?.[selectTpmemDataIndex ?? 0]?.name ?? ''}
            tpmemId={acceptOfferData?.getVBOfferAcceptListByOfferId?.[selectTpmemDataIndex ?? 0]?.tpmem_id ?? 0}
          />
        }
        content={<TpmemDetailContent tpmemId={tpmemId} />}
        footer={<TpmemDetailFooter onClickClose={() => setIsTpmemDetailShow(false)} />}
        onClose={() => setIsTpmemDetailShow(false)}
        width={955}
        height="auto"
        overflow="visible"
      />
    </HideWrapper>
  );
}

export default AcceptOffer;
