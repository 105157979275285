import React, { ReactNode, useMemo } from 'react';
import { styled } from '@linaria/react';
import { LimitChanger } from './limitChanger';
import { Pagination } from './pagination';

export interface Props {
  children: ReactNode;
  limit: 10 | 20 | 30;
  onChangeLimit: (value: 10 | 20 | 30) => void;
  page: number;
  dataSize: number;
  onChangePage: (page: number) => void;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledLimitWrapper = styled.div`
  height: 42px;
  padding: 16px 0;
  width: 100%;
  box-sizing: border-box;
`;

const StyledChildrenWrapper = styled.div`
  flex: auto;
  overflow: auto;
  height: 100%;
`;

const StyledPaginationWrapper = styled.div`
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export function PaginationBox({ children, limit, onChangeLimit, page, dataSize, onChangePage }: Props) {
  const totalPage = useMemo(() => Math.ceil(dataSize / limit), [dataSize, limit]);
  return (
    <StyledWrapper>
      <StyledLimitWrapper>
        <LimitChanger limit={limit} onClick={onChangeLimit} />
      </StyledLimitWrapper>
      <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
      <StyledPaginationWrapper>
        <Pagination page={page} totalPage={totalPage} dataSize={dataSize} onChange={onChangePage} />
      </StyledPaginationWrapper>
    </StyledWrapper>
  );
}
