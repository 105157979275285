import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';

type DisplayType = 'block' | 'inline' | 'inline-block' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid' | 'none';

type JustifyContentType =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

type AlignItemsType = 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';

type FlexWrapType = 'nowrap' | 'wrap' | 'wrap-reverse';

type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export const colorPalette = {
  primaryBlue: '#008599',
  blue: '#00A5BF',
  darkBlue: '#16495F',
  blueGray: '#5F797D',
  liteBlue: '#F8FEFF',
  darkGray: '#484848',
  liteGray: '#969696',
  superLiteGray: '#DBDBDB',
  bgGray: '#FBFBFB',
  white: '#FFFFFF',
  black: '#000000',
  modalBg: 'Rgba(0,0,0,0.5)',
  redLite: '#F35555',
  cautionRed: '#E50000',
  okBlue: '#3170C8',
  warningYellow: '#E8A700',
  bgRed: 'rgba(255, 0, 0, 0.1)',
  bgYellow: 'rgba(255, 184, 0, 0.1)',
  inherit: 'inherit',
  transparent: 'transparent',
} as const;

export type ColorKeyType = keyof typeof colorPalette;
type ColorValueType = typeof colorPalette[ColorKeyType];

export interface Props {
  children: ReactNode;
  display?: DisplayType;
  flexDirection?: FlexDirectionType;
  flex?: string;
  justifyContent?: JustifyContentType;
  alignItems?: AlignItemsType;
  flexWrap?: FlexWrapType;
  gap?: number;
  columnGap?: number;
  rowGap?: number;
  width?: number | string;
  height?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  backgroundColor?: ColorKeyType;
  border?: string;
  borderRadius?: number;
  overflow?: string;
  pa?: number;
  px?: number;
  py?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  ma?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
}

const StyledDiv = styled.div<{
  display: DisplayType;
  flexDirection: FlexDirectionType;
  flex: string;
  justifyContent: JustifyContentType;
  alignItems: AlignItemsType;
  flexWrap: FlexWrapType;
  gap: string;
  width: string;
  height: string;
  minWidth: string;
  minHeight: string;
  maxWidth: string;
  maxHeight: string;
  padding: string;
  margin: string;
  border: string;
  borderRadius: number;
  overflow?: string;
  backgroundColor: ColorValueType;
}>`
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex: ${({ flex }) => flex};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeight }) => minHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  overflow: ${({ overflow }) => overflow || 'visible'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-sizing: border-box;
`;

export function Box({
  children,
  display = 'block',
  flexDirection = 'row',
  flex = 'initial',
  justifyContent = 'flex-start',
  alignItems = 'normal',
  flexWrap = 'nowrap',
  gap,
  columnGap,
  rowGap,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  backgroundColor,
  border = 'none',
  borderRadius = 0,
  overflow,
  pa,
  px,
  py,
  pt,
  pr,
  pb,
  pl,
  ma,
  mx,
  my,
  mt,
  mr,
  mb,
  ml,
}: Props) {
  const calculatePadding = React.useMemo(() => {
    let paddingArray: (number | string)[] = [0, 0, 0, 0];
    if (pa) {
      paddingArray = [`${pa}px`, `${pa}px`, `${pa}px`, `${pa}px`];
    }
    if (px) {
      paddingArray[1] = `${px}px`;
      paddingArray[3] = `${px}px`;
    }
    if (py) {
      paddingArray[0] = `${py}px`;
      paddingArray[2] = `${py}px`;
    }
    if (pt) {
      paddingArray[0] = `${pt}px`;
    }
    if (pr) {
      paddingArray[1] = `${pr}px`;
    }
    if (pb) {
      paddingArray[2] = `${pb}px`;
    }
    if (pl) {
      paddingArray[3] = `${pl}px`;
    }
    return paddingArray.join(' ');
  }, [pa, px, py, pt, pr, pb, pl]);

  const calculateMargin = React.useMemo(() => {
    let marginArray: (number | string)[] = [0, 0, 0, 0];
    if (ma) {
      marginArray = [`${ma}px`, `${ma}px`, `${ma}px`, `${ma}px`];
    }
    if (mx) {
      marginArray[1] = `${mx}px`;
      marginArray[3] = `${mx}px`;
    }
    if (my) {
      marginArray[0] = `${my}px`;
      marginArray[2] = `${my}px`;
    }
    if (mt) {
      marginArray[0] = `${mt}px`;
    }
    if (mr) {
      marginArray[1] = `${mr}px`;
    }
    if (mb) {
      marginArray[2] = `${mb}px`;
    }
    if (ml) {
      marginArray[3] = `${ml}px`;
    }
    return marginArray.join(' ');
  }, [ma, mx, my, mt, mr, mb, ml]);

  const calculateGap = React.useMemo(() => {
    let gapArray: string[] = ['normal', 'normal'];
    if (gap) {
      gapArray = [`${gap}px`, `${gap}px`];
    }
    if (rowGap) {
      gapArray[0] = `${rowGap}px`;
    }
    if (columnGap) {
      gapArray[1] = `${columnGap}px`;
    }
    return gapArray.join(' ');
  }, [gap, columnGap, rowGap]);

  const calculateWidthAndHeight = (value?: string | number): string => {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'number') {
      return `${value}px`;
    }
    return 'auto';
  };

  const calculateMaxWidthAndMaxHeight = (value?: string | number): string => {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'number') {
      return `${value}px`;
    }
    return 'none';
  };

  return (
    <StyledDiv
      display={display}
      flexDirection={flexDirection}
      flex={flex}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      gap={calculateGap}
      width={calculateWidthAndHeight(width)}
      height={calculateWidthAndHeight(height)}
      minWidth={calculateWidthAndHeight(minWidth)}
      minHeight={calculateWidthAndHeight(minHeight)}
      maxWidth={calculateMaxWidthAndMaxHeight(maxWidth)}
      maxHeight={calculateMaxWidthAndMaxHeight(maxHeight)}
      border={border}
      borderRadius={borderRadius}
      overflow={overflow}
      backgroundColor={backgroundColor ? colorPalette[backgroundColor] : 'inherit'}
      padding={calculatePadding}
      margin={calculateMargin}
    >
      {children}
    </StyledDiv>
  );
}
