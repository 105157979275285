/**
 * Figma ID: 07-01-01
 * 名称: 逆オファー
 */
import React from 'react';
import { styled } from '@linaria/react';
import { Link } from 'react-router-dom';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { orderBy } from 'lodash';
import { Icon } from 'components/icon';
import { Loading } from 'components/loading/loading';
import { VbOfferList, useGetMBusinessBrandQuery, useGetVbOfferListQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ColorKeyType } from 'components/list/listRow';
import { RevercedOfferStatusChip } from './components/statusChip';
import SearchHeader from './components/searchHeader';
import { ReversedOfferListStatusIndex } from './status';

const StyledListWrapper = styled.div`
  height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  overflow: auto;
`;
const COLORS_NAME = {
  Red: 'red',
  White: 'white',
};

export interface ReverceOfferModel {
  status?: number; //	ステータス
  offer_no?: string; //	オファーNo
  btmem_name?: string; //	バイトラベル会員名
  tp_num?: number; //	受入店舗数
  btmem_state?: string; //	居住都道府県
  state_city?: string; //	希望地域
  biz_name?: string; //	業種名
  brand_name?: string; //	ブランド名
  begin_end?: string; //	バイト期間
  work_time?: string; //	希望時間帯
  created_date?: string; //	登録日時
  offer_id?: number; //	逆オファーID
  biz_id?: number; //	業種ID
  brand_id?: number; //	ブランドID
  btmem_id?: number; //	バイトラベル会員ID
}

export type ReverceOfferSearchWordStateModel = {
  offerNo?: string;
  btmemName?: string;
  stateCity?: string;
  bizId?: string;
  brandId?: string;
};

function Screen07() {
  const [searchWord, setSearchWord] = React.useState<ReverceOfferSearchWordStateModel>();
  const [statusSelect, setStatusSelect] = React.useState<string>('');
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [itemData, setItemData] = React.useState<(VbOfferList | null)[]>([]);
  const { data, loading, refetch, error: offerListError } = useGetVbOfferListQuery();

  const { data: { getMBusinessBrand: businessBrand } = { getMBusinessBrand: [] }, error: getMBusinessBrandError } =
    useGetMBusinessBrandQuery({
      context: { clientName: 'master' },
    });

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (offerListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [offerListError, openErrorModal]);
  React.useEffect(() => {
    if (getMBusinessBrandError) {
      openErrorModal({
        message: '業種情報の取得ができませんでした。',
      });
    }
  }, [getMBusinessBrandError, openErrorModal]);

  React.useEffect(() => {
    setItemData(data?.getVBOfferList ?? []);
    refetch();
  }, [data, refetch]);

  const bizNameList =
    businessBrand?.map((element) => ({
      value: element?.id.toString() || '',
      label: element?.name || '',
    })) || [];

  const brandNameList: { value: string; label: string }[] = searchWord?.bizId
    ? businessBrand
        ?.find((b) => b?.id?.toString() === searchWord?.bizId)
        ?.brand?.map((element) => ({
          value: element?.brand_id.toString() || '',
          label: element?.brand_name || '',
        })) || []
    : [];

  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeSort = (key: string) => {
    setCurrentPage(1);
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { width: 24, key: 'eye' },
    { width: 56, key: 'status', columnName: '状態' },
    { width: 120, key: 'offer_no', columnName: 'オファーNo.' },
    { width: 140, key: 'btmem_name', columnName: 'オファーした人' },
    { width: 140, key: 'tp_num', columnName: '受入店舗' },
    { width: 140, key: 'btmem_state', columnName: '住まいの都道府県' },
    { width: 140, key: 'state_city', columnName: '希望地域' },
    { width: 140, key: 'begin_end', columnName: 'バイト期間' },
    { width: 140, key: 'work_time', columnName: '希望時間帯' },
    { width: 140, key: 'created_date', columnName: '登録日時' },
    { width: 140, key: 'biz_name', columnName: '希望業種' },
    { width: 140, key: 'brand_name', columnName: '希望ブランド' },
  ];

  const searchAction = React.useCallback(
    (bizId?: string, brandId?: string) => {
      let searchItems = data?.getVBOfferList?.filter(
        (val) =>
          val?.offer_no?.includes(searchWord?.offerNo ?? '') &&
          val.btmem_name?.includes(searchWord?.btmemName ?? '') &&
          val.state_city?.includes(searchWord?.stateCity ?? '')
      );
      // ページ初期化
      setCurrentPage(1);
      if (bizId !== undefined && bizId !== 'all') {
        searchItems = searchItems?.filter((val) => String(val?.biz_id) === bizId);
      }
      if (brandId !== undefined && brandId !== 'all') {
        searchItems = searchItems?.filter((val) => String(val?.brand_id) === brandId);
      }
      setItemData(searchItems ?? []);
    },
    [data?.getVBOfferList, searchWord?.btmemName, searchWord?.offerNo, searchWord?.stateCity]
  );

  const clearAction = () => {
    // ページ初期化
    setCurrentPage(1);
    setSearchWord({});
    setItemData(data?.getVBOfferList ?? []);
  };

  const backgroundColor = (status: ReversedOfferListStatusIndex): ColorKeyType => {
    switch (status) {
      case ReversedOfferListStatusIndex.FORCE_STOP:
        return COLORS_NAME.Red as ColorKeyType;
      default:
        return COLORS_NAME.White as ColorKeyType;
    }
  };

  const displayItems = React.useMemo(() => {
    let items = itemData;
    if (statusSelect) {
      items = items.filter((val) => {
        switch (statusSelect) {
          case '0':
            return val?.status === 0;
          default:
            return true;
        }
      });
    }

    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'identification', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items
      .map((item, index) => ({
        uniqueKey: index,
        eye: (
          <Link to={`/s07/${item?.offer_id ?? 0}`}>
            <Icon name="eye" />
          </Link>
        ),
        status: <RevercedOfferStatusChip status={item?.status ?? 0} />,
        offer_no: item?.offer_no,
        btmem_name: item?.btmem_name,
        tp_num: item?.tp_num,
        btmem_state: item?.btmem_state,
        state_city: item?.state_city,
        biz_name: item?.biz_name,
        brand_name: item?.brand_name,
        begin_end: item?.begin_end,
        work_time: item?.work_time,
        created_date: item?.created_date,
        offer_id: item?.offer_id ?? 0,
        biz_id: item?.biz_id,
        brand_id: item?.brand_id,
        btmem_id: item?.btmem_id,
        backgroundColor: backgroundColor(item?.status ?? 0),
      }))
      .slice(currentLimit * (currentPage - 1), currentLimit * currentPage);
  }, [sort, statusSelect, currentPage, currentLimit, itemData]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box height={30} mb={26} display="flex" alignItems="center">
        <Text color="darkBlue" variant="h1">
          逆オファー
        </Text>
      </Box>
      <Box flex="auto">
        <Card overflow="hidden">
          {loading ? (
            <Loading />
          ) : (
            <Box display="flex" flexDirection="column">
              <SearchHeader
                searchWord={searchWord}
                setSearchWord={setSearchWord}
                searchAction={searchAction}
                setCurrentPage={setCurrentPage}
                clearAction={clearAction}
                bizNameList={bizNameList ?? []}
                brandNameList={brandNameList ?? []}
              />
              <Divider option="horizontal" />
              <Box flex="auto" px={16}>
                <PaginationBox
                  dataSize={dataSize}
                  limit={currentLimit}
                  page={currentPage}
                  onChangeLimit={handleChangeLimit}
                  onChangePage={handleChangePage}
                >
                  <StyledListWrapper>
                    <List
                      key={String(currentLimit) + String(currentPage) + (sort?.key || '')}
                      header={header}
                      items={displayItems}
                      sort={sort}
                      onChangeSort={handleChangeSort}
                      rowHeight={41}
                    />
                  </StyledListWrapper>
                </PaginationBox>
              </Box>
            </Box>
          )}
        </Card>
      </Box>
    </Box>
  );
}

export default Screen07;
