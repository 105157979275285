import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@linaria/react';
import { Icon } from '../icon';
import { Text } from '../text';
import { GENERIC_COLOR, PRIMARY_COLOR } from '../assets/css/style';

export interface Props {
  menu: {
    label: string;
    value: string;
  }[];
  onClick?: (value: string) => void;
  right?: number; // 最後尾に置かれることが多いコンポーネントなので、右からの位置を調整できるようにしています。
  width?: number;
}
const IconWrap = styled.div`
  position: relative;
  cursor: pointer;
  height: 24px;
`;

const PullDownListMenuWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 124px;
  height: auto;
  background: ${GENERIC_COLOR.WHITE};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid ${PRIMARY_COLOR.PRIMARY_BLUE};
  z-index: 3;
`;

const ListMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  background: ${GENERIC_COLOR.WHITE};
  cursor: pointer;
  &:hover {
    background-color: ${GENERIC_COLOR.SUPERLITE_GRAY};
    color: ${PRIMARY_COLOR.DARK_BLUE};
    opacity: 0.5;
  }
  &:active {
    background-color: ${PRIMARY_COLOR.BLUE};
    color: ${GENERIC_COLOR.WHITE};
  }
`;
export function ThreeDotLeader({ menu = [], onClick = () => {}, right, width }: Props) {
  const [openMenu, setOpenMenu] = useState(false);

  const pulldownRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    // dropdownが非表示の場合何もしない
    if (!pulldownRef.current) {
      return;
    }
    // ボタンかドロップダウンメニュー以外が押された場合はドロップダウンメニューを閉じる
    if (!iconRef.current?.contains(e.target as Node) && !pulldownRef.current.contains(e.target as Node)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <IconWrap>
      <IconWrap ref={iconRef} onClick={() => setOpenMenu(!openMenu)}>
        {openMenu ? <Icon name="ellipsisSelected" /> : <Icon name="ellipsis" />}
      </IconWrap>
      {openMenu && (
        <PullDownListMenuWrap ref={pulldownRef} style={{ right, width }}>
          {menu.map((val) => (
            <ListMenu
              onClick={() => {
                onClick(val.value);
                setOpenMenu(false);
              }}
            >
              <Text variant="caption11" color="darkBlue">
                {val.label}
              </Text>
            </ListMenu>
          ))}
        </PullDownListMenuWrap>
      )}
    </IconWrap>
  );
}
