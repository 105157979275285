/**
 * Figma ID: 01-01-01
 * 名称: ログイン
 */
import React from 'react';
import { BitravelLogo } from 'components/assets/css/pages/s01/screen01Style';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { MainButton } from 'components/mainButton';
import { TextLink } from 'components/textLink';

type InputMailAndPasswordModel = {
  onSignIn: (args: { email: string; password: string }) => Promise<void>;
};
export function InputMailAndPassword({ onSignIn }: InputMailAndPasswordModel) {
  const passwordLength = 8;
  const regExp = /^(?=.*[A-Z])(?=.*[^$*.\\[\]{}()?&quot;!@#%&/\\,&gt;&lt;&prime;:;|_~`+=])[a-zA-Z0-9.?/-]/;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({ reValidateMode: 'onChange' });
  const onSubmit = handleSubmit(async (data) => {
    await onSignIn(data);
  });

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="right" pt={24} pr={24}>
        <Text variant="caption11" color="liteGray">
          事業再構築
        </Text>
      </Box>
      <Box width="100%" justifyContent="center" display="grid" mt={116}>
        <BitravelLogo width={200} height={35} src="/bitravel_logo.png" alt="bitravel_logo" />
        <Box justifyContent="center" alignItems="center" display="grid" pt={10}>
          <Text color="darkBlue" variant="body18" bold>
            運営管理
          </Text>
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>メールアドレス</InputLabel>
        <RhfInput
          placeholder="メールアドレス"
          name="email"
          control={control}
          rules={{
            required: 'メールアドレスを入力してください。',
            pattern: {
              value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
              message: '入力形式がメールアドレスではありません。',
            },
          }}
        />
        {errors.email?.message && <Text color="cautionRed" variant="caption12">{`※${errors.email.message}`}</Text>}
      </Box>
      <Box display="grid" justifyContent="center" pt={24}>
        <InputLabel>パスワード</InputLabel>
        <RhfInput
          placeholder="パスワード"
          type="password"
          name="password"
          control={control}
          rules={{
            validate: (value) => regExp.test(value) || '数字・大文字・小文字・特殊文字を使用してください。',
            minLength: { value: passwordLength, message: '8文字以上で入力してください' },
          }}
        />
        {errors.password?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.password.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            onSubmit();
          }}
        >
          ログイン
        </MainButton>
      </Box>
      <Box pt={24} display="grid" justifyContent="center">
        <TextLink to="/forgotPassword">パスワードを忘れた方はこちら</TextLink>
      </Box>
    </Box>
  );
}
