import { MainMenu } from 'components/mainMenuBar/types';

// メニューリストの宣言
// TODO: selectedのときなどアイコンの色がデザインとずれている。Iconコンポーネントで色を変えられるようにしたい
export const OperationalMainMenu: MainMenu[] = [
  {
    pageNo: 1,
    title: 'HOME',
    icon: { normal: 'top', selected: 'top' },
    link: '/',
    matchedRoutes: ['/'],
  },
  {
    pageNo: 2,
    title: '加盟店',
    icon: { normal: 'store', selected: 'store' },
    matchedRoutes: ['/s03', '/s03/:id', '/s04-01', '/s04-01/:offerId', '/s04-02', '/s04-02/:offerId'],
    accordionList: [
      {
        accordionTitle: '加盟店情報',
        accordionLink: '/s03',
        matchedRoutes: ['/s03', '/s03/:id'],
      },
      { accordionTitle: 'バイト募集情報', accordionLink: '/s04-01', matchedRoutes: ['/s04-01', '/s04-01/:offerId'] },
      { accordionTitle: '成約バイト状況', accordionLink: '/s04-02', matchedRoutes: ['/s04-02', '/s04-02/:offerId'] },
      { accordionTitle: '加盟店請求', accordionLink: '/s08-04', matchedRoutes: ['/s08-04'] },
    ],
  },
  {
    pageNo: 3,
    title: 'バイトラベラー',
    icon: { normal: 'hail', selected: 'hail' },
    matchedRoutes: ['/s05', '/s05/:id', '/S06', '/S06/:id'],
    accordionList: [
      { accordionTitle: '会員情報', accordionLink: '/s05', matchedRoutes: ['/s05', '/s05/:id'] },
      { accordionTitle: '本人確認', accordionLink: '/s06', matchedRoutes: ['/s06', '/s06/:id'] },
    ],
  },
  {
    pageNo: 4,
    title: '逆オファー',
    icon: {
      normal: 'reverseOffer',
      selected: 'reverseOffer',
    },
    link: '/s07',
    matchedRoutes: ['/s07', '/s07/:offerId'],
  },
  {
    pageNo: 5,
    title: 'レビュー管理',
    icon: {
      normal: 'rateReview',
      selected: 'rateReview',
    },
    link: '/S11',
    matchedRoutes: ['/S11', '/S11/:work_id'],
  },
  {
    pageNo: 6,
    title: '支払関連',
    icon: { normal: 'outcome', selected: 'outcome' },
    matchedRoutes: ['/s08-01', '/s08-05'],
    accordionList: [
      { accordionTitle: 'バイト代支払', accordionLink: '/s08-01', matchedRoutes: ['/s08-01'] },
      { accordionTitle: '払戻支払', accordionLink: '/s08-05', matchedRoutes: ['/s08-05'] },
    ],
  },
  {
    pageNo: 7,
    title: '通知配信',
    icon: { normal: 'mailOutline', selected: 'mailOutline' },
    link: '/s09',
    matchedRoutes: ['/s09', '/s09/:id'],
  },
  {
    pageNo: 8,
    title: '各種設定',
    icon: { normal: 'settings', selected: 'settings' },
    matchedRoutes: ['/s10-01', '/s10-02', '/s10-03', '/S10-04', '/S10-05', '/S10-08', '/s10-11'],
    accordionList: [
      { accordionTitle: 'アカウント設定', accordionLink: '/s10-01', matchedRoutes: ['/s10-01'] },
      { accordionTitle: '加盟店設定', accordionLink: '/s10-02', matchedRoutes: ['/s10-02'] },
      { accordionTitle: 'バイトラベラー設定', accordionLink: '/s10-03', matchedRoutes: ['/s10-03'] },
      { accordionTitle: 'eギフト設定', accordionLink: '/S10-08', matchedRoutes: ['/S10-08'] },
      { accordionTitle: '二段階認証設定', accordionLink: '/s10-04', matchedRoutes: ['/s10-04'] },
      { accordionTitle: '経理関連設定', accordionLink: '/S10-05', matchedRoutes: ['/S10-05'] },
      { accordionTitle: 'GMOあおぞら連携', accordionLink: '/s10-11', matchedRoutes: ['/s10-11'] },
    ],
  },
];
