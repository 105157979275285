/**
 * Figma ID: 10-01-02-05
 * 名称: ユーザー招待 新規登録
 */
import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';
import { useForm } from 'react-hook-form';

type Props = {
  onSubmitConfirmationCode: (confirmationCode: string) => Promise<void>;
  reSendConfirmationCode: () => Promise<void>;
};
export default function InputConfirmCode({ onSubmitConfirmationCode, reSendConfirmationCode }: Props) {
  const codeLength = 6;
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ inputCode: string }>({ reValidateMode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    onSubmitConfirmationCode(data.inputCode);
  });

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={24}>
      <Box width={376}>
        <Text variant="body14">メールに記載された確認コードを入力してください。</Text>
      </Box>
      <Box>
        <RhfInput
          placeholder="6桁のコードを入力"
          name="inputCode"
          control={control}
          rules={{ validate: (value) => value.length === codeLength || '6桁のコードを入力してください' }}
        />
        {errors.inputCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
        )}
      </Box>
      <Box pt={8}>
        <TextLink
          onClick={() => {
            reSendConfirmationCode();
          }}
        >
          確認コードを再送
        </TextLink>
      </Box>
      <Box>
        <MainButton
          disabled={!isValid}
          onClick={() => {
            onSubmit();
          }}
        >
          認証
        </MainButton>
      </Box>
    </Box>
  );
}
