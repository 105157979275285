import { Box } from 'components/box';
import { Tab } from 'components/newTab';
import React from 'react';
import type { Name } from 'components/icon/icon';
import { Icon } from 'components/icon';
import { useNotify } from '../provider/notifyProvider';
import { TabNumber, SystemStatus, BooleanEnum } from '../const ';
import { TabCommonContent } from './tabCommonContent';
import { ContentsPropsType, PageTabType } from '../type';

const ContentsPropsList: ContentsPropsType[] = [
  {
    label: '払戻請求',
    column: 't_pay',
    messageStatus: TabNumber.REFUND_REQUEST,
    sysId: SystemStatus.MEMBER_STORE,
    iconName: 'currencyYen',
    clearButton: true,
  },
  {
    label: '会員登録',
    column: 't_regist',
    messageStatus: TabNumber.MEMBERSHIP_REGISTRATION,
    sysId: SystemStatus.MEMBER_STORE,
    iconName: 'check',
    clearButton: true,
  },
  {
    label: '会員情報変更',
    column: 't_change',
    messageStatus: TabNumber.MEMBERSHIP_UPDATE,
    sysId: SystemStatus.MEMBER_STORE,
    iconName: 'personOutline',
    clearButton: true,
  },
  {
    label: '退会',
    column: 't_quit',
    messageStatus: TabNumber.MEMBERSIHP_CANCELLATION,
    sysId: SystemStatus.MEMBER_STORE,
    iconName: 'wavingHand',
    clearButton: true,
  },
];

export function MemberStore() {
  const notifyContext = useNotify();
  const { notification } = notifyContext;

  const items: {
    label: string;
    content: React.ReactNode;
    hasNotification?: boolean | undefined;
  }[] = ContentsPropsList.map((item) => {
    const column = item.column as keyof PageTabType;

    return {
      content: (
        <TabCommonContent
          hasAlert={notification[column] === BooleanEnum.TRUE}
          column={column}
          messageStatus={item.messageStatus}
          sysId={item.sysId}
          label={item.label}
          icon={<Icon name={item.iconName as Name} color="#16495F" />}
          clearButton={item.clearButton}
        />
      ),
      hasNotification: notification[column] === BooleanEnum.TRUE,
      label: item.label,
    };
  });

  return (
    <Box display="flex" flex="1">
      <Tab items={items} />
    </Box>
  );
}
