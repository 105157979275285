import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { ReactNode } from 'react';
import { NewUpdateModalStep } from '../constants';

/**
 * Figma ID: 10-03-06-04,10-03-06-10,10-03-08-04,10-03-08-10
 * 名称: 確認モーダル
 */

interface Props {
  title: string;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  onClose: () => void;
  content: ReactNode;
  mutation?: () => void;
}

export function CheckModal({ title, setModalStep, onClose, content, mutation }: Props) {
  const displayCheckModal = React.useMemo(
    () => ({
      width: 800,
      height: "auto",
      header: (
        <Text variant="h2" color="darkBlue">
          {title}
        </Text>
      ),
      content,
      footer: (
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton thin onClick={onClose} variant="secondary" width={104}>
            キャンセル
          </MainButton>
          <MainButton
            thin
            onClick={() => {
              setModalStep(2);
            }}
            variant="secondary"
            width={104}
          >
            戻る
          </MainButton>
          <MainButton thin onClick={mutation} variant="primary" width={104}>
            作成
          </MainButton>
        </Box>
      ),
      onClose: () => onClose,
    }),
    [content, mutation, onClose, setModalStep, title]
  );
  return { displayCheckModal };
}
