import { Box } from 'components/box';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { DatePicker } from 'components/datePicker';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { formatDate, splitString, formatJpDate } from 'components/utils';
import { DateTime, NewUpdateModalStep, ListTitleWidth } from 'pages/s10-03/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useAuth } from 'hooks/authProvider';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { ErrorMessage } from 'components/errorMessage';
import { ModalZindex } from 'components/const';
import { styled } from '@linaria/react';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { RhfSelect } from 'components/rhfSelect';
import { differenceInMilliseconds } from 'date-fns';
import { BenefitsSettingMembershipPrivilegeCampaignUpdateType, CreateBlockKey } from '../s10-03-types';
import { CompleteModal } from './complete-modal';
import { CheckModal } from './check-modal';
import {
  Message,
  addOneDay,
  checkAllTrue,
  checkBrandMessages,
  chipColor,
  chipText,
  flattenArray,
  formatHyphenDate,
  convertStringToArray,
  getBizNamesByIds,
  getBrandNamesByIds,
  getGiftCodeName,
  getStateNamesByIds,
  getViewBizNames,
  getViewBrandNames,
  leaderMenu,
  removeNullValues,
  splitComponent,
} from '../s10-03-utils';
import { RegionModal } from './region-modal';
import { TextSet } from '../components/textSet';

/**
 * Figma ID: 10-03-06-02, 10-03-08-02
 * 名称: キャンペーン新規作成 期間/eギフト/ブランドを選択
 */

type DefaultValues = {
  title: string;
  brandIds: string;
  beginEnd: string;
  beginDate: string;
  endDate: string;
  freeMonths?: number | null;
  stateNames: string;
  stateIds: string;
  egiftCode: string | number;
  inclResident?: boolean;
  bizBrandList: {
    bizId: string;
    brandIds: string[];
  }[];
};

interface Props {
  modalStep: NewUpdateModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  setBrand?: boolean;
  defaultValues?: DefaultValues;
  update: (data: BenefitsSettingMembershipPrivilegeCampaignUpdateType) => void;
  titles: {
    newTitleValue: string;
    copiedTitleValue: string;
  };
}

interface ModalData {
  title?: string; // キャンペーンタイトル
  brand_ids?: string[]; // ブランド
  begin_end?: string | null;
  end_date?: string | null;
  begin_date?: string | null;
  biz_names?: string;
  brand_names?: string;
  state_names?: string;
  state_ids?: string;
  valid_days?: number;
  ticket_num?: number;
  gift_name?: string;
  egift_code?: number;
  incl_resident_disp?: string;
  incl_resident?: boolean;
  viewBizNames?: string[];
  viewBrandNames?: string[];
}

const StyledChildCheckboxWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  margin-bottom: 8px;
  padding: 0 16px;
`;

export function SelectGiftBrand({ modalStep, setModalStep, setBrand, defaultValues, update, titles }: Props) {
  const { openErrorModal } = useErrorModal();

  const [modalData, setModalData] = useState<ModalData>();
  const [giftOption, setGiftOption] = useState<Array<gql.SEgiftCode | undefined>>();
  const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const { data } = gql.useGetMBusinessBrandQuery({ context: { clientName: 'master' } });
  const { data: sEgiftCode, error } = gql.useGetSEgiftCodeQuery();

  const {
    control,
    reset,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateBlockKey>({ reValidateMode: 'onSubmit' });

  const onClickHandler = handleSubmit((formData) => {
    if (formData) {
      const values = formData.brand_ids?.ids || [];
      const flatValues = values?.flat().flatMap((value) => value.optionValues);
      const ids = removeNullValues(flatValues);
      const brandNames = getBrandNamesByIds(ids, data?.getMBusinessBrand || []);
      const bizNames = getBizNamesByIds(ids, data?.getMBusinessBrand || []);
      const viewBrandNames = getViewBrandNames(ids, data?.getMBusinessBrand || []);
      const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
      setModalData({
        ...modalData,
        title: formData.title,
        begin_end: `${formatDate(formData?.begin_date || '')} ~ ${formatDate(formData?.end_date || '')}`,
        brand_ids: ids,
        brand_names: brandNames,
        biz_names: bizNames,
        viewBizNames,
        viewBrandNames,
        gift_name: getGiftCodeName(formData.gift, giftOption) || '',
        egift_code: Number(formData.gift),
      });
      setModalStep(NewUpdateModalStep.REGION);
    }
  });

  const resetModal = useCallback(() => {
    const viewBrandNames = getViewBrandNames([], data?.getMBusinessBrand || []);
    const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
    setModalData({
      title: '',
      begin_end: '',
      begin_date: '',
      end_date: '',
      biz_names: '',
      brand_names: '',
      state_names: '',
      brand_ids: [],
      state_ids: '',
      gift_name: '',
      egift_code: undefined,
      incl_resident_disp: '',
      viewBrandNames,
      viewBizNames,
    });
  }, [data?.getMBusinessBrand]);

  const bizIds = useMemo(
    () =>
      data?.getMBusinessBrand?.reduce((acc: number[], item) => {
        if (item?.id && !acc.includes(item?.id)) {
          acc.push(item?.id);
        }
        return acc;
      }, []),
    [data]
  );

  const brandOptions = useMemo(() => {
    // DBから取得した業種、ブランドデータから、ブランドのみを取得し1つの配列にまとめる
    const brand = data?.getMBusinessBrand?.flatMap((getMBusinessBrand) => getMBusinessBrand?.brand);

    // 取得した業種、ブランドのデータを業種毎の配列に成形
    const result = brand?.reduce((acc: Array<Array<typeof brand[number]>>, curr) => {
      if (curr) {
        const found = acc.find((e) => e[0]?.biz_id === curr.biz_id);
        if (found) {
          found.push(curr);
        } else {
          acc.push([curr]);
        }
      }
      return acc;
    }, []);

    //   checkBoxGroupのoptionsに設定する選択肢の生成
    const brandOptionDateSet = result?.map((group) => ({
      options: group.map((item) => ({
        value: String(item?.brand_id),
        label: item?.brand_name || '',
      })),
    }));

    return brandOptionDateSet;
  }, [data?.getMBusinessBrand]);

  const [stateIds, setStateIds] = useState<string[]>([]);

  const defaultSelectedBeginDate = useMemo(
    () => (defaultValues?.beginDate ? new Date(formatJpDate(defaultValues?.beginDate)) : null),
    [defaultValues]
  );
  const defaultSeletedEndDate = useMemo(
    () => (defaultValues?.endDate ? new Date(formatJpDate(defaultValues?.endDate)) : null),
    [defaultValues]
  );

  const initializeDefaultData = useCallback(
    (values?: DefaultValues) => {
      setSelectedBeginDate(defaultSelectedBeginDate);
      setSelectedEndDate(defaultSeletedEndDate);
      setStateIds(splitString(values?.stateIds || '', ','));
    },
    [defaultSelectedBeginDate, defaultSeletedEndDate]
  );

  useEffect(() => {
    const brandIdsResult = splitString(defaultValues?.brandIds || '', ',');
    const brandIds = flattenArray(brandIdsResult);
    const convertString = convertStringToArray(defaultValues?.brandIds || '');
    const flatValues = flattenArray(convertString);
    const viewBrandNames = getViewBrandNames(flatValues, data?.getMBusinessBrand || []);
    const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
    const dataSet = {
      title: defaultValues?.title,
      begin_end: defaultValues?.beginEnd,
      begin_date: defaultValues?.beginDate || '',
      end_date: defaultValues?.endDate || '',
      free_months_disp: defaultValues?.freeMonths?.toString() || '',
      state_names: defaultValues?.stateNames || '',
      brand_ids: brandIds,
      state_ids: defaultValues?.stateIds || '',
      egift_code: defaultValues?.egiftCode ? Number(defaultValues?.egiftCode) : undefined,
      incl_resident: defaultValues?.inclResident,
      viewBrandNames,
      viewBizNames,
    };
    setModalData(dataSet);
    setGiftOption(sEgiftCode?.getSEgiftCode);
    initializeDefaultData(defaultValues);
  }, [defaultValues, initializeDefaultData, data?.getMBusinessBrand, sEgiftCode]);

  useEffect(() => {
    if (error) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [error, openErrorModal]);

  // ギフトコードのオプション
  const giftCodeOptionDateSet = useMemo(
    () =>
      giftOption
        ? giftOption.map((gift) => ({
            value: String(gift?.egift_code) || '',
            label: gift?.name || '',
          }))
        : [
            {
              value: '',
              label: '',
            },
          ],
    [giftOption]
  );

  const backToDefaultValues = useCallback(() => {
    reset();
    setStateIds(splitString(defaultValues?.stateIds || '', ','));
    setSelectedBeginDate(defaultSelectedBeginDate);
    setSelectedEndDate(defaultSeletedEndDate);
  }, [reset, defaultValues?.stateIds, defaultSelectedBeginDate, defaultSeletedEndDate]);

  const onClose = useCallback(() => {
    backToDefaultValues();
    setModalStep(NewUpdateModalStep.NULL);
  }, [backToDefaultValues, setModalStep]);

  const { data: mAreaState } = gql.useGetMAreaStateQuery({ context: { clientName: 'master' } });

  // 地域モーダル作成
  const { displayRegionModal } = RegionModal({
    title: defaultValues ? 'キャンペーン複製編集　地域を選択' : 'キャンペーン新規作成　地域を選択',
    stateIds,
    setModalStep,
    setModalData: (values: string[]) => {
      const stateNames = getStateNamesByIds(values, mAreaState?.getMAreaState);

      setModalData({ ...modalData, state_ids: values.join(','), state_names: stateNames });
    },
    setRadioBotton: (value: boolean, label: string) => {
      setModalData({ ...modalData, incl_resident_disp: label, incl_resident: value });
    },
    onClose,
    getMAreaState: mAreaState?.getMAreaState,
    setBrand,
    inclResident: modalData?.incl_resident,
  });

  // 確認モーダルに渡すキャンペーンテーブルボディ作成
  const listItems = useMemo(() => {
    const row = {
      title: modalData?.title,
      chip: {
        color: chipColor(0),
        text: chipText(0),
      },
      leaderMenu: leaderMenu(0),
      rows: (
        <CampaignTableBody pa={0} gap={4}>
          <CampaignTableRow isDivider pa={0} gap={16} height={18}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                実施期間
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.begin_end}
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
          {setBrand ? splitComponent(modalData?.viewBizNames, modalData?.viewBrandNames) : null}
          {setBrand ? (
            <CampaignTableRow isDivider pa={0} gap={16} height={18}>
              <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
                <Text color="darkBlue" variant="caption12" bold>
                  該当地域在住者
                </Text>
              </CampaignTableItem>
              <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
                <Text color="blueGray" variant="caption12">
                  {modalData?.incl_resident_disp}
                </Text>
              </CampaignTableItem>
            </CampaignTableRow>
          ) : null}
          <CampaignTableRow isDivider pa={0} gap={16} height={18}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                eギフト
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.gift_name}
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
          <CampaignTableRow isDivider pa={0} gap={16} height={84}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={84} dividerHeight={84} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                地域
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={84} dividerHeight={84} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.state_names}
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
        </CampaignTableBody>
      ),
    };
    return row;
  }, [modalData, setBrand]);

  const auth = useAuth();
  const { userInfo } = auth;

  // 確認モーダル用キャンペーンテーブル
  const content = <CampaignTable listItems={[listItems]} frame simple bold />;
  // 確認モーダル生成
  const { displayCheckModal } = CheckModal({
    title: defaultValues ? 'キャンペーン複製編集　確認' : 'キャンペーン新規作成　確認',
    setModalStep,
    onClose: () => {
      onClose();
      displayRegionModal.onClose();
    },
    content,
    mutation: () => {
      update({
        begin_date: `${formatHyphenDate(modalData?.begin_date || '')} ${DateTime.BeginDateTime}`,
        end_date: `${formatHyphenDate(modalData?.end_date || '')} ${DateTime.EndDateTime}`,
        brand_ids: modalData?.brand_ids?.join(',') || '',
        state_ids: modalData?.state_ids || '',
        title: modalData?.title || '',
        umgmt_id: userInfo?.id || 0,
        egift_code: modalData?.egift_code || 0,
        incl_resident: modalData?.incl_resident,
      });
      onClose();
      displayRegionModal.reset();
    },
  });

  // 完了モーダル生成
  const { displayCompleteModal } = CompleteModal({
    title: defaultValues ? 'キャンペーン複製編集　完了' : 'キャンペーン新規作成　完了',
    onClose: () => {
      onClose();
      displayRegionModal.reset();
      resetModal();
    },
  });

  const beginDateHandleInput = useCallback(
    (date: Date | null) => {
      setSelectedBeginDate(date);
      setValue('begin_date', date || new Date());
      setModalData({ ...modalData, begin_date: date?.toString() || new Date().toString() });
    },
    [modalData, setValue]
  );

  const endDateHandleInput = useCallback(
    (date: Date | null) => {
      setSelectedEndDate(date);
      setValue('end_date', date || new Date());
      setModalData({ ...modalData, end_date: date?.toString() || new Date().toString() });
    },
    [modalData, setValue]
  );

  /**
   * 開始日と終了日の整合性をチェック
   * 開始日が終了日よりも後の場合、終了日を開始日に合わせる
   */
  useEffect(() => {
    if (selectedBeginDate) {
      const differenceRepeatDate = differenceInMilliseconds(selectedEndDate || 0, selectedBeginDate || 0);
      if (differenceRepeatDate <= 0) {
        setSelectedEndDate(addOneDay(selectedBeginDate || new Date()));
        setValue('end_date', addOneDay(selectedBeginDate || new Date()));
        setModalData({ ...modalData, end_date: addOneDay(selectedBeginDate || new Date()).toString() });
      }
    }
  }, [modalData, selectedBeginDate, selectedEndDate, setSelectedEndDate, setValue]);

  let heightSize = setBrand ? 546 : 406;
  // エラーがある場合ための高さ調整
  if (Object.keys(errors).length > 0) {
    heightSize += Object.keys(errors).length * 20;
  }

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case NewUpdateModalStep.INPUT:
        return {
          width: 800,
          height: heightSize,
          header: (
            <Text variant="h2" color="darkBlue">
              {defaultValues ? titles.copiedTitleValue : titles.newTitleValue}
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" gap={8}>
              <Box mb={10}>
                <InputLabel>キャンペーン名</InputLabel>
                <RhfInput
                  name="title"
                  control={control}
                  defaultValue={defaultValues?.title || ''}
                  placeholder="キャンペーン名を入力"
                  rules={{ required: '必須項目を入力' }}
                  fullWidth
                />
                {errors?.title && (
                  <Box>
                    <ErrorMessage>{errors?.title.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
              <Box alignItems="start" display="flex" gap={8} mb={10}>
                <TextSet
                  label="配布期間 開始期間を指定"
                  error
                  content={
                    <>
                      <DatePicker
                        width={160}
                        minDate={addOneDay(new Date())}
                        placeholderText="開始期間を指定"
                        selected={selectedBeginDate}
                        {...register('begin_date', {
                          required: '開始期間を選択してください。',
                          value: defaultValues?.beginDate
                            ? new Date(formatJpDate(defaultValues?.beginDate))
                            : undefined,
                        })}
                        onChange={beginDateHandleInput}
                      />
                      {errors?.begin_date && (
                        <Box pt={4}>
                          <ErrorMessage>{errors?.begin_date.message}</ErrorMessage>
                        </Box>
                      )}
                    </>
                  }
                />
                <Box mt={32}>
                  <Text color="liteGray" variant="body14" display="inline">
                    ~
                  </Text>
                </Box>
                <TextSet
                  label="終了期間を指定"
                  content={
                    <Box>
                      <DatePicker
                        width={160}
                        minDate={addOneDay(selectedBeginDate || new Date())}
                        placeholderText="終了期間を指定"
                        selected={selectedEndDate}
                        {...register('end_date', {
                          required: '終了期間を選択してください。',
                          value: defaultValues?.endDate ? new Date(formatJpDate(defaultValues?.endDate)) : undefined,
                        })}
                        onChange={endDateHandleInput}
                      />
                      {errors?.end_date && (
                        <Box pt={4}>
                          <ErrorMessage>{errors?.end_date.message}</ErrorMessage>
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Box>
              {setBrand ? (
                <>
                  {bizIds?.map((bizId, index) => {
                    const defaultSelectedBrands =
                      defaultValues?.bizBrandList.find((item) => item.bizId === bizId.toString())?.brandIds || [];
                    const brands = brandOptions ? brandOptions[index].options : [];
                    return (
                      <>
                        <RhfCheckbox
                          name={`brand_ids.views.${index}.value`}
                          control={control}
                          defaultValue={defaultSelectedBrands.length === brands.length}
                          onChange={(event) => {
                            const options = brandOptions ? brandOptions[index].options : [];
                            options.forEach((option, optionIndex) => {
                              /**
                               * 子ブランド全てにチェックを入れるか外すか
                               */
                              if (event.target.checked) {
                                setValue(
                                  `brand_ids.views.${index}.optionValues.${Number(option.value)}`,
                                  event.target.checked
                                );
                                setValue(
                                  `brand_ids.ids.${index}.optionValues.${Number(option.value)}`,
                                  options[optionIndex].value
                                );
                              } else {
                                setValue(`brand_ids.views.${index}.optionValues.${Number(option.value)}`, null);
                                setValue(`brand_ids.ids.${index}.optionValues.${Number(option.value)}`, null);
                              }
                            });
                          }}
                        >
                          <Text variant="h3" color="blueGray">
                            {data?.getMBusinessBrand
                              ? (data?.getMBusinessBrand?.find((element) => element?.id === bizId)?.name as string)
                              : ''}
                          </Text>
                        </RhfCheckbox>
                        <StyledChildCheckboxWrapper>
                          {brands.map((option) => (
                            <RhfCheckbox
                              name={`brand_ids.views.${index}.optionValues.${Number(option.value)}`}
                              control={control}
                              defaultValue={defaultSelectedBrands.includes(option.value)}
                              onChange={(event) => {
                                const allBrandsChecked = checkAllTrue(
                                  getValues(`brand_ids.views.${index}.optionValues`).filter((v) => v !== undefined),
                                  true
                                );
                                setValue(`brand_ids.views.${index}.value`, allBrandsChecked);
                                setValue(
                                  `brand_ids.ids.${index}.optionValues.${Number(option.value)}`,
                                  event.target.checked ? option.value : null
                                );
                              }}
                            >
                              {option.label}
                            </RhfCheckbox>
                          ))}
                        </StyledChildCheckboxWrapper>
                      </>
                    );
                  })}
                  {checkBrandMessages((errors?.brand_ids?.views as Message[]) || []) && (
                    <Box pt={4}>
                      <ErrorMessage>ブランドをチェックしてください</ErrorMessage>
                    </Box>
                  )}
                </>
              ) : null}
              <Box alignItems="center" backgroundColor="white" display="flex" height={78} width={336} gap={8} mt={11}>
                <TextSet
                  label="eギフトを選択"
                  content={
                    <>
                      <RhfSelect
                        width={336}
                        control={control}
                        name="gift"
                        options={giftCodeOptionDateSet}
                        defaultValue={defaultValues?.egiftCode.toString() || ''}
                        placeholder="選択"
                        rules={{ required: 'eギフトを選択してください' }}
                      />
                      {errors?.gift && (
                        <Box pt={4}>
                          <ErrorMessage>{errors?.gift?.message}</ErrorMessage>
                        </Box>
                      )}
                    </>
                  }
                />
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={(event) => {
                  onClickHandler(event);
                }}
                variant="primary"
                width={104}
              >
                次へ
              </MainButton>
            </Box>
          ),
          onClose,
        };
      case NewUpdateModalStep.REGION:
        return {
          width: displayRegionModal.width,
          height: displayRegionModal.height,
          header: displayRegionModal.header,
          content: displayRegionModal.content,
          footer: displayRegionModal.footer,
          onClose: displayRegionModal.onClose,
        };
      case NewUpdateModalStep.CHECK:
        return {
          width: displayCheckModal.width,
          height: displayCheckModal.height,
          header: displayCheckModal.header,
          content: displayCheckModal.content,
          footer: displayCheckModal.footer,
          onClose: displayCheckModal.onClose,
        };
      case NewUpdateModalStep.COMPLETE:
        return {
          width: displayCompleteModal.width,
          height: displayCompleteModal.height,
          header: displayCompleteModal.header,
          content: displayCompleteModal.content,
          footer: displayCompleteModal.footer,
          onClose: displayCompleteModal.onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose,
        };
    }
  }, [
    modalStep,
    defaultValues,
    titles,
    control,
    getValues,
    errors,
    selectedBeginDate,
    register,
    beginDateHandleInput,
    selectedEndDate,
    endDateHandleInput,
    setBrand,
    bizIds,
    giftCodeOptionDateSet,
    onClose,
    displayRegionModal,
    displayCheckModal,
    displayCompleteModal,
    data?.getMBusinessBrand,
    brandOptions,
    setValue,
    onClickHandler,
    heightSize,
  ]);
  return { displayModal };
}
