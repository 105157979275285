/**
 * Figma ID: 10-01-01-01, 10-01-01-02, 10-01-01-03
 * 名称: 管理アカウント
 */
import React, { useEffect } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { ThreeDotLeader } from 'components/threeDotLeader';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Card } from 'components/card';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useNavigate } from 'react-router-dom';
import { ModalZindex } from 'components/const';
import { CreateAccountModal } from './createAccountModal/createAccount';
import { CompleteCreateAccountModal } from './createAccountModal/completeCreateAccount';
import { UpdateMailModal } from './updateMailModal/updateMail';
import { InputConfirmCodeModal } from './updateMailModal/inputCode';
import { CompleteUpdateMailModal } from './updateMailModal/completeUpdateMail';
import { UpdatePasswordModal } from './updatePasswordModal/updatePassword';
import { CompleteUpdatePasswordModal } from './updatePasswordModal/completeUpdatePassword';
import { UpdatePermissionModal } from './updatePermissionModal/updatePermission';
import { CompleteUpdatePermissionModal } from './updatePermissionModal/completeUpdatePermission';
import { DeleteAccountModal } from './deleteAccountModal/delete';
import { CompleteDeleteAccountModal } from './deleteAccountModal/completeDelete';

const privilegeList = ['管理アカウント', '管理者', '一般'];
export const privilegeSelectOption = privilegeList.map((p, i) => ({ label: p, value: String(i) }));

export interface AccountSettingMemberModel {
  id?: number;
  email?: string;
  password?: string;
  privilege_name?: string;
  privilege?: number;
  name?: string;
  status?: number;
  firstName?: string;
  lastName?: string;
}

type CreateAccountPhaseModel = 'NULL' | 'Create' | 'Complete';
type UpdateMailPhaseModel = 'NULL' | 'SendMail' | 'InputCode' | 'Complete';
type UpdatePasswordPhaseModel = 'NULL' | 'UpdatePassword' | 'Complete';
type UpdatePermissionPhaseModel = 'NULL' | 'UpdatePermission' | 'Complete';
type DeletePhaseModel = 'NULL' | 'Delete' | 'Complete';

function S1001() {
  const {
    data: accountData,
    loading: accountDataLoading,
    refetch,
    error: accountDataError,
  } = gql.useGetVuMemberListQuery({ fetchPolicy: 'no-cache' });
  const navigate = useNavigate();
  const { updateEmail, confirmUpdateEmail, changePassword, userInfo, signOut } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [newEmail, setNewEmail] = React.useState<string>('');

  const [createAccountPhase, setCreateAccountPhase] = React.useState<CreateAccountPhaseModel>('NULL');
  const [updateMailPhase, setUpdateMailPhase] = React.useState<UpdateMailPhaseModel>('NULL');
  const [updatePasswordPhase, setUpdatePasswordPhase] = React.useState<UpdatePasswordPhaseModel>('NULL');
  const [updatePermissionPhase, setUpdatePermissionPhase] = React.useState<UpdatePermissionPhaseModel>('NULL');
  const [deletePhase, setDeletePhase] = React.useState<DeletePhaseModel>('NULL');

  const [updatePrivilegeTarget, setUpdatePrivilegeTarget] = React.useState<{
    id: number;
    name: string;
    privilege: number;
  } | null>(null);
  const [deleteAccountTarget, setDeleteAccountTarget] = React.useState<number | null>(null);

  const [addUMember] = gql.useAddupdUMemberMutation();
  const [deleteUMemberById] = gql.useDeleteUMemberByIdMutation();
  const [updateUMemberPrivilegeById] = gql.useUpdateUMemberPrivilegeByIdMutation();

  const { openErrorModal } = useErrorModal();

  const closeModal = () => {
    setUpdatePrivilegeTarget(null);
    setDeleteAccountTarget(null);
    setCreateAccountPhase('NULL');
    setUpdateMailPhase('NULL');
    setUpdatePasswordPhase('NULL');
    setUpdatePermissionPhase('NULL');
    setDeletePhase('NULL');
  };

  const generateThreeDotLeaderOptions = (item: { id: number; privilege: number }) => {
    const myId = userInfo?.id;
    const myPrivilege = userInfo?.privilege;
    if (myPrivilege === 0) {
      return myId === item.id
        ? [{ label: 'パスワード変更', value: 'updatePassword' }]
        : [
            { label: '権限変更', value: 'updatePermission' },
            { label: '削除', value: 'delete' },
          ];
    }
    if (myPrivilege === 1) {
      if (myId === item.id) {
        return [
          { label: 'メールアドレス編集', value: 'updateMail' },
          { label: 'パスワード変更', value: 'updatePassword' },
          { label: '権限変更', value: 'updatePermission' },
          { label: '削除', value: 'delete' },
        ];
      }
      if (item.privilege === 1) {
        return [
          { label: 'メールアドレス編集', value: 'updateMail' },
          { label: 'パスワード変更', value: 'updatePassword' },
          { label: '権限変更', value: 'updatePermission' },
          { label: '削除', value: 'delete' },
        ];
      }
      return [
        { label: '権限変更', value: 'updatePermission' },
        { label: '削除', value: 'delete' },
      ];
    }
    return [
      { label: 'メールアドレス編集', value: 'updateMail' },
      { label: 'パスワード変更', value: 'updatePassword' },
      { label: '削除', value: 'delete' },
    ];
  };

  const showThreeDotLeader = (item: { id: number; privilege: number }): boolean => {
    const myId = userInfo?.id;
    const myPrivilege = userInfo?.privilege;
    switch (myPrivilege) {
      case 0:
        return true;
      case 1:
        if (myId === item.id) {
          return true;
        }
        return item.privilege !== 0 && item.privilege !== 1;
      case 2:
        return item.id === myId;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (accountDataError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [accountDataError, openErrorModal]);

  return (
    <>
      {accountDataLoading || (isLoading && <Loading />)}
      <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
        <Box display="flex" justifyContent="space-between">
          <Text variant="h1" color="darkBlue">
            アカウント設定
          </Text>
          <MainButton
            icon="addCircle"
            thin
            onClick={() => {
              setCreateAccountPhase('Create');
            }}
          >
            <Text variant="h2" color="white">
              新規作成
            </Text>
          </MainButton>
        </Box>

        <Card overflow="hidden">
          <Box pa={16}>
            <Box display="flex" flexDirection="column" gap={8}>
              {accountData?.getVUMemberList.map((item) => (
                <React.Fragment key={item.id}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" height={28}>
                    <Box display="flex" alignItems="center" gap={16}>
                      <Box width={160}>
                        <Text variant="h3" color="darkBlue">
                          {item.name}
                          {userInfo?.id === item.id && ' (自分)'}
                        </Text>
                      </Box>
                      <Divider option="vertical" length={16} />
                      <Box width={104}>
                        <Text variant="caption12" color="blueGray">
                          {item.privilege_name}
                        </Text>
                      </Box>
                      <Divider option="vertical" length={16} />
                      <Box flex="auto">
                        <Text variant="caption12" color="blueGray">
                          {item.email}
                        </Text>
                      </Box>
                    </Box>
                    {showThreeDotLeader({ id: item.id, privilege: item.privilege }) && (
                      <ThreeDotLeader
                        menu={generateThreeDotLeaderOptions({ id: item.id, privilege: item.privilege })}
                        right={0}
                        onClick={(val) => {
                          switch (val) {
                            case 'updatePassword':
                              setUpdatePasswordPhase('UpdatePassword');
                              break;
                            case 'updatePermission':
                              setUpdatePrivilegeTarget({ id: item.id, name: item.name, privilege: item.privilege });
                              setUpdatePermissionPhase('UpdatePermission');
                              break;
                            case 'delete':
                              setDeleteAccountTarget(item.id);
                              setDeletePhase('Delete');
                              break;
                            default:
                              setUpdateMailPhase('SendMail');
                          }
                        }}
                      />
                    )}
                  </Box>
                  <Divider option="horizontal" />
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Card>
      </Box>

      {createAccountPhase === 'Create' && (
        <CreateAccountModal
          onClickClose={closeModal}
          toNextPhase={(value) => {
            // ユーザーに招待メールを送り、DBにユーザー情報を登録する
            // ※mutationを実行すると招待メールが送信される
            setIsLoading(true);
            addUMember({
              variables: {
                email: value.email,
                first_name: value.firstName,
                last_name: value.lastName,
                privilege: Number(value.privilege),
              },
            })
              .then(() => {
                refetch();
                setCreateAccountPhase('Complete');
                setIsLoading(false);
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: 'アカウントの作成に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {createAccountPhase === 'Complete' && <CompleteCreateAccountModal onClickClose={closeModal} />}

      {updateMailPhase === 'SendMail' && (
        <UpdateMailModal
          oldEmail={userInfo?.email || ''}
          onClickClose={closeModal}
          toNextPhase={(email) => {
            setIsLoading(true);
            updateEmail(email)
              .then(() => {
                setNewEmail(email);
                setUpdateMailPhase('InputCode');
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: 'メールアドレスの更新に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {updateMailPhase === 'InputCode' && (
        <InputConfirmCodeModal
          onClickClose={closeModal}
          toNextPhase={(confirmationCode) => {
            setIsLoading(true);
            confirmUpdateEmail(confirmationCode, newEmail)
              .then(() => {
                setUpdateMailPhase('Complete');
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: 'メールアドレスの更新に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {updateMailPhase === 'Complete' && (
        <CompleteUpdateMailModal
          onClickClose={() => {
            signOut().then(() => {
              navigate('/signIn');
            });
          }}
        />
      )}

      {updatePasswordPhase === 'UpdatePassword' && (
        <UpdatePasswordModal
          onClickClose={closeModal}
          toNextPhase={(value) => {
            setIsLoading(true);
            changePassword(value.oldPassword, value.newPassword)
              .then(() => {
                refetch();
                setUpdatePasswordPhase('Complete');
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: 'パスワードの変更に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {updatePasswordPhase === 'Complete' && <CompleteUpdatePasswordModal onClickClose={closeModal} />}

      {updatePermissionPhase === 'UpdatePermission' && (
        <UpdatePermissionModal
          targetName={updatePrivilegeTarget?.name ?? ''}
          targetPrivilege={String(updatePrivilegeTarget?.privilege)}
          onClickClose={closeModal}
          toNextPhase={(newPrivilege) => {
            if (!updatePrivilegeTarget) {
              closeModal();
              openErrorModal({ message: '権限の変更に失敗しました' });
              return;
            }
            setIsLoading(true);
            updateUMemberPrivilegeById({
              variables: { id: updatePrivilegeTarget.id, privilege: Number(newPrivilege) },
            })
              .then(() => {
                refetch();
                setUpdatePermissionPhase('Complete');
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: '権限の変更に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
      {updatePermissionPhase === 'Complete' && <CompleteUpdatePermissionModal onClickClose={closeModal} />}

      {deletePhase === 'Delete' && (
        <DeleteAccountModal
          isDeleteMe={userInfo?.id === deleteAccountTarget}
          toNextPhase={() => {
            if (!deleteAccountTarget) {
              closeModal();
              openErrorModal({ message: 'アカウントの削除に失敗しました' });
              return;
            }
            setIsLoading(true);
            deleteUMemberById({ variables: { id: deleteAccountTarget } })
              .then(() => {
                refetch();
                setDeletePhase('Complete');
              })
              .catch(() => {
                closeModal();
                openErrorModal({ message: 'アカウントの削除に失敗しました' });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          onClickClose={closeModal}
        />
      )}
      {deletePhase === 'Complete' && (
        <CompleteDeleteAccountModal
          isDeleteMe={userInfo?.id === deleteAccountTarget}
          onClickClose={closeModal}
          onClickCloseAndLogout={() => {
            signOut().then(() => {
              navigate('/signIn');
            });
          }}
        />
      )}
    </>
  );
}

export default S1001;
