import React from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { ThreeDotLeader } from '../threeDotLeader';
import { Divider } from '../newDivider';

export interface Props {
  text1: string | null | undefined;
  text2?: string | null | undefined;
  deadline: string | null | undefined;
  menu: { label: string; value: string }[];
  menuCallback: (value: string) => void;
}

const ListWrapper = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`;

const LeftWrapper = styled.div`
  flex: 1;
`;

const CenterWrapper = styled.div`
  flex: 1;
  padding: 0 20px;
  box-sizing: border-box;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export function EgiftList({ text1, text2, deadline, menu, menuCallback }: Props) {
  return (
    <ListWrapper>
      <LeftWrapper>
        <Text variant="h3" color="darkBlue">
          {text1}
        </Text>
      </LeftWrapper>
      {text2 ? (
        <>
          <Divider option="vertical" length={21} />
          <CenterWrapper>
            <Text variant="h3" color="darkBlue">
              {text2}
            </Text>
          </CenterWrapper>
        </>
      ) : null}
      <RightWrapper>
        <Divider option="vertical" length={21} />
        <Text variant="caption11" color="blueGray">
          発行期限
        </Text>
        <Text variant="caption12" color="black">
          {deadline}
        </Text>
        <ThreeDotLeader
          menu={menu}
          right={0}
          onClick={(threeDotLeaderCallback) => menuCallback(threeDotLeaderCallback)}
        />
      </RightWrapper>
    </ListWrapper>
  );
}
