import { Box } from 'components/box';
import { Card } from 'components/card';
import { Text } from 'components/text';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as gql from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { NotifyInfoStatus } from '../const';
import { DeleteNotifyInfoModal, EditNotifyInfoModal } from '../components/modal';

enum ModalType {
  NONE = 'none',
  DELETE = 'delete',
  EDIT = 'edit',
}

export function S09Details() {
  const params = useParams<{ id: string }>();
  const [activeModalType, setActiveModalType] = useState<ModalType>(ModalType.NONE);
  const { data, error, loading, refetch } = gql.useGetVuSendNotifyInfoByIdQuery({
    variables: { id: parseInt(params.id ?? '0', 10) },
  });
  const vuSendNotifyInfo: gql.VuSendNotifyInfo | undefined = data?.getVUSendNotifyInfoById;

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (error) {
      setActiveModalType(ModalType.NONE);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [error, openErrorModal]);

  return (
    <>
      {loading && <Loading />}
      <Box display="flex" flex="1" flexDirection="column" gap={25}>
        <Text variant="h1" color="darkBlue">
          通知配信
        </Text>
        <Card overflow="hidden">
          <Box display="flex" flexDirection="row" ma={16} justifyContent="space-between">
            <Box display="flex" flexDirection="row" gap={8} alignItems="center">
              <Text variant="h2" color="darkBlue">
                {vuSendNotifyInfo?.title}
              </Text>
              <Text variant="caption12" color="darkBlue">
                {`(${vuSendNotifyInfo?.status === NotifyInfoStatus.ROUGH_DRAFT ? '下書き' : '配信済'})`}
              </Text>
            </Box>
            {vuSendNotifyInfo?.status === NotifyInfoStatus.ROUGH_DRAFT && (
              <Box display="flex" flexDirection="row" gap={8}>
                <MainButton
                  width={94}
                  variant="secondary"
                  icon="trash"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                  onClick={() => setActiveModalType(ModalType.DELETE)}
                >
                  削除
                </MainButton>
                <MainButton width={94} icon="edit" onClick={() => setActiveModalType(ModalType.EDIT)}>
                  編集
                </MainButton>
              </Box>
            )}
          </Box>
          <Divider option="horizontal" />
          <Box display="flex" flexDirection="column" flex="1" ma={24} gap={16}>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="row" justifyContent="space-between" height={68}>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Text variant="caption12" color="blueGray">
                  配信先
                </Text>
                <Text variant="body14" color="liteGray">
                  {vuSendNotifyInfo?.to_kind_name}
                </Text>
                <Text variant="body14" color="liteGray">
                  {vuSendNotifyInfo?.brand_name}
                </Text>
              </Box>
              <Box display="flex" flexDirection="row" gap={24}>
                <Divider option="vertical" length={68} />
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Text variant="caption12" color="blueGray">
                    作成日時
                  </Text>
                  <Text variant="body14" color="liteGray">
                    {vuSendNotifyInfo?.do_day}
                  </Text>
                  <Text variant="body14" color="liteGray">
                    {vuSendNotifyInfo?.do_time}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" flex="1">
              <Text variant="body14" color="black" whiteSpace="pre-wrap" wordBreak="break-all">
                {vuSendNotifyInfo?.body}
              </Text>
            </Box>
          </Box>
        </Card>
      </Box>
      <DeleteNotifyInfoModal
        isOpen={activeModalType === ModalType.DELETE}
        onClose={() => {
          setActiveModalType(ModalType.NONE);
        }}
      />
      <EditNotifyInfoModal
        isOpen={activeModalType === ModalType.EDIT}
        onClose={() => {
          setActiveModalType(ModalType.NONE);
        }}
        data={vuSendNotifyInfo}
        refetch={() => {
          refetch();
        }}
      />
    </>
  );
}
