import React, { createContext, useContext, useState, useMemo } from "react";
import * as gql from 'graphql/graphql-ow';

/*
 * HOMEタブの通知情報用Provider
 * 
 */
type NotifyContextType = {
  notification: gql.VuNewlyHome;
  setNotification: React.Dispatch<
    React.SetStateAction<gql.VuNewlyHome>
  >;
};

// Contextオブジェクト作成
const NotifyContext = createContext<NotifyContextType>(
  {} as NotifyContextType
);
type ProviderProps = {
  children: React.ReactNode;
};
export const useNotify = () => useContext(NotifyContext);

// Providerをコンポーネント化する
export function NotifyProvider(props: ProviderProps) {
  // Propsでchildrenを取り出す
  const { children } = props;

  // screenNoを0で指定
  const [notification, setNotification ] = useState<gql.VuNewlyHome>({
    b_badge: 0,
    b_identfy: 0,
    b_join: 0,
    b_pay: 0,
    b_quit: 0,
    t_change: 0,
    t_pay: 0,
    t_quit: 0,
    t_regist: 0,
  });

  // グローバルで管理するState
  const NotifyProviderValue = useMemo(() => ({ notification, setNotification }), [notification, setNotification]);

  // valueを設定したProviderコンポーネントを返却
  return (
    <NotifyContext.Provider value={NotifyProviderValue}>{children}</NotifyContext.Provider>
  );
};