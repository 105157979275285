import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import React from 'react';
import { VtMemberBase } from 'graphql/graphql-ow';

import { SubMenuItemsType } from '../type';
import { BaseInfo } from './details';

/**
 * Figma ID: 03-12-01
 * 基本情報
 * BaseInfo
 */

export function UnRegisteredCard(props: { data: VtMemberBase | null }) {
  const { data } = props;

  const subMenuItems: SubMenuItemsType = [{ label: '基本情報', content: <BaseInfo id={data?.id ?? 0} /> }];

  return (
    <Card overflow="hidden">
      <SubMenu items={subMenuItems} />
    </Card>
  );
}
