import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useMemo } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { VTTicketMgtInfo } from '../s10-02-types';
import { CHIP_TEXT, ListTitleWidth } from '../constants';
import { chipColor, leaderMenu } from '../s10-02-utils';
/**
 * Figma ID: 10-02-10-01
 * 名称: トライアルチケット管理
 */

export interface Props {
  cpRow: Array<VTTicketMgtInfo | null>;
}

const makeListData = ({ cpRow }: Props) =>
  cpRow.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={0} gap={4} height="auto">
        <CampaignTableRow pt={18} pl={0} pb={18} pr={18} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              総発行数
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={104} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row?.issue_count}
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={320} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              ¥{row?.issue_price}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        <CampaignTableRow isDivider pt={18} pl={0} pb={18} pr={18} gap={16} height={18}>
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              総発行無効数
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={104} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row?.expired_count}
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={320} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12">
              ¥{row?.expired_price}
            </Text>
          </CampaignTableItem>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

export function TicketManagement() {
  const { data, loading } = gql.useGetVtTicketMgtInfoQuery();

  const listItems = useMemo(() => makeListData({ cpRow: data?.getVTTicketMgtInfo || [] }), [data]);

  if (loading) {
    return Loading();
  }

  return (
    <HideWrapper>
      <Box px={16} pt={0} pb={24}>
        <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
          <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
            <Text color="darkBlue" variant="h2">
              トライアルチケット管理
            </Text>
            <Text color="darkBlue" variant="caption12">
              トライアルチケットの総発行数や金額が確認できます
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <ScrollWrapper bottom={311}>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollWrapper>
      </Box>
    </HideWrapper>
  );
}
