/**
 * Figma ID: 01-01-01
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import InputMail from './InputMail';
import CompleteSendMail from './CompleteSendMail';
import ResetPassword from './ResetPassword';
import CompleteUpdatePassword from './CompleteUpdatePassword';
import { TitleHeader } from '../component/TitleHeader';

type ForgotPasswordPhaseModel = 'InputMail' | 'CompleteSendMail' | 'InputCodeAndPassword' | 'CompleteUpdatePassword';
export function ForgotPassword() {
  const [forgotPasswordPhase, setForgotPasswordPhase] = React.useState<ForgotPasswordPhaseModel>('InputMail');
  const auth = useAuth();
  const { openErrorModal } = useErrorModal();
  const [email, setEmail] = React.useState<string>('');

  const handleSendMail = async (_email: string) => {
    try {
      await auth.sendEmailToResetPassword(_email);
      setEmail(_email);
      setForgotPasswordPhase('CompleteSendMail');
    } catch (error) {
      openErrorModal({ message: 'メール送信に失敗しました。' });
    }
  };

  const handleResetPassword = async (args: { confirmationCode: string; password: string }) => {
    try {
      await auth.resetPassword(email, args.confirmationCode, args.password);
      setForgotPasswordPhase('CompleteUpdatePassword');
    } catch (error) {
      openErrorModal({ message: 'パスワードの再設定に失敗しました' });
    } finally {
      setEmail('');
    }
  };

  const content = () => {
    switch (forgotPasswordPhase) {
      case 'CompleteSendMail':
        return <CompleteSendMail nextPhase={() => setForgotPasswordPhase('InputCodeAndPassword')} />;
      case 'InputCodeAndPassword':
        return <ResetPassword onResetPassword={handleResetPassword} />;
      case 'CompleteUpdatePassword':
        return <CompleteUpdatePassword />;
      default:
        return <InputMail onSendMail={handleSendMail} />;
    }
  };

  return (
    <Box width="100vw" height="100vh" justifyContent="center" pt={40}>
      <TitleHeader />
      {content()}
    </Box>
  );
}
