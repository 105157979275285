import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Text } from 'components/text';
import { CreateTicketKey } from 'pages/s03/type';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAddUTrialTicketMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { ErrorMessage } from 'components/errorMessage';
import { useAuth } from 'hooks/authProvider';

enum ModalStep {
  CREATE_TICKET = 1,
  CONFIRM = 2,
  COMPLETE = 3,
}

export function CreateTicketModal(props: { isOpen: boolean; id: number; onClose: () => void; refetch: () => void }) {
  const { isOpen, id, onClose, refetch } = props;
  const { openErrorModal } = useErrorModal();
  const [step, setStep] = useState<ModalStep>(ModalStep.CREATE_TICKET);
  const {
    control,
    trigger,
    reset,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTicketKey>({ reValidateMode: 'onSubmit' });
  const [addUTrialTicket, { loading: addUTraialTicketLoading }] = useAddUTrialTicketMutation();

  const onModalClose = () => {
    reset();
    onClose();
    setStep(ModalStep.CREATE_TICKET);
  };
  const dateOfExpiry = () => {
    const dt = new Date();
    if (getValues('limitDate') === undefined) return dt;
    dt.setDate(dt.getDate() + Number(getValues('limitDate')));
    return dt;
  };

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      setStep(ModalStep.CONFIRM);
    }
  });

  const { userInfo } = useAuth();

  const createTicketModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        チケット付与
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" gap={17.5} pa={8} flex="1">
          <Box display="flex" flexDirection="column">
            <Text variant="body14">加盟店に新たにチケッ卜を付与します。</Text>
            <Text variant="body14">期限と枚数を設定してください。</Text>
          </Box>
          <Box display="flex" flexDirection="column" gap={8} width={142} height={78}>
            <Text variant="h3" color="blueGray">
              期限
            </Text>
            <Box display="flex" flexDirection="row" gap={8}>
              <RhfInput
                name="limitDate"
                control={control}
                width={120}
                placeholder="90"
                rules={{
                  required: '期限を入力してください',
                  min: { value: 1, message: '1以上の数字を入力してください' },
                  max: { value: 365, message: '365以下の数字を入力してください' },
                }}
                type="number"
              />
              <Box mt={8}>
                <Text variant="body14" color="blueGray">
                  日
                </Text>
              </Box>
            </Box>
            <Box>{errors?.limitDate && <ErrorMessage>{errors?.limitDate.message}</ErrorMessage>}</Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={8} width={142} height={78}>
            <Text variant="h3" color="blueGray">
              枚数
            </Text>
            <Box display="flex" flexDirection="row" gap={8}>
              <RhfSelect
                width={120}
                control={control}
                name="numOfSheets"
                options={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' },
                  { value: '3', label: '3' },
                  { value: '4', label: '4' },
                  { value: '5', label: '5' },
                  { value: '6', label: '6' },
                  { value: '7', label: '7' },
                  { value: '8', label: '8' },
                  { value: '9', label: '9' },
                  { value: '10', label: '10' },
                ]}
                rules={{ required: '枚数を選択してください' }}
              />

              <Box mt={8}>
                <Text variant="body14" color="blueGray">
                  枚
                </Text>
              </Box>
            </Box>
            <Box>{errors?.numOfSheets && <ErrorMessage>{errors?.numOfSheets.message}</ErrorMessage>}</Box>
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={(data) => {
            onClickHandler(data);
          }}
        >
          付与
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 418,
  };

  const confirmModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        チケット付与 確認
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" gap={16} pa={8} flex="1">
          <Text variant="body14">以下のチケットを付与してよろしいですか？</Text>
          <Box display="flex" flexDirection="column">
            <Text variant="body14">・運営からのプレゼント</Text>
            <Text variant="body14">{`・付与日：${new Intl.DateTimeFormat('ja-JP', {
              dateStyle: 'long',
              timeZone: 'JST',
            }).format(new Date())}（本日）`}</Text>
            <Text variant="body14">{`・期限：${getValues('limitDate')}日（${new Intl.DateTimeFormat('ja-JP', {
              dateStyle: 'long',
              timeZone: 'JST',
            }).format(dateOfExpiry())}）`}</Text>
            <Text variant="body14">{`・枚数：${getValues('numOfSheets')}枚`}</Text>
          </Box>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {addUTraialTicketLoading && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => setStep(ModalStep.CREATE_TICKET)}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            if (!userInfo) {
              openErrorModal({
                message:
                  'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
              });
              return;
            }

            addUTrialTicket({
              variables: {
                item: '運営からのプレゼント',
                ticket_num: Number(getValues('numOfSheets')),
                tpmem_id: id,
                umgmt_id: userInfo.id,
                valid_days: Number(getValues('limitDate')),
              },
            })
              .then((e) => {
                if (!e.errors) {
                  setStep(ModalStep.COMPLETE);
                } else {
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              })
              .then(() => {
                refetch();
              })
              .catch(() => {
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  zIndex: ModalZindex.ERRMODALZINDEX,
                });
              });
          }}
        >
          設定
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 309,
  };
  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        チケット付与 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        <Box display="flex" flexDirection="column" gap={16} pa={8} flex="1">
          <Text variant="body14">情報の変更が完了しました。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189,
  };

  const modalContent = {
    [ModalStep.CREATE_TICKET]: createTicketModal,
    [ModalStep.CONFIRM]: confirmModal,
    [ModalStep.COMPLETE]: completeModal,
  };

  return <Modal isOpen={isOpen} onClose={() => onModalClose()} {...modalContent[step]} overflow="visible" />;
}
