/**
 * Figma ID: 10-08-02-04
 * 名称: ギフト選択肢(新規作成)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { UseFormWatch } from 'react-hook-form';
import type { InputDataModel } from '../GiftChoice';

export function ConfirmHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        新規追加 確認
      </Text>
    </Box>
  );
}

type ConfirmContentModel = {
  watch: UseFormWatch<InputDataModel>;
};
export function ConfirmContent(props: ConfirmContentModel) {
  const { watch } = props;
  const currentDeadline = watch('deadline');
  const currentGiftName = watch('giftName');
  const deadline = `${String(currentDeadline?.getFullYear())}年${String(
    (currentDeadline?.getMonth() ?? 0) + 1
  )}月${String(currentDeadline?.getDate())}日`;
  return (
    <Box>
      <Box pb={16}>
        <Text variant="body14">以下の商品を追加してよろしいですか？</Text>
      </Box>
      <Box>
        <Text variant="body14">・{currentGiftName}</Text>
      </Box>
      <Box>
        <Text variant="body14">・発行期限:{deadline}</Text>
      </Box>
    </Box>
  );
}

type ConfirmFooterModel = {
  onClickClose: () => void;
  onClickNext: () => void;
  onClickBack: () => void;
};
export function ConfirmFooter(props: ConfirmFooterModel) {
  const { onClickClose, onClickBack, onClickNext } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickBack} width={104} variant="secondary">
        戻る
      </MainButton>
      <MainButton onClick={onClickNext} width={104}>
        更新
      </MainButton>
    </Box>
  );
}
