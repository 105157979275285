/**
 * Figma ID: 04-02-02
 * 名称: 募集情報（成約バイト状況）
 */
import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { Text } from 'components/text';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { VtArbeitOfferInfo, VtMemberBase, useGetVtMemberBaseByIdLazyQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ImageModal } from 'components/newModal';
import { TextLink } from 'components/textLink';
import { StoreModal } from 'modal/storeModal';
import { Divider } from 'components/newDivider';
import { charcterReplaceBr } from 'components/characterEdit/charcterReplaceBr';

interface Props {
  vtArbeitOfferInfo: VtArbeitOfferInfo;
}

const StyledOverflowWrapper = styled.div`
  height: calc(100vh - 223px);
  max-height: calc(100vh - 223px);
  overflow: auto;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 126px;
  height: 94.5px;
  object-fit: contain;
  cursor: pointer;
`;

// 募集情報
export function RecruitInfo({ vtArbeitOfferInfo }: Props) {
  const { openErrorModal } = useErrorModal();
  const [getVtMemberBaseById, { error: getVtMemberBaseByIdError }] = useGetVtMemberBaseByIdLazyQuery();
  React.useEffect(() => {
    if (getVtMemberBaseByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVtMemberBaseByIdError, openErrorModal]);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [vtMemberBase, setVtMemberBase] = React.useState<VtMemberBase | null>(null);

  /**
   * 加盟店モーダル表示
   */
  const showStoreModal = React.useCallback(
    (tpmem_id: number) => {
      (async () => {
        const result = await getVtMemberBaseById({ variables: { id: tpmem_id } });
        const vTMemberBase = result?.data?.getVTMemberBaseById;
        // 加盟店の情報を設定してモーダルを表示する
        if (vTMemberBase) {
          setVtMemberBase(vTMemberBase);
        }
      })();
    },
    [getVtMemberBaseById]
  );

  const items = () => [
    { label: '掲載No.', content: vtArbeitOfferInfo.offer_number },
    { label: '採用方式', content: vtArbeitOfferInfo.auto_decide ? '自動採用' : '手動採用' },
    {
      label: '掲載タイトル',
      content: (
        <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          <Text variant="body14">{vtArbeitOfferInfo.title}</Text>
        </div>
      ),
    },
    {
      label: '募集店',
      content: (
        <div
          role="button"
          style={{ cursor: 'pointer' }}
          tabIndex={0}
          onClick={() => showStoreModal(vtArbeitOfferInfo.tpmem_id)}
          onKeyDown={() => {}}
        >
          <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            <Text variant="body14" underline color="primaryBlue">
              {vtArbeitOfferInfo.name}
            </Text>
          </span>
        </div>
      ),
    },
    { label: '募集開始', content: vtArbeitOfferInfo.post_begin },
    { label: '募集締切', content: vtArbeitOfferInfo.post_end },
    { label: 'バイト期間', content: vtArbeitOfferInfo.begin_end },
    { label: '休憩', content: vtArbeitOfferInfo.break_time && `${vtArbeitOfferInfo.break_time}分` },
    {
      label: '時給',
      content:
        vtArbeitOfferInfo.hour_wage !== undefined &&
        vtArbeitOfferInfo.hour_wage !== null &&
        `¥${vtArbeitOfferInfo.hour_wage_disp ?? ''}`,
    },
    { label: '当日の店舗責任者', content: vtArbeitOfferInfo.chief },
    {
      label: '交通費',
      content:
        vtArbeitOfferInfo.trans_fee !== undefined &&
        vtArbeitOfferInfo.trans_fee !== null &&
        `¥${vtArbeitOfferInfo.trans_fee}`,
    },
    {
      label: '業務詳細',
      content: (
        <Text wordBreak="break-all" variant="body14">
          {charcterReplaceBr(vtArbeitOfferInfo.description)}
        </Text>
      ),
    },
    {
      label: '働くための条件',
      content: (
        <Text wordBreak="break-all" variant="body14">
          {charcterReplaceBr(vtArbeitOfferInfo.conditions)}
        </Text>
      ),
    },
    {
      label: '注意事項',
      content: (
        <Text wordBreak="break-all" variant="body14">
          {charcterReplaceBr(vtArbeitOfferInfo.caution)}
        </Text>
      ),
    },
    {
      label: '持ち物',
      content: (
        <Text wordBreak="break-all" variant="body14">
          {charcterReplaceBr(vtArbeitOfferInfo.belongings)}
        </Text>
      ),
    },
    {
      label: 'アクセス',
      content: (
        <Text wordBreak="break-all" variant="body14">
          {charcterReplaceBr(vtArbeitOfferInfo.access)}
        </Text>
      ),
    },
    {
      label: 'メイン画像',
      content: (
        <Box display="flex" flexWrap="wrap" gap={8}>
          {vtArbeitOfferInfo.image1 && (
            <StyledImage
              src={vtArbeitOfferInfo.image1 || ''}
              alt="サムネイル"
              onClick={() => setModalImage(vtArbeitOfferInfo.image1 || '')}
            />
          )}
          {vtArbeitOfferInfo.image2 && (
            <StyledImage
              src={vtArbeitOfferInfo.image2 || ''}
              alt="サムネイル"
              onClick={() => setModalImage(vtArbeitOfferInfo.image2 || '')}
            />
          )}
          {vtArbeitOfferInfo.image3 && (
            <StyledImage
              src={vtArbeitOfferInfo.image3 || ''}
              alt="サムネイル"
              onClick={() => setModalImage(vtArbeitOfferInfo.image3 || '')}
            />
          )}
          {vtArbeitOfferInfo.image4 && (
            <StyledImage
              src={vtArbeitOfferInfo.image4 || ''}
              alt="サムネイル"
              onClick={() => setModalImage(vtArbeitOfferInfo.image4 || '')}
            />
          )}
          {vtArbeitOfferInfo.image5 && (
            <StyledImage
              src={vtArbeitOfferInfo.image5 || ''}
              alt="サムネイル"
              onClick={() => setModalImage(vtArbeitOfferInfo.image5 || '')}
            />
          )}
        </Box>
      ),
    },
    {
      label: '添付ファイル',
      content: (
        <Box>
          <TextLink variant="body14" href={vtArbeitOfferInfo?.attached_file || ''}>
            {vtArbeitOfferInfo.attached_name || ''}
          </TextLink>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box px={24} pb={16} display="flex" flexDirection="column" alignItems="center" width="100%">
        <Box width="100%" height={60} display="flex" alignItems="center">
          <Text color="darkBlue" variant="h2">
            募集情報
          </Text>
        </Box>
        <Divider option="horizontal" />
        <StyledOverflowWrapper>
          <Info items={items()} />
        </StyledOverflowWrapper>
      </Box>

      <ImageModal isOpen={modalImage !== null} src={modalImage || ''} onClose={() => setModalImage(null)} />
      {vtMemberBase && (
        <StoreModal
          isOpen={Boolean(vtMemberBase)}
          vtMemberBase={vtMemberBase}
          onClickClose={() => setVtMemberBase(null)}
        />
      )}
    </>
  );
}
