import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import { ErrorModalProvider } from './components/error/errorModalProvider';
import { AuthProvider } from './hooks/authProvider';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import ApolloWrapper from './graphql/use-graphql-ow';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <ErrorModalProvider>
      <ApolloWrapper>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ApolloWrapper>
    </ErrorModalProvider>
  );
}

reportWebVitals();
