import React from 'react';

export function usePagenation() {
  const [limit, setLimit] = React.useState<10 | 20 | 30>(10);
  const [page, setPage] = React.useState<number>(1);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  return {
    limit,
    page,
    handleChangeLimit,
    handleChangePage,
    setLimit,
    setPage,
  };
}

export function useSort({ onBeforeSort }: { onBeforeSort?: () => void } = {}) {
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeSort = (key: string) => {
    onBeforeSort?.();
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };
  return {
    sort,
    handleChangeSort,
  };
}
