import * as gql from 'graphql/graphql-ow';
import {
  Control,
  UseFormGetValues,
  UseFormTrigger,
  UseFormClearErrors,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ResultRows } from 'components/const';
import { CreateNotifyInfoModalForm, ModalRet } from '../../../type';
import { DeliveryModalType, NotifyDeliveryModal } from '../notifyDeliveryModal';
import { getSendNotifyMutationParam } from '../../../util';

export function EditModal(
  isOpen: boolean,
  control: Control<CreateNotifyInfoModalForm>,
  handleSubmit: UseFormHandleSubmit<CreateNotifyInfoModalForm>,
  trigger: UseFormTrigger<CreateNotifyInfoModalForm>,
  clearErrors: UseFormClearErrors<CreateNotifyInfoModalForm>,
  getValues: UseFormGetValues<CreateNotifyInfoModalForm>,
  setValue: UseFormSetValue<CreateNotifyInfoModalForm>,
  onClose: () => void,
  errors: FieldErrors<CreateNotifyInfoModalForm>,
  onNext: () => void,
  toRoughDraft: () => void,
  refetch: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();
  const params = useParams<{ id: string }>();
  const [updateUSendNotifyDraftMutation, { loading: uSendNotifyDraftLoading }] =
    gql.useUpdateUSendNotifyDraftMutation();

  // 下書き保存処理
  const onSaveDraft = () => {
    // 配信先からMutationのパラメータを取得する
    const mutationParam = getSendNotifyMutationParam(
      getValues('deliveryDest'),
      getValues('business'),
      getValues('brand')
    );

    // mutationパラメータ ※必須項目
    const mutationVariables: gql.UpdateUSendNotifyDraftMutationVariables = {
      id: Number(params.id),
      title: getValues('title') ?? '',
    };

    // 任意項目設定
    const body = getValues('body');
    if (body) mutationVariables.body = body;
    if (mutationParam.bizId) mutationVariables.biz_id = Number(mutationParam.bizId);
    if (mutationParam.brandId) mutationVariables.brand_id = Number(mutationParam.brandId);
    if (mutationParam.sysId) mutationVariables.sys_id = Number(mutationParam.sysId);

    // 下書き保存処理
    updateUSendNotifyDraftMutation({
      variables: mutationVariables,
    })
      .then((e) => {
        if (e.data?.updateUSendNotifyDraft?.resultRows === ResultRows.UPDATED) {
          // 編集画面を更新する
          refetch();
          toRoughDraft();
        } else {
          onClose();
          openErrorModal({
            message: '下書きの保存に失敗しました。',
          });
        }
      })
      .catch(() => {
        onClose();
        openErrorModal({
          message:
            'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        });
      });
  };

  // 通知配信モーダル
  const modalContent = NotifyDeliveryModal(
    isOpen,
    DeliveryModalType.Edit,
    control,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    setValue,
    onClose,
    errors,
    onNext,
    onSaveDraft,
    uSendNotifyDraftLoading || false
  );

  return modalContent;
}
