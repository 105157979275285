/**
 * Figma ID: 10-01-05-01
 * 名称: 管理アカウント（権限変更）
 */
import React, { useState } from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { InputLabel } from 'components/inputLabel';
import { Select } from 'components/select';
import { Modal } from 'components/newModal';

type Props = {
  targetName: string;
  targetPrivilege: string;
  onClickClose: () => void;
  toNextPhase: (privilege: string) => void;
};

export function UpdatePermissionModal({ targetName, targetPrivilege, onClickClose, toNextPhase }: Props) {
  const [privilege, setPrivilege] = useState(targetPrivilege);

  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            権限変更
          </Text>
        </Box>
      }
      content={
        <Box>
          <Box>
            <Box pt={8} pb={9.5}>
              <InputLabel>氏名</InputLabel>
            </Box>
            <Text variant="body14">{targetName}</Text>
          </Box>
          <Box pt={19.5}>
            <Box pb={5.5}>
              <InputLabel>権限</InputLabel>
            </Box>
            <Select
              placeholder="権限を選択してください"
              value={privilege}
              options={[
                { label: '管理者', value: '1' },
                { label: '一般', value: '2' },
              ]}
              onChange={(value) => setPrivilege(value)}
            />
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton onClick={() => toNextPhase(privilege)} width={104} disabled={!privilege}>
            変更
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={324}
      width={800}
      overflow="visible"
    />
  );
}
