import { Box } from 'components/box';
import { ToggleButton } from 'components/toggleButton';
import React, { useState } from 'react';
import { Card } from 'components/card';
import {
  MainContentArea,
  MainContentHeader,
  MainContentTitle,
  MainContentWrapper,
} from 'components/pageLayout/mainContent';
import { NotifyProvider } from './provider/notifyProvider';
import { Bitravel, MemberStore } from './components';

/**
 * Figma ID: 02-02-01
 * 名称: HOME
 */

export function S02() {
  const [toggleState, setToggleState] = useState<string>('employee');

  return (
    <NotifyProvider>
      <MainContentWrapper>
        <MainContentHeader>
          <MainContentTitle text="HOME" />
          <Box display="flex" height={34} gap={8}>
            <ToggleButton
              currentValue={toggleState}
              onClick={(value) => setToggleState(value)}
              options={[
                { label: '加盟店', value: 'store' },
                { label: 'バイトラベラー', value: 'employee' },
              ]}
            />
          </Box>
        </MainContentHeader>
        <MainContentArea>
          <Card overflow="hidden">
            <Box display="flex" height="100%" pt={16}>
              {toggleState === 'store' ? <MemberStore /> : <Bitravel />}
            </Box>
          </Card>
        </MainContentArea>
      </MainContentWrapper>
    </NotifyProvider>
  );
}
