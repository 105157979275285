/**
 * Figma ID: 10-04-03
 * 名称: 2段階認証設定
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

export function CompleteActivateMfaHeader() {
  return (
    <Box>
      <Text color="darkBlue" variant="h2">
        二段階認証設定 完了
      </Text>
    </Box>
  );
}

export function CompleteActivateMfaContent() {
  return (
    <Box>
      <Text variant="body14">設定が完了しました。</Text>
    </Box>
  );
}

type CompleteUpdateModel = {
  onClickClose: () => void;
};
export function CompleteActivateMfaFooter(props: CompleteUpdateModel) {
  const { onClickClose } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
      <MainButton onClick={onClickClose} width={130} variant="secondary">
        閉じる
      </MainButton>
    </Box>
  );
}
