import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';

export interface Props {
  children: ReactNode;
}

const StyledErrorWrapper = styled.div`
  padding-left: 8px;
  height: 18px;
  display: flex;
  align-items: center;
`;

export function ErrorMessage({ children }: Props) {
  return (
    <StyledErrorWrapper>
      <Text variant="caption11" color="cautionRed">
        {children}
      </Text>
    </StyledErrorWrapper>
  );
}
