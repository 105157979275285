import React from 'react';
import { styled } from '@linaria/react';
import { Text } from '../text';
import { Divider } from '../newDivider/divider';

export interface Props {
  limit: 10 | 20 | 30;
  onClick: (value: 10 | 20 | 30) => void;
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 18px;
`;

const StyledButton = styled.button`
  display: contents;
  border: none;
  background-color: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export function LimitChanger({ limit, onClick }: Props) {
  return (
    <StyledDiv>
      <Divider option="vertical" length={12} />
      <StyledButton onClick={() => onClick(10)}>
        <Text variant="caption11" color={limit === 10 ? 'black' : 'primaryBlue'}>
          10件
        </Text>
      </StyledButton>
      <Divider option="vertical" length={12} />
      <StyledButton onClick={() => onClick(20)}>
        <Text variant="caption11" color={limit === 20 ? 'black' : 'primaryBlue'}>
          20件
        </Text>
      </StyledButton>
      <Divider option="vertical" length={12} />
      <StyledButton onClick={() => onClick(30)}>
        <Text variant="caption11" color={limit === 30 ? 'black' : 'primaryBlue'}>
          30件
        </Text>
      </StyledButton>
      <Divider option="vertical" length={12} />
    </StyledDiv>
  );
}
