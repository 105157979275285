import { SystemType } from 'components/const';

// 配信通知Mutation用パラメータ取得
// ※ 配信先種別によって、brandId、bizId、sysIdの値を取得する
export const getSendNotifyMutationParam = (deliveryDest = '', businessId = '', brand = '') => {
  let brandId = '';
  let bizId = '';
  let sysId = '';

  if (deliveryDest) {
    if (deliveryDest === 'バイトラベラーすべて') {
      sysId = String(SystemType.BT_MEMBER);
    } else {
      sysId = String(SystemType.MW_MEMBER);
      bizId = businessId;

      if (deliveryDest !== '加盟店すべて') {
        brandId = brand;
      }
    }
  }
  return { brandId, bizId, sysId };
};
