import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s03/type';
import React from 'react';

export function completeModal(onClose: () => void): ModalRet {
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        店舗ブロック登録 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pa={8} width="100%">
          <Text variant="body14">店舗ブロック登録が完了しました。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189,
  };

  return modalContent;
}
