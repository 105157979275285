import { styled } from '@linaria/react';
import { Icon } from 'components/icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type EyeIconProps<T> = {
  hasAlert?: boolean;
  status: T;
  url: string;
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  top: -8px;
  left: 15px;
`;

export function EyeIcon<T>({ hasAlert = false, status, url }: EyeIconProps<T>) {
  const navigate = useNavigate();

  return hasAlert ? (
    <Wrapper>
      <Indicator>
        <Icon name="indicatorRed" size={8} />
      </Indicator>
      <Icon name="eye" onClick={() => navigate(url, { state: { ...status } })} />
    </Wrapper>
  ) : (
    <Icon name="eye" onClick={() => navigate(url, { state: { ...status } })} />
  );
}
