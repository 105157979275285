import { GENERIC_COLOR, PRIMARY_COLOR } from 'components/assets/css/style';
import React from 'react';
import { styled } from '@linaria/react';

const Screen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: ${GENERIC_COLOR.MORDAL_bg};
`;

export function Loading(): JSX.Element {
  return (
    <Screen>
      <svg width="40" height="40" fill={PRIMARY_COLOR.BLUE} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 40 20 C 40 31.0457 31.0457 40 20 40 C 8.9543 40 0 31.0457 0 20 C 0 8.9543 8.9543 0 20 0 C 31.0457 0 40 8.9543 40 20 Z M 4 20 C 4 28.8366 11.1634 36 20 36 C 28.8366 36 36 28.8366 36 20 C 36 11.1634 28.8366 4 20 4 C 11.1634 4 4 11.1634 4 20 Z"
          opacity=".5"
          fill={GENERIC_COLOR.LITE_GRAY}
        />
        <path d="M 40 20 C 40 23.1562 39.253 26.2676 37.8201 29.0798 C 36.3872 31.892 34.3091 34.3252 31.7557 36.1803 C 29.2023 38.0355 26.246 39.26 23.1287 39.7538 C 20.0113 40.2475 16.8214 39.9965 13.8197 39.0211 L 15.0557 35.2169 C 17.4571 35.9972 20.0091 36.198 22.503 35.803 C 24.9968 35.408 27.3618 34.4284 29.4046 32.9443 C 31.4473 31.4601 33.1098 29.5136 34.2561 27.2638 C 35.4024 25.0141 36 22.525 36 20 H 40 Z">
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="0.75"
            from="0 20 20"
            to="360 20 20"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </Screen>
  );
}
