import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { Header } from 'layout/header';
import { MainMenuBar } from 'components/mainMenuBar/mainMenuBar';
import { MainMenu } from 'components/mainMenuBar/types';

export type Props = {
  children: React.ReactNode;
  currentRoute: string;
  mailAddress: string;
  userName: string;
  onClickLogout: () => void;
  mainMenuBarData: MainMenu[];
  headerContent?: JSX.Element;
};

const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const StyledColumn = styled.div`
  display: flex;
  height: calc(100vh - 48px);
`;

const StyledOpen = styled.div`
  flex: auto;
  height: calc(100vh - 96px);
  overflow: auto;
  padding: 24px;
`;

// 各画面の呼び出しベース（左にサイドメニュー）
export function Layout({
  children,
  currentRoute,
  mailAddress,
  userName,
  onClickLogout,
  mainMenuBarData,
  headerContent,
}: Props) {
  // サイドメニュー状態
  const [isOpenMainMenuBar, setIsOpenMainMenuBar] = useState<boolean>(true);
  return (
    <StyledWrapper>
      <Header
        isOpenMainMenuBar={isOpenMainMenuBar}
        currentRoute={currentRoute}
        mailAddress={mailAddress}
        userName={userName}
        toggleMainMenubar={(value) => setIsOpenMainMenuBar(value)}
        onClickLogout={onClickLogout}
      />
      <StyledColumn>
        <MainMenuBar
          currentRoute={currentRoute}
          isOpenMainMenuBar={isOpenMainMenuBar}
          toggleMainMenubar={(value) => setIsOpenMainMenuBar(value)}
          mainMenuBarData={mainMenuBarData}
          headerContent={headerContent}
        />
        <StyledOpen>{children}</StyledOpen>
      </StyledColumn>
    </StyledWrapper>
  );
}
