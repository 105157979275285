import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from 'react-hook-form';
import { MBusiness, MBusinessData } from 'graphql/graphql-ow';
import { InputType } from './constants';

export interface VTTicketMgtInfo {
  issue_count?: string; // 総発行数
  issue_price?: string; // 総金額
  expired_count?: string; // 総発行無効数
  expired_price?: string; // 総発行無効金額
}

export interface BasicModalType {
  ticketNumber: string; // 枚数
  expirationDays: string; // 期限日数
  recruitCount: string; // 募集件数
  recruitDays: string; // 募集期間
}

export type BasicModalTypeKeys = keyof BasicModalType;

export interface MiniModalViewDataTyep {
  title: {
    input: string;
    check: string;
    complete: string;
  };
  label: string;
  name: BasicModalTypeKeys;
  placeholder: string;
  inputType: InputType;
  unit: string;
  maxAmount?: string | number;
  options?: ModalOptionType[];
  data?: string | number | null;
  errorMessage?: string;
}

export interface EducationFeeSettinModalViewDataTyep {
  title: {
    input: string;
    check: string;
    complete: string;
  };
  max: number;
  min: number;
  default: number;
  biz_id?: number;
  bt_rank_id?: number;
  runk?: string | null;
  range?: string | null;
}

export interface ModalOptionType {
  label: string;
  value: string;
}

export interface ViewBrandIdsType {
  optionValues: string[];
}

export interface CampaignKindUpdateType {
  title: string;
  begin_date: string;
  end_date: string;
  ticket_num: number;
  valid_days: number;
  umgmt_id: number;
  state_ids: string;
  brand_ids: string;
  give_num?: number;
}

export interface RateOptionsType {
  defaultOptions: ModalOptionType[];
}

export interface MonthlyCampaignUpdateType {
  begin_date: string;
  brand_ids: string;
  end_date: string;
  free_months: number;
  state_ids: string;
  title: string;
  umgmt_id: number;
}

export type CreateBlockKey = {
  title: string;
  months: string;
  begin_end: string;
  begin_date: Date;
  end_date: Date;
  brand_ids?: {
    ids: {
      optionValues: Array<string | null>;
    }[];
    views: {
      value: boolean;
      optionValues: Array<boolean | string | null>;
    }[];
  };
};

export type RegionModalKey = {
  state_ids?: {
    ids: {
      optionValues: Array<string | null>;
    }[];
    views: {
      value: boolean;
      optionValues: Array<boolean | string | null>;
    }[];
  };
};

export type SelectDataTicketBrandKey = {
  title: string;
  valid_days: string;
  ticket_num: string;
  begin_end: Date;
  begin_date: Date;
  end_date: Date;
  brand_ids?: {
    ids: {
      optionValues: Array<string | null>;
    }[];
    views: {
      value: boolean;
      optionValues: Array<boolean | string | null>;
    }[];
  };
};

export type EducationFeeSettingKey = {
  minRate: string;
  maxRate: string;
  default: string;
};

/**
 * 名称: 業種/ブランド管理
 */
export enum ConrirmModalStep {
  NULL = 0,
  CONFIRM = 1,
  ICON_UPDATE = 2,
  ICON_UPDATE_CHECK = 3,
  ICON_UPDATE_COMPLETE = 4,
  BRAND_ADD = 5,
  BRAND_ADD_CHECK = 6,
  BRAND_ADD_COMPLETE = 7,
  LICENSE_ADD_SELECT = 8,
  LICENSE_ADD_CHECK = 9,
  LICENSE_ADD_COMPLETE = 10,
  SKILL_ADD_SELECT = 11,
  SKILL_ADD_CHECK = 12,
  SKILL_ADD_COMPLETE = 13,
}

export enum NewRegistrationModalStep {
  NULL = 0,
  INPUT = 1,
  CHECK = 2,
  COMPLETE = 3,
}

export type BusinessInfoSettingKey = {
  bizName: string;
  bizCode: string;
  brand: {
    name: string;
    code: string;
  }[];
  license: {
    lcValue: string;
  }[];
};

export interface NewRegisterProps {
  newBusinessModalStep: NewRegistrationModalStep;
  setNewBusinessModalStep: React.Dispatch<React.SetStateAction<NewRegistrationModalStep>>;
  onClose: () => void;
  mutation: () => void;
  refetch: () => void;
  control: Control<BusinessInfoSettingKey>;
  getValues: UseFormGetValues<BusinessInfoSettingKey>;
  errors: FieldErrors<BusinessInfoSettingKey>;
  // handleSubmit: (event?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<BusinessInfoSettingKey>;
  brandFields: FieldArrayWithId<BusinessInfoSettingKey, 'brand', 'id'>[];
  brandAppend: UseFieldArrayAppend<BusinessInfoSettingKey, 'brand'>;
  brandRemove: UseFieldArrayRemove;
  licenseFields: FieldArrayWithId<BusinessInfoSettingKey, 'license', 'id'>[];
  licenseAppend: UseFieldArrayAppend<BusinessInfoSettingKey, 'license'>;
  licenseRemove: UseFieldArrayRemove;
  fileIconInputRef: React.RefObject<HTMLInputElement>;
  fileSkillSectionInputRef: React.RefObject<HTMLInputElement>;
  fileSkillCheckInputRef: React.RefObject<HTMLInputElement>;
  selectedFileIcon: File | null;
  selectedFileSkillSection: File | null;
  selectedFileSkillCheck: File | null;
  setSelectedFileIcon: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedFileSkillSection: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedFileSkillCheck: React.Dispatch<React.SetStateAction<File | null>>;
}

export interface ConfirmEditProps {
  confirmEditModalStep: ConrirmModalStep;
  setConrirmEditModalStep: React.Dispatch<React.SetStateAction<ConrirmModalStep>>;
  onClose: () => void;
  onCancel: () => void;
  control: Control<BusinessInfoSettingKey>;
  getValues: UseFormGetValues<BusinessInfoSettingKey>;
  handleSubmit: UseFormHandleSubmit<BusinessInfoSettingKey>;
  errors: FieldErrors<BusinessInfoSettingKey>;
  brandFields: FieldArrayWithId<BusinessInfoSettingKey, 'brand', 'id'>[];
  brandAppend: UseFieldArrayAppend<BusinessInfoSettingKey, 'brand'>;
  brandRemove: UseFieldArrayRemove;
  licenseFields: FieldArrayWithId<BusinessInfoSettingKey, 'license', 'id'>[];
  licenseAppend: UseFieldArrayAppend<BusinessInfoSettingKey, 'license'>;
  licenseRemove: UseFieldArrayRemove;
  licenseListPrepare: () => void;
  businessInfo: MBusiness;
  mBusinessDataInfo: MBusinessData | null | undefined;
  // mBrand: (MBrand | null)[] | null | undefined;
  // mLicense: (MLicense | null)[] | null | undefined;
  // mSkillSection: (MSkillSection | null)[] | null | undefined;
  // mSkillCheck: (MSkillCheck | null)[] | null | undefined;
  iconMutation: () => void;
  brandMutation: () => void;
  licenseMutation: () => void;
  skillCheckMutation: () => void;
  // brandRefetch: () => void;
  // licenseRefetch: () => void;
  // skillSectionRefetch: () => void;
  // skillCheckRefetch: () => void;
  mBusinessDataByIdRefetch: () => void;
  fileIconInputRef: React.RefObject<HTMLInputElement>;
  fileSkillSectionInputRef: React.RefObject<HTMLInputElement>;
  fileSkillCheckInputRef: React.RefObject<HTMLInputElement>;
  selectedFileIcon: File | null;
  selectedFileSkillSection: File | null;
  selectedFileSkillCheck: File | null;
  setSelectedFileIcon: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedFileSkillSection: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedFileSkillCheck: React.Dispatch<React.SetStateAction<File | null>>;
}

// ICON変更
export interface IconProps {
  fileName: string | undefined | null;
  fileInfo: File | null;
  fileIconInputRef: React.RefObject<HTMLInputElement>;
  // useSetFile: () => React.Dispatch<React.SetStateAction<File | null>>;
}
// スキルチェック再登録
export interface SkillProps {
  SectionInfo: File | null;
  SkillInfo: File | null;
  fileSkillSectionInputRef: React.RefObject<HTMLInputElement>;
  fileSkillCheckInputRef: React.RefObject<HTMLInputElement>;
  // useSetFile: () => React.Dispatch<React.SetStateAction<File | null>>;
}
