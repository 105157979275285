import { Modal } from 'components/newModal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { VtMemberListCreateKey } from 'pages/s03/type';
import { EnterBrandBusinessModal } from './enterBrandBusinessModal';
import { EnterStoreInfoModal } from './enterStoreInfoModal';
import { ConfirmModal } from './confirmModal';
import { CompleteModal } from './completeModal';

enum ModalStep {
  EnterBrandBusiness = 1,
  EnterStoreInfo = 2,
  Confirm = 3,
  Complete = 4,
}

export function RegistrationModal(props: { isOpen: boolean; onClose: () => void }) {
  const { isOpen, onClose } = props;
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.EnterBrandBusiness);

  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<VtMemberListCreateKey>({ reValidateMode: 'onSubmit' });

  const onCloseModal = () => {
    reset();
    setModalStep(ModalStep.EnterBrandBusiness);
    onClose();
  };

  const enterBrandBusiness = EnterBrandBusinessModal(control, getValues, handleSubmit, errors, onCloseModal, () =>
    setModalStep(ModalStep.EnterStoreInfo)
  );
  const enterStoreInfo = EnterStoreInfoModal(
    control,
    getValues,
    handleSubmit,
    errors,
    onCloseModal,
    () => setModalStep(ModalStep.EnterBrandBusiness),
    () => setModalStep(ModalStep.Confirm)
  );
  const confirm = ConfirmModal(
    control,
    getValues,
    setValue,
    onCloseModal,
    () => setModalStep(ModalStep.EnterStoreInfo),
    () => setModalStep(ModalStep.Complete)
  );
  const complete = CompleteModal(control, getValues, onCloseModal);

  const modalMap = {
    [ModalStep.EnterBrandBusiness]: enterBrandBusiness,
    [ModalStep.EnterStoreInfo]: enterStoreInfo,
    [ModalStep.Confirm]: confirm,
    [ModalStep.Complete]: complete,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onCloseModal()}
      {...modalMap[modalStep]}
      overflow={modalStep === ModalStep.EnterStoreInfo ? 'auto' : 'visible'}
      height="auto"
    />
  );
}
