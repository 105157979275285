/**
 * Figma ID: 10-08-02-02
 * 名称: ギフト選択肢(新規作成)
 */
import React from 'react';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { DatePicker } from 'components/datePicker';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { RhfSelect } from 'components/rhfSelect';
import { EGiftType } from 'pages/s10-08/eGiftType';
import { useGetSEgiftCodeByThanksGift0Query } from 'graphql/graphql-ow';
import type { InputDataModel } from '../GiftChoice';

export function GiftNameAndTypeHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        新規追加
      </Text>
    </Box>
  );
}

type GiftNameAndTypeContentModel = {
  control: Control<InputDataModel>;
  register: UseFormRegister<InputDataModel>;
  setValue: UseFormSetValue<InputDataModel>;
  watch: UseFormWatch<InputDataModel>;
  errors: FieldErrors<InputDataModel>;
};
export function GiftNameAndTypeContent(props: GiftNameAndTypeContentModel) {
  const { control, register, setValue, watch, errors } = props;
  const { data } = useGetSEgiftCodeByThanksGift0Query();

  const selectedDate = watch('deadline');
  return (
    <Box>
      <Box pb={10}>
        <Text variant="body14">商品名、ギフトタイプ、発行期限を入力してください。</Text>
      </Box>
      <Box pb={27}>
        <InputLabel>商品名</InputLabel>
        <RhfInput
          control={control}
          name="giftName"
          placeholder="商品名"
          defaultValue=""
          rules={{ required: '商品名入力してください。' }}
        />
        {errors.giftName?.message && <Text color="cautionRed" variant="caption12">{`${errors.giftName.message}`}</Text>}
      </Box>
      <Box pb={27}>
        <InputLabel>ギフトタイプ</InputLabel>
        <RhfSelect
          control={control}
          name="giftType"
          placeholder="ギフトタイプを選択"
          defaultValue=""
          options={[
            { label: EGiftType.SELECT_GIFT, value: '1' },
            { label: EGiftType.SINGLE, value: '2' },
            { label: EGiftType.MINI, value: '3' },
            { label: EGiftType.QUO_CARD_PAY, value: '4' },
          ]}
          rules={{ required: 'ギフトタイプ入力してください。' }}
        />
        {errors.giftType?.message && <Text color="cautionRed" variant="caption12">{`${errors.giftType.message}`}</Text>}
      </Box>
      <Box pb={18}>
        <InputLabel>発行期限</InputLabel>
        <DatePicker
          placeholderText="発行期限"
          minDate={new Date()}
          selected={selectedDate}
          {...register('deadline', { required: '発行期限を選択してください。' })}
          onChange={(val) => setValue('deadline', val)}
        />
        {!selectedDate && <Text color="cautionRed" variant="caption12">{`${errors.deadline?.message ?? ''}`}</Text>}
      </Box>
    </Box>
  );
}

type GiftNameAndTypeFooterModel = {
  onClickClose: () => void;
  onClickNext: () => void;
};
export function GiftNameAndTypeFooter(props: GiftNameAndTypeFooterModel) {
  const { onClickClose, onClickNext } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickNext} width={104}>
        次へ
      </MainButton>
    </Box>
  );
}
