import React from 'react';
import { Text } from 'components/text';

export function MainContentTitle({ text }: { text: string }) {
  return (
    <Text variant="h1" color="darkBlue">
      {text}
    </Text>
  );
}
