import { CommonIdentificationStatus } from 'components/const';

export function identificationLabel(status: number, isMemberStore?: boolean) {
  switch (status) {
    case CommonIdentificationStatus.NOT_SUBMITTED:
      return '未提出';
    case CommonIdentificationStatus.UNCONFIRMED_IDENTITY:
      return isMemberStore ? '確認中' : '本人未確認';
    case CommonIdentificationStatus.CONFIRMED_IDENTITY:
      return isMemberStore ? '承認済' : '本人確認済';
    case CommonIdentificationStatus.REJECTED:
      return isMemberStore ? '未提出' : '非承認';
    case CommonIdentificationStatus.REQUEST_RECONFIRMATION:
      return isMemberStore ? '確認中' : '再確認依頼';
    case CommonIdentificationStatus.EXPIRATION_RESIDENCE:
      return isMemberStore ? '未提出' : '在留期限切';
    case CommonIdentificationStatus.RENEWAL_RESIDENCE:
      return isMemberStore ? '確認中' : '在留更新';
    default:
      return '-';
  }
}
