import { usePagenation } from 'components/utils';
import React, { useCallback } from 'react';
import * as gql from 'graphql/graphql-ow';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { useErrorModal } from 'components/error/errorModalProvider';
import { MessageType, SystemType } from 'components/const';
import { useNotify } from '../provider/notifyProvider';
import { useRefetchHomeApi, useRefetchListApi } from '../hooks';
import { RowAction, TabNumber } from '../const ';
import { ListItemsType, PageTabType } from '../type';
import { ListRow, List } from './CustomList';

type PropType = {
  hasAlert?: boolean;
  icon: React.ReactNode;
  label: string;
  clearButton?: boolean;
  messageStatus: number;
  sysId: number;
  column: string;
};

export function TabCommonContent({
  hasAlert = false,
  icon,
  label,
  clearButton = false,
  messageStatus,
  sysId,
  column,
}: PropType) {
  // ページネーション
  const { limit, page, handleChangeLimit, handleChangePage, setPage } = usePagenation();
  const [dataSize, setDataSize] = React.useState<number>(0);
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();

  const notifyContext = useNotify();
  const { setNotification } = notifyContext;

  const { query, data, loading: listLoading, refetch, called } = useRefetchListApi(messageStatus, sysId);
  const { home, refetch: refetchNewlyHome } = useRefetchHomeApi();

  // タブ毎のページ情報
  // ※Paginationが別タブでも共有されてしまうのでタブ毎にページ情報を保持する
  const [tabPage, setTabPage] = React.useState<PageTabType>({
    b_badge: 1,
    b_identfy: 1,
    b_join: 1,
    b_pay: 1,
    b_quit: 1,
    t_change: 1,
    t_pay: 1,
    t_quit: 1,
    t_regist: 1,
  });

  // 初回描画制御
  const [isInitialTabRender, setIsInitialTabRender] = React.useState<boolean>(true);

  // タブが切り替わったらタブ毎のページ情報に更新する
  React.useEffect(() => {
    setPage(tabPage[column as keyof PageTabType]);
  }, [tabPage, column, setPage]);

  // タブ通知情報を設定する
  const setTabNotify = useCallback(
    (tabList: gql.VuNewlyHome) => {
      if (!tabList) return;

      setNotification({
        b_pay: tabList.b_pay || 0,
        b_identfy: tabList.b_identfy || 0,
        b_join: tabList.b_join || 0,
        b_quit: tabList.b_quit || 0,
        b_badge: tabList.b_badge || 0,
        t_change: tabList.t_change || 0,
        t_pay: tabList.t_pay || 0,
        t_quit: tabList.t_quit || 0,
        t_regist: tabList.t_regist || 0,
      });
    },
    [setNotification]
  );

  // HOME画面更新
  const refetchHome = useCallback(async () => {
    // HOME画面更新
    await refetchNewlyHome();

    const tabList = home ?? [];
    if (tabList) {
      setTabNotify(tabList);
    }
  }, [home, refetchNewlyHome, setTabNotify]);

  // 既読更新
  const [updateReadMutation, { loading: updateReadLoading, error: updateReadError }] =
    gql.useUpdateURecvNotifyReadBySysIdKindMutation();

  const updateRead = useCallback(async () => {
    const result = await updateReadMutation({ variables: { kind: messageStatus, sys_id: sysId } });

    if (result.data?.updateURecvNotifyReadBySysIdKind?.resultRows === 0) {
      openErrorModal({ message: '既読の更新に失敗しました。' });
    }
  }, [messageStatus, sysId, updateReadMutation, openErrorModal]);

  React.useEffect(() => {
    if (updateReadError) {
      openErrorModal({ message: '既読の更新に失敗しました。' });
    }
  }, [updateReadError, openErrorModal]);

  // クリック時の画面遷移実装
  const onClickRow = useCallback(
    (item: gql.URecvNotify | null) => {
      if (!item) return;
      const screenNo = item.screen_no;
      const btmemId = item.ref_no ?? '0';

      switch (screenNo) {
        case RowAction.TRANSIT_MYPAGE_SCREEN_03_12:
        case RowAction.TRANSIT_MYPAGE_SCREEN_03_04:
          navigate(`/s03/${btmemId}`);
          break;
        case RowAction.TRANSIT_MYPAGE_SCREEN_05:
          navigate(`/s05/${btmemId}`);
          break;
        case RowAction.TRANSIT_MYPAGE_SCREEN_06:
          navigate(`/s06/${btmemId}`);
          break;
        case RowAction.TRANSIT_MYPAGE_SCREEN_08_01:
          navigate('/s08-01');
          break;
        case RowAction.TRANSIT_MYPAGE_SCREEN_08_05:
          navigate('/s08-05');
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const listItems: ListItemsType[] = [];

  data?.forEach((item) => {
    const text = item.title;
    const recvDate = item.recv_date;

    listItems.push({
      uniqueKey: item.id,
      text: (
        <ListRow item={item} onClickRow={() => onClickRow(item)}>
          {text}
        </ListRow>
      ),
      dateTime: recvDate ?? '',
      hasAlert: item.is_read ?? false,
    });
  });

  React.useEffect(() => {
    // 初期表示時は運営よりタブを表示した再に強制的にHOME画面を更新する
    // 別画面から戻ってきたタイミングでタブの通知情報が更新されない為
    if (messageStatus === TabNumber.PAYMENT_REQUEST && isInitialTabRender === true) {
      (async () => {
        await refetchHome();
        setIsInitialTabRender(false);
      })();
    }
  }, [messageStatus, isInitialTabRender, refetchHome]);

  React.useEffect(() => {
    const refetchData = async () => {
      try {
        await refetch();

        // 退会タブの場合は、2回refetchすると既読更新されるのでrefetchHomeしないようにする
        if (sysId === SystemType.BT_MEMBER && messageStatus === MessageType.DELETE) return;

        await refetchHome();
      } catch (error) {
        openErrorModal({
          title: 'APIエラー',
          message: 'データの取得に失敗しました',
        });
      }
    };

    const queryData = async () => {
      try {
        await query();
        await refetchHome();
      } catch (error) {
        openErrorModal({
          title: 'APIエラー',
          message: 'データの取得に失敗しました',
        });
      }
    };

    if (!called) queryData();
    else refetchData();
  }, [refetch, refetchHome, called, query, openErrorModal, sysId, messageStatus]);

  // データサイズ設定
  React.useEffect(() => {
    setDataSize(data?.length || 0);
  }, [data]);

  const tabHeight = 40;
  const headerHeight = 60;
  const dividerHeight = 1;
  const totalHeaderHeight = headerHeight + dividerHeight;

  return (
    <>
      {(listLoading || updateReadLoading) && <Loading />}
      <Box display="flex" flexDirection="column" width="100%" height={`calc(100% - ${tabHeight}px)`}>
        <Box
          display="flex"
          flexDirection="row"
          flex="1"
          justifyContent="space-between"
          alignItems="center"
          pa={16}
          minHeight={headerHeight}
          maxHeight={headerHeight}
        >
          <Box display="flex" flex="row" alignItems="center" gap={8}>
            {icon}
            <Text color="darkBlue" variant="h2">
              {label}
            </Text>
          </Box>
          {clearButton && (
            <MainButton
              variant="secondary"
              onClick={() => {
                (async () => {
                  // バイトラベル会員の退会については情報取得した時点でデータ上は既読状態になっているので
                  // タブ情報の通知状態のみ更新する ※refetchHomeだと何故か更新されない
                  if (sysId === SystemType.BT_MEMBER && messageStatus === MessageType.DELETE) {
                    // タブ情報のみ更新
                    const result = await refetchNewlyHome();
                    const tabList = result.data.getURecvNotifyByS3K1.home ?? [];
                    if (tabList) {
                      setTabNotify(tabList);
                    }

                    return;
                  }
                  // 既読更新
                  await updateRead();
                  // HOME画面更新
                  await refetchHome();
                })();
              }}
              disabled={!hasAlert}
              width={102}
              thin
            >
              既読にする
            </MainButton>
          )}
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="auto" px={24} height={`calc(100% - ${totalHeaderHeight}px)`}>
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page ?? 0}
            onChangeLimit={handleChangeLimit}
            onChangePage={(resultPage) => {
              handleChangePage(resultPage);
              tabPage[column as keyof PageTabType] = resultPage;
              setTabPage(tabPage);
            }}
          >
            <List listItems={listItems} page={page} limit={limit} />
          </PaginationBox>
        </Box>
      </Box>
    </>
  );
}
