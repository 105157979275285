import React from 'react';
import { Box } from 'components/box';

const marginBottom = 24;
const height = 32;
export const mainContentHeaderHeight = marginBottom + height;

export function MainContentHeader({ children }: { children: React.ReactNode }) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={marginBottom}
      height={height}
      width="100%"
    >
      {children}
    </Box>
  );
}
