import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s09/type';
import React from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { ModalHeaderContentWrapper } from 'components/pageLayout/ModalHeaderContentWrapper';
import { useErrorModal } from 'components/error/errorModalProvider';
import { useAuth } from 'hooks/authProvider';
import { UseFormGetValues } from 'react-hook-form';
import { CreateNotifyInfoModalForm } from '../../../type';
import { getSendNotifyMutationParam } from '../../../util';

export function ConfirmModal(
  id: string | undefined,
  getValues: UseFormGetValues<CreateNotifyInfoModalForm>,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void,
  refetch: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();

  const [updateUSendNotifyMutation, { loading: uSendNotifyLoading }] = gql.useUpdateUSendNotifyMutation();

  const deliveryDest = getValues('deliveryDest');
  const brand = getValues('brand');
  const [getMBrandById, { loading: mBrandByIdLoading }] = gql.useGetMBrandByIdLazyQuery();
  // ブランド情報をState管理
  const [mBrandByIdData, setBrandDataById] = React.useState<gql.MBrand | undefined>(undefined);

  React.useEffect(() => {
    if (brand !== undefined && brand !== null && brand !== '' && brand !== '0') {
      getMBrandById({
        context: { clientName: 'master' },
        variables: { mid: Number(brand) },
      })
        .then((data) => {
          if (data.error) {
            throw new Error(data.error.message);
          }
          setBrandDataById(data.data?.getMBrandById as gql.MBrand);
        })
        .catch((e) => {
          if (e instanceof Error) {
            onClose();
            openErrorModal({
              message:
                'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            });
          }
        });
    }
  }, [brand, getMBrandById, onClose, openErrorModal]);
  const getBrandName = () => {
    if (brand === undefined) return '';
    return mBrandByIdData?.name || '';
  };

  const auth = useAuth();

  const modalContent = {
    header: (
      <ModalHeaderContentWrapper>
        <Text variant="h2" color="darkBlue">
          配信
        </Text>
      </ModalHeaderContentWrapper>
    ),
    content: (
      <Box display="flex" flexDirection="column" flex="1" gap={16}>
        <Text variant="body14">作成したお知らせ「{getValues('title')}」を以下に配信します。</Text>
        <Box display="flex" flexDirection="column">
          <Text variant="body14">・{getValues('deliveryDest')}</Text>
          {deliveryDest !== '加盟店すべて' && deliveryDest !== 'バイトラベラーすべて' && (
            <Text variant="body14">・{getBrandName()}</Text>
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Text variant="body14">よろしいですか？</Text>
          <Text variant="body14">この操作は中止できません。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {(uSendNotifyLoading || mBrandByIdLoading) && <Loading />}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            // 配信先からMutationのパラメータを取得する
            const mutationParam = getSendNotifyMutationParam(
              deliveryDest,
              String(mBrandByIdData?.biz_id ?? 0),
              getValues('brand')
            );

            // mutationパラメータ ※必須項目
            const mutationVariables: gql.UpdateUSendNotifyMutationVariables = {
              id: Number(id),
              body: getValues('body') ?? '',
              sys_id: Number(mutationParam.sysId),
              title: getValues('title') ?? '',
              umgmt_id: Number(auth.userInfo?.id),
            };

            // mutationパラメータ ※任意項目
            // 任意項目設定
            if (mutationParam.bizId) mutationVariables.biz_id = Number(mutationParam.bizId);
            if (mutationParam.brandId) mutationVariables.brand_id = Number(mutationParam.brandId);

            updateUSendNotifyMutation({
              variables: mutationVariables,
            })
              .then((data) => {
                if (data.errors && data.errors.length > 0) {
                  onClose();
                  openErrorModal({
                    message: '配信出来ませんでした。',
                  });
                } else {
                  refetch();
                  onNext();
                }
              })
              .catch((e) => {
                // 通信自体ができない場合
                if (e instanceof Error) {
                  onClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              });
          }}
        >
          配信
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 'auto',
  };

  return modalContent;
}
