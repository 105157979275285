import React, { useId } from 'react';
import { styled } from '@linaria/react';
import { Select } from '../select';
import { InputLabel } from '../inputLabel';
import { ErrorMessage } from '../errorMessage';

export interface Props {
  label: string;
  optional?: boolean;
  value: string;
  options: {
    label: string;
    value: string;
  }[];
  onChange: (value: string, label: string) => void;
  placeholder?: string;
  errorMessage?: string;
  width?: number;
  fullWidth?: boolean;
}

const StyledDiv = styled.div<{ width: number; fullWidth?: boolean }>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  height: 78px;
`;

const StyledLabelWrapper = styled.div`
  margin-bottom: 4px;
  height: 24px;
`;

export function SelectForm({
  label,
  value,
  options,
  placeholder,
  onChange,
  optional,
  errorMessage,
  width = 328,
  fullWidth,
}: Props) {
  const id = useId();

  return (
    <StyledDiv width={width} fullWidth={fullWidth}>
      <StyledLabelWrapper>
        <InputLabel htmlFor={id} optional={optional} width={width} fullWidth={fullWidth}>
          {label}
        </InputLabel>
      </StyledLabelWrapper>
      <Select
        options={options}
        value={value}
        width={width}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onChange={onChange}
        error={Boolean(errorMessage)}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </StyledDiv>
  );
}
