import { Box } from 'components/box';
import React, { useEffect, useMemo } from 'react';
import { useGetVtMemberBlockListByIdQuery, useDeleteTTpmemBlockByIdMutation } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { List } from 'components/list';
import { ListHeaderType, ListItemsType } from 'pages/s03/type';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { UnblockModal, CreateBlockModal } from 'pages/s03/components/modal';
import { useErrorModal } from 'components/error/errorModalProvider';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';

/**
 * Figma ID: 03-07-01
 * 店舗ブロック
 * Review
 */

enum ModalType {
  None = 'none',
  BaseInfoEdit = 'baseInfoEdit',
  CreateBlock = 'createBlock',
}

export function Block(props: { id: number; name: string }) {
  const { id, name } = props;
  const {
    data: vTMemberBlockListData,
    loading: vTMemberBlockListLoading,
    error: vTMemberBlockListError,
    refetch: vTMemberBlockListRefetch,
  } = useGetVtMemberBlockListByIdQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  const [deleteTTpmemBlockByIdMutation, { loading: tTpmemBlockByIdLoading, error: tTpmemBlockByIdError }] =
    useDeleteTTpmemBlockByIdMutation();
  const blockListMember = vTMemberBlockListData?.getVTMemberBlockListById.member;
  const blockListBlockData = vTMemberBlockListData?.getVTMemberBlockListById.block;
  let blockListBlock = blockListBlockData;
  const { openErrorModal } = useErrorModal();
  const { page, handleChangeLimit, handleChangePage, limit } = usePagenation();
  const { sort, handleChangeSort } = useSort();

  const [modalType, setModalType] = React.useState(ModalType.None);

  const [activeModalType, setActiveModalType] = React.useState<ModalType>(ModalType.None);
  const [blockId, setBlockId] = React.useState<number | null>(null);
  const [tpmemId, setTpmemId] = React.useState<number | null>(null);

  const listHeader: ListHeaderType = [
    { width: 24, key: 'threeDotReader' },
    { width: 40, key: 'image' },
    { width: 200, key: 'name', columnName: '店舗名' },
    { width: 120, key: 'reg_date', columnName: 'ブロック日' },
    { width: 120, key: 'block_name', columnName: 'ブロック者' },
    { width: 233, key: 'reason', columnName: '理由' },
  ];
  if (sort) {
    blockListBlock = orderBy(blockListBlock, sort.key, sort.direction);
  }
  const blockList = useMemo(
    () =>
      blockListBlock?.map((item) => ({
        block_id: item?.block_id || 0,
        block_name: item?.block_name || '',
        image: item?.image || '',
        name: item?.name || '',
        reason: item?.reason || '',
        reg_date: item?.reg_date || '',
        status: item?.status || '',
        tpmem_id: item.tpmem_id,
      })) || [],
    [blockListBlock]
  );
  const listItems: ListItemsType = useMemo(
    () =>
      blockList
        .map((item, key) => ({
          threeDotReader: (
            <ThreeDotLeader
              menu={[
                {
                  label: '解除',
                  value: 'unblock',
                },
              ]}
              onClick={(e) => {
                if (e === 'unblock') {
                  setBlockId(item?.block_id ?? 0);
                  setTpmemId(item?.tpmem_id ?? 0);
                  setActiveModalType(ModalType.BaseInfoEdit);
                }
              }}
            />
          ),
          image: <img width={40} height={40} src={item?.image ?? ''} alt="" />,
          name: item?.name,
          reg_date: item?.reg_date,
          block_name: item?.block_name,
          reason: item?.reason,
          uniqueKey: key,
        }))
        .slice(limit * (page - 1), limit * page),
    [page, limit, blockList]
  );
  useEffect(() => {
    if (vTMemberBlockListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTMemberBlockListError, openErrorModal]);

  useEffect(() => {
    if (tTpmemBlockByIdError) {
      setActiveModalType(ModalType.None);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [tTpmemBlockByIdError, openErrorModal]);

  return (
    <>
      {(vTMemberBlockListLoading || tTpmemBlockByIdLoading) && <Loading />}
      <Box px={16} display="flex" flex="1" flexDirection="column" overflow="hidden">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">{`店舗ブロック(${blockListMember?.block_num ?? 0})`}</Text>
          <MainButton iconColor="white" icon="check" width={160} onClick={() => setModalType(ModalType.CreateBlock)}>
            店舗ブロック登録
          </MainButton>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flexDirection="column" flex="1">
          <PaginationBox
            dataSize={blockListBlock?.length ?? 0}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <ScrollWrapper bottom={345}>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={listHeader}
                items={listItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                width="100%"
                rowWidth="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
      <UnblockModal
        isOpen={activeModalType === ModalType.BaseInfoEdit}
        onClose={() => {
          setActiveModalType(ModalType.None);
          vTMemberBlockListRefetch();
        }}
        onUnblock={() => {
          if (blockId !== undefined) {
            deleteTTpmemBlockByIdMutation({
              variables: {
                id: tpmemId ?? 0,
                block_id: blockId ?? 0,
              },
            }).then((e) => {
              if (e.errors) {
                setActiveModalType(ModalType.None);
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              }
            });
          }
        }}
      />
      <CreateBlockModal
        isOpen={modalType === ModalType.CreateBlock}
        myId={id}
        name={name}
        bizId={blockListMember?.biz_id ?? 0}
        onClose={() => {
          setModalType(ModalType.None);
          vTMemberBlockListRefetch();
        }}
      />
    </>
  );
}
