import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Checkbox, Props as CheckboxProps } from '../newCheckbox/checkbox';

// value は react-hook-form が提供するものを使うのでomitする
// checked には value を渡す
export type RhfCheckboxProps<T extends FieldValues> = Omit<CheckboxProps, 'value' | 'checked'> & UseControllerProps<T>;

export function RhfCheckbox<T extends FieldValues>(props: RhfCheckboxProps<T>) {
  const { name, control, rules, defaultValue, onChange: customOnChange, ...otherProps } = props;
  const {
    field: { ref, value, onChange, ...rest },
  } = useController<T>({ name, control, rules, defaultValue });

  return (
    <Checkbox
      checked={value}
      {...rest}
      {...otherProps}
      onChange={(event) => {
        onChange(event);
        customOnChange?.(event);
      }}
    />
  );
}
