import React from 'react';
import { styled } from '@linaria/react';
import { Control, FieldArrayWithId, UseFieldArrayAppend, FieldErrors } from 'react-hook-form';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Box } from 'components/box';
import { Icon } from 'components/icon';
import { MainButton } from 'components/mainButton';
import { ErrorMessage } from 'components/errorMessage';
import { MLicense2 } from 'graphql/graphql-ow';
import { AddInput } from './addInput';
import { BusinessInfoSettingKey } from '../../s10-02-types';

const IconWrap = styled.div`
  position: relative;
  cursor: pointer;
  height: 24px;
`;

// 資格入力項目 登録・削除 コンポーネント
export interface Props {
  fields: FieldArrayWithId<BusinessInfoSettingKey, 'license', 'id'>[];
  control: Control<BusinessInfoSettingKey>;
  append: UseFieldArrayAppend<BusinessInfoSettingKey, 'license'>;
  remove: (index: number) => void;
  errors: FieldErrors<BusinessInfoSettingKey>;
  licenses: MLicense2[] | undefined | null;
  inputs: { lcValue: string }[];
}

const licenseValidation = (val: string, licenses: MLicense2[], inputs: { lcValue: string }[], index: number) => {
  for (let i = 0; i < licenses.length; i += 1) {
    if (val === licenses[i].license_name) {
      return '登録済なので使用できません。';
    }
  }
  const licenseList = Array.from(new Set(inputs.map((item) => item.lcValue)));
  if (licenseList.length !== inputs.length && inputs.length > 1) {
    const wkName = inputs[index].lcValue;
    for (let i = 0; i < inputs.length; i += 1) {
      if (i !== index) {
        if (wkName === inputs[i].lcValue) {
          return '同じ業種保有資格があります。';
        }
      }
    }
  }
  return undefined;
};

export function InputsLicensesContainer({ fields, control, append, remove, errors, licenses, inputs }: Props) {
  // 入力項目削除
  const reduce = (index: number) => () => {
    remove(index);
  };

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>
          <Box alignItems="center" display="flex" gap={8}>
            <AddInput
              width={720}
              control={control}
              name={`license.${index}.lcValue`}
              placeholder="資格名を入力"
              rules={{
                required: '資格名を入力してください',
                validate: (val) => licenseValidation(val as string, licenses || [], inputs, index),
              }}
            />
            {index > 0 && (
              <IconWrap onClick={reduce(index)}>
                <Icon name="cancel" />
              </IconWrap>
            )}
          </Box>
          {errors?.license && errors.license[index] && (
            <Box pt={4}>
              <ErrorMessage>{errors.license[index]?.lcValue?.message}</ErrorMessage>
            </Box>
          )}
        </div>
      ))}
      <Box display="flex" justifyContent="center" gap={5.5} pb={16}>
        <MainButton
          icon="addCircle"
          iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
          variant="secondary"
          thin
          onClick={() => [append({ lcValue: '' })]}
          width={108}
        >
          行追加
        </MainButton>
      </Box>
    </>
  );
}
