/**
 * Figma ID: 01-02-02
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { ContentTitle } from '../component/ContentTitle';

type CompleteSendMailModel = {
  nextPhase: () => void;
};
export default function CompleteSendMail(props: CompleteSendMailModel) {
  const { nextPhase } = props;

  return (
    <Box display="grid" justifyContent="center" alignItems="center" pt={34}>
      <ContentTitle title="パスワード再設定 送信完了" />
      <Box display="grid" justifyContent="center" pt={21}>
        <Box width={300}>
          <Text variant="body14">お使いのメールアドレスにパスワード再設定用の確認コードが送信されました。</Text>
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          onClick={() => {
            nextPhase();
          }}
        >
          次へ
        </MainButton>
      </Box>
    </Box>
  );
}
