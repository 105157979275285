import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Card } from 'components/card';
import { Text } from 'components/text';
import { usePagenation, useSort } from 'components/utils';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { List } from 'components/list';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { useGetVtBillCsvListNewlyBillStoreK1Query } from 'graphql/graphql-ow';
import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { ListHeaderType, ListItemsType } from './type';

/**
 * Figma ID: 08-04
 * 名称: 加盟店請求
 */

export function S0804() {
  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();
  const { openErrorModal } = useErrorModal();

  const { data, loading, error } = useGetVtBillCsvListNewlyBillStoreK1Query();
  React.useEffect(() => {
    if (error) {
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [error, openErrorModal]);

  const listHeader: ListHeaderType = useMemo(
    () => [
      { key: 'pay_date', width: 128, columnName: '発行年月' },
      { key: 'output_date', width: 140, columnName: '処理日時' },
    ],
    []
  );

  const listItems: ListItemsType = useMemo(() => {
    if (loading) {
      return [];
    }
    let items = data?.getVTBillCsvListNewlyBillStoreK1?.csvlist || [];

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item, key) => ({
      pay_date: item.pay_date,
      output_date: item.output_date,
      uniqueKey: key,
    }));
  }, [data, sort, loading]);

  return (
    <Box display="flex" flex="1" flexDirection="column" gap={26} height="100%">
      {loading && <Loading />}
      <Text variant="h1" color="darkBlue">
        加盟店請求
      </Text>
      <Box display="flex" flex="1">
        <Card overflow="hidden">
          <Box display="flex" flexDirection="column" width="100%" height="calc(100% - 32px)">
            <Box
              display="flex"
              flexDirection="row"
              height={60}
              px={16}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" gap={8} alignItems="center">
                <Text variant="h2" color="darkBlue">
                  月次加盟店請求処理
                </Text>
                <Text variant="caption12" color="darkBlue">
                  決済会社の月次自動処理一覧です。
                </Text>
              </Box>
            </Box>
            <Divider option="horizontal" />
            <Box display="flex" flexDirection="column" flex="auto" px={16}>
              <PaginationBox
                dataSize={listItems.length}
                limit={limit}
                page={page}
                onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
                onChangePage={(value: number) => handleChangePage(value)}
              >
                <ScrollWrapper bottom={380}>
                  <List
                    header={listHeader}
                    items={listItems.slice(limit * (page - 1), limit * page)}
                    key={String(limit) + String(page) + String(sort?.key || '')}
                    sort={sort}
                    onChangeSort={handleChangeSort}
                    rowHeight={40}
                    rowWidth="100%"
                    width="100%"
                  />
                </ScrollWrapper>
              </PaginationBox>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
