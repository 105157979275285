/**
 * Figma ID: 04-02-02
 * 名称: バイト募集（募集情報）
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { SubMenu } from 'components/subMenu';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { Card } from 'components/card';
import {
  useGetVtArbeitOfferInfoWorkingByIdQuery,
  useGetVtWorkingLogging2ListByOfferIdQuery,
  useGetVtArbeitEntryListWorkingByEntryIdLazyQuery,
  VtArbeitEntryList,
} from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { RecruitInfo } from './components/recruitInfo';
import { Adopt } from './components/adopt';
import { History } from './components/history';
import { StatusChip } from '../components/statusChip';

// 募集情報
export function S0402Details() {
  const { offerId } = useParams();
  const { openErrorModal } = useErrorModal();

  // 加盟店バイト募集情報取得
  const {
    data: { getVTArbeitOfferInfoWorkingById: arbeitOfferInfo } = {
      getVTArbeitOfferInfoWorkingById: { info: null, working: null },
    },
    loading: arbeitOfferInfoWorkingLoading,
    error: arbeitOfferInfoWorkingError,
  } = useGetVtArbeitOfferInfoWorkingByIdQuery({ variables: { id: Number(offerId) } });

  // 加盟店制約バイト状況一覧 & 店舗会員履歴情報取得
  const {
    data: { getVTWorkingLogging2ListByOfferId: loggingInfo } = {
      getVTWorkingLogging2ListByOfferId: { logging: null, working: null },
    },
    loading: workingLoggingLoading,
    error: workingLoggingError,
  } = useGetVtWorkingLogging2ListByOfferIdQuery({ variables: { offer_id: Number(offerId) } });

  // 採用情報取得
  const [getVtArbeitEntryList, { loading: arbeitEntryListLoading, error: arbeitEntryListError }] =
    useGetVtArbeitEntryListWorkingByEntryIdLazyQuery();

  React.useEffect(() => {
    if (arbeitEntryListError || arbeitOfferInfoWorkingError || workingLoggingError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [arbeitEntryListError, arbeitOfferInfoWorkingError, workingLoggingError, openErrorModal]);
  // 採用情報
  const [arbeitEntryInfo, setArbeitEntryInfo] = React.useState<VtArbeitEntryList | null>(null);

  React.useEffect(() => {
    // entry_idが未取得または採用情報一覧が取得済みの場合は処理を抜ける
    if (!arbeitOfferInfo?.working?.entry_id || arbeitEntryInfo) return;
    (async () => {
      // 採用情報を取得する
      const vtArbeitEntryList = await getVtArbeitEntryList({
        variables: { entry_id: arbeitOfferInfo?.working?.entry_id },
      });
      if (vtArbeitEntryList.data?.getVTArbeitEntryListWorkingByEntryId.entry) {
        setArbeitEntryInfo(vtArbeitEntryList.data?.getVTArbeitEntryListWorkingByEntryId.entry);
      }
    })();
  }, [arbeitOfferInfo?.working?.entry_id, arbeitEntryInfo, getVtArbeitEntryList]);

  return (
    <>
      {(arbeitOfferInfoWorkingLoading || workingLoggingLoading || arbeitEntryListLoading) && <Loading />}
      <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={8} width="100%" height={32}>
          <StatusChip status={Number(arbeitOfferInfo?.working?.status)} variant="big" width={128} />
          <Box flex="auto">
            <Text color="darkBlue" variant="h1">
              {arbeitOfferInfo?.working?.offer_number}
            </Text>
          </Box>
        </Box>
        <Card overflow="hidden">
          <SubMenu
            items={[
              {
                content: (
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {arbeitOfferInfo?.info && <RecruitInfo vtArbeitOfferInfo={arbeitOfferInfo?.info} />}
                  </div>
                ),
                label: '募集情報',
              },
              {
                content: (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <Adopt arbeitEntryInfo={arbeitEntryInfo} />
                  </div>
                ),
                label: '採用',
              },
              {
                content: (
                  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <History loggingList={loggingInfo?.logging} />
                  </div>
                ),
                label: 'バイト状況履歴',
              },
            ]}
          />
        </Card>
      </Box>
    </>
  );
}
