/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { Textarea, Props as TextAreaProps } from '../textarea/textarea';

// React Hook Form では name を必須にしたいため。
// Textarea で定義されている name プロパティを外す
export type RhfTextAreaProps<T extends FieldValues> = Omit<TextAreaProps, 'name'> & UseControllerProps<T>;

export function RhfTextarea<T extends FieldValues>(props: RhfTextAreaProps<T>) {
  const { name, control, rules, defaultValue, ...otherProps } = props;
  const {
    field: { ref, ...rest },
    formState: { errors },
  } = useController<T>({ name, control, rules, defaultValue });

  return <Textarea {...rest} {...otherProps} error={!!errors[name]} />;
}
