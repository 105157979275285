import React from 'react';
import { ContentsCard, PageBody, PageContentsWrapper } from 'components/assets/css/pages/pageStyle';
import { SubMenu } from 'components/subMenu';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { BenefitsSetting } from './components/benefits-setting';
import { MembershipPrivilegeCampaign } from './components/membership-privilege-campaign';
import { AffiliatedStorePriority } from './components/affiliated-store-priority';
import { BonusSettings } from './components/bonus-settings';

/**
 * Figma ID: 10-03
 * バイトラベラー設定画面
 */
export function S1003() {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box height={30} mb={26} display="flex" alignItems="center">
        <Text color="darkBlue" variant="h1">
          バイトラベラー設定
        </Text>
      </Box>
      <PageContentsWrapper>
        <PageBody bottom={5}>
          <ContentsCard>
            <SubMenu
              items={[
                {
                  content: <AffiliatedStorePriority />,
                  label: '所属店舗日時優先表示',
                },
                {
                  content: <BonusSettings />,
                  label: 'ボーナス設定',
                },

                {
                  content: <BenefitsSetting />,
                  label: '都道府県バイト特典設定',
                },
                {
                  content: <MembershipPrivilegeCampaign />,
                  label: '入会特典キャンペーン',
                },
              ]}
            />
          </ContentsCard>
        </PageBody>
      </PageContentsWrapper>
    </Box>
  );
}
