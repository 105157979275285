import { ColorKeyType } from 'components/list/listRow';
import { COLORS_NAME } from './constants';

export const backgroundColor = (status?: number): ColorKeyType => {
  switch (status) {
    case 1:
      return COLORS_NAME.White as ColorKeyType;
    default:
      return COLORS_NAME.Red as ColorKeyType;
  }
};

// 郵便番号にハイフンを挿入する
export function formatZipCode(zipCode: string) {
  return zipCode.replace(/(\d{3})(\d{4})/, '$1-$2');
}
