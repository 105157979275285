/**
 * Figma ID: 04-01-04-01
 * 名称: バイト募集情報（採用履歴）
 */

import React from 'react';
import { styled } from '@linaria/react';
import { HistoryList } from 'components/historyList';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { PaginationBox } from 'components/pagination/paginationBox';
import { GetVtArbeitOfferLogging1ListByIdQuery } from 'graphql/graphql-ow';
import { Divider } from 'components/newDivider';

type VtArbeitOfferLogging1List = NonNullable<
  GetVtArbeitOfferLogging1ListByIdQuery['getVTArbeitOfferLogging1ListById']['logging']
>;
type VtArbeitOfferLogging1ListItem = NonNullable<VtArbeitOfferLogging1List[number]>;

interface Props {
  vtArbeitOfferLogging1List: VtArbeitOfferLogging1List;
}

const StyledListWrapper = styled.div`
  height: calc(100vh - 310px);
  max-height: calc(100vh - 310px);
  overflow: auto;
`;

// バイト募集情報
export function History({ vtArbeitOfferLogging1List }: Props) {
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const displayHistoryItems: {
    uniqueKey: string | number;
    text: string;
    dateTime: string;
  }[] = React.useMemo(
    () =>
      vtArbeitOfferLogging1List
        .filter((item): item is VtArbeitOfferLogging1ListItem => item !== null)
        .map((item) => ({
          uniqueKey: item.id,
          text: item.matter || '',
          dateTime: item.log_date || '',
        }))
        .slice(currentLimit * (currentPage - 1), currentLimit * currentPage),
    [vtArbeitOfferLogging1List, currentPage, currentLimit]
  );

  return (
    <Box px={24} pb={16} display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box height={60} width="100%" display="flex" alignItems="center">
        <Text color="darkBlue" variant="h2">
          採用履歴
        </Text>
      </Box>
      <Divider option="horizontal" />

      <Box flex="auto" width="100%">
        <PaginationBox
          dataSize={vtArbeitOfferLogging1List.length}
          limit={currentLimit}
          page={currentPage}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <StyledListWrapper>
            <HistoryList items={displayHistoryItems} />
          </StyledListWrapper>
        </PaginationBox>
      </Box>
    </Box>
  );
}
