/**
 * Figma ID: 07-01-03
 * 名称: 逆オファー（オファーした人詳細モーダル）
 */
import React from 'react';
import { Info } from 'components/info';
import { Box } from 'components/box';
import { Text } from 'components/text';
import Divider from 'components/divider/divider';
import { GetVbMemberBaseByIdQuery } from 'graphql/graphql-ow';
import { Avatar } from 'components/avatar';
import { styled } from '@linaria/react';
import { identificationLabel } from 'components/labelList/identificationLabel';

const StyledAvatarBG = styled.div`
  width: 80px;
  height: 60px;

  background-color: black;
`;

type BtmemDetailContentModel = {
  data?: GetVbMemberBaseByIdQuery;
};
function BtmemDetailContent(props: BtmemDetailContentModel) {
  const { data } = props;
  const info = data?.getVBMemberBaseById;
  const address = `〒${info?.zip_code?.slice(0, 3) ?? ''}-${info?.zip_code?.slice(3, 7) ?? ''} ${info?.state ?? ''} ${
    info?.city ?? ''
  } ${info?.address ?? ''} ${info?.address2 ?? ''}`;
  const fullBank = `${info?.bank ?? ''} ${info?.branch_name ?? ''} ${info?.account_type ?? ''} ${
    info?.account_no ?? ''
  } ${info?.account_name ?? ''}`;
  const tpmemNameList = info?.tpmem_names?.split(',') ?? [];
  const tpmemSubNameList = info?.sub_names?.split(',') ?? [];
  const tpBizNameList = info?.tp_biz_names?.split(',') ?? [];
  const bizLicenseNameList = info?.lc_biz_names?.split(',') ?? [];
  const bizLicenseList = info?.license_list?.split('\n') ?? [];
  const rankBizNameList = info?.rank_biz_names?.split(',') ?? [];
  const ranknameList = info?.rank_names?.split(',') ?? [];
  const tpMemList = () => {
    const item = [];
    for (let i = 0; i < tpmemNameList.length; i += 1) {
      item.push(
        {
          label: `所属店舗(${tpBizNameList[i]})`,
          content: tpmemNameList[i],
        },
        {
          label: `サブ店舗(${tpBizNameList[i]})`,
          content: tpmemSubNameList[i] || '-',
        }
      );
    }
    return item;
  };
  const bizLicense = () => {
    const item = [];
    for (let i = 0; i < bizLicenseNameList.length; i += 1) {
      item.push({
        label: `${bizLicenseNameList[i]}資格`,
        content: bizLicenseList[i],
      });
    }
    return item;
  };
  const rankBiz = () => {
    const item = [];
    for (let i = 0; i < rankBizNameList.length; i += 1) {
      item.push({
        label: `ランク(${rankBizNameList[i]})`,
        content: ranknameList[i],
      });
    }
    return item;
  };

  return (
    <div
      style={{
        overflow: 'auto',
        height: 450,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <StyledAvatarBG>
          <Avatar src={info?.image ?? ''} alt="tpmemImage" width={80} height={60} />
        </StyledAvatarBG>
        <Box px={24}>
          <Text variant="h1" bold color="darkBlue">
            {info?.name}
          </Text>
        </Box>
      </Box>
      <Box py={8}>
        <Divider option="horizontal" />
      </Box>
      <Info
        items={[
          {
            label: '氏名（フリガナ）',
            content: info?.kana,
          },
          {
            label: 'メールアドレス(ID)',
            content: info?.email,
          },
          {
            label: '会員番号',
            content: info?.btmem_number,
          },
          {
            label: '電話番号',
            content: info?.phone,
          },
          {
            label: '生年月日',
            content: info?.birthday,
          },
          {
            label: '年齢',
            content: info?.age,
          },
          ...rankBiz(),
          {
            label: '達成都道府県',
            content: info?.state_count,
          },
          {
            label: 'バイト回数',
            content: `${info?.worked_count ?? '0'} ${info?.badge_name ?? ''}`,
          },
          {
            label: '入会日',
            content: info?.created_date,
          },
          {
            label: '住所',
            content: address,
          },
          ...tpMemList(),
          {
            label: '性別',
            content: info?.gender,
          },
          {
            label: '国籍',
            content: info?.nationality || '-',
          },
          {
            label: '属性',
            content: info?.attribute || '-',
          },
          {
            label: '保有資格',
            content: info?.general_licenses || '-',
          },
          ...bizLicense(),
          {
            label: '制服サイズ',
            content: info?.uniform_size,
          },
          {
            label: '遅刻率',
            content: `${String(info?.late_count)}回 / ${String(info?.late_per)}%`,
          },
          {
            label: 'キャンセル率',
            content: `${String(info?.cancel_count)}回 / ${String(info?.cancel_per)}%`,
          },
          {
            label: 'ドタキャン率',
            content: `${String(info?.imminent_count)}回 / ${String(info?.imminent_per)}%`,
          },
          {
            label: 'バックレ回数',
            content: `${String(info?.skip_count)}回`,
          },
          {
            label: '登録業種',
            content: info?.reg_biz_list,
          },
          {
            label: '入金口座',
            content: fullBank,
          },
          {
            label: '本人確認',
            content: identificationLabel(info?.identification ?? 0),
          },
        ]}
      />
    </div>
  );
}

export default BtmemDetailContent;
