/**
 * Figma ID: 10-01-02-06
 * 名称: ユーザー招待 新規登録
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { TextLink } from 'components/textLink';

type Props = {
  onClickToLogin: () => void;
};

export default function CompleteConfirmCode({ onClickToLogin }: Props) {
  return (
    <Box width={376} display="flex" flexDirection="column" justifyContent="center" gap={24}>
      <Box>
        <Text variant="body14">
          確認コードの入力が完了しました。
          <br />
          ログイン画面からログインしてください。
        </Text>
      </Box>
      <Box justifyContent="center" display="flex">
        <TextLink onClick={() => onClickToLogin()}>ログイン画面へ</TextLink>
      </Box>
    </Box>
  );
}
