/**
 * Figma ID: 10-01-03-01
 * 名称: 管理アカウント（メールアドレス編集）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { InputLabel } from 'components/inputLabel';

type Props = {
  oldEmail: string;
  toNextPhase: (email: string) => void;
  onClickClose: () => void;
};

type FormValues = {
  email: string;
};

export function UpdateMailModal({ oldEmail, toNextPhase, onClickClose }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: 'onChange' });

  const onSubmit = handleSubmit((data) => {
    toNextPhase(data.email);
  });

  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            メールアドレス編集
          </Text>
        </Box>
      }
      content={
        <Box pa={8}>
          <Box pb={25}>
            <Box pb={5.5}>
              <InputLabel>現在のメールアドレス</InputLabel>
            </Box>
            <Text variant="body14">{oldEmail}</Text>
          </Box>
          <Box height={78} display="flex" flexDirection="column" gap={4}>
            <Box pb={5.5}>
              <InputLabel>メールアドレス</InputLabel>
            </Box>
            <RhfInput
              placeholder="メールアドレス"
              name="email"
              control={control}
              rules={{
                required: 'メールアドレスを入力してください。',
                pattern: {
                  value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                  message: '入力形式がメールアドレスではありません。',
                },
              }}
              width={752}
            />
            {errors.email?.message && <Text color="cautionRed" variant="caption12">{`※${errors.email.message}`}</Text>}
          </Box>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            キャンセル
          </MainButton>
          <MainButton
            disabled={!isValid}
            onClick={() => {
              onSubmit();
            }}
            width={104}
          >
            送信
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={324}
      width={800}
    />
  );
}
