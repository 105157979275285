/**
 * Figma ID: 01-01-01
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { MainButton } from 'components/mainButton';
import { TitleHeader } from '../component/TitleHeader';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  onSignInWithMfaToken: (token: string) => Promise<void>;
};

export function InputMfaToken({ onSignInWithMfaToken }: Props) {
  const inputCodeLength = 6;
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ inputCode: string }>({ reValidateMode: 'onChange' });
  const watchInputCode = watch('inputCode') ?? '';

  const onSubmit = handleSubmit(async (data) => {
    await onSignInWithMfaToken(data.inputCode);
  });

  return (
    <Box>
      <TitleHeader />
      <Box display="grid" justifyContent="center" pt={24}>
        <ContentTitle title="２段階認証コードを入力" />
        <Box display="grid" justifyContent="center">
          <Box py={24} justifyContent="center" width={300}>
            <Text variant="body14">認証アプリの認証コードを入力してください。</Text>
          </Box>
          <RhfInput
            placeholder="6桁のコードを入力してください。"
            name="inputCode"
            control={control}
            rules={{
              minLength: { value: inputCodeLength, message: '認証コードは6文字で入力してください。' },
            }}
          />
        </Box>
        {errors.inputCode?.message && (
          <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
        )}
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" pt={42}>
        <MainButton
          width={160}
          disabled={watchInputCode.length !== inputCodeLength}
          onClick={() => {
            onSubmit();
          }}
        >
          認証
        </MainButton>
      </Box>
    </Box>
  );
}
