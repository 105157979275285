/**
 * Figma ID: 01-02-01
 * 名称: ログイン
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { InputLabel } from 'components/inputLabel';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { MainButton } from 'components/mainButton';
import { TextLink } from 'components/textLink';
import { ContentTitle } from '../component/ContentTitle';

type Props = {
  onSendMail: (email: string) => Promise<void>;
};
export default function InputMail({ onSendMail }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({ mode: 'onChange' });

  const onSubmit = handleSubmit(async (data) => {
    await onSendMail(data.email);
  });

  return (
    <Box display="grid" justifyContent="center" pt={34}>
      <ContentTitle title="パスワード再設定" />
      <Box display="flex" flexDirection="column" justifyContent="center" pt={24} px={24} gap={24}>
        <Box display="flex" justifyContent="center">
          <Text variant="body14">パスワード再設定用のURLをメールで送信します。</Text>
        </Box>
        <Box display="flex" flexDirection="column" gap={4} justifyContent="flex-start" height={78}>
          <InputLabel>メールアドレス</InputLabel>
          <RhfInput
            placeholder="メールアドレス"
            name="email"
            control={control}
            rules={{
              required: 'メールアドレスを入力してください。',
              pattern: {
                value: /^[\w\-._]+@[\w\-._]+\.[A-Za-z]+/,
                message: '入力形式がメールアドレスではありません。',
              },
            }}
          />
          {errors.email?.message && <Text color="cautionRed" variant="caption12">{`※${errors.email.message}`}</Text>}
        </Box>
        <Box display="grid" justifyContent="center" alignItems="center">
          <MainButton
            disabled={!isValid}
            width={160}
            onClick={() => {
              onSubmit();
            }}
          >
            送信
          </MainButton>
        </Box>
        <Box display="grid" justifyContent="center">
          <TextLink to="/signIn">ログイン画面へ戻る</TextLink>
        </Box>
      </Box>
    </Box>
  );
}
