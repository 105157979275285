/**
 * Figma ID: 10-04-01, 10-04-04
 * 名称: 2段階認証設定
 */
import { Box } from 'components/box';
import React, { useEffect, useMemo } from 'react';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Card } from 'components/card';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { ActivateMfa } from './components/ActivateMfa';
import { ReleaseMfaModalContent, ReleaseMfaModalFooter, ReleaseMfaModalHeader } from './components/ReleaseMfaModal';
import {
  CompleteReleaseMfaModalContent,
  CompleteReleaseMfaModalFooter,
  CompleteReleaseMfaModalHeader,
} from './components/CompleteReleaseMfaModal';
import {
  CompleteActivateMfaContent,
  CompleteActivateMfaFooter,
  CompleteActivateMfaHeader,
} from './components/CompleteActivateMfaModal';
import { QrCodeToInstallApp } from './components/QrCodeToInstallApp';

type ModalStep = 'NULL' | 'CompleteActivateMfa' | 'ReleaseConfirm' | 'ReleaseComplete';

export default function S1004() {
  const auth = useAuth();
  const { openErrorModal } = useErrorModal();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisplayActivatePage, setIsDisplayActivatePage] = React.useState(false);
  const [isMfaActivated, setIsMfaActivated] = React.useState(false);
  const [modalStep, setModalStep] = React.useState<ModalStep>('NULL');
  const [qrCodeToActivateMfa, setQrCodeToActivateMfa] = React.useState<string>('');

  useEffect(() => {
    auth.checkMfaActivated().then((result) => {
      setIsMfaActivated(result);
    });
  }, [auth]);

  const displayModal = useMemo(() => {
    const handleReleaseMfa = async () => {
      try {
        setIsLoading(true);
        await auth.releaseMfa();
        const result = await auth.checkMfaActivated();
        setIsMfaActivated(result);
        setModalStep('ReleaseComplete');
      } catch (error) {
        openErrorModal({ message: '2段階認証の解除に失敗しました。' });
        setModalStep('NULL');
      } finally {
        setIsLoading(false);
      }
    };

    switch (modalStep) {
      case 'CompleteActivateMfa':
        return {
          header: <CompleteActivateMfaHeader />,
          content: <CompleteActivateMfaContent />,
          footer: (
            <CompleteActivateMfaFooter
              onClickClose={() => {
                setModalStep('NULL');
                setIsMfaActivated(true);
                setIsDisplayActivatePage(false);
              }}
            />
          ),
        };
      case 'ReleaseConfirm':
        return {
          header: <ReleaseMfaModalHeader />,
          content: <ReleaseMfaModalContent />,
          footer: (
            <ReleaseMfaModalFooter
              toNextPhase={() => {
                handleReleaseMfa();
              }}
              onClickClose={() => setModalStep('NULL')}
            />
          ),
        };
      case 'ReleaseComplete':
        return {
          header: <CompleteReleaseMfaModalHeader />,
          content: <CompleteReleaseMfaModalContent />,
          footer: (
            <CompleteReleaseMfaModalFooter
              onClickClose={() => {
                setModalStep('NULL');
              }}
            />
          ),
        };

      default:
        return {
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [modalStep, auth, openErrorModal]);

  const displayActivatePage = async () => {
    try {
      setIsLoading(true);
      if (!auth.userInfo) {
        throw new Error('ユーザー情報が取得できませんでした。');
      }
      const issuer = encodeURI('バイトラベル');
      const token = await auth.generateTotpToken();
      const qrCode = `otpauth://totp/AWSCognito:${auth.userInfo?.email}?secret=${token}&issuer=${issuer}`;
      setQrCodeToActivateMfa(qrCode);
      setIsDisplayActivatePage(true);
    } catch (error) {
      openErrorModal({ message: '2段階認証の設定に失敗しました。' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleActivateMfa = async (code: string) => {
    try {
      setIsLoading(true);
      await auth.activateMfa(code);
      setModalStep('CompleteActivateMfa');
    } catch (error) {
      openErrorModal({ message: '2段階認証の設定に失敗しました。' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box display="flex" flexDirection="column" gap={26}>
        <Text variant="h1" color="darkBlue">
          二段階認証設定
        </Text>
        <Box height="calc(100vh - 152px)">
          <Card overflow="hidden">
            <Box pa={16}>
              {isDisplayActivatePage ? (
                <ActivateMfa qrCodeToActivateMfa={qrCodeToActivateMfa} onActivateMfa={handleActivateMfa} />
              ) : (
                <>
                  <QrCodeToInstallApp />
                  <Box pt={16}>
                    {isMfaActivated ? (
                      <MainButton onClick={() => setModalStep('ReleaseConfirm')}>解除</MainButton>
                    ) : (
                      <MainButton
                        onClick={() => {
                          displayActivatePage();
                        }}
                      >
                        設定
                      </MainButton>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
      <Modal
        isOpen={modalStep !== 'NULL'}
        onClose={() => setModalStep('NULL')}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow="visible"
      />
    </>
  );
}
