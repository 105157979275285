import React from 'react';
import { Box } from 'components/box';

export function ModalHeaderContentWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box mt={2} mb={2}>
      {children}
    </Box>
  );
}
