/**
 * Figma ID: 10-08-01-02, 10-08-01-03
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import type { UpdateDeadlinePhase } from '../GiftUpdate';

type UpdateDeadlineFooterModel = {
  onClickNext: () => void;
  onClickClose: () => void;
  onClickBack: () => void;
  updateDeadlinePhase?: UpdateDeadlinePhase;
};
function UpdateDeadlineFooter(props: UpdateDeadlineFooterModel) {
  const { onClickNext, onClickClose, onClickBack, updateDeadlinePhase } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary" thin>
        キャンセル
      </MainButton>
      {updateDeadlinePhase !== 'selectedGift' && (
        <MainButton onClick={onClickBack} width={104} variant="secondary" thin>
          戻る
        </MainButton>
      )}
      <MainButton onClick={onClickNext} width={104} thin>
        次へ
      </MainButton>
    </Box>
  );
}

export default UpdateDeadlineFooter;
