import React, { useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { MemberHistoryStatus, CommonIdentificationStatus } from 'components/const';
import { Box } from 'components/box';
import { Chip } from 'components/chip';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import { Avatar } from 'components/avatar';
import { useErrorModal } from 'components/error/errorModalProvider';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { styled } from '@linaria/react';
import { ApplicationDetails } from './components/applicationDetails';
import { Photograph } from './components/photograph';
import { WorkflowHistory } from './components/workflowHistory';

// Figma管理番号
// 06-02-01
// 06-02-02
// 06-02-03
// 06-03-04
// 06-03-01
// 06-03-02
// 06-03-03
// 06-03-04
// 06-04-01
// 06-05-01
// 06-05-02
// 06-06-01

const StyledAvatarBG = styled.div`
  width: 80;
  height: 60;

  background-color: black;
`;

const StyledChipWrapper = styled.div`
  width: 56px;
`;

export function S06Details() {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) navigate('/s06');

  const [
    getupdVBIdentificationInfoByKey,
    { data: identificationInfoData, loading: identificationInfoLoading, error: identificationInfoError },
  ] = gql.useGetupdVbIdentificationInfoByKeyMutation({
    fetchPolicy: 'no-cache',
  });
  const {
    data: loggingListData,
    loading: loggingListLoading,
    error: loggingListError,
  } = gql.useGetVbIdentificationLoggingListByKeyQuery({
    variables: { internal_key: Number(id) },
    fetchPolicy: 'no-cache',
  });
  const {
    data: { getVBIdentificationListByKey: { entry_date: entryData } } = {
      getVBIdentificationListByKey: {
        entry_date: '',
      },
    },
    loading: identificationListLoading,
    error: getVBIdentificationListError,
  } = gql.useGetVbIdentificationListByKeyQuery({ variables: { internal_key: Number(id) }, fetchPolicy: 'no-cache' });

  /* dummy用：バイトラベル本人確認申請内容 */
  const dummyIdentificationInfo: gql.VbIdentificationInfo = {} as gql.VbIdentificationInfo;

  /* 履歴をワークフロー履歴(=2)だけ抽出 */
  const extractIdentificationLoggingList = loggingListData?.getVBIdentificationLoggingListByKey.logging.filter(
    (item) => item.kind === MemberHistoryStatus.WORKFLOW
  );
  const bLoggingList = useMemo(
    () =>
      (extractIdentificationLoggingList as gql.BLogging[])?.map((item) => ({
        btmem_id: item.btmem_id,
        log_date: item.log_date as string,
        matter: item.matter as string,
      })) || [],
    [extractIdentificationLoggingList]
  );

  const generateStatus = (
    identification: number
  ): {
    chipColor: 'warningYellow' | 'cautionRed' | 'okBlue';
    backgroundColor: 'white' | 'bgRed' | 'bgYellow';
    text: string;
  } => {
    const identificationText = identificationLabel(identification);

    switch (identification) {
      case CommonIdentificationStatus.UNCONFIRMED_IDENTITY:
        return {
          chipColor: 'warningYellow',
          backgroundColor: 'bgYellow',
          text: identificationText,
        };
      case CommonIdentificationStatus.CONFIRMED_IDENTITY:
        return {
          chipColor: 'okBlue',
          backgroundColor: 'white',
          text: identificationText,
        };
      case CommonIdentificationStatus.REJECTED:
        return {
          chipColor: 'cautionRed',
          backgroundColor: 'bgRed',
          text: identificationText,
        };
      case CommonIdentificationStatus.REQUEST_RECONFIRMATION:
        return {
          chipColor: 'warningYellow',
          backgroundColor: 'bgYellow',
          text: identificationText,
        };
      case CommonIdentificationStatus.EXPIRATION_RESIDENCE:
        return {
          chipColor: 'cautionRed',
          backgroundColor: 'bgRed',
          text: identificationText,
        };
      case CommonIdentificationStatus.RENEWAL_RESIDENCE:
        return {
          chipColor: 'warningYellow',
          backgroundColor: 'bgYellow',
          text: identificationText,
        };
      default:
        return {
          chipColor: 'warningYellow',
          backgroundColor: 'bgYellow',
          text: identificationText,
        };
    }
  };

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  useEffect(() => {
    if (getVBIdentificationListError || identificationInfoError || loggingListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVBIdentificationListError, identificationInfoError, loggingListError, openErrorModal]);

  useEffect(() => {
    getupdVBIdentificationInfoByKey({ variables: { internal_key: Number(id) } }).catch(() => {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    });
  }, [getupdVBIdentificationInfoByKey, id, openErrorModal]);

  return (
    <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
      {identificationListLoading && identificationInfoLoading && loggingListLoading && <Loading />}
      <Box
        height={60}
        display="flex"
        alignItems="center"
        columnGap={24}
        backgroundColor={
          identificationInfoData
            ? generateStatus(identificationInfoData.getupdVBIdentificationInfoByKey.identification).backgroundColor
            : 'white'
        }
      >
        {identificationInfoData?.getupdVBIdentificationInfoByKey.image !== undefined && (
          <StyledAvatarBG>
            <Avatar
              src={identificationInfoData?.getupdVBIdentificationInfoByKey.image ?? ''}
              alt="サムネイル"
              width={80}
              height={60}
            />
          </StyledAvatarBG>
        )}
        <Box height={48} backgroundColor="transparent">
          {identificationInfoData?.getupdVBIdentificationInfoByKey.identification !== undefined && (
            <StyledChipWrapper>
              <Chip
                color={generateStatus(identificationInfoData?.getupdVBIdentificationInfoByKey.identification).chipColor}
                width={3}
              >
                {generateStatus(identificationInfoData?.getupdVBIdentificationInfoByKey.identification).text}
              </Chip>
            </StyledChipWrapper>
          )}
          <Text variant="h1" color="darkBlue">
            {identificationInfoData?.getupdVBIdentificationInfoByKey.name}
          </Text>
        </Box>
      </Box>
      <Card overflow="hidden">
        <SubMenu
          items={[
            {
              label: '申請内容',
              content: (
                <ApplicationDetails
                  applicationDetail={identificationInfoData?.getupdVBIdentificationInfoByKey ?? dummyIdentificationInfo}
                  refetchIdentification={() =>
                    getupdVBIdentificationInfoByKey({ variables: { internal_key: Number(id) } })
                  }
                />
              ),
            },
            {
              label: '写真',
              content: <Photograph image={identificationInfoData?.getupdVBIdentificationInfoByKey.image || ''} />,
            },
            {
              label: 'ワークフロー履歴',
              content: <WorkflowHistory workflowHistory={bLoggingList} />,
            },
          ]}
        />
      </Card>
    </Box>
  );
}
