/**
 * Figma ID: 10-04-02
 * 名称: 2段階認証設定
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { RhfInput } from 'components/rhfInput';
import { useForm } from 'react-hook-form';
import { QRCodeCanvas } from 'qrcode.react';

type Props = {
  qrCodeToActivateMfa: string;
  onActivateMfa: (code: string) => Promise<void>;
};

export function ActivateMfa({ qrCodeToActivateMfa, onActivateMfa }: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ inputCode: string }>({ reValidateMode: 'onChange' });
  const inputCodeLength = 6;

  const onSubmit = handleSubmit(async (data) => {
    await onActivateMfa(data.inputCode);
  });

  return (
    <Box>
      <Box pt={16} px={16}>
        <Box>
          <Text variant="body14" color="black">
            1. お持ちのスマートフォンからGoogle Authenticatorを開き、QRコードを読み込んでください。
          </Text>
          <Box pt={20}>{qrCodeToActivateMfa && <QRCodeCanvas value={qrCodeToActivateMfa} size={80} />}</Box>
        </Box>
      </Box>
      <Box pt={16} px={16}>
        <Box>
          <Text variant="body14" color="black">
            2. アプリケーションで生成された6桁の数字を入力してください。
          </Text>
          <Box display="flex" gap={5} pt={10}>
            <RhfInput
              name="inputCode"
              control={control}
              placeholder="123456"
              width={160}
              rules={{ validate: (value) => value.length === inputCodeLength || '6桁の認証コードを入力してください。' }}
            />
            <MainButton
              disabled={!isValid}
              onClick={() => {
                onSubmit();
              }}
            >
              設定する
            </MainButton>
          </Box>
          {errors.inputCode?.message && (
            <Text color="cautionRed" variant="caption12">{`※${errors.inputCode.message}`}</Text>
          )}
        </Box>
      </Box>
    </Box>
  );
}
