import { Box } from 'components/box';
import { Card } from 'components/card';
import { Tab } from 'components/newTab';
import { Text } from 'components/text';
import React, { useContext } from 'react';
import { RefundPayment, RefundPaymentList } from './details';
import { NewlyNotificationContext, NewlyNotificationContextProvider } from './components/newlyNotificationContext';
import { NewlyNotification } from './const';

/**
 * Figma ID: 08-05
 * 名称: 払戻支払
 */

function Content() {
  const { newlyNotification } = useContext(NewlyNotificationContext);
  const { re_pay: rePay } = newlyNotification;

  const [menuIndex, setMenuIndex] = React.useState<number>(0);
  // 出力No
  const [outputNumber, setOutputNumber] = React.useState<string>('');

  return (
    <Box display="flex" flex="1" flexDirection="column" gap={26} height="100%">
      <Text variant="h1" color="darkBlue">
        払戻支払
      </Text>
      <Box display="flex" flex="1">
        <Card overflow="hidden">
          <Box display="flex" height="100%" py={16}>
            <Tab
              defaultIndex={menuIndex}
              items={[
                {
                  label: '払戻支払一覧',
                  content: <RefundPaymentList setMenuIndex={(num: number) => setMenuIndex(num)} />,
                },
                {
                  label: '払戻支払',
                  content: (
                    <RefundPayment
                      outputNumber={outputNumber}
                      setOutputNumber={(_outputNumber: string) => setOutputNumber(_outputNumber)}
                    />
                  ),
                  hasNotification: rePay === NewlyNotification.TRUE,
                },
              ]}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export function S0805() {
  return (
    <NewlyNotificationContextProvider>
      <Content />
    </NewlyNotificationContextProvider>
  );
}
