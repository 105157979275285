/**
 * Stringの日付を分解してObjectにして返す
 * @param input YYYY/MM/DD hh:mm:ss
 * @returns
 */
type FormattedShiftDate = {
  year: string;
  month: string;
  day: string;
  time: string;
};

export enum FormatDateType {
  Numeric = 'numeric',
  Digit = '2-digit',
}

export const parseDate = (input?: string | null): FormattedShiftDate | null => {
  if (!input) return null;
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}:\d{2}:\d{2})$/;
  const match = input.match(dateRegex);
  if (match) {
    const time = match[4].split(':');
    return {
      year: match[1],
      month: match[2],
      day: match[3],
      time: `${time[0]}:${time[1]}`,
    };
  }
  return {
    year: '',
    month: '',
    day: '',
    time: '',
  };
};

/**
 * @param textDate 'YYYY-MM-DD hh:mm:ss'
 * @returns ISO 8601形式の文字列
 */
export const strDateToISO = (textDate: string) => new Date(textDate).toISOString();
export const splitString = (str: string, delimiter: string): string[] => str.split(delimiter);

/**
 * 時間を分解してObjectにして返す
 */
type TimeObject = {
  hour: number;
  minute: number;
  second: number;
};
export const parseTimeString = (timeString: string): TimeObject => {
  const [HH, ii, ss] = timeString.split(':').map(Number);
  return { hour: HH, minute: ii, second: ss };
};

export const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);

  const options = {
    year: FormatDateType.Numeric,
    month: FormatDateType.Digit,
    day: FormatDateType.Digit,
  };

  const formattedDate = new Intl.DateTimeFormat('ja-JP', options).format(date);

  const year = formattedDate.slice(0, 4);
  const month = formattedDate.slice(5, 7);
  const day = formattedDate.slice(8, 10);

  return `${year}年${month}月${day}日`;
};

export const formatJpDate = (japaneseDate: string) => {
  const year = japaneseDate.slice(0, 4);
  const month = japaneseDate.slice(5, 7);
  const day = japaneseDate.slice(8, 10);

  return `${year}-${month}-${day}T00:00:00`;
};

export { useSort, usePagenation } from './hooks';
