/**
 * ファイルアップロード用のAPI
 */
import axios from 'axios';

export type FileUploadProps = {
  filePath: string;
  file: File;
};

// ファイルアップロード
export default async function uploadFile({filePath, file}: FileUploadProps) {
  // ヘッダ情報
  const headers = {
    "x-api-key" : process.env.REACT_APP_FILE_UPLOAD_API_KEY,
    "Content-Type": file.type
  };
  
  const response = await axios.put(`${process.env.REACT_APP_FILE_UPLOAD_API_URL || ""}/${filePath}`, file, {headers});

  // レスポンスを返す
  // アップロードするだけであれば参照する必要はないが、レスポンスは返却しておく
  return response;
}
