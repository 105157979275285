/**
 * Figma ID: 07-01-03
 * 名称: 逆オファー（オファーした人詳細モーダル）
 */
import React from 'react';
import { Text } from 'components/text';
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Link as RouterLink } from 'react-router-dom';

type BtmemDetailHeaderModel = {
  name?: string;
  id?: number;
};

function BtmemDetailHeader(props: BtmemDetailHeaderModel) {
  const { name, id } = props;
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Text variant="h2" color="darkBlue">
        {name}
      </Text>
      <RouterLink to={`/s05/${id ?? 0}`} style={{ textDecoration: 'none' }}>
        <MainButton width={74} variant="secondary">
          詳しく
        </MainButton>
      </RouterLink>
    </Box>
  );
}

export default BtmemDetailHeader;
