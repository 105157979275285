import {S3, AWSError} from "aws-sdk";
import {PDFFont, PDFPage} from 'pdf-lib';

// S3からデータを取得する
export const getObject = (s3: S3, bucket: string, key: string) : Promise<S3.Body | undefined | null>  =>   
  new Promise((resolve) => {
  s3.getObject({
    Bucket: bucket, Key: key
  }, (err: AWSError, data: S3.GetObjectOutput) => {
    if (err) {
      // エラーログを出力する
      console.log(err);
      resolve(null);
    } else {
      resolve(data?.Body);
    }
  });
});

// データを指定したテキスト幅で分割した配列で返す
export const splitPdfString = (customFont: PDFFont, fontSize: number, data: string, textWidth: number) => {
  if (!data) return [""];
  
  const list = [];

  // 1文字毎に分割する
  const targetList = data.split('');

  let targetString = "";
  targetList.forEach((char) => {
    // 対象文字を追加
    const preString = targetString + char;
    const textWidthWork = customFont.widthOfTextAtSize(preString, fontSize);
    
    // 追加した文字列がテキスト幅を超えるかチェックする
    if (textWidthWork > textWidth) {
      // テキストサイズを超えた場合一覧に追加する
      list.push(targetString);
      // 文字列を初期化
      // 新規行の一文字目を追加
      targetString = char;
    } else {
      // 対象の文字を追加
      targetString += char;
    }
  });

  // 文字列が初期化されていない状態で残っている場合は一覧に追加する
  if (targetString) list.push(targetString);

  return list;
};

// 全行印字する
export const drawAllLine = (list: string[], lineHeight: number, baseX: number, baseY: number, page: PDFPage, customFont: PDFFont, fontSize: number, maxLineCount: number | null=null) => {
  // 全行数分印字する
  for (let i = 0; i < list.length; i += 1) {
    // 最大行以上は表示しない
    if (maxLineCount) {
      if (i >= 15) break;
    }
    
    // Y座標
    const y = baseY - (i * lineHeight);
    page.drawText(list[i] , { x: baseX, y, font: customFont, size: fontSize });        
  }
};

// テキスト幅を超えているかチェックする
export const isOverTextWidth = (customFont: PDFFont, fontSize: number, data: string, textWidth: number) => {
  const customTextWidth = customFont.widthOfTextAtSize(data, fontSize);
  return customTextWidth > textWidth;
};
