import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React from 'react';
import { useGetTmemberCsvDataForMfkLazyQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { downloadCsvFile } from 'components/csv';
import { Loading } from 'components/loading/loading';

export function CsvOutputModal(props: { isOpen: boolean; onClose: () => void }) {
  const { isOpen, onClose } = props;
  const { openErrorModal } = useErrorModal();

  const [getTmemberCsvDataForMfk, { data, loading }] = useGetTmemberCsvDataForMfkLazyQuery();

  const onClickDownload = React.useCallback(async () => {
    const today = new Date();
    const csvFileName = `${today.getFullYear()}${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${today.getDate()}_フィル株式会社_口座振替依頼.csv`;

    const columns = [
      { header: '顧客番号', key: 'client_number' },
      { header: '顧客名', key: 'client_name' },
      { header: '電話番号', key: 'phone' },
      { header: '郵便番号', key: 'zip_code' },
      { header: '都道府県', key: 'state' },
      { header: '住所1', key: 'address1' },
      { header: '住所2', key: 'address2' },
      { header: '担当者名', key: 'mco_t_name' },
      { header: '担当者名（カナ）', key: 'mco_t_kana' },
      { header: 'メールアドレス', key: 'email' },
    ];
    try {
      const response = await getTmemberCsvDataForMfk(); // データを取得
      if (response.error) {
        onClose();
        openErrorModal({ message: 'CSVファイルの取得に失敗しました。' });
      } else {
        const csvData = response.data?.getTmemberCsvDataForMfk?.csv_data ?? '';
        const rows = csvData.split('\n').map((row) => row.split(','));
        await downloadCsvFile(columns, rows, csvFileName); // CSVファイルをダウンロード
        onClose(); // ダウンロードが完了した後にonCloseを呼び出す
      }
    } catch (error) {
      onClose();
      openErrorModal({ message: 'CSVファイルの取得に失敗しました。' });
    }
  }, [getTmemberCsvDataForMfk, onClose, openErrorModal]);

  const modal = {
    header: (
      <Text variant="h2" color="darkBlue">
        決済会社用CSV出力
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center" flex="1">
        {loading && <Loading />}
        <Box display="flex" flexDirection="column" gap={16} flex="1">
          <Text variant="body14">まだCSVを出力していない登録店舗のCSVを出力します。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
        <MainButton
          width={104}
          variant="primary"
          thin
          onClick={() => {
            onClickDownload();
          }}
        >
          ダウンロード
        </MainButton>
      </Box>
    ),
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={modal.header}
      content={modal.content}
      footer={modal.footer}
      width={384}
      height={189}
    />
  );
}
