import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Box } from '../box';
import { Divider } from '../newDivider';

type AlignItemsType = 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';

export interface Props {
  children: ReactNode;
  isDivider?: boolean;
  width?: string | number;
  height?: string | number;
  dividerHeight?: number;
  alignItems?: AlignItemsType;
}

const StyledWordContent = styled.div`
  word-wrap: break-word;
`;

export function CampaignTableItem({ children, isDivider, width, height, dividerHeight, alignItems }: Props) {
  return (
    <>
      {isDivider && (
        <Box width={1} height={dividerHeight}>
          <Divider option="vertical" length={dividerHeight} />
        </Box>
      )}
      <StyledWordContent>
        <Box width={width} height={height} display="flex" alignItems={alignItems} flex="none">
          {children}
        </Box>
      </StyledWordContent>
    </>
  );
}
