import React, { HTMLInputTypeAttribute, useId } from 'react';
import { InputLabel } from 'components/inputLabel';
import { Box } from 'components/box';
import { RhfInput } from 'components/rhfInput';
import { Control, RegisterOptions } from 'react-hook-form';
import { Text } from 'components/text';
import { BasicModalType } from '../s10-02-types';

export interface Props {
  label: string;
  optional?: boolean;
  name: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  width?: number;
  control?: Control<BasicModalType>;
  rules?: RegisterOptions<BasicModalType>;
  unit?: string;
  defaultValue?: string;
}

/**
 * Figma ID: 10-02-06-02、10-02-07-02、10-02-08-05
 * 名称: バリデーション用共通入力フォーム
 */
export function RhfBasicForm({
  label,
  name,
  type,
  placeholder,
  optional,
  width = 328,
  control,
  rules,
  unit,
  defaultValue,
}: Props) {
  const id = useId();
  type BasicModalTypeKeys = keyof BasicModalType;
  return (
    <Box>
      <Box pb={4}>
        <InputLabel htmlFor={id} optional={optional} width={width}>
          {label}
        </InputLabel>
      </Box>
      <Box display="flex" alignItems="center" height={32}>
        <RhfInput
          id={id}
          name={name as BasicModalTypeKeys}
          type={type}
          width={width}
          placeholder={placeholder}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
        />
        {unit && (
          <Box pl={8}>
            <Text color="blueGray" variant="body14">
              {unit}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
