/**
 * Figma ID: 10-08-01-02, 10-08-01-03
 * 名称: eギフト設定（更新モーダル）
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import type { UpdateDeadlinePhase } from '../GiftUpdate';

type UpdateDeadlineHeaderModel = {
  updateDeadlinePhase?: UpdateDeadlinePhase;
};
function UpdateDeadlineHeader(props: UpdateDeadlineHeaderModel) {
  const { updateDeadlinePhase } = props;
  const phaseTitle = () => {
    switch (updateDeadlinePhase) {
      case 'confirmUpdateData':
        return '更新 確認';
      default:
        return '更新';
    }
  };
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Text variant="h2" color="darkBlue">
        {phaseTitle()}
      </Text>
    </Box>
  );
}

export default UpdateDeadlineHeader;
