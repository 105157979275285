/**
 * Figma ID: 10-01-03-03
 * 名称: 管理アカウント（メールアドレス編集）
 */

import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';

type Props = {
  onClickClose: () => void;
};

export function CompleteUpdateMailModal({ onClickClose }: Props) {
  return (
    <Modal
      isOpen
      header={
        <Box pl={5}>
          <Text color="darkBlue" variant="h2">
            確認コードを入力 完了
          </Text>
        </Box>
      }
      content={
        <Box pa={8}>
          <Text variant="body14">確認コードの入力が完了しました。</Text>
          <br />
          <Text variant="body14">ログイン画面から再度ログインしてください。</Text>
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end" gap={4}>
          <MainButton onClick={onClickClose} width={130} variant="secondary">
            ログイン画面へ
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      height={210}
      width={384}
    />
  );
}
