import React, { ReactNode } from 'react';
import { Box } from '../box';
import { Divider } from '../newDivider';

export interface Props {
  children: ReactNode;
  isDivider?: boolean;
  pa?: number;
  pl?: number;
  pt?: number;
  pb?: number;
  pr?: number;
  gap?: number;
  height?: number | string;
  width?: number | string;
}

export function CampaignTableRow({ children, pa, pl, pt, pb, pr, gap, height, width, isDivider }: Props) {
  return (
    <>
      {isDivider && <Divider option="horizontal" />}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        pa={pa}
        pl={pl}
        pt={pt}
        pb={pb}
        pr={pr}
        gap={gap}
        height={height}
        width={width}
        flex="none"
      >
        {children}
      </Box>
    </>
  );
}
