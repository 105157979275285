import React from 'react';
import { styled } from '@linaria/react';
import { GENERIC_COLOR, LABEL_COLOR } from '../assets/css/style';

export interface Props {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  width?: number;
  fullWidth?: boolean;
  height?: number;
  fullHeight?: boolean;
  error?: boolean;
}

const StyledTextarea = styled.textarea<{
  width: number;
  fullWidth?: boolean;
  height: number;
  fullHeight?: boolean;
  error: boolean;
}>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  height: ${({ height, fullHeight }) => (fullHeight ? '100%' : `${height}px`)};
  padding: 4px 8px;
  background: ${GENERIC_COLOR.WHITE};
  border: 1px solid ${({ error }) => (error ? GENERIC_COLOR.CAUTION_RED : GENERIC_COLOR.SUPERLITE_GRAY)};
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${GENERIC_COLOR.BLACK};
  resize: none;
  outline: none;
  &:focus {
    border: 1px solid ${LABEL_COLOR.BLUE};
  }

  ::placeholder {
    color: ${GENERIC_COLOR.LITE_GRAY};
  }
`;

export function Textarea({
  id,
  name,
  value,
  width = 328,
  height = 188,
  placeholder,
  fullWidth,
  fullHeight,
  onChange,
  error = false,
}: Props) {
  return (
    <StyledTextarea
      id={id}
      name={name}
      value={value}
      width={width}
      height={height}
      placeholder={placeholder}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      onChange={(event) => onChange?.(event)}
      error={error}
    />
  );
}
