import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useEffect } from 'react';
import { useGetVtBelongListMemberByTpmemIdIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { MemberStatus } from 'pages/s03/const';
import { Chip } from 'components/chip';
import { Info } from 'components/info';
import { InfoItemsType } from 'pages/s03/type';
import { Divider } from 'components/newDivider';
import { useNavigate } from 'react-router-dom';
import { styled } from '@linaria/react';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { ChangeBigImgWithStatus } from '../changeBigImgWithStatus';

enum BtMemberStatus {
  Unregistered = 0, // 未登録
  Active = 1, // アクティブ
  Pending = 2, // 一時利用停止
  IndefinitelySuspend = 3, // 無期限利用停止
  Suspended = 4, // 休止中
}
const StyledChipWrapper = styled.div`
  width: 56px;
`;

export function MemberInfoModal(props: {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  tpmemId: number;
  label: string;
}) {
  const { isOpen, onClose, id, tpmemId, label } = props;
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();
  const [
    getVtBelongListMemberByTpmemIdIdLazy,
    { data: vtMemberBelongListData, loading: vtMemberBelongListLoading, error: vtMemberBelongListError },
  ] = useGetVtBelongListMemberByTpmemIdIdLazyQuery({
    variables: {
      id,
      tpmem_id: tpmemId,
    },
  });
  useEffect(() => {
    if (id !== undefined && id !== 0 && tpmemId !== undefined && tpmemId !== 0) {
      getVtBelongListMemberByTpmemIdIdLazy({ variables: { id, tpmem_id: tpmemId } });
    }
  }, [id, tpmemId, getVtBelongListMemberByTpmemIdIdLazy]);

  useEffect(() => {
    if (vtMemberBelongListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [vtMemberBelongListError, openErrorModal]);

  const memberList = vtMemberBelongListData?.getVTBelongListMemberByTpmemIdId.member;

  const lcBizNamesList = memberList?.lc_biz_names?.split(',');
  const tpBizNamesList = memberList?.tp_biz_names?.split(',');
  const rankBizNamesList = memberList?.rank_biz_names?.split(',');
  const rankList = memberList?.rank_names?.split(',');
  const licenceList = memberList?.license_list?.split('\n');
  const mainNameList = memberList?.tpmem_names?.split(',');
  const subNameList = memberList?.sub_names?.split(',');

  const rankContent: InfoItemsType = (rankBizNamesList ?? []).map((lcBizNames, index) => ({
    label: `ランク(${lcBizNames})`,
    content: rankList?.[index] || '-',
  }));
  const licenceContent: InfoItemsType = (lcBizNamesList ?? []).map((lcBizName, index) => ({
    label: `${lcBizName}資格`,
    content: licenceList?.[index] || '-',
  }));

  const belongStoreContent = () => {
    const item = [];
    if (tpBizNamesList !== undefined) {
      for (let i = 0; i < tpBizNamesList.length; i += 1) {
        item.push({ label: `所属店舗(${tpBizNamesList[i]})`, content: mainNameList?.[i] });
        item.push({ label: `サブ店舗(${tpBizNamesList[i]})`, content: subNameList?.[i] || '-' });
      }
    }
    return item;
  };

  const infoItems: InfoItemsType = [
    { label: '氏名(フリガナ)', content: memberList?.kana },
    { label: 'メールアドレス(ID)', content: memberList?.email },
    { label: '会員番号', content: memberList?.id },
    { label: '電話番号', content: memberList?.phone },
    { label: '生年月日', content: memberList?.birthday },
    { label: '年齢', content: memberList?.age },

    ...rankContent,

    { label: '達成都道府県', content: memberList?.state_count },
    { label: 'バイト回数', content: `${memberList?.worked_count ?? '0'}  ${memberList?.badge_name ?? ''}` },
    { label: '入会日', content: memberList?.created_date },
    {
      label: '住所',
      content: `〒${memberList?.zip_code?.slice(0, 3) ?? ''}-${memberList?.zip_code?.slice(3, 7) ?? ''} 
      ${memberList?.state ?? ''}${memberList?.city ?? ''}${memberList?.address ?? ''}${memberList?.address2 ?? ''}`,
    },

    ...belongStoreContent(),

    { label: '性別', content: memberList?.gender },
    { label: '属性', content: memberList?.attribute || '-' },
    { label: '保有資格', content: memberList?.general_licenses || '-' },

    ...licenceContent,

    { label: '制服サイズ', content: memberList?.uniform_size },
    { label: '遅刻率', content: `${memberList?.late_count ?? 0}回 / ${memberList?.late_per ?? 0}%` },
    { label: 'キャンセル率', content: `${memberList?.cancel_count ?? 0}回 / ${memberList?.cancel_per ?? 0}%` },
    { label: 'ドタキャン率', content: `${memberList?.imminent_count ?? 0}回 / ${memberList?.imminent_per ?? 0}%` },
    { label: 'バックレ回数', content: `${memberList?.imminent_count ?? 0}回` },

    { label: '登録業種', content: memberList?.reg_biz_list },
    { label: '入金口座', content: memberList?.bank },
    { label: '本人確認', content: identificationLabel(memberList?.identification ?? 0) },
  ];

  const modalContent = {
    header: (
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text variant="h2" color="darkBlue">
          {memberList?.name}
        </Text>
        <MainButton variant="secondary" width={74} onClick={() => navigate(`/s05/${memberList?.id || 0}`)}>
          詳しく
        </MainButton>
      </Box>
    ),
    content: (
      <Box display="flex" flexDirection="column" rowGap={8} pa={0} width="100%">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            alignItems: 'center',
            height: '60px',
            backgroundColor: `${memberList?.status === BtMemberStatus.Suspended ? 'rgba(229, 0, 0, 0.1)' : ''}`,
          }}
        >
          <ChangeBigImgWithStatus
            status={memberList?.status === BtMemberStatus.Active ? BtMemberStatus.Active : MemberStatus.Suspended}
          >
            <Box backgroundColor="black" display="flex" justifyContent="center">
              <img
                src={memberList?.image ?? ''}
                alt=""
                style={{ width: '80px', height: '60px', objectFit: 'contain' }}
              />
            </Box>
          </ChangeBigImgWithStatus>
          <Box display="flex" flexDirection="column" gap={1} height={48}>
            <StyledChipWrapper>
              <Chip color="darkBlue" width={56}>
                {label}
              </Chip>
            </StyledChipWrapper>
            <Text color="darkBlue" variant="h1">
              {memberList?.name}
            </Text>
          </Box>
        </div>
        <Divider option="horizontal" />
        <Info items={infoItems} />
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 1003,
    height: '90vh',
  };

  return (
    <>
      {vtMemberBelongListLoading && <Loading />}
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        header={modalContent.header}
        content={modalContent.content}
        footer={modalContent.footer}
        width={modalContent.width}
        height={modalContent.height}
      />
    </>
  );
}
