import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';

type ContentTitleModel = {
  title: string;
};

export function ContentTitle(props: ContentTitleModel) {
  const { title } = props;
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Text bold variant="body16" color="primaryBlue">
        {title}
      </Text>
      <Divider option="horizontal" length={376} />
    </Box>
  );
}
