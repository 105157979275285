import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { Icon } from 'components/icon';
import { VbPayCsvAllList, useUpdateCMonthPayCsvDlByCsvNoMutation } from 'graphql/graphql-ow';
import { usePagenation, useSort } from 'components/utils';
import { orderBy } from 'lodash';
import { useAuth } from 'hooks/authProvider';
import { downloadFromUrl } from 'components/csv';

interface Props {
  paidSalaries: VbPayCsvAllList[];
  refetch: () => void;
}

const StyledListWrapper = styled.div`
  height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
  overflow: auto;
`;

// Figma管理番号
// 10-06-01
// 10-06-02
// 10-06-03
export function AllMonthlyPartTimeJobExpenses({ paidSalaries, refetch }: Props) {
  const [dataSize, setDataSize] = React.useState<number>(0);
  const { userInfo } = useAuth();
  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page } = usePagenation();
  const [updateDownloadDate] = useUpdateCMonthPayCsvDlByCsvNoMutation();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [csvNo, setCsvNo] = React.useState<number>(0);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [downloadCsvData, setDownloadCsvData] = React.useState<{ name?: string; url?: string }>();

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { width: 40, key: 'three_point_reader' },
    { width: 128, key: 'pay_date', columnName: '発行年月' },
    { width: 160, key: 'output_number', columnName: '出力No.' },
    { width: 140, key: 'output_date', columnName: '生成日時' },
    { width: 140, key: 'dl_date', columnName: '最終ダウンロード日' },
    { width: 140, key: 'name', columnName: '最終ダウンロード者' },
    { width: 130, key: 'dl_count', columnName: 'ダウンロード回数' },
  ];

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let items = paidSalaries;
    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'status', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }
    return items
      .map((item) => ({
        uniqueKey: Math.random(),
        three_point_reader: (
          <ThreeDotLeader
            menu={[{ label: '全件ダウンロード', value: 'download' }]}
            onClick={() => {
              setIsModalOpen(true);
              setCsvNo(item.csv_no);
              const csvName = item.csv_url?.split('/');
              setDownloadCsvData({
                name: csvName?.[csvName.length - 1],
                url: item.csv_url ?? '',
              });
            }}
          />
        ),
        pay_date: item.unopened ? (
          <Box display="flex" alignItems="center" gap={4}>
            <Icon name="indicatorRed" size={8} />
            <Text variant="caption12">{item.pay_date}</Text>
          </Box>
        ) : (
          item.pay_date
        ),
        output_number: item.output_number,
        output_date: item.output_date,
        dl_date: item.dl_date,
        name: item.name,
        dl_count: item.dl_count,
      }))
      .slice(limit * (page - 1), limit * page);
  }, [paidSalaries, sort, limit, page]);

  return (
    <>
      <Box px={24} pt={6} flexDirection="column" alignItems="center" gap={16} width="100%" overflow="hidden">
        <Box display="flex" gap={8} alignItems="center" width="100%" py={18}>
          <Text variant="h2" color="darkBlue">
            バイト費用月次全件
          </Text>
          <Text variant="caption12" color="darkBlue">
            毎月末日にCSVが自動生成されます
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Box flex="auto" width="100%">
          <PaginationBox
            dataSize={dataSize}
            limit={limit}
            page={page}
            onChangeLimit={handleChangeLimit}
            onChangePage={handleChangePage}
          >
            <StyledListWrapper>
              <List
                key={String(limit) + String(page) + (sort?.key || '')}
                header={header}
                items={displayItems}
                sort={sort}
                onChangeSort={handleChangeSort}
                width="100%"
                rowWidth="100%"
                rowHeight={40}
              />
            </StyledListWrapper>
          </PaginationBox>
        </Box>
      </Box>
      <Modal
        width={384}
        height={231}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header={
          <Text variant="h2" color="darkBlue">
            全件ダウンロード
          </Text>
        }
        content={
          <Box display="grid" height="100%" alignItems="center">
            <Text variant="body14">
              全件ダウンロードを行うと、即日対応で出力した支払い請求も含まれます。
              <br />
              よろしいですか？
              <br />
              {errorMessage && (
                <Text variant="caption12" color="cautionRed">
                  {errorMessage}
                </Text>
              )}
            </Text>
          </Box>
        }
        footer={
          <Box display="flex" justifyContent="flex-end" columnGap={8}>
            <MainButton
              width={104}
              variant="secondary"
              onClick={() => {
                setIsModalOpen(false);
                setErrorMessage('');
              }}
            >
              キャンセル
            </MainButton>
            <MainButton
              width={104}
              onClick={() => {
                updateDownloadDate({ variables: { download_uid: Number(userInfo?.id) ?? '', csv_no: csvNo } })
                  .then(() => {
                    downloadFromUrl(downloadCsvData?.name ?? '', downloadCsvData?.url ?? '');
                    setErrorMessage('');
                    setIsModalOpen(false);
                    refetch();
                  })
                  .catch((e) => setErrorMessage('エラーが発生しました。時間をおいて再度お試しください。'));
              }}
            >
              ダウンロード
            </MainButton>
          </Box>
        }
      />
    </>
  );
}
