import { Box } from 'components/box';
import { Tab } from 'components/newTab';
import { TabItemsType } from 'pages/s03/type';
import React, { useEffect } from 'react';
import { useGetVtMemberBelongListByIdLazyQuery } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { Content } from './content';

/**
 * Figma ID: 03-05-01
 * 所属
 * Belong
 */

export function Belong(props: { id: number }) {
  const { id } = props;
  const { openErrorModal } = useErrorModal();
  const [
    getVtMemberBelongListById,
    { data: vTMemberBelongListByIdData, loading: vTMemberBelongListByIdLoading, error: vTMemberBelongListByIdError },
  ] = useGetVtMemberBelongListByIdLazyQuery({ variables: { id } });

  useEffect(() => {
    if (vTMemberBelongListByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTMemberBelongListByIdError, openErrorModal]);

  const data = vTMemberBelongListByIdData?.getVTMemberBelongListById;

  const tabItems: TabItemsType = [
    {
      label: '所属',
      content: <Content data={data?.belong ?? null} label="所属" belongNum={data?.member?.belong_num ?? 0} />,
    },
    {
      label: 'サブ',
      content: <Content data={data?.sub ?? null} label="サブ" belongNum={data?.member?.sub_num ?? 0} />,
    },
  ];

  useEffect(() => {
    if (id !== undefined && id !== 0) {
      getVtMemberBelongListById({ variables: { id } });
    }
  }, [id, getVtMemberBelongListById]);

  return (
    <Box px={16} pt={24} gap={18} display="flex" flex="1" overflow="hidden">
      {vTMemberBelongListByIdLoading && <Loading />}
      <Tab items={tabItems} />
    </Box>
  );
}
