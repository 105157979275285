/**
 * Figma ID: 07-01-01, 07-01-03
 * 名称: 逆オファー（ステータスチップ）
 */
import React from 'react';
import { Chip } from 'components/chip';
import { chipColors } from 'components/chip/chip';
import { ReversedOfferListStatusIndex } from '../status';

type ChipColorKeyType = keyof typeof chipColors;
type StatusChipModel = {
  status: number;
  variant?: 'big' | 'normal';
  width?: number;
};

const getRevercedOfferStatusTextColor = (status: number) => {
  let statusText = '';
  let statusColor: ChipColorKeyType;
  switch (status) {
    case ReversedOfferListStatusIndex.REVERCED_OFFER:
      statusText = '逆オファー';
      statusColor = 'darkBlue';
      break;
    case ReversedOfferListStatusIndex.SUCCESS:
      statusText = '成約';
      statusColor = 'okBlue';
      break;
    case ReversedOfferListStatusIndex.EXPIRED:
      statusText = '期限終了';
      statusColor = 'liteGray';
      break;
    case ReversedOfferListStatusIndex.DELETE:
      statusText = '削除';
      statusColor = 'liteGray';
      break;
    default:
      statusText = '強制停止';
      statusColor = 'cautionRed';
  }
  return {
    statusText,
    statusColor,
  };
};

function StatusChipContent(variant: StatusChipModel['variant'], color: ChipColorKeyType, text: string, width?: number) {
  return (
    <Chip color={color} width={width} variant={variant}>
      {text}
    </Chip>
  );
}

export function RevercedOfferStatusChip(props: StatusChipModel) {
  const { status, width, variant } = props;
  const result = getRevercedOfferStatusTextColor(status);
  return StatusChipContent(variant, result.statusColor, result.statusText, width);
}
