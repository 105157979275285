/**
 * Figma ID: 10-08-03-02
 * 名称: 固定特典設定(設定)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

import { RhfSelect } from 'components/rhfSelect';
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { GetSEgiftCodeQuery } from 'graphql/graphql-ow';
import type { SelectedGiftModel } from '../FixedPrivilegeSetting';

export function SelectGiftHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        設定
      </Text>
    </Box>
  );
}

type SelectGiftContentModel = {
  selectOption: GetSEgiftCodeQuery | undefined;
  control: Control<SelectedGiftModel>;
  watch: UseFormWatch<SelectedGiftModel>;
  setValue: UseFormSetValue<SelectedGiftModel>;
  errors: FieldErrors<SelectedGiftModel>;
};
type SelectOptionModel = {
  label: string;
  value: string;
};
export function SelectGiftContent(props: SelectGiftContentModel) {
  const { selectOption, setValue, control, watch, errors } = props;
  const [optionData, setOptionData] = React.useState<SelectOptionModel[]>([]);
  const currentConfigCode = watch('configCode');
  React.useEffect(() => {
    const optionResult: SelectOptionModel[] = [];
    selectOption?.getSEgiftCode?.map((option) =>
      optionResult.push({ label: option?.name ?? '', value: option?.egift_code.toString() ?? '' })
    );
    setOptionData(optionResult);
  }, [selectOption]);
  React.useEffect(() => {
    selectOption?.getSEgiftCode.map(
      (val) =>
        val?.egift_code.toString() === currentConfigCode &&
        (setValue('title', val?.name ?? ''),
        setValue('deadline', val?.deadline ?? ''),
        setValue('egiftCode', val?.egift_code ?? ''))
    );
  }, [currentConfigCode, selectOption?.getSEgiftCode, setValue]);
  return (
    <Box>
      <Box pb={8}>
        <Text variant="body14">eギフトを選択してください。</Text>
      </Box>
      <Box pb={18}>
        <RhfSelect
          control={control}
          options={optionData}
          name="configCode"
          placeholder="選択"
          defaultValue=""
          rules={{ required: 'ギフトを選択してください。' }}
        />
        {errors.configCode?.message && (
          <Text color="cautionRed" variant="caption12">
            {errors.configCode.message}
          </Text>
        )}
      </Box>
    </Box>
  );
}

type CompleteFooterModel = {
  onClickClose: () => void;
  onClickNext: () => void;
};
export function SelectGiftFooter(props: CompleteFooterModel) {
  const { onClickClose, onClickNext } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickNext} width={104}>
        次へ
      </MainButton>
    </Box>
  );
}
