/**
 * Figma ID: 07-01-01
 * 名称: 逆オファー（検索バー）
 */
import React from 'react';
import { Box } from 'components/box';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Select } from 'components/select';
import { Divider } from 'components/newDivider';
import type { ReverceOfferSearchWordStateModel } from '../index';

type SearchHeaderModel = {
  searchWord?: ReverceOfferSearchWordStateModel;
  setSearchWord: ({ ...ReverceOfferSearchWordStateModel }) => void;
  searchAction: (bizId?: string, brandId?: string) => void;
  setCurrentPage: (x: number) => void;
  clearAction: () => void;
  bizNameList: { value: string; label: string }[];
  brandNameList: { value: string; label: string }[];
};
export default function SearchHeader(props: SearchHeaderModel) {
  const { searchWord, setSearchWord, setCurrentPage, searchAction, clearAction, bizNameList, brandNameList } = props;
  return (
    <Box display="flex" alignItems="center" pa={16} gap={16} flexWrap="wrap">
      <Input
        value={searchWord?.offerNo ?? ''}
        onChange={(event) => setSearchWord({ ...searchWord, offerNo: event.target.value })}
        width={120}
        placeholder="オファーNo."
      />
      <Input
        value={searchWord?.btmemName ?? ''}
        onChange={(event) => setSearchWord({ ...searchWord, btmemName: event.target.value })}
        width={120}
        placeholder="氏名"
      />
      <Input
        value={searchWord?.stateCity ?? ''}
        onChange={(event) => setSearchWord({ ...searchWord, stateCity: event.target.value })}
        width={120}
        placeholder="希望地域"
      />
      <MainButton
        variant="primary"
        width={80}
        icon="search"
        onClick={() => searchAction(searchWord?.bizId, searchWord?.brandId)}
      >
        検索
      </MainButton>
      <Box>
        <Divider option="vertical" length={24} />
      </Box>
      <Select
        value={searchWord?.bizId ?? ''}
        options={bizNameList}
        onChange={(value) => {
          setCurrentPage(1);
          setSearchWord({ ...searchWord, bizId: value });
          searchAction(value, searchWord?.brandId);
        }}
        placeholder="業種"
        width={120}
      />
      <Select
        value={searchWord?.brandId ?? ''}
        options={brandNameList}
        onChange={(value) => {
          setCurrentPage(1);
          setSearchWord({ ...searchWord, brandId: value });
          searchAction(searchWord?.bizId, value);
        }}
        disabled={!searchWord?.bizId}
        placeholder="全てのブランド"
        width={160}
      />
      <MainButton variant="clear" width={44} onClick={() => clearAction()}>
        クリア
      </MainButton>
    </Box>
  );
}
