/**
 * Figma ID: 10-01-02-03, 10-01-02-04, 10-01-02-05, 10-01-02-06
 * 名称: ユーザー招待 新規登録
 */
import React from 'react';
import { Box } from 'components/box';
import { BitravelLogo } from 'components/assets/css/pages/s01/screen01Style';
import { Text } from 'components/text';
import { Divider } from 'components/newDivider';
import { useAuth } from 'hooks/authProvider';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import RegisterMailAndPassword from './RegisterMailAndPassword';
import RegisterInformationSubmitted from './RegisterInformationSubmitted';
import InputConfirmCode from './InputConfirmCode';
import CompleteConfirmCode from './CompleteConfirmCode';

type RegisterPhaseModel = 'SendMail' | 'CompleteSendMail' | 'InputConfirmCode' | 'CompleteCode';

export default function Screen10010203() {
  const auth = useAuth();
  // クエリストリングのemailを取得する
  const [searchParams] = useSearchParams();
  const [registerPhase, setRegisterPhase] = React.useState<RegisterPhaseModel>('SendMail');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [storedEmail, setStoredEmail] = React.useState<string>('');
  const { openErrorModal } = useErrorModal();
  const navigate = useNavigate();

  const inviteTitle = () => {
    switch (registerPhase) {
      case 'SendMail':
        return '新規登録 - パスワード設定';
      case 'CompleteSendMail':
        return '新規登録 完了';
      case 'InputConfirmCode':
        return '確認コードを入力';
      default:
        return '確認コードを入力 完了';
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setStoredEmail(email);
      await auth.signUp(searchParams.get('email') || email, password);
      setRegisterPhase('CompleteSendMail');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { message } = error;
      if (message === 'An account with the given email already exists.') {
        openErrorModal({
          message: 'メールアドレスは既に使用されています。確認コードが未入力の場合は確認コードを入力してください。',
        });
        setRegisterPhase('InputConfirmCode');
        return;
      }
      openErrorModal({ message: 'エラーが発生しました' });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmSignUp = async (confirmationCode: string) => {
    try {
      setIsLoading(true);
      await auth.confirmSignUp(searchParams.get('email') || storedEmail, confirmationCode);
      setRegisterPhase('CompleteCode');
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { message } = error;
      if (message === 'User cannot be confirmed. Current status is CONFIRMED') {
        openErrorModal({
          message: 'このユーザーは既に確認済みです',
        });
        setRegisterPhase('CompleteCode');
        return;
      }
      openErrorModal({ message: 'エラーが発生しました' });
    } finally {
      setIsLoading(false);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      setIsLoading(true);
      await auth.resendConfirmationCode(searchParams.get('email') || storedEmail);
    } catch {
      openErrorModal({ message: 'エラーが発生しました' });
    } finally {
      setIsLoading(false);
    }
  };

  const toLogin = () => {
    // ログアウト後にログイン画面に遷移する
    auth.signOut().then(() => {
      navigate('/signIn');
    });
  };

  const inviteContent = () => {
    switch (registerPhase) {
      case 'SendMail':
        return (
          <RegisterMailAndPassword queryStringEmail={searchParams.get('email')} onSubmitEmailAndPassword={signUp} />
        );
      case 'CompleteSendMail':
        return <RegisterInformationSubmitted onClickNext={() => setRegisterPhase('InputConfirmCode')} />;
      case 'InputConfirmCode':
        return (
          <InputConfirmCode onSubmitConfirmationCode={confirmSignUp} reSendConfirmationCode={resendConfirmationCode} />
        );
      default:
        return <CompleteConfirmCode onClickToLogin={toLogin} />;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" py={40}>
        <Box width={376} display="flex" flexDirection="column" gap={32}>
          <Box width="100%" display="flex" justifyContent="center" gap={4}>
            <BitravelLogo width={104} height={17.96} src="/bitravel_logo.png" alt="bitravel_logo" />
            <Text color="darkBlue" variant="body14" bold>
              運営管理
            </Text>
          </Box>
          <Box display="flex" flexDirection="column" gap={24}>
            <Box>
              <Text bold variant="body16" color="primaryBlue">
                {inviteTitle()}
              </Text>
              <Divider option="horizontal" length={356} />
            </Box>
            <Box>{inviteContent()}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
