import { Card } from 'components/card';
import { SubMenu } from 'components/subMenu';
import React from 'react';
import { VtMemberBase } from 'graphql/graphql-ow';

import { SubMenuItemsType } from '../type';
import { BaseInfo, Belong, Block, Education, Group, Review, Ticket, Usage } from './details';

/**
 * Figma ID: 03-04-01
 * 基本情報
 * BaseInfo
 */
/**
 * Figma ID: 03-05-01
 * 所属
 * Belong
 */
/**
 * Figma ID: 03-06-01
 * レビュー
 * Review
 */
/**
 * Figma ID: 03-07-01
 * 店舗ブロック
 * Block
 */
/**
 * Figma ID: 03-08-01
 * グループ店舗
 * Group
 */
/**
 * Figma ID: 03-09-01
 * トライアルチケット
 * Ticket
 */
/**
 * Figma ID: 03-10-01
 * 利用履歴
 * Usage
 */
/**
 * Figma ID: 03-11-01
 * 育成料履歴
 * Education
 */

export function RegisteredCard(props: { data: VtMemberBase | null }) {
  const { data } = props;
  const subMenuItems: SubMenuItemsType = [
    { label: '基本情報', content: <BaseInfo id={data?.id ?? 0} /> },
    { label: `所属(${data?.belong_total ?? 0})`, content: <Belong id={data?.id ?? 0} /> },
    { label: 'レビュー', content: <Review id={data?.id ?? 0} /> },
    {
      label: `店舗ブロック(${data?.block_num ?? 0})`,
      content: <Block id={data?.id ?? 0} name={data?.store_name ?? ''} />,
    },
    { label: `グループ店舗(${data?.group_num ?? 0})`, content: <Group id={data?.id ?? 0} /> },
    { label: 'トライアルチケット', content: <Ticket id={data?.id ?? 0} /> },
    { label: '利用履歴', content: <Usage id={data?.id ?? 0} /> },
    { label: '育成料履歴', content: <Education id={data?.id ?? 0} /> },
  ];

  return (
    <Card overflow="hidden">
      <SubMenu items={subMenuItems} />
    </Card>
  );
}
