import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { STpmem } from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { BONUS_PER_OPTIONS, CHIP_TEXT, InputType, ModalStep, ListTitleWidth } from '../constants';
import { chipColor, leaderMenu } from '../s10-03-utils';
import { MiniModalViewDataTyep } from '../s10-03-types';
import { BasicModal } from '../modal/basic-modal';
/**
 * Figma ID: 10-03-07-01
 * 名称: ボーナス設定
 */

export interface Props {
  cpRow: STpmem[];
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
}

const makeListData = ({ cpRow, setModalStep }: Props) =>
  cpRow.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              料率
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row.bonus_per}%
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setModalStep(ModalStep.INPUT)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

export function BonusSettings() {
  const { openErrorModal } = useErrorModal();

  const [modalViewData, setModalViewData] = useState<MiniModalViewDataTyep>();
  const { data: getData, loading, error, refetch } = gql.useGetSTpmemQuery();
  const [update, { error: bonusPerError, loading: updateLoading }] = gql.useUpdateSTpmemBonusPerMutation();
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const handleModalClose = useCallback(() => {
    refetch().then((e) => {
      if (e.loading) {
        setModalStep(ModalStep.NULL);
      }
    });
  }, [refetch]);

  useEffect(() => {
    if (getData) {
      setModalViewData({
        title: {
          input: 'ボーナス設定',
          check: 'ボーナス設定',
          complete: 'ボーナス設定 完了',
        },
        label: '料率（％）',
        name: 'bonus_per',
        placeholder: '選択',
        inputType: InputType.Select,
        unit: '%',
        text: '料率',
        options: BONUS_PER_OPTIONS,
        data: getData?.getSTpmem.bonus_per,
        infoText: '設定可能範囲：10% ~ 100%',
      });
    }
  }, [getData]);
  const listItems = useMemo(
    () =>
      makeListData({
        cpRow: getData?.getSTpmem ? [getData?.getSTpmem] : [],
        setModalStep,
      }),
    [getData]
  );
  const { displayModal } = BasicModal({
    modalStep,
    setModalStep,
    onClose: () => handleModalClose(),
    modalViewData,
    mutation: (data?: string | number | null) => {
      update({ variables: { bonus_per: data as number } })
        .then((e) => {
          if (!e.errors && !bonusPerError) {
            setModalStep(ModalStep.COMPLETE);
            refetch();
          } else {
            openErrorModal({
              message: '料率の設定に失敗しました。\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    },
  });

  if (error) {
    openErrorModal({
      message:
        'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      zIndex: ModalZindex.ERRMODALZINDEX,
    });
    refetch();
  }

  if (loading || updateLoading) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                ボーナス設定
              </Text>
              <Text color="darkBlue" variant="caption12">
                全体に適用される育成料に対するボーナスの料率を設定
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <ScrollWrapper bottom={311}>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollWrapper>
        </Box>
      </HideWrapper>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => displayModal.onClose}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow="visible"
      />
    </>
  );
}
