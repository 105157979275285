import React, { createContext, ReactNode, useContext, useState, useMemo } from 'react';
import { ErrorModal } from '../errorModal';

interface ErrorModalContextType {
  openErrorModal: (args: {
    title?: string;
    message: string | React.ReactNode;
    onClose?: () => void;
    zIndex?: number;
  }) => void;
}
export const ErrorModalContext = createContext<ErrorModalContextType>({ openErrorModal: () => {} });
type Props = {
  children: ReactNode;
};
export function ErrorModalProvider({ children }: Props) {
  const [message, setMessage] = useState<string | React.ReactNode>('');
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [onClose, setOnClose] = useState<(() => void) | undefined>(undefined);
  const [zIndex, setzIndex] = useState<number | undefined>(undefined);

  const handleOnClose = () => {
    onClose?.();
    setTitle(undefined);
    setMessage('');
    setOnClose(undefined);
    setzIndex(undefined);
  };
  const value = useMemo(
    () => ({
      openErrorModal: (args: {
        title?: string;
        message: string | React.ReactNode;
        onClose?: () => void;
        zIndex?: number;
      }) => {
        setTitle(args.title);
        setMessage(args.message);
        setOnClose(() => args.onClose);
        setzIndex(args.zIndex);
      },
    }),
    []
  );
  return (
    <ErrorModalContext.Provider value={value}>
      <ErrorModal isOpen={!!message} message={message} title={title} onClose={handleOnClose} zIndex={zIndex} />
      {children}
    </ErrorModalContext.Provider>
  );
}
export const useErrorModal = () => useContext(ErrorModalContext);
