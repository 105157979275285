import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React from 'react';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';
import { DeleteModalStep } from '../constants';

/**
 * Figma ID: 10-02-01-06, 10-02-01-07, 10-02-02-06, 10-02-02-07, 10-02-09-06, 10-02-09-07, 10-03-06-06, 10-03-06-07, 10-03-08-06, 10-03-08-07
 * 名称: 削除モーダル
 */

interface Props {
  deleteModalStep: DeleteModalStep;
  deleteId: number;
  setDeleteModalStep: React.Dispatch<React.SetStateAction<DeleteModalStep>>;
  onClose?: () => void;
}

export function DeleteModal({ deleteModalStep, deleteId, setDeleteModalStep, onClose }: Props) {
  const { openErrorModal } = useErrorModal();

  const [deleteSCampaignByIdMutation] = gql.useDeleteSCampaignByIdMutation({
    variables: { id: deleteId },
  });

  const displayDeleteModal = React.useMemo(() => {
    switch (deleteModalStep) {
      case DeleteModalStep.CHECK:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              削除
            </Text>
          ),
          content: (
            <Text variant="body14">
              本当に情報を削除しますか？
              <br />
              削除した情報は元に戻せません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  setDeleteModalStep(DeleteModalStep.NULL);
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  deleteSCampaignByIdMutation()
                    .then((e) => {
                      if (!e.errors) {
                        setDeleteModalStep(DeleteModalStep.COMPLETE);
                      } else {
                        openErrorModal({
                          message: '削除に失敗しました。\n少し時間をおいてから再度お試しください。',
                          zIndex: ModalZindex.ERRMODALZINDEX,
                        });
                      }
                    })
                    .catch(() => {
                      openErrorModal({
                        message:
                          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                        zIndex: ModalZindex.ERRMODALZINDEX,
                      });
                    });
                }}
                variant="primary"
                width={104}
              >
                削除
              </MainButton>
            </Box>
          ),
          onClose: () => setDeleteModalStep(DeleteModalStep.NULL),
        };
      case DeleteModalStep.COMPLETE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              削除 完了
            </Text>
          ),
          content: <Text variant="body14">情報の削除が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                閉じる
              </MainButton>
            </Box>
          ),
          onClose: () => setDeleteModalStep(DeleteModalStep.NULL),
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose: () => setDeleteModalStep(DeleteModalStep.NULL),
        };
    }
  }, [deleteModalStep, onClose, deleteSCampaignByIdMutation, setDeleteModalStep, openErrorModal]);
  return { displayDeleteModal };
}
