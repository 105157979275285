import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useUpdateTScoreVisibleByWorkIdMutation,
  useUpdateBArbeitScoreVisibleByWorkIdMutation,
  useGetVWorkScoreInfoByWorkIdLazyQuery,
  useGetVtMemberBaseByIdLazyQuery,
  useGetVbMemberBaseByIdLazyQuery,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Avatar } from 'components/avatar';
import { Stars } from 'components/stars';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { styled } from '@linaria/react';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { Modal } from 'components/newModal';
import { StoreModal } from 'modal/storeModal';
import { BitravelMemberModal } from 'modal/bitravelMemberModal';

enum ModalStep {
  NULL = 0,
  TPMEM_COMMENT_DELETE_CONFIRMATION = 1,
  BTMEM_COMMENT_DELETE_CONFIRMATION = 2,
  DELETE_COMPLETE = 3,
}

const StyledOpenModalLink = styled.button`
  text-decoration-line: underline;
  color: ${PRIMARY_COLOR.PRIMARY_BLUE};
  border: 0;
  background-color: inherit;
  display: inline-flex;
  cursor: pointer;
`;

// Figma管理番号
// 11-01-03
// 11-01-06
// 11-01-07
export function S11Details() {
  const [isOpenStoreModal, setIsOpenStoreModal] = useState<boolean>(false);
  const [isOpenBitravelerModal, setIsOpenBitravelerModal] = useState<boolean>(false);
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);

  const { workId } = useParams();
  const [
    updateTScoreVisibleByWorkIdMutation,
    { loading: tScoreVisibleByWorkIdLoading, error: tScoreVisibleByWorkIdError },
  ] = useUpdateTScoreVisibleByWorkIdMutation();
  const [
    updateBArbeitScoreVisibleByWorkIdMutation,
    { loading: bScoreVisibleByWorkIdLoading, error: bScoreVisibleByWorkIdError },
  ] = useUpdateBArbeitScoreVisibleByWorkIdMutation();
  const { openErrorModal } = useErrorModal();

  const [
    getVWorkScoreInfoByWorkId,
    {
      data: vWorkScoreInfoByWorkIdData,
      loading: vWorkScoreInfoByWorkIdLoading,
      refetch,
      error: vWorkScoreInfoByWorkIdError,
    },
  ] = useGetVWorkScoreInfoByWorkIdLazyQuery();

  React.useEffect(() => {
    if (vWorkScoreInfoByWorkIdData === undefined && workId !== undefined) {
      getVWorkScoreInfoByWorkId({
        variables: {
          work_id: Number(workId),
        },
      });
    }
  }, [workId, vWorkScoreInfoByWorkIdData, getVWorkScoreInfoByWorkId]);
  const workScoreInfo = vWorkScoreInfoByWorkIdData?.getVWorkScoreInfoByWorkId;

  const [
    getVtMemberBaseById,
    { data: vTMemberBaseByIdData, loading: vTMemberBaseByIdLoading, error: vTMemberBaseByIdError },
  ] = useGetVtMemberBaseByIdLazyQuery();

  React.useEffect(() => {
    if (
      vTMemberBaseByIdData === undefined &&
      workScoreInfo?.tpmem_id !== undefined &&
      workScoreInfo?.tpmem_id !== 0 &&
      workScoreInfo?.tpmem_id !== null
    ) {
      getVtMemberBaseById({
        variables: {
          id: Number(workScoreInfo.tpmem_id),
        },
      });
    }
  }, [workScoreInfo?.tpmem_id, vTMemberBaseByIdData, getVtMemberBaseById]);
  const tMemberBase = vTMemberBaseByIdData?.getVTMemberBaseById;

  const [
    getVbMemberBaseById,
    { data: vBMemberBaseByIdData, loading: vBMemberBaseByIdLoading, error: vBMemberBaseByIdError },
  ] = useGetVbMemberBaseByIdLazyQuery();

  React.useEffect(() => {
    if (
      vBMemberBaseByIdData === undefined &&
      workScoreInfo?.btmem_id !== undefined &&
      workScoreInfo?.btmem_id !== 0 &&
      workScoreInfo?.btmem_id !== null
    ) {
      getVbMemberBaseById({
        variables: {
          id: Number(workScoreInfo.btmem_id),
        },
      });
    }
  }, [workScoreInfo?.btmem_id, vBMemberBaseByIdData, getVbMemberBaseById]);
  const bMemberBase = vBMemberBaseByIdData?.getVBMemberBaseById;

  React.useEffect(() => {
    if (tScoreVisibleByWorkIdError || bScoreVisibleByWorkIdError || vWorkScoreInfoByWorkIdError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [tScoreVisibleByWorkIdError, bScoreVisibleByWorkIdError, vWorkScoreInfoByWorkIdError, openErrorModal]);
  React.useEffect(() => {
    if (vTMemberBaseByIdError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message: '加盟店情報の取得ができませんでした。',
      });
    }
  }, [vTMemberBaseByIdError, openErrorModal]);
  React.useEffect(() => {
    if (vBMemberBaseByIdError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message: 'バイトラベル会員情報の取得ができませんでした。',
      });
    }
  }, [vBMemberBaseByIdError, openErrorModal]);

  const navigate = useNavigate();

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.TPMEM_COMMENT_DELETE_CONFIRMATION:
        return {
          width: 384,
          height: 212,
          header: (
            <Text variant="h2" color="darkBlue">
              削除
            </Text>
          ),
          content: (
            <Text variant="body14">
              本当にコメントを削除しますか？
              <br />
              削除した情報は元に戻せません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                }}
              >
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  updateBArbeitScoreVisibleByWorkIdMutation({
                    variables: {
                      work_id: Number(workId ?? 0),
                    },
                  }).then((e) => {
                    if (e.data?.updateBArbeitScoreVisibleByWorkId.resultRows === 1) {
                      setModalStep(ModalStep.DELETE_COMPLETE);
                    } else {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message: 'コメント削除に失敗しました。',
                      });
                    }
                  });
                }}
              >
                削除
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.BTMEM_COMMENT_DELETE_CONFIRMATION:
        return {
          width: 384,
          height: 212,
          header: (
            <Text variant="h2" color="darkBlue">
              削除
            </Text>
          ),
          content: (
            <Text variant="body14">
              本当にコメントを削除しますか？
              <br />
              削除した情報は元に戻せません。
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                }}
              >
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  updateTScoreVisibleByWorkIdMutation({
                    variables: {
                      work_id: Number(workId ?? 0),
                    },
                  }).then((e) => {
                    if (e.data?.updateTScoreVisibleByWorkId.resultRows === 1) {
                      setModalStep(ModalStep.DELETE_COMPLETE);
                    } else {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message: 'コメント削除に失敗しました。',
                      });
                    }
                  });
                }}
              >
                削除
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.DELETE_COMPLETE:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              削除 完了
            </Text>
          ),
          content: <Text variant="body14">コメントの削除が完了しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  refetch();
                  setModalStep(ModalStep.NULL);
                }}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };

      default:
        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [
    modalStep,
    workId,
    openErrorModal,
    updateTScoreVisibleByWorkIdMutation,
    updateBArbeitScoreVisibleByWorkIdMutation,
    refetch,
  ]);

  return (
    <>
      {(tScoreVisibleByWorkIdLoading ||
        bScoreVisibleByWorkIdLoading ||
        vWorkScoreInfoByWorkIdLoading ||
        vTMemberBaseByIdLoading ||
        vBMemberBaseByIdLoading) && <Loading />}
      <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
        <Text variant="h1" color="darkBlue">
          レビュー管理
        </Text>
        <Card overflow="hidden">
          <Box height="100%" display="flex" flexDirection="column">
            <Box height={60} pa={16} display="flex" alignItems="center" justifyContent="space-between" gap={8}>
              <Box display="flex" alignItems="center" gap={8}>
                <Text variant="h2" color="darkBlue">
                  {workScoreInfo?.offer_no || ''}
                </Text>
                <Text variant="caption12" color="darkBlue">
                  のレビュー
                </Text>
              </Box>
              <MainButton
                icon="eye"
                width={164}
                variant="secondary"
                onClick={() => navigate(`/s04-02/${String(workScoreInfo?.offer_id)}`)}
              >
                募集情報を確認
              </MainButton>
            </Box>
            <Divider option="horizontal" />
            <Box height="100%" pa={24} display="flex" gap={24}>
              <Box width="calc(100% - 50px)">
                <Box mb={24}>
                  <Text variant="caption12" bold color="darkBlue">
                    加盟店のレビュー
                  </Text>
                </Box>
                {workScoreInfo?.tpmem_id !== null && workScoreInfo?.tpmem_id !== undefined ? (
                  <Box display="flex" gap={16}>
                    <Avatar rounded objectFit="cover" src={workScoreInfo?.tpmem_image || ''} />
                    <Box flex="auto" display="flex" flexDirection="column" gap={8}>
                      <Box flex="auto" display="flex" flexDirection="column" gap={4}>
                        <StyledOpenModalLink onClick={() => setIsOpenStoreModal(true)}>
                          <Text color="primaryBlue">{workScoreInfo?.tpmem_name || ''}</Text>
                        </StyledOpenModalLink>
                        <Text variant="caption12">{workScoreInfo?.tp_post_day || ''}</Text>
                        <Stars rate={workScoreInfo?.bt_score || 0} />
                      </Box>
                      <Text>{workScoreInfo?.bt_comment || ''}</Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <ThreeDotLeader
                        right={0}
                        menu={[{ label: 'コメント削除', value: 'deleteComment' }]}
                        onClick={() => {
                          setModalStep(ModalStep.TPMEM_COMMENT_DELETE_CONFIRMATION);
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" gap={16}>
                    <Text color="darkBlue">まだレビューをされていません。</Text>
                  </Box>
                )}
              </Box>
              <Divider option="vertical" />
              <Box width="calc(100% - 50px)">
                <Box mb={24}>
                  <Text variant="caption12" bold color="darkBlue">
                    バイトラベラーのレビュー
                  </Text>
                </Box>
                {workScoreInfo?.btmem_id !== null && workScoreInfo?.btmem_id !== undefined ? (
                  <Box display="flex" gap={16}>
                    <Avatar rounded objectFit="cover" src={workScoreInfo?.btmem_image || ''} />
                    <Box flex="auto" display="flex" flexDirection="column" gap={8}>
                      <Box flex="auto" display="flex" flexDirection="column" gap={4}>
                        <StyledOpenModalLink onClick={() => setIsOpenBitravelerModal(true)}>
                          <Text color="primaryBlue">{workScoreInfo?.btmem_name || ''}</Text>
                        </StyledOpenModalLink>
                        <Text variant="caption12">{workScoreInfo?.bt_post_day || ''}</Text>
                        <Stars rate={workScoreInfo?.tp_score || 0} />
                      </Box>
                      <Text>{workScoreInfo?.tp_comment || ''}</Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <ThreeDotLeader
                        right={0}
                        menu={[{ label: 'コメント削除', value: 'deleteComment' }]}
                        onClick={() => {
                          setModalStep(ModalStep.BTMEM_COMMENT_DELETE_CONFIRMATION);
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" gap={16}>
                    <Text color="darkBlue">まだレビューをされていません。</Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      {tMemberBase !== undefined && (
        <StoreModal
          isOpen={isOpenStoreModal}
          vtMemberBase={tMemberBase}
          onClickClose={() => setIsOpenStoreModal(false)}
        />
      )}
      {bMemberBase !== undefined && (
        <BitravelMemberModal
          isOpen={isOpenBitravelerModal}
          vbMemberBase={bMemberBase}
          onClickClose={() => setIsOpenBitravelerModal(false)}
        />
      )}
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(0)}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
      />
    </>
  );
}
