import * as gql from 'graphql/graphql-ow';
import { TabNumber, SystemStatus } from './const ';

const useRefetchHomeApi = () => {
  const {
    data: { getURecvNotifyByS3K1: { home } } = {
      getURecvNotifyByS3K1: {
        home: {} as gql.VuNewlyHome,
      },
    },
    refetch,
  } = gql.useGetURecvNotifyByS3K1Query({
    variables: {},
  });

  return { home, refetch };
};

const useRefetchListApi = (messageStatus: TabNumber, sysId: SystemStatus) => {
  const [getPaymentRequest, paymentRequest] = gql.useGetURecvNotifyByS3K1LazyQuery({
    variables: {},
  });
  const [getIdentifyVerification, identifyVerification] = gql.useGetURecvNotifyByS3K2LazyQuery({
    variables: {},
  });
  const [getMembershipRegistration, membershipRegistration] = gql.useGetURecvNotifyByS3K3LazyQuery({
    variables: {},
  });
  const [getMembershipCancellation, membershipCancellation] = gql.useGetupdURecvNotifyByS3K4Mutation({ variables: {} });
  const [getOccurrenceOfEvent, occurrenceOfEvent] = gql.useGetURecvNotifyByS3K5LazyQuery({
    variables: {},
  });
  const [getMembershipRegistrationOfMemberStore, membershipRegistrationOfMemberStore] =
    gql.useGetURecvNotifyByS2K3LazyQuery({
      variables: {},
    });
  const [getMembershipCancellationOfMemberStore, membershipCancellationOfMemberStore] =
    gql.useGetURecvNotifyByS2K4LazyQuery({
      variables: {},
    });
  const [getRefundRequest, refundRequest] = gql.useGetURecvNotifyByS2K6LazyQuery({
    variables: {},
  });
  const [getMembershipUpdate, membershipUpdate] = gql.useGetURecvNotifyByS2K7LazyQuery({
    variables: {},
  });

  if (sysId === SystemStatus.BEITRAVEL) {
    switch (true) {
      case messageStatus === TabNumber.PAYMENT_REQUEST:
        return {
          ...paymentRequest,
          query: getPaymentRequest,
          data: paymentRequest.data?.getURecvNotifyByS3K1.list ?? ([] as gql.URecvNotify[]),
        };
      case messageStatus === TabNumber.IDENTIFY_VERIFICATION:
        return {
          ...identifyVerification,
          query: getIdentifyVerification,
          data: identifyVerification.data?.getURecvNotifyByS3K2 ?? ([] as gql.URecvNotify[]),
        };
      case messageStatus === TabNumber.MEMBERSHIP_REGISTRATION:
        return {
          ...membershipRegistration,
          query: getMembershipRegistration,
          data: membershipRegistration.data?.getURecvNotifyByS3K3 ?? ([] as gql.URecvNotify[]),
        };
      case messageStatus === TabNumber.MEMBERSIHP_CANCELLATION:
        return {
          ...membershipCancellation,
          query: getMembershipCancellation,
          data: membershipCancellation.data?.getupdURecvNotifyByS3K4 ?? ([] as gql.URecvNotify[]),
          // MEMO: Mutationにrefetch()はないため代用
          refetch: getMembershipCancellation,
          called: true,
        };
      case messageStatus === TabNumber.OCCURRENCE_OF_EVENT:
        return {
          ...occurrenceOfEvent,
          query: getOccurrenceOfEvent,
          data: occurrenceOfEvent.data?.getURecvNotifyByS3K5 ?? ([] as gql.URecvNotify[]),
        };
      default:
    }
  }

  switch (true) {
    case messageStatus === TabNumber.MEMBERSHIP_REGISTRATION:
      return {
        ...membershipRegistrationOfMemberStore,
        query: getMembershipRegistrationOfMemberStore,
        data: membershipRegistrationOfMemberStore.data?.getURecvNotifyByS2K3.list ?? ([] as gql.URecvNotify[]),
      };
    case messageStatus === TabNumber.MEMBERSIHP_CANCELLATION:
      return {
        ...membershipCancellationOfMemberStore,
        query: getMembershipCancellationOfMemberStore,
        data: membershipCancellationOfMemberStore.data?.getURecvNotifyByS2K4 ?? ([] as gql.URecvNotify[]),
      };
    case messageStatus === TabNumber.REFUND_REQUEST:
      return {
        ...refundRequest,
        query: getRefundRequest,
        data: refundRequest.data?.getURecvNotifyByS2K6 ?? ([] as gql.URecvNotify[]),
      };
    case messageStatus === TabNumber.MEMBERSHIP_UPDATE:
      return {
        ...membershipUpdate,
        query: getMembershipUpdate,
        data: membershipUpdate.data?.getURecvNotifyByS2K7 ?? ([] as gql.URecvNotify[]),
      };
    default:
  }

  return {
    query: () => {},
    data: [] as gql.URecvNotify[],
    error: undefined,
    loading: true,
    refetch: () => {},
    called: false,
  };
};

export { useRefetchHomeApi, useRefetchListApi };
