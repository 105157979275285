import React, { ReactNode } from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { styled } from '@linaria/react';
import { GENERIC_COLOR } from 'components/assets/css/style';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { Modal, ImageModal } from 'components/newModal';
import { SelectForm } from 'components/selectForm';
import { useErrorModal } from 'components/error/errorModalProvider';
import { FetchResult } from '@apollo/client';
import { StickyHeader } from 'components/assets/css/pages/pageStyle';
import { ScrollableArea } from 'components/scrollableArea';

export interface Props {
  applicationDetail: gql.VbIdentificationInfo;
  refetchIdentification: () => Promise<FetchResult<gql.GetupdVbIdentificationInfoByKeyMutation>>;
}

enum ModalStep {
  NULL = 0,
  ID_CONFIRMATION = 1,
  ID_CONFIRMED = 2,
  ID_DENIAL_CONFIRMATION = 3,
  DENIAL_REASON_CONFIRMATION = 4,
  DENIAL_CONFIRMED = 5,
}

const StyledImage = styled.img`
  cursor: pointer;
`;

// 否認理由種別
enum RejectReasonKind {
  IDENTIFICATION = 1, // 本人確認
}

// 証明書用ラベル（オモテ・ウラ）
export interface DocProps {
  children: ReactNode;
  width?: number;
}
const StyledDiv = styled.div<{
  width: number;
  height: number;
  color: string;
  borderRadius: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0;
  background: ${({ color }) => color};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  box-sizing: border-box;
`;

function DocChip({ children, width }: DocProps) {
  return (
    <StyledDiv width={width ?? 52} height={26} color={GENERIC_COLOR.DARK_GRAY} borderRadius={1}>
      <Text variant="caption12" color="white" bold>
        {children}
      </Text>
    </StyledDiv>
  );
}

export function ApplicationDetails({ applicationDetail, refetchIdentification }: Props) {
  const [
    updateBIdentificationApprovalByIdSubmitDate,
    { loading: bIdentificationApprovalLoading, error: bIdentificationApprovalError },
  ] = gql.useUpdateBIdentificationApprovalByIdSubmitDateMutation();
  const [
    updateBIdentificationDenialByIdSubmitDate,
    { loading: bIdentificationDenialLoading, error: bIdentificationDenialError },
  ] = gql.useUpdateBIdentificationDenialByIdSubmitDateMutation();

  const {
    data: mRejectReasonData,
    loading: mRejectReasonLoading,
    error: mRejectReasonError,
  } = gql.useGetMRejectReasonByKindQuery({
    context: { clientName: 'master' },
    variables: {
      kind: RejectReasonKind.IDENTIFICATION, // 1: 本人確認
    },
  });
  const [denialReason, setDenialReason] = React.useState<string>('');
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [isImageModalOpen, setIsImageModalOpen] = React.useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = React.useState<string>('');
  const { openErrorModal } = useErrorModal();

  React.useEffect(() => {
    if (bIdentificationApprovalError || bIdentificationDenialError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [bIdentificationApprovalError, bIdentificationDenialError, openErrorModal]);
  React.useEffect(() => {
    if (mRejectReasonError && modalStep === ModalStep.ID_DENIAL_CONFIRMATION) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message: '拒否理由が取得できませんでした。',
      });
    }
  }, [mRejectReasonError, modalStep, openErrorModal]);

  const displayItems = () => {
    const items: {
      label: string;
      content: ReactNode;
    }[] = [
      { label: '氏名', content: applicationDetail.name },
      { label: '氏名(フリガナ)', content: applicationDetail.kana },
      { label: '生年月日', content: applicationDetail.birthday },
      { label: '年齢', content: applicationDetail.age },
      {
        label: '住所',
        content: `〒${applicationDetail.zip_code ? applicationDetail.zip_code.slice(0, 3) : ''}-${
          applicationDetail.zip_code ? applicationDetail.zip_code.slice(3) : ''
        }
                  ${applicationDetail.state || ''}
                  ${applicationDetail.city || ''}
                  ${applicationDetail.address || ''}
                  ${applicationDetail.address2 || ''}`,
      },
      { label: '国籍', content: applicationDetail.nationality || '-' },
      {
        label: applicationDetail.id_type || '',
        content: (
          <Box display="flex" height={120} gap={24}>
            <Box display="flex" gap={8}>
              <DocChip>オモテ</DocChip>
              <Box>
                <StyledImage
                  height={120}
                  src={applicationDetail.id_doc1 || ''}
                  alt={applicationDetail.id_type || ''}
                  onClick={() => {
                    setModalImageSrc(applicationDetail.id_doc1 || '');
                    setIsImageModalOpen(true);
                  }}
                />
              </Box>
            </Box>
            {applicationDetail.id_doc2 && (
              <Box display="flex" gap={8}>
                <DocChip width={40}>ウラ</DocChip>
                <Box>
                  <StyledImage
                    height={120}
                    src={applicationDetail.id_doc2 || ''}
                    alt={applicationDetail.id_type || ''}
                    onClick={() => {
                      setModalImageSrc(applicationDetail.id_doc2 || '');
                      setIsImageModalOpen(true);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
    ];

    if (applicationDetail.id2_type) {
      items.push({
        label: applicationDetail.id2_type || '',
        content: (
          <Box display="flex" height={120} gap={24}>
            <Box>
              <StyledImage
                height={120}
                src={applicationDetail.id2_doc1 || ''}
                alt={applicationDetail.id2_type || ''}
                onClick={() => {
                  setModalImageSrc(applicationDetail.id2_doc1 || '');
                  setIsImageModalOpen(true);
                }}
              />
            </Box>
            {applicationDetail.id2_doc2 && (
              <Box>
                <StyledImage
                  height={120}
                  src={applicationDetail.id2_doc2 || ''}
                  alt={applicationDetail.id2_type || ''}
                  onClick={() => {
                    setModalImageSrc(applicationDetail.id2_doc2 || '');
                    setIsImageModalOpen(true);
                  }}
                />
              </Box>
            )}
          </Box>
        ),
      });
    }

    if (!applicationDetail.is_japanese) {
      items.push(
        ...[
          { label: '在留資格', content: applicationDetail.residence_name },
          { label: '在留期限', content: applicationDetail.period_date ?? '-' },
        ]
      );
    }

    items.push(
      ...[
        { label: '性別', content: applicationDetail.gender },
        { label: '電話番号', content: applicationDetail.phone },
        { label: 'メールアドレス(ID)', content: applicationDetail.email },
        { label: '保有資格', content: applicationDetail.general_licenses ?? '-' },
        { label: '入会日', content: applicationDetail.created_date },
        { label: '会員番号', content: applicationDetail.btmem_number },
      ]
    );
    return items;
  };
  const displayStudentItems = () => {
    const items: {
      label: string;
      content: ReactNode;
    }[] = [{ label: '学生', content: applicationDetail.student }];

    if (applicationDetail.reg_hs_student) {
      items.push(
        ...[
          { label: '学校名', content: applicationDetail.school },
          { label: '校則でのバイト禁止有無', content: applicationDetail.rule },
          { label: '親権者承諾', content: applicationDetail.parental },
          { label: '親権者名', content: applicationDetail.parental_name },
          { label: '緊急連絡先', content: applicationDetail.ecn },
        ]
      );
    }
    return items;
  };
  const denialReasons = mRejectReasonData?.getMRejectReasonByKind as gql.MRejectReason[];
  const denialOptions = React.useMemo(
    () =>
      denialReasons?.map((item) => ({
        value: String(item.no),
        label: item.reason,
      })) || [],
    [denialReasons]
  );
  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case ModalStep.ID_CONFIRMATION:
        return {
          width: 384,
          height: 210,
          header: (
            <Text variant="h2" color="darkBlue">
              承認
            </Text>
          ),
          content: (
            <>
              <Text variant="body14">このユーザーの本人確認書類を承認しますか？</Text>
              <br />
              <Text variant="body14">この操作は取り消せません。</Text>
            </>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  (async () => {
                    const result = await updateBIdentificationApprovalByIdSubmitDate({
                      variables: {
                        id: applicationDetail.btmem_id,
                        submit_date: applicationDetail?.submit_date || '',
                      },
                    });
                    await refetchIdentification();

                    return result;
                  })()
                    .then((result) => {
                      if (result.data?.updateBIdentificationApprovalByIdSubmitDate.resultRows === 0) {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了できませんでした。画面を更新してもう一度お試しください',
                        });
                      } else if (result.data?.updateBIdentificationApprovalByIdSubmitDate.resultRows === 1) {
                        setModalStep(ModalStep.ID_CONFIRMED);
                        refetchIdentification();
                      } else if (
                        result.data?.updateBIdentificationApprovalByIdSubmitDate?.resultRows &&
                        result.data?.updateBIdentificationApprovalByIdSubmitDate.resultRows >= 2
                      ) {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了しました。変更されたデータが複数あります。',
                        });
                      } else {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了できませんでした',
                        });
                      }
                    })
                    .catch(() => {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message: 'ステータスの変更が完了できませんでした',
                      });
                    });
                }}
              >
                承認
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.ID_CONFIRMED:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              承認 完了
            </Text>
          ),
          content: <Text variant="body14">ユーザーの本人確認書類を承認しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                閉じる
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.ID_DENIAL_CONFIRMATION:
        return {
          width: 384,
          height: 346,
          header: (
            <Text variant="h2" color="darkBlue">
              否認
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" gap={16}>
              <div>
                <Text variant="body14">このユーザーの本人確認書類を否認しますか？ </Text>
                <br />
                <Text variant="body14">ユーザーは再度本人確認書類を提出するステータスに入ります。</Text>
                <br />
                <Text variant="body14">この操作は取り消せません。</Text>
              </div>
              <SelectForm
                label="否認理由"
                value={denialReason}
                placeholder="否認理由を選択"
                options={denialOptions}
                onChange={(value) => setDenialReason(value)}
                width={336}
              />
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                }}
              >
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  setModalStep(ModalStep.DENIAL_REASON_CONFIRMATION);
                }}
                disabled={!denialReason}
              >
                確認
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.DENIAL_REASON_CONFIRMATION:
        return {
          width: 384,
          height: 263,
          header: (
            <Text variant="h2" color="darkBlue">
              否認 確認
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" gap={16}>
              <Text variant="body14">以下の内容で否認します。</Text>
              <Text variant="body14">{denialOptions.find(({ value }) => value === denialReason)?.label || ''}</Text>
              <Text variant="body14">よろしいですか？</Text>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                width={104}
                variant="secondary"
                onClick={() => {
                  setModalStep(ModalStep.NULL);
                }}
              >
                キャンセル
              </MainButton>
              <MainButton
                width={104}
                onClick={() => {
                  (async () => {
                    const result = await updateBIdentificationDenialByIdSubmitDate({
                      variables: {
                        id: applicationDetail.btmem_id,
                        reason: denialOptions.find(({ value }) => value === denialReason)?.label || '',
                        submit_date: applicationDetail?.submit_date || '',
                      },
                    });
                    await refetchIdentification();

                    return result;
                  })()
                    .then((result) => {
                      if (result.data?.updateBIdentificationDenialByIdSubmitDate.resultRows === 0) {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了できませんでした。画面を更新してもう一度お試しください',
                        });
                      } else if (result.data?.updateBIdentificationDenialByIdSubmitDate.resultRows === 1) {
                        setModalStep(ModalStep.DENIAL_CONFIRMED);
                        refetchIdentification();
                      } else if (
                        result.data?.updateBIdentificationDenialByIdSubmitDate?.resultRows &&
                        result.data?.updateBIdentificationDenialByIdSubmitDate.resultRows >= 2
                      ) {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了しました。変更されたデータが複数あります。',
                        });
                      } else {
                        setModalStep(ModalStep.NULL);
                        openErrorModal({
                          message: 'ステータスの変更が完了できませんでした',
                        });
                      }
                    })
                    .catch(() => {
                      setModalStep(ModalStep.NULL);
                      openErrorModal({
                        message: 'ステータスの変更が完了できませんでした',
                      });
                    });
                }}
              >
                否認
              </MainButton>
            </Box>
          ),
        };
      case ModalStep.DENIAL_CONFIRMED:
        return {
          width: 384,
          height: 189,
          header: (
            <Text variant="h2" color="darkBlue">
              否認 完了
            </Text>
          ),
          content: <Text variant="body14">ユーザーの本人確認書類を否認しました。</Text>,
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton width={104} variant="secondary" onClick={() => setModalStep(ModalStep.NULL)}>
                閉じる
              </MainButton>
            </Box>
          ),
        };

      default:
        setDenialReason('');

        return {
          width: 384,
          height: 210,
          header: null,
          content: null,
          footer: null,
        };
    }
  }, [
    modalStep,
    denialReason,
    denialOptions,
    updateBIdentificationApprovalByIdSubmitDate,
    applicationDetail.btmem_id,
    applicationDetail?.submit_date,
    refetchIdentification,
    openErrorModal,
    updateBIdentificationDenialByIdSubmitDate,
  ]);

  return (
    <ScrollableArea>
      {(bIdentificationApprovalLoading || bIdentificationDenialLoading || mRejectReasonLoading) && <Loading />}
      <Box px={24} pb={16} width="100%">
        <StickyHeader height={60}>
          <Box flex="auto">
            <Text variant="h2" color="darkBlue">
              申請内容
            </Text>
          </Box>
          {applicationDetail.identification !== 2 &&
            applicationDetail.identification !== 3 &&
            applicationDetail.identification !== undefined && (
              <>
                <MainButton variant="warnSecondary" onClick={() => setModalStep(ModalStep.ID_DENIAL_CONFIRMATION)}>
                  否認
                </MainButton>
                <MainButton
                  variant="primary"
                  icon="adminPanelSettings"
                  onClick={() => setModalStep(ModalStep.ID_CONFIRMATION)}
                >
                  承認
                </MainButton>
              </>
            )}
        </StickyHeader>

        <Box>
          <Info items={displayItems()} />
        </Box>
        {applicationDetail.reg_minor && (
          <>
            <Box
              backgroundColor="blueGray"
              px={8}
              py={4}
              display="flex"
              alignItems="center"
              borderRadius={4}
              height={29}
            >
              <Text variant="body14" color="white" bold>
                16歳以上18歳以下特記事項
              </Text>
            </Box>
            <Box>
              <Info items={displayStudentItems()} />
            </Box>
          </>
        )}
      </Box>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => setModalStep(ModalStep.NULL)}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow="visible"
      />
      <ImageModal src={modalImageSrc} isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)} />
    </ScrollableArea>
  );
}
