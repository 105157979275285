import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Modal } from 'components/newModal';
import { Text } from 'components/text';
import React, { useState } from 'react';

enum ModalStep {
  Delete = 1,
  Complete = 2,
}

export function TicketDeleteModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  refetch: () => void;
}) {
  const { isOpen, onClose, onDelete, refetch } = props;

  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Delete);

  const onModalClose = () => {
    setModalStep(ModalStep.Delete);
    onClose();
  };

  const deleteModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        チケット削除
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" flex="1">
          <Text variant="body14">このチケットを削除しますか？</Text>
          <Text variant="body14">削除した情報は元に戻せません。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          キャンセル
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            setModalStep(ModalStep.Complete);
            onDelete();
            refetch();
          }}
        >
          削除
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 210,
  };

  const completeModal = {
    header: (
      <Text variant="h2" color="darkBlue">
        チケット削除 完了
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pa={8}>
          <Text variant="body14">情報の削除が完了しました。</Text>
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onModalClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189,
  };

  const modalContent = modalStep === ModalStep.Delete ? deleteModal : completeModal;

  return <Modal isOpen={isOpen} onClose={() => onClose()} {...modalContent} />;
}
