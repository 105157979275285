import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { STpmemType } from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import * as gql from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Loading } from 'components/loading/loading';
import { Modal } from 'components/newModal';
import { ModalZindex } from 'components/const';
import { CHIP_TEXT, InputType, ModalStep, ListTitleWidth } from '../constants';
import { chipColor, leaderMenu } from '../s10-02-utils';
import { BasicModal } from '../modal/basic-modal';
import { MiniModalViewDataTyep } from '../s10-02-types';

/**
 * Figma ID: 10-02-06-01
 * 名称: 募集可能件数
 */

export interface Props {
  cpRow: STpmemType[];
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
}

const makeListData = ({ cpRow, setModalStep }: Props) =>
  cpRow.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              件数
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={100} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row.recruit_num}件
            </Text>
          </CampaignTableItem>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={160} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setModalStep(1)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

export function Recruitable() {
  const { openErrorModal } = useErrorModal();
  const [modalViewData, setModalViewData] = useState<MiniModalViewDataTyep>();
  const { data: getData, loading, error, refetch } = gql.useGetSTpmemTypeByTypeId1Query();

  const [update, { error: recruitNumError, loading: recruitNumLoading }] =
    gql.useUpdateSTpmemTypeRecruitNumByTypeIdMutation();

  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  useEffect(() => {
    if (getData) {
      setModalViewData({
        title: {
          input: '募集可能件数',
          check: '募集可能件数',
          complete: '募集可能件数 完了',
        },
        label: '件数',
        name: 'recruitCount',
        placeholder: '件数',
        inputType: InputType.Input,
        unit: '件',
        data: getData?.getSTpmemTypeByTypeId1.recruit_num,
      });
    }
  }, [getData]);

  const handleModalClose = useCallback(() => {
    refetch().then((e) => {
      if (!e.loading) {
        setModalStep(ModalStep.NULL);
      }
    });
  }, [refetch]);

  const listItems = useMemo(
    () =>
      makeListData({ cpRow: getData?.getSTpmemTypeByTypeId1 ? [getData?.getSTpmemTypeByTypeId1] : [], setModalStep }),
    [getData]
  );
  const { displayModal } = BasicModal({
    modalStep,
    setModalStep,
    onClose: handleModalClose,
    id: getData?.getSTpmemTypeByTypeId1.type_id || 0,
    modalViewData,
    mutation: (type_id?: number, data?: string | number | null) => {
      update({ variables: { type_id: type_id as number, recruit_num: data as number } })
        .then((e) => {
          if (!e.errors && !recruitNumError) {
            setModalStep(3);
            refetch();
          } else {
            openErrorModal({
              message: '募集可能件数の更新に失敗しました。\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    },
    rules: {
      required: '件数を入力してください。',
      max: {
        value: 999,
        message: '999以下の数値で入力してください。',
      },
      pattern: {
        value: /^\d*$/,
        message: '数値で入力してください。',
      },
    },
  });

  if (error) {
    openErrorModal({
      message:
        'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      zIndex: ModalZindex.ERRMODALZINDEX,
    });
    refetch();
  }

  if (loading || recruitNumLoading) {
    return Loading();
  }
  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                募集可能件数
              </Text>
              <Text color="darkBlue" variant="caption12">
                加盟店会員全体の募集可能件数を自由に設定できます
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <ScrollWrapper bottom={311}>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollWrapper>
        </Box>
      </HideWrapper>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== 0}
        onClose={() => displayModal.onClose}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
      />
    </>
  );
}
