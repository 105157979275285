/**
 * Figma ID: 10-01-02-04
 * 名称: ユーザー招待 新規登録
 */
import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React from 'react';

type RegisterInformationSubmittedModel = {
  onClickNext: () => void;
};
export default function RegisterInformationSubmitted(props: RegisterInformationSubmittedModel) {
  const { onClickNext } = props;
  return (
    <Box width="100%">
      <Box>
        <Text variant="body14">登録情報の送信が完了しました。</Text>
      </Box>
      <Box>
        <Text variant="body14">受信したメールから新規登録を行なってください。</Text>
      </Box>
      <Box justifyContent="center" display="grid" pt={24}>
        <MainButton onClick={onClickNext}>次へ</MainButton>
      </Box>
    </Box>
  );
}
