/**
 * Figma ID: 07-04-01
 * 名称: 逆オファー（募集履歴）
 */
import { HideWrapper, InnerBox, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { HistoryList } from 'components/historyList';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Text } from 'components/text';
import * as gql from 'graphql/graphql-ow';
import React from 'react';

export interface RecruitmentHistoryModel {
  dateTime: string;
  text: string;
  uniqueKey: string | number;
}
type RecruitmentHistoryTabModel = {
  selectTab: () => void;
  recruitmentHistoryData: gql.GetVbOfferListLoggingByOfferIdQuery | undefined;
};
function RecruitmentHistory(props: RecruitmentHistoryTabModel) {
  const { selectTab, recruitmentHistoryData } = props;
  const [limit, setLimit] = React.useState<10 | 20 | 30>(10);
  const [page, setPage] = React.useState<number>(1);
  const [displayHistoryData, setDisplayHistoryData] = React.useState<RecruitmentHistoryModel[]>([]);
  React.useEffect(() => selectTab(), [selectTab]);
  React.useEffect(() => {
    const data: RecruitmentHistoryModel[] = [];
    recruitmentHistoryData?.getVBOfferListLoggingByOfferId?.logging?.map((item) =>
      data.push({
        dateTime: item?.log_date ?? '',
        text: item?.matter ?? '',
        uniqueKey: item?.id ?? '',
      })
    );
    setDisplayHistoryData(data);
  }, [recruitmentHistoryData?.getVBOfferListLoggingByOfferId?.logging]);
  const handleLimitChange = (value: 10 | 20 | 30) => {
    setPage(1);
    setLimit(value);
  };
  const handlePageChange = (value: number) => {
    setPage(value);
  };
  return (
    <HideWrapper>
      <InnerBox padding="24px">
        <Box pb={18}>
          <Text color="darkBlue" variant="h2">
            募集履歴
          </Text>
        </Box>
        <Divider option="horizontal" />
        <PaginationBox
          dataSize={recruitmentHistoryData?.getVBOfferListLoggingByOfferId?.logging?.length ?? 0}
          limit={limit}
          page={page}
          onChangeLimit={handleLimitChange}
          onChangePage={handlePageChange}
        >
          <ScrollWrapper bottom={350}>
            <HistoryList items={displayHistoryData.slice(limit * (page - 1), limit * page)} />
          </ScrollWrapper>
        </PaginationBox>
      </InnerBox>
    </HideWrapper>
  );
}

export default RecruitmentHistory;
