/**
 * Figma ID: 09-02-01, 09-02-02, 09-02-03
 * 新規作成画面
 */
import * as gql from 'graphql/graphql-ow';
import {
  Control,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormTrigger,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { useErrorModal } from 'components/error/errorModalProvider';
import { CreateNotifyInfoModalForm, ModalRet } from '../../../type';
import { NotifyDeliveryModal, DeliveryModalType } from '../notifyDeliveryModal';
import { getSendNotifyMutationParam } from '../../../util';

export function CreateModal(
  isOpen: boolean,
  control: Control<CreateNotifyInfoModalForm>,
  handleSubmit: UseFormHandleSubmit<CreateNotifyInfoModalForm>,
  trigger: UseFormTrigger<CreateNotifyInfoModalForm>,
  clearErrors: UseFormClearErrors<CreateNotifyInfoModalForm>,
  getValues: UseFormGetValues<CreateNotifyInfoModalForm>,
  setValue: UseFormSetValue<CreateNotifyInfoModalForm>,
  onClose: () => void,
  errors: FieldErrors<CreateNotifyInfoModalForm>,
  toRoughDraft: () => void,
  toPublished: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();
  const [addUSendNotifyDraftMutation, { loading: uSendNotifyDraftLoading }] = gql.useAddUSendNotifyDraftMutation();

  // 下書き保存処理
  const onSaveDraft = () => {
    // 配信先からMutationのパラメータを取得する
    const mutationParam = getSendNotifyMutationParam(
      getValues('deliveryDest'),
      getValues('business'),
      getValues('brand')
    );

    // mutationパラメータ ※必須項目
    const mutationVariables: gql.AddUSendNotifyDraftMutationVariables = {
      title: getValues('title') ?? '',
    };

    // 任意項目設定
    const body = getValues('body');
    if (body) mutationVariables.body = body;
    if (mutationParam.bizId) mutationVariables.biz_id = Number(mutationParam.bizId);
    if (mutationParam.brandId) mutationVariables.brand_id = Number(mutationParam.brandId);
    if (mutationParam.sysId) mutationVariables.sys_id = Number(mutationParam.sysId);

    addUSendNotifyDraftMutation({
      variables: mutationVariables,
    })
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          onClose();
          openErrorModal({
            message: '下書きの保存に失敗しました。',
          });
        } else {
          toRoughDraft();
        }
      })
      .catch((e) => {
        // 通信自体ができない場合
        if (e instanceof Error) {
          onClose();
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
          });
        }
      });
  };

  // 通知配信モーダル
  const modalContent = NotifyDeliveryModal(
    isOpen,
    DeliveryModalType.New,
    control,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    setValue,
    onClose,
    errors,
    toPublished,
    onSaveDraft,
    uSendNotifyDraftLoading || false
  );

  return modalContent;
}
