/**
 * Figma ID: 10-08-02-06, 10-08-02-07
 * 名称: ギフト選択肢(削除)
 */
import React from 'react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MainButton } from 'components/mainButton';

export function DeleteHeader() {
  return (
    <Box pl={5}>
      <Text color="darkBlue" variant="h2">
        削除
      </Text>
    </Box>
  );
}

type DeleteContentModel = {
  isDeletable?: boolean;
};
export function DeleteContent(props: DeleteContentModel) {
  const { isDeletable } = props;
  return (
    <Box>
      <Box>
        <Text variant="body14">本当に情報を削除しますか？</Text>
      </Box>
      <Box>
        <Text variant="body14">削除した情報は元に戻せません。</Text>
      </Box>
      {!isDeletable && (
        <Box pt={16}>
          <Box>
            <Text variant="body14" color="cautionRed">
              削除できません。
            </Text>
          </Box>
          <Box>
            <Text variant="body14" color="cautionRed">
              実施前、実施中および特典に利用されていないキャンペーンが削除できます。
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}

type DeleteFooterModel = {
  onClickClose: () => void;
  isDeletable?: boolean;
  onClickDelete: () => void;
};
export function DeleteFooter(props: DeleteFooterModel) {
  const { onClickClose, isDeletable, onClickDelete } = props;
  return (
    <Box display="flex" justifyContent="end" alignItems="end" gap={8}>
      <MainButton onClick={onClickClose} width={104} variant="secondary">
        キャンセル
      </MainButton>
      <MainButton onClick={onClickDelete} width={104} disabled={!isDeletable}>
        削除
      </MainButton>
    </Box>
  );
}
