import React from 'react';
import { Chip } from 'components/chip';
import { AgreementStatus } from 'components/const';

type Prop = {
  status: number;
  variant?: 'normal' | 'big',
  width: number
};

export function StatusChip({status, variant='normal', width} : Prop) {
  switch (status) {
    case AgreementStatus.BEFORE: return <Chip color="blue" variant={variant} width={width}>バイト前</Chip>;
    case AgreementStatus.STAND_BY: return <Chip color="okBlue" variant={variant} width={width}>スタンバイ</Chip>;
    case AgreementStatus.IN_PROGRESS : return <Chip color="darkBlue" variant={variant} width={width}>バイト中</Chip>;
    case AgreementStatus.NO_CHECK_IN: return <Chip color="cautionRed" variant={variant} width={width}>チェックインなし</Chip>;
    case AgreementStatus.NO_CHECK_OUT: return <Chip color="cautionRed" variant={variant} width={width}>チェックアウトなし</Chip>;
    case AgreementStatus.NO_CHECK_IN_OUT: return <Chip color="cautionRed" variant={variant} width={width}>イン/アウトなし</Chip>;
    case AgreementStatus.WAITING_COMPLETE_QR_CHECKOUT_FINISHED:
    case AgreementStatus.WAITING_COMPLETE_QR_NO_CHECKOUT:
      return <Chip color="green" variant={variant} width={width}>完了待ち</Chip>;
    case AgreementStatus.REQUEST_CORRECTION_IN_PROGRESS:
    case AgreementStatus.REQUEST_CORRECTION_REJECTED:
      return <Chip color="warningYellow" variant={variant} width={width}>修正依頼</Chip>;
    case AgreementStatus.COMPLETE: return <Chip color="liteGray" variant={variant} width={width}>完了</Chip>;
    case AgreementStatus.CANCEL: return <Chip color="liteGray" variant={variant} width={width}>キャンセル</Chip>;
    case AgreementStatus.NO_SHOW: return <Chip color="liteGray" variant={variant} width={width}>バックレ</Chip>;
    default: return null;
  }
};
