import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { RhfRadioGroup } from 'components/rhfRadioGroup/rhfRadioGroup';
import { RhfInput } from 'components/rhfInput';
import { RhfSelect } from 'components/rhfSelect';
import { Hours, Minutes } from 'components/select/selectOptions';
import { Text } from 'components/text';
import { BaseInfoEditKey, ModalRet } from 'pages/s03/type';
import { Control, UseFormGetValues, useWatch, UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Checkbox } from 'components/newCheckbox';
import { ErrorMessage } from 'components/errorMessage';
import { DatePicker } from 'components/datePicker';
import _ from 'lodash';
import { getYear } from 'date-fns';

const ConfirmText = styled.div`
  font-family: 'Meiryo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #484848;
`;

type CustomListItemsType = {
  label: string;
  content: React.ReactNode;
  optional?: boolean;
}[];

function CustomList(props: { items: CustomListItemsType }) {
  const { items } = props;

  const listItems = items.map(({ label, content, optional = false }) => (
    <Box display="flex" flexDirection="column" gap={5.5}>
      <InputLabel optional={optional}>{label}</InputLabel>
      {typeof content === 'string' ? <ConfirmText>{content}</ConfirmText> : <div>{content}</div>}
    </Box>
  ));

  return (
    <Box display="flex" flexDirection="column" rowGap={19.5}>
      {listItems}
    </Box>
  );
}

export function EditStoreInfoModal(
  control: Control<BaseInfoEditKey, any>,
  getValues: UseFormGetValues<BaseInfoEditKey>,
  setValue: (name: keyof BaseInfoEditKey, value: any) => void,
  handleSubmit: UseFormHandleSubmit<BaseInfoEditKey>,
  errors: FieldErrors<BaseInfoEditKey>,
  onClose: () => void,
  onNext: () => void,
  setSelectedFile: (file: File | null) => void
): ModalRet {
  const [checkboxState, setCheckboxState] = React.useState(true);
  const currentOpenTime = useWatch({ control, name: 'open_time' });
  const currentCloseTime = useWatch({ control, name: 'close_time' });

  const initialManagerBirthYear = getValues('mgr_dob_year');
  const initialManagerBirthMonth = getValues('mgr_dob_month');
  const initialManagerBirthDay = getValues('mgr_dob_day');

  const initialManagerDateOfBirth = new Date(
    typeof initialManagerBirthYear === 'number' ? initialManagerBirthYear : 0,
    typeof initialManagerBirthMonth === 'number' ? initialManagerBirthMonth - 1 : 0, // 月は0から始まるため、-1する
    typeof initialManagerBirthDay === 'number' ? initialManagerBirthDay : 0
  );

  const currentManagerDateOfBirth = useWatch({
    control,
    name: 'mgr_birthday',
    defaultValue: initialManagerDateOfBirth.toDateString(),
  });

  useEffect(() => {
    if (checkboxState) {
      setValue('beforeHH', '0');
      setValue('beforeMM', '00');
      setValue('afterHH', '0');
      setValue('afterMM', '00');
    }
  }, [checkboxState, setValue]);

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const handleClickBulkRegister = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        setSelectedFile(file);
        setValue('image', result as string);
      };
      reader.readAsDataURL(file);
    }
    return () => {
      setValue('image', getValues('image') || '');
    };
  };

  const onClickHandler = handleSubmit((data) => {
    if (data) {
      // 開始時間の時と分を結合してopen_timeにセット
      if (getValues('beforeHH') === undefined || getValues('beforeMM') === undefined) {
        setValue('open_time', '00:00');
      } else {
        setValue('open_time', `${getValues('beforeHH')}:${getValues('beforeMM')}`);
      }
      // 終了時間の時と分を結合してclose_timeにセット
      if (getValues('afterHH') === undefined || getValues('afterMM') === undefined) {
        setValue('close_time', '00:00');
      } else {
        setValue('close_time', `${getValues('afterHH')}:${getValues('afterMM')}`);
      }
      onNext();
    }
  });
  useEffect(() => {
    const wkOpenTime = (currentOpenTime || '').split(':');
    setValue('beforeHH', wkOpenTime[0]);
    setValue('beforeMM', wkOpenTime[1]);
    const wkCloseTime = (currentCloseTime || '').split(':');
    setValue('afterHH', wkCloseTime[0]);
    setValue('afterMM', wkCloseTime[1]);
  }, [currentOpenTime, currentCloseTime, setValue]);

  const customListItems: CustomListItemsType = [
    { label: '店舗名', content: getValues('store_name') },
    {
      label: '店舗画像',
      content: (
        <Box display="flex" flexDirection="column" gap={8}>
          <img src={getValues('image') || ''} alt="" style={{ width: 160, height: 120 }} />
          <MainButton variant="secondary" width={104} onClick={() => handleClickBulkRegister()}>
            画像を選択
          </MainButton>
        </Box>
      ),
    },
    {
      label: '郵便番号',
      content: `${getValues('zip_code')?.slice(0, 3) ?? ''}-${getValues('zip_code')?.slice(3, 7) ?? ''}`,
    },
    {
      label: '住所',
      content: `${getValues('state') ?? ''}${getValues('city') ?? ''}
        ${getValues('address') ?? ''}${getValues('address2') ?? ''}`,
    },
    { label: '電話番号', content: getValues('phone') },
    {
      label: '営業時間',
      content: (
        <Box display="flex" flexDirection="row" gap={13} alignItems="center">
          <Box display="flex" flexDirection="row" gap={8} alignItems="center">
            <div>
              <RhfSelect
                width={80}
                control={control}
                name="beforeHH"
                options={Hours}
                defaultValue={getValues('beforeHH') ?? '00'}
                placeholder="00"
                disabled={checkboxState}
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.beforeHH && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.beforeHH.message}</ErrorMessage>
                </Box>
              )}
            </div>
            <ConfirmText>:</ConfirmText>
            <div>
              <RhfSelect
                width={80}
                control={control}
                name="beforeMM"
                options={Minutes}
                defaultValue={getValues('beforeMM') ?? '00'}
                placeholder="00"
                disabled={checkboxState}
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.beforeMM && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.beforeMM.message}</ErrorMessage>
                </Box>
              )}
            </div>
            <ConfirmText>~</ConfirmText>
            <div>
              <RhfSelect
                width={80}
                control={control}
                name="afterHH"
                options={Hours}
                defaultValue={getValues('afterHH') ?? '00'}
                placeholder="00"
                disabled={checkboxState}
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.afterHH && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.afterHH.message}</ErrorMessage>
                </Box>
              )}
            </div>
            <ConfirmText>:</ConfirmText>
            <div>
              <RhfSelect
                width={80}
                control={control}
                name="afterMM"
                options={Minutes}
                defaultValue={getValues('afterMM') ?? '00'}
                placeholder="00"
                disabled={checkboxState}
                rules={{ required: '必須項目を入力' }}
              />
              {errors?.afterMM && (
                <Box pt={4}>
                  <ErrorMessage>{errors?.afterMM.message}</ErrorMessage>
                </Box>
              )}
            </div>
          </Box>
          <Checkbox onChange={(e) => setCheckboxState(e.target.checked)} checked={checkboxState}>
            24時間営業
          </Checkbox>
        </Box>
      ),
    },
    {
      label: '駐車場',
      content: (
        <RhfRadioGroup
          name="parking"
          options={[
            { label: '有', value: '有' },
            { label: '無', value: '無' },
          ]}
          control={control}
          defaultValue={`${getValues('parking') ?? '有'}`}
        />
      ),
    },
    {
      label: '責任者氏名',
      content: (
        <Box display="flex" flexDirection="row" gap={16}>
          <div>
            <RhfInput
              name="mgr_last_name"
              control={control}
              width={120}
              placeholder="名字"
              defaultValue={`${getValues('mgr_last_name') ?? ''}`}
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mgr_last_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mgr_last_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mgr_first_name"
              control={control}
              width={120}
              placeholder="名前"
              defaultValue={`${getValues('mgr_first_name') ?? ''}`}
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mgr_first_name && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mgr_first_name.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '責任者氏名(フリガナ)',
      content: (
        <Box display="flex" flexDirection="row" gap={16}>
          <div>
            <RhfInput
              name="mgr_last_kana"
              control={control}
              width={120}
              placeholder="名字(カナ)"
              defaultValue={`${getValues('mgr_last_kana') ?? ''}`}
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mgr_last_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mgr_last_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
          <div>
            <RhfInput
              name="mgr_first_kana"
              control={control}
              width={120}
              placeholder="名前(カナ)"
              defaultValue={`${getValues('mgr_first_kana') ?? ''}`}
              rules={{ required: '必須項目を入力' }}
            />
            {errors?.mgr_first_kana && (
              <Box pt={4}>
                <ErrorMessage>{errors?.mgr_first_kana.message}</ErrorMessage>
              </Box>
            )}
          </div>
        </Box>
      ),
    },
    {
      label: '責任者生年月日',
      content: (
        <Box display="flex" flexDirection="row" gap={8} alignItems="center">
          <DatePicker
            onChange={(value) => {
              if (value) {
                setValue('mgr_birthday', value.toDateString());
                setValue('mgr_dob_year', value.getFullYear());
                setValue('mgr_dob_month', value.getMonth() + 1); // 月は0から始まるため+1する
                setValue('mgr_dob_day', value.getDate());
              }
            }}
            placeholderText="責任者生年月日"
            selected={
              currentManagerDateOfBirth
                ? // APIからはmgr_birthdayがYYYY年MM月DD日の形式で返ってくる。
                  // フォーム内ではフォーマットしているが、resetされたときにYYYY年MM月DD日に戻ってしまい、フォーマットエラーを起こす。
                  // 回避のためここで「年」「月」「日」を削除してフォーマットしている。
                  new Date(currentManagerDateOfBirth.replace(/年|月/g, '-').replace('日', ''))
                : undefined
            }
            width={168}
            yearRange={_.range(getYear(new Date()) - 80, getYear(new Date()) - 20, 1)}
          />
          <RhfInput
            name="mgr_birthday"
            type="hidden"
            control={control}
            defaultValue={`${getValues('mgr_birthday') ?? ''}`}
            rules={{ required: '責任者生年月日を入力してください' }}
          />
        </Box>
      ),
    },
    {
      label: '責任者電話番号',
      content: (
        <Box>
          <RhfInput
            name="mgr_phone"
            control={control}
            width={328}
            placeholder="電話番号"
            defaultValue={`${getValues('mgr_phone') ?? ''}`}
            rules={{ pattern: { value: /^\d*$/g, message: '数字のみ入力してください' } }}
          />
          {errors?.mco_phone && (
            <Box pt={4}>
              <ErrorMessage>{errors?.mco_phone.message}</ErrorMessage>
            </Box>
          )}
        </Box>
      ),
      optional: true,
    },
    {
      label: '銀行口座',
      content: `${getValues('bank') || ''} ${getValues('branch_name') || ''} ${getValues('account_type') || ''} ${
        getValues('account_no') || ''
      } ${getValues('account_name') || ''}`,
    },
  ];
  // 業種 省略表示文字数
  const bizNameMax = 20;
  const bizName = getValues('biz_name') ?? '';
  const editBizName = bizName.length > bizNameMax ? `${bizName.slice(0, bizNameMax)}…` : bizName;
  // ブランド 省略表示文字数
  const brandNameMax = 20;
  const brandName = getValues('brand_name') ?? '';
  const editBrandName = brandName.length > brandNameMax ? `${brandName.slice(0, brandNameMax)}…` : brandName;

  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        基本情報編集 - 店舗情報
      </Text>
    ),
    content: (
      <Box display="flex" height="auto" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} width="100%">
          <Text variant="body14">店舗情報を修正、入力して完了を押してください。</Text>
          <Box display="flex" flexDirection="row" gap={32}>
            <Box display="flex" flexDirection="column" gap={5.5} width={328}>
              <InputLabel>業種</InputLabel>
              <ConfirmText>{editBizName}</ConfirmText>
            </Box>
            <Box display="flex" flexDirection="column" gap={5.5} width={328}>
              <InputLabel>ブランド</InputLabel>
              <ConfirmText>{editBrandName}</ConfirmText>
            </Box>
          </Box>
          <CustomList items={customListItems} />
        </Box>
      </Box>
    ),
    footer: (
      <>
        <Box display="flex" justifyContent="flex-end" columnGap={8}>
          <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
            キャンセル
          </MainButton>
          <MainButton
            width={104}
            thin
            onClick={() => {
              onClickHandler();
            }}
          >
            次へ
          </MainButton>
        </Box>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          accept="image/jpeg, image/png"
        />
      </>
    ),
    width: 800,
    height: 558,
  };

  return {
    ...modalContent,
  };
}
