import React, { ReactNode, useMemo } from 'react';
import { styled } from '@linaria/react';
import { Icon } from '../icon';
import { Text } from '../text';

export interface Props {
  children?: ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledLabel = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const StyledCheckWrapper = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
`;

const StyledInvisibleInput = styled.input<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export function Checkbox({ children, checked, disabled = false, onChange }: Props) {
  const displayIcon = useMemo(() => {
    if (disabled) {
      return <Icon name="checkboxDisabled" />;
    }
    if (checked) {
      return <Icon name="checkboxChecked" />;
    }
    return <Icon name="checkboxUnchecked" />;
  }, [checked, disabled]);

  return (
    <StyledLabel disabled={disabled}>
      <StyledCheckWrapper>
        <StyledInvisibleInput type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        {displayIcon}
      </StyledCheckWrapper>
      {typeof children === 'string' ? <Text variant="caption12">{children}</Text> : children}
    </StyledLabel>
  );
}
