import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { Rating } from 'components/rating';
import { ScoreToast } from 'components/rating/scoreToast';
import { Text } from 'components/text';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ScrollableArea } from 'components/scrollableArea';
import * as gql from 'graphql/graphql-ow';

import { usePagenation } from 'components/utils';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { Select } from 'components/select';
import { ThreeDotLeader } from 'components/threeDotLeader';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'components/newModal';
import { Icon } from 'components/icon';
import { TpmemDetailHeader } from 'modal/tpmemDetail/TpmemDetailHeader';
import { TpmemDetailContent } from 'modal/tpmemDetail/TpmemDetailContent';
import { TpmemDetailFooter } from 'modal/tpmemDetail/TpmemDetailFooter';
import { TextLink } from 'components/textLink';
import { Avatar } from 'components/avatar';
import { CommentDeleteModal } from './modal/commentDeleteModal';
import { MenuOption, ModalStep, StarsOptionName } from '../../constants';

/**
 * Figma ID: 05-04-01
 * 名称: レビュー
 */

function IconStars({ num }: { num: number }) {
  return (
    <Box display="flex" justifyContent="center" width={128}>
      {[...(Array(num) as number[])].map((_) => (
        <Icon name="star" size={24} />
      ))}
    </Box>
  );
}

function ListRow({
  image,
  post_date,
  score,
  comment,
  tpmemName,
  tpmemId,
  setIsTpmemDetailShow,
  setModalData,
}: {
  image: string;
  post_date: string;
  score: number;
  comment: string;
  tpmemName: string;
  tpmemId: number;
  setIsTpmemDetailShow: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<
    React.SetStateAction<
      | {
          tpmemName: string;
          tpmemId: number;
        }
      | undefined
    >
  >;
}) {
  return (
    <Box display="flex" flexDirection="row" height={100} width="100%" my={8} gap={16}>
      {image ? <Avatar rounded src={image} objectFit="cover" /> : <Box width={32}>&nbsp;</Box>}
      <Box display="flex" flex="1" flexDirection="column" gap={4}>
        {tpmemId ? (
          <TextLink
            lineClamp={1}
            onClick={() => {
              setIsTpmemDetailShow(true);
              setModalData({ tpmemId, tpmemName });
            }}
          >
            {tpmemName}
          </TextLink>
        ) : (
          <Text color="primaryBlue" variant="caption12">
            {tpmemName}
          </Text>
        )}
        <Box ml={6}>
          <Text variant="caption12">{post_date}</Text>
        </Box>
        <Box ml={6}>
          <Rating value={score} size="small" precision={0.5} readOnly />
        </Box>
        <Box ml={6}>
          <Text variant="body14">{comment}</Text>
        </Box>
      </Box>
    </Box>
  );
}

export const ScoreOption = [
  { label: StarsOptionName.AllStars, value: StarsOptionName.AllStars, icon: <IconStars num={0} /> },
  { label: StarsOptionName.FiveStars, value: StarsOptionName.FiveStars, icon: <IconStars num={5} /> },
  { label: StarsOptionName.FourStars, value: StarsOptionName.FourStars, icon: <IconStars num={4} /> },
  { label: StarsOptionName.ThreeStars, value: StarsOptionName.ThreeStars, icon: <IconStars num={3} /> },
  { label: StarsOptionName.TwoStars, value: StarsOptionName.TwoStars, icon: <IconStars num={2} /> },
  { label: StarsOptionName.OneStar, value: StarsOptionName.OneStar, icon: <IconStars num={1} /> },
];

export function Review({ id }: { id: string }) {
  const {
    data: reviewListData,
    loading: reviewListLoading,
    error: reviewListError,
    refetch: reviewListRefetch,
  } = gql.useGetVbReviewListScoreByBtmemIdQuery({
    variables: { btmem_id: Number(id) },
  });
  const {
    data: scorePercentInfoData,
    loading: scorePercentInfoLoading,
    error: scorePercentInfoError,
    refetch: scorePercentInfoRefetch,
  } = gql.useGetVbScorePercentInfoByBtmemIdQuery({ variables: { btmem_id: Number(id) } });

  const [commentDelete, { loading: commentDeleteLoading, error: commentDeleteError }] =
    gql.useUpdateBArbeitScoreVisibleByWorkIdMutation();
  const { limit, page, handleChangeLimit, handleChangePage } = usePagenation();
  const [dataSize, setDataSize] = useState<number>(0);
  const { openErrorModal } = useErrorModal();
  const [scoreOptionValue, setScoreOption] = useState<string>(StarsOptionName.AllStars);
  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [workId, setWorkId] = React.useState<number>(0);
  const navigate = useNavigate();
  const [isTpmemDetailShow, setIsTpmemDetailShow] = React.useState(false);
  const [modalData, setModalData] = React.useState<{ tpmemName: string; tpmemId: number }>();

  const handleOptionChange = (value: string) => {
    setScoreOption(value);
  };

  const filteredData =
    scoreOptionValue !== StarsOptionName.AllStars
      ? reviewListData?.getVBReviewListScoreByBtmemId.list.filter(
          (item) => item.score === Number(scoreOptionValue.charAt(scoreOptionValue.length - 2))
        )
      : reviewListData?.getVBReviewListScoreByBtmemId.list;

  const sortedData = [...(filteredData || [])].sort((a, b) => (a.score || 0) - (b.score || 0));

  const items = useMemo(
    () =>
      sortedData.map((data) => ({
        row: (
          <ListRow
            image={data.image || ''}
            post_date={data.post_date || ''}
            score={data.score || 0}
            comment={data.comment || ''}
            tpmemName={data.name || ''}
            tpmemId={data.tpmem_id || 0}
            setIsTpmemDetailShow={setIsTpmemDetailShow}
            setModalData={setModalData}
          />
        ),
        data,
      })),
    [sortedData]
  );

  const deleteHandler = useCallback(() => {
    commentDelete({ variables: { work_id: workId } }).then(() => {
      reviewListRefetch();
      scorePercentInfoRefetch();
      setModalStep(ModalStep.COMPLETE);
    });
  }, [commentDelete, workId, reviewListRefetch, scorePercentInfoRefetch]);

  const onCloseHandler = useCallback(() => {
    setModalStep(ModalStep.NULL);
  }, []);

  const modal = CommentDeleteModal({
    modalStep,
    title: {
      delete: 'コメント削除',
      complete: 'コメント削除 完了',
    },
    onClose: onCloseHandler,
    deleteHandler,
  });

  if (reviewListError || scorePercentInfoError) {
    openErrorModal({ message: 'データ取得に失敗しました。' });
  }

  React.useEffect(() => {
    if (commentDeleteError) {
      setModalStep(ModalStep.NULL);
      openErrorModal({
        message: (
          <Text variant="body14">
            サーバーとの接続に失敗しました。
            <br />
            一時的にサーバーとの接続が不安定となっている可能性があります
            <br />
            少し時間をおいてから再度お試しください。
          </Text>
        ),
      });
    }
  }, [commentDeleteError, openErrorModal]);

  useEffect(() => {
    setDataSize(items?.length || 0);
  }, [items?.length]);

  if (reviewListLoading || scorePercentInfoLoading || commentDeleteLoading) {
    return Loading();
  }

  return (
    <>
      <Box px={24} display="flex" flex="1" flexDirection="column">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">
            レビュー
          </Text>
        </Box>
        <Divider option="horizontal" />
        <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" flexDirection="row" justifyContent="space-between" height={40} alignItems="center" pt={8}>
            <Box display="flex" alignItems="center" gap={8} flexDirection="row">
              <ScoreToast
                vTScorePercentInfo={{
                  tpmem_id: Number(id),
                  score5_per: scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score5_per,
                  score4_per: scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score4_per,
                  score3_per: scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score3_per,
                  score2_per: scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score2_per,
                  score1_per: scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score1_per,
                }}
              >
                <Rating
                  value={scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score || 0}
                  size="large"
                  precision={0.5}
                  readOnly
                />
              </ScoreToast>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Box>
                  <Text variant="bigTitle">{scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score}</Text>
                </Box>
                <Box ml={8}>
                  <Text variant="caption12">{`(${String(
                    scorePercentInfoData?.getVBScorePercentInfoByBtmemId.score_count
                  )}人が評価)`}</Text>
                </Box>
              </Box>
            </Box>
            <Box flex="row">
              <Select
                width={100}
                value={scoreOptionValue}
                options={ScoreOption}
                onChange={(value) => {
                  if (!value) {
                    // 未選択時は「全ての星」を選択
                    handleOptionChange(StarsOptionName.AllStars);
                  } else {
                    handleOptionChange(value);
                  }
                  // 1ページ目に戻す
                  handleChangePage(1);
                }}
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" flexDirection="column">
            <PaginationBox
              dataSize={dataSize}
              limit={limit}
              page={page}
              onChangeLimit={handleChangeLimit}
              onChangePage={handleChangePage}
            >
              <div style={{ height: 'calc(100vh - 400px)' }}>
                <ScrollableArea>
                  {items
                    ?.map((item, key) => (
                      <Box key={`${String(key)}`}>
                        <Divider option="horizontal" length={0} />
                        <Box
                          my={4}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box width="100%">{item.row}</Box>
                          <Box>
                            <ThreeDotLeader
                              menu={MenuOption}
                              onClick={(value: string) => {
                                if (value === 'delete') {
                                  setWorkId(item.data.work_id);
                                  setModalStep(ModalStep.DELETE);
                                } else if (value === 'move') {
                                  navigate(`/s04-02/${String(item.data.offer_id)}`);
                                }
                              }}
                              right={1}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))
                    .slice(limit * (page - 1), limit * page)}
                  <Divider option="horizontal" length={0} />
                </ScrollableArea>
              </div>
            </PaginationBox>
          </Box>
        </Box>
      </Box>
      <Modal
        height={modal.height}
        width={modal.width}
        isOpen={modalStep !== ModalStep.NULL}
        content={modal.content}
        header={modal.header}
        footer={modal.footer}
        onClose={modal.onClose}
      />
      <Modal
        isOpen={isTpmemDetailShow}
        header={<TpmemDetailHeader tpmemName={modalData?.tpmemName} tpmemId={modalData?.tpmemId} />}
        content={<TpmemDetailContent tpmemId={modalData?.tpmemId} />}
        footer={<TpmemDetailFooter onClickClose={() => setIsTpmemDetailShow(false)} />}
        onClose={() => setIsTpmemDetailShow(false)}
        width={955}
        height="auto"
      />
    </>
  );
}
