import React, { useMemo } from 'react';
import { Modal } from 'components/newModal';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Link as RouterLink } from 'react-router-dom';
import { MainButton } from 'components/mainButton';
import { Info } from 'components/info';
import { Avatar } from 'components/avatar';
import { Divider } from 'components/newDivider';
import { VbMemberBase } from 'graphql/graphql-ow';
import NoImgSvg from 'components/assets/images/no-image-small.svg';
import { identificationLabel } from 'components/labelList/identificationLabel';

type Props = {
  isOpen: boolean;
  vbMemberBase: VbMemberBase;
  onClickClose: () => void;
};

/**
 * バイトラベル会員用モーダルダイアログ
 */
// Figma管理番号
// 11-01-04
export function BitravelMemberModal({ isOpen, vbMemberBase, onClickClose }: Props) {
  const address = useMemo<string>(
    () =>
      `${
        vbMemberBase.zip_code
          ? `〒${vbMemberBase?.zip_code?.slice(0, 3) ?? ''}-${vbMemberBase?.zip_code?.slice(3, 7) ?? ''} `
          : ''
      }
          ${vbMemberBase.state ? `${vbMemberBase.state} ` : ''}
          ${vbMemberBase.city ? `${vbMemberBase.city} ` : ''}
          ${vbMemberBase.address ? `${vbMemberBase.address} ` : ''}
          ${vbMemberBase.address2 ? `${vbMemberBase.address2} ` : ''}`,
    [vbMemberBase]
  );

  const bankInfo = useMemo<string>(
    () =>
      `${vbMemberBase.bank ? `${vbMemberBase.bank} ` : ''}
      ${vbMemberBase.branch_name ? `${vbMemberBase.branch_name} ` : ''}
      ${vbMemberBase.account_type ? `${vbMemberBase.account_type} ` : ''}
      ${vbMemberBase.account_no ? `${vbMemberBase.account_no} ` : ''}
      ${vbMemberBase.account_name ? `${vbMemberBase.account_name} ` : ''}`,
    [vbMemberBase]
  );

  const tpmemNameList = vbMemberBase.tpmem_names ? vbMemberBase.tpmem_names.split(',') : [];
  const tpmemSubNameList = vbMemberBase.sub_names ? vbMemberBase.sub_names.split(',') : [];
  const tpBizNameList = vbMemberBase.tp_biz_names ? vbMemberBase.tp_biz_names.split(',') : [];
  const bizLicenseNameList = vbMemberBase.lc_biz_names ? vbMemberBase.lc_biz_names.split(',') : [];
  const bizLicenseList = vbMemberBase.license_list ? vbMemberBase.license_list.split('\n') : [];
  const rankBizNameList = vbMemberBase.rank_biz_names ? vbMemberBase.rank_biz_names.split(',') : [];
  const ranknameList = vbMemberBase.rank_names ? vbMemberBase.rank_names.split(',') : [];
  const tpMemList = () => {
    const item = [];
    for (let i = 0; i < tpmemNameList.length; i += 1) {
      item.push(
        {
          label: `所属店舗(${tpBizNameList[i]})`,
          content: tpmemNameList[i],
        },
        {
          label: `サブ店舗(${tpBizNameList[i]})`,
          content: tpmemSubNameList[i] || '-',
        }
      );
    }
    return item;
  };
  const bizLicense = () => {
    const item = [];
    for (let i = 0; i < bizLicenseNameList.length; i += 1) {
      item.push({
        label: `${bizLicenseNameList[i]}資格`,
        content: bizLicenseList[i],
      });
    }
    return item;
  };
  const rankBiz = () => {
    const item = [];
    for (let i = 0; i < rankBizNameList.length; i += 1) {
      item.push({
        label: `ランク(${rankBizNameList[i]})`,
        content: ranknameList[i],
      });
    }
    return item;
  };

  return (
    <Modal
      isOpen={isOpen}
      header={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text variant="h2" color="darkBlue">
            {vbMemberBase.name}
          </Text>
          <RouterLink to={`/s05/${vbMemberBase.id}`} style={{ textDecoration: 'none' }}>
            <MainButton width={74} variant="secondary">
              詳しく
            </MainButton>
          </RouterLink>
        </Box>
      }
      content={
        <Box display="flex" flexDirection="column" gap={8}>
          <Box display="flex" alignItems="center" gap={24}>
            <Avatar src={vbMemberBase.image || NoImgSvg} width={90} height={60} />
            <Text variant="h1" color="darkBlue">
              {vbMemberBase.name}
            </Text>
          </Box>
          <Divider option="horizontal" />
          <Info
            items={[
              {
                label: '氏名（フリガナ）',
                content: vbMemberBase.kana,
              },
              {
                label: 'メールアドレス(ID)',
                content: vbMemberBase.email,
              },
              {
                label: '会員番号',
                content: vbMemberBase.btmem_number,
              },
              {
                label: '電話番号',
                content: vbMemberBase.phone,
              },
              {
                label: '生年月日',
                content: vbMemberBase.birthday,
              },
              {
                label: '年齢',
                content: vbMemberBase.age,
              },
              ...rankBiz(),
              {
                label: '達成都道府県',
                content: vbMemberBase.state_count,
              },
              {
                label: 'バイト回数',
                content: `${vbMemberBase.worked_count ?? '0'} ${vbMemberBase.badge_name ?? ''}`,
              },
              {
                label: '入会日',
                content: vbMemberBase.created_date,
              },
              {
                label: '住所',
                content: address,
              },
              ...tpMemList(),
              {
                label: '性別',
                content: vbMemberBase.gender,
              },
              {
                label: '国籍',
                content: vbMemberBase.nationality || '-',
              },
              {
                label: '属性',
                content: vbMemberBase.attribute || '-',
              },
              {
                label: '保有資格',
                content: vbMemberBase.general_licenses || '-',
              },
              ...bizLicense(),
              {
                label: '制服サイズ',
                content: vbMemberBase.uniform_size,
              },
              {
                label: '遅刻率',
                content: `${vbMemberBase.late_count ? `${vbMemberBase.late_count}回` : '0回'} / ${
                  vbMemberBase.late_per ? `${vbMemberBase.late_per}%` : '0%'
                }`,
              },
              {
                label: 'キャンセル率',
                content: `${vbMemberBase.cancel_count ? `${vbMemberBase.cancel_count}回` : '0回'} / ${
                  vbMemberBase.cancel_per ? `${vbMemberBase.cancel_per}%` : '0%'
                }`,
              },
              {
                label: 'ドタキャン率',
                content: `${vbMemberBase.imminent_count ? `${vbMemberBase.imminent_count}回` : '0回'} / ${
                  vbMemberBase.imminent_per ? `${vbMemberBase.imminent_per}%` : '0%'
                }`,
              },
              {
                label: 'バックレ回数',
                content: `${vbMemberBase.skip_count ? `${vbMemberBase.skip_count}回` : '0回'}`,
              },
              {
                label: '登録業種',
                content: vbMemberBase.reg_biz_list || '-',
              },
              {
                label: '入金口座',
                content: bankInfo,
              },
              {
                label: '本人確認',
                content: identificationLabel(vbMemberBase.identification),
              },
            ]}
          />
        </Box>
      }
      footer={
        <Box display="flex" justifyContent="end" alignItems="end">
          <MainButton onClick={onClickClose} width={104} variant="secondary">
            閉じる
          </MainButton>
        </Box>
      }
      onClose={onClickClose}
      width={1003}
      height="calc(100vh - 100px)"
    />
  );
}
