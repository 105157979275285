/**
 * Figma ID: 04-02-04
 * 名称: バイト状況履歴（成約バイト状況）
 */
import React from 'react';
import { HistoryList } from 'components/historyList';
import { Box } from 'components/box';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Text } from 'components/text';
import { PaginationBox } from 'components/pagination/paginationBox';
import { TLogging } from 'graphql/graphql-ow';
import { Divider } from 'components/newDivider';

interface Props {
  loggingList: TLogging[] | null;
}

// バイト状況履歴
export function History({ loggingList }: Props) {
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const displayHistoryItems: {
    uniqueKey: string | number;
    text: string;
    dateTime: string;
  }[] = React.useMemo(() => {
    if (!loggingList) return [];

    return loggingList
      .map((item) => ({
        uniqueKey: item.tpmem_id,
        text: String(item.matter),
        dateTime: String(item.log_date),
      }))
      .slice(currentLimit * (currentPage - 1), currentLimit * currentPage);
  }, [currentPage, currentLimit, loggingList]);

  return (
    <Box px={24} pb={16} display="flex" flexDirection="column" alignItems="center" overflow="scroll" width="100%">
      <Box height={60} width="100%" display="flex" alignItems="center">
        <Text color="darkBlue" variant="h2">
          バイト状況履歴
        </Text>
      </Box>
      <Divider option="horizontal" />
      <Box flex="auto" width="100%">
        <PaginationBox
          dataSize={loggingList?.length || 0}
          limit={currentLimit}
          page={currentPage}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <ScrollWrapper bottom={311}>
            <HistoryList items={displayHistoryItems} />
          </ScrollWrapper>
        </PaginationBox>
      </Box>
    </Box>
  );
}
