import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import React, { ReactNode, useMemo, ComponentProps } from 'react';
import { useWatch } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { ErrorMessage } from 'components/errorMessage';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Divider } from 'components/newDivider';
import { styled } from '@linaria/react';

import { handleFileSet, iconEditConfirmSubFinction, skillReRegistrationSubFinction } from '../s10-02-utils';
import { NewRegistrationModalStep, NewRegisterProps } from '../s10-02-types';
import { InputsBrandContainer } from '../components/inputsContainer/inputsBrandContainer';
import { InputsLicensesContainer } from '../components/inputsContainer/inputsLicensesContainer';

export interface Props {
  items: {
    label: string;
    labelBold?: boolean;
    content: ReactNode;
    align?: string;
    pt?: number;
    isShow?: boolean;
  }[];
}

const StyledWrapper = styled.div<{
  align?: string;
}>`
  width: 100%;
`;

const StyledRow = styled.div<{
  align?: string;
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  padding: 8px 0px;
  border-bottom: #dbdbdb 1px solid;
`;

const StyledLabel = styled.div<{
  pt?: number;
}>`
  min-width: 212px;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : 'auto')};
  line-height: 28px;
`;

const StyledContent = styled.div`
  width: 100%;
  flex: auto;
  line-height: 28px;
`;

export function Info({ items }: Props) {
  return (
    <StyledWrapper>
      {items.map(
        (item) =>
          item.isShow !== false && (
            <StyledRow key={item.label} align={item.align}>
              <StyledLabel pt={item.pt}>
                <Text variant="body14" color="darkGray" bold={item.labelBold}>
                  {item.label}
                </Text>
              </StyledLabel>
              <StyledContent>
                {typeof item.content === 'string' || typeof item.content === 'number' ? (
                  <Text variant="body14">{item.content}</Text>
                ) : (
                  item.content
                )}
              </StyledContent>
            </StyledRow>
          )
      )}
    </StyledWrapper>
  );
}

export type InfoItemsType = ComponentProps<typeof Info>['items'];

/**
 * Figma ID: 10-02-11-02, 10-02-11-03, 10-02-11-04, 10-02-11-05
 * 名称: 業種/ブランド 新規登録
 */
export function NewRegisterBusiness({
  newBusinessModalStep,
  setNewBusinessModalStep,
  onClose,
  mutation,
  refetch,
  control,
  getValues,
  errors,
  handleSubmit,
  brandFields,
  brandAppend,
  brandRemove,
  licenseFields,
  licenseAppend,
  licenseRemove,
  fileIconInputRef,
  fileSkillSectionInputRef,
  fileSkillCheckInputRef,
  selectedFileIcon,
  selectedFileSkillSection,
  selectedFileSkillCheck,
  setSelectedFileIcon,
  setSelectedFileSkillSection,
  setSelectedFileSkillCheck,
}: NewRegisterProps) {
  const { openErrorModal } = useErrorModal();

  const closeFunc = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const errorFunc = React.useCallback(() => {
    onClose();
    openErrorModal({ message: 'ファイル選択できませんでした' });
  }, [onClose, openErrorModal]);

  // 画像ファイル（png）
  const handleIconFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'png', setSelectedFileIcon, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileIcon, errorFunc]
  );

  // スキルチェック（セクション）
  const handleSkillSectionFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'csv', setSelectedFileSkillSection, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileSkillSection, errorFunc]
  );

  // スキルチェック（チェック項目）
  const handleSkillCheckFileSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFileSet(event, 'csv', setSelectedFileSkillCheck, errorFunc);
      event.target.value = '';
    },
    [setSelectedFileSkillCheck, errorFunc]
  );

  const onClickHandler = handleSubmit(() => {
    setNewBusinessModalStep(NewRegistrationModalStep.CHECK);
  });

  const iconElement: React.ReactNode = useMemo(
    () => (
      <Box display="flex" flexDirection="row" gap={16}>
        <Box>
          {selectedFileIcon && (
            <img src={URL.createObjectURL(selectedFileIcon)} width="144px" height="144px" alt="icon" />
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="end">
          {selectedFileIcon && (
            <Text variant="h3" color="blueGray">
              {selectedFileIcon.name}
            </Text>
          )}
        </Box>
      </Box>
    ),
    [selectedFileIcon]
  );

  const brandList = useWatch({ control, name: 'brand' });
  const brandElement = React.useMemo(() => {
    if (brandList === undefined) return '';
    return (
      <Box display="flex" flexDirection="column" gap={8}>
        {brandList.map((item) => (
          <Text variant="body14">
            {item.name}({item.code})
          </Text>
        ))}
      </Box>
    );
  }, [brandList]);

  const liceseList = useWatch({ control, name: 'license' });
  const licenseElement = React.useMemo(() => {
    if (liceseList === undefined) return '';
    return (
      <Box display="flex" flexDirection="column" gap={8}>
        {liceseList.map((item) => (
          <Text variant="body14">{item.lcValue}</Text>
        ))}
      </Box>
    );
  }, [liceseList]);

  const bizCode = useWatch({ control, name: 'bizCode' });
  const bizName = useWatch({ control, name: 'bizName' });

  const infoItems: InfoItemsType = useMemo(
    () => [
      { label: '業種名', content: bizName },
      { label: '業種コード', content: bizCode },
      { label: '業種アイコン', content: iconElement },
      { label: 'ブランド', content: brandElement },
      { label: '業種保有資格', content: licenseElement },
      { label: 'スキルチェック（セクション）', content: selectedFileSkillSection?.name || '' },
      { label: 'スキルチェック（チェック項目）', content: selectedFileSkillCheck?.name || '' },
    ],
    [bizName, bizCode, iconElement, brandElement, licenseElement, selectedFileSkillSection, selectedFileSkillCheck]
  );

  const displayModal = React.useMemo(() => {
    switch (newBusinessModalStep) {
      case NewRegistrationModalStep.INPUT:
        return {
          width: 820,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              新規登録
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" rowGap={16} pb={8}>
              <Text variant="body14">各項目を入力して「次へ」を押してください。</Text>
              <ScrollWrapper bottom={300}>
                <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
                  <Text variant="h3" color="blueGray">
                    業種名
                  </Text>
                  <RhfInput
                    name="bizName"
                    control={control}
                    fullWidth
                    defaultValue=""
                    placeholder="業種名を入力"
                    rules={{ required: '業種名を入力してください' }}
                  />
                  {errors?.bizName && <ErrorMessage>{errors?.bizName.message}</ErrorMessage>}
                </Box>
                <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
                  <Text variant="h3" color="blueGray">
                    業種コード
                  </Text>
                  <RhfInput
                    name="bizCode"
                    control={control}
                    fullWidth
                    defaultValue=""
                    placeholder="英子文字３桁の省略コードを入力"
                    rules={{
                      required: '業種コードを入力してください',
                      validate: (value) => {
                        if (typeof value === 'string') {
                          if (value.length !== 3) {
                            return '3桁で入力してください';
                          }
                          if (value !== value.match(/^[a-z]+$/)?.[0]) {
                            return '英子文字で入力してください';
                          }
                        }
                        return undefined;
                      },
                    }}
                  />
                  {errors?.bizCode && <ErrorMessage>{errors?.bizCode.message}</ErrorMessage>}
                </Box>
                {iconEditConfirmSubFinction({
                  fileName: '',
                  fileInfo: selectedFileIcon,
                  fileIconInputRef,
                })}

                <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
                  <Text variant="h3" color="blueGray">
                    ブランド/ブランドコード
                  </Text>
                  <InputsBrandContainer
                    fields={brandFields}
                    control={control}
                    append={brandAppend}
                    remove={brandRemove}
                    errors={errors}
                    brands={[]}
                    inputs={brandList}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={5.5} pb={8}>
                  <Text variant="h3" color="blueGray">
                    業種保有資格
                  </Text>
                  <InputsLicensesContainer
                    fields={licenseFields}
                    control={control}
                    append={licenseAppend}
                    remove={licenseRemove}
                    errors={errors}
                    licenses={[]}
                    inputs={liceseList}
                  />
                </Box>
                {skillReRegistrationSubFinction({
                  SectionInfo: selectedFileSkillSection,
                  SkillInfo: selectedFileSkillCheck,
                  fileSkillSectionInputRef,
                  fileSkillCheckInputRef,
                })}
              </ScrollWrapper>
              <input
                type="file"
                ref={fileIconInputRef}
                style={{ display: 'none' }}
                onChange={handleIconFileSelect}
                accept="image/png"
              />
              <input
                type="file"
                ref={fileSkillSectionInputRef}
                style={{ display: 'none' }}
                onChange={handleSkillSectionFileSelect}
                accept="text/csv"
              />
              <input
                type="file"
                ref={fileSkillCheckInputRef}
                style={{ display: 'none' }}
                onChange={handleSkillCheckFileSelect}
                accept="text/csv"
              />
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  closeFunc();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  closeFunc();
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={(event) => {
                  onClickHandler(event);
                }}
                variant="primary"
                width={104}
              >
                次へ
              </MainButton>
            </Box>
          ),
        };
      case NewRegistrationModalStep.CHECK:
        return {
          width: 800,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              新規登録 確認
            </Text>
          ),
          content: (
            <Box px={16} display="flex" flex="1" flexDirection="column">
              <Text variant="body14">
                下記内容で登録します。よろしければ登録を押してください。
                <br />
                <br />
              </Text>
              <Divider option="horizontal" length={0} />
              <Box display="flex" flexDirection="column" flex="1" pb={16}>
                <ScrollWrapper bottom={244}>
                  <Info items={infoItems} />
                </ScrollWrapper>
              </Box>
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  closeFunc();
                }}
                variant="secondary"
                width={104}
              >
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  setNewBusinessModalStep(NewRegistrationModalStep.INPUT);
                }}
                variant="secondary"
                width={104}
              >
                戻る
              </MainButton>
              <MainButton
                thin
                onClick={() => {
                  mutation();
                }}
                variant="primary"
                width={104}
              >
                登録
              </MainButton>
            </Box>
          ),
        };
      case NewRegistrationModalStep.COMPLETE:
        return {
          width: 384,
          height: 'auto',
          header: (
            <Text variant="h2" color="darkBlue">
              新規登録 完了
            </Text>
          ),
          content: (
            <Text variant="body14">
              情報の設定が完了しました。
              <br />
            </Text>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton
                thin
                onClick={() => {
                  closeFunc();
                  refetch();
                }}
                variant="secondary"
                width={104}
              >
                閉じる
              </MainButton>
            </Box>
          ),
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose: () => {
            onClose();
          },
        };
    }
  }, [
    closeFunc,
    newBusinessModalStep,
    setNewBusinessModalStep,
    onClose,
    refetch,
    mutation,
    control,
    errors,
    onClickHandler,
    handleIconFileSelect,
    handleSkillSectionFileSelect,
    handleSkillCheckFileSelect,
    selectedFileIcon,
    selectedFileSkillSection,
    selectedFileSkillCheck,
    infoItems,
    fileIconInputRef,
    fileSkillSectionInputRef,
    fileSkillCheckInputRef,
    brandFields,
    brandAppend,
    brandRemove,
    licenseFields,
    licenseAppend,
    licenseRemove,
    brandList,
    liceseList,
  ]);
  return displayModal;
}
