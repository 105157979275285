import { HideWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useMemo, useEffect } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { VsLendStaffList } from 'graphql/graphql-ow';
import * as gql from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import { CHIP_TEXT, ModalStep, ListTitleWidth } from 'pages/s10-02/constants';
import { chipColor, leaderMenu } from 'pages/s10-02/s10-02-utils';
import { EducationFeeSettingModal } from 'pages/s10-02/modal/education-fee-setting-modal';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { EducationFeeSettinModalViewDataTyep } from 'pages/s10-02/s10-02-types';
import { ScrollableArea } from 'components/scrollableArea';

import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

/**
 * Figma ID: 10-02-03-01 10-02-04-01
 * 名称: 育成料設定（コンビニ）育成料設定（カフェ）
 */
export interface Props {
  id?: number;
  name?: string;
}

export interface ListProps {
  cpRow: VsLendStaffList[][];
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  setModalData: React.Dispatch<React.SetStateAction<EducationFeeSettinModalViewDataTyep | undefined>>;
  name?: string;
}

const makeListData = ({ cpRow, setModalStep, setModalData, name }: ListProps) =>
  cpRow.map((rows) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        {rows.map((row) => (
          <>
            <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <Text color="darkBlue" variant="caption12" bold>
                  {row.rank_name}
                </Text>
              </CampaignTableItem>
              <CampaignTableItem isDivider width={88} height={18} dividerHeight={18} alignItems="center">
                <Text color="blueGray" variant="caption12" bold>
                  最小/最大
                </Text>
              </CampaignTableItem>
              <CampaignTableItem width={88} height={18} dividerHeight={18} alignItems="center">
                <Text color="blueGray" variant="caption12">
                  {row.educ_range}
                </Text>
              </CampaignTableItem>
              <CampaignTableItem isDivider width={88} height={18} dividerHeight={18} alignItems="center">
                <Text color="blueGray" variant="caption12" bold>
                  デフォルト値
                </Text>
              </CampaignTableItem>
              <CampaignTableItem height={18} dividerHeight={18} alignItems="center">
                <Text color="blueGray" variant="caption12">
                  {row.educ_default}%
                </Text>
              </CampaignTableItem>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                gap={8}
                backgroundColor="white"
              >
                <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
                  {null}
                </Box>
                <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
                  <CampaignTableItem width={160} height={18} dividerHeight={18} alignItems="center">
                    <MainButton
                      onClick={() => {
                        setModalData({
                          title: {
                            input: `育成料設定(${name || ''})`,
                            check: `育成料設定(${name || ''})`,
                            complete: `育成料設定(${name || ''}) 完了`,
                          },
                          biz_id: row.biz_id,
                          bt_rank_id: row.bt_rank_id,
                          runk: row.rank_name,
                          min: row.educ_fee_min,
                          max: row.educ_fee_max,
                          default: row.educ_default,
                          range: row.educ_range,
                        });
                        setModalStep(ModalStep.INPUT);
                      }}
                      variant="secondary"
                      width={160}
                      thin
                      icon="edit"
                      iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                    >
                      変更
                    </MainButton>
                  </CampaignTableItem>
                </Box>
              </Box>
            </CampaignTableRow>
            <Divider option="horizontal" />
          </>
        ))}
      </CampaignTableBody>
    ),
  }));

export function BusinessTab({ id, name }: Props) {
  const { openErrorModal } = useErrorModal();

  const [modalStep, setModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [modalData, setModalData] = React.useState<EducationFeeSettinModalViewDataTyep>();
  const {
    data,
    loading: loadingStaffListApiLoading,
    error: errorStaffListApiError,
    refetch,
  } = gql.useGetVsLendStaffListByBizIdQuery({ variables: { biz_id: id ?? 0 } });
  const [update, { loading: updateLendStaffApiLoading, error }] = gql.useUpdateSLendStaffByBtRankIdBizIdMutation();

  useEffect(() => {
    if (errorStaffListApiError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [errorStaffListApiError, openErrorModal]);

  const listItems = useMemo(
    () =>
      makeListData({
        cpRow: [data?.getVSLendStaffListByBizId || []] || [[]],
        setModalStep,
        setModalData,
        name,
      }),
    [data, name]
  );

  const displayModal = EducationFeeSettingModal({
    modalStep,
    setModalStep,
    onClose: () => {},
    modalData,
    mutation: (educ_fee: number, educ_fee_max: number, educ_fee_min: number) => {
      update({
        variables: {
          biz_id: modalData?.biz_id || 0,
          bt_rank_id: modalData?.bt_rank_id || 0,
          educ_fee,
          educ_fee_max,
          educ_fee_min,
        },
      })
        .then((e) => {
          if (!e.errors && !error) {
            setModalStep(ModalStep.COMPLETE);
            refetch();
          } else {
            openErrorModal({
              message: '育成料の更新に失敗しました。\n少し時間をおいてから再度お試しください。',
              zIndex: ModalZindex.ERRMODALZINDEX,
            });
          }
        })
        .catch(() => {
          openErrorModal({
            message:
              'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
            zIndex: ModalZindex.ERRMODALZINDEX,
          });
        });
    },
  });

  if (error) {
    refetch();
  }

  if (loadingStaffListApiLoading || updateLendStaffApiLoading) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
          <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
            <Text color="darkBlue" variant="h2">
              育成料設定({name})
            </Text>
            <Text color="darkBlue" variant="caption12">
              ランクに応じた育成料のレンジとデフォルト値を設定
            </Text>
          </Box>
        </Box>
        <Divider option="horizontal" />
        <ScrollableArea>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollableArea>
      </HideWrapper>
      <Modal
        width={displayModal.width}
        height={displayModal.height}
        isOpen={modalStep !== ModalStep.NULL}
        onClose={() => displayModal.onClose}
        header={displayModal.header}
        content={displayModal.content}
        footer={displayModal.footer}
        overflow="visible"
      />
    </>
  );
}
