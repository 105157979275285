import React, { HTMLInputTypeAttribute } from 'react';
import { styled } from '@linaria/react';
import { Icon } from '../icon';
import type { Name } from '../icon/icon';
import { GENERIC_COLOR, PRIMARY_COLOR, LABEL_COLOR } from '../assets/css/style';

export interface Props {
  id?: string;
  name?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  width?: number;
  fullWidth?: boolean;
  error?: boolean;
  min?: number;
  max?: number;
}

const StyledInput = styled.input<{
  width: number;
  fullWidth?: boolean;
  error: boolean;
}>`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : `${width}px`)};
  height: 32px;
  padding: 4px 8px;
  background: ${GENERIC_COLOR.WHITE};
  border: 1px solid ${({ error }) => (error ? GENERIC_COLOR.CAUTION_RED : GENERIC_COLOR.SUPERLITE_GRAY)};
  border-radius: 2px;
  box-sizing: border-box;
  font-family: 'Meiryo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${GENERIC_COLOR.BLACK};
  ::placeholder {
    color: ${GENERIC_COLOR.LITE_GRAY};
  }
  outline: none;
  &:focus {
    border: 1px solid ${LABEL_COLOR.BLUE};
  }
  // typeがnumber の場合、スピンボタンを非表示にする
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export function Input({
  id,
  name,
  value,
  width = 328,
  placeholder,
  fullWidth,
  onChange,
  type = 'text',
  error = false,
  min,
  max,
}: Props) {
  const [inputType, setInputType] = React.useState(type);
  const iconStatus = () => {
    let result: { name: Name; color: string } = { name: 'eye', color: GENERIC_COLOR.SUPERLITE_GRAY };
    if (value === undefined || value === '') return result;
    if (inputType === 'password') {
      result = { name: 'eye', color: PRIMARY_COLOR.PRIMARY_BLUE };
    } else {
      result = { name: 'eyeSlash', color: PRIMARY_COLOR.PRIMARY_BLUE };
    }
    return result;
  };
  return (
    <div style={{ position: 'relative' }}>
      {type === 'password' && (
        <div style={{ position: 'absolute', left: width - 30, top: 3 }}>
          <Icon
            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
            name={iconStatus().name}
            color={iconStatus().color}
          />
        </div>
      )}
      <StyledInput
        id={id}
        name={name}
        type={inputType}
        value={value}
        width={width}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onChange={(event) => onChange(event)}
        error={error}
        min={min}
        max={max}
        onWheel={(event) => {
          // スクロール上下による値の変更を無効化する
          if (type === 'number') {
            event.currentTarget.blur();
          }
        }}
      />
    </div>
  );
}
