import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useMemo } from 'react';
import { useGetVtMemberGroupListByIdQuery, VtGroupList } from 'graphql/graphql-ow';
import { useErrorModal } from 'components/error/errorModalProvider';
import NoImageSmall from 'components/assets/images/no-image-small.svg';
import { Loading } from 'components/loading/loading';
import { Divider } from 'components/newDivider';
import { PaginationBox } from 'components/pagination';
import { ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { List } from 'components/list';
import { usePagenation } from 'pages/s03/components/usePagenate';
import { ListHeaderType, ListItemsType } from 'pages/s03/type';
import { TextLink } from 'components/textLink';
import { MwStoreInfoModal } from 'pages/s03/components/modal';
import { useSort } from 'components/utils';
import { orderBy } from 'lodash';

/**
 * Figma ID: 03-08-01
 * グループ店舗
 * Review
 */
enum ModalType {
  NONE = 'NONE',
  MWSTORE_INFO = 'MWSTORE_INFO',
}

export function Group(props: { id: number }) {
  const { id } = props;
  const [modalType, setModalType] = React.useState<ModalType>(ModalType.NONE);
  const [modalId, setModalId] = React.useState<{ tpmemId: number }>({ tpmemId: 0 });
  const { openErrorModal } = useErrorModal();
  const {
    data: vTMemberGroupData,
    loading: vTMemberGroupLoading,
    error: vTMemberGroupError,
  } = useGetVtMemberGroupListByIdQuery({
    variables: { id },
  });
  React.useEffect(() => {
    if (vTMemberGroupError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [vTMemberGroupError, openErrorModal]);

  const groupList = vTMemberGroupData?.getVTMemberGroupListById.group;

  const { sort, handleChangeSort } = useSort();

  const { items, handleChangeLimit, handleChangePage, pagenation } = usePagenation<VtGroupList | null>(
    groupList ?? [],
    10,
    1
  );

  const listHeader: ListHeaderType = [
    { width: 40, key: 'image' },
    { width: 200, key: 'name', columnName: '店舗名' },
    { width: 140, key: 'accept_date', columnName: 'グループ店登録日' },
  ];

  const listItems: ListItemsType = useMemo(
    () =>
      orderBy(items, sort?.key, sort?.direction).map((item, key) => ({
        image: <img src={item?.image || NoImageSmall} alt="" width={40} height={40} style={{ objectFit: 'cover' }} />,
        name: (
          <TextLink
            variant="caption12"
            onClick={() => {
              setModalId({ tpmemId: item?.tpmem_id ?? 0 });
              setModalType(ModalType.MWSTORE_INFO);
            }}
          >
            {item?.name}
          </TextLink>
        ),
        uniqueKey: key,
        accept_date: item?.accept_date,
      })),
    [items, sort]
  );

  return (
    <>
      <Box px={16} display="flex" flex="1" flexDirection="column">
        {vTMemberGroupLoading && <Loading />}
        <Box display="flex" flexDirection="row" alignItems="center" height={60}>
          <Text variant="h2" color="darkBlue">{`グループ店舗(${
            vTMemberGroupData?.getVTMemberGroupListById.member?.group_num ?? 0
          })`}</Text>
        </Box>
        <Divider option="horizontal" length={0} />
        <Box display="flex" flexDirection="column" flex="1">
          <PaginationBox
            dataSize={groupList?.length ?? 0}
            limit={pagenation.limit}
            page={pagenation.page}
            onChangeLimit={(value: 10 | 20 | 30) => handleChangeLimit(value)}
            onChangePage={(value: number) => handleChangePage(value)}
          >
            <ScrollWrapper bottom={345}>
              <List
                header={listHeader}
                items={listItems}
                onChangeSort={handleChangeSort}
                sort={sort}
                width="100%"
                rowWidth="100%"
              />
            </ScrollWrapper>
          </PaginationBox>
        </Box>
      </Box>
      <MwStoreInfoModal
        isOpen={modalType === ModalType.MWSTORE_INFO}
        onClose={() => setModalType(ModalType.NONE)}
        {...modalId}
      />
    </>
  );
}
