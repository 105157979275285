import { useEffect } from 'react';

export const useScrollToFormError = ({
  errors,
  isSubmitting,
}: {
  errors: { [key: string]: any };
  isSubmitting: boolean;
}) => {
  useEffect(() => {
    const formNames = Object.keys(errors);
    formNames.forEach((name) => {
      const errorElement = document.getElementsByName(name)[0];
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }, [isSubmitting, errors]);
};
