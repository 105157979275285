import React from 'react';
import * as gql from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { Card } from 'components/card';
import { Input } from 'components/input';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Select } from 'components/select';
import { PaginationBox } from 'components/pagination';
import { Icon } from 'components/icon';
import { Chip } from 'components/chip';
import { List } from 'components/list';
import { CommonIdentificationStatus } from 'components/const';
import { identificationLabel } from 'components/labelList/identificationLabel';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';
import { usePagenation, useSort } from 'components/utils';
import { useErrorModal } from 'components/error/errorModalProvider';

enum NewlyStatus {
  NEWLY = 1,
  NOT_NEWLY = 0,
}

const RelativeContainer = styled.div`
  position: relative;
`;

const Indicator = styled.div`
  position: absolute;
  top: -8px;
  right: 0;
`;

const StyledListWrapper = styled.div`
  height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  overflow: auto;
`;

type ListHeaderType = React.ComponentProps<typeof List>['header'];
type ListItemsType = React.ComponentProps<typeof List>['items'];
type SelectOptionType = React.ComponentProps<typeof Select>['options'];

// Figma管理番号
// 06-01-01
export function S06() {
  const {
    data: { getVBIdentificationList: data } = {
      getVBIdentificationList: [] as gql.VbIdentificationList[],
    },
    loading,
    error: getVBIdentificationListError,
  } = gql.useGetVbIdentificationListQuery({ fetchPolicy: 'no-cache' });

  const [name, setName] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState<{
    name?: string;
    authState?: string;
  }>();

  const onSearch = React.useCallback(() => setSearchValue({ ...searchValue, name }), [name, searchValue]);
  const onClear = () => {
    setName('');
    setSearchValue(undefined);
  };

  const { sort, handleChangeSort } = useSort();
  const { handleChangeLimit, handleChangePage, limit, page, setPage } = usePagenation();

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    if (getVBIdentificationListError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
      });
    }
  }, [getVBIdentificationListError, openErrorModal]);

  const generateChip = (identification: number) => {
    const label = identificationLabel(identification);

    switch (identification) {
      case CommonIdentificationStatus.UNCONFIRMED_IDENTITY:
        return <Chip color="warningYellow">{label}</Chip>;
      case CommonIdentificationStatus.CONFIRMED_IDENTITY:
        return <Chip color="okBlue">{label}</Chip>;
      case CommonIdentificationStatus.REJECTED:
        return <Chip color="cautionRed">{label}</Chip>;
      case CommonIdentificationStatus.REQUEST_RECONFIRMATION:
        return <Chip color="warningYellow">{label}</Chip>;
      case CommonIdentificationStatus.EXPIRATION_RESIDENCE:
        return <Chip color="cautionRed">{label}</Chip>;
      case CommonIdentificationStatus.RENEWAL_RESIDENCE:
        return <Chip color="warningYellow">{label}</Chip>;
      default:
        return <Chip color="warningYellow">{label}</Chip>;
    }
  };

  const generateBG = (identification: number) => {
    switch (identification) {
      case CommonIdentificationStatus.CONFIRMED_IDENTITY:
        return 'white';
      case CommonIdentificationStatus.REJECTED:
      case CommonIdentificationStatus.EXPIRATION_RESIDENCE:
        return 'red';
      case CommonIdentificationStatus.UNCONFIRMED_IDENTITY:
      case CommonIdentificationStatus.REQUEST_RECONFIRMATION:
      case CommonIdentificationStatus.RENEWAL_RESIDENCE:
      default:
        return 'yellow';
    }
  };

  const authStatusOptions: SelectOptionType = React.useMemo(
    () => [
      { value: '0', label: '全ての状態' },
      { value: String(CommonIdentificationStatus.UNCONFIRMED_IDENTITY), label: '本人未確認' },
      { value: String(CommonIdentificationStatus.CONFIRMED_IDENTITY), label: '本人確認済' },
      { value: String(CommonIdentificationStatus.REJECTED), label: '非承認' },
      { value: String(CommonIdentificationStatus.REQUEST_RECONFIRMATION), label: '再確認依頼' },
      { value: String(CommonIdentificationStatus.EXPIRATION_RESIDENCE), label: '在留期限切' },
      { value: String(CommonIdentificationStatus.RENEWAL_RESIDENCE), label: '在留更新' },
    ],
    []
  );

  const listHeader: ListHeaderType = [
    { width: 24, key: 'eye' },
    { width: 56, key: 'status', columnName: '状態' },
    { width: 120, key: 'name', columnName: '氏名' },
    { width: 140, key: 'entry_date', columnName: '登録日時' },
    { width: 140, key: 'complete_date', columnName: '承認日時' },
    { width: 140, key: 'id_type', columnName: '本人確認書類' },
  ];

  const listItems: ListItemsType = React.useMemo(() => {
    if (loading) return [];
    let items = data;

    if (searchValue?.authState && searchValue?.authState !== authStatusOptions[0].value)
      items = items.filter(({ identification }) => identification.toString() === searchValue.authState);

    if (searchValue?.name) {
      const regexp = new RegExp(searchValue.name, 'i');
      items = items.filter((item) => item.name?.match(regexp));
    }

    if (sort) {
      if (sort.key === 'status') {
        items = orderBy(items, 'identification', sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items.map((item) => ({
      uniqueKey: item.btmem_id + Math.random(),
      eye: (
        <RelativeContainer>
          <Link to={`/s06/${item.internal_key || 0}`}>
            <Icon name="eye" />
          </Link>
          {item?.newly === NewlyStatus.NEWLY && (
            <Indicator>
              <Icon name="indicatorRed" size={8} />
            </Indicator>
          )}
        </RelativeContainer>
      ),
      status: generateChip(item.identification),
      name: item.name,
      entry_date: item.entry_date,
      complete_date: item.complete_date,
      id_type: item.id_type,
      backgroundColor: generateBG(item.identification),
    }));
  }, [authStatusOptions, data, loading, searchValue, sort]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      {loading && <Loading />}
      <Box height={30} mb={26} display="flex" alignItems="center">
        <Text color="darkBlue" variant="h1">
          本人確認
        </Text>
      </Box>
      <Box flex="auto">
        <Card overflow="hidden">
          <Box display="flex" flexDirection="column">
            <Box height={64} display="flex" alignItems="center" pa={16} gap={16}>
              <Input value={name ?? ''} onChange={(e) => setName(e.target.value)} width={160} placeholder="氏名" />
              <MainButton
                variant="primary"
                width={80}
                icon="search"
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  onSearch();
                }}
              >
                検索
              </MainButton>
              <Divider option="vertical" length={24} />
              <Select
                value={searchValue?.authState ?? ''}
                options={authStatusOptions}
                onChange={(value) => {
                  // ページ初期化
                  setPage(1);
                  setSearchValue({ ...searchValue, authState: value });
                }}
                placeholder="状態"
                width={120}
              />
              <MainButton
                variant="clear"
                width={44}
                onClick={() => {
                  // ページ初期化
                  setPage(1);
                  onClear();
                }}
              >
                クリア
              </MainButton>
            </Box>
            <Divider option="horizontal" />
            <Box flex="auto" px={16}>
              <PaginationBox
                dataSize={listItems.length}
                limit={limit}
                page={page}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
              >
                <StyledListWrapper>
                  <List
                    key={String(limit) + String(page) + String(sort?.key || '')}
                    header={listHeader}
                    items={listItems.slice(limit * (page - 1), limit * page)}
                    sort={sort}
                    onChangeSort={handleChangeSort}
                    rowHeight={41}
                    width="100%"
                    rowWidth="100%"
                  />
                </StyledListWrapper>
              </PaginationBox>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
