import React, { ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './app.css';
import { MainLayout } from 'MainLayout';

import { S05 } from 'pages/s05';
import { S05Details } from 'pages/s05/[id]';

import Screen07 from 'pages/s07';
import Screen07Details from 'pages/s07/details';

import { S02 } from 'pages/s02';

import Screen1008 from 'pages/s10-08';

import { S0801 } from 'pages/s08-01';
import { S0805 } from 'pages/s08-05';
import { S0804 } from 'pages/s08-04';

import { S03 } from 'pages/s03';

import { S1002 } from 'pages/s10-02';

import { S03Details } from 'pages/s03/[id]';

import { S1003 } from 'pages/s10-03';
import Screen10010203 from 'pages/s10-01/inviteUser';
import { S09 } from 'pages/s09';
import { S09Details } from 'pages/s09/[id]';
import { useAuth } from './hooks/authProvider';
import { SignIn } from './pages/s01';
import { ForgotPassword } from './pages/s01/forgotPassword';

import { S0401 } from './pages/s04-01';
import { S0401Details } from './pages/s04-01/[id]';
import { S0402 } from './pages/s04-02';
import { S0402Details } from './pages/s04-02/[id]';
import { S06 } from './pages/s06';
import { S06Details } from './pages/s06/[id]';
import S1001 from './pages/s10-01';

import S1004 from './pages/s10-04';
import { S1005 } from './pages/s10-05';
import S1011 from './pages/s10-11';

import { S11 } from './pages/s11';
import { S11Details } from './pages/s11/[id]';

function ProtectedRoute({ children }: { children: ReactElement }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/signIn" replace />;
  }

  return children;
}

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div />;
  }

  // ログインは /signin に飛ばすだけ
  // ログアウトは auth.signOut() を呼ぶだけ
  // ログイン後しか表示させないページは PrivateRoute で囲むだけ

  // 多重認証手順
  // １．トークン生成
  // ２．QRコード生成
  // ３．Authentivatorで認証コード発行
  // ４．認証
  // ５．ここで初めて設定を呼び出してONにできる

  // Indexが初期画面

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          {/** 重要
            新しいルーティングはすべてこの部分に記入してください！
          */}
          {/* 'HOME' */}
          <Route path="/" element={<S02 />} />
          <Route index element={<S02 />} />
          {/* 加盟店 */}
          <Route path="/s03" element={<S03 />} />
          <Route path="/s03/:id" element={<S03Details />} />
          <Route path="/s04-01" element={<S0401 />} />
          <Route path="/s04-01/:id" element={<S0401Details />} />
          <Route path="/s04-02" element={<S0402 />} />
          <Route path="/s04-02/:offerId" element={<S0402Details />} />
          {/* バイトラベラー */}
          <Route path="/s05" element={<S05 />} />
          <Route path="/s05/:id" element={<S05Details />} />

          <Route path="/s06" element={<S06 />} />
          <Route path="/S06/:id" element={<S06Details />} />
          {/* 逆オファー */}
          <Route path="/s07" element={<Screen07 />} />
          <Route path="/s07/:offerId" element={<Screen07Details />} />
          {/* レビュー管理 */}
          <Route path="/S11" element={<S11 />} />
          <Route path="/S11/:workId" element={<S11Details />} />
          {/* 支払関連 */}
          <Route path="s08-01" element={<S0801 />} />
          <Route path="s08-05" element={<S0805 />} />
          {/* 請求関連 */}
          <Route path="s08-04" element={<S0804 />} />
          {/* 通知配信 */}
          <Route path="/s09" element={<S09 />} />
          <Route path="/s09/:id" element={<S09Details />} />
          {/* 各種設定 */}
          <Route path="/s10-01" element={<S1001 />} />
          <Route path="/s10-02" element={<S1002 />} />
          <Route path="/s10-03" element={<S1003 />} />
          <Route path="/S10-04" element={<S1004 />} />

          <Route path="/S10-05" element={<S1005 />} />
          <Route path="/S10-08" element={<Screen1008 />} />
          <Route path="/s10-11" element={<S1011 />} />
          <Route path="/screen1008/:btmem_id" element={<Screen1008 />} />
        </Route>

        <Route path="/s10-01/inviteUser" element={<Screen10010203 />} />
        <Route path="signIn" element={<SignIn />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
