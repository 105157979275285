import { Box } from 'components/box';
import { Info } from 'components/info';
import { MainButton } from 'components/mainButton';
import { Divider } from 'components/newDivider';
import { Text } from 'components/text';
import { InfoItemsType, ModalRet, VtMemberListCreateKey } from 'pages/s03/type';
import React from 'react';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';
import {
  useGetMBrandByIdLazyQuery,
  useGetMBusinessByIdLazyQuery,
  useGetMStateByIdLazyQuery,
  useGetMCityByIdLazyQuery,
  useAddTBaseMutation,
} from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { useErrorModal } from 'components/error/errorModalProvider';
import { ModalZindex } from 'components/const';

export function ConfirmModal(
  control: Control<VtMemberListCreateKey>,
  getValues: UseFormGetValues<VtMemberListCreateKey>,
  setValue: (key: keyof VtMemberListCreateKey, value: any) => void,
  onClose: () => void,
  onBack: () => void,
  onNext: () => void
): ModalRet {
  const { openErrorModal } = useErrorModal();
  const currentBusiness = useWatch({ control, name: 'business' });
  const currentBrand = useWatch({ control, name: 'brand' });
  const currentState = useWatch({ control, name: 'state' });
  const currentCity = useWatch({ control, name: 'city' });

  const [getMBrandById, { data: mBrandByIdData, loading: mBrandByIdLoading, error: mBrandByIdError }] =
    useGetMBrandByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentBrand) },
    });
  const [getMBusinessById, { data: mBusinessByIdData, loading: mBusinessByIdLoading, error: mBusinessByIdError }] =
    useGetMBusinessByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentBusiness) },
    });
  const [getMStateById, { data: mStateByIdData, loading: mStateByIdLoading, error: mStateByIdError }] =
    useGetMStateByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentState) },
    });
  const [getMCityById, { data: mMCityByIdData, loading: mMCityByIdLoading, error: mCityByIdError }] =
    useGetMCityByIdLazyQuery({
      context: { clientName: 'master' },
      variables: { mid: Number(currentCity) },
    });

  const [addTBaseMutation, { loading: addTBaseLoading }] = useAddTBaseMutation();

  const businessData = mBusinessByIdData?.getMBusinessById;
  const brandData = mBrandByIdData?.getMBrandById;
  const stateData = mStateByIdData?.getMStateById;
  const cityData = mMCityByIdData?.getMCityById;

  React.useEffect(() => {
    if (currentBusiness === undefined || currentBusiness === null) return;
    getMBusinessById({
      variables: { mid: Number(currentBusiness) },
    });
  }, [currentBusiness, getMBusinessById]);
  React.useEffect(() => {
    if (currentBrand === undefined || currentBrand === null) return;
    getMBrandById({
      variables: { mid: Number(currentBrand) },
    });
  }, [currentBrand, getMBrandById]);
  React.useEffect(() => {
    if (currentState === undefined || currentState === null) return;
    getMStateById({
      variables: { mid: Number(currentState) },
    });
  }, [currentState, getMStateById]);
  React.useEffect(() => {
    if (currentCity === undefined || currentCity === null) return;
    getMCityById({
      variables: { mid: Number(currentCity) },
    });
  }, [currentCity, getMCityById]);

  React.useEffect(() => {
    if (mBusinessByIdError || mBrandByIdError || mStateByIdError || mCityByIdError) {
      openErrorModal({
        message:
          'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
        zIndex: ModalZindex.ERRMODALZINDEX,
      });
    }
  }, [mBusinessByIdError, mBrandByIdError, mStateByIdError, mCityByIdError, openErrorModal]);

  const infoItems: InfoItemsType = [
    { label: '業種', content: businessData?.name || '' },
    { label: 'ブランド', content: brandData?.name || '' },
    { label: '店舗名', content: getValues('storeName') },
    {
      label: '住所',
      content: `〒${getValues('zipCode1')}-${getValues('zipCode2')} ${stateData?.name || ''}
        ${cityData?.name || ''}${getValues('address1')}${getValues('address2')}`,
    },
    { label: '電話番号', content: getValues('phone') },
    { label: '緯度/経度', content: `${getValues('lat')}/${getValues('lng')}` },
  ];

  const modalContent: ModalRet = {
    header: (
      <Text variant="h2" color="darkBlue">
        新規登録 確認
      </Text>
    ),
    content: (
      <Box display="flex" height="100%" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="column" rowGap={16} pb={8} width="100%">
          <Text variant="body14">下記内容で登録します。よろしければ登録を押してください。</Text>
          <Divider option="horizontal" />
          <Info items={infoItems} />
        </Box>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        {(addTBaseLoading || mMCityByIdLoading || mStateByIdLoading || mBrandByIdLoading || mBusinessByIdLoading) && (
          <Loading />
        )}
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          キャンセル
        </MainButton>
        <MainButton width={104} variant="secondary" thin onClick={() => onBack()}>
          戻る
        </MainButton>
        <MainButton
          width={104}
          thin
          onClick={() => {
            addTBaseMutation({
              variables: {
                address1: getValues('address1'),
                address2: getValues('address2'),
                brand_id: Number(getValues('brand')),
                city: cityData?.name as string,
                name: getValues('storeName'),
                kana: '', // NULL指定でOK
                lat: Number(getValues('lat')),
                lng: Number(getValues('lng')),
                phone: getValues('phone'),
                state: stateData?.name as string,
                zip_code: `${getValues('zipCode1')}${getValues('zipCode2')}`,
              },
            })
              .then((e) => {
                if (!e.errors) {
                  setValue('id', e.data?.addTBase?.id); // 登録IDをモーダルに出力するために、idをsetValueする
                  onNext();
                } else {
                  onClose();
                  openErrorModal({
                    message:
                      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                  });
                }
              })
              .catch((e) => {
                onClose();
                openErrorModal({
                  message:
                    'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。',
                });
              });
          }}
        >
          登録
        </MainButton>
      </Box>
    ),
    height: 476,
    width: 800,
  };

  return {
    ...modalContent,
  };
}
