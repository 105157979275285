import { HideWrapper, ScrollWrapper } from 'components/assets/css/pages/pageStyle';
import { Box } from 'components/box';
import { Text } from 'components/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { Divider } from 'components/newDivider';
import { SBenefits } from 'graphql/graphql-ow';
import { MainButton } from 'components/mainButton';
import { PRIMARY_COLOR } from 'components/assets/css/style';
import * as gql from 'graphql/graphql-ow';
import { Modal } from 'components/newModal';
import { Loading } from 'components/loading/loading';
import { ErrorModal } from 'components/errorModal';
import { CHIP_TEXT, InputType, ModalStep, TICKET_OPTIONS, ListTitleWidth } from '../constants';
import { chipColor, leaderMenu } from '../s10-02-utils';
import { MiniModalViewDataTyep } from '../s10-02-types';
import { BasicModal } from '../modal/basic-modal';
/**
 * Figma ID: 10-02-08-01
 * 名称: 友達招待特典設定
 */

export interface Props {
  cpRow: Array<SBenefits | undefined>;
  setTicketNumberModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  setValidDaysModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
}

const makeListData = ({ cpRow, setTicketNumberModalStep, setValidDaysModalStep }: Props) =>
  cpRow?.map((row) => ({
    chip: {
      color: chipColor(),
      text: CHIP_TEXT.Before,
    },
    leaderMenu: leaderMenu(1),
    rows: (
      <CampaignTableBody pa={4} gap={4} height="auto">
        <CampaignTableRow pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              枚数
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={100} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row?.iv_ticket_num}枚
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setTicketNumberModalStep(ModalStep.INPUT)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <CampaignTableRow isDivider pt={18} pb={18} gap={16} height={18} width="100%">
          <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
            <Text color="darkBlue" variant="caption12" bold>
              期限
            </Text>
          </CampaignTableItem>
          <CampaignTableItem isDivider width={100} height={18} dividerHeight={18} alignItems="center">
            <Text color="blueGray" variant="caption12" bold>
              {row?.iv_valid_days}日
            </Text>
          </CampaignTableItem>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={8}
            backgroundColor="white"
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center" gap={8}>
              {null}
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" ml={-18}>
              <CampaignTableItem width={ListTitleWidth.NarrowWidth} height={18} dividerHeight={18} alignItems="center">
                <MainButton
                  onClick={() => setValidDaysModalStep(ModalStep.INPUT)}
                  variant="secondary"
                  width={160}
                  thin
                  icon="edit"
                  iconColor={PRIMARY_COLOR.PRIMARY_BLUE}
                >
                  変更
                </MainButton>
              </CampaignTableItem>
            </Box>
          </Box>
        </CampaignTableRow>
        <Divider option="horizontal" />
      </CampaignTableBody>
    ),
  }));

export function PrivilegeSetting() {
  const { data: getData, loading, error, refetch } = gql.useGetSBenefitsQuery();

  const [updateTicketNum, { error: ticketNumError, loading: ticketNumLoading }] =
    gql.useUpdateSBenefitsTicketNumMutation();

  const [updateValidDays, { error: validDaysError, loading: validDaysLoading }] =
    gql.useUpdateSBenefitsValidDaysMutation();

  const [ticketNumberModalStep, setTicketNumberModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [validDaysModalStep, setValidDaysModalStep] = React.useState<ModalStep>(ModalStep.NULL);
  const [errorMessageModalStep, setErrorMessageModalStep] = React.useState<boolean>(false);
  const [TicketNumberViewData, setTicketNumberViewData] = useState<MiniModalViewDataTyep>();

  const [ValidDaysViewData, setValidDaysViewData] = useState<MiniModalViewDataTyep>();

  const [loadingStep, setLoadingStep] = useState<boolean>();
  const errorMessage = error || ticketNumError || validDaysError;
  useEffect(() => {
    if (getData) {
      setTicketNumberViewData({
        title: {
          input: '枚数',
          check: '枚数',
          complete: '枚数設定 完了',
        },
        label: '枚数',
        name: 'ticketNumber',
        placeholder: '選択',
        inputType: InputType.Select,
        unit: '枚',
        options: TICKET_OPTIONS,
        data: getData.getSBenefits.iv_ticket_num,
      });
      setValidDaysViewData({
        title: {
          input: '期限',
          check: '期限',
          complete: '期限変更 完了',
        },
        label: '期限',
        name: 'expirationDays',
        placeholder: '日',
        inputType: InputType.Input,
        unit: '日',
        data: getData.getSBenefits.iv_valid_days,
      });
    }
    if (errorMessage && errorMessage.message) {
      setErrorMessageModalStep(true);
    }
  }, [errorMessage, getData]);
  const listItems = useMemo(
    () => makeListData({ cpRow: [getData?.getSBenefits], setTicketNumberModalStep, setValidDaysModalStep }),
    [getData]
  );

  const handleTicketNumberModalClose = useCallback(() => {
    setLoadingStep(true);
    refetch()
      .then((e) => {
        if (!e.loading) {
          setTicketNumberModalStep(ModalStep.NULL);
          setLoadingStep(false);
        }
      })
      .finally(() => {
        setLoadingStep(false);
      });
  }, [refetch]);

  const handleValidDaysModalClose = useCallback(() => {
    setLoadingStep(true);
    refetch()
      .then((e) => {
        if (!e.loading) {
          setValidDaysModalStep(ModalStep.NULL);
          setLoadingStep(false);
        }
      })
      .finally(() => {
        setLoadingStep(false);
      });
  }, [refetch]);

  const { displayModal: ticketNumberModal } = BasicModal({
    modalStep: ticketNumberModalStep,
    setModalStep: setTicketNumberModalStep,
    onClose: handleTicketNumberModalClose,
    modalViewData: TicketNumberViewData,
    mutation: (type_id?: number, data?: string | number | null) => {
      updateTicketNum({ variables: { iv_ticket_num: data as number } }).then((result) => {
        if (!result.errors) {
          setTicketNumberModalStep(ModalStep.COMPLETE);
        } else {
          setErrorMessageModalStep(true);
          setTicketNumberModalStep(ModalStep.NULL);
        }
      });
    },
    rules: {
      required: '枚数を入力してください。',
    },
  });
  const { displayModal: validDaysModal } = BasicModal({
    modalStep: validDaysModalStep,
    setModalStep: setValidDaysModalStep,
    onClose: handleValidDaysModalClose,
    modalViewData: ValidDaysViewData,
    mutation: (type_id?: number, data?: string | number | null) => {
      updateValidDays({ variables: { iv_valid_days: data as number } }).then((result) => {
        if (!result.errors) {
          setValidDaysModalStep(ModalStep.COMPLETE);
        } else {
          setErrorMessageModalStep(true);
          setTicketNumberModalStep(ModalStep.NULL);
        }
      });
    },
    rules: {
      required: '期限を入力してください。',
      max: {
        value: 999,
        message: '999以下の数値で入力してください。',
      },
      pattern: {
        value: /^\d*$/,
        message: '数値で入力してください。',
      },
    },
  });

  if (loading || ticketNumLoading || validDaysLoading || loadingStep) {
    return Loading();
  }

  return (
    <>
      <HideWrapper>
        <Box px={16} pt={0} pb={24}>
          <Box display="flex" height={60} width="100%" alignItems="center" justifyContent="space-between" gap={8}>
            <Box display="flex" flexDirection="row" flex="1" alignItems="center" gap={8}>
              <Text color="darkBlue" variant="h2">
                友達招待特典設定
              </Text>
              <Text color="darkBlue" variant="caption12">
                加盟店の招待コード使用によるトライアルチケット特典の期限と枚数設定
              </Text>
            </Box>
          </Box>
          <Divider option="horizontal" />
          <ScrollWrapper bottom={311}>{listItems ? <CampaignTable listItems={listItems} /> : null}</ScrollWrapper>
        </Box>
      </HideWrapper>
      <Modal
        width={ticketNumberModal.width}
        height={ticketNumberModal.height}
        isOpen={ticketNumberModalStep !== ModalStep.NULL}
        onClose={ticketNumberModal.onClose}
        header={ticketNumberModal.header}
        content={ticketNumberModal.content}
        footer={ticketNumberModal.footer}
        overflow="visible"
      />
      <Modal
        width={validDaysModal.width}
        height={validDaysModal.height}
        isOpen={validDaysModalStep !== ModalStep.NULL}
        onClose={validDaysModal.onClose}
        header={validDaysModal.header}
        content={validDaysModal.content}
        footer={validDaysModal.footer}
      />
      <ErrorModal
        title="Error"
        isOpen={errorMessageModalStep}
        message={errorMessage?.message || ''}
        onClose={() => {
          setErrorMessageModalStep(false);
        }}
      />
    </>
  );
}
