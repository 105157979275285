import React, { ReactNode } from 'react';
import { VbWorkScoreList, useGetMBusinessQuery, useGetMBrandByBizIdLazyQuery } from 'graphql/graphql-ow';
import { Loading } from 'components/loading/loading';
import { styled } from '@linaria/react';
import { Box } from 'components/box';
import { Divider } from 'components/newDivider';
import { Input } from 'components/input';
import { Select } from 'components/select';
import { MainButton } from 'components/mainButton';
import { DatePicker } from 'components/datePicker';
import { PaginationBox } from 'components/pagination';
import { List } from 'components/list';
import { Icon } from 'components/icon';
import { Link } from 'react-router-dom';
import { orderBy } from 'lodash';
import { isSameDay } from 'date-fns';
import { useErrorModal } from 'components/error/errorModalProvider';

interface Props {
  bitravelerReviewList: VbWorkScoreList[];
}

const StyledListWrapper = styled.div`
  height: calc(100vh - 450px);
  max-height: calc(100vh - 450px);
  overflow: auto;
`;

type FormProps = {
  name: string;
  business: {
    // 業種データ
    label: string;
    value: string;
  };
  brand: {
    // ブランドデータ
    label: string;
    value: string;
  };
  rank: {
    // ランクデータ
    label: string;
    value: string;
  };
  belong: {
    // 所属の有無
    label: string;
    value: string;
  };
  score: {
    // スコアデータ
    label: string;
    value: string;
  };
};

// Figma管理番号
// 11-01-02
export function Bitraveler({ bitravelerReviewList }: Props) {
  const [currentLimit, setCurrentLimit] = React.useState<10 | 20 | 30>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [dataSize, setDataSize] = React.useState<number>(0);
  const [sort, setSort] = React.useState<
    | {
        key: string;
        direction: 'asc' | 'desc';
      }
    | undefined
  >();

  const handleChangeLimit = (value: 10 | 20 | 30) => {
    setCurrentPage(1);
    setCurrentLimit(value);
  };

  const handleChangePage = (value: number) => {
    setCurrentPage(value);
  };

  const handleChangeSort = (key: string) => {
    setCurrentPage(1);
    if (sort?.key !== key) {
      setSort({
        key,
        direction: 'asc',
      });
      return;
    }
    if (sort && sort.key === key) {
      if (sort.direction === 'asc') {
        setSort({
          key,
          direction: 'desc',
        });
        return;
      }
    }
    setSort(undefined);
  };

  const [searchNo, setSearchNo] = React.useState<string>('');
  const [searchStore, setSearchStore] = React.useState<string>('');
  const [searchBitraveler, setSearchBitraveler] = React.useState<string>('');
  // 検索条件用の型
  type SearchCondition = {
    no: string;
    store: string;
    bitraveler: string;
  };
  const [searchConditions, setSearchConditions] = React.useState<SearchCondition | null>(null);
  const [workingDay, setWorkingDay] = React.useState<Date | null>(null);
  const [form, setForm] = React.useState<FormProps>({
    name: '',
    business: { label: '', value: '' },
    brand: { label: '', value: '' },
    rank: { label: '', value: '' },
    belong: { label: '', value: '' },
    score: { label: '', value: '' },
  });

  const {
    data: mBusinessData,
    loading: mBusinessLoading,
    error: mBusinessError,
  } = useGetMBusinessQuery({
    context: { clientName: 'master' },
  });

  const [getMBrandByBizId, { data: mBrandByBizIdData, loading: mBrandByBizIdLoading, error: mBrandByBizIdError }] =
    useGetMBrandByBizIdLazyQuery({
      context: { clientName: 'master' },
    });

  const [businessSelect, setBusinessSelect] = React.useState<string>('');
  // 業種が選択されたらselectedStateIdを更新する関数
  const handleBusinessSelect = (businessId: string) => {
    setBusinessSelect(businessId);
  };

  const brandOption = React.useMemo(
    () => [
      ...((businessSelect &&
        mBrandByBizIdData?.getMBrandByBizId
          ?.map((brand) => ({
            label: brand?.name || '',
            value: brand?.name || '',
          }))
          .filter((option) => option !== null && option !== undefined)) ||
        []),
    ],
    [mBrandByBizIdData, businessSelect]
  );

  // エラーダイアログ
  const { openErrorModal } = useErrorModal();
  React.useEffect(() => {
    let stringMessage =
      'サーバーとの接続に失敗しました。\n一時的にサーバーとの接続が不安定となっている可能性があります\n少し時間をおいてから再度お試しください。';

    if (mBusinessError || mBrandByBizIdError) {
      setSearchNo('');
      setSearchStore('');
      setSearchBitraveler('');
      setSearchConditions(null);
      setWorkingDay(null);
      setBusinessSelect('');
      setForm({
        name: '',
        business: { label: '', value: '' },
        brand: { label: '', value: '' },
        rank: { label: '', value: '' },
        belong: { label: '', value: '' },
        score: { label: '', value: '' },
      });
      setCurrentPage(1);
      if (mBusinessError) {
        stringMessage = '業種情報が取得できませんでした。';
      } else if (mBrandByBizIdError) {
        stringMessage = 'ブランド情報が取得できませんでした。';
      }

      openErrorModal({
        message: stringMessage,
      });
    }
  }, [mBusinessError, mBrandByBizIdError, openErrorModal]);

  React.useEffect(() => {
    if (businessSelect) {
      getMBrandByBizId({ variables: { bid: Number(businessSelect) } });
    }
  }, [businessSelect, getMBrandByBizId]);

  const header: {
    width: number;
    columnName?: string;
    key: string;
  }[] = [
    { width: 40, key: 'eye' },
    { width: 120, key: 'btmem_name', columnName: 'バイトラベラー' },
    { width: 80, key: 'rank_name', columnName: 'ランク' },
    { width: 80, key: 'belong', columnName: '所属' },
    { width: 156, key: 'tpmem_name', columnName: '店舗名' },
    { width: 120, key: 'bt_score', columnName: '今回のスコア' },
    { width: 120, key: 'tp_post_day', columnName: '加盟店投稿日' },
    { width: 120, key: 'work_day', columnName: 'バイト日' },
    { width: 88, key: 'hour_wage', columnName: '時給' },
    { width: 88, key: 'trans_fee', columnName: '交通費' },
    { width: 112, key: 'offer_no', columnName: '掲載No.' },
    { width: 120, key: 'biz_name', columnName: '業種' },
    { width: 120, key: 'brand_name', columnName: 'ブランド' },
  ];

  /**
   * 検索条件でのフィルタ処理
   */
  const filterSearchConditions = React.useCallback((searchParams: SearchCondition, list: VbWorkScoreList[]) => {
    // 検索条件でフィルタリング
    const filteredList = [...list]
      .filter(({ offer_no }) => {
        // 募集掲載No.
        if (!searchParams.no) return true;
        return offer_no === searchParams.no;
      })
      .filter(({ tpmem_name }) => {
        // 店舗名
        if (!searchParams.store) return true;
        return String(tpmem_name).indexOf(searchParams.store || '') > -1;
      })
      .filter(({ btmem_name }) => {
        // バイトラベラー
        if (!searchParams.bitraveler) return true;
        return String(btmem_name).indexOf(searchParams.bitraveler || '') > -1;
      });

    return filteredList;
  }, []);

  /**
   * バイト日でのフィルタ処理
   */
  const filterWorkingDay = (workingDate: Date, list: VbWorkScoreList[]) => {
    const filteredList = [...list].filter(({ work_day }) => {
      if (!work_day) {
        return false;
      }
      const regex = /(\d{4})年(\d{2})月(\d{2})日/;
      const matches = work_day.match(regex);

      if (matches) {
        const year = Number(matches[1]);
        const month = Number(matches[2]);
        const day = Number(matches[3]);
        return isSameDay(new Date(year, month - 1, day), workingDate);
      }
      return false;
    });

    return filteredList;
  };

  const displayItems: { uniqueKey: string | number; [key: string]: ReactNode }[] = React.useMemo(() => {
    let items = bitravelerReviewList;

    // 業種 でフィルタリング
    if (form.business.value) {
      items = items.filter(({ biz_name }) => form.business.label === biz_name);
    }

    // ブランドでフィルタリング
    if (form.brand.value) {
      items = items.filter(({ brand_name }) => form.brand.value === brand_name);
    }

    // ランクでフィルタリング
    if (form.rank.value) {
      const targetRank = form.rank.value;
      items = items.filter(({ rank_name }) => {
        const itemIds = rank_name?.split(',');
        return itemIds?.some((id) => targetRank === id);
      });
    }

    // 所属でフィルタリング
    if (form.belong.value) {
      items = items.filter(({ belong }) => form.belong.value === String(belong));
    }

    // スコアでフィルタリング
    if (form.score.value) {
      items = items.filter(({ bt_score }) => form.score.value === String(bt_score));
    }

    // 検索条件でフィルタリング
    if (searchConditions) items = filterSearchConditions(searchConditions, items);

    // バイト日でフィルタリング
    if (workingDay) items = filterWorkingDay(workingDay, items);

    setDataSize(items.length);
    if (sort) {
      if (sort.key === 'btmem_name') {
        items = orderBy(items, 'btmem_name', sort.direction);
      } else if (sort.key === 'hour_wage') {
        items = orderBy(items, (item) => parseInt(item.hour_wage?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else if (sort.key === 'trans_fee') {
        items = orderBy(items, (item) => parseInt(item.trans_fee?.replace(/[^\d]/g, '') ?? '0', 10), sort.direction);
      } else {
        items = orderBy(items, sort.key, sort.direction);
      }
    }

    return items
      .map((item) => ({
        uniqueKey: Math.random(),
        eye: (
          <Link to={`/S11/${item.work_id}`}>
            <Icon name="eye" />
          </Link>
        ),
        btmem_name: item.btmem_name,
        rank_name: item.rank_name,
        belong: item.belong === 1 ? '有り' : '無し',
        tpmem_name: item.tpmem_name,
        bt_score: item.bt_score,
        tp_post_day: item.tp_post_day,
        work_day: item.work_day,
        hour_wage: `¥${item.hour_wage || ''}`,
        trans_fee: `¥${item.trans_fee || ''}`,
        offer_no: item.offer_no,
        biz_name: item.biz_name,
        brand_name: item.brand_name,
      }))
      .slice(currentLimit * (currentPage - 1), currentLimit * currentPage);
  }, [
    currentLimit,
    currentPage,
    bitravelerReviewList,
    form,
    searchConditions,
    sort,
    workingDay,
    filterSearchConditions,
  ]);

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {(mBusinessLoading || mBrandByBizIdLoading) && <Loading />}
      <Box display="flex" pa={16} gap={16} alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center" gap={16}>
          <Input
            value={searchNo}
            onChange={(event) => setSearchNo(event?.target.value || '')}
            placeholder="募集掲載No."
            width={120}
          />
          <Input
            value={searchStore}
            onChange={(event) => setSearchStore(event?.target.value || '')}
            placeholder="店舗名"
            width={120}
          />
          <Input
            value={searchBitraveler}
            onChange={(event) => setSearchBitraveler(event?.target.value || '')}
            placeholder="バイトラベラー"
            width={120}
          />
          <MainButton
            variant="primary"
            width={80}
            icon="search"
            onClick={() => {
              setSearchConditions({
                no: searchNo,
                store: searchStore,
                bitraveler: searchBitraveler,
              });
              setCurrentPage(1);
            }}
          >
            検索
          </MainButton>
          <Divider option="vertical" length={24} />
          <Select
            value={form.business.value}
            options={[
              ...(mBusinessData?.getMBusiness
                ?.map((business) => ({
                  label: business?.name || '',
                  value: String(business?.id) || '',
                }))
                ?.filter((option) => option !== null && option !== undefined) || []),
            ]}
            placeholder="業種"
            onChange={(value: string, label: string) => {
              setForm({ ...form, business: { value, label } });
              handleBusinessSelect(value);
              setCurrentPage(1);
            }}
            width={176}
          />
          <Select
            value={form.brand.value}
            options={brandOption || []}
            placeholder="ブランド"
            onChange={(value: string, label: string) => {
              setForm({ ...form, brand: { value, label } });
              setCurrentPage(1);
            }}
            width={176}
          />
          <Divider option="vertical" length={24} />
          <Select
            value={form.rank.value}
            options={[
              { label: 'B', value: 'B' },
              { label: 'A', value: 'A' },
              { label: 'S', value: 'S' },
              { label: '2S', value: '2S' },
              { label: '3S', value: '3S' },
            ]}
            placeholder="ランク"
            onChange={(value: string, label: string) => {
              setForm({ ...form, rank: { value, label } });
              setCurrentPage(1);
            }}
            width={120}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={16}>
          <Select
            value={form.belong.value}
            options={[
              { label: '有り', value: '1' },
              { label: '無し', value: '0' },
            ]}
            placeholder="所属"
            onChange={(value: string, label: string) => {
              setForm({ ...form, belong: { value, label } });
              setCurrentPage(1);
            }}
            width={120}
          />
          <DatePicker
            selected={workingDay}
            onChange={(value) => {
              setWorkingDay(value);
            }}
            placeholderText="バイト日"
            width={120}
          />
          <Select
            value={form.score.value}
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
            ]}
            placeholder="スコア"
            onChange={(value: string, label: string) => {
              setForm({ ...form, score: { value, label } });
              setCurrentPage(1);
            }}
            width={120}
          />
          <MainButton
            variant="clear"
            width={44}
            onClick={() => {
              setSearchNo('');
              setSearchStore('');
              setSearchBitraveler('');
              setSearchConditions(null);
              setWorkingDay(null);
              setBusinessSelect('');
              setForm({
                name: '',
                business: { label: '', value: '' },
                brand: { label: '', value: '' },
                rank: { label: '', value: '' },
                belong: { label: '', value: '' },
                score: { label: '', value: '' },
              });
              setCurrentPage(1);
            }}
          >
            クリア
          </MainButton>
        </Box>
      </Box>
      <Divider option="horizontal" />
      <Box flex="auto" width="100%" px={16} pb={0}>
        <PaginationBox
          dataSize={dataSize}
          limit={currentLimit}
          page={currentPage}
          onChangeLimit={handleChangeLimit}
          onChangePage={handleChangePage}
        >
          <StyledListWrapper>
            <List
              key={String(currentLimit) + String(currentPage) + (sort?.key || '')}
              header={header}
              items={displayItems}
              sort={sort}
              rowHeight={40}
              onChangeSort={handleChangeSort}
            />
          </StyledListWrapper>
        </PaginationBox>
      </Box>
    </Box>
  );
}
