import React from 'react';
import { ContentsCard, PageBody, HideWrapper } from 'components/assets/css/pages/pageStyle';
import { SubMenu } from 'components/subMenu';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { MonthlyCampaign } from './components/monthly-campaign';
import { TicketManagement } from './components/ticket-management';
import { MembershipCampaign } from './components/membership-campaign';
import { RegionalCampaign } from './components/regional-campaign';
import { Recruitable } from './components/recruitable';
import { RecruitmentPeriod } from './components/recruitment-period';
import { PrivilegeSetting } from './components/privilege-setting';
import { EducationFeeSetting } from './components/education-fee-setting';
import { BusinessBrandManagement } from './components/business-brand_management';

/**
 * Figma ID: 10-02
 * メインフレーム画面
 */
export function S1002() {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box height={30} mb={26} display="flex" alignItems="center">
        <Text color="blueGray" variant="h1">
          加盟店設定
        </Text>
      </Box>
      <HideWrapper>
        <PageBody bottom={5}>
          <ContentsCard>
            <SubMenu
              items={[
                {
                  content: <MonthlyCampaign />,
                  label: '月会費無料キャンペーン',
                },
                {
                  content: <MembershipCampaign />,
                  label: '入会特典キャンペーン',
                },
                { content: <RegionalCampaign />, label: '地域別キャンペーン' },
                {
                  content: <PrivilegeSetting />,
                  label: '友達招待特典設定',
                },
                {
                  content: <EducationFeeSetting />,
                  label: '育成料設定',
                },
                {
                  content: <Recruitable />,
                  label: '募集可能件数',
                },
                {
                  content: <RecruitmentPeriod />,
                  label: '募集可能期間',
                },
                {
                  content: <TicketManagement />,
                  label: 'トライアルチケット管理',
                },
                {
                  content: <BusinessBrandManagement />,
                  label: '業種/ブランド管理',
                },
              ]}
            />
          </ContentsCard>
        </PageBody>
      </HideWrapper>
    </Box>
  );
}
