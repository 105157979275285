import React from 'react';
import { Loading } from 'components/loading/loading';
import { Box } from 'components/box';
import { Text } from 'components/text';
import { SubMenu } from 'components/subMenu';
import { ContentsCard, PageBody } from 'components/assets/css/pages/pageStyle';
import {
  SPaymentDays,
  SWithholdDay,
  TWalletSum,
  VbPayCsvAllList,
  VtBalanceList,
  useGetVbPayCsvAllListQuery,
  useGetVtBalanceListQuery,
} from 'graphql/graphql-ow';
import { WithholdingTaxConfig } from './components/withholdingTaxConfig';
import { AllMonthlyPartTimeJobExpenses } from './components/allMonthlyPartTimeJobExpenses';
import { AnnualPaydayConfig } from './components/annualPaydayConfig';
import { WalletBallanceList } from './components/walletBalanceList';

// Figma管理番号

export function S1005() {
  const {
    data: paidSalariesData,
    loading: paidSalariesLoading,
    refetch: paidSalariesRefetch,
  } = useGetVbPayCsvAllListQuery(); // 10-06-01 : バイト費用月次全件
  const { data: walletBalanceListData, loading: walletBalanceListLoading } = useGetVtBalanceListQuery(); // 10-07-01 : ウォレット残高一覧

  /* ダミーデータ */
  const dummyWithHoldingTax: SWithholdDay[] = [];
  const dummyAnnualPaydayData: SPaymentDays[] = [];
  const dummyWalletBalanceListData: VtBalanceList[] = [];
  const dummyWalletSum: TWalletSum = {} as TWalletSum;
  const dummyPaidSalariesData: VbPayCsvAllList[] = [];

  return (
    <Box display="flex" flexDirection="column" gap={24} width="100%" height="100%">
      {(paidSalariesLoading || walletBalanceListLoading) && <Loading />}
      <Text variant="h1" color="darkBlue">
        経理関連設定
      </Text>
      <PageBody bottom={60}>
        <ContentsCard>
          <SubMenu
            items={[
              {
                label: '源泉徴収設定',
                content: <WithholdingTaxConfig withHoldingTax={dummyWithHoldingTax} />,
              },
              {
                label: 'バイト費用月次全件',
                content: (
                  <AllMonthlyPartTimeJobExpenses
                    paidSalaries={paidSalariesData?.getVBPayCsvAllList.csv ?? dummyPaidSalariesData}
                    refetch={() => {
                      paidSalariesRefetch();
                    }}
                  />
                ),
                hasNotification: paidSalariesData?.getVBPayCsvAllList.newly.mn_all === 1 ?? false,
              },
              {
                label: '給料日年間設定',
                content: <AnnualPaydayConfig annualPayday={dummyAnnualPaydayData} />,
              },
              {
                label: 'ウォレット残高一覧',
                content: (
                  <WalletBallanceList
                    walletBalanceList={walletBalanceListData?.getVTBalanceList.balance ?? dummyWalletBalanceListData}
                    walletSum={walletBalanceListData?.getVTBalanceList.wallet ?? dummyWalletSum}
                  />
                ),
              },
            ]}
          />
        </ContentsCard>
      </PageBody>
    </Box>
  );
}
