import { Box } from 'components/box';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { ModalRet } from 'pages/s09/type';
import React from 'react';


export function completeRoughDrafhModal(
  onClose: ()=> void
): ModalRet {
  
  const modalContent = {
    header: (
      <Text variant="h2" color="darkBlue">
        下書き保存
      </Text>
    ),
    content: (
      <Box display='flex' flexDirection='row' alignItems='center' height='100%'>
        <Text variant="body14">
          お知らせの下書きを保存しました。
        </Text>
      </Box>
    ),
    footer: (
      <Box display="flex" justifyContent="flex-end" columnGap={8}>
        <MainButton width={104} variant="secondary" thin onClick={() => onClose()}>
          閉じる
        </MainButton>
      </Box>
    ),
    width: 384,
    height: 189
  };
  
  return modalContent;
} 