import { Box } from 'components/box';
import { DatePicker } from 'components/datePicker';
import { InputLabel } from 'components/inputLabel';
import { MainButton } from 'components/mainButton';
import { Text } from 'components/text';
import { DateTime, NewUpdateModalStep, ParcentList, ListTitleWidth } from 'pages/s10-02/constants';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  checkAllTrue,
  checkBrandMessages,
  chipColor,
  chipText,
  convertStringToArray,
  flattenArray,
  formatHyphenDate,
  getStateNamesByIds,
  getViewBizNames,
  getViewBrandNames,
  leaderMenu,
  splitComponent,
  Message,
  addOneDay,
} from 'pages/s10-02/s10-02-utils';
import { CampaignTable, CampaignTableBody, CampaignTableItem, CampaignTableRow } from 'components/campaignTable';
import { formatDate, formatJpDate, splitString } from 'components/utils';
import * as gql from 'graphql/graphql-ow';
import { CreateBlockKey, MonthlyCampaignUpdateType } from 'pages/s10-02/s10-02-types';
import { TextSet } from 'pages/s10-02/components/textSet';
import { useAuth } from 'hooks/authProvider';
import { useForm } from 'react-hook-form';
import { RhfInput } from 'components/rhfInput';
import { ErrorMessage } from 'components/errorMessage';
import { RhfCheckbox } from 'components/rhfCheckbox';
import { styled } from '@linaria/react';
import { RhfSelect } from 'components/rhfSelect';
import { differenceInMilliseconds } from 'date-fns';
import { CheckModal } from '../check-modal';
import { RegionModal } from '../region-modal';
import { CompleteModal } from '../complete-modal';

/**
 * Figma ID: 10-02-01-02
 * 名称: 月会費無料キャンペーン新規作成 期間/ブランドを選択
 */

type DefaultValues = {
  title: string;
  brandIds: string;
  beginEnd: string;
  beginDate: string;
  endDate: string;
  freeMonths?: number | null;
  stateNames: string;
  stateIds: string;
  bizBrandList: {
    bizId: string;
    brandIds: string[];
  }[];
};

interface Props {
  modalStep: NewUpdateModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<NewUpdateModalStep>>;
  defaultValues?: DefaultValues;
  update: (data: MonthlyCampaignUpdateType) => void;
}

interface ModalData {
  title?: string; // キャンペーンタイトル
  brand_ids?: string[]; // ブランド
  begin_end?: string | null;
  end_date?: string | null;
  begin_date?: string | null;
  free_months_disp?: string;
  state_names?: string;
  state_ids?: string;
  viewBizNames?: string[];
  viewBrandNames?: string[];
}

const StyledChildCheckboxWrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  row-gap: 8px;
  margin-bottom: 8px;
  padding: 0 16px;
`;

export function SelectPeriodBrand({ modalStep, setModalStep, defaultValues, update }: Props) {
  const [modalData, setModalData] = useState<ModalData>();

  const { data } = gql.useGetMBusinessBrandQuery({ context: { clientName: 'master' } });
  const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  // バリデーション用
  const {
    control,
    reset,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateBlockKey>({ reValidateMode: 'onSubmit' });

  const onClickHandler = handleSubmit((formData) => {
    if (formData) {
      // TODO: RhfCheckBoxでvalueの値をサポートするように修正してください。
      // 現状は、ブランドIDをindexに設定して利用しています。
      const selectedBrandIds: string[] = [];
      formData.brand_ids?.views.forEach((view) => {
        view.optionValues.forEach((optionValue, index) => {
          if (optionValue) {
            selectedBrandIds.push(index.toString());
          }
        });
      });

      const viewBrandNames = getViewBrandNames(selectedBrandIds, data?.getMBusinessBrand || []);
      const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
      setModalData({
        ...modalData,
        title: formData.title,
        begin_end: `${formatDate(formData?.begin_date || '')} ~ ${formatDate(formData?.end_date || '')}`,
        brand_ids: selectedBrandIds,
        viewBizNames,
        viewBrandNames,
        free_months_disp: formData.months,
      });
      setModalStep(NewUpdateModalStep.REGION);
    }
  });

  const resetModal = useCallback(() => {
    const viewBrandNames = getViewBrandNames([], data?.getMBusinessBrand || []);
    const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
    setModalData({
      title: '',
      begin_end: undefined,
      begin_date: '',
      end_date: '',
      free_months_disp: '',
      state_names: '',
      brand_ids: [],
      state_ids: '',
      viewBrandNames,
      viewBizNames,
    });
  }, [data?.getMBusinessBrand]);

  const bizIds = useMemo(
    () =>
      data?.getMBusinessBrand?.reduce((acc: number[], item) => {
        if (item?.id && !acc.includes(item?.id)) {
          acc.push(item?.id);
        }
        return acc;
      }, []),
    [data]
  );

  const brandOptions = useMemo(() => {
    // DBから取得した業種、ブランドデータから、ブランドのみを取得し1つの配列にまとめる
    const brand = data?.getMBusinessBrand?.flatMap((getMBusinessBrand) => getMBusinessBrand?.brand);

    // 取得した業種、ブランドのデータを業種毎の配列に成形
    const result = brand?.reduce((acc: Array<Array<typeof brand[number]>>, curr) => {
      if (curr) {
        const found = acc.find((e) => e[0]?.biz_id === curr.biz_id);
        if (found) {
          found.push(curr);
        } else {
          acc.push([curr]);
        }
      }
      return acc;
    }, []);

    //   checkBoxGroupのoptionsに設定する選択肢の生成
    const brandOptionDateSet = result?.map((group) => ({
      options: group.map((item) => ({
        value: String(item?.brand_id),
        label: item?.brand_name || '',
      })),
    }));

    return brandOptionDateSet;
  }, [data?.getMBusinessBrand]);

  const [stateIds, setStateIds] = useState<string[]>([]);

  const defaultSelectedBeginDate = useMemo(
    () => (defaultValues?.beginDate ? new Date(formatJpDate(defaultValues?.beginDate)) : null),
    [defaultValues]
  );
  const defaultSeletedEndDate = useMemo(
    () => (defaultValues?.endDate ? new Date(formatJpDate(defaultValues?.endDate)) : null),
    [defaultValues]
  );

  const initializeDefaultData = useCallback(
    (values?: DefaultValues) => {
      setSelectedBeginDate(defaultSelectedBeginDate);
      setSelectedEndDate(defaultSeletedEndDate);
      setStateIds(splitString(values?.stateIds || '', ','));
    },
    [defaultSelectedBeginDate, defaultSeletedEndDate]
  );

  useEffect(() => {
    const brandIdsResult = splitString(defaultValues?.brandIds || '', ',');
    const brandIds = flattenArray(brandIdsResult);
    const convertString = convertStringToArray(defaultValues?.brandIds || '');
    const flatValues = flattenArray(convertString);
    const viewBrandNames = getViewBrandNames(flatValues, data?.getMBusinessBrand || []);
    const viewBizNames = getViewBizNames(data?.getMBusinessBrand || []);
    const dataSet = {
      title: defaultValues?.title,
      begin_end: defaultValues?.beginEnd,
      begin_date: defaultValues?.beginDate || '',
      end_date: defaultValues?.endDate || '',
      free_months_disp: defaultValues?.freeMonths?.toString() || '',
      state_names: defaultValues?.stateNames || '',
      brand_ids: brandIds,
      state_ids: defaultValues?.stateIds || '',
      viewBrandNames,
      viewBizNames,
    };
    setModalData(dataSet);
    initializeDefaultData(defaultValues);
  }, [defaultValues, initializeDefaultData, data?.getMBusinessBrand]);

  const backToDefaultValues = useCallback(() => {
    reset();
    setStateIds(splitString(defaultValues?.stateIds || '', ','));
    setSelectedBeginDate(defaultSelectedBeginDate);
    setSelectedEndDate(defaultSeletedEndDate);
  }, [reset, defaultValues?.stateIds, defaultSelectedBeginDate, defaultSeletedEndDate]);

  const onClose = useCallback(() => {
    backToDefaultValues();
    setModalStep(NewUpdateModalStep.NULL);
  }, [backToDefaultValues, setModalStep]);

  const { data: mAreaState } = gql.useGetMAreaStateQuery({ context: { clientName: 'master' } });

  // 地域モーダル作成
  const { displayRegionModal } = RegionModal({
    title: defaultValues ? '月会費無料キャンペーン複製編集　地域を選択' : '月会費無料キャンペーン新規作成　地域を選択',
    stateIds,
    setModalStep,
    setModalData: (values: string[]) => {
      const stateNames = getStateNamesByIds(values, mAreaState?.getMAreaState);

      setModalData({ ...modalData, state_ids: values.join(','), state_names: stateNames });
    },
    onClose,
    getMAreaState: mAreaState?.getMAreaState,
  });

  // 確認モーダルに渡すキャンペーンテーブルボディ作成
  const listItems = useMemo(() => {
    const row = {
      title: modalData?.title,
      chip: {
        color: chipColor(0),
        text: chipText(0),
      },
      leaderMenu: leaderMenu(0),
      rows: (
        <CampaignTableBody pa={0} gap={4}>
          <CampaignTableRow isDivider pa={0} gap={16} height={18}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                実施期間
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.begin_end}
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
          {splitComponent(modalData?.viewBizNames, modalData?.viewBrandNames)}
          <CampaignTableRow isDivider pa={0} gap={16} height={18}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={18} dividerHeight={18} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                無料継続期間
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={18} dividerHeight={18} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.free_months_disp}ヶ月
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
          <CampaignTableRow isDivider pa={0} gap={16} height={84}>
            <CampaignTableItem width={ListTitleWidth.WideWidth} height={84} dividerHeight={84} alignItems="center">
              <Text color="darkBlue" variant="caption12" bold>
                地域
              </Text>
            </CampaignTableItem>
            <CampaignTableItem isDivider height={84} dividerHeight={84} alignItems="center">
              <Text color="blueGray" variant="caption12">
                {modalData?.state_names}
              </Text>
            </CampaignTableItem>
          </CampaignTableRow>
        </CampaignTableBody>
      ),
    };
    return row;
  }, [modalData]);

  const auth = useAuth();
  const { userInfo } = auth;

  // 確認モーダル用キャンペーンテーブル
  const content = <CampaignTable listItems={[listItems]} frame simple bold />;
  // 確認モーダル生成
  const { displayCheckModal } = CheckModal({
    title: defaultValues ? '月会費無料キャンペーン複製編集成　確認' : '月会費無料キャンペーン新規作成　確認',
    setModalStep,
    onClose: () => {
      onClose();
      displayRegionModal.reset();
    },
    content,
    mutation: () => {
      update({
        begin_date: `${formatHyphenDate(modalData?.begin_date || '')} ${DateTime.BeginDateTime}`,
        end_date: `${formatHyphenDate(modalData?.end_date || '')} ${DateTime.EndDateTime}`,
        brand_ids: modalData?.brand_ids?.join(',') || '',
        state_ids: modalData?.state_ids || '',
        title: modalData?.title || '',
        free_months: Number(modalData?.free_months_disp) || 0,
        umgmt_id: userInfo?.id || 0,
      });
      onClose();
      displayRegionModal.reset();
    },
  });

  // 完了モーダル生成
  const { displayCompleteModal } = CompleteModal({
    title: defaultValues ? '月会費無料キャンペーン複製編集　完了' : '月会費無料キャンペーン新規作成　完了',
    onClose: () => {
      onClose();
      displayRegionModal.reset();
      resetModal();
    },
  });

  const beginDateHandleInput = useCallback(
    (date: Date | null) => {
      setSelectedBeginDate(date);
      setValue('begin_date', date || new Date());
      setModalData({ ...modalData, begin_date: date?.toString() || new Date().toString() });
    },
    [modalData, setValue]
  );

  const endDateHandleInput = useCallback(
    (date: Date | null) => {
      setSelectedEndDate(date);
      setValue('end_date', date || new Date());
      setModalData({ ...modalData, end_date: date?.toString() || new Date().toString() });
    },
    [modalData, setValue]
  );

  /**
   * 開始日と終了日の整合性をチェック
   * 開始日が終了日よりも後の場合、終了日を開始日に合わせる
   */
  useEffect(() => {
    if (selectedBeginDate) {
      const differenceRepeatDate = differenceInMilliseconds(selectedEndDate || 0, selectedBeginDate || 0);
      if (differenceRepeatDate <= 0) {
        setSelectedEndDate(addOneDay(selectedBeginDate || new Date()));
        setValue('end_date', addOneDay(selectedBeginDate || new Date()));
        setModalData({ ...modalData, end_date: addOneDay(selectedBeginDate || new Date()).toString() });
      }
    }
  }, [modalData, selectedBeginDate, selectedEndDate, setSelectedEndDate, setValue]);

  const displayModal = React.useMemo(() => {
    switch (modalStep) {
      case NewUpdateModalStep.INPUT:
        return {
          width: 800,
          height: 530,
          header: (
            <Text variant="h2" color="darkBlue">
              {defaultValues
                ? '月会費無料キャンペーン複製編集　期間/ブランドを選択'
                : '月会費無料キャンペーン新規作成　期間/ブランドを選択'}
            </Text>
          ),
          content: (
            <Box display="flex" flexDirection="column" gap={8}>
              <Box mb={12}>
                <InputLabel>入会キャンペーン名</InputLabel>
                <RhfInput
                  name="title"
                  control={control}
                  defaultValue={defaultValues?.title || ''}
                  placeholder="キャンペーン名を入力"
                  rules={{ required: '必須項目を入力' }}
                  fullWidth
                />
                {errors?.title && (
                  <Box pt={4}>
                    <ErrorMessage>{errors?.title.message}</ErrorMessage>
                  </Box>
                )}
              </Box>
              <Box mb={12}>
                <Box alignItems="start" display="flex" gap={8}>
                  <TextSet
                    label="入会期限 開始期間を指定"
                    error
                    content={
                      <>
                        <DatePicker
                          width={160}
                          minDate={addOneDay(new Date())}
                          placeholderText="開始期間を指定"
                          selected={selectedBeginDate}
                          {...register('begin_date', {
                            required: '開始期間を選択してください。',
                            value: defaultValues?.beginDate
                              ? new Date(formatJpDate(defaultValues?.beginDate))
                              : undefined,
                          })}
                          onChange={beginDateHandleInput}
                        />
                        {errors?.begin_date && (
                          <Box pt={4}>
                            <ErrorMessage>{errors?.begin_date.message}</ErrorMessage>
                          </Box>
                        )}
                      </>
                    }
                  />
                  <Box mt={32}>
                    <Text color="liteGray" variant="body14" display="inline">
                      ~
                    </Text>
                  </Box>
                  <TextSet
                    label="終了期間を指定"
                    content={
                      <Box>
                        <DatePicker
                          width={160}
                          minDate={addOneDay(selectedBeginDate || new Date())}
                          placeholderText="終了期間を指定"
                          selected={selectedEndDate}
                          {...register('end_date', {
                            required: '終了期間を選択してください。',
                            value: defaultValues?.endDate ? new Date(formatJpDate(defaultValues?.endDate)) : undefined,
                          })}
                          onChange={endDateHandleInput}
                        />
                        {errors?.end_date && (
                          <Box pt={4}>
                            <ErrorMessage>{errors?.end_date.message}</ErrorMessage>
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </Box>
              </Box>
              <Box mb={12}>
                <TextSet
                  label="月会費無料の継続期間"
                  content={
                    <>
                      <Box alignItems="center" backgroundColor="white" display="flex" width={176} gap={8}>
                        <RhfSelect
                          width={120}
                          control={control}
                          name="months"
                          options={ParcentList}
                          defaultValue={defaultValues?.freeMonths?.toString() || ''}
                          placeholder="期間を選択"
                          rules={{ required: '期間を選択してください' }}
                        />
                        <Box mt={10}>
                          <Text color="blueGray" variant="body14">
                            ヶ月
                          </Text>
                        </Box>
                      </Box>
                      {errors?.months && (
                        <Box pt={4}>
                          <ErrorMessage>{errors?.months.message}</ErrorMessage>
                        </Box>
                      )}
                    </>
                  }
                />
              </Box>
              {bizIds?.map((bizId, index) => {
                const defaultSelectedBrands =
                  defaultValues?.bizBrandList.find((item) => item.bizId === bizId.toString())?.brandIds || [];
                const brands = brandOptions ? brandOptions[index].options : [];
                return (
                  <>
                    <RhfCheckbox
                      name={`brand_ids.views.${index}.value`}
                      control={control}
                      defaultValue={defaultSelectedBrands.length === brands.length}
                      onChange={(event) => {
                        const options = brandOptions ? brandOptions[index].options : [];
                        options.forEach((option, optionIndex) => {
                          /**
                           * 子ブランド全てにチェックを入れるか外すか
                           */
                          if (event.target.checked) {
                            setValue(
                              `brand_ids.views.${index}.optionValues.${Number(option.value)}`,
                              event.target.checked
                            );
                            setValue(
                              `brand_ids.ids.${index}.optionValues.${Number(option.value)}`,
                              options[optionIndex].value
                            );
                          } else {
                            setValue(`brand_ids.views.${index}.optionValues.${Number(option.value)}`, null);
                            setValue(`brand_ids.ids.${index}.optionValues.${Number(option.value)}`, null);
                          }
                        });
                      }}
                    >
                      <Text variant="h3" color="blueGray">
                        {data?.getMBusinessBrand
                          ? (data?.getMBusinessBrand?.find((element) => element?.id === bizId)?.name as string)
                          : ''}
                      </Text>
                    </RhfCheckbox>
                    <StyledChildCheckboxWrapper>
                      {brands.map((option) => (
                        <RhfCheckbox
                          name={`brand_ids.views.${index}.optionValues.${Number(option.value)}`}
                          control={control}
                          defaultValue={defaultSelectedBrands.includes(option.value)}
                          rules={{
                            validate: (_, values) => {
                              let isAtLeastOneChecked = false;
                              values.brand_ids?.views.forEach((brand) => {
                                brand.optionValues.forEach((value) => {
                                  if (value) {
                                    isAtLeastOneChecked = true;
                                  }
                                });
                              });
                              return isAtLeastOneChecked || 'ブランドをチェックしてください';
                            },
                          }}
                          onChange={(event) => {
                            const allBrandsChecked = checkAllTrue(
                              getValues(`brand_ids.views.${index}.optionValues`).filter((v) => v !== undefined),
                              true
                            );
                            setValue(`brand_ids.views.${index}.value`, allBrandsChecked);
                            setValue(
                              `brand_ids.ids.${index}.optionValues.${Number(option.value)}`,
                              event.target.checked ? option.value : null
                            );
                          }}
                        >
                          {option.label}
                        </RhfCheckbox>
                      ))}
                    </StyledChildCheckboxWrapper>
                  </>
                );
              })}
              {checkBrandMessages((errors?.brand_ids?.views as Message[]) || []) && (
                <Box pt={4}>
                  <ErrorMessage>ブランドをチェックしてください</ErrorMessage>
                </Box>
              )}
            </Box>
          ),
          footer: (
            <Box display="flex" justifyContent="flex-end" columnGap={8}>
              <MainButton thin onClick={onClose} variant="secondary" width={104}>
                キャンセル
              </MainButton>
              <MainButton
                thin
                onClick={(event) => {
                  onClickHandler(event);
                }}
                variant="primary"
                width={104}
              >
                次へ
              </MainButton>
            </Box>
          ),
          onClose,
        };
      case NewUpdateModalStep.REGION:
        return {
          width: displayRegionModal.width,
          height: displayRegionModal.height,
          header: displayRegionModal.header,
          content: displayRegionModal.content,
          footer: displayRegionModal.footer,
          onClose: displayRegionModal.onClose,
        };
      case NewUpdateModalStep.CHECK:
        return {
          width: displayCheckModal.width,
          height: displayCheckModal.height,
          header: displayCheckModal.header,
          content: displayCheckModal.content,
          footer: displayCheckModal.footer,
          onClose: displayCheckModal.onClose,
        };
      case NewUpdateModalStep.COMPLETE:
        return {
          width: displayCompleteModal.width,
          height: displayCompleteModal.height,
          header: displayCompleteModal.header,
          content: displayCompleteModal.content,
          footer: displayCompleteModal.footer,
          onClose: displayCompleteModal.onClose,
        };
      default:
        return {
          width: 384,
          height: 210,
          onClose,
        };
    }
  }, [
    modalStep,
    defaultValues,
    control,
    getValues,
    errors,
    selectedBeginDate,
    register,
    beginDateHandleInput,
    selectedEndDate,
    endDateHandleInput,
    bizIds,
    onClose,
    displayRegionModal,
    displayCheckModal,
    displayCompleteModal,
    data?.getMBusinessBrand,
    brandOptions,
    setValue,
    onClickHandler,
  ]);
  return { displayModal };
}
