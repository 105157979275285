import React, { ReactNode, useMemo } from 'react';
import { styled } from '@linaria/react';
import { ListHeader } from './listHeader';
import { ListRow, ColorKeyType } from './listRow';

export interface Props {
  header: {
    width: number | string;
    columnName?: string;
    columnIcon?: ReactNode;
    key: string;
  }[];
  items: {
    // レンダリングの最適化のために一意なキーを設定して下さい
    uniqueKey: string | number;
    backgroundColor?: ColorKeyType;
    [key: string]: ReactNode;
  }[];
  sort?: {
    key: string;
    direction: 'asc' | 'desc';
  };
  onChangeSort: (key: string) => void;
  rowHeight?: number;
  rowWidth?: number | string;
  width?: number | string;
  isSortable?: boolean;
}

const StyledHeaderWrapper = styled.div<{ width: number | string }>`
  position: sticky;
  top: 0;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  z-index: 1;
`;

export function List({
  header,
  items,
  sort,
  onChangeSort,
  rowHeight,
  rowWidth,
  width = 'max-content',
  isSortable = true,
}: Props) {
  const rowItems = useMemo(
    () =>
      items.map(({ uniqueKey, backgroundColor, ...item }) => ({
        key: uniqueKey,
        backgroundColor,
        rowItem: header.map((h) => ({
          width: h.width,
          content: item[h.key],
          key: h.key,
        })),
      })),
    [items, header]
  );

  return (
    <>
      <StyledHeaderWrapper width={width}>
        <ListHeader header={header} sort={sort} onChangeSort={onChangeSort} isSortable={isSortable} />
      </StyledHeaderWrapper>
      <ul>
        {rowItems.map(({ key, backgroundColor, rowItem }) => (
          <ListRow
            key={key}
            backgroundColor={backgroundColor}
            rowItem={rowItem}
            rowHeight={rowHeight}
            rowWidth={rowWidth}
          />
        ))}
      </ul>
    </>
  );
}
